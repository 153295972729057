import { Injectable } from '@angular/core';
import { IEnvironmentRequestsStates, IEnvironmentRequestsService } from './environmentRequests.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AlertService } from 'client/app/services/alert.service';
@Injectable()
export class EnvironmentRequestsService implements IEnvironmentRequestsService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService,
        private alertSvc: AlertService) {
        //
    }

    async initDelegate(states: IEnvironmentRequestsStates): Promise<object> {
        states.moment = moment;
        await this.queryRequests(states);
        return {};
    }

    async load10RequestHandler(show10: boolean, states: IEnvironmentRequestsStates) {
        states.show10Request = show10;
        if (states.show10Request) {
            states.pageTitle = '1.0 Environment Requests (LDS)';
        } else {
            states.pageTitle = '2.0 Environment Requests';
        }

        await this.queryRequests(states);
    }

    async queryRequests(states: IEnvironmentRequestsStates) {
        this.argosStore.setItem('buildDaysToQuery', states.daysToQuery);
        if (!states.daysToQuery || states.daysToQuery <= 0 || states.daysToQuery > 3000) {
            states.daysToQuery = 30;
        }

        const monthAgo = new Date();
        monthAgo.setDate(monthAgo.getDate() - states.daysToQuery);

        try {
            if (states.show10Request) {
                const requests = await this.dataAccess.genericFind({
                    model: 'EnvironmentRequest',
                    filter: {
                        order: 'requestDate desc',
                        where: {
                            requestDate: {
                                gte: monthAgo
                            }
                        }
                    }
                });
                states.tableParams = requests;
            } else {
                const requests = await this.dataAccess.genericFind({
                    model: 'Environment20Request',
                    filter: {
                        order: 'requestDate desc',
                        where: {
                            requestDate: {
                                gte: monthAgo
                            }
                        }
                    }
                });
                states.tableParams = requests;
            }
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }
}
