import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IEcsAppEditStates, IEcsAppEditService } from './ecsAppEdit.component.d';
import { StateService, UIRouter } from '@uirouter/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { LoginService } from '../../../services/loginService.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { EcsAppAlarms } from './handler/ecsAppAlarmsHandler.service';
import { EcsAppService } from './handler/ecsAppServiceHandler.service';
import { EcsAppActivityLog } from './handler/ecsAppActivityLogHandler.service';
import { EcsAppTask } from './handler/ecsAppTaskHandler.service';
import { EcsAppShare } from './handler/ecsAppShareHandler.service';
import { MatTableDataSource } from '@angular/material/table';
import { EcsAppEnvironment } from './handler/ecsAppEnvironmentHandler.service';
import * as ynModule from 'yn'

@Injectable()
export class EcsAppEditService implements IEcsAppEditService {
    constructor(private uiRouter: UIRouter, private dataAccess: NgDataAccess,
        private state: StateService, private argosStore: ArgosStoreService,
        private ecsAppAlarmsHandler: EcsAppAlarms, private ecsAppServiceHandler: EcsAppService,
        private ecsAppActivityLogHandler: EcsAppActivityLog, private ecsAppTaskHandler: EcsAppTask, private cdr: ChangeDetectorRef,
        private ecsAppShareHandler: EcsAppShare, private loginServiceHandler: LoginService, private ecsAppEnvironmentHandler: EcsAppEnvironment) {
        //
    }

    // ecsAppAlarmsHandler: EcsAppAlarms = new EcsAppAlarms(this.dataAccess, this.argosStore, this);

    async initDelegate(states: IEcsAppEditStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IEcsAppEditStates) {
        if (this.uiRouter.globals.params.id && this.uiRouter.globals.params.accountname) {
            states.clusterName = this.uiRouter.globals.params.id;
            states.awsAccountName = this.uiRouter.globals.params.accountname;
            states.isAdmin = this.loginServiceHandler.hasRole('ecsAdmin');  // returns true if user is admin or ecsAdmin
            states.isDeployOrOperatorOrAdmin = states.isAdmin;
            states.ec2InstanceTypes = [];
            states.taskMemoryOptions = [];
            states.serviceEvents = [];
            states.selectedEc2InstanceCount = [];
            states.argosUsers = [];
            states.userAdmins = []; // can be a admin or ecs admin
            states.eventChanges = {
                taskContainerDefinitions: {},   //store web, worker container changes excluding env vars edits
                envVariableChanges: {}
            };
            states.newEnvName = '',
            states.newEnvValue = '',
            states.newScalePolicy = {
                TargetTrackingScalingPolicyConfiguration: {
                    PredefinedMetricSpecification: {}
                },
                currentTaskDef: {}
            };

            let today = new Date();
            today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes());
            states.selectedMetricDates = {
                fromDate: new Date(today.getTime() - (1000 * 600 * 1)),   // start with 10 mins range
                toDate: today
            };
            states.chartSettings = {
                labels: [],
                series: [],
                data: [],
                colors: ['#45b7cd', '#00ADF9', '#FF5252', '#631717'],
                datasetOverride: [],
                options: {}
            };

            const [envObj, envRoles, envMgmtSettings] = await Promise.all([
                this.dataAccess.genericMethod({
                    model: 'Environment', method: 'findOne', parameters: {
                        filter: { where: { hostingAppName: states.clusterName }, include: 'client' }
                    }
                }),
                this.dataAccess.genericFind({
                    model: 'EnvironmentRole'
                }),
                this.dataAccess.genericMethod({
                    model: 'EnvironmentManagement', method: 'findOne', parameters: {}
                })
            ]);

            if (envObj) {
                await this.initCurrentUserEnvironmentPermission(envRoles, envObj, states);
            }

            states.envMgmtSettings = envMgmtSettings;

            await this.ecsAppShareHandler.getEnvironmentActivityLog(states);
        } else {
            this.state.go('argos.ecsAccess.ecsAppList');
        }
    }

    async initEcs(envObj: any, states: IEcsAppEditStates) {
        const results = await Promise.all([
            this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsClusters', parameters: {
                    filterList: [states.clusterName],
                    awsAccountName: this.uiRouter.globals.params.accountname
                }
            }),
            this.dataAccess.genericMethod({
                model: 'EnvironmentManagement', method: 'findOne', parameters: {}
            }),
            this.dataAccess.genericFind({
                model: 'Environment',
                filter: { where: { and: [{ hostingAppName: { neq: states.clusterName } }] }, order: 'hostingAppName' }
            }),
            this.dataAccess.genericMethod({
                model: 'ArgosUser', method: 'getUsers', parameters: {
                    filter: {
                        order: 'email'
                    }
                }
            })
        ]);

        await this.initUser(envObj, results, states);

    }

    async initUser(envObj: any, results: any, states: IEcsAppEditStates) {

        for (const key in results[1].ecsEc2Options) {
            states.ec2InstanceTypes.push({ id: key, name: results[1].ecsEc2Options[key] });
        }

        states.ecsResourceTags = results[1].ecsResourceTags;
        states.envVarsAllowedToFlowDownIntoNonWebAndWorkerTasks = results[1].ecsTaskdefEnvVarPassThrough;

        if (results[0] && results[0].clusters && results[0].clusters.length > 0 && envObj) {
            states.cluster = results[0].clusters[0];
            states.cluster.isValid = true;
            states.cluster.errorMessage = [];
            states.cluster.environment = envObj;  // set environment

            states.cluster.environment.userPermissions.forEach(function (u: any) {
                u.editMode = false;
            });

            if (states.cluster.environment.createdAt) {
                states.cluster.environment.createdAt = this.formatDate(states.cluster.environment.createdAt);
            }

            states.cluster.appUrl = 'https://' + envObj.dnsCname + '.clarifyhealth.' + envObj.domainSuffix;
            states.minimumEcsCount = states.cluster.ec2Info.length; // we need at least 1 container to show the task def management screen
            states.userPermissionTable = new MatTableDataSource(_.orderBy(states.cluster.environment.userPermissions, ['email']));

            this.initPostEnvironment(states);
            this.initInstanceHealthTable(states.cluster.targetInstancesHealth, states);
            this.initEcsMaintenanceMode(states);
            this.filterEC2InstanceTypes(states);

            this.getContrainerInfo(states.clusterName, states.cluster.containers,
                states.cluster.accountName, states.cluster.isFargateEnabled, states);
            // await this.ecsAppServiceHandler.getServiceEvents(states.cluster.services, states.cluster.accountName, states);
            this.getClientCareGroupings(envObj.dbName, states);
            // await this.ecsAppAlarmsHandler.getCloudWatchAlarms(envObj, states);
            this.initTaskMemoryOptions(states);

            // init instance counts
            states.cluster.services.forEach((serv: any) => {
                states.selectedEc2InstanceCount.push('1');
            });

            // init clonable environments
            states.cloneableEnvs = _.filter(results[2], function (e) {
                if (e.ecsParameters.isCloneable && e.dbServerId === states.cluster.environment.dbServerId) {
                    return e;
                }
            });

            if (ynModule(states.cluster.environment.ecsParameters.isEnvironmentChangesDisabled)) {
                states.cluster.isEnvironmentChangesDisabled = true;
            } else {
                states.cluster.isEnvironmentChangesDisabled = false;
            }
        }

        states.argosUsers = results[3];
        states.userAdmins = _.filter(states.argosUsers, function (userRole) {
            if (userRole.roles && ((userRole.roles.admin && userRole.roles.admin === true) || (userRole.roles.ecsAdmin && userRole.roles.ecsAdmin === true))) {
                return userRole;
            }
        });
    }

    async initPostEnvironment(states: IEcsAppEditStates) {

        const [salesForceObj, upTimeAlarms, awsRdsSecretObj] = await Promise.all([
            this.dataAccess.genericMethod({
                model: 'SalesforceAccount', method: 'findOne', parameters: {
                    filter: { where: { salesforceId: states.cluster.environment.client.salesforceAccountId } }
                }
            }),
            this.dataAccess.genericMethod({
                model: 'UpTimeApi', method: 'getAlarmChecks', parameters: {
                    searchTerm: states.cluster.appUrl.replace('https://', '')
                }
            }),
            this.dataAccess.genericMethod({
                model: 'Environment', method: 'getRdsSecretInfo', parameters: {
                    dbName: states.cluster.environment.dbName,
                    awsAccountName: this.uiRouter.globals.params.accountname,
                    requestedBy: this.argosStore.getItem('username')
                }
            })
        ]);

        if (salesForceObj.name) {
            states.cluster.environment.salesforceAccountName = salesForceObj.name;
            // add this customer to tags
            states.ecsResourceTags.push({
                key: 'customer',
                value: states.cluster.environment.salesforceAccountName
            });
        }

        states.cluster.upTimeAlarms = upTimeAlarms.alarms;
        states.awsRdsSecret = awsRdsSecretObj.secret;
        this.cdr.detectChanges();
    }

    async refreshRdsSecretInfo(states: IEcsAppEditStates) {
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getRdsSecretInfo', parameters: {
                dbName: states.cluster.environment.dbName,
                awsAccountName: this.uiRouter.globals.params.accountname,
                requestedBy: this.argosStore.getItem('username')
            }
        });

        states.awsRdsSecret = result.secret;
    }

    initTaskMemoryOptions(states: IEcsAppEditStates) {
        for (let x = 1; x < 31; x++) {
            const memInGb = 1024 * x;
            states.taskMemoryOptions.push(memInGb.toString());
        }
    }

    formatDate(dateValue: any) {
        const d = new Date(dateValue);
        const month = d.toLocaleString('default', { month: 'long' });
        const mins = ('0' + d.getMinutes()).slice(-2);

        return month + ' ' + d.getDate() + ', ' + d.getFullYear();
    }

    isActionAllowed(actionName: string, states: IEcsAppEditStates) {
        if (states.isAdmin) {
            return true;
        } else {
            return states.currentUserEnvironmentPermissions.includes(actionName);
        }
    }

    isArgosStaging(clusterName: string, states: IEcsAppEditStates) {
        if (states.cluster.environment.ecsParameters.isArgosStaging === 'true') {
            return true;
        } else {
            return false;
        }
    }

    async checkCloneArgosRequestClone(states: IEcsAppEditStates, cdr: ChangeDetectorRef) {
        states.isCloningRequested = true;

        const minutesAgo = 1200000;   // 20 minutes

        const result = await this.dataAccess.genericFind({
            model: 'EnvironmentActivityLog',
            filter: {
                where: {
                    clusterName: states.clusterName,
                    environmentAction: 'database clone',
                    createdAt: {
                        gte: Date.now() - minutesAgo
                    }
                },
                order: 'id desc'
            }
        });

        if (result && result.length > 0) {
            swal({
                title: 'Database clone request failed',
                text: 'A recent database clone was requested by ' + result[0].triggeredBy + ' at ' + result[0].createdAt + '. Atlas Database clone request can only occur every 20 minutes. ',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                //
            });
        } else {
            await this.cloneArgosRequestClone(states, cdr);
        }
    }

    async cloneArgosRequestClone(states: IEcsAppEditStates, cdr: ChangeDetectorRef) {

        states.cloneDbStatus = 'Requesting database clone from prod database';
        cdr.detectChanges();

        const args = {
            requestedBy: this.argosStore.getItem('username'),
            awsAccountName: 'clarify'
        };

        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'cloneArgosDbClone', parameters: args
        });

        if (!result.isSuccessful) {
            states.cloneDbStatus += '\nRequest failed please check build log ' + result.buildLog;
            cdr.detectChanges();
        } else {

            states.cloneDbStatus += '\nRequest submitted with build log ' + result.buildLog;
            states.pollingVars.runId = result.runId;
            states.pollingVars.buildLog = result.buildLog;
            states.pollingVars.fromEcsCluster = 'argos-v2-1000242-ecs';
            cdr.detectChanges();

            states.promotePollingTimer = window.setInterval(async () => {
                const data = await this.dataAccess.genericMethod({
                    model: 'Environment', method: 'getScreenStatus', parameters: {
                        runId: states.pollingVars.runId, buildLog: states.pollingVars.buildLog
                    }
                });
                if (data.status.indexOf('running') !== -1) {
                    states.cloneDbStatus += '.';
                } else if (data.status.indexOf('db swap ready') !== 0) {
                    clearInterval(states.promotePollingTimer);
                    states.cloneDbStatus += '\n' + data.status;

                    await this.ecsAppActivityLogHandler.saveEventToLog(true, states);
                    states.isCloningRequested = false;
                } else {
                    clearInterval(states.promotePollingTimer);
                    states.cloneDbStatus += '\n' + 'failed: ' + data.status;
                    await this.ecsAppActivityLogHandler.saveEventToLog(false, states);
                    states.isCloningRequested = false;
                }
                cdr.detectChanges();
            }, 5000);

        }

    }

    async checkCloneDbRequest(selectedFromEnvironmentToCloneId: any, states: IEcsAppEditStates, cdr: ChangeDetectorRef) {
        states.isCloningRequested = true;

        const minutesAgo = 3600000;   // 60 minutes

        const result = await this.dataAccess.genericFind({
            model: 'EnvironmentActivityLog',
            filter: {
                where: {
                    clusterName: states.clusterName,
                    environmentAction: 'database clone',
                    createdAt: {
                        gte: Date.now() - minutesAgo
                    }
                },
                order: 'id desc'
            }
        });

        if (result && result.length > 0) {
            swal({
                title: 'Database clone request failed',
                text: 'A recent database clone was requested by ' + result[0].triggeredBy + ' at ' + result[0].createdAt + '. Database clone request can only occur every hour. ',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                //
            });
        } else {
            await this.cloneDbRequest(selectedFromEnvironmentToCloneId, states, cdr);
        }
    }

    async cloneDbRequest(selectedFromEnvironmentToCloneId: any, states: IEcsAppEditStates, cdr: ChangeDetectorRef) {
        const env = (_.filter(states.cloneableEnvs, function (e) {
            if (e.id === selectedFromEnvironmentToCloneId) {
                return e;
            }
        }));

        if (env && env.length === 1) {

            const envInfo = env[0];

            const args = {
                clientInternal: states.cluster.environment.client.internal_name,
                fromEcsName: env[0].hostingAppName,
                fromEcsDbName: env[0].dbName,
                toEcsName: states.clusterName,
                toEcsDbName: states.cluster.environment.dbName,
                dbServerId: states.cluster.environment.dbServerId,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            };

            states.cloneDbStatus = 'Requesting database clone from database ' + args.fromEcsDbName + ' to ' + args.toEcsDbName;
            cdr.detectChanges();

            const result = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'cloneEcsDb', parameters: args
            });

            if (!result.isSuccessful) {
                states.cloneDbStatus += '\nRequest failed please check build log ' + result.buildLog;
                cdr.detectChanges();
            } else {

                states.cloneDbStatus += '\nRequest submitted with build log ' + result.buildLog;
                states.pollingVars.runId = result.runId;
                states.pollingVars.buildLog = result.buildLog;
                states.pollingVars.fromEcsCluster = result.fromEcsCluster;
                cdr.detectChanges();

                states.promotePollingTimer = window.setInterval(async () => {
                    const data = await this.dataAccess.genericMethod({
                        model: 'Environment', method: 'getScreenStatus', parameters: {
                            runId: states.pollingVars.runId, buildLog: states.pollingVars.buildLog
                        }
                    });
                    if (data.status.indexOf('running') !== -1) {
                        states.cloneDbStatus += '.';
                    } else if (data.status.indexOf('db swap ready') !== 0) {
                        clearInterval(states.promotePollingTimer);
                        states.cloneDbStatus += '\n' + data.status;

                        await this.ecsAppActivityLogHandler.saveEventToLog(true, states);
                        states.isCloningRequested = false;
                    } else {
                        clearInterval(states.promotePollingTimer);
                        states.cloneDbStatus += '\n' + 'failed: ' + data.status;
                        await this.ecsAppActivityLogHandler.saveEventToLog(true, states);
                        states.isCloningRequested = false;
                    }
                    cdr.detectChanges();
                }, 5000);

            }
        }
    }

    filterEC2InstanceTypes(states: IEcsAppEditStates) {
        // only show EC2 instances that are not already being used by ECS
        if (states.cluster.ec2Info.length > 0) {
            const ec2sInUse = _.uniq(_.map(states.cluster.ec2Info, 'ec2InstanceType'));
            states.ec2InstanceTypes = _.filter(states.ec2InstanceTypes, function (instance) {
                if (ec2sInUse.indexOf(instance.instanceType) === -1) {
                    return instance;
                }
            });
        }
    }

    async initCurrentUserEnvironmentPermission(environmentRoles: any, envObj: any, states: IEcsAppEditStates) {
        const argosStore = this.argosStore;
        let userPermission: any = _.filter(envObj.userPermissions, function (user) {
            if (user.email === argosStore.getItem('username')) {
                return user;
            }
        });

        if (states.isAdmin) {
            await this.initEcs(envObj, states);
        } else if (userPermission && userPermission.length === 1) {
            userPermission = userPermission[0];

            if (userPermission.view) {
                states.currentUserEnvironmentPermissions = states.currentUserEnvironmentPermissions.concat(_.filter(environmentRoles, function (r) {
                    return r.view === true;
                }));
            }

            if (userPermission.deploy) {
                states.isDeployOrOperatorOrAdmin = true;
                states.currentUserEnvironmentPermissions = states.currentUserEnvironmentPermissions.concat(_.filter(environmentRoles, function (r) {
                    return r.deploy === true;
                }));
            }

            if (userPermission.operator) {
                states.isDeployOrOperatorOrAdmin = true;
                states.currentUserEnvironmentPermissions = states.currentUserEnvironmentPermissions.concat(_.filter(environmentRoles, function (r) {
                    return r.operator === true;
                }));
            }

            if (userPermission.manage) {
                states.currentUserEnvironmentPermissions = states.currentUserEnvironmentPermissions.concat(_.filter(environmentRoles, function (r) {
                    return r.manage === true;
                }));
            }

            // list of all named features
            states.currentUserEnvironmentPermissions = _.uniq(_.map(states.currentUserEnvironmentPermissions, 'id'));
            await this.initEcs(envObj, states);

        } else {
            // kick them out as they dont have permission
            swal({
                title: 'Access denied',
                text: 'Sorry you dont have permission to access this environment. Please contact IT@clarifyhealth.com',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                this.state.go('argos.dashboard');
            });
        }
    }

    async initEcsMaintenanceMode(states: IEcsAppEditStates) {

        if (states.cluster.targetGroupInfo.length > 0 && states.cluster.targetGroupInfo[0].LoadBalancerArns.length > 0) {
            const result = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsMaintenanceMode',
                parameters: {
                    loadBalancerArn: states.cluster.targetGroupInfo[0].LoadBalancerArns[0],
                    awsAccountName: states.cluster.accountName
                }
            });
            if (result.isSuccessful) {
                states.cluster.isMaintenanceModeEnabled = result.isEnabled;
            }
        }
    }

    initInstanceHealthTable(dataSource: any, states: IEcsAppEditStates) {
        // setup instance health table
        states.cluster.targetInstancesHealth = _.orderBy(dataSource, ['HealthCheckPort']);
    }

    async getClientCareGroupings(dbName: string, states: IEcsAppEditStates) {
        const ccgs = await this.dataAccess.genericFind({
            model: 'ClientCareGrouping',
            filter: { where: { databaseName: dbName } }
        });
        ccgs.forEach(function (c: any) {
            if (c && c.overrides.pii && c.overrides.pii.allow) {
                states.cluster.argosClientCareGroupingsHasPatientPiiEnabled = true;
            }

            if (c.careGrouping.toLowerCase().indexOf('clarifyattributedprovider') > -1) {
                states.cluster.argosClientCareGroupingsHasNpoEnabled = true;
            }
        });
    }

    async refreshContrainerTaskDefs(states: IEcsAppEditStates) {
        const response = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsActiveTasks',
            parameters: {
                clusterName: states.clusterName,
                containers: states.cluster.isFargateEnabled ? [] : states.cluster.containers,
                awsAccountName: states.cluster.accountName
            }
        });

        _.forEach(response.activeTasks, function (container) {
            const containerInfo = _.find(states.containerTaskDefinitions, { containerId: container.containerId });
            if (containerInfo) {
                containerInfo.taskDescriptions = container.taskDescriptions;
            }
        });
    }

    async getEcsUpgradeTasksActive(states: IEcsAppEditStates) {
        let upgradeTasks: any[] = [];
        await this.refreshContrainerTaskDefs(states); // refresh active tasks

        // search our list


        states.containerTaskDefinitions.forEach((ctd: any) => {
            const foundTasks = _.filter(ctd.taskDescriptions, (td: any) => td.taskDefName.includes('-upgrade-task'));
            upgradeTasks = upgradeTasks.concat(foundTasks);
        });

        upgradeTasks = _.uniq(_.map(upgradeTasks, 'taskDefName'));

        return upgradeTasks;
    }

    async getContrainerInfo(clusterName: string,
        containers: any,
        accountName: string,
        isFargateEnabled: any,
        states: IEcsAppEditStates) {
        const response = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsActiveTasks',
            parameters: {
                clusterName,
                containers: states.cluster.isFargateEnabled ? [] : states.cluster.containers,
                awsAccountName: accountName
            }
        });
        states.containerTaskDefinitions = response.activeTasks; 

        await this.ecsAppTaskHandler.initTaskDefSettings(accountName, states);
        this.cdr.detectChanges();

        //let taskDefContainerDefs = this.ecsAppShareHandler.getTaskDefContainerDefinitions(containers, states);
        //this.ecsAppTaskHandler.setFargateTaskCpu(states, null, states.currentWebTaskDef);
        //this.ecsAppTaskHandler.setFargateTaskCpu(states, null, states.currentWorkerTaskDef);
        this.ecsAppTaskHandler.checkTaskDefVariables(states);
        await this.initPatches(states);
        this.cdr.detectChanges();
    }

    async initPatches(states: IEcsAppEditStates) {
        if (!!states.cluster.uiVersion) {
            await this.getPatchTableList(states);
        }

        const taskDefinitions = this.ecsAppShareHandler.getContainerDefinitions(_.get(_.find(states.containerTaskDefinitions, 'containerId'), 'containerId'), '-upgrade-task', states);
        if (_.isEmpty(states.cluster.appliedPatches)) {
            const environmentPatches = _.get(_.find(_.get(taskDefinitions, '[0].environment'), ['name', 'DATA_PATCHES']), 'value');
            if (!_.isNil(environmentPatches) && _.size(states.patchListTable)) {
                states.selectedPatches = _.map(_.filter(states.patchListTable, (patches: any) => _.includes(_.split(environmentPatches, ','), patches.id)), (patch: any) => { return { id: patch.id, name: patch.name }; });
            }
        } else {
            states.selectedPatches = _.cloneDeep(states.cluster.appliedPatches);
            states.selectedPatchNames = _.map(states.cluster.appliedPatches, 'name').join(', ');
        }
        this.cdr.detectChanges();
    }


    async getPatchTableList(states: IEcsAppEditStates) {
        let buildTag = '';
        if (states.selectedBuildTag === states.currentBuildTag) {
            buildTag = states.cluster.uiVersion;
        } else {
            buildTag = _.last(_.split(states.selectedBuildTag, '-')) as string;
        }
        const results = await this.dataAccess.genericFind({
            model: 'Patch',
            filter: {
                order: 'createdDate desc',
                where: {
                    and: [
                        { archived: false },
                        { 'releaseInfo.tagName': buildTag }
                    ]
                }
            }
        });
        states.patchListTable = results;
        this.cdr.detectChanges();
    }
}
