import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IErrorLogModalProps, IErrorLogModalStates } from './errorLogModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ErrorLogModalService } from './errorLogModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-errorLogModal',
    templateUrl: './errorLogModal.component.html',
    providers: [ErrorLogModalService]
})

export class ErrorLogModalComponent extends ReactComponentBase<IErrorLogModalProps, IErrorLogModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ErrorLogModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ErrorLogModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close('cancel');
    }
}
