import { Injectable } from '@angular/core';
import { ArgosStoreService } from './argosStore.service';

@Injectable()
export class RootScopeShareService {
    // private _userInfo: any;
    // private _argosStore: ArgosStoreService;
    private _genericDataSet: any = {};
    constructor(private store: ArgosStoreService) {
        // this._argosStore = this.store;
        // this._userInfo = this._argosStore.getItem('profile');
    }

    setGenericData(key: any, val: any) {
        this._genericDataSet[key] = val;
    }

    getGenericData(key: any) {
        return this._genericDataSet[key];
    }

    removeData(key: any) {
        delete this._genericDataSet[key];
    }
}
