import { ChangeDetectorRef, OnInit, Component, OnDestroy } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { IEditDataReleaseStates } from './editDataRelease.component.d';
import { EditDataReleaseServices } from './editDataRelease.component.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ReleaseAddDagModalComponent } from '../releaseAddDagModal/releaseAddDagModal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewReleaseDagConfigModalComponent } from '../viewReleaseDagConfigModal/viewReleaseDagConfigModal.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, switchMap} from 'rxjs/operators';
import { DagLaunchConfirmationModalComponent } from '../dagLaunchConfirmationModal/dagLaunchConfirmationModal.component';
import { RunDataReleaseServices } from '../runDataRelease/runDataRelease.component.service';
import { StateService } from '@uirouter/core';
import { ReleaseRemoveDagModalComponent } from '../releaseRemoveDagModal/releaseRemoveDagModal.component';

@Component({
    selector: 'argos-data-release-manager-edit',
    templateUrl: './editDataRelease.component.html',
    styleUrls: ['./editDataRelease.component.less'],
    providers: [EditDataReleaseServices, RunDataReleaseServices]
})

export class EditDataReleaseComponent extends ReactComponentBase<{}, IEditDataReleaseStates, {}> 
    implements OnInit, OnDestroy {
    constructor(private svc: EditDataReleaseServices, private runSvc: RunDataReleaseServices, private cdr: ChangeDetectorRef, private matDialog: MatDialog, private SnackBar: MatSnackBar, private state: StateService) {
        super({
        } as unknown as IEditDataReleaseStates);
    }

    myCustomerControl = new FormControl('customer');
    myClarifyControl = new FormControl('clarify_data');
    filteredCustomerDags: Observable<string[]>;
    filteredClarifyDags: Observable<string[]>;
    activeDags: string[] = [];
    activeCustomerDags: string[] = [];
    activeClarifyDags: string[] = [];
    showSpinner = false;

    private async _filterCustomer(value: string): Promise<string[]> {
        const filterValue = value.toLowerCase();
        return this.activeCustomerDags.filter((option: string) => option.toLowerCase().includes(filterValue));
    }

    private async _filterClarify(value: string): Promise<string[]> {
        const filterValue = value.toLowerCase();
        return this.activeClarifyDags.filter((option: string) => option.toLowerCase().includes(filterValue));
    }

    async ngOnInit() {
        this.showSpinner = true;
        await this.svc.initDelegate(this.states);
        this.activeDags = await this.svc.getActiveDags();
        this.activeCustomerDags = await this.svc.filterDagsByType('customer', this.activeDags);
        this.activeClarifyDags = await this.svc.filterDagsByType('clarify_data', this.activeDags);
        this.filteredClarifyDags = this.myClarifyControl.valueChanges.pipe(
            startWith(''),
            switchMap((value: string) => this._filterClarify(value || '')),
        );
        this.filteredCustomerDags = this.myCustomerControl.valueChanges.pipe(
            startWith(''),
            switchMap((value: string) => this._filterCustomer(value || '')),
        );
        this.showSpinner = false;
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        if (this.states.myInterval) {
            clearInterval(this.states.myInterval);
        }
    }

    editDagConfig(dagId: string, dagConfig: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    dagId,
                    dagConfig
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ReleaseAddDagModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result) => {
            if (!result.dagConfig) {
                return;
            }
            else {
                this.svc.editDagConfigDelegate(this.states, dagId, result.dagConfig, this.activeDags);
            }
        });
    }

    viewReleaseDagConfig(dagConfig: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    dagConfig
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ViewReleaseDagConfigModalComponent, dialogConfig);
    }

    backToMainPage() {
        this.state.go('argos.data.data_release_manager.list');
    }

    async addDagToRelease(dagId: string) {
        if (this.states.dagsInRelease.map((dag) => dag.dagId).includes(dagId)) {
            this.SnackBar.open('Dag Already in Release. Remove the existing dag first', 'Close', {
                duration: 3000
            });
            return;
        }
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    dagId,
                    dagConfig: ''
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ReleaseAddDagModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (result) => {
            if (!result.dagConfig || !result.dagId) {
                return;
            }
            await this.svc.addDagToReleaseDelegate(this.states, result.dagId, result.dagConfig, this.activeDags);
            this.cdr.detectChanges();
        });
    }

    removeDagFromRelease(dagId: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {}
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ReleaseRemoveDagModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (result) => {
            if (result === 'confirm') {
                await this.svc.removeDagFromReleaseDelegate(this.states, dagId, this.activeDags);
                this.cdr.detectChanges();
            }
        });
    }

    finalizeRelease() {
        this.svc.finalizeReleaseDelegate(this.states);
    }

    cancelAlert() {
        // no alert here for now
    }

    openAirflowLink(dagId: string) {
        const airflowBaseUrl = this.states.astronomerBaseUrl;
        const airflowDagLink = `${airflowBaseUrl}/${dagId}/grid`;
        window.open(airflowDagLink, '_blank');
    }

    async triggerDag(releaseId: string, dataReleaseDagId: string, dagId: string, dagConfig: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                isPseudo: true
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(DagLaunchConfirmationModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (result) => {
            if (!result.runDags) {
                return;
            }
            else {
                await this.runSvc.triggerDag(releaseId, dataReleaseDagId, dagId, dagConfig, true);
            }
        });
        this.svc.startTimerWithInterval(this.states);
    }

    updatedTablesWrapper(updatedTables: any, dagId: string) {
        if (!dagId.includes("final") && (updatedTables.length === 0 || updatedTables === '[]')) {
            return 'Not Tracked';
        }
        return updatedTables
    }

    async resetPseudoDag(states: IEditDataReleaseStates, id: string) {
        await this.svc.resetPseudoDagDelegate(states, id);
    }

}
