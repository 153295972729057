import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IPceMetaDataEditStates } from './pceMetaDataEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PceMetaDataEditService } from './pceMetaDataEdit.component.service';
import { ValidationService } from '../../../services/validationService.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-pceMetaDataEdit',
    templateUrl: './pceMetaDataEdit.component.html',
    providers: [PceMetaDataEditService]
})

export class PceMetaDataEditComponent extends ReactComponentBase<{}, IPceMetaDataEditStates, {}> implements OnInit {
    _: any = _;
    episodePrefix = 'episode_';

    constructor(private svc: PceMetaDataEditService, private cdr: ChangeDetectorRef, private validationSrv: ValidationService) {
        super({
            aggregationTypes: [],
            filterFieldsToDelete: []
        } as unknown as IPceMetaDataEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.svc.save(this.states);
    }


    isSaveDisabled() {
        let saveDisabled = false;
        this.states.errorMessage = '';

        const reqProperties = ['template', 'columnName', 'status'];

        switch (this.states.pceMetaData.template) {
            case 'initial_encounter_market_share':
            case 'referral_encounter_market_share':
                reqProperties.push('selectionFieldColumnId');
                break;
            case 'pce_episode':
                reqProperties.push('selectionFieldColumnId', 'aggregationType');
                break;
            case 'physician_yearly_metrics':
                reqProperties.push('selectionFieldColumnId', 'aggregationType', 'npiColumnId');
                break;
            case 'freeform_sql':
                reqProperties.push('sql', 'freeformSqlTableId');
                break;
        }

        this.states.lodash.forEach(reqProperties, (rp: string) => {
            if (!this.states.pceMetaData[rp]) {
                this.states.pceMetaData[rp] = '';
            }
        });

        if (this.states.obsNames.includes(this.states.pceMetaData.columnName)) {
            saveDisabled = true;
            this.states.errorMessage += 'The name already exists, please change. ';
        }

        if (this.states.pceMetaData.sql !== this.states.originalSql && this.states.pceMetaData.status === 'Active' && this.states.pceMetaData.template === 'freeform_sql') {
            saveDisabled = true;
            this.states.errorMessage += 'The SQL has changed. Please submit for review. ';
        }

        if (this.states.pceMetaData.sql && this.states.pceMetaData.freeformSqlTableId) {
            const tableName = this.states.lodash.find(this.states.freeformSqlTables, { id: this.states.pceMetaData.freeformSqlTableId }).tableName;
            const joinCols = this.states.freeformSqlJoinConditions[tableName];
            if (!joinCols.every((joinCol: any) => this.states.pceMetaData.sql.includes(joinCol))) {
                saveDisabled = true;
                this.states.errorMessage += `The SQL must include all join columns (${joinCols}). `;
            }
        }

        const propertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.pceMetaData, reqProperties);
        if (propertyErrors.length > 0) {
            saveDisabled = true;
            const allMissingArgs = this.states.lodash.map(this.states.lodash.map(propertyErrors, 'propertyName'), (ma: string) => this.states.lodash.startCase(ma));
            this.states.errorMessage += 'The following arguments are missing: ' + allMissingArgs.join(', ') + '. ';
        }

        return saveDisabled;
    }

    selectionFieldChanged(selectedId: number) {
        if (this.states.pceMetaData.template === 'pce_episode') {
            const selectedField = this.states.marketShareFields.find(field => field.id === selectedId);
            if (selectedField?.dataType) {
                this.svc.selectionFieldChanged(selectedField.dataType, this.states);
            }
        } else if (this.states.pceMetaData.template === 'physician_yearly_metrics') {
            const selectedField = this.states.benchmarkSelectFields.find(field => field.id === selectedId);
            if (selectedField?.dataType) {
                this.svc.selectionFieldChanged(selectedField.dataType, this.states);
            }
        }

        if (['initial_encounter_market_share', 'referral_encounter_market_share'].includes(this.states.pceMetaData.template)) {
            this.svc.setName(this.states);
        }
    }

    templateFieldChanged() {
        this.svc.templateFieldChanged(this.states);
    }

    addFilterCriteria() {
        this.svc.addFilterCriteria(this.states);
    }

    deleteFilterCriteria(id: any, index: number) {
        this.svc.deleteFilterCriteria(id, index, this.states);
    }

    setObservationTag(id: any, index: number) {
        this.svc.setObservationTag(id, index, this.states);
    }

    operatorListItemChanged(row: any) {
        this.svc.operatorListItemChanged(row);
    }

    viewHistory() {
        this.svc.viewHistory(this.states);
    }

    submitSQLForReview() {
        this.svc.submitSQLForReview(this.states);
    }

    goToSqlPr() {
        this.svc.goToSqlPr(this.states);
    }

    goToSqlHistory() {
        this.svc.goToSqlHistory(this.states);
    }

    onKeyDown(event: KeyboardEvent): void {
        const cursorPosition = (event.target as HTMLInputElement).selectionStart;
    
        if (cursorPosition <= this.episodePrefix.length && event.key === 'Backspace') {
            event.preventDefault();
        }
    }

}
