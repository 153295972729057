import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IPrismNotificationsStates, IPrismNotificationsService } from './prismNotifications.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class PrismNotificationsService implements IPrismNotificationsService {
    constructor(private dataAccess: NgDataAccess, private alertSvc: AlertService) {
        //
    }

    async initDelegate(states: IPrismNotificationsStates): Promise<object> {
        states.clients = await this.dataAccess.genericFind({
            model: 'Client',
            filter: { fields: { id: true, internal_name: true } }
        });

        states.clients = _.orderBy(states.clients, ['internal_name', 'id']);
        states.clients.unshift({ internal_name: 'SYSTEM_WIDE', id: states.systemWide });
        states.clientListdropdown = states.clients;

        await this.getPrismTableList(states);

        return {};
    }

    async getPrismTableList(states: IPrismNotificationsStates) {
        const tableItems = await this.dataAccess.genericFind({
            model: 'PrismNotification'
        });

        tableItems.forEach((n: any) => {
            n.editMode = false;
        });

        states.clientTable = _.orderBy(tableItems, ['clientId', 'internal_name', 'severity', 'notificationText']);
        // filter out list of already notifield clients
        states.clientListdropdown = _.reject(states.clients, (cli) => _.find(states.clientTable, { clientId: cli.id }));
    }

    async upsertNotifications(states: IPrismNotificationsStates, notification: any) {

        await this.dataAccess.genericUpsert({
            model: 'PrismNotification',
            data: notification
        });
        await this.getPrismTableList(states);
        states.submitForm = {};
    }

    async deleteNotification(states: IPrismNotificationsStates, notification: any, cdr: ChangeDetectorRef) {
        swal({
            title: 'Really?',
            text: 'Are you sure you want to remove the message for client ' + notification.internalName + '?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (!isConfirm.dismiss) {
                await this.dataAccess.genericDelete({
                    model: 'PrismNotification',
                    id: notification.clientId
                });
                await this.getPrismTableList(states);
                cdr.detectChanges();
            }
        });
    }
}
