import { Injectable } from '@angular/core';
import { IViewVersionHistoryModalProps, IViewVersionHistoryModalStates, IViewVersionHistoryModalService } from './viewVersionHistoryModal.component.d';
import * as _ from 'lodash';

@Injectable()
export class ViewVersionHistoryModalService implements IViewVersionHistoryModalService {
    initDelegate(props: IViewVersionHistoryModalProps, states: IViewVersionHistoryModalStates): Object {
        states.versionHistory = props.versionHistory;
        return {};
    }

    changeDelegate(oldProps: IViewVersionHistoryModalProps, newProps: IViewVersionHistoryModalProps, states: IViewVersionHistoryModalStates): Object {
        this.initDelegate(newProps, states);
        return {};
    }

    viewDiffs(version: number, states: IViewVersionHistoryModalStates) {
        states.showDiffs = true;
        states.compareHistory = [];
        states.currentVersion = ('(v' + version + ')');
        states.previousVersion = '';
        const currentRow: any = _.find(states.versionHistory, { version });
        let previousRow: any = {};
        if (currentRow) {
            if (version > 1) {
                const previousVersion = version - 1;
                states.previousVersion = ('(v' + previousVersion + ')');
                previousRow = _.find(states.versionHistory, { version: previousVersion });
            }
        }
        states.compareHistory = _.map(_.keys(currentRow.definition), function (key) {
            return {
                key: _.startCase(key),
                previousValue: _.get(previousRow, 'definition.' + key),
                currentValue: _.get(currentRow, 'definition.' + key)
            };
        });
    }
}
