import { Injectable } from '@angular/core';
import { IQATestResultSubmoduleDetailStates, IQATestResultSubmoduleDetailService } from './qaTestResultDashboardSubmoduleDetail.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';
import { PlatformReleaseResultsService } from '../../../services/platformReleaseResultsService.service';

@Injectable()
export class QATestResultDetailService implements IQATestResultSubmoduleDetailService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService, private platformReleaseResultsService: PlatformReleaseResultsService) {
        //
    }

    async initDelegate(states: IQATestResultSubmoduleDetailStates): Promise<object> {
        await this.fetchResults(states);
        return {};
    }

    async fetchResults(states: IQATestResultSubmoduleDetailStates) {
        states.pctProductId = this.platformReleaseResultsService.getproductId();
        const dateFilter = this.platformReleaseResultsService.getSelectedDate();
        const requestPayload: any = {
            name: this.uiRouter.globals.params.customer,
            submodule_name: this.uiRouter.globals.params.submoduleid,
            product_name: this.uiRouter.globals.params.productId
        };
        if (dateFilter) {
            requestPayload.release_date = dateFilter;
        }
        const pctResults = await this.dataAccess.genericFind({
            model: 'ProductionConfigurationTestResults',
            filter: {
                order: 'dashboard_name asc',
                where: requestPayload
            }
        });
        states.pctSelectedReleaseDate = dateFilter;
        states.pctSubmoduleId = this.uiRouter.globals.params.submoduleid;
        states.customerName = this.uiRouter.globals.params.customer

        console.log(states.pctSubmoduleId, states.customerName)
        const groupedByDashboardName = _.groupBy(pctResults, 'dashboard_name');
        const mapped_array = _.map(groupedByDashboardName, dashboard => {
            const totalTests = dashboard.length;
            const dashboardName = _.sortBy(_.uniqBy(dashboard, 'dashboard_name'), ['dashboard_name']);
            const passedTests = _.filter(dashboard, { status: 'PASS' }).length;
            const passedPCTPercentage = ((passedTests / totalTests) * 100).toFixed(1) + '%';
            const failedPCTPercentage = (((totalTests - passedTests) / totalTests) * 100).toFixed(1) + '%';
            const comments = _.filter(dashboard).map((item) => {
                return item.comments;
            });
            const filterComments = _.uniq(comments);
            
            return {
                dashboardName: dashboardName[0].dashboard_name,
                passedPCTPercentage,
                failedPCTPercentage,
                comments: filterComments
            };
        });
        console.log(mapped_array)
        states.pctDataTable.data = _.orderBy(mapped_array, 'dashboardName');
    }
}