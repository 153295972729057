import { Component, OnInit } from '@angular/core';
import { IReleaseNotesStates } from './releaseNotes.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ReleaseNotesService } from './releaseNotes.component.service';

@Component({
    selector: 'argos-releaseNotes',
    templateUrl: './releaseNotes.component.html',
    providers: [ReleaseNotesService]
})

export class ReleaseNotesComponent extends ReactComponentBase<{}, IReleaseNotesStates, {}> implements OnInit {
    constructor(private svc: ReleaseNotesService) {
        super({
            releaseNotes: [],
            project: 'clarify-hospital',
            changesSince: 'sinceLastDeploy'
        } as unknown as IReleaseNotesStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
    }

    refreshReleaseNotes() {
        this.svc.refreshReleaseNotes(this.states);
    }

    exportReleaseNotes() {
        this.svc.exportReleaseNotes(this.states);
    }
}
