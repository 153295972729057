import { Injectable } from '@angular/core';
import { IDashboardStates, IDashboardService } from './dashboard.component.d';
import * as moment from 'moment';
import * as _ from 'lodash';
import { NgDataAccess } from '../../../services/dataAccess.service';

declare const saveAs: any;

@Injectable()
export class DashboardService implements IDashboardService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: IDashboardStates): Promise<Object> {
        await this.initDataDashboardStats(states);
        await this.initDataEnvironments(states);
        return {};
    }

    async initDataDashboardStats(states: IDashboardStates) {
        const dashboardStats = await this.dataAccess.genericFind({
            model: 'ViewDashboardStats'
        });
        states.totalClaimCount = _.get(_.find(dashboardStats, { label: 'Claims' }), 'totalCount') || 0;
        states.totalPatientCount = _.get(_.find(dashboardStats, { label: 'Patients' }), 'totalCount') || 0;
        states.totalPredictionsCount = _.get(_.find(dashboardStats, { label: 'Predictions' }), 'totalCount') || 0;
        states.totalCareGroupingsCount = _.get(_.find(dashboardStats, { label: 'Care Groupings' }), 'totalCount') || 0;
    }

    async initDataEnvironments(states: IDashboardStates) {

        // const response: HttpResponse<object>
        const environments: any = await this.dataAccess.genericFind({
            model: 'Environment',
            filter: {
                where: {
                    environmentType: 'client_prod',
                    isDisabled: false,
                    applicationType: 'prism'
                },
                include: 'client'
            }
        });
        const prismConnectSummaryRow: any = {
            dnsCname: 'Total'
        };
        states.environments = _.orderBy(environments, 'dnsCname');
        states.months = [];
        states.monthColumns = [];

        for (let i = 0; i <= 24; i++) {
            const month = moment().subtract(i, 'months');
            states.months.push({
                name: 'month' + month.format('MMMYYYY'),
                label: month.format('MMM YYYY'),
                month: month.month() + 1,
                year: month.year(),
                date: month,
                mau: 0,
                dau: 0,
                daumau: 0
            });
            states.monthColumns.push({ type: 'month' + month.format('MMMYYYY') + 'totalLogins', metric: 'total', date: month });
            states.monthColumns.push({ type: 'month' + month.format('MMMYYYY') + 'dau', metric: 'dau', date: month });
            states.monthColumns.push({ type: 'month' + month.format('MMMYYYY') + 'mau', metric: 'mau', date: month });
            states.monthColumns.push({ type: 'month' + month.format('MMMYYYY') + 'daumau', metric: 'daumau', date: month });
        }

        states.monthColumns.forEach(function (monthColumn) {
            prismConnectSummaryRow[monthColumn.type] = 0;
        });

        states.environments.unshift(prismConnectSummaryRow);

        states.monthColumnsFiltered = this.filterMonths(states, states.monthColumns);
        states.monthsFiltered = this.filterMonths(states, states.months);

        await Promise.all(states.environments.map(async (e) => {
            await this.calculateDAUMAU(states, e, 'Prism/Connect', prismConnectSummaryRow);
        }));
    }

    async calculateDAUMAU(states: IDashboardStates, e: any, patientType: string, summaryRow: any) {
        // Summary row doesn't have an id, so make sure we skip that one
        if (e && e.id) {
            const version = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getAppVersion',
                parameters: {
                    id: e.id
                }
            });
            const activationDate = _.get(version, 'activationDate');
            if (activationDate) {
                e.activationDate = moment(activationDate).format('MM/DD/YYYY');
            } else {
                e.activationDate = 'N/A';
            }

            if (patientType === 'Patient') {
                states.totalOnboardedPatients += (version.totalPatientsOnboarded || 0);
            }

            e.clientErrorTotal = version.clientErrorTotal || 0;
            e.allErrorTotal = version.allErrorTotal || 0;
            e.totalActivatedUsers = version.totalActivatedUsers || 0;

            states.months.forEach(function (month, index) {

                e[month.name + 'totalLogins'] = Math.round(_.get(_.find(version.monthlyActiveUsers, { userType: patientType, month: month.month, year: month.year }), 'totalLogins', 0));
                e[month.name + 'mau'] = Math.round(_.get(_.find(version.monthlyActiveUsers, { userType: patientType, month: month.month, year: month.year }), 'monthlyActiveUsers', 0));
                e[month.name + 'dau'] = Math.round(_.get(_.find(version.monthlyActiveUsers, { userType: patientType, month: month.month, year: month.year }), 'dailyActiveUsers', 0));
                e[month.name + 'daumau'] = 0;

                if (e[month.name + 'totalLogins'] > 0) {
                    summaryRow[month.name + 'totalLogins'] += e[month.name + 'totalLogins'];
                }
                if (e[month.name + 'dau'] > 0) {
                    summaryRow[month.name + 'dau'] += e[month.name + 'dau'];
                }
                if (e[month.name + 'mau'] > 0) {
                    e[month.name + 'daumau'] = Math.round(100 * e[month.name + 'dau'] / e[month.name + 'mau']) + '%';

                    summaryRow[month.name + 'mau'] += e[month.name + 'mau'];
                    summaryRow[month.name + 'daumau'] = Math.round(100 * summaryRow[month.name + 'dau'] / summaryRow[month.name + 'mau']) + '%';
                }
            });
        }
    }

    dateRangeChange(states: IDashboardStates, startDate: Date, endDate: Date) {

        states.datePicker.date.startDate = startDate;
        states.datePicker.date.endDate = endDate;
        states.monthColumnsFiltered = this.filterMonths(states, states.monthColumns);
        states.monthsFiltered = this.filterMonths(states, states.months);
    }

    filterMonths(states: IDashboardStates, months: any[]) {
        return _.filter(months, function (month) {
            const isAfterStart = month.date.isSameOrAfter(moment(states.datePicker.date.startDate).format('MM/DD/YYYY'));
            const isBeforeEnd = month.date.isSameOrBefore(moment(states.datePicker.date.endDate).format('MM/DD/YYYY'));
            let metricToggle = true;
            if (month.metric) {
                metricToggle = (month.metric === 'dau' && states.metricToggles.dau) ||
                    (month.metric === 'mau' && states.metricToggles.mau) ||
                    (month.metric === 'daumau' && states.metricToggles.daumau) ||
                    (month.metric === 'total' && states.metricToggles.total);
            }
            return isAfterStart && isBeforeEnd && metricToggle;
        });
    }

    metricTogglesChanged(states: IDashboardStates) {
        states.monthLabelColSpan = 0;
        _.forEach(_.keys(states.metricToggles), function (key) {
            if (states.metricToggles[key]) {
                states.monthLabelColSpan++;
            }
        });
        states.monthColumnsFiltered = this.filterMonths(states, states.monthColumns);
    }

    episodeEnvironments(states: IDashboardStates) {
        // for now just limit the request to fixed list
        const filteredArray = _.filter(states.environments, function (env) {
            return (env.isEpisodesByClient);
        });

        filteredArray.forEach((env) => {
            env.url = 'https://' + env.dnsCname + '.clarifyhealth.' + env.domainSuffix;
        });

        states.filteredArray = filteredArray;
    }

    async environmentEpisodeDropdownChanged(states: IDashboardStates) {
        if (states.selectedEpisodeEnvironment !== '') {
            // console.log('getting data for ' + states.selectedEpisodeEnvironment.url);
            const version = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getAppVersion',
                parameters: {
                    id: states.selectedEpisodeEnvironment.id
                }
            });
            if (version.monthlyEventCounts) {

                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                states.monthlyEventCounts = _.orderBy(version.monthlyEventCounts, ['year', 'month']);

                for (let i = 0; i < states.monthlyEventCounts.length; i++) {
                    states.monthlyEventCounts[i].monthName = (months[states.monthlyEventCounts[i].month - 1] || '?');
                }
            }
        }
    }

    export(states: IDashboardStates) {
        let exportString = '';
        const columns: any[] = [];

        if (states.environments && states.environments.length > 0) {
            exportString += ('Environment\t');
            exportString += ('Activation Date\t');
            exportString += ('Client User Errors\t');
            exportString += ('All User Errors\t');
            exportString += ('Client User Count\t');
            _.forEach(states.monthColumnsFiltered, (monthColumn) => {
                columns.push(monthColumn.type);
                exportString += (monthColumn.date.format('MMM YYYY') + '\t');
            });

            exportString += '\n';

            _.forEach(states.environments, (environment) => {
                exportString += (environment.dnsCname + '\t');
                exportString += (environment.activationDate + '\t');
                exportString += (environment.clientErrorTotal + '\t');
                exportString += (environment.allErrorTotal + '\t');
                exportString += (environment.totalActivatedUsers + '\t');
                _.forEach(columns, function (column) {
                    exportString += (environment[column] + '\t');
                });
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, 'argos_engagement.csv');
        }
    }
    exportEpisodeInfo(states: IDashboardStates) {
        const fileName = states.selectedEpisodeEnvironment.dnsCname;
        const data = states.monthlyEventCounts;
        let exportString = '';
        const columns: any[] = [];

        if (data && data.length > 0) {
            for (const prop in data[0]) {
                if (prop[0] !== '$' && !_.isFunction(data[0][prop]) && !_.isObject(data[0][prop])) {
                    columns.push(prop);
                    exportString += (prop + ',');
                }
            }
            exportString += '\n';

            data.forEach((environment: any) => {
                columns.forEach(function (column) {
                    exportString += (environment[column] + ',');
                });
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, fileName + '.csv');
        }
    }
}
