import { Injectable } from '@angular/core';
import { IEpisodeGroupingImportStates, IEpisodeGroupingImportService } from './episodeGroupingImport.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { CONST } from '../../../constants/globals';
import swal from 'sweetalert2';
declare const $: any;

@Injectable()
export class EpisodeGroupingImportService implements IEpisodeGroupingImportService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService) {
        //
    }
    async initDelegate(states: IEpisodeGroupingImportStates): Promise<IEpisodeGroupingImportStates> {
        const newStates = await this.activate(states);
        return newStates;
    }

    async activate(initStates: IEpisodeGroupingImportStates): Promise<IEpisodeGroupingImportStates> {
        const states = _.cloneDeep(initStates);
        states.eventAction = true;
        const productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle',
            filter: { fields: ['shortName'] }
        });
        const episodeGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { where: { assignable: true } }
        });
        const existingEpisodeGroupings = await this.dataAccess.genericFind({
            model: 'EpisodeGrouping',
            filter: { order: 'groupByAttribute' }
        });
        const episodeGroupingEntities = await this.dataAccess.genericFind({
            model: 'EpisodeGroupingEntity'
        });
        const observationCategoryHierarchies = await this.dataAccess.genericFind({
            model: 'ObservationCategoryHierarchy'
        });

        states.productBundles = productBundles.map(function (p: any) { return p.shortName; });
        states.existingEpisodeGroupings = _.map(existingEpisodeGroupings, function (episodeGrouping: any) {
            episodeGrouping.careGroupings = _.join(_.orderBy(_.map(episodeGrouping.validCareGroupings, function (validCareGrouping) {
                return _.get(_.find(episodeGroupings, { name: validCareGrouping }), 'title');
            })), ', ');
            episodeGrouping.productBundles = _.join(_.map(episodeGrouping.productBundles, function (productBundle) {
                return _.get(_.find(states.productBundles, { shortName: productBundle }), 'title');
            }), ', ');
            return episodeGrouping;
        });
        states.careGroupings = _.uniq(episodeGroupings.map(function (c: any) { return c.name; }));
        states.episodeGroupingEntities = _.map(episodeGroupingEntities, 'entity');
        states.groupCategory = _.uniq(states.existingEpisodeGroupings.map(function (i) { return i.groupCategory; }));
        states.mapBoundary = states.existingEpisodeGroupings.map(function (i) { return i.mapBoundary; });
        states.orderBy = states.existingEpisodeGroupings.map(function (i) { return i.orderBy; });

        states.observationCategoryHierarchies = _.orderBy(_.map(observationCategoryHierarchies, function (h: any) {
            h.name = h.category + CONST.HIERARCHY_DELIMITER + h.category2 + CONST.HIERARCHY_DELIMITER + h.category3 + CONST.HIERARCHY_DELIMITER + h.category4;
            return h;
        }), 'name');

        states.eventAction = false;
        return states;
    }

    getFieldErrorMessage(row: any, states: IEpisodeGroupingImportStates) {
        let result = '';
        if (row.propertyErrors) {
            result = row.propertyErrors.map(function (err: any) { return err.message; }).join('\n');
        }
        return result;
    }

    async importValidEpisodeGroupsHandler(states: IEpisodeGroupingImportStates, cb: Function) {
        states.eventAction = true;
        const savePromises: any[] = [];
        for (let i = 0, len = states.episodeGroupings.length; i < len; i++) {
            const eg = states.episodeGroupings[i];
            if (eg.isImportReady && !eg.imported) {
                eg.imported = true;
                savePromises.push(this.saveEpisodeGrouping(eg, states));
            }
        }
        await Promise.all(savePromises);
        const recordsSavedCount = savePromises.length;
        states.eventAction = false;

        swal({
            title: 'Import requested',
            text: recordsSavedCount + ' records submitted',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
        }).then(() => {
            cb();
        });

    }

    copyToClipboard(value: any, states: IEpisodeGroupingImportStates) {
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(value).select();
        document.execCommand('copy');
        $temp.remove();
    }

    copyErrorToClipboardHandler(eg: any, states: IEpisodeGroupingImportStates) {
        const errorMessage = this.getFieldErrorMessage(eg, states);
        this.copyToClipboard(errorMessage, states);
    }

    copyCSVHeadersHandler(states: IEpisodeGroupingImportStates) {
        let result = '';

        const eg = states.existingEpisodeGroupings[0];

        Object.keys(eg).forEach(function (key, index) {
            result += key + ',';
        });

        // remove , at the end
        this.copyToClipboard(result.replace(/,\s*$/, ''), states);
    }

    /** ***********************
    validate functions
    *************************/

    validateEpisodeGroupings(states: IEpisodeGroupingImportStates) {
        const episodeGroupings: any[] = [];
        _.forEach(states.episodeGroupings, eg => {
            eg.isImportReady = true;    // used to determine if the row is valid
            eg.imported = false;    // used to determine if row was imported successfully
            eg.propertyErrors = []; // store all errors in property
            episodeGroupings.push(this.validateEpisodeGroup(eg, states));
        });
        return episodeGroupings;
    }

    validateEpisodeGroup(episodeGrouping: any, states: IEpisodeGroupingImportStates): any {
        const eg = _.cloneDeep(episodeGrouping);
        this.validateAllRequiredFields(eg, states);
        this.validateValueInList(eg, ['Visible', 'Internal Only', 'Disable']);
        this.isBoolValue(eg,
            ['cacheable', 'filterOnly', 'usesCrossJoin', 'benchmarkableAcrossCareGroupings', 'patientAttribute', 'usesArrayFiltering', 'sequenceType'],
            states);
        this.validatePropertyDups(eg, ['groupByAttribute'], states);
        this.validatePropertyDups(eg, ['filterAttribute'], states);
        this.validatePropertyDups(eg, ['groupIdSql', 'groupNameSql'], states);
        this.validateGroupCategoryValue(eg, states);
        this.validateGroupJoinEntity(eg, states);
        this.validateMapBoundary(eg, states);
        this.validateOrderBy(eg, states);
        this.validateProductBundles(eg, states);
        this.validateCareGroupings(eg, states);
        this.validateDisplayGroups(eg, states);
        return eg;
    }

    validateValueInList(episodeGroup: any, validValues: any) {
        const propertyValue = episodeGroup['defaultVisibility'];
        if (propertyValue && propertyValue.toString().trim().length > 0 && validValues.indexOf(propertyValue) > -1) {
            return;
        } else {
            episodeGroup.isImportReady = false;
            episodeGroup.propertyErrors.push({
                field: 'defaultVisibility',
                message: 'defaultVisibility is empty or an invalid value. valid values are ' + validValues.join(', ')
            });
        }
    }
    // does not check if value is null
    isBoolValue(episodeGroup: any, propertyNames: any, states: IEpisodeGroupingImportStates) {

        // all values have to match to be consider a dup
        for (let i = 0, len = propertyNames.length; i < len; i++) {
            const propName = propertyNames[i];
            if (episodeGroup[propName]) {

                let isTrue;
                switch (episodeGroup[propName].toLowerCase().trim()) {
                    case 'true': case 'yes': case '1':
                        isTrue = true;
                        break;
                    case 'false': case 'no': case '0': case null:
                        isTrue = false;
                        break;
                    default:
                        isTrue = null;
                }

                if (isTrue === null) {
                    episodeGroup.isImportReady = false;
                    episodeGroup.propertyErrors.push({
                        field: propName,
                        message: propName + ' must be a valid boolean value. try true or false'
                    });
                } else {
                    delete episodeGroup[propName];
                    episodeGroup[propName] = isTrue;
                }
            }
        }

    }

    validatePropertyDups(episodeGroup: any, propertyNames: any, states: IEpisodeGroupingImportStates) {
        // check against its own list of new items to import
        const toImportCountDup = _.filter(states.episodeGroupings, function (toImportEg) {
            let matchingValue = true;
            // all values have to match to be consider a dup
            for (let i = 0, len = propertyNames.length; i < len; i++) {
                const propName = propertyNames[i];
                if (propName === 'groupByAttribute' || propName === 'filterAttribute') {
                    if (episodeGroup[propName] !== toImportEg.groupByAttribute &&
                        episodeGroup[propName] !== toImportEg.filterAttribute) {
                        matchingValue = false;
                        break;
                    }
                } else if (episodeGroup[propName] !== toImportEg[propName]) {
                    matchingValue = false;
                    break;
                }
            }
            if (matchingValue) {
                // console.log(episodeGroup[propName] + ' propName vs ' + toImportEg[propName])
                return toImportEg;
            }
        });

        // has to be more than 1 since we need to include self compare
        if (toImportCountDup.length > 1) {
            episodeGroup.isImportReady = false;
            episodeGroup.propertyErrors.push({
                field: propertyNames[0],
                message: propertyNames.join(', ') + ' already exists in this import'
            });
        }

        // only do the larger check if the episode grouping is still valid
        if (toImportCountDup.length <= 1) {
            // check against the existing data
            const existCountDup = _.filter(states.existingEpisodeGroupings, function (existingEg) {
                let existingValue = true;
                // all values have to match to be consider a dup
                for (let i = 0, len = propertyNames.length; i < len; i++) {
                    const propName = propertyNames[i];
                    if (propName === 'groupByAttribute' || propName === 'filterAttribute') {
                        if (episodeGroup[propName] !== existingEg.groupByAttribute &&
                            episodeGroup[propName] !== existingEg.filterAttribute) {
                            existingValue = false;
                            break;
                        }
                    } else if (episodeGroup[propName] !== existingEg[propName]) {
                        existingValue = false;
                        break;
                    }
                }
                if (existingValue) {
                    return existingEg;
                }
            });
            if (existCountDup.length > 0) {
                episodeGroup.isImportReady = false;
                episodeGroup.propertyErrors.push({
                    field: propertyNames[0],
                    message: propertyNames.join(', ') + ' already exists in the database'
                });
            }
        }
    }

    validateAllRequiredFields(episodeGroup: any, states: IEpisodeGroupingImportStates) {
        const requiredProperties = ['groupByAttribute', 'groupByName', 'filterAttribute', 'filterName', 'pluralName', 'groupIdSql', 'groupNameSql', 'defaultVisibility', 'displayGroup', 'productBundles'];

        for (const key in episodeGroup) {
            if (requiredProperties.indexOf(key) > -1 && (episodeGroup?.[key] === null || episodeGroup?.[key]?.toString()?.trim()?.length === 0)) {
                episodeGroup.isImportReady = false;
                episodeGroup.propertyErrors.push({
                    field: key,
                    message: key + ' is a required field'
                });
            }
        }

        // check if the require property does not exists
        for (let i = 0, len = requiredProperties.length; i < len; i++) {
            const propName = requiredProperties[i];

            if (!episodeGroup[propName]) {
                episodeGroup.isImportReady = false;
                episodeGroup.propertyErrors.push({
                    field: propName,
                    message: propName + ' is not found and a required field'
                });
            }
        }
    }

    validateGroupCategoryValue(episodeGroup: any, states: IEpisodeGroupingImportStates) {
        if (episodeGroup.groupCategory && episodeGroup.groupCategory.toString().trim().length > 0 && states.groupCategory.indexOf(episodeGroup.groupCategory) === -1) {
            episodeGroup.isImportReady = false;
            episodeGroup.propertyErrors.push({
                field: 'groupCategory',
                message: 'invalid groupCategory value. valid values are ' + states.groupCategory.join(', ')
            });
        }
    }

    validateGroupJoinEntity(episodeGroup: any, states: IEpisodeGroupingImportStates) {
        if (episodeGroup.joinEntity && episodeGroup.joinEntity.toString().trim().length > 0 && states.episodeGroupingEntities.indexOf(episodeGroup.joinEntity) === -1) {
            episodeGroup.isImportReady = false;
            episodeGroup.propertyErrors.push({
                field: 'joinEntity',
                message: 'invalid joinEntity value. valid values are ' + states.episodeGroupingEntities.join(', ')
            });
        }
    }

    validateMapBoundary(episodeGroup: any, states: IEpisodeGroupingImportStates) {
        if (episodeGroup.mapBoundary && episodeGroup.mapBoundary.toString().trim().length > 0 && states.mapBoundary.indexOf(episodeGroup.mapBoundary) === -1) {
            episodeGroup.isImportReady = false;
            episodeGroup.propertyErrors.push({
                field: 'mapBoundary',
                message: 'invalid mapBoundary value. valid values are ' + states.mapBoundary.join(', ')
            });
        }
    }

    validateOrderBy(episodeGroup: any, states: IEpisodeGroupingImportStates) {
        if (episodeGroup.orderBy && episodeGroup.orderBy.toString().trim().length > 0 && states.orderBy.indexOf(episodeGroup.orderBy) > -1) {
            return;
        } else {
            episodeGroup.isImportReady = false;
            episodeGroup.propertyErrors.push({
                field: 'orderBy',
                message: 'orderBy is empty or an invalid value. valid values are ' + states.orderBy.join(', ')
            });
        }
    }

    validateProductBundles(episodeGroup: any, states: IEpisodeGroupingImportStates) {

        if (episodeGroup.productBundles && episodeGroup.productBundles.toString().trim().length > 0) {

            const prodBundles = JSON.parse(episodeGroup.productBundles);

            if (prodBundles) {
                prodBundles.forEach(function (pb: any) {
                    if (states.productBundles.indexOf(pb) === -1) {
                        episodeGroup.isImportReady = false;
                        episodeGroup.propertyErrors.push({
                            field: 'productBundles',
                            message: pb + ' does not exists in product bundle. valid values are ' + states.productBundles.join(', ')
                        });
                    }
                });
            }

        }
    }

    validateCareGroupings(episodeGroup: any, states: IEpisodeGroupingImportStates) {

        if (episodeGroup.validCareGroupings && episodeGroup.validCareGroupings.toString().trim().length > 0) {

            const caregrps = JSON.parse(episodeGroup.validCareGroupings);

            if (caregrps) {
                caregrps.forEach(function (cg: any) {
                    if (states.careGroupings.indexOf(cg) === -1) {
                        episodeGroup.isImportReady = false;
                        episodeGroup.propertyErrors.push({
                            field: 'validCareGroupings',
                            message: cg + ' does not exists in valid care groupings. valid values are ' + states.careGroupings.join(', ')
                        });
                    }
                });
            }
        }
    }

    validateDisplayGroups(episodeGroup: any, states: IEpisodeGroupingImportStates) {

        let isValid = false;

        if (!episodeGroup.displayGroup2) {
            episodeGroup.displayGroup2 = '';
        }

        if (!episodeGroup.displayGroup3) {
            episodeGroup.displayGroup3 = '';
        }

        if (episodeGroup.displayGroup4) {
            episodeGroup.displayGroup4 = '';
        }

        for (let i = 0, len = states.observationCategoryHierarchies.length; i < len; i++) {
            const h = states.observationCategoryHierarchies[i];
            if (h.category === episodeGroup.displayGroup && h.category2 === episodeGroup.displayGroup2 && h.category3 === episodeGroup.displayGroup3 && h.category4 === episodeGroup.displayGroup4) {
                isValid = true;
                break;
            }
        }

        if (!isValid) {
            episodeGroup.isImportReady = false;
            episodeGroup.propertyErrors.push({
                field: 'displayGroup',
                message: 'displayGroup combination is invalid. valid combinations are ' + states.observationCategoryHierarchies.map(function (i) { return i.name; }).join(', ')
            });
        }
    }

    /** ***********************
    save functions
    *************************/
    async saveEpisodeGrouping(episodeGrouping: any, states: IEpisodeGroupingImportStates) {
        await this.dataAccess.genericCreate({
            model: 'EpisodeGrouping',
            data: episodeGrouping
        });
        await this.getEpisodeGrouping(episodeGrouping, states);
        await this.saveEpisodeGroupingHistory(episodeGrouping, states);
        states.existingEpisodeGroupings.push(episodeGrouping);
    }

    async getEpisodeGrouping(episodeGrouping: any, states: IEpisodeGroupingImportStates) {
        return this.dataAccess.genericFind({
            model: 'EpisodeGrouping',
            filter: {
                groupByAttribute: episodeGrouping?.groupByAttribute,
                include: ['episodeGroupingHistories']
            }
        });
    }

    async saveEpisodeGroupingHistory(episodeGrouping: any, states: IEpisodeGroupingImportStates) {

        let version = 0;
        const username = this.argosStore.getItem('username');
        if (episodeGrouping && episodeGrouping.episodeGroupingHistories && episodeGrouping.episodeGroupingHistories.length > 0) {
            version = _.max(_.map(episodeGrouping.episodeGroupingHistories, 'version')) || 0;
        }
        version++;

        delete episodeGrouping.episodeGroupingHistories;
        return this.dataAccess.genericUpsert({
            model: 'EpisodeGroupingHistory',
            data: {
                episodeGroupingId: episodeGrouping.groupByAttribute,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: episodeGrouping
            }
        });
    }
}
