import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewReferenceDataSourceHistoryModalProps, IViewReferenceDataSourceHistoryModalStates } from './viewReferenceDataSourceHistoryModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewReferenceDataSourceHistoryModalService } from './viewReferenceDataSourceHistoryModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-viewReferenceDataSourceHistoryModal',
    templateUrl: './viewReferenceDataSourceHistoryModal.component.html',
    providers: [ViewReferenceDataSourceHistoryModalService]
})

export class ViewReferenceDataSourceHistoryModalComponent
    extends ReactComponentBase<IViewReferenceDataSourceHistoryModalProps, IViewReferenceDataSourceHistoryModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewReferenceDataSourceHistoryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewReferenceDataSourceHistoryModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close({});
    }

    viewDiffs(version: number) {
        this.svc.viewDiffsHandler(version, this.states);
    }
}
