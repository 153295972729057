import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { IClarifyInsightsFilterList } from './clarifyInsightsFilterList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ClarifyInsightsFilterListService } from './clarifyInsightsFilterList.component.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import { ClarifyInsightsProvisionCustomerModalComponent } from '../clarifyInsightsProvisionCustomerModal/clarifyInsightsProvisionCustomerModal.component';
import { ClarifyInsightsFinalizeCustomerModalComponent } from '../clarifyInsightsFinalizeCustomerModal/clarifyInsightsFinalizeCustomerModal.component';
import * as _ from 'lodash';

@Component({
    selector: 'argos-clarifyInsightsFilterList',
    templateUrl: './clarifyInsightsFilterList.component.html',
    providers: [ClarifyInsightsFilterListService]
})

export class ClarifyInsightsFilterListComponent extends ReactComponentBase<{}, IClarifyInsightsFilterList, {}> implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: ClarifyInsightsFilterListService, private cdr: ChangeDetectorRef,
        private matDialog: MatDialog, private matSvc: MaterialUtil,
        private urlService: UrlService) {
        super({
            customers: [],
            filterObj: {},
            matTableColumns: ['history', 'customerName', 'extractType', 'dataSource', 'filterName', 'extractStartDate', 'extractEndDate', 'sbdohEnabled', 'createdBy', 'createdDate'],
            table: new MatTableDataSource([])
        } as unknown as IClarifyInsightsFilterList);
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.table.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    provisionCustomerForm() {
        this.matDialog.open(ClarifyInsightsProvisionCustomerModalComponent);
    }

    finalizeAccount(row: any) {
        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    customerId: row.customerId,
                    customerCode: row.customerCode,
                    s3DestinationBucket: row.s3DestinationBucket
                }
            }
        };
        const modalInstance = this.matDialog.open(ClarifyInsightsFinalizeCustomerModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async ($event: any) => {
            await this.svc.init(this.states);
            this.cdr.detectChanges();
        });
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.table.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.table.filter);
    }

    ngAfterViewInit() {
        this.states.table.sort = this.matTbSort;
    }

    backToLandingPage() {
        this.svc.backToLandingPageDelegate();
    }

    backToDeliveryHub() {
        this.svc.backToDeliveryHubDelegate();
    }

    displayRxOnly(row: any) {
        if (row.extractType === 'Enriched Claims' && row.rxOnly === true) {
            return 'Enriched Claims (Rx Only)';
        }
        else {
            return row.extractType;
        }
    }

}
