import { PipeTransform, Pipe } from '@angular/core';
import { ArgosStoreService } from '../services/argosStore.service';

@Pipe({
    name: 'checkMenu'
})
export class MenuPipe implements PipeTransform {
    constructor(private argosStore: ArgosStoreService) {
        //
    }

    transform(menu: any, args?: string): any {
        const roles = this.argosStore.getItem('roles') || {};

        if (roles.inactive === true) {
            return true;
        } else {
            return !(roles[menu] || roles.admin);
        }
    }
}
