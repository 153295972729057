import { Injectable } from '@angular/core';
import { NgDataAccess } from 'client/app/services/dataAccess.service';
import { ArgosStoreService } from '../../services/argosStore.service';



@Injectable()
export class ClarifyBotServices {

    constructor(private dataAccess: NgDataAccess,private argosStore: ArgosStoreService) {
    }

    async initDelegate(states: any): Promise<object> {
        await this.init(states);
        return states;
    }

    async init(states: any) {
        return states;
    }

    async collections(states: any) {
        const response = await this.dataAccess.genericMethod({
            model: 'ClarifyBot',
            method: 'getCollectionsDataSourceMap'
        });
        states.collections = response.collections;
        return Object.keys(response.collections);
    }

    async getDataSourceForCollection(states: any, collection: string) {
        return states.collections[collection];
    }


    async queryBot(states: any, collection: string, dataSource: string, query: string) {
        states.saveInProgress = true;
        const submitTimestamp = new Date();
        const botResponse = await this.dataAccess.genericMethod({
            model: 'ClarifyBot',
            method: 'botResponse',
            parameters: {
                collection,
                query,
                metadata_filters: dataSource !== null ? [{
                    key: 'data_source',
                    operator: '==',
                    value: dataSource
                }] : null
            }
        });
        // update history table on successful response.
        const clarifyBotHistory = await this.dataAccess.genericUpsert({
            model: 'ClarifyBotHistory',
            data: {
                request: query,
                response: botResponse.answer,
                dataSource,
                submitTimestamp,
                responseTimestamp: new Date(),
                sources: JSON.stringify(botResponse.sources),
                createdByUser: this.argosStore.getItem('username')
            }
        });
        botResponse.chat_id = clarifyBotHistory.id;
        states.saveInProgress = false;
        return botResponse;
    }

    async getHistory(states: any) {
        const history = await this.dataAccess.genericFind({
            model: 'ClarifyBotHistory',
            filter: {
                order: 'submit_timestamp DESC',
                limit:100
            }
        });
        return history;
    }

    async updateFeedback(states: any, chatId: string, vote: string, feedbackType = '', feedbackText = '') {
        try {
            const clarifyBotHistory = await this.dataAccess.genericUpsert({
                model: 'ClarifyBotHistory',
                data: {
                    id: chatId,
                    vote,
                    feedbackType,
                    feedbackText
                }
            });
            return { status: 'success' };
        } catch (error) {
            console.error('Error in updateFeedback:', error);
            return { status: 'error', message: 'An error occurred while updating feedback.' };
        }
    }
}
