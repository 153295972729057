import * as _ from 'lodash';
import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EcsAppShare } from './ecsAppShareHandler.service';

@Injectable()
export class EcsAppService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private ecsAppShareHandler: EcsAppShare) {
        //
    }

    initServiceEventsTable(dataSource: any[], states: IEcsAppEditStates) {

        // setup instance health table
        states.serviceEventTable.data = dataSource.reverse();
    }

    async getServiceEvents(serviceNames: any, accountName: any, states: IEcsAppEditStates) {
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsServiceInfo', parameters: {
                clusterName: states.clusterName,
                serviceNames,
                awsAccountName: accountName
            }
        });
        if (result.response && result.response.services) {

            result.response.services.forEach(function (serv: any) {
                const events = _.map(serv.events, s => _.assign(s, { serviceName: serv.serviceName }));
                states.serviceEvents = states.serviceEvents.concat(events);
            });

            // sort data by timestamp
            states.serviceEvents.sort(function (a, b) {
                const keyA = new Date(a.createdAt);
                const keyB = new Date(b.createdAt);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

            states.serviceEvents.forEach(function (event) {
                event.createdAt = moment(event.createdAt).format('MM/DD/YYYY h:mm a');
            });

            const fromDate = new Date(states.selectedMetricDates.fromDate);  // -1 because months are from 0 to 11
            const toDate = new Date(states.selectedMetricDates.toDate);

            const filterEvents = _.filter(states.serviceEvents, function (e) {
                const createdAtDate = new Date(e.createdAt);
                if (createdAtDate > fromDate && createdAtDate < toDate) {
                    return e;
                }
            });

            this.initServiceEventsTable(filterEvents, states);
        }
    }

    async selectedServiceUpdate(states: IEcsAppEditStates) {
        if (states.selectedService && states.selectedService.serviceName && states.selectedService.desiredCount !== null) {
            const response = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'updateEcsService', parameters: {
                    clusterName: states.clusterName,
                    serviceName: states.selectedService.serviceName,
                    desiredCount: states.selectedService.desiredCount,
                    requestedBy: this.argosStore.getItem('username'),
                    awsAccountName: states.cluster.accountName
                }
            });
            swal({
                title: 'Instances updated to ' + states.selectedService.desiredCount,
                text: response.response + '. This can take about 10 minutes to complete',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    await this.ecsAppShareHandler.getEnvironmentActivityLog(states);
                }
            });
        }
    }

    async loadServiceInfo(serviceName: any, states: IEcsAppEditStates) {
        states.selectedService.isLoading = true;

        const [serviceInfo, ebInfo] = await Promise.all([
            this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsServiceInfo', parameters: {
                    clusterName: states.clusterName,
                    serviceNames: [serviceName],
                    awsAccountName: states.cluster.accountName
                }
            }),
            this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEventBridgeSchedules', parameters: {
                    clusterName: states.clusterName, 
                    awsAccountName: states.cluster.accountName
                }
            })
        ]);
 
        //init service info
        states.selectedService = serviceInfo.response.services[0];
        states.selectedService.scalingPolicies = serviceInfo.scalingPolicies;
        states.selectedService.scalableTargets = serviceInfo.scalableTargets;
        states.selectedService.scheduleActions = serviceInfo.scheduleActions;
        states.selectedService.hasEventBridgeSchedule = false;
        states.selectedService.isLoading = false;

        //init event bridge schedules
        if (ebInfo.isSuccessful && ebInfo.schedules.length > 0) {
            states.selectedService.eventBridgeSchedules = ebInfo.schedules;
            if (_.find(ebInfo.schedules, { Name: states.selectedService.serviceName + '-bounce' })) {
                states.selectedService.hasEventBridgeSchedule = true;    
            }
        }
    }

    async addRemoveServiceEventBridgeSchedule(states: IEcsAppEditStates) {
        let result = false;

        if (states.selectedService.hasEventBridgeSchedule === true) {
            await swal({
                title: 'Remove Event Bridge Schedule',
                text: 'Are you sure you want to remove the Event Bridge Schedule for ' + states.selectedService.serviceName,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, remove it!',
                cancelButtonText: 'Nevermind'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    let deleteResult = await this.dataAccess.genericMethod({
                        model: 'Environment', method: 'deleteEventBridgeSchedule', parameters: {
                            eventBridgeName: states.selectedService.serviceName + '-bounce', 
                            awsAccountName: states.cluster.accountName
                        }
                    });
                     
                    result = deleteResult.isSuccessful;
                }
            });
        } else {
            await swal({
                title: 'Add Event Bridge Schedule',
                text: 'Are you sure you want to add the Event Bridge Schedule for ' + states.selectedService.serviceName,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, add it!',
                cancelButtonText: 'Nevermind'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    let addResult = await this.dataAccess.genericMethod({
                        model: 'Environment', method: 'createEventBridgeSchedule', parameters: {
                            ecsServiceName: states.selectedService.serviceName,
                            ecsClusterArn: states.selectedService.clusterArn, 
                            ecsServiceArn: states.selectedService.serviceArn, 
                            awsAccountName: states.cluster.accountName
                        }
                    });

                    result = addResult.isSuccessful;
                }
            });
        }

        return result;
    }

    isServiceSetupValid(states: IEcsAppEditStates) {
        let activeScalingPolicy = states.selectedService.scalingPolicies.filter(function (sp: any) {
            if (sp.TargetTrackingScalingPolicyConfiguration.DisableScaleIn === false) {
                return sp;
            }
        });
        
        let scalingTargets = states.selectedService.scalableTargets.filter(function (st: any) {
            if (st.MinCapacity === 0 && st.MaxCapacity === 0) {
                return st;
            }
        });

        if (activeScalingPolicy.length > 0 && scalingTargets.length > 0) {
            swal({
                title: 'Configuration is invalid',
                text: 'Scaling Target max and min is 0 with at least 1 active Service Policy Scaling configuration. This means ECS will rest desired instances to 0. We recommend disabling all Service Policy Scaling configurations',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    await this.ecsAppShareHandler.getEnvironmentActivityLog(states);
                }
            });
        }
    }

    async awsRdsSecretEnabledChanged(enabled: boolean, states: IEcsAppEditStates) {
        const args = {
            clusterName: states.clusterName,
            dbName: states.cluster.environment.dbName,
            awsAccountName: states.cluster.accountName,
            isEnabled: enabled,
            requestedBy: this.argosStore.getItem('username')
        };

        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'updateAuth0SecretRotationStatus', parameters: args
        });

        return result.isSuccessful;
    }
}
