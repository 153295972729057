import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IEnvironmentsListStates } from './environmentsList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EnvironmentsListService } from './environmentsList.component.service';
import * as _ from 'lodash';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'argos-environmentsList',
    templateUrl: './environmentsList.component.html',
    styleUrls: ['./environmentsList.component.less'],
    providers: [EnvironmentsListService]
})

export class EnvironmentsListComponent extends ReactComponentBase<{}, IEnvironmentsListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;

    constructor(private svc: EnvironmentsListService, private cdr: ChangeDetectorRef, 
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            environmentsTable: new MatTableDataSource([]),
            environments: [],
            filterObj: {
                multiColumnFilterValue: ['argosClientCareGroupingsFilter'],
                multiColumnFilterORLogicValue: ['argosClientProductConfigurationFilter']
            },
            argosClientCareGroupings: [],
            argosCCGColumnFilterList: [],
            argosCCGColumnPlaceholder: 'argos Client CareGroupings Filter',
            pageSize: 50,
            defaultDisplayMatTableColumns: ['clientName', 'environmentTypeName', 'url', 'argosClientProductConfigurationFilter', 'hostingProviderName', 'serviceName', 'claimFileType', 'argosClientCareGroupingsFilter', 'lastDeployed', 'lastLogin', 'lastLoginUser', 'uiVersion', 'dbVersion', 'dbUpdated', 'assignedOwnerEmailString'],
            matTableColumns: ['clientName', 'environmentTypeName', 'url', 'argosClientProductConfigurationFilter', 'hostingProviderName', 'serviceName', 'claimFileType', 'argosClientCareGroupingsFilter', 'lastDeployed', 'lastLogin', 'lastLoginUser', 'uiVersion', 'dbVersion', 'dbUpdated', 'assignedOwnerEmailString'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'environmentsList',
            argosClientProductConfiguration: [],
        } as unknown as IEnvironmentsListStates);
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }
    
    multiFilterChange(columnName: string, event: MatSelectChange) {
        //if uninitialized or clear filter by setting to empty array
        this.states.filterObj = this.matSvc.multiFilterChange(this.states.filterObj, columnName, event);  
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    getColumnFilterList(columnName: string, data: any[]) {
        let result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        return this.matSvc.getMatSelectPlaceholderName(columnName, filterValue);
    }

    multiFilterCCGsChange(columnName: string, event: MatSelectChange) {
        //if uninitialized or clear filter by setting to empty array
        this.states.filterObj = this.matSvc.multiFilterChange(this.states.filterObj, columnName, event);  
        this.states.argosCCGColumnPlaceholder = this.matSvc.getMatSelectPlaceholderName('argosClientCareGroupingsFilter', event.value);
        this.states.table.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.table.filter);
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    pageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        this.states.tableShowRows = this.states.environments.slice(page, page + event.pageSize);
        this.states.table = new MatTableDataSource(this.states.tableShowRows);
    }

    ngAfterViewInit(): void {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    //pagination functions
    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }
}
