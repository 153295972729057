import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { TransitionService, Transition } from '@uirouter/core';
import { Title } from '@angular/platform-browser';

import * as _ from 'lodash';

@Component({
    selector: 'argos-root',
    template: `<ui-view></ui-view>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    title = 'argos-V2';

    constructor(private titleService: Title, private transitionService: TransitionService) {
        //
    }

    ngOnInit() {

        this.transitionService.onSuccess({}, (transition: Transition) => {

            // fallback to the states pageTitle for other screens/routes
            const pageTitle = _.get(transition, 'router.globals.current.data.pageTitle');
            if (pageTitle) {
                this.titleService.setTitle(`Argos | ${pageTitle}`);
                return;
            }

            this.titleService.setTitle(`Argos`);
        });
    }
}
