// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-role-edit .tab-height {
  height: calc(100vh - 310px);
  overflow-y: auto;
}
.user-role-edit .menu-list {
  list-style-type: none;
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/users/userRoleEdit/userRoleEdit.component.less"],"names":[],"mappings":"AAAA;EAEQ,2BAAA;EACA,gBAAA;AAAR;AAHA;EAMQ,qBAAA;EACA,iBAAA;AAAR","sourcesContent":[".user-role-edit {\n    .tab-height {\n        height: calc(100vh - 310px); \n        overflow-y: auto;\n    }\n    .menu-list {\n        list-style-type: none;\n        margin-left: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
