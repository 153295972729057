// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row.mb-2 {
    margin-bottom: 10px;
}

.pointer {
    cursor: pointer;
    float: right;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/referral/marketShareMetaEdit/marketShareMetaEdit.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB","sourcesContent":[".row.mb-2 {\n    margin-bottom: 10px;\n}\n\n.pointer {\n    cursor: pointer;\n    float: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
