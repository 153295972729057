import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IEpisodeGroupingEditStates, IEpisodeGroupingEditService } from './episodeGroupingEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { StateService, UIRouter } from '@uirouter/core';
import * as _ from 'lodash';
import { CONST } from '../../../constants/globals';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewEpisodeGroupingHistoryModalComponent } from '../viewEpisodeGroupingHistoryModal/viewEpisodeGroupingHistoryModal.component';
import { TestSqlModalComponent } from '../../testSqlModal/testSqlModal.component';
import { EditJoinEntityModalComponent } from '../editJoinEntityModal/editJoinEntityModal.component';
import { EditValidValueQueryModalComponent } from '../../validValueQueries/editValidValueQueryModal.component';

@Injectable()
export class EpisodeGroupingEditService implements IEpisodeGroupingEditService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService,
        private uiRouter: UIRouter, private state: StateService,
        private matDialog: MatDialog,
        private changeDetectRef: ChangeDetectorRef) {
        //
    }

    async initDelegate(states: IEpisodeGroupingEditStates): Promise<object> {
        states.lodash = _;
        await this.init(states);
        return {};
    }

    async init(states: IEpisodeGroupingEditStates) {
        const hierarchies = await this.dataAccess.genericFind({
            model: 'ObservationCategoryHierarchy'
        });

        const entities = await this.dataAccess.genericFind({
            model: 'EpisodeGroupingEntity'
        });

        const productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle',
            filter: { order: 'title' }
        });

        const careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { order: 'title', where: { assignable: true } }
        });

        const tablePartitionMappings = await this.dataAccess.genericFind({
            model: 'TablePartitionMapping'
        });
        states.tablePartitionMappings = _.map(tablePartitionMappings, 'name');

        const validValuesQueries = await this.dataAccess.genericFind({
            model: 'ValidValuesQuery'
        });
        states.validValuesQueries = _.map(validValuesQueries, 'name');

        states.observationCategoryHierarchies = _.orderBy(_.map(hierarchies, function (h: any) {
            h.name = h.category + CONST.HIERARCHY_DELIMITER + h.category2 + CONST.HIERARCHY_DELIMITER + h.category3 + CONST.HIERARCHY_DELIMITER + h.category4;
            return h;
        }), 'name');
        states.episodeGroupingEntities = _.map(entities, 'entity');
        states.productBundles = productBundles;
        states.careGroupings = careGroupings;


        if (this.uiRouter.globals.params.id) {
            states.editMode = true;
            const episodeGrouping = await this.getEpisodeGrouping(states);
            states.episodeGrouping = episodeGrouping;
            states.episodeGrouping.selectedValidCareGrouping = {};

            if (this.uiRouter.globals.current.name === 'argos.ccgDesigner.episodeGroupings.copy') {
                states.editMode = false;
                states.episodeGrouping.defaultVisibility = 'Disable';
                delete states.episodeGrouping.groupByAttribute;
                delete states.episodeGrouping.filterAttribute;
            }

            states.episodeGrouping.validValues = _.chain(states.episodeGrouping)
                .get('validValues')
                .map(function (val: any, idx: any) {
                    val.order = val.order || idx;
                    return val;
                }).value();

            states.originalEpisodeGrouping = _.cloneDeep(episodeGrouping);
            const hierarchy = episodeGrouping.displayGroup + CONST.HIERARCHY_DELIMITER + episodeGrouping.displayGroup2 + CONST.HIERARCHY_DELIMITER + episodeGrouping.displayGroup3 + CONST.HIERARCHY_DELIMITER + episodeGrouping.displayGroup4;
            states.episodeGrouping.observationCategoryHierarchyId = _.get(_.find(states.observationCategoryHierarchies, { name: hierarchy }), 'id');
            _.forEach(states.episodeGrouping.validCareGroupings, function (validCareGrouping) {
                states.episodeGrouping.selectedValidCareGrouping[validCareGrouping] = true;
            });
            _.forEach(states.episodeGrouping.productBundles, function (productBundle: any) {
                const productBundleObj: any = _.find(states.productBundles, { shortName: productBundle });
                if (productBundleObj) {
                    productBundleObj.enabled = true;
                }
            });
            if (!_.isEmpty(states.episodeGrouping.calculateBy)) {
                states.calculateBy = states.episodeGrouping.calculateBy.join(', ');
            }
        } else {
            states.episodeGrouping = {
                defaultVisibility: 'Disable',
                groupCategory: 'other',
                selectedValidCareGrouping: {}
            };
        }
    }

    async checkExistingEpisodeGroupingGroupByAttribute(states: IEpisodeGroupingEditStates) {
        await this.checkExistingEpisodeGrouping('groupByAttribute', states);
    }

    async checkExistingEpisodeGroupingGroupByName(states: IEpisodeGroupingEditStates) {
        await this.checkExistingEpisodeGrouping('groupByName', states);
    }

    async checkExistingEpisodeGroupingFilterAttribute(states: IEpisodeGroupingEditStates) {
        await this.checkExistingEpisodeGrouping('filterAttribute', states);
    }

    async checkExistingEpisodeGrouping(property: string, states: IEpisodeGroupingEditStates) {
        if (states.episodeGroupingForm) {

            states.episodeGroupingForm[property] = {
                exists: false
            };

            if (states.episodeGrouping && states.episodeGrouping[property] && states.episodeGrouping[property].length > 0) {
                const filter = {
                    where: {
                        or: [
                            { groupByAttribute: states.episodeGrouping[property] },
                            { groupByName: states.episodeGrouping[property] },
                            { filterAttribute: states.episodeGrouping[property] }
                        ]
                    }
                };
               
                const response = await this.dataAccess.genericFind({
                    model: 'EpisodeGrouping',
                    filter
                });
                if (this.uiRouter.globals.params.id) {
                    _.remove(response, { groupByAttribute: this.uiRouter.globals.params.id });
                }
                if (response && response.length > 0) {
                    states.episodeGroupingForm[property].exists = true;
                }
            }
        }
    }

    checkedUsesArrayFilteringHandler(states: IEpisodeGroupingEditStates) {
        if (_.get(states, 'episodeGrouping.usesArrayFiltering')) {
            states.episodeGrouping.filterOnly = true;
            states.episodeGrouping.cacheable = false;
            states.episodeGrouping.joinEntity = null;
        }
    }

    async getEpisodeGrouping(states: IEpisodeGroupingEditStates) {
        let id = this.uiRouter.globals.params.id;
        if (states.episodeGrouping && states.episodeGrouping.groupByAttribute) {
            id = states.episodeGrouping.groupByAttribute;
        }

        const episodeGrouping = await this.dataAccess.genericMethod({
            model: 'EpisodeGrouping', method: 'findById',
            parameters: {
                id,
                filter: {
                    include: ['episodeGroupingHistories']
                }
            }
        });
        return episodeGrouping;
    }

    async getEpisodeGroupingByAttribute(groupByAttribute: string) {
        const episodeGrouping = await this.dataAccess.genericMethod({
            model: 'EpisodeGrouping', method: 'findOne',
            parameters: {
                filter: {
                    where: {
                        groupByAttribute
                    }
                }
            }
        });
        return episodeGrouping;
    }

    addValidValueHandler(states: IEpisodeGroupingEditStates) {
        if (_.isArray(_.get(states.episodeGrouping, 'validValues'))) {
            if (!_.isNil(states.newValidValue.name) && !_.isNil(states.newValidValue.id)) {
                states.episodeGrouping.validValues.push(_.clone(states.newValidValue));
                states.newValidValue = {};
            }
        }
    }

    deleteValidValueHandler(validValue: any, states: IEpisodeGroupingEditStates) {
        if (_.isArray(_.get(states.episodeGrouping, 'validValues'))) {
            _.remove(states.episodeGrouping.validValues, function (vv: any) {
                return vv.id === validValue.id && vv.name === validValue.name;
            });
        }
    }

    async saveHandler(states: IEpisodeGroupingEditStates, isClose: boolean) {
        // Need to add logic here
        states.saveInProgress = true;
        // New episode groupings don't have an originalEpisodeGrouping
        states.originalEpisodeGrouping = states.originalEpisodeGrouping || {};

        this.setGroupingProperties(states);

        const idSQLChanged = !_.isEqual(states.episodeGrouping.groupIdSql, states.originalEpisodeGrouping.groupIdSql);
        const nameSQLChanged = !_.isEqual(states.episodeGrouping.groupNameSql, states.originalEpisodeGrouping.groupNameSql);
        const joinEntityChanged = !_.isEqual(states.episodeGrouping.joinEntity, states.originalEpisodeGrouping.joinEntity);
        const sqlColNameChanged = !_.isEqual(states.episodeGrouping.columnName, states.originalEpisodeGrouping.columnName);        
        const runSQLTest = idSQLChanged || nameSQLChanged || joinEntityChanged || sqlColNameChanged;

        if (runSQLTest) {
            console.log('Running SQL Test');
            this.testSQLHandler(states, true, isClose);
        } else {
            await this.saveEpisodeGrouping(states, isClose);
        }
    }

    setGroupingProperties(states: IEpisodeGroupingEditStates) {
        states.episodeGrouping.validCareGroupings = [];
        _.forEach(states.careGroupings, function (careGrouping) {
            if (states.episodeGrouping.selectedValidCareGrouping[careGrouping.name]) {
                states.episodeGrouping.validCareGroupings.push(careGrouping.name);
            }
        });
        states.episodeGrouping.productBundles = _.map(_.filter(states.productBundles, { enabled: true }), 'shortName');
        states.episodeGrouping.validValues = _.chain(states.episodeGrouping)
            .get('validValues')
            .sortBy('order')
            .map(function (val) {
                return val;
            }).value();

        if (!_.get(states.episodeGrouping, 'usesArrayFiltering') && _.get(states.episodeGrouping, 'inputRegex')) {
            states.episodeGrouping.inputRegex = null;
            states.episodeGrouping.inputErrorMessage = null;
        }

        if (states.episodeGrouping.observationCategoryHierarchyId) {
            const hierarchy = _.find(states.observationCategoryHierarchies, { id: states.episodeGrouping.observationCategoryHierarchyId });
            states.episodeGrouping.displayGroup = _.get(hierarchy, 'category');
            states.episodeGrouping.displayGroup2 = _.get(hierarchy, 'category2');
            states.episodeGrouping.displayGroup3 = _.get(hierarchy, 'category3');
            states.episodeGrouping.displayGroup4 = _.get(hierarchy, 'category4');
        }

        if (!_.isEmpty(_.trim(states.calculateBy))) {
            states.episodeGrouping.calculateBy = _.split(states.calculateBy.replace(new RegExp(' ', 'g'), ''), ',');
        } else {
            states.episodeGrouping.calculateBy = [];
        }        
    }

    async saveEpisodeGrouping(states: IEpisodeGroupingEditStates, isClose: boolean) {

        this.setGroupingProperties(states);

        // remove temp property not part of object to save
        delete states.episodeGrouping.selectedValidCareGrouping;

        const episodeGrouping = await this.dataAccess.genericUpsert({
            model: 'EpisodeGrouping',
            data: states.episodeGrouping
        });
        await this.saveEpisodeGroupingHistory(episodeGrouping, states);

        if (isClose) {
            this.state.go('argos.ccgDesigner.episodeGroupings.list');
        } else {
            this.state.go('argos.ccgDesigner.episodeGroupings.edit', { id: episodeGrouping.groupByAttribute }, { reload: true });
        }

        states.saveInProgress = false;
    }

    async saveEpisodeGroupingHistory(episodeGrouping: any, states: IEpisodeGroupingEditStates) {

        // Only save a new version if something actually changed.
        if (_.isEqual(states.originalEpisodeGrouping, episodeGrouping)) {
            return Promise.resolve();
        }

        let version = 0;
        const username = this.argosStore.getItem('username');
        if (states.episodeGrouping && states.episodeGrouping.episodeGroupingHistories && states.episodeGrouping.episodeGroupingHistories.length > 0) {
            version = _.max(_.map(states.episodeGrouping.episodeGroupingHistories, 'version')) || 0;
        }
        version++;

        delete episodeGrouping.episodeGroupingHistories;
        return this.dataAccess.genericUpsert({
            model: 'EpisodeGroupingHistory',
            data: {
                episodeGroupingId: states.episodeGrouping.groupByAttribute,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: episodeGrouping
            }
        });
    }

    viewHistoryHnadler(states: IEpisodeGroupingEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    episodeGrouping: states.episodeGrouping
                }
            }
        };

        this.matDialog.open(ViewEpisodeGroupingHistoryModalComponent, dialogConfig);
    }

    testSQLHandler(states: IEpisodeGroupingEditStates, isSaving = false, isClose = false) {

        this.setGroupingProperties(states);

        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    type: 'EpisodeGrouping',
                    episodeGrouping: states.episodeGrouping,
                    isSaving
                }
            }
        };

        const dialogRef = this.matDialog.open(TestSqlModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async (action: 'cancel'|'save') => {
            switch(action) {
                case 'cancel':
                    states.saveInProgress = false;
                    this.changeDetectRef.detectChanges();
                    break;
                case 'save':
                    await this.saveEpisodeGrouping(states, isClose);
                    break;
            }
        });
    }

    editValidValuesQueryHandler(validValuesQueryName: string) {

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    validValueQueryName: validValuesQueryName,
                    supportsCache: true,
                    editMode: true
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(EditValidValueQueryModalComponent, dialogConfig);
    }

    editJoinEntityHandler(joinEntity: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    episodeGroupingEntityId: joinEntity,
                    editMode: true
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(EditJoinEntityModalComponent, dialogConfig);
    }

    async fetchValidValuesHandler(states: IEpisodeGroupingEditStates) {
        states.fetchingValidValues = true;
        try {
            const validValues = await this.dataAccess.genericMethod({
                model: 'EpisodeGrouping',
                method: 'generateValidValues',
                parameters: {
                    groupByAttribute: states.episodeGrouping.groupByAttribute
                }
            });
            states.fetchingValidValues = false;
            if (_.size(validValues) > 0) {
                states.episodeGrouping.validValues = validValues;
            }
        } catch (err) {
            states.fetchingValidValues = false;
        }
    }
}
