import { Component, OnInit } from '@angular/core';
import { IModelMetaDataListStates } from './modelMetaDataList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ModelMetaDataListService } from './modelMetaDataList.component.service';

@Component({
    selector: 'argos-modelMetaDataList',
    templateUrl: './modelMetaDataList.component.html',
    providers: [ModelMetaDataListService]
})

export class ModelMetaDataListComponent extends ReactComponentBase<{}, IModelMetaDataListStates, {}> implements OnInit {
    constructor(private svc: ModelMetaDataListService) {
        super({
            showType: 'hideEndPoint',
            modelMetaDataList: []
        } as unknown as IModelMetaDataListStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
    }
}
