import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IErrorMapService, IErrorMapStates } from './pipelineErrorMap.component.d';
import * as _ from 'lodash';

@Injectable()
export class PipelineErrorMapService implements IErrorMapService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter) {
        //
    }

    async initDelegate(states: IErrorMapStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IErrorMapStates) {
        await this.querySteps(states);
    }

    async querySteps(states: IErrorMapStates) {
        const allErrorsDetail: any[] = [];
        const res = await this.dataAccess.genericFind({
            model: 'PipelineErrorMap'
        });
        res.forEach((r: any) => {
            const currentError = {
                errorType: r.errorType,
                errorDisplayText: r.errorDisplayText,
                errorMsgDetail: r.errorMsgDetail
            };
            allErrorsDetail.push(currentError);
        });
        states.allErrorsDetail = _.cloneDeep(_.orderBy(allErrorsDetail, ['errorType'], ['errorDisplayText']));
        states.allErrors.data = states.allErrorsDetail;
    }

    async addErrorMap(states: IErrorMapStates) {
        const currentErrorMap = states.allErrors.data;
        if (!_.isEmpty(states.newErrorMap.errorDisplayText) && !_.isEmpty(states.newErrorMap.errorMsgDetail)) {
            currentErrorMap.push(states.newErrorMap);
        }
        const data = {
            errorType: states.newErrorMap.errorType,
            errorDisplayText: _.trim(states.newErrorMap.errorDisplayText),
            errorMsgDetail: _.trim(states.newErrorMap.errorMsgDetail)
        };
        const resp = await this.dataAccess.genericCreate({
            model: 'PipelineErrorMap',
            data
        });
        if (resp) {
            states.allErrorsDetail = _.cloneDeep(_.orderBy(currentErrorMap, ['errorType', 'errorDisplayText']));
            states.allErrors.data = states.allErrorsDetail;
            states.newErrorMap = {};
        }
    }

}
