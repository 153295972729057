import { Component, OnInit, Inject, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ISetTableConfigModalProps, ISetTableConfigModalStates } from './setTablesConfigModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { SetTableConfigModalService} from './setTablesConfigModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'argos-setTablesConfigModal',
    templateUrl: './setTablesConfigModal.component.html',
    providers: [SetTableConfigModalService]
})

export class SetTableConfigModalComponent extends ReactComponentBase<ISetTableConfigModalProps, ISetTableConfigModalStates, {}> 
    implements OnInit, AfterViewInit {
        @ViewChild('matTbSort') matTbSort = new MatSort();

        constructor(private svc: SetTableConfigModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
            private dialogRef: MatDialogRef<SetTableConfigModalComponent>, private SnackBar: MatSnackBar) {
                super(
                    {
                        tables: new MatTableDataSource<Object>(),
                        tableConfig: [],
                        queryRunning: true
                    }
                );
                this.props = dialogData.props;
            }

        ngOnInit() {
            this.svc.init(this.props, this.states);
            this.cdr.detectChanges();
        }

        ngAfterViewInit() {
            this.states.tables.sort = this.matTbSort;
            this.states.tables.sortingDataAccessor = (item: any, property: string) => {
                switch (property) {
                    case 'lastUpdateDate': return new Date(item['lastUpdateDate']);
                    default: return item[property];
                }
            };
        }

        async saveTableConfig() {
            const response = await this.svc.updateTableConfig(this.props, this.states);
            this.SnackBar.open(response, 'done', { panelClass: 'snack-bar-success' });
            this.dialogRef.close('done');
        }

        async saveAllTables() {
            const response = await this.svc.updateAllTables(this.props, this.states);
            this.SnackBar.open(response, 'done', { panelClass: 'snack-bar-success' });
            this.dialogRef.close('done');
        }

        async removeSelections() {
            const response = await this.svc.removeAllTables(this.props, this.states);
            this.SnackBar.open(response, 'done', { panelClass: 'snack-bar-success' });
            this.dialogRef.close('done');
        }

        close() {
            this.dialogRef.close('cancel');
        }
}
