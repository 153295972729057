import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IPrism1MetricEditStates } from './prism1MetricEdit.component.d';
import { UrlService } from '../../../services/url.service';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { Prism1MetricEditService } from './prism1MetricEdit.component.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-prism1MetricEdit',
    templateUrl: './prism1MetricEdit.component.html',
    providers: [Prism1MetricEditService]
})

export class Prism1MetricEditComponent extends ReactComponentBase<{}, IPrism1MetricEditStates, {}> implements OnInit {
    constructor(private svc: Prism1MetricEditService, private cdr: ChangeDetectorRef, private urlService: UrlService) {
        super({
            originalMetric: {},
            displayRestrictedGroupingsLength: 0,
            displayRestrictedGroupings: '',
            externalFunctionParam: {},
            metric: {
                defaultVisibility: 'Disable'
            },
            metricformValidity: true,
            metricSqlValidity: true,
            selectedProductBundleCount: 0
        } as unknown as IPrism1MetricEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
        if (this.urlService.checkShowViewHistoryFromUrl()) {
            this.viewHistory();
        }
    }

    metricNameChange() {
        this.svc.metricNameChangeHandler(this.states);
    }

    metricSqlChanged() {
        this.svc.metricSqlChangeHandler(this.states);
    }

    unitsChanged(event: Event): void {
        if (this.states.metric.units !== 'Variable') {
            this.states.metric.variableUnitsSql = null;
        }
    }

    getSelectedProductBundleCount() {
        this.setStates({ selectedProductBundleCount: _.filter(this.states.productBundles, { enabled: true }).length });
    }

    testSql(isSaving = false) {
        this.svc.testSqlHandler(this.states, isSaving);
    }

    save(isClose = false) {
        this.svc.saveHandler(this.states, isClose);
    }

    viewHistory() {
        this.svc.viewHistory(this.states);
    }

    editRestrictedGroupings() {
        this.svc.editRestrictedGroupings(this.states);
    }

    getStorageType() {
        // this.svc.getStorageType(this.states);
    }

    editExternalJoinEntity(externalJoinEntity: string) {
        this.svc.editExternalJoinEntityHandler(externalJoinEntity);
    }

    clearJoinEntity() {
        this.states.metric.externalJoinEntity = null;
    }

    metricTitleChange() {
        this.svc.otherMetricTitleChangeHandler(this.states);
    }
}
