import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MD5Service } from '../../../services/md5.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ClarifyInsightsCreateDeliveryModalService } from './clarifyInsightsCreateDeliveryModal.component.service';
import { IClarifyInsightsCreateDeliveryModalInterface } from './clarifyInsightsCreateDeliveryModal.component.d';
import swal from 'sweetalert2';


@Component({
    selector: 'argos-clarifyInsightsCreateDeliveryModal',
    templateUrl: './clarifyInsightsCreateDeliveryModal.component.html',
    providers: [ClarifyInsightsCreateDeliveryModalService, MD5Service],
    styleUrls: ['./clarifyInsightsCreateDeliveryModal.component.css']
})

export class ClarifyInsightsCreateDeliveryModalComponent extends ReactComponentBase<{}, IClarifyInsightsCreateDeliveryModalInterface, {}> implements OnInit {
    constructor(private svc: ClarifyInsightsCreateDeliveryModalService,
                private cdr: ChangeDetectorRef,
                private SnackBar: MatSnackBar,
                public dialogRef: MatDialogRef<ClarifyInsightsCreateDeliveryModalComponent>) {
        super({
            validationFields: ['activeCustomerCode', 'activeCustomerId', 'activeExtractType', 'activeFilterName', 'activeDataSource', 'deliveryDate', 'deliveryNote', 'exportAsCsv'],
        } as unknown as IClarifyInsightsCreateDeliveryModalInterface);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    async changeEvent(event: any, fieldName: string) {
        switch (fieldName) {
            case 'customerCode': 
                this.states.activeExtractType = undefined;
                this.states.activeFilterName = undefined;
                this.states.activeDataSource = undefined;
                this.states.deliveryDate = undefined;
                this.states.deliveryNote = undefined;
                this.states.exportAsCsv = false;
                this.states.activeCustomerCode = (event !== '') ? event : undefined;
                this.states.activeCustomerId = (this.states.activeCustomerCode) ? this.states.allCustomers.find(customer => customer.customerCode === this.states.activeCustomerCode).customerId : undefined;
                break;
            case 'extractType':
                this.states.activeFilterName = undefined;
                this.states.activeDataSource = undefined;
                this.states.deliveryDate = undefined;
                this.states.deliveryNote = undefined;
                this.states.exportAsCsv = false;
                this.states.activeExtractType = (event !== '') ? event : undefined;
                break;
            case 'filterName':
                this.states.activeDataSource = undefined;
                this.states.deliveryDate = undefined;
                this.states.deliveryNote = undefined;
                this.states.exportAsCsv = false;
                this.states.activeFilterName = (event !== '') ? event : undefined;
                break;
            case 'dataSource':
                this.states.deliveryDate = undefined;
                this.states.deliveryNote = undefined;
                this.states.exportAsCsv = false;
                this.states.activeDataSource = (event !== '') ? event : undefined;
                break;
        };
        this.svc.refreshData(this.states);
        this.cdr.detectChanges();
    }

    close() {
        this.dialogRef.close('cancel');
    }

    clearSelections(states: IClarifyInsightsCreateDeliveryModalInterface) {
        states.activeCustomerCode = undefined;
        states.activeCustomerId = undefined;
        states.activeExtractType = undefined;
        states.activeFilterName = undefined;
        states.activeDataSource = undefined;
        states.deliveryDate = undefined;
        states.deliveryNote = undefined;
        states.exportAsCsv = false;
    }

    updateDeliveryDate(event: any) {
        this.svc.updateDeliveryDateDelegate(this.states, event.value);
    }

    isCreateReady(states: IClarifyInsightsCreateDeliveryModalInterface) {
        return (states.activeCustomerCode && states.activeExtractType && states.activeFilterName && states.activeDataSource && states.deliveryDate) ? true : false;
    }

    async createDelivery(states: IClarifyInsightsCreateDeliveryModalInterface) {
        const success: boolean = await this.confirmCreateDelivery(states);
        if (success) {
            this.dialogRef.close('create');
        }
    }

    async confirmCreateDelivery(states: IClarifyInsightsCreateDeliveryModalInterface) {
        let json: { [key: string]: any } = {};
        this.states.validationFields.forEach(field => {
            const fieldName = field.replace('active', '');
            json[fieldName] = this.states[field];
        });

        let deliveriesInProgress = await this.svc.deliveryInProgress(this.states);
        let title = deliveriesInProgress > 0 ? `There are currently ${deliveriesInProgress} deliveries already in progress, are you sure you want to continue?` : 'Create Delivery?';
        const swalResponse = await swal({
            title: title,
            html: this.formatJsonToHtmlSwal(json),
            showCancelButton: true,
            confirmButtonText: 'Create',
            cancelButtonText: 'Cancel'
        });

        if (swalResponse.value) {
            try {
                await this.svc.createDelivery(this.states);
                this.SnackBar.open('Delivery Created Successfully', 'Dismiss', {duration: 4000});
                return true
            }
            catch (error) {
                console.log(error);
                this.SnackBar.open(`Delivery Creation Failed with Error`, 'Dismiss', {duration: 4000});
                return false;
            }
        }
        else {
            this.SnackBar.open('Delivery Creation Cancelled', 'Dismiss', {duration: 4000});
            return false;
        }
        
    }

    friendlyColumnName(columnName: string) { 
        const renameColumns: { [key: string]: string } = {
            'Customer Code': 'Customer'
        };
        let tempName = columnName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
        return renameColumns[tempName] || tempName;
    }
        

    formatJsonToHtmlSwal(json: { [key: string]: any }) {
        let html = '<div style="text-align: left;"><ul>';
        for (const [key, value] of Object.entries(json)) {
            if (key.toLowerCase() === 'customerid' || (key.toLowerCase() === 'deliverynote' && !value) ) { 
                // don't show customerId or empty deliveryNote
            }
            else {
                html += `<li><strong>${this.friendlyColumnName(key)}:</strong> ${typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</li>`;
            }
        }
        html += '</ul></div>';
        return html;
    }
    


}