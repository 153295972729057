import { Injectable } from '@angular/core';

@Injectable()
export class ArgosStoreService {
    storage: any;
    useCache = true;
    delimiter = '.';
    inMemoryCache: any = {};
    nameSpace = 'argos';

    constructor() {
        this.storage = this.getInternalStorage();
    }

    private getInternalStorage() {
        const storage: any = {};

        storage.set = (name: string, value: any) => {
            return window.localStorage.setItem(name, value);
        };
        storage.get = (name: string) => {
            return window.localStorage.getItem(name);
        };
        storage.remove = (name: string) => {
            return window.localStorage.removeItem(name);
        };
        storage.clear = () => {
            window.localStorage.clear();
        };

        return storage;
    }

    private getNamespacedKey(key: string) {
        if (!this.nameSpace) {
            return key;
        }
        else {
            return [this.nameSpace, key].join(this.delimiter);
        }
    }

    public setItem(name: string, value: any) {
        if (this.useCache) {
            this.inMemoryCache[name] = value;
        }
        this.storage.set(this.getNamespacedKey(name), JSON.stringify(value));
    }

    public getItem(name: string) {
        let obj;
        if (this.useCache && name in this.inMemoryCache) {
            return this.inMemoryCache[name];
        }
        const saved = this.storage.get(this.getNamespacedKey(name));
        try {
            if (typeof saved === 'undefined' || saved === 'undefined') {
                obj = undefined;
            }
            else {
                obj = JSON.parse(saved);
            }

            if (this.useCache) {
                this.inMemoryCache[name] = obj;
            }
        } catch (e) {
            console.error('Error parsing saved value', e);
            this.removeItem(name);
        }
        return obj;
    }

    public removeItem(name: string) {
        if (this.useCache) {
            delete this.inMemoryCache[name];
        }
        this.storage.remove(this.getNamespacedKey(name));
    }
}
