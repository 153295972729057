import * as _ from 'lodash';
import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { EcsAppShare } from './ecsAppShareHandler.service';

@Injectable()
export class EcsAppAlarms {
    constructor(private dataAccess: NgDataAccess,
        private argosStore: ArgosStoreService, private ecsAppShareHandler: EcsAppShare) {

    }

    async addUptimeAlarmHttp(papertailUrl: string, states: IEcsAppEditStates) {

        const dnsCname = states.cluster.environment.dnsCname;
        const domainSuffix = states.cluster.environment.domainSuffix;

        const result = await this.dataAccess.genericMethod({
            model: 'UpTimeApi', method: 'createAlarmHttp', parameters: {
                dnsCname,
                domainSuffix,
                notes: papertailUrl,
                tags: [states.cluster.accountName, states.cluster.environment.environmentType],
                clusterName: states.cluster.clusterName,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            }
        });

        if (result.isSuccessful) {
            states.cluster.upTimeAlarms.push(result.alarm);
        }
    }

    async addUptimeAlarmTransaction(papertailUrl: string, states: IEcsAppEditStates) {

        const dnsCname = states.cluster.environment.dnsCname;
        const domainSuffix = states.cluster.environment.domainSuffix;
        let tenant = 'prod';
        const tags = [states.cluster.accountName, states.cluster.environment.environmentType];

        if (states.cluster.auth0Tenant && states.cluster.auth0Tenant.value === 'development') {
            tenant = 'dev';
        }

        if (states.cluster.isSSOEnabled) {
            tags.push('sso');
        }

        const result = await this.dataAccess.genericMethod({
            model: 'UpTimeApi', method: 'createAlarmTransaction', parameters: {
                dnsCname,
                domainSuffix,
                notes: papertailUrl,
                tags,
                isSSO: states.cluster.isSSOEnabled,
                auth0Connection: states.cluster.auth0Connection.value,
                tenantName: tenant,
                clusterName: states.cluster.clusterName,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            }
        });

        if (result.isSuccessful) {
            states.cluster.upTimeAlarms.push(result.alarm);
            this.ecsAppShareHandler.getEnvironmentActivityLog(states);
        } else {
            // show error
            swal({
                title: 'Transaction creation failed',
                text: result.errorMessage,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                //
            });
        }
    }

    async pauseUptimeAlarm(disable: boolean, alarm: any, states: IEcsAppEditStates) {

        const result = await this.dataAccess.genericMethod({
            model: 'UpTimeApi', method: 'updateAlarmPauseState', parameters: {
                alarms: [{ id: alarm.pk, name: alarm.name }],
                isDisabled: disable,
                clusterName: states.cluster.clusterName,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            }
        });

        if (result.isSuccessful) {
            alarm.is_paused = disable;
            this.ecsAppShareHandler.getEnvironmentActivityLog(states);
        }
    }

    async deleteUptimeAlarm(alarm: any, states: any) {

        const result = await this.dataAccess.genericMethod({
            model: 'UpTimeApi', method: 'deleteAlarm', parameters: {
                alarmId: alarm.pk,
                alarmName: alarm.name,
                clusterName: states.cluster.clusterName,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            }
        });

        if (result.isSuccessful) {
            // delete row from alarms
            for (let i = 0; i < states.cluster.upTimeAlarms.length; i++) {
                if (states.cluster.upTimeAlarms[i].pk === alarm.pk) {
                    states.cluster.upTimeAlarms.splice(i, 1);
                    break;
                }
            }

            this.ecsAppShareHandler.getEnvironmentActivityLog(states);
        }

        return result;
    }


    async addAwsCanary(name: string, states: IEcsAppEditStates) {

        const cType = name.indexOf('-ta') === -1 ? 'uptime' : 'transaction';
        states.isAlarmsDisabled = true;
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'createAwsCanary', parameters: {
                dnsCname: states.cluster.environment.dnsCname,
                domainSuffix: states.cluster.environment.domainSuffix,
                canaryType: cType,
                awsAccountName: 'clarify'
            }
        });

        if (result.isSuccessful) {
            states.cluster.missingCanarys.splice(states.cluster.missingCanarys.indexOf(name), 1);
            this.getCloudWatchAlarms(states.cluster.environment, states);

            const eventDetail = {
                alarmName: result.alarmName,
                canaryName: result.canaryName,
            };

            const activityLog: any = {
                clusterName: states.clusterName,
                environmentAction: 'edit alarm',
                eventDetail,
                triggeredBy: this.argosStore.getItem('username'),
                awsAccount: states.cluster.accountName
            };
            activityLog.message = 'canary ' + eventDetail.canaryName + ' has been created';

            await this.dataAccess.genericCreate({
                model: 'EnvironmentActivityLog',
                data: activityLog
            });
            this.ecsAppShareHandler.getEnvironmentActivityLog(states);
        }
        states.isAlarmsDisabled = false;
    }

    async getCloudWatchAlarms(envObj: any, states: IEcsAppEditStates) {

        let dnsCname = envObj.dnsCname;
        let domainSuffix = envObj.domainSuffix;

        // ensure we are using lower case
        dnsCname = dnsCname.toLowerCase();
        domainSuffix = domainSuffix.toLowerCase();

        let utCanaryName = dnsCname + '-' + domainSuffix + '-' + 'ut';
        let taCanaryName = dnsCname + '-' + domainSuffix + '-' + 'ta';

        if (utCanaryName.length > 21) {
            utCanaryName = dnsCname.slice(0, 14) + '-' + domainSuffix + '-' + 'ut';
            taCanaryName = dnsCname.slice(0, 14) + '-' + domainSuffix + '-' + 'ta';
        }

        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getCloudWatchAlarms', parameters: {
                canaryNames: [utCanaryName, taCanaryName]
            }
        });

        const dataAccess = this.dataAccess;
        states.cluster.canarys = result.canarys;
        states.cluster.missingCanarys = result.missingCanarys;

        states.cluster.canarys.forEach(function (c: any) {
            envObj.ecsParameters[c.Name.indexOf('-ta') === -1 ? 'canaryUptime' : 'canaryTransaction'] = c.Name;
            dataAccess.genericUpsert({
                model: 'Environment',
                data: envObj
            });
        });
    }

    disableEnvironmentCanarys(states: IEcsAppEditStates) {
        // disable active canarys
        states.cluster.canarys.forEach(async (c: any) => {
            if (c.Status.State === 'RUNNING') {
                await this.updateAlarmStatus(c, states);
            }
        });
    }

    async updateAlarmStatus(canary: any, states: IEcsAppEditStates) {
        const start = canary.Status.State === 'RUNNING' ? false : true;

        states.isAlarmsDisabled = true;
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'updateAwsCanary',
            parameters: {
                canaryName: canary.Name,
                runStatus: start,
                awsAccountName: 'clarify'
            }
        });
        delete canary.disableUpdate;
        if (result.isSuccessful) {
            canary.Status.State = canary.Status.State === 'RUNNING' ? 'STOPPED' : 'RUNNING';

            const eventDetail = {
                canaryName: canary.Name,
                runStateSetTo: start
            };

            const activityLog: any = {
                clusterName: states.cluster.clusterName,
                environmentAction: 'edit alarm',
                eventDetail,
                triggeredBy: this.argosStore.getItem('username'),
                awsAccount: states.cluster.accountName
            };

            activityLog.message = 'canary ' + eventDetail.canaryName + ' has been ' + (start ? 'started' : 'stopped');
            await this.dataAccess.genericCreate({
                model: 'EnvironmentActivityLog',
                data: activityLog
            });
            this.ecsAppShareHandler.getEnvironmentActivityLog(states);
        }
        states.isAlarmsDisabled = false;
    }

    async maintenanceModeClicked(states: IEcsAppEditStates) {
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'updateEcsMaintenanceMode',
            parameters: {
                clusterName: states.clusterName,
                loadBalancerArn: states.cluster.targetGroupInfo[0].LoadBalancerArns[0],
                enable: !states.cluster.isMaintenanceModeEnabled,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            }
        });

        if (result.isSuccessful) {
            states.cluster.isMaintenanceModeEnabled = !states.cluster.isMaintenanceModeEnabled;
            await this.updateEcsParameterProperty('isExcludedFromReleaseCycle', states.cluster.isMaintenanceModeEnabled, states);
            await this.saveEnvironmentIsDisabled(states.cluster.isMaintenanceModeEnabled, states);

            for (let i = 0; i < states.cluster.upTimeAlarms.length; i++) {
                const alarm = states.cluster.upTimeAlarms[i];
                await this.pauseUptimeAlarm(states.cluster.isMaintenanceModeEnabled, alarm, states);
            }

            // await this.disableEnvironmentCanarys(states);
        }
    }

    async saveEnvironmentIsDisabled(enabled: boolean, states: IEcsAppEditStates) {

        const envObj = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'findOne', parameters: {
                filter: { where: { id: states.cluster.environment.id } }
            }
        });

        if (envObj) {
            envObj.isDisabled = enabled;
            await this.dataAccess.genericUpsert({
                model: 'Environment',
                data: envObj
            });// save the permission
        }
    }

    async archiveEnvironment(states: IEcsAppEditStates) {
        // save who requested this

        const eventDetail: any = {};

        eventDetail.upTimeAlarms = _.map(states.cluster.upTimeAlarms, function (u: any) {
            return {
                pk: u.pk,
                name: u.name,
                url: u.msp_address,
                checkType: u.check_type
            };
        });

        const activityLog = {
            clusterName: states.clusterName,
            environmentAction: 'environment archived',
            message: 'environment archived',
            eventDetail,
            triggeredBy: this.argosStore.getItem('username'),
            awsAccount: states.awsAccountName
        };

        const envObj = await this.dataAccess.genericFind({
            model: 'Environment',
            filter: {
                where: { id: states.cluster.environment.id }
            }
        });

        if (envObj) {
            // await this.disableEnvironmentCanarys(states);    //disable active canarys
            // if no alarms then deleting is successful
            let alarmDelReq = {
                isSuccessful: true,
                errorMessage: ''
            };

            for (let i = 0; i < states.cluster.upTimeAlarms.length; i++) {
                const alarm = states.cluster.upTimeAlarms[i];
                alarmDelReq = await this.deleteUptimeAlarm(alarm, states);

                if (!alarmDelReq.isSuccessful) {
                    break;
                }
            }

            if (!alarmDelReq.isSuccessful) {
                swal({
                    title: 'Archiving Stopped. Failed to delete all Uptime Alarms',
                    text: alarmDelReq.errorMessage,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                }).then(async (isConfirm: any) => {
                    //
                });
            } else {
                // proceed with archiving
                envObj[0].isArchived = true;
                envObj[0].isDisabled = true;
                envObj[0].ecsParameters['isExcludedFromReleaseCycle'] = true;
                states.cluster.environment.isArchived = true;

                // save the permission
                await this.dataAccess.genericUpsert({
                    model: 'Environment',
                    data: envObj[0]
                });

                await this.dataAccess.genericCreate({
                    model: 'EnvironmentActivityLog',
                    data: activityLog
                });
                this.ecsAppShareHandler.getEnvironmentActivityLog(states);
            }
        }
    }

    async updateEcsParameterProperty(parameterName: string, exclude: boolean, states: IEcsAppEditStates) {
        if (exclude) {
            states.cluster.environment.ecsParameters[parameterName] = true;
        } else {
            delete states.cluster.environment.ecsParameters[parameterName];
        }

        const data = states.cluster.environment;
        await this.dataAccess.genericUpsert({
            model: 'Environment',
            data
        });

        states.cluster[parameterName] = exclude;
    }
}
