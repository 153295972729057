import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class ValidationService {
    constructor() {
        //
    }

    validatePropertyValueNotExistsInList(propertyName: string, propertyValue: any, validateValueList: any[]) {
        const propertyErrors: any[] = []; // hold list of errors

        if (propertyValue && propertyValue.length > 0) {
            if (validateValueList.includes(propertyValue)) {
                propertyErrors.push({
                    propertyName,
                    message: 'value is already used and must be unique'
                });
            }
        }

        return propertyErrors;
    }

    validatePropertyValueWithRegex(propertyName: string, propertyValue: any, regex: string, errorMessage: string) {
        const propertyErrors: any[] = []; // hold list of errors
        const regTest = new RegExp(regex);
        if (propertyValue && propertyValue.length > 0) {
            if (!regTest.test(propertyValue)) {
                propertyErrors.push({
                    propertyName,
                    message: errorMessage
                });
            }
        }

        return propertyErrors;
    }

    validatePropertyValueExistsInList(propertyName: string, propertyValue: any, validateValueList: any[]) {
        const propertyErrors: any[] = []; // hold list of errors

        if (propertyValue && propertyValue.length > 0) {
            _.filter(validateValueList, (value) => {
                if (value === propertyValue) {
                    propertyErrors.push({
                        propertyName,
                        message: 'value is already used'
                    });
                }
            });
        }

        return propertyErrors;
    }

    validateAllRequiredProperties(obj: any, requiredProperties: string[]) {
        const propertyErrors: any[] = []; // hold list of errors

        for (const key in obj) {
            if (requiredProperties.indexOf(key) > -1 && (obj[key] === null || obj[key].toString().trim().length === 0)) {
                propertyErrors.push({
                    propertyName: key,
                    message: _.startCase(key) + ' is a required field'
                });
                requiredProperties.splice(requiredProperties.indexOf(key), 1);
            }
        }

        // check if the require property does not exists
        for (let i = 0, len = requiredProperties.length; i < len; i++) {
            const propName = requiredProperties[i];

            if (!obj[propName]) {
                propertyErrors.push({
                    propertyName: propName,
                    message: _.startCase(propName) + ' is a required field'
                });
            }
        }

        return propertyErrors;
    }

    showPropertyError(propertyName: string, propertyErrors: any[]) {
        let result = {};
        if (this.errorExists(propertyName, propertyErrors)) {
            result = { color: 'red' };
        }

        return result;
    }

    errorExists(propertyName: string, propertyErrors: any[]) {
        let result = false;

        if (propertyErrors) {
            result = propertyErrors.map((i: any) => { return i.propertyName; }).indexOf(propertyName) > -1;
        }

        return result;
    }

    getPropertyErrorMessage(propertyName: string, propertyErrors: any[]) {
        let result = '';

        const errors = _.filter(propertyErrors, (pE) => {
            if (pE.propertyName === propertyName) {
                return pE;
            }
        });

        if (errors) {
            result = errors.map((err) => { return err.message; }).join('\n');
        }

        return result;
    }

}
