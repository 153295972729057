import { Component } from '@angular/core';

@Component({
    selector: 'argos-admin',
    template: `<ui-view></ui-view>`
})

export class CareAdminComponent {

}
