import { Injectable, ChangeDetectorRef } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { IViewAiAnalysisModalProps, IViewAiAnalysisModalStates, IViewAiAnalysisModalService } from './viewAiAnalysisModal.component.d';


@Injectable()
export class ViewAiAnalysisModalService implements IViewAiAnalysisModalService {
    constructor(private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {}
    async initDelegate(props: IViewAiAnalysisModalProps, states: IViewAiAnalysisModalStates): Promise<object> {
        states.tableName = props.tableName;
        states.isTableQuestion = props.isTableQuestion;
        states.messages = [];
        states.question = '';
        states.invalidTable = false;

        if (states.isTableQuestion) {
            states.modalName = 'Reference Data AI Analysis';
            states.apiUrl = 'refTableAnalysis/analyze/';
            states.fetchingResponse = true;
            const response = await this.dataAccess.genericMethod({
                model: 'Teradrome', method: 'postClarifyLlmAPI', parameters: {
                    endpoint: 'refTableAnalysis/check/',
                    params: { ref_table_name: states.tableName }
                }
            });
            if (response && response.detail) {
                states.messages.unshift({
                    message: response.detail,
                    userMessage: false
                });
                states.invalidTable = true;
            } else {
                states.tablePath = response.ref_table_s3_path;
                states.messages.unshift({
                    message: 
                    `Welcome to the AI Analysis Chat.
                    Please ask a question about the data in ${states.tableName}. Word your questions as precisely as possible to avoid any ambiguity.
                    Unlike other Argos chat bots, this one will not retain context from your previous question. Therefore, each question needs to be all-encompassing.
                    You can also request a visualization of the data. For example, you can ask, "Plot a bar chart of distinct zip codes per state".
                    If you get an error, try rephrasing your question.`,
                    userMessage: false
                });
            }
        }

        else {
            states.modalName = 'AI Reference Data Explorer';
            states.apiUrl = 'ai_utils/ref_tbls_metadata/analyze';
            states.messages.unshift({
                message: 'This AI bot lets you explore reference tables metadata and find relevant sources of information. You can ask questions like "Find crosswalk between zips and counties" or "Where are all the reference lists and keys stored"?',
                userMessage: false
            });
        }

        states.fetchingResponse = false;

        return {};
    }

    changeDelegate(oldProps: IViewAiAnalysisModalProps, newProps: IViewAiAnalysisModalProps, states: IViewAiAnalysisModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    async sendQuestion(states: IViewAiAnalysisModalStates, question: string | undefined) {  
        states.messages.unshift({
            message: question,
            userMessage: true
        });

        states.question = undefined;

        this.cdr.detectChanges();
        
        const params: any = {
            user_question: question,
            username: this.argosStore.getItem('username')
        };

        if (states.isTableQuestion) {
            params.ref_table_s3_path = states.tablePath;
        } else if (states.sessionKey) {
            params.session_key = states.sessionKey;
        }

        states.fetchingResponse = true;

        try {
            const response = states.apiUrl.startsWith('ai_utils') ? 
                await this.dataAccess.genericMethod({
                    model: 'ClarifyBot',
                    method: 'post',
                    parameters: {
                        endpoint: states.apiUrl,
                        body: params
                    }
                }) :
                await this.dataAccess.genericMethod({
                    model: 'Teradrome', method: 'postClarifyLlmAPI', parameters: {
                        endpoint: states.apiUrl,
                        params
                    }
                });
            
            if (response.session_key) {
                states.sessionKey = response.session_key;
            }
    
            if (response.image_data) {
                states.messages.unshift({
                    image: `data:image/png;base64,${response.image_data}`,
                    userMessage: false,
                });
    
            } else if (response.tabular_data) {
                states.messages.unshift({
                    table: this.formatDataAsTable(response.tabular_data),
                    userMessage: false,
                });
    
            } else if (response.llm_response) {
                let message = response.llm_response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
                message = message.replace(/`(.*?)`/g, '<i>$1</i>');
    
                states.messages.unshift({
                    message,
                    userMessage: false,
                });
            }
        } catch (error) {
            console.log(error);
            states.messages.unshift({
                message: 'Issue sending question, please try again.',
                userMessage: false,
            });
        }

        

        this.cdr.detectChanges();
        states.fetchingResponse = false;
    }

    formatDataAsTable(data: any) {      
        if (!data.length) return 'Issue generating table, please try asking again.';
        const dataObject = JSON.parse(data);

        // Calculate max width for each column
        const headers = Object.keys(dataObject[0]);
        const colWidths = headers.map(header => 
            Math.max(header.length, ...dataObject.map((obj: any) => 
                typeof obj[header] === 'number' ? obj[header].toFixed(2).length : obj[header].toString().length
            ))
        );
    
        // Replace spaces with &nbsp; for alignment
        const replaceSpaces = (str: any, width: any) => {
            const padding = width - str.length;
            return str + '&nbsp;'.repeat(padding);
        };
    
        // Create header row
        const headerRow = headers.map((header, i) => replaceSpaces(header, colWidths[i])).join(' | ');
        let table = '| ' + headerRow + ' |\n';
    
        // Create separator row
        const separatorRow = colWidths.map(width => '-'.repeat(width).replace(/ /g, '&nbsp;')).join('-|-');
        table += '|-' + separatorRow + '-|\n';
    
        // Append each row of data
        dataObject.forEach((item: any) => {
            const row = headers.map((header, i) => {
                const cellValue = typeof item[header] === 'number' ? item[header].toFixed(2) : item[header].toString();
                return replaceSpaces(cellValue, colWidths[i]);
            }).join(' | ');
            table += `| ${row} |\n`;
        });
    
        // Replace remaining spaces between columns with &nbsp;
        return table.replace(/ /g, '&nbsp;');

    }

}
