import { Injectable } from '@angular/core';
import { IPrism1MetricImportStates, IPrism1MetricImportService } from './prism1MetricImport.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { CONST } from '../../../constants/globals';
import swal from 'sweetalert2';
import * as moment from 'moment';
const md5 = require('blueimp-md5');
declare const $: any;

@Injectable()
export class Prism1MetricImportService implements IPrism1MetricImportService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: IPrism1MetricImportStates): Promise<IPrism1MetricImportStates> {
        states.moment = moment;
        const newStates = await this.activate(states);
        return newStates;
    }

    async activate(initstates: IPrism1MetricImportStates): Promise<IPrism1MetricImportStates> {
        const states = _.cloneDeep(initstates);
        states.eventAction = true;
        const productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle'
        });
        const existingMetrics = await this.dataAccess.genericFind({
            model: 'OtherMetric',
            filter: { order: 'name' }
        });
        const careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping'
        });
        const observationCategoryHierarchies = await this.dataAccess.genericFind({
            model: 'ObservationCategoryHierarchy'
        });
        const observations = await this.dataAccess.genericFind({
            model: 'Observation',
            filter: { order: 'name' }
        });
        const episodeGroupings = await this.dataAccess.genericFind({
            model: 'EpisodeGrouping',
            filter: { order: 'groupByName', fields: ['groupByAttribute', 'groupByName', 'validCareGroupings'] }
        });

        states.productBundles = productBundles.map(function (p: any) { return p.shortName; });
        states.existingMetrics = _.map(existingMetrics, function (metric: any) {
            metric.careGroupings = _.join(_.orderBy(_.map(metric.validCareGroupings, function (validCareGrouping) {
                return _.get(_.find(careGroupings, { name: validCareGrouping }), 'title');
            })), ', ');
            metric.productBundleList = _.join(_.map(metric.productBundles, function (productBundle) {
                return _.get(_.find(states.productBundles, { shortName: productBundle }), 'title');
            }), ', ');

            return metric;
        });

        states.observationCategoryHierarchies = _.orderBy(_.map(observationCategoryHierarchies, function (h: any) {
            h.name = h.category + CONST.HIERARCHY_DELIMITER + h.category2 + CONST.HIERARCHY_DELIMITER + h.category3 + CONST.HIERARCHY_DELIMITER + h.category4;
            return h;
        }), 'name');

        states.observations = observations;
        states.observationNames = observations.map(function (i: any) { return i.name; });
        states.episodeGroupings = episodeGroupings.map(function (i: any) { return i.groupByName; });

        states.careGroupings = careGroupings.filter(function (c: any) {
            if (c.assignable) {
                return c;
            }
        });
        states.careGroupings = states.careGroupings.map(function (i: any) { return i.name; });
        states.aggregationTypes = states.existingMetrics.map(function (i) { return i.aggregationType; });
        states.metricUnits = states.existingMetrics.map(function (i) { return i.units; });
        states.betterDirections = states.existingMetrics.map(function (i) { return i.betterDirection; });
        states.storageTypes = states.existingMetrics.map(function (i) { return i.storageType; });
        states.eventAction = false;
        return states;
    }

    copyErrorToClipboard(row: any, states: IPrism1MetricImportStates) {
        const errorMessage = this.getFieldErrorMessage(row, states);
        this.copyToClipboard(errorMessage, states);
    }

    getFieldErrorMessage(row: any, states: IPrism1MetricImportStates) {

        let result = '';
        if (row.propertyErrors) {
            result = row.propertyErrors.map(function (err: any) { return err.message; }).join('\n');
        }
        return result;
    }

    copyToClipboard(value: any, states: IPrism1MetricImportStates) {
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(value).select();
        document.execCommand('copy');
        $temp.remove();
    }

    copyCSVHeaders(states: IPrism1MetricImportStates) {
        let result = '';
        const eg = states.existingMetrics[0];

        Object.keys(eg).forEach(function (key, index) {
            result += key + ',';
        });

        // remove , at the end
        this.copyToClipboard(result.replace(/,\s*$/, ''), states);
    }

    errorExists(row: any, propertyName: string, states: IPrism1MetricImportStates) {

        let result;
        if (row.propertyErrors) {
            result = row.propertyErrors.map(function (i: any) { return i.field; }).indexOf(propertyName) > -1;
        }
        return result;
    }

    // validation functions
    validateMetrics(states: IPrism1MetricImportStates) {
        const metrics: any[] = [];
        _.forEach(states.metrics, metric => {
            metric.isImportReady = true;    // used to determine if the row is valid
            metric.imported = false;    // used to determine if row was imported successfully
            metric.propertyErrors = []; // store all errors in property
            metrics.push(this.validateMetric(metric, states));
        });
        return metrics;
    }

    validateMetric(metric: any, states: IPrism1MetricImportStates): any {
        const m = _.cloneDeep(metric);
        this.validateAllRequiredFields(m);
        this.validatePropertyDups(m, ['name'], states);
        this.validateBucketHierarchy(m, states);

        this.validateListInList(m, 'validCareGroupings', states.careGroupings);
        this.validateListInList(m, 'productBundles', states.productBundles);
        this.validateListInList(m, 'restrictedGroupings', states.episodeGroupings);

        this.validateValueInList(m, 'defaultVisibility', ['Visible', 'Internal Only', 'Disable'], false);
        this.validateValueInList(m, 'associatedObservationId', states.observationNames, true);
        this.validateValueInList(m, 'aggregationType', states.aggregationTypes, true);
        this.validateValueInList(m, 'metricUnit', states.metricUnits, true);
        this.validateValueInList(m, 'betterDirection', states.betterDirections, true);
        this.validateValueInList(m, 'storageType', states.storageTypes, true);
        return m;
    }

    validateBucketHierarchy(metricObj: any, states: IPrism1MetricImportStates) {
        let isValid = false;

        if (!metricObj.bucket2) {
            metricObj.bucket2 = '';
        }

        if (!metricObj.bucket3) {
            metricObj.bucket3 = '';
        }

        if (!metricObj.bucket4) {
            metricObj.bucket4 = '';
        }

        for (let i = 0, len = states.observationCategoryHierarchies.length; i < len; i++) {
            const h = states.observationCategoryHierarchies[i];
            if (h.category === metricObj.bucket && h.category2 === metricObj.bucket2 && h.category3 === metricObj.bucket3 && h.category4 === metricObj.bucket4) {
                isValid = true;
                break;
            }
        }

        if (!isValid) {
            metricObj.isImportReady = false;
            metricObj.propertyErrors.push({
                field: 'bucket',
                message: 'bucket combination is invalid. valid combinations are ' + states.observationCategoryHierarchies.map(function (i) { return i.name; }).join(', ')
            });
        }
    }

    validateListInList(metricObj: any,
        propertyName: any,
        validList: any): any {
        const valueList = metricObj[propertyName];
        if (valueList && valueList.toString().trim().length > 0) {

            const values = JSON.parse(valueList);

            if (values) {
                values.forEach(function (v: any) {
                    if (validList?.indexOf(v) === -1) {
                        metricObj.isImportReady = false;
                        metricObj.propertyErrors.push({
                            field: propertyName,
                            message: v + ' does not exists in valid list. valid values are ' + validList.join(', ')
                        });
                    }
                });
            }
        }
    }

    validateValueInList(metricObj: any,
        propertyName: any,
        validValues: any,
        isNullable: any) {
        const propertyValue = metricObj[propertyName];
        if (isNullable && !propertyValue) {
            return;
        } else if (propertyValue && propertyValue.toString().trim().length > 0 && validValues.indexOf(propertyValue) > -1) {
            return;
        } else {
            metricObj.isImportReady = false;
            metricObj.propertyErrors.push({
                field: propertyName,
                message: propertyName + ' is empty or an invalid value. valid values are ' + validValues.join(', ')
            });
        }
    }


    validateAllRequiredFields(metricObj: any) {
        const requiredProperties = ['name', 'shortName', 'bucket', 'productBundles'];

        for (const key in metricObj) {
            if (requiredProperties.indexOf(key) > -1 && (metricObj[key] === null || metricObj[key].toString().trim().length === 0)) {
                metricObj.isImportReady = false;
                metricObj.propertyErrors.push({
                    field: key,
                    message: key + ' is a required field'
                });
            }
        }

        // check if the require property does not exists
        for (let i = 0, len = requiredProperties.length; i < len; i++) {
            const propName = requiredProperties[i];

            if (!metricObj[propName]) {
                metricObj.isImportReady = false;
                metricObj.propertyErrors.push({
                    field: propName,
                    message: propName + ' is not found and a required field'
                });
            }
        }
    }

    validatePropertyDups(metricObj: any, propertyNames: any, states: IPrism1MetricImportStates) {
        // check against its own list of new items to import
        const toImportCountDup = _.filter(states.metrics, function (toImportEg) {

            let matchingValue = true;

            // all values have to match to be consider a dup
            for (let i = 0, len = propertyNames.length; i < len; i++) {
                const propName = propertyNames[i];

                if (metricObj[propName] !== toImportEg[propName]) {
                    matchingValue = false;
                    break;
                }
            }

            if (matchingValue) {
                return toImportEg;
            }
        });

        // has to be more than 1 since we need to include self compare
        if (toImportCountDup.length > 1) {
            metricObj.isImportReady = false;
            metricObj.propertyErrors.push({
                field: propertyNames[0],
                message: propertyNames.join(', ') + ' already exists in this import'
            });
        }

        // only do the larger check if the episode grouping is still valid
        if (toImportCountDup.length <= 1) {
            // check against the existing data
            const existCountDup = _.filter(states.existingMetrics, function (existingEg) {

                let existingValue = true;
                // all values have to match to be consider a dup
                for (let i = 0, len = propertyNames.length; i < len; i++) {
                    const propName = propertyNames[i];

                    if (metricObj[propName] !== existingEg[propName]) {
                        existingValue = false;
                        break;
                    }
                }

                if (existingValue) {
                    return existingEg;
                }
            });

            if (existCountDup.length > 0) {
                metricObj.isImportReady = false;
                metricObj.propertyErrors.push({
                    field: propertyNames[0],
                    message: propertyNames.join(', ') + ' already exists in the database'
                });
            }
        }
    }


    // does not check if value is null
    isBoolValue(metricObj: any, propertyNames: any, states: IPrism1MetricImportStates) {

        // all values have to match to be consider a dup
        for (let i = 0, len = propertyNames.length; i < len; i++) {
            const propName = propertyNames[i];

            if (metricObj[propName]) {

                let isTrue;
                switch (metricObj[propName].toLowerCase().trim()) {
                    case 'true': case 'yes': case '1':
                        isTrue = true;
                        break;
                    case 'false': case 'no': case '0': case null:
                        isTrue = false;
                        break;
                    default:
                        isTrue = null;
                }

                if (isTrue === null) {
                    metricObj.isImportReady = false;
                    metricObj.propertyErrors.push({
                        field: propName,
                        message: propName + ' must be a valid boolean value. try true or false'
                    });
                } else {
                    delete metricObj[propName];
                    metricObj[propName] = isTrue;
                }
            }
        }

    }

    convertAssoicatedObservationNameToId(metricObj: any, states: IPrism1MetricImportStates) {

        if (metricObj.associatedObservationId !== null && metricObj.associatedObservationId.trim().length === 0) {
            delete metricObj.associatedObservationId;   // set this to null if nothing is placed
        } else if (metricObj.associatedObservationId !== null) {
            const selectedObservation = _.filter(states.observations, function (obs) {
                if (obs.name === metricObj.associatedObservationId) {
                    return obs;
                }
            });

            if (selectedObservation.length > 0) {
                metricObj.associatedObservationId = parseInt(selectedObservation[0].id);
            }
        }
    }

    async importValidMetrics(states: IPrism1MetricImportStates, cb: Function) {
        states.eventAction = true;
        const savePromises: any[] = [];
        for (let i = 0, len = states.metrics.length; i < len; i++) {
            const m = states.metrics[i];
            if (m.isImportReady && !m.imported) {
                m.imported = true;
                savePromises.push(this.saveMetric(m, i, states));
            }
        }

        await Promise.all(savePromises);
        const recordsSavedCount = savePromises.length;
        states.eventAction = false;
        swal({
            title: 'Import requested',
            text: recordsSavedCount + ' records submitted',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
        }).then(() => {
            cb();
        });
    }

    async saveMetric(metricObj: any, index: number, states: IPrism1MetricImportStates) {
        metricObj.id = md5(metricObj.name);

        if (!metricObj.metricType) {
            metricObj.metricType = null;
        }

        // users can enter the name and this will convert it to its required id if not null
        this.convertAssoicatedObservationNameToId(metricObj, states);
        const metric = await this.dataAccess.genericUpsert({
            model: 'OtherMetric',
            data: metricObj
        });
        await this.getMetric(metric, states);
        await this.saveMetricHistory(metric, states);
        states.existingMetrics.push(metricObj);
        states.saveInProgress = false;
        states.metrics[index] = metricObj;
    }

    async getMetric(metricObj: any, states: IPrism1MetricImportStates) {
        return this.dataAccess.genericFind({
            model: 'OtherMetric',
            filter: {
                id: metricObj.id,
                filter: {
                    include: ['metricHistories']
                }
            }
        });
    }

    async saveMetricHistory(metric: any, states: IPrism1MetricImportStates) {

        let version = 0;
        const username = this.argosStore.getItem('username');
        if (metric && metric.metricHistories && metric.metricHistories.length > 0) {
            version = _.max(_.map(metric.metricHistories, 'version')) || 0;
        }
        version++;

        delete metric.metricHistories;
        return this.dataAccess.genericUpsert({
            model: 'MetricHistory',
            data: {
                otherMetricId: metric.id,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: metric
            }
        });
    }
}
