// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  height: 580px;
  overflow-y: auto;
  overflow-x: scroll;
  width: 100%;
}
.modal-body .extra-text {
  float: right;
}
.chartAreaWrapper {
  position: relative;
  height: 500px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/pipelineHistory/pipelineHistoryChartModel/pipelineHistoryChartModel.component.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACF;AALA;EAMI,YAAA;AAEJ;AACA;EACE,kBAAA;EACA,aAAA;AACF","sourcesContent":[".modal-body {\n  height: 580px;\n  overflow-y: auto;\n  overflow-x: scroll;\n  width: 100%;\n  .extra-text {\n    float: right;\n  }\n}\n.chartAreaWrapper {\n  position: relative;\n  height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
