import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IProductConfigurationContentSyncStatusStates } from './productConfigurationContentSyncStatus.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { ProductConfigurationContentSyncStatusService } from './productConfigurationContentSyncStatus.component.service';

@Component({
    selector: 'argos-productConfigurationContentSyncStatus',
    templateUrl: './productConfigurationContentSyncStatus.component.html',
    providers: [ProductConfigurationContentSyncStatusService]
})

export class ProductConfigurationContentSyncStatusComponent extends ReactComponentBase<{}, IProductConfigurationContentSyncStatusStates, {}> implements OnInit {
    constructor(private svc: ProductConfigurationContentSyncStatusService, private cdr: ChangeDetectorRef) {
        super();
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    startSync() {
        this.svc.startSync(this.states);
    }

    queryStatus() {
        this.svc.queryStatus(this.states);
    }

    viewCounts(row: any) {
        this.svc.viewCounts(row);
    }

    viewContent(row: any) {
        this.svc.viewContent(row);
    }
}
