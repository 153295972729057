import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IDeequTestResultListStates } from './deequTestResultList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { DeequTestResultListService } from './deequTestResultList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-deequTestResultList',
    templateUrl: './deequTestResultList.component.html',
    providers: [DeequTestResultListService]
})

export class DeequTestResultListComponent extends ReactComponentBase<{}, IDeequTestResultListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: DeequTestResultListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            dataUnitTestResults: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['table_name', 'table_filter', 'column_name', 'test_type', 'check', 'check_level', 'constraint', 'constraint_status', 'constraint_message', 'metric_value', 'run_id'],
            matTableColumns: ['table_name', 'table_filter', 'column_name', 'test_type', 'check', 'check_level', 'check_status', 'constraint', 'constraint_status', 'constraint_message', 'metric_entity', 'metric_instance', 'metric_value', 'run_id'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'deequTestResultList'
        } as unknown as IDeequTestResultListStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    openCircleCiJob(id: any) {
        window.open('https://circleci.com/gh/clarifyhealth/tera_tests/' + id, '_blank');
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        let result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }
}
