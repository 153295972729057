import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IClarifyReportsListStates, IClarifyReportsListService } from './clarifyReportsList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { ArgosStoreService } from '../../../services/argosStore.service';

@Injectable()
export class ClarifyReportsListService implements IClarifyReportsListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog,
        private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {
    }

    async initDelegate(states: IClarifyReportsListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IClarifyReportsListStates) {

        const clarifyReports = await this.dataAccess.genericFind({
            model: 'ClarifyReport',
            filter: {
                include: {
                    relation: 'pipelineHistory'
                }
            }
        });

        states.clarifyReports = _.map(_.orderBy(clarifyReports, 'pipelineHistory.endTime', 'desc'), function (cr) {
            return {
                reportId: cr.id,
                reportName: cr.reportName,
                reportDate: new Date(cr.pipelineHistory.endTime).toISOString().slice(0, 10),
                reportPath: cr.reportPath,
                emrClusterId: cr.emrClusterId,
                dataSource: cr.pipelineHistory.dataSource,
                stage: cr.pipelineHistory.stage
            };
        });

        states.table.data = states.clarifyReports;
    }

    setTablePageSize(states: IClarifyReportsListStates) {
        states.pageSize = 25;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.clarifyReports.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IClarifyReportsListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IClarifyReportsListStates) {
        const data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    async getClarifyReportUrl(clarifyReportId: number) {
        await this.dataAccess.genericUpsert({
            model: 'ClarifyReportAudit',
            data: {
                clarifyReportId: clarifyReportId,
                accessedTimestamp: new Date(),
                accessedByUser: this.argosStore.getItem('username')
            }
        });
        return this.dataAccess.genericMethod({
            model: 'ClarifyReport', method: 'getClarifyReportUrl', parameters: {
                clarifyReportId: clarifyReportId,
                awsAccountName: 'clarify'
            }
        });
    }
}
