import { Injectable } from '@angular/core';
import { IPipelineDataSourceListStates, IPipelineDataSourceListService } from './pipelineDataSourceList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class PipelineDataSourceListService implements IPipelineDataSourceListService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: IPipelineDataSourceListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IPipelineDataSourceListStates) {
        const result = await this.dataAccess.genericFind({
            model: 'DataSource',
            filter: {
                order: 'name'
            }
        });

        states.dataSources = _.orderBy(result, ['name']);
    }
}
