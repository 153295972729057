import { PipeTransform, Pipe } from '@angular/core';
import { ArgosStoreService } from '../services/argosStore.service';
import * as _ from 'lodash';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    constructor(private argosStore: ArgosStoreService) {
        //
    }

    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }
        return _.filter(items, filter);
    }
}
