import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { IPublishReleaseNotesProps, IPublishReleaseNotesStates } from './publishReleaseNotes.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PublishReleaseNotesService } from './publishReleaseNotes.component.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'argos-publishReleaseNotes',
    templateUrl: './publishReleaseNotes.component.html',
    providers: [PublishReleaseNotesService]
})

export class PublishReleaseNotesComponent extends ReactComponentBase<IPublishReleaseNotesProps, IPublishReleaseNotesStates, {}> implements OnInit, OnChanges {
    constructor(private svc: PublishReleaseNotesService, private state: StateService) {
        super({
            product: 'Care Prism',
            noData: '',
            releaseNotes: []
        } as unknown as IPublishReleaseNotesStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    cancel() {
        this.state.go('argos.prism.releases');
    }

    fetchReleaseNotes() {
        this.svc.fetchReleaseNotes(this.states);
    }

    publishReleaseNotes() {
        this.svc.publishReleaseNotes(this.states);
    }
}
