import { Injectable } from '@angular/core';
import { IObservationExceptionAddEditProps, IObservationExceptionAddEditStates, IObservationExceptionAddEditService } from './observationExceptionAddEdit.component.d';
import { ObservationExceptionService } from '../../../services/observationExceptionService.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
@Injectable()
export class ObservationExceptionAddEditService implements IObservationExceptionAddEditService {
    constructor(private observationExceptionService: ObservationExceptionService, private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private state: StateService) {
        //
    }
    async initDelegate(props: IObservationExceptionAddEditProps, states: IObservationExceptionAddEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async changeDelegate(oldProps: IObservationExceptionAddEditProps, newProps: IObservationExceptionAddEditProps, states: IObservationExceptionAddEditStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async init(states: IObservationExceptionAddEditStates) {
        states.observationException = this.observationExceptionService.getObservationException() || {};
        states.dataSources = this.observationExceptionService.getDataSources() || [];
        states.statusOptions = this.observationExceptionService.getStatusOptions() || [];
        let observationExceptions: any;
        if (_.isEmpty(states.observationException)) {
            if (this.uiRouter.globals.params.id) {
                observationExceptions = await this.dataAccess.genericMethod({
                    model: 'ObservationException', method: 'findOne', parameters: {
                        filter: {
                            where: {
                                id: this.uiRouter.globals.params.id
                            }
                        }
                    }
                });
            }
        }

        let dataSources: any;
        if (_.isEmpty(states.dataSources)) {
            dataSources = await this.dataAccess.genericFind({
                model: 'DataSource'
            });
        }

        if (dataSources || observationExceptions) {
            const observationException = observationExceptions || states.observationException;
            states.dataSources = dataSources || states.dataSources;

            if (!_.isEmpty(observationException)) {
                states.observationException = this.observationExceptionService.processObservationException(
                    observationException, states.dataSources);
                this.initalizeSelections(states.dataSources,
                    states.statusOptions,
                    states.observationException, states);
            } else {
                states.observationException = {};
            }
        } else {
            states.observationException = this.observationExceptionService.processObservationException(
                states.observationException, states.dataSources);
            this.initalizeSelections(states.dataSources,
                states.statusOptions,
                states.observationException, states);
        }

        switch (this.uiRouter.globals.current.name) {
            case 'argos.ccgDesigner.observationException.add':
                states.mode = 'ADD';
                break;
            case 'argos.ccgDesigner.observationException.edit':
            default:
                states.mode = 'EDIT';
                break;
        }
    }

    initalizeSelections(dataSources: any,
        statusOptions: any,
        observationException: any,
        states: IObservationExceptionAddEditStates) {
        states.selectedDataSource = _.find(dataSources, { id: observationException.dataSource }) || {};
        states.selectedStatus = _.find(statusOptions, { id: observationException.status }) || {};
    }

    applySelections(selectedDataSource: any, selectedStatus: any,
        observationException: any) {
        observationException.dataSource = selectedDataSource.id;
        observationException.status = selectedStatus.id;
    }

    updateObservationExceptionDates(observationException: any, mode: any) {
        switch (mode) {
            case 'ADD':
                observationException.createdDate = moment().toDate();
                observationException.updatedDate = moment().toDate();
                break;
            case 'EDIT':
            default:
                observationException.updatedDate = moment().toDate();
                break;
        }
    }

    async saveHandler(states: IObservationExceptionAddEditStates, andClose?: any) {
        // Need to add logic here
        try {
            states.saveInProgress = true;
            this.applySelections(states.selectedDataSource, states.selectedStatus, states.observationException);
            this.updateObservationExceptionDates(states.observationException, states.mode);
            const data = _.cloneDeep(states.observationException);
            await this.dataAccess.genericUpsert({
                model: 'ObservationException',
                data
            });
            if (andClose) {
                this.state.go('argos.ccgDesigner.observationException.list');
            }
            states.saveInProgress = false;
        } catch (error) {
            states.saveInProgress = false;
        }
    }
}
