// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-responsive {
  overflow-y: auto;
  /* Add vertical scroll if content exceeds max height */
}
.modal-body {
  margin: 0 auto;
  /* Center modal content horizontally */
}
.table-container {
  max-height: calc(100vh - 220px);
  /* Adjust as needed */
  overflow-y: auto;
}
th,
td {
  white-space: nowrap;
  /* Prevent text wrapping in cells */
}
.mat-header-row {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  /* Adjust background color as needed */
  z-index: 100;
  /* Ensure header remains on top of content */
}
`, "",{"version":3,"sources":["webpack://./client/app/components/dataDashboard/additionalInfoModal/additionalInfoModal.component.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACF,sDAAsD;AACxD;AACA;EACI,cAAA;EACF,sCAAsC;AACxC;AACA;EACI,+BAAA;EACF,qBAAqB;EAAnB,gBAAA;AAEJ;AACA;;EACA,mBAAA;EAEE,mCAAmC;AACrC;AAAA;EACA,gBAAA;EACA,MAAA;EACA,yBAAA;EAEE,sCAAsC;EADxC,YAAA;EAGE,4CAA4C;AAC9C","sourcesContent":[".table-responsive {\n    overflow-y: auto; /* Add vertical scroll if content exceeds max height */\n}\n\n.modal-body {\n    margin: 0 auto; /* Center modal content horizontally */\n}\n\n.table-container {\n    max-height: calc(100vh - 220px); /* Adjust as needed */\n    overflow-y: auto;\n}\n  \nth, td {\nwhite-space: nowrap; /* Prevent text wrapping in cells */\n}\n  \n.mat-header-row {\nposition: sticky;\ntop: 0;\nbackground-color: #f9f9f9; /* Adjust background color as needed */\nz-index: 100; /* Ensure header remains on top of content */\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
