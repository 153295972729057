import { ChangeDetectorRef, Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { ICcgReferenceDataListStates } from './ccgReferenceDataList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CcgReferenceDataListService } from './ccgReferenceDataList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';
import { UIRouter } from '@uirouter/core';

@Component({
    selector: 'argos-ccgReferenceDataList',
    templateUrl: './ccgReferenceDataList.component.html',
    providers: [CcgReferenceDataListService]
})

export class CcgReferenceDataListComponent extends ReactComponentBase<{}, ICcgReferenceDataListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;

    constructor(private svc: CcgReferenceDataListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService, private uiRouter: UIRouter) {
        super({
            pageSize: 50,
            ccgReferenceTables: [],
            matTable: new MatTableDataSource([]),
            filterObj: {},
            matTableColumns: ['referenceTable', 'owner', 'viewReferenceSourceButton', 'deleteButton'],
            matTableSettingName: 'ccgReferenceDataList',
            defaultDisplayMatTableColumns: ['referenceTable', 'owner', 'viewReferenceSourceButton', 'deleteButton']
        } as unknown as ICcgReferenceDataListStates);
    }

    async ngOnInit() {
        this.states.matTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.refresh();
    }

    ngAfterViewInit() {
        this.states.matTable.paginator = this.paginator;
        this.states.matTable.sort = this.matTbSort;
        this.matTbSort.sort({
            id: 'referenceTable',
            start: 'asc',
            disableClear: true
        });
    }

    truncateText(text: string) {
        return _.truncate(text, { length: 100 });
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.matTable.filter = JSON.stringify(this.states.filterObj);
    }

    refresh: Function = () => {
        this.cdr.detectChanges();
    };

    deleteTable(row: any) {
        this.svc.deleteTable(row, this.states);
    }

    addTable() {
        this.svc.addTable(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }
}
