import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IObservationListStates, IObservationListService } from './observationList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { CONST } from '../../../constants/globals';
import * as moment from 'moment';
import { ViewHistoryModalComponent } from '../viewHistoryModal/viewHistoryModal.component';
import { ViewSQLModalComponent } from '../viewSQLModal/viewSQLModal.component';
import { AlertService } from 'client/app/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { ViewEnvironmentUsageModalComponent } from '../../prismUsage/viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
@Injectable()
export class ObservationListService implements IObservationListService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private matDialog: MatDialog, private alertSvc: AlertService, private changeDetection: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService) {
        //
    }
    async initDelegate(states: IObservationListStates): Promise<object> {
        states.moment = moment;
        await this.activate(states);
        return {};
    }

    async activate(states: IObservationListStates) {
        const productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle'
        });
        const observationCategoryHierarchiesData = await this.dataAccess.genericFind({
            model: 'ObservationCategoryHierarchy'
        });
        const observations = await this.dataAccess.genericFind({
            model: 'Observation',
            filter: {
                include: ['observationGroup', 'observationType', 'observationEpisodeSubset', 'observationInterval', 'ccgFeature']
            }
        });
        const observationHistories = await this.dataAccess.genericFind({
            model: 'LatestObservationHistory'
        });
        const observationsTestUrls = await this.dataAccess.genericMethod({
            model: 'Teradrome', method: 'getObservationTestUrl', parameters: {
            }
        });

        states.productBundles = productBundles;
        const observationCategoryHierarchies: any = _.orderBy(_.map(observationCategoryHierarchiesData, (h: any) => {
            return {
                id: h.id,
                name: h.category + CONST.HIERARCHY_DELIMITER + h.category2 + CONST.HIERARCHY_DELIMITER + h.category3 + CONST.HIERARCHY_DELIMITER + h.category4
            };
        }), 'name');
        states.observations = _.orderBy(_.map(observations, (o: any) => {
            o.group_name = _.get(o, 'observationGroup.name');
            o.type_name = _.get(o, 'observationType.name');
            o.episode_subset_name = _.get(o, 'observationEpisodeSubset.name');
            o.version = _.get(_.find(observationHistories, { observationId: o.id }), 'version') || 1;
            o.observationCategoryHierarchy = _.get(_.find(observationCategoryHierarchies, { id: o.observationCategoryHierarchyId }), 'name');
            o.lastUpdatedBy = _.get(_.find(observationHistories, { observationId: o.id }), 'createdBy');
            o.lastUpdateDate = _.get(_.find(observationHistories, { observationId: o.id }), 'creationDate');
            o.status = _.get(o, 'status');
            o.bulkSelected = false;
            o.productBundleList = _.join(_.map(o.productBundles, function (productBundle) {
                return _.get(_.find(states.productBundles, { shortName: productBundle }), 'title');
            }), ', ');
            o.intervalName = _.get(o, 'observationInterval.name');
            o.ccgFeatureName = _.get(o, 'ccgFeature.name'); 
            
            return o;
        }), ['group_name', 'name']);
        // this.setTablePageSize(states);
        states.table.data = states.observations;
        states.observationsTestUrl = observationsTestUrls[0];

        const usages = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage',
            method: 'aggregateUsageResults',
            parameters: {
                metaDataTypes: ['metric']
            }
        });

        for (const observation of states.table.data) {
            const usage = _.find(usages, { meta_data_name: observation.name });
            observation.usageCount = _.toNumber(_.get(usage, 'total_usage', 0));
            observation.viewCount = _.toNumber(_.get(usage, 'total_views', 0));
            observation.lastViewed = _.get(usage, 'last_viewed');
            if (observation.lastViewed) {
                observation.lastViewedFromNow = moment(observation.lastViewed).fromNow();
            } else {
                observation.lastViewedFromNow = '-';
                // set these to sort last
                observation.lastViewed = '';
            }
        }
    }

    setTablePageSize(states: IObservationListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.observations.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    selectVisibleObservation(selected: boolean, states: IObservationListStates) {

        if (states.tableShowRows) {
            for (let i = 0; states.tableShowRows.length > i; i++) {

                const observationObj = (_.filter(states.observations, function (o) {
                    if (o.id === states.tableShowRows[i].id) {
                        return o;
                    }
                }));

                if (observationObj && observationObj.length === 1) {
                    observationObj[0].bulkSelected = selected;
                }
            }
        }
    }

    updateObservationStatus(statusValue: string, states: IObservationListStates) {
        states.buttonActionsDisabled = true;
        const arr_of_selected_checkboxes: any = [];
        let count = 0;
        // dynamically populate array of objects (corresponding to selected checkboxes) and capture the count of selections:
        for (let i = 0; states.observations.length > i; i++) {
            if (states.observations[i].bulkSelected) {
                arr_of_selected_checkboxes.push(states.observations[i]);
                count++;
            }
        }
        swal({
            title: 'Are you sure that you want to update the ' + count + ' selected observation(s)',
            text: 'Bulk update to ' + statusValue,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, update them!',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                // iterate the array of selected checkbox objects and update the status value of each object to the parameter
                try {
                    for (let j = 0; arr_of_selected_checkboxes.length > j; j++) {
                        const data = arr_of_selected_checkboxes[j];
                        data.status = statusValue;
                        const observation = await this.dataAccess.genericUpsert({
                            model: 'Observation',
                            data
                        });
                        const newObs = await this.getObservation(observation.id);
                        await this.dataAccess.genericMethod({
                            model: 'Teradrome', method: 'saveObservationHistory', parameters: {
                                observation: newObs,
                                username: this.argosStore.getItem('username')
                            }
                        });
                    }
                } catch (error) {
                    states.buttonActionsDisabled = false;
                }
            }
        });
        states.buttonActionsDisabled = false;
        this.changeDetection.detectChanges();
    }

    async getObservation(id: any) {
        return this.dataAccess.genericMethod({
            model: 'Observation', method: 'findById',
            parameters: {
                id,
                filter: {
                    include: [
                        { observationFilterPredicates: ['observationFilterPredicateOrs'] },
                        'observationHistories'
                    ]
                }
            }
        });
    }

    viewHistory(observation: any, states: IObservationListStates) {
        const observationWithHistory = this.getObservation(observation.id);
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    observation: observationWithHistory
                }
            }
        };
        this.matDialog.open(ViewHistoryModalComponent, dialogConfig);
    }

    viewMetricSQL() {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    observation: undefined,
                    sqlMode: 'all'
                }
            }
        };
        this.matDialog.open(ViewSQLModalComponent, dialogConfig);
    }

    // Triggers a CircleCI run by committing to a branch
    async triggerObservationTest(states: IObservationListStates) {
        const username = this.argosStore.getItem('username');
        const response = await this.dataAccess.genericMethod({
            model: 'Teradrome', method: 'triggerObservationTest', parameters: {
                username
            }
        });
        swal({
            title: 'Test Triggered',
            text: 'Triggered test for all observations in the Test status.',
            type: 'success',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'View Test',
        }).then(async (isConfirm: any) => {
            if (!isConfirm.value) {
                window.open(states.observationsTestUrl, '_blank');
            }
        });
        return response;
    }

    recalculateMetricStatistics() {
        swal({
            title: 'Recalculate Metric Statistics',
            text: 'This will run a batch process that may increase Athena load for the next hour. Are you sure?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, recalculate!',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                try {
                    await this.dataAccess.genericMethod({
                        model: 'MetricStatistic', method: 'calculateStatistics', parameters: {
                        }
                    });
                } catch (error) {
                    this.alertSvc.handleError(error);
                }
            }
        });
    }

    viewEnvironments(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    metaDataTypes: ['metric'],
                    metaDataNames: [row.name]
                }
            }
        };
        this.matDialog.open(ViewEnvironmentUsageModalComponent, dialogConfig);
    }

    exportToExcel(states: IObservationListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IObservationListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.changeDetection.detectChanges();
            }, 1000);
        });
    }
}
