import { Component, OnInit } from '@angular/core';
import { IAllowedFieldEditStates } from './allowedFieldEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { AllowedFieldEditService } from './allowedFieldEdit.component.service';

@Component({
    selector: 'argos-allowedFieldEdit',
    templateUrl: './allowedFieldEdit.component.html',
    providers: [AllowedFieldEditService]
})

export class AllowedFieldEditComponent extends ReactComponentBase<{}, IAllowedFieldEditStates, {}> implements OnInit {
    constructor(private svc: AllowedFieldEditService) {
        super();
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
    }

    addNewField() {
        this.svc.addNewField(this.states);
    }
    save() {
        this.svc.saveHandler(this.states);
    }
}
