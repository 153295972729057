import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import { Injectable } from '@angular/core';
@Injectable()
export class EcsAppActivityLog {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess) {
        //
    }

    async saveEventToLog(successful: boolean, states: IEcsAppEditStates) {

        const eventDetail = {
            runId: states.pollingVars.runId,
            buildLog: states.pollingVars.buildLog,
            fromEcsCluster: states.pollingVars.fromEcsCluster
        };

        const activityLog: any = {
            clusterName: states.clusterName,
            environmentAction: 'database clone',
            eventDetail,
            triggeredBy: this.argosStore.getItem('username'),
            awsAccount: states.awsAccountName,
            isSuccessful: successful,
        };

        activityLog.message = activityLog.environmentAction + ' from ' + eventDetail.fromEcsCluster + ' to ' + states.clusterName;

        await this.dataAccess.genericCreate({
            model: 'EnvironmentActivityLog',
            data: activityLog
        });
    }
}
