import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { ISalesforceSyncStatusStates } from './salesforceSyncStatus.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { SalesforceSyncStatusService } from './salesforceSyncStatus.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-salesforceSyncStatus',
    templateUrl: './salesforceSyncStatus.component.html',
    providers: [SalesforceSyncStatusService]
})

export class SalesforceSyncStatusComponent extends ReactComponentBase<{}, ISalesforceSyncStatusStates, {}> implements OnInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: SalesforceSyncStatusService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            salesforceSyncStatuses: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['name', 'status', 'startDate', 'endDate', 'updatedCount', 'addedCount', 'warningCount', 'errorCount', 'viewLogButton'],
            matTableColumns: ['name', 'status', 'startDate', 'endDate', 'updatedCount', 'addedCount', 'warningCount', 'errorCount', 'viewLogButton'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'salesforceSyncStatus'
        } as unknown as ISalesforceSyncStatusStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    startContactSync() {
        this.svc.startContactSync(this.states);
    }

    startAccountSync() {
        this.svc.startAccountSync(this.states);
    }

    queryStatus() {
        this.svc.queryStatus(this.states);
    }

    viewLog(row: any) {
        this.svc.viewLog(row);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        let result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        return this.matSvc.getMatSelectPlaceholderName(columnName, filterValue);
    }
}
