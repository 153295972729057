import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IViewTeraUsersModalProps, IViewTeraUsersModalStates } from './viewTeraUsersModal.component.d';
import swal from 'sweetalert2';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewTeraUsersModalService } from './viewTeraUsersModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-ViewTeraUsersModal',
    templateUrl: './viewTeraUsersModal.component.html',
    providers: [ViewTeraUsersModalService]
})

export class ViewTeraUsersModalComponent extends ReactComponentBase<IViewTeraUsersModalProps, IViewTeraUsersModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewTeraUsersModalService, @Inject(MAT_DIALOG_DATA) dialogData: any, private cdr: ChangeDetectorRef,
        private dialogRef: MatDialogRef<ViewTeraUsersModalComponent>) {
        super({
            saveInProgress: false,
            isValidToSave: false,
            propertyErrors: [],
            filterObj: {},
            defaultDisplayMatTableColumns: ['editButton','Name', 'Team', 'Jira Project', 'Email', 'deleteButton'],
            matTableColumns: ['editButton','ownerName', 'ownerTeam', 'ownerJiraProject', 'ownerEmail', 'deleteButton'],
            ownerTeamValues: ['CCG', 'Atlas', 'DXTR', 'DPAS', 'CDI', 'DS', 'QA', 'VA', 'SOL', 'Clinical Informatics'],
            ownerJiraProjectValues: ['TERA', 'PRSM', 'DXTR', 'DS', 'INST', 'DPAS', 'RM', 'DOPS', 'INFX', 'VA', 'TM', 'QA']
        } as unknown as IViewTeraUsersModalStates
        );
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    isValid() {
        return this.svc.isValid(this.states);
    }

    errorExists(propertyName: any) {
        return this.svc.errorExists(propertyName, this.states);
    }

    editRow(row: any) {
        this.states.editingMode = true;
        row.editMode = true;
    }

    cancelEdit(row: any) {
        this.states.editingMode = false;
        row.editMode = false;
        if (!row.id) {
            this.states.teraUsers.data.shift();
            this.states.teraUsers.data = this.states.teraUsers.data;
        }
    }

    isRowSavable(row: any) {
        const result = row.ownerName && row.ownerEmail && row.ownerJiraProject && row.ownerTeam;
        return !result;
    }

    saveRow(row: any) {
        this.states.editingMode = false;
        row.editMode = false;
        this.svc.saveRowHandler(row);
    }

    deleteUser(row: any) {
        this.svc.deleteUser(row, this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.svc.save(this.states);
        this.cdr.detectChanges();
    }

    closeModal() {
        this.cdr.detectChanges();
        this.dialogRef.close({teraUsers:this.states.teraUsers});
    }
}
