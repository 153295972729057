import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime, skip } from 'rxjs/operators';

@Component({
    selector: 'ngx-table-select-filter',
    template: `
    <select class="form-control"
        [(ngModel)]="query"
        [formControl]="inputControl">

        <option [value]="'$placeholder'" [disabled]="true" [selected]="query === '$placeholder'" style="display: none;">Select...</option>
        <option [value]="''">None</option>
        <option *ngFor="let option of selectOptions" [ngValue]="option.id">
          {{ option.title }}
        </option>
    </select>
  `,
})
export class NgxTableFilterSelectDirective implements OnInit {

    @Input() selectOptions: any[];
    @Output() filter = new EventEmitter<string>();
    inputControl = new FormControl();
    query = '$placeholder';

    ngOnInit() {
        // This will ignore the first model change event and use debounce time to make sure the model is changed.
        this.inputControl.valueChanges
            .pipe(
                skip(1),
                distinctUntilChanged(),
                debounceTime(300)
            )
            .subscribe((value: string) => this.filter.emit(this.query));
    }
}
