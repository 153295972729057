import { Injectable } from '@angular/core';
import { ICareGroupingsEditStates, ICareGroupingsEditService } from './careGroupingsEdit.component.d';
import { StateService, UIRouter } from '@uirouter/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { AlertService } from '../../../services/alert.service';
import * as _ from 'lodash';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewCareGroupingHistoryComponent } from '../../careGroupings/viewCareGroupingHistory/viewCareGroupingHistory.component';
import { CONST } from '../../../constants/globals';
import { MD5Service } from '../../../services/md5.service';

@Injectable()
export class CareGroupingsEditService implements ICareGroupingsEditService {

    constructor(private state: StateService, private uiRouter: UIRouter,
        private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private argosStore: ArgosStoreService, private alertSvc: AlertService, private md5: MD5Service) {
        //
    }
    async initDelegate(states: ICareGroupingsEditStates): Promise<Object> {
        await this.activate(states);
        return {};
    }

    async activate(states: ICareGroupingsEditStates) {
        if (this.uiRouter.globals.params.id) {

            states.existingCaregroupings = await this.dataAccess.genericFind({
                model: 'CareGrouping',
                filter: { include: ['careGroupingHistories'] }
            });

            const careGrouping = _.cloneDeep(_.find(states.existingCaregroupings, (cg: any) => { return cg.id === this.uiRouter.globals.params.id; }));

            states.careGrouping = careGrouping;
            states.originalCareGrouping = _.cloneDeep(careGrouping);

            _.set(states.careGrouping, 'settings.ccgDefaultMaxDate', _.get(states.careGrouping, 'settings.ccgDefaultMaxDate') || 'END_MONTH');
            _.set(states.careGrouping, 'settings.ccgDefaultMinDateOffset', _.get(states.careGrouping, 'settings.ccgDefaultMinDateOffset') || 'START_MONTH');

            const dateOpt = _.get(states.careGrouping, 'settings.dateOptions');
            if (dateOpt && dateOpt.length > 0) {
                states.careGrouping.settings.dateOptions = JSON.stringify(dateOpt);
            } else {
                delete states.careGrouping.settings.dateOptions;
            }

            const trendPeriods = _.get(states.careGrouping, 'settings.trendPeriods');
            if (trendPeriods && trendPeriods.length > 0) {
                states.careGrouping.settings.trendPeriods = JSON.stringify(trendPeriods);
            } else {
                delete states.careGrouping.settings.trendPeriods;
            }

            const requiredMinFilters = _.get(states.careGrouping, 'settings.requiredMinFilters');
            if (requiredMinFilters && requiredMinFilters.length > 0) {
                states.careGrouping.settings.requiredMinFilters = JSON.stringify(requiredMinFilters);
            } else {
                delete states.careGrouping.settings.requiredMinFilters;
            }

            await this.initOtherMetrics(states);

            if (this.uiRouter.globals.current.name === 'argos.ccgAdmin.careGroupings.copy') {
                delete states.careGrouping.id;
                delete states.careGrouping.name;
                delete states.careGrouping.title;
                delete states.careGrouping.description;
            }

        } else {
            states.careGrouping = {};
        }

        await this.initfetchingMetaData(states);
    }



    async initOtherMetrics(states: ICareGroupingsEditStates) {
        // Get a list of potential volume metrics from OtherMetric
        const otherMetrics = await this.dataAccess.genericFind({
            model: 'OtherMetric',
            filter: {
                order: 'name',
                fields: ['id', 'name', 'aggregationType', 'units', 'validCareGroupings'],
                where: {
                    aggregationType: 'Sum',
                    units: 'Count'
                }
            }
        });

        const ccgBaseName = _.split(states.careGrouping.name, '.')[0];
        states.otherMetrics = _.concat([''], _(otherMetrics).filter(function (o) {
            return _.includes(o.validCareGroupings, ccgBaseName) ||
                _.includes(o.validCareGroupings, states.careGrouping.name);
        }).map('name').value());
        _.set(_.find(states.settings, { name: 'volumeMetric' }), 'options', states.otherMetrics);
    }

    async initfetchingMetaData(states: ICareGroupingsEditStates) {
        const bulkStoreMetaData = await this.dataAccess.genericMethod({
            model: 'MetaDataSync', method: 'findById', parameters: {
                id: 'bulkStoreMetaData'
            }
        });
        if (_.get(bulkStoreMetaData, 'syncStatus') === 'Syncing') {
            states.fetchingMetaData = true;
        } else {
            states.fetchingMetaData = false;
        }
    }

    viewHistory(states: ICareGroupingsEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    careGrouping: states.careGrouping
                }
            }
        };
        this.matDialog.open(ViewCareGroupingHistoryComponent, dialogConfig);
    }

    async fetchCcgDateDomainBounds(states: ICareGroupingsEditStates) {
        const baseTable = _.get(states, ['careGrouping', 'settings', CONST.CG_BASE_TABLE_NAME]);
        const startDateColumn = _.get(states, ['careGrouping', 'settings', CONST.CG_START_DATE_COLUMN_NAME]);
        const endDateColumn = _.get(states, ['careGrouping', 'settings', CONST.CG_END_DATE_COLUMN_NAME]);
        const threshold = _.get(states, ['careGrouping', 'settings', CONST.CG_DATE_QUERY_THRESHOLD]);

        states.fetchingDateBounds = true;

        const response = await this.dataAccess.genericMethod({
            model: 'CareGrouping', method: 'fetchDateDomainBounds', parameters: {
                body: {
                    baseTable,
                    startDateColumn,
                    endDateColumn,
                    threshold
                }
            }
        });
        _.set(states, `careGrouping.settings.${CONST.CG_MIN_START_DATE_NAME}`, _.get(response, 'results.minStartDt'));
        _.set(states, `careGrouping.settings.${CONST.CG_MAX_END_DATE_NAME}`, _.get(response, 'results.maxEndDt'));
        states.fetchingDateBounds = false;
    }

    async saveCareGroupingHistory(states: ICareGroupingsEditStates) {
        // Only save a new version if something actually changed.
        if (_.isEqual(states.originalCareGrouping, states.careGrouping)) {
            return Promise.resolve();
        }

        let version = 0;
        const username = this.argosStore.getItem('username');
        if (states.careGrouping && states.careGrouping.careGroupingHistories && states.careGrouping.careGroupingHistories.length > 0) {
            version = _.max(_.map(states.careGrouping.careGroupingHistories, 'version')) || 0;
        }
        version++;

        delete states.careGrouping.careGroupingHistories;
        const careGroupingHistor = await this.dataAccess.genericUpsert({
            model: 'CareGroupingHistory',
            data: {
                careGroupingId: states.careGrouping.id,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: states.careGrouping
            }
        });
        return careGroupingHistor;
    }


    async save(states: ICareGroupingsEditStates) {
        states.saveInProgress = true;
        try {

            if (!states.careGrouping.id) {
                states.careGrouping.id = this.md5.md5(states.careGrouping.name);
            }

            if (states.careGrouping.settings.dateOptions) {
                states.careGrouping.settings.dateOptions = JSON.parse(states.careGrouping.settings.dateOptions);
            }

            if (states.careGrouping.settings.trendPeriods) {
                states.careGrouping.settings.trendPeriods = JSON.parse(states.careGrouping.settings.trendPeriods);
            }

            if (states.careGrouping.settings.requiredMinFilters) {
                states.careGrouping.settings.requiredMinFilters = JSON.parse(states.careGrouping.settings.requiredMinFilters);
            }

            await this.dataAccess.genericUpsert({
                model: 'CareGrouping',
                data: states.careGrouping
            });
            await this.saveCareGroupingHistory(states);
            this.state.go('argos.ccgAdmin.careGroupings.list');
            states.saveInProgress = false;
        } catch (e) {
            this.alertSvc.handleError(e);
        }
    }

    async getCareGroupingByName(name: string) {
        const episodeGrouping = await this.dataAccess.genericMethod({
            model: 'CareGrouping', method: 'findOne',
            parameters: {
                filter: {
                    where: {
                        name
                    }
                }
            }
        });
        return episodeGrouping;
    }
}
