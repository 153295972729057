import { Injectable } from '@angular/core';
import { IDagLaunchConfirmationModalService, IDagLaunchConfirmationModalStates  } from './dagLaunchConfirmationModal.component.d';

@Injectable()
export class DagLaunchConfirmationModalService implements IDagLaunchConfirmationModalService {
    constructor() {}

    async initDelegate(states: IDagLaunchConfirmationModalStates): Promise<object> {
        await this.init(states);
        return states;
    };

    async init(states: IDagLaunchConfirmationModalStates) {
        states.runDags = false;
        return states;
    };

    launchDags(states: IDagLaunchConfirmationModalStates) {
        states.runDags = true;
        return states.runDags;
    };

} 