import { Injectable, ChangeDetectorRef } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IClarifyInsightsFilterEdit } from './clarifyInsightsFilterEdit.component.d';
import { StateService, UIRouter } from '@uirouter/core';
import { MD5Service } from '../../../services/md5.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { AlertService } from 'client/app/services/alert.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';


@Injectable()
export class ClarifyInsightsFilterEditService {


    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter, private changeDetection: ChangeDetectorRef,
        private md5: MD5Service, private argosStore: ArgosStoreService, private alertSvc: AlertService,
        private state: StateService) { }

    async initDelegate(states: IClarifyInsightsFilterEdit): Promise<object> {
        states.isLoading = true;
        await this.init(states);
        states.isLoading = false;
        return {};
    }

    async init(states: IClarifyInsightsFilterEdit) {
        states.disabledEvaluationDataSourcesClaims = ['cuds_v2', 'qe', 'qe_sample']; // data sources not allowed in Evaluation env
        states.disabledEvaluationDataSourcesMarketShare = ['combined']; // data sources including these strings not allowed in Evaluation env
        if (this.uiRouter.globals.params.id) {
            const id = this.uiRouter.globals.params.id;

            states.filter = await this.dataAccess.genericMethod({
                model: 'ClarifyInsightsFilter', method: 'findById',
                parameters: {
                    id
                }
            });

            states.filter.extractStartDate = states.filter.extractStartDate ? new Date(states.filter.extractStartDate).toISOString().slice(0, 10) : null;
            states.filter.extractEndDate = states.filter.extractEndDate ? new Date(states.filter.extractEndDate).toISOString().slice(0, 10) : null;
            states.filter.fileDownloadDate = states.filter.fileDownloadDate ? new Date(states.filter.fileDownloadDate).toISOString().slice(0, 10) : null;
            states.filter.createdDate = new Date(states.filter.createdDate).toISOString().slice(0, 10);
            states.filter.hcpcsAndDxFlag = states.filter.hcpcsAndDxFlag !== null ? states.filter.hcpcsAndDxFlag.toString() : null;
        } else {
            states.filter = {};
            swal({
                title: 'Extract Type',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#57C84D', 
                confirmButtonText: 'Add',
                cancelButtonText: 'Cancel',
                input: 'select',
                inputOptions: {
                    claims: 'Enriched Claims',
                    market_share: 'Market Share Encounters',
                    hospital_rates: 'Hospital Rates',
                    provider_performance: 'Provider Performance',
                    provider_directory: 'Provider Directory'
                }
            }).then(async (isConfirm: any) => {      
                if (isConfirm.value) {
                    states.filter.extractType = isConfirm.value;
                    this.setEnabledFields(states);
                    this.changeDetection.detectChanges();
                    if (isConfirm.value === 'market_share') {
                        states.filter.excludeQeData = true;
                    }
                } else {
                    this.state.go('argos.clarifyInsightsFilter.list');
                }
            });
        }

        this.setEnabledFields(states); 

        states.customers = await this.dataAccess.genericMethod({
            model: 'ClarifyInsightsCustomer', method: 'find', parameters: {
                filter: {
                    order: 'customerName'
                }
            }
        });
        
        if (this.uiRouter.globals.params.id) {
            // find the matching customer/extract_type when editing an existing filter
            this.filterCustomers(states);
        }

        const allSpecialties = await this.dataAccess.genericMethod({
            model: 'ReferenceDataList', method: 'find', parameters: {
                filter: {
                    where: {
                        name: 'Specialty Clean'
                    },
                    include: {
                        relation: 'referenceDataListItems'
                    },
                    order: 'name'
                }
            }
        });
        states.specialties = _.orderBy(allSpecialties[0].referenceDataListItems.filter((specialty: any) => specialty.key), 'key');
        
        const allStates = await this.dataAccess.genericMethod({
            model: 'ReferenceDataList', method: 'find', parameters: {
                filter: {
                    where: {
                        name: 'state_cds'
                    },
                    include: {
                        relation: 'referenceDataListItems'
                    },
                    order: 'name'
                }
            }
        });
        states.states = _.orderBy(allStates[0].referenceDataListItems.filter((state: any) => state.value !== 'Unknown'), 'value');
        states.originalFilter = JSON.parse(JSON.stringify(states.filter));
        states.payerTypes = ['C', 'M', 'R', 'U', 'O', 'D', 'FFS'];
    
        states.miradorCpts = await this.setMiradorCodes('HCPCS Codes');
        states.miradorDiags = await this.setMiradorCodes('Mirador Redaction Diagnosis Codes/Prefixes');
    }

    setEnabledFields(states: IClarifyInsightsFilterEdit){
        switch (states.filter.extractType) {
            case 'claims':
                states.enabledFields = ['createdBy', 'extractType', 'createdDate', 'filterVersion', 'customerId', 'id', 'dataSource', 'extractStartDate', 'extractEndDate', 'filterName', 'patientFilters', 'payerTypeList', 'patientListAthenaTableName', 'sbdohEnabled', 'patientStateCdList', 'patientZip3List', 'providerNpiList', 'dxCodeList', 'hcpcsCdList', 'hcpcsAndDxFlag', 'generateTransitToken', 'datavantTransitTokenPartner', 'rxOnly'];
                states.dataSources = ['cuds_v2', 'cuds_v2_life_sciences', 'cuds_v2_life_sciences_sample', 'cuds_v2_customers', 'qe', 'qe_sample', 'koala', 'iguana_v3', 'iguana_medicaid_v3'];
                break;
            case 'market_share':
                states.enabledFields = ['createdBy', 'extractType', 'createdDate', 'filterVersion', 'customerId', 'id', 'dataSource', 'extractStartDate', 'extractEndDate', 'filterName', 'patientFilters', 'patientStateCdList', 'providerZip3List', 'providerStateCdList', 'providerNpiList', 'hcpcsCdList', 'excludeQeData'];
                states.dataSources = ['combined', 'cigna', 'koala'];
                break;
            case 'provider_directory':
            case 'provider_performance':
                states.enabledFields = ['createdBy', 'extractType', 'createdDate', 'filterVersion', 'customerId', 'id', 'filterName', 'providerStateCdList', 'providerZip5List', 'providerSpecialtyList', 'providerCountyList', 'providerNpiList'];
                states.dataSources = [];
                break;
            default:
                states.enabledFields = ['createdBy', 'extractType', 'createdDate', 'filterVersion', 'customerId', 'id', 'providerNpiList', 'providerStateCdList', 'medicareProviderIdList', 'sourceProviderIdList', 'healthSystemList', 'serviceLineList', 'fileDownloadDate', 'filterName', 'excludeCommercialServiceVolume', 'excludeInvalidCodesRates', 'excludeQeBasedFields'];
                states.dataSources = [];
                break;
        }
        // find the matching customer/extract_type when adding a new filter
        this.filterCustomers(states);
    }

    filterCustomers(states: IClarifyInsightsFilterEdit) {
        let extractType = states.filter.extractType;
        if (extractType === 'claims') {
            extractType = 'enriched_claims'; 
        }

        states.customers = (states.customers) ? states.customers.filter((customer: any) => customer.extractType === extractType) as [any]: undefined;
    }

    
    async setMiradorCodes(listName: string) {
        const miradorRedactionTags = await this.dataAccess.genericMethod({
            model: 'ReferenceDataList', method: 'find', parameters: {
                filter: {
                    where: {
                        name: listName
                    },
                    include: {
                        relation: 'referenceDataListTags',
                        scope: { 
                            where: {
                                tag: 'Mirador Redaction'
                            }
                        }
                    }
                }
            }
        });

        const parentTagId = miradorRedactionTags[0].referenceDataListTags[0].id;

        const miradorRedactionCpt = await this.dataAccess.genericMethod({
            model: 'ViewReferenceDataListItemParentTags', method: 'find', parameters: {
                filter: {
                    where: {
                        topParentId: parentTagId
                    }
                }
            }
        });
        return _.map(miradorRedactionCpt, 'key');
    }

    clearFile(field: any, states: IClarifyInsightsFilterEdit) {
        states.filter[field] = null;
        this.changeDetection.detectChanges();
    }

    uploadFile(file: any, field: any, states: IClarifyInsightsFilterEdit) {
        const result: string[] = [];
        const invalidValue: string[] = [];
        let reader_string = '';

        const file_obj = file.target.files[0];
        const reader = new FileReader();
        const diagnosisRegex = new RegExp('[A-TV-Z][0-9][0-9AB]\\.?[0-9A-TV-Z]{0,4}');
        const hcpcsRegex = new RegExp('^([A-Z0-9]{1}[0-9]{3}[A-Z0-9]{1},?\s*)*$');
        const countyRegex = new RegExp('^[a-zA-Z ]+$'); // letters and spaces only
        let title = '';
        let description = '';

        reader.readAsText(file_obj);
        reader.onload = (e) => {
            reader_string = reader.result as string;
            const csvRaw = reader_string.split('\r\n');
            csvRaw.forEach(function (value: any) {
                if (!value) {
                    return;
                } else if (field === 'providerNpiList' && (value.length !== 10 || !['1', '2'].includes(value.charAt(0)))) {
                    invalidValue.push(value);
                } else if (field === 'dxCodeList' && (!diagnosisRegex.test(value) || states.miradorDiags.some(diag => value.startsWith(diag)))) {
                    invalidValue.push(value);
                } else if (field === 'hcpcsCdList' && (!hcpcsRegex.test(value) || states.miradorCpts.includes(value))) {
                    invalidValue.push(value);
                } else if (['patientZip3List', 'providerZip3List'].includes(field) && (isNaN(value) || (value.length !== 3))) {
                    invalidValue.push(value);
                } else if ((field === 'providerZip5List') && (isNaN(value) || (value.length !== 5))) {
                    invalidValue.push(value);
                } else if (field === 'sourceProviderIdList' && isNaN(value)) {
                    invalidValue.push(value);
                } else if (field === 'medicareProviderIdList' && value.length !== 6) {
                    invalidValue.push(value);
                } else if (field === 'providerCountyList' && (!countyRegex.test(value))) {
                    invalidValue.push(value);
                } else {
                    result.push(value);
                }
            });

            if (field === 'providerNpiList') {
                title = 'Invalid NPIs';
                description = ' because npis must be 10 digits and start with 1 or 2.';
            } else if (field === 'dxCodeList') {
                title = 'Invalid Diagnosis Codes';
                description = ' because they do not match ICD-10 diagnosis code format or are on the Mirador Redaction List. ICD-10 diagnosis codes always begin with a letter (except U) followed by a digit. The third character is usually a digit, but could be an A or B. After the first three characters, there may be a decimal point, and up to three more alphanumeric characters.';
            } else if (field === 'hcpcsCdList') {
                title = 'Invalid HCPCS Codes';
                description = ' because they do not match HCPCS code format or are on the Mirador Redaction List. Codes must start with 1 letter or digit, followed by 4 digits and/or letters. Letters must be capitalized [A-Z]. Total length 5 characters.';
            } else if (field === 'patientZip3List') {
                title = 'Invalid Zip 3s';
                description = ' because zip 3s must be 3 digits.';
            } else if (field === 'providerZip3List') {
                title = 'Invalid Zip 3s';
                description = ' because zip 3s must be 3 digits.';
            } else if (field === 'providerZip5List') {
                title = 'Invalid Zip 5s';
                description = ' because zip 5s must be 5 digits.';
            } else if (field === 'sourceProviderIdList') {
                title = 'Invalid Source Provider ID';
                description = ' because source provider ids can only include numbers.';
            } else if (field === 'medicareProviderIdList') {
                title = 'Invalid Medicare Provider ID';
                description = ' because Medicare provider ids must have 6 characters.';
            } else if (field === 'providerCountyList') {
                title = 'Invalid County';
                description = ' because county names should only contain letters and spaces.';
            }

            if (invalidValue.length > 0) {
                swal({
                    title,
                    text: 'You cannot add ' + invalidValue.join(',') + description,
                    type: 'warning',
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                }).then(async (isConfirm: any) => {
                    //
                });
            } else {
                states.filter[field] = result;
                this.changeDetection.detectChanges();
            }
        };

    }

    async save(states: IClarifyInsightsFilterEdit) {
        states.saveInProgress = true;
        states.filter.extractEndDate = states.filter.extractEndDate === '' ? null : states.filter.extractEndDate;
        if (states.filter.filterVersion) {
            states.filter.id = null;
        }
        states.filter.filterVersion = (states.filter.filterVersion) ? states.filter.filterVersion + 1 : 1;
        states.filter.createdBy = this.argosStore.getItem('username');
        delete states.filter.createdDate;

        if (states.filter.patientListAthenaTableName) {
            states.filter.patientStateCdList = null;
            states.filter.hcpcsCdList = null;
            states.filter.dxCodeList = null;
            states.filter.providerNpiList = null;
            states.filter.patientZip3List = null;
        }
        if (states.filter.hcpcsAndDxFlag !== null && (!states.filter.hcpcsCdList || !states.filter.dxCodeList)) {
            states.filter.hcpcsAndDxFlag = null;
        }


        states.filter = _.pick(states.filter, states.enabledFields);

        if (['hospital_rates', 'provider_performance', 'provider_directory'].includes(states.filter.extractType)) { 
            states.filter.dataSource = states.filter.extractType;
        }

        try {
            // save
            const filter = await this.dataAccess.genericUpsert({
                model: 'ClarifyInsightsFilter',
                data: states.filter
            });

            this.state.go('argos.clarifyInsightsFilter.edit', { id: filter.id }, { reload: true });
            states.saveInProgress = false;
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    validateFilter(states: IClarifyInsightsFilterEdit) {
        // turn off save if selecting a data source not allowed in Evaluation env
        let filterErrors: any[] = [];
        if (states.filter.customerId == 'b74a43dbb36287ea86eb5b0c7b86e8e8' && states.filter.extractType == 'claims') {
            (states.disabledEvaluationDataSourcesClaims.some(source => states.filter.dataSource == source)) ? filterErrors.push('Invalid data source choice') : null;
        }
        if (states.filter.customerId == 'b74a43dbb36287ea86eb5b0c7b86e8e8' && states.filter.extractType == 'market_share') {
            (states.disabledEvaluationDataSourcesMarketShare.some(source => states.filter.dataSource.includes(source)) && !states.filter.excludeQeData) ? filterErrors.push('Invalid data source choice') : null;
        }
        return filterErrors;
    }

}
