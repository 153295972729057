import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { ICompoundFiltersSelectCategoryModalProps, ICompoundFiltersSelectCategoryModalStates } from './compoundFiltersSelectCategoryModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CompoundFiltersSelectCategoryModalService } from './compoundFiltersSelectCategoryModal.component.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'app-compoundFiltersSelectCategoryModal',
    templateUrl: './compoundFiltersSelectCategoryModal.component.html',
    providers: [CompoundFiltersSelectCategoryModalService]
})

export class CompoundFiltersSelectCategoryModalComponent extends ReactComponentBase<ICompoundFiltersSelectCategoryModalProps, ICompoundFiltersSelectCategoryModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: CompoundFiltersSelectCategoryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<CompoundFiltersSelectCategoryModalComponent>) {
        super({
            availableGroupings: [],
            compoundFilter: {}
        } as unknown as ICompoundFiltersSelectCategoryModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close(eventName = 'cancel') {
        this.dialogRef.close({ eventName, data: this.states.compoundFilter });
    }

    select(episodeGrouping: any) {
        this.svc.selectHandler(episodeGrouping, this.states);
        this.close('apply');
    }
}
