import { Injectable } from '@angular/core';
import { IDependentVariableMetadataSqlFilterProps, IDependentVariableMetadataSqlFilterStates, IDependentVariableMetadataSqlFilterService } from './dependentVariableMetadataSqlFilter.component.d';
import * as _ from 'lodash';
@Injectable()
export class DependentVariableMetadataSqlFilterService implements IDependentVariableMetadataSqlFilterService {

    initDelegate(props: IDependentVariableMetadataSqlFilterProps, states: IDependentVariableMetadataSqlFilterStates): object {
        states.sqlFilters = props.filters;
        states.newFiliter = {
            field: '',
            field2: ''
        };
        states.sqlFilterConditionOptions = ['is', 'is not', '=', '>', '>=', '<=', '<', '!='];
        return {};
    }

    changeDelegate(oldProps: IDependentVariableMetadataSqlFilterProps, newProps: IDependentVariableMetadataSqlFilterProps, states: IDependentVariableMetadataSqlFilterStates): object {
        this.initDelegate(newProps, states);
        return {};
    }
}
