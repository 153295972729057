import { Ng2StateDeclaration, Transition } from '@uirouter/angular';

import { AppComponent } from './app.component';
// import { NavigationComponent } from './components/shared/navigation/navigation.component';
import { ContentComponent } from './components/shared/content/content.component';
import { Error404Component } from './components/shared/error404/error404.component';
import { InactiveComponent } from './components/shared/inactive/inactive.component';
import { CareAdminComponent } from './components/careGroupings/careGroupingsList/ccgAdmin.component';
import { CareGroupingsComponent } from './components/careGroupings/careGroupingsList/careGroupings.component';
import { CareGroupingsListComponent } from './components/careGroupings/careGroupingsList/careGroupingsList.component';
import { CareGroupingsEditComponent } from './components/careGroupings/careGroupingsEdit/careGroupingsEdit.component';
import { CcgDefinitionComponent } from './components/attributedProviderCCG/ccgDefinition/ccgDefinition.component';
import { CcgDefinitionRootComponent } from './components/attributedProviderCCG/ccgDefinition/ccgDefinitionRoot.component';
import { CCGDesignerComponent } from './components/attributedProviderCCG/ccgDefinition/ccgDesigner.component';
import { CcgProviderTypesComponent } from './components/attributedProviderCCG/ccgProviderTypes/ccgProviderTypes.component';
import { CcgSourceSchemasComponent } from './components/attributedProviderCCG/ccgSourceSchemas/ccgSourceSchemas.component';
import { BuildsComponent } from './components/builds/builds/builds.component';
import { BuildsRootComponent } from './components/builds/builds/buildsRoot.component';
import { EnvironmentsRootComponent } from './components/builds/builds/environmentsRoot.component';
import { BuildDetailComponent } from './components/builds/buildDetail/buildDetail.component';
import { ColumnUsageComponent } from './components/columnUsage/columnUsage/columnUsage.component';
import { CompoundFiltersListComponent } from './components/compoundFilters/compoundFiltersList/compoundFiltersList.component';
import { CompoundFiltersComponent } from './components/compoundFilters/compoundFiltersList/compoundFilters.component';
import { CompoundFiltersAddEditComponent } from './components/compoundFilters/compoundFiltersAddEdit/compoundFiltersAddEdit.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { EcsAppComponent } from './components/ecs/ecsAppList/ecsApp.component';
import { EcsAppListComponent } from './components/ecs/ecsAppList/ecsAppList.component';
import { EcsAppEditComponent } from './components/ecs/ecsAppEdit/ecsAppEdit.component';
import { EcsAdminComponent } from './components/ecs/ecsActivityLogList/ecsAdmin.component';
import { EcsActivityLogListComponent } from './components/ecs/ecsActivityLogList/ecsActivityLogList.component';
import { EcsDashboardComponent } from './components/ecs/ecsDashboard/ecsDashboard.component';
import { EcsRepoEditComponent } from './components/ecs/ecsRepoEdit/ecsRepoEdit.component';
import { EnvironmentRequestsComponent } from './components/environmentRequests/environmentRequests/environmentRequests.component';
import { EnvironmentRequestsRootComponent } from './components/environmentRequests/environmentRequests/environmentRequestsRoot.component';
import { NewEnvironment20Component } from './components/environmentRequests/newEnvironment20Request/newEnvironment20.component';
import { NewEnvironment20RequestComponent } from './components/environmentRequests/newEnvironment20Request/newEnvironment20Request.component';
import { NewEnvironmentRequestComponent } from './components/environmentRequests/newEnvironmentRequest/newEnvironmentRequest.component';
import { EnvironmentsListComponent } from './components/environments/environmentsList/environmentsList.component';
import { EnvironmentsComponent } from './components/environments/environmentsList/environments.component';
import { ClientCareGroupingsComponent } from './components/clientCareGroupings/clientCareGroupingsList/clientCareGroupings.component';
import { ClientCareGroupingsListComponent } from './components/clientCareGroupings/clientCareGroupingsList/clientCareGroupingsList.component';
import { MarkedForDeletionComponent } from './components/markedForDeletion/markedForDeletionList/markedForDeletion.component';
import { MarkedForDeletionListComponent } from './components/markedForDeletion/markedForDeletionList/markedForDeletionList.component';
import { EpisodeGroupingEditComponent } from './components/episodeGroupings/episodeGroupingEdit/episodeGroupingEdit.component';
import { EpisodeGroupingImportComponent } from './components/episodeGroupings/episodeGroupingImport/episodeGroupingImport.component';
import { EpisodeGroupingListComponent } from './components/episodeGroupings/episodeGroupingList/episodeGroupingList.component';
import { EpisodeGroupingComponent } from './components/episodeGroupings/episodeGroupingList/episodeGrouping.component';
import { JoinEntitiesListComponent } from './components/episodeGroupings/joinEntitiesList/joinEntitiesList.component';
import { JoinEntitiesComponent } from './components/episodeGroupings/joinEntitiesList/joinEntities.component';
import { ValidValueQueriesListComponent } from './components/validValueQueries/validValueQueriesList.component';
import { ValidValueQueriesComponent } from './components/validValueQueries/validValueQueries.component';
import { ClarifyReportsComponent } from './components/clarifyReports/clarifyReportsList/clarifyReports.component';
import { ClarifyReportsListComponent } from './components/clarifyReports/clarifyReportsList/clarifyReportsList.component';
import { ReferenceDataComponent } from './components/modelFeatures/referenceData.component';
import { ModelFeaturesListComponent } from './components/modelFeatures/modelFeaturesList/modelFeaturesList.component';
import { ModelFeaturesComponent } from './components/modelFeatures/modelFeaturesList/modelFeatures.component';
import { TeraUsersComponent } from './components/teraUsers/teraUsersList/teraUsers.component';
import { TeraUsersListComponent } from './components/teraUsers/teraUsersList/teraUsersList.component';
import { ModelMetaDataComponent } from './components/modelMetaData/modelMetaData.component';
import { NetworkDesignerComponent } from './components/networksStatus/networkDesigner.component';
import { NetworksStatusComponent } from './components/networksStatus/networksStatus/networksStatus.component';
import { ObservationExceptionListComponent } from './components/observationExceptions/observationExceptionList/observationExceptionList.component';
import { ObservationExceptionComponent } from './components/observationExceptions/observationExceptionList/observationException.component';
import { ObservationExceptionAddEditComponent } from './components/observationExceptions/observationExceptionAddEdit/observationExceptionAddEdit.component';
import { AllowedFieldEditComponent } from './components/observations/allowedFieldEdit/allowedFieldEdit.component';
import { AllowedFieldListComponent } from './components/observations/allowedFieldList/allowedFieldList.component';
import { AllowedFieldComponent } from './components/observations/allowedFieldList/allowedField.component';
import { CcgReportListComponent } from './components/observations/ccgReportList/ccgReportList.component';
import { HierarchyEditComponent } from './components/observations/hierarchyEdit/hierarchyEdit.component';
import { HierarchyListComponent } from './components/observations/hierarchyList/hierarchyList.component';
import { HierarchyComponent } from './components/observations/hierarchyList/hierarchy.component';
import { ObservationEditComponent } from './components/observations/observationEdit/observationEdit.component';
import { ObservationListComponent } from './components/observations/observationList/observationList.component';
import { ObservationComponent } from './components/observations/observationList/observation.component';
import { PipelineDataSourceListComponent } from './components/pipelineDataSources/pipelineDataSourceList/pipelineDataSourceList.component';
import { PipelineDataComponent } from './components/pipelineDataSources/pipelineDataSourceList/pipelineData.component';
import { PipelineDataSourceComponent } from './components/pipelineDataSources/pipelineDataSourceList/pipelineDataSource.component';
import { PipelineDataSourceEditComponent } from './components/pipelineDataSources/pipelineDataSourceEdit/pipelineDataSourceEdit.component';
import { PipelineErrorMapComponent } from './components/pipelineHistory/pipelineErrorMap/pipelineErrorMap.component';
import { PipelineErrorMapRootComponent } from './components/pipelineHistory/pipelineErrorMap/pipelineErrorMapRoot.component';
import { PipelineHistoryComponent } from './components/pipelineHistory/pipelineHistory/pipelineHistory.component';
import { PipelineHistoryRootComponent } from './components/pipelineHistory/pipelineHistory/pipelineHistoryRoot.component';
import { PipelineHistoryDetailComponent } from './components/pipelineHistory/pipelineHistoryDetail/pipelineHistoryDetail.component';
import { PipelineHistoryDetailRootComponent } from './components/pipelineHistory/pipelineHistoryDetail/pipelineHistoryDetailRoot.component';
import { PipelineStepDetailComponent } from './components/pipelineHistory/pipelineStepDetail/pipelineStepDetail.component';
import { PipelineQueueComponent } from './components/pipelineQueue/pipelineQueue.component';
import { PipelineQueueRootComponent } from './components/pipelineQueue/pipelineQueueRoot.component';
import { Prism1MetricEditComponent } from './components/prism1Metrics/prism1MetricEdit/prism1MetricEdit.component';
import { Prism1MetricImportComponent } from './components/prism1Metrics/prism1MetricImport/prism1MetricImport.component';
import { Prism1MetricListComponent } from './components/prism1Metrics/prism1MetricList/prism1MetricList.component';
import { Prism1MetricComponent } from './components/prism1Metrics/prism1MetricList/prism1Metric.component';
import { PrismNotificationsRootComponent } from './components/prismNotifications/prismNotifications/prismNotificationsRoot.component';
import { PrismNotificationsComponent } from './components/prismNotifications/prismNotifications/prismNotifications.component';
import { PrismUsageComponent } from './components/prismUsage/prismUsage/prismUsage.component';
import { AtlasClaraChatHistoryComponent } from './components/atlasClaraChatHistory/atlasClaraChatHistory.component';
import { ProductListComponent } from './components/product/productList/productList.component';
import { ProductComponent } from './components/product/productList/product.component';
import { ProductAddEditComponent } from './components/product/productAddEdit/productAddEdit.component';
import { ProductBundlesListComponent } from './components/productBundles/productBundlesList/productBundlesList.component';
import { ProductBundlesComponent } from './components/productBundles/productBundlesList/productBundles.component';
import { CcgFeatureEditComponent } from './components/programEpisodes/ccgFeatureEdit/ccgFeatureEdit.component';
import { CcgFeatureListComponent } from './components/programEpisodes/ccgFeatureList/ccgFeatureList.component';
import { CcgFeatureComponent } from './components/programEpisodes/ccgFeatureList/ccgFeature.component';
import { ProgramEpisodeEditComponent } from './components/programEpisodes/programEpisodeEdit/programEpisodeEdit.component';
import { ProgramEpisodeListComponent } from './components/programEpisodes/programEpisodeList/programEpisodeList.component';
import { ProgramEpisodeComponent } from './components/programEpisodes/programEpisodeList/programEpisode.component';
import { ProgramModelListComponent } from './components/programModels/programModelList/programModelList.component';
import { ProgramModelComponent } from './components/programModels/programModelList/programModel.component';
import { ProgramModelEditComponent } from './components/programModels/programModelEdit/programModelEdit.component';
import { VersionHistoryListComponent } from './components/versionHistory/versionHistoryList.component';
import { EditReleaseNotesComponent } from './components/publishReleaseNotes/editReleaseNotes/editReleaseNotes.component';
import { PreviewReleaseNotesComponent } from './components/publishReleaseNotes/previewReleaseNotes/previewReleaseNotes.component';
import { PublishReleaseNotesComponent } from './components/publishReleaseNotes/publishReleaseNotes/publishReleaseNotes.component';
import { ReleasesComponent } from './components/publishReleaseNotes/releases/releases.component';
import { QueryPerformanceComponent } from './components/queryPerformance/queryPerformance/queryPerformance.component';
import { DataProvisioningQuestionnaireComponent } from './components/dataProvisioningQuestionnaire/dataProvisioningQuestionnaire.component';
import { FilterPerformanceComponent } from './components/environments/filterPerformance/filterPerformance.component';
import { AthenaUsageComponent } from './components/environments/athenaUsage/athenaUsage.component';
import { QuerySettingsComponent } from './components/querySettings/querySettings/querySettings.component';
import { RedshiftClustersComponent } from './components/redshiftClusters/redshiftClusters/redshiftClusters.component';
import { ReferenceDataSourcesEditComponent } from './components/referenceDataSources/referenceDataSourcesEdit/referenceDataSourcesEdit.component';
import { ReferenceDataSourcesListComponent } from './components/referenceDataSources/referenceDataSourcesList/referenceDataSourcesList.component';
import { ReferenceDataSourcesComponent } from './components/referenceDataSources/referenceDataSourcesList/referenceDataSources.component';
import { ClarifyProviderRevisionEditComponent } from './components/clarifyProviderRevision/clarifyProviderRevisionEdit/clarifyProviderRevisionEdit.component';
import { ClarifyProviderRevisionListComponent } from './components/clarifyProviderRevision/clarifyProviderRevisionList/clarifyProviderRevisionList.component';
import { ClarifyProviderRevisionComponent } from './components/clarifyProviderRevision/clarifyProviderRevisionList/clarifyProviderRevision.component';
import { ReferenceListItemsListComponent } from './components/referenceListItems/referenceListItemsList/referenceListItemsList.component';
import { ReferenceListItemsComponent } from './components/referenceListItems/referenceListItemsList/referenceListItems.component';
import { ReferenceListItemEditComponent } from './components/referenceListItems/referenceListItemEdit/referenceListItemEdit.component';
import { ReleaseNotesComponent } from './components/releaseNotes/releaseNotes/releaseNotes.component';
import { SalesforceSyncStatusComponent } from './components/salesforceSync/salesforceSyncStatus/salesforceSyncStatus.component';
import { ProductConfigurationContentSyncStatusComponent } from './components/productConfigurationContentSync/productConfigurationContentSyncStatus.component';
import { TerraTestResultEditComponent } from './components/terraTests/terraTestResultEdit/terraTestResultEdit.component';
import { TerraTestResultsComponent } from './components/terraTests/terraTestResults/terraTestResults.component';
import { TerraTestResultsRootComponent } from './components/terraTests/terraTestResults/terraTestResultsRoot.component';
import { UserListComponent } from './components/users/userList/userList.component';
import { UserEngagementComponent } from './components/users/userEngagement/userEngagement.component';
import { UserRoleComponent } from './components/users/userRoleList/userRole.component';
import { UserRoleListComponent } from './components/users/userRoleList/userRoleList.component';
import { UserRoleEditComponent } from './components/users/userRoleEdit/userRoleEdit.component';
import { MarketShareMetaEditComponent } from './components/referral/marketShareMetaEdit/marketShareMetaEdit.component';
import { MarketShareMetaListComponent } from './components/referral/marketShareMetaList/marketShareMetaList.component';
import { MarketShareMetaComponent } from './components/referral/marketShareMetaList/marketShareMeta.component';
import { ClientEditComponent } from './components/client/clientEdit/clientEdit.component';
import { ClientListComponent } from './components/client/clientList/clientList.component';
import { ClientComponent } from './components/client/clientList/client.component';
import { LoginComponent } from './components/login/login/login.component';
import { CallbackComponent } from './components/login/callback/callback.component';
import { PayerRatesMetaListComponent } from './components/payerRates/payerRatesMetaList/payerRatesMetaList.component';
import { PayerRatesMetaComponent } from './components/payerRates/payerRatesMetaList/payerRatesMeta.component';
import { PayerRatesMetaEditComponent } from './components/payerRates/payerRatesMetaEdit/payerRatesMetaEdit.component';
import { PatchManagementComponent } from './components/patchManagement/patchManagementList/patchManagement.component';
import { PatchManagementListComponent } from './components/patchManagement/patchManagementList/patchManagementList.component';
import { PatchManagementAddEditModalComponent } from './components/patchManagement/patchManagementAddEditModal/patchManagementAddEditModal.component';
import { PatchManagementPreviewModalComponent } from './components/patchManagement/patchManagementPreviewModal/patchManagementPreviewModal.component';
import { QATestResultsComponent } from './components/platformReleaseTestsResults/testResultsDashboard/qaTestResultDashboard.component';
import { QATestResultsRootComponent } from './components/platformReleaseTestsResults/testResultsDashboard/qaTestResultsRoot.component';
import { QATestResultsProductDetailComponent } from './components/platformReleaseTestsResults/testResultsDashboardProductsDetail/qaTestResultDashboardProductsDetail.component';
import { QATestResultsSubmoduleDetailComponent } from './components/platformReleaseTestsResults/testResultsDashboardSubmoduleDetail/qaTestResultDashboardSubmoduleDetail.component';
import { PerformanceTestResultsDashboardProductsDetail } from './components/platformReleaseTestsResults/performanceTestResultsProductDetail/performanceTestResultsDashboardProductsDetail.component';
import { ClarifyInsightsLandingPageComponent } from './components/clarifyInsights/clarifyInsightsLandingPage/clarifyInsightsLandingPage.component';
import { ClarifyInsightsFilterListComponent } from './components/clarifyInsights/clarifyInsightsFilterList/clarifyInsightsFilterList.component';
import { ClarifyInsightsFilterComponent } from './components/clarifyInsights/clarifyInsightsFilterList/clarifyInsightsFilter.component';
import { ClarifyInsightsFilterEditComponent } from './components/clarifyInsights/clarifyInsightsFilterEdit/clarifyInsightsFilterEdit.component';
import { ClarifyInsightsFilterHistoryComponent } from './components/clarifyInsights/clarifyInsightsFilterHistory/clarifyInsightsFilterHistory.component';
import { ClarifyInsightsDataEvaluationUserComponent } from './components/clarifyInsights/clarifyInsightsDataEvaluationUser/clarifyInsightsDataEvaluationUser.component';
import { ClarifyInsightsDeliveryHubComponent } from './components/clarifyInsights/clarifyInsightsDeliveryHub/clarifyInsightsDeliveryHub.component';
import { DataDashboardComponent } from './components/dataDashboard/dataDashboard/dataDashboard.component';
import { ApiPerformanceTestResultsDashboardProductsDetail } from './components/platformReleaseTestsResults/apiPerformanceTestResultsProductDetail/apiPerformanceTestResultsDashboardProductsDetail.component';
import { CypressFeatureTestResultDetail } from './components/platformReleaseTestsResults/cypressFeatureTestResultsDetail/cypressFeatureTestResultDetail.component';
import { ScratchDatabaseCleanupComponent } from './components/scratchDatabaseCleanup/scratchDatabaseCleanupLandingPage/scratchDatabaseCleanup.component';
import { ScratchDatabaseCleanupRootComponent } from './components/scratchDatabaseCleanup/scratchDatabaseCleanupLandingPage/scratchDatabaseCleanupRoot.component';
import { AutomatedGoldPulldownListComponent } from './components/automatedGoldPulldown/automatedGoldPulldownList/automatedGoldPulldownList.component';
import { AutomatedGoldPulldownComponent } from './components/automatedGoldPulldown/automatedGoldPulldown.component';
import { StageSourceTableListComponent } from './components/deequTests/stageSourceTableList/stageSourceTableList.component';
import { StageSourceTableColumnListComponent } from './components/deequTests/stageSourceTableColumnList/stageSourceTableColumnList.component';
import { StageSourceTableComponent } from './components/deequTests/stageSourceTableList/stageSourceTable.component';
import { DataReleaseManagerComponent } from './components/dataReleaseManager/dataReleaseManagerMainPage/dataReleaseManager.component';
import { DataReleaseManagerRootComponent } from './components/dataReleaseManager/dataReleaseManagerMainPage/dataReleaseManagerRoot.component';
import { CreateDataReleaseComponent } from './components/dataReleaseManager/createDataRelease/createDataRelease.component';
import { RunDataReleaseComponent } from './components/dataReleaseManager/runDataRelease/runDataRelease.component';
import { EditDataReleaseComponent } from './components/dataReleaseManager/editDataRelease/editDataRelease.component';
import { DataAiChatComponent } from './components/dataAiChat/aiChat/dataAiChat.component';
import { ClarifyBotRootComponent } from './components/clarifyBot/clarifyBotRoot.component';
import { ClarifyBotComponent } from './components/clarifyBot/clarifyBot.component';
import { AdvanceMetaDataComponent } from './components/advanceCCG/advanceMetaDataList/advanceMetaData.component';
import { AdvanceMetaDataListComponent } from './components/advanceCCG/advanceMetaDataList/advanceMetaDataList.component';
import { AdvanceMetaDataEditComponent } from './components/advanceCCG/advanceMetaDataEdit/advanceMetaDataEdit.component';
import { HospitalRatesLandingComponent } from './components/hospitalRates/hospitalRatesLandingPage/hospitalRatesLandingPage.component';
import { QATestResultsTabAndTileDetailComponent } from './components/platformReleaseTestsResults/testResultsDashboardTabAndTileDetails/qaTestResultsDashboardTabAndTileDetails.component';
import { HospitalRatesBulkAddComponent } from './components/hospitalRates/hospitalRatesBulkAdd/hospitalRatesBulkAdd.component';
import { HospitalRatesLandingPageRootComponent } from './components/hospitalRates/hospitalRatesLandingPage/hospitalRatesLandingPageRoot.component';
import { VersionHistoryComponent } from './components/versionHistory/versionHistory.component';
import { ViewReleaseImpactComponent } from './components/dataReleaseManager/viewReleaseImpact/viewReleaseImpact.component';
import { CcgReferenceDataListComponent } from './components/episodeGroupings/ccgReferenceDataList/ccgReferenceDataList.component';
import { CcgReferenceDataComponent } from './components/episodeGroupings/ccgReferenceDataList/ccgReferenceData.component';
import { PceMetaDataComponent } from './components/pceCCG/pceMetaDataList/pceMetaData.component';
import { PceMetaDataListComponent } from './components/pceCCG/pceMetaDataList/pceMetaDataList.component';
import { PceMetaDataEditComponent } from './components/pceCCG/pceMetaDataEdit/pceMetaDataEdit.component';
import { DeequTestResultComponent } from './components/deequTests/deequTestResultList/deequTestResult.component';
import { DeequTestResultListComponent } from './components/deequTests/deequTestResultList/deequTestResultList.component';
import { ClaraIqReleaseNotesComponent } from './components/claraIqReleaseNotes/claraIqReleaseNotes.component';


// STATE SECTION
export function returnTo($transition$: Transition): any {
    if ($transition$.redirectedFrom()) {
        // The user was redirected to the login state (e.g., via the requiresAuth hook when trying to activate contacts)
        // Return to the original attempted target state (e.g., contacts)
        return $transition$.redirectedFrom().targetState();
    }

    const $state = $transition$.router.stateService;
    // The user was not redirected to the login state; they directly activated the login state somehow.
    // Return them to the state they came from.
    if ($transition$.from().name !== '') {
        return $state.target($transition$.from(), $transition$.params('from'));
    }

    // If the fromState's name is empty, then this was the initial transition. Just return them to the home state
    return $state.target('login');
}

export const appState = {
    name: 'app',
    redirectTo: 'login',
    component: AppComponent,
};

export const contentState = {
    name: 'argos',
    url: '/argos',
    component: ContentComponent,
    data: {
        requiresAuth: true
    }
};

export const loginState = {
    name: 'login',
    url: '/login?saveState&saveParams',
    component: LoginComponent,
    data: {
        pageTitle: 'Login'
    },
    resolve: [
        { token: 'returnTo', deps: [Transition], resolveFn: returnTo },
    ]
};

export const callbackState = {
    name: 'callback',
    url: '/callback',
    component: CallbackComponent,
    data: {
        pageTitle: 'Loading...'
    }
};

export const error404State: Ng2StateDeclaration = {
    name: 'argos.404',
    url: '/404',
    component: Error404Component,
    data: {
        pageTitle: '404 Not Found',
        requiresAuth: true
    }
};

export const inactiveState: Ng2StateDeclaration = {
    name: 'argos.inactive',
    url: '/inactive',
    component: InactiveComponent,
    data: {
        pageTitle: 'Inactive Page',
        requiresAuth: true
    }
};

export const ccgAdminState: Ng2StateDeclaration = {
    name: 'argos.ccgAdmin',
    component: CareAdminComponent,
    data: {
        requiresAuth: true
    }
};

export const careGroupingsState: Ng2StateDeclaration = {
    name: 'argos.ccgAdmin.careGroupings',
    component: CareGroupingsComponent,
    data: {
        pageTitle: 'Care Groupings',
        requiresAuth: true,
    }
};

export const careGroupingsListState: Ng2StateDeclaration = {
    name: 'argos.ccgAdmin.careGroupings.list',
    url: '/care_groupings?queryString',
    component: CareGroupingsListComponent,
    data: {
        pageTitle: 'Care Groupings',
        requiresAuth: true,
        actions: {
            bulk_store_meta_data_allowed: false,
            mark_for_deletion_allowed: false,
            edit_allowed: false,
            copy_allowed: false,
        },
        requiredApiAccess: [
            { model: 'CareGroupings', access: ['read'] },
            { model: 'MarkForDeletion', access: ['read', 'create', 'update', 'destroyById'] },
            { model: 'MetaDataUsage', access: ['aggregateUsageResults'] },
            { model: 'MetaDataSync', access: ['findById'] }
        ]
    }
};

export const careGroupingsCopyState: Ng2StateDeclaration = {
    name: 'argos.ccgAdmin.careGroupings.copy',
    url: '/care_groupings/copy/:id',
    component: CareGroupingsEditComponent,
    data: {
        pageTitle: 'Copy Care Groupings',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'CareGroupingHistory', access: ['read', 'create', 'update'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'MetaDataSync', access: ['read'] },
            { model: 'CareGroupings', access: ['read', 'create', 'update'] }
        ]
    }
};

export const careGroupingsEditState: Ng2StateDeclaration = {
    name: 'argos.ccgAdmin.careGroupings.edit',
    url: '/care_groupings/edit/:id?showViewHistory',
    component: CareGroupingsEditComponent,
    data: {
        pageTitle: 'Edit Care Groupings',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'CareGroupingHistory', access: ['read', 'create', 'update'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'MetaDataSync', access: ['read'] },
            { model: 'CareGroupings', access: ['read', 'create', 'update'] }
        ]
    }
};

export const ccgState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner',
    component: CCGDesignerComponent,
    data: {
        requiresAuth: true
    }
};

export const networkDesignerState: Ng2StateDeclaration = {
    name: 'argos.networkDesigner',
    component: NetworkDesignerComponent,
    data: {
        requiresAuth: true
    }
};

export const ccgAttributedProviderCCGState: Ng2StateDeclaration = {
    name: 'argos.networkDesigner.attributedProviderCCG',
    component: CcgDefinitionRootComponent,
    data: {
        requiresAuth: true
    }
};

export const ccgDefinitionState: Ng2StateDeclaration = {
    name: 'argos.networkDesigner.attributedProviderCCG.definition',
    url: '/definition',
    component: CcgDefinitionComponent,
    data: {
        pageTitle: 'Clarify Attributed Provider CCG - Definition',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ClarifyAttributedProviderCCG', access: ['getData', 'save'] }
        ]
    }
};

export const ccgProviderTypesState: Ng2StateDeclaration = {
    name: 'argos.networkDesigner.attributedProviderCCG.providerTypes',
    url: '/provider_types',
    component: CcgProviderTypesComponent,
    data: {
        pageTitle: 'Clarify Attributed Provider CCG - Provider Types',
        requiresAuth: true,
        actions: {
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'ClarifyAttributedProviderProviderType', access: ['read', 'save'] }
        ]
    }
};

export const ccgSourceSchemasState: Ng2StateDeclaration = {
    name: 'argos.networkDesigner.attributedProviderCCG.sourceSchemas',
    url: '/source_schemas',
    component: CcgSourceSchemasComponent,
    data: {
        pageTitle: 'Clarify Attributed Provider CCG - Source Schemas',
        requiresAuth: true,
        actions: {
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'ClarifyAttributedProviderSourceSchema', access: ['read', 'save'] }
        ]
    }
};

export const environmentsState: Ng2StateDeclaration = {
    name: 'argos.environments',
    component: EnvironmentsRootComponent,
    data: {
        requiresAuth: true
    }
};

export const buildsState: Ng2StateDeclaration = {
    name: 'argos.environments.builds.list',
    url: '/builds',
    component: BuildsComponent,
    data: {
        pageTitle: 'Builds',
        requiresAuth: true
    }
};

export const buildsRootState: Ng2StateDeclaration = {
    name: 'argos.environments.builds',
    component: BuildsRootComponent
    ,
    data: {
        pageTitle: 'Builds',
        requiresAuth: true
    }
};

export const buildDetailState: Ng2StateDeclaration = {
    name: 'argos.environments.builds.detail',
    url: '/builds/:id',
    component: BuildDetailComponent,
    data: {
        pageTitle: 'Build Detail',
        requiresAuth: true
    }
};

export const columnUsageState: Ng2StateDeclaration = {
    name: 'argos.prism.columnUsage',
    url: '/column-usage',
    component: ColumnUsageComponent,
    data: {
        pageTitle: 'Column Usage',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: ['getEnvironments', 'getColumnUsage'] },
            { model: 'CareGrouping', access: ['read'] }
        ]
    }
};

export const compoundFiltersState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.compoundFilters',
    component: CompoundFiltersComponent,
    data: {
        pageTitle: 'Compound Filters',
        requiresAuth: true
    }
};

export const compoundFiltersListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.compoundFilters.list',
    url: '/compound_filters?queryString',
    component: CompoundFiltersListComponent,
    data: {
        pageTitle: 'Compound Filters',
        requiresAuth: true,
        actions: {
            add_allowed: false,
            edit_allowed: false,
            mark_for_deletion_allowed: false
        },
        requiredApiAccess: [
            { model: 'CompoundFilter', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'MetaDataUsage', access: ['aggregateUsageResults'] },
            { model: 'MarkForDeletion', access: ['read', 'create', 'update', 'destroyById'] }
        ]
    }
};

export const compoundFiltersAddEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.compoundFilters.edit',
    url: '/compound_filters/edit/:name',
    component: CompoundFiltersAddEditComponent,
    data: {
        pageTitle: 'Edit Compound Filters',
        requiresAuth: true,
        actions: {
            add_or_edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'CompoundFilter', access: ['read', 'create', 'update'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read'] }
        ]
    }
};

export const compoundFiltersAddState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.compoundFilters.add',
    url: '/compound_filters/add',
    component: CompoundFiltersAddEditComponent,
    data: {
        pageTitle: 'Add Compound Filters',
        requiresAuth: true,
        actions: {
            add_or_edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'CompoundFilter', access: ['read', 'create', 'update'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read'] }
        ]
    }
};

export const dashboardState: Ng2StateDeclaration = {
    name: 'argos.dashboard',
    url: '/dashboard',
    component: DashboardComponent,
    data: {
        pageTitle: 'Dashboard',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ViewDashboardStats', access: ['read'] },
            { model: 'Environment', access: ['read', 'getAppVersion'] },
            { model: 'Client', access: ['read', 'getAppVersion'] }
        ]
    }
};

export const ecsAccessState: Ng2StateDeclaration = {
    name: 'argos.ecsAccess',
    component: EcsAppComponent,
    data: {
        requiresAuth: true
    }
};

export const ecsAppListState: Ng2StateDeclaration = {
    name: 'argos.ecsAccess.ecsAppList',
    url: '/ecs-apps?accountname&queryString',
    component: EcsAppListComponent,
    data: {
        pageTitle: 'ECS App List',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: ['read', 'create', 'update',
                'getEcsClusters', 'getEcsBuilds', 'getEcsActiveTasks',
                'getAppVersion', 'createEcsTaskDefinition', 'runEcsTaskDefinition',
                'getEcsDeployStatus', 'getAppVersion', 'upsertElbResourceTags'] },
            { model: 'EnvironmentActivityLog', access: ['read', 'create'] },
            { model: 'NetworksStatus', access: ['getNetworkEnvironmentsStatus'] },
            { model: 'UpTimeApi', access: ['getAlarmChecks'] },
            { model: 'SalesforceAccount', access: ['read'] },
            { model: 'ClientCareGrouping', access: ['read'] },
            { model: 'EnvironmentManagement', access: ['read'] },
            { model: 'JiraApi', access: ['createIssue'] },
            { model: 'EnvironmentPatch', access: ['create', 'update'] },
            { model: 'Patch', access: ['read'] }
        ]
    }
};

export const ecsAppEditState: Ng2StateDeclaration = {
    name: 'argos.ecsAccess.ecsAppEdit',
    url: '/edit_ecs_app?id&accountname',
    component: EcsAppEditComponent,
    data: {
        pageTitle: 'ECS App Edit',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: ['getCloudWatchMetrics', 'getAwsVpcs', 'getEcsDebugInfo',
                'getTargetGroupArn', 'getScreenStatus', 'getEcsClusters',
                'getRdsSecretInfo', 'cloneArgosDbClone', 'getScreenStatus',
                'cloneEcsDb', 'getEcsMaintenanceMode', 'getEcsActiveTasks',
                'getCloudWatchAlarms', 'create', 'update',
                'updateAwsCanary', 'updateEcsMaintenanceMode', 'upsertEcsServiceAutoScaling',
                'upsertEcsServiceScalableTarget', 'upsertEcsServiceScalableSchedule',
                'deleteEcsServiceScalableSchedule', 'updateEcsServiceScheduleSuspendedState',
                'updateEcsInstanceType', 'createEcsTaskDefinition', 'runEcsTaskDefinition',
                'registerEcsTaskDef', 'upsertElbResourceTags', 'clearAppCache', 'retartEcsService',
                'retartEc2Instance', 'getEcsServiceInfo', 'updateEcsService', 'getEventBridgeSchedules',
                'deleteEventBridgeSchedule', 'createEventBridgeSchedule', 'updateAuth0SecretRotationStatus',
                'getEcsBuilds', 'getAppVersion', 'stopTask'
            ] },
            { model: 'EnvironmentRole', access: ['read'] },
            { model: 'EnvironmentManagement', access: ['read'] },
            { model: 'ArgosUser', access: ['getUsers'] },
            { model: 'SalesforceAccount', access: ['read'] },
            { model: 'UpTimeApi', access: ['getAlarmChecks', 'createAlarmHttp' ,'createAlarmTransaction',
                'updateAlarmPauseState', 'deleteAlarm', 'createAwsCanary'
            ] },
            { model: 'EnvironmentActivityLog', access: ['read', 'create'] },
            { model: 'ClientCareGrouping', access: ['read'] },
            { model: 'Patch', access: ['read', 'archivePatches', 'deleteUnAppliedPatches', 'deleteEnvPatchRecord'] }
        ]
    }
};

export const adminState: Ng2StateDeclaration = {
    name: 'argos.admin',
    component: EcsAdminComponent,
    data: {
        requiresAuth: true
    }
};

export const ecsActivityLogListState: Ng2StateDeclaration = {
    name: 'argos.admin.ecsActivityLogList',
    url: '/ecs-events?queryString',
    component: EcsActivityLogListComponent,
    data: {
        pageTitle: 'ECS Activity Log List',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'EnvironmentActivityLog', access: ['read'] }
        ]
    }
};

export const ecsDashboardState: Ng2StateDeclaration = {
    name: 'argos.admin.ecsDashboard',
    url: '/ecs-dashboard',
    component: EcsDashboardComponent,
    data: {
        pageTitle: 'ECS Dashboard',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Auth0Client', access: ['getApplication', 'disableConnectionApps',
                'getApplications', 'getConnections',
                'createApplication', 'create', 'update',
                'createUser'
            ] },
            { model: 'Environment', access: ['read', 'getAppVersion', 'getEnvironments',
                'createAuth0Secret'
            ] },
            { model: 'EnvironmentManagement', access: ['read', 'create', 'update'] },
            { model: 'EnvironmentActivityLog', access: ['read'] }
        ]
    }
};

export const ecsRepoEditState: Ng2StateDeclaration = {
    name: 'argos.ecsAccess.ecsRepoEdit',
    url: '/edit_ecs_repo',
    component: EcsRepoEditComponent,
    data: {
        pageTitle: 'ECS Repositories',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: [
                'pushSideBranchIntoRepo',
                'getEcrRepos',
                'getEcrRepoImages',
                'getScreenStatus'
            ] },
            { model: 'EnvironmentActivityLog', access: ['create'] }
        ]
    }
};

export const environmentRequestsRootState: Ng2StateDeclaration = {
    name: 'argos.environments.environmentRequests',
    component: EnvironmentRequestsRootComponent,
    data: {
        pageTitle: 'Environment Requests',
        requiresAuth: true
    }
};

export const environmentRequestsState: Ng2StateDeclaration = {
    name: 'argos.environments.environmentRequests.list',
    url: '/environment_requests',
    component: EnvironmentRequestsComponent,
    data: {
        pageTitle: 'Environment Requests',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'EnvironmentRequest', access: ['read'] },
            { model: 'Environment20Request', access: ['read'] }
        ]
    }
};

export const environment20RequestState: Ng2StateDeclaration = {
    name: 'argos.environments.environment20Requests',
    component: NewEnvironment20Component,
    data: {
        requiresAuth: true
    }
};

export const environment20RequestListState: Ng2StateDeclaration = {
    name: 'argos.environments.environment20Requests.list',
    url: '/environment2_0_requests',
    component: EnvironmentRequestsComponent,
    data: {
        pageTitle: 'Environment Requests',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'EnvironmentRequest', access: ['read'] },
            { model: 'Environment20Request', access: ['read'] }
        ]
    }
};

export const environment20RequestAddState: Ng2StateDeclaration = {
    name: 'argos.environments.environment20Requests.add',
    url: '/environment2_0_requests/add',
    component: NewEnvironment20RequestComponent,
    data: {
        pageTitle: 'New Environment 2.0 Request',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment20Request', access: ['read', 'create', 'update'] },
            { model: 'Client', access: ['read', 'create', 'update'] },
            { model: 'Auth0Client', access: ['read', 'create', 'update'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'Customer', access: ['read', 'create', 'update'] },
            { model: 'Environment', access: [
                'getEcsBuilds',
                'upsertEcsServiceScalableSchedule',
                'runEcsTaskDefinition',
                'getAwsVpcs',
                'createAuth0Secret',
                'uploadLogoImage',
                'createEcsPostgresDB',
                'createEcsAppRequest'
            ] },
            { model: 'ProductConfiguration', access: ['read'] },
            { model: 'ArgosUser', access: ['getActiveUsers'] },
            { model: 'UpTimeApi', access: ['createAlarmHttp', 'createAlarmTransaction'] },
            { model: 'EnvironmentBuildLog', access: ['create'] },
            { model: 'EnvironmentActivityLog', access: ['create'] },
            { model: 'Environment', access: ['create'] },
            { model: 'JiraApi', access: ['getUserId', 'createIssue'] }
        ]
    }
};

export const environment20RequestEditState: Ng2StateDeclaration = {
    name: 'argos.environments.environment20Requests.edit',
    url: '/environment2_0_requests/edit/:id',
    component: NewEnvironment20RequestComponent,
    data: {
        pageTitle: 'Environment 2.0 Request',
        requiresAuth: true
    }
};

export const newEnvironmentRequestState: Ng2StateDeclaration = {
    name: 'argos.environments.environmentRequests.add',
    url: '/add',
    component: NewEnvironmentRequestComponent,
    data: {
        pageTitle: 'New Environment Request',
        requiresAuth: true
    }
};

export const environmentState: Ng2StateDeclaration = {
    name: 'argos.environments.environments',
    component: EnvironmentsComponent,
    data: {
        pageTitle: 'Environments',
        requiresAuth: true
    }
};

export const environmentListState: Ng2StateDeclaration = {
    name: 'argos.environments.environments.list',
    url: '/environments',
    component: EnvironmentsListComponent,
    data: {
        pageTitle: 'Environments',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClientProductConfiguration', access: ['read'] },
            { model: 'ClientCareGrouping', access: ['read'] },
            { model: 'Environment', access: ['getAppVersion', 'getEnvironments'] }
        ]
    }
};

export const clientCareGroupingsState: Ng2StateDeclaration = {
    name: 'argos.environments.clientCareGroupings',
    component: ClientCareGroupingsComponent,
    data: {
        pageTitle: 'Care Grouping Usage',
        requiresAuth: true
    }
};

export const clientCareGroupingsListState: Ng2StateDeclaration = {
    name: 'argos.environments.clientCareGroupings.list',
    url: '/client-care-groupings',
    component: ClientCareGroupingsListComponent,
    data: {
        pageTitle: 'Care Grouping Usage',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClientCareGrouping', access: ['getClientCareGroupings'] }
        ]
    }
};

export const episodeGroupingAddtState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.episodeGroupings.add',
    url: '/episode_groupings/add',
    component: EpisodeGroupingEditComponent,
    data: {
        pageTitle: 'Add Groupings/Filters',
        requiresAuth: true,
        actions: {
            edit_allowed: false,
        },
        requiredApiAccess: [
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read', 'create', 'update'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'TablePartitionMapping', access: ['read'] },
            { model: 'ValidValuesQuery', access: ['read', 'create', 'update'] },
            { model: 'EpisodeGrouping', access: ['read', 'create', 'update', 'generateValidValues', 'testSql'] },
            { model: 'EpisodeGroupingHistories', access: ['read'] },
            { model: 'EpisodeGroupingHistory', access: ['read', 'create', 'update'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'EpisodeGroupingEntityHistory', access: ['read', 'create', 'update'] }
        ]
    }
};

export const episodeGroupingEdittState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.episodeGroupings.edit',
    url: '/episode_groupings/edit/:id?showViewHistory',
    component: EpisodeGroupingEditComponent,
    data: {
        pageTitle: 'Edit Groupings/Filters',
        requiresAuth: true,
        actions: {
            edit_allowed: false,
        },
        requiredApiAccess: [
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read', 'create', 'update'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'TablePartitionMapping', access: ['read'] },
            { model: 'ValidValuesQuery', access: ['read', 'create', 'update'] },
            { model: 'EpisodeGrouping', access: ['read', 'create', 'update', 'generateValidValues', 'testSql'] },
            { model: 'EpisodeGroupingHistories', access: ['read'] },
            { model: 'EpisodeGroupingHistory', access: ['read', 'create', 'update'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'EpisodeGroupingEntityHistory', access: ['read', 'create', 'update'] }
        ]
    }
};

export const episodeGroupingCopytState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.episodeGroupings.copy',
    url: '/episode_groupings/copy/:id',
    component: EpisodeGroupingEditComponent,
    data: {
        pageTitle: 'Copy Groupings/Filters',
        requiresAuth: true,
        actions: {
            edit_allowed: false,
        },
        requiredApiAccess: [
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read', 'create', 'update'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'TablePartitionMapping', access: ['read'] },
            { model: 'ValidValuesQuery', access: ['read', 'create', 'update'] },
            { model: 'EpisodeGrouping', access: ['read', 'create', 'update', 'generateValidValues', 'testSql'] },
            { model: 'EpisodeGroupingHistories', access: ['read'] },
            { model: 'EpisodeGroupingHistory', access: ['read', 'create', 'update'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'EpisodeGroupingEntityHistory', access: ['read', 'create', 'update'] }
        ]
    }
};

export const episodeGroupingImportState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.episodeGroupings.import',
    url: '/import',
    component: EpisodeGroupingImportComponent,
    data: {
        pageTitle: 'Groupings/Filters Import',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read', 'create'] },
            { model: 'EpisodeGroupingEntity', access: ['read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'EpisodeGroupingHistories', access: ['read'] },
            { model: 'EpisodeGroupingHistory', access: ['create', 'update'] }
        ]
    }
};

export const episodeGroupingListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.episodeGroupings.list',
    url: '/episode_groupings?queryString',
    component: EpisodeGroupingListComponent,
    data: {
        pageTitle: 'Groupings/Filters',
        requiresAuth: true,
        actions: {
            import_allowed: false,
            add_allowed: false,
            copy_allowed: false,
            mark_for_deletion_allowed: false,
            delete_allowed: false,
        },
        requiredApiAccess: [
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read'] },
            { model: 'ViewEpisodeGroupingHistoryLatest', access: ['read'] },
            { model: 'MetaDataUsage', access: ['aggregateUsageResults'] },
            { model: 'MarkForDeletion', access: ['read', 'create', 'update', 'destroyById'] }
        ]
    }
};

export const episodeGroupingState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.episodeGroupings',
    component: EpisodeGroupingComponent,
    data: {
        pageTitle: 'Groupings/Filters',
        requiresAuth: true
    }
};

export const joinEntitiesState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.joinEntities',
    component: JoinEntitiesComponent,
    data: {
        pageTitle: 'Join Definitions',
        requiresAuth: true
    }
};

export const joinEntitiesListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.joinEntities.list',
    url: '/join_entities?queryString&?openModal',
    component: JoinEntitiesListComponent,
    data: {
        pageTitle: 'Join Definitions',
        requiresAuth: true,
        actions: {
            add_or_edit_allowed: false,
            mark_for_deletion_allowed: false
        },
        requiredApiAccess: [
            { model: 'EpisodeGroupingEntity', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'MarkForDeletion', access: ['read', 'create', 'update', 'destroyById'] }
        ]
    }
};

export const validValueQueriesState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.validValueQueries',
    component: ValidValueQueriesComponent,
    data: {
        pageTitle: 'Valid Value Queries',
        requiresAuth: true
    }
};

export const validValueQueriesListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.validValueQueries.list',
    url: '/valid_value_queries?queryString&?openModal',
    component: ValidValueQueriesListComponent,
    data: {
        pageTitle: 'Valid Value Queries',
        requiresAuth: true,
        actions: {
            add_or_edit_allowed: false,
            mark_for_deletion_allowed: false
        },
        requiredApiAccess: [
            { model: 'ValidValuesQuery', access: ['read'] }
        ]
    }
};

export const markedForDeletionState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.markedForDeletion',
    component: MarkedForDeletionComponent,
    data: {
        pageTitle: 'Marked for Deletion',
        requiresAuth: true
    }
};

export const markedForDeletionListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.markedForDeletion.list',
    url: '/marked_for_deletion?queryString',
    component: MarkedForDeletionListComponent,
    data: {
        pageTitle: 'Marked for Deletion',
        requiresAuth: true,
        actions: {
            view_deletions_allowed: false,
            undelete_allowed: false
        },
        requiredApiAccess: [
            { model: 'OtherMetric', access: ['read'] },
            { model: 'MarkForDeletion', access: ['read', 'destroyById'] }
        ]
    }
};

export const clarifyReportsState: Ng2StateDeclaration = {
    name: 'argos.clarifyReports',
    component: ClarifyReportsComponent,
    data: {
        requiresAuth: true
    }
};

export const clarifyReportsListState: Ng2StateDeclaration = {
    name: 'argos.clarifyReports.list',
    url: '/clarify_reports',
    component: ClarifyReportsListComponent,
    data: {
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyReport', access: ['read', 'getClarifyReportUrl'] },
            { model: 'PipelineHistory', access: ['read'] },
            { model: 'ClarifyReportAudit', access: ['create', 'update'] }
        ]
    }
};

export const referenceDataState: Ng2StateDeclaration = {
    name: 'argos.referenceData',
    component: ReferenceDataComponent,
    data: {
        requiresAuth: true
    }
};

export const teraUsersState: Ng2StateDeclaration = {
    name: 'argos.referenceData.teraUsers',
    component: TeraUsersComponent,
    data: {
        requiresAuth: true
    }
};

export const teraUsersListState: Ng2StateDeclaration = {
    name: 'argos.referenceData.teraUsers.list',
    url: '/tera_users',
    component: TeraUsersListComponent,
    data: {
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'TeraUser', access: ['read'] }
        ]
    }
};

export const modelMetaDataState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData',
    component: ModelMetaDataComponent,
    data: {
        requiresAuth: true
    }
};

export const networksStatusState: Ng2StateDeclaration = {
    name: 'argos.networkDesigner.networksStatus',
    url: '/network-statistics',
    component: NetworksStatusComponent,
    data: {
        pageTitle: 'Model Meta Data Patient Year List',
        requiresAuth: true,
        actions: {
            execute_allowed: false
        },
        requiredApiAccess: [
            { model: 'NetworksStatus', access: [
                'read',
                'getNetworkEnvironmentsStatus',
                'getNetworkEnvironmentEcsInfo',
                'upgradeAllNPOEnvironments',
                'upgradeNPOEnvironment',
                'resizeMachine',
                'resizeAllMachines',
                'getRunningNPOTaskId',
                'setMaxWorkers']
            },
            { model: 'Environment', access: ['getEcsServiceInfo', 'getEcsServiceInfo', 'stopTask'] }
        ]
    }
};

export const observationExceptionState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationException',
    component: ObservationExceptionComponent,
    data: {
        pageTitle: 'Observation Exceptions',
        requiresAuth: true
    }
};

export const observationExceptionListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationException.list',
    url: '/observationException/list?queryString',
    component: ObservationExceptionListComponent,
    data: {
        pageTitle: 'Observation Exceptions',
        requiresAuth: true,
        actions: {
            add_allowed: false,
            edit_allowed: false,
            copy_allowed: false
        },
        requiredApiAccess: [
            { model: 'DataSource', access: ['read'] },
            { model: 'ObservationException', access: ['read'] }
        ]
    }
};

export const observationExceptionAddState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationException.add',
    url: '/observationException/list/add',
    component: ObservationExceptionAddEditComponent,
    data: {
        pageTitle: 'Add Observation Exceptions',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ObservationException', access: ['read', 'create', 'update'] },
            { model: 'DataSource', access: ['read'] }
        ]
    }
};

export const observationExceptionEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationException.edit',
    url: '/observationException/list/edit/:id',
    component: ObservationExceptionAddEditComponent,
    data: {
        pageTitle: 'Edit Observation Exceptions',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ObservationException', access: ['read', 'create', 'update'] },
            { model: 'DataSource', access: ['read'] }
        ]
    }
};

export const allowedFieldAddState: Ng2StateDeclaration = {
    name: 'argos.ccgAdmin.observationGroupAllowedFields.add',
    url: '/observation_group_allowed_fields/add',
    component: AllowedFieldEditComponent,
    data: {
        pageTitle: 'Observation Group Allowed Field',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ObservationGroupAllowedField', access: ['create', 'update'] },
            { model: 'ObservationGroup', access: ['read'] },
            { model: 'ListItem', access: ['read'] },
            { model: 'PrismNotification', access: ['read'] }
        ]
    }
};

export const allowedFieldEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationException.view',
    url: '/observation_group_allowed_fields/view/:id',
    component: AllowedFieldEditComponent,
    data: {
        pageTitle: 'Observation Group Allowed Field',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ObservationGroup', access: ['read'] },
            { model: 'ListItem', access: ['read'] },
            { model: 'PrismNotification', access: ['read'] }
        ]
    }
};

export const allowedFieldState: Ng2StateDeclaration = {
    name: 'argos.ccgAdmin.observationGroupAllowedFields',
    component: AllowedFieldComponent,
    data: {
        pageTitle: 'Observation Group Allowed Fields',
        requiresAuth: true
    }
};

export const allowedFieldListState: Ng2StateDeclaration = {
    name: 'argos.ccgAdmin.observationGroupAllowedFields.list',
    url: '/observation_group_allowed_fields',
    component: AllowedFieldListComponent,
    data: {
        pageTitle: 'Observation Group Allowed Fields',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ObservationGroupAllowedField', access: ['read'] },
            { model: 'ObservationGroup', access: ['read'] },
            { model: 'FieldListItem', access: ['read'] },
            { model: 'ObservationSelectionFields', access: ['read'] },
            { model: 'ObservationKeyFields', access: ['read'] }
        ]
    }
};

export const ccgReportListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.ccgReportList',
    url: '/ccg-reports',
    component: CcgReportListComponent,
    data: {
        pageTitle: 'CCG Reports',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'CcgReport', access: ['getReport'] }
        ]
    }
};

export const hierarchyEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationHierarchies.edit',
    url: '/observation_hierarchies/edit/:id',
    component: HierarchyEditComponent,
    data: {
        pageTitle: 'Edit Hierarchy',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ObservationCategoryHierarchy', access: ['read', 'create', 'update'] },
        ]
    }
};

export const hierarchyAddState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationHierarchies.add',
    url: '/observation_hierarchies/add',
    component: HierarchyEditComponent,
    data: {
        pageTitle: 'Add Hierarchy',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ObservationCategoryHierarchy', access: ['read', 'create', 'update'] },
        ]
    }
};

export const hierarchyState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationHierarchies',
    component: HierarchyComponent,
    data: {
        pageTitle: 'Hierarchies',
        requiresAuth: true
    }
};

export const hierarchyListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observationHierarchies.list',
    url: '/observation_hierarchies',
    component: HierarchyListComponent,
    data: {
        pageTitle: 'Hierarchies',
        requiresAuth: true,
        actions: {
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'ObservationCategoryHierarchy', access: ['read'] }
        ]
    }
};

export const observationEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observations.edit',
    url: '/observations/edit/:id?showViewHistory',
    component: ObservationEditComponent,
    data: {
        pageTitle: 'Edit Observation',
        requiresAuth: true,
        actions: {
            lock_allowed: false,
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ObservationGroup', access: ['read'] },
            { model: 'ObservationType', access: ['read'] },
            { model: 'ObservationEpisodeSubset', access: ['read'] },
            { model: 'ObservationGroupObservationType', access: ['read'] },
            { model: 'ReferenceDataListTag', access: ['read'] },
            { model: 'ReferenceDataList', access: ['read'] },
            { model: 'ViewReferenceDataListParentTag', access: ['read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'Observation', access: ['read', 'getObsDep', 'deleteObservation', 'create', 'update', 'getMetricSql', 'getAllMetricSql', 'getSql'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'ObservationInterval', access: ['read'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'ListItem', access: ['read'] },
            { model: 'Teradrome', access: ['openFreeformSQLPR', 'saveObservationHistory'] },
            { model: 'ObservationHistory', access: ['read'] },
            { model: 'ProgramEpisode', access: ['read'] },
            { model: 'ObservationFilterPredicate', access: ['destroyById', 'create', 'update'] },
            { model: 'ObservationSelectionField', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationKeyField', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ProgramEpisodeObservation', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationFilterPredicateOr', access: ['read', 'create', 'update'] },
            { model: 'ProgramModelCompatibleObservationGroup', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationGroupAllowedField', access: ['read'] }
        ]
    }
};

export const observationCopyState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observations.copy',
    url: '/observations/copy/:id',
    component: ObservationEditComponent,
    data: {
        pageTitle: 'Copy Observation',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ObservationGroup', access: ['read'] },
            { model: 'ObservationType', access: ['read'] },
            { model: 'ObservationEpisodeSubset', access: ['read'] },
            { model: 'ObservationGroupObservationType', access: ['read'] },
            { model: 'ReferenceDataListTag', access: ['read'] },
            { model: 'ReferenceDataList', access: ['read'] },
            { model: 'ViewReferenceDataListParentTag', access: ['read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'Observation', access: ['read', 'getObsDep', 'deleteObservation', 'create', 'update', 'getMetricSql', 'getAllMetricSql', 'getSql'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'ObservationInterval', access: ['read'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'ListItem', access: ['read'] },
            { model: 'Teradrome', access: ['openFreeformSQLPR', 'saveObservationHistory'] },
            { model: 'ObservationHistory', access: ['read'] },
            { model: 'ProgramEpisode', access: ['read'] },
            { model: 'ObservationFilterPredicate', access: ['destroyById', 'create', 'update'] },
            { model: 'ObservationSelectionField', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationKeyField', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ProgramEpisodeObservation', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationFilterPredicateOr', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ProgramModelCompatibleObservationGroup', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationGroupAllowedField', access: ['read'] }
        ]
    }
};

export const observationNewState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observations.add',
    url: '/observations/add',
    component: ObservationEditComponent,
    data: {
        pageTitle: 'Add Observation',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ObservationGroup', access: ['read'] },
            { model: 'ObservationType', access: ['read'] },
            { model: 'ObservationEpisodeSubset', access: ['read'] },
            { model: 'ObservationGroupObservationType', access: ['read'] },
            { model: 'ReferenceDataListTag', access: ['read'] },
            { model: 'ReferenceDataList', access: ['read'] },
            { model: 'ViewReferenceDataListParentTag', access: ['read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'Observation', access: ['read', 'getObsDep', 'deleteObservation', 'create', 'update', 'getMetricSql', 'getAllMetricSql', 'getSql'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'ObservationInterval', access: ['read'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'ListItem', access: ['read'] },
            { model: 'Teradrome', access: ['openFreeformSQLPR', 'saveObservationHistory'] },
            { model: 'ObservationHistory', access: ['read'] },
            { model: 'ProgramEpisode', access: ['read'] },
            { model: 'ObservationFilterPredicate', access: ['destroyById', 'create', 'update'] },
            { model: 'ObservationSelectionField', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationKeyField', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ProgramEpisodeObservation', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationFilterPredicateOr', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ProgramModelCompatibleObservationGroup', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'ObservationGroupAllowedField', access: ['read'] }
        ]
    }
};

export const observationState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observations',
    component: ObservationComponent,
    data: {
        pageTitle: 'Observations',
        requiresAuth: true
    }
};

export const observationListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.observations.list',
    url: '/observations?queryString',
    component: ObservationListComponent,
    data: {
        pageTitle: 'Observations',
        requiresAuth: true,
        actions: {
            add_allowed: false,
            copy_allowed: false,
            more_drop_down_allowed: false
        },
        requiredApiAccess: [
            { model: 'ProductBundle', access: ['read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'Observation', access: ['read', 'getMetricSql', 'getAllMetricSql', 'getSql'] },
            { model: 'ObservationGroup', access: ['read'] },
            { model: 'ObservationType', access: ['read'] },
            { model: 'ObservationEpisodeSubset', access: ['read'] },
            { model: 'ObservationInterval', access: ['read'] },
            { model: 'LatestObservationHistory', access: ['read'] },
            { model: 'Teradrome', access: ['getObservationTestUrl'] },
            { model: 'MetaDataUsage', access: ['aggregateUsageResults'] }
        ]
    }
};

export const modelFeaturesState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.modelFeatures',
    component: ModelFeaturesComponent,
    data: {
        pageTitle: 'Model Features',
        requiresAuth: true
    }
};

export const modelFeaturesListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.modelFeatures.list',
    url: '/model_features?queryString',
    component: ModelFeaturesListComponent,
    data: {
        pageTitle: 'Model Features',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ModelFeature', access: ['read', 'create', 'update'] }
        ]
    }
};

export const pipelineDataState: Ng2StateDeclaration = {
    name: 'argos.data',
    component: PipelineDataComponent,
    data: {
        requiresAuth: true
    }
};

export const pipelineDataSourceState: Ng2StateDeclaration = {
    name: 'argos.data.datasource',
    component: PipelineDataSourceComponent,
    data: {
        pageTitle: 'Pipeline Datasources',
        requiresAuth: true
    }
};

export const pipelineDataSourceListState: Ng2StateDeclaration = {
    name: 'argos.data.datasource.list',
    url: '/datasource',
    component: PipelineDataSourceListComponent,
    data: {
        pageTitle: 'Pipeline Datasources',
        requiresAuth: true,
        actions: {
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'DataSource', access: ['read'] }
        ]
    }
};

export const pipelineDataSourceEditState: Ng2StateDeclaration = {
    name: 'argos.data.datasource.edit',
    url: '/datasource/edit/:id',
    component: PipelineDataSourceEditComponent,
    data: {
        pageTitle: 'Edit Data source',
        requiresAuth: true,
        actions: {
            save_allowed: false
        },
        requiredApiAccess: [
            { model: 'DataSource', access: ['read', 'create', 'update'] }
        ]
    }
};

export const pipelineDataSourceAddState: Ng2StateDeclaration = {
    name: 'argos.data.datasource.add',
    url: '/datasource/add',
    component: PipelineDataSourceEditComponent,
    data: {
        pageTitle: 'Add Data source',
        requiresAuth: true,
        actions: {
            save_allowed: false
        },
        requiredApiAccess: [
            { model: 'DataSource', access: ['read', 'create', 'update'] }
        ]
    }
};

export const pipelineHistoryRootState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_history',
    component: PipelineHistoryRootComponent,
    data: {
        pageTitle: 'Pipeline History',
        requiresAuth: true
    }
};

export const pipelineHistoryState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_history.list',
    url: '/pipelineHistory/list?queryString',
    component: PipelineHistoryComponent,
    data: {
        pageTitle: 'Pipeline History',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'PipelineHistory', access: ['getPipelineHistory'] }
        ]
    }
};

export const pipelineErrorMapRootState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_error_map',
    component: PipelineErrorMapRootComponent,
    data: {
        pageTitle: 'EMR Error Mapping',
        requiresAuth: true
    }
};

export const pipelineErrorMapState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_error_map.list',
    url: '/pipelineErrorMap/list',
    component: PipelineErrorMapComponent,
    data: {
        pageTitle: 'EMR Error Mapping',
        requiresAuth: true,
        actions: {
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'PipelineErrorMap', access: ['read', 'create'] }
        ]
    }
};

export const pipelineHistoryDetailRootState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_history_details',
    component: PipelineHistoryDetailRootComponent,
    data: {
        pageTitle: 'Pipeline Details',
        requiresAuth: true
    },
    params: {
        currentTimeFrameSelection: null,
        currentPipelineTypeSelection: null
    }
};

export const pipelineHistoryDetailState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_history.detail',
    url: '/pipelineHistoryDetail/:stageKey/:dsKey/:pipelineIds',
    component: PipelineHistoryDetailComponent,
    data: {
        pageTitle: 'Pipeline Details',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'PipelineStep', access: ['read'] },
            { model: 'PipelineHistory', access: ['read'] }
        ]
    },
    params: {
        currentTimeFrameSelection: null,
        currentPipelineTypeSelection: null
    }
};

export const pipelineHistoryDetailStepsState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_history.steps',
    url: '/pipelineStepDetail/:stageKey/:dsKey/:pipelineId',
    component: PipelineStepDetailComponent,
    data: {
        pageTitle: 'Pipeline History',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'PipelineStep', access: ['read'] },
            { model: 'PipelineHistory', access: ['read'] }
        ]
    },
    params: {
        pipelineIds: null,
        previousTimeFrameSelection: null,
        previousPipelineTypeSelection: null
    }
};

export const pipelineQueueRootState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_queue',
    component: PipelineQueueRootComponent,
    data: {
        pageTitle: 'Pipeline Queue',
        requiresAuth: true
    }
};


export const pipelineQueueState: Ng2StateDeclaration = {
    name: 'argos.data.pipeline_queue.list',
    url: '/pipelineQueue',
    component: PipelineQueueComponent,
    data: {
        pageTitle: 'Pipeline Queue',
        requiresAuth: true,
        actions: {
            add_allowed: false,
            save_allowed: false
        },
        requiredApiAccess: [
            { model: 'PipelineQueueDetail', access: ['read', 'create', 'update', 'delete'] },
            { model: 'PipelineQueueAudit', access: ['read', 'create'] },
            { model: 'Astronomer', access: ['getDagsByPattern'] }
        ]
    }
};

export const dataProvisioningQuestionnaireState: Ng2StateDeclaration = {
    name: 'argos.data.dataProvisioningQuestionnaire',
    url: '/data-provisioning-questionnaires',
    component: DataProvisioningQuestionnaireComponent,
    data: {
        pageTitle: 'Data Provisioning Questionnaires',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'DataProvisioningQuestionnaire', access: ['getResponses'] }
        ]
    }
};


export const prism1MetricEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.prism1Metrics.edit',
    url: '/prism1_metrics/edit/:id?showViewHistory',
    component: Prism1MetricEditComponent,
    data: {
        pageTitle: 'Edit Prism 1.0 Metrics',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'MetricHistory', access: ['create', 'update', 'read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'Observation', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read'] },
            { model: 'OtherMetric', access: ['read', 'create', 'update', 'testSql'] },
            { model: 'Observation', access: ['read'] }
        ]
    }
};

export const prism1MetricAddState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.prism1Metrics.add',
    url: '/prism1_metrics/add',
    component: Prism1MetricEditComponent,
    data: {
        pageTitle: 'Add Prism 1.0 Metrics',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'MetricHistory', access: ['create', 'update', 'read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'Observation', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read'] },
            { model: 'OtherMetric', access: ['read', 'create', 'update', 'testSql'] },
            { model: 'Observation', access: ['read'] }
        ]
    }
};

export const prism1MetricCopyState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.prism1Metrics.copy',
    url: '/prism1_metrics/copy/:id',
    component: Prism1MetricEditComponent,
    data: {
        pageTitle: 'Copy Prism 1.0 Metrics',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'MetricHistory', access: ['create', 'update', 'read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'Observation', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read'] },
            { model: 'OtherMetric', access: ['read', 'create', 'update', 'testSql'] },
            { model: 'Observation', access: ['read'] }
        ]
    }
};

export const prism1MetricImportState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.prism1Metrics.import',
    url: '/prism1_metrics/import',
    component: Prism1MetricImportComponent,
    data: {
        pageTitle: 'Import Prism 1.0 Metrics',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'OtherMetric', access: ['read', 'create', 'update', 'testSql'] },
            { model: 'MetricHistory', access: ['read', 'create', 'update'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'ObservationCategoryHierarchy', access: ['read'] },
            { model: 'Observation', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read'] }
        ]
    }
};

export const prism1MetricState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.prism1Metrics',
    component: Prism1MetricComponent,
    data: {
        pageTitle: 'Prism 1.0 Metrics',
        requiresAuth: true
    }
};

export const prism1MetricListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.prism1Metrics.list',
    url: '/prism1_metrics?queryString',
    component: Prism1MetricListComponent,
    data: {
        pageTitle: 'Prism 1.0 Metrics',
        requiresAuth: true,
        actions: {
            add_allowed: false,
            copy_allowed: false,
            import_allowed: false,
            mark_for_deletion_allowed: false
        },
        requiredApiAccess: [
            { model: 'ProductBundle', access: ['read'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'ViewOtherMetricHistoryLatest', access: ['read'] },
            { model: 'MetaDataUsage', access: ['aggregateUsageResults'] },
            { model: 'MarkForDeletion', access: ['read', 'create', 'update', 'destroyById'] }
        ]
    }
};

export const prismState: Ng2StateDeclaration = {
    name: 'argos.prism',
    component: PrismNotificationsRootComponent,
    data: {
        requiresAuth: true
    }
};

export const prismNotificationsState: Ng2StateDeclaration = {
    name: 'argos.prism.prism_notifications',
    url: '/prism_notification',
    component: PrismNotificationsComponent,
    data: {
        pageTitle: 'Prism Error Notification',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'PrismNotification', access: ['read', 'create', 'update'] },
            { model: 'Client', access: ['read'] }
        ]
    }
};

export const prismUsageState: Ng2StateDeclaration = {
    name: 'argos.prism.prismUsage',
    url: '/prism-usage',
    component: PrismUsageComponent,
    data: {
        pageTitle: 'Prism Usage',
        requiresAuth: true,
        actions: {
            sync_usage_allowed: false
        },
        requiredApiAccess: [
            { model: 'Observation', access: ['read'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'Environment', access: ['getEnvironments'] },
            { model: 'MetaDataUsage', access: ['getUsageTypes', 'aggregateUsageResults', 'initializeMetaDataUsageSync'] }
        ]
    }
};

export const atlasClaraChatHistoryState: Ng2StateDeclaration = {
    name: 'argos.prism.atlasClaraChatHistory',
    url: '/atlas_clara_chat_history',
    component: AtlasClaraChatHistoryComponent,
    data: {
        pageTitle: 'Atlas ClaraIQ Chat History',
        requiresAuth: true,
        actions: {
            sync_history_allowed: false
        },
        requiredApiAccess: [
            { model: 'ClaraChatHistory', access: ['getQuestionsOverview', 'getQuestionHistory', 'initializeClaraChatHistorySync'] }
        ]
    }
};

export const productState: Ng2StateDeclaration = {
    name: 'argos.prism.product',
    component: ProductComponent,
    data: {
        pageTitle: 'Products',
        requiresAuth: true
    }
};

export const productListState: Ng2StateDeclaration = {
    name: 'argos.prism.product.list',
    url: '/products/list',
    component: ProductListComponent,
    data: {
        pageTitle: 'Products',
        requiresAuth: true,
        actions: {
            add_allowed: false,
            copy_allowed: false,
            edit_allowed: false,
            used_by_allowed: false,
        },
        requiredApiAccess: [
            { model: 'ProductConfiguration', access: ['read'] },
            { model: 'ClientProductConfiguration', access: ['read'] },
            { model: 'Environment', access: ['read'] }
        ]
    }
};

// id: copy
export const productAddState: Ng2StateDeclaration = {
    name: 'argos.prism.product.add',
    url: '/products/list/add/',
    component: ProductAddEditComponent,
    data: {
        pageTitle: 'Add Products',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ProductConfiguration', access: ['read', 'create', 'update'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'ProductConfigurationContent', access: ['read'] }
        ]
    }
};

export const productEditState: Ng2StateDeclaration = {
    name: 'argos.prism.product.edit',
    url: '/products/list/edit/:id',
    component: ProductAddEditComponent,
    data: {
        pageTitle: 'Edit Products',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ProductConfiguration', access: ['read', 'create', 'update'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'ProductBundle', access: ['read'] },
            { model: 'ProductConfigurationContent', access: ['read'] }
        ]
    }
};

export const productBundlesState: Ng2StateDeclaration = {
    name: 'argos.prism.productBundles',
    component: ProductBundlesComponent,
    data: {
        pageTitle: 'Product Bundles',
        requiresAuth: true
    }
};

export const productBundlesListState: Ng2StateDeclaration = {
    name: 'argos.prism.productBundles.list',
    url: '/product_bundles?queryString',
    component: ProductBundlesListComponent,
    data: {
        pageTitle: 'Product Bundles',
        requiresAuth: true,
        actions: {
            add_allowed: false,
            edit_allowed: false,
            used_by_allowed: false
        },
        requiredApiAccess: [
            { model: 'ProductBundle', access: ['read', 'create', 'update'] },
            { model: 'ProductConfiguration', access: ['read'] },
            { model: 'ClientProductConfiguration', access: ['read'] },
            { model: 'Environment', access: ['read'] }
        ]
    }
};

export const ccgFeatureEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.ccgfeatures.edit',
    url: '/ccgfeatures/edit/:id',
    component: CcgFeatureEditComponent,
    data: {
        pageTitle: 'Edit CCG Feature',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'CcgFeatureDataSourceLimit', access: ['read', 'create', 'update', 'destroyById'] },
            { model: 'CcgFeature', access: ['read'] },
            { model: 'ProgramEpisode', access: ['read'] },
            { model: 'DataSource', access: ['read'] },
            { model: 'ProgramEpisodeCcgFeature', access: ['read', 'create', 'update', 'destroyById'] },
            { model: 'CcgFeature', access: ['read', 'create', 'update', 'destroyById'] }
        ]
    }
};

export const ccgFeatureState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.ccgfeatures',
    component: CcgFeatureComponent,
    data: {
        pageTitle: 'CCG Features',
        requiresAuth: true
    }
};

export const ccgFeatureListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.ccgfeatures.list',
    url: '/ccgfeatures',
    component: CcgFeatureListComponent,
    data: {
        pageTitle: 'CCG Features',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'CcgFeature', access: ['read'] }
        ]
    }
};

export const patchManagementState: Ng2StateDeclaration = {
    name: 'argos.prism.patch_management',
    component: PatchManagementComponent,
    data: {
        pageTitle: 'Patch Management',
        requiresAuth: true
    }
};

export const patchManagementListState: Ng2StateDeclaration = {
    name: 'argos.prism.patch_management.list',
    url: '/patch_management/list',
    component: PatchManagementListComponent,
    data: {
        pageTitle: 'Patch Management List',
        requiresAuth: true,
        actions: {
            add_allowed: false,
            delete_allowed: false,
            edit_allowed: false,
            preview_allowed: false,
        },
        requiredApiAccess: [
            { model: 'Patch', access: ['read', 'delete'] },
            { model: 'Environments', access: ['read'] }
        ]
    }
};

export const patchManagementAddState: Ng2StateDeclaration = {
    name: 'argos.prism.patch_management.add',
    url: '/patch_management/add',
    component: PatchManagementAddEditModalComponent,
    data: {
        pageTitle: 'Patch Management Add',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Patch', access: ['read', 'create', 'update', 'initializeSearchGitTagAndDiff'] },
            { model: 'Environment', access: ['getAppVersion', 'getEcsBuilds'] }
        ]
    }
};

export const patchManagementEditState: Ng2StateDeclaration = {
    name: 'argos.prism.patch_management.edit',
    url: '/patch_management/edit/:id',
    component: PatchManagementAddEditModalComponent,
    data: {
        pageTitle: 'Patch Management Edit',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Patch', access: ['read', 'create', 'update', 'initializeSearchGitTagAndDiff'] },
            { model: 'Environment', access: ['getAppVersion', 'getEcsBuilds'] }
        ]
    }
};

export const patchManagementPreviewState: Ng2StateDeclaration = {
    name: 'argos.prism.patch_management.preview',
    url: '/patch_management/preview/:id',
    component: PatchManagementPreviewModalComponent,
    data: {
        pageTitle: 'Patch Management Preview',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: ['read'] }
        ]
    }
};

export const automatedGoldPullDownState: Ng2StateDeclaration = {
    name: 'argos.prism.automatedGoldPulldown',
    component: AutomatedGoldPulldownComponent,
    data: {
        pageTitle: 'Automated Gold PullDown',
        requiresAuth: true
    }
};

export const automatedGoldPullDownListState: Ng2StateDeclaration = {
    name: 'argos.prism.automatedGoldPulldown.list',
    url: '/automatedGoldPulldown/list',
    component: AutomatedGoldPulldownListComponent,
    data: {
        pageTitle: 'Automated Gold Pulldown List',
        requiresAuth: true,
        actions: {
            add_allowed: true,
            edit_allowed: true
        },
        requiredApiAccess: [
            { model: 'AutomatedPulldown', access: ['read', 'create', 'update',
                'pushAutomatedPulldownBranchToAtlas', 'checkAllBranchesStatus',
                'fetchManualBranchFileStatus', 'getExtractedFiles',
                'closeAndDeleteBranch', 'deleteBranchRow', 'getScreenStatus', 'getGithubBranches']
            }
        ]
    }
};

export const programEpisodeEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.programepisodes.edit',
    url: '/programepisodes/edit/:id',
    component: ProgramEpisodeEditComponent,
    data: {
        pageTitle: 'Edit Program Episode',
        requiresAuth: true,
        actions: {
            edit_allowed: false,
            clone_allowed: false
        },
        requiredApiAccess: [
            { model: 'ProgramEpisode', access: ['read', 'create', 'update'] },
            { model: 'ProgramSetting', access: ['read', 'create', 'update', 'createMany', 'delete'] },
            { model: 'ProgramModel', access: ['read'] },
            { model: 'ProgramEpisodeObservations', access: ['read'] },
            { model: 'ProgramSettings', access: ['read'] },
            { model: 'CcgType', access: ['read'] },
            { model: 'ListItem', access: ['read'] },
            { model: 'ReferenceDataListTag', access: ['read'] },
            { model: 'ReferenceDataList', access: ['read'] },
            { model: 'ProgramEpisodeObservation', access: ['createMany'] }
        ]
    }
};

export const programEpisodeState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.programepisodes',
    component: ProgramEpisodeComponent,
    data: {
        pageTitle: 'Program Episodes',
        requiresAuth: true
    }
};

export const programEpisodeListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.programepisodes.list',
    url: '/programepisodes',
    component: ProgramEpisodeListComponent,
    data: {
        pageTitle: 'Program Episodes',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ProgramEpisode', access: ['read'] },
            { model: 'ProgramModel', access: ['read'] },
            { model: 'ProgramEpisodeObservation', access: ['count'] }
        ]
    }
};

export const programModelState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.programmodels',
    component: ProgramModelComponent,
    data: {
        pageTitle: 'Program Models',
        requiresAuth: true
    }
};

export const programModelListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.programmodels.list',
    url: '/programmodels',
    component: ProgramModelListComponent,
    data: {
        pageTitle: 'Program Models',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ProgramModel', access: ['read'] },
            { model: 'CcgType', access: ['read'] },
            { model: 'ProgramEpisode', access: ['count'] }


        ]
    }
};

export const programModelEditState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.programmodels.edit',
    url: '/programmodels/edit/:id',
    component: ProgramModelEditComponent,
    data: {
        pageTitle: 'Edit Program Models',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ProgramModel', access: ['read', 'create', 'update'] },
            { model: 'ProgramSetting', access: ['read', 'create', 'update'] },
            { model: 'ProgramModel', access: ['read'] },
            { model: 'ProgramSettings', access: ['read'] },
            { model: 'CcgType', access: ['read'] },
            { model: 'ReferenceDataListTag', access: ['read'] },
            { model: 'ReferenceDataList', access: ['read'] },
            { model: 'ViewReferenceDataListParentTags', access: ['read'] },
            { model: 'ListItem', access: ['read'] }
        ]
    }
};
export const versionHistoryState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.versionhistory',
    component: VersionHistoryComponent,
    data: {
        pageTitle: 'Version History',
        requiresAuth: true
    }
};
export const versionHistoryListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.versionhistory.list',
    url: '/versionhistory',
    component: VersionHistoryListComponent,
    data: {
        pageTitle: 'Version History',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'MetaDataUsage', access: ['getAllMetdataVersionHistory'] },
            { model: 'OtherMetric', access: ['read'] },
            { model: 'Observation', access: ['read'] },
            { model: 'CareGrouping', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['read'] },
            { model: 'EpisodeGroupingEntity', access: ['read'] }
        ]
    }
};
export const editReleaseNotesState: Ng2StateDeclaration = {
    name: 'argos.edit_release',
    url: '/publish_release/edit_releases/:id',
    component: EditReleaseNotesComponent,
    data: {
        pageTitle: 'Edit Release Notes',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ReleaseNote', access: ['read', 'create', 'update', 'delete'] },
            { model: 'Release', access: ['read', 'create', 'update', 'delete', 'getReleaseNotes'] },
        ]
    }
};

export const previewReleaseNotesState: Ng2StateDeclaration = {
    name: 'argos.preview_release',
    url: '/preview_releases/:id',
    component: PreviewReleaseNotesComponent,
    data: {
        pageTitle: 'Preview Release Notes',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Release', access: ['read'] },
            { model: 'ReleaseNote', access: ['read'] }
        ]
    }
};

export const publishReleaseNotesState: Ng2StateDeclaration = {
    name: 'argos.publish_release',
    url: '/publish_release',
    component: PublishReleaseNotesComponent,
    data: {
        pageTitle: 'Publish Release Notes',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ReleaseNote', access: ['create', 'update'] },
            { model: 'Release', access: ['read', 'create', 'update', 'getReleaseNotes'] }
        ]
    }
};

export const releasesState: Ng2StateDeclaration = {
    name: 'argos.prism.releases',
    url: '/releases',
    component: ReleasesComponent,
    data: {
        pageTitle: 'Releases',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Release', access: ['read', 'destroyById'] }
        ]
    }
};

export const queryPerformanceState: Ng2StateDeclaration = {
    name: 'argos.environments.queryPerformance',
    url: '/query-performance',
    component: QueryPerformanceComponent,
    data: {
        pageTitle: 'Query Performance',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: ['getEnvironments', 'getQueryPerformance'] }
        ]
    }
};

export const filterPerformanceState: Ng2StateDeclaration = {
    name: 'argos.environments.filterPerformance',
    url: '/filter-performance',
    component: FilterPerformanceComponent,
    data: {
        pageTitle: 'Filter Performance',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'EpisodeGrouping', access: ['read'] },
            { model: 'Environment', access: ['getEnvironments', 'getFilterPerformance'] }
        ]
    }
};

export const athenaUsageState: Ng2StateDeclaration = {
    name: 'argos.environments.athenaUsage',
    url: '/athena_usage',
    component: AthenaUsageComponent,
    data: {
        pageTitle: 'Athena Usage',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: ['getEnvironments', 'getAppVersion'] }
        ]
    }
};

export const querySettingsState: Ng2StateDeclaration = {
    name: 'argos.environments.querySettings',
    url: '/query-settings',
    component: QuerySettingsComponent,
    data: {
        pageTitle: 'Query Settings',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: ['read', 'getQuerySettings'] }
        ]
    }
};

export const redshiftClustersState: Ng2StateDeclaration = {
    name: 'argos.admin.redshiftClusters',
    url: '/redshift-clusters',
    component: RedshiftClustersComponent,
    data: {
        pageTitle: 'Redshift Clusters',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'RedshiftClusterInfo', access: ['read'] }
        ]
    }
};

export const referenceDataSourcesEditState: Ng2StateDeclaration = {
    name: 'argos.referenceData.referenceDataSources.edit',
    url: '/ref_data_sources/edit/:id',
    component: ReferenceDataSourcesEditComponent,
    data: {
        pageTitle: 'Edit Reference Data',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ReferenceDataClass', access: ['read', 'create', 'update', 'delete'] },
            { model: 'TeradromeTable', access: ['create', 'update', 'deleteAll'] },
            { model: 'ReferenceData', access: ['read', 'create', 'update', 'delete', 'getDagConfigs'] },
            { model: 'ReferenceDataHistory', access: ['create', 'update', 'read'] },
            { model: 'TeradromeColumn', access: ['read', 'create', 'update'] },
            { model: 'TeradromeDeequTest', access: ['read'] },
            { model: 'TeraUser', access: ['create', 'update', 'read'] },
            { model: 'Teradrome', access: ['postClarifyLlmAPI'] },
            { model: 'Astronomer', access: ['triggerDag', 'getDagsUrl', 'getAverageDagRuntime'] }
        ]
    }
};

export const referenceDataSourcesAddState: Ng2StateDeclaration = {
    name: 'argos.referenceData.referenceDataSources.add',
    url: '/ref_data_sources/add',
    component: ReferenceDataSourcesEditComponent,
    data: {
        pageTitle: 'Add Reference Data',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'ReferenceDataClass', access: ['read', 'create', 'update', 'delete'] },
            { model: 'TeradromeTable', access: ['create', 'update'] },
            { model: 'ReferenceData', access: ['read', 'create', 'update', 'delete'] },
            { model: 'ReferenceDataHistory', access: ['create', 'update'] },
            { model: 'TeradromeColumn', access: ['read', 'create', 'update'] },
            { model: 'TeraUser', access: ['read'] },
            { model: 'TeradromeDeequTest', access: ['read'] },
            { model: 'ReferenceDataHistories', access: ['read'] }
        ]
    }
};

export const referenceDataSourcesState: Ng2StateDeclaration = {
    name: 'argos.referenceData.referenceDataSources',
    component: ReferenceDataSourcesComponent,
    data: {
        pageTitle: 'Reference Data',
        requiresAuth: true
    }
};

export const referenceDataSourcesListState: Ng2StateDeclaration = {
    name: 'argos.referenceData.referenceDataSources.list',
    url: '/ref_data_sources',
    component: ReferenceDataSourcesListComponent,
    data: {
        pageTitle: 'Reference Data',
        requiresAuth: true,
        actions: {
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'ReferenceData', access: ['read', 'getDiffReportUrl'] },
            { model: 'ReferenceDataClass', access: ['read'] },
            { model: 'TeraUser', access: ['read'] },
            { model: 'TableUsage', access: ['getTableUsage']},
            { model: 'Teradrome', access: ['findTextInGitRepo']},
            { model: 'ReferenceData', access: ['getDagConfigs'] },
        ]
    }
};

export const clarifyProviderRevisionEditState: Ng2StateDeclaration = {
    name: 'argos.referenceData.clarifyProviderRevision.edit',
    url: '/clarify_provider_revisions/edit/:id',
    component: ClarifyProviderRevisionEditComponent,
    data: {
        pageTitle: 'Edit Clarify Provider Revision Data',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'DataSource', access: ['read'] },
            { model: 'ClarifyProviderNpiRevision', access: ['read', 'create', 'update', 'delete', 'fetchNpiAttributed'] },
            { model: 'ClarifyProviderAttributedParentIdRevision', access: ['read', 'create', 'update', 'delete', 'fetchNpiAttributed'] },
            { model: 'ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision', access: ['read', 'create', 'update', 'delete', 'fetchNpiAttributed'] }
        ]
    },
    params: {
        npiAttributes: null,
        currentRevisionGrainSelection: null
    }
};

export const clarifyProviderRevisionNewState: Ng2StateDeclaration = {
    name: 'argos.referenceData.clarifyProviderRevision.add',
    url: '/clarify_provider_revisions/add',
    component: ClarifyProviderRevisionEditComponent,
    data: {
        pageTitle: 'Add Clarify Provider Revision Data',
        requiresAuth: true,
        actions: {
            edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'DataSource', access: ['read'] },
            { model: 'ClarifyProviderNpiRevision', access: ['read', 'create', 'update', 'delete', 'fetchProviderNpiAttributed'] },
            { model: 'ClarifyProviderAttributedParentIdRevision', access: ['read', 'create', 'update', 'delete', 'fetchProviderAttributedParentIdAttributed'] },
            { model: 'ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision', access: ['read', 'create', 'update', 'delete', 'fetchProviderPrimaryPhysicianGroupAffiliationNpiAttributed'] }
        ]
    },
    params: {
        npiAttributes: null,
        currentRevisionGrainSelection: null
    }
};

export const clarifyProviderRevisionState: Ng2StateDeclaration = {
    name: 'argos.referenceData.clarifyProviderRevision',
    component: ClarifyProviderRevisionComponent,
    data: {
        pageTitle: 'Clarify Provider Revision Data',
        requiresAuth: true
    }
};

export const clarifyProviderRevisionListState: Ng2StateDeclaration = {
    name: 'argos.referenceData.clarifyProviderRevision.list',
    url: '/clarify_provider_revisions',
    component: ClarifyProviderRevisionListComponent,
    data: {
        pageTitle: 'Reference Data',
        requiresAuth: true,
        actions: {
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'ClarifyProviderNpiRevision', access: ['read'] },
            { model: 'ClarifyProviderAttributedParentIdRevision', access: ['read'] },
            { model: 'ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision', access: ['read'] }
        ]
    },
    params: {
        currentRevisionGrainSelection: null
    }
};

export const dataUnitTestResultState: Ng2StateDeclaration = {
    name: 'argos.data.deequUnitTestResult',
    component: DeequTestResultComponent,
    data: {
        pageTitle: 'Data Unit Test Results',
        requiresAuth: true
    }
};

export const dataUnitTestResultListState: Ng2StateDeclaration = {
    name: 'argos.data.deequUnitTestResult.list',
    url: '/data_unit_test_result?database&tableName&runId',
    component: DeequTestResultListComponent,
    data: {
        pageTitle: 'Data Unit Test Results',
        requiresAuth: true,
        actions: {
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'DataUnitTestResult', access: ['read'] }
        ]
    }
};


export const referenceListItemsState: Ng2StateDeclaration = {
    name: 'argos.referenceData.referenceLists',
    component: ReferenceListItemsComponent,
    data: {
        pageTitle: 'Reference Lists',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ViewReferenceDataListSummary', access: ['read'] }
        ]
    }
};

export const referenceListItemsListState: Ng2StateDeclaration = {
    name: 'argos.referenceData.referenceLists.list',
    url: '/reference_lists?queryString',
    component: ReferenceListItemsListComponent,
    data: {
        pageTitle: 'Reference Lists',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ViewReferenceDataListSummary', access: ['read'] }
        ]
    }
};

export const referenceListItemsEditState: Ng2StateDeclaration = {
    name: 'argos.referenceData.referenceLists.edit',
    url: '/reference_lists/edit/:id',
    component: ReferenceListItemEditComponent,
    data: {
        pageTitle: 'Edit Reference Lists',
        requiresAuth: true,
        actions: {
            add_tag_and_items_allowed: false
        },
        requiredApiAccess: [
            { model: 'ReferenceDataListTag', access: ['read', 'create', 'update', 'deleteById'] },
            { model: 'ReferenceDataListItemTag', access: ['read', 'create', 'update', 'createMany', 'deleteAll', 'deleteById'] },
            { model: 'ReferenceDataListItem', access: ['read', 'create', 'update', 'createMany'] },
            { model: 'ViewReferenceDataListSummary', access: ['read'] },
            { model: 'ObservationFilterPredicate', access: ['read'] },
            { model: 'Observation', access: ['read'] },
            { model: 'Teradrome', access: ['saveReferenceListHistory'] },
            { model: 'ViewReferenceDataListItemParentTag', access: ['read'] },
            { model: 'ViewReferenceDataListParentTag', access: ['read'] },
            { model: 'ViewReferenceDataListTag', access: ['read'] },
            { model: 'ReferenceData', access: ['read'] },
            { model: 'ReferenceDataListHistory', access: ['read', 'create', 'update'] },
            { model: 'ViewReferenceDataListItemParentTags', access: ['read'] },
            { model: 'Astronomer', access: ['triggerDag'] }
        ]
    }
};

export const releaseNotesState: Ng2StateDeclaration = {
    name: 'argos.releaseNotes',
    url: '/release-notes',
    component: ReleaseNotesComponent,
    data: {
        pageTitle: 'Release Notes',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Environment', access: ['getEnvironments', 'getReleaseNotes'] }
        ]
    }
};

export const salesforceSyncStatusState: Ng2StateDeclaration = {
    name: 'argos.environments.salesforce_sync_status',
    url: '/salesforce_sync_status',
    component: SalesforceSyncStatusComponent,
    data: {
        pageTitle: 'Salesforce Sync Status',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'SalesforceSyncLog', access: ['read', 'startContactSync', 'startAccountSync'] }
        ]
    }
};

export const productConfigurationContentSyncStatusComponent: Ng2StateDeclaration = {
    name: 'argos.prism.product.sync',
    url: '/product_configuration_content_sync',
    component: ProductConfigurationContentSyncStatusComponent,
    data: {
        pageTitle: 'Product Configuration Content Sync',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ProductConfigurationSyncLog', access: ['read', 'startSync'] }
        ]
    }
};

export const terraTestResultEditState: Ng2StateDeclaration = {
    name: 'argos.data.terratestresults.edit',
    url: '/terraTests/edit/:id',
    component: TerraTestResultEditComponent,
    data: {
        pageTitle: 'Terra Test Result',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'TerraTestResult', access: ['read'] }
        ]
    }
};

export const terraTestResultsRootState: Ng2StateDeclaration = {
    name: 'argos.data.terratestresults',
    component: TerraTestResultsRootComponent,
    data: {
        pageTitle: 'Terra Test Results',
        requiresAuth: true
    }
};

export const terraTestResultsState: Ng2StateDeclaration = {
    name: 'argos.data.terratestresults.list',
    url: '/terraTests',
    component: TerraTestResultsComponent,
    data: {
        pageTitle: 'Terra Test Results',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'TerraTestResult', access: ['read'] }
        ]
    }
};

export const userListState: Ng2StateDeclaration = {
    name: 'argos.admin.users',
    url: '/users?queryString',
    component: UserListComponent,
    data: {
        pageTitle: 'Users',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ArgosUser', access: ['read', 'create', 'update'] },
            { model: 'ArgosUserHistory', access: ['create', 'update'] },
            { model: 'ArgosUserRole', access: ['read'] }
        ]
    }
};

export const userEngagementState: Ng2StateDeclaration = {
    name: 'argos.admin.userEngagement',
    url: '/user_engagement?queryString',
    component: UserEngagementComponent,
    data: {
        pageTitle: 'User Engagement',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ArgosUserActivity', access: ['read'] },
            { model: 'UserErrorLog', access: ['read'] }
        ]
    }
};

export const userRoleState: Ng2StateDeclaration = {
    name: 'argos.admin.userRoles',
    component: UserRoleComponent,
    data: {
        pageTitle: 'User Roles',
        requiresAuth: true
    }
};

export const userRoleListState: Ng2StateDeclaration = {
    name: 'argos.admin.userRoles.list',
    url: '/user_roles',
    component: UserRoleListComponent,
    data: {
        pageTitle: 'User Roles',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ArgosUserRole', access: ['read'] },
            { model: 'ArgosUser', access: ['read', 'create', 'update'] }
        ]
    }
};

export const userRoleEditState: Ng2StateDeclaration = {
    name: 'argos.admin.userRoles.edit',
    url: '/user_roles/edit/:id',
    component: UserRoleEditComponent,
    data: {
        pageTitle: 'Edit User Role',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ArgosUserRole', access: ['read', 'create', 'update'] },
            { model: 'ArgosUserRoleHistories', access: ['read', 'create', 'update'] },
            { model: 'ArgosUserRoleHistory', access: ['read', 'create', 'update'] }
        ]
    }
};

export const userRoleAddState: Ng2StateDeclaration = {
    name: 'argos.admin.userRoles.add',
    url: '/user_roles/add',
    component: UserRoleEditComponent,
    data: {
        pageTitle: 'Add User Role',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ArgosUserRole', access: ['read', 'create', 'update'] },
            { model: 'ArgosUserRoleHistories', access: ['read', 'create', 'update'] },
            { model: 'ArgosUserRoleHistory', access: ['read', 'create', 'update'] }
        ]
    }
};

export const marketShareMetaState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.marketShareMeta',
    component: MarketShareMetaComponent,
    data: {
        pageTitle: 'UsersMarket Share Metadata',
        requiresAuth: true
    }
};

export const marketShareMetaListState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.marketShareMeta.list',
    url: '/marketShareMeta',
    component: MarketShareMetaListComponent,
    data: {
        pageTitle: 'UsersMarket Share Metadata',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'MarketShareSourceTableMetadata', access: ['read'] },
            { model: 'ReferralPatientJourneyMarketShareColumn', access: ['read', 'create', 'update', 'deleteAll'] }
        ]
    }
};

export const marketShareMetaEditState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.marketShareMeta.edit',
    url: '/marketShareMeta/edit/:id',
    component: MarketShareMetaEditComponent,
    data: {
        pageTitle: 'Edit UsersMarket Share Metadata',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'MarketShareSourceTableMetadata', access: ['read', 'create', 'update'] }
        ]
    }
};

export const clientState: Ng2StateDeclaration = {
    name: 'argos.environments.clients',
    component: ClientComponent,
    data: {
        pageTitle: 'Client',
        requiresAuth: true
    }
};

export const clientListState: Ng2StateDeclaration = {
    name: 'argos.environments.clients.list',
    url: '/clients',
    component: ClientListComponent,
    data: {
        pageTitle: 'Client',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Client', access: ['environments'] }
        ]
    }
};

export const clientEditState: Ng2StateDeclaration = {
    name: 'argos.environments.clients.edit.environment',
    url: '/clients/edit/:id/environment/:environmentId',
    component: ClientEditComponent,
    data: {
        pageTitle: 'Edit Client Environment',
        requiresAuth: true,
        actions: {
            edit_allowed: false,
            add_care_grouping_allowed: false,
            add_edit_care_grouping_allowed: false,
            mark_for_deletion_allowed: false,
            edit_ecs_configurations_allowed: false,
            delete_uptime_allowed: false
        },
        requiredApiAccess: [
            { model: 'Client', access: ['read', 'create', 'update'] },
            { model: 'SalesforceAccount', access: ['read'] },
            { model: 'DataSource', access: ['read'] },
            { model: 'ProductConfiguration', access: ['read'] },
            { model: 'ArgosUser', access: ['getActiveUsers'] },
            { model: 'Environments', access: ['read'] },
            { model: 'Products', access: ['read'] },
            { model: 'ClientProductConfiguration', access: ['read', 'create', 'update', 'createMany', 'destroyById'] },
            { model: 'ClientProduct', access: ['read'] },
            { model: 'ClientDataSource', access: ['read', 'create', 'update', 'destroyById'] },
            // environment edit
            { model: 'Auth0Client', access: ['read'] },
            { model: 'ClientCareGrouping', access: ['read', 'create', 'update', 'validateCareGroupingOverrides', 'validateTableExistence'] },
            { model: 'DatabaseServer', access: ['read'] },
            { model: 'Environment', access: ['read', 'create', 'update', 'getEnvironment', 'destroyById'] },
            { model: 'MetaDataUsage', access: ['getEnvironmentUsageDetails'] },
            { model: 'UpTimeApi', access: ['getAlarmChecks'] },
            { model: 'ClientCareGroupingHistory', access: ['read', 'create', 'update'] },
            { model: 'CareGrouping', access: ['read', 'create', 'update'] }
        ]
    }
};

export const clientEnvironmentsEditState: Ng2StateDeclaration = {
    name: 'argos.environments.clients.edit',
    url: '/clients/edit/:id',
    component: ClientEditComponent,
    data: {
        pageTitle: 'Edit Client',
        requiresAuth: true,
        actions: {
            edit_allowed: false,
            add_allowed: false
        },
        requiredApiAccess: [
            { model: 'Client', access: ['read', 'create', 'update'] },
            { model: 'SalesforceAccount', access: ['read'] },
            { model: 'DataSource', access: ['read'] },
            { model: 'ProductConfiguration', access: ['read'] },
            { model: 'ArgosUser', access: ['getActiveUsers'] },
            { model: 'Environments', access: ['read'] },
            { model: 'Products', access: ['read'] },
            { model: 'ClientProductConfiguration', access: ['read', 'create', 'update', 'createMany', 'destroyById'] },
            { model: 'ClientProduct', access: ['read'] },
            { model: 'ClientDataSource', access: ['read', 'create', 'update', 'destroyById'] },
            // environment edit
            { model: 'Auth0Client', access: ['read'] },
            { model: 'ClientCareGrouping', access: ['read', 'create', 'update'] },
            { model: 'DatabaseServer', access: ['read'] },
            { model: 'Environment', access: ['read', 'create', 'update', 'getEnvironment', 'destroyById'] },
            { model: 'MetaDataUsage', access: ['getEnvironmentUsageDetails'] },
            { model: 'UpTimeApi', access: ['getAlarmChecks'] }
        ]
    }
};

export const clientAddState: Ng2StateDeclaration = {
    name: 'argos.environments.clients.add',
    url: '/clients/add',
    component: ClientEditComponent,
    data: {
        pageTitle: 'Add Client',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Client', access: ['read', 'create', 'update'] },
            { model: 'SalesforceAccount', access: ['read'] },
            { model: 'DataSource', access: ['read'] },
            { model: 'ProductConfiguration', access: ['read'] },
            { model: 'ArgosUser', access: ['getActiveUsers'] },
            { model: 'Environments', access: ['read'] },
            { model: 'Products', access: ['read'] },
            { model: 'ClientProductConfiguration', access: ['read', 'create', 'update', 'createMany', 'destroyById'] },
            { model: 'ClientProduct', access: ['read'] },
            { model: 'ClientDataSource', access: ['read', 'create', 'update', 'destroyById'] },
            // environment edit
            { model: 'Auth0Client', access: ['read'] },
            { model: 'ClientCareGrouping', access: ['read', 'create', 'update'] },
            { model: 'DatabaseServer', access: ['read'] },
            { model: 'Environment', access: ['read', 'create', 'update', 'getEnvironment', 'destroyById'] },
            { model: 'MetaDataUsage', access: ['getEnvironmentUsageDetails'] },
            { model: 'UpTimeApi', access: ['getAlarmChecks'] }
        ]
    }
};

export const payerRatesMetaState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.payerRatesMeta',
    component: PayerRatesMetaComponent,
    data: {
        pageTitle: 'Payer Rates Metadata',
        requiresAuth: true
    }
};

export const payerRatesMetaListState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.payerRatesMeta.list',
    url: '/payerRatesMeta',
    component: PayerRatesMetaListComponent,
    data: {
        pageTitle: 'Payer Rates Metadata',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'PayerRateMeta', access: ['read'] },
            { model: 'ClarifyBot', access: ['get', 'post'] }
        ]
    }
};

export const payerRatesMetaEditState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.payerRatesMeta.edit',
    url: '/payerRatesMeta/edit/:id',
    component: PayerRatesMetaEditComponent,
    data: {
        pageTitle: 'Edit Payer Rates Metadata',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'PayerRateMeta', access: ['read', 'create', 'update'] },
            { model: 'PayerRateMetaHistory', access: ['read', 'create', 'update'] },
            { model: 'PayerRateMetaHistories', access: ['read'] },
            { model: 'PipelineHistory', access: ['read'] }
        ]
    }
};

export const payerRatesMetaNewState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.payerRatesMeta.add',
    url: '/payerRatesMeta/add',
    component: PayerRatesMetaEditComponent,
    data: {
        pageTitle: 'Add Payer Rates Metadata',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'PayerRateMeta', access: ['read', 'create', 'update'] },
            { model: 'PayerRateMetaHistory', access: ['read', 'create', 'update'] },
            { model: 'PayerRateMetaHistories', access: ['read'] },
            { model: 'PipelineHistory', access: ['read'] }
        ]
    }
};

export const qaTestResultsRootState: Ng2StateDeclaration = {
    name: 'argos.data.pctTestResults',
    component: QATestResultsRootComponent,
    data: {
        pageTitle: 'QA Test Results',
        requiresAuth: true
    }
};

export const qaTestResultsState: Ng2StateDeclaration = {
    name: 'argos.data.pctTestResults.list',
    url: '/platformReleaseTestResults',
    component: QATestResultsComponent,
    data: {
        pageTitle: 'Atlas Release Test Results',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ProductionConfigurationTestResults', access: ['read'] },
            { model: 'PerformanceTestResults', access: ['read'] },
            { model: 'ApiPerformanceTestResults', access: ['read'] },
            { model: 'CypressTestResults', access: ['read'] }
        ]
    }
};

export const qaTestResultProductDetailsState: Ng2StateDeclaration = {
    name: 'argos.data.pctTestResults.productdetails',
    url: '/platformReleaseTestResults/productdetails/:id',
    component: QATestResultsProductDetailComponent,
    data: {
        pageTitle: 'Product Detail Atlas Release Test Details',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ProductionConfigurationTestResults', access: ['read'] }
        ]
    }
};

export const qaTestResultSubmoduleDetailsState: Ng2StateDeclaration = {
    name: 'argos.data.pctTestResults.submoduledetails',
    url: '/platformReleaseTestResults/productdetails/:id/:submoduleid/:customer',
    component: QATestResultsSubmoduleDetailComponent,
    data: {
        pageTitle: 'Submodule Detail Atlas Release Test Details',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ProductionConfigurationTestResults', access: ['read'] }
        ]
    }
};

export const qaTestResultTabAndTileDetailsState: Ng2StateDeclaration = {
    name: 'argos.data.pctTestResults.tabandtiledetails',
    url: '/platformReleaseTestResults/productdetails/:id/:submoduleid/:customer/:dashboard',
    component: QATestResultsTabAndTileDetailComponent,
    data: {
        pageTitle: 'Dashboard Tabs and Tiles Details',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ProductionConfigurationTestResults', access: ['read'] }
        ]
    }
};

export const performanceTestResultDetailsState: Ng2StateDeclaration = {
    name: 'argos.data.pctTestResults.performanceTestDetails',
    url: '/platformReleaseTestResults/performance/:id?:module',
    component: PerformanceTestResultsDashboardProductsDetail,
    data: {
        pageTitle: 'Atlas Performance Test Details',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'PerformanceTestResults', access: ['read'] }
        ]
    }
};

export const apiPerformanceTestResultDetailsState: Ng2StateDeclaration = {
    name: 'argos.data.pctTestResults.apiPerformanceTestDetails',
    url: '/platformReleaseTestResults/apiperformance/:id?:module',
    component: ApiPerformanceTestResultsDashboardProductsDetail,
    data: {
        pageTitle: 'Atlas API Performance Test Details',
        requiresAuth: true
    }
};

export const cypressFeatureTestResultsDetailState: Ng2StateDeclaration = {
    name: 'argos.data.pctTestResults.cypressTestDetail',
    url: '/platformReleaseTestResults/cypress/:id',
    component: CypressFeatureTestResultDetail,
    data: {
        pageTitle: 'Atlas Cypress Test Feature Details',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'CypressTestResults', access: ['read'] }
        ]
    }
};

export const clarifyInsightsLandingPageState: Ng2StateDeclaration = {
    name: 'argos.clarifyInsights',
    url: '/clarifyInsights',
    component: ClarifyInsightsLandingPageComponent,
    data: {
        pageTitle: 'Clarify Insights',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Astronomer', access: ['getDagsUrl'] },
            { model: 'ClarifyInsightsCustomer', access: ['read', 'create', 'update', 'delete', 'provisionAccount', 'getAllCustomersDistinct'] },
            { model: 'ArgosUser', access: ['getCurrentUser'] },
            { model: 'JiraApi', access: ['getUserId', 'createIssue', 'getIssueStatus', 'addComment', 'transitionIssue'] },
            { model: 'DataSource', access: ['read'] },
            { model: 'ClarifyInsightsFilter', access: ['read', 'delete'] },
            { model: 'ClarifyInsightsTransferHistory', access: ['read'] },
            { model: 'ClarifyInsightsExtractHistory', access: ['read'] }
        ]
    }
};

export const clarifyInsightFilterState: Ng2StateDeclaration = {
    name: 'argos.clarifyInsightsFilter',
    component: ClarifyInsightsFilterComponent,
    data: {
        pageTitle: 'Insight Customer Filters',
        requiresAuth: true
    }
};

export const clarifyInsightFilterListState: Ng2StateDeclaration = {
    name: 'argos.clarifyInsightsFilter.list',
    url: '/clarifyInsightsFilter/list?queryString',
    component: ClarifyInsightsFilterListComponent,
    data: {
        pageTitle: 'Insight Customer Filters',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyInsightsFilter', access: ['read'] },
            { model: 'ClarifyInsightsCustomer', access: ['read', 'create'] },
            { model: 'JiraApi', access: ['getUserId', 'createIssue'] },
            { model: 'ArgosUser', access: ['getCurrentUser'] },
            { model: 'ClarifyInsightsTransferHistory', access: ['read'] }
        ]
    }
};

export const clarifyInsightFilterEditState: Ng2StateDeclaration = {
    name: 'argos.clarifyInsightsFilter.edit',
    url: '/clarifyInsightsFilter/edit/:id',
    component: ClarifyInsightsFilterEditComponent,
    data: {
        pageTitle: 'Edit Insight Customer Filters',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyInsightsCustomer', access: ['read'] },
            { model: 'ReferenceDataList', access: ['read'] },
            { model: 'ReferenceDataListItems', access: ['read'] },
            { model: 'ReferenceDataListTags', access: ['read'] },
            { model: 'ViewReferenceDataListItemParentTags', access: ['read'] },
            { model: 'ClarifyInsightsFilter', access: ['read', 'create', 'update'] }
        ]
    }
};

export const clarifyInsightFilterNewState: Ng2StateDeclaration = {
    name: 'argos.clarifyInsightsFilter.add',
    url: '/clarifyInsightsFilter/edit/add',
    component: ClarifyInsightsFilterEditComponent,
    data: {
        pageTitle: 'Add Insight Customer Filters',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyInsightsCustomer', access: ['read'] },
            { model: 'ReferenceDataListItems', access: ['read'] },
            { model: 'ReferenceDataList', access: ['read'] },
            { model: 'ReferenceDataListTags', access: ['read'] },
            { model: 'ViewReferenceDataListItemParentTags', access: ['read'] },
            { model: 'ClarifyInsightsFilter', access: ['read', 'create', 'update'] }
        ]
    }
};

export const clarifyInsightFilterHistoryState: Ng2StateDeclaration = {
    name: 'argos.clarifyInsightsFilter.history',
    url: '/clarifyInsightsFilter/history/:id/:dataSource/:extractType/:filterName',
    component: ClarifyInsightsFilterHistoryComponent,
    data: {
        pageTitle: 'Insight Customer Filters History',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyInsightsFilter', access: ['read'] }
        ]
    }
};

export const clarifyInsightsDataEvaluationUserState: Ng2StateDeclaration = {
    name: 'argos.clarifyInsightsDataEvaluationUser',
    url: '/clarifyInsightsDataEvaluationUser',
    component: ClarifyInsightsDataEvaluationUserComponent,
    data: {
        pageTitle: 'Clarify Insights Data Evaluation Users',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyInsightsDataEvaluationUser', access: ['read', 'getUsersAndRoles', 'editUser', 'create', 'update', 'createUser'] },
        ]
    }
};

export const clarifyInsightsDeliveryHubState: Ng2StateDeclaration = {
    name: 'argos.clarifyInsightsDeliveryHub',
    url: '/clarifyInsightsDeliveryHub',
    component: ClarifyInsightsDeliveryHubComponent,
    data: {
        pageTitle: 'Clarify Insights Delivery Hub',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyInsightsCustomer', access: ['read', 'getDestinationBucketByCustomerAndExtractType'] },
            { model: 'ClarifyInsightsFilter', access: ['read'] },
            { model: 'Astronomer', access: ['getDagsUrl', 'getDagDocMd', 'getDagStatus'] },
            { model: 'ClarifyInsightsTransferHistory', access: ['read', 'triggerDag', 'create'] },
            { model: 'ClarifyInsightsExtractHistory', access: ['read','create', 'update', 'getRunFilterVersion', 'getExtractRunOutputPath'] },
            { model: 'ClarifyInsightsDataDelivery', access: ['read', 'create', 'getExtractRunId', 'triggerDag', 'update', 'getPresignedS3Url'] },
        ]
    }
};

export const dataDashboardRootState: Ng2StateDeclaration = {
    name: 'argos.data.data_dashboard',
    url: '/dataDashboard',
    component: DataDashboardComponent,
    data: {
        pageTitle: 'Dude, Where is My Data?',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'DataDashboard', access: ['read', 'getDistinctDataSources'] },
            { model: 'DataDashboardAdditionalInfo', access: ['read'] },
        ]
    },
    params: {
        dataSourceDrillDown: null
    }
};

export const scratchDatabaseCleanupRootState: Ng2StateDeclaration = {
    name: 'argos.data.scratch_database_cleanup',
    component: ScratchDatabaseCleanupRootComponent,
    data: {
        pageTitle: 'Scratch Database Cleanup',
        requiresAuth: true
    }
};

export const scratchDatabaseCleanupState: Ng2StateDeclaration = {
    name: 'argos.data.scratch_database_cleanup.list',
    url: '/scratchDatabaseCleanup',
    component: ScratchDatabaseCleanupComponent,
    data: {
        pageTitle: 'ScratchDatabaseCleanup',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ScratchDatabaseCleanup', access: ['read', 'getAllTables', 'updateTableDeletionConfig'] }
        ]
    }
};

export const stageSourceTableState: Ng2StateDeclaration = {
    name: 'argos.data.stageSourceTable',
    component: StageSourceTableComponent,
    data: {
        pageTitle: 'Deequ Stages/Sources/Tabless',
        requiresAuth: true
    }
};

export const dataReleaseManagerRootState: Ng2StateDeclaration = {
    name: 'argos.data.data_release_manager',
    component: DataReleaseManagerRootComponent,
    data: {
        pageTitle: 'Data Release Manager',
        requiresAuth: true
    }
};

export const stageSourceTableListState: Ng2StateDeclaration = {
    name: 'argos.data.stageSourceTable.list',
    url: '/deequStages',
    component: StageSourceTableListComponent,
    data: {
        pageTitle: 'Deequ Stages/Sources/Tables',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'TeradromeStage', access: ['read'] },
            { model: 'ViewTeradromeDeequStats', access: ['read'] },
            { model: 'TeradromeTable', access: ['read', 'destroyById', 'create', 'update'] },
            { model: 'TeradromeStageSource', access: ['read'] },
            { model: 'TeradromeDeequTest', access: ['read', 'create', 'update', 'destroyById'] },
            { model: 'TeradromeColumn', access: ['read', 'destroyById'] },
            { model: 'TeradromeDeequTestHistory', access: ['read', 'create', 'update'] },
            { model: 'ClarifyBot', access: ['post'] }
        ]
    },
    params: {
        stage: null,
        source: null
    }
};

export const stageSourceTableColumnListState: Ng2StateDeclaration = {
    name: 'argos.data.stageSourceTable.columnList',
    url: '/deequStages?tableId',
    component: StageSourceTableColumnListComponent,
    data: {
        pageTitle: 'Deequ Stages/Sources/Tables',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'TeradromeTable', access: ['read'] },
            { model: 'TeradromeStage', access: ['read'] },
            { model: 'TeradromeStageSource', access: ['read'] },
            { model: 'TeradromeColumn', access: ['read'] },
            { model: 'ViewTeradromeDeequTestUnnested', access: ['read'] },
            { model: 'TeradromeStageSource', access: ['read'] }
        ]
    },
    params: {
        tableName: null,
        dataSourceFilter: null,
        stage: null,
        source: null
    }
};

export const dataReleaseManagerState: Ng2StateDeclaration = {
    name: 'argos.data.data_release_manager.list',
    url: '/dataReleaseManager',
    component: DataReleaseManagerComponent,
    data: {
        pageTitle: 'Data Release Manager',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'DataRelease', access: ['create', 'read', 'update', 'queryReleases'] },
            { model: 'JiraApi', access: ['getTransitions', 'transitionIssue'] }
        ]
    }
};

export const createDataReleaseState: Ng2StateDeclaration = {
    name: 'argos.data.data_release_manager.create',
    url: '/dataReleaseManager/create',
    component: CreateDataReleaseComponent,
    data: {
        pageTitle: 'Create Data Release',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'Astronomer', access: ['getDagsUrl', 'getDagsByPattern'] },
            { model: 'DataReleaseDags', access: [ 'addDagToRelease'] },
            { model: 'DataRelease', access: ['createRelease', 'getDistinctReleaseNames', 'addJiraTicketId'] },
            { model: 'JiraApi', access: ['createIssue', 'transitionIssue', 'getUserId'] }
        ]
    }
};

export const runDataReleaseState: Ng2StateDeclaration = {
    name: 'argos.data.data_release_manager.run',
    url: '/dataReleaseManager/run/:releaseId',
    component: RunDataReleaseComponent,
    data: {
        pageTitle: 'Run Data Release',
        requiresAuth: true,
        actions: {
            run_release_allowed: false,
            finalize_allowed: false
        },
        requiredApiAccess: [
            { model: 'GitHub', access: ['getFinalCatalogSources'] },
            { model: 'Astronomer', access: ['getDagsUrl', 'getDagStatus', 'updateDagStatus'] },
            { model: 'DataRelease', access: ['getReleaseInfo', 'updateReleaseStatus', 'triggerDag', 'triggerAllDags'] },
            { model: 'DataReleaseDags', access: ['getDagsInRelease', 'updateDagRunStatus', 'updateDagStatus', 'updateDagInRelease'] },
            { model: 'DataReleaseDagRuns', access: ['updateDagRunStatus'] },
            { model: 'JiraApi', access: ['transitionIssue'] }
        ]
    }
};
export const viewReleaseImpactState: Ng2StateDeclaration = {
    name: 'argos.data.data_release_manager.viewReleaseImpact',
    url: '/dataReleaseManager/viewReleaseImpact/:releaseId/:releaseName',
    component: ViewReleaseImpactComponent,
    data: {
        pageTitle: 'View Release Impact',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'CareGrouping', access: ['read', 'create', 'update'] },
            { model: 'ClientCareGrouping', access: ['getClientCareGroupings'] },
            { model: 'GitHub', access: ['getFinalCatalogSources'] },
            { model: 'Astronomer', access: ['getDagsUrl'] },
            { model: 'MetaDataUsage', access: ['getEnvironmentUsageResults'] }
        ]
    }
};
export const editDataReleaseState: Ng2StateDeclaration = {
    name: 'argos.data.data_release_manager.edit',
    url: '/dataReleaseManager/edit/:releaseId/:releaseDate/:releaseName',
    component: EditDataReleaseComponent,
    data: {
        pageTitle: 'Edit Data Release',
        requiresAuth: true,
        actions: {
            finalize_allowed: false
        },
        requiredApiAccess: [
            { model: 'Astronomer', access: ['getDagsUrl', 'getDagsByPattern', 'getDagStatus'] },
            { model: 'DataReleaseDags', access: ['create', 'update', 'getDagsInRelease', 'updateDagStatus', 'updateDagInRelease',
                'addDagToRelease', 'findDataReleaseDagRunIds', 'destroyById'] },
            { model: 'DataReleaseDagRuns', access: ['updateDagRunStatus', 'findDataReleaseDagRunIds', 'destroyById'] },
            { model: 'DataRelease', access: ['getReleaseInfo', 'updateRelease', 'updateReleaseStatus'] },
            { model: 'JiraApi', access: ['transitionIssue', 'addComment', 'updateAssigneeWithId'] }
        ]

    }
};

export const claraIqReleaseNotesState: Ng2StateDeclaration = {
    name: 'argos.data.release_notes',
    url: '/releaseNotes',
    component: ClaraIqReleaseNotesComponent,
    data: {
        pageTitle: 'Generate Release Notes',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyBot', access: ['post'] },
        ]
    }
};

export const ClarifyBotRootState: Ng2StateDeclaration = {
    name: 'argos.data.clarifyBot',
    component: ClarifyBotRootComponent,
    data: {
        pageTitle: 'Clarify Bot',
        requiresAuth: true
    }
};


export const ClarifyBotState: Ng2StateDeclaration = {
    name: 'argos.data.clarifyBot.list',
    url: '/clarifyBot',
    component: ClarifyBotComponent,
    data: {
        pageTitle: 'Clarify Bot',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyBot', access: ['getCollectionsDataSourceMap', 'botResponse', ] },
            { model: 'ClarifyBotHistory', access: ['read', 'create', 'update'] }
        ]
    }
};

export const dataAiChatState: Ng2StateDeclaration = {
    name: 'argos.data.data_ai_chat',
    url: '/dataAiChat',
    component: DataAiChatComponent,
    data: {
        pageTitle: 'Data AI Chat',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'ClarifyBot', access: ['post'] }
        ]
    }
};

export const advanceMetaDataState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.advanceMetaData',
    component: AdvanceMetaDataComponent,
    data: {
        pageTitle: 'Advance Metadata',
        requiresAuth: true
    }
};

export const advanceMetaDataListState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.advanceMetaData.list',
    url: '/advanceMetaData',
    component: AdvanceMetaDataListComponent,
    data: {
        pageTitle: 'Advance Metadata',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'AdvanceEpMetaData', access: ['read'] },
            { model: 'AdvanceSrMetaData', access: ['read'] },
            { model: 'Advance', access: ['getReferenceIds']}
        ]
    }
};

export const advanceMetaDataEditState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.advanceMetaData.edit',
    url: '/advanceMetaData/edit/:runType/:id',
    component: AdvanceMetaDataEditComponent,
    data: {
        pageTitle: 'Edit Advance Metadata',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'DataSource', access: ['read'] },
            { model: 'ReferenceDataListItem', access: ['read'] },
            { model: 'TeradromeTable', access: ['read'] },
            { model: 'TeradromeColumn', access: ['read'] },
            { model: 'DataSource', access: ['read'] },
            { model: 'AdvanceEpMetaData', access: ['read', 'create', 'update'] },
            { model: 'AdvanceSrMetaData', access: ['read', 'create', 'update'] },
            { model: 'ClarifyProviderNpiRevision', access: ['read'] },
            { model: 'ClarifyProviderAttributedParentIdRevision', access: ['read'] },
            { model: 'ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision', access: ['read'] },
            { model: 'Advance', access: ['initilizeRefDataSync', 'retrieveExcel', 'uploadExcel', 'read', 'getReferenceIds'] },
            { model: 'AdvanceEpAttributedParentIdPhysicianGroupExclusion', access: ['read', 'createMany'] },
            { model: 'AdvanceEpAttributedParentIdNpiExclusion', access: ['read', 'createMany'] },
            { model: 'AdvanceEpAttributedParentIdExclusion', access: ['read', 'createMany'] },
            { model: 'AdvancePrimaryPhysicianGroupAffiliationNpiEligibility', access: ['read', 'createMany'] },
            { model: 'AdvanceNpiEligibility', access: ['read', 'createMany'] },
            { model: 'AdvanceEpAlternateFacilitySelection', access: ['read', 'createMany'] },
            { model: 'AdvanceSrRenderingProviderNpiReferringNpiExclusion', access: ['read', 'createMany'] },
            { model: 'AdvanceSrRenderingProviderNpiExclusion', access: ['read', 'createMany'] },
            { model: 'AdvanceSrRenderingProviderNpiPrimaryPhysicianGroupExclusion', access: ['read', 'createMany'] },
            { model: 'Astronomer', access: ['triggerDag', 'getDagsUrl'] }
        ]
    }
};

export const advanceMetaDataNewState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.advanceMetaData.add',
    url: '/advanceMetaData/add/:runType',
    component: AdvanceMetaDataEditComponent,
    data: {
        pageTitle: 'Add Advance Metadata',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'DataSource', access: ['read'] },
            { model: 'ReferenceDataListItem', access: ['read'] },
            { model: 'TeradromeTable', access: ['read'] },
            { model: 'TeradromeColumn', access: ['read'] },
            { model: 'DataSource', access: ['read'] },
            { model: 'AdvanceEpMetaData', access: ['read', 'create', 'update'] },
            { model: 'AdvanceSrMetaData', access: ['read', 'create', 'update'] },
            { model: 'ClarifyProviderNpiRevision', access: ['read'] },
            { model: 'ClarifyProviderAttributedParentIdRevision', access: ['read'] },
            { model: 'ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision', access: ['read'] },
            { model: 'Advance', access: ['initilizeRefDataSync', 'retrieveExcel', 'uploadExcel'] },
            { model: 'Advance', access: ['read'] },
            { model: 'AdvanceEpAttributedParentIdPhysicianGroupExclusion', access: ['read', 'createMany'] },
            { model: 'AdvanceEpAttributedParentIdNpiExclusion', access: ['read', 'createMany'] },
            { model: 'AdvanceEpAttributedParentIdExclusion', access: ['read', 'createMany'] },
            { model: 'AdvancePrimaryPhysicianGroupAffiliationNpiEligibility', access: ['read', 'createMany'] },
            { model: 'AdvanceNpiEligibility', access: ['read', 'createMany'] },
            { model: 'AdvanceEpAlternateFacilitySelection', access: ['read', 'createMany'] },
            { model: 'AdvanceSrRenderingProviderNpiReferringNpiExclusion', access: ['read', 'createMany'] },
            { model: 'AdvanceSrRenderingProviderNpiExclusion', access: ['read', 'createMany'] },
            { model: 'AdvanceSrRenderingProviderNpiPrimaryPhysicianGroupExclusion', access: ['read', 'createMany'] }
        ]
    }
};

export const hospitalRatesLandingRootState: Ng2StateDeclaration = {
    name: 'argos.hospitalRates',
    component: HospitalRatesLandingPageRootComponent,
    data: {
        pageTitle: 'Hospital Rates',
        requiresAuth: true
    }
};

export const hospitalRatesLandingState: Ng2StateDeclaration = {
    name: 'argos.hospitalRates.list',
    url: '/hospitalRates',
    component: HospitalRatesLandingComponent,
    data: {
        pageTitle: 'Hospital Rates Dashboard',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'HospitalRatesHospitals', access: ['read', 'create',
                'updateClarifyMetadata', 'updateHospitalRecord', 'updatePriority', 'getAllHospitals'] },
            { model: 'HospitalRatesPriority', access: ['read', 'create'] },
            { model: 'HospitalRatesClarifyMetadata', access: ['read', 'create'] },
            { model: 'HospitalRatesLoadSequences', access: ['getCurrentLoadSequence'] },
            { model: 'HospitalRatesHospitalLoadSequences', access: ['create'] }
        ]
    }
};

export const hospitalRatesBulkAddState: Ng2StateDeclaration = {
    name: 'argos.hospitalRates.bulkAdd',
    url: '/hospitalRates/bulkAdd',
    component: HospitalRatesBulkAddComponent,
    data: {
        pageTitle: 'Hospital Rates Bulk Add',
        requiresAuth: true,
        requiredApiAccess: [
            { model: 'HospitalRatesHospitals', access: ['read', 'create'] },
            { model: 'HospitalRatesPriority', access: ['create'] },
            { model: 'HospitalRatesClarifyMetadata', access: ['create'] },
            { model: 'HospitalRatesHospitalLoadSequences', access: ['create'] },
            { model: 'HospitalRatesBulkAddErrors', access: ['create'] },
            { model: 'HospitalRatesLoadSequences', access: ['getCurrentLoadSequence'] }
        ]
    }
};

export const ccgReferenceDataState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.ccgReferenceData',
    component: CcgReferenceDataComponent,
    data: {
        pageTitle: 'CCG Reference Data',
        requiresAuth: true
    }
};

export const ccgReferenceDataListState: Ng2StateDeclaration = {
    name: 'argos.ccgDesigner.ccgReferenceData.list',
    url: '/ccg_reference_data',
    component: CcgReferenceDataListComponent,
    data: {
        pageTitle: 'CCG Reference Data',
        requiresAuth: true,
        actions: {
            add_or_edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'CCGReferenceTableName', access: ['read', 'create', 'update'] },
            { model: 'ReferenceData', access: ['read'] },
            { model: 'ReferenceDataClass', access: ['read'] },
            { model: 'TeraUser', access: ['read'] },
            { model: 'EpisodeGrouping', access: ['getReferenceTables'] },
            { model: 'genericCreate', access: ['create'] },
            { model: 'TableUsage', access: ['getTableUsage']},
            { model: 'Teradrome', access: ['findTextInGitRepo']}
        ]
    }
};

export const pceMetaDataState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.pceMetaData',
    component: PceMetaDataComponent,
    data: {
        pageTitle: 'PCE Metadata',
        requiresAuth: true
    }
};

export const pceMetaDataListState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.pceMetaData.list',
    url: '/pceMetaData',
    component: PceMetaDataListComponent,
    data: {
        pageTitle: 'PCE Metadata',
        requiresAuth: true,
        actions: {
            add_or_edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'PceObservation', access: ['read'] },
            { model: 'TeradromeColumn', access: ['read'] }
        ]
    }
};

export const pceMetaDataEditState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.pceMetaData.edit',
    url: '/pceMetaData/edit/:id',
    component: PceMetaDataEditComponent,
    data: {
        pageTitle: 'Edit PCE Metadata',
        requiresAuth: true,
        actions: {
            add_or_edit_allowed: false
        },
        requiredApiAccess: [
            { model: 'PceObservationHistory', access: ['read', 'create', 'update'] },
            { model: 'PceObservation', access: ['read', 'create', 'update'] },
            { model: 'PceObservationFilterPredicate', access: ['read', 'create', 'update', 'destroyById'] },
            { model: 'Observation', access: ['read'] },
            { model: 'TeradromeTable', access: ['read'] },
            { model: 'TeradromeColumn', access: ['read'] },
            { model: 'ListItem', access: ['read'] },
            { model: 'ReferenceDataListTag', access: ['read'] },
            { model: 'ReferenceDataList', access: ['read'] },
            { model: 'ViewReferenceDataListParentTags', access: ['read'] },
            { model: 'PceObservationFilterPredicates', access: ['read'] },
            { model: 'PceObservationHistories', access: ['read'] },
            { model: 'NpiColumn', access: ['read'] },
            { model: 'Teradrome', access: ['openFreeformSQLPR', 'getPceJoinKeyDict'] }
        ]
    }
};

export const pceMetaDataNewState: Ng2StateDeclaration = {
    name: 'argos.modelMetaData.pceMetaData.add',
    url: '/pceMetaData/add',
    component: PceMetaDataEditComponent,
    data: {
        pageTitle: 'Add PCE Metadata',
        requiresAuth: true,
        actions: {
            add_or_edit_allowed: false
        }
    }
};

export const APP_STATES = [
    appState,
    contentState,
    loginState,
    callbackState,
    clientEnvironmentsEditState,
    clientEditState,
    clientAddState,
    clientListState,
    clientState,
    careGroupingsListState,
    ccgAdminState,
    careGroupingsState,
    error404State,
    inactiveState,
    careGroupingsCopyState,
    careGroupingsEditState,
    ccgState,
    ccgAttributedProviderCCGState,
    ccgDefinitionState,
    ccgProviderTypesState,
    ccgSourceSchemasState,
    environmentsState,
    buildsState,
    buildsRootState,
    buildDetailState,
    columnUsageState,
    compoundFiltersListState,
    compoundFiltersState,
    compoundFiltersAddEditState,
    compoundFiltersAddState,
    dataProvisioningQuestionnaireState,
    dashboardState,
    ecsAccessState,
    ecsAppListState,
    ecsAppEditState,
    adminState,
    ecsActivityLogListState,
    ecsDashboardState,
    ecsRepoEditState,
    environmentRequestsState,
    environmentRequestsRootState,
    environment20RequestState,
    environment20RequestListState,
    environment20RequestEditState,
    environment20RequestAddState,
    newEnvironmentRequestState,
    environmentListState,
    environmentState,
    clientCareGroupingsState,
    clientCareGroupingsListState,
    markedForDeletionListState,
    markedForDeletionState,
    episodeGroupingEdittState,
    episodeGroupingAddtState,
    episodeGroupingCopytState,
    episodeGroupingImportState,
    episodeGroupingListState,
    episodeGroupingState,
    joinEntitiesListState,
    joinEntitiesState,
    validValueQueriesListState,
    validValueQueriesState,
    clarifyReportsState,
    clarifyReportsListState,
    referenceDataState,
    modelFeaturesListState,
    modelFeaturesState,
    teraUsersState,
    teraUsersListState,
    modelMetaDataState,
    networkDesignerState,
    networksStatusState,
    observationExceptionListState,
    observationExceptionState,
    observationExceptionAddState,
    observationExceptionEditState,
    allowedFieldAddState,
    allowedFieldEditState,
    allowedFieldListState,
    allowedFieldState,
    ccgReportListState,
    hierarchyEditState,
    hierarchyAddState,
    hierarchyListState,
    hierarchyState,
    observationEditState,
    observationCopyState,
    observationListState,
    observationNewState,
    observationState,
    pipelineDataSourceListState,
    pipelineDataState,
    pipelineDataSourceState,
    pipelineDataSourceEditState,
    pipelineDataSourceAddState,
    pipelineErrorMapState,
    pipelineErrorMapRootState,
    pipelineHistoryState,
    pipelineHistoryRootState,
    pipelineHistoryDetailState,
    pipelineHistoryDetailRootState,
    pipelineHistoryDetailStepsState,
    pipelineQueueState,
    pipelineQueueRootState,
    prism1MetricEditState,
    prism1MetricAddState,
    prism1MetricCopyState,
    prism1MetricImportState,
    prism1MetricListState,
    prism1MetricState,
    prismState,
    prismNotificationsState,
    prismUsageState,
    atlasClaraChatHistoryState,
    productListState,
    productState,
    productEditState,
    productAddState,
    productBundlesListState,
    productBundlesState,
    patchManagementState,
    patchManagementListState,
    patchManagementAddState,
    patchManagementEditState,
    patchManagementPreviewState,
    ccgFeatureEditState,
    ccgFeatureListState,
    ccgFeatureState,
    programEpisodeEditState,
    programEpisodeListState,
    programEpisodeState,
    programModelListState,
    programModelState,
    programModelEditState,
    versionHistoryState,
    versionHistoryListState,
    editReleaseNotesState,
    previewReleaseNotesState,
    publishReleaseNotesState,
    releasesState,
    queryPerformanceState,
    filterPerformanceState,
    athenaUsageState,
    querySettingsState,
    redshiftClustersState,
    referenceDataSourcesEditState,
    referenceDataSourcesAddState,
    referenceDataSourcesListState,
    referenceDataSourcesState,
    clarifyProviderRevisionEditState,
    clarifyProviderRevisionNewState,
    clarifyProviderRevisionListState,
    clarifyProviderRevisionState,
    dataUnitTestResultState,
    dataUnitTestResultListState,
    referenceListItemsListState,
    referenceListItemsState,
    referenceListItemsEditState,
    releaseNotesState,
    salesforceSyncStatusState,
    productConfigurationContentSyncStatusComponent,
    terraTestResultEditState,
    terraTestResultsState,
    terraTestResultsRootState,
    userListState,
    userEngagementState,
    userRoleState,
    userRoleListState,
    userRoleEditState,
    userRoleAddState,
    marketShareMetaEditState,
    marketShareMetaListState,
    marketShareMetaState,
    payerRatesMetaListState,
    payerRatesMetaState,
    payerRatesMetaEditState,
    payerRatesMetaNewState,
    qaTestResultsRootState,
    qaTestResultsState,
    qaTestResultProductDetailsState,
    qaTestResultSubmoduleDetailsState,
    performanceTestResultDetailsState,
    apiPerformanceTestResultDetailsState,
    clarifyInsightsLandingPageState,
    clarifyInsightFilterState,
    clarifyInsightFilterListState,
    clarifyInsightFilterEditState,
    clarifyInsightFilterNewState,
    clarifyInsightFilterHistoryState,
    clarifyInsightsDataEvaluationUserState,
    clarifyInsightsDeliveryHubState,
    dataDashboardRootState,
    cypressFeatureTestResultsDetailState,
    scratchDatabaseCleanupRootState,
    scratchDatabaseCleanupState,
    automatedGoldPullDownListState,
    automatedGoldPullDownState,
    stageSourceTableState,
    stageSourceTableListState,
    stageSourceTableColumnListState,
    dataReleaseManagerRootState,
    dataReleaseManagerState,
    createDataReleaseState,
    runDataReleaseState,
    viewReleaseImpactState,
    editDataReleaseState,
    dataAiChatState,
    claraIqReleaseNotesState,
    ClarifyBotRootState,
    ClarifyBotState,
    advanceMetaDataState,
    advanceMetaDataListState,
    advanceMetaDataEditState,
    advanceMetaDataNewState,
    qaTestResultTabAndTileDetailsState,
    hospitalRatesLandingRootState,
    hospitalRatesLandingState,
    hospitalRatesBulkAddState,
    ccgReferenceDataState,
    ccgReferenceDataListState,
    pceMetaDataState,
    pceMetaDataListState,
    pceMetaDataEditState,
    pceMetaDataNewState
];
