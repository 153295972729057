import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IUserEngagementStates, IUserEngagementService } from './userEngagement.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import * as _ from 'lodash';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { UtilsService } from '../../../services/utils.service';

@Injectable()
export class UserEngagementService implements IUserEngagementService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private cdr: ChangeDetectorRef, 
                private argosStore: ArgosStoreService, private utils: UtilsService) {
        //
    }

    async initDelegate(states: IUserEngagementStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IUserEngagementStates) {
        let promises = [];
        promises.push(this.loadUserActivity(states));
        promises.push(this.loadUserErrors(states));
        await Promise.all(promises);
        this.cdr.detectChanges();
    }

    async loadUserErrors(states: IUserEngagementStates) {
        states.errors = await this.dataAccess.genericFind({
            model: 'UserErrorLog',
            filter: { 
                order: 'requestDate desc'
            }
        });

        _.forEach(states.errors, (e:any) => {
            e.requestBody = JSON.stringify(e.requestBody);
        });

        states.errorsTable.data = states.errors;
    }
       

    async loadUserActivity(states: IUserEngagementStates) {
        states.users = await this.dataAccess.genericFind({
            model: 'ArgosUserActivity',
            filter: { 
                order: 'id desc',
                include: 'argosUser' 
            }
        });

        _.forEach(states.users, (u:any) => {
            u.email = u.argosUser.email;
            u.localDate = this.utils.getLocalDate(u.createdAt);
            u.details = JSON.stringify(u.eventDetails);
        });

        states.usersTable.data = states.users;
    }

    openMatTableSettings(states: IUserEngagementStates, matTableColumns: any[], defaultDisplayMatTableColumns: string[], matTableSettingName: string) {
        let data = { 
            tableColumns: matTableColumns,
            defaultDisplayTableColumns: defaultDisplayMatTableColumns,
            settingName: matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

}
