import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IPrismNotificationsStates } from './prismNotifications.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PrismNotificationsService } from './prismNotifications.component.service';

@Component({
    selector: 'argos-prismNotifications',
    templateUrl: './prismNotifications.component.html',
    providers: [PrismNotificationsService]
})

export class PrismNotificationsComponent extends ReactComponentBase<{}, IPrismNotificationsStates, {}> implements OnInit {
    constructor(private svc: PrismNotificationsService, private cdr: ChangeDetectorRef) {
        super({
            clientListdropdown: [],
            submitForm: {
                internalName: null,
                notificationText: '',
                severity: 'high'
            },
            edit: false,
            systemWide: '00000000-0000-0000-0000-000000000000'
        } as unknown as IPrismNotificationsStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    cancel() {
        this.states.submitForm = {
            internalName: null,
            notificationText: '',
            severity: 'high'
        };
    }

    async editRow(notificationObj: any, enableEdit: boolean) {
        notificationObj.editMode = enableEdit;

        if (!enableEdit) {
            await this.svc.getPrismTableList(this.states);
            this.cdr.detectChanges();
        }
    }

    async saveRow(notificationObj: any) {
        await this.svc.upsertNotifications(this.states, notificationObj);
        notificationObj.editMode = false;
        this.cdr.detectChanges();
    }

    async deleteRow(notificationObj: any) {
        await this.svc.deleteNotification(this.states, notificationObj, this.cdr);
    }

    async addNotifications() {
        const notification = {
            clientId: this.states.submitForm.client.id,
            internalName: this.states.submitForm.client.internal_name,
            notificationText: this.states.submitForm.notificationText,
            severity: this.states.submitForm.severity
        };

        await this.svc.upsertNotifications(this.states, notification);
        this.cdr.detectChanges();
    }
}
