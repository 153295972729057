import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IEcsActivityLogListStates } from './ecsActivityLogList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EcsActivityLogListService } from './ecsActivityLogList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { LoginService } from '../../../services/loginService.service';
import { StateService, UIRouter } from '@uirouter/core';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

declare const $: any;
@Component({
    selector: 'argos-ecsActivityLogList',
    templateUrl: './ecsActivityLogList.component.html',
    providers: [EcsActivityLogListService]
})

export class EcsActivityLogListComponent
    extends ReactComponentBase<{}, IEcsActivityLogListStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;

    constructor(private svc: EcsActivityLogListService, private matSvc: MaterialUtil, private loginServiceHandler: LoginService, 
                private state: StateService, private cdr: ChangeDetectorRef, private urlService: UrlService) {
        super({
            pageSize: 50,
            filterObj: {
                matchExtactColumnFilterValue: ['environmentAction', 'triggeredBy']    // set this to filter property to exact match
            },
            defaultDisplayMatTableColumns: ['id', 'clusterName', 'environmentAction', 'detailMessage', 'copyButton', 'triggeredBy', 'dateFullName', 'awsAccount'],
            matTableColumns: ['id', 'clusterName', 'environmentAction', 'detailMessage', 'copyButton', 'triggeredBy', 'dateFullName', 'awsAccount'],
            dataSources: [],
            matTableSettingName: 'ecsActivityLogList',
            table: new MatTableDataSource([])
        } as unknown as IEcsActivityLogListStates);
    }

    ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.table.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    daysToQueryChanged() {
        this.svc.daysToQueryChangedhandler(this.states);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.table.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.table.filter);
        this.cdr.detectChanges();
    }

    searchLog(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.table.filter = filterValue.trim().toLowerCase();
    }

    copyToClipboard(row: any) {
        const value = JSON.stringify(row);

        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(value).select();
        document.execCommand('copy');
        $temp.remove();
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        return this.matSvc.getMatSelectPlaceholderName(columnName, filterValue);
    }
}
