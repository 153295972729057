import { Injectable } from '@angular/core';
import { ArgosStoreService } from './argosStore.service';
import { JwtHelperService } from '@auth0/angular-jwt';

/**
 * This service emulates an Authentication Service.
 */
@Injectable()
export class AuthService {
    jwtHelper: JwtHelperService = new JwtHelperService();
    // data
    constructor(private storeSVc: ArgosStoreService) { }

    /**
     * Returns true if the user is currently authenticated, else false
     */
    isAuthenticated() {
        const token = this.storeSVc.getItem('token');
        return token && !this.jwtHelper.isTokenExpired(token);
    }

    /**
     * Fake authentication function that returns a promise that is either resolved or rejected.
     *
     * Given a username and password, checks that the username matches one of the known
     * usernames (this.usernames), and that the password matches 'password'.
     *
     * Delays 800ms to simulate an async REST API delay.
     */
    authenticate(username: any, password: any) {
        return true;
    }

    /** Logs the current user out */
    logout() {
        // this.appConfig.emailAddress = undefined;
        // this.appConfig.save();
    }
}
