import { Component } from "@angular/core";
import { ReactComponentBase } from "../../reactComponentBase/reactComponentBase.component";
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'argos-release-remove-dag-modal',
    templateUrl: './releaseRemoveDagModal.component.html'
})

export class ReleaseRemoveDagModalComponent extends ReactComponentBase<{}, {}, {}> {
    constructor(private dialogRef: MatDialogRef<ReleaseRemoveDagModalComponent>) {
        super();
    }

    confirm() {
        this.dialogRef.close('confirm');
    }

    close() {
        this.dialogRef.close('cancel');
    }
}