import { Injectable } from '@angular/core';
import { IReferenceListItemsListStates, IReferenceListItemsListService } from './referenceListItemsList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class ReferenceListItemsListService implements IReferenceListItemsListService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(states: IReferenceListItemsListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IReferenceListItemsListStates) {
        states.referenceDataLists = await this.dataAccess.genericFind({
            model: 'ViewReferenceDataListSummary'
        });
        states.referenceDataLists.forEach((list: any) => {
            list.supportsTagHierarchy = list.autoCreated || false;
        });
        
        states.referenceListItemsTable.data = _.orderBy(states.referenceDataLists, ['name']);
    }

}
