import { Injectable } from '@angular/core';
import { IReImportEnvironmentListModalProps, IReImportEnvironmentListModalStates, IReImportEnvironmentListModalService } from './reImportEnvironmentListModal.component.d';

@Injectable()
export class ReImportEnvironmentListModalService implements IReImportEnvironmentListModalService {
    initDelegate(props: IReImportEnvironmentListModalProps, states: IReImportEnvironmentListModalStates): object {
        states.environment = props.environment;
        return {};
    }

    changeDelegate(oldProps: IReImportEnvironmentListModalProps, newProps: IReImportEnvironmentListModalProps, states: IReImportEnvironmentListModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }
}
