import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { ReactComponentBase } from "../../reactComponentBase/reactComponentBase.component";
import { IDataDashLegendModalStates } from "./dataDashLegendModal.component.d";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataDashLegendModalService } from "./dataDashLegendModal.component.service";

@Component({
    selector: 'argos-data-dash-legend-confirmation-modal',
    templateUrl: './dataDashLegendModal.component.html',
    providers: [DataDashLegendModalService]
})

export class DataDashLegendModalComponent extends ReactComponentBase<{}, IDataDashLegendModalStates, {}>
    implements OnInit {
    constructor(private svc: DataDashLegendModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any, private dialogRef: MatDialogRef<DataDashLegendModalComponent>) {
            super();
        }

    ngOnInit() {
        this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    close() {
        this.dialogRef.close('cancel');
    }
}