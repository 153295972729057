import { Component } from '@angular/core';
import { IAddNewFieldModalStates } from './addNewFieldModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { AddNewFieldModalService } from './addNewFieldModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-addNewFieldModal',
    templateUrl: './addNewFieldModal.component.html',
    providers: [AddNewFieldModalService]
})

export class AddNewFieldModalComponent extends ReactComponentBase<{}, IAddNewFieldModalStates, {}> {
    constructor(private svc: AddNewFieldModalService,
        private dialogRef: MatDialogRef<AddNewFieldModalComponent>) {
        super({
            listItem: {
                listId: 3
            }
        } as unknown as IAddNewFieldModalStates);
    }

    closeModal() {
        this.dialogRef.close();
    }

    fieldChanged() {
        this.states.errorMessage = '';
    }
    async save() {
        await this.svc.saveHandler(this.states);
        this.closeModal();
    }
}
