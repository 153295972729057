import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IEditJoinEntityModalProps, IEditJoinEntityModalStates } from './editJoinEntityModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EditJoinEntityModalService } from './editJoinEntityModal.component.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-editJoinEntityModal',
    templateUrl: './editJoinEntityModal.component.html',
    providers: [EditJoinEntityModalService]
})

export class EditJoinEntityModalComponent extends ReactComponentBase<IEditJoinEntityModalProps, IEditJoinEntityModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: EditJoinEntityModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<EditJoinEntityModalComponent>, private cdr: ChangeDetectorRef) {
        super({
            episodeGroupingEntity: {}
        } as IEditJoinEntityModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    cancelModal(eventName = 'cancel') {
        this.dialogRef.close({ eventName, data: this.states.episodeGroupingEntity });
    }

    viewHistory() {
        this.svc.viewHistoryHandler(this.states);
    }

    async save() {
        await this.svc.saveHandler(this.states);
        this.cancelModal('apply');
    }
}
