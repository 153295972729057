import { Injectable, ChangeDetectorRef } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { IViewAiChatModalProps, IViewAiChatModalStates, IViewAiChatModalService } from './viewAiChatModal.component.d';


@Injectable()
export class ViewAiChatModalService implements IViewAiChatModalService {
    constructor(private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {}
    async initDelegate(props: IViewAiChatModalProps, states: IViewAiChatModalStates): Promise<object> {
        states.apiParams = props.apiParams;
        states.modalName = props.modalName;
        states.endpoint = props.endpoint;
        states.initialMessage = props.initialMessage;
        states.messages = [{
            message: states.initialMessage,
            userMessage: false
        }];
        states.question = '';
        states.fetchingResponse = false;

        return {};
    }

    changeDelegate(oldProps: IViewAiChatModalProps, newProps: IViewAiChatModalProps, states: IViewAiChatModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    async sendQuestion(states: IViewAiChatModalStates, question: string | undefined) {  
        states.messages.unshift({
            message: question,
            userMessage: true
        });

        states.question = undefined;

        this.cdr.detectChanges();
        
        const params: any = {
            user_question: question,
            username: this.argosStore.getItem('username')
        };

        if (states.sessionId) {
            // At the moment, some endpoints require session_id and some require session_key
            if (states.endpoint.startsWith('ai_utils')) {
                params.session_id = states.sessionId;
            }
            params.session_key = states.sessionId;
        }

        states.fetchingResponse = true;

        try {
            const response = await this.dataAccess.genericMethod({
                model: 'ClarifyBot',
                method: 'post',
                parameters: {
                    endpoint: states.endpoint,
                    body: { ...params, ...states.apiParams }

                }
            });

            if (response.session_key) {
                states.sessionId = response.session_key;
            } else if (response.session_id) {
                states.sessionId = response.session_id;
            }
    
            if (response.image_data) {
                states.messages.unshift({
                    image: `data:image/png;base64,${response.image_data}`,
                    userMessage: false,
                });
    
            } else if (response.tabular_data) {
                states.messages.unshift({
                    table: this.formatDataAsTable(response.tabular_data),
                    userMessage: false,
                });
    
            } else if (response.llm_response) {
                let message = response.llm_response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
                message = message.replace(/`(.*?)`/g, '<i>$1</i>');

                if (response.sql_query) {
                    message += `<pre><code>${response.sql_query}</code></pre>`;
                }

                states.messages.unshift({
                    message,
                    userMessage: false,
                });
            }
        } catch (error) {
            console.log(error);
            states.messages.unshift({
                message: 'Issue sending question, please try again.',
                userMessage: false,
            });
        }

        

        this.cdr.detectChanges();
        states.fetchingResponse = false;
    }

    formatDataAsTable(data: any) {      
        if (!data.length) return 'Issue generating table, please try asking again.';
        const dataObject = JSON.parse(data);

        // Calculate max width for each column
        const headers = Object.keys(dataObject[0]);
        const colWidths = headers.map(header => 
            Math.max(header.length, ...dataObject.map((obj: any) => 
                typeof obj[header] === 'number' ? obj[header].toFixed(2).length : obj[header].toString().length
            ))
        );
    
        // Replace spaces with &nbsp; for alignment
        const replaceSpaces = (str: any, width: any) => {
            const padding = width - str.length;
            return str + '&nbsp;'.repeat(padding);
        };
    
        // Create header row
        const headerRow = headers.map((header, i) => replaceSpaces(header, colWidths[i])).join(' | ');
        let table = '| ' + headerRow + ' |\n';
    
        // Create separator row
        const separatorRow = colWidths.map(width => '-'.repeat(width).replace(/ /g, '&nbsp;')).join('-|-');
        table += '|-' + separatorRow + '-|\n';
    
        // Append each row of data
        dataObject.forEach((item: any) => {
            const row = headers.map((header, i) => {
                const cellValue = typeof item[header] === 'number' ? item[header].toFixed(2) : item[header].toString();
                return replaceSpaces(cellValue, colWidths[i]);
            }).join(' | ');
            table += `| ${row} |\n`;
        });
    
        // Replace remaining spaces between columns with &nbsp;
        return table.replace(/ /g, '&nbsp;');

    }

}
