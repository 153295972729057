import { Component, Inject} from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClarifyInsightsProvisionCustomerModalService } from './clarifyInsightsProvisionCustomerModal.component.service';
import { IClarifyInsightsProvisionCustomerModalInterface } from './clarifyInsightsProvisionCustomerModal.component.d';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MD5Service } from '../../../services/md5.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-clarifyInsightsProvisionCustomerModal',
    templateUrl: './clarifyInsightsProvisionCustomerModal.component.html',
    providers: [ClarifyInsightsProvisionCustomerModalService, MD5Service]
})

export class ClarifyInsightsProvisionCustomerModalComponent extends ReactComponentBase<{}, IClarifyInsightsProvisionCustomerModalInterface, {}> {

    constructor(private svc: ClarifyInsightsProvisionCustomerModalService, public dialogRef: MatDialogRef<ClarifyInsightsProvisionCustomerModalComponent>,
        private SnackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) dialogData: any) {

        let existingAccounts = dialogData?.props.additionalFields ? dialogData?.props.additionalFields : [];
        let accountIdAndBucket: string[] = [];
        _.forEach(existingAccounts, (fields) => {
            let key = fields.awsAccountId + ':' + fields.s3DestinationBucket;
            if (!(accountIdAndBucket.includes(key))) {
                accountIdAndBucket.push(key);
            }
        })

        super({
            customerForm: {},
            customerName: (dialogData?.props.customerName) ? dialogData.props.customerName : null,
            customerCode: (dialogData?.props.customerCode) ? dialogData.props.customerCode : null,
            reuseAccount: false,
            generateNewAccount: false,
            byoA: false,
            s3Bucket: null,
            awsAccountId: null,
            extractType: null,
            isAdditionalExtractType: (dialogData?.props.isAdditionalExtractType) ? dialogData?.props.isAdditionalExtractType : false,
            accountIdAndBucket: accountIdAndBucket,
            activeExtractTypes: ['Market Share', 'Enriched Claims', 'Hospital Rates', 'Provider Performance', 'Provider Directory'],
            existingAccountAndBucket: null
        } as IClarifyInsightsProvisionCustomerModalInterface);
    }

    async submit(customerName: string, customerCode: string, reuseAccount: boolean, byoA: boolean, awsAccountIdRaw: string, s3Bucket: string, extractType: string, existingAccountAndBucket: string) {
        const awsAccountName = `Clarify-${customerCode}`;
        const md5 = new MD5Service();
        const customerId = md5.md5(customerCode);
        let s3DestinationBucket;
        let awsAccountId;
        if (byoA) {
            s3DestinationBucket = s3Bucket.replace('s3://', '');
            awsAccountId = awsAccountIdRaw;
        }
        else if (reuseAccount) {
            s3DestinationBucket = existingAccountAndBucket.split(':')[1];
            awsAccountId = existingAccountAndBucket.split(':')[0];
        }
        else {
            s3DestinationBucket = this.getS3BucketName(customerCode);
            awsAccountId = awsAccountIdRaw;
        }
        extractType = extractType.replace(/\s+/g, '_').toLowerCase();
        const validationResult = await this.isCustomerCodeValid(customerCode, this.states.isAdditionalExtractType);
        if (!validationResult.isValid) {
            this.SnackBar.open(JSON.stringify(validationResult.validationMessages), 'close', { panelClass: 'snack-bar-error' });
            return;
        }
        const response = await this.svc.createNewCustomer(
            customerId,
            customerName,
            customerCode,
            awsAccountName,
            s3DestinationBucket,
            reuseAccount,
            byoA, 
            awsAccountId,
            extractType
        );
        this.SnackBar.open(response, 'close', { panelClass: 'snack-bar-success' });
        this.dialogRef.close();
    }

    getS3BucketName(customerCode: string) {
        const md5 = new MD5Service();
        const customerId = md5.md5(customerCode);
        const s3DestinationBucketSuffix = customerId.substring(0, 5);
        return `clarify-${customerCode.toLowerCase()}-${s3DestinationBucketSuffix.toLowerCase()}`;
    }

    close() {
        this.dialogRef.close('cancel');
    }

    disableByoA(states: IClarifyInsightsProvisionCustomerModalInterface) {
        if (states.extractType === 'Enriched Claims' || states.extractType === 'Market Share') {
            states.byoA = false;
            return true;
        }
        return false;
    }

    reuseAccountHandler(event: any) {
        if (event.checked) {
            this.states.reuseAccount = true;
            this.states.byoA = false;
            this.states.generateNewAccount = false;
        }
        else {
            this.states.reuseAccount = false;
        }
    }

    byoaHandler(event: any) {
        if (event.checked) {
            this.states.reuseAccount = false;
            this.states.byoA = true;
            this.states.generateNewAccount = false;
        }
        else {
            this.states.byoA = false;
        }
    }

    generateNewAccountHanlder(event: any) {
        if (event.checked) {
            this.states.reuseAccount = false;
            this.states.byoA = false;
            this.states.generateNewAccount = true;
        }
        else {
            this.states.generateNewAccount = false;
        }
    }

    clearAccountCreationType() {
        this.states.reuseAccount = false;
        this.states.byoA = false;
        this.states.generateNewAccount = false;
    }


    async isCustomerCodeValid(customerCode: string, isAdditionalExtractType: boolean) {
        let isValid: boolean = true;
        let validationMessages: string[] = [];
        const validationRules: { [key: string]: { message: string; check: (customerCode: string) => boolean } } = {
            length: {
                message: 'Customer Code must be 3-15 characters long',
                check: (customerCode: string) => {
                    return customerCode.length >= 3 && customerCode.length <= 15;
                }
            },
            lettersOnly: {
                message: 'Customer Code must be letters only',
                check: (customerCode: string) => {
                    return /^[a-zA-Z]*$/.test(customerCode);
                }
            },
        };
        for (const rule in validationRules) {
            if (!validationRules[rule].check(customerCode)) {
                isValid = false;
                validationMessages.push(validationRules[rule].message);
            }
        }
        if (validationMessages.length > 0) {
            return {isValid, validationMessages};
        }
        // Check if customer code is unique separately since it's an async operation
        const isUnique = await this.svc.isCustomerCodeUnique(customerCode, isAdditionalExtractType);
        if (!isUnique) {
            isValid = false;
            validationMessages.push('Customer Code must be unique');
        }
        return {isValid, validationMessages};
    }

}