import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { INetworksStatusStates } from './networksStatus.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { NetworksStatusService } from './networksStatus.component.service';
import { LoginService } from '../../../services/loginService.service';
import { StateService, UIRouter } from '@uirouter/core';

@Component({
    selector: 'argos-networksStatus',
    templateUrl: './networksStatus.component.html',
    styleUrls: ['./networksStatus.component.less'],
    providers: [NetworksStatusService]
})

export class NetworksStatusComponent
    extends ReactComponentBase<{}, INetworksStatusStates, {}> implements OnInit {
    constructor(private svc: NetworksStatusService, private cdr: ChangeDetectorRef, private loginServiceHandler: LoginService, private state: StateService) {
        super({
            environments: [],
            clusters: []
        } as unknown as INetworksStatusStates);
    }

    async ngOnInit() {

        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    reImport(environment: any, doNothingUpgrade: boolean) {
        this.svc.upgradeNPOEnvironment(environment, true, false, doNothingUpgrade);
    }

    upgradeNPOEnvironmentWithPresto(environment: any) {
        this.svc.upgradeNPOEnvironment(environment, false, true, false);
    }

    resizeAllMachines(type: string) {
        this.svc.resizeAllMachinesHandler(type, this.states);
    }

    stopUpgradeNPONetworks() {
        this.svc.stopUpgradeNPONetworksHandler();
    }

    reImportEnvironmentChecker(doNothingUpgrade: boolean) {
        this.svc.reImportEnvironmentCheckerHandler(doNothingUpgrade, this.states);
    }

    setMaxWorkers() {
        this.svc.setMaxWorkersHandler(this.states);
    }

    getNetworkEnvironmentEcsInfo() {
        this.svc.getNetworkEnvironmentEcsInfo(this.states);
    }

    getNetworkEnvironmentsStatus() {
        this.svc.getNetworkEnvironmentsStatus(this.states);
    }

    getEnvironments() {
        return this.svc.getEnvironments(this.states);
    }

    openLog(clusterName: any) {
        window.open('https://my.papertrailapp.com/groups/17647492/events?q=' + clusterName + '%20AND%20NOT%20%28"handling%20container%20change"%20OR%20"Managed%20task"%20OR%20"ecs-agent"%20OR%20"uptime.com"%29', '_blank');
    }

    openAWSEcsTasks(clusterName: any) {
        window.open('https://us-west-2.console.aws.amazon.com/ecs/home?region=us-west-2#/clusters/' + clusterName + '/tasks', '_blank');
    }

    getRowClass(environment: any) {
        return this.svc.getRowClass(environment);
    }

    getESCInstanceClass(environment: any) {
        return this.svc.getESCInstanceClass(environment, this.states);
    }

    getCurrentEC2(environment: any) {
        return this.svc.getCurrentEC2(environment, this.states);
    }

    getWorkerClass(environment: any) {
        return this.svc.getWorkerClass(environment, this.states);
    }

    showStatusDetails(environment: any) {
        this.svc.showStatusDetails(environment, this.states);
    }

    stopNPOUpgradeTask(environment: any) {
        this.svc.stopNPOUpgradeTask(environment, this.states);
    }

    isEcsInfoLoading() {
        return this.svc.isEcsInfoLoading(this.states);
    }

    upgradeNPOEnvironment(environment: any, doNothingUpgrade: boolean) {
        this.svc.upgradeNPOEnvironment(environment, false, false, doNothingUpgrade);
    }

    resizeMachine(environment: any, resizeType: string) {
        this.svc.resizeMachine(environment, resizeType, this.states);
    }

    updateScheduledScalingSuspendedState(environment: any, disable: boolean) {
        this.svc.updateScheduledScalingSuspendedState(environment, disable, this.states);
    }

    updateAllScheduledScalingSuspendedState(disable: boolean) {
        this.svc.updateAllScheduledScalingSuspendedState(disable, this.states);
    }

    // isSchedulerBulk() {
    //   this.svc.isSchedulerBulk(this.states);
    // }
}
