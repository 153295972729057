import { Injectable } from '@angular/core';
import { val } from '@uirouter/core';
import { values } from 'lodash';
import { ArgosStoreService } from './argosStore.service';

@Injectable()
export class PlatformReleaseResultsService {
    selectedReleaseDate: string;
    productId: any;
    argosStore: ArgosStoreService;
    activeTestResultsTab: number;

    constructor() {
        this.argosStore = new ArgosStoreService();
        const date = this.argosStore.getItem('selectedReleaseDate');
        const productname = this.argosStore.getItem('productId');
        const activeTab = this.argosStore.getItem('activeTestResultsTab');
        this.selectedReleaseDate = date || '';
        this.productId = productname || '';
        this.activeTestResultsTab = activeTab || 0;
    }

    public getSelectedDate() {
        return this.selectedReleaseDate;
    }

    public setSelectedDate(date: string) {
        this.argosStore.setItem('selectedReleaseDate', date);
        this.selectedReleaseDate = date;
    }

    public getproductId() {
        return this.productId;
    }

    public setproductId(value: any) {
        this.argosStore.setItem('productId', value);
        this.productId = value;
    }

    public getActiveTestResultsTab() {
        return this.activeTestResultsTab;
    }

    public setActiveTestResultsTab(tabIndex: number) {
        this.argosStore.setItem('activeTestResultsTab', tabIndex);
        this.activeTestResultsTab = tabIndex;
    }

}
