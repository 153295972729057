import { Component, OnInit, Inject } from "@angular/core";
import { ReactComponentBase } from "../../reactComponentBase/reactComponentBase.component";
import { IViewReleaseDagConfigModalProps, IViewReleaseDagConfigModalStates } from "./viewReleaseDagConfigModal.component.d";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewReleaseDagConfigService } from "./viewReleaseDagConfigModal.component.service";

@Component({
    selector: 'argos-release-view-dag-config-modal',
    templateUrl: './viewReleaseDagConfigModal.component.html',
    providers: [ViewReleaseDagConfigService]
})

export class ViewReleaseDagConfigModalComponent extends ReactComponentBase<IViewReleaseDagConfigModalProps, IViewReleaseDagConfigModalStates, {}>
    implements OnInit {
    constructor(private svc: ViewReleaseDagConfigService, @Inject(MAT_DIALOG_DATA) dialogData: any, private dialogRef: MatDialogRef<ViewReleaseDagConfigModalComponent>) {
            super();
            this.props = dialogData.props;
        }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    };

    close() {
        this.dialogRef.close('cancel');
    };
}