import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ITeraUsersEditProps, ITeraUsersEditStates, ITeraUsersEditService } from './teraUsersEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';

// TODO: Determine if needed!
import { ArgosStoreService } from '../../../services/argosStore.service';

import { StateService, UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MD5Service } from '../../../services/md5.service';
import { AlertService } from 'client/app/services/alert.service';
@Injectable()
export class TeraUsersEditService implements ITeraUsersEditService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog,
        private state: StateService, private md5: MD5Service,
        private alertSvc: AlertService, private cdr: ChangeDetectorRef) {
        //
    }

    async initDelegate(props: ITeraUsersEditProps, states: ITeraUsersEditStates): Promise<object> {
        await this.activate(props, states);
        return {};
    }

    async activate(props: ITeraUsersEditProps, states: ITeraUsersEditStates) {
        console.log('Loading tera user!!');
        if (props.teraUserId) {
            const teraUser = await this.getTeraUser(props.teraUserId);
            states.teraUser = teraUser;
        } else {
            states.teraUser = {
                id: null,
                ownerName: '',
                ownerTeam: '',
                ownerJiraProject: '',
                ownerEmail: '',
                slackUserId: '',
                lastModified: null
            };
        }
        states.originalTeraUser = _.cloneDeep(states.teraUser);
    }

    async getTeraUser(id: any) {
        return this.dataAccess.genericMethod({
            model: 'TeraUser', method: 'findById',
            parameters: {
                id
            }
        });
    }

    async save(states: ITeraUsersEditStates) {

        try {
            states.saveInProgress = true;
            states.teraUser.lastModified = new Date();
            states.teraUser = await this.dataAccess.genericUpsert({
                model: 'TeraUser',
                data: states.teraUser
            });
            states.saveInProgress = false;

            return { status: 'success' }
        } catch (error: any) {
            this.alertSvc.handleError(error);
            states.saveInProgress = false;
            return { status: 'failure', message: `Error saving user: ${error.body.error.message}`};
        }
    }

    async deleteTeraUser(states: ITeraUsersEditStates) {
        console.log("Delete user!!");
        try {
            await this.dataAccess.genericDelete({
                model: 'TeraUser',
                id: states.teraUser.id
            });
            return { status: 'success' }
        }
        catch (error) {
            return { status: 'failure', message: 'Check that user is the owner of any reference tables or has created deequ tests.' };
        }
    }
}
