import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ICompoundFiltersListStates, ICompoundFiltersListService } from './compoundFiltersList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { ViewEnvironmentUsageModalComponent } from '../../prismUsage/viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { MatDialogConfig, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
@Injectable()
export class CompoundFiltersListService implements ICompoundFiltersListService {
    constructor(private state: StateService, private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService) {
        //
    }
    async initDelegate(states: ICompoundFiltersListStates): Promise<Object> {
        await this.activate(states);
        return {};
    }
    async activate(states: ICompoundFiltersListStates) {
        const compoundFilters = await this.dataAccess.genericFind({
            model: 'CompoundFilter'
        });

        states.careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { order: 'title', where: { assignable: true } }
        });
        states.productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle'
        });

        const markForDeletions = await this.dataAccess.genericFind({
            model: 'MarkForDeletion',
            filter: {
                where: {
                    objectType: 'compoundFilter'
                }
            }
        });

        const usages = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage',
            method: 'aggregateUsageResults',
            parameters: {
                metaDataTypes: ['compoundFilter']
            }
        });

        states.compoundFilterData = _.map(_.orderBy(compoundFilters, ['title']), function (cf: any) {
            const usage = _.find(usages, { meta_data_name: cf.name });
            cf.usageCount = _.toNumber(_.get(usage, 'total_usage', 0));
            cf.viewCount = _.toNumber(_.get(usage, 'total_views', 0));
            cf.lastViewed = _.get(usage, 'last_viewed');
            if (cf.lastViewed) {
                cf.lastViewedFromNow = moment(cf.lastViewed).fromNow();
            } else {
                cf.lastViewedFromNow = '-';
                // set these to sort last
                cf.lastViewed = '';
            }
            cf.careGroupings = _.join(_.orderBy(_.map(cf.validCareGroupings, function (validCareGrouping) {
                const ccg = _.find(states.careGroupings, { name: validCareGrouping });
                return _.get(ccg, 'title') || validCareGrouping;
            })), ', ');
            cf.productBundleList = _.join(_.map(cf.productBundles, function (productBundle) {
                return _.get(_.find(states.productBundles, { shortName: productBundle }), 'title');
            }), ', ');
            cf.excludeFromSyncText = cf.excludeFromSync ? 'Yes' : 'No';

            const markForDeletion: any = _.find(markForDeletions, { objectName: cf.name });
            if (markForDeletion) {
                cf.markForDeletionId = markForDeletion.id;
            }

            return cf;
        });

        states.compoundFilterMatTable.data = states.compoundFilterData; 
    }

    addCompoundFilter() {
        this.state.go('argos.ccgDesigner.compoundFilter.add');
    }

    viewEnvironments(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    metaDataTypes: ['careGrouping'],
                    metaDataNames: [row.name]
                }
            }
        };
        this.matDialog.open(ViewEnvironmentUsageModalComponent, dialogConfig);
    }

    markForDeletion(row: any) {
        swal({
            title: 'Are you sure?',
            text: `Do you want to delete compound filter ${row.name}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const newRow = await this.dataAccess.genericUpsert({
                    model: 'MarkForDeletion',
                    data: {
                        objectType: 'compoundFilter',
                        objectName: row.name,
                        objectDefinition: row,
                        deletedBy: this.argosStore.getItem('username'),
                        deletedDate: new Date()
                    }
                });
                row.markForDeletionId = newRow.id;
                this.cdr.detectChanges();
            }
        });
    }

    async undelete(row: any) {
        if (row && row.markForDeletionId) {
            await this.dataAccess.genericMethod({
                model: 'MarkForDeletion',
                method: 'destroyById',
                parameters: {
                    id: row.markForDeletionId
                }
            });
            delete row.markForDeletionId;
            this.cdr.detectChanges();
        }
    }

    exportToExcel(states: ICompoundFiltersListStates) {
        const fileName = states.matTableSettingName;
        const data = states.compoundFilterMatTable.data;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(fileName, columns, data);
    }

    openMatTableSettings(states: ICompoundFiltersListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
