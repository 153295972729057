import { Injectable } from '@angular/core';
import {
    IProductConfigurationContentSyncModalProps,
    IProductConfigurationContentSyncModalStates,
    IProductConfigurationContentSyncModalService
} from './productConfigurationContentSyncModal.component.d';

@Injectable()
export class ProductConfigurationContentSyncModalService implements IProductConfigurationContentSyncModalService {
    initDelegate(props: IProductConfigurationContentSyncModalProps, states: IProductConfigurationContentSyncModalStates): object {
        states.content = props.content;
        states.title = props.title;
        return {};
    }

    changeDelegate(oldProps: IProductConfigurationContentSyncModalProps, newProps: IProductConfigurationContentSyncModalProps, states: IProductConfigurationContentSyncModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

}
