import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IQuerySettingsStates, IQuerySettingsService } from './querySettings.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { ViewEnvironmentUsageModalComponent } from '../../prismUsage/viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { AlertService } from 'client/app/services/alert.service';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { environment } from 'client/environments/environment';

@Injectable()
export class QuerySettingsService implements IQuerySettingsService {
    constructor(private dataAccess: NgDataAccess, private dialog: MatDialog,
        private alertSvc: AlertService, private cdr: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService) {
        //
    }

    async initDelegate(states: IQuerySettingsStates): Promise<object> {
        return this.getEnvironments();
    }

    async getEnvironments(): Promise<object> {

        let environments: any = await this.dataAccess.genericFind({
            model: 'Environment',
            filter: {
                where: {
                    hostingProvider: 'ecs',
                    applicationType: 'prism',
                    hostingAppName: { neq: null },
                    isDisabled: false,
                    isArchived: false,
                    isQueryExempt: false
                },
                include: 'client'
            }
        });

        _.forEach(environments, e => {
            if (e.dnsCname) {
                e.url = 'https://' + e.dnsCname + '.clarifyhealth.' + e.domainSuffix;
            }
        });

        environments = _.orderBy(environments, 'url');
        return environments;
    }

    async fetchResults(states: IQuerySettingsStates): Promise<void> {
        states.queryRunning = true;
        states.searchResults = [];
        const selectedEnvs: any[] = states.selectedEnvironmentUrls.value;

        // filter out toggles
        const filteredEnvs = selectedEnvs.filter( x => !states.environmentTypes.includes(x) );

        Promise.all(filteredEnvs.map(async (env) => {

            var queryResult = await this.dataAccess.genericMethod({
                model: 'Environment', method: `getQuerySettings`, parameters: {
                    environmentId: env.id
                }
            });

            if (_.isEmpty(queryResult)) {
                states.queryFailedMessage.push(env.url)
                this.cdr.detectChanges();
            } else {
                return queryResult;
            }

        })).then((results) => {

            results = results.filter(function( element ) {
               return element !== undefined;
            });

            _.forEach(results, (result: any) => {
                states.searchResults = states.searchResults.concat(result);
            });

            // add filter for default value overrides
            _.forEach(states.searchResults, (r: any) => {
                r.isDefaultDiff = (r.value !== r.default).toString();
            });

            states.environmentMatTable.data = states.searchResults;
            states.queryRunning = false;
            this.cdr.detectChanges();
        }).catch((error: any) => {
            return;
        });
    }

    generateToggles(states: IQuerySettingsStates) {
        for (var env of states.environments) {
            if (states.environmentTypes.indexOf(env.environmentType) === -1) {
                states.environmentTypes.push(env.environmentType);
            }
        }
    }

    toggleEnvironmentType(states: IQuerySettingsStates, selectedEnvironmentTypes: Array<String>) {
        return states.selectedEnvironmentUrls.value = states.environments.filter(env => selectedEnvironmentTypes.includes(env.environmentType));
    }

    exportToExcel(states: IQuerySettingsStates) { 
        const exportFileName = states.environmentMatTableSettingName;
        const data = states.environmentMatTable.data;
        const columns = states.matTableColumns;
        
        this.exportToExcelSvc.exportToFile(exportFileName, columns, data); 
    }

    openMatTableSettings(states: IQuerySettingsStates) { 
        let data = { 
            tableColumns: states.matTableColumns,
            settingName: states.environmentMatTableSettingName
        };
        const dialogRef = this.dialog.open(MatTableSettingsModelComponent, {
            data,
        });
        
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe((result: any) => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
