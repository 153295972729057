import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IProductBundlesListStates, IProductBundlesListService } from './productBundlesList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewClientUsageModalComponent } from '../../prismUsage/viewClientUsageModel/viewClientUsageModal.component';
import * as _ from 'lodash';

@Injectable()
export class ProductBundlesListService implements IProductBundlesListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private changeDetection: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService) {
        //
    }
    async initDelegate(states: IProductBundlesListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IProductBundlesListStates) {
        states.productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle'
        });

        let productConfigs = await this.dataAccess.genericFind({
            model: 'ProductConfiguration'
        });

        let clientEnvSelectedProducts = await this.dataAccess.genericFind({
            model: 'ClientProductConfiguration',
            filter: {
                include: {
                    relation: 'client', // include the owner object
                    scope: { // further filter the owner object
                        include: { // include environments for each client
                            relation: 'environments'
                        }
                    }
                }
            }
        });

        _.forEach(states.productBundles, (productBundle: any) => { 
            //determine which productConfigs are associated with this productBundle
            let pcs =_.filter(productConfigs, (productConfig: any) => {
                return _.includes(productConfig.config.productBundles, productBundle.shortName);
            }) 
            productBundle.productConfigs = pcs;

            //determine which clients are associated with this productBundle
            let clientProducts = _.uniqBy(_.filter(clientEnvSelectedProducts, (clientProduct: any) => {
                return _.includes(_.map(productBundle.productConfigs, 'name'), clientProduct.productConfiguration);
            }), 'clientId');

            //assign and clean up lists
            productBundle.clientProducts = clientProducts; 
            productBundle.uniqueClients = _.flatten(_.map(clientProducts, (clientProduct: any) => {
                
                let result = _.map(_.filter(clientProduct.client.environments, (e: any) => { return (!e.isDisabled && !e.isArchived) }), (env: any) => {
                    let envInfo = {
                        clientName: clientProduct.client.name,
                        environmentId: env.id,
                        url: env.dnsCname + '.clarifyhealth.' + env.domainSuffix,
                        clientId: clientProduct.client.id,
                        environmentType: env.environmentType
                    };
                    return envInfo;
                });

                return result;
            }));

            productBundle.uniqueClients = _.orderBy(productBundle.uniqueClients, ['clientName', 'url'], ['asc']);

            
            
        });

        states.productBundleTable.data = _.orderBy(states.productBundles, ['shortName', 'title']);
    }

    async saveRowHandler(row: any) {
        // force the shortName to be camelCase
        row.shortName = _.camelCase(row.shortName);
        await this.dataAccess.genericUpsert({
            model: 'ProductBundle',
            data: row
        });
    }

    exportToExcel(states: IProductBundlesListStates) {

        const data = states.productBundleTable.data;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IProductBundlesListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe((result: any) => {
            setTimeout(() => {
                this.changeDetection.detectChanges();
            }, 1000);
        });
    }

    viewClientUsage(row: any, states: IProductBundlesListStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-large',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    productName: row.title,
                    clientInfo: row.uniqueClients
                }
            }
        };
        this.matDialog.open(ViewClientUsageModalComponent, dialogConfig);
    }
}
