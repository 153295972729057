import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IHierarchyListStates } from './hierarchyList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { HierarchyListService } from './hierarchyList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-hierarchyList',
    templateUrl: './hierarchyList.component.html',
    providers: [HierarchyListService]
})

export class HierarchyListComponent 
    extends ReactComponentBase<{}, IHierarchyListStates, {}> 
    implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;

    constructor(private svc: HierarchyListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil,  private urlService: UrlService) {
        super({
            observationCategoryHierarchies: [],
            filterObj: {
                matchExtactColumnFilterValue: ['status']    // set this to filter property to exact match
            },
            defaultDisplayMatTableColumns: ['editButton', 'category', 'category2', 'category3', 'category4'],
            matTableColumns: ['editButton', 'category', 'category2', 'category3', 'category4'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'hierarchyList'
        } as unknown as IHierarchyListStates);
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.table.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.table.filter);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        return this.matSvc.getMatSelectPlaceholderName(columnName, filterValue);
    }
}
