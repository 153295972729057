import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IEditEnvironmentDatasourceFilterModalProps, IEditEnvironmentDatasourceFilterModalStates } from './editEnvironmentDatasourceFilterModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EditEnvironmentDatasourceFilterModalService } from './editEnvironmentDatasourceFilterModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-editEnvironmentDatasourceFilterModal',
    templateUrl: './editEnvironmentDatasourceFilterModal.component.html',
    providers: [EditEnvironmentDatasourceFilterModalService]
})

export class EditEnvironmentDatasourceFilterModalComponent extends ReactComponentBase<IEditEnvironmentDatasourceFilterModalProps, IEditEnvironmentDatasourceFilterModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: EditEnvironmentDatasourceFilterModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<EditEnvironmentDatasourceFilterModalComponent>) {
        super({
            selectedDataSourceName: {},
            dataSourceObj: {},
            careGroupings: [],
            selectedObservations: [],
            dataFilterFields: [
                { id: 1, field: 'ccn', condition: '' },
                { id: 2, field: 'state code', condition: '' },
                { id: 3, field: 'zip', condition: '' },
                { id: 4, field: 'ssa county code', condition: '' },
                { id: 5, field: 'hospital npi', condition: '' },
                { id: 6, field: 'physician npi', condition: '' },
                { id: 7, field: 'drg', condition: '' }]
        } as unknown as IEditEnvironmentDatasourceFilterModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close(null);
    }

    careGroupingChanged() {
        this.svc.careGroupingChanged(this.states);
    }

    save() {
        this.svc.save(this.states);
        this.dialogRef.close(this.states.dataSourceObj);
    }

    queryFilterChange() {
        this.svc.queryFilterChange(this.states);
    }

    selectedDataFilterChanged() {
        this.svc.selectedDataFilterChanged(this.states);
    }

    fieldChanged() {
        this.svc.fieldChanged(this.states);
    }

    buildEcsSiteFieldChanged() {
        // this.svc.buildEcsSiteFieldChanged(this.states);
    }
}
