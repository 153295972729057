import { Directive, ElementRef, NgZone, DoCheck } from '@angular/core';

@Directive({ selector: '[ngxTableResizeInitiator]' })
export class NgxTableResizeInitiatorDirective implements DoCheck {
    offsetHeight = 0;
    offsetWidth = 0;

    constructor(private element: ElementRef, private zone: NgZone) { }

    onSizeChange(): void {
        this.zone.runOutsideAngular(() => {
            this.zone.run(() => {
                // const evt = document.createEvent('UIEvents');
                // evt.initUIEvent('resize', true, false, window, 0);
                const evt = new Event('UIEvents', { bubbles: true, cancelable: false });
                document.dispatchEvent(evt);
            });
        });
    }

    ngDoCheck() {
        const { offsetHeight, offsetWidth } = this.element.nativeElement;

        if (offsetHeight !== this.offsetHeight || offsetWidth !== this.offsetWidth) {
            this.offsetWidth = offsetWidth;
            this.offsetHeight = offsetHeight;
            this.onSizeChange();
        }
    }
}
