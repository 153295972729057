import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IDashboardStates } from './dashboard.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { DashboardService } from './dashboard.component.service';

import * as moment from 'moment';

@Component({
    selector: 'argos-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
    providers: [DashboardService]
})

export class DashboardComponent extends ReactComponentBase<{}, IDashboardStates, {}> implements OnInit {
    constructor(private svc: DashboardService, private cdr: ChangeDetectorRef) {
        super({
            datePickerOptions: {
                ranges: {
                    '1 Month Back': [moment().subtract(1, 'month').endOf('month'), moment().endOf('month')],
                    '3 Months Back': [moment().subtract(3, 'month').endOf('month'), moment().endOf('month')],
                    '6 Months Back': [moment().subtract(6, 'month').endOf('month'), moment().endOf('month')],
                    '9 Months Back': [moment().subtract(9, 'month').endOf('month'), moment().endOf('month')],
                    '12 Months Back': [moment().subtract(12, 'month').endOf('month'), moment().endOf('month')],
                    '15 Months Back': [moment().subtract(15, 'month').endOf('month'), moment().endOf('month')],
                    '18 Months Back': [moment().subtract(18, 'month').endOf('month'), moment().endOf('month')],
                    '21 Months Back': [moment().subtract(21, 'month').endOf('month'), moment().endOf('month')],
                    '24 Months Back': [moment().subtract(24, 'month').endOf('month'), moment().endOf('month')]
                },
                alwaysShowCalendars: true,
                locale: {
                    format: 'MMM YYYY'
                }
            },
            monthLabelColSpan: 4,
            metricToggles: { total: true, dau: true, mau: true, daumau: true },
            datePicker: {
                date: {
                    startDate: moment().subtract(12, 'month').endOf('month').toDate(),
                    endDate: moment().endOf('month').toDate()
                }
            },
            totalOnboardedPatients: 0,
            monthlyActiveUsers: 0,
            dailyActiveUsers: 0,
            daumau: 0,
            environments: [],
            months: []
        } as unknown as IDashboardStates
        );
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    export() {
        this.svc.export(this.states);
    }
    exportEpisodeInfo() {
        this.svc.exportEpisodeInfo(this.states);
    }
    environmentEpisodeDropdownChanged() {
        this.svc.environmentEpisodeDropdownChanged(this.states);
    }

    metricTogglesChanged() {
        this.svc.metricTogglesChanged(this.states);
    }

    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        if (dateRangeStart.value.length > 0 && dateRangeEnd.value.length > 0) {
            this.svc.dateRangeChange(this.states, moment(dateRangeStart.value, 'MM-DD-YYYY').toDate(), moment(dateRangeEnd.value, 'MM-DD-YYYY').toDate());
            this.cdr.detectChanges();
        }
    }
}
