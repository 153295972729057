import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DataReleaseManagerServices } from './dataReleaseManager.component.service'; 
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { IDataReleaseManagerStates } from './dataReleaseManager.component.d';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort} from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'argos-data-release-manager-list',
    templateUrl: './dataReleaseManager.component.html',
    providers: [DataReleaseManagerServices]
})

export class DataReleaseManagerComponent extends ReactComponentBase<{}, IDataReleaseManagerStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;

    constructor(private svc: DataReleaseManagerServices, private cdr: ChangeDetectorRef) {
        super({
            dataReleases: new MatTableDataSource([]),
            filterStatus: 'In-Progress',
            filteredDataReleases: new MatTableDataSource([])
        });
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.states.filteredDataReleases.data = await this.svc.filterDataByStatus(this.states);
        await this.ngAfterViewInit();
    }

    async ngAfterViewInit() {
        setTimeout(async () => {
            this.states.pageSize = 10;
            this.states.filteredDataReleases.paginator = this.paginator;
            this.states.filteredDataReleases.sort = this.matTbSort;
            this.cdr.detectChanges();
        })
    }

    openJiraTicket(jiraTicketId: string) {
        const baseJiraUrl = 'https://clarifyhealth.atlassian.net/browse/';
        window.open(baseJiraUrl + jiraTicketId, '_blank');
    }

    async changeEvent(event: any) {
        this.states.filterStatus = event
        this.states.filteredDataReleases.data = await this.svc.filterDataByStatus(this.states);
    }

    async cancelRelease(releaseId: string) {
        await this.svc.cancelRelease(releaseId);
        this.states.dataReleases.data = await this.svc.queryReleases();
        this.states.filteredDataReleases.data = await this.svc.filterDataByStatus(this.states);
    }
}
