import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IProgramEpisodeListStates, IProgramEpisodeListService, IProgramEpisode } from './programEpisodeList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class ProgramEpisodeListService implements IProgramEpisodeListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, private cdr: ChangeDetectorRef) {
        //
    }

    async initDelegate(states: IProgramEpisodeListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IProgramEpisodeListStates) {
        const programEpisodeObjs = await this.dataAccess.genericFind({
            model: 'ProgramEpisode',
            filter: {
                order: 'name',
                include: ['programModel', {
                    relation: 'ccgType',
                    scope: {
                        fields: ['name']
                    }
                }]
            }
        });

        programEpisodeObjs.forEach(async (prgEpiObj: IProgramEpisode) => {
            const total = await this.dataAccess.genericCount({
                model: 'ProgramEpisodeObservation',
                where: { programEpisodeId: prgEpiObj.id }
            });
            prgEpiObj.observationsAssigned = total.count;
            prgEpiObj.ccgTypeName = prgEpiObj.ccgType?.name;
            prgEpiObj.programModelName = prgEpiObj.programModel?.name;
        });

        states.programEpisodes = _.orderBy(programEpisodeObjs, ['programModelId']);
        states.table.data = states.programEpisodes;
    }

    setTablePageSize(states: IProgramEpisodeListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.programEpisodes.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IProgramEpisodeListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IProgramEpisodeListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
