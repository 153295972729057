import { Component, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClarifyInsightsFinalizeCustomerModalService } from './clarifyInsightsFinalizeCustomerModal.component.service';
import { IClarifyInsightsFinalizeCustomerModalInterface } from './clarifyInsightsFinalizeCustomerModal.component.d';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MD5Service } from '../../../services/md5.service';

@Component({
    selector: 'argos-clarifyInsightsFinalizeCustomerModal',
    templateUrl: './clarifyInsightsFinalizeCustomerModal.component.html',
    providers: [ClarifyInsightsFinalizeCustomerModalService, MD5Service]
})

export class ClarifyInsightsFinalizeCustomerModalComponent extends ReactComponentBase<{}, IClarifyInsightsFinalizeCustomerModalInterface, {}> implements OnInit {
    constructor(private svc: ClarifyInsightsFinalizeCustomerModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
        public dialogRef: MatDialogRef<ClarifyInsightsFinalizeCustomerModalComponent>, private SnackBar: MatSnackBar) {
        super({
            customerId: '',
            customerCode: '',
            s3DestinationBucket: '',
            waitNotification: false
        } as IClarifyInsightsFinalizeCustomerModalInterface);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        const newStates = this.svc.initDelegate(this.props, this.states);
        this.setStates(newStates);
    }

    async finalizeAccount(awsAccountId: any) {
        const row = {
            awsAccountId,
            customerId: this.states.customerId
        };
        this.states.waitNotification = true;
        const response = await this.svc.finalizeAccountHelper(row, this.states.customerCode, this.states.s3DestinationBucket);
        this.states.waitNotification = false;
        this.dialogRef.close('done');
        this.SnackBar.open(response, 'close', { panelClass: 'snack-bar-success' });
    }

    close() {
        this.dialogRef.close('cancel');
    }
}
