import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IReferenceDataSourcesEditStates, IReferenceDataSourcesEditService } from './referenceDataSourcesEdit.component.d';
import { TeraUsersEditComponent } from '../../teraUsers/teraUsersEdit/teraUsersEdit.component';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { StateService, UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewReferenceDataSourceHistoryModalComponent } from '../viewReferenceDataSourceHistoryModal/viewReferenceDataSourceHistoryModal.component';
import { ViewTeraUsersModalComponent } from '../viewTeraUsersModal/viewTeraUsersModal.component';
import { MD5Service } from '../../../services/md5.service';
import { AlertService } from 'client/app/services/alert.service';
@Injectable()
export class ReferenceDataSourcesEditService implements IReferenceDataSourcesEditService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog,
        private state: StateService, private md5: MD5Service,
        private alertSvc: AlertService, private cdr: ChangeDetectorRef) {
        //
    }

    async initDelegate(states: IReferenceDataSourcesEditStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IReferenceDataSourcesEditStates) {
        states.teraUsers = await this.getTeraUsers();
        states.referenceDataClasses = await this.getReferenceDataClasses();
        states.fullReferenceDataClasses = await this.dataAccess.genericFind({
            model: 'ReferenceDataClass'
        });
        if (this.uiRouter.globals.params.id) {
            const referenceDataSource = await this.getReferenceDataSource(states);
            states.referenceDataSource = referenceDataSource;

            const urlEndPoint = (() => {
                switch (states.referenceDataSource.referenceDataClass.source) {
                    case 'argos_sql': return 'argos_sql/' + states.referenceDataSource.tableName + '.sql';
                    case 'argos_table': return 'argos_table/' + states.referenceDataSource.tableName + '.json';
                    case 'athena_sql': return 'athena_sql/' + states.referenceDataSource.tableName + '.sql';
                    case 'git_csv': return 'csv/' + states.referenceDataSource.tableName + '.csv';
                    case 'url_custom': return 'url_custom/' + states.referenceDataSource.referenceDataClass.className + '.json';
                    case 'url_generic': return 'url_generic/' + states.referenceDataSource.tableName + '.json';
                    default: return null;
                }
            })();
            states.refDataRepoLink = 'https://github.com/clarifyhealth/reference_data/blob/main/' + urlEndPoint;
            states.deequTests = await this.getDeequTests(states);

            const dagConfigs: any = await this.dataAccess.genericMethod({
                model: 'ReferenceData',
                method: 'getDagConfigs',
                parameters: {
                    referenceDataSources: [states.referenceDataSource]
                }
            });

            const [{ pipelineTriggerText, dagId, dagConfig, promotionTriggerText, promotionDagId, promotionDagConfig }] = dagConfigs;
            Object.assign(states, { pipelineTriggerText, dagId, dagConfig, promotionTriggerText, promotionDagId, promotionDagConfig });
            

            if (referenceDataSource.pipelineStartTm) {
                await this.getAvgPipelineRunTime(states);
            }
        } else {
            const ownerInfo: any = _.find(states.teraUsers, { ownerEmail: this.argosStore.getItem('username') });

            states.referenceDataSource = {
                id: null,
                referenceDataClass: {
                    teraUser: {}
                },
                teradromeColumn: [],
                teradromeTable: {
                    description: undefined
                }
            };

            if (ownerInfo) {
                states.referenceDataSource.referenceDataClass.ownerId = ownerInfo.id;
                states.referenceDataSource.referenceDataClass.teraUser = {
                    id: ownerInfo.id,
                    ownerName: ownerInfo.ownerName,
                    ownerEmail: ownerInfo.ownerEmail,
                    ownerJiraProject: ownerInfo.ownerJiraProject,
                    ownerTeam: ownerInfo.ownerTeam
                };
            }

            states.deequTests = [];
        }
        states.originalReferenceDataSource = _.cloneDeep(states.referenceDataSource);

    }

    async getReferenceDataSource(states: IReferenceDataSourcesEditStates) {
        let id = this.uiRouter.globals.params.id;
        if (states.referenceDataSource && states.referenceDataSource.id) {
            id = states.referenceDataSource.id;
        }
        const data = await this.dataAccess.genericMethod({
            model: 'ReferenceData', method: 'findById',
            parameters: {
                id,
                filter: {
                    include: [
                        'referenceDataHistories',
                        'teradromeColumn',
                        'teradromeTable',
                        {
                            relation: 'referenceDataClass',
                            scope: {
                                include: 'teraUser'
                            }
                        }
                    ]
                }
            }
        });

        if (!data.referenceDataClass.teraUser) {
            data.referenceDataClass.teraUser = {};

        }
        // Remove any columns in teradromeColumn that are deactived
        if (data.teradromeColumn) {
            data.teradromeColumn = _.filter(data.teradromeColumn, { deletedTimestamp: null });
        }
        return data;
    }

    async getTeraUsers() {
        return _.orderBy(await this.dataAccess.genericFind({
            model: 'TeraUser'
        }), 'ownerName');
    }

    async getReferenceDataClasses() {
        const refDataClass = await this.dataAccess.genericFind({
            model: 'ReferenceDataClass',
            filter: { where: { source: 'url_custom' } }
        });
        return _.orderBy(_.uniq(_.map(refDataClass, 'className')));
    }

    async getDeequTests(states: IReferenceDataSourcesEditStates) {
        if (states.referenceDataSource.teradromeTableId) {
            return this.dataAccess.genericFind({
                model: 'TeradromeDeequTest',
                filter: { where: { teradromeTableId: states.referenceDataSource.teradromeTableId } }
            });
        }
        return [];
    }

    ownerChanged(states: IReferenceDataSourcesEditStates) {
        const ownerInfo: any = _.find(states.teraUsers, { id: states.referenceDataSource.referenceDataClass.ownerId });
        if (!states.referenceDataSource.referenceDataClass.teraUser) {
            states.referenceDataSource.referenceDataClass.teraUser = {};
        }
        states.referenceDataSource.referenceDataClass.teraUser.ownerEmail = ownerInfo.ownerEmail;
        states.referenceDataSource.referenceDataClass.teraUser.ownerJiraProject = ownerInfo.ownerJiraProject;
        states.referenceDataSource.referenceDataClass.teraUser.ownerTeam = ownerInfo.ownerTeam;
        states.referenceDataSource.referenceDataClass.teraUser.ownerName = ownerInfo.ownerName;
    }

    async saveNewUrlCustomClass(states: IReferenceDataSourcesEditStates) {
        states.addUrlCustomClass = false;
        states.referenceDataClasses.unshift(states.newUrlCustomClass);
        states.referenceDataSource.className = states.newUrlCustomClass;
        if (!states.addedClassNames) {
            states.addedClassNames = [states.newUrlCustomClass];
        } else {
            states.addedClassNames.push(states.newUrlCustomClass);
        }
    }

    async tableNameChangeHandler(states: IReferenceDataSourcesEditStates) {
        states.tableformExists = false;

        if (states.referenceDataSource && states.referenceDataSource.tableName && states.referenceDataSource.tableName.length > 0) {
            const response = await this.dataAccess.genericFind({
                model: 'ReferenceData'
            });

            if (response.filter((e: any) => e.tableName === states.referenceDataSource.tableName).length > 0) {
                states.tableformExists = true;
                this.cdr.detectChanges();
            }
        }
    }

    async save(states: IReferenceDataSourcesEditStates) {
        states.saveInProgress = true;

        if (!states.referenceDataSource.id) {
            if (states.referenceDataSource.className) {
                states.referenceDataSource.referenceDataClass.className = states.referenceDataSource.className;
            } else {
                states.referenceDataSource.referenceDataClass.className = states.referenceDataSource.tableName;
            }

        }

        try {
            // save
            const classData = await this.dataAccess.genericUpsert({
                model: 'ReferenceDataClass',
                data: states.referenceDataSource.referenceDataClass
            });

            if (!_.isEqual(states.originalReferenceDataSource.teradromeTable.description, states.referenceDataSource.teradromeTable.description)) {
                await this.dataAccess.genericUpsert({
                    model: 'TeradromeTable',
                    data: states.referenceDataSource.teradromeTable
                });
            }

            states.referenceDataSource.lastModified = Date.now();

            if (!states.referenceDataSource.id) {
                // Add new table to teradromeTable
                const teradromeTable = await this.dataAccess.genericUpsert({
                    model: 'TeradromeTable',
                    data: {
                        tableName: states.referenceDataSource.tableName,
                        teradromeStageId: 21, // reference data stage
                        lastReported: states.referenceDataSource.lastModified
                    }
                });

                states.referenceDataSource.teradromeTableId = teradromeTable.id;

                states.referenceDataSource.classId = classData.id;
            }

            if (states.referenceDataSource.teradromeColumn) {
                await this.saveReferenceDataColumn(states);
            }

            // Need if for new table
            states.referenceDataSource = await this.dataAccess.genericUpsert({
                model: 'ReferenceData',
                data: states.referenceDataSource
            });

            // Reload for history insert w/ existing or new id
            states.referenceDataSource = await this.getReferenceDataSource(states);

            await this.saveReferenceDataHistory(states);
            this.state.go('argos.referenceData.referenceDataSources.list');
            states.saveInProgress = false;
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    async saveReferenceDataHistory(states: IReferenceDataSourcesEditStates) {
        // Only save a new version if something actually changed.
        if (_.isEqual(states.originalReferenceDataSource, states.referenceDataSource)) {
            return Promise.resolve();
        }

        const updates = [states.referenceDataSource];

        if (!_.isEqual(states.originalReferenceDataSource.referenceDataClass, states.referenceDataSource.referenceDataClass)) {
            const classes = await this.dataAccess.genericFind({
                model: 'ReferenceData',
                filter: {
                    include: [
                        'referenceDataHistories',
                        {
                            relation: 'referenceDataClass',
                            scope: {
                                include: 'teraUser'
                            }
                        }
                    ],
                    where: { classId: states.referenceDataSource.classId }
                }
            });

            _.forEach(classes, function (c) {
                c.referenceDataClass = states.referenceDataSource.referenceDataClass;
                c.classId = states.referenceDataSource.classId;
                if (c.id !== states.referenceDataSource.id) {
                    updates.push(c);
                }
            });
        }

        const username = this.argosStore.getItem('username');
        await Promise.all(_.map(updates, (u: any) => {
            let version = 0;
            if (u && u.referenceDataHistories && u.referenceDataHistories.length > 0) {
                version = _.max(_.map(u.referenceDataHistories, 'version')) || 0;
            }
            version++;

            delete u.referenceDataHistories;
            const newObservationHistory = this.dataAccess.genericUpsert({
                model: 'ReferenceDataHistory',
                data: {
                    referenceDataId: u.id,
                    version,
                    createdBy: username,
                    creationDate: new Date(),
                    definition: u
                }
            });
            return newObservationHistory;
        }
        ));
    }

    async saveReferenceDataColumn(states: IReferenceDataSourcesEditStates) {
        if (!_.isEqual(states.originalReferenceDataSource.teradromeColumn, states.referenceDataSource.teradromeColumn)) {
            const updatedRows = states.referenceDataSource.teradromeColumn.filter(function(obj: any) {
                return states.originalReferenceDataSource.teradromeColumn.some(function(obj2: any) {
                    return obj.id === obj2.id && JSON.stringify(obj) !== JSON.stringify(obj2) || (!obj.id && obj.columnName && obj.dataType);
                });
            });
            const savePromises: any = [];
            const dataAccess = this.dataAccess;

            updatedRows.forEach(function (row: any) {
                const careGroupingHistor = dataAccess.genericUpsert({
                    model: 'TeradromeColumn',
                    data: row
                });
                savePromises.push(careGroupingHistor);
            });

            await Promise.all(savePromises);
        }
    }

    addNewTeraUser(states: IReferenceDataSourcesEditStates) {

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            width: '1500px',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    teraUserId: ''
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            TeraUsersEditComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result) => {
            this.matDialog.closeAll();
            states.referenceDataSource.referenceDataClass.ownerId = result.teraUser.id;
            states.teraUsers = [...states.teraUsers, result.teraUser];
            this.ownerChanged(states);
            this.cdr.detectChanges();
        });
    }

    viewHistory(states: IReferenceDataSourcesEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    referenceDataSource: states.referenceDataSource
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewReferenceDataSourceHistoryModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            this.matDialog.closeAll();
            // modalInstance = undefined;
            // modalInstance.close();
        });
    }

    deleteReferenceTable(states: IReferenceDataSourcesEditStates) {
        swal({
            title: 'Confirm ' + states.referenceDataSource.tableName + ' Table Deletion',
            text: 'Are you sure you want to delete ' + states.referenceDataSource.tableName + '? Please confirm table is no longer being generated by the reference data pipeline and that it is not being used by Atlas or downstream pipelines before deletion. Please note, you will lose all Deequ tests associated with this table with deletion.',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                // Delete from TeradromeTable. Cascade delete will take care of the rest of the tables
                if (states.referenceDataSource.teradromeTableId) {
                    await this.dataAccess.genericMethod({
                        model: 'TeradromeTable', method: 'deleteAll',
                        parameters: { where: { id: states.referenceDataSource.teradromeTableId } }
                    });
                }

                const refDataClass = await this.dataAccess.genericFind({
                    model: 'ReferenceDataClass',
                    filter: {
                        where: {
                            id: states.referenceDataSource.classId
                        },
                        include: ['referenceData'] }
                });

                // Check to see if only this table is in the class, so we also delete the class
                if (refDataClass[0].referenceData.length === 1) {
                    await this.dataAccess.genericDelete({
                        model: 'ReferenceDataClass',
                        id: refDataClass[0].id
                    });
                }
                // Delete the ref data class
                await this.dataAccess.genericDelete({
                    model: 'ReferenceData',
                    id: this.uiRouter.globals.params.id
                });

                this.state.go('argos.referenceData.referenceDataSources.list');
            }
        });
    }

    canColumnBePrimaryKey(columnName: string) {
        const nonPrimaryKeyColumns = ['file_set', 'file_path', 'data_source', 'vendor_file_set'];
        return !nonPrimaryKeyColumns.includes(columnName);
    }

    async triggerPipeline(states: IReferenceDataSourcesEditStates, isPromotion: boolean) {
        const triggeredByUser = await this.argosStore.getItem('username').replace('@clarifyhealth.com', '');
        const dagId = isPromotion ? states.promotionDagId : states.dagId;
        const dagConfig = isPromotion ? states.promotionDagConfig : states.dagConfig;
        states.referenceDataSource.pipelineIsPromotion = isPromotion;


        const astronomerResponse = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'triggerDag',
            parameters: {
                dagId, 
                dagConfig: JSON.stringify(dagConfig),
                triggeredByUser,
            }
        });

        const dagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });

        const airflowUrl = `${dagsUrl.data}/${dagId}/grid?dag_run_id=${encodeURIComponent(astronomerResponse.data.dag_run_id)}`;
        const pipelineStartTm = Date.now();

        // Need to populate url and start time in both states and the reference data source, so reflected regardles of save
        states.referenceDataSource.airflowUrl = airflowUrl;
        states.referenceDataSource.pipelineStartTm = pipelineStartTm;

        const referenceDataSource = await this.getReferenceDataSource(states);
        referenceDataSource.airflowUrl = airflowUrl;
        referenceDataSource.pipelineStartTm = pipelineStartTm;
        referenceDataSource.pipelineIsPromotion = isPromotion;
        await this.dataAccess.genericUpsert({
            model: 'ReferenceData',
            data: referenceDataSource
        });

        await this.getAvgPipelineRunTime(states);

        swal({
            title: 'Pipeline Started Successfully',
            text: 'Would you like to go to Airflow to view the running pipeline?',
            type: 'success',
            showCancelButton: true,
            confirmButtonColor: '#57C84D',
            confirmButtonText: 'Go to Airflow',
            cancelButtonText: 'Back to Edit'
        }).then((thirdConfirm: any) => {
            if (thirdConfirm.value) {
                window.open(airflowUrl, '_blank');
            }
        });
    }

    async getAvgPipelineRunTime(states: IReferenceDataSourcesEditStates) {
        const dag = states.referenceDataSource.pipelineIsPromotion ? states.promotionDagId : states.dagId;

        const pipelineHistories = await this.dataAccess.genericFind({
            model: 'PipelineHistory',
            filter: {
                where: {
                    pipelineName: { like: `airflow.${dag}%` },
                    status: 'SUCCESS'
                },
                order: 'end_tm DESC',
                limit: 10
            }
        });
        if (pipelineHistories.length === 0) {
            const avgRuntime = await this.dataAccess.genericMethod({
                model: 'Astronomer',
                method: 'getAverageDagRuntime',
                parameters: {
                    dagId: dag,
                }
            });
            states.avgRuntime = avgRuntime?.data;
        } else {
            states.avgRuntime = _.meanBy(pipelineHistories, (ph: any) => {
                return (new Date(ph.endTime).getTime() - new Date(ph.startTime).getTime()) / 1000;
            });
        }

        this.getProgress(states);

        states.intervalId = window.setInterval(() => {
            this.getProgress(states);
            if (states.pipelineProgress >= 100) {
                clearInterval(states.intervalId);
            }
        }, 10000);
    }

    getProgress(states: IReferenceDataSourcesEditStates) {
        const now = Date.now();
        const pipelineStartTm: any = new Date(states.referenceDataSource.pipelineStartTm).getTime();
        const elapsedTime = (now - pipelineStartTm) / 1000;
        // If the pipeline has been running for more than a day, allow the user to trigger it again
        if (elapsedTime > 86400) {
            states.avgRuntime = null;
            states.pipelineProgress = 100;
            states.referenceDataSource.airflowUrl = null;
        } else {
            const progress = (elapsedTime / states.avgRuntime) * 100;
            states.pipelineProgress =  Math.min(progress, 100);
        }
        this.cdr.detectChanges();
    }

    openAirflowUrl(states: IReferenceDataSourcesEditStates) {
        window.open(states.referenceDataSource.airflowUrl, '_blank');
    }
}
