import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IScheduledScalingStateModelProps, IScheduledScalingStateModelStates, IScheduledScalingStateModelService } from './scheduledScalingStateModel.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';

import { from, of } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class ScheduledScalingStateModelService implements IScheduledScalingStateModelService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef) {
    }

    initDelegate(props: IScheduledScalingStateModelProps, states: IScheduledScalingStateModelStates): object {
        return {};
    }

    async upsertBulkScheduleStatus(props: IScheduledScalingStateModelProps) {

        let incremental = 90 / props.clusters.length; // dont use 100 so we control the final step

        props.clusters = _.map(props.clusters, o => _.extend({ scheduledScalingUpdateStatus: 'pending' }, o));
        this.cdr.detectChanges();

        for (let i = 0; i < props.clusters.length; i++) {
            const c = props.clusters[i];
            c.scheduledScalingUpdateStatus = 'updating';
            props.progressValue = incremental;
            this.cdr.detectChanges();

            if (props.cancel) {
                break;
            }

            const response = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsServiceInfo', parameters: {
                    clusterName: c.clusterName,
                    serviceNames: c.services,
                    awsAccountName: c.accountName
                }
            });

            const promises = [];
            for (let j = 0; j < response.scalableTargets.length; j++) {
                const scheduledTarget = response.scalableTargets[j];

                scheduledTarget.SuspendedState.ScheduledScalingSuspended = props.disable;

                promises.push(this.dataAccess.genericMethod({
                    model: 'Environment', method: 'updateEcsServiceScheduleSuspendedState',
                    parameters: {
                        clusterName: c.clusterName,
                        scalableTarget: scheduledTarget,
                        requestedBy: this.argosStore.getItem('username'),
                        awsAccountName: c.accountName
                    }
                }));
            }

            if (promises.length > 0) {
                // isSuccessful
                const results = await Promise.all(promises);
                const succeeded = _.sum(_.map(results, (r) => {
                    return r.isSuccessful ? 1 : 0;
                }));
                c.scheduledScalingUpdateStatus = (promises.length === succeeded) ? 'success' : 'failed';
            } else {
                c.scheduledScalingUpdateStatus = 'no scheduled scaling found';
            }

            incremental += incremental;
        } // for

        props.progressValue = 100;  // completed
    }

    changeDelegate(oldProps: IScheduledScalingStateModelProps, newProps: IScheduledScalingStateModelProps, states: IScheduledScalingStateModelStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

}
