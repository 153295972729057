import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import {
    IScheduledScalingStateModelProps,
    IScheduledScalingStateModelStates
} from './scheduledScalingStateModel.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ScheduledScalingStateModelService } from './scheduledScalingStateModel.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'argos-scheduledScalingStateModel',
    templateUrl: './scheduledScalingStateModel.component.html',
    providers: [ScheduledScalingStateModelService]
})

export class ScheduledScalingStateModelComponent extends ReactComponentBase<IScheduledScalingStateModelProps, IScheduledScalingStateModelStates, {}> implements OnInit {
    constructor(private svc: ScheduledScalingStateModelService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ScheduledScalingStateModelComponent>) {
        super();
        this.props = dialogData.props;
        this.props.progressValue = 0;
        this.props.cancel = false;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
        this.svc.upsertBulkScheduleStatus(this.props);
        this.cdr.detectChanges();
    }

    close() {
        this.props.cancel = true;
        this.props.clusters.forEach(function (c) { delete c.scheduledScalingUpdateStatus; }); // clean up property
        this.dialogRef.close('cancel');
    }
}


