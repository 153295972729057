import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IVersionHistoryListStates, IVersionHistoryListService } from './versionHistoryList.component.d';
import { NgDataAccess } from '../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../matTableSettingsModel/matTableSettingsModel.component';
import { AlertService } from 'client/app/services/alert.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as process from 'process';
import { ViewHistoryModalComponent } from '../observations/viewHistoryModal/viewHistoryModal.component';
import { ViewMetricHistoryModalComponent } from '../prism1Metrics/viewMetricHistoryModal/viewMetricHistoryModal.component';
import { ViewCareGroupingHistoryComponent } from '../careGroupings/viewCareGroupingHistory/viewCareGroupingHistory.component';
import { ViewJoinEntityHistoryModalComponent } from '../episodeGroupings/viewJoinEntityHistoryModal/viewJoinEntityHistoryModal.component';
import { ViewEpisodeGroupingHistoryModalComponent } from '../episodeGroupings/viewEpisodeGroupingHistoryModal/viewEpisodeGroupingHistoryModal.component';

@Injectable()
export class VersionHistoryListService implements IVersionHistoryListService {
    constructor(private dataAccess: NgDataAccess,  private matDialog: MatDialog, 
        private cdr: ChangeDetectorRef,
        private alertSvc: AlertService, private http: HttpClient) {
        
    }
    async initDelegate(states: IVersionHistoryListStates): Promise<object> {
        await this.activate(states);
        return {};
    }
    async activate(states: IVersionHistoryListStates) {
        const metadata = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage',
            method: 'getAllMetdataVersionHistory',
            parameters: {
                metadataTypes: ['otherMetric', 'observation', 'careGrouping', 'episodeGrouping', 'episodeGroupingEntity']
            }
        });
        states.versionHistoryTable.data = metadata;

    }
    setTablePageSize(states: IVersionHistoryListStates) {
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        states.pageSize = (possibleRowsToShow > 10) ? possibleRowsToShow : 10;
        states.tableShowRows = states.versionHistories.slice(0, states.pageSize);
        states.versionHistoryTable = new MatTableDataSource(states.tableShowRows);
    }
    async viewHistory(row: any) {
        const metadataType = row.metaDataType;
        const metdataName = row.metaDataName;
        const metadataHistories = row.metaDataType === 'otherMetric' ? 'metricHistories' : row.metaDataType + 'Histories';
        const metadata = _.first(await this.dataAccess.genericFind({
            model: _.upperFirst(metadataType),
            filter: { 
                where: { 
                    or: [
                        { observationId: metdataName },
                        { id: metdataName },
                        { entity: metdataName },
                        { name: metdataName },
                        { groupByAttribute: metdataName }
                    ]
                },
                include: [metadataHistories]
            }
        }));
        let dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {}
            }
        };
        let component: any;
        switch (row.metaDataType) {
            case 'otherMetric':
                dialogConfig.data.props = {
                    metric: metadata
                };
                component = ViewMetricHistoryModalComponent;
                break;
            case 'observation':
                dialogConfig.data.props = {
                    observation: metadata
                };
                component = ViewHistoryModalComponent;
                break;
            case 'careGrouping':
                dialogConfig.data.props = {
                    careGrouping: metadata
                };
                component = ViewCareGroupingHistoryComponent;
                break;
            case 'episodeGrouping':
                dialogConfig.data.props = {
                    episodeGrouping: metadata
                };
                component = ViewEpisodeGroupingHistoryModalComponent;
                break;
            case 'episodeGroupingEntity':
                dialogConfig.data.props = {
                    episodeGroupingEntity: metadata
                };
                component = ViewJoinEntityHistoryModalComponent;
                break;      
        }
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            component, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            this.matDialog.closeAll();
        });
        
    }
}
