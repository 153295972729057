import { Injectable } from '@angular/core';
import { ICompoundFiltersSelectCategoryModalProps, ICompoundFiltersSelectCategoryModalStates, ICompoundFiltersSelectCategoryModalService } from './compoundFiltersSelectCategoryModal.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from 'client/app/services/dataAccess.service';

@Injectable()
export class CompoundFiltersSelectCategoryModalService implements ICompoundFiltersSelectCategoryModalService {
    constructor(private dataAccess: NgDataAccess) {

    }
    async initDelegate(props: ICompoundFiltersSelectCategoryModalProps, states: ICompoundFiltersSelectCategoryModalStates): Promise<Object> {
        states.compoundFilter = _.get(props, 'compoundFilter', {});
        states.tokenIndex = _.get(props, 'tokenIndex', 0);
        const episodeGroupings = await this.dataAccess.genericFind({
            model: 'EpisodeGrouping'
        });
        states.availableGroupings = _.filter(episodeGroupings, function (eg) {
            return _.size(eg.validCareGroupings) > 0 && _.size(_.intersection(eg.validCareGroupings, states.compoundFilter.validCareGroupings)) === _.size(states.compoundFilter.validCareGroupings);
        });
        return states;
    }

    async changeDelegate(oldProps: ICompoundFiltersSelectCategoryModalProps, newProps: ICompoundFiltersSelectCategoryModalProps, states: ICompoundFiltersSelectCategoryModalStates): Promise<Object> {
        return this.initDelegate(newProps, states);
    }

    selectHandler(episodeGrouping: any, states: ICompoundFiltersSelectCategoryModalStates) {
        if (episodeGrouping) {
            _.set(states, ['compoundFilter', 'templateTokens', states.tokenIndex, 'groupByAttribute'], episodeGrouping.groupByAttribute);
        }
    }
}
