// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ccg-name-td {
  display: flex;
  align-items: center;
  min-height: 32px;
}
.ccg-name-td .ccg-warning-svg {
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/clientCareGroupings/clientCareGroupingsList/clientCareGroupingsList.component.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAJA;EAKM,gBAAA;AAEN","sourcesContent":[".ccg-name-td {\n  display: flex;\n  align-items: center;\n  min-height: 32px;\n  .ccg-warning-svg {\n      margin-left: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
