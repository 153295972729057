import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ICreateDataReleaseStates, ICreateDataReleaseService } from './createDataRelease.component.d';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { StateService } from '@uirouter/core';


@Injectable()
export class CreateDataReleaseServices implements ICreateDataReleaseService {
    
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService,  private state: StateService,) {
    };

    async initDelegate(states: ICreateDataReleaseStates): Promise<object> {
        await this.init(states);
        return states;
    };

    async init(states: ICreateDataReleaseStates) {
        const astronomerDagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });
        states.astronomerBaseUrl = astronomerDagsUrl.data;
        return states;
    };

    async getActiveDags() {
        const finalDags = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsByPattern',
            parameters: {
                pattern: 'deployment-final'
            }
        });
        const rawDags = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsByPattern',
            parameters: {
                pattern: 'deployment-raw'
            }
        });
        const abstractDags = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsByPattern',
            parameters: {
                pattern: 'deployment-abstract'
            }
        });
        const refDags = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsByPattern',
            parameters: {
                pattern: 'deployment-reference'
            }
        });
        const allDags = finalDags.data.concat(rawDags.data).concat(abstractDags.data).concat(refDags.data);
        return allDags;
    }
    
    async filterDagsByType(dagsType: string, finalDags: any) {
        const dagTypes: string[] = ['customer', 'clarify_data'];
        let filteredDags = finalDags.filter((dag: any) => dag.tags.map((tag: { name: string; }) => tag.name).includes(dagsType));
        // dags with no tags get loaded into both customer and clarify_data
        const dagsWithNoTags = finalDags.filter((dag: any) => dag.tags.map((tag: { name: string; }) => tag.name).includes(dagTypes[0]) === false 
                                    && dag.tags.map((tag: { name: string; }) => tag.name).includes(dagTypes[1]) === false);
        filteredDags = filteredDags.concat(dagsWithNoTags);
        const dagsList = filteredDags.map((dag: any) => dag.dag_id);
        const selectableDags = dagsList.filter((dag_id: String) => !dag_id.startsWith('pseudo') && !dag_id.startsWith('post-deployment') && !dag_id.includes('qa-to-staging'));
        return selectableDags.sort();
    };

    updateReleaseDateDelegate(states: ICreateDataReleaseStates, releaseDate: Date) {
        states.releaseDate = releaseDate.toLocaleDateString('en-US');
        states.encodedReleaseDate = releaseDate.toISOString().split('T')[0];
    };

    addDagToReleaseDelegate(states: ICreateDataReleaseStates, dagId: string, dagConfig: any) {
        states.dagsInRelease.push({
            dagId: dagId,
            dagConfig: dagConfig,
            addedByUser: this.getUser()
        });
        states.dagsInRelease = [...states.dagsInRelease]
    };

    removeDagFromReleaseDelegate(states: ICreateDataReleaseStates, dagId: string) {
        states.dagsInRelease = states.dagsInRelease.filter((dag: any) => dag.dagId !== dagId);
    };

    getUser() {
        return this.argosStore.getItem('username');
    };

    async getReleaseNames() {
        return await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'getDistinctReleaseNames'
        });
    };

    async createReleaseDelegate(states: ICreateDataReleaseStates, activeDags: any) {
        const releaseName = states.releaseName
        const releaseDate = states.releaseDate;
        const dagsList = states.dagsInRelease.map((dag: any) => dag.dagId);
        const activeDagIds = activeDags.map((dag: any) => dag.dag_id);
        const createdByUser = this.getUser();
        const results = await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'createRelease',
            parameters: {
                releaseName: releaseName,
                releaseDate: releaseDate,
                dagsList: dagsList,
                createdByUser: createdByUser
            }
        });
        const releaseId = results.id;
        for (const dag of states.dagsInRelease) {
            let pseudoDagId = `pseudo-${dag.dagId}`;
            let pseudoDagStatus = activeDagIds.includes(pseudoDagId) ? 'Pending' : 'None';
            await this.dataAccess.genericMethod({
                model: 'DataReleaseDags',
                method: 'addDagToRelease',
                parameters: {
                    releaseId: releaseId,
                    dagId: dag.dagId,
                    pseudoDagStatus: pseudoDagStatus,
                    dagConfig: dag.dagConfig,
                    addedByUser: createdByUser
                }
            });
        }
        const jiraTicketId = await this.createReleaseTicket(states, releaseId, createdByUser);
        await this.updateJiraTicketStatus(jiraTicketId, 71); //set to "Prepare Release"
        this.state.go('argos.data.data_release_manager.list');
    };

    async createReleaseTicket(states: ICreateDataReleaseStates, releaseId: Number, createdByUser: string) {
        const baseUrl = window.location.href
        const editReleaseUrl = `${baseUrl.replace('/create', '/edit')}/${releaseId}/${states.encodedReleaseDate}/${encodeURIComponent(states.releaseName)}`;
        const runReleaseUrl = `${baseUrl.replace('/create', '/run')}/${releaseId}`;
        const jiraUserId = await this.getJiraUserId(createdByUser);
        const jiraResponse = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'createIssue',
            parameters: {
                project: 'RM',
                title: `${states.releaseName} on ${states.releaseDate}`,
                description: `Data Release: ${states.releaseName}
                              Edit Release Link: ${editReleaseUrl}
                              Run Release Link: ${runReleaseUrl}
                              Created By: ${this.getUser()}
                              Release Date: ${states.releaseDate}`,
                assigneeId: jiraUserId,
                issueType: 'Release Request'
            }
        });
        if (jiraResponse.isSuccessful) {
            const jiraTicketId = jiraResponse.response.key;
            await this.dataAccess.genericMethod({
                model: 'DataRelease',
                method: 'addJiraTicketId',
                parameters: {
                    releaseId: releaseId,
                    jiraTicketId: jiraTicketId,
                    jiraTicketType: 'rm'
                }
            });
            return jiraTicketId
        }
        else {
            console.log('jiraResponse', jiraResponse);
            return null;
        }
    };

    async updateJiraTicketStatus(jiraTicketId: string, newStatusCode: number) {
        const transitionResponse = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'transitionIssue',
            parameters: {
                jiraTicketId: jiraTicketId,
                transitionId: newStatusCode
            }
        });
        return transitionResponse;
    };

    async getJiraUserId(email: string) {
        const response = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'getUserId',
            parameters: {
                email: email
            }
        });
        return response.accountId;
    };

}