import { Component } from '@angular/core';

@Component({
    selector: 'argos-joinEntitie',
    template: `<ui-view></ui-view>`
})

export class JoinEntitiesComponent {

}
