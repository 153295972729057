import { Component, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { IClarifyInsightsFilterHistory } from './clarifyInsightsFilterHistory.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ClarifyInsightsFilterHistoryService } from './clarifyInsightsFilterHistory.component.service';


@Component({
    selector: 'argos-clarifyInsightsFilerHistory',
    templateUrl: './clarifyInsightsFilterHistory.component.html',
    styleUrls: ['./clarifyInsightsFilterHistory.component.css'],
    providers: [ClarifyInsightsFilterHistoryService]
})

export class ClarifyInsightsFilterHistoryComponent extends ReactComponentBase<{}, IClarifyInsightsFilterHistory, {}> implements OnInit {

    constructor(private svc: ClarifyInsightsFilterHistoryService, private cdr: ChangeDetectorRef) {
        super();
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    viewDiffs(version: any) {
        this.svc.viewDiffs(version, this.states);
        this.cdr.detectChanges();
    }
}
