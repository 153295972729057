import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IPreviewReleaseNotesProps, IPreviewReleaseNotesStates } from './previewReleaseNotes.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PreviewReleaseNotesService } from './previewReleaseNotes.component.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'argos-previewReleaseNotes',
    templateUrl: './previewReleaseNotes.component.html',
    providers: [PreviewReleaseNotesService]
})

export class PreviewReleaseNotesComponent extends ReactComponentBase<IPreviewReleaseNotesProps, IPreviewReleaseNotesStates, {}> implements OnInit {
    constructor(private svc: PreviewReleaseNotesService, private state: StateService,
        private cdr: ChangeDetectorRef) {
        super({
            improvements: [],
            newFeatures: [],
            release: {},
            bugs: []
        } as IPreviewReleaseNotesStates);
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.props, this.states);
        this.setStates(newStates);
        this.cdr.detectChanges();
    }

    cancel() {
        this.state.go('argos.prism.releases');
    }
}
