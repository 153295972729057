import { Injectable} from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { IViewTeraUsersModalProps, IViewTeraUsersModalStates, IViewTeraUsersModalService } from './viewTeraUsersModal.component.d';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import swal from 'sweetalert2';
import * as _ from 'lodash';
@Injectable()
export class ViewTeraUsersModalService implements IViewTeraUsersModalService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog) {
        //
    }
    initDelegate(props: IViewTeraUsersModalProps, states: IViewTeraUsersModalStates): object {
        states.teraUsers = props.teraUsers;
        states.addUser = false;
        states.newTeraUser = {};
        states.editingMode = false;
        return states;
    }

    changeDelegate(oldProps: IViewTeraUsersModalProps, newProps: IViewTeraUsersModalProps, states: IViewTeraUsersModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }


    async saveRowHandler(row: any) {
        // force the shortName to be camelCase
        row.lastModified = new Date();
        await this.dataAccess.genericUpsert({
            model: 'TeraUser',
            data: row
        });
    }

    validateAllRequiredFields(objToValdate: any, requiredProperties: any, states: IViewTeraUsersModalStates) {
        requiredProperties.forEach((propertyName: any) => {
            if (!objToValdate[propertyName] || objToValdate[propertyName].toString().trim().length === 0) {

                states.isValidToSave = false;
                states.propertyErrors.push({
                    field: propertyName,
                    message: propertyName + ' is a required field'
                });
            }
        });

    }


    isValid(states: IViewTeraUsersModalStates) {
        states.propertyErrors = [];
        states.isValidToSave = true;

        // Validate fields
        this.validateAllRequiredFields(states.newTeraUser, ['ownerName', 'ownerTeam', 'ownerJiraProject', 'ownerEmail'], states);

        return states.isValidToSave;
    }

    errorExists(propertyName: any, states: IViewTeraUsersModalStates) {
        let result;
        if (states.newTeraUser && states.propertyErrors) {
            result = states.propertyErrors.map(function (i: any) { return i.field; }).indexOf(propertyName) > -1;
        }
        return result;
    }

    async save(states: IViewTeraUsersModalStates) {
        try {
            states.saveInProgress = true;
            // Add other teraUsers fields Here
            const lastModified = new Date();
            states.newTeraUser.lastModified = lastModified;
            const data = _.cloneDeep(states.newTeraUser);
            const result = await this.dataAccess.genericUpsert({
                model: 'TeraUser',
                data
            });
            states.saveInProgress = false;
            states.addUser = false;
            states.teraUsers = [...states.teraUsers, result];
            console.log('Done Saving');
        } catch (error) {
            console.log('saved failed');
        }
    }

    async deleteUser(row: any, states: IViewTeraUsersModalStates) {
        swal({
            title: 'Really?',
            text: 'Are you sure you want to remove the delete this user ' + row.ownerName + '?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                // Delete the ref data class
                await this.dataAccess.genericDelete({
                    model: 'TeraUser',
                    id: row.id
                });
                states.teraUsers = states.teraUsers.filter((value: any) => value.id !== row.id);
            }
        });
    }
}
