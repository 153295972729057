import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewEnvironmentUsageModalProps, IViewEnvironmentUsageModalStates } from './viewEnvironmentUsageModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewEnvironmentUsageModalService } from './viewEnvironmentUsageModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
    selector: 'argos-viewEnvironmentUsageModal',
    templateUrl: './viewEnvironmentUsageModal.component.html',
    providers: [ViewEnvironmentUsageModalService]
})

export class ViewEnvironmentUsageModalComponent extends ReactComponentBase<IViewEnvironmentUsageModalProps, IViewEnvironmentUsageModalStates, {}> implements OnInit, OnChanges {

    moment = moment;

    constructor(private svc: ViewEnvironmentUsageModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewEnvironmentUsageModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close();
    }
}
