import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IQueryPerformanceStates } from './queryPerformance.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { QueryPerformanceService } from './queryPerformance.component.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-queryPerformance',
    templateUrl: './queryPerformance.component.html',
    providers: [QueryPerformanceService]
})

export class QueryPerformanceComponent extends ReactComponentBase<{}, IQueryPerformanceStates, {}> implements OnInit {
    constructor(private svc: QueryPerformanceService, private cdr: ChangeDetectorRef) {
        super({
            groupBy: 'name',
            results: []
        } as unknown as IQueryPerformanceStates);
    }

    async ngOnInit() {
        this.setStates(await this.svc.initDelegate(this.states));
        this.setQueryRunning();
    }

    groupByChanged() {
        this.svc.groupByChanged(this.states);
    }

    fetchResults() {
        this.svc.fetchResults(this.states, this.setQueryRunning);
    }

    setQueryRunning: Function = () => {
        this.cdr.detectChanges();
    };

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }
}
