import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IPatchManagementAddEditModalProps, IPatchManagementAddEditModalStates, IPatchManagementAddEditModalService } from './patchManagementAddEditModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { ArgosStoreService } from '../../../services/argosStore.service';

@Injectable()
export class PatchManagementAddEditModalService implements IPatchManagementAddEditModalService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef) {
        //
    }

    async initDelegate(props: IPatchManagementAddEditModalProps, states: IPatchManagementAddEditModalStates): Promise<object> {
        await this.init(states, props);
        return {};
    }


    changeDelegate(oldProps: IPatchManagementAddEditModalProps, newProps: IPatchManagementAddEditModalProps, states: IPatchManagementAddEditModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    async init(states: IPatchManagementAddEditModalStates, props: IPatchManagementAddEditModalProps) {
        states.patch = _.cloneDeep(_.isEmpty(props.content) ? states.patch : props.content);
        states.edit = !_.isEmpty(props.content);
        states.selectedFiles = _.cloneDeep(_.get(states, 'patch.selectedFiles'));
        await this.getBuildTags(states);
        this.cdr.detectChanges();
    }

    async getBranchDataFromGithub(states: IPatchManagementAddEditModalStates, cdr: ChangeDetectorRef) {
        states.loading = true;
        states.message = `Fetching all Meta Data from Gold and Argos, searching Github for ${states.patch.releaseInfo.tagName} and Diffing. This may take a while...`;
        cdr.detectChanges();
        try {
            const result = await this.dataAccess.genericMethod({
                model: 'Patch',
                method: 'initializeSearchGitTagAndDiff',
                parameters: {
                    tagName: states.patch.releaseInfo.tagName
                }
            });
            if (result) {
                states.loading = false;
                states.message = result.message;
                states.errorStack = result.errorStack;
                console.log(states.errorStack);
                if (!_.isNil(result.availableFiles)) {
                    states.patch.availableFiles = _.cloneDeep(result.availableFiles);
                    states.patch.releaseFiles = _.cloneDeep(result.releaseFolderFiles);
                    states.patch.releaseInfo.patchFolder = result.patchFolder;
                    cdr.detectChanges();
                }
            }

        } catch (error) {
            console.log(error);
            states.message = 'Something went wrong...Please check console logs';
            states.loading = false;
            cdr.detectChanges();
        }
    }


    async savePatch(states: IPatchManagementAddEditModalStates) {
        states.saveInProgress = true;
        try {
            states.patch.createdBy = this.argosStore.getItem('username');
            states.patch.createdDate = new Date();
            states.patch.archived = false;
            const cloneData = _.cloneDeep(states.patch);
            const omitLargeFiles = _.omit(cloneData, ['releaseFiles', 'availableFiles']);
            const result = await this.dataAccess.genericUpsert({
                model: 'Patch',
                data: omitLargeFiles
            });
            if (result?.id) {
                await Promise.all([this.dataAccess.genericUpsert({
                    model: 'Patch',
                    data: { id: result.id, releaseFiles: states.patch.releaseFiles }
                }), this.dataAccess.genericUpsert({
                    model: 'Patch',
                    data: { id: result.id, availableFiles: states.patch.availableFiles }
                })]).then(() => {
                    states.saveInProgress = false;
                });
            }
            return result;
        } catch (error) {
            console.log(error);
        }
    }

    async getBuildTags(states: IPatchManagementAddEditModalStates) {

        const data = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsBuilds', parameters: {
                repositoryName: states.repositoryName, awsAccountName: states.awsAccounts[0].id
            }
        });

        states.buildReleaseTags = _(data.response).filter(item => _.includes(item, 'prod-release') && !_.includes(item, 'prod-release-latest')).map((it: any) => ({ tagName: _.last(it.split('-')), label: it })).value();
        const demoProdId = 'c5e4a910-e607-11ed-bcac-15c5f74ed5f1';
        const version = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getAppVersion',
            parameters: {
                id: demoProdId
            }
        });
        if (version) {
            if (!_.find(states.buildReleaseTags, { tagName: `${states.patch.releaseInfo.tagName}` }) && states.edit) {
                states.buildReleaseTags.push({ tagName: `${states.patch.releaseInfo.tagName}`, label: `prod-release-${states.patch.releaseInfo.tagName}` });
            } else if(!states.edit) {
                states.patch.releaseInfo.tagName = `${version.localVersion}`;
            }
            this.cdr.detectChanges();
        }
    }
}
