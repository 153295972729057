import { Injectable } from '@angular/core';
import { IQPerformanceProductDetailStates, IQPerformanceProductDetailService } from './performanceTestResultsDashboardProductsDetail.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';
import { PlatformReleaseResultsService } from '../../../services/platformReleaseResultsService.service';

@Injectable()
export class QAPerformanceResultDetailService implements IQPerformanceProductDetailService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService, private platformReleaseResultsService: PlatformReleaseResultsService) {
        //
    }

    async initDelegate(states: IQPerformanceProductDetailStates): Promise<object> {
        await this.fetchResults(states);
        return {};
    }

    async fetchResults(states: IQPerformanceProductDetailStates) {
        const performanceTestResults = await this.dataAccess.genericFind({
            model: 'PerformanceTestResults',
            filter: {
                where: {
                    and: [
                        { product: { like: this.uiRouter.globals.params.id } },
                        { module: { like: this.uiRouter.globals.params.module } }
                    ]
                }
            },
        });

        states.product = this.uiRouter.globals.params.id;
        states.module = this.uiRouter.globals.params.module;

        // Set state data for Trend chart
        states.performanceTestResults = performanceTestResults;

        const resultsGroupedByPageName = _(performanceTestResults)
            .map((obj: any) => ({ ...obj, release_name: new Date(obj.release_name).toLocaleDateString('es-pa') }))
            .orderBy(['submodule', 'dashboard', 'tab', 'product', 'release_name'])
            .groupBy((item) => [item['submodule'], item['dashboard'], item['tab']])
            .value();

        const columnKeys = _(states.performanceTestResults)
            .map((obj: any) => ({ ...obj, release_name: new Date(obj.release_name).toLocaleDateString('es-pa') }))
            .orderBy(['product'], ['desc'])
            .orderBy([(obj) => {
                return new Date(obj.release_name);
              }],['asc'])
            .groupBy('release_name')
            .value();

        const structuredData: object[] = [];
        const releaseKeys: string[] = Object.keys(resultsGroupedByPageName);

        for (let i = 0; i < releaseKeys.length; i++) {
            const releaseData = _.orderBy(resultsGroupedByPageName[releaseKeys[i]], ['dashboard']);
            let currentPageData: any;
            for (let j = 0; j < releaseData.length; j++) {
                const releaseKey = releaseData[j].release_name;
                if (j === 0) {
                    currentPageData = {
                        submodule: releaseData[j].submodule,
                        dashboard: releaseData[j].dashboard,
                        tab: releaseData[j].tab,
                        [releaseKey]: releaseData[j].load_time
                    };
                } else {
                    currentPageData[releaseKey] = releaseData[j].load_time;
                }
            }
            structuredData.push(currentPageData);
        }

        states.displayedColumns = ['submodule', 'dashboard', 'tab', ...(Object.keys(columnKeys))];
        states.performanceTestDataTable.data = structuredData;
    }
}
