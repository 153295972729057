import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IEcsDashboardStates } from './ecsDashboard.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EcsDashboardService } from './ecsDashboard.component.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { LoginService } from '../../../services/loginService.service';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';
import swal from 'sweetalert2';
// mat table imports
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';

declare const $: any;
@Component({
    selector: 'argos-ecsDashboard',
    templateUrl: './ecsDashboard.component.html',
    providers: [EcsDashboardService]
})

export class EcsDashboardComponent extends ReactComponentBase<{}, IEcsDashboardStates, {}> implements OnInit, AfterViewInit {
    myControl = new FormControl('');
    filteredOptions: Observable<string[]>;
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('connectionPaginator') connectionPaginator: MatPaginator;
    @ViewChild('connectionMatTbSort') connectionMatTbSort = new MatSort();
    _: any = _;
    // charts
    // @ViewChild('releaseStackedBarCanvas') releaseStackedBarCanvas: ElementRef | undefined;
    // releaseStackedBarChart: any;

    constructor(private svc: EcsDashboardService, private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private loginServiceHandler: LoginService, private state: StateService) {
        super({
            activeTab: 0,
            releaseParameters: {
                eventLogs: [],
                selectedRange: '30 day',
                selectedRangeOptions: ['30 day', '90 day', 'since inception'],
                releaseEventTable: new MatTableDataSource([]),
                pageSize: 10,
                defaultDisplayMatTableColumns: ['url', 'toBuildTag', 'triggeredBy', 'runTime'],
                matTableColumns: ['url', 'toBuildTag', 'triggeredBy', 'runTime'],
                filterObj: {},
                releaseParametersMatTableSettingName: 'ecsDashboardReleaseParameters'
            },
            auth0Object: {
                clients: [],
                connections: [],
                cloneableClients: [],
                domain: ['dev', 'prod'],
                newAuth0DnsCname: '',
                newAuth0domainSuffix: [{ id: 'us', value: 'clarifyhealth.us' }, { id: 'com', value: 'clarifyhealth.com' }],
                newAuth0domainSuffixSelected: 'com',
                newAuth0ApplicationName: '',
                webOriginValidationResults: [],
                isValidatingWebOrigin: false,
                selectedClient: {
                    callbacks: [],
                    web_origins: []
                },
                validatedWebOrigin: new MatTableDataSource([])
            },
            filterObj: {},
            chartObj: {},
            pageSize: 5,
            releaseEventColumns: ['url', 'toBuildTag', 'triggeredBy', 'runTime'],
            releaseEventTableShow: [],
            releaseEventTableAll: [],
            envManagement: {},
            newKeyValue: '',
            newPapertrailExclusionKeyValue: '',
            newEcsAppEditEnv: '',
            newEc2: {
                key: '',
                value: ''
            },
            newEcsTag: {
                key: '',
                value: ''
            },
            selectedConnection: {},
            connectionFilterObj: {},
            connectionPageSize: 10,
            connectionMatTable: new MatTableDataSource([]),
            connectionMatTableColumns: ['name', 'strategy', 'signUpDisabled', 'enabledClientsCount'],
            webOriginValidationMatTable: new MatTableDataSource([])
        } as unknown as IEcsDashboardStates);
    }

    async ngOnInit() {
        this.states.releaseParameters.releaseEventTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.releaseParameters.pageSize = this.matSvc.getUserDefaultPageSize(this.states.releaseParameters.releaseParametersMatTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    private connectionNameFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const options: string[] = _.map(this.states.auth0Object.connections, 'name');
        return options.filter(option => option.toLowerCase().includes(filterValue));
    }

    connectionFilterChange(columnName: string, event: any) {
        this.states.connectionFilterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.connectionMatTable.filter = JSON.stringify(this.states.connectionFilterObj);
    }



    selectedEditConnection(connectionName: string) {
        this.states.selectedConnection = _.find(this.states.auth0Object.connections, { name: connectionName });
    }

    async auth0DomainSelected() {
        await this.svc.auth0DomainSelected(this.states);
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this.connectionNameFilter(value || '')),
        );
        this.cdr.detectChanges();
    }

    searchWebOrigins(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.webOriginValidationMatTable.filter = filterValue.trim().toLowerCase();
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
    }

    isInvalidCsvString(csvValue: any) {
        if (csvValue) {
            const pattern = /^[A-Za-z0-9]+(?:, ?[A-Za-z0-9]+)*$/;
            return !(!!pattern.exec(csvValue));
        }
        return undefined;
    }

    validateConnection() {
        // states.auth0Object.connections
        // app_type: 'spa'
    }

    async removeAuth0AppConnections(connectionObject: any) {
        this.svc.removeAuth0AppConnections(connectionObject, this.cdr, this.states);
    }

    async auth0ClientRowSelected(selected: string) {
        this.states.auth0Object.selectedClient = _.find(this.states.auth0Object.clients, { name: selected });

        const appInfo = await this.dataAccess.genericMethod({
            model: 'Auth0Client', method: 'getApplication', parameters: {
                tenantName: this.states.auth0Object.selectedDomain,
                clientAppId: this.states.auth0Object.selectedClient.client_id
            }
        });

        this.states.auth0Object.selectedClient.connections = _.filter(this.states.auth0Object.connections, function (c) {
            if (appInfo.connections.indexOf(c.name) > -1) {
                return c;
            }
        });

        this.cdr.detectChanges();
    }

    validateAuth0WebOrigins() {
        const urlNotFound: any[] = [];
        const atlasEnvFound: any[] = [];
        // let handleErr = function() {
        //     return;
        // };

        this.states.auth0Object.isValidatingWebOrigin = true;    // disable validation button
        this.states.auth0Object.webOriginValidationResults = [];

        // process matching urls to environment records and ones that are not found
        this.states.auth0Object.selectedClient.web_origins.forEach((woUrl: any) => {
            woUrl = woUrl.toLowerCase();
            const foundObj = _.find(this.states.atlasEnvUrls, function (u) {
                return u.url === woUrl;
            });
            if (foundObj) {
                atlasEnvFound.push(foundObj);
            } else {
                urlNotFound.push({
                    url: woUrl,
                    isValid: false,
                    reason: ['not in argos environment record']
                });
            }
        }); // foreach

        // check found environment urls
        Promise.all(atlasEnvFound.map(async (e) => {
            const response = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getAppVersion',
                parameters: {
                    id: e.id
                }
            });
            const result: any = {
                id: e.id,
                url: e.url,
                clientId: e.clientId,
                isValid: false,
                reason: ['url did not respond']
            };

            if (response && response.localVersion) {
                result.isValid = true;
                delete result.reason;
            }

            return result;
        })).then((results) => {
            this.states.auth0Object.webOriginValidationResults = urlNotFound.concat(results);    // merge found and not found url objs
            this.states.auth0Object.isValidatingWebOrigin = false;   // enable validation button
            this.initAuth0ValidationTable();
        });
    }

    async releaseQueryRequests() {
        await this.svc.releaseQueryRequests(this.states.releaseParameters.fromDate, this.states.releaseParameters.toDate, this.states);

        const releaseStackedBarCanvas: HTMLCanvasElement = document.getElementById('releaseStackedBarCanvas') as HTMLCanvasElement;
        this.states.chartObj.releaseStackedBarCtx = releaseStackedBarCanvas.getContext('2d');
        this.svc.createServiceMetricsChart(this.states);

        const upgradeHorizontalBarCanvas: HTMLCanvasElement = document.getElementById('upgradeHorizontalBarChart') as HTMLCanvasElement;
        this.states.chartObj.upgradeHorizontalBarCtx = upgradeHorizontalBarCanvas.getContext('2d');
        this.svc.createUpgradeHorizontalBarChart(this.states);

        this.cdr.detectChanges();
    }

    async intiUpgradeRunTimeSingleEnvChartSettings() {

        const upgradeHorizontalBarCanvas: HTMLCanvasElement = document.getElementById('upgradeRunTimeSingleEnvChart') as HTMLCanvasElement;
        this.states.chartObj.upgradeRunTimeSingleEnvCtx = upgradeHorizontalBarCanvas.getContext('2d');

        this.svc.intiUpgradeRunTimeSingleEnvChartSettings(this.states.releaseParameters.selectedClusterName, this.states.releaseParameters.selectedRange, this.states);
    }

    addKeyValue(objArray: any, value: any) {
        this.svc.addKeyValue(objArray, value, this.states);
    }

    deleteKeyValue(objArray: any[], key: any) {
        objArray = objArray.splice(key, 1);
    }

    addEc2Option(key: any, value: any) {
        this.svc.addEc2Option(key, value, this.states);
    }

    addkeyValueObj(key: any, value: any, objArray: any[]) {
        this.svc.addkeyValueObj(key, value, objArray, this.states, this.cdr);
        this.resetNewEcsKeyValues();
    }

    resetNewEcsKeyValues() {
        const reset = {
            key: '',
            value: ''
        };

        this.states.newEc2 = {
            key: '',
            value: ''
        };
        this.states.newEcsTag = {
            key: '',
            value: ''
        };
    }

    deleteObjectByKey(obj: any, key: any) {
        delete obj[key];
    }

    deleteTagByKey(objlist: any, key: any) {
        _.remove(objlist, {
            key
        });

        this.cdr.detectChanges();
    }

    saveEcsManagement() {
        this.svc.saveEcsManagement(this.states);
        this.cdr.detectChanges();
    }

    createAuth0Login() {
        this.svc.createAuth0Login(this.states);
    }

    auth0ExportValidCallbacks() {
        this.svc.auth0ExportValidCallbacks(this.states);
    }

    auth0ExportValidWebOrigins() {
        this.svc.auth0ExportValidWebOrigins(this.states);
    }

    async validateAuth0WebOrigin(env: any) {
        await this.svc.validateAuth0WebOrigin(env);
        this.cdr.detectChanges();
    }

    auth0AppNameExists() {
        return this.svc.auth0AppNameExists(this.states);
    }

    isDnsValid() {
        return this.svc.isDnsValid(this.states);
    }

    createAuth0Application() {
        this.svc.createAuth0Application(this.states);
    }

    initAuth0ValidationTable() {

        this.states.auth0Object.webOriginValidationResults = _.orderBy(this.states.auth0Object.webOriginValidationResults, ['isValid', 'url']);
        this.states.webOriginValidationMatTable.data = this.states.auth0Object.webOriginValidationResults;


        this.states.auth0Object.validatedWebOrigin =
            new MatTableDataSource(this.states.webOriginValidationResultsShow);

        this.cdr.detectChanges();

    }

    releaseChartClick(points: any) {
        this.svc.releaseChartClick(points, this.states);
    }

    ngAfterViewInit() {
        this.states.webOriginValidationMatTable.sort = this.matTbSort;
        this.states.releaseParameters.releaseEventTable.paginator = this.paginator;
        this.states.releaseParameters.releaseEventTable.sort = this.matTbSort;

        this.states.connectionMatTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.connectionMatTable.paginator = this.connectionPaginator;
        this.states.connectionMatTable.sort = this.connectionMatTbSort;
    }

    dateRangeChange(startDate: Date, endDate: Date) {
        //
    }

    filterChange(columnName: string, event: any) {
        this.states.releaseParameters.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.releaseParameters.releaseEventTable.filter = JSON.stringify(this.states.releaseParameters.filterObj);
    }

    // pagination functions
    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.releaseParameters.releaseParametersMatTableSettingName, this.states.releaseParameters.defaultDisplayMatTableColumns);
        return result;
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }
}
