import { Injectable } from '@angular/core';
import { IViewClientCareGroupingHistoryModalProps, IViewClientCareGroupingHistoryModalStates, IViewClientCareGroupingHistoryModalService } from './viewClientCareGroupingHistoryModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
@Injectable()
export class ViewClientCareGroupingHistoryModalService implements IViewClientCareGroupingHistoryModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(props: IViewClientCareGroupingHistoryModalProps, states: IViewClientCareGroupingHistoryModalStates): Promise<object> {
        states.clientCareGrouping = props.clientCareGrouping;
        states.lodash = _;
        await this.init(states);
        return {};
    }

    async changeDelegate(oldProps: IViewClientCareGroupingHistoryModalProps, newProps: IViewClientCareGroupingHistoryModalProps, states: IViewClientCareGroupingHistoryModalStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async init(states: IViewClientCareGroupingHistoryModalStates) {
        const histories = await this.dataAccess.genericFind({
            model: 'ClientCareGroupingHistory',
            filter: {
                where: {
                    clientCareGroupingId: states.clientCareGrouping.id
                }
            }
        });
        states.histories = histories;
    }

    viewDiffsHandler(version: any, states: IViewClientCareGroupingHistoryModalStates) {
        states.showDiffs = true;
        states.compareHistory = [];
        states.currentVersion = ('(v' + version + ')');
        states.previousVersion = '';
        const currentRow = _.find(states.histories, { version });
        let previousRow = {};
        if (currentRow) {
            if (version > 1) {
                const previousVersion = version - 1;
                states.previousVersion = ('(v' + previousVersion + ')');
                previousRow = _.find(states.histories, { version: previousVersion });
            }
        }
        const currentSettingKeys = _.map(_.keys(_.get(currentRow, 'definition.overrides')), function (key) {
            return 'overrides.' + key;
        });
        const previousSettingKeys = _.map(_.keys(_.get(previousRow, 'definition.overrides')), function (key) {
            return 'overrides.' + key;
        });
        const allKeys = _.uniq(_.union(currentSettingKeys, previousSettingKeys));
        allKeys.unshift('id', 'clientId', 'databaseName', 'careGrouping', 'enabled', 'title', 'notes');
        states.compareHistory = _.map(allKeys, function (key) {
            if (key === 'overrides.pii') {
                states.previousValue = JSON.stringify(_.get(previousRow, 'definition.' + key));
                states.currentValue = JSON.stringify(_.get(currentRow, 'definition.' + key));
            }
            else {
                states.previousValue = _.get(previousRow, 'definition.' + key);
                states.currentValue = _.get(currentRow, 'definition.' + key);
            }
            return {
                key: _.startCase(key),
                previousValue: states.previousValue,
                currentValue: states.currentValue
            };
        });
    }
}
