import { Component, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { IObservationExceptionAddEditProps, IObservationExceptionAddEditStates } from './observationExceptionAddEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ObservationExceptionAddEditService } from './observationExceptionAddEdit.component.service';
import * as _ from 'lodash';
@Component({
    selector: 'argos-observationExceptionAddEdit',
    templateUrl: './observationExceptionAddEdit.component.html',
    providers: [ObservationExceptionAddEditService]
})

export class ObservationExceptionAddEditComponent
    extends ReactComponentBase<IObservationExceptionAddEditProps, IObservationExceptionAddEditStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ObservationExceptionAddEditService, private cdr: ChangeDetectorRef) {
        super({
            saveInProgress: false,
            selectedDataSource: {},
            selectedStatus: {}
        } as IObservationExceptionAddEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    isAddMode() {
        return this.states.mode === 'ADD';
    }

    isEditMode() {
        return this.states.mode === 'EDIT';
    }

    getObservationName() {
        return _.get(this.states, 'observationException.observationName');
    }

    async save(andClose?: boolean) {
        await this.svc.saveHandler(this.states, andClose);
        this.cdr.detectChanges();
    }
}
