// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-selector {
    margin-top: 10px;
    margin-bottom: 10px;
  }

.title-h2 {
    text-align: center;
}

.header-container {
    width: 800px;
}

.body-container {
    max-height: calc(100vh - 150px); 
    overflow-y: scroll;
}

.mat-form-field.datepicker {
    max-width: 200px;
}

section.legend-section{
    float:right;
    margin-bottom: 10px;
}

section.dropdown-section {
    float: left;
}

section.footer-section {
    float: right;
    margin-top: 10px;
}

textarea.note {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./client/app/components/clarifyInsights/clarifyInsightsCreateDeliveryModal/clarifyInsightsCreateDeliveryModal.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;EACrB;;AAEF;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,+BAA+B;IAC/B,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".filter-selector {\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n\n.title-h2 {\n    text-align: center;\n}\n\n.header-container {\n    width: 800px;\n}\n\n.body-container {\n    max-height: calc(100vh - 150px); \n    overflow-y: scroll;\n}\n\n.mat-form-field.datepicker {\n    max-width: 200px;\n}\n\nsection.legend-section{\n    float:right;\n    margin-bottom: 10px;\n}\n\nsection.dropdown-section {\n    float: left;\n}\n\nsection.footer-section {\n    float: right;\n    margin-top: 10px;\n}\n\ntextarea.note {\n    width: 100%;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
