import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IEnvironmentsListStates, IClientCareGrouping, IEnvironmentsListService, IClientProductConfiguration } from './environmentsList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { EnvironmentService } from '../../../services/environmentService.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import * as moment from 'moment';
declare const $: any;
declare const saveAs: any;
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { MaterialUtil } from '../../../services/materialUtil.service';

@Injectable()
export class EnvironmentsListService implements IEnvironmentsListService {
    constructor(private dataAccess: NgDataAccess, private environmentService: EnvironmentService, 
        private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, 
        private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        //
    }

    async initDelegate(states: IEnvironmentsListStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IEnvironmentsListStates) {
        states.dataLoading = true;
        await this.getEnvironments(states);
        this.formatColumnFilterList(states);
        states.dataLoading = false;
    }

    async modifyEnvData(environments: any, states: IEnvironmentsListStates) {
        let enableProducts: IClientProductConfiguration[] = [];
        states.argosClientProductConfiguration = await this.dataAccess.genericFind({
            model: 'ClientProductConfiguration'
        });
        let argosClientCareGroupings: IClientCareGrouping[] = [], 
            validCCGs: IClientCareGrouping[] = [];
        states.environments = _.orderBy(environments, ['client.name', 'name']);

        argosClientCareGroupings = await this.dataAccess.genericFind({
            model: 'ClientCareGrouping'
        });
        
        const dataAccess = this.dataAccess;
        const environmentService = this.environmentService;

        environments.forEach(async function (environment: any) {
            validCCGs = _.orderBy((_.filter(argosClientCareGroupings, (c) => {
                return (c.clientId === environment.clientId && c.databaseName === environment.dbName);
            })), ['careGrouping'], ['asc']);

            if (validCCGs && validCCGs.length > 0) {
                environment.argosClientCareGroupings = validCCGs;
                environment.argosClientCareGroupingsFilter = _.uniq(_.map(validCCGs, 'careGrouping'));
                environment.argosClientCareGroupingsStatus = 'Argos Client Care Groupings Configured';
                environment.ccgEnabled = true;
                _.forEach(validCCGs, (c) => {
                    if (c.careGrouping) {
                        environment.argosClientCareGroupingsStatus += '\n' + c.careGrouping;
                    }
                });
            } else {
                environment.argosClientCareGroupingsFilter = [];
                environment.argosClientCareGroupingsStatus = 'No Argos Client Care Groupings Configured';
            }

            environment.clientName = _.get(environment, 'client.name');
            environment.url = environment.dnsCname ? `https://${environment.dnsCname}.clarifyhealth.${environment.domainSuffix}` : '';
            environment.serviceName = environment.hostingAppName || environment.herokuSiteName || environment.daticaAppServiceName;

            environment.assignedOwnerEmailString = _.get(environment.client.assignedOwnerEmail, 'email', '');

            if (environment.environment20RequestId) {
                environment.claimFileType = 'ccg';
            } else {
                environment.claimFileType = _.get(environment, 'client.claim_file_type');
            }
            environment.environmentTypeName = _.get(_.find(environmentService.environmentTypes, { id: environment.environmentType }), 'title');
            environment.hostingProviderName = _.get(_.find(environmentService.hostingProviders, { id: environment.hostingProvider }), 'title');
            if (environment.hostingProvider === 'ecs') {
                if (environment.hostingAccount && environment.hostingAccount !== 'clarify') {
                    environment.hostingProviderName = _.get(_.find(environmentService.hostingAccounts, { id: environment.hostingAccount }), 'title');
                }
            }

            if (environment.dnsCname && environment.isDisabled === false) {
                try {
                    const version = await dataAccess.genericMethod({
                        model: 'Environment', method: 'getAppVersion',
                        parameters: {
                            id: environment.id
                        }
                    });
                    environment.uiVersion = version.localVersion || version.version;

                    const lastDeployed = _.get(_.find(version.installedVersions, { settingKey: 'CarePrismFunctionsAndViews' }), 'settingDate', environment.herokuReleasedAt);
                    if (lastDeployed) {
                        environment.lastDeployedDate = lastDeployed;
                        environment.lastDeployed = moment(new Date(lastDeployed)).fromNow();
                    }

                    environment.lastLogin = (version.lastLogin && version.lastLogin.length > 0) ? moment(new Date(version.lastLogin)).fromNow() : 'More than 1 month ago';
                    environment.lastLoginDate = new Date((version.lastLogin && version.lastLogin.length > 0) ? version.lastLogin : null);
                    environment.lastLoginDaysAgo = moment().diff(new Date(environment.lastLoginDate), 'days');

                    const dbVersion = _.find(_.get(version, 'installedVersions'), { settingKey: 'CarePrismDBVersion' });
                    environment.dbVersion = _.get(dbVersion, 'settingValue');
                    const dbUpdated = _.get(dbVersion, 'settingDate');
                    if (dbUpdated) {
                        environment.dbUpdatedDate = new Date(dbUpdated);
                        environment.dbUpdated = moment(new Date(dbUpdated)).fromNow();
                    }

                    // If we don't have a uiVersion, there is possibly some other issue going on.
                    if (!environment.uiVersion) {
                        environment.lastLogin = 'Possible Environment Error';
                        environment.lastLoginDate = new Date('');
                    }
                } catch (error) {
                    environment.lastLogin = 'Error';
                    environment.lastLoginDate = new Date('');
                    console.log(error);
                }

            } else {
                environment.lastLoginDate = new Date('');
                environment.dbUpdatedDate = new Date('');
            }
            enableProducts = _.orderBy(_.filter(states.argosClientProductConfiguration, { clientId: environment.clientId }), ['productConfiguration'], ['asc']);
            if (enableProducts?.length > 0) {
                environment.argosClientProductConfiguration = enableProducts;
                environment.argosClientProductConfigurationFilter = _.uniq(_.map(enableProducts, 'productConfiguration'));
                environment.argosClientProductConfigurationStatus = 'Argos Client Product Configured';
                _.forEach(enableProducts, (products: IClientProductConfiguration) => {
                    if (products.productConfiguration) {
                        environment.argosClientProductConfigurationStatus += '\n' + products.productConfiguration;
                    }
                });
            } else {
                environment.argosClientProductConfigurationFilter = [];
                environment.argosClientProductConfigurationStatus = 'No Argos Client Product Configured';
            }
        });

        states.argosClientCareGroupings = argosClientCareGroupings;
        states.table.data = states.environments;
    }

    async getEnvironments(states: IEnvironmentsListStates) {
        const environments = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEnvironments',
            parameters: {}
        });
        await this.modifyEnvData(environments, states);
    }

    formatColumnFilterList(states: IEnvironmentsListStates) {
        states.argosCCGColumnFilterList = this.matSvc.getColumnFilterList('argosClientCareGroupingsFilter', states.table.data);
    }

    showAlertTimer(title: string, message: string, status: any, seconds: number) {
        let timer = seconds * 1000;
        timer = seconds * 1000;
        swal({
            title,
            html: message + '<br/>The website should be ready in <strong class="swal-timer-count">' + timer / 1000 + '</strong> seconds...',
            type: status,
            timer,
            allowEscapeKey: false
        }).then(function () {
            swal.close();
        });
        const e = $('.sweet-alert').find('.swal-timer-count');
        let n = +e.text();
        setInterval(function () {
            n > 1 && e.text(--n);
        }, 1000);
    }

    exportEnvironmentInfoHandler(states: IEnvironmentsListStates) {
        let exportString = '';
        const data = states.environments;

        if (data && data.length > 0) {
            const columns: any[] = [];
            for (const prop in data[0]) {
                if (prop[0] !== '$' && !_.isFunction(data[0][prop]) && !_.isObject(data[0][prop])) {
                    columns.push(prop);
                    exportString += (prop + '\t');
                }
            }
            exportString += '\n';

            data.forEach(function (environment: any) {
                columns.forEach(function (column) {
                    exportString += (environment[column] + '\t');
                });
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, 'environments.csv');
        }
    }

    setTablePageSize(states: IEnvironmentsListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.environments.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IEnvironmentsListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile('environments', columns, data);
    }

    openMatTableSettings(states: IEnvironmentsListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
