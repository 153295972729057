import { Component, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ClarifyInsightsTriggerExtractModalService } from './clarifyInsightsTriggerExtractModal.component.service';
import { IClarifyInsightsTriggerExtractModalInterface } from './clarifyInsightsTriggerExtractModal.component.d';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import swal from 'sweetalert2';

@Component({
    selector: 'argos-clarifyInsightsTriggerExtractModal',
    templateUrl: './clarifyInsightsTriggerExtractModal.component.html',
    providers: [ClarifyInsightsTriggerExtractModalService],
    styleUrls: ['./clarifyInsightsTriggerExtractModal.component.css']
})


export class ClarifyInsightsTriggerExtractModalComponent extends ReactComponentBase<{}, IClarifyInsightsTriggerExtractModalInterface, {}> implements OnInit {
    constructor(private svc: ClarifyInsightsTriggerExtractModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
                public dialogRef: MatDialogRef<ClarifyInsightsTriggerExtractModalComponent>, private snackBar: MatSnackBar) {
        super({
            deliveryId: dialogData.props.deliveryId,
            customerCode: dialogData.props.customerCode,
            filterName: dialogData.props.filterName,
            extractType: dialogData.props.extractType,
            dataSource: dialogData.props.dataSource,
            dagName: dialogData.props.dagName,
            exportAsCsv: dialogData.props.exportAsCsv,
            requiredFields: ['customerCode', 'filterName', 'extractType', 'dataSource', 'dagName', 'exportAsCsv'],
            optionalFields: {}
        } as IClarifyInsightsTriggerExtractModalInterface);
    }

    ngOnInit() {
        this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    close() {
        this.dialogRef.close();
    }

    async verifyAndLaunch() {
        this.states.dagConfig = this.svc.updateDagConfig(this.states); // ensure dagConfig is up to date
        const isValidConfig: (boolean | any) = this.isConfigValid(this.states);
        if (isValidConfig[0]) {
            const swalResponse = await swal({
                title: 'Start the dag?',
                html: this.formatJsonToHtmlSwal(this.states),
                width: '800px',
                showCancelButton: true,
                confirmButtonText: 'Yes, start the dag!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            })
            if (swalResponse.value) {
                const dagResult: any = await this.svc.launchDag(this.states);
                if (dagResult.isSuccessful) {
                    this.snackBar.open('Dag Started Successfully', 'Dismiss', {
                        duration: 4000,
                    });
                    this.dialogRef.close('confirm');
                }
                else {
                    this.snackBar.open('Dag Failed to Start', 'Dismiss', {
                        duration: 4000,
                    });
                }
                this.dialogRef.close('confirm');
            }
        } else {
            swal({
                title: 'Invalid Configuration',
                text: `${JSON.stringify(isValidConfig[1])}`,
            })
        }
            
    }

    isConfigValid(states: IClarifyInsightsTriggerExtractModalInterface) {
        // add checks here when adding new fields to the extract dags
        let isValidConfig: boolean = true;
        let validationErrors: string[] = [];
        const validationRules = {
            customerCode: {
                message: 'Customer Code must be a string',
                check: (value: any) => {
                    return (value) ? typeof value === 'string': false;
                }
            },
            filterName: {
                message: 'Filter Name must be a string',
                check: (value: any) => {
                    return (value) ? typeof value === 'string': false;
                }
            },
            extractType: {
                message: 'Extract Type must be a string',
                check: (value: any) => {
                    return (value) ? typeof value === 'string': false;
                }
            },
            dataSource: {
                message: 'Data Source must be a string',
                check: (value: any) => {
                    return (value) ? typeof value === 'string': false;
                }
            },
            dagName: {
                message: 'Dag Name must be a string',
                check: (value: any) => {
                    return (value) ? typeof value === 'string': false;
                }
            },
            high_risk_patient_limit: {
                message: 'High Risk Patient Limit must be an integer between 0-100',
                check: (value: any) => {
                    return Number.isInteger(value) && value >= 0 && value <= 100;
                }
            },
            run_id: {
                message: 'Run ID must match our run_id format or be empty',
                check: (value: any) => {
                    return (value) ? value.match(/\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}/): true;
                }
            },
            do_not_create_athena_tables: {
                message: 'Do Not Create Athena Tables must be a boolean',
                check: (value: any) => {
                    return this.checkFieldType(value) == 'boolean';
                }
            },
            use_argos_staging: {
                message: 'Use Argos Staging must be a boolean',
                check: (value: any) => {
                    return this.checkFieldType(value) == 'boolean';
                }
            },
            export_as_csv: {
                message: 'Export As CSV must be a boolean',
                check: (value: any) => {
                    return this.checkFieldType(value) == 'boolean';
                }
            },
            num_csv_output_files: {
                message: 'Number of CSV Output Files must be an positive integer',
                check: (value: any) => {
                    return value % 1 === 0 && value > 0;
                }
            },
            export_csv_with_header: {
                message: 'Export CSV With Header must be a boolean',
                check: (value: any) => {
                    return this.checkFieldType(value) == 'boolean';
                }
            },
            csv_delimiter: {
                message: 'CSV Delimiter must be a string and either a comma or pipe',
                check: (value: any) => {
                    return (value) ? (typeof value === 'string' && (value === ',' || value === '|')): true;
                }
            },
        };
        Object.entries(validationRules).forEach(([field, { message, check }]) => {
            if (states.requiredFields.includes(field)) {
                if (!check(states[field])) {
                    isValidConfig = false;
                    validationErrors.push(message);
                }
            }
            else if (states.optionalFieldsOriginal.length === 0) {
                // no optional fields for this dag, no need to validate
            }
            else if (states.optionalFieldsOriginal.some((fieldObj: any) => Object.keys(fieldObj)[0] === field)) {
                if (!check(states.optionalFields.find((fieldObj: any) => Object.keys(fieldObj)[0] === field)[field])) {
                    isValidConfig = false;
                    validationErrors.push(message);
                }
            }
            else if (!states.optionalFieldsOriginal.some((fieldObj: any) => Object.keys(fieldObj)[0] === field)) {
                // if field is not in optionalFieldsOriginal, no need to validate
            }
            else {
                isValidConfig = false;
                validationErrors.push(`Field ${field} not found in validation rules`);
            }
        });
        return [isValidConfig, validationErrors];
    }

    formatJsonToHtmlSwal(states: IClarifyInsightsTriggerExtractModalInterface) {
        const json: { [key: string]: any } = {
            'Dag Name': states.dagName,
            'Filter Name': states.filterName
        };
        let html = '<div style="text-align: left;"><strong><u>Required Fields</u></string><ul>';
        for (const [key, value] of Object.entries(json)) {
            html += `<li><strong>${key}:</strong> ${typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</li>`;
        };
        if (Object.keys(states.optionalFields).length > 0) {
            html += '</ul><strong><u>Optional Fields</u></string><ul>';
            for (const field of states.optionalFields) {
                const key = Object.keys(field)[0];
                const value = field[key];
                html += `<li><strong>${key}:</strong> ${typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</li>`;
            }
        }
        html += '</ul></div>';
        return html;
    }

    checkFieldType(value: any) {
        return typeof value;
    }

    updateOptionalBool(key: string, value: any) {
        this.states.optionalFields[key] = value;
    }

    updateOptionalField(value: string, key: string) {
        let isValidChange: boolean = false;
        const optionalField = this.states.optionalFieldsOriginal.find((field: any) => Object.keys(field)[0] === key);
        const optionalFieldIndex = this.states.optionalFields.findIndex((field: any) => Object.keys(field)[0] === key);
        switch (this.checkFieldType(optionalField[key])) {
            case 'number':
                if (Number.isInteger(parseInt(value))) {
                    this.states.optionalFields[optionalFieldIndex][key] = value;
                    isValidChange = true;
                }
                break;
            case 'string':
                this.states.optionalFields[optionalFieldIndex][key] = value;
                isValidChange = true;
                break;
            default:
                break;
        }
        if (!isValidChange && value) {
            this.snackBar.open('Invalid Input', 'Dismiss', {
                duration: 2000,
            });
        }
        
    }

    openAirflowLink(dagId: string, dagRunId: string) {
        const airflowBaseUrl = this.states.astronomerBaseUrl;
        const airflowDagLink = (dagRunId) ? `${airflowBaseUrl}/${dagId}/grid?dag_run_id=${encodeURIComponent(dagRunId)}` :`${airflowBaseUrl}/${dagId}/grid`;
        window.open(airflowDagLink, '_blank');
    }

 }