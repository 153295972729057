import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core'; 
import { ArgosStoreService } from './argosStore.service';
import { NgDataAccess } from './dataAccess.service';
import { CONST } from '../constants/globals';
const yn = require('yn');
import * as _ from 'lodash';

interface UserRoleModel {
    roleName: string;
    stateNames: string[];
    redirectTo: string;
    redirectToUrl: string;
    accessSettings: {[key: string]: any};
}

/**
 * This service emulates an Authentication Service.
 */
@Injectable()
export class UserRoleService {

    userRoles: UserRoleModel[] = [];

    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService) { }

    // get the argos role configuration for the current user
    async getUserRoleConfigByRoleName(role: string) {
        if (_.isEmpty(this.userRoles)) {
            this.userRoles = await this.dataAccess.genericMethod({
                model: 'ArgosUser', method: 'getUserPermissions'
            });

            this.argosStore.setItem('userRoles', this.userRoles);   // cache the user roles
        }

        return _.find(this.userRoles, { roleName: role }) || (({} as any) as UserRoleModel);
    }


    setUserProfile(profile: any) {
        this.argosStore.setItem('profile', profile);
    }

    getUserProfile() {
        return this.argosStore.getItem('profile');
    }

    getEmailAddress() {
        const userProfile = this.getUserProfile();
        return _.get(userProfile, 'email', _.get(userProfile, 'name'));
    }

    getAuth0UserId() {
        const userProfile = this.getUserProfile();
        const result = _.get(userProfile, 'sub', null);
        return result;
    }

    getDisplayName() {
        let result;
        const userProfile = this.getUserProfile();
        const firstName = _.get(userProfile, 'user_metadata.firstName', null);
        const lastName = _.get(userProfile, 'user_metadata.lastName', null);
        if (!firstName) {
            result = _.get(userProfile, 'nickname', this.getEmailAddress().split('@')[0]);
        } else {
            result = `${firstName}`;
        }

        return _.startCase(result);
    }

    getSSOUserRole() {
        const userProfile = this.getUserProfile();
        return _.toLower(_.get(userProfile, 'app_metadata.role', null));
    }

    async isAdminUser() {
        let result = false;
        const userRole = await this.getUserRole();
        if (userRole === 'admin') {
            result = true;
        }
        return result;
    }

    async getUserRoleDisplayName() {
        const result = await this.getUserRole();
        return _.startCase(result);
    }

    async getCurrentDatabaseInfo() {
        // only admins can see the database name
        let result = this.argosStore.getItem('currentDatabaseInfo');
        if (!result) {
            const userRole = await this.getUserRole();
            if (CONST.USER_ROLE_ADMIN === userRole) {
                result = await this.dataAccess.genericMethod({
                    model: 'ArgosUser', method: 'getCurrentDatabaseInfo'
                });
            } else {
                result = {};
            }
            this.argosStore.setItem('currentDatabaseInfo', result);
        }

        return result;
    }

    // get what the users assign role is
    async getUserRole() {

        let result = this.argosStore.getItem('roleName');

        if (!result) {
            // get role
            const username = this.argosStore.getItem('username');
            const profile: any = this.argosStore.getItem('profile');

            const argUsr = await this.dataAccess.genericMethod({
                model: 'ArgosUser', method: 'getCurrentUserRole',
                parameters: {
                    email: username,
                    profile
                }
            });

            // if for some reason we don't get a role back, set to inactive
            if (!argUsr.role || !argUsr.permissionType) {
                console.error('error getting user role for ' + username + ' setting to inactive. found ' + JSON.stringify(argUsr));
                result = CONST.USER_ROLE_INACTIVE;
            } else {
                result = argUsr.role;
                // console.log(username + ' is using role permission type ' + argUsr.permissionType);
                this.argosStore.setItem('rolePermissionType', argUsr.permissionType);
            }

            this.argosStore.setItem('roleName', result);
        }

        return result;
    }

    getUserRolePermissionType() {
        return this.argosStore.getItem('rolePermissionType');
    }

    getDefaultMenus(allMenus: any) {
        const result: string[] = [];
        _.forOwn(allMenus, (value, key) => {
            _.forEach(value, (val, k) => {
                result.push(val.sref);
            });
        });

        return result;
    }

    async isValidScreenAccess(name: string) {
        const userRole = await this.getUserRole();
        let result = false;

        if (CONST.USER_ROLE_ADMIN === userRole) {
            result = true;
        } else if (CONST.USER_ROLE_INACTIVE === userRole) {
            result = false;
        } else {
            let screenActionsAllowed: string[] = this.argosStore.getItem('userRoleScreenAccess');

            if (!screenActionsAllowed) {
                screenActionsAllowed = [];
                const userRoleConfig = await this.getUserRoleConfigByRoleName(userRole);
                const userRoleActions = userRoleConfig.accessSettings['menuItems'] || {};
                _.forOwn(userRoleActions, (value, parentKey) => {
                    _.forOwn(value.actions, (val, k) => {
                        if (val !== false) {
                            screenActionsAllowed.push(parentKey + '.' + k);
                        }
                    });
                });
                this.argosStore.setItem('userRoleScreenAccess', screenActionsAllowed);
            }

            result = _.includes(screenActionsAllowed, name);
        }

        return result;
    }

    async getValidMenuItems(allMenuOptions?: any[]): Promise<any> {
        const userRole = await this.getUserRole();
        let result: string[] = [];

        if (CONST.USER_ROLE_ADMIN === userRole) {
            result = this.getDefaultMenus(allMenuOptions);
        } else if (CONST.USER_ROLE_INACTIVE === userRole) {
            result = [] ;
        } else {
            const userRoleConfig = await this.getUserRoleConfigByRoleName(userRole);
            const userRoles = userRoleConfig.accessSettings['menuItems'] || {};
            _.forOwn(userRoles, (value, key) => {
                result.push(key);
            });
        }

        return result;
    }

    async isValidPageByUserRole(userRole: any, stateName: string, stateParentName?: string): Promise<boolean> {

        if (!userRole) {
            return false;   // no role so invalidate everything
        }

        userRole = userRole.toLowerCase();
        const allowedRoleStateNames = ['argos.inactive', 'argos.404'];
        const userRoleConfig = await this.getUserRoleConfigByRoleName(userRole);
        let isValid = true;

        if (CONST.USER_ROLE_ADMIN === userRole) {
            isValid = true; // always true for admin
        } else if (allowedRoleStateNames.includes(stateName)) {
            isValid = true;
        } else if (CONST.USER_ROLE_INACTIVE === userRole) {
            isValid = false; // always false for inactive
        } else if (!_.isEmpty(userRoleConfig) && userRole) {
            if (stateName) {
                isValid = (userRoleConfig.stateNames.indexOf(stateName) > -1);
            } else {
                isValid = false;
            }
        } else {
            isValid = false;
        }

        return isValid;
    }

    //menu
    async initMenuSelections(userRole: any, state: StateService) {
        const argosSysMenus: any[] = [];
        const ngStates = state.get();
        _.forEach(ngStates, (cState: any) => {
            if (cState.name.startsWith('argos.')) {
                cState.data = _.pick(cState.data, Object.getOwnPropertyNames(cState.data));
                //if actions or requiredApiAccess are not defined, then define them as empty
                if (_.isUndefined(cState.data.actions)) {
                    cState.data.actions = {};
                }
                if (_.isUndefined(cState.data.requiredApiAccess)) {
                    cState.data.requiredApiAccess = {};
                }
                argosSysMenus.push(cState);
            }
        });

        let argosMenus = _.cloneDeep(require('../constants/menus'));    //load menus object
        //figure out what menus are selected and therefore visible for this user
        _.forOwn(argosMenus, (value, key) => { 
            _.forEach(value, (m: any) => {
                let parentActions = _.filter(argosSysMenus, function (cState: any) { return cState.name === m.sref })
                this.processMenuSelection(m, m.sref, parentActions[0]?.data?.actions, parentActions[0]?.data?.requiredApiAccess, userRole);                
                
                //setup actions states for child menu items
                if (m.sref.endsWith('.list') || m.sref === 'argos.ecsAccess.ecsAppList') {
                    
                    //if this is a list item, then we assume there could be add/edit/delete
                    let parentStateName = m.sref.substring(0, m.sref.length - 4);                    
                    m.subMenus = [];
                    
                    const childSystemMenus:any[] = [];
                    _.forEach(argosSysMenus, (cState: any) => { 
                        if (cState.name.startsWith(parentStateName) && cState.name !== m.sref) {
                            childSystemMenus.push(_.clone(cState));
                        }
                    })

                    _.forEach(childSystemMenus, (childSystemMenu: any) => {
                        let displayName = childSystemMenu?.data.pageTitle || childSystemMenu.name.substring(childSystemMenu.name.lastIndexOf('.') + 1, childSystemMenu.name.length);
                        let newMenu = {
                            sref: childSystemMenu.name,
                            selected: false,
                            name: displayName
                        };
                        this.processMenuSelection(newMenu, newMenu.sref, childSystemMenu.data?.actions, childSystemMenu.data?.requiredApiAccess, userRole);  
                        m.subMenus.push(newMenu);
                    });
                }
            });
        } );

        return argosMenus;
    }

    async saveMenuSelections(userRole: any, originalUserRole: any, argosMenus: any) {
        //save menu access
        userRole.accessSettings['menuItems'] = {};  //clear menu access
        
        _.forOwn(argosMenus, (value: any, key: string) => { 
            _.forEach(value, (menu: any) => {
                this.saveMenuAccess(menu, menu.sref, userRole);
                _.forEach(menu.subMenus, (subMenu: any) => {
                    this.saveMenuAccess(subMenu, subMenu.sref, userRole);
                });
            });
        });

        await this.dataAccess.genericUpsert({
            model: 'ArgosUserRole',
            data: userRole
        });

        await this.saveUserRoleHistory(userRole, originalUserRole);
    }

    async saveUserRoleHistory(userRole: any, originalUserRole: any) {
        // Only save a new version if something actually changed.
        if (_.isEqual(originalUserRole, userRole)) {
            return Promise.resolve();
        }

        //set  the next version
        let version = 0;
        const username = this.argosStore.getItem('username');
        if (userRole && userRole.argosUserRoleHistories && userRole.argosUserRoleHistories.length > 0) {
            version = _.max(_.map(userRole.argosUserRoleHistories, 'version')) || 0;
        }
        version++;

        //dont save the history in the history table
        delete userRole.argosUserRoleHistories;    
        delete userRole.propertyErrors;
        delete userRole.argosMenus;
        delete userRole.argosUser;
        delete userRole.numberOfUsers;
        
        const argosUserRoleHistory = await this.dataAccess.genericUpsert({
            model: 'ArgosUserRoleHistory',
            data: {
                argosUserRoleId: userRole.id,
                version: version,
                createdBy: username,
                creationDate: new Date(),
                definition: userRole
            }
        });
    }

    saveMenuAccess(menu: any, menuStateName: string, userRole: any) {
        if (menu.selected) {
            //append the sref to the menu access
            if (!userRole.accessSettings['menuItems'][menuStateName]) {
                userRole.accessSettings['menuItems'][menuStateName] = {}  //init the menu object. its existence means the menu is selected
            }

            //now add the actions to the menu
            if (menu.actions) {
                if(!userRole.accessSettings['menuItems'][menuStateName]['actions']) {
                    userRole.accessSettings['menuItems'][menuStateName]['actions'] = {};
                }

                _.forOwn(menu.actions, (value: any, key: string) => { 
                    if (value === true) {
                        userRole.accessSettings['menuItems'][menuStateName]['actions'][key] = true;
                    }
                });
            }

            //add requiredApiAccess to the menu by just copying it over. No changes should be allowed to this from the UI
            if (menu.requiredApiAccess) {
                if(!userRole.accessSettings['menuItems'][menuStateName]['requiredApiAccess']) {
                    userRole.accessSettings['menuItems'][menuStateName]['requiredApiAccess'] = menu.requiredApiAccess;
                }
            }
        }
    }

    processMenuSelection(sysMenu: any, sysMenuStateName: string, defaultActions: any, defaultRequiredApiAccess: any, userRole: any) {
        //init actions and requiredApiAccess if not defined and if nothign is passed in set it to empty
        if (!sysMenu.actions) {
            sysMenu.actions = defaultActions || {};
        }

        //we set the requiredApiAccess so it is save in the role object. You should NOT be able to change this from the UI
        if (!sysMenu.requiredApiAccess) {
            sysMenu.requiredApiAccess = defaultRequiredApiAccess || {};
        }
        
        if (userRole.accessSettings?.menuItems[sysMenuStateName]) {
            sysMenu.selected = true;
            _.forOwn(userRole.accessSettings?.menuItems[sysMenuStateName].actions, (value, key) => { 
                if (value === true) {           
                    sysMenu.actions[key] = true;
                }
            });
        }
    }
}
