import { Component } from '@angular/core';

@Component({
    selector: 'argos-allowedField',
    template: `<ui-view></ui-view>`
})

export class AllowedFieldComponent {

}
