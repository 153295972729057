import { Injectable } from '@angular/core';
import { IPayerRatesMetaEditStates, IPayerRatesMetaEditService } from './payerRatesMetaEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewPayerRatesMetaHistoryComponent } from '../../payerRates/viewPayerRatesMetaHistory/viewPayerRatesMetaHistory.component';

@Injectable()
export class PayerRatesMetaEditService implements IPayerRatesMetaEditService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private state: StateService, private argosStore: ArgosStoreService, private matDialog: MatDialog) {
        //
    }
    async initDelegate(states: IPayerRatesMetaEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IPayerRatesMetaEditStates) {
        if (this.uiRouter.globals.params.id) {
           
            const payerRatesMeta = await this.dataAccess.genericMethod({
                model: 'PayerRateMeta',
                method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.id,
                    filter: { 
                        include: ['payerRateMetaHistories'],
                        fields: ['id', 'dataSource', 'payerId', 'parameters', 'webscrapingType', 'url', 'status', 'lastRunDate', 'skipUniqueLinks', 'skipTocUpload', 'notes', 'dataSize', 'stage', 'clusterIds', 'workerCount', 'numFiles', 'instanceSize']
                    }
                }
            });


            states.payerRatesMeta = _.cloneDeep(payerRatesMeta);
            states.originalPayerRatesMeta = _.cloneDeep(payerRatesMeta);

            const parameters = (payerRatesMeta.parameters) ? JSON.stringify(payerRatesMeta?.parameters, undefined, 4) : undefined;
            _.set(states.payerRatesMeta, 'parameters', parameters);

            states.payerRatesMeta.lastRunDate = (states.payerRatesMeta.lastRunDate) ? new Date(states.payerRatesMeta.lastRunDate).toISOString().slice(0, 10) : undefined;
            if (states.payerRatesMeta.clusterIds) {
                const pipelineRuns = await this.dataAccess.genericFind({
                    model: 'PipelineHistory',
                    filter: {
                        order: 'startTime desc',
                        where: {
                            emrClusterId: { inq: states.payerRatesMeta.clusterIds }
                        }
                    }
                });

                const runInfo = _.map(pipelineRuns, (p: any) => {
                    const startDate = new Date(p.startTime).toDateString();
                    return {
                        emrClusterId: p.emrClusterId,
                        runtimeHrs: (Date.parse(p.endTime) - Date.parse(p.startTime)) / (1000 * 60 * 60),
                        cost: parseInt(p.cost).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }),
                        date: startDate
                    };
                });

                states.pipelineRuns = runInfo;

            } else {
                states.pipelineRuns = null;
            }

        }
    }

    viewHistory(states: IPayerRatesMetaEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    payerRatesMeta: states.payerRatesMeta
                }
            }
        };
        this.matDialog.open(ViewPayerRatesMetaHistoryComponent, dialogConfig);
    }

    async saveHistory(states: IPayerRatesMetaEditStates) {
        // Only save a new version if something actually changed.
        if (_.isEqual(states.originalPayerRatesMeta, states.payerRatesMeta)) {
            return Promise.resolve();
        }

        // set  the next version
        let version = 0;
        const username = this.argosStore.getItem('username');
        if (states.payerRatesMeta && states.payerRatesMeta.payerRateMetaHistories && states.payerRatesMeta.payerRateMetaHistories.length > 0) {
            version = _.max(_.map(states.payerRatesMeta.payerRateMetaHistories, 'version')) || 0;
        }
        version++;

        delete states.payerRatesMeta.payerRateMetaHistories;    // dont save the history in the history table
        const payerRatesMetaHistory = await this.dataAccess.genericUpsert({
            model: 'PayerRateMetaHistory',
            data: {
                payerRateMetaId: states.payerRatesMeta.id,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: states.payerRatesMeta
            }
        });
        return payerRatesMetaHistory;
    }

    async save(states: IPayerRatesMetaEditStates) {
        states.saveInProgress = true;
        states.payerRatesMeta.parameters = (states.payerRatesMeta.parameters) ? JSON.parse(states.payerRatesMeta.parameters) : undefined;
        const data = states.payerRatesMeta;
        const result: any = await this.dataAccess.genericUpsert({
            model: 'PayerRateMeta',
            data
        });

        states.payerRatesMeta.id = result.id;
        await this.saveHistory(states);
        this.state.go('argos.modelMetaData.payerRatesMeta.list');
        states.saveInProgress = false;
    }
}
