import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { IViewReleaseImpactEnvironmentModalProps, IViewReleaseImpactEnvironmentModalStates } from "./viewReleaseImpactEnvironmentModal.component.d";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewReleaseImpactEnvironmentModalService } from "./viewReleaseImpactEnvironmentModal.component.service";
import { ReactComponentBase } from "client/app/components/reactComponentBase/reactComponentBase.component";
import { Clipboard } from '@angular/cdk/clipboard';
import { MatPaginator } from "@angular/material/paginator";
@Component({
    selector: 'argos-release-view-release-impact-environment-modal',
    templateUrl: './viewReleaseImpactEnvironmentModal.component.html',
    providers: [ViewReleaseImpactEnvironmentModalService]
})

export class ViewReleaseImpactEnvironmentModalComponent extends ReactComponentBase<IViewReleaseImpactEnvironmentModalProps, IViewReleaseImpactEnvironmentModalStates, {}>
    implements OnInit {
    copyText = 'Copy to Clipboard';
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    dataSize: any;

    constructor(private svc: ViewReleaseImpactEnvironmentModalService, @Inject(MAT_DIALOG_DATA) dialogData: any, private dialogRef: MatDialogRef<ViewReleaseImpactEnvironmentModalComponent>, private clipboard: Clipboard) {
            super();
            this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    };

    close() {
        this.dialogRef.close('cancel');
    };
    copyToClipboard() {
        this.clipboard.copy(this.states.environments);
        this.copyText = 'Copied!';
    }
}