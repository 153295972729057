import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IEcsRepoEditStates } from './ecsRepoEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EcsRepoEditService } from './ecsRepoEdit.component.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'argos-ecsRepoEdit',
    templateUrl: './ecsRepoEdit.component.html',
    providers: [EcsRepoEditService]
})

export class EcsRepoEditComponent extends ReactComponentBase<{}, IEcsRepoEditStates, {}> implements OnInit {
    constructor(private svc: EcsRepoEditService, private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef) {
        super({
            activeTab: 0,
            awsAccounts: [{ id: 'clarify', name: 'Clarify AWS Account', },
                { id: 'cleardata', name: 'Clear Data AWS Account' }
            ],
            repoMatTableColumns: ['tagValue', 'dateValue'],
            selectedAwsAccount: 'clarify',
            pollingVars: {}
        } as IEcsRepoEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.states.repoMatTable = new MatTableDataSource(this.states.repoImages);
        this.cdr.detectChanges();
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.repoMatTable.filter = filterValue.trim().toLowerCase();
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
    }

    async pushImage() {

        this.states.isPushingImage = true;
        let pushAdditional = false;
        let noBuild = false;

        if (this.states.selectedRepository === 'clarify-platform' && this.states.selectedAwsAccount === 'cleardata') {
            pushAdditional = true;

            this.states.repoImages.forEach((image) => {
                if (image && image.imageTags && image.imageTags.length > 0 && image.imageTags.includes(this.states.sideBranchLabel)) {
                    noBuild = true;
                }
            });
        }

        const pushImageArg: any = {
            repositoryName: this.states.selectedRepository,
            gitRepositoryName: this.states.gitRepositoryName,
            sideBranchName: this.states.sideBranchName,
            sideBranchLabel: this.states.sideBranchLabel,
            awsAccountName: this.states.selectedAwsAccount,
            pushAdditional,
            noBuild
        };

        if (this.states.sideBranchTag && this.states.sideBranchTag.trim().length > 0) {
            pushImageArg.sideBranchTag = this.states.sideBranchTag;
        }
        const response = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'pushSideBranchIntoRepo', parameters: pushImageArg
        });
        this.states.pollingVars.runId = response.runId;
        this.states.pollingVars.buildLog = response.buildLog;

        this.states.pushImageStatus = 'Pushing';
        if (this.states.sideBranchName) {
            this.states.pushImageStatus += ' side branch ' + this.states.sideBranchName;
        }

        this.states.pushImageStatus += ' with label ' + this.states.sideBranchLabel;

        if (this.states.sideBranchTag) {
            this.states.pushImageStatus += ' with tag ' + this.states.sideBranchTag;
        }

        if (pushAdditional = true) {
            this.states.pushImageStatus += ' to two regions';
        }

        this.states.pushImageStatus += '.';
        this.states.pushImageStatus += ' This could take 20 minutes. Log located at ' + this.states.pollingVars.buildLog + '. Running';
        this.cdr.detectChanges();

        // need to use the arrow option to get access to this
        this.states.promotePollingTimer = setInterval(() => {
            this.svc.pollPromoteImageStatus(this.states);
        }, 5000);

    }

    selectedAWSServiceChanged() {
        this.svc.loadEcrRepos(this.states);
    }

    selectedRepoFieldChanged() {
        this.svc.selectedRepoFieldChanged(this.states);
    }
}
