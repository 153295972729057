import { Injectable } from '@angular/core';
import { IHierarchyEditStates, IHierarchyEditService } from './hierarchyEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';

@Injectable()
export class HierarchyEditService implements IHierarchyEditService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private state: StateService) {
        //
    }
    async initDelegate(states: IHierarchyEditStates): Promise<object> {
        let observationHierarchy = {};
        if (this.uiRouter.globals.params.id) {
            observationHierarchy = await this.dataAccess.genericMethod({
                model: 'ObservationCategoryHierarchy', method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.id
                }
            });
        } else {
            observationHierarchy = {
                category: '',
                category2: '',
                category3: '',
                category4: ''
            };
        }
        return { observationHierarchy };
    }

    async saveHander(states: IHierarchyEditStates) {
        // Need to add logic here
        states.saveInProgress = true;
        const data = states.observationHierarchy;
        await this.dataAccess.genericUpsert({
            model: 'ObservationCategoryHierarchy',
            data
        });
        this.state.go('argos.ccgDesigner.observationHierarchies.list');
        states.saveInProgress = false;
    }
}
