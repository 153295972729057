import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
// import { MatDialogRef } from '@angular/material/dialog';
import { IViewDeequThresholdModalProps, IViewDeequThresholdModalStates, IViewDeequThresholdModalService } from './viewDeequThresholdModal.component.d';
import { ViewDeequThresholdModalComponent } from './viewDeequThresholdModal.component';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';


@Injectable()
export class ViewDeequThresholdModalService implements IViewDeequThresholdModalService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private dialogRef: MatDialogRef<ViewDeequThresholdModalComponent>) {}
    async initDelegate(props: IViewDeequThresholdModalProps, states: IViewDeequThresholdModalStates): Promise<object> {
        states.lodash = _;
        states.sources = props.sources;
        states.args = props.args;
        states.thresholdKey = props.thresholdKey;

        // if the thresholdKey is not in the args or value is empty, then we need an object with key of sourceName from sources and default of 'common'
        if (!states.args[states.thresholdKey]) {
            states.args[states.thresholdKey] = {};
            states.sources.forEach((source: any) => {
                states.args[states.thresholdKey][source.sourceName] = undefined;
            });
            states.args[states.thresholdKey]['common'] = undefined;
        }

        // If numer of keys in states.args[states.thresholdKey] is less than number of sources in stat.sources, then we need to add the missing sources
        if (Object.keys(states.args[states.thresholdKey]).length < states.sources.length + 1) {
            states.sources.forEach((source: any) => {
                if (!states.args[states.thresholdKey][source.sourceName]) {
                    states.args[states.thresholdKey][source.sourceName] = undefined;
                }
            });
            if (!states.args[states.thresholdKey]['common']) {
                states.args[states.thresholdKey]['common'] = undefined;
            }
        }

        return {};
    }

    changeDelegate(oldProps: IViewDeequThresholdModalProps, newProps: IViewDeequThresholdModalProps, states: IViewDeequThresholdModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    saveDelegate(states: IViewDeequThresholdModalStates) {
        // remove keys with undefined values for lower_limit and upper_limit in args
        for (const key in states.args[states.thresholdKey]) {
            if (states.args[states.thresholdKey][key] === undefined) {
                delete states.args[states.thresholdKey][key];
            }
        }
        this.dialogRef.close(states.args);
    }

    argsUpdate(arg: any, states: IViewDeequThresholdModalStates) {
        states.args[states.thresholdKey][arg.key] = arg.value;
    }

}
