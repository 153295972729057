import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IEnvironmentEditProps, IEnvironmentEditStates } from './environmentEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EnvironmentEditService } from './environmentEdit.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import swal from 'sweetalert2';

@Component({
    selector: 'argos-environmentEdit',
    templateUrl: './environmentEdit.component.html',
    providers: [EnvironmentEditService]
})

export class EnvironmentEditComponent
    extends ReactComponentBase<IEnvironmentEditProps, IEnvironmentEditStates, {}> implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: EnvironmentEditService, private cdr: ChangeDetectorRef) {
        super({
            platformBuilds: [],
            disablePlatformUI: true,
            applicationTypes: ['prism', 'argos', 'airflow', 'provider-portal', 'pricer api'],
            ecrRepo: ['', 'clarify-platform', 'prism-1.0', 'argos'],
            nameExists: false,
            showDeletedCareGroupings: false,
            clientCareGroupingMatTable: new MatTableDataSource([])
        } as unknown as IEnvironmentEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.states.clientCareGroupingMatTable.sort = this.matTbSort;
    }

    environmentNameChange() {
        this.svc.environmentNameChangeHandler(this.states);
    }
    addEditCareGrouping(row?: any) {
        this.svc.addEditCareGroupingHandler(this.states, row, this.cdr);
    }
    viewCareGroupingHistory(row: any) {
        this.svc.viewCareGroupingHistoryHandler(row);
    }
    migrateCareGrouping(row: any) {
        this.svc.migrateCareGroupingHandler(row);
    }
    migrationComplete(row: any) {
        this.svc.migrationCompleteHandler(row, this.states);
    }
    searchClientCareGroupings(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.clientCareGroupingMatTable.filter = filterValue.toLowerCase();
    }

    addEcsKeyValue(key: any, value: any) {
        this.svc.addEcsKeyValueHandler(key, value, this.states);
    }

    deleteEcsKeyValue(key: any) {
        delete this.states.environment.ecsParameters[key];
    }

    markForDeletion(row: any) {
        this.svc.markForDeletion(row, this.states);
    }

    undelete(row: any) {
        this.svc.undelete(row, this.states);
    }

    showDeletedChanged() {
        this.svc.showGrid(this.states);
    }

    save() {
        this.svc.saveHandler(this.states);
    }

    async deleteUptimeAlarms() {

        const alarms = await this.svc.findAlarms(this.states.environment.dnsCname + '.clarifyhealth.' + this.states.environment.domainSuffix);        

        if (alarms.isSuccessful = true && alarms.alarms.length > 0) {
            this.svc.deleteUptimeAlarms(alarms, this.states);
        } else {
            swal({
                title: 'Error',
                text: 'No alarms found',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                //
            });
        }
    }
}
