import { Injectable } from '@angular/core';
declare const saveAs: any;

@Injectable()
export class ExportToExcelService {
    constructor() {
        //
    }

    exportToFile(fileName: string, columns: any, data: any, trimTrailing = false) {
        let exportString = '';
        const delimiter = ',';

        if (data && data.length > 0) {
            for (const column of columns) {
                exportString += (column + delimiter);
            }
            if (trimTrailing) {
                exportString = exportString.slice(0, -1);
            }
            exportString += '\n';

            data.forEach(function (row: any) {
                columns.forEach(function (column: any) {
                    exportString += ('"' + row[column] + '"' + delimiter);
                });
                if (trimTrailing) {
                    exportString = exportString.slice(0, -1);
                }
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, `${fileName}.csv`);
        }
    }
}
