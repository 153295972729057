import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IClientEditStates, IClientEditService } from './clientEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/angular';
import { MD5Service } from '../../../services/md5.service';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class ClientEditService implements IClientEditService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private md5: MD5Service, private state: StateService,
        private alertSvc: AlertService, private changeDetection: ChangeDetectorRef) {
        //
    }
    async initDelegate(states: IClientEditStates): Promise<Object> {
        await this.activate(states);
        return states;
    }

    async activate(states: IClientEditStates) {

        const salesforceAccounts = await this.dataAccess.genericFind({
            model: 'SalesforceAccount',
            filter: {
                where: {
                    type: {
                        inq: ['Customer', 'Selling', 'Prospect', 'OTF Customer', 'Former ARR Customer']
                    }
                }
            }
        });

        states.salesforceAccounts = _.orderBy(_.filter(salesforceAccounts, function (item: any) {
            return (item.name && !item.name.toLowerCase().includes('closed'));
        }), ['name']);
        const dataSources = await this.dataAccess.genericFind({
            model: 'DataSource',
            filter: {}
        });
        states.availableDataSources = _.orderBy(dataSources, 'code');
        states.productConfigurations = await this.dataAccess.genericFind({
            model: 'ProductConfiguration',
            filter: { order: 'title' }
        });

        states.assignedOwnerEmailOptions = await this.dataAccess.genericMethod({
            model: 'ArgosUser',
            method: 'getActiveUsers',
            parameters: {}
        });

        states.assignedOwnerEmailOptions = _.map(states.assignedOwnerEmailOptions, function (u: any) {
            return {
                id: u.id,
                email: u.email
            }
        });
 
        if (this.uiRouter.globals.params.id) {
            states.client = await this.dataAccess.genericMethod({
                model: 'Client',
                method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.id,
                    filter: { include: ['environments', 'products', 'clientProductConfigurations', 'clientDataSources'] }
                }
            });

            states.availableProductConfigurations = this.updateAvailableProductConfigurations(states.productConfigurations, states.client.clientProductConfigurations, states);

            // $stateParams does not have the environmentId
            if (this.uiRouter.globals.params && this.uiRouter.globals.params.environmentId) {
                const index = _.findIndex(states.client.environments, { id: this.uiRouter.globals.params.environmentId });
                // console.log('selected tab should be ' + index);
                if (index >= 0) {
                    setTimeout(function () {
                        states.activeTab = index + 1;
                    });
                }
            }

            if (states.client.assignedOwnerEmail) {
                states.selectedAssignedOwnerId = states.client.assignedOwnerEmail.id;    
            }

        } else {
            states.client = {
                clientProducts: {},
                clientProductConfigurations: []
            };

            states.availableProductConfigurations =
                this.updateAvailableProductConfigurations(states.productConfigurations, states.client.clientProductConfigurations, states);
        }
    }

    async clientNameChange(states: IClientEditStates) {
        if (states.client && !states.client.id) {
            states.client.internal_name = _.snakeCase(states.client.name);
        }

        if (states.clientform) {
            if (states.client.name && states.client.name.length > 0) {
                const response = await this.dataAccess.genericFind({
                    model: 'Client',
                    filter: {
                        where: {
                            name: states.client.name
                        }
                    }
                });
                if (response && response.length > 0 && states.client.id !== response[0].id) {
                    states.clientformNameExists = true;
                }
                else {
                    states.clientformNameExists = false;
                }
                this.changeDetection.detectChanges();
            }
        }
    }

    async internalNameChange(states: IClientEditStates) {
        if (states.clientform) {
            states.clientformInternalNameExists = true;
            const response = await this.dataAccess.genericFind({
                model: 'Client',
                filter: {
                    where: {
                        internal_name: states.client.internal_name
                    }
                }
            });
            if (response.length === 0) {
                states.clientformInternalNameExists = false;
            }
            this.changeDetection.detectChanges();
        }
    }

    addEnvironment(states: IClientEditStates) {
        const newEnvironment = {
            clientId: states.client.id,
            name: ''
        };

        if (!states.client.environments || !_.isArray(states.client.environments)) {
            states.client.environments = [];
        }
        states.client.environments.push(newEnvironment);

        setTimeout(function () {
            states.activeTab = states.client.environments.length;
        });
    }

    async save(states: IClientEditStates) {
        if (!states.client.id) {
            states.client.id = this.md5.md5('Client' + states.client.internal_name);
        }

        const clientProductionConfigurations = _.compact(_.map(states.client.clientProductConfigurations, function (cpc: any) {
            if (!cpc.clientId) {
                cpc.clientId = states.client.id;
                return cpc;
            }
        }));
        try {
            await this.dataAccess.genericUpsert({
                model: 'Client',
                data: states.client
            });
            if (clientProductionConfigurations.length > 0) {
                await this.dataAccess.genericMethod({
                    model: 'ClientProductConfiguration', method: 'createMany',
                    parameters: { clientProductionConfigurations }
                });
            }
            this.state.go('argos.environments.clients.list');
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    async addEditDataSource(states: IClientEditStates) {
        await this.dataAccess.genericUpsert({
            model: 'ClientDataSource',
            data: {
                clientId: states.client.id,
                dataSource: states.dataSourceToAdd,
                reason: states.dataSourceReason
            }
        });
        await this.activate(states);
        this.changeDetection.detectChanges();
    }

    async deleteDataSource(row: any, states: IClientEditStates) {
        if (row && row.id) {
            await this.dataAccess.genericMethod({
                model: 'ClientDataSource', method: 'destroyById',
                parameters: { id: row.id }
            });
            await this.activate(states);
            this.changeDetection.detectChanges();
        }
    }

    async addEditProductConfiguraton(id: any, states: IClientEditStates) {
        const productConfiguration: any = _.find(states.productConfigurations, { id });

        const clientProductConfiguration: any = {
            clientId: states.client.id,
            productConfiguration: productConfiguration?.name
        };
        if (!_.find(states.client.clientProductConfigurations, { productConfiguration: clientProductConfiguration.productConfiguration })) {
            if (states.client.id) {
                states.productConfigToAdd = undefined;
                try {
                    await this.dataAccess.genericUpsert({
                        model: 'ClientProductConfiguration',
                        data: clientProductConfiguration
                    });
                    states.client.clientProductConfigurations = await this.dataAccess.genericFind({
                        model: 'ClientProductConfiguration',
                        filter: { where: { clientId: this.uiRouter.globals.params.id } }
                    });
                } catch (error) {
                    this.alertSvc.handleError(error);
                }

                states.availableProductConfigurations = this.updateAvailableProductConfigurations(states.productConfigurations, states.client.clientProductConfigurations, states);
            } else {
                states.client.clientProductConfigurations.push(clientProductConfiguration);
                states.availableProductConfigurations = this.updateAvailableProductConfigurations(states.productConfigurations, states.client.clientProductConfigurations, states);
            }
            this.changeDetection.detectChanges();
        }
    }

    async deleteProductConfiguration(clientProductConfiguration: any, states: IClientEditStates) {
        if (clientProductConfiguration.id) {
            try {
                await this.dataAccess.genericMethod({
                    model: 'ClientProductConfiguration', method: 'destroyById',
                    parameters: { id: clientProductConfiguration.id }
                });
                states.client.clientProductConfigurations = await this.dataAccess.genericFind({
                    model: 'ClientProductConfiguration',
                    filter: { where: { clientId: this.uiRouter.globals.params.id } }
                });
            } catch (error) {
                this.alertSvc.handleError(error);
            }
            states.availableProductConfigurations = this.updateAvailableProductConfigurations(states.productConfigurations, states.client.clientProductConfigurations, states);
        } else {
            states.client.clientProductConfigurations = _.reject(states.client.clientProductConfigurations, { productConfiguration: clientProductConfiguration.productConfiguration });
            states.availableProductConfigurations = this.updateAvailableProductConfigurations(states.productConfigurations, states.client.clientProductConfigurations, states);
        }
        this.changeDetection.detectChanges();
    }

    getProductConfiguration(idOrName: any, states: IClientEditStates) {
        return _.find(states.productConfigurations, { id: idOrName }) || _.find(states.productConfigurations, { name: idOrName }) || {};
    }

    updateAvailableProductConfigurations(productConfigurations: any,
        clientProductConfigurations: any,
        states: IClientEditStates) {
        return _.filter(productConfigurations, function (pc) {
            return _.isNil(_.find(clientProductConfigurations, { productConfiguration: pc.name }));
        });
    }
}
