import { Injectable } from '@angular/core';
import { IProgramEpisodeEditStates, IProgramEpisodeEditService } from './programEpisodeEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import swal from 'sweetalert2';
import { LoginService } from 'client/app/services/loginService.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class ProgramEpisodeEditService implements IProgramEpisodeEditService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService, private loginService: LoginService) {
        //
    }
    async initDelegate(states: IProgramEpisodeEditStates): Promise<object> {
        states.lodash = _;
        states.isAuthorized = this.loginService.hasRole('ccgAdmin');
        await this.init(states);
        this.initTable(states);
        return {};
    }

    async init(states: IProgramEpisodeEditStates) {
        if (this.uiRouter.globals.params.id) {
            let promises: any[] = [];
            promises.push(this.dataAccess.genericMethod({
                model: 'ProgramEpisode', method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.id,
                    filter: {
                        order: 'name',
                        include: ['programModel', 'programEpisodeObservations', 'programSettings', {
                            relation: 'ccgType',
                            scope: {
                                fields: ['name']
                            }
                        }]
                    }
                }
            }));

            promises.push(this.dataAccess.genericFind({
                model: 'CcgType',
                filter: {
                    order: 'name'
                }
            }));

            promises.push(this.dataAccess.genericFind({
                model: 'ListItem',
                filter: { order: 'key', where: { listId: { inq: [3, 4] } } }
            }));

            // reference data list tags
            promises.push(this.dataAccess.genericFind({
                model: 'ReferenceDataListTag',
                filter: { include: 'referenceDataList' }
            }));

            promises.push(this.dataAccess.genericFind({
                model: 'ProgramEpisode',
                filter: {
                    order: 'name',
                    fields: ['name'],
                }
            }));

            const [programEpisode, ccgList, listItems, referenceDataListTags, prgEpNames] = await Promise.all(promises);
            
            states.programEpisode = programEpisode;
            states.CCGList = ccgList;
            states.referenceDataListTags = referenceDataListTags;
            states.programEpisodeNames = _.map(prgEpNames, 'name');
            _.pull(states.programEpisodeNames, states.programEpisode.name); //remove current name from list to check

            // Note these properties below are defined as a big int and so is stored as a string when it flows down from postgres
            states.programEpisode.programSettings = states.programEpisode.programSettings.map((pgs: any) => {
                return {
                ...pgs,
                settingListItemId: pgs.settingListItemId !== null ? parseInt(pgs.settingListItemId, 10) : null,
                operatorListItemId: pgs.operatorListItemId !== null ? parseInt(pgs.operatorListItemId, 10) : null,
                filterPredicateListItemId: pgs.filterPredicateListItemId !== null ? parseInt(pgs.filterPredicateListItemId, 10) : null
                };
            });

            // list items
            states.listItems = _.filter(listItems, { listId: 3 });
            states.operators = _.filter(listItems, { listId: 4 });

            states.listItems.forEach(function (item: any) {
                item.tableColumnName = item.key + '.' + item.value;
            });

            states.referenceDataLists = _.orderBy(_.uniqBy(_.map(states.referenceDataListTags, function (tag: any) {
                return {
                    id: tag.referenceDataListId,
                    name: tag.referenceDataList.name
                };
            }), 'id'), 'name');

            if (states.programEpisode && states.programEpisode.programSettings) {
                _.forEach(states.programEpisode.programSettings, (filterPredicate) => {
                    if (filterPredicate.valueReferenceListTagId) {
                        const tag = _.find(states.referenceDataListTags, { id: filterPredicate.valueReferenceListTagId });
                        filterPredicate.referenceDataListId = _.get(tag, 'referenceDataListId');
                    }
                });
            }
        }
    }

    initTable(states: IProgramEpisodeEditStates) {
        states.myformArray = new FormArray(
            states.programEpisode.programSettings.map(
                (x: any) =>
                    this.createGroup(x)
            )
        );
        states.dataSource = states.myformArray.controls;
    }

    createGroup(data: any) {
        const copyData = _.clone(data) || {};
        const group = new FormGroup({
            filterEpisodeBuildStepId: new FormControl(copyData.filterEpisodeBuildStepId),
            filterPredicateListItemId: new FormControl(copyData.filterPredicateListItemId),
            id: new FormControl(copyData.id),
            operatorListItemId: new FormControl(copyData.operatorListItemId),
            programEpisodeId: new FormControl(copyData.programEpisodeId),
            programModelId: new FormControl(copyData.programModelId),
            settingListItemId: new FormControl(copyData.settingListItemId),
            value: new FormControl(copyData.value),
            valueReferenceListTagId: new FormControl(copyData.valueReferenceListTagId),
            referenceDataListId: new FormControl(copyData.referenceDataListId)
        });
        return group;
    }

    confirmCloneHandler(states: IProgramEpisodeEditStates) {
        swal({
            title: 'Confirm clone request',
            text: 'Are you sure? This can take a few seconds',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then(async (result: any) => {
            if (result.value) {
                await this.createClone(states);
            }
        });
    }

    async createClone(states: IProgramEpisodeEditStates) {
        states.disableField = true;
        states.newProgramEpisode = false;

        const copiedObj = _.cloneDeep(states.programEpisode);
        const prgSettings = _.cloneDeep(states.programEpisode.programSettings);
        const prgEpiObs = _.cloneDeep(states.programEpisode.programEpisodeObservations);


        delete copiedObj.programModel;
        delete copiedObj.programEpisodeObservations;
        delete copiedObj.programSettings;
        delete copiedObj.id;

        const clonedNumber = _.random(1, 100);
        copiedObj.name = `cloned ${copiedObj.name}${clonedNumber}`;
        copiedObj.description = `cloned ${copiedObj.description}${clonedNumber}`;

        let savedObj = await this.dataAccess.genericCreate({
            model: 'ProgramEpisode',
            data: copiedObj
        });
        const savedObjId = savedObj.id;
        for (let k = 0; k < prgSettings.length; k++) {
            const tempObj = prgSettings[k];
            delete tempObj.id;
            tempObj.programEpisodeId = savedObjId;
        }

        for (let i = 0; i < prgEpiObs.length; i++) {
            const tempObj = prgEpiObs[i];
            delete tempObj.id;
            tempObj.programEpisodeId = savedObjId;
        }

        savedObj.programEpisodeObservations = prgEpiObs;
        savedObj.programSettings = prgSettings;
        try {
            savedObj = await this.copyPrgSettings(savedObj);
            savedObj = await this.savePrgEpiObservation(savedObj);
            swal({
                title: 'Clone completed',
                text: 'Loading cloned program episode onto screen now',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            }).then(() => {
                this.state.go('argos.ccgDesigner.programepisodes.edit',
                    { id: savedObjId }, { reload: true, inherit: false, notify: true });
            });
        } catch (error) {
            states.disableField = false;
            console.log('error ' + JSON.stringify(error));
        }
    }

    async copyPrgSettings(clonedObj: any): Promise<any> {
        const parameters = clonedObj.programSettings || [];
        if (parameters.length > 0) {
            const savedObj = await this.dataAccess.genericCreateMany({
                model: 'ProgramSetting',
                data: parameters
            });
            clonedObj.programSettings = savedObj;
        }
        return clonedObj;
    }

    async savePrgEpiObservation(clonedObj: any): Promise<any> {
        const parameters = clonedObj.programEpisodeObservations || [];
        if (parameters.length > 0) {
            const savedObj = await this.dataAccess.genericCreateMany({
                model: 'ProgramEpisodeObservation',
                data: parameters
            });
            clonedObj.programEpisodeObservations = savedObj;
        }
        return clonedObj;
    }

    formArrayValueChanges(dataList: any[], states: IProgramEpisodeEditStates, cb: Function) {
        if (!_.isEqual(states.programEpisode.programSettings, dataList)) {
            states.programEpisode.programSettings = _.cloneDeep(dataList);
            cb();
        }
    }

    addProgramSetting(states: IProgramEpisodeEditStates, cb: Function) {
        states.myformArray.push(this.createGroup(null));
        cb();
    }

    removePrgSettings(row: any, index: any, states: IProgramEpisodeEditStates, cb: Function) {
        states.myformArray.removeAt(index);
        if (row.get('id')?.value) {
            states.deleteProgramSettings.push(row.get('id')?.value);
        }
        cb();
    }

    async updatePrgSettings(states: IProgramEpisodeEditStates) {
        const updatePromises: any = [];
        if (states.programEpisode.programSettings) {
            states.programEpisode.programSettings.forEach((prgSetting: any) => {
                if (prgSetting.value) {
                    prgSetting.programEpisodeId = states.programEpisode.id;

                    // if list tag is set then value must be null
                    if (prgSetting.valueReferenceListTagId) {
                        prgSetting.value = null;
                    }
                    const data = this.dataAccess.genericUpsert({
                        model: 'ProgramSetting',
                        data: prgSetting
                    });
                    updatePromises.push(data);
                }
            });
        }

        return Promise.all(updatePromises);
    }

    async deletePrgSettings(states: IProgramEpisodeEditStates) {
        return Promise.all(_.union(_.map(states.deleteProgramSettings, (id) => {
            return this.dataAccess.genericDelete({
                model: 'ProgramSetting', id
            });
        })));
    }

    async update(states: IProgramEpisodeEditStates) {
        states.disableField = true;
        const data = _.cloneDeep(states.programEpisode);
        try {
            await this.dataAccess.genericUpsert({
                model: 'ProgramEpisode',
                data
            });
        } catch (error) {
            states.disableField = false;
            console.log('error ' + JSON.stringify(error));
        }

        await this.deletePrgSettings(states);
        await this.updatePrgSettings(states);
        this.state.go('argos.ccgDesigner.programepisodes.list');
    }
}
