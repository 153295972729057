import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IUserEngagementStates } from './userEngagement.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { UserEngagementService } from './userEngagement.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LoginService } from '../../../services/loginService.service';
import { StateService, UIRouter } from '@uirouter/core';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';
import { table } from 'console';
declare const $: any;
@Component({
    selector: 'argos-userEngagement',
    templateUrl: './userEngagement.component.html',
    providers: [UserEngagementService]
})

export class UserEngagementComponent extends ReactComponentBase<{}, IUserEngagementStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('usersMatTbSort', { static: false }) usersMatTbSort: MatSort;
    @ViewChild('usersPaginator', { static: false }) usersPaginator: MatPaginator;
    @ViewChild('errorsMatTbSort', { static: false }) errorsMatTbSort: MatSort;
    @ViewChild('errorsPaginator', { static: false }) errorsPaginator: MatPaginator;
    _: any = _;

    constructor(private svc: UserEngagementService, private loginServiceHandler: LoginService, private state: StateService, 
                private cdr: ChangeDetectorRef, private matSvc: MaterialUtil, private urlService: UrlService) {
        super({ 
            users: [],
            errors: [],
            usersTable: new MatTableDataSource([]),
            errorsTable: new MatTableDataSource([]),
            originalUserData: [],
            usersPageSize: 50,
            errorsPageSize: 50,
            usersFilterObj: {},
            usersDefaultDisplayMatTableColumns: ['id', 'email',  'argosUserActivityType', 'details', 'localDate'],
            usersMatTableColumns: ['id', 'email',  'argosUserActivityType', 'details', 'localDate'],
            usersMatTableSettingName: 'userEngagement',
            errorsFilterObj: {},
            errorsDefaultDisplayMatTableColumns: ['copyButton', 'id', 'userEmail', 'appUrl', 'requestUrl', 'requestBody', 'requestDate', 'errorMessage', 'errorStack'],
            errorsMatTableColumns: ['copyButton', 'id', 'userEmail', 'appUrl', 'requestUrl', 'requestBody', 'requestDate', 'errorMessage', 'errorStack'],
            errorsMatTableSettingName: 'argosUserErrors',
            activeTab: 0
        } as unknown as IUserEngagementStates);
    }

    ngAfterViewInit() {
        this.states.usersTable.sort = this.usersMatTbSort;
        this.states.usersTable.paginator = this.usersPaginator;
    }

    async ngOnInit() {
        //const queryString = this.urlService.getQueryStringFromUrl();
        this.states.usersTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.usersPageSize = this.matSvc.getUserDefaultPageSize(this.states.usersMatTableSettingName);

        this.states.errorsTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.errorsPageSize = this.matSvc.getUserDefaultPageSize(this.states.errorsMatTableSettingName);
        
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    //this.states.usersfilterObj, this.states.usersTable
    filterChange(columnName: string, value: string, filterObj: any, gridTable: any) {
        filterObj[columnName] = value.trim().toLowerCase();
        gridTable.filter = JSON.stringify(filterObj);
        this.cdr.detectChanges();
    }
 
    //pagination functions
    openMatTableSettings(matTableColumns: any[], defaultDisplayMatTableColumns: string[], matTableSettingName: string) {
        this.svc.openMatTableSettings(this.states, matTableColumns, defaultDisplayMatTableColumns, matTableSettingName);
    }

    isColumnHidden(columnName: string, matTableSettingName: string, defaultDisplayMatTableColumns: string[]) {
        let result = this.matSvc.isColumnHidden(columnName, matTableSettingName, defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        return this.matSvc.getMatSelectPlaceholderName(columnName, filterValue);
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;

        //mat-tabs lazy load so setting so you need to set the sort and paginator here when the objects are loaded
        //for some reason they have to be reseet each time the tab moves otherwise you lose the paging and sorting functionality
        this.states.usersTable.sort = this.usersMatTbSort;
        this.states.usersTable.paginator = this.usersPaginator;
        this.states.errorsTable.sort = this.errorsMatTbSort;
        this.states.errorsTable.paginator = this.errorsPaginator;
    }

    copyToClipboard(data: any) {
        const value = JSON.stringify(data);

        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(value).select();
        document.execCommand('copy');
        $temp.remove();
    }
}
