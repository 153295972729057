import { Component } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'argos-stageSourceTable',
    template: `<ui-view></ui-view>`
})

export class StageSourceTableComponent {
    

}
