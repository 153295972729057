// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep.mat-drawer-container.mat-sidenav-container {
        min-height: 700px;
        background: white;
    }
    ::ng-deep.mat-drawer-content.mat-sidenav-content {
        background: white;
        min-height: 700px;
        border-top: solid 1px rgba(0, 0, 0, 0.12);
        border-right: solid 1px rgba(0, 0, 0, 0.12);
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    }
    ::ng-deep.d2h-code-linenumber, .d2h-code-side-linenumber {
        position: relative;
    }
    ::ng-deep.mat-tab-group {
        height: 700px;
    }`, "",{"version":3,"sources":["webpack://./client/app/components/patchManagement/patchManagementPreviewModal/patchManagementPreviewModal.component.ts"],"names":[],"mappings":"AAAA;QACQ,iBAAiB;QACjB,iBAAiB;IACrB;IACA;QACI,iBAAiB;QACjB,iBAAiB;QACjB,yCAAyC;QACzC,2CAA2C;QAC3C,4CAA4C;IAChD;IACA;QACI,kBAAkB;IACtB;IACA;QACI,aAAa;IACjB","sourcesContent":["::ng-deep.mat-drawer-container.mat-sidenav-container {\n        min-height: 700px;\n        background: white;\n    }\n    ::ng-deep.mat-drawer-content.mat-sidenav-content {\n        background: white;\n        min-height: 700px;\n        border-top: solid 1px rgba(0, 0, 0, 0.12);\n        border-right: solid 1px rgba(0, 0, 0, 0.12);\n        border-bottom: solid 1px rgba(0, 0, 0, 0.12);\n    }\n    ::ng-deep.d2h-code-linenumber, .d2h-code-side-linenumber {\n        position: relative;\n    }\n    ::ng-deep.mat-tab-group {\n        height: 700px;\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
