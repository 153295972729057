import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IMarketShareMetaListStates } from './marketShareMetaList.component.d';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';
import { MarketShareMetaListService } from './marketShareMetaList.component.service';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
    selector: 'argos-marketShareMetaList',
    templateUrl: './marketShareMetaList.component.html',
    providers: [MarketShareMetaListService]
})

export class MarketShareMetaListComponent extends ReactComponentBase<{}, IMarketShareMetaListStates, {}> implements OnInit {
    constructor(private svc: MarketShareMetaListService, private cdr: ChangeDetectorRef) {
        super({ showType: 'hideEndPoint' } as unknown as IMarketShareMetaListStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    getParametersCount(parameters: any): number {
        return parameters && typeof parameters === 'object' ? Object.keys(parameters).length : 0;
    }

    launchPatientJourneyModal() {
        this.svc.launchPatientJourneyModal();
    }
}
