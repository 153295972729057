import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IClarifyInsightsFilterEdit } from './clarifyInsightsFilterEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ClarifyInsightsFilterEditService } from './clarifyInsightsFilterEdit.component.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ValidationService } from '../../../services/validationService.service';
import * as _ from 'lodash';



@Component({
    selector: 'argos-clarifyInsightsFilterEdit',
    templateUrl: './clarifyInsightsFilterEdit.component.html',
    providers: [ClarifyInsightsFilterEditService],
    styleUrls: ['./clarifyInsightsFilterEdit.component.less']
})

export class ClarifyInsightsFilterEditComponent extends ReactComponentBase<{}, IClarifyInsightsFilterEdit, {}> implements OnInit {

    constructor(private svc: ClarifyInsightsFilterEditService, private cdr: ChangeDetectorRef, private matDialog: MatDialog,
        private validationSrv: ValidationService) {

            super();
    }

    async ngOnInit() {
        this.states.isLoading = true;
        await this.svc.initDelegate(this.states);
        this.states.isLoading = false;
        this.cdr.detectChanges();
    }

    uploadFile(file: any, field: any) {
        this.svc.uploadFile(file, field, this.states);
    }

    clearFile(field: any) {
        this.svc.clearFile(field, this.states);
    }

    save() {
        this.svc.save(this.states);
    }

    isSaveDisabled() {
        
        const reqProperties = ['customerId', 'extractType', 'filterName'];

        switch (this.states.filter.extractType) {
            case 'claims':
                reqProperties.push('dataSource', 'extractStartDate');
                break;
            case 'market_share':
                reqProperties.push('dataSource', 'extractStartDate');
                break;
        }

        if (this.states.filter.hcpcsCdList && this.states.filter.dxCodeList) {
            reqProperties.push('hcpcsAndDxFlag');
        }
        const propertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.filter, reqProperties);
        let filterErrors = [];
        if (propertyErrors.length === 0) {
            filterErrors = this.svc.validateFilter(this.states);
        }
        const dataSourceErrors = this.states.dataSources.length > 0 ? this.states.dataSources.includes(this.states.filter.dataSource) : true;
        const ageBool = (this.states.filter.extractType === 'market_share') ? (this.states.filter.patientAgeLowerThresh && this.states.filter.patientAgeUpperThresh && this.states.filter.patientAgeUpperThresh < this.states.filter.patientAgeLowerThresh) || (!this.states.filter.patientAgeLowerThresh && this.states.filter.patientAgeUpperThresh) || (this.states.filter.patientAgeLowerThresh && !this.states.filter.patientAgeUpperThresh) : false;
        const transitTokenErrors = (this.states.filter.extractType === 'claims') ? (this.states.filter.generateTransitToken && !this.states.filter.datavantTransitTokenPartner || !this.states.filter.generateTransitToken && this.states.filter.datavantTransitTokenPartner) : false;
        return propertyErrors.length > 0 || this.states.filter.patientListAthenaTableName && this.states.filter.patientListAthenaTableName.includes('.') || _.isEqual(_.omitBy(this.states.filter, v => v !== 0 && !v), _.omitBy(this.states.originalFilter, v => v !== 0 && !v)) || ageBool || !dataSourceErrors || transitTokenErrors || filterErrors.length > 0;

    }

    clearSelection(field: string) {
        this.states.filter[field] = undefined;
    }

}
