import {
    Component,
    OnChanges,
    SimpleChanges,
    OnInit,
    Inject,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import {
    IBranchNameModalStates,
    IBranchNameModalProps,
    IBranchNameModalService,
} from './automatedGoldPulldownBranchInfo.component.d';
import { ReactComponentBase } from '../../../reactComponentBase/reactComponentBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutomatedGoldPulldownBranchInfoService } from './automatedGoldPulldownBranchInfo.component.service';
import { StateService } from '@uirouter/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown3';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AutomatedGoldPulldownListService } from '../automatedGoldPulldownList.component.service';

@Component({
    selector: 'argos-automated-gold-pulldown-branch-info',
    templateUrl: './automatedGoldPulldownBranchInfo.component.html',
    providers: [AutomatedGoldPulldownBranchInfoService, AutomatedGoldPulldownListService ]
})
export class AutomatedGoldPulldownBranchInfoComponent
    extends ReactComponentBase<IBranchNameModalProps, IBranchNameModalStates, {}>
    implements OnInit, OnChanges {
    _: any = _;
    moment: any = moment;
    readonly ARGOS_METADATA = ['careGroupings', 'episodeGroupings', 'episodeGroupingEntities', 'tablePartitionMappings', 'validValuesQueries', 'compoundFilters', 'otherMetrics', 'observations'];
    readonly PRISM_GOLD_METADATA = ['spotlightGroups', 'cohorts', 'cohortGroups', 'scorecardFormulas', 'scorecardTemplates', 'userRoles', 'dashboards', 'dashboardVariations', 'dashboardModules', 'menuConfig', 'drillDownSets'];
    constructor(
        @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<AutomatedGoldPulldownBranchInfoComponent>,
        private svc: AutomatedGoldPulldownBranchInfoService,
        private state: StateService,
        private cdr: ChangeDetectorRef
    ) {
        super({
            branchInfo: {
                branchName: '',
                commitMessage: '',
                baseBranch: '',
                manualBranchName: ''
            },
            logsText: '',
            dropdownMetaDataFilesSettings: {
                singleSelection: false,
                idField: 'filePath',
                textField: 'fileName',
                selectAllText: 'Select All Files',
                unSelectAllText: 'UnSelect All Files',
                itemsShowLimit: 2,
                allowSearchFilter: true
            } as IDropdownSettings,
            pulldown: {
                selectedFiles: {
                    dashboardModules: {
                        parentModules: [],
                        childModules: []
                    }
                },
                availableFormattedFiles: {},
                originalFiles: []
            },
            releaseBranches: [],
            selectedMetaDataTypes: [],
            metaDataTypeString: '',
            selectedFilesForGit: [],
            metaDataTypes: [{ name: 'Care Groupings', value: 'careGroupings', checked: false }, { name: 'Episode Groupings', value: 'episodeGroupings', checked: false }, { name: 'Episode Grouping Entities', value: 'episodeGroupingEntities', checked: false }, { name: 'Table Partition Mappings', value: 'tablePartitionMappings', checked: false }, { name: 'Valid Values Queries', value: 'validValuesQueries', checked: false }, { name: 'Compound Filters', value: 'compoundFilters', checked: false }, { name: 'Other Metrics', value: 'otherMetrics', checked: false }, { name: 'Observations', value: 'observations', checked: false }, { name: 'Spotlight Groups', value: 'spotlightGroups', checked: false }, { name: 'Cohorts', value: 'cohorts', checked: false }, { name: 'Cohort Groups', value: 'cohortGroups', checked: false }, { name: 'Scorecard Formulas', value: 'scorecardFormulas', checked: false }, { name: 'Scorecard Templates', value: 'scorecardTemplates', checked: false }, { name: 'User Roles', value: 'userRoles', checked: false }, { name: 'Dashboards', value: 'dashboards', checked: false }, { name: 'Dashboard Variations', value: 'dashboardVariations', checked: false }, { name: 'Dashboard Modules', value: 'dashboardModules', checked: false }, { name: 'Menu Config', value: 'menuConfig', checked: false }],
            selectAll: false
        } as IBranchNameModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
        this.deselectAll();
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(
            changes.props.previousValue,
            changes.props.currentValue,
            this.states
        );
    }

    cancel() {
        this.dialogRef.close('cancel');
    }

    async fetch(generate = false) {
        this.states.manual = true;
        this.svc.generateFilePathsForGithub(this.states, this.cdr);
        this.states.branchInfo = this.generatePulldownInfo();
        this.states.loading = true;
        if (generate) {
            this.dialogRef.close(this.states.branchInfo);
        } else {
            await this.svc.fetchPulldownFiles(this.states, this.cdr);
        }
    }

    generatePulldownInfo() {
        return {
            manualBranchName: this.states.branchInfo.manualBranchName,
            branchName: `prism-gold/automated-prism-gold-content-pulldown-${moment().format('YYYY-MM-DD')}-${this.states.branchInfo.manualBranchName}`,
            commitMessage: `${this.states.branchInfo.commitMessage }`,
            baseBranch: this.states.branchInfo.baseBranch,
            metaDataTypeString: _.size(this.states.selectedMetaDataTypes) ? this.states.metaDataTypeString : '',
            selectedFilesForGit: this.states.selectedFilesForGit,
            availableFormattedFiles: this.states.pulldown.availableFormattedFiles,
            originalFiles: this.states.pulldown.originalFiles,
            manual: true
        };
    }

    typeChecked(type: any) {
        if (!type.checked) {
            _.remove(this.states.selectedMetaDataTypes, (item: any) => item === type.value);
        } else {
            this.states.selectedMetaDataTypes.push(type.value);
        }
        this.states.selectedMetaDataTypes = _.uniq(this.states.selectedMetaDataTypes);
        this.generateMetaDataTypeString();
    }

    toggleSelectAll() {
        this.states.selectAll = !this.states.selectAll;
        if (this.states.selectAll) {
            this.states.selectedMetaDataTypes = _.map(this.states.metaDataTypes, 'value');
        } else {
            this.states.selectAll = false;
            this.states.selectedMetaDataTypes = [];
        }
        this.states.selectedMetaDataTypes = _.uniq(this.states.selectedMetaDataTypes);
        this.states.metaDataTypes = _.map(this.states.metaDataTypes, (item: any) => {
            item.checked = this.states.selectAll;
            return item;
        });
        this.generateMetaDataTypeString();
    }

    deselectAll() {
        this.states.selectAll = false;
        this.states.selectedMetaDataTypes = [];
        this.states.metaDataTypes = _.map(this.states.metaDataTypes, (item: any) => {
            item.checked = false;
            return item;
        });
        this.generateMetaDataTypeString();
    }

    generateMetaDataTypeString() {
        this.states.metaDataTypeString = _.size(this.states.selectedMetaDataTypes) ? `-k ${this.states.selectedMetaDataTypes.join(' ')}` : undefined;
    }

    onFileSelect(event: any, item: string) {
        if (!(item in this.states.pulldown.selectedFiles) && !_.includes(item, 'dashboardModules')) {
            _.set(this.states.pulldown.selectedFiles, item, []);
        }
        if (_.includes(item, 'dashboardModules')) {
            if (_.includes(item, 'childModules')) {
                if (!_.find(this.states.pulldown.selectedFiles.dashboardModules.childModules, event)) {
                    this.states.pulldown.selectedFiles.dashboardModules.childModules.push(event);
                }
            } else {
                if (!_.find(this.states.pulldown.selectedFiles.dashboardModules.parentModules, event)) {
                    this.states.pulldown.selectedFiles.dashboardModules.parentModules.push(event);
                }
            }
        } else {
            _.uniqBy(this.states.pulldown.selectedFiles[item], 'fileName');
        }
    }

    onFilesSelectAll(event: any[], item: string) {
        _.set(this.states.pulldown.selectedFiles, item, event);
    }

    onFileDeSelect(event: any, item: string) {
        const selectedItemFiles = _.cloneDeep(_.get(this.states.pulldown.selectedFiles, item));
        _.set(this.states.pulldown.selectedFiles, item, _.filter(selectedItemFiles, metaData => {
            return (metaData.fileName !== event.fileName);
        }));
    }

    onFilesDeSelectAll(event: any[], item: string) {
        _.set(this.states.pulldown.selectedFiles, item, []);
    }

    onConfigurationDropDownClose(event: any) {
        _.omitBy(this.states.pulldown.selectedFiles, _.isNil);
        this.states.pulldown.selectedFiles = _.cloneDeep(this.states.pulldown.selectedFiles);
        this.svc.generateFilePathsForGithub(this.states, this.cdr);
        this.cdr.detectChanges();
    }
}
