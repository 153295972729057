import { Component, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { IPipelineQueueStates } from './pipelineQueue.component.d';
import { PipelineQueueService } from './pipelineQueue.component.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, switchMap} from 'rxjs/operators';

/**
 * @title Drag&Drop sorting
 */
@Component({
    selector: 'argos-pipelineQueue',
    templateUrl: './pipelineQueue.component.html',
    providers: [PipelineQueueService],
    styleUrls: ['./pipelineQueue.component.css']
})

export class PipelineQueueComponent extends ReactComponentBase<{}, IPipelineQueueStates, {}>
    implements OnInit, OnChanges {
    constructor(private svc: PipelineQueueService, private cdr: ChangeDetectorRef) {
        super({
            pipelineQueue: [],
            toBeRemoved: [],
            changes: [],
            config: '',
            executeDate: null,
            showInput: false,
            allDags: []
        } as unknown as IPipelineQueueStates);
    }
    myDagControl = new FormControl('');
    filteredDags: Observable<string[]>;
    showSpinner = false;

    async ngOnInit() {
        this.showSpinner = true;
        await this.svc.initDelegate(this.states);
        this.states.allDags = await this.svc.getDags();
        this.filteredDags = this.myDagControl.valueChanges.pipe(
            startWith(''),
            switchMap(value => this._filterDags(value))
        );
        this.showSpinner = false;
        this.cdr.detectChanges();
    }

    private async _filterDags(value: string): Promise<string[]> {
        const filterValue = value.toLowerCase();
        return this.states.allDags.filter((option: string) => option.toLowerCase().includes(filterValue));
    };

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    async drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            // Initialize the changes array
            let changes = [];
            
            // Reorder the item within the array
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        
            // Iterate over the array to update the changes
            for (let i = 0; i < event.container.data.length; i++) {
                const item:any = event.container.data[i];
                const newIndex = i + 1;
                const previousIndex = item.queueOrder;
        
                // Create a change log object for the item's new index
                const itemChangeLog = {
                    dag_info: item,
                    previous_index: previousIndex,
                    new_index: newIndex
                };
        
                // Push the change log object to the array
                if (previousIndex != newIndex) {
                    changes.push(itemChangeLog);
                }
            }
        
            // Assign the changes array to this.states.changes
            this.states.changes = changes;
        } else {
            // If moving between containers, use transferArrayItem function
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
        
        this.cdr.detectChanges();
    }

    async showInputBox() {
        this.states.config = '';
        this.states.executeDate = null;
        this.states.showInput = true;
        this.cdr.detectChanges();
    }

    async addToQueue() {
        await this.svc.addHandler(this.states);
        this.states.showInput = false;
        this.cdr.detectChanges();
    }

    async updateQueue() {
        await this.svc.saveHandler(this.states);
        this.cdr.detectChanges();
    }

    async getAuditData() {
        this.svc.getAuditHandler(this.states);
        this.cdr.detectChanges();
    }
    isValidJson = true;
    validationError = '';

    validateJson() {
        try {
            JSON.parse(this.states.config);
            this.isValidJson = true;
            this.validationError = ''; // Reset error message
        } catch (error) {
            this.isValidJson = false;
            this.validationError = "Invalid Config";
        }
    }

    formatConfigAsJson(config: string): string {
        try {
          const parsedConfig = JSON.parse(JSON.stringify(config));
          return JSON.stringify(parsedConfig, null, 4); // Format JSON with 2-space indentation
        } catch (error) {
          return 'Invalid JSON'; // Display this if JSON is invalid
        }
      }

      isMultiline(dag: string): boolean {
        const threshold = 30;
        return dag.length > threshold;
      }
}
