import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IDataAiChatStates } from './dataAiChat.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { DataAiChatService } from './dataAiChat.component.service';

declare const $: any;
@Component({
    selector: 'argos-dataAiChat',
    templateUrl: './dataAiChat.component.html',
    providers: [DataAiChatService]
})

export class DataAiChatComponent extends ReactComponentBase<{}, IDataAiChatStates, {}> implements OnInit {
    constructor(private svc: DataAiChatService, private cdr: ChangeDetectorRef) {
        super({
            messages: [],
            sessionId: undefined,
            question: '',
            clarifySchema: ''
        } as IDataAiChatStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    sendQuestion(question: string) {
        this.svc.sendQuestion(this.states, question);
    }

    reload() {
        window.location.reload();
    }

    copySQL(messageIndex: number) {
        const value = this.states.messages[messageIndex].sql;

        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(value).select();
        document.execCommand('copy');
        $temp.remove();
    }
}