import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IClarifyInsightsDeliveryHub } from './clarifyInsightsDeliveryHub.component.d';
import { StateService } from '@uirouter/core';
import { ArgosStoreService } from 'client/app/services/argosStore.service';

@Injectable()
export class ClarifyInsightsDeliveryHubService {
    constructor(private dataAccess: NgDataAccess,
                private state: StateService,
                private argosStore: ArgosStoreService) {}
    
    async initDelegate(states: IClarifyInsightsDeliveryHub): Promise<object> {
        await this.baseQuery(states);
        await this.populateDeliveries(states);
        return states;
    }

    async refreshData(states: IClarifyInsightsDeliveryHub) {
        this.filterQuery(states);
        await this.populateTransferHistory(states);
        await this.populateExtractHistory(states);
        await this.populateDeliveries(states);
        return states;
    }

    async baseQuery(states: IClarifyInsightsDeliveryHub) {
        const customers = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsCustomer',
            filter: {
                where: {
                    and: [{isActive: true}]
                }
            }
        });
        states.customers = customers.map((customer: { extractType: string; }) => {
            if (customer.extractType === 'enriched_claims') {
                customer.extractType = 'claims';
            }
            return customer;
        });
        states.allExtractTypes = states.activeCustomerId ? states.customers.filter(customer => customer.customerId === states.activeCustomerId).map(customer => customer.extractType) : Array.from(new Set(states.customers.map(customer => customer.extractType)));
        states.allFilters = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsFilter'
        });
        states.distinctCustomers = Array.from(new Set(states.customers.map(customer => customer.customerId)).values()).map(customerId => {
            return {
                customerId: customerId,
                customerCode: states.customers.find(customer => customer.customerId === customerId).customerCode
            }
        });
        states.distinctCustomers = this.sortObjectArrayAlphabetically(states.distinctCustomers, 'customerCode');
        const dagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });
        states.dagsUrlBase = `${dagsUrl.data}`;
        states.recentExtracts = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsExtractHistory',
            filter: {
                order: 'outputS3PathRunId DESC',
                limit: 50
            }
        });
    }

    filterQuery(states: IClarifyInsightsDeliveryHub) {
        let tempFilters = states.allFilters;
        let tempExtractTypes = states.allExtractTypes;
        if (states.activeCustomerCode) {
            tempExtractTypes = states.customers.filter(customer => customer.customerId === states.activeCustomerId).map(customer => customer.extractType);
            tempFilters = states.allFilters.filter(filter => filter.customerId === states.activeCustomerId);
        }
        if (states.activeExtractType) {
            tempFilters = tempFilters.filter(filter => filter.extractType === states.activeExtractType);
        }
        states.filteredFilterNames = this.sortStringArrayAlphabetically(Array.from(new Set(tempFilters.map(filter => filter.filterName))));
        states.filteredExtractTypes = this.sortStringArrayAlphabetically(Array.from(new Set(tempExtractTypes.map(extractType => extractType))));
        return states;
    }

    filterDeliveries(states: IClarifyInsightsDeliveryHub) {
        const displayedDeliveryHistory = (states.activeDeliveryStatus === 'Complete') ? this.filterByStatus(states.deliveryHistory.data, true) : this.filterByStatus(states.deliveryHistory.data, false)
        return displayedDeliveryHistory;
    }
    
    filterByStatus(deliveryHistory: any, deliveryStatus: boolean) {
        return deliveryHistory.filter((delivery: any) => delivery.isComplete === deliveryStatus);
    }

    async populateTransferHistory(states: IClarifyInsightsDeliveryHub) {
        let transferFilters = {
            where: {
                and: [] as any[]
            },
            order: 'createdDate DESC'
        };
        if (states.activeCustomerId) {
            transferFilters.where.and.push({customerId: states.activeCustomerId});
        }
        if (states.activeExtractType) {
            transferFilters.where.and.push({extractType: (states.activeExtractType == 'claims') ? 'enriched_claims' : states.activeExtractType});
        }
        if (states.activeFilterName) {
            transferFilters.where.and.push({filterName: states.activeFilterName});
        }
        const transferHistory = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsTransferHistory',
            filter: transferFilters
        });
        transferHistory.forEach((transfer: { createdDate: string; customerId: string; customerCode: string; srcPath: string; destPath: string; runConfig: string; athenaDatabaseName: string, exclusions: string[]}) => {
            transfer.createdDate = new Date(transfer.createdDate).toLocaleDateString();
            transfer.customerCode = this.getCustomerCode(transfer.customerId, states);
            transfer.runConfig = JSON.stringify({'sourcePath': transfer.srcPath, 'destinationPath': transfer.destPath, 'athenaDatabaseName': transfer.athenaDatabaseName, 'exclusions': transfer.exclusions});
        });
        states.transferHistory.data = transferHistory;
    }

    async populateExtractHistory(states: IClarifyInsightsDeliveryHub) {
        let extractFilters = {
            where: {
                and: [] as any[]
            },
            order: 'createdDate DESC'
        };
        if (states.activeCustomerId) {
            extractFilters.where.and.push({customerId: states.activeCustomerId});
        }
        if (states.activeExtractType) {
            extractFilters.where.and.push({extractType: states.activeExtractType});
        }
        if (states.activeFilterName) {
            extractFilters.where.and.push({filterName: states.activeFilterName});
        }
        const extractHistory = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsExtractHistory',
            filter: extractFilters
        });
        extractHistory.forEach((extract: { createdDate: string; customerId: string; customerCode: string; extractTablesSchema: object; runConfig: string; outputS3Path: string; inputS3Path: string; outputS3PathRunId: string; inputS3PathRunId: string; runId: string}) => {
            extract.createdDate = new Date(extract.createdDate).toLocaleDateString();
            extract.customerCode = this.getCustomerCode(extract.customerId, states);
            let outputS3PathHyperlink = this.generateS3Hyperlink(extract.outputS3Path);
            let inputS3PathHyperlink = this.generateS3Hyperlink(extract.inputS3Path);
            extract.runConfig = JSON.stringify({'outputPath': `<a href='${outputS3PathHyperlink}' target='_blank'>${extract.outputS3Path}</a>`, 'inputPath': `<a href='${inputS3PathHyperlink}' target='_blank'>${extract.inputS3Path}</a>`, 'outputRunId': extract.outputS3PathRunId, 'inputRunId': extract.inputS3PathRunId});
            extract.runId = extract.outputS3PathRunId;
        });
        states.extractHistory.data = extractHistory;
    }

    generateS3Hyperlink(s3Path: string) {
        let pathComponents = s3Path.split('/');
        let bucket = pathComponents[2];
        let prefix = pathComponents.slice(3).join('/');
        return `https://us-west-2.console.aws.amazon.com/s3/buckets/${bucket}?region=us-west-2&bucketType=general&prefix=${prefix}/&showversions=false`;
    }

    async populateDeliveries(states: IClarifyInsightsDeliveryHub) {
        let deliveryFilters = {
            where: {
                and: [] as any[]
            },
            order: 'id DESC'
        };
        if (states.activeCustomerId) {
            deliveryFilters.where.and.push({customerId: states.activeCustomerId});
        }
        if (states.activeExtractType) {
            deliveryFilters.where.and.push({extractType: states.activeExtractType});
        }
        if (states.activeFilterName) {
            deliveryFilters.where.and.push({filterName: states.activeFilterName});
        }

        let deliveries = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsDataDelivery',
            filter: deliveryFilters
        });
        deliveries.forEach((delivery: any) => {
            delivery.dagConfig = this.buildExtractDagConfig(delivery);
        });
        deliveries.forEach(async (delivery: any) => await this.updateCompleteExtractRuns(delivery, states));
        deliveries.forEach(async (delivery: any) => await this.updateCompleteTransferRuns(delivery));
        deliveries = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsDataDelivery',
            filter: deliveryFilters
        });
        deliveries.forEach(async (delivery: any) => {
            delivery.nextStage = this.getNextDeliveryStage(delivery);
            delivery.isComplete = this.markRunsComplete(delivery);
            delivery.dagConfig = this.buildExtractDagConfig(delivery);
            delivery.latestClarifyReports = await this.getLatestClarifyReports(delivery.id);
        });
        states.deliveryHistory.data = deliveries;
        states.displayedDeliveryHistory.data = this.filterDeliveries(states);
    }

    async updateCompleteExtractRuns(delivery: any, states: IClarifyInsightsDeliveryHub) {
        let updateFailed;
        if (delivery.latestExtractDagRunId && delivery.latestExtractComplete == false) {
            const dagStatusResponse = await this.checkDagStatus(delivery);
            updateFailed = true;
            await this.updateExtractStatus(states, delivery, dagStatusResponse, updateFailed);
        }
        else if (delivery.latestExtractDagRunId && !delivery.latestExtractSuccess && delivery.latestExtractComplete == true && !delivery.latestTransferSuccess) {
            // re-check failed extract runs for uncompleted deliveries to see if we re ran the task successfully, don't update already failed runs
            const dagStatusResponse = await this.checkDagStatus(delivery);
            updateFailed = false;
            await this.updateExtractStatus(states, delivery, dagStatusResponse, updateFailed);
        }
        else {
            console.log(`Dag status for ${delivery.latestExtractDagRunId} run_id already updated`);
        }
    }

    async updateCompleteTransferRuns(delivery: any) {
        if (delivery.latestTransferRunId && delivery.latestTransferComplete == false) {
            // check dag status for unfinished runs
            const dagStatusResponse = await this.dataAccess.genericMethod({
                model: 'Astronomer',
                method: 'getDagStatus',
                parameters: {
                    dagId: 'insights-s3-to-s3-transfer-pipeline',
                    dagRunId: delivery.latestTransferRunId
                }
            });
            let currentDagStatus = (dagStatusResponse.data && dagStatusResponse.data[0].toUpperCase() + dagStatusResponse.data.slice(1)) || ""
            if (currentDagStatus === 'Failed') {
                await this.dataAccess.genericUpsert({
                    model: 'ClarifyInsightsDataDelivery',
                    data: {
                        id: delivery.id,
                        latestTransferComplete: true,
                        latestTransferSuccess: false
                    }
                });
            }
            else if (currentDagStatus === 'Success') {
                await this.dataAccess.genericUpsert({
                    model: 'ClarifyInsightsDataDelivery',
                    data: {
                        id: delivery.id,
                        latestTransferComplete: true,
                        latestTransferSuccess: true
                    }
                });
            }
            else {
                console.log(`Dag status for insights-s3-to-s3-transfer-pipeline run ${delivery.latestTransferRunId}`);
            }
        }
    }

    getNextDeliveryStage(delivery: any) {
        if (!delivery.latestExtractDagRunId) {
            return 'extractStart';
        }
        if (delivery.latestExtractComplete == false) {
            return 'extractComplete';
        }
        if (delivery.latestExtractSuccess == false) {
            return 'extractFailed';
        }
        if (!delivery.latestQaRunId) {
            return 'QA';
        }
        if (!delivery.latestTransferRunId) {
            return 'transferStart';
        }
        if (delivery.latestTransferComplete == false) {
            return 'transferComplete';
        }
        if (delivery.latestTransferSuccess == false) {
            return 'transferFailed';
        }
        return 'Complete';
    };


    buildExtractDagConfig(element: any) {
        let dagName: string = '';
        switch (element.extractType) {
            case 'claims':
                dagName = `enriched_claim_extract-${element.customerCode}-${element.dataSource}-pipeline`;
                break;
            case 'market_share':
                dagName = `market_share_extract-${element.customerCode}-${element.dataSource}-pipeline`;
                break;
            case 'provider_performance':
                dagName = `provider_performance-extract-${element.customerCode}-pipeline`;
                break;
            case 'provider_directory':
                dagName = `provider_directory-extract-${element.customerCode}-pipeline`;
                break;
            case 'hospital_rates':
                dagName = `clarify-hospital-rates-extract-${element.customerCode}-pipeline`;
                break;
            default:
                console.log('Invalid extract type');
                return;
        }
        let dagConfig: any = {};
        dagConfig.dagName = dagName;
        dagConfig.customerCode = element.customerCode;
        dagConfig.dataSource = element.dataSource;
        dagConfig.filterName = element.filterName;
        dagConfig.extractType = element.extractType;
        return dagConfig;
    }

    markRunsComplete(delivery: any) {
        const status = delivery.latestExtractComplete && delivery.latestExtractSuccess && delivery.latestTransferComplete && delivery.latestTransferSuccess;
        const returnValue = (status === true) ? true : false;
        return returnValue;
    }

    async triggerQaFromRow(element: any) {
        await this.dataAccess.genericUpsert({
            model: 'ClarifyInsightsDataDelivery',
            data: {
                id: element.id,
                latestQaRunId: new Date().toISOString(),
                latestQaBy: this.getUser()
            }
        });

    }

    async updateDeliveryTransfer(id: string, dagRunId: string) {
        try {
            await this.dataAccess.genericUpsert({
                model: 'ClarifyInsightsDataDelivery',
                data: {
                    id: id,
                    latestTransferRunId: dagRunId,
                    latestTransferBy: this.getUser(),
                    latestTransferComplete: false
                }
            });
        }
        catch (e) {
            console.log(e);
        }
    }

    async editNote(element: any, newNote: string) {
        await this.dataAccess.genericUpsert({
            model: 'ClarifyInsightsDataDelivery',
            data: {
                id: element.id,
                deliveryNote: newNote
            }
        });
    };

    async clearLastStage(element: any) {
        switch (element.nextStage) {
            case 'transferComplete': 
            case 'transferFailed':
            case 'Complete':
                await this.dataAccess.genericUpsert({
                    model: 'ClarifyInsightsDataDelivery',
                    data: {
                        id: element.id,
                        latestTransferRunId: null,
                        latestTransferComplete: null,
                        latestTransferSuccess: null,
                        latestTransferBy: null
                    }
                });
                break;
            case 'transferStart':
                await this.dataAccess.genericUpsert({
                    model: 'ClarifyInsightsDataDelivery',
                    data: {
                        id: element.id,
                        latestQaRunId: null,
                        latestQaBy: null
                    }
                });
                break;
            case 'QA':
            case 'extractComplete':
            case 'extractFailed':
                await this.dataAccess.genericUpsert({
                    model: 'ClarifyInsightsDataDelivery',
                    data: {
                        id: element.id,
                        latestExtractRunId: null,
                        latestExtractDagRunId: null,
                        latestExtractComplete: null,
                        latestExtractSuccess: null
                    }
                });
                break;
            default:
                break;
        };
    }

    async updateDeliveryNote(element: any, newNote: string) {
        await this.dataAccess.genericUpsert({
            model: 'ClarifyInsightsDataDelivery',
            data: {
                id: element.id,
                deliveryNote: newNote
            }
        });
    }

    navToCustomerFilters(element: any, fieldsToPass: string[] = [], states: IClarifyInsightsDeliveryHub) {
        let params: {} = {};
        if (element.customerCode && (fieldsToPass.includes('customerCode') || fieldsToPass.length === 0)) {
            params = {...params, customerName: this.getCustomerName(element.customerCode, states)};
        }
        if (element.extractType && (fieldsToPass.includes('customerCode') || fieldsToPass.length === 0)) {
            params = {...params, extractType: element.extractType.replace('_', ' ')};
        }
        if (element.filterName && (fieldsToPass.includes('customerCode') || fieldsToPass.length === 0)) {
            params = {...params, filterName: element.filterName};
        }
        this.state.go(`argos.clarifyInsightsFilter.list`, {'queryString':JSON.stringify(params)});
    }

    async clearSelections(states: IClarifyInsightsDeliveryHub) {
        states.activeCustomerCode = undefined;
        states.activeExtractType = undefined;
        states.activeFilterName = undefined;
        states.activeCustomerId = undefined;
    }

    getUser(){
        return this.argosStore.getItem('username').replace('@clarifyhealth.com', '');
    };

    getCustomerCode(customerId: string, states: IClarifyInsightsDeliveryHub) {
        return states.distinctCustomers.find(customer => customer.customerId === customerId).customerCode;
    }

    getCustomerName(customerCode: string, states: IClarifyInsightsDeliveryHub) {
        return states.customers.find(customer => customer.customerCode === customerCode).customerName;
    }

    getCustomerDestinationBucket(customerId: string, extractType: string, states: IClarifyInsightsDeliveryHub) {
        return states.customers.find(customer => customer.customerId === customerId && customer.extractType === extractType).s3DestinationBucket;
    }

    sortStringArrayAlphabetically(array: string[]) {
        return array.sort((a, b) => a.localeCompare(b));
    }

    sortObjectArrayAlphabetically(array: any[], key: string) {
        return array.sort((a, b) => a[key].localeCompare(b[key]));
    }
    
    async generatedPresignedS3Url(s3Path: string) {
        const presignedUrl = await this.dataAccess.genericMethod({
            model: 'ClarifyInsightsDataDelivery',
            method: 'getPresignedS3Url',
            parameters: {
                s3Path: s3Path
            }
        });
        return presignedUrl;
    }

    async getLatestClarifyReports(deliveryId: string) {
        if (deliveryId !== null && deliveryId !== undefined) {
            const extractHistories = await this.dataAccess.genericFind({
                model: 'ClarifyInsightsExtractHistory', 
                filter: {
                    where: {
                        deliveryId: deliveryId
                    },
                    order: 'outputS3PathRunId DESC',
                    limit: 1
                }
            });
            return extractHistories.length > 0 && extractHistories.clarifyReportingPaths !== null ? extractHistories[0].clarifyReportingPaths : [];
        }
        return [];
    }

    async updateExtractStatus(states: IClarifyInsightsDeliveryHub, delivery: any, dagStatusResponse: any, updateFailed: boolean = true) {
        let currentDagStatus = (dagStatusResponse.data && dagStatusResponse.data[0].toUpperCase() + dagStatusResponse.data.slice(1)) || ""
        if (currentDagStatus === 'Failed') {
            if (updateFailed) {
                await this.dataAccess.genericUpsert({
                    model: 'ClarifyInsightsDataDelivery',
                    data: {
                        id: delivery.id,
                        latestExtractComplete: true,
                        latestExtractSuccess: false,
                    }
                });
            }
            else {
                console.log(`Dag status for ${delivery.dagConfig.dagName} run is already failed`);
            }
        }
        else if (currentDagStatus === 'Success') {
            const xcomResponse = await this.dataAccess.genericMethod({
                model: 'ClarifyInsightsDataDelivery',
                method: 'getExtractRunId',
                parameters: {
                    dagId: delivery.dagConfig.dagName,
                    dagRunId: encodeURIComponent(delivery.latestExtractDagRunId),
                    taskId: (delivery.extractType.startsWith('prov')) ? 'launch_cluster' : 'start_cluster',
                    xcomKey: 'return_value'
                }
            });
            const extractRun = JSON.parse(xcomResponse.replace(/'/g, '"'));
            const jsonSteps: [] = extractRun.steps_json_file[0].HadoopJarStep.Args;
            const runName: string = jsonSteps.find((step: any) => step.startsWith('--run_name'))
            let extractRunId = runName.replace('--run_name=', '')
            extractRunId = (extractRunId !== '${run_id}') ? extractRunId : undefined;
            if (!extractRunId) {
                // market_share and hospital_rates store the run id here
                extractRunId = extractRun.cluster_run_id
            }
            // update delivery with successful extract run id
            await this.dataAccess.genericUpsert({
                model: 'ClarifyInsightsDataDelivery',
                data: {
                    id: delivery.id,
                    latestExtractComplete: true,
                    latestExtractSuccess: true,
                    latestExtractRunId: extractRunId
                }
            });
            // add delivery id to extract history
            const extract = states.recentExtracts.find((extract: { outputS3PathRunId: any; }) => extract.outputS3PathRunId === extractRunId);
            await this.dataAccess.genericUpsert({
                model: 'ClarifyInsightsExtractHistory',
                data: {
                    id: extract.id,
                    deliveryId: delivery.id,
                    createdBy: delivery.latestExtractBy
                }
            });
        }
    }

    async checkDagStatus(delivery: any) {
        return await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagStatus',
            parameters: {
                dagId: delivery.dagConfig.dagName,
                dagRunId: delivery.latestExtractDagRunId
            }
        });
    }
}
