import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { IVersionHistoryListStates } from './versionHistoryList.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { VersionHistoryListService } from './versionHistoryList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-version-history-list',
    templateUrl: './versionHistoryList.component.html',
    providers: [VersionHistoryListService]
})

export class VersionHistoryListComponent extends ReactComponentBase<{}, IVersionHistoryListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: VersionHistoryListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            filterObj: {},
            versionHistory: [],
            pageSize: 50,
            matTableColumns: ['metaDataName', 'metaDataType', 'creationDate', 'createdBy', 'viewHistoryButton'],
            matTableSettingName: 'versionHistoryList',
            versionHistoryTable: new MatTableDataSource([]),

        } as unknown as IVersionHistoryListStates);
    }
    ngAfterViewInit() {
        this.states.versionHistoryTable.paginator = this.paginator;
        this.matTbSort.sort(({id: 'creationDate', start: 'desc'}) as MatSortable);
        this.states.versionHistoryTable.sort = this.matTbSort;
    }
    async ngOnInit() {
        this.states.versionHistoryTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }
    filterChange(columnName: string, event: any) {
        let eventValue = '';
        if (_.isString(event)){
            eventValue = event.trim().toLowerCase();
        } else {
            eventValue = event.target.value.trim().toLowerCase();
        }
        this.states.filterObj[columnName] = eventValue;
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.versionHistoryTable.filter = JSON.stringify(this.states.filterObj);
    }
    findName(row: any) {
        return row.metaDataType === 'observation' || row.metaDataType === 'otherMetric' || row.metaDataType === 'careGrouping' ? 
            JSON.parse(row.definition).name : row.metaDataName;
    }
    
    viewHistory(row: any) {
        this.svc.viewHistory(row);
    }   
    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        return this.matSvc.getMatSelectPlaceholderName(columnName, filterValue);
    }
}