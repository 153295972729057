import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IPceMetaDataEditStates, IPceMetaDataEditService } from './pceMetaDataEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditObservationTagModalComponent } from '../../observations/editObservationTagModal/editObservationTagModal.component';
import { ViewHistoryModalComponent } from '../../observations/viewHistoryModal/viewHistoryModal.component';
import swal from 'sweetalert2';
import { all } from 'bluebird';

const aggregationTypes: { [key: string]: string[] } = {
    array: ['collect_list', 'collect_set', 'count'],
    boolean: ['count'],
    date: ['count', 'max', 'min'],
    decimal: ['avg', 'count', 'max', 'min', 'sum'],
    integer: ['avg', 'count', 'max', 'min', 'sum'],
    long: ['avg', 'count', 'max', 'min', 'sum'],
    string: ['collect_list', 'collect_set', 'count']
};

@Injectable()
export class PceMetaDataEditService implements IPceMetaDataEditService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private state: StateService, private changeDetectRef: ChangeDetectorRef,
        private matDialog: MatDialog, private argosStore: ArgosStoreService) {
    }

    async initDelegate(states: IPceMetaDataEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IPceMetaDataEditStates) {
        states.lodash = _;

        states.freeformSqlJoinConditions = await this.dataAccess.genericMethod({
            model: 'Teradrome', method: 'getPceJoinKeyDict'});

        if (this.uiRouter.globals.params.id) {
            await this.getPceMetaData(states);

            // Rename history key so we reuse observation history modal
            states.pceMetaData.observationHistories = states.pceMetaData.pceObservationHistories;
            delete states.pceMetaData.pceObservationHistories;


            if (states.pceMetaData?.teradromeColumn?.dataType) {
                this.selectionFieldChanged(states.pceMetaData.teradromeColumn.dataType, states, true);
            }
    
            states.pceMetaData.creationDate = (states.pceMetaData.creationDate) ? new Date(states.pceMetaData.creationDate).toISOString().slice(0, 10) : undefined;
            states.obsNames = [];

        } else {
            states.pceMetaData = {id: undefined, columnName: '', template: '', githubPrUrl: '', status: undefined};

            const allObsNames = await this.dataAccess.genericFind({
                model: 'Observation',
                filter: {
                    fields: ['name']
                }
            });
            const allPceObsNames = await this.dataAccess.genericFind({
                model: 'PceObservation',
                filter: {
                    fields: ['columnName']
                }
            });
            states.obsNames = _.map(allObsNames, 'name').concat(_.map(allPceObsNames, 'columnName'));
        }

        // Get market share fields for dropdowns
        const marketShareTable = await this.dataAccess.genericFind({
            model: 'TeradromeTable',
            filter: {
                include: 'teradromeColumn',
                where: {
                    tableName: 'market_share'
                }
            }
        });
        states.marketShareFields = _.orderBy(_.uniq(_.flatten(marketShareTable.map((table: any) => {
            return table.teradromeColumn.map((column: any) => {
                return _.pick(column, ['id', 'columnName', 'dataType']);
            });
        })).sort()), 'columnName');

        states.marketShareSelectFields = _.cloneDeep(states.marketShareFields);

        const benchmarksTable = await this.dataAccess.genericFind({
            model: 'TeradromeTable',
            filter: {
                include: 'teradromeColumn',
                where: {
                    tableName: 'ccg_pce'
                }
            }
        });
        states.benchmarkSelectFields = _.cloneDeep(_.orderBy(_.uniq(_.flatten(benchmarksTable.map((table: any) => {
            return table.teradromeColumn.map((column: any) => {
                return _.pick(column, ['id', 'columnName', 'dataType']);
            });
        })).sort()), 'columnName'));

        states.npiColumns = states.benchmarkSelectFields.filter(field => field.columnName.includes('npi'));

        states.availableOrCriteria = states.pceMetaData.pceObservationFilterPredicates?.map((predicate: any) => {
            return predicate.id;
        }) || [];

        // Get operators for filter dropdowns
        const operators = await this.dataAccess.genericFind({
            model: 'ListItem',
            filter: {
                where: {
                    listId: 4
                }
            }
        });
        states.operators = _.map(operators, (operator: any) => {
            operator.id = parseInt(operator.id);
            return operator;
        });

        // Get tables for freeform sql dropdown
        states.freeformSqlTables = await this.dataAccess.genericFind({
            model: 'TeradromeTable',
            filter: {
                fields: ['tableName', 'id'],
                where: {
                    tableName: { inq: _.keys(states.freeformSqlJoinConditions) }
                },
                order: 'tableName'
            }
        });

        // Get reference list tags for dropdowns
        const referenceDataListTags = await this.dataAccess.genericFind({
            model: 'ReferenceDataListTag',
            filter: { include: ['referenceDataList', 'viewReferenceDataListParentTags'] }
        });

        states.referenceDataListTags = _.map(referenceDataListTags, (tag: any) => {
            tag.fullPath = (tag.viewReferenceDataListParentTags) ? tag.viewReferenceDataListParentTags.parentTags.join('/') + '/' + tag.tag : tag.tag;
            return tag;
        });
        states.referenceDataLists = _.orderBy(_.uniqBy(_.map(states.referenceDataListTags, (tag: any) => {
            return {
                id: tag.referenceDataListId,
                name: tag.referenceDataList.name
            };
        }), 'id'), 'name');

        if (states.pceMetaData && states.pceMetaData.pceObservationFilterPredicates) {
            _.forEach(states.pceMetaData.pceObservationFilterPredicates, (filterPredicate) => {
                if (filterPredicate.valueReferenceListTagId) {
                    const tag = _.find(states.referenceDataListTags, { id: filterPredicate.valueReferenceListTagId });
                    filterPredicate.referenceDataListId = _.get(tag, 'referenceDataListId');
                    filterPredicate.valueReferenceListParentTagId = _.get(tag, 'parentListTagId');
                }
            });
        }

        states.originalSql = states.pceMetaData.sql || '';
    }

    async getPceMetaData(states: IPceMetaDataEditStates) {
        states.pceMetaData = await this.dataAccess.genericMethod({
            model: 'PceObservation',
            method: 'findById',
            parameters: {
                id: this.uiRouter.globals.params.id || states.pceMetaData.id,
                filter: {
                    include: [
                        {
                            relation: 'pceObservationFilterPredicates',
                            scope: {
                                include: ['teradromeColumn']
                            }
                        },
                        'teradromeColumn',
                        'pceObservationHistories',
                        'npiColumn'
                    ]
                }
            }
        });
    }

    selectionFieldChanged(dataType: string, states: IPceMetaDataEditStates, isInit = false) {
        if (dataType.startsWith('decimal')) {
            dataType = 'decimal';
        }

        // Temporarily clear the aggregation select so field resets, without field holding previous value
        states.aggregationTypes = [];
        if (states.pceMetaData?.aggregationType && !isInit) {
            states.pceMetaData.aggregationType = null;
        }
        this.changeDetectRef.detectChanges();

        states.aggregationTypes = aggregationTypes[dataType] || [];   
    }

    setName(states: IPceMetaDataEditStates) {
        // Automatically set the columnName for initial_encounter_market_share and referral_encounter_market_share
        let columnName = '';
        if (states.pceMetaData.selectionFieldColumnId) {
            const column = _.find(states.marketShareFields, { id: states.pceMetaData.selectionFieldColumnId }).columnName;
            columnName = `${states.pceMetaData.template.split('_')[0]}${(states.pceMetaData.template === 'referral_encounter_market_share') ? '_encounter' : ''}_${column}`;

        }
        states.pceMetaData.columnName = columnName;

    }

    templateFieldChanged(states: IPceMetaDataEditStates) { 
        if (states.pceMetaData.template === 'pce_episode') {
            states.marketShareSelectFields = _.filter(states.marketShareFields, (field) => {
                return Object.keys(aggregationTypes).some(type => field.dataType.startsWith(type));
            });
        } else {
            states.marketShareSelectFields = _.cloneDeep(states.marketShareFields);
        }

        if (states.pceMetaData.template === 'freeform_sql' && states.pceMetaData.status === 'Active') {
            states.pceMetaData.status = 'Test';
        }

        if (states.pceMetaData.template === 'pce_episode' && !states.pceMetaData.columnName.startsWith('episode_')) {
            states.pceMetaData.columnName = 'episode_';
        }
        
        const template = states.pceMetaData.template;
        // Temporarily clear the template so selectionField select is reset with ngIf
        states.pceMetaData.template = '';
        this.changeDetectRef.detectChanges();

        states.pceMetaData = {
            id:  states.pceMetaData.id,
            columnName: states.pceMetaData.columnName,
            template,
            selectionFieldColumnId: null,
            status: states.pceMetaData.status
        };

        if (['initial_encounter_market_share', 'referral_encounter_market_share'].includes(states.pceMetaData.template)) {
            this.setName(states);
        }
    }

    addFilterCriteria(states: IPceMetaDataEditStates) {
        if (!states.pceMetaData.pceObservationFilterPredicates) {
            states.pceMetaData.pceObservationFilterPredicates = [];
        }
        states.pceMetaData.pceObservationFilterPredicates.push({});
    }

    setObservationTag(row: any, index: number, states: IPceMetaDataEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            data: {
                props: {
                    referenceDataListId: row.referenceDataListId,
                    valueReferenceListTagId: row.valueReferenceListTagId,
                    tagIndex: index
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            EditObservationTagModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            if (result) {
                const tagId = result.tagIds[result.tagIds.length - 1].tagId;
                states.pceMetaData.pceObservationFilterPredicates[result.tagIndex].valueReferenceListTagId = tagId;
                const parentTag: any = _.find(states.referenceDataListTags, { id: tagId });
                states.pceMetaData.pceObservationFilterPredicates[result.tagIndex].valueReferenceListParentTagId = parentTag.parentListTagId;
                this.changeDetectRef.detectChanges();
            }
        });
    }

    deleteFilterCriteria(row: any, index: number, states: IPceMetaDataEditStates) {
        swal({
            title: 'Delete Filter Criteria',
            text: 'Are you sure you want to delete this row?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                if (row && row.id) {
                    states.filterFieldsToDelete.push(row.id);
                }
                states.pceMetaData.pceObservationFilterPredicates.splice(index, 1);
                this.changeDetectRef.detectChanges();
            }
        });
    }

    async save(states: IPceMetaDataEditStates, stayOnPage = false) {
        states.saveInProgress = true;

        states.pceMetaData.version = (states.pceMetaData.version) ? states.pceMetaData.version + 1 : 1;
        if (!states.pceMetaData.id) {
            states.pceMetaData.creationDate = new Date().toISOString();
        }

        const pceMetaData: any = await this.dataAccess.genericUpsert({
            model: 'PceObservation',
            data: states.pceMetaData
        });
        states.pceMetaData.id = pceMetaData.id;

        const promises: any[] = [];
        const deletionPromises: any[] = [];
        states.filterFieldsToDelete.forEach((id) => {
            // check to see if any ids are in the orCriteriaId of the objects in states.pceMetaData.pceObservationFilterPredicates
            const idsToClear = _.map(_.filter(states.pceMetaData.pceObservationFilterPredicates, (predicate: any) => {
                return predicate.orCriteriaId === id;
            }), 'id');
            if (idsToClear.length > 0) {
                // clear orCriteriaId of those objects in states.pceMetaData.pceObservationFilterPredicates
                idsToClear.forEach((id: any) => {
                    const predicate: any = _.find(states.pceMetaData.pceObservationFilterPredicates, { id });
                    predicate.orCriteriaId = null;  
                });
            }
            deletionPromises.push(this.dataAccess.genericMethod({
                model: 'PceObservationFilterPredicate', method: 'destroyById',
                parameters: { id }
            }));
        });

        if (states.pceMetaData.pceObservationFilterPredicates) {
            states.pceMetaData.pceObservationFilterPredicates.forEach((filterPredicate: any) => {
                filterPredicate.pceObservationId = states.pceMetaData.id;
                // Need to execute before deletion to avoid foreign key constraint
                promises.unshift(
                    this.dataAccess.genericUpsert({
                        model: 'PceObservationFilterPredicate',
                        data: filterPredicate
                    })
                );
            });
        }

        await Promise.all(promises);
        await Promise.all(deletionPromises);

        await this.getPceMetaData(states);

        delete states.pceMetaData.pceObservationHistories;
        delete states.pceMetaData.teradromeColumn;

        const pceObservation = JSON.parse(JSON.stringify(states.pceMetaData));
        const username = this.argosStore.getItem('username');

        await this.dataAccess.genericUpsert({
            model: 'PceObservationHistory',
            data: {
                pceObservationId: states.pceMetaData.id,
                version: states.pceMetaData.version,
                createdBy: username,
                creationDate: new Date(),
                definition: pceObservation
            }
        });

        states.saveInProgress = false;
        if (stayOnPage) {
            this.state.go('argos.modelMetaData.pceMetaData.edit', { id: states.pceMetaData.id }, { reload: true });
        } else {
            this.state.go('argos.modelMetaData.pceMetaData.list');
        }
    }

    operatorListItemChanged(filterCriteria: any) {
        // operatorListItemId represent IS and IS NOT
        if (filterCriteria.operatorListItemId === 10175 || filterCriteria.operatorListItemId === 10174) {
            filterCriteria.value = 'NULL';
        }
    }

    viewHistory(states: IPceMetaDataEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    observation: states.pceMetaData
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewHistoryModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            this.matDialog.closeAll();
        });
    }

    async submitSQLForReview(states: IPceMetaDataEditStates) {
        const obsName = states.pceMetaData.columnName;
        const sql = states.pceMetaData.sql;
        const username = this.argosStore.getItem('username');

        const response = await this.dataAccess.genericMethod({
            model: 'Teradrome', method: 'openFreeformSQLPR', parameters: {
                obsName,
                sql,
                username,
                baseDirectory: 'pce'
            }
        });

        swal({
            title: 'Observation PR Created',
            text: 'Successfully created a PR in the freeform_sql_observation repo.',
            type: 'success',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'View PR',
            cancelButtonColor: '#6AA84F',
            allowOutsideClick: false
        }).then(async (isConfirm: any) => {
            if (!isConfirm.value) {
                window.open(response.data.html_url, '_blank');
            }
        });

        states.pceMetaData.githubPrUrl = response.data.html_url;
        if (states.pceMetaData.status === 'Active') {
            states.pceMetaData.sql = states.originalSql;
        } else {
            states.pceMetaData.status = 'Test';
        }
        this.save(states, true);

        return response;
    }

    goToSqlPr(states: IPceMetaDataEditStates) {
        window.open(states.pceMetaData.githubPrUrl, '_blank');
    }

    goToSqlHistory(states: IPceMetaDataEditStates) {
        window.open('https://github.com/clarifyhealth/freeform_sql_observations/commits/main/pce/' + states.pceMetaData.columnName + '.sql', '_blank');
    }
}
