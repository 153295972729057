import { Injectable, ElementRef, QueryList } from '@angular/core';
import {
    IClarifySwimlaneProps,
    IClarifySwimlaneStates,
    IClarifySwimlaneService,
    SwimlaneDataItemModel,
    SwimlaneClusterNode,
    SwimlaneNode,
    SwimEdgesGroup,
    SwimlaneLineSettingModel
} from './clarifySwimlane.component.d';
import { Edge, Node, ClusterNode } from '@swimlane/ngx-graph';
import * as _ from 'lodash';

declare const LeaderLine: any;

@Injectable()
export class ClarifySwimlaneService implements IClarifySwimlaneService {
    initDelegate(props: IClarifySwimlaneProps, states: IClarifySwimlaneStates): Object {
        const edges = props?.edges || [];
        const nodes = props?.nodes || [];
        const clusters = props?.clusters || [];
        const stageOrder = props?.stageOrder;
        const sourceStage = props?.sourceStage;
        const showingDetail = props?.showingDetail;
        const swimlaneData = this.getSwimlaneData(edges, nodes, clusters, sourceStage, stageOrder, states);
        return { swimlaneData, allStages: [sourceStage, ...stageOrder], showingDetail};
    }

    changeDelegate(oldProps: IClarifySwimlaneProps, newProps: IClarifySwimlaneProps, states: IClarifySwimlaneStates): Object {
        if (!oldProps) {
            return {};
        }
        return {};
    }

    drawNodeLines(swimlaneNodes: QueryList<ElementRef>, edges: Edge[], options: SwimlaneLineSettingModel) {
        let nodeEles: Element[] = [], nodeLines = [];
        if (swimlaneNodes?.length > 0 && edges?.length > 0) {
            nodeEles = swimlaneNodes.map((item) => (item.nativeElement));
            // nodeLines = _.map(edges, edge => (new LeaderLine(_.find(nodeEles, { id: edge.source }), _.find(nodeEles, { id: edge.target }), options)));
            nodeLines = _.map(edges, edge => {
                const startNode = _.find(nodeEles, { id: edge.source });
                const endNode = _.find(nodeEles, { id: edge.target });
              
                if (startNode && endNode) {
                  return new LeaderLine(startNode, endNode, options);
                } else {
                  console.error(`Missing node(s): startNode - ${edge.source}, endNode - ${edge.target}`);
                  return null;
                }
              }).filter(line => line !== null);
              
        }
        return nodeLines;
    }

    getSwimlaneData(edges: Edge[], nodes: Node[], clusters: ClusterNode[], sourceStage: string, stageOrder: string[], states: IClarifySwimlaneStates): SwimlaneDataItemModel[] {
        const clusterNodes = _.map(clusters, cluster => {
            return _.assign({}, cluster, { nodeQueue: this.getNodeQueue(nodes, cluster, edges) });
        });
        const clusterGroupByStage = _.groupBy(clusterNodes, 'stage');
        const sourceStageClusters = clusterGroupByStage[sourceStage];
        const allStages = [sourceStage, ...stageOrder];
        let swimlaneData: SwimlaneDataItemModel[] = [], sourceStageClusterIdsOrder: string[] = [], clustersOrderByRow: SwimlaneClusterNode[][][];
        if (stageOrder?.length > 0) {
            sourceStageClusterIdsOrder = _.chain(edges)
                .filter(edge => {
                    return _.some(sourceStageClusters, sourceCluster => {
                        return _.includes(sourceCluster?.childNodeIds, edge?.source);
                    });
                })
                .map('source')
                .union()
                .value();
            clustersOrderByRow = _.map(sourceStageClusterIdsOrder, sourceStageClusterId => {
                return _.map(allStages, stage => {
                    if (stage === sourceStage) {
                        return [_.assign({}, clusterGroupByStage[stage][0], {
                            childNodeIds: [sourceStageClusterId],
                            nodeQueue: [[_.find(clusterGroupByStage[stage][0].nodeQueue[0], { id: sourceStageClusterId })]]
                        })];
                    } else {
                        return _.filter(clusterGroupByStage[stage], { dataSourceId: sourceStageClusterId });
                    }
                });
            }) as SwimlaneClusterNode[][][];
            swimlaneData = _.map(clustersOrderByRow, (clusters: SwimlaneClusterNode[][]) => {
                return {
                    clusters,
                    height: this.calcClustersSize(clusters, states)
                };
            });
        }
        return swimlaneData;
    }

    private getNodeQueue(nodes: Node[], cluster: ClusterNode, edges: Edge[]): Node[][] {
        const filteredNodes = _.reduce(nodes, (results, node) => {
            if (_.includes(cluster?.childNodeIds, node?.id)) {
                results.push({ ...node, contentText: this.getNodeTextList(node) });
            }
            return results;
        }, [] as SwimlaneNode[]);
        let containSource = false, containTarget = false;
        const edgesGroup = _.reduce(edges, (result: SwimEdgesGroup, edge) => {
            containSource = _.includes(cluster?.childNodeIds, edge?.source);
            containTarget = _.includes(cluster?.childNodeIds, edge?.target);
            if (containSource && containTarget) {
                result.middleEdges.push(edge);
            } else if (containSource) {
                result.endEdges.push(edge);
            } else if (containTarget) {
                result.startEdges.push(edge);
            } else {
                // invalid
            }
            return result;
        }, { startEdges: [], middleEdges: [], endEdges: [] });
        const nodeQueue: SwimlaneNode[][] = [];
        let sourceNode: SwimlaneNode, targetNode: SwimlaneNode;
        let middleSourceQueueIndex, middleTargetQueueIndex;
        if (edgesGroup.middleEdges?.length > 0) {
            nodeQueue[0] = _.chain(edgesGroup.startEdges).map((startEdge: Edge) => (_.find(filteredNodes, { id: startEdge.target }))).compact().value();

            _.forEach(edgesGroup.middleEdges, item => {

                middleSourceQueueIndex = _.findIndex(nodeQueue, (queue: Node[]) => (!_.isEmpty(_.find(queue, { id: item.source }))));
                middleTargetQueueIndex = _.findIndex(nodeQueue, (queue: Node[]) => (!_.isEmpty(_.find(queue, { id: item.target }))));
                sourceNode = _.find(filteredNodes, { id: item.source }) as SwimlaneNode;
                targetNode = _.find(filteredNodes, { id: item.target }) as SwimlaneNode;

                if (middleSourceQueueIndex >= 0 && middleTargetQueueIndex >= 0) {
                    // nodes has been excited
                } else if (middleSourceQueueIndex >= 0) {
                    if (nodeQueue[middleSourceQueueIndex + 1]?.length > 0) {
                        nodeQueue[middleSourceQueueIndex + 1].push(targetNode);
                    } else {
                        nodeQueue[middleSourceQueueIndex + 1] = [targetNode];
                    }
                } else if (middleTargetQueueIndex >= 1) {
                    nodeQueue[middleSourceQueueIndex - 1].push(sourceNode);
                } else {
                    if (!_.find(nodeQueue[0], { id: sourceNode.id })) {
                        nodeQueue[0].push(sourceNode);
                    }

                    if (nodeQueue[1]?.length > 0) {
                        if (!_.find(nodeQueue[1], { id: targetNode.id })) {
                            nodeQueue[1].push(targetNode);
                        }
                    } else {
                        nodeQueue[1] = [targetNode];
                    }
                }
            });

        } else {
            nodeQueue[0] = _.chain(_.map(edgesGroup.startEdges, 'target'))
                .concat(_.map(edgesGroup.endEdges, 'source'))
                .union()
                .map(id => (_.find(filteredNodes, { id })))
                .compact()
                .value();
        }
        return nodeQueue;
    }

    private calcClustersSize(clusterArr: SwimlaneClusterNode[][], states: IClarifySwimlaneStates): number {
        let maxHeight = 0;
        return _.reduce(clusterArr, (size, clusters) => {
            if (clusters?.length > 0) {
                maxHeight = _.reduce(clusters, (result, cluster, clusterIndex) => {
                    result += this.calcNodeQueueSize(cluster.nodeQueue, states) + states.clusterLabelSize + states.clusterPadding * 2;
                    if (clusterIndex > 0) {
                        result += states.clusterMargin;
                    }
                    return result;
                }, 0);
            }

            return (maxHeight > size ? maxHeight : size);
        }, 0);
    }

    private calcNodeQueueSize(nodeQueue: SwimlaneNode[][], states: IClarifySwimlaneStates): number {
        let maxHeight = 0;
        return _.reduce(nodeQueue, (result, queue) => {
            maxHeight = _.reduce(queue, (resultSize, node, index: number) => {
                resultSize += node.contentText.length * states.nodeHeight + states.nodePadding * 2;
                if (index > 0) {
                    resultSize += states.nodeMargin;
                }
                return resultSize;
            }, 0);
            return (maxHeight > result ? maxHeight : result);
        }, 0);
    }

    getNodeTextList(node: Node | undefined): string[] {
        const result = [];
        const includeDagLabelStages = ['contract','referral_optimization'];
        const excludeDagLabelStages = ['ccg','predictions'];
        if (node) {

            if (((!node.data?.displayCcg && !node.data?.titleCard) || (node.data?.displayCcg && node.label)) 
                    && (node.data?.runStage?.toLowerCase() !== node.data?.dagVariation)
                        && (!excludeDagLabelStages.includes(node.data?.runStage?.toLowerCase()))
                            || includeDagLabelStages.includes(node.data?.runStage?.toLowerCase())) {
                result.push(`Dag: ${node.data.dagVariation}`);
            }

            if (!node.data?.titleCard) {
                if (node.data?.runId && node.data?.runId.startsWith('aeon_')) {
                    result.push(`ID: No Run`)
                }
                else {
                    result.push(`ID: ${node.data?.runId || 'None'}`);
                }
            }

            if (node.data?.displayCcg) {
                result.push(`Ccg: ${node.data.displayCcg.replace('ccg_','').replace('_model','')}`);
            }

            if (node.data?.runType && node.data?.runType !== 'Full') {
                result.push(`Run type: ${node.data.runType}`);
            }

            if (node.data?.isPredictions && node.data?.payerGroup != 'None' && node.data?.metricType != 'None') {
                result.push(`Payer Group: ${node.data?.payerGroup || 'None'}`);
            }
            if (node.data?.isPredictions && node.data?.runYear != 'None'
                 && node.data?.runYear != 'Rolling'
                  && node.data?.metricType != 'None') {
                result.push(`Run Year: ${node.data?.runYear || 'None'}`);
            }

            if (node.data?.titleCard) {
                result.push(`DS: ${node.data?.dataSource.toUpperCase() || 'None'}`)
                result.push('Received:') 
                result.push(`${node.data?.fileSetDate || 'None'}`);
            }
            if (node.data?.enrichedRunId) {
                result.push(`EnrichID: ${node.data?.enrichedRunId || 'None'}`);
            }
            if (node.data?.ccgRunId) {
                result.push(`CcgID: ${node.data?.ccgRunId || 'None'}`);
            }
            if (node.data?.marketShareRunId) {
                result.push(`MSID: ${node.data?.marketShareRunId || 'None'}`);
            }
            if (node.data?.dateDeployedToStaging) { 
                result.push(`Staging: ${node.data?.dateDeployedToStaging}`)
            }
            if (node.data?.elapsedTimeToProd) {
                result.push(`Time to Prod: ${node.data?.elapsedTimeToProd} days`);
            }
        }

        return result;
    }

    getNodeHeightSize(node: Node | undefined, states: IClarifySwimlaneStates): number {
        const nodeText = this.getNodeTextList(node);
        return nodeText.length * states.nodeHeight + states.nodePadding * 2; // font-size: 16  padding: 4
    }
}
