import { Injectable } from '@angular/core';
import { IEnvironmentModalProps, IEnvironmentModalStates, IEnvironmentModalService } from './environmentModal.component.d';
import * as _ from 'lodash';
declare const $: any;

@Injectable()
export class EnvironmentModalService implements IEnvironmentModalService {
    initDelegate(props: IEnvironmentModalProps, states: IEnvironmentModalStates): object {
        states.commands = props.commands;
        return {};
    }

    changeDelegate(oldProps: IEnvironmentModalProps, newProps: IEnvironmentModalProps, states: IEnvironmentModalStates): object {
        if (!oldProps) {
            return {};
        }
        return {};
    }

    copyToClipboardHandler(command: any, states: IEnvironmentModalStates) {
        states.commands.forEach(function (c: any) {
            delete c.copyText;
        });
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(command.commandLines).select();
        document.execCommand('copy');
        $temp.remove();
        command.copyText = 'Copied!';
    }
}
