import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IStageSourceTableColumnListStates, IStageSourceTableColumnListService } from './stageSourceTableColumnList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { UIRouter, StateService } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { ViewDeequModalComponent } from '../deequModal/viewDeequModal.component';
import swal from 'sweetalert2';


@Injectable()
export class StageSourceTableColumnListService implements IStageSourceTableColumnListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, 
        private matDialog: MatDialog, private cdr: ChangeDetectorRef, private uiRouter: UIRouter, private state: StateService) {
        //
    }

    async initDelegate(states: IStageSourceTableColumnListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IStageSourceTableColumnListStates) {
        states.lodash = _;

        const table = await this.dataAccess.genericFind({
            model: 'TeradromeTable',
            filter: {
                include: [ 'teradromeStage', 'teradromeStageSource'],
                where: {
                    id: this.uiRouter.globals.params.tableId
                }
            }
        });
        states.teradromeTable = table[0];
        states.stage = states.teradromeTable.teradromeStage;
        states.stage.name = states.stage.stageName;
        states.source = states.teradromeTable.teradromeStageSource;
        if (states.source) {
            states.source.name = states.source.sourceName;
        }
        if (!states.stage.dataSourceAgnosticSchema || states.stage.stageName === 'reference_data') {
            states.sources = [];
        } else {
            const sources = await this.dataAccess.genericFind({
                model: 'TeradromeStageSource',
                filter: {
                    where: {
                        teradromeStageId: states.stage.id
                    },
                    fields: ['id', 'sourceName']
                }
            });

            states.sources = _.filter(_.sortBy(sources, 'sourceName'), function(s: any) {
                return !s.sourceName.endsWith('_sample');
            });
        }

        this.viewTests(states, this.uiRouter.globals.params.tableId, states.teradromeTable.tableName); 
    }

    async filterList(states: IStageSourceTableColumnListStates, level: any, filterValue?: any, filterId?: number, currentLevel = '', agnosticSchema?: boolean) {
        // This method is called when a user clicks a stage in list, if stage is data source agnostic then the list to should filtered to TeradromeTable. If it is not data source schema agnostic then the list should be filtered to TeradromeStageSource
        const stage = level !== 'stage' ? states.stage : undefined;
        const source = level === 'table' && !states.stage.dataSourceAgnosticSchema ? states.source : undefined;
        this.state.go('argos.data.stageSourceTable.list', {stage, source});
    }

    async viewTests(states: IStageSourceTableColumnListStates, teradromeTableId: any, tableName: any, dataSourceFilter?: any) {
        states.tableName = tableName;
        states.teradromeTableId = teradromeTableId;
        let columns = await this.dataAccess.genericFind({
            model: 'TeradromeColumn',
            filter: {
                include: [
                    {
                        relation: 'viewTeradromeDeequTestUnnested',
                        scope: {
                            include: 'teradromeStageSource'
                        },
                    }, 
                    'lastReportedStageSource'
                ],
                where: {
                    teradromeTableId,
                    teradromeStageSourceId: dataSourceFilter,
                    deletedTimestamp: null
                }
            }
        });
        // Filter out pipeline generated columns
        columns = _.filter(columns, function(c: any) {
            return !['file_set', 'file_path', 'data_source', 'vendor_file_set'].includes(c.columnName);
        });

        states.table.data = _.sortBy(_.map(columns, function(c: any) {
            return {
                ...c,
                testCount: c.viewTeradromeDeequTestUnnested.length,
                lastReported: c.lastReported,
                viewTeradromeDeequTestUnnested: _.map(c.viewTeradromeDeequTestUnnested, function(t: any) {
                    return {
                        ...t,
                        sourceName: t.teradromeStageSource ? t.teradromeStageSource.sourceName : 'Common',
                        sourceId: t.teradromeStageSource ? t.teradromeStageSource.id : null
                    };
                }),
                deletable: c.lastReported && new Date(c.lastReported) < new Date(new Date().setFullYear(new Date().getFullYear() - 1)) ? true : false
            };
        }), 'columnName');

        // if dataSourceFilter provided, filter viewTeradromeDeequTestUnnested in states.table.data by dataSourceFilter
        if (dataSourceFilter) {
            states.table.data = _.sortBy(_.map(states.table.data, function(c: any) {
                return {
                    ...c,
                    viewTeradromeDeequTestUnnested: _.filter(c.viewTeradromeDeequTestUnnested, function(t: any) {
                        return t.sourceId === dataSourceFilter;
                    })
                };
            }), 'columnName');
        }

        const headerTests = await this.dataAccess.genericFind({
            model: 'ViewTeradromeDeequTestUnnested',
            filter: {
                include: {
                    relation: 'teradromeStageSource'
                },
                where: {
                    teradromeColumnId: null,
                    teradromeTableId,
                    teradromeStageSourceId: dataSourceFilter ? dataSourceFilter : undefined,
                    deletedTimestamp: null
                }
            }
        });
        states.headerTable.data = _.sortBy(_.map(headerTests, function(ht: any) {
            return {
                ...ht,
                sourceName: ht.teradromeStageSource ? ht.teradromeStageSource.sourceName : 'Common'

            };
        }), 'name');

        // Clear filters
        states.filterObj = {};
        states.table.filter = JSON.stringify(states.filterObj);
        states.filterObjHeader = {};
        states.headerTable.filter = JSON.stringify(states.filterObjHeader);

        this.cdr.detectChanges();
    }

    setTablePageSize(states: IStageSourceTableColumnListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.stageSourceTable.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IStageSourceTableColumnListStates) {

        const data = states.table.data;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    exportToExcelHeader(states: IStageSourceTableColumnListStates) {

        const data = states.headerTable.data;
        const columns = states.headerTestColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingNameHeader, columns, data);
    }

    openMatTableSettings(states: IStageSourceTableColumnListStates) {
        const data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    openMatTableSettingsHeader(states: IStageSourceTableColumnListStates) {
        const data = { 
            tableColumns: states.headerTestColumns,
            defaultDisplayTableColumns: states.defaultHeaderTestColumns,
            settingName: states.matTableSettingNameHeader
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    launchTestModal(test: any | undefined, columnId: number | undefined, states: IStageSourceTableColumnListStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    deequTest: test || {args: {} },
                    teradromeTable: states.teradromeTable,
                    columns: states.table.data,
                    source: states.source,
                    stage: states.stage,
                    columnId,
                    testType: states.activeTab === 1 ? 'table' : 'column',
                    sources: states.sources
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewDeequModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async  (result: any) => {
            this.matDialog.closeAll();

            // Fresh teradromeTable
            const table = await this.dataAccess.genericFind({
                model: 'TeradromeTable',
                filter: {
                    include: [ 'teradromeStage', 'teradromeStageSource'],
                    where: {
                        id: this.uiRouter.globals.params.tableId
                    }
                }
            });
            states.teradromeTable = table[0];

            this.viewTests(states, states.teradromeTableId, states.tableName);
        });
    }

    async deleteTable(states: IStageSourceTableColumnListStates, tableId: any) {

        await this.dataAccess.genericMethod({
            model: 'TeradromeTable', method: 'destroyById',
            parameters: { id: tableId }
        }
        );

        this.filterList(states, 'table', states.stage.name, states.stage.id, '', states.stage.dataSourceAgnosticSchema);
    }

    async deleteColumn(states: IStageSourceTableColumnListStates, columnId: any) {

        let deequTests = await this.dataAccess.genericFind({
            model: 'TeradromeDeequTest'
        });

        deequTests = _.filter(deequTests, function(d: any) {
            return _.includes(d.teradromeColumnIds, columnId);
        });

        const testNames = _.map(deequTests, 'name');
        swal({
            title: 'Are you sure?',
            text: deequTests.length ? `You are about to delete the column and the following tests: ${testNames.join(', ')}.` : 'This test has no corresponing Deequ tests.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#DD6B55'
        }).then(async (isConfirm: any) => {      
            if (isConfirm.value) {

                await this.dataAccess.genericMethod({
                    model: 'TeradromeColumn', method: 'destroyById',
                    parameters: { id: columnId }
                }
                );
    
                this.viewTests(states, states.teradromeTableId, states.tableName);

                this.cdr.detectChanges();
            }
        });
    }

}
