import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { INewEnvironment20RequestStates } from './newEnvironment20Request.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { NewEnvironment20RequestService } from './newEnvironment20Request.component.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'argos-newEnvironment20Request',
    templateUrl: './newEnvironment20Request.component.html',
    providers: [NewEnvironment20RequestService]
})

export class NewEnvironment20RequestComponent
    extends ReactComponentBase<{}, INewEnvironment20RequestStates, {}> implements OnInit {
    customerFrmCtrl = new FormControl('');
    customerFilteredOptions: Observable<string[]>;

    constructor(private svc: NewEnvironment20RequestService, private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef) {
        super({
            env2_0_request: {
                environmentName: 'newEnvironmentRequest',
                environmentDatasources: [],
                domainName: 'clarifyhealth.us',
                hostingProvider: 'ecs',
                approvalManagers: [],
                environmentBuilds: [],
                url: '',
                auth0Id: -1,
                isSSOEnabled: false,
                environmentType: '',
                queryFilters: [],
                datasourceParameters: [],
                filterParameters: { productConfiguration: []},
                // properties not part of db table
                propertyErrors: [],
                productConfigs: [],
                assignedOwnerEmails: [],
            },
            selectedCustomer: {},
            newAuth0Obj: {},
            isAddAuth0FieldsVisible: false,
            awsAccounts: [{ id: 'clarify', name: 'Clarify AWS Account', },
                { id: 'cleardata', name: 'Clear Data AWS Account' }],
            ec2InstanceType: [{ id: 't3.medium', name: 'small - internal' },
                { id: 't3a.large', name: 'medium - prod site' },
                { id: 't3a.xlarge', name: 'large - heavy use' }],
            siteTypeOptions: ['client_prod', 'demo'],
            domainTypes: ['clarifyhealth.us', 'clarifyhealth.com'],
            clientProvidedDataOptions: [{ id: true, name: 'yes' }, { id: false, name: 'no' }],
            clientProvidedDataUseOptions: [{ id: true, name: 'will host pii information' },
                { id: false, name: 'will not contain pii information' }],
            launchType: [{ id: 'ecs', name: 'AWS ECS', },
                { id: 'fargate', name: 'AWS Fargate' }],
            clarifyRdsIdentifiers: [{ id: 'platform-rds' }, { id: 'platform-rds-dev' }],
            clients: [],
            customers: [],
            careGroupings: [],
            auth0Connections: [],
            buildTags: [],
            vpcList: [],

            appUrlIsInUse: false,
            isUptimeAlarmEnabled: true,
            isSSOEnabledOverride: false,
            isCreateDedicatedRedis: false,
            buildRequest: {
                isRequestingEnvironment: false,
                appUrl: '',
                client: {},
                vpc: { rds: {} },
                selectedEc2InstanceType: 't3.medium'
            },
            buildLog: '',
            productConfigs: [],
            assignedOwnerEmails: [],
            selectedAssignedOwnerEmails: [],
            selectedProductBundles: [],
            buildSteps: [],
            dropdownProductBundlesSettings: {
                singleSelection: false,
                idField: 'id',
                textField: 'title',
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 5,
                allowSearchFilter: true
            },
            dropdownAssignedOwnerEmailsSettings: {
                singleSelection: false,
                idField: 'id',
                textField: 'email',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 5,
                allowSearchFilter: true
            },
            approver: {},
        } as unknown as INewEnvironment20RequestStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.customerFilteredOptions = this.customerFrmCtrl.valueChanges.pipe(
            startWith(''),
            map(value => this.customerSuggestionFilter(value || '')),
        );

        this.cdr.detectChanges();
    }

    onConfigurationSelect(dropdownName: any, event: any) {
        if (dropdownName === 'productConfiguration') {
            this.states.selectedProductBundles.push(event);
        }
        else if (dropdownName === 'assignedOwnerEmails') {
            this.states.selectedAssignedOwnerEmails.push(event);
        }
    }

    onConfigurationSelectAll(dropdownName: any, event: any[]) {
        if (dropdownName === 'productConfiguration') {
            this.states.selectedProductBundles = event;    
        }
    }

    onConfigurationDeSelect(dropdownName: any, event: any) {
        if (dropdownName === 'productConfiguration') {
            const selectedProductBundles = _.cloneDeep(this.states.selectedProductBundles);
            this.states.selectedProductBundles = _.filter(selectedProductBundles, product => {
                return (product.id !== event.id);
            });    
        }
        else if (dropdownName === 'assignedOwnerEmails') {
            const selectedAssignedOwnerEmails = _.cloneDeep(this.states.selectedAssignedOwnerEmails);
            this.states.selectedAssignedOwnerEmails = _.filter(selectedAssignedOwnerEmails, emails => {
                return (emails.id !== event.id);
            });    
        }
    }

    onConfigurationDeSelectAll(dropdownName: any, event: any[]) {
        if (dropdownName === 'productConfiguration') {
            this.states.selectedProductBundles = [];    
        }
        else if (dropdownName === 'assignedOwnerEmails') {
            this.states.selectedAssignedOwnerEmails = [];    
        }         
    }

    onConfigurationDropDownClose(dropdownName: any, event: any) {
        if (dropdownName === 'productConfiguration') {
            this.states.env2_0_request.filterParameters.productConfiguration = this.states.selectedProductBundles;
        }
        else if (dropdownName === 'assignedOwnerEmails') {
            this.states.env2_0_request.assignedOwnerEmails = this.states.selectedAssignedOwnerEmails;
        }
    }

    private customerSuggestionFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const list = this.states.customers.filter(option => option.customerName.toLowerCase().includes(filterValue));
        return _.map(list, 'customerName');
    }

    buildFieldChanged() {
        // TODO Version 1.0 has no implementation
    }

    requestApproval() {
        this.svc.requestApproval(this.states);
    }
    async buildEcsSite() {


        this.states.buildSteps = ['dbSetupStep', 'appSetupStep', 'dnsSetupStep', 'argosEnvSetupStep', 'uptimeSetupStep', 'serviceSchedulingSetupStep', 'customFieldSetupStep', 'customFieldSetupCheck', 'done'];
        const sleepStepCount = 0;

        this.states.buildLog = 'starting build...\n';
        this.states.buildRequest.isRequestingEnvironment = true;
        const args = this.getEcsPostgresDBArgs();
        const data = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'createEcsPostgresDB', parameters: {
                clientInternal: args.clientInternal.internal_name,
                dbName: args.dbName,
                dbUserName: args.dbUserName,
                namingOverride: args.namingOverride,
                awsAccountName: args.awsAccountName,
                rdsIdentifier: args.rdsIdentifier
            }
        });

        if (this.states.env2_0_request.imageLogoData) {
            this.uploadLogoFile(this.states.env2_0_request.imageLogoData, this.states.buildRequest.client.internal_name, this.states.env2_0_request.imageLogoExtension, data.runId);
        }

        this.svc.addBuildLog('\nBuild log at ' + data.buildLog, this.states);

        if (this.states.buildRequest.awsAccountName === 'cleardata') {
            this.svc.addBuildLog('\nAuto build is not supported for Cleardata. Preparing ECS database folder for manual review' + data.dbName, this.states);
            this.svc.addBuildLog('\nDatabase folder at ' + data.folderPath, this.states);
            await this.getEcsClearDataBuild(data.runId, data.buildLog, data.secretId);
        } else {
            this.svc.addBuildLog('Creating ECS database ' + data.dbName, this.states);
            // 'Creating ECS environment',
            await this.showEcsBuildProgress(data.runId, data.buildLog, data.secretId, data.dbName, data.dbUserName);
        }
    }

    async uploadLogoFile(imageLogoData: string, customerName: string, imageLogoExtension: string, runId: string) {
        this.dataAccess.genericMethod({
            model: 'Environment', method: 'uploadLogoImage', parameters: {
                logoImage: imageLogoData,
                logoName: customerName,
                logoExtension: imageLogoExtension,
                runId,
                bucketName: 'care-pilot-assets/logos',
                awsAccountName: 'clarify'
            }
        });
    }

    getEcsPostgresDBArgs() {
        const result: any = {
            // clientInternal: this.states.buildRequest.client.internal_name,
            clientInternal: this.states.buildRequest.client,
            dbName: this.states.buildRequest.ecsNamingConventionOverride,
            dbUserName: this.states.buildRequest.ecsNamingConventionOverride + '_role',
            namingOverride: this.states.buildRequest.ecsNamingConventionOverride,
            awsAccountName: this.states.buildRequest.awsAccountName,
            rdsIdentifier: this.states.buildRequest.selectedClarifyRdsIdent,
        };

        if (this.states.buildRequest.awsAccountName === 'cleardata') {
            result.rdsEndPoint = this.states.buildRequest.vpc.rds.Address;
            result.testMode = true;
        }
        return result;
    }

    async getEcsClearDataBuild(runId: any, buildLogName: any, requestedSecretId: any) {
        this.svc.addBuildLog('\nPreparing ECS app folder for manual review', this.states);

        const response = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'createEcsAppRequest', parameters: {
                runId,
                clientInternal: this.states.buildRequest.client.internal_name,
                awsSecretName: requestedSecretId,
                auth0ConnId: this.states.buildRequest.auth0Obj.auth0Connection,
                buildTag: this.states.buildRequest.buildTag,
                namingOverride: this.states.buildRequest.ecsNamingConventionOverride,
                useDotComCert: this.svc.isDotComCert(this.states),
                instanceType: this.states.buildRequest.selectedEc2InstanceType,
                awsAccountName: this.states.buildRequest.awsAccountName,
                vpcId: this.states.buildRequest.vpc.vpcId,
                publicSubnets: this.svc.getAwsGroupSelected(true, 'subnets', this.states),
                privateSubnets: this.svc.getAwsGroupSelected(false, 'subnets', this.states),
                publicSg: this.svc.getAwsGroupSelected(true, 'securityGroups', this.states),
                privateSg: this.svc.getAwsGroupSelected(false, 'securityGroups', this.states),
                redisUri: 'you must manually create this and add the full url here',
                rdsHost: this.states.buildRequest.vpc.rds.Address,
                // testMode: true,
                launchType: this.states.buildRequest.selectedLaunchType
            }
        });
        this.svc.addBuildLog('\nECS environment requested. Build Log is ' + buildLogName, this.states);
        this.svc.addBuildLog('\nECS folder at ' + response.folderPath, this.states);
        this.svc.addBuildLog('\nRequest completed', this.states);
    }

    showEcsBuildProgress(runId: any,
        buildLogName: any,
        requestedSecretId: any,
        databaseName: any,
        databaseUserName: any) {

        this.states.buildSteps = ['clientOwnerSetupStep', 'dbSetupStep', 'appSetupStep', 'dnsSetupStep', 'argosEnvSetupStep', 'uptimeSetupStep', 'serviceSchedulingSetupStep', 'customFieldSetupStep', 'customFieldSetupCheck', 'done'];
        const sleepStepCount = 0;

        if (!this.states.isUptimeAlarmEnabled) {
            this.states.buildSteps.splice(this.states.buildSteps.indexOf('uptimeSetupStep'), 1);    // remove step to skip it
        }

        this.states.refreshIntervalId = setInterval(async () => {
            const currentStep = this.states.buildSteps[0];

            switch (currentStep) {
                case 'clientOwnerSetupStep':
                    try {
                        this.svc.addBuildLog('\nAdding assigned owner emails to client ', this.states);

                        const ClientObj = await this.dataAccess.genericMethod({
                            model: 'Client',
                            method: 'findById',
                            parameters: {
                                id: this.states.buildRequest.client.id,
                            }
                        });
                
                        // only assign if empty
                        if (_.isEmpty(ClientObj.assignedOwnerEmail) && this.states.selectedAssignedOwnerEmails.length > 0) {
                            ClientObj.assignedOwnerEmail = this.states.selectedAssignedOwnerEmails[0];
                            const newClientObj = await this.svc.updateClient(ClientObj);
                            this.svc.addBuildLog('\nArgos Client assignee update to ' + JSON.stringify(ClientObj.assignedOwnerEmail), this.states);
                        } else {
                            this.svc.addBuildLog('\nSkipping Argos Client updated because assignee is already set ' + JSON.stringify(ClientObj.assignedOwnerEmail), this.states);
                        }

                    } catch (error) {
                        this.svc.addBuildLog('\nError at ' + currentStep + ': ' + JSON.stringify(error), this.states);
                        this.stopBuild();
                    }
                    // finished so pop to the next step
                    this.moveToNextBuildStep(currentStep);
                    break;
                case 'dbSetupStep':
                    try {
                        const data = await this.dataAccess.genericMethod({
                            model: 'Environment', method: 'getScreenStatus', parameters: {
                                runId, buildLog: buildLogName
                            }
                        });
                        if (data.status.indexOf('running') !== -1 || data.status.length === 0) {
                            this.svc.addBuildLog('.', this.states);

                        } else if (data.status.indexOf('ecs platform database setup completed') === -1) {
                            this.svc.addBuildLog('\nECS platform database setup failed: ' + data.status, this.states);
                            this.stopBuild();
                        } else {

                            this.svc.addBuildLog('\nDatabase setup completed.', this.states);
                            await this.dataAccess.genericCreate({
                                model: 'EnvironmentBuildLog',
                                data: {
                                    runId,
                                    message: 'Database setup completed.',
                                    isError: false,
                                    logSource: 'argos'
                                }
                            });

                            if (this.states.isCreateDedicatedRedis) {
                                this.svc.addBuildLog('\nDetected NPO site, setting up Redis now.', this.states);

                                const response = await this.dataAccess.genericMethod({
                                    model: 'Environment', method: 'createRedisCluster', parameters: {
                                        runId,
                                        clientInternal: this.states.buildRequest.client.internal_name,
                                        awsAccountName: this.states.buildRequest.awsAccountName,
                                        namingOverride: this.states.buildRequest.ecsNamingConventionOverride
                                    }
                                });

                                this.states.createRedisRequestResponse = response;
                                this.svc.addBuildLog('\n' + response.status, this.states);

                                this.states.createdRedisDNS = 'master.' + response.redisName + '.yjcame.usw2.cache.amazonaws.com:6379';

                            }

                            this.svc.addBuildLog('\nSetting up ECS app now.', this.states);
                            await this.dataAccess.genericCreate({
                                model: 'EnvironmentBuildLog',
                                data: {
                                    runId,
                                    message: 'Setting up ECS app now',
                                    isError: false,
                                    logSource: 'argos'
                                }
                            });

                            buildLogName = null;

                            const ecsArg: any = {
                                runId,
                                clientInternal: this.states.buildRequest.client.internal_name,
                                awsSecretName: requestedSecretId,
                                auth0ConnId: this.states.buildRequest.auth0Obj.auth0Connection,
                                buildTag: this.states.buildRequest.buildTag,
                                namingOverride: this.states.buildRequest.ecsNamingConventionOverride,
                                useDotComCert: this.svc.isDotComCert(this.states),
                                instanceType: this.states.buildRequest.selectedEc2InstanceType,
                                awsAccountName: this.states.buildRequest.awsAccountName,
                                // testMode: true,
                                launchType: this.states.buildRequest.selectedLaunchType,
                                rdsIdentifier: this.states.buildRequest.selectedClarifyRdsIdent,
                                siteType: this.states.env2_0_request.environmentType,
                                npoType: this.states.env2_0_request.isNpoEnvironment,
                                redisIdentifier: this.states.createdRedisDNS
                            };

                            if (this.states.buildRequest.auth0Obj && this.states.buildRequest.auth0Obj.auth0Tenant === 'dev') {
                                ecsArg.auth0Domain = 'clarifyhealth-dev.us.auth0.com';
                            }

                            const response = await this.dataAccess.genericMethod({
                                model: 'Environment', method: 'createEcsAppRequest', parameters: ecsArg
                            });

                            this.states.ecsCreateRequestResponse = response;
                            this.svc.addBuildLog('\n' + response.status, this.states);
                            buildLogName = response.buildLog;
                            this.svc.addBuildLog('\nECS environment requested. Build Log is ' + buildLogName, this.states);

                            // finished so pop to the next step
                            this.moveToNextBuildStep(currentStep);
                        }
                    } catch (error) {
                        // error
                        this.svc.addBuildLog('\nError at ' + currentStep, this.states);
                        this.stopBuild();
                    }
                    break;
                case 'appSetupStep':
                    try {
                        // only do something if buildLogName is set from the step above
                        if (buildLogName) {
                            const data = await this.dataAccess.genericMethod({
                                model: 'Environment', method: 'getScreenStatus', parameters: {
                                    runId, buildLog: buildLogName
                                }
                            });
                            if (data.status.indexOf('running') !== -1) {
                                this.svc.addBuildLog('.', this.states);
                            } else if (data.status.indexOf('ecs platform app setup completed') === -1) {
                                // if you are not runnning or completed then we assume failed
                                this.svc.addBuildLog('\nECS platform app setup failed: ' + data.status, this.states);
                                await this.dataAccess.genericCreate({
                                    model: 'EnvironmentBuildLog',
                                    data: {
                                        runId,
                                        message: 'Ecs platform app setup reported ' + data.status,
                                        isError: true,
                                        logSource: 'argos'
                                    }
                                });
                                this.stopBuild();
                            } else {
                                this.svc.addBuildLog('\nECS platform app setup completed.', this.states);
                                // finished so pop to the next step
                                this.moveToNextBuildStep(currentStep);
                            }
                        }
                    } catch (error) {
                        // error
                        this.svc.addBuildLog('\nError at ' + currentStep + ': ' + JSON.stringify(error), this.states);
                        this.stopBuild();
                    }
                    break;
                case 'dnsSetupStep':
                    this.svc.addBuildLog('\nSetting up Domain skipped', this.states);
                    this.moveToNextBuildStep(currentStep);
                    break;
                case 'argosEnvSetupStep':
                    try {
                        const envObj = await this.dataAccess.genericMethod({
                            model: 'Environment', method: 'findOne', parameters: {
                                filter: { where: { hostingProvider: 'ecs' } }
                            }
                        });

                        // setup correct db server id
                        let filterName = this.states.buildRequest.selectedClarifyRdsIdent;
                        if (this.states.buildRequest.awsAccountName === 'cleardata') {
                            filterName = 'localhost';
                        }

                        const dbServerObj = await this.dataAccess.genericMethod({
                            model: 'DatabaseServer', method: 'findOne', parameters: {
                                filter: { where: { name: filterName } }
                            }
                        });

                        const dbServerId = dbServerObj ? dbServerObj.id : null;

                        const cloneEnvObj = this.svc.cloneEnvironment(runId, envObj, this.states.ecsCreateRequestResponse.clusterName, databaseName, databaseUserName, dbServerId, this.states);
                        const newEnvObj = await this.svc.createEnvironment(cloneEnvObj);
                        this.svc.addBuildLog('\nArgos Environment created', this.states);
                        await this.svc.saveEnvironmentCreationToLog(runId, newEnvObj); // save steps to log tables
                    } catch (error) {
                        this.svc.addBuildLog('\nError at ' + currentStep + ': ' + JSON.stringify(error), this.states);
                        this.stopBuild();
                    }
                    // finished so pop to the next step
                    this.moveToNextBuildStep(currentStep);
                    break;
                // case 'clientOwnerSetupStep':
                //     try {
                //         this.svc.addBuildLog('\nAdding assigned owner emails to client ', this.states);

                //         let newClientObj = await this.dataAccess.genericMethod({
                //             model: 'Client',
                //             method: 'findById',
                //             parameters: {
                //                 id: this.states.buildRequest.client.id,
                //             }
                //         });

                //         newClientObj.assignedOwnerEmails = this.states.selectedAssignedOwnerEmails;

                //         const newClientObj = await this.svc.updateClient(newClientObj);
                        
                //         this.svc.addBuildLog('\nArgos Client updated', this.states);
                //     } catch (error) {
                //         this.svc.addBuildLog('\nError at ' + currentStep + ': ' + JSON.stringify(error), this.states);
                //         this.stopBuild();
                //     }
                //     // finished so pop to the next step
                //     this.moveToNextBuildStep(currentStep);
                //     break;
                case 'uptimeSetupStep':
                    try {
                        if (this.states.isSSOEnabledOverride) {
                            this.states.env2_0_request.isSSOEnabled = !this.states.env2_0_request.isSSOEnabled;
                        }

                        const alertUptimeResult: any = await this.svc.createUptimeAlarm(this.states.ecsCreateRequestResponse.clusterName, this.states.env2_0_request.isSSOEnabled, this.states);

                        if (alertUptimeResult) {
                            this.svc.addBuildLog('\nTransaction and uptime alarm created', this.states);
                        } else {
                            this.svc.addBuildLog('\nTransaction and uptime alarm failed', this.states);
                        }

                    } catch (error) {
                        this.svc.addBuildLog('\nError at ' + currentStep, this.states);
                        this.stopBuild();
                    }
                    // finished so pop to the next step
                    this.moveToNextBuildStep(currentStep);
                    break;
                case 'serviceSchedulingSetupStep':
                    try {
                        if (this.states.env2_0_request.isNpoEnvironment) {
                            // web
                            let response = this.svc.createEcsServiceScaling(this.states.ecsCreateRequestResponse.clusterName, '-service', 'mon-fri', '0', '23', '1', '1', this.states);
                            response = this.svc.createEcsServiceScaling(this.states.ecsCreateRequestResponse.clusterName, '-service', 'mon-fri', '0', '4', '2', '2', this.states);
                            // worker
                            response = this.svc.createEcsServiceScaling(this.states.ecsCreateRequestResponse.clusterName, '-worker-service', 'mon-fri', '0', '23', '0', '0', this.states);
                            response = this.svc.createEcsServiceScaling(this.states.ecsCreateRequestResponse.clusterName, '-worker-service', 'mon-fri', '0', '4', '4', '4', this.states);
                        } else {
                            // web
                            let response = this.svc.createEcsServiceScaling(this.states.ecsCreateRequestResponse.clusterName, '-service', 'mon-fri', '0', '23', '1', '1', this.states);
                            response = this.svc.createEcsServiceScaling(this.states.ecsCreateRequestResponse.clusterName, '-service', 'mon-fri', '0', '4', '2', '2', this.states);
                            // worker
                            response = this.svc.createEcsServiceScaling(this.states.ecsCreateRequestResponse.clusterName, '-worker-service', 'mon-fri', '0', '23', '0', '0', this.states);
                            response = this.svc.createEcsServiceScaling(this.states.ecsCreateRequestResponse.clusterName, '-worker-service', 'mon-fri', '0', '4', '2', '2', this.states);
                        }

                        this.svc.addBuildLog('\nService Schedules created', this.states);
                    } catch (error) {
                        this.svc.addBuildLog('\nError at ' + currentStep, this.states);
                        this.stopBuild();
                    }
                    // finished so pop to the next step
                    this.moveToNextBuildStep(currentStep);
                    break;
                case 'customFieldSetupStep':
                    const response = await this.dataAccess.genericMethod({
                        model: 'Environment', method: 'createEcsCustomFieldRequest', parameters: {
                            runId,
                            clientInternal: this.states.buildRequest.client.internal_name,
                            awsAccountName: this.states.buildRequest.awsAccountName,
                            namingOverride: this.states.buildRequest.ecsNamingConventionOverride,
                            dbName: this.states.buildRequest.ecsNamingConventionOverride
                        }
                    });
                    buildLogName = response.buildLog;
                    this.svc.addBuildLog('\nECS Custom field requested. Build Log is ' + buildLogName, this.states);
                    this.svc.addBuildLog('\nECS folder at ' + response.folderPath, this.states);
                    this.svc.addBuildLog('\nRequest completed', this.states);

                    this.moveToNextBuildStep(currentStep);
                    break;
                case 'customFieldSetupCheck':
                    // only do something if buildLogName is set from the step above
                    if (buildLogName) {

                        const data = await this.dataAccess.genericMethod({
                            model: 'Environment', method: 'getScreenStatus', parameters: {
                                runId, buildLog: buildLogName
                            }
                        });

                        if (data.status.indexOf('running') !== -1) {
                            this.svc.addBuildLog('.', this.states);
                        } else if (data.status.indexOf('custom field setup completed') === -1) {
                            this.svc.addBuildLog('\nCustom field setup completed failed: ' + data.status, this.states);
                            this.stopBuild();
                        } else {
                            this.svc.addBuildLog('\nCustom field setup completed.', this.states);
                            await this.dataAccess.genericCreate({
                                model: 'EnvironmentBuildLog',
                                data: {
                                    runId,
                                    message: 'custom field setup reported',
                                    isError: false,
                                    logSource: 'argos'
                                }
                            });
                        }
                    }
                    this.moveToNextBuildStep(currentStep);
                    break;
                case 'done':
                    this.svc.addBuildLog('\nEnvironment creation completed', this.states);
                    clearInterval(this.states.refreshIntervalId);
                    break;
            }
        }, 60000);
    }

    moveToNextBuildStep(currentStep: any) {
        // if currentStep exists then we have not removed it and moved to the next step
        // otherwise we already did move it so do nothing
        if (this.states.buildSteps.indexOf(currentStep) >= 0 && this.states.buildSteps.length > 0) {
            this.states.buildSteps.splice(0, 1);
            this.svc.addBuildLog('\nmoving to ' + this.states.buildSteps[0], this.states);
        }
    }

    stopBuild() {
        this.states.buildSteps = ['stop'];
        clearInterval(this.states.refreshIntervalId);
        this.svc.addBuildLog('\nBuild process stopped', this.states);
    }

    approverUpdateRequest() {
        this.svc.approverUpdateRequest(this.states);
    }

    errorExists(row: any, propertyName: any) {
        return this.svc.errorExists(row, propertyName);
    }

    buildEcsSiteFieldChanged() {
        this.svc.buildEcsSiteFieldChanged(this.states);
    }

    selectedCustomerNameChanged(selectedCustName: any) {
        this.svc.selectedCustomerNameChanged(selectedCustName, this.states);
    }

    clientProvidedDataUseOptionChanged() {
        this.svc.clientProvidedDataUseOptionChanged(this.states);
    }

    addDatasourceFilter() {
        this.svc.addDatasourceFilter(this.states);
    }

    editDatasourceFilter(dsObj: any, isReadOnly: any) {
        this.svc.editDatasourceFilter(dsObj, isReadOnly);
    }

    deleteDatasourceFilter(dsObj: any) {
        this.svc.deleteDatasourceFilter(dsObj, this.states);
    }

    getCareGroupingName(careGroupingId: any) {
        // TODO Not implemented in one version
    }

    isValid() {
        return this.svc.isValid(this.states);
    }

    selectedClientChange() {
        this.svc.selectedClientChange(this.states);
    }

    showAddAuth0Field() {
        this.states.isAddAuth0FieldsVisible = true;
    }

    addAuth0Object() {
        this.svc.addAuth0Object(this.states);
    }

    selectedAWSServiceChanged(awsAccountName: any) {
        this.svc.selectedAWSServiceChanged(awsAccountName, this.states);
    }

    getAwsGroupSelectedCount(isPublic: any, groupType: any) {
        return this.svc.getAwsGroupSelectedCount(isPublic, groupType, this.states);
    }

    isValidBuildRequest() {
        return this.svc.isValidBuildRequest(this.states);
    }

    readLogoFile(file: any) {
        this.svc.readLogoFile(file, this.states);
    }
}
