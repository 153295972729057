import { Injectable } from '@angular/core';
import { IAllowedFieldListStates, IAllowedFieldListService } from './allowedFieldList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class AllowedFieldListService implements IAllowedFieldListService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: IAllowedFieldListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IAllowedFieldListStates) {
        const observationGroupAllowedFields = await this.dataAccess.genericFind({
            model: 'ObservationGroupAllowedField',
            filter: {
                include: ['observationGroup', 'fieldListItem', 'observationSelectionFields', 'observationKeyFields']
            }
        });
        states.observationGroupAllowedFields = _.orderBy(_.map(observationGroupAllowedFields, (ogaf: any) => {
            ogaf.observationGroupName = ogaf.observationGroup.name;
            ogaf.allowedFieldName = ogaf.fieldListItem.key + '.' + ogaf.fieldListItem.value;
            ogaf.usedInObservationsCount = _.get(ogaf, 'observationSelectionFields.length', 0) + _.get(ogaf, 'observationSelectionFields.length', 0);
            return ogaf;
        }), function (ogaf) {
            return ogaf.observationGroup.name + '.' + ogaf.fieldListItem.key + '.' + ogaf.fieldListItem.value;
        });
    }
}
