import { Injectable } from '@angular/core';
import { IViewSQLModalProps, IViewSQLModalStates, IViewSQLModalService } from './viewSQLModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
declare const $: any;

@Injectable()
export class ViewSQLModalService implements IViewSQLModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(props: IViewSQLModalProps, states: IViewSQLModalStates): Promise<object> {
        states.observation = props.observation || {};
        states.sqlMode = props.sqlMode || '';
        if (states.sqlMode === 'metric') {
            const result = await this.dataAccess.genericMethod({
                model: 'Observation', method: 'getMetricSql',
                parameters: { id: states.observation.id, filter: {} }
            });
            states.sql = result.sql;
        } else if (states.sqlMode === 'all') {
            const result = await this.dataAccess.genericMethod({
                model: 'Observation', method: 'getAllMetricSql', parameters: {
                }
            });
            states.sql = result.sql;
        } else {
            const result = await this.dataAccess.genericMethod({
                model: 'Observation', method: 'getSql',
                parameters: { id: states.observation.id, filter: {} }
            });
            states.sql = result.sql;
        }
        return {};
    }

    async changeDelegate(oldProps: IViewSQLModalProps, newProps: IViewSQLModalProps, states: IViewSQLModalStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    copyToClipboardHandler(command: any, states: IViewSQLModalStates) {
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(command).select();
        document.execCommand('copy');
        $temp.remove();
        states.copied = 'Copied!';
    }
}
