import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IClaraIqReleaseNotesStates } from './claraIqReleaseNotes.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { ClaraIqReleaseNotesService } from './claraIqReleaseNotes.component.service';
import * as _ from 'lodash';


@Component({
    selector: 'argos-claraIqReleaseNotes',
    templateUrl: './claraIqReleaseNotes.component.html',
    providers: [ClaraIqReleaseNotesService],
    styleUrls: ['./claraIqReleaseNotes.component.css']
})

export class ClaraIqReleaseNotesComponent extends ReactComponentBase<{}, IClaraIqReleaseNotesStates, {}> implements OnInit {
    constructor(private svc: ClaraIqReleaseNotesService, private cdr: ChangeDetectorRef) {
        super({
            jiraFilterId: 13707,
            releaseNotes: ''
        } as unknown as IClaraIqReleaseNotesStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    copyMarkdown() {
        navigator.clipboard.writeText(this.states.releaseNotes)
            .catch(err => {
                console.error('Failed to copy markdown:', err);
            });
    }

    refresh() {
        this.svc.activate(this.states);
    }


}

