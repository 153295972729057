// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  /* semi-transparent white background */
  z-index: 9999;
  /* ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* Prevent graph rendering issue if user scrolls while spinner is up */
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  /* Change spinner color here */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./client/app/components/dataDashboard/dataDashboard/dataDashboard.component.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACF,sCAAsC;EAApC,aAAA;EAEF,oCAAoC;EADlC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EAGF,sEAAsE;AACxE;AADE;EACE,oCAAA;EACA,uBAAA;EAGF,8BAA8B;EAF5B,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;AAIJ;AADE;EACE;IAAK,yBAAA;EAIP;AACF","sourcesContent":[".spinner-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.5); /* semi-transparent white background */\n    z-index: 9999; /* ensure it's above other content */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden; /* Prevent graph rendering issue if user scrolls while spinner is up */\n  }\n  \n  .spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-left-color: #333; /* Change spinner color here */\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    to { transform: rotate(360deg); }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
