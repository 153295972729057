import { Component, OnInit } from '@angular/core';
import { IModelMetaDataEditStates } from './modelMetaDataEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ModelMetaDataEditService } from './modelMetaDataEdit.component.service';

@Component({
    selector: 'argos-modelMetaDataEdit',
    templateUrl: './modelMetaDataEdit.component.html',
    providers: [ModelMetaDataEditService]
})

export class ModelMetaDataEditComponent extends ReactComponentBase<{}, IModelMetaDataEditStates, {}> implements OnInit {
    constructor(private svc: ModelMetaDataEditService) {
        super({
            trainingSourceOptions: ['QE', 'Iguana'],
            predictionSourceOptions: ['Cigna', 'QE', 'Humana'],
            // multi select
            trainYearOptions: ['2014', '2015', '2016', '2017', '2018', '2019q1', '2019q2', '2019q3', '2019q4'],
            // multi select
            predictYearOptions: ['2014', '2015', '2016', '2017', '2018', '2019q1', '2019q2', '2019q3', '2019q4'],


            ccgTypeOptions: ['patient_year', 'episodic'],
            predictionTypeOptions: ['retrospective', 'prospective', 'predictive'],
            featureOptions: ['standard_tco_concurrent', 'standard_tco_non_concurrent', 'standard_exploratory'],
            // can be none
            additionalFeatureOptions: ['standard set of tco concurrent', 'standard set of tco non-concurrent', 'standard set of exploratory'],
            episodeProgramNameOptions: ['Clarify Exploratory', 'Clarify Exploratory-Outpatient'],
            episodeCcgsOptions: ['standard_ip_ccgs', 'standard_neuro_ccgs', 'standard_op_ccgs'],
            // can be none
            additionalEpisodeCcgsOptions: [],
            ccgNameOptions: ['Patient Year', 'Rolling Patient Year', 'Monthly Patient Year'],

            // relation object
            attributesAreOptions: ['pcp', 'sbdoh', 'concurrent', 'utilizer_only'],
            modelTypeOptions: ['glm', 'rf', 'xgboost', 'matching_median', 'matching_mea'],
            prePostFunctionOptions: ['ln', 'exp', 'sqrt', 'pow_2'],
            sqlFilterConditionOptions: ['is', 'is not', '==', '>', '>=', '<=', '<', '!='],

            modelMetaDataObject: {
                trainYears: [],
                predictYears: [],
                additionalFeatures: [],
                additionalEpisodeCcgs: [],
                propertyErrors: []
            }
        } as unknown as IModelMetaDataEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
    }

    errorExists(row: any, propertyName: any) {
        let result;
        if (row && row.propertyErrors) {
            result = row.propertyErrors.map(function (i: any) { return i.field; }).indexOf(propertyName) > -1;
        }
        return result;
    }

    addDependentVariableMetaData() {
        this.svc.addDependentVariableMetaDataHandler(this.states);
    }

    getErrorInDependentModel(row: any) {
        this.svc.getErrorInDependentModelHandler(row);
    }

    addDependentVariableMetaDataSqlFilterOption(row: any) {
        this.svc.addDependentVariableMetaDataSqlFilterOptionHandler(row);
    }

    removeDependentVariableMetaData(row: any, index: number) {
        this.svc.removeDependentVariableMetaDataHandler(row, index, this.states);
    }

    save() {
        this.svc.saveHandler(this.states);
    }

    isValid() {
        return this.svc.isValidHandler(this.states);
    }

    selectedFilterChange(selectedFilter: any, event: any) {
        // TODO
        selectedFilter = event;
    }
}
