import swal from 'sweetalert2';
import * as _ from 'lodash';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MD5Service } from '../../../services/md5.service';
import { Component, ChangeDetectorRef, OnInit, Inject } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClarifyInsightsDataEvaluationUserProvisionModalService } from './clarifyInsightsDataEvaluationUserProvisionModal.component.service';
import { IClarifyInsightsDataEvaluationProvisionModalUser } from './clarifyInsightsDataEvaluationUserProvisionModal.component.d';


@Component({
    templateUrl: './clarifyInsightsDataEvaluationUserProvisionModal.component.html',
    providers: [ClarifyInsightsDataEvaluationUserProvisionModalService]
})

export class ClarifyInsightsDataEvaluationUserProvisionModalComponent extends ReactComponentBase<{}, IClarifyInsightsDataEvaluationProvisionModalUser, {}>  implements OnInit{

    userForm: FormGroup;
    constructor(private svc: ClarifyInsightsDataEvaluationUserProvisionModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
    public dialogRef: MatDialogRef<ClarifyInsightsDataEvaluationUserProvisionModalComponent>, private md5: MD5Service){
        super({
            emailError: false,
            emailErrorMessage: null,
            firstNameError: false,
            firstNameErrorMessage: null,
            lastNameError: false,
            lastNameErrorMessage: null,
            userDurationError: false,
            userDurationErrorMessage: null,
            roles: dialogData.props.roles,
            rolesError: false,
            rolesErrorMessage: null,
            password: null,
            showPassword: false
        });
        this.userForm = new FormGroup({
            email: new FormControl({value: '', disabled: false}, [Validators.required, Validators.email]),
            firstName: new FormControl({value: '', disabled: false}, [Validators.required]),
            lastName: new FormControl({value: '', disabled: false}, [Validators.required]),
            userDuration: new FormControl({value: '', disabled: false}, [Validators.required, Validators.min(0)]),
            roles: new FormControl({value: [], disabled: false}, [Validators.required])
        },
        {validators: this.rolesValidation});
    }

    async ngOnInit() {
        this.cdr.detectChanges();
    }

    close() { 
        this.dialogRef.close();
    }

    async submit() { 
        let valid = this.validateInputForm();
        if (valid) {
            let createUserResponse: any;
            let password: string;
            const roleIds = _.map(_.filter(this.states.roles, (role) => this.userForm.controls.roles.value.includes(role.name)), (i) => i.id);
            let userDuration = this.isInternalUser(this.userForm.controls.email.value) ? null : this.userForm.controls.userDuration.value;
            let userRoles = this.isInternalUser(this.userForm.controls.email.value) ? [3, 5] : roleIds;
            swal({
                title: `Are you sure you want to proceed with creating a user for ${this.userForm.controls.email.value}?`,
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#57C84D', 
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    let seed = new Date().toDateString() + (Math.random() * 1000000000000).toString();
                    password = this.md5.md5(seed);
                    createUserResponse = await this.svc.submit(
                        this.userForm.controls.email.value, 
                        this.userForm.controls.firstName.value, 
                        this.userForm.controls.lastName.value, 
                        userDuration, 
                        userRoles,
                        password
                    )
                } else {
                    this.states.password = null;
                }
                if (createUserResponse.success) {
                    this.userForm.controls.email.disable();
                    this.userForm.controls.firstName.disable();
                    this.userForm.controls.lastName.disable();
                    this.userForm.controls.userDuration.disable();
                    this.userForm.controls.roles.disable();
                    await swal({
                        title: `Successful created user for ${this.userForm.controls.email.value}?`,
                        type: 'success',
                        confirmButtonColor: '#57C84D', 
                        confirmButtonText: 'Ok'
                    });
                    this.states.password = password;
                }
                else {
                    this.states.password = null;
                    await swal({
                        title: `There was an error creating user for ${this.userForm.controls.email.value}: ${createUserResponse.message}`,
                        type: 'error',
                        confirmButtonColor: '#57C84D', 
                        confirmButtonText: 'Ok'
                    });
                }
            });

        }
        else {
            this.states.password = null;
        }
    }

    isInternalUser(email: string) {
        if (email && email.endsWith("@clarifyhealth.com")) {
            return true;
        }
        else { 
            return false
        }
    }

    validateInputForm() {
        this.states.emailError = false;
        this.states.firstNameError = false;
        this.states.lastNameError = false;
        this.states.userDurationError = false;
        this.states.rolesError = false;
        if (this.userForm.status == 'INVALID') {
            if (this.userForm.controls.email.errors && 'required' in this.userForm.controls.email.errors) {
                this.states.emailError = true;
                this.states.emailErrorMessage = 'Email is required';
            }
            else if (this.userForm.controls.email.errors && 'email' in this.userForm.controls.email.errors) {
                this.states.emailError = true;
                this.states.emailErrorMessage = 'Please enter a valid email';
            }
            if (this.userForm.controls.firstName.errors && 'required' in this.userForm.controls.firstName.errors) {
                this.states.firstNameError = true;
                this.states.firstNameErrorMessage = 'First name is required';
            }
            if (this.userForm.controls.lastName.errors && 'required' in this.userForm.controls.lastName.errors) {
                this.states.lastNameError = true;
                this.states.lastNameErrorMessage = 'Last name is required';
            }
            if (this.userForm.controls.userDuration.errors && 'required' in this.userForm.controls.userDuration.errors) {
                this.states.userDurationError = true;
                this.states.userDurationErrorMessage = 'Account duration is required';
            }
            else if (this.userForm.controls.userDuration.errors && 'min' in this.userForm.controls.userDuration.errors) {
                this.states.userDurationError = true;
                this.states.userDurationErrorMessage = 'Account duration cannot be negative';
            }
            if (this.userForm.controls.roles.errors && 'required' in this.userForm.controls.roles.errors) {
                this.states.rolesError = true;
                this.states.rolesErrorMessage = 'At least 1 role is required';
            }
            else if (this.userForm.errors && 'missing_clarify_external' in this.userForm.errors) {
                this.states.rolesError = true;
                this.states.rolesErrorMessage = "External users must be assigned the Clarify External role";
            }
            else if (this.userForm.errors && 'missing_database_selection' in this.userForm.errors) {
                this.states.rolesError = true;
                this.states.rolesErrorMessage = "Users must be provided permission to at least one database";
            }
            return false;
        }
        else {
            return true;
        }
    }

    rolesValidation(ac: AbstractControl): ValidationErrors | null {
        if (ac.value.email && !ac.value.email.endsWith("@clarifyhealth.com") && ac.value.roles && !ac.value.roles.includes('Clarify External')){
            return {'missing_clarify_external': true};
        }
        else if (ac.value.email && !ac.value.email.endsWith("@clarifyhealth.com") && ac.value.roles && ac.value.roles.length == 1) {
            return {'missing_database_selection': true}
        }
        else {
            return null;
        }
    }

    showPassword() {
        this.states.showPassword = true;
    }

    disableFieldsInternalUsers(event: any) {
        if (this.isInternalUser(event.target.value)) {
            this.userForm.controls.userDuration.disable();
            this.userForm.controls.roles.disable();
        }
        else {
            this.userForm.controls.userDuration.enable();
            this.userForm.controls.roles.enable();
        }
    }

}
