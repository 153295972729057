import { Injectable } from '@angular/core';
import { IViewHistoryModalProps, IViewHistoryModalStates, IViewHistoryModalService } from './viewHistoryModal.component.d';
import * as _ from 'lodash';
@Injectable()
export class ViewHistoryModalService implements IViewHistoryModalService {
    initDelegate(props: IViewHistoryModalProps, states: IViewHistoryModalStates): object {
        states.observation = props.observation;
        states.lodash = _;
        return {};
    }

    changeDelegate(oldProps: IViewHistoryModalProps, newProps: IViewHistoryModalProps, states: IViewHistoryModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    viewDiffsHandler(version: any, states: IViewHistoryModalStates) {
        states.showDiffs = true;
        states.compareHistory = [];
        states.currentVersion = ('(v' + version + ')');
        states.previousVersion = '';
        const currentRow: any = _.find(states.observation.observationHistories, { version });
        let previousRow: any = {};
        if (currentRow) {
            if (version > 1) {
                const previousVersion = version - 1;
                states.previousVersion = ('(v' + previousVersion + ')');
                previousRow = _.find(states.observation.observationHistories, { version: previousVersion });
            }
        }
        states.compareHistory = _.map(_.keys(currentRow.definition), function (key) {
            const previousValue = _.get(previousRow, 'definition.' + key);
            const currentValue = _.get(currentRow, 'definition.' + key);
            return {
                key: _.startCase(key),
                previousValue: typeof previousValue === 'object' ? JSON.stringify(previousValue) : previousValue,
                currentValue: typeof currentValue === 'object' ? JSON.stringify(currentValue) : currentValue
            };
        });
    }
}
