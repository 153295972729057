import { Component } from '@angular/core';

@Component({
    selector: 'argos-referenceListItems',
    template: `<ui-view></ui-view>`
})

export class ReferenceListItemsComponent {

}
