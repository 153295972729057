import * as _ from 'lodash';
import swal from 'sweetalert2';
import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ClarifyInsightsDataEvaluationUserService } from './clarifyInsightsDataEvaluationUser.component.service';
import { IClarifyInsightsDataEvaluationUserDisplay } from './clarifyInsightsDataEvaluationUser.component.d';
import { ClarifyInsightsDataEvaluationUserProvisionModalComponent } from '../clarifyInsightsDataEvaluationUserProvisionModal/clarifyInsightsDataEvaluationUserProvisionModal.component';
import { ClarifyInsightsDataEvaluationUserPasswordResetModalComponent } from '../clarifyInsightsDataEvaluationUserPasswordResetModal/clarifyInsightsDataEvaluationUserPasswordResetModal.component';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';


@Component({
    templateUrl: './clarifyInsightsDataEvaluationUser.component.html',
    providers: [ClarifyInsightsDataEvaluationUserService]
})

export class ClarifyInsightsDataEvaluationUserComponent extends ReactComponentBase<{}, IClarifyInsightsDataEvaluationUserDisplay, {}> implements OnInit, AfterViewInit  {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['firstName', 'lastName', 'roles', 'username', 'email', 'active', 'expirationDate', 'editActiveStatus', 'editExpiration', 'editPassword'];
    totalLength: number;
    pageSize: number = 20;
    pageEvent: any;
    dataSource: MatTableDataSource<any>;


    constructor(private svc: ClarifyInsightsDataEvaluationUserService, private cdr: ChangeDetectorRef, private matDialog: MatDialog) {
        super();
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.dataSource = new MatTableDataSource(this.states.users);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.totalLength = this.states.users.length;
        this.dataSource.sortingDataAccessor = (item, property) => {
            if (property === 'firstName') {
                return item.first_name;
              } else if (property === 'lastName') {
                return item.last_name;
            }
            return item[property];
          };
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        if (this.dataSource) {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = (item, property) => {
                if (property === 'firstName') {
                  return item.first_name;
                } else if (property === 'lastName') {
                    return item.last_name;
                  }
                 else {
                  return item[property];
                }
              };
        }
    }

    async provisionUser() {
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ClarifyInsightsDataEvaluationUserProvisionModalComponent, {
            data: {
                props: {
                    roles: this.states.roles
                }
            }
        });
        modalInstance.afterClosed().subscribe(async () => {
            await this.svc.init(this.states);
            this.cdr.detectChanges();
        });
    }

    displayRoles(roles: [any]) {
        let userRoles = new Array();
        _.forEach(roles, (role) => {
            userRoles.push(role.name);
        })
        return userRoles.join(", ");
    }

    isInternalUser(email: string) {
        if (email && email.endsWith("@clarifyhealth.com")) {
            return true;
        }
        else { 
            return false
        }
    }

    async disableUser(row: any) {
        await swal({
            title: `Are you sure you want to disable ${row.username}?`,
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#57C84D', 
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel'
        }).then(async (response) => {
            if(response.value) {
                await this.svc.disableUser(row);
                await swal({
                    title: 'Successfully modified user',
                    type: 'info'
                });
            }
        })
        await this.ngOnInit();
    }

    async enableUser(row: any) {
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ClarifyInsightsDataEvaluationUserPasswordResetModalComponent, {
            data: {
                props: {
                    id: row.id,
                    username: row.username
                }
            }
        });
        modalInstance.afterClosed().subscribe(async () => {
            await this.svc.init(this.states);
            this.cdr.detectChanges();
        });
    }

    async editExpiration(row: any, event: any) {
        const newExpiration = event.value;
        if (newExpiration < new Date()) {
            await swal({
                title: 'Cannot set expiration date cannot be in the past',
                type: 'error'
            })
        }
        else {
            await swal({
                title: `Are you sure you want to update the expiration for ${row.username} to ${event.value.toLocaleDateString()}?`,
                type: 'question',
                cancelButtonText: 'Cancel',
                showCancelButton: true
            }).then(async (response) => {
                if (response.value) {
                    await this.svc.editExpiration(row, newExpiration);
                    await this.ngOnInit();
                }
            })
            await this.ngOnInit();
            await swal({
                title: 'Successfully modified user',
                type: 'info'
            })
        }
    }

}
