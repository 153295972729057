import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ISalesforceSyncStatusStates, ISalesforceSyncStatusService } from './salesforceSyncStatus.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { SalesforceSyncModalComponent } from '../salesforceSyncModal/salesforceSyncModal.component';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
//import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class SalesforceSyncStatusService implements ISalesforceSyncStatusService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private exportToExcelSvc: ExportToExcelService, private cdr: ChangeDetectorRef) {
        //
    }

    async initDelegate(states: ISalesforceSyncStatusStates): Promise<object> {
        states.moment = moment;
        await this.queryStatus(states);
        return {};
    }

    async queryStatus(states: ISalesforceSyncStatusStates) {
        states.salesforceSyncStatuses = await this.dataAccess.genericFind({
            model: 'SalesforceSyncLog',
            filter: {
                order: 'startDate desc'
            }
        });

        states.table.data = states.salesforceSyncStatuses;
    }

    async startContactSync(states: ISalesforceSyncStatusStates) {
        await this.dataAccess.genericMethod({
            model: 'SalesforceSyncLog', method: 'startContactSync', parameters: {

            }
        });
        await this.queryStatus(states);
    }

    async startAccountSync(states: ISalesforceSyncStatusStates) {
        await this.dataAccess.genericMethod({
            model: 'SalesforceSyncLog', method: 'startAccountSync', parameters: {

            }
        });
        await this.queryStatus(states);
    }

    viewLog(salesforceSyncLog: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    logText: salesforceSyncLog.logText
                }
            }
        };
        this.matDialog.open(SalesforceSyncModalComponent, dialogConfig);
    }

    setTablePageSize(states: ISalesforceSyncStatusStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.salesforceSyncStatuses.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: ISalesforceSyncStatusStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;
        const filteredColumns = columns.filter(function(x) { return x.toLowerCase().indexOf("button") === -1 })

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, filteredColumns, data);
    }

    openMatTableSettings(states: ISalesforceSyncStatusStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
