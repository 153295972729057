import { Injectable } from '@angular/core';
import { IPatchManagementPreviewModalProps, IPatchManagementPreviewModalStates, IPatchManagementPreviewModalService } from './patchManagementPreviewModal.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from 'client/app/services/dataAccess.service';

@Injectable()
export class PatchManagementPreviewModalService implements IPatchManagementPreviewModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(props: IPatchManagementPreviewModalProps, states: IPatchManagementPreviewModalStates): Promise<object> {
        await this.init(states, props);
        return {};
    }
    changeDelegate(oldProps: IPatchManagementPreviewModalProps, newProps: IPatchManagementPreviewModalProps, states: IPatchManagementPreviewModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }
    async init(states: IPatchManagementPreviewModalStates, props: IPatchManagementPreviewModalProps) {
        states.content = props.content;
        states.envNames = await this.getEnvNames(states, props);
        states.filesPreviewModalTable = _.cloneDeep(this.formatTable(props.content.selectedFiles));
        return states;
    }

    async getEnvNames(states: IPatchManagementPreviewModalStates, props: IPatchManagementPreviewModalProps) {

        const envNames = await this.dataAccess.genericFind({
            model: 'Environment',
            filter: {
                order: 'name asc',
                where: {
                    id: { inq: _.map(states.content?.environments, 'environmentId') }
                },
                fields: ['name', 'dnsCname', 'domainSuffix', 'id', 'clientId']
            }
        });

        _.forEach(envNames, function (e) {
            e.url = `${e.dnsCname}.clarifyhealth.${e.domainSuffix}`
        });

        return envNames;
    }

    formatTable(files: any): any[] {
        const formattedTable: any = [];
        for (const [key, value] of Object.entries(files)) {
            formattedTable.push({ metaDataType: _.startCase(key), selectedFileNames: value });
        }
        return formattedTable;
    }

}
