import { Component } from '@angular/core';

@Component({
    selector: 'argos-clarifyReports',
    template: `<ui-view></ui-view>`
})

export class ClarifyReportsComponent {

}
