import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ICcgDefinitionStates } from './ccgDefinition.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CcgDefinitionService } from './ccgDefinition.component.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnalyzeMetaDataModalComponent } from '../analyzeMetaDataModal/analyzeMetaDataModal.component';
import * as _ from 'lodash';
import { ViewListModalComponent } from '../viewListModal/viewListModal.component';

@Component({
    selector: 'argos-ccgDefinition',
    templateUrl: './ccgDefinition.component.html',
    providers: [CcgDefinitionService]
})

export class CcgDefinitionComponent extends ReactComponentBase<{}, ICcgDefinitionStates, {}> implements OnInit {
    constructor(private svc: CcgDefinitionService, private matDialog: MatDialog,
        private cdr: ChangeDetectorRef) {
        super({
            data: {
                ccg: {}
            },
            saving: false
        } as ICcgDefinitionStates);
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.states);
        this.setStates(newStates);
        this.states.selectedProviderType = _.orderBy(this.states.data.providerTypeObservationMappings, ['sortOrder', 'name'])[0];
        this.cdr.detectChanges();
    }

    analyzeMetaData(quickAnalysis = false) {
        const dialogConfig: MatDialogConfig = {
            panelClass: (quickAnalysis ? 'argos-modal-full-height' : 'modal-full'),
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    metaData: this.states.data?.ccg?.metaData,
                    quickAnalysis
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            AnalyzeMetaDataModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(($event: any) => {
            if ($event) {
                // states.careGrouping = $event;
            }
        });
    }

    async save() {
        const selectedProviderType = this.states.selectedProviderType;
        const newStates = await this.svc.saveHandler(this.states);
        this.setStates(newStates);
        this.states.selectedProviderType = _.find(this.states.data.providerTypeObservationMappings, { name: _.get(selectedProviderType, 'name') }) ||
            _.orderBy(this.states.data.providerTypeObservationMappings, ['sortOrder', 'name'])[0];
        this.cdr.detectChanges();
    }

    createMetaData() {
        this.svc.createMetaDataHandler(this.states);
    }

    sourceSchemaChanged() {
        this.svc.sourceSchemaChangedHandler(this.states);
    }

    getObservationMapping(ignoreThisObservation: any) {
        let result: any[] = [];

        if (this.states.selectedProviderType) {
            const pTOMap: any = _.find(this.states.data.providerTypeObservationMappings, { name: this.states.selectedProviderType.name });

            if (pTOMap && pTOMap.observationMappings) {
                _.forEach(pTOMap.observationMappings, (om: any) => {
                    if (!ignoreThisObservation || om.observation.name !== ignoreThisObservation) {
                        result.push(om)
                    }
                });
            }

            result = _.orderBy(result, ['sortOrder', 'observation.name']);
        }

        return result;
    }

    deleteProviderType() {
        _.remove(this.states.data.providerTypeObservationMappings, { id: this.states.selectedProviderType.id });
        this.states.selectedProviderType = 0;
        delete this.states.selectedProviderType;
        this.svc.getSelectedObjects(this.states);
        this.states.selectedProviderType = _.orderBy(this.states.data.providerTypeObservationMappings, ['sortOrder', 'name'])[0];
    }

    deleteObservationMapping(observationId: any) {
        const providerType: any = _.find(this.states.data.providerTypeObservationMappings, { id: this.states.selectedProviderType.id });
        _.remove(providerType.observationMappings, { observationId });
        this.svc.getSelectedObjects(this.states);
        this.cdr.detectChanges();
    }

    addObservationMapping() {
        const states = this.states;
        const providerTypeId = this.states.selectedProviderType.id;
        const providerType: any = _.find(this.states.data.providerTypeObservationMappings, { id: providerTypeId });

        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    rows: _.filter(states.data.observations, function (o) { return !_.includes(_.map(providerType.observationMappings, 'observationId'), o.id); }),
                    columns: [
                        { dataId: 'selected', title: 'Selected' },
                        { dataId: 'name', title: 'Name' },
                        { dataId: 'title', title: 'Title' },
                        { dataId: 'denominatorObservationName', title: 'Denominator Observation Name' },
                        { dataId: 'units', title: 'Units' }
                    ],
                    modalTitle: 'Observations'
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewListModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((selectedObservations: any) => {
            if (selectedObservations) {
                const providerType: any = _.find(states.data.providerTypeObservationMappings, { id: providerTypeId });
                _.forEach(selectedObservations, function (observation) {
                    if (!_.find(providerType.observationMappings, { observationId: observation.id })) {
                        const observationMapping = {
                            providerTypeId,
                            observationId: observation.id,
                            observation
                        };
                        providerType.observationMappings.push(observationMapping);
                    }
                });
                states.data.observations = _.map(states.data.observations, function (o: any) { return _.omit(o, 'selected'); });
                this.svc.getSelectedObjects(states);
            }
            this.cdr.detectChanges();
        });
    }

    addProviderType() {
        const states = this.states;
        let data = _.filter(states.data.providerTypes, function (o) { return !_.includes(_.map(states.data.providerTypeObservationMappings, 'id'), o.id); });
        data = _.map(data, p => _.extend({ selected: false }, p));

        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    rows: data,
                    columns: [
                        { dataId: 'selected', title: 'Selected' },
                        { dataId: 'name', title: 'Name' },
                        { dataId: 'npiColumn', title: 'NPI Column' },
                        { dataId: 'sourceTable', title: 'Source Table' },
                        { dataId: 'tableFilter', title: 'Table Filter' },
                        { dataId: 'observationReplaceFrom', title: 'Observation Replace From' },
                        { dataId: 'observationReplaceTo', title: 'Observation Replace To' }
                    ],
                    modalTitle: 'Provider Types'
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewListModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((selectedProviderTypes: any) => {
            if (selectedProviderTypes) {
                _.forEach(selectedProviderTypes, function (providerType) {
                    providerType.observationMappings = [];
                    states.data.providerTypeObservationMappings.push(providerType);
                });
                console.log('states.data.providerTypes', states.data.providerTypes);
                states.data.providerTypes = _.map(states.data.providerTypes, function (pt: any) { return _.omit(pt, 'selected'); });
                this.svc.getSelectedObjects(states);
                this.cdr.detectChanges();
            }
        });
    }
}
