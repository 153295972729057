import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { IQApiPerformanceProductDetailStates } from './apiPerformanceTestResultsDashboardProductsDetail.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { QAApiPerformanceResultDetailService } from './apiPerformanceTestResultsDashboardProductsDetail.component.service';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { CategoryScale, Chart, ChartData, ChartOptions, LinearScale, LineController, LineElement, Legend, Title, Tooltip, SubTitle, PointElement } from 'chart.js';
import { ChartService } from '../../../services/chartService.service';
import { MatDialog } from '@angular/material/dialog';

function registerChartComponents() {
    Chart.register(CategoryScale);
    Chart.register(LinearScale);
    Chart.register(LineController);
    Chart.register(LineElement);
    Chart.register(Legend);
    Chart.register(Title);
    Chart.register(Tooltip);
    Chart.register(SubTitle);
    Chart.register(PointElement);
}
registerChartComponents();

@Component({
    selector: 'argos-qaPerformanceResultDetail',
    templateUrl: './apiPerformanceTestResultsDashboardProductsDetail.component.html',
    providers: [QAApiPerformanceResultDetailService],
    styleUrls: ['../testResultsDashboard/qaTestResultDashboard.component.less']
})

export class ApiPerformanceTestResultsDashboardProductsDetail extends ReactComponentBase<{}, IQApiPerformanceProductDetailStates, {}> implements OnInit {
    // chart
    @ViewChild('doubleLineCanvas') doubleLineCanvas: ElementRef | undefined;
    doubleLineChart: any;

    // chart
    @ViewChild('doubleLineCanvas2') doubleLineCanvas2: ElementRef | undefined;
    doubleLineChart2: any;

    constructor(private svc: QAApiPerformanceResultDetailService, private cdr: ChangeDetectorRef, private chartSvc: ChartService, private matDialog: MatDialog) {
        super({
            apiPerformanceTestResults: [],
            apiPerformanceTestDataTable: new MatTableDataSource([]),
            apiPerformanceTestUncachedDataTable: new MatTableDataSource([]),
            apiChartSettings: {
                colors: ['#45b7cd', '#00ADF9', '#FF5252', '#631717']
            },
            apiChartSettings2: {
                colors: ['#45b7cd', '#00ADF9', '#FF5252', '#631717']
            },
        } as IQApiPerformanceProductDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges;

        this.createApiPerformanceCachedResultChart(this.states.apiPerformanceTestResults);

        if (this.states.apiPerformanceTestUncachedDataTable.data.length) {
            this.createApiPerformanceUncachedResultChart(this.states.apiPerformanceTestResults);
        }
    }

    async createApiPerformanceCachedResultChart(data: any) {
        // Getting unique sorted dates for runs
        const mappedTestRanAt = _(data)
            .filter((dt: any) => dt.release_name !== '')
            .map((dt: any) => _.assign(dt, { release_name: new Date(dt.release_name).toLocaleDateString('es-pa') }))
            .value();

        const chartLabelTestRanAt = _(mappedTestRanAt)
            .uniqBy('release_name')
            .map((d: any) => d.release_name)
            .orderBy([(date) => {
                return new Date(date);
            }],['asc'])
            .value();

        // Group by dashboard name
        const groupedPageName = _.groupBy(data, x => [x.dashboard_name, x.tab_name]);

        const apiPerformanceDataset = [] as any;
        const groupedPageNameKeys = Object.keys(groupedPageName);

        for (let i = 0; i < groupedPageNameKeys.length; i++) {
            const legendLabel = groupedPageNameKeys[i].split(',')[0] + ' (' + groupedPageNameKeys[i].split(',')[1] + ')';
            const currentKey = groupedPageNameKeys[i];
            const performanceData: any[] = groupedPageName[currentKey];
            const uniqPerformanceData = _.uniqBy(performanceData, 'release_name');
            const lineColor = this.chartSvc.getRandomColor();
            const xAxisData: any[] = [];

            for (let j = 0; j < chartLabelTestRanAt.length; j++) {
                const containsDataByTestRanAt = uniqPerformanceData.find(e => e.release_name === chartLabelTestRanAt[j]);
                if (containsDataByTestRanAt) {
                    xAxisData.push(containsDataByTestRanAt.cached_load_time);
                } else {
                    xAxisData.push(null);
                }
            }

            apiPerformanceDataset.push({
                label: legendLabel,
                data: xAxisData,
                backgroundColor: lineColor,
                borderColor: lineColor,
                hoverBorderWidth: 15,
                hoverBorderColor: lineColor,
                fill: false
            });
        }

        if (this.doubleLineChart) {
            this.doubleLineChart.destroy();
        }

        this.states.apiChartSettings = {
            type: 'line',
            data: {
                labels: chartLabelTestRanAt,
                datasets: apiPerformanceDataset,
            },
            options: {
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        labels: {
                            filter(legendItem: any, chartData: any) {
                                if (chartData.datasets[legendItem.datasetIndex].label === chartData.datasets[legendItem.datasetIndex - 1]?.label) {
                                    return false;
                                }
                                return true;
                            }
                        }

                    },
                },

            }

        };


        this.doubleLineChart = new Chart(this.doubleLineCanvas?.nativeElement, this.states.apiChartSettings);
    }

    async createApiPerformanceUncachedResultChart(data: any) {
        // Getting unique sorted dates for runs
        const mappedTestRanAt = _.map(data, (dt: any) => {
            return _.assign(dt, { release_name: new Date(dt.release_name).toLocaleDateString('es-pa') });
        });
        const chartLabelTestRanAt = _.uniqBy(mappedTestRanAt, 'release_name').map(d => d.release_name).sort();

        // Group by dashboard name
        const groupedPageName = _.groupBy(data, x => [x.dashboard_name, x.tab_name]);

        const apiPerformanceDataset = [] as any;
        const groupedPageNameKeys = Object.keys(groupedPageName);

        for (let i = 0; i < groupedPageNameKeys.length; i++) {
            const legendLabel = groupedPageNameKeys[i].split(',')[0] + ' (' + groupedPageNameKeys[i].split(',')[1] + ')';
            const currentKey = groupedPageNameKeys[i];
            const performanceData: any[] = groupedPageName[currentKey];
            const uniqPerformanceData = _.uniqBy(performanceData, 'release_name');
            const lineColor = this.chartSvc.getRandomColor();
            const xAxisData: any[] = [];

            for (let j = 0; j < chartLabelTestRanAt.length; j++) {
                const containsDataByTestRanAt = uniqPerformanceData.find(e => e.release_name === chartLabelTestRanAt[j]);
                if (containsDataByTestRanAt) {
                    xAxisData.push(containsDataByTestRanAt.uncached_load_time);
                } else {
                    xAxisData.push(null);
                }
            }

            apiPerformanceDataset.push({
                label: legendLabel,
                data: xAxisData,
                backgroundColor: lineColor,
                borderColor: lineColor,
                hoverBorderWidth: 15,
                hoverBorderColor: lineColor,
                fill: false
            });
        }

        if (this.doubleLineChart2) {
            this.doubleLineChart2.destroy();
        }

        this.states.apiChartSettings2 = {
            type: 'line',
            data: {
                labels: chartLabelTestRanAt,
                datasets: apiPerformanceDataset,
            },
            options: {
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        labels: {
                            filter(legendItem: any, chartData: any) {
                                if (chartData.datasets[legendItem.datasetIndex].label === chartData.datasets[legendItem.datasetIndex - 1]?.label) {
                                    return false;
                                }
                                return true;
                            }
                        }

                    },
                },

            }

        };

        this.doubleLineChart2 = new Chart(this.doubleLineCanvas2?.nativeElement, this.states.apiChartSettings2);
    }

    viewAllApiPerformanceTestResults() {
        this.svc.viewAllApiPerformanceTestResultsHnadler(this.states);
    }
    
}
