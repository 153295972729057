import { StateService } from '@uirouter/core';
import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EcsAppShare } from './ecsAppShareHandler.service';
import { EcsAppPatch } from './ecsAppPatchHandler.service';

@Injectable()
export class EcsAppEvent {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private ecsAppShareHandler: EcsAppShare, private ecsAppPatchHandler: EcsAppPatch, private state: StateService) {
        //
    }

    async submitEnvChanges(runUpgradeTask: boolean, container: any, states: IEcsAppEditStates) {

        if (!states.cluster.webServiceName) {
            swal({
                title: 'Sorry we cant submit changes',
                text: 'Argos is unable to determine the correct ECS service name\nPlease alert DevOps',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                //
            });
        } else if (states.cluster.errorMessage.indexOf('No worker defintion found for ' + states.cluster.workerTaskName + ' found') >= 0) {
            swal({
                title: 'Sorry we cant submit changes',
                text: 'No worker defintion found',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                //
            });
        } else if (this.getInvalidEnvironmentVarsByPermission(container, states).length > 0) {
            const invalidVars = this.getInvalidEnvironmentVarsByPermission(container, states);
            swal({
                title: 'Missing Environment Variables for IAM Role Permissions',
                text: invalidVars.map(function (v) { return v.name + '=' + v.value; }).join('\n'),
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                //
            });
        } else {
            swal({
                title: 'Really?',
                text: 'Confirm you want to push changes',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm: any) => {
                if (!isConfirm.dismiss) {
                    await this.pushChanges(runUpgradeTask, container, states);
                }
            });
        }   // else
    }


    getInvalidEnvironmentVarsByPermission(container: any, states: IEcsAppEditStates) {
        const result: any[] = [];
        let taskDefinitions = this.ecsAppShareHandler.getContainerDefinitions(container, '-task', states);
        taskDefinitions = this.ecsAppShareHandler.excludeTaskDefContainerDefintion(taskDefinitions);

        // only validate for prism 2.0
        if (states.cluster.newAwsAccessPermission === 'IAM Role' && taskDefinitions.length > 0 && states.repositoryName === 'clarify-platform') {
            const envs = taskDefinitions[0].environment;
            const requiredEnvs = [{ name: 'AWS_ATHENA_RESULTS_S3_BUCKET', value: 's3://bucket_name' }];

            if (states.cluster.accountName.toLowerCase() === 'cleardata') {
                requiredEnvs.push({ name: 'AWS_ASSUME_ROLE_ARN', value: 'full_arn_path' });
            }

            requiredEnvs.forEach(function (reqEnv) {
                if (!_.get(_.find(envs, { name: reqEnv.name }), 'name')) {
                    result.push(reqEnv);
                }
            });
        }

        return result;
    }


    setAwsPermission(taskDefs: any, states: IEcsAppEditStates) {

        const td = _.find(taskDefs, function (td) {
            if (td.name.indexOf('log_router') === -1) {
                return td;
            }
        });

        // when IAM role is set always remove athena secret to prevent issues in environment
        if (td && td.secrets && states.cluster.newAwsAccessPermission === 'IAM Role') {
            for (let x = 0; x < td.secrets.length; x++) {
                const s = td.secrets[x];
                if (s.name.toUpperCase() === 'ATHENA_SECRET') {
                    td.secrets.splice(x, 1);
                    states.eventChanges.useIamRolePermission = true;
                }
            }
        }
    }

    async pushChanges(runUpgradeTask: boolean, container: any, states: IEcsAppEditStates) {
        await this.saveHiddenEnvironmentVariableSelection(states);  // save admin hidden environment variables

        // dont use JSON.stringify use angular.toJson to strip out angular stuff
        const taskDefinition = this.ecsAppShareHandler.getTaskDefinition(container, '-task', states);
        const webResourceTags = this.ecsAppShareHandler.getEnvironmentTags(container, '-task', states);

        this.setAwsPermission(taskDefinition.containerDefinitions, states);

        if (states.currentBuildTag !== states.selectedBuildTag && states.repositoryName === 'clarify-platform') {
            await this.ecsAppPatchHandler.buildVersionChanged(states.cluster.environment.id, states.cluster.uiVersion, states.cluster.appliedPatches, states.patchListTable);
            states.selectedPatches = [];
        }

        if (_.size(states.selectedPatches)) {
            await this.ecsAppPatchHandler.updateEnvPatches(states.cluster.environment.name, states.cluster.environment.id, states.cluster.appliedPatches, states.selectedPatches);
        }

        let workerArgs: any;
        let webArgs: any = {
            clusterName: states.cluster.clusterName,
            serviceName: states.cluster.webServiceName,
            taskDefinition: taskDefinition.containerDefinitions,
            taskDefinitionName: states.cluster.webTaskName,
            requestedBy: this.argosStore.getItem('username'),
            buildTag: states.selectedBuildTag,
            resourceTags: webResourceTags,
            rollBackTaskDefRevisionNumber: taskDefinition.revision,
            awsAccountName: states.cluster.accountName,
            executionRoleArn: taskDefinition.executionRoleArn,
            taskRoleArn: taskDefinition.taskRoleArn
        };

        if (states.cluster.isFargateEnabled) {
            webArgs.taskMemory = taskDefinition.memory;
            webArgs.taskCpu = taskDefinition.cpu;
        }

        const updatePromises: any[] = [];

        updatePromises.push(this.dataAccess.genericMethod({
            model: 'Environment', method: 'createEcsTaskDefinition', parameters: webArgs
        }));

        // filter out any other things like logger so that we can flow changes down to other task def in other services
        taskDefinition.containerDefinitions = this.ecsAppShareHandler.excludeTaskDefContainerDefintion(taskDefinition.containerDefinitions);

        if (states.cluster.workerServiceName && states.cluster.workerTaskName) {

            const taskWorkerDefinition = this.ecsAppShareHandler.getTaskDefinition(container, '-worker-task', states);
            const workerResourceTags = this.ecsAppShareHandler.getEnvironmentTags(container, '-worker-task', states);
            this.setAwsPermission(taskWorkerDefinition.containerDefinitions, states);

            // get the reference to the worker container so we can update it
            const tempTaskWorkerDefs = this.ecsAppShareHandler.excludeTaskDefContainerDefintion(taskWorkerDefinition.containerDefinitions);
            const workerNodeOptions: any = _.find(tempTaskWorkerDefs[0].environment, { name: 'NODE_OPTIONS' });


            // we need to sync the web and worker environment variables so do it here
            // deep clone the web environment variables to the worker
            tempTaskWorkerDefs[0].environment = JSON.parse(JSON.stringify(_.filter(taskDefinition.containerDefinitions[0].environment, function (env) { return env.name !== 'NODE_OPTIONS'; })));
            //_.clone(taskDefinition.containerDefinitions[0].environment);

            workerArgs = {
                clusterName: states.cluster.clusterName,
                serviceName: states.cluster.workerServiceName,
                taskDefinition: taskWorkerDefinition.containerDefinitions,
                taskDefinitionName: states.cluster.workerTaskName,
                requestedBy: this.argosStore.getItem('username'),
                buildTag: states.selectedBuildTag,
                resourceTags: workerResourceTags,
                rollBackTaskDefRevisionNumber: 0,
                awsAccountName: states.cluster.accountName,
                executionRoleArn: taskWorkerDefinition.executionRoleArn,
                taskRoleArn: taskWorkerDefinition.taskRoleArn
            };

            if (states.cluster.isFargateEnabled) {
                workerArgs.taskMemory = taskWorkerDefinition.memory;
                workerArgs.taskCpu = taskWorkerDefinition.cpu;

                //NODE OPTIONS should not be carried from web taskDefinition because worker manages its own
                if (workerNodeOptions) {
                    tempTaskWorkerDefs[0].environment.push(workerNodeOptions);
                }
            }

            updatePromises.push(this.dataAccess.genericMethod({
                model: 'Environment', method: 'createEcsTaskDefinition', parameters: workerArgs
            }));
        }

        // confirm there is a upgrade task
        if (states.cluster.upgradeTaskName) {
            // now check if we need to skip running upgrade
            if (!runUpgradeTask) {
                console.log('upgrade skipped ' + states.cluster.upgradeTaskName);
            } else {
                const upgradeTaskDef = this.ecsAppShareHandler.getTaskDefinition(container, '-upgrade-task', states);
                this.setAwsPermission(upgradeTaskDef.containerDefinitions, states);

                const upgradeTaskArg: any = {
                    clusterName: states.cluster.clusterName,
                    taskDefinitionName: states.cluster.upgradeTaskName,
                    taskDefinition: upgradeTaskDef.containerDefinitions,
                    buildTag: states.selectedBuildTag,
                    awsAccountName: states.cluster.accountName,
                    requestedBy: this.argosStore.getItem('username'),
                    executionRoleArn: upgradeTaskDef.executionRoleArn,
                    taskRoleArn: upgradeTaskDef.taskRoleArn
                };

                if (states.selectedPatches.length > 0) {
                    upgradeTaskArg.dataPatches = _.orderBy(_.map(states.selectedPatches, 'id'), ['asc']).toString();                                 
                }

                // allow these values from the environment variables to flow into the upgrade task def environment variables
                this.ecsAppShareHandler.addUpgradeEnvironmentVariable(upgradeTaskDef.containerDefinitions, taskDefinition.containerDefinitions[0].environment, states.envVarsAllowedToFlowDownIntoNonWebAndWorkerTasks);

                updatePromises.push(this.dataAccess.genericMethod({
                    model: 'Environment', method: 'runEcsTaskDefinition', parameters: upgradeTaskArg
                }));
            }
        }

        if (states.cluster.npoUpgradeTaskName) {
            const npoTaskDef = this.ecsAppShareHandler.getTaskDefinition(container, '-npo-upgrade-task', states);
            this.setAwsPermission(npoTaskDef.containerDefinitions, states);

            const npoTaskArg = {
                clusterName: states.cluster.clusterName,
                taskDefinitionName: states.cluster.npoUpgradeTaskName,
                taskDefinition: npoTaskDef.containerDefinitions,
                buildTag: states.selectedBuildTag,
                awsAccountName: states.cluster.accountName,
                executionRoleArn: npoTaskDef.executionRoleArn,
                taskRoleArn: npoTaskDef.taskRoleArn
            };

            // allow these values from the environment variables to flow into the upgrade task def environment variables
            this.ecsAppShareHandler.addUpgradeEnvironmentVariable(npoTaskDef.containerDefinitions, taskDefinition.containerDefinitions[0].environment, states.envVarsAllowedToFlowDownIntoNonWebAndWorkerTasks);

            updatePromises.push(this.dataAccess.genericMethod({
                model: 'Environment', method: 'registerEcsTaskDef', parameters: npoTaskArg
            }));

        }

        Promise.all(updatePromises).then(async (result) => {
            // console.log('submitEnvChanges response is ' + JSON.stringify(result));
            let changeSuccessful: any = true;
            let errorMessage = '';

            result.forEach((r) => {
                if (!r.isSuccessful) {
                    changeSuccessful = false;
                    errorMessage += JSON.stringify(r.error.message) + '\n';
                }
                console.log('response is ' + JSON.stringify(r));
            });

            const response = result[0];
            let messageAlert = 'It takes about 5-10 minute to swap your new app';

            if (changeSuccessful === false) {
                messageAlert = 'Request failed: ' + errorMessage;
            } else {
                await this.saveWorkerEventChanges(container, workerArgs, states);
                await this.saveWebEventChanges(container, webArgs, states);
            }

            //alert user of refresh screen
            swal({
                title: 'Changes submitted. The screen will refresh now',
                text: messageAlert,
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => { 
                this.state.go('argos.ecsAccess.ecsAppEdit', { id: states.cluster.clusterName, accountname: states.cluster.accountName }, { reload: true });
            });
        });

        // save tags
        const result = await this.saveResourceTags(states);
        if (result.isSuccessful) {
            console.log('tags saved for target group and load balancer');
        } else {
            console.log('failed to save tags for target group and load balancer');
        }

    }

    isFailoverEnvironment(states: IEcsAppEditStates) {
        let result = false;

        if (states.cluster.environment.ecsParameters.isFailoverEnvironment && states.cluster.environment.ecsParameters.isFailoverEnvironment === 'true') {
            result = true;
        }

        return result;
    }

    runUpgradeTask(states: IEcsAppEditStates) {
        let result = false;
        let isFailoverEnv = this.isFailoverEnvironment(states);
        let patchedEnv = (states.selectedPatches.length > 0);
        let existingPatches = (!states.cluster.appliedPatches || states.cluster.appliedPatches.length === 0) ? false : true;
        let buildChanged = states.selectedBuildTag !== states.currentBuildTag;
        
        if (isFailoverEnv) {
            result = false;
        } else if (buildChanged) {  
            result = true;
        } else if (patchedEnv) {
            result = true;
        }

        return result;
    }

    async saveResourceTags(states: IEcsAppEditStates) {
        const resourceArns = [];

        // get all resourceArns to upsert
        for (let j = 0; j < states.cluster.targetGroupInfo.length; j++) {
            const tg = states.cluster.targetGroupInfo[0];

            resourceArns.push(tg.TargetGroupArn);
            // each tg can have many lbs
            for (let i = 0; i < tg.LoadBalancerArns.length; i++) {
                resourceArns.push(tg.LoadBalancerArns[i]);
            }
        }

        const tagArg = {
            resourceArnList: resourceArns,
            tags: states.ecsResourceTags,
            requestedBy: this.argosStore.getItem('username'),
            awsAccountName: states.cluster.accountName
        };

        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'upsertElbResourceTags', parameters: tagArg
        });

        return result;
    }

    async saveWebEventChanges(container: any, webArgs: any, states: IEcsAppEditStates) {
        let message: string[] = [];

        if (states.eventChanges.newMemory && states.eventChanges.newCpu) {
            message.push(' task size memory changed to ' + states.eventChanges.newMemory + ' and task cpu changed to ' + states.eventChanges.newCpu);
        }

        if (states.eventChanges.taskContainerDefinitions && states.eventChanges.taskContainerDefinitions[webArgs.taskDefinitionName] && states.eventChanges.taskContainerDefinitions[webArgs.taskDefinitionName].updatedNodeOptions) {
            message.push(`${webArgs.taskDefinitionName} NODE_OPTIONS was auto updated to ${states.eventChanges.taskContainerDefinitions[webArgs.taskDefinitionName].updatedNodeOptions.value}`);
        }

        if (states.eventChanges.envVariableChanges && Object.keys(states.eventChanges.envVariableChanges).length > 0) {
            for (const key in states.eventChanges.envVariableChanges) {
                message.push(key + ((states.eventChanges.envVariableChanges[key]) ? ' environment variable was created' : ' environment variable was deleted'));
            }
        }

        const activityLog: any = {
            clusterName: webArgs.clusterName,
            triggeredBy: this.argosStore.getItem('username'),
            awsAccount: states.cluster.accountName
        };

        // save environment changes
        if (message.length > 0) {

            states.eventChanges.serviceName = webArgs.serviceName;
            // update log properties
            activityLog.environmentAction = 'environment configuration change';
            activityLog.eventDetail = states.eventChanges;
            activityLog.message = activityLog.environmentAction + ': ' + message.join('. ');

            // do this last
            if (activityLog.eventDetail.rollbackTaskDefName && activityLog.eventDetail.rollbackTaskDefName !== states.cluster.taskGroups[0]) {
                // something is wrong here so remove rolback information
                delete activityLog.rollbackTaskDefNumber;
                delete activityLog.rollbackTaskDefName;
            }
            await this.dataAccess.genericCreate({
                model: 'EnvironmentActivityLog',
                data: activityLog
            });
        } else {
            // no changes made so user must of just rebooted environment
            // update log properties
            activityLog.environmentAction = 'environment configuration change';
            activityLog.eventDetail = states.eventChanges;
            activityLog.message = activityLog.environmentAction + ': ' + message.join('. ');

            await this.dataAccess.genericCreate({
                model: 'EnvironmentActivityLog',
                data: activityLog
            });

        }

    }

    async saveWorkerEventChanges(container: any, workerArgs: any, states: IEcsAppEditStates) {
        if (workerArgs) {
            let message: string[] = [];
            if (states.eventChanges.taskContainerDefinitions && states.eventChanges.taskContainerDefinitions[workerArgs.taskDefinitionName] && states.eventChanges.taskContainerDefinitions[workerArgs.taskDefinitionName].updatedNodeOptions) {
                message.push(`${workerArgs.taskDefinitionName} NODE_OPTIONS was auto updated to ${states.eventChanges.taskContainerDefinitions[workerArgs.taskDefinitionName].updatedNodeOptions.value}`);
                delete states.eventChanges.taskContainerDefinitions[workerArgs.taskDefinitionName];
            }

            if (message.length > 0) {
                const activityLog: any = {
                    environmentAction: 'environment configuration change',
                    eventDetail: {
                        serviceName: workerArgs.serviceName,
                        envVariableChanges: {}
                    },
                    message: `environment configuration change: ${message.join('. ')}`,
                    clusterName: workerArgs.clusterName,
                    triggeredBy: this.argosStore.getItem('username'),
                    awsAccount: states.cluster.accountName
                };

                await this.dataAccess.genericCreate({
                    model: 'EnvironmentActivityLog',
                    data: activityLog
                });
            }
        }
    }

    async saveHiddenEnvironmentVariableSelection(states: IEcsAppEditStates) {
        const envObj = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'findOne', parameters: {
                filter: { where: { id: states.cluster.environment.id } }
            }
        });
        if (envObj) {

            const removedHiddenVars = _.difference(envObj.hiddenEcsVariables, states.cluster.environment.hiddenEcsVariables);
            const hiddenVars = _.difference(states.cluster.environment.hiddenEcsVariables, envObj.hiddenEcsVariables);

            // make sure we have changes before saving anything
            if (removedHiddenVars.length > 0 || hiddenVars.length > 0) {

                envObj.hiddenEcsVariables = states.cluster.environment.hiddenEcsVariables;
                const eventDetail = {
                    removedHidden: removedHiddenVars,
                    hidden: hiddenVars
                };
                const activityLog = {
                    clusterName: states.cluster.clusterName,
                    environmentAction: 'edit environment variable visibility',
                    eventDetail,
                    triggeredBy: this.argosStore.getItem('username'),
                    awsAccount: states.cluster.accountName,
                    message: ''
                };

                if (hiddenVars.length > 0) {
                    activityLog.message += 'environment variables hidden ' + JSON.stringify(hiddenVars);
                }

                if (removedHiddenVars.length > 0) {
                    activityLog.message += ' environment variables unhidden ' + JSON.stringify(removedHiddenVars);
                }

                activityLog.message = activityLog.message.trim();
                await this.dataAccess.genericCreate({
                    model: 'EnvironmentActivityLog',
                    data: activityLog
                });
                await this.dataAccess.genericUpsert({
                    model: 'Environment',
                    data: envObj
                });    // save the permission
            }
        }
    }
}
