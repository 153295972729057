import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { IClientCareGroupingsListStates } from './clientCareGroupingsList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ClientCareGroupingsListService } from './clientCareGroupingsList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'argos-client-care-groupings-list',
    templateUrl: './clientCareGroupingsList.component.html',
    providers: [ClientCareGroupingsListService],
    styleUrls: ['./clientCareGroupingsList.component.less']
})

export class ClientCareGroupingsListComponent
    extends ReactComponentBase<{}, IClientCareGroupingsListStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;

    constructor(private svc: ClientCareGroupingsListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            pageSize: 50,
            fetchingMetaData: true,
            moment,
            selectedCareGrouping: 'All',
            selectedEnabled: 'Yes',
            filterObj: {},
            defaultDisplayMatTableColumns: ['careGrouping', 'url', 'title', 'usageCount', 'viewCount', 'clientViewCount', 'lastViewed', 'defaultBaseTable', 'overrideBaseTable', 'whereClause', 'joinClause', 'lastUpdateDate', 'lastUpdatedBy', ],
            matTableColumns: ['careGrouping', 'url', 'title', 'usageCount', 'viewCount', 'clientViewCount', 'lastViewed', 'defaultBaseTable', 'overrideBaseTable', 'whereClause', 'joinClause', 'lastUpdateDate', 'lastUpdatedBy'],
            table: new MatTableDataSource([]),
            environmentMatTableSettingName: 'clientCareGroupingsList',
            careGroupings: []
        } as unknown as IClientCareGroupingsListStates);
    }



    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.environmentMatTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    ngAfterViewInit(): void {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    filterResults() {
        this.svc.filterResults(this.states);
    }

    filterChange(columnName: string, event: any) {
        // let filterValues = {}
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    pageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        this.states.tableShowRows = this.states.clientCareGroupings.slice(page, page + event.pageSize);
        this.states.table = new MatTableDataSource(this.states.tableShowRows);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    //pagination functions
    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.environmentMatTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    truncateCellText(text: string) {
        const MAX_STRING_LENGTH = 60;
        if (_.size(text) > MAX_STRING_LENGTH) {
            return text.substring(0, MAX_STRING_LENGTH) + '...';
        }
        return text;
    }
}
