import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IViewJoinEntityHistoryModalProps, IViewJoinEntityHistoryModalStates } from './viewJoinEntityHistoryModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewJoinEntityHistoryModalService } from './viewJoinEntityHistoryModal.component.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-viewJoinEntityHistoryModal',
    templateUrl: './viewJoinEntityHistoryModal.component.html',
    providers: [ViewJoinEntityHistoryModalService]
})

export class ViewJoinEntityHistoryModalComponent extends ReactComponentBase<IViewJoinEntityHistoryModalProps, IViewJoinEntityHistoryModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewJoinEntityHistoryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewJoinEntityHistoryModalComponent>, private cdr: ChangeDetectorRef) {
        super({
            showDiffs: false,
            episodeGroupingEntity: {}
        } as IViewJoinEntityHistoryModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    backToHistory() {
        this.states.showDiffs = false;
    }

    viewDiffs(version: number) {
        this.svc.viewDiffsHandler(version, this.states);
    }
    closeModal() {
        this.dialogRef.close('cancel');
    }
}
