import { Injectable,  ChangeDetectorRef } from '@angular/core';
import { IClaraIqReleaseNotesStates } from './claraIqReleaseNotes.component.d';
import { NgDataAccess } from '../../services/dataAccess.service';
import { ArgosStoreService } from '../../services/argosStore.service';
import * as _ from 'lodash';
import * as marked from 'marked';
import swal from 'sweetalert2';
import { StateService } from '@uirouter/core';

@Injectable()
export class ClaraIqReleaseNotesService {
    constructor(private dataAccess: NgDataAccess, private state: StateService,
        private changeDetectRef: ChangeDetectorRef, private argosStore: ArgosStoreService) {
        //
    }

    async initDelegate(states: IClaraIqReleaseNotesStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IClaraIqReleaseNotesStates) {
        states.jiraFilterId = 13707;
        swal({
            title: 'Enter Details',
            html:
                '<input id="swal-input1" class="swal2-input" placeholder="Jira Filter ID (required)">' +
                '<input id="swal-input2" class="swal2-input" placeholder="Project/Squad Name (optional)">',
            showCancelButton: true,
            confirmButtonColor: '#57C84D',
            confirmButtonText: 'Generate Release Notes',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const jiraFilterElement = document.getElementById('swal-input1') as HTMLInputElement;
                const projectSquadElement = document.getElementById('swal-input2') as HTMLInputElement;
        
                const jiraFilterId = Number(jiraFilterElement.value);
                const projectSquadName = projectSquadElement ? projectSquadElement.value : '';
        
                if (!jiraFilterId) {
                    swal.showValidationError('Please enter Jira Filter ID.');
                    return false;
                } else if (isNaN(jiraFilterId)) {
                    swal.showValidationError('Please enter a valid number for Jira Filter ID.');
                    return false;
                } else {
                    states.jiraFilterId = jiraFilterId;
                    states.project = projectSquadName || null;

                    const clarifyBotBody: any = {
                        jira_filter_id: states.jiraFilterId,
                        username: this.argosStore.getItem('username')
                    };

                    if (states.project) {
                        clarifyBotBody['project'] = states.project;
                    }
        
                    const response = await this.dataAccess.genericMethod({
                        model: 'ClarifyBot',
                        method: 'post',
                        parameters: {
                            endpoint: 'ai_utils/jira_to_release_notes/create',
                            body: clarifyBotBody
                        }
                    });
                    states.releaseNotes = response.llm_response;
                    states.releaseNotesHtml = await marked.parse(states.releaseNotes);
                    this.changeDetectRef.detectChanges();

                    return true;
                }
            },
            onOpen: () => {
                const confirmButton = swal.getConfirmButton() as HTMLButtonElement;
                const jiraInputElement = document.getElementById('swal-input1') as HTMLInputElement;
        
                // Disable the confirm button initially
                confirmButton.disabled = true;
        
                // Enable confirm button only when Jira Filter ID input is not empty
                jiraInputElement.addEventListener('input', () => {
                    confirmButton.disabled = !jiraInputElement.value.trim();
                });
            }
        });
    }

}
