import { Injectable } from '@angular/core';
import { ICcgReportListStates, ICcgReportListService } from './ccgReportList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
declare const saveAs: any;

@Injectable()
export class CcgReportListService implements ICcgReportListService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(states: ICcgReportListStates): Promise<object> {
        await this.init(states);
        return {};
    }
    async init(states: ICcgReportListStates) {
        const result = await this.dataAccess.genericMethod({
            model: 'CcgReport', method: 'getReport', parameters: {
                reportName: 'show reports'
            }
        });

        if (result && result.length > 0 && result[0].data) {
            states.clientTable = _.orderBy(result[0].data, ['report']);
        }
    }

    async requestReport(reportName: string) {

        let result;

        if (reportName.toLowerCase() === 'customer names') {
            result = await this.dataAccess.genericFind({
                model: 'SalesforceAccount',
                filter: {
                    fields: ['name'],
                    where: {
                        type: 'Customer'
                    },
                    order: 'name asc'
                }
            });
            // aws tags have char restricts so the customer name values need to be adjusted
            result = result.map((el: any) => ({ ...el, aws_tag_name: el.name.replace(/[^0-9a-z _.:/=+\-@]/gi, '') }));

        } else if (reportName.toLowerCase() === 'canaries to upgrade') {
            result = await this.dataAccess.genericMethod({
                model: 'CcgReport', method: 'getCanaryReport',
                parameters: {
                    awsAccountName: 'clarify'
                }
            });

            if (result.data && result.data.length === 0) {
                result = [{ report: 'no canaries to upgrade' }];
            } else {
                result = result.data;
            }


        } else {
            result = await this.dataAccess.genericMethod({
                model: 'CcgReport', method: 'getReport', parameters: {
                    reportName
                }
            });

            if (result && result.length > 0) {
                result = result[0].data;
            }
        }

        this.exportReport(reportName, result);
    }

    exportReport(fileName: any, data: any) {
        let exportString = '';
        const delimitor = ',';

        if (data && data.length > 0) {
            const columns: any = [];
            for (const prop in data[0]) {
                if (prop[0] !== '$' && !_.isFunction(data[0][prop]) && !_.isObject(data[0][prop])) {
                    columns.push(prop);
                    exportString += (prop + delimitor);
                }
            }
            exportString += '\n';

            data.forEach((environment: any) => {
                columns.forEach((column: any) => {
                    exportString += ('\"' + environment[column] + '\"' + delimitor);
                });
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, fileName + '.csv');
        }
    }
}
