import { Injectable } from '@angular/core';
import { IViewListModalProps, IViewListModalStates, IViewListModalService } from './viewListModal.component.d';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
@Injectable()
export class ViewListModalService implements IViewListModalService {
    initDelegate(props: IViewListModalProps, states: IViewListModalStates): Object {
        states.columns = _.map(props.columns, 'dataId');
        states.rows = _.cloneDeep(props.rows);
        states.modalTitle = props.modalTitle;

        states.table = new MatTableDataSource(states.rows);
        return {};
    }

    changeDelegate(oldProps: IViewListModalProps, newProps: IViewListModalProps, states: IViewListModalStates): Object {
        this.initDelegate(newProps, states);
        return {};
    }

    searchHandler(props: IViewListModalProps, states: IViewListModalStates) {
        states.searchString = _.toLower(_.trim(states.searchString));
        const rows = _.cloneDeep(props.rows);
        if (!_.isEmpty(states.searchString)) {
            const filteredRows = _.filter(rows, function (row) {
                let found = false;
                _.forEach(states.columns, function (column) {
                    if (!found && _.includes(_.toLower(row[column.dataId]), states.searchString)) {
                        found = true;
                    }
                });
                return found;
            });
            states.rows = _.cloneDeep(filteredRows);
        } else {
            states.rows = _.cloneDeep(rows);
        }
    }

    tableChange(states: IViewListModalStates) {
        states.tableShowRows = states.rows.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }
}
