import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ITeraUsersEditProps, ITeraUsersEditStates } from './teraUsersEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { TeraUsersEditService } from './teraUsersEdit.component.service';
import { ValidationService } from '../../../services/validationService.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import swal from 'sweetalert2';
import * as _ from 'lodash';


@Component({
    selector: 'argos-teraUsersEdit',
    templateUrl: './teraUsersEdit.component.html',
    providers: [TeraUsersEditService]
})

export class TeraUsersEditComponent extends ReactComponentBase<ITeraUsersEditProps, ITeraUsersEditStates, {}> implements OnInit {
    constructor(private svc: TeraUsersEditService, @Inject(MAT_DIALOG_DATA) dialogData: any, private cdr: ChangeDetectorRef,
        private validationSrv: ValidationService, private dialogRef: MatDialogRef<TeraUsersEditComponent>) {
        super({
            saveInProgress: false,
            propertyErrors: [],
            ownerTeamValues: ['CCG', 'Atlas', 'DXTR', 'DPAS', 'CDI', 'DS', 'QA', 'VA', 'SOL', 'Clinical Informatics'],
            ownerJiraProjectValues: ['TERA', 'PRSM', 'DXTR', 'DS', 'INST', 'DPAS', 'RM', 'DOPS', 'INFX', 'VA', 'TM', 'QA']
        } as unknown as ITeraUsersEditStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    async save() {
        const result = await this.svc.save(this.states);
        this.cdr.detectChanges();
        if (result.status == 'success') {
            this.dialogRef.close(this.states);
        }
        else {
             swal({
                title: 'Error saving User',
                text: result.message,
                type: 'error',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            });
        }
    }

    isSaveDisabled() {
        const reqProperties = ['ownerName', 'ownerTeam', 'ownerJiraProject', 'ownerEmail', 'slackUserId'];
        const propertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.teraUser, reqProperties);
        return propertyErrors.length > 0;
    }

    async deleteTeraUser() {
         swal({
            title: 'Confirm ' + this.states.teraUser.ownerName + ' Deletion',
            text: 'Are you sure you want to delete ' + this.states.teraUser.ownerName + '?',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                // TODO: Add code to:
                // Update the owner_id column in reference_data_class to be NULL for any records owned by the user.
                // Update the tera_user_id column in teradrome_deequ_test to be NULL for any records owned by the user.
                // Update the tera_user_id column in teradrome_deequ_test_history to be NULL for any records owned by the user.

                // Delete the Tera User record.
                const result = await this.svc.deleteTeraUser(this.states);
                if (result.status == 'success') {
                    this.dialogRef.close(this.states);
                }
                else {
                    swal({
                        title: 'Error deleting User',
                        text: result.message,
                        type: 'error',
                        confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                    })
                }
            }
        });
    }

    closeModal() {
        this.cdr.detectChanges();
        this.dialogRef.close();
    }
}
