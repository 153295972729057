import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IProgramEpisodeEditStates } from './programEpisodeEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ProgramEpisodeEditService } from './programEpisodeEdit.component.service';
import * as _ from 'lodash';
import { FormArray } from '@angular/forms';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'argos-programEpisodeEdit',
    templateUrl: './programEpisodeEdit.component.html',
    providers: [ProgramEpisodeEditService]
})

export class ProgramEpisodeEditComponent extends ReactComponentBase<{}, IProgramEpisodeEditStates, {}> implements OnInit {
    @ViewChild('programSettingsTable') programSettingsTable: MatTable<any>;
    constructor(private svc: ProgramEpisodeEditService, private cdr: ChangeDetectorRef) {
        super({
            deleteProgramSettings: [],
            newProgramEpisode: false,
            disableField: false,
            programEpisode: {
                programModel: {}
            },
            CCGList: [],
            programEpisodeNames: [],
            programEpisodeNameExists: false,
            myformArray: new FormArray([])
        } as unknown as IProgramEpisodeEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.states.myformArray.valueChanges.subscribe(dataList => {
            this.svc.formArrayValueChanges(dataList, this.states, this.renderTable);
        });
        this.cdr.detectChanges();
    }

    renderTable: Function = () => {
        this.programSettingsTable.renderRows();
    };

    addProgramSetting() {
        this.svc.addProgramSetting(this.states, this.renderTable);
    }

    removePrgSettings(row: any, index: number) {
        this.svc.removePrgSettings(row, index, this.states, this.renderTable);
    }

    confirmClone() {
        this.svc.confirmCloneHandler(this.states);
    }

    update() {
        this.svc.update(this.states);
    }

    programEpisodeNameChange() {
        this.states.programEpisodeNameExists = this.states.programEpisodeNames.includes(this.states.programEpisode.name);
    }
}
