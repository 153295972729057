
import { ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { HospitalRatesAddHospitalModalServices } from './hospitalRatesAddHospitalModal.component.service';
import { IHospitalRatesAddHospitalModalStates, IHospitalRatesAddHospitalModalProps } from '../hospitalRatesAddHospitalModal/hospitalRatesAddHospitalModal.component.d';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-hospital-rates-add-hospital-modal',
    templateUrl: './hospitalRatesAddHospitalModal.component.html',
    providers: [HospitalRatesAddHospitalModalServices]
})

export class HospitalRatesAddHospitalModalComponent extends ReactComponentBase<IHospitalRatesAddHospitalModalProps, IHospitalRatesAddHospitalModalStates, {}>
    implements OnInit {

        constructor(private svc: HospitalRatesAddHospitalModalServices, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
                        private dialogRef: MatDialogRef<HospitalRatesAddHospitalModalComponent>) {
            super({
                id: null,
                priority: null,
                ccn: null,
                npi: null,
                hospitalName: null,
                streetAddress: null,
                city: null,
                state: null,
                zipCode: null,
                ratesFileUrl: null,
                customers: null,
                updatedBy: null,
                isEdit: false
            });
            this.props = dialogData.props;
        };

        ngOnInit() {
            this.svc.initDelegate(this.props, this.states);
            this.cdr.detectChanges();
        };

        async addHospital(states: IHospitalRatesAddHospitalModalStates) {
            const validateSuccess = await this.svc.validateHospitalRecord(states);
            const currentLoadSequence = await this.svc.getCurrentLoadSequence();
            if (validateSuccess) {
                const addHospitalResponse = await this.svc.addHospital(states, currentLoadSequence);
                if (addHospitalResponse) {
                    this.dialogRef.close();
                }
                else {
                    alert('Failed to add hospital, check with DPAS if error persists');
                }
            }
        };

        async editHospital(states: IHospitalRatesAddHospitalModalStates, props: IHospitalRatesAddHospitalModalProps) {
            const validateSuccess = await this.svc.validateHospitalRecord(states, props);
            if (validateSuccess) {
                const editHospitalResponse = await this.svc.editHospital(states, props);
                if (editHospitalResponse) {
                    this.dialogRef.close();
                }
                else {
                    alert('Failed to edit hospital, check with DPAS if error persists');
                }
            };
        };

        close() {
            this.dialogRef.close();
        };
    }
