import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { ClarifyBotServices } from './clarifyBot.component.service';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { switchMap, startWith } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'argos-clarifyBot',
    templateUrl: './clarifyBot.component.html',
    styleUrls: ['./clarifyBot.component.less'],
    providers: [ClarifyBotServices]
})
export class ClarifyBotComponent extends ReactComponentBase<{}, { saveInProgress: boolean; }, {}> implements OnInit {
    messages: { 
        content: string;
        sentByUser: boolean;
        chat_id?: string;
        sources?: {
            url: string;
            Title: string;
            Score: string;
        }[];
        showDropdown?: boolean;
        selectedFeedbackType?: string;
        feedback_text?: string;
    }[] = [];
    newMessage = '';
    history: any[] = [];
    historyTabActive = false;
    tableHeaders: string[];
    tableData: any[];
    responseColumnWidth: any = {};
    feedbackTypes: string[] = ['Information is incorrect', 'Information is outdated', 'Clara could not find the information needed', 'Response used the wrong sources', 'Response is not detailed enough', 'Other'];
    showTable = false;
    collection: any = undefined;
    dataSource: any = undefined;
    
    collectionControl = new FormControl(null);
    dataSourceControl = new FormControl(null);
    collections$ = this.svc.collections(this.states);
    dataSources$ = this.collectionControl.valueChanges.pipe(
        startWith(this.collectionControl.value),
        switchMap((collection) => {
            this.dataSourceControl.reset();
            if(!collection){
                return of([]);
            } else {
                return this.svc.getDataSourceForCollection(this.states, collection);
            }
        }
        )
    );
    constructor(private svc: ClarifyBotServices, private cdr: ChangeDetectorRef, private _snackBar: MatSnackBar, private dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.cdr.detectChanges();
    }

    async sendMessage(collection: string, dataSource: string, query: string): Promise<void> {
        const userMessage = this.newMessage.trim();
        this.newMessage = '';
        this.messages.push({ content: userMessage, sentByUser: true });
        this.svc.queryBot(this.states, collection, dataSource, query).then((botResponse) => {
            if (userMessage) {
                const content = 'Using collection: ' + collection + '\n System Responded: ' + botResponse.answer;
                this.messages.push({ content, sentByUser: false, chat_id: botResponse.chat_id, sources: botResponse.sources });
            }
            this.cdr.detectChanges();
        });
    }

    onTabChange(event: MatTabChangeEvent) {
        if (event.index === 1) {
            this.historyTabActive = true;
            this.svc.getHistory(this.states).then((history) => {
                this.history = history;
                this.getHistory();
                this.cdr.detectChanges();
            });
        } else {
            this.historyTabActive = false;
        }
    }

    isDateColumn(column: string): boolean {
        // You can customize this based on your data structure
        return column.toLowerCase().includes('timestamp');
    }

    async getHistory(): Promise<void> {
        if (this.history.length > 0) {
            this.tableHeaders = Object.keys(this.history[0]);
        } else {
            // Handle the case where there is no data
            this.tableHeaders = [];
        }
        this.tableData = this.history;
        for (const header of this.tableHeaders) {
            if (header.toLowerCase() === 'response') {
                this.responseColumnWidth[header] = '300px';
            } else {
                this.responseColumnWidth[header] = 'auto';
            }
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 3000, // Duration in milliseconds
        });
    }

    async updateFeedback(message: any, vote: string): Promise<void> {
        this.svc.updateFeedback(this.states, message.chat_id, vote, message?.selectedFeedbackType, message?.feedback_text).then((response) => {
            message.showDropdown = false;
            if (response.status === 'success') {
                this.openSnackBar('Feedback submitted!', 'Close');
            }
            this.cdr.detectChanges();
        });
    }

    showFeedbackDropdown(message: any) {
        message.showDropdown = true;
        message.selectedFeedbackType = ''; // Reset selected feedback type
    }

    toggleTable() {
        this.showTable = !this.showTable;
    }

}
