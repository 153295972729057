import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IQATestResultProductDetailStates } from './qaTestResultDashboardProductsDetail.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { QATestResultDetailService } from './qaTestResultDashboardProductsDetail.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as _ from 'lodash';

@Component({
    selector: 'argos-qaTestResultDetail',
    templateUrl: './qaTestResultDashboardProductsDetail.component.html',
    providers: [QATestResultDetailService],
    styleUrls: ['../testResultsDashboard/qaTestResultDashboard.component.less']
})

export class QATestResultsProductDetailComponent extends ReactComponentBase<{}, IQATestResultProductDetailStates, {}> implements OnInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: QATestResultDetailService, private cdr: ChangeDetectorRef) {
        super({
            pctSubmoduleDetails: {},
            pctSubmoduleCollapse: '',
            pctSubTable: new MatTableDataSource([]),
            pctDataTable: new MatTableDataSource([])
        } as IQATestResultProductDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges;
    }

    searchMatTable(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.pctDataTable.filter = filterValue.trim().toLowerCase();
    }

    async matPanelStateChange(subModuleName: string): Promise<void> {
        const value = _.get(this.states, 'SubmoduleCollapse');
        this.setStates({
            subTable: new MatTableDataSource([])
        });
        if (value !== subModuleName) {
            await this.svc.calculatepctResultsPercentageBySubmoduleName(subModuleName, this.states);
            this.cdr.detectChanges();
        } else {
            this.setStates({
                SubmoduleCollapse: '',
            });
        }
    }

}
