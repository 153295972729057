import { Injectable } from '@angular/core';
import { IModelMetaDataListStates, IModelMetaDataListService } from './modelMetaDataList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
@Injectable()
export class ModelMetaDataListService implements IModelMetaDataListService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(states: IModelMetaDataListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IModelMetaDataListStates) {
        const objs = await this.dataAccess.genericFind({
            model: 'ModelMetaData',
            filter: { order: 'id' }
        });
        states.modelMetaDataList = _.orderBy(objs, ['id']);
    }
}
