import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewDeequModalProps, IViewDeequModalStates } from './viewDeequModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewDeequModalService } from './viewDeequModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationService } from '../../../services/validationService.service';
import swal from 'sweetalert2';


@Component({
    selector: 'argos-viewDeequModal',
    templateUrl: './viewDeequModal.component.html',
    providers: [ViewDeequModalService]
})

export class ViewDeequModalComponent
    extends ReactComponentBase<IViewDeequModalProps, IViewDeequModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewDeequModalService, private validationSrv: ValidationService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewDeequModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        if (this.states.fetchingResponse) {
            swal({
                title: 'Are you sure?',
                text: 'It looks like you are awaiting a response in your chat. You will lose your chat history if you close.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result: any) => {
                if (result.value) {
                    this.dialogRef.close({});
                }
            });
        } else {
            this.dialogRef.close({});
        }
    }

    sendQuestion(question: string) {
        this.svc.sendQuestion(this.states, question);
    }

    testTypeChanges() {
        this.svc.testTypeChanges(this.states);
    }

    argsUpdate(arg: any, updateName: boolean | undefined) {
        this.svc.argsUpdate(arg, updateName, this.states);
    }

    argsCleared(arg: any) {
        this.svc.argsCleared(arg, this.states);
    }

    uploadFile(file: any) {
        this.svc.uploadFile(file, this.states);
    }

    clearFile(field: any) {
        this.svc.clearFile(this.states);
    }

    useConstant() {
        this.svc.useConstant(this.states);
    }

    updateName() {
        this.svc.updateName(this.states);
    }

    deleteTest() {
        this.svc.deleteTest(this.states);
    }

    saveModal() {
        this.svc.saveModal(this.states);
        this.dialogRef.close({});
    }

    isSaveDisabled() {
        let errorMessage = '';
        const reqProperties = ['level', 'name', 'type'];
        const propertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.deequTest, reqProperties);

        const reqArgs = ['column', 'columns', 'datatype', 'quantile', 'columnA', 'columnB', 'maxBins', 'pattern', 'columnCondition', 'constraintName', 'allowed_values', 'allowedValuesType', 'allowed_values_list', 'columnAType', 'columnBType'];
        const missingArgs = this.states.lodash.intersection(reqArgs, Object.keys(this.states.deequTest.args).filter(a => !this.states.deequTest.args[a]));

        // If satisfies test type, confirm either all or none of the all column args are populated, except exclusions can be null
        const allColumnsArgs = ['all_columns_arg', 'all_columns_boolean', 'all_columns_exclusions'];
        let saveError = false;
        if (this.states.deequTest.type === 'satisfies') {
            const missingAllColumnArgs = this.states.lodash.intersection(allColumnsArgs, Object.keys(this.states.deequTest.args).filter(a => !this.states.deequTest.args[a]));
            if (!(missingAllColumnArgs.length === 0 || missingAllColumnArgs.length === 3) && !(missingAllColumnArgs.length === 1 && missingAllColumnArgs[0] === 'all_columns_exclusions')) {
                saveError = true;
                errorMessage += 'Satisfies test requires that All Columns Boolean is populated. ';
            }
            if (this.states.deequTest.args.columnCondition && this.states.deequTest.args.all_columns_arg && !this.states.deequTest.args.columnCondition.includes('all_columns')) {
                saveError = true;
                errorMessage += 'The string "all_columns" is missing from Column Condition and user is attempting to use All Columns functionality. ';
            }
        }

        if (propertyErrors.length + missingArgs.length > 0) {
            saveError = true;
            const allMissingArgs = this.states.lodash.map(this.states.lodash.map(propertyErrors, 'propertyName').concat(missingArgs), (ma: string) => this.states.lodash.startCase(ma));
            errorMessage += 'The following arguments are missing: ' + allMissingArgs.join(', ') + '. ';
        }

        if (this.states.testNames.includes(this.states.deequTest.name)) {
            saveError = true;
            errorMessage += 'Test name ' + this.states.deequTest.name + ' already exists. ';
        }

        if (!this.states.teraUserId) {
            saveError = true;
            errorMessage += 'User must be added in tera_user table to edit or create tests. ';
        }

        if (this.states.deequTest.args?.lower_limit?.[this.states.defaultLimitSource] > this.states.deequTest.args?.upper_limit?.[this.states.defaultLimitSource]) {
            saveError = true;
            errorMessage += 'Lower limit must be less than or equal to upper limit. ';
        }

        // if allowed_values_list and the string 'valid_value_col' not in the value, then error
        if (this.states.deequTest.args?.allowed_values_list && !this.states.deequTest.args?.allowed_values_list.includes('valid_value_col')) {
            saveError = true;
            errorMessage += 'The SQL for allowed values must have a column aliased as "valid_value_col". ';
        }

        const isAtLeastOneValuePopulated = (obj: any) => {
            return obj && Object.values(obj).some(value => value !== null && value !== undefined && value !== '');
        };

        if ('upper_limit' in this.states.deequTest.args && !isAtLeastOneValuePopulated(this.states.deequTest.args.lower_limit) && !isAtLeastOneValuePopulated(this.states.deequTest.args.upper_limit)) {
            saveError = true;
            errorMessage += 'Either lower limit and/or upper limit must be populated. ';
        }

        if (!this.states.containedInManualDisabled) {
            saveError = true;
            errorMessage += 'Please click the "Validate List" button to confirm that the values will upload correctly before you can save. ';
        }

        // Check test vs column(s) data type
        // 1. numeric test type and non-numeric columns
        // 2. string test type and non-string columns
        // 3. Any test except satisfies for array, map, struct column
        const stringTestTypes = ['hasMaxLength', 'hasMinLength', 'isContainedIn', 'hasPattern'];
        const stringDataTypes = ['string'];
        const numericTestTypes = ['hasMax', 'hasMin', 'hasMean', 'hasSum', 'hasStandardDeviation', 'isPositive', 'isNonNegative'];
        const numericComparisonTestTypes = ['isGreaterThan', 'isGreaterThanOrEqualTo', 'isLessThan', 'isLessThanOrEqualTo'];
        const numericDataTypes = ['boolean', 'integer', 'double', 'float', 'long'];
        const column = this.states.columns.find(column => column.columnName === this.states.deequTest.args.column);
        const columnA = this.states.columns.find(column => column.columnName === this.states.deequTest.args.columnA);
        const columnB = this.states.columns.find(column => column.columnName === this.states.deequTest.args.columnB);
        if (numericTestTypes.includes(this.states.deequTest.type) && !numericDataTypes.includes(column.dataType) ||
            numericComparisonTestTypes.includes(this.states.deequTest.type) &&
                (!numericDataTypes.includes(columnA.dataType) || !numericDataTypes.includes(columnB.dataType)) ||
            stringTestTypes.includes(this.states.deequTest.type) && !stringDataTypes.includes(column.dataType) ||
            column && this.states.deequTest.type !== 'satisfies' && this.states.unsupportedDataTypes.includes(column.dataType)
        ) {
            saveError = true;
            errorMessage += 'Test type is not compatible with selected column data type. ';
        }


        this.states.saveErrorMessage = errorMessage;

        // Display error if save disabled
        return missingArgs.length !== 0 || saveError;

    }

    launchThreshModal(thresholdKey: string) {
        this.svc.launchThreshModal(thresholdKey, this.states);
    }

    changeTeradromeStageSource(sourceId: string) {
        this.svc.changeTeradromeStageSource(sourceId, this.states);
    }

    addAllColumnConstraints() {
        this.svc.addAllColumnConstraints(this.states);
    }

    viewDiffs(version: number) {
        this.svc.viewDiffsHandler(version, this.states);
    }

    containedInManualUpload(updateVal: any | undefined) {
        this.svc.containedInManualUpload(this.states, updateVal);
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
        if (event.index = 2 && this.states.messages.length === 0 && !this.states.fetchingResponse) {
            if (!this.states.teradromeTable.description) {
                this.states.messages.unshift({
                    message: `Before you can use the AI Chat feature, you must provide a description for the table ${this.states.teradromeTable.tableName}. You will only be prompted for this once per table.`,
                    userMessage: false
                });
            } else {
                this.svc.sendQuestion(this.states, undefined);
            }
        }
    }
}
