import * as _ from 'lodash';
import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { EnvironmentModalComponent } from '../../../environments/environmentModal/environmentModal.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class EcsAppAWS {
    constructor(private matDialog: MatDialog) {

    }

    openAwsEcsSite(states: IEcsAppEditStates) {
        window.open('https://us-west-2.console.aws.amazon.com/ecs/home?region=us-west-2#/clusters/' + states.clusterName + '/services', '_blank');
    }

    openApp(states: IEcsAppEditStates) {
        if (states.cluster.appUrl.length > 0) {
            window.open(states.cluster.appUrl, '_blank');
        }
    }

    openAWSEcsService(serviceName: string, states: IEcsAppEditStates) {
        if (serviceName) {
            return 'https://us-west-2.console.aws.amazon.com/ecs/home?region=us-west-2#/clusters/' + states.clusterName + '/services/' + serviceName + '/details';
        } else {
            return '';
        }
    }

    openAWSEcsTasks(states: IEcsAppEditStates) {
        window.open('https://us-west-2.console.aws.amazon.com/ecs/home?region=us-west-2#/clusters/' + states.clusterName + '/tasks', '_blank');
    }

    openAWSEcsTaskOverview(clusterName: string, taskId: string, region: string) {
        return 'https://us-west-2.console.aws.amazon.com/ecs/v2/clusters/' + clusterName + '/tasks/' + taskId + '/configuration?region=' + region;
    }

    openAWSEcsTaskRevision(taskDefName: string, revisionId: string, region: string) {
        return 'https://' + region + '.console.aws.amazon.com/ecs/v2/task-definitions/' + taskDefName + '/' + revisionId + '/containers?region=' + region;
    }

    getAWSEcsTargetGroupUrl(states: IEcsAppEditStates) {
        if (states.cluster.targetGroupName) {
            return 'https://us-west-2.console.aws.amazon.com/ec2/v2/home?region=us-west-2#TargetGroups:search=' + states.cluster.targetGroupName;
        } else {
            return '';
        }
    }

    getAWSEcsLoadBalancerUrl(states: IEcsAppEditStates) {
        if (states.cluster.loadBalancerName) {
            return 'https://us-west-2.console.aws.amazon.com/ec2/home?region=us-west-2#LoadBalancers:search=' + states.cluster.loadBalancerName + ';sort=loadBalancerName';
        } else {
            return '';
        }
    }

    getAWSCanaryURL(canaryName: string) {
        return 'https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#synthetics:canary/detail/' + canaryName;
    }

    getUptimeURL(alarmId: number) {
        return 'https://uptime.com/devices/services/' + alarmId;
    }

    getAwsTasksDefsLink(taskDefinitionName: string, revision?: any) {
        let result = 'https://us-west-2.console.aws.amazon.com/ecs/v2/task-definitions/' + taskDefinitionName;
        if (revision) {
            result += '/' + revision + '/containers?region=us-west-2';
        } else {
            result += '?status=ACTIVE&region=us-west-2';
        }

        return result;
    }

    getAWSEc2InstanceURL(instanceId: string) {
        return 'https://us-west-2.console.aws.amazon.com/ec2/v2/home?region=us-west-2#Instances:instanceId=' + instanceId + ';sort=instanceId';
    }

    openMetrics(states: IEcsAppEditStates) {
        window.open('https://us-west-2.console.aws.amazon.com/ecs/home?region=us-west-2#/clusters/' + states.clusterName + '/services/' + states.selectedService.serviceName + '/metrics', '_blank');
    }

    openLog(states: IEcsAppEditStates, filter: string) {
        window.open(this.getPapertrailUrl(states, filter), '_blank');
    }

    getPapertrailUrl(states: IEcsAppEditStates, filter: string) {
        let exclusionStr = states.envMgmtSettings.papertrailLogExclusion.join('"%20OR%20"').replace(/ /g, '%20');
        let result = 'https://my.papertrailapp.com/groups/17647492/events?q=' + filter + '%20AND%20NOT%20%28"' + exclusionStr + '"%29';
        return result;
    }

    getAwsGroupSelected(isPublic: any, groupType: string, states: IEcsAppEditStates) {

        let groupList = [];
        let fieldName;

        if (groupType === 'securityGroups') {
            fieldName = 'groupId';
            groupList = states.cluster.selectedVpc.securityGroups;
        } else if (groupType === 'subnets') {
            fieldName = 'subnetId';
            groupList = states.cluster.selectedVpc.subnets;
        }

        const result = _.filter(groupList, function (i) {
            if (i.isPublic === isPublic) {
                return i;
            }
        });

        return _.uniq(_.map(result, fieldName));
    }

    exportFargateMigrationEnvironmentVariables(singleTask: any, states: IEcsAppEditStates) {

        let envVars = '';
        let tfVars = '';
        const databaseName = '';
        const standardEnvironmentVars = ['AUTH0_CLIENT_ID', 'AUTH0_CONNECTION', 'AUTH0_SECRET', 'CLIENT_INTERNAL_NAME', 'DATABASE', 'CUSTOM_FIELD_S3_LOCATION', 'REDISCLOUD_URL', 'PING_INTERVAL', 'PING_WAIT_SECONDS', 'NPO_BATCH_SIZE', 'SSL_CERT_FILE_PATH', 'SSL_KEY_FILE_PATH', 'NODE_OPTIONS', 'FARGATE_ENABLED'];

        singleTask.environment.forEach(function (env: any) {
            // exclude hidden variables
            if (standardEnvironmentVars.indexOf(env.name.toUpperCase()) === -1) {
                envVars += '{' + '\n';
                envVars += '"name": "' + env.name + ',"\n';
                envVars += '"value": "' + env.value + '"\n';
                envVars += '},' + '\n';
            }
        });

        if (envVars.length > 0) {
            envVars = envVars.replace(/,(\s+)?$/, '');
            envVars = ',' + '\n' + envVars;
        }

        const commands = [{
            name: states.clusterName + ' ' + states.cluster.accountName + ' - Environment Variables - Place this under fargate_terraform/task-definitions/task.json',
            commandLines: envVars
        }];

        const reqEnvVars = ['AUTH0_CLIENT_ID', 'AUTH0_CONNECTION', 'AUTH0_SECRET', 'CLIENT_INTERNAL_NAME', 'DATABASE', 'REDISCLOUD_URL'];

        tfVars += 'ecs_cluster_name="' + states.clusterName + '"\n';
        tfVars += 'max_web_count="2"\n';
        tfVars += 'max_worker_count="4"\n';
        tfVars += 'rds_admin_password="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"\n';
        tfVars += 'clarify_platform_build_number="' + states.currentBuildTag + '"\n';

        singleTask.environment.forEach(function (env: any) {
            // exclude hidden variables
            if (reqEnvVars.indexOf(env.name.toUpperCase()) > -1) {
                // only take the redis_uri if its clear data
                if (env.name.toUpperCase() === 'REDISCLOUD_URL') {
                    if (states.cluster.selectedVpc) {
                        tfVars += 'redis_uri="' + env.value + '"\n';
                    }
                } else {
                    tfVars += env.name.toLowerCase() + '="' + env.value + '"\n';
                }
            }
        });

        singleTask.secrets.forEach(function (secret: any) {
            // exclude hidden variables
            if (secret.name.toUpperCase() === 'RDS_SECRET') {
                tfVars += 'rds_secret_arn="' + secret.valueFrom + '"\n';
            }
        });

        if (states.cluster.appUrl.indexOf('.com') > -1) {
            if (states.cluster.selectedVpc) {
                tfVars += 'ssl_cert="cleardata_dot_com"\n';
            } else {
                tfVars += 'ssl_cert="use_dot_com"\n';
            }
        } else {
            tfVars += 'ssl_cert="use_dot_us"\n';
        }

        // cleardata
        if (states.cluster.selectedVpc) {
            tfVars += 'rds_admin_user="postgres"\n';
            tfVars += 'vpc_id="' + states.cluster.selectedVpc.vpcId + '"\n';
            tfVars += 'public_security_group="[' + this.getAwsGroupSelected(true, 'securityGroups', states) + ']"\n';
            tfVars += 'public_subnet_ids="[' + this.getAwsGroupSelected(true, 'subnets', states) + ']"\n';
            tfVars += 'private_security_group="[' + this.getAwsGroupSelected(false, 'securityGroups', states) + ']"\n';
            tfVars += 'private_subnet_ids="[' + this.getAwsGroupSelected(false, 'subnets', states) + ']"\n';
            tfVars += 'rds_host="' + states.cluster.selectedVpc.rds.Address + '"\n';
            tfVars += 'athena_secret="cleardata"\n';
            tfVars += 'ecr_image="cleardata"\n';
            tfVars += 'ecs_execution_role="cleardata"\n';
            tfVars += 'ecs_service_role="cleardata"\n';
            tfVars += 'profile"="cleardata"\n';
        } else {
            tfVars += 'redis_uri="rediss://master.platform-rediss.yjcame.usw2.cache.amazonaws.com:6379"\n';
        }

        commands.push({
            name: 'fargate_terraform/terraform.tfvars',
            commandLines: tfVars
        });

        this.openModal(commands);
    }

    openModal(commands: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    commands
                }
            }
        };
        this.matDialog.open(EnvironmentModalComponent, dialogConfig);
    }

    exportEnvironmentVariables(singleTask: any, states: IEcsAppEditStates) {

        let envVars = '';
        let databaseName = '';

        singleTask.environment.forEach(function (env: any) {
            // exclude hidden variables
            if (states.cluster.environment.hiddenEcsVariables.indexOf(env.name) === -1 || states.isAdmin) {
                envVars += 'export ' + env.name + '=' + env.value + '\n';

                if (env.name.toLowerCase() === 'database') {
                    databaseName = env.value;
                }
            }
        });

        const commands = [{
            name: 'ECS - Environment Variables',
            commandLines: envVars
        }];

        commands.push({
            name: 'Get credentials from https://us-west-2.console.aws.amazon.com/secretsmanager/home?region=us-west-2#!/secret?name=' + databaseName + '-platform-rds-secret',
            commandLines: 'export DATABASE_URL=postgres://{role}:{password}@{db_host}:5432/' + databaseName + '?ssl=true'
        });

        this.openModal(commands);
    }
}
