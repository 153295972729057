import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IClarifyInsightsCreateDeliveryModalInterface } from './clarifyInsightsCreateDeliveryModal.component.d';
import { MD5Service } from '../../../services/md5.service';
import { ArgosStoreService } from 'client/app/services/argosStore.service';

@Injectable()
export class ClarifyInsightsCreateDeliveryModalService {

    constructor(private dataAccess: NgDataAccess, private md5: MD5Service, private argosStore: ArgosStoreService) { }

    async initDelegate(states: IClarifyInsightsCreateDeliveryModalInterface): Promise<object> {
        await this.init(states)
        return states;
    }

    async init(states: IClarifyInsightsCreateDeliveryModalInterface) {
        await this.baseQuery(states);
        this.filterQuery(states);
        return states;
    }

    refreshData(states: IClarifyInsightsCreateDeliveryModalInterface) {
        this.filterQuery(states);
        return states;
    }

    async baseQuery(states: IClarifyInsightsCreateDeliveryModalInterface) {
        const customers = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsCustomer',
            filter: {
                where: {
                    and: [{isActive: true}]
                }
            }
        });
        states.allCustomers = customers.map((customer: { extractType: string; }) => {
            if (customer.extractType === 'enriched_claims') {
                customer.extractType = 'claims';
            }
            return customer;
        });
        states.allCustomerCodes = Array.from(new Set(states.allCustomers.map((customer: { customerId: string; }) => customer.customerId)).values()).map(customerId => states.allCustomers.find((customer: { customerId: string; }) => customer.customerId === customerId).customerCode);
        const allFilters = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsFilter'
        });
        states.allFilters = Array.from(new Set(allFilters.map((filter: { filterName: any; }) => filter.filterName)).values()).map(filterName => allFilters.find((filter: { filterName: any; }) => filter.filterName === filterName));
        const allExtractTypes = states.allFilters.map((filter: { extractType: any; }) => filter.extractType);
        states.allExtractTypes = Array.from(new Set(allExtractTypes.map((extractType: any) => extractType)));
        const allDataSources = states.allFilters.map((filter: { dataSource: any; }) => filter.dataSource);
        states.allDataSources = Array.from(new Set(allDataSources.map((dataSource: any) => dataSource)));
        return states;
    }

    filterQuery(states: IClarifyInsightsCreateDeliveryModalInterface) {
        let tempFilters: any = states.allFilters;
        let tempExtractTypes: any = states.allExtractTypes;
        this.resetDropdown(states, 'filteredExtractTypes')
        if (states.activeCustomerCode) {
            tempExtractTypes = states.allCustomers.filter((customer: { customerId: any; }) => customer.customerId === states.activeCustomerId).map((customer: { extractType: any; }) => customer.extractType);
            tempFilters = (tempFilters) ? tempFilters.filter((filter: { customerId: any; }) => filter.customerId === states.activeCustomerId): undefined;
            states.filteredExtractTypes = Array.from(new Set(tempExtractTypes.map((extractType: any) => extractType)));
            let resetFields = ['filteredFilterNames', 'filteredDataSources']
            for (const resetField of resetFields) {
                this.resetDropdown(states, resetField)
            }
        }
        if (states.activeExtractType) {
            tempFilters = tempFilters.filter((filter: { extractType: any; }) => filter.extractType === states.activeExtractType);
            states.filteredFilterNames = Array.from(new Set(tempFilters.map((filter: { filterName: any; }) => filter.filterName)));
            this.resetDropdown(states, 'filteredDataSources')
        }
        if (states.activeFilterName) {
            tempFilters = tempFilters.filter((filter: { filterName: any; }) => filter.filterName === states.activeFilterName);
            states.filteredDataSources = Array.from(new Set(tempFilters.map((filter: { dataSource: any; }) => filter.dataSource)));
        }
        return states;
    }

    resetDropdown(states: IClarifyInsightsCreateDeliveryModalInterface, fieldName: string) {
        switch (fieldName) {
            case 'filteredDataSources':
                states.filteredDataSources = new Array<string>();
                break;
            case 'filteredFilterNames':
                states.filteredFilterNames = new Array<string>(); 
                break;
            case 'filteredExtractTypes':
                states.filteredExtractTypes = new Array<string>();
                break; 
            default:
                break;
        }
    }

    updateDeliveryDateDelegate(states: IClarifyInsightsCreateDeliveryModalInterface, deliveryDate: Date) {
        states.deliveryDate = deliveryDate.toLocaleDateString('en-US');
        states.encodedDeliveryDate = deliveryDate.toISOString().split('T')[0];
    };

    getUser(){
        return this.argosStore.getItem('username').replace('@clarifyhealth.com', '');
    };

    async createDelivery(states: IClarifyInsightsCreateDeliveryModalInterface) {
        const user: string = this.getUser();
        const response = await this.dataAccess.genericCreate({
            model: 'ClarifyInsightsDataDelivery',
            data: {
                customerId: states.activeCustomerId,
                customerCode: states.activeCustomerCode,
                extractType: states.activeExtractType,
                filterName: states.activeFilterName,
                dataSource: states.activeDataSource,
                deliveryDate: states.encodedDeliveryDate,
                deliveryNote: states.deliveryNote,
                requestedBy: user,
                requestedAt: new Date().toISOString(),
                exportAsCsv: states.exportAsCsv
            }
        });
    }

    async deliveryInProgress(states: IClarifyInsightsCreateDeliveryModalInterface) {
        const response = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsDataDelivery',
            filter: {
                where: {
                    customerId: states.activeCustomerId,
                    customerCode: states.activeCustomerCode,
                    extractType: states.activeExtractType,
                    filterName: states.activeFilterName,
                    dataSource: states.activeDataSource,
                    or: [
                        {latestTransferSuccess: false},
                        {latestTransferSuccess: null}
                    ]
                }
            }
        });
        return response.length;
    }

}