// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-statistics .top-button {
  height: 25px;
  width: 155px;
  padding: 0;
}
.network-statistics .bottom-button {
  height: 25px;
  width: 155px;
  padding: 0;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/networksStatus/networksStatus/networksStatus.component.less"],"names":[],"mappings":"AAAA;EAEQ,YAAA;EACA,YAAA;EACA,UAAA;AAAR;AAJA;EAOQ,YAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;AAAR","sourcesContent":[".network-statistics {\n    .top-button {\n        height: 25px;\n        width: 155px;\n        padding: 0; \n    }\n    .bottom-button {\n        height: 25px;\n        width: 155px;\n        padding: 0; \n        margin-top: 5px;\n    }    \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
