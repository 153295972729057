import { Injectable } from '@angular/core';
import { IProgramModelEditStates, IProgramModelEditService } from './programModelEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class ProgramModelEditService implements IProgramModelEditService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService, private alertSvc: AlertService) {
        //
    }
    async initDelegate(states: IProgramModelEditStates): Promise<object> {
        states.lodash = _;
        await this.init(states);
        return {};
    }

    async init(states: IProgramModelEditStates) {
        const id = this.uiRouter.globals.params.id;
        if (id) {
            try {
                let promises: any[] = [];
                promises.push(this.dataAccess.genericMethod({
                    model: 'ProgramModel',
                    method: 'findById',
                    parameters: {
                        id,
                        filter: {
                            include: [ 'programSettings']
                        }
                    }
                }));
                
                promises.push(this.dataAccess.genericFind({
                    model: 'CcgType',
                    filter: {
                        order: 'name'
                    }
                }));

                promises.push(this.dataAccess.genericFind({
                    model: 'ListItem',
                    filter: {
                        order: 'key'
                    }
                }));

                promises.push(this.dataAccess.genericFind({
                    model: 'ProgramModel',
                    filter: {
                        order: 'name',
                        fields: ['name'],
                    }
                }));

                const [programModel, ccgList, items, programModelNames] = await Promise.all(promises);
                states.programModel = programModel;
                states.CCGList = ccgList;
                
                states.programModelNames = _.map(programModelNames, 'name');
                _.pull(states.programModelNames, states.programModel.name); //remove current name from list to check

                // Note these properties below are defined as a big int and so is stored as a string when it flows down from postgres
                states.programModel.programSettings = states.programModel.programSettings.map(pgs => {
                    return {
                    ...pgs,
                    settingListItemId: pgs.settingListItemId !== null ? parseInt(pgs.settingListItemId, 10) : null,
                    operatorListItemId: pgs.operatorListItemId !== null ? parseInt(pgs.operatorListItemId, 10) : null,
                    filterPredicateListItemId: pgs.filterPredicateListItemId !== null ? parseInt(pgs.filterPredicateListItemId, 10) : null
                    };
                });

                this.processListItems(items, states);
            } catch (error) {
                console.log('error');
            }

            const referenceDataListTags = await this.dataAccess.genericFind({
                model: 'ReferenceDataListTag',
                filter: { include: ['referenceDataList', 'viewReferenceDataListParentTags'] }
            });

            states.referenceDataListTags = _.map(referenceDataListTags, (tag: any) => {
                tag.fullPath = (tag.viewReferenceDataListParentTags) ? tag.viewReferenceDataListParentTags.parentTags.join('/') + '/' + tag.tag : tag.tag;
                return tag;
            });
    
            states.referenceDataLists = _.orderBy(_.uniqBy(_.map(states.referenceDataListTags, (tag: any) => {
                return {
                    id: tag.referenceDataListId,
                    name: tag.referenceDataList.name
                };
            }), 'id'), 'name');
    
            _.forEach(states.programModel.programSettings, (pm) => {
                if (pm.valueReferenceListTagId) {
                    const tag = _.find(states.referenceDataListTags, { id: pm.valueReferenceListTagId });
                    pm.referenceDataListId = _.get(tag, 'referenceDataListId');
                    pm.valueReferenceListParentTagId = _.get(tag, 'parentListTagId');
                }
            });
        }
    }
 

    processListItems(lI: any, states: IProgramModelEditStates) {

        // init program setting rows
        _.forEach(states.programModel.programSettings, (ps: any) => {
            const lItem: any = _.find(lI, { id: _.toNumber(ps.settingListItemId) });
            // found a match
            if (lItem) {
                ps.programSettingKey = lItem.key;
            }
        });

        // init filter criterias
        _.forEach(states.programModel.programSettings, (ps: any) => {
            const predicateListItem: any = _.find(lI, { id: ps.filterPredicateListItemId });
            const operatorListItem: any = _.find(lI, { id: ps.operatorListItemId });
            states.operators = _.filter(lI, { listId: 4 });

            // found a match
            if (predicateListItem && operatorListItem) {
                const predicate = predicateListItem;
                const operator = operatorListItem;
                ps.tableColumnName = predicate.key + '.' + predicate.value;
                ps.operatorValue = operatorListItem.key;
            }
        });
    }

    async saveHandler(states: IProgramModelEditStates) {
        states.saveInProgress = true;
        try {
            await this.dataAccess.genericUpsert({
                model: 'ProgramModel',
                data: states.programModel
            });
            if (states.programModel.programSettings) {
                const updatePromises: any[] = [];
                states.programModel.programSettings.forEach((ps: any) => {
                    updatePromises.push(
                        this.dataAccess.genericUpsert({
                            model: 'ProgramSetting',
                            data: ps
                        })
                    );
                });
                await Promise.all(updatePromises);
            }
            this.state.go('argos.ccgDesigner.programmodels.list');
            states.saveInProgress = false;
        } catch (e) {
            states.saveInProgress = false;
            this.alertSvc.handleError(e);
        }
    }
}
