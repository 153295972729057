import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ITeraUsersListStates, ITeraUsersListService } from './teraUsersList.component.d';
import { TeraUsersEditComponent } from '../teraUsersEdit/teraUsersEdit.component';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { ArgosStoreService } from '../../../services/argosStore.service';

@Injectable()
export class TeraUsersListService implements ITeraUsersListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog,
        private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {
    }

    async initDelegate(states: ITeraUsersListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: ITeraUsersListStates) {

        states.teraUsers = _.orderBy(await this.dataAccess.genericFind({
            model: 'TeraUser'
        }), 'ownerName');

        states.table.data = states.teraUsers;
    }

    setTablePageSize(states: ITeraUsersListStates) {
        states.pageSize = 25;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.teraUsers.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    editTeraUser(id: string, states: ITeraUsersListStates) {
        // teraUserToEdit = _.filter(states.teraUsers, user => user.id = id);
        // TODO: Handle case where user does not exist?
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            width: '1500px',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    teraUserId: id
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            TeraUsersEditComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async() => {
            this.matDialog.closeAll();
            await this.activate(states);
            this.cdr.detectChanges();
        });
    }

    exportToExcel(states: ITeraUsersListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: ITeraUsersListStates) {
        const data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
