// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-chart {
  color: black;
  margin-bottom: 15px;
  float: right;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/pipelineHistory/pipelineHistoryDetail/pipelineHistoryDetail.component.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,YAAA;AACF","sourcesContent":[".view-chart {\n  color: black;\n  margin-bottom: 15px;\n  float: right;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
