import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IPayerRatesMetaListStates, IPayerRatesMetaListService } from './payerRatesMetaList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import swal from 'sweetalert2';
import { ViewAiChatModalComponent } from '../../aiChat/aiChatModal/viewAiChatModal.component';

@Injectable()
export class PayerRatesMetaListService implements IPayerRatesMetaListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, private cdr: ChangeDetectorRef) {
        //
    }
    
    async initDelegate(states: IPayerRatesMetaListStates): Promise<Object> {
        await this.activate(states);
        return {};
    }
    
    async activate(states: IPayerRatesMetaListStates) {
        states.payerRateMeta = await this.dataAccess.genericFind({
            model: 'PayerRateMeta',
            filter: {
                fields: ['id', 'dataSource', 'payerId', 'url', 'lastRunDate', 'status', 'webscrapingType'],
                order: 'dataSource'
            }
        });

        states.payerRateMeta = states.payerRateMeta.map((meta: any) => {
            meta.lastRunDate = (meta.lastRunDate) ? new Date(meta.lastRunDate).toISOString().slice(0, 10) : undefined;
            return meta;
        });
        
        states.payerRateMeta = _.orderBy(states.payerRateMeta, ['dataSource']);
        states.table.data = states.payerRateMeta;
    }

    setTablePageSize(states: IPayerRatesMetaListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.payerRateMeta.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IPayerRatesMetaListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IPayerRatesMetaListStates) {
        const data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    async launchAiAnalysis(states: IPayerRatesMetaListStates) {
        const response = await this.dataAccess.genericMethod({
            model: 'ClarifyBot',
            method: 'get',
            parameters: {
                endpoint: 'ai_utils/payer_rates/tables'
            }
        });
        const responseJson = JSON.parse(response);
        states.tables = _.sortBy(responseJson.tables, ['name']);
        states.issuers = responseJson.issuers;

        const inputOptions: any = {};
        states.tables.forEach((table: any) => {
            inputOptions[table.name] = _.startCase(table.name);
        });

        swal({
            title: 'Select a table to analyze',
            type: 'question',
            input: 'select',
            inputOptions,
            inputPlaceholder: 'Select a table',
            showCancelButton: true,
            onOpen: (popup) => {
                const selectElement = popup.querySelector('select');
                const confirmButton = popup.querySelector('.swal2-confirm');
        
                // Initially disable the confirm button
                confirmButton.setAttribute('disabled', 'disabled');
        
                // Enable confirm button when an option is selected
                selectElement.addEventListener('change', () => {
                    if (selectElement.value) {
                        confirmButton.removeAttribute('disabled');
                    } else {
                        confirmButton.setAttribute('disabled', 'disabled');
                    }
                });
            },
            preConfirm: () => {
                const selectElement: any = document.querySelector('.swal2-popup select');
                return selectElement.value;
            }
        }).then((result: any) => {
            if (result.value) {
                const table = states.tables.find((t: any) => t.name === result.value);

                // if required_filter_fields then we need user to input value for each field
                const requiredFields: any = {};
                if (table.required_filter_fields && table.required_filter_fields.length > 0) {
                    const requiredFields: any = {};
                
                    // Mapping field names to indices
                    const fieldIndices: { [key: string]: number } = {
                        issuer_id: 0,
                        billing_class: 1,
                    };
                
                    let issuersData = states.issuers;
                
                    const fieldsText = table.required_filter_fields.map(_.startCase).join(' and ');
                
                    const swalHtml = `
                        <div class="swal2-input-group">
                            <input id="npi_input" class="swal2-input" placeholder="Optional NPI (10-digit number)" type="text" maxlength="10">
                            <div id="spinner" style="display:none; text-align:center; margin-top:10px;">
                                <i class="fa fa-spinner fa-spin fa-2x"></i>
                            </div>
                            <select id="required_fields_select" class="swal2-input" style="margin-top:10px;">
                                <option value="" disabled selected>Select ${fieldsText}</option>
                                <!-- Options will be populated dynamically -->
                            </select>
                        </div>
                    `;
                
                    swal({
                        title: 'Please select the required fields',
                        html: swalHtml,
                        showCancelButton: true,
                        onOpen: async (popup) => {
                            const npiInput = popup.querySelector('#npi_input') as HTMLInputElement;
                            const select = popup.querySelector('#required_fields_select') as HTMLSelectElement;
                            const confirmButton = popup.querySelector('.swal2-confirm') as HTMLButtonElement;
                            const spinner = popup.querySelector('#spinner') as HTMLDivElement;
                
                            // Function to populate the dropdown options
                            const populateDropdown = (issuers: any[]) => {
                                const fieldCombinations = issuers.map((item: any[]) => {
                                    const combination: any = {};
                                    table.required_filter_fields.forEach((field: string) => {
                                        const index = fieldIndices[field];
                                        combination[field] = item[index];
                                    });
                                    return combination;
                                });
                
                                const uniqueCombinations = _.uniqWith(fieldCombinations, _.isEqual);
                
                                const optionsHtml = uniqueCombinations
                                    .map((combination: any) => {
                                        const value = JSON.stringify(combination);
                                        const text = table.required_filter_fields
                                            .map((field: any) => combination[field])
                                            .join(' - ');
                                        return `<option value='${value}'>${text}</option>`;
                                    })
                                    .sort()
                                    .join('');
                
                                select.innerHTML = `<option value="" disabled selected>Select ${fieldsText}</option>${optionsHtml}`;
                            };
                
                            populateDropdown(issuersData);
                
                            // Initially disable the confirm button
                            confirmButton.setAttribute('disabled', 'disabled');
                
                            // Function to check if an option is selected
                            const checkSelection = () => {
                                if (select.value) {
                                    confirmButton.removeAttribute('disabled');
                                } else {
                                    confirmButton.setAttribute('disabled', 'disabled');
                                }
                            };
                
                            // Event listener for NPI input changes
                            npiInput.addEventListener('input', async () => {
                                const npiValue = npiInput.value.trim();
                                if (/^\d{10}$/.test(npiValue)) {
                                    spinner.style.display = 'block';
                                    select.setAttribute('disabled', 'disabled');
                                    
                                    try {
                                        const response = await this.dataAccess.genericMethod({
                                            model: 'ClarifyBot',
                                            method: 'get',
                                            parameters: {
                                                endpoint: 'ai_utils/payer_rates/issuers',
                                                body: { npi: npiValue },
                                            },
                                        });
                
                                        const responseJson = JSON.parse(response);
                                        issuersData = responseJson.issuers;
                
                                        populateDropdown(issuersData);
                                    } catch (error) {
                                        console.error('Error fetching issuers with NPI:', error);
                                    } finally {
                                        spinner.style.display = 'none';
                                        select.removeAttribute('disabled');
                                        checkSelection();
                                    }
                                } else if (npiValue.length === 0) {
                                    issuersData = states.issuers;
                                    populateDropdown(issuersData);
                                    checkSelection();
                                }
                            });
                
                            // Event listener for dropdown selection changes
                            select.addEventListener('change', checkSelection);
                        },
                        preConfirm: () => {
                            const select = document.getElementById('required_fields_select') as HTMLSelectElement;
                            const selectedCombination = JSON.parse(select.value);
                            Object.assign(requiredFields, selectedCombination);                            
                        },
                    }).then((isConfirm) => {
                        if (isConfirm.value) {
                            this.openChatModal(states, result.value, requiredFields);
                        }
                    });
                } else {
                    this.openChatModal(states, result.value, {});
                }
            }
        });
    }

    openChatModal(states: IPayerRatesMetaListStates, tableName: string, requiredFields: any) {
        let initialMessage = `Welcome to the AI Payer Rates Chat.\nPlease ask a question about the data in <strong>${tableName}</strong>. Word your questions as precisely as possible to avoid any ambiguity.`;
        if (Object.keys(requiredFields).length > 0) {
            const filterFieldsHtml = Object.keys(requiredFields).map((field: any) => `\n${field} = ${requiredFields[field]}`).join('');
            initialMessage += `\n\nPlease note that this table has the following filter fields:${filterFieldsHtml}`;
        }
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            width: '75%',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    endpoint: 'ai_utils/payer_rates/analyze',
                    modalName: 'Payer Rates Analysis',
                    apiParams: {
                        table_name: tableName,
                        table_filter_conditions: requiredFields,
                    },
                    initialMessage
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewAiChatModalComponent, dialogConfig);

        modalInstance.afterClosed().subscribe(async  (result: any) => {
            if (result?.reload) {
                this.launchAiAnalysis(states);
            }
        });
    }
}
