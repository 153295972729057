import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class EcsAppEnvironment {

    addEnvironmentVariable(containerTaskDef: any, singleTask: any, states: IEcsAppEditStates) {

        states.newEnvName = states.newEnvName.trim();

        // prevent duplicate env let entries
        const valueExistsCount = _.filter(singleTask.environment, function (envValue) {
            if (envValue.name.toLowerCase() === states.newEnvName.toLowerCase()) {
                return envValue;
            }
        });

        if (valueExistsCount.length > 0) {
            swal({
                title: 'Environment variable exists',
                text: 'Please remove the existing environment variable first',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            });

            return;
        }

        const env = {
            name: states.newEnvName,
            value: states.newEnvValue
        };

        singleTask.environment.push(env);

        // record the changes
        states.eventChanges.envVariableChanges[states.newEnvName] = true;
        states.eventChanges = this.addRollBackInfo(containerTaskDef.name, containerTaskDef.revisionNumber, states.eventChanges, states);

        states.newEnvName = '';
        states.newEnvValue = '';
    }

    deleteSetting(singleTask: any, key: any, states: IEcsAppEditStates) {
        for (let i = 0; i < singleTask.environment.length; i++) {
            if (singleTask.environment[i].name === key) {
                states.eventChanges.envVariableChanges[key] = false;
                singleTask.environment.splice(i, 1);

                // remove this env from hidden env if it exists
                const index = states.cluster.environment.hiddenEcsVariables.indexOf(key);
                if (index >= 0) {
                    states.cluster.environment.hiddenEcsVariables.splice(index, 1);
                }
                break;
            }
        }
        states.eventChanges = this.addRollBackInfo(null, null, states.eventChanges, states);
    }

    addRollBackInfo(taskName: string, revisionNumber: string, logArg: any, states: IEcsAppEditStates) {
        if (taskName && revisionNumber) {
            logArg.rollbackTaskDefName = taskName;
            logArg.rollbackTaskDefNumber = revisionNumber;
        }
        return logArg;
    }
}
