

const UI_CONST = {

    USER_ROLE_ADMIN: 'admin',
    USER_ROLE_INACTIVE: 'inactive',

    // ccg list
    CCG_START_DATE_COLUMN_NAME: 'episodeStartDate',
    CCG_END_DATE_COLUMN_NAME: 'episodeEndDate',
    CCG_BASE_TABLE_NAME: 'baseTable',
    CG_DATE_QUERY_THRESHOLD: 'ccgDateQueryThreshold',

    // ccg edit
    CG_MIN_START_DATE_NAME: 'ccgMinStartDate',
    CG_MAX_END_DATE_NAME: 'ccgMaxEndDate',
    CG_START_DATE_COLUMN_NAME: 'episodeStartDate',
    CG_END_DATE_COLUMN_NAME: 'episodeEndDate',
    CG_BASE_TABLE_NAME: 'baseTable',

    CCG_MIN_OFFSET_OPTS: [
        'START_YEAR',
        'PREV_YEAR',
        'START_QUARTER',
        'PREV_QUARTER',
        'START_MONTH',
        'PREV_MONTH',
        'YEAR',
        'QUARTER',
        'MONTH',
        'CCG_DEFAULT_DATE'
    ],

    CCG_MAX_OFFSET_OPTS: [
        'END_YEAR',
        'END_PREV_YEAR',
        'END_QUARTER',
        'END_PREV_QUARTER',
        'END_MONTH',
        'END_PREV_MONTH',
        'YEAR',
        'QUARTER',
        'MONTH',
        'CCG_DEFAULT_DATE'
    ],

    HIERARCHY_DELIMITER: ' / '
};

export const CONST = { ...UI_CONST };
