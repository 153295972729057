// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ul-collapse .panel-collapse {
  display: none;
}
.arrow-collapsed .accordion-toggle:before {
  transform: rotate(90deg);
}
.mat-expansion-panel .mat-expansion-panel-header {
  padding: 0 15px;
  font-size: 12px;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title span {
  font-size: 12px;
}
::ng-deep .mat-expansion-panel-body {
  padding: 0 24px 0px !important;
}
.mat-expansion-panel-header {
  height: 31px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 31px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/shared/navigation/navigation.component.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;AAAJ;AAGA;EAEI,wBAAA;AAFJ;AAKA;EAEI,eAAA;EACA,eAAA;AAJJ;AACA;EAMQ,eAAA;AAJR;AASA;EACI,8BAAA;AAPJ;AASA;EACI,YAAA;AAPJ;AASA;EACI,YAAA;AAPJ","sourcesContent":[".ul-collapse {\n  .panel-collapse {\n    display: none;\n  }\n}\n.arrow-collapsed {\n  .accordion-toggle:before {\n    transform: rotate(90deg);\n  }\n}\n.mat-expansion-panel {\n  .mat-expansion-panel-header {\n    padding: 0 15px;\n    font-size: 12px;\n    .mat-expansion-panel-header-title {\n      span {\n        font-size: 12px;\n      }\n    }\n  }\n}\n::ng-deep .mat-expansion-panel-body {\n    padding: 0 24px 0px !important;\n}\n.mat-expansion-panel-header {\n    height: 31px;\n}\n.mat-expansion-panel-header.mat-expanded {\n    height: 31px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
