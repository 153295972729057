import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IClientCareGroupingsListStates, IClientCareGroupingsListService } from './clientCareGroupingsList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { CONST } from '../../../constants/globals';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewEnvironmentUsageModalComponent } from '../../prismUsage/viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class ClientCareGroupingsListService implements IClientCareGroupingsListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private exportToExcelSvc: ExportToExcelService,
        private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef) {

    }
    async initDelegate(states: IClientCareGroupingsListStates): Promise<Object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IClientCareGroupingsListStates) {
        const clientCareGroupings = await this.dataAccess.genericMethod({
            model: 'ClientCareGrouping',
            method: 'getClientCareGroupings',
            parameters: {}
        });
        states.allClientCareGroupings = _.orderBy(clientCareGroupings, ['careGrouping', 'url']);
        states.clientCareGroupings = states.allClientCareGroupings;
        states.table.data = states.clientCareGroupings;

        const careGroupings = _.uniqBy(_.map(states.allClientCareGroupings, ccg => {
            return { name: ccg.careGrouping };
        }), 'name');
        states.careGroupings = _.union([{name: 'All'}], careGroupings);

        this.filterResults(states);
    }

    filterResults(states: IClientCareGroupingsListStates) {
        states.clientCareGroupings = _.filter(states.allClientCareGroupings, ccg => {
            const careGroupingFilter = (states.selectedCareGrouping === 'All' || ccg.careGrouping === states.selectedCareGrouping);
            const enabledFilter = (states.selectedEnabled === 'Yes' && ccg.enabled) || 
                (states.selectedEnabled === 'No' && !ccg.enabled) || 
                (states.selectedEnabled === 'All');
            return careGroupingFilter && enabledFilter;
        });
        states.table.data = states.clientCareGroupings;
    }

    setTablePageSize(states: IClientCareGroupingsListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.clientCareGroupings.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IClientCareGroupingsListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile('careGroupings', columns, data);
    }

    openMatTableSettings(states: IClientCareGroupingsListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.environmentMatTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
