import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { parse } from 'papaparse';
import * as _ from 'lodash';

@Injectable()
export class FileService {
    constructor() {
        //
    }

    public importFileHandler(event: any, cb: Function) {
        const reader = new FileReader();
        const file = event?.target?.files[0];
        if (file) {
            reader.readAsText(file);
            reader.onload = (event) => {
                cb(event.target?.result);
            };
        } else {
            cb('\n');
        }

    }

    uploadFileHandler(importFile: string): any[] {
        const lineCount = importFile.split('\n').length;
        if (lineCount > 501) {
            swal({
                title: 'Import limit error',
                text: 'You are not allowed to import more than a 500 records at a time',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            });
            return [];
        }

        // Empty file, or file with only header
        if (lineCount === 2) {
            return [];
        }
        const lines: any[] = parse(importFile).data || [];
        const headers: any[] = lines[0];
        const resultList = [];

        for (let i = 1; i < lines.length; i++) {
            const data = lines[i];
            if (data.length === headers.length) {
                const episode: any = {};
                for (let j = 0; j < headers.length; j++) {
                    _.set(episode, headers[j], data[j]);
                }
                resultList.push(episode);
            }
        }

        return resultList;
    }
}
