import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';

@Injectable()
export class ClarifyInsightsProvisionCustomerModalService {

    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService) { }

    async createNewCustomer(customerId: string, customerName: string, customerCode: string, awsAccountName: string, s3DestinationBucket: string, reuseAccount: boolean, byoA: boolean, awsAccountId: string, extractType: string) {
        const currentUser = await this.dataAccess.genericMethod({
            model: 'ArgosUser',
            method: 'getCurrentUser'
        });
        if (reuseAccount || byoA) {
            try {
                await this.dataAccess.genericCreate({
                    model: 'ClarifyInsightsCustomer',
                    data: {
                        customerId,
                        customerName,
                        customerCode,
                        awsAccountName,
                        s3DestinationBucket,
                        isProvisioned: true,
                        isActive: true,
                        createdDate: new Date(Date.now()),
                        createdBy: this.argosStore.getItem('username'),
                        accountProvisioningTicketNumber: null,
                        awsAccountId,
                        extractType
                    }
                });
                return `${customerCode} successfully created `;
            }
            catch (exception: any) {
                return `Adding customer ${customerCode} to database failed: ` + exception.body.error.message;
            }
        } else {
            const watcherJira = await this.dataAccess.genericMethod({
                model: 'JiraApi',
                method: 'getUserId',
                parameters: {
                    email: currentUser.email
                }
            });
            const watcherJiraName = watcherJira.displayName;
            const jiraResponse = await this.dataAccess.genericMethod({
                model: 'JiraApi',
                method: 'createIssue',
                parameters: {
                    project: 'IT',
                    title: `Clarify Insights Account Provisioning Request ${customerName}`,
                    description: `
            Clarify Insights new customer provisioning request
            AWS Account Name: **${awsAccountName}**
    
            The following roles need to be generated in ${awsAccountName} all including the "AdministratorAccess" policy. Please ensure the role naming matches
            exactly to ensure downstream automation processes function as intended:
            1. Admin SSO Role: **Clarify${customerCode}Admin**
            2. Admin non-SSO Role: **ClarifyAdmin**. Please include the following trust relationship as well:
            {"Version": "2012-10-17", "Statement": [{"Effect": "Allow", "Principal": {"AWS": "arn:aws:iam::947634201780:role/data_exchange_service_role"}, "Action": "sts:AssumeRole"}]}
            3. Customer Admin non-SSO Role: **${customerCode}Admin**
            
            **Steps to finalize customer setup and close this ticket:**
            1. Please provide ${watcherJiraName} with the AWS account ID as a Slack message or via email.
            2. Mark this ticket as Done. This will enable the "finalize" step in the UI and notify ${watcherJiraName}.
            3. ${watcherJiraName} will finalize the customer setup via the UI.
            `,
                    assigneeId: '5b4e79a0bdb143114a16cb68'
                }
            });
    
            if (jiraResponse.isSuccessful) {
                const jiraTicketId = jiraResponse.response.key;
                try {
                    await this.dataAccess.genericCreate({
                        model: 'ClarifyInsightsCustomer',
                        data: {
                            customerId,
                            customerName,
                            customerCode,
                            awsAccountName,
                            s3DestinationBucket,
                            isProvisioned: false,
                            isActive: true,
                            createdDate: new Date(Date.now()),
                            createdBy: this.argosStore.getItem('username'),
                            accountProvisioningTicketNumber: jiraTicketId,
                            extractType
                        }
                    });
                    return `${customerCode} successfully created and logged ${jiraTicketId}`;
                }
                catch (exception: any) {
                    return `Adding customer ${customerCode} to database failed: ` + exception.body.error.message;
                }
            }
            else {
                return 'Creating Jira ticket failed: ' + jiraResponse.error.message;
            }
        }
    }

    async isCustomerCodeUnique(customerCode: string, isAdditionalExtractType: boolean) {
        let isUnique = true;
        if (isAdditionalExtractType) {
            return isUnique;
        }
        const customers: string[] = await this.dataAccess.genericMethod({
            model: 'ClarifyInsightsCustomer',
            method: 'getAllCustomersDistinct'
        });
        const customerCodes = customers.map((customer) => customer.toLowerCase());
        if (customerCodes.includes(customerCode.toLowerCase())) {
            isUnique = false;
        }
        const allDataSources = await this.dataAccess.genericFind({
            model: 'DataSource'
        });
        const dataSources = allDataSources.filter((dataSource: any) => dataSource.name.toLowerCase() === customerCode.toLowerCase());
        if (dataSources.length > 0) {
            isUnique = false;
        }
        return isUnique;
    }
        
}
