import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IPrismUsageStates } from './prismUsage.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PrismUsageService } from './prismUsage.component.service';
import * as moment from 'moment';

@Component({
    selector: 'argos-prismUsage',
    templateUrl: './prismUsage.component.html',
    providers: [PrismUsageService]
})

export class PrismUsageComponent extends ReactComponentBase<{}, IPrismUsageStates, {}> implements OnInit {

    constructor(private svc: PrismUsageService, private cdr: ChangeDetectorRef) {
        super({
            metaDataTypes: [{
                type: 'careGrouping',
                name: 'Care Groupings',
                linkType: 'careGrouping'
            }, {
                type: 'metric',
                name: 'Metrics',
                linkType: 'metric'
            }, {
                type: 'grouping',
                name: 'Group Bys',
                linkType: 'groupBy'
            }, {
                type: 'filter',
                name: 'Filters',
                linkType: 'filter'
            }, {
                type: 'patient_cohort',
                name: 'Cohorts'
            }, {
                type: 'benchmark',
                name: 'Benchmarks'
            }, {
                type: 'scorecard',
                name: 'Scorecards'
            }, {
                type: 'dashboard',
                name: 'Dashboards'
            }],
            spotlightUsageSummary: [],
            summaryKeys: {},
            usedBy: [],
            filterByUsageType: '*',
            environmentOptions: [{
                id: 'client_prod',
                label: 'Client Production Environments'
            }, {
                id: 'demo',
                label: 'Demo Environments'
            }, {
                id: 'dev_qa',
                label: 'QA Environments'
            }],
            selectedEnvironmentOptions: [{
                id: 'client_prod',
                label: 'Client Production Environments'
            }, {
                id: 'demo',
                label: 'Demo Environments'
            }, {
                id: 'dev_qa',
                label: 'QA Environments'
            }],
            selectedEnvironmentId: null,
            activeTab: 0,
            dropdownEnvironmentOptionsSettings: {
                singleSelection: false,
                idField: 'id',
                textField: 'label',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 5,
                allowSearchFilter: false
            }            
        } as unknown as IPrismUsageStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        await this.fetchResults();
        this.cdr.detectChanges();
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
    }

    async filterChanged() {
        await this.svc.filterChanged(this.states);
        this.cdr.detectChanges();
    }

    async syncUsage() {
        await this.svc.syncUsage(this.states);
        this.cdr.detectChanges();
    }

    async fetchResults() {
        await this.svc.fetchResults(this.states);
        this.cdr.detectChanges();
    }

    async singleEnvironmentSelected() {
        this.svc.singleEnvironmentSelected(this.states);
        this.cdr.detectChanges();
    }

    exportToExcel(usageType: any) {
        this.svc.exportToExcel(usageType);
    }

    viewItem(usageType: any, row: any) {
        this.svc.viewItem(usageType, row, this.states);
    }

    viewEnvironments(type: any, name: any) {
        this.svc.viewEnvironments(type, name, this.states);
    }

    sortData(event: any, metaDataType: any) {
        this.svc.sortData(event, metaDataType, this.states);
    }

    searchMetaDataUsage(event: any, metaDataType: any) {
        this.svc.filterData(event, metaDataType, this.states);
    }

    momentFromNow(_date: any) {
        return moment(_date).fromNow();
    }
}
