import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ICareGroupingsListStates } from './careGroupingsList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CareGroupingsListService } from './careGroupingsList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-careGroupingsList',
    templateUrl: './careGroupingsList.component.html',
    providers: [CareGroupingsListService],
    styleUrls: ['./careGroupingsList.component.less']
})

export class CareGroupingsListComponent
    extends ReactComponentBase<{}, ICareGroupingsListStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;

    constructor(private svc: CareGroupingsListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService
    ) {
        super({
            pageSize: 50,
            fetchingMetaData: true,
            filterObj: {},
            defaultDisplayMatTableColumns: ['copyButton', 'viewButton', 'name', 'title', 'displayBaseTable', 'excludeFromSyncText', 'usageCount', 'viewCount', 'lastViewed', 'markForDeletionButton'],
            matTableColumns: ['copyButton', 'viewButton', 'name', 'title', 'displayBaseTable', 'assignable', 'description', 'excludeFromSyncText', 'usageCount', 'viewCount', 'lastViewedFromNow', 'markForDeletionButton'],
            table: new MatTableDataSource([]),
            environmentMatTableSettingName: 'careGroupingsList',
            careGroupings: []
        } as unknown as ICareGroupingsListStates);
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.environmentMatTableSettingName);
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.table.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    bulkStoreMetaData() {
        this.svc.bulkStoreMetaData(this.states);
    }

    ngAfterViewInit(): void {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.table.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.table.filter);
    }

    searchCareGroupings(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.table.filter = filterValue.trim().toLowerCase();
    }

    pageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        this.states.tableShowRows = this.states.careGroupings.slice(page, page + event.pageSize);
        this.states.table = new MatTableDataSource(this.states.tableShowRows);
    }

    viewEnvironments(row: any) {
        this.svc.viewEnvironments(row);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    markForDeletion(row: any) {
        this.svc.markForDeletion(row);
    }

    undelete(row: any) {
        this.svc.undelete(row);
    }

    // pagination functions
    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.environmentMatTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }
}
