import { Injectable } from '@angular/core';
import { IViewEnvironmentUsageModalProps, IViewEnvironmentUsageModalStates, IViewEnvironmentUsageModalService } from './viewEnvironmentUsageModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';

@Injectable()
export class ViewEnvironmentUsageModalService implements IViewEnvironmentUsageModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(props: IViewEnvironmentUsageModalProps, states: IViewEnvironmentUsageModalStates) {
        states.metaDataTypes = props.metaDataTypes;
        states.metaDataNames = props.metaDataNames;
        states.usageTypes = props.usageTypes;
        states.environmentTypes = props.environmentTypes;
        states.environmentId = props.environmentId;
        await this.fetchUsages(states);
        return {};
    }

    async changeDelegate(oldProps: IViewEnvironmentUsageModalProps, newProps: IViewEnvironmentUsageModalProps, states: IViewEnvironmentUsageModalStates) {
        this.initDelegate(newProps, states);
        await this.fetchUsages(states);
        return {};
    }

    async fetchUsages(states: IViewEnvironmentUsageModalStates) {
        states.environmentUsages = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage',
            method: 'getEnvironmentUsageResults',
            parameters: {
                metaDataTypes: states.metaDataTypes,
                metaDataNames: states.metaDataNames,
                usageTypes: states.usageTypes,
                environmentTypes: states.environmentTypes,
                environmentId: states.environmentId
            }
        });
    }

}
