import { Injectable } from '@angular/core';
import { ICypressTestResultDetailStates, ICypressTestResultDetailService } from './cypressFeatureTestResultDetail.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';
import { MatTableDataSource } from '@angular/material/table';
import { PlatformReleaseResultsService } from '../../../services/platformReleaseResultsService.service';

@Injectable()
export class CypressFeatureTestResultDetailService implements ICypressTestResultDetailService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter, private platformReleaseResultsService: PlatformReleaseResultsService,
        private state: StateService) {
        //
    }

    async initDelegate(states: ICypressTestResultDetailStates): Promise<object> {
        await this.fetchResults(states);
        return {};
    }

    async fetchResults(states: ICypressTestResultDetailStates) {
        let grouping = this.uiRouter.globals.params.id === 'Pending' ? '' : this.uiRouter.globals.params.id;
        let cypressTestResults = await this.dataAccess.genericFind({
            model: 'CypressTestResults',
            filter: {
                where: {
                    feature_grouping: { like: grouping }
                },
                order: 'test_ran_at desc'
            },
        });

        const latestRunDate = cypressTestResults[0].test_ran_at;

        cypressTestResults = _(cypressTestResults)
            .filter((obj: any) => obj.test_ran_at === latestRunDate)
            .map((obj: any) => ({ 
                ...obj, 
                test_file_name: obj.test_file_name.split('/').pop(),
                duration: `${Math.floor((parseInt(obj.duration)/1000) * 100)/100} seconds`,
                test_ran_at: new Date(obj.test_ran_at).toLocaleDateString('es-pa')
            }))
            .value();

        const featurePassed: any = _.chain(cypressTestResults).filter(res => res.status === "passed").value();
        const featureFailed: any = _.chain(cypressTestResults).filter(res => res.status === "failed").value();
        const featurePending: any = _.chain(cypressTestResults).filter(res => res.status === "pending").forEach(res => res.feature_grouping="Pending").value();
            
        states.cypressFeatureResultsSummary = { passes: featurePassed.length, failures: featureFailed.length, pendings: featurePending.length }
        states.feature_grouping = this.uiRouter.globals.params.id;
        states.latestRunDate = new Date(latestRunDate).toLocaleDateString('es-pa');
        states.cypressTestResultsDetail = cypressTestResults;
        states.cypressTestResultsDataTable.data = cypressTestResults;
        states.displayedColumns = ['test_title', 'status', 'environment', 'duration', 'test_file_name', 'comments'];
    }

}
