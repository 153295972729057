// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-selector {
    margin-top: 10px;
    margin-bottom: 10px;
  }

.mat-form-field {
  margin-left: 20px;
}

th {
  white-space: nowrap;
  text-align: center;
}

td.no-wrap{
  white-space: nowrap;
  text-align: center;
}

div.history-table {
  height: calc(35vh);
}

div.delivery-table {
  height: calc(60vh);
}

div.div-center {
  text-align: center;
}

div.header-div{
  margin-top: 10px;
  margin-bottom: 10px;
}

.mat-label-bold {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/clarifyInsights/clarifyInsightsDeliveryHub/clarifyInsightsDeliveryHub.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;EACrB;;AAEF;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".filter-selector {\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n\n.mat-form-field {\n  margin-left: 20px;\n}\n\nth {\n  white-space: nowrap;\n  text-align: center;\n}\n\ntd.no-wrap{\n  white-space: nowrap;\n  text-align: center;\n}\n\ndiv.history-table {\n  height: calc(35vh);\n}\n\ndiv.delivery-table {\n  height: calc(60vh);\n}\n\ndiv.div-center {\n  text-align: center;\n}\n\ndiv.header-div{\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.mat-label-bold {\n  font-weight: bold;\n  font-size: 20px;\n  margin-bottom: 5px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
