import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ICcgFeatureListStates } from './ccgFeatureList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CcgFeatureListService } from './ccgFeatureList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-ccgFeatureList',
    templateUrl: './ccgFeatureList.component.html',
    providers: [CcgFeatureListService]
})

export class CcgFeatureListComponent extends ReactComponentBase<{}, ICcgFeatureListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: CcgFeatureListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            ecsClusterNames: [],
            ccgFeatures: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['name', 'description', 'pythonModule', 'version', 'dependencyDepth'],
            matTableColumns: ['name', 'description', 'pythonModule', 'version', 'dependencyDepth'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'ccgFeatureList'
        } as unknown as ICcgFeatureListStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    searchCcgFeatures(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.ccgFeatures.filter = filterValue.trim().toLowerCase();
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        let result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }
}
