import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IAnalyzeMetaDataModalProps, IAnalyzeMetaDataModalStates } from './analyzeMetaDataModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { AnalyzeMetaDataModalService } from './analyzeMetaDataModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-analyzeMetaDataModal',
    templateUrl: './analyzeMetaDataModal.component.html',
    providers: [AnalyzeMetaDataModalService]
})

export class AnalyzeMetaDataModalComponent extends ReactComponentBase<IAnalyzeMetaDataModalProps, IAnalyzeMetaDataModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: AnalyzeMetaDataModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<AnalyzeMetaDataModalComponent>) {
        super({
            loading: true,
            selectedProviderType: 0
        } as IAnalyzeMetaDataModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.props, this.states);
        this.setStates(newStates);
        this.states.selectedProviderType = this.states.data[0];
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        const newStates = await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.setStates(newStates);
    }

    close() {
        this.dialogRef.close({ eventName: 'cancel' });
    }

    exportToExcel() {
        this.svc.exportToExcelHandler(this.states);
    }

    analyzeObservation(title: string, observationType: string, observations: any) {
        this.svc.analyzeObservationHandler(title, observationType, observations, this.states);
    }
}
