import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IDataDashboardStates, IDataDashboardService } from './dataDashboard.component.d';
import { Edge, Node, ClusterNode } from '@swimlane/ngx-graph';
import { UIRouter } from '@uirouter/core';
import * as _ from 'lodash';

@Injectable()
export class DataDashboardServices implements IDataDashboardService {

    readonly NODE_COLORS = {
        pre_raw: '#50abcc',
        raw: '#7ed3ed',
        abstract: '#a27ea8',
        enriched: '#7aa3e5',
        ccg: '#a95963',
        predictions: '#a8385d',
        predictions_combiner: '#95a838',
        post_predictions: '#FBE698',
        market_share: '#FBE698',
        contract: '#95a838',
        pce: '#FBE698',
    };

    readonly HISTORICAL_EXTRACTS_TO_DISPLAY = 4;

    readonly stageOrder = ['pre_raw', 'raw', 'abstract', 'enriched', 'ccg', 'predictions', 'predictions_combiner', 'post_predictions'];

    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter) {
    }

    async initDelegate(states: IDataDashboardStates, dataSource: string, dataSources: string[]): Promise<object> {
        await this.init(states, dataSource, dataSources);
        return states;
    }

    async init(states: IDataDashboardStates, dataSource: string, dataSources: string[]) {
        let allInfo;
        if (dataSource == 'all') {
            dataSources = dataSources.filter((dataSource: string) => dataSource !== 'all');
            allInfo = await this.query(dataSources, false);
        }
        else {
            allInfo = await this.query(Array(this.HISTORICAL_EXTRACTS_TO_DISPLAY).fill(dataSource), true);
        }
        states.nodes = allInfo['allNodes'];
        states.edges = allInfo['allEdges'];
        states.clusters = allInfo['allClusters'];
    }

    async query(configuredDataSources: string[], showPreviousExtracts: boolean) {
        const allNodes: any[] = [];
        const allEdges: any[] = [];
        const allClusters: any[] = [];

        for (let i = 0; i < configuredDataSources.length; i++) {
            let extractIndex = showPreviousExtracts ? i : 0;
            const dataSourceInfo = await this.querySteps(configuredDataSources[i], extractIndex);
            allNodes.push(...dataSourceInfo['dataSourceNodes']);
            allEdges.push(...dataSourceInfo['dataSourceEdges']);
            allClusters.push(...dataSourceInfo['dataSourceClusters']);
        }
        const dataSourceCluster = this.formatDataSourceCluster(allClusters);
        allClusters.push(dataSourceCluster);
        return { allNodes, allEdges, allClusters };
    }

    async querySteps(dataSource: string, extractIndex: number) {
        const preRawRuns = await this.dataAccess.genericFind({
            model: 'DataDashboard',
            filter: {
                where: {
                    dataSource: dataSource,
                    or: [{subStage: 'customer_transfer'}, {subStage: 'gt_pre_raw'}]
                },
                order: 'fileSetDate desc, run_id desc',
                limit: (extractIndex + 1)
            }
        });
        if (preRawRuns.length < extractIndex + 1) { 
            return {dataSourceNodes: [], dataSourceEdges: [], dataSourceClusters: []};
        }
        let allRuns = await this.dataAccess.genericFind({
            model: 'DataDashboard',
            filter: {
                where: { and: [
                    { dataSource: dataSource},
                    { precedentFileHashes: { like: `%${preRawRuns[extractIndex]['fileSetHash']}%`}}
                ]}
            },
        });

        const preRawFileDate = preRawRuns[extractIndex].fileSetDate;
        let ccgRunIds = await this.getAllRunIdsByStage('ccg', null, dataSource);
        const pceRunIds = await this.getAllRunIdsByStage('ccg', 'pce', dataSource);
        ccgRunIds = ccgRunIds.concat(pceRunIds); 
        const marketShareRunsIds = await this.getAllRunIdsByStage('ccg', 'market_share', dataSource);
        const enrichedRunsIds = await this.getAllRunIdsByStage('enriched', null, dataSource);
        const eligibleStages: any[] = this.getEligibleStages(allRuns, this.stageOrder);
        const eligibleRuns: any[] = allRuns.filter((run: { stage: string | any[]; }) => eligibleStages.includes(run.stage));
        let displayRuns: any[] = [];
        let dataSourceTitleCard = undefined;

        eligibleStages.forEach((stage: any) => {
            let stageRuns = eligibleRuns.filter((run: any) => (run.stage === stage));
            // drop duplicate run ids
            stageRuns = [...new Map(stageRuns.map(run => [run.runId + run.subStage, run])).values()];
            stageRuns.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
            if (stage === 'pre_raw') {
                const preRawSubStages: string[] = ['sync', 'customer_transfer', 'deid', 'gt_pre_raw'];
                preRawSubStages.forEach((subStage: any) => {
                    const subStageRuns = stageRuns.filter((stageRun: any) => (stageRun.subStage === subStage));
                    if (subStageRuns.length > 0) {
                        const displayRun = subStageRuns.reduce((prev: any, current: any) => (prev.runId > current.runId) ? prev : current);
                        displayRuns.push(displayRun);
                    }
                });
            }
            else if (stage === 'ccg') {
                let displayRunsTemp = []; //allow sort before pushing to displayRuns
                let currentStageRuns = stageRuns;
                currentStageRuns.forEach((stageRun: any) => {
                    stageRun.runType = (stageRun.dagId.includes('incremental')) ? '_Incremental' : (stageRun.dagId.includes('feature-rerun')) ? '_Feature_rerun' : 'Full';
                });
                const subStageRuns = currentStageRuns.filter((run: any) => (run.subStage));
                const allCcgRuns = currentStageRuns.filter((run: any) => (!run.subStage));
                const uniqueCcgs = new Set(allCcgRuns.map(a => a.ccgName))
                for (const ccg of uniqueCcgs) {
                    let ccgRuns = allCcgRuns.filter((run: any) => (run.ccgName === ccg));
                    const runTypes = ['_Incremental', '_Feature_rerun', 'Full' ]
                    for (const runType of runTypes) {
                        const typeRuns = ccgRuns.filter((run: any) => (run.runType == runType));
                        if (typeRuns.length > 0) {
                            typeRuns.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
                            const displayRun = typeRuns.reduce((prev: any, current: any) => (prev.runId > current.runId) ? prev : current);
                            displayRunsTemp.push(displayRun);
                        }
                    };
                };
                subStageRuns.forEach((stageRun: any) => {
                    subStageRuns.sort((a: any, b: any) => b.subStage.localeCompare(a.subStage));
                    stageRun.runType = (stageRun.subStage == 'contract') ? '' : stageRun.runType;
                    displayRunsTemp.push(stageRun);
                });
                displayRunsTemp.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
                displayRuns = displayRuns.concat(displayRunsTemp);
            }
            else if (stage === 'predictions') {
                const uniqueCcgs = new Set(stageRuns.map(a => a.ccgName));
                for (const ccg of uniqueCcgs) {
                    const runsByCcg = stageRuns.filter((run: any) => (run.ccgName === ccg));
                    const uniqueMetrics = new Set(runsByCcg.map(a => a.metricType))
                    for (const uniqueMetric of uniqueMetrics) {
                        const metricRuns = runsByCcg.filter((run: any) => (run.metricType === uniqueMetric));
                        metricRuns.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
                        const displayRun = metricRuns.reduce((prev: any, current: any) => (prev.runId > current.runId) ? prev : current);
                        const metricParsed = this.parseMetricType(displayRun.metricType, displayRun.ccgName);
                        displayRun.payerGroup = metricParsed['payerGroup'];
                        displayRun.runYear = metricParsed['runYear'];
                        displayRun.metricType = (displayRun.metricType) ? displayRun.metricType : 'None';
                        displayRuns.push(displayRun);
                    };
                };
            }
            else if (stage === 'predictions_combiner') {
                stageRuns.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
                for (let stageRun of stageRuns) {
                    let filteredRun = this.filterRunIdsByStage(stageRun, ccgRunIds);
                    stageRun = filteredRun['stageRun'];
                    stageRun.ccgRunId = (filteredRun['runIdstoFilter'].length > 0) ? filteredRun['runIdstoFilter']: '';
                    displayRuns.push(stageRun);
                }
            }
            else if (stage === 'post_predictions') {
                stageRuns.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
                for (let stageRun of stageRuns) {
                    let filteredRun = this.filterRunIdsByStage(stageRun, ccgRunIds);
                    stageRun = filteredRun['stageRun'];
                    stageRun.ccgRunId = (filteredRun['runIdstoFilter'].length > 0) ? filteredRun['runIdstoFilter']: '';
                    filteredRun = this.filterRunIdsByStage(stageRun, marketShareRunsIds);
                    stageRun = filteredRun['stageRun'];
                    stageRun.marketShareRunId = (filteredRun['runIdstoFilter'].length > 0) ? filteredRun['runIdstoFilter']: '';
                    filteredRun = this.filterRunIdsByStage(stageRun, enrichedRunsIds);
                    stageRun = filteredRun['stageRun'];
                    stageRun.enrichedRunId = (filteredRun['runIdstoFilter'].length > 0) ? filteredRun['runIdstoFilter']: '';
                    displayRuns.push(stageRun);
                }
            }
            else {
                if (stage == 'raw' && ['iguana_v3', 'iguana_medicaid_v3'].includes(dataSource)) {
                    const csvToParquetRuns = stageRuns.filter(x => x.subStage == 'csv_to_parquet');
                    csvToParquetRuns.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
                    const csvToParquetDisplayRuns = csvToParquetRuns.reduce((prev: any, current: any) => (prev.runId > current.runId) ? prev : current);
                    displayRuns.push(csvToParquetDisplayRuns);

                    const splitRuns = stageRuns.filter(x => x.subStage == 'split');
                    if (splitRuns.length > 0 ) { 
                        splitRuns.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
                        const splitDisplayRuns = splitRuns.reduce((prev: any, current: any) => (prev.runId > current.runId) ? prev : current);
                        displayRuns.push(splitDisplayRuns);
                    }
                }
                else {
                    stageRuns.sort((a: any, b: any) => b.runId.localeCompare(a.runId));
                    displayRuns = displayRuns.concat(stageRuns);
                }
            }
        });
        const dataSourceNodes = await this.formatNodes(displayRuns, preRawFileDate);
        dataSourceTitleCard = this.formatTitleCard(preRawRuns[extractIndex]);
        dataSourceNodes.push(dataSourceTitleCard);
        const dataSourceEdges = this.formatEdges(displayRuns);
        const dataSourceClusters = this.formatClusters(displayRuns, dataSource, dataSourceTitleCard, eligibleStages);
        return { dataSourceNodes, dataSourceEdges, dataSourceClusters };
    }

    async formatNodes(displayRuns: any[], preRawFileDate: string) {
        const dataSourceNodes: Node[] = [];
        for (const run of displayRuns) {
            let id = '';
            let bgColor: string | undefined = undefined;
            let runStage: string | undefined = undefined;
            let dagVariation = run.dagId.replace(`-${run.dataSource}`, '').replace('-pipeline','').replace(/^-/, '');
            if (run.stage === 'pre_raw') {
                id = `${run.subStage}_${run.dataSource}_${run.id}`;
                if (run.subStage == 'customer_transfer') {
                    dagVariation = 'sync';
                }
                else if (run.subStage == 'gt_pre_raw') {
                    dagVariation = 'gt_pre_raw';
                }
                else {
                    dagVariation = 'deid';
                }
            }
            else if (run.stage === 'ccg') {
                id = `${run.ccgName}_${run.dataSource}${run.runType}_${run.id}`;
                dagVariation = dagVariation.replace(`ccg-`, '');
                bgColor = (run.subStage) ? _.get(this.NODE_COLORS, run.subStage) : undefined;
                runStage = (run.subStage == 'market_share' || run.subStage == 'contract' || run.subStage == 'pce') ? run.subStage : undefined;
            }
            else if (run.stage === 'predictions') {
                id = `${run.stage}_${run.ccgName}_${run.dataSource}_${run.metricType}_${run.id}`;
                dagVariation = dagVariation.replace(`prediction-`, '');
            }
            else if (run.stage === 'predictions_combiner') {
                id = `${run.stage}_${run.ccgName}_${run.dataSource}_${run.id}`;
                dagVariation = (dagVariation.includes('incremental')) ? 'incremental' : 'full';
            }
            else if (run.stage === 'post_predictions') {
                id = `${run.stage}_${run.subStage}_${run.dataSource}_${run.id}`;
                runStage = run.subStage
            }
            else if (run.stage === 'raw' && ['iguana_v3', 'iguana_medicaid_v3'].includes(run.dataSource)) {
                id = `${run.stage}_${run.dataSource}_${run.id}`;
                dagVariation = (run.subStage == 'csv_to_parquet') ? 'csv_to_parquet' : 'split';
            }
            else {
                id = `${run.stage}_${run.dataSource}_${run.id}`;
            }

            let timeToProdDeployment;
            if (run.deployedToProd) {
                let dateDeployedToProd: any = new Date(run.dateDeployedToProd);
                let preRawFileDateConverted: any = new Date(preRawFileDate);
                timeToProdDeployment = (dateDeployedToProd - preRawFileDateConverted) / 1000 / 60 / 60 / 24;
            }
            const runCard: Node = {
                id,
                label: run.dagId,
                data: {
                    dataSource: run.dataSource,
                    dagVariation: dagVariation,
                    runStage: (runStage) ? runStage : run.stage,
                    runId: run.runId,
                    bgColor: (bgColor) ? bgColor : _.get(this.NODE_COLORS, run.stage),
                    titleCard: false,
                    displayCcg: run.ccgName,
                    runType: (run.runType) ? run.runType.replace('_', '') : '',
                    metricType: run.metricType,
                    payerGroup: run.payerGroup,
                    runYear: run.runYear,
                    isPredictions: (run.metricType || run.payerGroup || run.runYear) ? true : false,
                    ccgRunId: (run.ccgRunId) ? run.ccgRunId: '',
                    marketShareRunId: (run.marketShareRunId) ? run.marketShareRunId: '',
                    enrichedRunId: (run.enrichedRunId) ? run.enrichedRunId: '',
                    dateDeployedToStaging: run.deployedToStaging ? run.dateDeployedToStaging : '',
                    elapsedTimeToProd: timeToProdDeployment !== undefined ? timeToProdDeployment : '',
                    additionalInfo: await this.getAdditionalInfo(run.runId, run.stage, run.dataSource)
                }
            };
            dataSourceNodes.push(runCard);
        };
        return dataSourceNodes;
    }

    formatTitleCard(preRawRun: any) {
        const formattedDataSource = preRawRun.dataSource.replace('iguana', 'ig').replace('memorial_hermann', 'mem_herm').replace('medicaid_v3', 'medicaid');
        const dataSourceTitleCard: Node = {
            id: `${preRawRun.dataSource}_title_card_${preRawRun.id}`,
            label: `${formattedDataSource}_title_card`,
            data: {
                dataSource: formattedDataSource,
                fileSetDate: preRawRun.fileSetDate,
                titleCard: true,
                bgColor: 'gray'
            }
        };
        return dataSourceTitleCard;
    }

    getEdgeMaps(sourceRunInfo: any, run: any) {
        const sourceRowId = sourceRunInfo[0]['id'];
        const sourceRunCcgName = sourceRunInfo[0]['ccgName'];
        const defaultId = `link_${run.dataSource}_${run.stage}_${run.id}`
        const edgeMaps: any = {
            'pre_raw': {
                'id': `link_${run.dataSource}_${run.subStage}_${run.id}`,
                'sourceRun': `customer_transfer_${run.dataSource}_${sourceRowId}`,
                'targetRun': `${run.subStage}_${run.dataSource}_${run.id}`
            },
            'abstract': {
                'id': defaultId,
                'sourceRun' : `raw_${run.dataSource}_${sourceRowId}`,
                'targetRun' : `${run.stage}_${run.dataSource}_${run.id}`
            },
            'ccg': {
                'id' : `link_${run.dataSource}_${run.stage}_${run.ccgName}${run.runType}_${run.id}`,
                'sourceRun' : `enriched_${run.dataSource}_${sourceRowId}`,
                'targetRun' : `${run.ccgName}_${run.dataSource}${run.runType}_${run.id}`
            },
            'predictions': {
                'id' : `link_${run.dataSource}_${run.stage}_${run.ccgName}_${run.metricType}_${run.id}`,
                'sourceRun' : `${this.translateCcgName(run.ccgName)}_${run.dataSource}${sourceRunInfo[0]['runType']}_${sourceRowId}`,
                'targetRun' : `predictions_${run.ccgName}_${run.dataSource}_${run.metricType}_${run.id}`
            },
            'predictions_combiner': {
                'id' : `link_${run.dataSource}_${run.stage}_${run.ccgName}_${run.id}`,
                'sourceRun' : `predictions_${sourceRunCcgName}_${run.dataSource}_${sourceRunInfo[0]['metricType']}_${sourceRowId}`,
                'targetRun' : `${run.stage}_${run.ccgName}_${run.dataSource}_${run.id}`
            },
            'post_predictions': {
                'id' : `link_${run.dataSource}_${sourceRunCcgName}_${run.stage}_${run.subStage}_${run.id}`,
                'sourceRun' : `predictions_combiner_${sourceRunCcgName}_${run.dataSource}_${sourceRowId}`,
                'targetRun' : `${run.stage}_${run.subStage}_${run.dataSource}_${run.id}`
            },
            'raw': {
                'id' : defaultId,
                'sourceRun' : `deid_${run.dataSource}_${sourceRowId}`,
                'targetRun' : `${run.stage}_${run.dataSource}_${run.id}`
            }
        };
        const defaultMap = {
            'id' : defaultId,
            'sourceRun' : `${sourceRunInfo[0]['stage']}_${run.dataSource}_${sourceRowId}`,
            'targetRun' : `${run.stage}_${run.dataSource}_${run.id}`
        }
        return edgeMaps[run.stage] || defaultMap;
    }

    formatEdges(displayRuns: any[]) {
        const dataSourceEdges: Edge[] = [];
        displayRuns.forEach((run: any) => {
            if (run.subStage === 'customer_transfer' || run.subStage === 'gt_pre_raw') {
                const nodeEdge: Edge = {
                    id: `link_title_${run.dataSource}`,
                    source: `${run.dataSource}_title_card_${run.id}`,
                    target: `${run.subStage}_${run.dataSource}_${run.id}`,
                    label: '',
                };
                dataSourceEdges.push(nodeEdge);
            }
            else {
                run.precedentRunIds.forEach((precedentRunId: any) => {
                    let sourceRunInfo: any = displayRuns.filter(precedent => precedent.runId === precedentRunId && precedent.stage != run.stage);
                    sourceRunInfo = (run.stage === 'abstract') ? sourceRunInfo.filter((sourceRun: any) => sourceRun.stage === 'raw') : sourceRunInfo;
                    if (sourceRunInfo.length === 0) {
                        return;
                    }
                    const edgeMaps = this.getEdgeMaps(sourceRunInfo, run);
                    const { id, sourceRun, targetRun } = edgeMaps;
                    const nodeEdge: Edge = {
                        id: id,
                        source: (run.stage === 'raw' && this.getGtDataSources().includes(run.dataSource)) ? `gt_pre_raw_${run.dataSource}_${sourceRunInfo[0]['id']}` : sourceRun,
                        target: targetRun,
                        label: '',
                    };
                    dataSourceEdges.push(nodeEdge);
                });
            }
        });
        return dataSourceEdges;
    }

    formatChildNodes(run: any, stage: string, dataSource: string) {
        const childNodeMapObject: { [key: string]: string } = {
            'pre_raw': `${run.subStage}_${dataSource}_${run.id}`,
            'ccg': `${run.ccgName}_${dataSource}${run.runType}_${run.id}`,
            'predictions': `${run.stage}_${run.ccgName}_${dataSource}_${run.metricType}_${run.id}`,
            'predictions_combiner': `${run.stage}_${run.ccgName}_${run.dataSource}_${run.id}`,
            'post_predictions': `${run.stage}_${run.subStage}_${dataSource}_${run.id}`,

        }
        return childNodeMapObject[stage] || `${stage}_${dataSource}_${run.id}`;
    }

    formatClusters(displayRuns: any, dataSource: string, dataSourceTitleCard: Node, eligibleStages: any[]) {
        const displayRunsGroupByStage = _.groupBy(displayRuns, 'stage');
        let dataSourceClusters: ClusterNode[] = [];

        eligibleStages.forEach((stage: any) => {
            const stageRuns = displayRunsGroupByStage[stage] || [];
            if (stageRuns.length === 0) {
                return;
            }
            const childNodes: any[] = [];
            const stageName: string = stage.replace('_', ' ');
            let stageLabel: string = stageName.replace(/(^\w|\s\w)/g, function (m: string) { return m.toUpperCase(); })
                .replace('Predictions Combiner', 'Pred Combiner').replace('Post Predictions', 'Post Pred');

            stageRuns.forEach((run: any) => {
                const childNode = this.formatChildNodes(run, stage, dataSource);
                childNodes.push(childNode);
            });
            // shrink label if too long
            const formattedDataSource = dataSource.replace('iguana', 'ig').replace('memorial_hermann', 'mem_herm').replace('_v3','').toLocaleUpperCase();
            const clusterNode: ClusterNode = {
                id: `${stage}_cluster_${dataSource}`,
                label: (stage == 'ccg') ? `${formattedDataSource} CCG` : `${formattedDataSource} ${stageLabel}`,
                childNodeIds: childNodes
            };
            _.set(clusterNode, 'dataSourceId', dataSourceTitleCard?.id);
            _.set(clusterNode, 'stage', stage);
            dataSourceClusters.push(clusterNode);
        });
        dataSourceClusters = this.validateClusters(dataSourceClusters, this.stageOrder);
        return dataSourceClusters;
    }

    validateClusters(clusters: any[], stageOrder: string[]) {
        // avoid displaying ghost clusters when post_predictions run w/o predictions_combiner
        let validClusters = [];
        let uniqueStages = clusters.map(a => a.stage);
        for (const cluster of clusters) {
            const clusterStage = cluster.stage;
            const clusterIndex = stageOrder.findIndex((stage: any) => stage === clusterStage);
            if (!uniqueStages.includes(stageOrder[clusterIndex-1]) && clusterStage !== 'pre_raw') {
                continue;
            }
            validClusters.push(cluster);
        }
        return validClusters;
    }

    formatDataSourceCluster(clusters: any) {
        let childNodes: string[] = [];
        for (const cluster of clusters) {
            if (!childNodes.includes(cluster.dataSourceId)) {
                childNodes.push(cluster.dataSourceId);
            }
        }
        const dataSourceCluster: ClusterNode = {
            id: 'data_source_cluster',
            label: 'Data Sources',
            childNodeIds: childNodes
        };
        _.set(dataSourceCluster, 'stage', 'data_source');
        return dataSourceCluster;
    }

    async getDataSources() {
        const allDataSources = await this.dataAccess.genericMethod({
            model: 'DataDashboard',
            method: 'getDistinctDataSources'
        });
        let configuredDataSources = allDataSources.map(function (ads: { data_source: any; }) {
            return ads.data_source;
        });
        const disabledSources = ['demo', 'horizon_care'];
        configuredDataSources = configuredDataSources.filter((dataSource: string) => !disabledSources.includes(dataSource));
        configuredDataSources.push('all')
        return configuredDataSources;
    }

    getEligibleStages(allRuns: any[], stageOrder: string[]) {
        const uniqueStages = new Set(allRuns.map(a => a.stage))
        let maxStageIndex = 0;
        for (const currentStage of uniqueStages) {
            const currentStageIndex = stageOrder.findIndex((stage: any) => stage === currentStage);
            maxStageIndex = (currentStageIndex > maxStageIndex) ? currentStageIndex : maxStageIndex;
        }
        const eligibleStages = stageOrder.slice(0, maxStageIndex + 1)
        return eligibleStages;
    }

    parseMetricType(metricType: string, ccgName: string) {
        let runYear = '';
        let payerGroup = '';
        if (metricType) {
            const regex_multi_year = '[1-2][0-9]{3}_[1-2][0-9]{3}';
            const regex_single_year = '[1-2][0-9]{3}';
            const multiYearMatch = metricType.match(regex_multi_year);
            const singleYearMatch = metricType.match(regex_single_year);
            runYear = (multiYearMatch) ? multiYearMatch[0] : (singleYearMatch) ? singleYearMatch[0] : 'None';
            payerGroup = (runYear !== metricType) ? metricType.replace(`_${runYear}`, '') : 'None';
        }
        else {
            runYear = 'None';
            payerGroup = 'All';
        }
        runYear = (ccgName.includes('rolling')) ? 'Rolling' : runYear;
        return { runYear, payerGroup };
    }

    getTargetPrecedentRunId(eligibleRuns: any[], targetRunId: string) {
        let targetRun = eligibleRuns.filter((run: any) => (run.runId === targetRunId));
        return targetRun[0].precedentRunIds[0];
    }

    translateCcgName(ccgName: string) {
        // hybrid patient year ccg replaced rolling and patient year ccg
        return (ccgName === 'ccg_rolling_patient_year' || ccgName === 'ccg_patient_year') ? 'ccg_hybrid_patient_year' : ccgName;
    }

    filterRunIdsByStage(stageRun: any, runIdArray: any[]) {
        // filter out run ids that are not direct precedent so we don't try to draw an edge
        let runIdstoFilter = [];
        for (const filterRunId of runIdArray) {
            if (stageRun.precedentRunIds.includes(filterRunId)) {
                stageRun.precedentRunIds = stageRun.precedentRunIds.filter((runId: any) => runId !== filterRunId);
                runIdstoFilter.push(filterRunId);
            };
        };
        return {stageRun, runIdstoFilter};
    }
    async getAllRunIdsByStage(stage: string, subStage: string | null, dataSource: string) {
        const allRunsByStage = await this.dataAccess.genericFind({
            model: 'DataDashboard',
            filter: {
                where: {
                    dataSource,
                    stage,
                    subStage
                }
            },
        });
        return allRunsByStage.map((run: any) => run.runId);
    }

    async getAdditionalInfo( runId: string ,stage: string, dataSource: string) {
        const addInfo = await this.dataAccess.genericFind({
            model: 'DataDashboardAdditionalInfo',
            filter: {
                where: {
                    dataSource,
                    stage,
                    runId
                }
            },
        });
        if (addInfo.length === 0) {
            return null;
        }
        return addInfo;
    }

    getGtDataSources() {
        return ['dragon', 'elephant', 'iguana_v3', 'iguana_medicaid_v3', 'oyster', 'qe', 'lds', 'aeon', 'abalone'];
    }
}
