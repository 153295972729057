import { Injectable } from '@angular/core';
import { IBuildsStates, IBuildsService } from './builds.component.d';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { NgDataAccess } from '../../../services/dataAccess.service';
@Injectable()
export class BuildsService implements IBuildsService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess) {
        //
    }
    initDelegate(states: IBuildsStates): object {
        states.daysToQuery = this.argosStore.getItem('buildDaysToQuery') || 30;
        this.queryUsers(states);
        return {};
    }

    async queryUsers(states: IBuildsStates) {
        this.argosStore.setItem('buildDaysToQuery', states.daysToQuery);
        if (!states.daysToQuery || states.daysToQuery <= 0 || states.daysToQuery > 100) {
            states.daysToQuery = 30;
        }
        const monthAgo = new Date();
        monthAgo.setDate(monthAgo.getDate() - states.daysToQuery);

        const builds = await this.dataAccess.genericFind({
            model: 'PrepareDatabaseRun',
            filter: {
                include: 'client',
                order: 'startedat desc',
                where: {
                    runStatus: {
                        neq: 4
                    },
                    startedat: {
                        gte: monthAgo
                    }
                }
            }
        });
        states.data = _.sortBy(builds, ['created_at']);
    }
}
