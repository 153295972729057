import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { IClarifyInsightsLandingPage} from './clarifyInsightsLandingPage.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ClarifyInsightsLandingPageService } from './clarifyInsightsLandingPage.component.service';
import { MatDialogConfig, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ClarifyInsightsProvisionCustomerModalComponent } from '../clarifyInsightsProvisionCustomerModal/clarifyInsightsProvisionCustomerModal.component';
import { ClarifyInsightsFinalizeCustomerModalComponent } from '../clarifyInsightsFinalizeCustomerModal/clarifyInsightsFinalizeCustomerModal.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import swal from 'sweetalert2';


@Component({
    selector: 'argos-clarifyInsightsLandingPage',
    templateUrl: './clarifyInsightsLandingPage.component.html',
    styleUrls: ['./clarifyInsightsLandingPage.component.css'],
    providers: [ClarifyInsightsLandingPageService],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({height: '0px', minHeight: '0'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ],
})

export class ClarifyInsightsLandingPageComponent extends ReactComponentBase<{}, IClarifyInsightsLandingPage, {}> implements OnInit {

    columnsToDisplay = ['customerCode', 'customerName', 'extractTypes'];
    columnsToDisplayWithExpand = [...this.columnsToDisplay, 'addExtractType', 'viewFilters', 'expand'];

    constructor(private svc: ClarifyInsightsLandingPageService, private cdr: ChangeDetectorRef, private matDialog: MatDialog) {
        super();
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    provisionCustomerForm() {
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ClarifyInsightsProvisionCustomerModalComponent);
        modalInstance.afterClosed().subscribe(async () => {
            await this.svc.init(this.states);
            this.cdr.detectChanges();
        });
    }

    addExtractType(element: any) {
        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    customerCode: element.customerCode,
                    customerName: element.customerName,
                    isAdditionalExtractType: true,
                    additionalFields: element.additionalFields
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ClarifyInsightsProvisionCustomerModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async () => {
            await this.svc.init(this.states);
            this.cdr.detectChanges();
        });
    }

    finalizeAccount(row: any) {
        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    customerId: row.customerId,
                    customerCode: row.customerCode,
                    s3DestinationBucket: row.s3DestinationBucket
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ClarifyInsightsFinalizeCustomerModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async () => {
            await this.svc.init(this.states);
            this.cdr.detectChanges();
        });
    }

    friendlyColumnName(columnName: string) {
        return columnName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    }

    viewFilters(element: any) {
        this.svc.navigateToFilters(element);
    }

    async deleteCustomerExtract(row: any) {
        const response = await swal({
            title: 'Are you sure?',
            text: 'This action cannot be undone',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        })
        if (response.value) {
            await this.svc.deleteCustomerExtract(row);
            await this.svc.init(this.states);
            this.cdr.detectChanges();
        }
    }

}
