import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class EcsAppShare {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    setWebTaskContainerEnvVar(keyname: string, keyValue: string, taskDefContainerName: string, environmentVars: any[], states: IEcsAppEditStates) {
        //update node options arg for task def
        const keyValueObj: any = _.find(environmentVars, { name: keyname });

        //update or add key value pair
        if (keyValueObj) {
            keyValueObj.value = keyValue;
        } else {
            environmentVars.push({ name: keyname, value: keyValue });
        }

        if (!states.eventChanges.taskContainerDefinitions[taskDefContainerName]) {
            states.eventChanges.taskContainerDefinitions[taskDefContainerName] = {};
        }
        states.eventChanges.taskContainerDefinitions[taskDefContainerName].updatedNodeOptions = keyValueObj;
    }

    getEnvironmentTags(container: any, taskFilter: any, states: IEcsAppEditStates) {
        let result = states.ecsResourceTags.map(t => ({ ...t }));

        if (states.containerTaskDefinitions) {
            const taskDefs = _.find(states.containerTaskDefinitions, function (ctd) { return ctd.containerId === container; });
            if (taskDefs) {
                const tagInfo = _.find(taskDefs.tagInfo, function (ti) { return ti.taskDefName === states.clusterName + taskFilter; });
                if (tagInfo && tagInfo.tags && tagInfo.tags.length > 0) {
                    result = _.unionBy(result, tagInfo.tags, 'key');
                }
            }
        }

        return result;
    }

    // given a container return the WEB task definitions running in it
    getContainerDefinitions(container: string, taskFilter: any, states: IEcsAppEditStates) {
        let result;
        const taskDef = this.getTaskDefinition(container, taskFilter, states);

        if (taskDef) {
            result = taskDef.containerDefinitions;
        }

        return result;
    }
    
    getTaskDefContainerDefinitions(containers: any[], states: IEcsAppEditStates) {
        let result = [];
        let filterVal =  'log_router'   //exclude log to handle ec2 and fargate setups
        let containerDef = _.find(containers, function (td: any) { return td.name.includes(filterVal) === false; });
        if (containerDef) {
            result.push(containerDef);
        }
        return result;
    } 

    getTask(container: string, taskFilter: any, states: IEcsAppEditStates) {
        let result;
        if (states.containerTaskDefinitions) {
            for (let k = 0; k < states.containerTaskDefinitions.length; k++) {
                const tasks: any = states.containerTaskDefinitions[k];

                // tasks can be null if no task are running so check below
                if (tasks && tasks.containerId === container) {

                    // get a list of task def that match the taskFilter arg. i.e only web or worker
                    const taskDefs = (_.filter(tasks.taskDefinitions, function (td) {
                        // we want matching names of taskDefNameFilter i.e WEB INSTANCE or Worker Instance
                        if (td.taskDefinition.taskDefinitionArn.indexOf(states.clusterName + taskFilter) >= 0) {
                            return td;
                        }
                    }));

                    if (taskDefs.length > 0) {

                        const mostRecent = {
                            index: 0,
                            revision: 0
                        };

                        // multiple task can be running. Example when you deploy changes you will have 2 task running at the same time for a short period
                        // so we want to display the most recent one to display for the user
                        for (let i = 0; i < taskDefs.length; i++) {
                            //    console.log('taskDefs[i] is ' + taskDefs[i].taskDefinition.taskDefinitionArn);

                            if (mostRecent.revision < taskDefs[i].taskDefinition.revision) {
                                mostRecent.index = i;
                                mostRecent.revision = taskDefs[i].taskDefinition.revision;
                            }
                        }

                        const definition = taskDefs[mostRecent.index];

                        // sort environment variables for easier display. task def save them alpha order but for some reason they are returned unordered
                        if (definition.taskDefinition.containerDefinitions) {
                            for (let j = 0; j < definition.taskDefinition.containerDefinitions.length; j++) {
                                const tempContainer = definition.taskDefinition.containerDefinitions[j];

                                // only sort if environment vars exists and we have at least 2 to sort
                                if (tempContainer.environment && tempContainer.environment.length > 1) {
                                    tempContainer.environment = _.orderBy(tempContainer.environment, ['name']);
                                }

                            }
                        }

                        result = definition;
                    }
                }
            }
        }
        return result;
    }

    // given a container return the WEB task definitions container def list running in it
    getTaskDefinition(container: string, taskFilter: any, states: IEcsAppEditStates) {
        let result;
        let task = this.getTask(container, taskFilter, states)
        
        if (task && task.taskDefinition) {
            result = task.taskDefinition;
        }

        return result;
    }

    async getEnvironmentActivityLog(states: IEcsAppEditStates) {

        let result;

        if (states.isAdmin) {
            result = await this.dataAccess.genericFind({
                model: 'EnvironmentActivityLog',
                filter: { where: { clusterName: states.clusterName }, order: 'id desc' }
            });
        } else {
            result = await this.dataAccess.genericFind({
                model: 'EnvironmentActivityLog',
                filter: { where: { and: [{ clusterName: states.clusterName }, { environmentAction: 'code change' }] }, order: 'id desc' }
            });
        }

        states.clusterActivity = result;
        states.clusterActivity.forEach(function (activity: any) {
            const d = new Date(activity.createdAt);
            const month = d.toLocaleString('en-us', { month: 'long' });
            const mins = ('0' + d.getMinutes()).slice(-2);

            activity.dateFullName = month + ' ' + d.getDate() + ', ' + d.getFullYear() + ' at ' + d.getHours() + ':' + mins;
        });

        states.activityLogTable.data = states.clusterActivity;
    }

    excludeTaskDefContainerDefintion(containers: any) {
        // filter out any other things like logger so that we can flow changes down to other task def in other services
        containers = _.filter(containers, function (td) {
            if (td.name.indexOf('log_router') === -1) {
                return td;
            }
        });

        return containers;
    }

    addUpgradeEnvironmentVariable(upgradeTaskDefData: any, environmentVars: any, envList: any) {
        const upgradeTaskDef: any = this.excludeTaskDefContainerDefintion(upgradeTaskDefData);

        // only allow these env names to flow into the upgrade task def environment variables
        for (let k = 0; k < envList.length; k++) {
            const key = envList[k];

            // first remove these envs from the upgrade task
            for (let i = 0; i < upgradeTaskDef[0].environment.length; i++) {

                if (upgradeTaskDef[0].environment[i].name === key) {
                    // console.log('key found in upgrade task removing ' + JSON.stringify(upgradeTaskDef[0].environment[i]));
                    upgradeTaskDef[0].environment.splice(i, 1);
                    break;
                }
            }

            // only add them back in if the key exists in the web task defs environment variables
            for (let i = 0; i < environmentVars.length; i++) {
                if (environmentVars[i].name === key) {
                    const env = {
                        name: environmentVars[i].name,
                        value: environmentVars[i].value.toString()
                    };
                    // console.log('key found in environmentVars adding ' + JSON.stringify(env));
                    upgradeTaskDef[0].environment.push(env);
                    break;
                }
            }

        }
    }

    async saveEnvironmentParaInfo(key: any, value: any, states: IEcsAppEditStates) {
        const envObj = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'findOne', parameters: {
                filter: { where: { id: states.cluster.environment.id } }
            }
        });
        if (envObj) {
            envObj.ecsParameters[key] = value;
            await this.dataAccess.genericUpsert({
                model: 'Environment',
                data: envObj
            });   // save the permission
        }
    }
}
