import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IObservationEditStates } from './observationEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { UrlService } from '../../../services/url.service';
import { ObservationEditService } from './observationEdit.component.service';
import { ValidationService } from '../../../services/validationService.service';

@Component({
    selector: 'argos-observationEdit',
    templateUrl: './observationEdit.component.html',
    providers: [ObservationEditService]
})

export class ObservationEditComponent extends ReactComponentBase<{}, IObservationEditStates, {}> implements OnInit {
    constructor(private svc: ObservationEditService, private cdr: ChangeDetectorRef, private validationSrv: ValidationService, private urlService: UrlService) {
        super({
            observationFilterPredicatesToDelete: [],
            observationFilterPredicateOrsToDelete: [],
            observationSelectionFieldsToDelete: [],
            observationKeyFieldsToDelete: [],
            programEpisodeObservationsToDelete: [],
            predictionStatuses: ['Not Available', 'Done'],
            observation: {
                defaultVisibility: 'Disable',
                sqlTagObservations: [],
                relatedCostObservations: [],
                relatedPaymentObservations: []
            },
            unitOptions: ['Count', 'Currency', 'Currency with pennies', 'Days', 'Integer', 'Number', 'Percent', 'Ratio'],
            saveInProgress: false
        } as unknown as IObservationEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
        if (this.urlService.checkShowViewHistoryFromUrl()) {
            this.viewHistory();
        }
    }

    observationNameChange() {
        this.svc.observationNameChangeHandler(this.states);
    }

    observationTitleChange() {
        this.svc.observationTitleChangeHandler(this.states);
    }

    getAllowedFields(didChange?: boolean) {
        this.svc.getAllowedFields(this.states, didChange);
    }

    viewHistory() {
        this.svc.viewHistory(this.states);
    }

    viewSQL() {
        this.svc.viewSQL(this.states);
    }

    viewMetricSQL() {
        this.svc.viewMetricSQL(this.states);
    }

    goToSqlPr() {
        this.svc.goToSqlPr(this.states);
    }

    goToSqlHistory() {
        this.svc.goToSqlHistory(this.states);
    }

    submitSQLForReview() {
        this.svc.submitSQLForReview(this.states);
    }

    lockObservation() {
        this.svc.lockObservation(this.states);
    }

    getObservationEpisodeSubsets() {
        return this.svc.getObservationEpisodeSubsets(this.states);
    }

    observationGroupSupportsTimeInterval() {
        return this.svc.observationGroupSupportsTimeInterval(this.states);
    }

    setPrograms() {
        this.svc.setPrograms(this.states, this.cdr);
    }

    getSelectedProductBundleCount() {
        return this.svc.getSelectedProductBundleCount(this.states);
    }

    isAddKeyFieldEnabled() {
        return this.svc.isAddKeyFieldEnabled(this.states);
    }

    nextNextKeyOrder() {
        return this.svc.nextNextKeyOrder(this.states);
    }

    deleteFilterField(id: any, index: number) {
        this.svc.deleteFilterField(id, index, this.states);
    }

    addFilterCriteria() {
        this.svc.addFilterCriteria(this.states);
    }

    operatorListItemChanged(row: any) {
        this.svc.operatorListItemChanged(row);
    }

    disableOrCondition(row: any) {
        return this.svc.disableOrCondition(row, this.states);
    }

    deleteFilterCriteria(id: any, index: number) {
        this.svc.deleteFilterCriteria(id, index, this.states);
    }

    setObservationTag(id: any, index: number) {
        this.svc.setObservationTag(id, index, this.states);
    }

    isError(propertyName: string) {
        return this.validationSrv.errorExists(propertyName, this.states.observation.propertyErrors);
    }

    hasPropertyError(propertyName: string) {
        return this.validationSrv.showPropertyError(propertyName, this.states.observation.propertyErrors);
    }

    isSaveDisabled() {
        let result = (this.states.observationformExists || this.states.observationformExistsCap || this.states.saveInProgress || this.states.observation.programEpisodeObservations?.length === 0 || this.getSelectedProductBundleCount() === 0 || (this.states.dependentObservations.length && this.states.dependentObservations.length !== 0 && !['Active', 'Dev'].includes(this.states.observation.status)) || (this.states.observation.sql !== this.states.originalSql && ['Active', 'Dev'].includes(this.states.observation.status)));

        // If selection fields are not set, disable save
        if (this.states.observation.observationSelectionFields?.some((sf: any) => !sf.observationGroupAllowedFieldId)) {
            result = true;
        }

        if (this.states.observation.propertyErrors) {

            const reqProperties = ['programEpisodeObservations', 'title', 'name', 'multiplier', 'status', 'observationTypeId', 'observationGroupId', 'observationEpisodeSubsetId', 'units', 'observationCategoryHierarchyId'];
            const propertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.observation, reqProperties);

            this.states.observation.propertyErrors = propertyErrors;
            result = (result || (this.states.observation.propertyErrors.length > 0));
        }

        return result;
    }

    save() {
        this.svc.save(this.states);
    }

    delete() {
        this.svc.deleteObservation(this.states);
    }
}
