import { Injectable } from '@angular/core';
import { IEditReleaseNotesProps, IEditReleaseNotesStates, IEditReleaseNotesService } from './editReleaseNotes.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter } from '@uirouter/core';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class EditReleaseNotesService implements IEditReleaseNotesService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private alertSvc: AlertService) {
        //
    }
    async initDelegate(props: IEditReleaseNotesProps, states: IEditReleaseNotesStates): Promise<IEditReleaseNotesStates> {
        const newStates = await this.findRelease(states);
        return newStates;
    }

    momentFromNow(date: any) {
        return moment(new Date(date)).fromNow();
    }

    async findRelease(initStates: IEditReleaseNotesStates): Promise<IEditReleaseNotesStates> {
        const states = _.cloneDeep(initStates);
        try {
            states.release = await this.dataAccess.genericMethod({
                model: 'Release', method: 'findOne', parameters: {
                    filter: {
                        where: {
                            id: this.uiRouter.globals.params.id
                        }
                    }
                }
            });
            states.release.releaseDate = new Date(states.release.releaseDate);
            await this.findReleaseNote(states);
        } catch (error) {
            this.alertSvc.handleError(error);
        }
        return states;
    }

    async findReleaseNote(states: IEditReleaseNotesStates) {
        try {
            const releaseNotes = await this.dataAccess.genericFind({
                model: 'ReleaseNote',
                filter: {
                    where: {
                        releaseId: this.uiRouter.globals.params.id
                    },
                    order: 'buildNumber asc'
                }
            });
            if (releaseNotes.length > 0) {
                states.releaseNotes = releaseNotes;
                states.fromBuild = states.releaseNotes[0].buildNumber;
                states.toBuild = states.buildNumber = states.releaseNotes[states.releaseNotes.length - 1].buildNumber;

                states.releaseNotes.forEach(function (releaseNote) {
                    if (releaseNote.releaseNote) {
                        releaseNote.include = true;
                    }
                });

                states.releaseNotesTable = _.orderBy(releaseNotes, ['buildNumber']);

                states.releaseName = states.product + ' - ' + moment(states.release.releaseDate).format('MM/DD/YYYY') + ' - Build ' + states.buildNumber;
            } else {
                states.noData = 'No Release Notes found for the ' + states.release.releaseName + ' release.';
            }
            states.queryRunning = false;
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    async refreshReleaseNotes(states: IEditReleaseNotesStates, cb: Function) {
        try {
            states.queryRunning = true;
            const releaseNotes = await this.dataAccess.genericMethod({
                model: 'Release', method: 'getReleaseNotes', parameters: {
                    project: states.product,
                    fromBuild: states.fromBuild,
                    toBuild: states.toBuild
                }
            });
            let clonedReleaseNotes = _.clone(releaseNotes);
            _.map(states.releaseNotesTable, function (item: any) {
                clonedReleaseNotes = _.filter(clonedReleaseNotes, function (clonedNote) {
                    return item.jiraItem !== clonedNote.id;
                });
            });
            this.confirmAdd(clonedReleaseNotes, states, cb);
        } catch (error) {
            this.alertSvc.handleError(error);
            cb();
        }
    }

    confirmAdd(notes: any, states: IEditReleaseNotesStates, cb: Function) {
        if (notes.length > 0) {
            swal({
                title: 'Really?',
                text: 'Are you sure you want to add the following Jira Items ' + _.map(notes, function (note: any) { return note.id; }).toString() + '?',
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, Add!',
                cancelButtonText: 'Cancel',
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    try {
                        states.queryRunning = true;
                        notes.forEach(async (releaseNote: any) => {
                            const data = await this.dataAccess.genericUpsert({
                                model: 'ReleaseNote',
                                data: {
                                    releaseId: this.uiRouter.globals.params.id,
                                    releaseNote: releaseNote.releaseNote,
                                    jiraItem: releaseNote.id,
                                    itemType: releaseNote.type,
                                    customerPublish: releaseNote.publish,
                                    buildNumber: releaseNote.fixedInBuild,
                                    summary: releaseNote.summary
                                }
                            });
                        });
                        await this.findRelease(states);
                        cb();
                    } catch (error) {
                        this.alertSvc.handleError(error);
                        cb();
                    }
                }
            });
        } else {
            swal({
                title: 'Oops!',
                text: 'No new Jira Items found!',
                type: 'error',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    await this.findReleaseNote(states);
                    cb();
                }
            });
        }
    }

    async editReleaseNotes(states: IEditReleaseNotesStates, cb: Function) {
        const release = await this.dataAccess.genericUpsert({
            model: 'Release',
            data: {
                releaseName: states.releaseName,
                releaseDate: states.release.releaseDate,
                product: states.product,
                buildNumber: states.buildNumber
            }
        });
        const releasePromises: any[] = [];
        let error = false;

        states.releaseNotes.forEach(async (releaseNote) => {
            if (!releaseNote.include) {
                try {
                    const id = releaseNote.id;
                    releasePromises.push(this.dataAccess.genericDelete({
                        model: 'ReleaseNote',
                        id
                    }));
                } catch (error) {
                    this.alertSvc.handleError(error);
                }
            } else {
                if (!releaseNote.releaseNote && !releaseNote.summary) {
                    error = true;
                }
            }
        });

        Promise.all(releasePromises).then(async (result) => {
            if (error) {
                swal({
                    title: 'Oops!',
                    text: 'Cannot add Jiras without release notes or summary!',
                    type: 'error',
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel',
                }).then(async (isConfirm: any) => {
                    if (isConfirm.value) {
                        await this.findReleaseNote(states);
                        cb();
                    }
                });
            } else {
                await this.findReleaseNote(states);
                cb();
            }
        });
    }

}
