// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  display: flex;
  align-items: center;
}
.download-btn {
  margin-left: 500px;
  /* Adjust as needed */
}
`, "",{"version":3,"sources":["webpack://./client/app/components/hospitalRates/hospitalRatesBulkAdd/hospitalRatesBulkAdd.component.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AAEA;EACI,kBAAA;EAAF,qBAAqB;AACvB","sourcesContent":[".form-group {\n    display: flex;\n    align-items: center;\n}\n\n.download-btn {\n    margin-left: 500px; /* Adjust as needed */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
