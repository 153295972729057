import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IAtlasClaraChatHistoryStates } from './atlasClaraChatHistory.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { AtlasClaraChatHistoryService } from './atlasClaraChatHistory.component.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'argos-atlasClaraChatHistory',
    templateUrl: './atlasClaraChatHistory.component.html',
    styleUrls: ['./atlasClaraChatHistory.component.less'],
    providers: [AtlasClaraChatHistoryService]
})

export class AtlasClaraChatHistoryComponent extends ReactComponentBase<{}, IAtlasClaraChatHistoryStates, {}> implements OnInit {

    constructor(private svc: AtlasClaraChatHistoryService, private cdr: ChangeDetectorRef) {
        super({
            metaDataTypes: [{
                type: 'question',
                name: 'Question'
            }, {
                type: 'total_asks',
                name: 'Total Asks'
            }]
        } as unknown as IAtlasClaraChatHistoryStates);
    }

    _ = _;    

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    async conversationTypeChanged() {
        await this.svc.getQuestionsOverview(this.states);
        this.cdr.detectChanges();
    }

    sortData(event: any) {
        this.svc.sortData(event, this.states);
        this.cdr.detectChanges();
    }

    async viewHistory(question: any) {
        await this.svc.viewHistory(question, this.states);
        this.cdr.detectChanges();
    }

    viewHistoryJSON(question: any) {
        this.svc.viewHistoryJSON(question, this.states);
        this.cdr.detectChanges();
    }
    
    setScreenMode(screenMode: string) {
        this.svc.setScreenMode(screenMode, this.states);
        this.cdr.detectChanges();
    }

    async syncUsage() {
        await this.svc.syncUsage(this.states);
        this.cdr.detectChanges();
    }

}
