import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IObservationEditStates, IObservationEditService } from './observationEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { CONST } from '../../../constants/globals';
import { EditProgramObservationModalComponent } from '../editProgramObservationModal/editProgramObservationModal.component';
import { EditObservationTagModalComponent } from '../editObservationTagModal/editObservationTagModal.component';
import { ViewHistoryModalComponent } from '../viewHistoryModal/viewHistoryModal.component';
import { ViewSQLModalComponent } from '../viewSQLModal/viewSQLModal.component';

@Injectable()
export class ObservationEditService implements IObservationEditService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog,
        private state: StateService, private changeDetection: ChangeDetectorRef) {
        //
    }
    async initDelegate(states: IObservationEditStates): Promise<object> {
        states.lodash = _;
        await this.init(states);
        return {};
    }
    async init(states: IObservationEditStates) {
        const observationGroups = await this.dataAccess.genericFind({
            model: 'ObservationGroup'
        });
        const observationTypes = await this.dataAccess.genericFind({
            model: 'ObservationType'
        });
        const observationEpisodeSubsets = await this.dataAccess.genericFind({
            model: 'ObservationEpisodeSubset'
        });
        const observationGroupObservationTypes = await this.dataAccess.genericFind({
            model: 'ObservationGroupObservationType'
        });

        const referenceDataListTags = await this.dataAccess.genericFind({
            model: 'ReferenceDataListTag',
            filter: { include: ['referenceDataList', 'viewReferenceDataListParentTags'] }
        });

        const observationCategoryHierarchies = await this.dataAccess.genericFind({
            model: 'ObservationCategoryHierarchy'
        });
        const observations = await this.dataAccess.genericFind({
            model: 'Observation',
            filter: { fields: ['id', 'name', 'units', 'sqlTagObservations', 'status'], order: 'name' }
        });
        const productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle',
            filter: { order: 'title' }
        });
        const observationIntervals = await this.dataAccess.genericFind({
            model: 'ObservationInterval',
            filter: { order: 'id' }
        });
        states.observationGroups = _.orderBy(observationGroups, 'name');
        states.sqlSelectId = _.get(_.find(states.observationGroups, (og: any) => { return og.templateName === 'sql_select'; }), 'id');
        states.observationTypes = observationTypes;
        states.observationEpisodeSubsets = observationEpisodeSubsets;
        states.observationGroupObservationTypes = observationGroupObservationTypes;
        states.referenceDataListTags = _.map(referenceDataListTags, (tag: any) => {
            tag.fullPath = (tag.viewReferenceDataListParentTags) ? tag.viewReferenceDataListParentTags.parentTags.join('/') + '/' + tag.tag : tag.tag;
            return tag;
        });
        states.referenceDataLists = _.orderBy(_.uniqBy(_.map(states.referenceDataListTags, (tag: any) => {
            return {
                id: tag.referenceDataListId,
                name: tag.referenceDataList.name
            };
        }), 'id'), 'name');
        states.observationCategoryHierarchies = _.orderBy(_.map(observationCategoryHierarchies, (h: any) => {
            return {
                id: h.id,
                name: h.category + CONST.HIERARCHY_DELIMITER + h.category2 + CONST.HIERARCHY_DELIMITER + h.category3 + CONST.HIERARCHY_DELIMITER + h.category4
            };
        }), 'name');

        states.observationNames = _.map(observations, 'name');
        states.observations = _.filter(observations, o => ['Active', 'Dev', 'Test'].includes(o.status));
        states.allCostObservations = _.filter(observations, { units: 'Currency' });
        states.productBundles = productBundles;
        states.observationIntervals = observationIntervals;

        if (this.uiRouter.globals.params.id) {
            const observation = await this.getObservation(states);
            states.observation = observation;
            states.canDeleteObservation = await this.getCanDeleteObservation(states)

            // Allow observation rename if the status is Draft and this is a CCG Admin
            if (_.includes(['Draft', 'Test'], states.observation.status) && states.ccgAdmin) {
                states.disableRename = false;
                states.disableChangeType = false;
            }

            _.forEach(states.observation.productBundles, (productBundleItem: any) => {
                const productBundle: any = _.find(states.productBundles, { shortName: productBundleItem });
                if (productBundle) {
                    productBundle.enabled = true;
                }
            });

            if (this.uiRouter.globals.current.name === 'argos.ccgDesigner.observations.copy') {
                delete states.observation.id;
                delete states.observation.observationHistories;
                delete states.observation.githubPrUrl;
                states.observation.observationFilterPredicates.forEach(removeIds);
                states.observation.observationSelectionFields.forEach(removeIds);
                states.observation.observationKeyFields.forEach(removeIds);
                states.observation.programEpisodeObservations.forEach(removeIds);

                states.observation.name = states.observation.name + '_copy';
                states.observation.status = 'Draft';
                states.disableRename = false;
                states.disableChangeType = false;
                states.observation.defaultVisibility = 'Disable';

                function removeIds(row: any) {
                    delete row.id;
                    delete row.observationId;
                    delete row.observationFilterPredicateOrId;
                }

            } else {
                states.originalObservation = _.cloneDeep(observation);
                // Available criteria for OR conditions
                if (states.observation && states.observation.observationFilterPredicates) {
                    states.availableOrCriteria = _.cloneDeep(states.observation.observationFilterPredicates);
                    _.forEach(states.observation.observationFilterPredicates, (observationFilterPredicate) => {
                        if (observationFilterPredicate.valueReferenceListTagId) {
                            const tag = _.find(states.referenceDataListTags, { id: observationFilterPredicate.valueReferenceListTagId });
                            observationFilterPredicate.referenceDataListId = _.get(tag, 'referenceDataListId');
                            observationFilterPredicate.valueReferenceListParentTagId = _.get(tag, 'parentListTagId');
                        }
                        if (observationFilterPredicate.observationFilterPredicateOrs && observationFilterPredicate.observationFilterPredicateOrs.length === 1) {
                            observationFilterPredicate.observationFilterPredicateOrId = observationFilterPredicate.observationFilterPredicateOrs[0].observationFilterPredicateId2;
                        }
                    });
                }
            }
            await this.getAllowedFields(states);
        } else {
            states.observation = {
                status: 'Draft',
                predictionStatus: 'Not Available',
                multiplier: 1.00,
                priority: 1,
                actionable: false,
                defaultVisibility: 'Disable',
                betterDirection: 'Lower'
            };
            states.disableRename = false;
            states.disableChangeType = false;
        }

        states.observation.propertyErrors = [];
        states.dependentObservations = _.map(_.filter(observations, function (u) {
            if (u.sqlTagObservations.includes(states.observation.id) && u.status === 'Active' && states.observation.status === 'Active') {
                return true;
            } else {
                return false;
            }
        }), 'name');

        states.originalSql = states.observation.sql;
    }
    async observationNameChangeHandler(states: IObservationEditStates) {
        if (states.observation && !states.observation.id) {
            // Force lower case with no spaces, extra characters, etc., but only when first creating the observation
            states.observation.name = _.snakeCase(_.toLower(states.observation.name));
        }

        states.observationformExists = false;
        states.observationformExistsCap = false;

        if (states.observation && states.observation.name && states.observation.name.length > 0) {
            const filter: any = {
                where: {
                    name: states.observation.name
                }
            };
            // If the observation has an ID, make sure we are not checking for the current observation
            if (states.observation.id) {
                filter.where.id = { neq: states.observation.id };
            }

            // database lookup with filter wasn't working, so had to filter separately
            const response = await this.dataAccess.genericFind({
                model: 'Observation'// ,
                // filter: { filter }
            });

            if (response.filter((e: any) => e.name === states.observation.name).length > 0) {
                states.observationformExists = true;
                this.changeDetection.detectChanges();
            }
            if (filter.where.name.includes('capped') && !states.observation.id) {
                states.observationformExistsCap = true;
                this.changeDetection.detectChanges();
            }
        }
        // }
    }

    async observationTitleChangeHandler(states: IObservationEditStates) {
        states.observationTitleDuplicate = {};

        if (states.observation && states.observation.title && states.observation.title.length > 0) {
            const existingObsfilter: any = {
                where: {
                    and: [
                        {
                            title: states.observation.title
                        },
                        {
                            id: { neq: states.observation.id }
                        }
                    ]
                }
            };
            const filter: any = {
                where: { title: states.observation.title }
            };

            const observations = await this.dataAccess.genericFind({
                model: 'Observation',
                filter: states.observation.id ? existingObsfilter : filter
            });

            const otherMetrics = await this.dataAccess.genericFind({
                model: 'OtherMetric',
                filter: {
                    where: { shortName: states.observation.title }
                }
            });

            if (otherMetrics && otherMetrics.length > 0) {
                states.observationTitleDuplicate = {
                    error: 'The name you selected is already used by an other metric, please consider renaming to prevent confusion.',
                    uiSref: 'prism1Metrics',
                    id: otherMetrics[0].id
                };
                this.changeDetection.detectChanges();
            } else if (observations.length > 0) {
                states.observationTitleDuplicate = {
                    error: 'The name you selected is already used by an observation, please consider renaming to prevent confusion.',
                    uiSref: 'observations',
                    id: observations[0].id
                };
                this.changeDetection.detectChanges();
            }
        }
    }

    operatorListItemChanged(filterCriteria: any) {
        // operatorListItemId represent IS and IS NOT
        if (filterCriteria.operatorListItemId === 10175 || filterCriteria.operatorListItemId === 10174) {
            filterCriteria.value = 'NULL';
        }
    }

    deleteFilterField(keyFieldId: any, index: any, states: IObservationEditStates) {
        states.observationKeyFieldsToDelete.push(keyFieldId);
        states.observation.observationKeyFields.splice(index, 1);
    }

    nextNextKeyOrder(states: IObservationEditStates) {

        let result = 1;

        if (states.observation && states.observation.observationKeyFields) {
            result = states.observation.observationKeyFields.length + 1;
        }

        return result;
    }

    async getAllowedFields(states: IObservationEditStates, didChange?: boolean) {
        if (didChange) {
            states.observation.observationSelectionFields = [];
            states.observation.observationFilterPredicates = [];
            states.observation.observationEpisodeSubsetId = null;
            states.observation.observationTypeId = null;
        }

        if (!states.observation || !states.observation.observationGroupId) {
            return;
        }

        states.availableObservationTypes = _.filter(states.observationTypes, function (observationType) {
            return (_.find(states.observationGroupObservationTypes, { observationGroupId: states.observation.observationGroupId, observationTypeId: observationType.id }));
        });

        if (states.availableObservationTypes && states.availableObservationTypes.length === 1) {
            states.observation.observationTypeId = states.availableObservationTypes[0].id;
        }

        const observationGroup = _.find(states.observationGroups, { id: states.observation.observationGroupId });
        if (observationGroup && observationGroup.selectionFieldsCount > 0) {
            if (states.observation && (!states.observation.observationSelectionFields || states.observation.observationSelectionFields.length === 0)) {
                for (let i = 1; i <= observationGroup.selectionFieldsCount; i++) {
                    if (!states.observation.observationSelectionFields) {
                        states.observation.observationSelectionFields = [];
                    }
                    states.observation.observationSelectionFields.push({
                        order: i
                    });
                }
            }
        }

        if (observationGroup && observationGroup.keyFieldsCount > 0) {
            if (states.observation && (!states.observation.observationKeyFields || states.observation.observationKeyFields.length === 0)) {
                for (let i = 1; i <= observationGroup.keyFieldsCount; i++) {
                    if (!states.observation.observationKeyFields) {
                        states.observation.observationKeyFields = [];
                    }
                    states.observation.observationKeyFields.push({
                        order: i
                    });
                }
            }
        }
        const listItems = await this.dataAccess.genericFind({
            model: 'ListItem',
            filter: {
                where: {
                    listId: {
                        inq: [3, 4]
                    }
                }
            }
        });
        states.operators = _.filter(listItems, { listId: 4 });
        const observationGroupAllowedFieldsData = await this.dataAccess.genericFind({
            model: 'ObservationGroupAllowedField'
        });
        const observationGroupAllowedFields = _.filter(observationGroupAllowedFieldsData, { observationGroupId: states.observation.observationGroupId });
        observationGroupAllowedFields.forEach((observationGroupAllowedField: any) => {
            const listItem = _.find(listItems, { listId: 3, id: parseInt(observationGroupAllowedField.fieldListItemId) });
            observationGroupAllowedField.tableColumnName = _.get(listItem, 'key') + '.' + _.get(listItem, 'value');
        });
        states.observationGroupAllowedFields = _.orderBy(observationGroupAllowedFields, 'tableColumnName');
        states.observationGroupAllowedFieldsForSelection = _.orderBy(
            _.filter(observationGroupAllowedFields, { allowedForSelection: true }), 'tableColumnName');
        states.observationGroupAllowedFieldsForKey = _.orderBy(
            _.filter(observationGroupAllowedFields, { allowedForKey: true }), 'tableColumnName');
    }

    isAddKeyFieldEnabled(states: IObservationEditStates) {
        let result = false;
        const filterOgs = _.filter(states.observationGroups, function (og) {
            if ((og.name === 'Trigger Offset - Medical Claim Detail' || og.name === 'Trigger Offset - Rx Claim Detail') && (states.observation && og.id === states.observation.observationGroupId)) {
                return og;
            }
        });

        if (filterOgs && filterOgs.length > 0) {
            result = false;
        }

        return result;
    }

    getObservationEpisodeSubsets(states: IObservationEditStates) {
        let result = states.observationEpisodeSubsets;

        if (states.observation && states.observation.observationGroupId) {
            const og = _.find(states.observationGroups, { id: states.observation.observationGroupId });
            if (og && !og.supportsSubsets) {
                result = _.filter(states.observationEpisodeSubsets, { id: 1 });
            }
        }

        return result;
    }

    async getCanDeleteObservation(states: IObservationEditStates) {
        let daysSinceLastUpdate = Math.ceil((new Date().getTime() - new Date(states.observation.lastModified).getTime()) / (1000 * 3600 * 24));

        // Observation can be deleted if no updates have been made in more than a year OR the user is a CCG
        // admin and the observation is Inactive.
        return states.observation.status === 'Inactive' && (daysSinceLastUpdate > 365 || states.ccgAdmin);
    }

    async deleteObservation(states: IObservationEditStates) {
        // Check to see if observation can be deleted.
        let dependencies = await this.dataAccess.genericMethod({
                model: 'Observation', method: 'getObsDep', parameters: {
                    id: states.observation.id
                }
            });
        // Cannot delete observation referenced by other items.
        if (dependencies.length > 0) {
            swal({
                title: 'References to Observation',
                text: `Observation cannot be deleted as it is referenced by one or more of: ${_.uniq(_.map(dependencies, (dep: any) => dep.dep))}`,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#57C84D', confirmButtonText: 'OK'
            })
        }
        else {
             swal({
                title: 'Delete Observation?',
                text: 'Deleting an observation cannot be undone!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#57C84D', confirmButtonText: 'Delete Observation',
                cancelButtonText: 'Cancel',
                cancelButtonColor: '#00bfff'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    await this.dataAccess.genericMethod({
                        model: 'Observation', method: 'deleteObservation', parameters: {
                        id: states.observation.id
                    }});
                    this.state.go('argos.ccgDesigner.observations.list');
                } else {
                    this.state.go('argos.ccgDesigner.observations.edit', { id: states.observation.id }, { reload: true });
                }
            });
        }
    }

    observationGroupSupportsTimeInterval(states: IObservationEditStates) {
        const observationGroupId = _.get(states, 'observation.observationGroupId');
        if (observationGroupId) {
            const og = _.find(states.observationGroups, { id: observationGroupId });
            if (og && _.startsWith(og.templateName, 'time_series_array')) {
                return true;
            }
        }
        return false;
    }

    setPrograms(states: IObservationEditStates, cdr: ChangeDetectorRef) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            data: {
                props: {
                    observation: states.observation
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            EditProgramObservationModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            if (result) {
                states.observation = result.observation;
                states.programEpisodeObservationsToDelete = result.programEpisodeObservationsToDelete;
            }
            cdr.detectChanges();
        });
    }

    setObservationTag(row: any, index: number, states: IObservationEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            data: {
                props: {
                    observation: states.observation,
                    referenceDataListId: row.referenceDataListId,
                    valueReferenceListTagId: row.valueReferenceListTagId,
                    tagIndex: index
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            EditObservationTagModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            if (result) {
                const tagId = result.tagIds[result.tagIds.length - 1].tagId;
                states.observation.observationFilterPredicates[result.tagIndex].valueReferenceListTagId = tagId;
                const parentTag: any = _.find(states.referenceDataListTags, { id: tagId });
                states.observation.observationFilterPredicates[result.tagIndex].valueReferenceListParentTagId = parentTag.parentListTagId;
                this.changeDetection.detectChanges();
            }
        });
    }

    viewHistory(states: IObservationEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    observation: states.observation
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewHistoryModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            this.matDialog.closeAll();
            // modalInstance = undefined;
            // modalInstance.close();
        });
    }

    viewSQL(states: IObservationEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    observation: states.observation,
                    sqlMode: 'observation'
                }
            }
        };
        this.matDialog.open(ViewSQLModalComponent, dialogConfig);
    }

    viewMetricSQL(states: IObservationEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    observation: states.observation,
                    sqlMode: 'metric'
                }
            }
        };
        this.matDialog.open(ViewSQLModalComponent, dialogConfig);
    }

    async submitSQLForReview(states: IObservationEditStates) {
        const obsName = states.observation.name;
        const sql = states.observation.sql;
        const username = this.argosStore.getItem('username');
        const response = await this.dataAccess.genericMethod({
            model: 'Teradrome', method: 'openFreeformSQLPR', parameters: {
                obsName,
                sql,
                username
            }
        });

        swal({
            title: 'Observation PR Created',
            text: 'Successfully created a PR in the freeform_sql_observation repo.',
            type: 'success',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'View PR',
            cancelButtonColor: '#6AA84F',
            allowOutsideClick: false
        }).then(async (isConfirm: any) => {
            if (!isConfirm.value) {
                window.open(response.data.html_url, '_blank');
            }
        });

        states.observation.githubPrUrl = response.data.html_url;
        if (_.includes(['Active', 'Dev'], states.observation.status)) {
            states.observation.sql = states.originalSql;
        } else {
            states.observation.status = 'Test';
        }
        this.save(states, true);
        this.state.go('argos.ccgDesigner.observations.edit', { id: states.observation.id }, { reload: true });

        return response;
    }

    goToSqlPr(states: IObservationEditStates) {
        window.open(states.observation.githubPrUrl, '_blank');
    }

    goToSqlHistory(states: IObservationEditStates) {
        window.open('https://github.com/clarifyhealth/freeform_sql_observations/commits/main/' + states.observation.name + '.sql', '_blank');
    }

    filterAvailableOrCriteria(rowId: any, states: IObservationEditStates) {
        // const availableOrCriteria = _.filter(states.availableOrCriteria, item => {

        // });
        //   return function(item) {
        //       // Don't allow user to choose the current row for an OR condition. See https://clarifyhealth.atlassian.net/browse/ARG-104
        //       if (item && item.id && item.id === rowId) {
        //           return false;
        //       }
        //       return true;
        //   }
    }

    addFilterCriteria(states: IObservationEditStates) {
        if (!states.observation.observationFilterPredicates) {
            states.observation.observationFilterPredicates = [];
        }
        states.observation.observationFilterPredicates.push({});
    }

    deleteFilterCriteria(row: any, index: number, states: IObservationEditStates) {
        swal({
            title: 'Delete Filter Criteria',
            text: 'Are you sure you want to delete this row?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                if (row && row.id) {
                    states.observationFilterPredicatesToDelete.push(row.id);
                    if (row.observationFilterPredicateOrs && row.observationFilterPredicateOrs.length === 1 &&
                        row.observationFilterPredicateOrs[0].id) {
                        states.observationFilterPredicateOrsToDelete.push(row.observationFilterPredicateOrs[0].id);
                    }
                    // Need to also check to see if this filterPredicate is the OR condition for another filterPredicate and delete if so
                    _.forEach(states.observation.observationFilterPredicates, (observationFilterPredicate, index) => {
                        if (observationFilterPredicate.observationFilterPredicateOrs && observationFilterPredicate.observationFilterPredicateOrs.length === 1) {
                            if (observationFilterPredicate.observationFilterPredicateOrs[0].observationFilterPredicateId2 === row.id) {
                                states.observationFilterPredicateOrsToDelete.push(observationFilterPredicate.observationFilterPredicateOrs[0].id);
                                states.observation.observationFilterPredicates[index].observationFilterPredicateOrId = undefined;
                            }
                        }
                    });
                }
                states.observation.observationFilterPredicates.splice(index, 1);
                this.changeDetection.detectChanges();
            }
        });
    }

    disableOrCondition(row: any, states: IObservationEditStates) {
        let foundOrCondition = false;
        if (states.observation && states.observation.observationFilterPredicates && states.observation.observationFilterPredicates.length > 0) {
            _.forEach(states.observation.observationFilterPredicates, (observationFilterPredicate) => {
                if (!_.isNil(observationFilterPredicate.observationFilterPredicateOrId) &&
                    observationFilterPredicate.observationFilterPredicateOrId !== row.observationFilterPredicateOrId) {
                    foundOrCondition = true;
                }
            });
        }
        return foundOrCondition;
    }

    lockObservation(states: IObservationEditStates) {
        let title = 'Lock Observation Definition';
        let text = 'Are you sure you want to lock edits for ' + states.observation.name + '?';
        if (states.observation.locked) {
            title = 'Unlock Observation Definition';
            text = 'Are you sure you want to unlock edits for ' + states.observation.name + '?';
        }
        swal({
            title,
            text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                states.observation.locked = !states.observation.locked;
                await this.save(states);
            }
        });
    }

    async getObservation(states: IObservationEditStates) {
        let id = this.uiRouter.globals.params.id;
        if (states.observation && states.observation.id) {
            id = states.observation.id;
        }
        const observationsList = await this.dataAccess.genericMethod({
            model: 'Observation',
            method: 'findOne', parameters: {
                filter: {
                    where: {
                        id: _.toNumber(id)
                    },
                    include: [
                        { observationFilterPredicates: ['observationFilterPredicateOrs'] },
                        'observationSelectionFields',
                        'observationKeyFields',
                        'programEpisodeObservations',
                        'observationHistories'
                    ]
                }
            }
        });
        // Loopback loading listItemId as string, so need to convert to number
        _.forEach(observationsList.observationFilterPredicates, (filterCriteria: any) => {
            if (filterCriteria.operatorListItemId) {
                filterCriteria.operatorListItemId = parseInt(filterCriteria.operatorListItemId);
            }
        });
        return observationsList;
    }

    async getObservationByName(name: string) {
        const observationsList = await this.dataAccess.genericMethod({
            model: 'Observation',
            method: 'findOne', parameters: {
                filter: {
                    where: {
                        name
                    },
                    include: [
                        { observationFilterPredicates: ['observationFilterPredicateOrs'] },
                        'observationSelectionFields',
                        'observationKeyFields',
                        'programEpisodeObservations'
                    ]
                }
            }
        });
        return observationsList;
    }

    getSelectedProductBundleCount(states: IObservationEditStates) {
        return _.filter(states.productBundles, { enabled: true }).length;
    }

    async save(states: IObservationEditStates, supressModal?: boolean) {
        // Need to add logic here
        states.saveInProgress = true;

        if (states.observation.observationGroupId !== states.sqlSelectId) {
            states.observation.sql = null;
        }
        states.observation.sqlTagObservations = _.pull(states.observation.sqlTagObservations, 0, null);
        states.observation.productBundles = _.map(_.filter(states.productBundles, { enabled: true }), 'shortName');

        // The following code is checking to see if any of the sql tag observations need to be linked to new program episodes,
        // so can skip if there are no sql tag observations
        if (!states.observation.sqlTagObservations) {
            await this.saveObservation(states, [], supressModal);
        }

        const programEpisodeIds = _.map(states.observation.programEpisodeObservations, 'programEpisodeId');
        const newProgramEpisodeObservations: any[] = [];

        programEpisodeIds.forEach((pe: any) => {
            states.observation.sqlTagObservations.forEach((obs: any) => {
                newProgramEpisodeObservations.push({
                    programEpisodeId: pe,
                    observationId: obs
                });
            });
        });

        // Retrieve program episode observations from the database.
        const programEpisodeObservations = await this.dataAccess.genericFind({
            model: 'ProgramEpisodeObservation'
        });

        // Find program episode observations that do not exist in database.
        const programEpisodesNotInDb = newProgramEpisodeObservations.filter(
            (npeo: any) => !programEpisodeObservations.some((peo: any) =>
                peo.programEpisodeId === npeo.programEpisodeId && peo.observationId === npeo.observationId));

        // If there are some, then prompt user for permission to insert them to database.
        if (programEpisodesNotInDb.length > 0) {
            // Fetch program episodes as their descriptions will be needed for creating the message below.
            const programEpisodes = await this.dataAccess.genericFind({
                model: 'ProgramEpisode'
            });
            // Fetch descriptions for program episodes and dependent observations.
            const programEpisodesNotInDbDesc = _.map(programEpisodesNotInDb, (peo: any) => {
                const obsDesc = _.get(_.find(states.observations, { id: peo.observationId }), 'name');
                const peDesc = _.get(_.find(programEpisodes, { id: peo.programEpisodeId }), 'name');

                return `<li>${peDesc ? peDesc : peo.programEpisodeId} - ${obsDesc ? obsDesc : peo.observationId}</li>`;
            });

            swal({
                title: 'Save Program Episode Observations',
                html: `Save the following program episode observations along with this observation?
                    <br>${programEpisodesNotInDbDesc.join('')}`,
                type: 'question',
                showCancelButton: true,
                confirmButtonText: 'Save',
                confirmButtonColor: '#57C84D',
                cancelButtonText: 'Back to Observation Edit',
                cancelButtonColor: '#00bfff'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    await this.saveObservation(states, programEpisodesNotInDb, supressModal);
                } else {
                    states.saveInProgress = false;
                    this.changeDetection.detectChanges();
                }
            });
        }
        else {
            await this.saveObservation(states, programEpisodesNotInDb, supressModal);
        }
    }

    async saveObservation(states: IObservationEditStates, programEpisodesNotInDb: any[], supressModal?: boolean) {

        const insertPromises: any = [];
        programEpisodesNotInDb.forEach((peo: any) => {
            const programEpisodeObservationPromise = this.dataAccess.genericUpsert({
                model: 'ProgramEpisodeObservation',
                data: peo
            });
            insertPromises.push(programEpisodeObservationPromise);
        });
        await Promise.all(insertPromises);

        const observation: any = await this.dataAccess.genericUpsert({
            model: 'Observation',
            data: states.observation
        });
        states.observation.id = observation.id;

        await this.deleteChildRecords(states);
        await this.saveChildRecords(states);
        states.observation = await this.getObservation(states);

        if (!_.isEqual(states.originalObservation, states.observation)) {
            await this.dataAccess.genericMethod({
                model: 'Teradrome', method: 'saveObservationHistory', parameters: {
                    observation: states.observation,
                    username: this.argosStore.getItem('username')
                }
            });
        }

        if (!supressModal) {
            swal({
                title: 'Observation Saved',
                text: 'Continue to...',
                type: 'success',
                showCancelButton: true,
                confirmButtonColor: '#57C84D', confirmButtonText: 'Observation List',
                cancelButtonText: 'Observation',
                cancelButtonColor: '#00bfff'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    this.state.go('argos.ccgDesigner.observations.list');
                } else {
                    this.state.go('argos.ccgDesigner.observations.edit', { id: observation.id }, { reload: true });
                }
            });
        }

        states.saveInProgress = false;
    }

    deleteChildRecords(states: IObservationEditStates) {
        return Promise.all(_.union(_.map(states.observationFilterPredicateOrsToDelete, (id) => {
            return this.dataAccess.genericMethod({
                model: 'ObservationFilterPredicateOr', method: 'destroyById',
                parameters: { id }
            });
        }), _.map(states.observationFilterPredicatesToDelete, (id) => {
            return this.dataAccess.genericMethod({
                model: 'ObservationFilterPredicate', method: 'destroyById',
                parameters: { id }
            });

        }), _.map(states.observationSelectionFieldsToDelete, (id) => {
            return this.dataAccess.genericMethod({
                model: 'ObservationSelectionField', method: 'destroyById',
                parameters: { id }
            });
        }), _.map(states.observationKeyFieldsToDelete, (id) => {
            return this.dataAccess.genericMethod({
                model: 'ObservationKeyField', method: 'destroyById',
                parameters: { id }
            });
        }), _.map(states.programEpisodeObservationsToDelete, (id) => {
            return this.dataAccess.genericMethod({
                model: 'ProgramEpisodeObservation', method: 'destroyById',
                parameters: { id }
            });
        })));
    }

    saveChildRecords(states: IObservationEditStates) {
        const updatePromises: any[] = [];
        if (states.observation.observationFilterPredicates) {
            states.observation.observationFilterPredicates.forEach((observationFilterPredicate: any) => {
                observationFilterPredicate.observationId = states.observation.id;
                updatePromises.push(
                    this.dataAccess.genericUpsert({
                        model: 'ObservationFilterPredicate',
                        data: observationFilterPredicate
                    })
                );
                // Set the OR condition if one exists.
                if (observationFilterPredicate.observationFilterPredicateOrId) {
                    let observationFilterPredicateOr: any = {};
                    if (observationFilterPredicate.observationFilterPredicateOrs && observationFilterPredicate.observationFilterPredicateOrs.length === 1) {
                        observationFilterPredicateOr = observationFilterPredicate.observationFilterPredicateOrs[0];
                    }
                    observationFilterPredicateOr.observationFilterPredicateId1 = observationFilterPredicate.id;
                    observationFilterPredicateOr.observationFilterPredicateId2 = observationFilterPredicate.observationFilterPredicateOrId;
                    updatePromises.push(
                        this.dataAccess.genericUpsert({
                            model: 'ObservationFilterPredicateOr',
                            data: observationFilterPredicateOr
                        })
                    );
                }
            });
        }
        if (states.observation.observationSelectionFields) {
            states.observation.observationSelectionFields.forEach((observationSelectionField: any) => {
                observationSelectionField.observationId = states.observation.id;
                updatePromises.push(
                    this.dataAccess.genericUpsert({
                        model: 'ObservationSelectionField',
                        data: observationSelectionField
                    })
                );
            });
        }
        if (states.observation.observationKeyFields) {
            states.observation.observationKeyFields.forEach((observationKeyField: any) => {
                observationKeyField.observationId = states.observation.id;
                updatePromises.push(
                    this.dataAccess.genericUpsert({
                        model: 'ObservationKeyField',
                        data: observationKeyField
                    })
                );
            });
        }
        if (states.observation.programEpisodeObservations) {
            states.observation.programEpisodeObservations.forEach((programEpisodeObservation: any) => {
                programEpisodeObservation.observationId = states.observation.id;
                updatePromises.push(
                    this.dataAccess.genericUpsert({
                        model: 'ProgramEpisodeObservation',
                        data: programEpisodeObservation
                    })
                );
            });
        }
        return Promise.all(updatePromises);
    }
}

