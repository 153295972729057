import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IObservationListStates } from './observationList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ObservationListService } from './observationList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'argos-observationList',
    templateUrl: './observationList.component.html',
    providers: [ObservationListService]
})

export class ObservationListComponent
    extends ReactComponentBase<{}, IObservationListStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;

    constructor(private svc: ObservationListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil,  private urlService: UrlService) {
        super({
            observations: [],
            observationsTestUrl: 'https://circleci.com/gh/clarifyhealth/automation',
            filterObj: {
                //matchExtactColumnFilterValue: ['status'],    // set this to filter property to exact match
                multiColumnFilterValue: ['status']
            },
            defaultDisplayMatTableColumns: ['group_name', 'id', 'name', 'status', 'copyButton', 'bulkSelected', 'version', 'title', 'observationCategoryHierarchy', 'type_name', 'productBundleList', 'episode_subset_name', 'lastUpdatedBy', 'usageCount', 'viewCount', 'lastViewedFromNow', 'includeInIncrementalRun', 'lastUpdateDate'],
            matTableColumns: ['group_name', 'id', 'name', 'status', 'copyButton', 'bulkSelected', 'version', 'title', 'observationCategoryHierarchy', 'type_name', 'productBundleList', 'episode_subset_name',
                'description','multiplier','notes','units','category','category2','category3','category4','sql','subsetDays','denominatorObservationName','predictionStatus','isProfessional','locked','relatedCostObservations',
                'intervalName','gatherStatistics','sqlTagObservations','defaultVisibility','supportsTrends','priority','actionable','calculateDisruptionRate','ccgFeatureName','relatedPaymentObservations',
                'numericNullsAllowed','neededInPrism','usageGuide','betterDirection','external','externalJoinSql','externalTableName','githubPrUrl','interim','preAggregated','externalJoinEntity',
                'lastUpdatedBy', 'includeInIncrementalRun', 'usageCount', 'viewCount', 'lastViewedFromNow', 'lastUpdateDate'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'observationList'
        } as unknown as IObservationListStates);
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.table.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    updateObservationStatus(statusValue: string) {
        this.svc.updateObservationStatus(statusValue, this.states);
    }

    recalculateMetricStatistics() {
        this.svc.recalculateMetricStatistics();
    }

    viewMetricSQL() {
        this.svc.viewMetricSQL();
    }

    triggerObservationTest() {
        this.svc.triggerObservationTest(this.states);
    }

    selectVisibleObservation(selected: boolean) {
        this.svc.selectVisibleObservation(selected, this.states);
    }

    viewHistory(row: any) {
        this.svc.viewHistory(row, this.states);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }
    
    multiFilterChange(columnName: string, event: MatSelectChange) {
        //if uninitialized or clear filter by setting to empty array
        this.states.filterObj = this.matSvc.multiFilterChange(this.states.filterObj, columnName, event);  
        this.states.table.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.table.filter);
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj = this.matSvc.filterChange(this.states.filterObj, columnName, filterValue);  
        this.states.table.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.table.filter);
    }

    searchObservations(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.table.filter = filterValue.trim().toLowerCase();
    }

    viewEnvironments(row: any) {
        this.svc.viewEnvironments(row);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        return this.matSvc.getMatSelectPlaceholderName(columnName, filterValue);
    }
}
