import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IClarifyInsightsTriggerExtractModalInterface } from './clarifyInsightsTriggerExtractModal.component.d';
import { ArgosStoreService } from 'client/app/services/argosStore.service';

@Injectable()
export class ClarifyInsightsTriggerExtractModalService {

    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService) { }

    async initDelegate(states: IClarifyInsightsTriggerExtractModalInterface): Promise<object> {
        await this.init(states)
        return states;
    }

    async init(states: IClarifyInsightsTriggerExtractModalInterface) {
        states.dagConfig = this.updateDagConfig(states);
        states.optionalFields = this.getOptionalFields(states)
        states.optionalFieldsOriginal = [...states.optionalFields];
        states.docMd = await this.getDocMd(states);
        states.astronomerBaseUrl = await this.getDagsUrlBase();
        return states;
    }

    async getDocMd(states: IClarifyInsightsTriggerExtractModalInterface) {
        const docMd = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagDocMd',
            parameters: {
                dagId: states.dagName
            }
        });
        if (!docMd.data.doc_md) {
            // Airflow doesn't populate doc_md until the DAG is run once, populate a link to the dag instead?
            return ['No documentation available for this DAG'];
        }
        else {
            // extract and clean optional fields from doc_md
            const docMdLines = docMd.data.doc_md.split('\n');
            let docMdFiltered = '';
            for (let i = 0; i < docMdLines.length; i++) {
                if (docMdLines[i].includes('-') && !docMdLines[i].includes('filter_name')) {
                    docMdFiltered += docMdLines[i].replace('**','').replace('**','').replace('-','') + '\n';
                }
            }
            const finalDocMd = (docMdFiltered.split('\n').length > 1) ? docMdFiltered.split('\n') : ['No optional fields available for this DAG'];
            return finalDocMd.filter((doc: string) => doc !== '');
        }
    }

    updateDagConfig(states: IClarifyInsightsTriggerExtractModalInterface) {
        const dagConfig: any = {
            'filter_name': states.filterName
        };
        if (Array.isArray(states.optionalFields)) {
            for (const field of states.optionalFields) {
                dagConfig[Object.keys(field)[0]] = Object.values(field)[0];
            }
        }
        return JSON.stringify(dagConfig, null, 4);
    }

    getOptionalFields(states: IClarifyInsightsTriggerExtractModalInterface){
        // map new optional fields to their default values here
        let optionalFields: any = [];
        switch (states.extractType) {
            case 'claims':
                optionalFields = [
                    {'run_id': ''},
                    {'high_risk_patient_limit': 0},
                    {'do_not_create_athena_tables': false},
                    {'use_argos_staging': false}
                ]
                break;
            case 'market_share':
                optionalFields = [
                    {'run_id': ''},
                    {'high_risk_patient_limit': 0},
                    {'do_not_create_athena_tables': false},
                    {'use_argos_staging': false}
                ]
                break;
            case 'hospital_rates':
                optionalFields = [
                    {'run_id': ''},
                    {'do_not_create_athena_tables': false},
                    {'use_argos_staging': false}
                ]
                break;
            case ('provider_performance' || 'provider_directory'):
                break;
            default:
                break;
        }
        optionalFields = this.addCsvExportFields(optionalFields, states);
        return optionalFields
    }

    addCsvExportFields(optionalFields: any, states:IClarifyInsightsTriggerExtractModalInterface) {
        if (states.exportAsCsv) {
            optionalFields.push({'export_as_csv': true});
            optionalFields.push({'num_csv_output_files': 1});
            optionalFields.push({'export_csv_with_header': false});
            optionalFields.push({'csv_delimiter': '|'});
        }
        return optionalFields;
    }

    async launchDag(states: IClarifyInsightsTriggerExtractModalInterface) {
        const dagConfig = this.updateDagConfig(states);
        const argosUser = this.getUser();
        let astroResponse;
        try {
            astroResponse = await this.dataAccess.genericMethod({
                model: 'ClarifyInsightsDataDelivery',
                method: 'triggerDag',
                parameters: {
                    dagId: states.dagName,
                    dagConfig: dagConfig,
                    triggeredByUser: argosUser
                }
            });
            if (astroResponse.isSuccessful) {
                await this.dataAccess.genericUpsert({
                    model: 'ClarifyInsightsDataDelivery',
                    data: {
                        id: states.deliveryId,
                        latestExtractDagRunId: astroResponse.data.dag_run_id,
                        latestExtractComplete: false,
                        latestExtractBy: argosUser
                    }
                });
                return {isSuccessful: true, error: null};
            }
            return {isSuccessful: false, error: astroResponse.error}           
        }
        catch (e) {
            console.log(e);
            return {isSuccessful: false, error: e};
        }
    
    }

    async getDagsUrlBase() {
        const response = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });
        return response.data;
    }

    getUser() {
        return this.argosStore.getItem('username').replace('@clarifyhealth.com', ''); 
    }


}