import { Injectable } from '@angular/core';
import { IAnalyzeMetaDataModalProps, IAnalyzeMetaDataModalStates, IAnalyzeMetaDataModalService } from './analyzeMetaDataModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnalyzeObservationModalComponent } from '../analyzeObservationModal/analyzeObservationModal.component';
declare const saveAs: any;

@Injectable()
export class AnalyzeMetaDataModalService implements IAnalyzeMetaDataModalService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog) {
        //
    }
    async initDelegate(props: IAnalyzeMetaDataModalProps, states: IAnalyzeMetaDataModalStates): Promise<IAnalyzeMetaDataModalStates> {
        states.lodash = _;
        states.metaData = props.metaData;
        states.quickAnalysis = props.quickAnalysis;
        const newStates = await this.initialize(states);
        return newStates;
    }

    async changeDelegate(oldProps: IAnalyzeMetaDataModalProps, newProps: IAnalyzeMetaDataModalProps, states: IAnalyzeMetaDataModalStates): Promise<IAnalyzeMetaDataModalStates> {
        const newStates = await this.initDelegate(newProps, states);
        return newStates;
    }

    async initialize(initStates: IAnalyzeMetaDataModalStates): Promise<IAnalyzeMetaDataModalStates> {
        const states = _.cloneDeep(initStates);
        const data = await this.dataAccess.genericMethod({
            model: 'ClarifyAttributedProviderCCG', method: 'getObservationAnalysis', parameters: {
                body: {
                    metaData: states.metaData,
                    quickAnalysis: states.quickAnalysis
                }
            }
        });
        states.data = data;
        states.loading = false;
        return states;
    }

    exportToExcelHandler(states: IAnalyzeMetaDataModalStates) {

        let exportString = '';
        const delimitor = ',';

        const data: any[] = [];
        _.forEach(states.data, function (pt) {
            _.forEach(pt.observations, function (o) {
                data.push(
                    _.extend(
                        { providerType: pt.providerType },
                        _.omit(o, ['observedExistence', 'predictionExistence']),
                        { observedExistence: _.join(o.observedExistence, '~') },
                        { predictionExistence: _.join(o.predictionExistence, '~') }
                    )
                );
            });
        });

        if (data && data.length > 0) {
            const columns: any[] = [];
            for (const prop in data[0]) {
                if (prop[0] !== '$' && !_.isFunction(data[0][prop]) && !_.isObject(data[0][prop])) {
                    columns.push(prop);
                    exportString += (prop + delimitor);
                }
            }
            exportString += '\n';

            data.forEach(function (row) {
                columns.forEach(function (column) {
                    exportString += (`"${row[column]}"` + delimitor);
                });
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, 'cap_observation_metric_mapping.csv');
        }
    }

    analyzeObservationHandler(title: string, observationType: string, observations: any, states: IAnalyzeMetaDataModalStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    observationTitle: title,
                    observationType,
                    fromYear: _.get(_.words(states.metaData.fromDt), '[0]', '2022'),
                    toYear: _.get(_.words(states.metaData.toDt), '[0]', '2022'),
                    observations
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            AnalyzeObservationModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(($event: any) => {
            if ($event) {
                // states.careGrouping = $event;
            }
        });
    }
}
