import { Injectable } from '@angular/core';
import { IQApiPerformanceProductDetailStates, IQApiPerformanceProductDetailService } from './apiPerformanceTestResultsDashboardProductsDetail.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';
import { PlatformReleaseResultsService } from '../../../services/platformReleaseResultsService.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiPerformanceTestResultsDashboardProductsDetailModalComponent } from '../apiPerformanceTestResultsProductDetail/apiPerformanceTestResultsProductDetailModal/apiPerformanceTestResultsProductDetailModal.component';

@Injectable()
export class QAApiPerformanceResultDetailService implements IQApiPerformanceProductDetailService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService, private platformReleaseResultsService: PlatformReleaseResultsService, private matDialog: MatDialog) {
        //
    }

    async initDelegate(states: IQApiPerformanceProductDetailStates): Promise<object> {
        await this.fetchResults(states);
        return {};
    }

    async fetchResults(states: IQApiPerformanceProductDetailStates) {
        const apiPerformanceTestResults = await this.dataAccess.genericFind({
            model: 'ApiPerformanceTestResults',
            filter: {
                where: {
                    and: [
                        { product_name: { like: this.uiRouter.globals.params.id } },
                        { module_name: { like: this.uiRouter.globals.params.module } }
                    ]
                }
            },
        });

        states.product = this.uiRouter.globals.params.id;
        states.module = this.uiRouter.globals.params.module;

        // Set state data for Trend chart
        states.apiPerformanceTestResults = apiPerformanceTestResults;

        const resultsGroupedByPageName = _(apiPerformanceTestResults)
            .filter((obj: any) => obj.release_name !== '')
            .map((obj: any) => ({ ...obj, release_name: new Date(obj.release_name).toLocaleDateString('es-pa') }))
            .orderBy(['submodule_name', 'dashboard_name', 'tab_name', 'product_name', 'release_name'], ['asc', 'asc', 'desc', 'asc'])
            .groupBy((item) => [item['submodule_name'], item['dashboard_name'], item['tab_name']])
            .value();

        const apiColumnKeys = _(states.apiPerformanceTestResults)
            .filter((obj: any) => obj.release_name !== '')
            .map((obj: any) => ({ ...obj, release_name: new Date(obj.release_name).toLocaleDateString('es-pa') }))
            .orderBy(['product_name', 'release_name'], ['desc', 'asc'])
            .orderBy([(obj) => {
              return new Date(obj.release_name);
            }],['asc'])
            .groupBy('release_name')
            .value();

        const structuredData: object[] = [];
        const groupedUncachedResults: object[] = [];

        const releaseKeys: string[] = Object.keys(resultsGroupedByPageName);

        for (let i = 0; i < releaseKeys.length; i++) {
            const releaseData = _.orderBy(resultsGroupedByPageName[releaseKeys[i]], ['dashboard_name']);
            let currentPageCachedData: any;
            let currentPageUncachedData: any;
            for (let j = 0; j < releaseData.length; j++) {
                const releaseKey = releaseData[j].release_name;

                if (j === 0) {
                    currentPageCachedData = {
                        submodule: releaseData[j].submodule_name,
                        dashboard: releaseData[j].dashboard_name,
                        tab: releaseData[j].tab_name,
                        [releaseKey]: releaseData[j].cached_load_time
                    };
                } else {
                    currentPageCachedData[releaseKey] = releaseData[j].cached_load_time;
                }
                if (releaseData[j].uncached_load_time) {
                    if (j === 0) {
                        currentPageUncachedData = {
                            submodule: releaseData[j].submodule_name,
                            dashboard: releaseData[j].dashboard_name,
                            tab: releaseData[j].tab_name,
                            [releaseKey]: releaseData[j].uncached_load_time
                        };
                    } else {
                        currentPageUncachedData[releaseKey] = releaseData[j].uncached_load_time;
                    }
                }
            }

            structuredData.push(currentPageCachedData);
            if (currentPageUncachedData) {
                groupedUncachedResults.push(currentPageUncachedData);
            }
        }
        states.apiPerformanceTestDataTable.data = structuredData;
        states.apiDisplayedColumns = ['submodule', 'dashboard', 'tab', ...(Object.keys(apiColumnKeys))];

        // set states for uncached api performance results
        states.apiPerformanceTestUncachedDataTable.data = groupedUncachedResults;

    }

    viewAllApiPerformanceTestResultsHnadler(states: IQApiPerformanceProductDetailStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    apiPerformanceTestResults: states.apiPerformanceTestResults,
                }
            }
        };

        this.matDialog.open(ApiPerformanceTestResultsDashboardProductsDetailModalComponent, dialogConfig);
    }
}
