import { Injectable } from '@angular/core';
import { ICompoundFiltersAddEditStates, ICompoundFiltersAddEditService } from './compoundFiltersAddEdit.component.d';
import { StateService, UIRouter } from '@uirouter/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { AlertService } from '../../../services/alert.service';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CompoundFiltersSelectCategoryModalComponent } from '../compoundFiltersSelectCategoryModal/compoundFiltersSelectCategoryModal.component';

@Injectable()
export class CompoundFiltersAddEditService implements ICompoundFiltersAddEditService {
    constructor(private state: StateService, private uiRouter: UIRouter,
        private dataAccess: NgDataAccess, private alertSvc: AlertService,
        private matDialog: MatDialog) {
        //
    }
    async initDelegate(states: ICompoundFiltersAddEditStates): Promise<Object> {
        await this.activate(states);
        return {};
    }

    async activate(states: ICompoundFiltersAddEditStates) {
        states.validCareGroupings = {};
        switch (this.uiRouter.globals.current.name) {
            case 'argos.ccgDesigner.compoundFilters.add':
                states.editRow = false;
                states.mode = 'ADD';
                break;
            case 'argos.ccgDesigner.compoundFilters.edit':
            default:
                states.editRow = true;
                states.mode = 'EDIT';
                break;
        }
        if (states.editRow) {

            const results = await this.dataAccess.genericMethod({
                model: 'CompoundFilter', method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.name
                }
            });

            states.compoundFilters = results;
        }

        await Promise.all([
            this.dataAccess.genericFind({
                model: 'ProductBundle',
                filter: { order: 'title' }
            }),
            this.dataAccess.genericFind({
                model: 'CareGrouping',
                filter: { order: 'title', where: { assignable: true } }
            }),
            this.dataAccess.genericFind({
                model: 'EpisodeGroupingEntity',
                filter: { order: 'entity' }
            })
        ])
            .then((results: any[]) => {
                states.productBundles = results[0];
                states.careGroupings = results[1];
                states.episodeGroupingEntities = _.map(results[2], 'entity');
                if (this.uiRouter.globals.current.name) {
                    _.forEach(states.compoundFilters.validCareGroupings, function (validCareGrouping) {
                        states.validCareGroupings[validCareGrouping] = true;
                    });
                    _.forEach(states.compoundFilters.productBundles, function (productBundle) {
                        const bundle: any = _.find(states.productBundles, { shortName: productBundle });
                        if (bundle) {
                            bundle.enabled = true;
                        }
                    });
                } else {
                    states.compoundFilters = {
                        templateTokens: []
                    };
                }
            });
    }

    async compoundFiltersNameChangedHandler(states: ICompoundFiltersAddEditStates) {
        const response = await this.dataAccess.genericFind({
            model: 'CompoundFilter',
            filter: {
                where: {
                    name: states.compoundFilters.name
                }
            }
        });
        if ((response && response.length > 0)) {
            states.nameError = false;
            // states.compoundFilterForm.name.$setValidity('exists', false);
        }
    }

    async addCompoundFilter(states: ICompoundFiltersAddEditStates) {
        let atLeastOneError = false;
        states.saveInProgress = true;
        if (this.validateTokens(states)) {
            states.compoundFilters.validCareGroupings = [];
            states.compoundFilters.productBundles = _.map(_.filter(states.productBundles, { enabled: true }), 'shortName');
            _.forEach(states.careGroupings, function (careGrouping) {
                if (states.validCareGroupings[careGrouping.name]) {
                    states.compoundFilters.validCareGroupings.push(careGrouping.name);
                }
            });
            _.forEach(_.get(states, 'compoundFilters.templateTokens'), function (token) {
                if ((token.type === 'SingleSelect' || token.type === 'MultiSelect') && !token.groupByAttribute) {
                    atLeastOneError = true;
                }
            });
            if (atLeastOneError) {
                states.saveInProgress = false;
                return;
            }
            this.updateCompoundFilterDates(states);
            try {
                await this.dataAccess.genericUpsert({
                    model: 'CompoundFilter',
                    data: states.compoundFilters
                });
                this.state.go('argos.ccgDesigner.compoundFilters.list');
                states.saveInProgress = false;
            } catch (e) {
                this.alertSvc.handleError(e);
            }
        } else {
            states.saveInProgress = false;
        }

    }

    updateCompoundFilterDates(states: ICompoundFiltersAddEditStates) {
        switch (states.mode) {
            case 'ADD':
                states.compoundFilters.createdDate = moment().toDate();
                states.compoundFilters.updatedDate = moment().toDate();
                break;
            case 'EDIT':
            default:
                states.compoundFilters.updatedDate = moment().toDate();
                break;
        }
    }

    addTemplateToken(states: ICompoundFiltersAddEditStates) {
        states.compoundFilters.templateTokens.push({});
    }

    deleteTemplateToken(row: any, index: number, states: ICompoundFiltersAddEditStates) {
        swal({
            title: 'Delete Template Token',
            text: 'Are you sure you want to delete this row?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                if (row) {
                    states.compoundFilters.templateTokens.splice(index, 1);
                }
            }
        });
    }

    validateTokens(states: ICompoundFiltersAddEditStates) {
        const formattedTokenArr: any[] = [];
        states.showTokenErrors.sql = [];
        states.showTokenErrors.desc = [];
        _.forEach(states.compoundFilters.templateTokens, function (token) {
            formattedTokenArr.push('{' + token.name + '}');
        });
        _.forEach(formattedTokenArr, function (ftt) {
            if (!_.includes(states.compoundFilters.sqlTemplate, ftt)) {
                states.showTokenErrors.sql.push(ftt);
            }
        });
        _.forEach(formattedTokenArr, function (ftt) {
            if (!_.includes(states.compoundFilters.descriptionTemplate, ftt)) {
                states.showTokenErrors.desc.push(ftt);
            }
        });
        if (_.isEmpty(states.showTokenErrors.desc) && _.isEmpty(states.showTokenErrors.sql)) {
            return true;
        }
        states.saveInProgress = false;
        return false;
    }

    editDropDownCategoryHandler(row: any, index: number, states: ICompoundFiltersAddEditStates, cb: Function) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    compoundFilter: states.compoundFilters,
                    tokenIndex: index
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            CompoundFiltersSelectCategoryModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((event: any) => {
            if (event && event.eventName === 'apply') {
                states.compoundFilters = _.cloneDeep(event.data);
                cb();
            }
        });
    }
}
