import { Injectable } from "@angular/core";
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IEditDataReleaseStates, IEditDataReleaseService } from './editDataRelease.component.d';
import { UIRouter } from '@uirouter/core';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from "lodash";
import { StateService } from '@uirouter/core';


@Injectable()
export class EditDataReleaseServices implements IEditDataReleaseService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService,
                private uiRouter: UIRouter, private state: StateService) {
    }

    async initDelegate(states: IEditDataReleaseStates): Promise<object> {
        await this.init(states);
        return states;
    };

    async init(states: IEditDataReleaseStates) {
        const astronomerDagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });
        states.astronomerBaseUrl = astronomerDagsUrl.data;
        await this.getReleaseInfo(states);
        return states;
    };

    async getActiveDags() {
        const finalDags = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsByPattern',
            parameters: {
                pattern: 'deployment-final'
            }
        });
        const rawDags = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsByPattern',
            parameters: {
                pattern: 'deployment-raw'
            }
        });
        const abstractDags = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsByPattern',
            parameters: {
                pattern: 'deployment-abstract'
            }
        });
        const refDags = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsByPattern',
            parameters: {
                pattern: 'deployment-reference'
            }
        });
        const allDags = finalDags.data.concat(rawDags.data).concat(abstractDags.data).concat(refDags.data);
        return allDags;
    }
    
    async filterDagsByType(dagsType: string, finalDags: any) {
        const dagTypes: string[] = ['customer', 'clarify_data'];
        let filteredDags = finalDags.filter((dag: any) => dag.tags.map((tag: { name: string; }) => tag.name).includes(dagsType));
        // dags missing tags get loaded into both customer and clarify_data
        const dagsWithNoTags = finalDags.filter((dag: any) => dag.tags.map((tag: { name: string; }) => tag.name).includes(dagTypes[0]) === false 
                                    && dag.tags.map((tag: { name: string; }) => tag.name).includes(dagTypes[1]) === false);
        filteredDags = filteredDags.concat(dagsWithNoTags);
        const dagsList = filteredDags.map((dag: any) => dag.dag_id);
        const selectableDags = dagsList.filter((dag_id: String) => !dag_id.startsWith('pseudo') && !dag_id.startsWith('post-deployment') && !dag_id.includes('qa-to-staging'));
        return selectableDags.sort();
    };

    async getReleaseInfo(states: IEditDataReleaseStates) {
        states.releaseDate = this.uiRouter.globals.params.releaseDate;
        states.releaseName = this.uiRouter.globals.params.releaseName;
        states.releaseId = this.uiRouter.globals.params.releaseId;
        const tempDags = await this.dataAccess.genericMethod({
            model: 'DataReleaseDags',
            method: 'getDagsInRelease',
            parameters: {
                releaseId: states.releaseId
            }
        });

        const checkStatusStates = ['Queued','Running']
        for (const dag of tempDags) {
            let dagStatus = dag.pseudoDagStatus;
            let dagRunId =  dag.pseudoDagRunId;
            let dagId = `pseudo-${dag.dagId}`;
            if (checkStatusStates.includes(dagStatus) && dagRunId){
                const dagStatusResponse = await this.dataAccess.genericMethod({
                    model: 'Astronomer',
                    method: 'getDagStatus', 
                    parameters: {
                        dagId: dagId,
                        dagRunId: dagRunId
                    }
                });
                let currentDagStatus = (dagStatusResponse.data && dagStatusResponse.data[0].toUpperCase() + dagStatusResponse.data.slice(1)) || ""
                currentDagStatus = (currentDagStatus === 'Queued') ? 'Running' : currentDagStatus;
                if (currentDagStatus !== dagStatus){
                    await this.dataAccess.genericMethod({
                        model: 'DataReleaseDags',
                        method: 'updateDagStatus',
                        parameters: {
                            id: dag.id,
                            dagStatus: currentDagStatus,
                            isPseudo: true
                        }
                    });
                    await this.dataAccess.genericMethod({
                        model: 'DataReleaseDagRuns',
                        method: 'updateDagRunStatus',
                        parameters: {
                            dagRunId: dagRunId,
                            dagStatus: currentDagStatus
                        }
                    });
                    dag.psuedoDagStatus = currentDagStatus;                    
                }
            }
        }

        states.dagsOnLoad = [...tempDags];
        states.dagsInRelease = [...tempDags];
        states.releaseInfo = await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'getReleaseInfo',
            parameters: {
                releaseId: states.releaseId
            }
        });
    };

    async addDagToReleaseDelegate(states: IEditDataReleaseStates, dagId: string, dagConfig: any, activeDags: any) {
        states.dagsInRelease.push({
            dagId: dagId,
            dagConfig: dagConfig,
            addedByUser: this.getUser()
        });
        states.dagsInRelease = [...states.dagsInRelease];
        await this.updateReleaseDelegate(states, true, activeDags);
    };
    
    async removeDagFromReleaseDelegate(states: IEditDataReleaseStates, dagId: string, activeDags: any) {
        states.dagsInRelease = states.dagsInRelease.filter((dag: any) => dag.dagId !== dagId);
        states.dagsInRelease = [...states.dagsInRelease];
        await this.updateReleaseDelegate(states, false, activeDags);
    };

    async editDagConfigDelegate(states: IEditDataReleaseStates, dagId: string, dagConfig: string, activeDags: any) {
        const updatedDag = states.dagsInRelease.filter((dag: any) => dag.dagId === dagId);
        states.dagsInRelease = states.dagsInRelease.filter((dag: any) => dag.dagId !== dagId);
        states.dagsInRelease.push({
            dagId: dagId,
            dagConfig: dagConfig,
            addedByUser: updatedDag[0].addedByUser
        });
        await this.updateReleaseDelegate(states, true, activeDags);
    };

    async updateReleaseDelegate(states: IEditDataReleaseStates, addingDag: boolean, activeDags: any) {
        const dagsList = states.dagsInRelease.map((dag: any) => dag.dagId);
        const activeDagIds = activeDags.map((dag: any) => dag.dag_id);
        const lastEditedByUser = this.getUser();
        await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'updateRelease',
            parameters: {
                releaseId: states.releaseId,
                dagsList: JSON.stringify(dagsList),
                lastEditedByUser: lastEditedByUser
            }
        });

        if (addingDag) {
            for (const dag of states.dagsInRelease) {
                if (states.dagsOnLoad.map((dagOnLoad: any) => dagOnLoad.dagId).includes(dag.dagId)) {
                    if (!dag.id) {
                        dag.id = states.dagsOnLoad.filter((dagOnLoad: any) => dagOnLoad.dagId === dag.dagId)[0].id;
                    }
                    await this.dataAccess.genericMethod({
                        model: 'DataReleaseDags',
                        method: 'updateDagInRelease',
                        parameters: {
                            id: dag.id,
                            dagConfig: dag.dagConfig,
                            lastEditedByUser: lastEditedByUser
                        }
                    });
                }
                else {
                    let pseudoDagId = `pseudo-${dag.dagId}`;
                    let pseudoDagStatus = activeDagIds.includes(pseudoDagId) ? 'Pending' : 'None';
                    await this.dataAccess.genericMethod({
                        model: 'DataReleaseDags',
                        method: 'addDagToRelease',
                        parameters: {
                            releaseId: states.releaseId,
                            dagId: dag.dagId,
                            pseudoDagStatus: pseudoDagStatus, 
                            dagConfig: dag.dagConfig,
                            addedByUser: lastEditedByUser
                        }
                    });
                }
            }
        }
        else {
            const finalDags = states.dagsInRelease.map((dag) => dag.dagId);
            for (const initDag of states.dagsOnLoad) {
                if (!finalDags.includes(initDag.dagId)) {
                    let dataReleaseDagRuns = await this.dataAccess.genericMethod({
                        model: 'DataReleaseDagRuns',
                        method: 'findDataReleaseDagRunIds',
                        parameters: {
                            dataReleaseDagId: initDag.id
                        }
                    });
                    let dataReleaseDagRunIds = dataReleaseDagRuns.map((run: any) => run.id);
                    _.forEach(dataReleaseDagRunIds, async(dataReleaseDagRunId) => {
                        await this.dataAccess.genericMethod({
                            model: 'DataReleaseDagRuns',
                            method: 'destroyById',
                            parameters: {
                                id: dataReleaseDagRunId
                            }
                        });
                    })
                    await this.dataAccess.genericMethod({
                        model: 'DataReleaseDags',
                        method: 'destroyById',
                        parameters: {
                            id: initDag.id
                        }
                    });
                }
            }
        }
        await this.getReleaseInfo(states);
    };

    async finalizeReleaseDelegate(states: IEditDataReleaseStates) {
        const releaseId = states.releaseId;
        const userName = this.getUser();
        await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'updateReleaseStatus',
            parameters: {
                releaseId: releaseId,
                releaseStatus: 'Finalized',
                finalizedByUser: userName
            }
        })
        await this.addTicketComment(states);
        // set to Ready For Devops
        await this.updateJiraTicketStatus(states.releaseInfo[0].rmJiraTicketId, 81); 
        // set assignee to Devops
        await this.updateTicketAssignee(states.releaseInfo[0].rmJiraTicketId, '557058:33a29884-e95d-4bc6-b9b9-cb03f5172dce');
        this.state.go('argos.data.data_release_manager.list');
    };

    async updateJiraTicketStatus(jiraTicketId: string, newStatusCode: number) {
        const transitionResponse = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'transitionIssue',
            parameters: {
                jiraTicketId: jiraTicketId,
                transitionId: newStatusCode
            }
        });
        return transitionResponse;
    };
    
    async addTicketComment(states: IEditDataReleaseStates) {
        const comment = `Release Dags:\n ${states.dagsInRelease.map((dag: any) => dag.dagId).join(', \n')}`;
        const jiraResponse = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'addComment',
            parameters: {
                jiraTicketId: states.releaseInfo[0].rmJiraTicketId,
                comment: comment
            }
        });
        return jiraResponse;
    };

    async updateTicketAssignee(jiraTicketId: string, assigneeJiraId: string) {
        const jiraResponse = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'updateAssigneeWithId',
            parameters: {
                jiraTicketId: jiraTicketId,
                assigneeId: assigneeJiraId
            }
        });
        return jiraResponse;
    }

    getUser() {
        const username = this.argosStore.getItem('username');
        return username;
    };

    startTimerWithInterval(states: IEditDataReleaseStates) {
        if (states.myInterval) {
            clearInterval(states.myInterval);
        }

        states.myInterval = setInterval(async () => {
            await this.getReleaseInfo(states);
        }, 60000)
    };

    async resetPseudoDagDelegate(states: IEditDataReleaseStates, id: string) {

        await this.dataAccess.genericUpsert({
            model: 'DataReleaseDags',
            data: {
                id: id,
                pseudoDagStatus: 'Pending',
                pseudoDagRunId: null
            }
        })
        await this.getReleaseInfo(states);
    }
}