import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { ITestSqlModalProps, ITestSqlModalStates } from './testSqlModal.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { TestSqlModalService } from './testSqlModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import swal from 'sweetalert2';

@Component({
    selector: 'argos-testSQLModal',
    templateUrl: './testSqlModal.component.html',
    providers: [TestSqlModalService]
})

export class TestSqlModalComponent
    extends ReactComponentBase<ITestSqlModalProps, ITestSqlModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: TestSqlModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<TestSqlModalComponent>) {
        super({
            careGroupings: []
        } as unknown as ITestSqlModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        if (this.props.isSaving) {
            swal({
                title: 'Changes will not be saved',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#57C84D',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                cancelButtonColor: '#00bfff'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    this.dialogRef.close('cancel');
                } else {
                    return;
                }
            });
        } else {
            this.dialogRef.close();
        }
    }

    selectedCareGroupings() {
        return this.svc.selectedCareGroupings(this.states);
    }

    async runTests() {
        await this.svc.runTestsHandler(this.states);
    }

    save() {
        this.dialogRef.close('save');
    }

    copyToClipboard(sql: any) {
        this.svc.copyToClipboardHandler(sql);
    }
}
