// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td.data-column {
    overflow: hidden;
    max-width: 500px;
    word-wrap: break-word;
    padding: 16px 16px 16px 16px;
    border-bottom: 1px solid #000000;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
}

td.label-column {
    white-space: nowrap;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
}

table {
    max-width: 100%;
    table-layout: fixed; 
}
`, "",{"version":3,"sources":["webpack://./client/app/components/clarifyInsights/clarifyInsightsFilterHistory/clarifyInsightsFilterHistory.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;IACrB,4BAA4B;IAC5B,gCAAgC;IAChC,6BAA6B;IAC7B,+BAA+B;AACnC;;AAEA;IACI,mBAAmB;IACnB,+BAA+B;IAC/B,gCAAgC;IAChC,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":["td.data-column {\n    overflow: hidden;\n    max-width: 500px;\n    word-wrap: break-word;\n    padding: 16px 16px 16px 16px;\n    border-bottom: 1px solid #000000;\n    border-top: 1px solid #000000;\n    border-right: 1px solid #000000;\n}\n\ntd.label-column {\n    white-space: nowrap;\n    border-right: 1px solid #000000;\n    border-bottom: 1px solid #000000;\n    border-top: 1px solid #000000;\n    border-left: 1px solid #000000;\n}\n\ntable {\n    max-width: 100%;\n    table-layout: fixed; \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
