
import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import { Injectable, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { EcsAppShare } from './ecsAppShareHandler.service';
import { AlertService } from 'client/app/services/alert.service';
import * as ynModule from 'yn'
import * as moment from 'moment';
@Injectable()
export class EcsAppTask {
    constructor(private dataAccess: NgDataAccess,
        private ecsAppShareHandler: EcsAppShare, private alertSvc: AlertService, private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef) {
        //
    }

    setFargateTaskCpu(states: IEcsAppEditStates, containerTaskDef: any, taskContainerDef: any) {

        if (containerTaskDef) {
            const memValue = parseInt(containerTaskDef.taskDefinition.memory);

            if (memValue > 16384) {
                // more than 16GB
                containerTaskDef.taskDefinition.cpu = '4096';
            } else if (memValue > 3072) {
                // 4-16GB ram
                containerTaskDef.taskDefinition.cpu = '2048';
            } else if (memValue > 1024) {
                // 2-3 GB ram
                containerTaskDef.taskDefinition.cpu = '1024';
            } else {
                containerTaskDef.taskDefinition.cpu = '512';
            }

            if (taskContainerDef) {
                //update node options arg for task def
                //containerTaskDef.taskDefinition.family
                this.ecsAppShareHandler.setWebTaskContainerEnvVar('NODE_OPTIONS', '--max_old_space_size=' + containerTaskDef.taskDefinition.memory, containerTaskDef.taskDefinition.family, taskContainerDef.environment, states)
            }
        }
    }

    //parse the most recent task defs used by the cluster
    async initTaskDefSettings(accountName: string, states: IEcsAppEditStates) {

        if (states.containerTaskDefinitions.length > 0) {
            const tasks = states.containerTaskDefinitions[0];

            // no active tasks are running
            if (!tasks) {
                return;
            }

            //clone the current task def
            const webTaskDefs = _.find(_.cloneDeep(tasks.taskDefinitions), function (td) {
                if (td.taskDefinition.taskDefinitionArn.indexOf(states.clusterName + '-task:') >= 0) {
                    return td;
                }
            });
            
            if (webTaskDefs) {
 
                const containersFiltered = this.ecsAppShareHandler.excludeTaskDefContainerDefintion(webTaskDefs.taskDefinition.containerDefinitions);

                if (containersFiltered.length > 0 && states.repositoryName === '') {

                    states.repositoryName = containersFiltered[0].image;

                    const taskDefInfo = webTaskDefs.taskDefinition.taskDefinitionArn.split('/')[1];
                    /*
                    if (taskDefInfo && taskDefInfo.split(':').length === 2) {
                        states.currentWebTaskDef.name = taskDefInfo.split(':')[0];
                        states.currentWebTaskDef.revisionNumber = taskDefInfo.split(':')[1];
                    }
                    */
                    const repoInfo = states.repositoryName.split('/')[1].split(':');

                    states.repositoryName = repoInfo[0];

                    if (!states.cluster.environment.ecsParameters.ecrRepoName || states.cluster.environment.ecsParameters.ecrRepoName !== states.repositoryName) {
                        states.cluster.environment.ecsParameters.ecrRepoName = states.repositoryName;
                        this.ecsAppShareHandler.saveEnvironmentParaInfo('ecrRepoName', states.repositoryName, states);
                    }

                    if (ynModule(states.cluster.environment.ecsParameters.isExcludedFromReleaseCycle)) {
                        states.cluster.isExcludedFromReleaseCycle = true;
                    } else {
                        states.cluster.isExcludedFromReleaseCycle = false;
                    }

                    if (repoInfo[1]) {
                        states.currentBuildTag = repoInfo[1];
                        states.selectedBuildTag = states.currentBuildTag;
                    }
                }
                const data = await this.dataAccess.genericMethod({
                    model: 'Environment', method: 'getEcsBuilds', parameters: {
                        repositoryName: states.repositoryName, awsAccountName: accountName
                    }
                });
                states.buildTags = data.response;

                for (let i = states.buildTags.length - 1; i >= 0; i--) {
                    if (!states.buildTags[i]) {
                        states.buildTags.splice(i, 1);
                    }
                }

                if (states.currentBuildTag === 'latest' && states.buildTags.indexOf('latest') === -1) {
                    states.buildTags.push('latest');
                }

                await this.getEnvironmentBuildVersion(states);
            }
        }
    }

    async getEnvironmentBuildVersion(states: IEcsAppEditStates) {
        if (states.cluster.appUrl && states.cluster.environment.applicationType === 'prism') {
            try {
                const version = await this.dataAccess.genericMethod({
                    model: 'Environment', method: 'getAppVersion',
                    parameters: {
                        id: states.cluster?.environment?.id
                    }
                });
                states.cluster.uiVersion = version.localVersion || version.version;

                if (states.cluster.uiVersion) {
                    states.cluster.uiVersion = states.cluster.uiVersion.toString().trim();
                    states.cluster.lastLoginDate = new Date((version.lastLogin && version.lastLogin.length > 0) ? version.lastLogin : null);
                    states.cluster.lastLoginDate = moment(states.cluster.lastLoginDate).format('MM/DD/YYYY');
                    states.cluster.lastLoginDaysAgo = moment().diff(new Date(states.cluster.lastLoginDate), 'days');
                } else {
                    states.cluster.uiVersion = 'UI Version Unavailable';
                }

                if (version.installedVersions) {
                    version.installedVersions.forEach(function (iv: any) {
                        if (iv.settingKey && iv.settingKey === 'CarePrismFunctionsAndViews') {
                            states.cluster.dbVersion = iv.settingValue.toString().trim();
                        }
                        if (iv.settingKey && iv.settingKey === 'PatchesApplied') {
                            states.cluster.appliedPatches = iv.settingValue === '0' ? [] : JSON.parse(iv.settingValue);
                            if (!_.size(states.cluster.appliedPatches)) {
                                states.selectedPatchNames = 'No Patches Applied';
                            }
                        }
                    });
                } else {
                    // we cant find a valid installedVersions value
                    states.cluster.dbVersion = 'DB Version Unavailable';
                }
                this.validateEcsApp(states);
                this.cdr.detectChanges();
            } catch (error) {
                states.cluster.uiVersion = 'NA';
                this.alertSvc.handleError(error);
            }
        }
    }

    validateEcsApp(states: IEcsAppEditStates) {
        states.cluster.isValid = true;

        if (states.cluster.dbVersion !== states.cluster.uiVersion) {
            states.cluster.isValid = false;
            states.cluster.errorMessage.push('DB and UI version do not match ' + states.cluster.dbVersion + ' ' + states.cluster.uiVersion + '.');
        }
    }

    checkTaskDefVariables(states: IEcsAppEditStates) {
        if (states.cluster.containers.length > 0) {
            const container = states.cluster.containers[0];
            const controlEnvObject: any[] = [];

            const secretEnvName = 'ATHENA_SECRET';
            const imageTagEnvName = 'IMAGE_BUILD_TAG';

            states.envVarsAllowedToFlowDownIntoNonWebAndWorkerTasks.push(secretEnvName);
            states.envVarsAllowedToFlowDownIntoNonWebAndWorkerTasks.unshift(imageTagEnvName);

            // get the task def values and use them as our control
            let taskDef = this.ecsAppShareHandler.getContainerDefinitions(container, '-task', states);

            if (!taskDef || taskDef.length === 0) {
                return;
            } else {
                taskDef = taskDef[0];
            }
            // get AWS Permission used
            if (states.repositoryName === 'clarify-platform') {
                states.cluster.awsAccessPermission = states.awsAccessPermission[0]; // default
                taskDef.secrets.forEach(function (s: any) {
                    if (s.name.toUpperCase() === secretEnvName) {
                        states.cluster.awsAccessPermission = states.awsAccessPermission[1];
                    }
                });
            } else {
                // this is not a prism 2.0 app
                states.cluster.awsAccessPermission = states.awsAccessPermission[1];
            }
            states.cluster.newAwsAccessPermission = states.cluster.awsAccessPermission;

            // set control values
            states.envVarsAllowedToFlowDownIntoNonWebAndWorkerTasks.forEach(function (key) {
                controlEnvObject.push({
                    name: key,
                    value: _.get(_.find(taskDef.environment, { name: key }), 'value')
                });
            });

            const controlBuildTag = (taskDef && taskDef.image && taskDef.image.split(':').length > 0 ? taskDef.image.split(':')[1] : 'NA');
            const controlSecretAthenaValue = _.get(_.find(taskDef.secrets, { name: secretEnvName }), 'valueFrom');

            // states.cluster.isTaskDefSslRequired = false;
            states.cluster.taskGroupsInfo = [];
            states.cluster.taskGroups.forEach((tg: any) => {
                const tgObj: any = {
                    envVars: []
                };
                const filterValue = tg.replace(states.cluster.clusterName, '');
                let taskDef = this.ecsAppShareHandler.getContainerDefinitions(container, filterValue, states);

                if (taskDef.length > 0) {
                    taskDef = taskDef[0];
                    tgObj.name = tg;
                    states.cluster.taskGroupsInfo.push(tgObj);
                    const currentBuildTag = (taskDef && taskDef.image && taskDef.image.split(':').length > 0 ? taskDef.image.split(':')[1] : 'NA');

                    // validation order needs to match the envVarsAllowedToFlowDownIntoNonWebAndWorkerTasks order
                    // validate build image
                    tgObj.envVars.push({
                        name: imageTagEnvName,
                        value: currentBuildTag,
                        isError: controlBuildTag !== currentBuildTag
                    });

                    // validate environment variables
                    states.envVarsAllowedToFlowDownIntoNonWebAndWorkerTasks.forEach((key: string) => {
                        if (key !== secretEnvName && key !== imageTagEnvName) {
                            const controlValue = _.get(_.find(controlEnvObject, { name: key }), 'value');
                            const envVars = (taskDef.environment ? taskDef.environment : []);
                            const currentEnv = this.validateTaskSecret(envVars, key, 'value', controlValue);
                            tgObj.envVars.push(currentEnv);
                        }
                    });

                    // validate athena secret
                    const secretVars = (taskDef.secrets ? taskDef.secrets : []);
                    const currentSecret = this.validateTaskSecret(secretVars, secretEnvName, 'valueFrom', controlSecretAthenaValue);
                    tgObj.envVars.push(currentSecret);
                    if (states.cluster.awsAccessPermission === 'IAM Role' && currentSecret.value) {
                        states.cluster.errorMessage.push('Environment will not function as expected. ' + tg + ' cannot have ' + secretEnvName + ' set when using ' + states.cluster.awsAccessPermission);
                    }

                    tgObj.hasError = _.sum(_.map(tgObj.envVars, 'isError')) > 0;

                }   // if
            });

            states.cluster.taskGroupsInfo = _.orderBy(states.cluster.taskGroupsInfo, function (tg) { return tg.name.length; }, ['asc']);
        }
    }

    validateTaskSecret(values: any[], key: string, fieldName: string, controlValue: string) {
        const currentEnv = {
            name: key,
            value: (_.get(_.find(values, { name: key }), fieldName)),
            isError: false
        };

        if (currentEnv.value !== controlValue) {
            currentEnv.isError = true;
        }

        return currentEnv;
    }

    async stopTaskDef(taskId: string, taskName: string, states: IEcsAppEditStates) {
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'stopTask', parameters: {
                clusterName: states.clusterName,
                taskId,
                taskName,
                awsAccountName: states.cluster.accountName,
                requestedBy: this.argosStore.getItem('username')
            }
        });

        return result;
    }
}
