import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IMarkedForDeletionListStates, IMarkedForDeletionListService } from './markedForDeletionList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteCommandsModalComponent } from '../deleteCommandsModal/deleteCommandsModal.component';
import { StateService } from '@uirouter/core';
import { EditJoinEntityModalComponent } from '../../episodeGroupings/editJoinEntityModal/editJoinEntityModal.component';

@Injectable()
export class MarkedForDeletionListService implements IMarkedForDeletionListService {

    readonly objectMapping: any = {
        episodeGrouping: {
            title: 'Grouping/Filter',
            table: 'episode_grouping',
            argosTable: 'episode_grouping',
            key: 'group_by_attribute',
            folder: 'episodeGroupings'
        },
        otherMetric: {
            title: 'Metric',
            table: 'metric',
            argosTable: 'other_metric',
            key: 'name',
            folder: 'otherMetrics'
        },
        episodeGroupingEntity: {
            title: 'Join',
            table: 'episode_grouping_entity',
            argosTable: 'episode_grouping_entity',
            key: 'entity',
            folder: 'episodeGroupingEntities'
        },
        careGrouping: {
            title: 'Care Grouping',
            table: 'care_grouping',
            argosTable: 'care_grouping',
            key: 'name',
            folder: 'careGroupings'
        },
        compoundFilter: {
            title: 'Compound Filter',
            table: 'compound_filter',
            argosTable: 'compound_filter',
            key: 'name',
            folder: 'compoundFilters'
        }
    };

    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private cdr: ChangeDetectorRef,
        private state: StateService) {
        //
    }
    async initDelegate(states: IMarkedForDeletionListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IMarkedForDeletionListStates) {

        const markedForDeletions = await this.dataAccess.genericFind({
            model: 'MarkForDeletion',
            filter: {
                where: {
                    deleted: false
                },
                order: 'objectType, objectName'
            }
        });

        states.markedForDeletions = markedForDeletions;
        states.markedForDeletionTable.data = states.markedForDeletions;
        states.viewPreviouslyDeleted = false;
        this.cdr.detectChanges();
    }

    async viewPreviouslyDeleted(states: IMarkedForDeletionListStates) {

        const markedForDeletions = await this.dataAccess.genericFind({
            model: 'MarkForDeletion',
            filter: {
                where: {
                    deleted: true
                },
                order: 'objectType, objectName'
            }
        });

        states.markedForDeletions = markedForDeletions;
        states.markedForDeletionTable.data = states.markedForDeletions;
        states.viewPreviouslyDeleted = true;
        this.cdr.detectChanges();
    }

    getTitle(objectType: string) {
        if (this.objectMapping[objectType]) {
            return this.objectMapping[objectType].title;
        }
        return objectType;
    }

    generateDeleteScript(states: IMarkedForDeletionListStates) {
        const sql = [];
        const file = [];
        const argos = [];
        for (const markedForDeletion of states.markedForDeletions) {
            const tableInfo: any = this.objectMapping[markedForDeletion.objectType];
            sql.push(`DELETE FROM ${tableInfo.table} WHERE ${tableInfo.key} = '${markedForDeletion.objectName}';`);
            argos.push(`DELETE FROM ${tableInfo.argosTable} WHERE ${tableInfo.key} = '${markedForDeletion.objectName}';`);
            file.push(`rm ./data/${tableInfo.folder}/${markedForDeletion.objectName}.json`);
        }

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    fileDeletion: _.join(file, '\n'),
                    sqlDeletion: _.join(sql, '\n'),
                    argosDeletion: _.join(argos, '\n')
                }
            }
        };
        this.matDialog.open(DeleteCommandsModalComponent, dialogConfig);

    }

    async linkToObject(row: any) {
        let url;
        if (row.objectType === 'otherMetric') {
            const metrics = await this.dataAccess.genericFind({
                model: 'OtherMetric',
                filter: {
                    where: {
                        name: row.objectName
                    }
                }
            });
            if (_.size(metrics) === 1) {
                url = this.state.href('argos.ccgDesigner.prism1Metrics.edit', { id: metrics[0].id });
            }
        }
        if (row.objectType === 'episodeGrouping') {
            url = this.state.href('argos.ccgDesigner.episodeGroupings.edit', { id: row.objectName });
        }
        if (row.objectType === 'episodeGroupingEntity') {

            const modalInstance: MatDialogRef<any> = this.matDialog.open(
                EditJoinEntityModalComponent,
                {
                    panelClass: 'argos-modal-panel',
                    autoFocus: false,
                    hasBackdrop: false,
                    data: {
                        props: {
                            episodeGroupingEntityId: row?.objectName,
                            editMode: true
                        }
                    }
                });
            return;

        }
        if (url) {
            window.open(url, '_blank');
        }
    }

    async undelete(row: any, states: IMarkedForDeletionListStates) {
        if (row && row.id) {
            swal({
                title: 'Are you sure?',
                text: `Do you want to undelete ${row.objectName}?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    await this.dataAccess.genericMethod({
                        model: 'MarkForDeletion',
                        method: 'destroyById',
                        parameters: {
                            id: row.id
                        }
                    });
                    this.activate(states);
                }
            });
        }
    }
}
