import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { IClarifyInsightsDataEvaluationUserDisplay, IClarifyInsightsDataEvaluationUser } from './clarifyInsightsDataEvaluationUser.component.d';
import * as _ from 'lodash';

@Injectable()
export class ClarifyInsightsDataEvaluationUserService {

    DEFAULT_ROLES = ['Admin', 'Public', 'Alpha', 'Gamma', 'sql_lab'];
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService) { 

    }

    async initDelegate(states: IClarifyInsightsDataEvaluationUserDisplay) {
        await this.init(states);
    }

    async init(states: IClarifyInsightsDataEvaluationUserDisplay) {

        let usersWithExpirationLookup: any = {};
        const usersWithExpiration = await this.dataAccess.genericFind({
            model: "ClarifyInsightsDataEvaluationUser"
        });

        _.forEach(usersWithExpiration, (user: IClarifyInsightsDataEvaluationUser) => {
            usersWithExpirationLookup[user.email] = user.expirationDate;
        })
        
        const usersAndRoles = await this.dataAccess.genericMethod({
            model: "ClarifyInsightsDataEvaluationUser",
            method: "getUsersAndRoles"
        });

        states.users = usersAndRoles.users;
        const roles = [...usersAndRoles.roles];
        // remove default roles
        const filteredRoles = _.filter(roles, (role) => {
            return !(this.DEFAULT_ROLES.includes(role.name));
        })
        states.roles = filteredRoles;
        _.forEach(states.users, (user) => {
            if (usersWithExpirationLookup[user.email]) {
                user.expirationDate = new Date(usersWithExpirationLookup[user.email]).toLocaleDateString();   
            }
            else {
                user.expirationDate = null;
            }        
        })
    }

    async disableUser(row: any){
        const disableUserResponse = await this.dataAccess.genericMethod({
            model: "ClarifyInsightsDataEvaluationUser",
            method: "editUser",
            parameters: {
                userId: row.id,
                userInfo: {
                    active: false
                }
            }
        });
        if (disableUserResponse.success) {
            await this.dataAccess.genericUpsert({
                model: "ClarifyInsightsDataEvaluationUser", 
                data: {
                    id: row.id,
                    email: row.email,
                    username: row.email,
                    firstName: row.first_name,
                    lastName: row.last_name,
                    roles: _.map(row.roles, (role: any) => role.id),
                    active: false,
                    expirationDate: row.expirationDate
                }
            })
        }
    }

    async enableUser(row: any, password: string) {
        const enableUserResponse = await this.dataAccess.genericMethod({
            model: "ClarifyInsightsDataEvaluationUser",
            method: "editUser",
            parameters: {
                userId: row.id,
                userInfo: {
                    active: true,
                    password: password
                }
            }
        });
        if (enableUserResponse.success) {
            await this.dataAccess.genericUpsert({
                model: "ClarifyInsightsDataEvaluationUser", 
                data: {
                    id: row.id,
                    email: row.email,
                    username: row.email,
                    firstName: row.first_name,
                    lastName: row.last_name,
                    roles: _.map(row.roles, (role: any) => role.id),
                    active: true,
                    expirationDate: row.expirationDate
                }
            })
        }
    }

    async editExpiration(row: any, newExpirationDate: any) {
        await this.dataAccess.genericUpsert({
            model: "ClarifyInsightsDataEvaluationUser", 
            data: {
                id: row.id,
                email: row.email,
                username: row.email,
                firstName: row.first_name,
                lastName: row.last_name,
                roles: _.map(row.roles, (role: any) => role.id),
                active: row.active,
                expirationDate: newExpirationDate
            }
        })
    }
}
