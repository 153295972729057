import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewPatientJourneyModalProps, IViewPatientJourneyModalStates } from './viewPatientJourneyModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewPatientJourneyModalService } from './viewPatientJourneyModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationService } from '../../../services/validationService.service';
import swal from 'sweetalert2';
import * as _ from 'lodash';


@Component({
    selector: 'argos-viewPatientJourneyModal',
    templateUrl: './viewPatientJourneyModal.component.html',
    providers: [ViewPatientJourneyModalService]
})

export class ViewPatientJourneyModalComponent
    extends ReactComponentBase<IViewPatientJourneyModalProps, IViewPatientJourneyModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewPatientJourneyModalService, private validationSrv: ValidationService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewPatientJourneyModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close({});
    }

    removeColumn(columnName: string) {
        this.states.patientJourneyColumns = this.states.patientJourneyColumns.filter(col => col.columnName !== columnName);
    }
    
    addColumn(columnName: any) {
        if (columnName) {
            const column = _.find(this.states.allMarketShareColumns, { columnName });
            this.states.patientJourneyColumns.push(column);
            this.states.selectedMarketShareColumn = null;
        }
        this.svc.setMarketShareColumn(this.states);
    }

    save() {
        this.svc.save(this.states).then(() => {
            this.dialogRef.close({});
        }).catch((err: any) => {
            swal('Error Saving: ', err.message);
        });
    }

}
