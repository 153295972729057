import { Injectable } from '@angular/core';
import { IPipelineDataSourceEditStates, IPipelineDataSourceEditService } from './pipelineDataSourceEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';

@Injectable()
export class PipelineDataSourceEditService implements IPipelineDataSourceEditService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private state: StateService) {
        //
    }
    async initDelegate(states: IPipelineDataSourceEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IPipelineDataSourceEditStates) {
        if (this.uiRouter.globals.params.id) {
            states.datasource = await this.dataAccess.genericMethod({
                model: 'DataSource', method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.id
                }
            });
            if (states.datasource.firstClaimDate) {
                states.datasource.firstClaimDate = new Date(states.datasource.firstClaimDate.replace(/-/g, '\/').replace(/T.+/, ''));
            }

            if (states.datasource.lastClaimDate) {
                states.datasource.lastClaimDate = new Date(states.datasource.lastClaimDate.replace(/-/g, '\/').replace(/T.+/, ''));
            }
        } else {
            states.datasource = {
                dataType: 'claim'
            };

            states.existingDataSources = await this.dataAccess.genericFind({
                model: 'DataSource',
                filter: {
                    order: 'name'
                }
            });
        }
    }

    async saveHandler(states: IPipelineDataSourceEditStates) {
        await this.dataAccess.genericUpsert({
            model: 'DataSource',
            data: states.datasource
        });
        this.state.go('argos.data.datasource.list');
    }
}
