import { Injectable } from '@angular/core';
import { IReleaseAddDagModalService, IReleaseAddDagModalProps, IReleaseAddDagModalStates } from './releaseAddDagModal.component.d';


@Injectable()
export class ReleaseAddDagModalService implements IReleaseAddDagModalService {
    constructor() {}

    async initDelegate(props: IReleaseAddDagModalProps, states: IReleaseAddDagModalStates): Promise<object> {
        await this.init(props, states);
        return states;
    };

    async init(props: IReleaseAddDagModalProps, states: IReleaseAddDagModalStates) {
        if (props.dagConfig) {
            states.formattedDagConfig = JSON.stringify(JSON.parse(props.dagConfig), null, 4);
        }
        return states;
    };

    addDagToRelease(props: IReleaseAddDagModalProps, dagConfig: string) {
        try {
            JSON.stringify(JSON.parse(dagConfig));
        } catch (error) {
            return 'error'
        }
        const validationResult = this.dagConfigValidator(dagConfig, props.dagId);
        if (!validationResult) {
            return 'error';
        }
        return JSON.stringify(JSON.parse(dagConfig.trim()));
    };

    dagConfigValidator(dagConfig: string, dagId: string) : boolean {
        if (!dagConfig.trim().startsWith('{') || !dagConfig.trim().endsWith('}')) {
            return false;
        }
        if (JSON.parse(dagConfig.trim()).hasOwnProperty('tables_to_promote') === false) {
            return false;
        }
        const tablesType = typeof JSON.parse(dagConfig)['tables_to_promote']
        if (dagId.includes('deployment-final') && tablesType !== 'object') {
            return false;
        }
        else if (dagId.includes('deployment-reference') && tablesType !== 'object') {
            return false;
        }
        return true;
    }
} 