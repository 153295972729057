import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup',
  templateUrl: './additionalInfoModal.component.html',
  styleUrls: ['./additionalInfoModal.component.less']
})
export class AdditionalInfoModalComponent {
  data: any;  
  stringData: string;
  displayedColumns: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public additionalInfo: any, private dialogRef: MatDialogRef<AdditionalInfoModalComponent>) {
    this.stringData = JSON.stringify(additionalInfo, null, 2);
    this.data = additionalInfo;
    this.displayedColumns = [...this.getKeys(this.data)];
  }
  
  getKeys(data: any[]): string[] {
    let keys: string[] = [];
    if (data && data.length > 0) {
        data.forEach(item => {
            const additionalInfoKeys = Object.keys(item.additionalInfo);
            additionalInfoKeys.forEach(key => {
                if (!keys.includes(key)) {
                    keys.push(key);
                }
            });
        });
    }
    return keys;
  }

  close() {
    this.dialogRef.close({ eventName: 'cancel' });
  }

  isPlainObject(obj: any): boolean {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}
}
