import { Component, OnInit, Inject } from '@angular/core';
import { ReactComponentBase } from '../../../reactComponentBase/reactComponentBase.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IApiPerformanceTestResultsDashboardProductsDetailModalProps, IApiPerformanceTestResultsDashboardProductsDetailModalStates } from './apiPerformanceTestResultsProductDetailModal.component.d';
import { ApiPerformanceTestResultsDashboardProductsDetailModalService } from './apiPerformanceTestResultsProductDetailModal.component.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'argos-apiPerformanceTestResultsProductDetailModal',
    templateUrl: './apiPerformanceTestResultsProductDetailModal.component.html',
    providers: [ApiPerformanceTestResultsDashboardProductsDetailModalService],
    styleUrls: ['../../testResultsDashboard/qaTestResultDashboard.component.less']
})

export class ApiPerformanceTestResultsDashboardProductsDetailModalComponent
    extends ReactComponentBase<IApiPerformanceTestResultsDashboardProductsDetailModalProps, IApiPerformanceTestResultsDashboardProductsDetailModalStates, {}> implements OnInit {
    constructor(private svc: ApiPerformanceTestResultsDashboardProductsDetailModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ApiPerformanceTestResultsDashboardProductsDetailModalComponent>) {
        super({
            apiPerformanceTestResults: {},
            productResults: {},
            apiPerformanceTestModalDataTable: new MatTableDataSource([])
        } as IApiPerformanceTestResultsDashboardProductsDetailModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    closeModal() {
        this.dialogRef.close({});
    }

    isNumeric(value: any): boolean {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    hasIncreased(element: any, column: string): boolean {
        const columnIndex = this.states.apiDisplayedColumns.indexOf(column);
        if (columnIndex > 0) {
            const prevValue = parseFloat(element[this.states.apiDisplayedColumns[columnIndex - 1]]);
            const currentValue = parseFloat(element[column]);
            return (currentValue - prevValue) >= 5;
        }
        return false;
    }


    hasDecreased(element: any, column: string): boolean {
        const columnIndex = this.states.apiDisplayedColumns.indexOf(column);
        if (columnIndex > 0) {
            const prevValue = parseFloat(element[this.states.apiDisplayedColumns[columnIndex - 1]]);
            const currentValue = parseFloat(element[column]);
            return (prevValue - currentValue) >= 5;
        }
        return false;
    }

    hasNotChanged(element: any, column: string): boolean {
        const columnIndex = this.states.apiDisplayedColumns.indexOf(column);
        if (columnIndex > 0) {
            const prevValue = parseFloat(element[this.states.apiDisplayedColumns[columnIndex - 1]]);
            const currentValue = parseFloat(element[column]);
            return Math.abs(prevValue - currentValue) < 1; // Returns true if the difference is less than 5 seconds
        }
        return false;
    }


}
