import { Component, OnChanges, SimpleChanges, OnInit, Inject, ViewChild } from '@angular/core';
import { IPatchManagementPreviewModalStates } from './patchManagementPreviewModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatchManagementPreviewModalService } from './patchManagementPreviewModal.component.service';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';
import * as Diff2Html from 'diff2html';
import { createPatch } from 'diff';
@Component({
    selector: 'argos-patch-management-preview-modal',
    templateUrl: './patchManagementPreviewModal.component.html',
    providers: [PatchManagementPreviewModalService],
    styles: [`::ng-deep.mat-drawer-container.mat-sidenav-container {
        min-height: 700px;
        background: white;
    }
    ::ng-deep.mat-drawer-content.mat-sidenav-content {
        background: white;
        min-height: 700px;
        border-top: solid 1px rgba(0, 0, 0, 0.12);
        border-right: solid 1px rgba(0, 0, 0, 0.12);
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    }
    ::ng-deep.d2h-code-linenumber, .d2h-code-side-linenumber {
        position: relative;
    }
    ::ng-deep.mat-tab-group {
        height: 700px;
    }`]
})

export class PatchManagementPreviewModalComponent extends ReactComponentBase<{}, IPatchManagementPreviewModalStates, {}> implements OnInit, OnChanges {
    @ViewChild('tbMatSort') tbMatSort = new MatSort();
    constructor(@Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<PatchManagementPreviewModalComponent>, private svc: PatchManagementPreviewModalService) {
        super({
            filesPreviewModalTable: [],
            content: {}
        } as IPatchManagementPreviewModalStates);
        this.props = dialogData.props;
    }
    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close('cancel');
    }

    openFileContent(files: any, metaDataType: string) {
        const obj = { fileName: files.fileName, type: _.includes(metaDataType, 'dashboardModules') ? metaDataType : _.camelCase(metaDataType) };

        this.states.selectedFileName = files.fileName;
        const file = JSON.stringify(JSON.parse(_.get(_.find(_.get(this.states.content.availableFiles, obj.type), ['fileName', this.states.selectedFileName]), 'fileValue')), undefined, 2);
        const releaseFileCheck = _.get(_.find(_.get(_.get(this.states.content, 'releaseFiles'), obj.type), ['fileName', `${this.states.selectedFileName}.json`]), 'fileValue');
        let releaseFile = '';
        if (!_.isEmpty(releaseFileCheck)) {
            releaseFile = JSON.stringify(JSON.parse(releaseFileCheck), undefined, 2);
        }
        const patch = createPatch(files.fileName, releaseFile, file);
        const fileVal = Diff2Html.html(patch, { drawFileList: true, matching: 'words', diffMaxLineLength: 10000, diffMaxChanges: 10000 });
        this.states.diffValue = fileVal;
        this.states.releaseFileValue = releaseFile ? JSON.parse(releaseFile) : {};
        this.states.patchFileValue = JSON.parse(file);
    }
}
