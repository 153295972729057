import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IViewClientUsageModalProps, IViewClientUsageModalStates, IViewClientUsageModalService } from './viewClientUsageModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { StateService, UIRouter } from '@uirouter/core';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable()
export class ViewClientUsageModalService implements IViewClientUsageModalService {
    constructor(private dataAccess: NgDataAccess, private state: StateService, private cdr: ChangeDetectorRef, private matDialog: MatDialog, private exportToExcelSvc: ExportToExcelService) {
        //
    }

    async initDelegate(props: IViewClientUsageModalProps, states: IViewClientUsageModalStates) {
        states.productName = props.productName;
        states.clientInfo = props.clientInfo;

        states.environmentListTable.data = states.clientInfo;

        return {};
    }

    async changeDelegate(oldProps: IViewClientUsageModalProps, newProps: IViewClientUsageModalProps, states: IViewClientUsageModalStates) {
        this.initDelegate(newProps, states);
        await this.fetchUsages(states);
        return {};
    }

    openClient(clientId: string) {
        let url = this.state.href('argos.client.edit.detail', { id: clientId });
        window.open(url, '_blank');
    }

    async fetchUsages(states: IViewClientUsageModalStates): Promise<object[]> {

        return [];
        /*
        states.ClientUsages = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage',
            method: 'getClientUsageResults',
            parameters: {
                metaDataTypes: states.metaDataTypes,
                metaDataNames: states.metaDataNames
            }
        });
        */
    }

    exportToExcel(states: IViewClientUsageModalStates) {

        const data = states.environmentListTable.data;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IViewClientUsageModalStates) {
        let data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe((result: any) => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

}
