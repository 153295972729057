import { Component } from '@angular/core';

@Component({
    selector: 'argos-scratch-database-cleanup',
    template: `<ui-view></ui-view>`
})

export class ScratchDatabaseCleanupRootComponent {

}
