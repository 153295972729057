import { Injectable } from '@angular/core';
import { Broadcaster } from './broadCaster.service';

@Injectable()
export class AlertService {
    constructor(private broadCaster: Broadcaster) {

    }

    public handleError(err: any) {
        console.log('err', err);
        this.broadCaster.broadcast('alertError', {
            title: 'Error!',
            msg: err?.body?.error?.message
        });
    }

    public handleSuccess(message: any) {
        console.log('message', message);
        this.broadCaster.broadcast('alertSuccess', {
            title: 'Success!',
            msg: message
        });
    }

    public clearErrors() {
        this.broadCaster.broadcast('clearErrors');
    }
}
