import { Injectable } from '@angular/core';
import { IErrorLogModalProps, IErrorLogModalStates, IErrorLogModalService } from './errorLogModal.component.d';

@Injectable()
export class ErrorLogModalService implements IErrorLogModalService {
    initDelegate(props: IErrorLogModalProps, states: IErrorLogModalStates): Object {
        states.error = props.error;
        return {};
    }

    changeDelegate(oldProps: IErrorLogModalProps, newProps: IErrorLogModalProps, states: IErrorLogModalStates): Object {
        states.error = newProps.error;
        return {};
    }

}
