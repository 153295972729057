import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IPatchManagementListProps, IPatchManagementListStates, IPatchManagementListService } from './patchManagementList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import * as moment from 'moment';
@Injectable()
export class PatchManagementListService implements IPatchManagementListService {
    constructor(private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog) {
        //
    }

    async initDelegate(states: IPatchManagementListStates): Promise<object> {
        await this.init(states);
        return {};
    }

    changeDelegate(states: IPatchManagementListStates): object {
        this.initDelegate(states);
        return states;
    }

    async init(states: IPatchManagementListStates) {
        states.loading = true;
        this.cdr.detectChanges();
        try {
            await this.getPatchTableList(states, this.cdr);
            states.loading = false;
            return states;
        } catch (error) {
            states.loading = false;
            states.hasError = true;
            return states;
        }
    }

    async getPatchTableList(states: IPatchManagementListStates, cdr: ChangeDetectorRef) {
        const results = _.map(await this.dataAccess.genericFind({
            model: 'Patch',
            filter: {
                order: 'createdDate desc',
                where: {
                    archived: false
                },
                include: 'environments'
            }
        }), (item: any) => {
            item.createdDate = moment(item.createdDate).fromNow();
            return item;
        });

        for (const index of results) {
            index.tagName = index.releaseInfo.tagName;
            index.deployedToEnvironmentCount = index.environments.length;
        }

        states.patchList = results;
        states.table.data = states.patchList;
    }

    deletePatch(patch: any, states: IPatchManagementListStates, cdr: ChangeDetectorRef) {
        if (!_.isEmpty(patch.environments)) {
            swal({
                title: 'Patch Delete Error',
                text: `You cannot delete Patch ${patch.name} as it is already deployed. It will be archived during the next release.`,
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            });
        } else {
            swal({
                title: 'Delete Patch',
                text: 'Are you sure you want to delete this patch?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
            }).then(async (isConfirm: any) => {
                if (!isConfirm.dismiss) {
                    await this.dataAccess.genericDelete({
                        model: 'Patch',
                        id: patch.id
                    });
                    states.loading = true;
                    await this.getPatchTableList(states, cdr);
                    states.loading = false;
                    cdr.detectChanges();
                }
            });
        }
    }

    setTablePageSize(states: IPatchManagementListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.patchList.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IPatchManagementListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;
        const filteredColumns = _.filter(columns, (x) => {  return x.toLowerCase().indexOf('button') === -1; });

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, filteredColumns, data);
    }

    openMatTableSettings(states: IPatchManagementListStates) {
        const data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}