import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewClientCareGroupingHistoryModalProps, IViewClientCareGroupingHistoryModalStates } from './viewClientCareGroupingHistoryModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewClientCareGroupingHistoryModalService } from './viewClientCareGroupingHistoryModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-viewClientCareGroupingHistoryModal',
    templateUrl: './viewClientCareGroupingHistoryModal.component.html',
    providers: [ViewClientCareGroupingHistoryModalService]
})

export class ViewClientCareGroupingHistoryModalComponent
    extends ReactComponentBase<IViewClientCareGroupingHistoryModalProps, IViewClientCareGroupingHistoryModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewClientCareGroupingHistoryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewClientCareGroupingHistoryModalComponent>) {
        super({
            showDiffs: false
        } as IViewClientCareGroupingHistoryModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }
    closeModal() {
        this.dialogRef.close({});
    }

    viewDiffs(version: any) {
        this.svc.viewDiffsHandler(version, this.states);
    }
}
