import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { INavigationStates, NavigationMenuModel } from './navigation.component.d';
import { UserRoleService } from '../../../services/userRole.service';
import { UIRouter } from '@uirouter/core';
import * as _ from 'lodash';

@Component({
    selector: 'argos-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.less']
})

export class NavigationComponent extends ReactComponentBase<{}, INavigationStates, {}> implements OnInit {
    menuItems: any = {};
    menus: NavigationMenuModel = {};
    constructor(private uiRouter: UIRouter, private userRoleSvc: UserRoleService,
        private cdr: ChangeDetectorRef) {
        super({} as INavigationStates);
    }

    async ngOnInit() {
        const currentState = this.uiRouter.globals?.current?.name;
        await this.getValidMenus();
        this.menuItems = {
            DataCollapse: _.startsWith(currentState, 'argos.data'),
            HospitalRatesCollapse: _.startsWith(currentState, 'argos.hospitalRates'),
            ClarifyInsightsCollapse: _.startsWith(currentState, 'argos.clarifyInsight'),
            EnvironmentsCollapse: _.startsWith(currentState, 'argos.environment'),
            AtlasCollapse: _.startsWith(currentState, 'argos.prism'),
            CCGDesignerCollapse: _.startsWith(currentState, 'argos.ccgDesigner'),
            ClarifyReportsCollapse: _.startsWith(currentState, 'argos.clarifyReports'),
            ModelMetaDataCollapse: _.startsWith(currentState, 'argos.modelMetaData'),
            ReferenceDataCollapse: _.startsWith(currentState, 'argos.referenceData'),
            NetworksCollapse: _.startsWith(currentState, 'argos.networkDesigner'),
            ECSCollapse: _.startsWith(currentState, 'argos.ecsAdmin'),
            AdminCollapse: _.startsWith(currentState, 'argos.admin'),
        };
        this.cdr.detectChanges();
    }

    openNav(menuId: string) {
        for (const prop in this.menuItems) {
            if (prop === menuId) {
                this.menuItems[prop] = true;
            } else {
                this.menuItems[prop] = false;
            }
        }
    }

    isActive(prefixes: any) {
        const currentState = this.uiRouter.globals?.current?.name;
        for (const prefix of prefixes) {
            if (_.startsWith(currentState, prefix)) {
                return { 'font-weight': '700' };
            }
        }
        return {};
    }

    async getValidMenus() {
        const defaultMenus = require('../../../constants/menus');
        const menus: NavigationMenuModel = _.cloneDeep(defaultMenus);
        const filteredMenu: NavigationMenuModel = _.cloneDeep(defaultMenus);
        const validMenuItems = await this.userRoleSvc.getValidMenuItems(defaultMenus) || {};

        if (!_.isEmpty(validMenuItems)) {
            //loop through system menus and remove any that are not valid
            _.forOwn(menus, (value, key) => {
                _.forEach(value, (val, k) => {
                    if (val && !_.includes(validMenuItems, val.sref)) {
                        _.remove(filteredMenu[key], (item) => item.sref === val.sref);  //remove the menu item if its not listed in the user role menu list
                        //if the menu is empty, remove the menu
                        if (filteredMenu[key].length === 0) {
                            delete filteredMenu[key];
                        }
                    }
                });
            });
            this.menus = filteredMenu;  //set the filtered menu as the menu to display
        } else {
            this.menus = {};    //show no menus
        }
    }
}
