import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { 
    IFilterPerformanceStates, 
    IEpisodeGroupingPerformance, 
    IEpisodeGrouping,
    IMetaDataUsage 
} from './filterPerformance.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewFilterPerfEnvironmentModalComponent } from './viewFilterPerfEnvironmentModal.component';
import * as _ from 'lodash';

@Component({
    selector: 'argos-filterPerformance',
    templateUrl: './filterPerformance.component.html'
})

export class FilterPerformanceComponent
    extends ReactComponentBase<{}, IFilterPerformanceStates, {}>
    implements OnInit {

    constructor(private exportToExcelSvc: ExportToExcelService,
        private cdr: ChangeDetectorRef, 
        private dataAccess: NgDataAccess,
        private matDialog: MatDialog) {
        super({
            queryRunning: true,
            results: [],
            matTableColumns: ['groupByAttribute', 'groupByName', 'cacheStrategy', 'usageCount', 'averageQueryTime', 'viewEnvironments'],
            exportToExcelName: 'filter_performance'
        } as IFilterPerformanceStates);
    }

    async ngOnInit() {
        await this.initDelegate();
    }

    async initDelegate() {
        const episodeGroupings = await this.dataAccess.genericFind({
            model: 'EpisodeGrouping',
            filter: { order: 'groupByAttribute' }
        });

        const allEnvironments = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEnvironments', parameters: {}
        });

        // filter out non-prod environments
        const environments = _.filter(allEnvironments, (e: any) => {
            return !e.isDisabled && !e.isArchived && _.includes(['client_prod', 'demo'], e.environmentType);
        });

        const requests = _.map(environments, async (e: any) => {
            let results;
            try {
                results = await this.dataAccess.genericMethod({
                    model: 'Environment', method: `getFilterPerformance`, parameters: {
                        id: e.id
                    }
                });
            } catch (err) {
                //
            }
            return results;
        });

        const filterPerformanceResults: IEpisodeGroupingPerformance[] = await Promise.all(requests);

        const results = this.activate(filterPerformanceResults, episodeGroupings) || [];
        this.setStates({ results, queryRunning: false });
        this.sortData({ active: 'usageCount', direction: 'desc' });
        this.cdr.detectChanges();
    }

    activate(filterPerformanceResults: IEpisodeGroupingPerformance[], 
        episodeGroupings: IEpisodeGrouping[]
    ): IEpisodeGrouping[] {

        const allPerformanceResults = _.reduce(filterPerformanceResults, (result, filterPerformanceResult) => {
            return _.concat(result, filterPerformanceResult);
        }, []);

        return _.compact(_.map(episodeGroupings, (episodeGrouping: any) => {

            const performances = _.filter(allPerformanceResults, { groupByAttribute: episodeGrouping.groupByAttribute });

            // Set usages by environment to view in modal
            episodeGrouping.usages = _.orderBy(_.map(performances, (p: any) => {
                return {
                    environmentUrl: _.replace(p.environmentUrl, '/filter_performance', ''),
                    totalViews: p.totalViews,
                    averageQueryTime: p.averageQueryTime / 1000
                };
            }), 'totalViews', 'desc');

            // calculate a weighted average of the average query times, weighted by the total views (environments with more views weighting higher)
            episodeGrouping.averageQueryTime = (_.sumBy(performances, (p: any) => { return p.totalViews * p.averageQueryTime; }) || 0) / (1000 * _.sumBy(performances, 'totalViews'));
            episodeGrouping.usageCount = _.sumBy(performances, 'totalViews');

            // Add a columnn that describes the caching strategy for this grouping
            if (_.size(episodeGrouping.validValues)) {
                episodeGrouping.cacheStrategy = 'Valid Values';
            } else if (episodeGrouping.validValuesQueryName) {
                episodeGrouping.cacheStrategy = 'Cached Values';                
            } else {
                episodeGrouping.cacheStrategy = 'None';
            }

            // only show groupings with usage/queries
            if (episodeGrouping.usageCount > 0 || episodeGrouping.averageQueryTime > 0) {
                return episodeGrouping;
            }

        }));
    }

    sortData(event: any) {
        if (event?.active) {
            this.states.results = _.orderBy(this.states.results, event.active, event.direction);
        }
    }

    viewEnvironments(row: any) {

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                groupByAttribute: row.groupByAttribute,
                usages: row.usages            
            }
        };

        this.matDialog.open(ViewFilterPerfEnvironmentModalComponent, dialogConfig);
    }    

    exportToExcel() {
        const data = _.cloneDeep(this.states.results);
        this.exportToExcelSvc.exportToFile(this.states.exportToExcelName, this.states.matTableColumns, data);
    }
}
