import { Component, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClarifyInsightsTriggerTransferModalService } from './clarifyInsightsTriggerTransferModal.component.service';
import { IClarifyInsightsTriggerTransferModalInterface } from './clarifyInsightsTriggerTransferModal.component.d';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MD5Service } from '../../../services/md5.service';

@Component({
    selector: 'argos-clarifyInsightsTriggerTransferModal',
    templateUrl: './clarifyInsightsTriggerTransferModal.component.html',
    providers: [ClarifyInsightsTriggerTransferModalService, MD5Service],
    styleUrls: ['./clarifyInsightsTriggerTransferModal.component.css']
})

export class ClarifyInsightsTriggerTransferModalComponent extends ReactComponentBase<{}, IClarifyInsightsTriggerTransferModalInterface, {}> implements OnInit {
    constructor(private svc: ClarifyInsightsTriggerTransferModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
        public dialogRef: MatDialogRef<ClarifyInsightsTriggerTransferModalComponent>, private SnackBar: MatSnackBar) {
        super({
            activeExtractType: dialogData.props.extractType,
            activeCustomerCode: dialogData.props.customerCode,
            activeDataSource: dialogData.props.dataSource,
            activeFilterName: dialogData.props.filterName,
            activeExtractRun: dialogData.props.extractRun,
            destPath: '',
            srcPath: '',
            exclusions: [],
            athenaDatabaseName: '',
            s3DestinationBucket: '',
            dagConfig: null,
            validConfig: false,
            autoGenerateDestPath: true,
            autoGenerateSrcPath: true,
            autoGenerateAthenaDatabaseName: true,
            dataSourceExtractTypes : ['enriched_claims', 'market_share']
        } as IClarifyInsightsTriggerTransferModalInterface);
    }


    ngOnInit() {
        this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    async updateInputField(value: string, fieldName: string) {
        switch (fieldName) {
            case 'destPath':
                this.states.destPath = value;
                break;
            case 'srcPath':
                this.states.srcPath = value;
                break;
            case 'athenaDatabaseName':
                this.states.athenaDatabaseName = value;
                break;
            case 'autoGenerateDestPath':
                this.states.autoGenerateDestPath = !this.states.autoGenerateDestPath;
                if (!this.states.autoGenerateDestPath) {
                    this.states.destPath = '';
                }
                else {
                    await this.svc.generateDestPath(this.states);
                }
                break;
            case 'autoGenerateSrcPath':
                this.states.autoGenerateSrcPath = !this.states.autoGenerateSrcPath;
                if (!this.states.autoGenerateSrcPath) {
                    this.states.srcPath = '';
                }
                else {
                    this.svc.generateSrcPath(this.states);
                }
                break;
            case 'autoGenerateAthenaDatabaseName':
                this.states.autoGenerateAthenaDatabaseName = !this.states.autoGenerateAthenaDatabaseName;
                if (!this.states.autoGenerateAthenaDatabaseName) {
                    this.states.athenaDatabaseName = '';
                }
                else {
                    this.svc.generateAthenaDatabaseName(this.states);
                }
                break;
            case 'exclusions':
                this.states.exlucsions = value;
                break;
        }
        await this.svc.updateConfig(this.states);
    }

    async triggerTransfer(states: IClarifyInsightsTriggerTransferModalInterface) {
        const triggerResults = await this.svc.triggerTransfer(states);
        if (triggerResults.isSuccessful) {
            const dagRunLink = `${states.dagsUrl}${triggerResults.dagRunId}`;
            this.SnackBar.open(`Transfer has been successfully triggered. View At: ${dagRunLink}`, 'Close', {
                duration: 5000
            });
            this.dialogRef.close(triggerResults.dagRunId);
        } else {
            this.SnackBar.open(`Transfer failed with error: ${triggerResults.error}`, 'Close', {
                duration: 5000
            });
        }
        
    }

    close() {
        this.dialogRef.close();
    }

}
