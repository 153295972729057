import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { IClarifyInsightsFilterHistory } from './clarifyInsightsFilterHistory.component.d';
import { UIRouter } from '@uirouter/core';


@Injectable()
export class ClarifyInsightsFilterHistoryService {


    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter) { }

    async initDelegate(states: IClarifyInsightsFilterHistory): Promise<object> {
        await this.init(states);
        return states;
    }

    async init(states: IClarifyInsightsFilterHistory) {
        states.customerId = this.uiRouter.globals.params.id;
        states.dataSource = this.uiRouter.globals.params.dataSource ? this.uiRouter.globals.params.dataSource: null;
        states.filterName = this.uiRouter.globals.params.filterName;
        const extractType = this.uiRouter.globals.params.extractType;
        states.extractType = (extractType === 'Enriched Claims') ? 'claims' : (extractType === 'Hospital Rates') ? 'hospital_rates' : (extractType === 'Market Share Encounters') ? 'market_share' : (extractType === 'Provider Directory') ? 'provider_directory' : 'provider_performance';
        let filters;
        if (['hospital_rates', 'provider_performance', 'provider_directory'].includes(states.extractType)) {
            // these don't have a dataSource, use filterName to handle multiple filters per client/extract
            filters = await this.dataAccess.genericFind({
                model: 'ClarifyInsightsFilter',
                filter: {
                    where: {
                        customerId: states.customerId,
                        filterName: states.filterName,
                        extractType: states.extractType
                    },
                    order: 'filterVersion DESC, id ASC'
                }
            });
        } 
        else {
            filters = await this.dataAccess.genericFind({
                model: 'ClarifyInsightsFilter',
                filter: {
                    where: {
                        customerId: states.customerId,
                        dataSource: states.dataSource,
                        filterName: states.filterName,
                        extractType: states.extractType
                    },
                    order: 'filterVersion DESC, id ASC'
                }
            });
        };
        states.filters = filters;
    }
      
    viewDiffs(version: any, states: IClarifyInsightsFilterHistory) {
        states.showDiffs = true;
        states.compareHistory = [];
        states.currentVersion = '(v' + version + ')';
        states.previousVersion = '';
        
        const currentRow = states.filters.find(history => history.filterVersion === version);
        if (currentRow && version > 1) {
            const previousVersion = version - 1;
            states.previousVersion = '(v' + previousVersion + ')';
            const previousRow = states.filters.find(history => history.filterVersion === previousVersion);
        
            if (currentRow && previousRow) {
                const allKeys = new Set([...Object.keys(currentRow), ...Object.keys(previousRow)]);
            
                allKeys.forEach(key => {
                    const previousValue = previousRow[key];
                    const currentValue = currentRow[key];

                    if (Array.isArray(previousValue) && Array.isArray(currentValue)) {
                        // Handle array comparison
                        states.compareHistory.push({
                            key: _.startCase(key),
                            currentValue: JSON.stringify(currentValue),
                            previousValue: JSON.stringify(previousValue)
                        });
                    } else {
                        // Non-array comparison
                        states.compareHistory.push({
                            key: _.startCase(key),
                            currentValue: currentValue,
                            previousValue: previousValue
                        });
                    }
                });
            }
        }
    }
    
}
