import { Component, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';
import { IFooterStates } from './footer.component.d';

@Component({
    selector: 'argos-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent extends ReactComponentBase<{}, IFooterStates, {}> implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        this.states.copyrightYear = new Date().getFullYear();
    }
}
