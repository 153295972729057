import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'argos-athena-usage',
    templateUrl: './athenaUsage.component.html'
})

export class AthenaUsageComponent implements OnInit {

    careGroupingUsages: any = [];
    environmentUsages: any = [];
    careGroupingTableColumns: any = [];
    environmentTableColumns: any = [];
    months: any = [];
    queryRunning = false;

    constructor(private exportToExcelSvc: ExportToExcelService,
        private cdr: ChangeDetectorRef, 
        private dataAccess: NgDataAccess,
        private matDialog: MatDialog) {
    }

    async ngOnInit() {
        await this.activate();
    }

    async activate() {

        this.queryRunning = true;

        const allEnvironments = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEnvironments', parameters: {}
        });

        const requests = _.map(allEnvironments, async (e: any) => {
            let results;
            try {
                results = await this.dataAccess.genericMethod({
                    model: 'Environment', method: 'getAppVersion', parameters: {
                        id: e.id
                    }
                });
                results.environmentUrl = e.dnsCname;
            } catch (err) {
                //
            }
            return results;
        });

        const athenaUsageResults: any = _.compact(await Promise.all(requests));

        this.months = _.map(_.range(0, 6), (m: number) => {
            return moment().subtract(m, 'M').format('YYYY-MM');
        })

        this.careGroupingTableColumns = ['careGrouping', 'total'];
        this.environmentTableColumns = ['environmentUrl', 'total'];
        for (const month of this.months) {
            this.careGroupingTableColumns.push(month);
            this.environmentTableColumns.push(month);
        }

        this.careGroupingUsages = [];
        this.environmentUsages = [];

        for (const result of athenaUsageResults) {
            const queryCostSummaries = _.filter(result.queryCostSummary, (qsc: any) => {
                return _.includes(this.months, qsc.month);
            });
            for (const queryCostSummary of queryCostSummaries) {
                const careGroupingUsage: any = _.find(this.careGroupingUsages, { careGrouping: queryCostSummary.careGrouping });
                if (careGroupingUsage) {
                    careGroupingUsage[queryCostSummary.month] += queryCostSummary.totalCost;
                } else {
                    const newInstance: any = {
                        careGrouping: queryCostSummary.careGrouping,
                        total: 0
                    };
                    for (const month of this.months) {
                        newInstance[month] = 0;    
                    }
                    newInstance[queryCostSummary.month] = queryCostSummary.totalCost;
                    this.careGroupingUsages.push(newInstance);
                }
            }
            const environmentUsage: any = {
                environmentUrl: result.environmentUrl,
                total: 0
            };
            for (const month of this.months) {
                environmentUsage[month] = _.sumBy(_.filter(queryCostSummaries, { month }), 'totalCost');
            }
            this.environmentUsages.push(environmentUsage);
        }

        for (const careGroupingUsage of this.careGroupingUsages) {
            for (const month of this.months) {
                careGroupingUsage.total += careGroupingUsage[month];
            }
        }

        for (const environmentUsage of this.environmentUsages) {
            for (const month of this.months) {
                environmentUsage.total += environmentUsage[month];
            }
        }

        this.sortCareGroupingUsages({ active: 'total', direction: 'desc'});
        this.sortEnvironmentUsages({ active: 'total', direction: 'desc'});

        this.queryRunning = false;
        this.cdr.detectChanges();
    }

    sortCareGroupingUsages(event: any) {
        if (event?.active) {
            this.careGroupingUsages = _.orderBy(this.careGroupingUsages, event.active, event.direction);
        }
    }

    sortEnvironmentUsages(event: any) {
        if (event?.active) {
            this.environmentUsages = _.orderBy(this.environmentUsages, event.active, event.direction);
        }
    }

    exportToExcel() {
        this.exportToExcelSvc.exportToFile('care-groupings-athena-usage', this.careGroupingTableColumns, _.cloneDeep(this.careGroupingUsages));
        this.exportToExcelSvc.exportToFile('environments-athena-usage', this.environmentTableColumns, _.cloneDeep(this.environmentUsages));
    }
}
