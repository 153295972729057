import { Component } from '@angular/core';

@Component({
    selector: 'argos-data-release-manager',
    template: `<ui-view></ui-view>`
})

export class DataReleaseManagerRootComponent {

}
