import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IPayerRatesMetaListStates } from './payerRatesMetaList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PayerRatesMetaListService } from './payerRatesMetaList.component.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-payerRatesMetaList',
    templateUrl: './payerRatesMetaList.component.html',
    providers: [PayerRatesMetaListService]
})

export class PayerRatesMetaListComponent extends ReactComponentBase<{}, IPayerRatesMetaListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: PayerRatesMetaListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({ 
            showType: 'hideEndPoint',
            payerRateMeta: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['dataSource', 'payerId', 'webscrapingType', 'status', 'lastRunDate', 'url'],
            matTableColumns: ['dataSource', 'payerId', 'webscrapingType', 'status', 'lastRunDate', 'url'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'payerRatesMetaList'
        } as unknown as IPayerRatesMetaListStates);
    }

    ngAfterViewInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        return this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
    }

    getColumnFilterList(columnName: string, data: any[]) {
        return this.matSvc.getColumnFilterList(columnName, data);
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    launchAiAnalysis() {
        this.svc.launchAiAnalysis(this.states);
    }
}
