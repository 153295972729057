import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewSQLModalProps, IViewSQLModalStates } from './viewSQLModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewSQLModalService } from './viewSQLModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-viewSQLModal',
    templateUrl: './viewSQLModal.component.html',
    providers: [ViewSQLModalService]
})

export class ViewSQLModalComponent extends ReactComponentBase<IViewSQLModalProps, IViewSQLModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewSQLModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewSQLModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }
    closeModal() {
        this.dialogRef.close({});
    }
    copyToClipboard(command: any) {
        this.svc.copyToClipboardHandler(command, this.states);
    }
}
