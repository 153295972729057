import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IEcsAppListStates, IEcsAppListService } from './ecsAppList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import swal from 'sweetalert2';
import { EcsAppListShare } from './handler/ecsAppListShareHandler.service';
import { AlertService } from 'client/app/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { ScheduledScalingStateModelComponent } from '../scheduledScalingStateModel/scheduledScalingStateModel.component';
import { MatDialogConfig, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { UserRoleService } from 'client/app/services/userRole.service';
import { UtilsService } from '../../../services/utils.service';
import { P } from '@angular/cdk/keycodes';
declare const saveAs: any;
@Injectable()
export class EcsAppListService implements IEcsAppListService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter, private state: StateService,
        private ecsAppListShareHandler: EcsAppListShare, private alertSvc: AlertService,
        private argosStore: ArgosStoreService, private matDialog: MatDialog, private cdr: ChangeDetectorRef, 
        private exportToExcelSvc: ExportToExcelService, private utils: UtilsService, private userRoleSvc: UserRoleService) {
    }

    async initDelegate(states: IEcsAppListStates): Promise<object> {
        states.lodash = _;
        await this.initEcsClusterList(states);
        return {};
    }

    // load all environment and related db data
    async loadEnvironmentInfo(states: IEcsAppListStates) {
        let promises: any[] = [];

        promises.push(this.dataAccess.genericFind({
            model: 'Environment',
                filter: {
                    where: { and: [{ hostingAppName: { neq: null } }, { isArchived: false }] },
                    include: 'client'
                }
        }));
        
        promises.push(this.dataAccess.genericFind({
            model: 'SalesforceAccount',
            filter: {
                fields: ['salesforceId', 'name'],
                where: {
                    type: 'Customer'
                },
                order: 'name asc'
            }
        }));

        promises.push(this.dataAccess.genericFind({
            model: 'ClientCareGrouping'
        }));

        promises.push(this.dataAccess.genericMethod({
            model: 'EnvironmentManagement', method: 'findOne', parameters: {}
            })
        );

        const [envObjs, salesforceAccounts, ccgs, envManagement] = await Promise.all(promises);

        states.argosClientCareGroupings = ccgs;
        states.envManagement = envManagement;

        envObjs.forEach((env: any) => {
            const sfObj: any = _.find(salesforceAccounts, { salesforceId: env.client.salesforceAccountId });
            if (sfObj && sfObj.name) {
                env.salesforceAccountName = sfObj.name;
            }
        });

        states.environmentDetails = envObjs;
        //build cluster object
        await this.ecsAppListShareHandler.loadEnvClusterObjects(states);
    }

    async initEcsClusterList(states: IEcsAppListStates) {
        // if a valid account is given
        try {
            let promises = [];

            if (this.uiRouter.globals.params.accountname && states.awsAccounts.map(function(i) { return i.id; }).indexOf(this.uiRouter.globals.params.accountname) > -1) {
                states.selectedAwsAccount = this.uiRouter.globals.params.accountname;
            } else {
                states.selectedAwsAccount = states.awsAccounts[0].id;
            }
            
            await this.ecsAppListShareHandler.refreshSystemSettings(states);
            await this.loadEnvironmentInfo(states);   //load all environment related data and set the table
            
            let awsAccounts = this.ecsAppListShareHandler.getSelectedAwsAccountNames(states);
            for (let i = 0; i < awsAccounts.awsAccountName.length; i++) {
                let awsAccount = awsAccounts.awsAccountName[i];
                //load all related ecs data and attach cluster data to cluster object
                await this.loadEcsClusterData(states, awsAccount); 
            } 

            // wrap up any other loading status
            states.clusters.forEach((c: any) => {
                c.isArgosSyncing = false;
                if (c.accountName !== 'pm2' && (typeof c.argosSyncError === 'undefined' || c.argosSyncError === 'undefined' || c.argosSyncError === null)) {
                    c.argosSyncError = true;
                    c.showAppEditLink = 'envEdit';
                    c.argosSyncErrorMessage = `No matching ECS found for argos environment ${c.clusterName}`;
                    states.clusterEnvironmentIssues.push({
                        clusterName: c.clusterName,
                        type: 'No matching ECS',
                        description: c.argosSyncErrorMessage
                    });
                }
            });
 
            this.setTablePageSize(states);
            this.ecsAppListShareHandler.loadEcsStats(states);
            states.cdr.detectChanges();
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    async loadEcsClusterData(states: IEcsAppListStates, awsAccount: string) {

        const clusterRsp = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsClusters', parameters: {
                filterList: states.filterClusters,
                awsAccountName: awsAccount,
                maxResults: states.clusterLoadBlocks,
                pageNumber: 0
            }
        });

        // store our list of clusters remaining to get 
        states.totalClusters = clusterRsp.totalClusters;

        //load remaining ecs cluster data
        await this.loadRemainingClusterBlocks(states, clusterRsp.allClusterNames, clusterRsp.totalClusters, awsAccount);

        this.ecsAppListShareHandler.loadEcsClusterObjects(clusterRsp, states);
        this.cdr.detectChanges();
    }

    async updateScheduledScalingSuspendedState(states: IEcsAppListStates, disable: boolean) {

        const bulkUpdateList = this.getSelectedClustersToPromote(states);

        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    clusters: bulkUpdateList,
                    disable
                }
            }
        };
        this.matDialog.open(ScheduledScalingStateModelComponent, dialogConfig);
    }

    setTablePageSize(states: IEcsAppListStates) {
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        states.pageSize = 30;

        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
    }

    async loadRemainingClusterBlocks(states: IEcsAppListStates, clusterList: string[], totalClusters: string[], awsAccount: string) {
        const pageGroups = (clusterList.length / states.clusterLoadBlocks);

        for (let i = 1; i < pageGroups; i++) {
            const startDate = new Date();

            const page = (i * states.clusterLoadBlocks);

            const clusterRsp = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsClusters', parameters: {
                    filterList: clusterList,
                    awsAccountName: awsAccount || '',
                    maxResults: states.clusterLoadBlocks || 0,
                    pageNumber: page
                }
            });

            const clusterNames = this.ecsAppListShareHandler.loadEcsClusterObjects(clusterRsp, states);
            states.clusterTable.data = states.clusterTable.data;  // _.orderBy(states.clusters, ['friendlyName']);
            this.cdr.detectChanges();
        }
    }

    async initBuildTags(repoName: string, awsAccounts: string[], states: IEcsAppListStates) {

        let promises = [];

        //get build from all selected aws accounts
        for (let i = 0; i < awsAccounts.length; i++) {
            let accountName = awsAccounts[i];
    
            promises.push(this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsBuilds', parameters: {
                    repositoryName: repoName, awsAccountName: accountName
                }
            }));
        }

        states.buildTags = [];  //clear builds
        let buildTagResults = await Promise.all(promises);

        if (buildTagResults.length > 0) {
            states.buildTags = buildTagResults[0].response;

            //get intersection of all builds from all selected aws accounts
            for (let i = 1; i < buildTagResults.length; i++) {
                let tagsByAccount = buildTagResults[i].response;
                states.buildTags = _.intersection(states.buildTags, tagsByAccount);
            }
        }
        
        //remove any null values
        for (let i = states.buildTags.length - 1; i >= 0; i--) {
            if (!states.buildTags[i]) {
                states.buildTags.splice(i, 1);
            }
        }
    }



    cancelPromoteMode(states: IEcsAppListStates) {

        if (states.promotePollingTimer) {
            clearInterval(states.promotePollingTimer);
        }

        // $state.go('argos.ecsAccess.ecsAppList', { accountname: states.selectedAwsAccount });
        if (this.uiRouter.globals.params) {
            this.uiRouter.globals.params.accountname = states.selectedAwsAccount;
        }

        this.state.go(this.uiRouter.globals.current, this.uiRouter.globals.params, { reload: true, inherit: false });
    }

    // get everyone that is selected and has not been processed yet
    getSelectedClustersToPromote(states: IEcsAppListStates) {
        return (_.filter(states.clusters, function (c) {
            if (c.bulkSelected === true && !c.hasOwnProperty('receiveTaskDef')) {
                return c;
            }
        }));
    }

    async proceedSelectedRowsToPromoteMode(repoName: any, states: IEcsAppListStates) {
        states.screenMode = 'promoteMode';  // switch to promote mode
        //states.activeClusterRequest = 0;    // enable screen again
        const promises = [];
        const today = new Date();
        const fromDate = today.setDate(today.getDate() - 30);

        // gather the clusters to promote
        // only get the ones that have been selected and we got a valid taskdef for
        states.clusterToPromote = (_.filter(states.clusters, function (c) {
            if (c.bulkSelected === true && c.receiveTaskDef === true) {
                c.promoteStatus = 'queued';
                return c;
            }
        }));

        let awsAccounts = _.uniq(_.map(states.clusterToPromote, 'accountName'))
        const releaseInfo = await this.dataAccess.genericFind({
            model: 'EnvironmentActivityLog',
            filter: {
                where:
                {
                    and: [{ createdAt: { gt: fromDate } },
                        { clusterName: { inq: states.clusterToPromote.map(function (c) { return c.clusterName; }) } },
                        { environmentAction: 'code change upgrade run time' }]
                },
                order: 'id'
            }
        });

        
        await this.initBuildTags(repoName, awsAccounts, states);

        const releaseAvgs = _.map(_.groupBy(releaseInfo, 'clusterName'), function (o, idx) {
            return {
                id: idx,
                maxReleaseTimeEvent: _.maxBy(releaseInfo, function (e: any) { return (e.upgradeEvent ? e.upgradeEvent.eventDetail.runTimeSeconds : 0); }),
                averageUpgradeTimeInSeconds: _.meanBy(o, function (e) {
                    return e.eventDetail ? e.eventDetail.runTimeSeconds : 0;
                })
            };
        });

        releaseAvgs.forEach(function (ra) {
            const updateClusters = (_.filter(states.clusterToPromote, function (c) {
                if (c.clusterName === ra.id) {
                    c.averageUpgradeTimeInSeconds = ra.averageUpgradeTimeInSeconds;
                    c.averageUpgradeTimeInMins = Math.floor(c.averageUpgradeTimeInSeconds / 60);
                    return c;
                }
            }));
        }); // $q

        // sort the largest by aws account then seconds to the top of the queue. any null values will default to the last position
        states.clusterToPromote =_.orderBy(states.clusterToPromote, ['accountName', (c) => { return c.averageUpgradeTimeInSeconds || 0; }], ['asc', 'desc']);
        states.cdr.detectChanges();
    }

    async processSelectedRows(ecsTaskDefs: any, states: IEcsAppListStates) {

        ecsTaskDefs.forEach((ecsActiveTasks: any) => {
            // now that all of our getEcsActiveTasks responses have returned push those values into the
            // corresponding custers
            const matchedCluster = (_.find(states.clusters, (c) => {
                if (c.bulkSelected === true && c.clusterName === ecsActiveTasks.clusterName) {
                    return c;
                }
            }));

            // make sure we have a valid match
            if (matchedCluster) {

                matchedCluster.containerTaskDefinitions = ecsActiveTasks.activeTasks;
                matchedCluster.isWebNotRunning = this.getIsWebNotRunning(matchedCluster.clusterName, matchedCluster.selectedContainer, '-task:', states);
                matchedCluster.selectedTaskDefinition = this.getContainerTaskDefinition(matchedCluster.clusterName, matchedCluster.selectedContainer, '-task:', states);
                matchedCluster.selectedWorkerTaskDefinition = this.getContainerTaskDefinition(matchedCluster.clusterName, matchedCluster.selectedContainer, '-worker-task:', states);
                matchedCluster.selectedUpgradeTaskDefinition = this.getContainerTaskDefinition(matchedCluster.clusterName, matchedCluster.selectedContainer, '-upgrade-task:', states);
                matchedCluster.selectedNpoTaskDefinition = this.getContainerTaskDefinition(matchedCluster.clusterName, matchedCluster.selectedContainer, '-npo-upgrade-task:', states);

                // every object needs a repo image already set otherwise we cant continue\
                // if selectedTaskDefinition is null then the app is prob not running and not ready for deploy
                if (matchedCluster.containerTaskDefinitions.length > 0 && matchedCluster.selectedTaskDefinition) {

                    matchedCluster.selectedTaskDefTags = this.getEnvironmentTags(matchedCluster.clusterName, matchedCluster.selectedContainer, '-task', states);
                    matchedCluster.selectedWorkerTaskDefTags = this.getEnvironmentTags(matchedCluster.clusterName, matchedCluster.selectedContainer, '-worker-task', states);
                    matchedCluster.receiveTaskDef = true;

                    let repoObj = this.getRepoName(matchedCluster.clusterName, matchedCluster.containerTaskDefinitions);
                    if (!_.isEmpty(repoObj)) {
                        matchedCluster.ecrRepoName = repoObj.repositoryName;
                        matchedCluster.ecrBuildImage = repoObj.buildImage;
                    }

                    // search for env variable across task def and cache them for easy reporting later
                    states.cachedSearchData = states.cachedSearchData.concat(this.cacheEnvVariableForSearch(matchedCluster, matchedCluster.selectedTaskDefinition, states));
                    states.cachedSearchData = states.cachedSearchData.concat(this.cacheEnvVariableForSearch(matchedCluster, matchedCluster.selectedWorkerTaskDefinition, states));
                    states.cachedSearchData = states.cachedSearchData.concat(this.cacheEnvVariableForSearch(matchedCluster, matchedCluster.selectedUpgradeTaskDefinition, states));
                    states.cachedSearchData = states.cachedSearchData.concat(this.cacheEnvVariableForSearch(matchedCluster, matchedCluster.selectedNpoTaskDefinition, states));

                    // check aws permission type iamrole or aws key
                    matchedCluster.IamRoleEnabled = this.isIamRoleEnabled(matchedCluster.selectedTaskDefinition);
                } else {
                    console.log('unabled to load ' + matchedCluster.clusterName + ' retrying');
                    delete matchedCluster.receiveTaskDef;   // retry failed loading

                    if (!matchedCluster.hasOwnProperty('receiveTaskDefAttempts')) {
                        matchedCluster.receiveTaskDefAttempts = 1;
                    } else if (matchedCluster.receiveTaskDefAttempts > 2) {
                        // after 3 attempts just move on
                        console.log('unabled to load ' + matchedCluster.clusterName + ' after retrying ' + matchedCluster.receiveTaskDefAttempts);
                        matchedCluster.receiveTaskDef = false;
                    } else {
                        // retry getting task def data
                        matchedCluster.receiveTaskDefAttempts += 1;
                    }
                }
            } else {
                matchedCluster.receiveTaskDef = false;
                console.log('cant find matching cluster ' + ecsActiveTasks.clusterName);
            }
        }); // foreach

        // get selected clusters that have not been process again
        const selectedClusters = this.getSelectedClustersToPromote(states);
        const selectedRepos = (_.filter(states.clusters, function (c) {
            if (c.bulkSelected === true && c.ecrRepoName) {
                return c;
            }
        }));
        const uniqueRepos = _.uniq(_.map(selectedRepos, 'ecrRepoName'));
        const processedClusters = (_.filter(states.clusters, function (c) {
            if (c.bulkSelected === true && c.receiveTaskDef) {
                return c;
            }
        }));

        const failedToGetTaskDefClusters = (_.filter(states.clusters, function (c) {
            if (c.bulkSelected === true && c.receiveTaskDef === false) {
                return c;
            }
        }));

        // if any repo does not match or we find a mismatched repo stop
        if (uniqueRepos.length > 1) {
            console.log('miss matching repo list ' + JSON.stringify(selectedRepos));

            swal({
                title: 'Mismatching Repos',
                text: 'One or more environments have different image repos or failed to load their task definition: ' + uniqueRepos.join(', ') + '\nPlease only select environments using the same ECR Repo or try again',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then((isConfirm) => {
                this.cancelPromoteMode(states);
            });
        } else if (selectedClusters.length > 0) {
            await this.pushCodeToSelectedRows(states);   // start the processing again for remaining selected clusters
        } else {
            // user option to continue or stop
            if (failedToGetTaskDefClusters.length > 0) {
                console.log('unable to load repo list ' + _.uniq(_.map(failedToGetTaskDefClusters, 'clusterName')).join(', '));

                swal({
                    title: 'Some environments failed to load',
                    text: _.uniq(_.map(failedToGetTaskDefClusters, 'clusterName')).join(', ') + ' environment(s) failed to load their task definition. \nDo you want to proceed?',
                    type: 'warning',
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Proceed',
                    showCancelButton: true
                }).then(async (isConfirm) => {
                    if (isConfirm.value) {
                        await this.proceedRowsToPromote(uniqueRepos[0], states);
                    } else {
                        this.cancelPromoteMode(states);
                    }
                });
            } else {
                await this.proceedRowsToPromote(uniqueRepos[0], states);
            }
        }
    }

    async proceedRowsToPromote(repoName: any, states: IEcsAppListStates) {

        if (states.isSearch) {
            let exportSuccessful = this.exportReport('argos_env_search', states.cachedSearchData);
            let msg = 'No matches found';

            if (exportSuccessful) {
                msg = 'Matches found and report has been exported';
            }

            swal({
                title: 'Search Completed. ' + msg,
                text: 'Screen refreshing',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then((isConfirm) => {
                this.cancelPromoteMode(states);
            });
        } else {
            await this.proceedSelectedRowsToPromoteMode(repoName, states);   // everything processed and got a repo
        }

    }

    pushCodeToSelectedRows(states: IEcsAppListStates) {

        // init patches if all builds are the same
        this.ecsAppListShareHandler.initPatchTableList(states);

        // get selected clusters that have not been process
        const selectedClusters = this.getSelectedClustersToPromote(states);
        const chunk = states.envManagement.ecsLoadDataChunk;
        const promises = [];
        const clusterChunkReadyToPromote = selectedClusters.slice(0, chunk);

        for (let i = 0; clusterChunkReadyToPromote.length > i; i++) {
            const cluster = clusterChunkReadyToPromote[i];
            const result = this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsActiveTasks', parameters: {
                    clusterName: cluster.clusterName,
                    containers: cluster.isFargateEnabled ? [] : cluster.containers,
                    awsAccountName: cluster.accountName
                }
            });
            promises.push(result);
        }

        // for now we need to wait so that aws does not throttle us by locking request
        promises.push(new Promise((resolve) => {
            const minWaitSec = states.envManagement.ecsLoadDataChunkMinDelaySeconds * 1000;
            const maxWaitSec = minWaitSec + 5000;
            const waitSeconds = this.utils.getRandomInt(minWaitSec, maxWaitSec);  // jitter wait between minimum sec - (minimum sec + 5 seconds)
            setTimeout(resolve, waitSeconds);
        }));

        Promise.all(promises).then(async (results) => {
            results.pop();  // should remove the last known response because that was just a timeout
            await this.processSelectedRows(results, states);
        }); // $q
    }

    getRepoName(clusterName: any, containerTaskDefinitions: any) {
        let result: any = {};

        if (containerTaskDefinitions.length > 0) {
            const tasks = containerTaskDefinitions[0];
            let taskDefs: any = tasks.taskDefinitions;

            taskDefs = _.filter(taskDefs, function (td) {
                if (td.taskDefinition.taskDefinitionArn.indexOf(clusterName + '-task:') >= 0) {
                    return td;
                }
            });

            if (taskDefs.length > 0) {

                const mostRecent = {
                    index: 0,
                    revision: 0
                };

                for (let i = 0; i < taskDefs.length; i++) {
                    if (mostRecent.revision < taskDefs[i].taskDefinition.revision) {
                        mostRecent.index = i;
                        mostRecent.revision = taskDefs[i].taskDefinition.revision;
                    }
                }

                const definition = taskDefs[mostRecent.index];

                let filteredContainer = _.filter(definition.taskDefinition.containerDefinitions, function (ct) {
                    if (ct.name.indexOf('log_router') === -1) {
                        return ct;
                    }
                });

                if (filteredContainer.length > 0) {
                    let repoStr = filteredContainer[0].image;
                    let repoInfo = repoStr.split('/')[1].split(':');

                    result.repositoryName = repoInfo[0];
                    result.buildImage = repoInfo[1];
                }

            }
        }

        return result;
    }

    getEnvironmentTags(clusterName: any, container: any, taskFilter: any, states: IEcsAppListStates) {
        let result = states.envManagement.ecsResourceTags.map((t: any) => ({ ...t }));  // clone ecsResourceTags

        const clusterObj = (_.find(states.clusters, function (o) {
            if (o.clusterName === clusterName) {
                return o;
            }
        }));

        if (clusterObj && clusterObj.containerTaskDefinitions) {

            // if customer name exists add it to tags
            if (clusterObj.salesforceAccountName) {
                result.push({
                    key: 'customer',
                    value: clusterObj.salesforceAccountName
                });
            }

            const taskDefs = _.find(clusterObj.containerTaskDefinitions, function (ctd) { return ctd.containerId === container; });
            if (taskDefs) {
                const tagInfo = _.find(taskDefs.tagInfo, function (ti) { return ti.taskDefName === clusterObj.clusterName + taskFilter; });

                if (tagInfo && tagInfo.tags && tagInfo.tags.length > 0) {
                    result = _.unionBy(result, tagInfo.tags, 'key');
                }
            }
        }

        return result;
    }

    getIsWebNotRunning(clusterName: any, container: any, taskDefNameFilter: any, states: IEcsAppListStates) {
        let result = false;
        let webTask = this.getContainerTask(clusterName, container, taskDefNameFilter, states);

        if (webTask && webTask.isNotRunning) {
            result = true;
        }

        return result;
    }

    getContainerTaskDefinition(clusterName: any, container: any, taskDefNameFilter: any, states: IEcsAppListStates) {
        let result;
        let task = this.getContainerTask(clusterName, container, taskDefNameFilter, states);

        if (task) {
            result = task.taskDefinition;
        }
        return result;
    }

    getContainerTask(clusterName: any, container: any, taskDefNameFilter: any, states: IEcsAppListStates) {

        const clusterObj = (_.find(states.clusters, function (o) {
            if (o.clusterName === clusterName) {
                return o;
            }
        }));

        if (clusterObj) {

            for (let k = 0; k < clusterObj.containerTaskDefinitions.length; k++) {
                const tasks = clusterObj.containerTaskDefinitions[k];
                if (tasks && tasks.containerId === container) {
                    const taskDefs = (_.filter(tasks.taskDefinitions, function (td) {
                        // we want matching names of taskDefNameFilter i.e WEB INSTANCE or Worker Instance
                        if (td.taskDefinition.taskDefinitionArn.indexOf(clusterName + taskDefNameFilter) >= 0) {
                            return td;
                        }
                    }));

                    if (taskDefs.length > 0) {

                        const mostRecent = {
                            index: 0,
                            revision: 0
                        };

                        // multiple task can be running. Example when you deploy changes you will have 2 task running at the same time for a short period
                        // so we want to display the most recent one
                        for (let i = 0; i < taskDefs.length; i++) {
                            if (mostRecent.revision < taskDefs[i].taskDefinition.revision) {
                                mostRecent.index = i;
                                mostRecent.revision = taskDefs[i].taskDefinition.revision;
                            }
                        }

                        const definition = taskDefs[mostRecent.index];
                        // console.log('getContainerTaskDefinition definition.taskDefinition is ' + JSON.stringify(definition.taskDefinition));
                        return definition;
                    }
                }
            }
        }
    } 

    cacheEnvVariableForSearch(clusterObj: any, taskDef: any, states: IEcsAppListStates) {
        const result = [];

        if (taskDef && taskDef.containerDefinitions && states.ecsSearchObjList.length > 0) {
            // only do something if we have the web task def to scan
            for (let k = 0; k < taskDef.containerDefinitions.length; k++) {
                const tf = taskDef.containerDefinitions[k];
                for (let j = 0; j < tf.environment.length; j++) {
                    const envObj = tf.environment[j];

                    const cachedObj = _.find(states.ecsSearchObjList, function (searchObj) {
                        if (searchObj.key.toLowerCase() === envObj.name.toLowerCase() && (searchObj.value.length === 0 || envObj.value.toLowerCase() === searchObj.value.toLowerCase())) {
                            return searchObj;
                        }
                    });

                    if (cachedObj) {
                        result.push({
                            clusterName: clusterObj.clusterName,
                            url: clusterObj.url,
                            envName: cachedObj.key,
                            envValue: envObj.value,
                            searchValue: cachedObj.value,
                            taskDefName: taskDef.family,
                            taskDefRevision: taskDef.revision
                        });
                    }

                }
            }
        }
        return result;
    }

    isIamRoleEnabled(taskDef: any) {
        let result = true;
        const secretEnvName = 'ATHENA_SECRET';

        if (taskDef) {
            // only do something if we have the web task def to scan
            for (let k = 0; k < taskDef.containerDefinitions.length; k++) {
                const tf = taskDef.containerDefinitions[k];
                if (tf.secrets) {
                    for (let j = 0; j < tf.secrets.length; j++) {
                        const secretObj = tf.secrets[j];

                        if (secretObj.name.toUpperCase() === secretEnvName) {
                            result = false;
                        }
                    }
                }

            }
        }
        return result;
    }

    getLoadingEcsClusterCount(states: IEcsAppListStates) {
        return _.filter(states.clusters, { isArgosSyncing: true }).length;
    }

    async createDeployErrorsReportToJiraTicket(desc: string, states: IEcsAppListStates) {
        let result = false;
        let buildImage = states.selectedBuildTag;
        const selectedRows = _.filter(states.clusters, function (c) { return c.bulkSelected === true; });
        let selectedRepo = _.uniq(_.map(selectedRows, 'ecrRepoName'));

        const jiraResponse = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'createIssue',
            parameters: {
                project: 'DOPS',
                title: `Deploy errors from ${selectedRepo} repo and ${buildImage} image`,
                description: `Created By: ${this.userRoleSvc.getEmailAddress()}
                              Git Repo: ${selectedRepo}  
                              Build Image: ${buildImage}
                              The following errors occured during the image deploy process:
                              ${desc}`,
                assigneeId: '5cc24461a6e4950feacb4728'
            }
        });

        return jiraResponse;
    }

    exportReport(fileName: any, data: any) {
        let result = false;
        if (data && data.length > 0) {
            const blob = new Blob([JSON.stringify(data, undefined, 4)], { type: 'text/plain;charset=utf-8' });
            result = true;
            return saveAs(blob, fileName + '.json');
        }

        return result;
    }

    openMatTableSettings(states: IEcsAppListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            settingName: states.clusterMatTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe((result: any) => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    exportToExcel(states: IEcsAppListStates) {
        const fileName = states.clusterMatTableSettingName;
        const data = states.clusterTable.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(fileName, columns, data);
    }
}
