// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#environment-list th {
  white-space: nowrap;
}
#environment-list tags-input {
  margin-top: -6px !important;
}
#environment-list label.control-label {
  padding-top: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/environments/environmentsList/environmentsList.component.less"],"names":[],"mappings":"AAAA;EAEE,mBAAA;AAAF;AAFA;EAKE,2BAAA;AAAF;AALA;EAQE,2BAAA;AAAF","sourcesContent":["#environment-list {\n\tth {\n\t\twhite-space: nowrap;\n\t}\n\ttags-input {\n\t\tmargin-top: -6px !important;\n\t}\n\tlabel.control-label {\n\t\tpadding-top: 0px !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
