import { Component, OnChanges, SimpleChanges, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { IEcsAppEditStates } from './ecsAppEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EcsAppEditService } from './ecsAppEdit.component.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ChartService } from '../../../services/chartService.service';
import { EcsAppRow } from './handler/ecsAppRowHandler.service';
import { EcsAppValid } from './handler/ecsAppValidHandler.service';
import { EcsAppAlarms } from './handler/ecsAppAlarmsHandler.service';
import { EcsAppAWS } from './handler/ecsAppAWSHandler.service';
import { EcsAppService } from './handler/ecsAppServiceHandler.service';
import { EcsAppActivityLog } from './handler/ecsAppActivityLogHandler.service';
import { EcsAppEvent } from './handler/ecsAppEventHandler.service';
import { EcsAppEnvironment } from './handler/ecsAppEnvironmentHandler.service';
import { EcsAppShare } from './handler/ecsAppShareHandler.service';
import { EcsAppEcsService } from './handler/ecsAppEcsServiceHandler.service';
import { EcsAppTask } from './handler/ecsAppTaskHandler.service';
import { EcsAppRestart } from './handler/ecsAppRestartHandler.service';
import { EcsAppPatch } from './handler/ecsAppPatchHandler.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown3';

import * as moment from 'moment';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core';
import { CategoryScale, Chart, ChartData, ChartOptions, LinearScale, LineController, LineElement, Legend, Title, Tooltip, SubTitle, PointElement } from 'chart.js';

function registerChartComponents() {
    Chart.register(CategoryScale);
    Chart.register(LinearScale);
    Chart.register(LineController);
    Chart.register(LineElement);
    Chart.register(Legend);
    Chart.register(Title);
    Chart.register(Tooltip);
    Chart.register(SubTitle);
    Chart.register(PointElement);
}
registerChartComponents();

declare const $: any;
@Component({
    selector: 'argos-ecsAppEdit',
    templateUrl: './ecsAppEdit.component.html',
    providers: [EcsAppEditService,
        EcsAppAWS,
        EcsAppAlarms,
        EcsAppRow,
        EcsAppValid,
        EcsAppService,
        EcsAppActivityLog,
        EcsAppEvent,
        EcsAppEnvironment,
        EcsAppShare,
        EcsAppEcsService,
        EcsAppTask,
        EcsAppPatch,
        EcsAppRestart],
    styles: [`::ng-deep .multiselect-dropdown { background: white }`]
})

export class EcsAppEditComponent extends ReactComponentBase<{}, IEcsAppEditStates, {}> implements OnInit, AfterViewInit {
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    private paginator: MatPaginator;
    private activityLogMatTbSort: MatSort;
    _: any = _;

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }
    /*
    @ViewChild(MatSort) set matSort(ms: MatSort) {
      this.activityLogMatTbSort = ms;
      this.setDataSourceAttributes();
    }
    */
    @ViewChild('matTbSort') matTbSort = new MatSort();

    setDataSourceAttributes() {
        this.states.activityLogTable.paginator = this.paginator;
        this.states.activityLogTable.sort = this.activityLogMatTbSort;
    }

    // chart
    @ViewChild('doubleLineCanvas') doubleLineCanvas: ElementRef | undefined;
    doubleLineChart: any;

    constructor(private svc: EcsAppEditService, private dataAccess: NgDataAccess,
        private ecsAppEventHandler: EcsAppEvent, private ecsAppAWSHandler: EcsAppAWS,
        private ecsAppAlarmsHandler: EcsAppAlarms, private ecsAppRowHandler: EcsAppRow,
        private ecsAppValidHandler: EcsAppValid, private ecsAppServiceHandler: EcsAppService,
        private ecsAppEnvironmentHandler: EcsAppEnvironment, private ecsAppShareHandler: EcsAppShare,
        private ecsAppEcsServiceHandler: EcsAppEcsService, private ecsAppTaskHandler: EcsAppTask,
        private state: StateService, private ecsAppRestartHandler: EcsAppRestart, private argosStore: ArgosStoreService,
        private cdr: ChangeDetectorRef, private chartSvc: ChartService, private matSvc: MaterialUtil) {
        super({
            showType: 'hideEndPoint',
            taskCpuOptions: ['256', '512', '1024', '2048', '4096'],
            chartSettings: {
                colors: ['#45b7cd', '#00ADF9', '#FF5252', '#631717']
            },
            userAdminsDisplayedColumns: ['email', 'view', 'deploy', 'operator', 'manage'],
            cluster: {
                containers: [],
                taskGroupsInfo: [],
                taskGroups: [],
                fargateServices: [],
                ec2Info: [],
                services: [],
                appUrl: '',
                environment: {
                    ecsParameters: {}
                },
                targetInstancesHealth: [],
                errorMessage: [],
                appliedPatches: [],
                isExcludedFromReleaseCycle: false
            },
            dropdownPatchSettings: {
                singleSelection: false,
                idField: 'id',
                textField: 'name',
                selectAllText: 'Select All Patches',
                unSelectAllText: 'UnSelect All Patches',
                itemsShowLimit: 3,
                allowSearchFilter: false,
                noDataAvailablePlaceholderText: 'No Patches available for current Build'
            } as IDropdownSettings,
            selectedService: {
                hasEventBridgeSchedule: false
            },
            repositoryName: '',
            refreshInstanceHealthStatusDisabled: false,
            newUserPermission: { view: true, deploy: false, manage: false, operator: false },
            awsAccessPermission: ['IAM Role', 'AWS Access Key'],
            isAdmin: false,
            activeTab: 0,
            activeScalingMangementTab: 0,
            selectedMetricDates: {},
            pollingVars: {},
            newServiceScheduler: {
                scheduledActionName: '',
                scalableDimension: 'ecs:service:DesiredCount',
                hour: 23,
                minute: 0,
                minCapacity: 1,
                maxCapacity: 1,
                isEnabled: true
            },
            clusterActivity: [],
            pageSize: 20,
            filterObj: {},
            activityLogMatTableColumns: ['icon', 'actions', 'triggeredBy', 'message', 'dateFullName'],
            activityLogTable: new MatTableDataSource([]),
            hrServiceSchedulerOptions: [],
            currentUserEnvironmentPermissions: [],
            serviceEventTable: new MatTableDataSource([]),
            patchListTable: [],
            selectedPatches: [],
            selectedPatchNames: '',
            envMgmtSettings: {}
        } as unknown as IEcsAppEditStates);
    }

    async ngOnInit() {

        this.states.activityLogTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();

        for (let k = 0; k < 24; k++) {
            this.states.hrServiceSchedulerOptions.push(k);
        }

        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    async ngAfterViewInit() {

        this.states.serviceEventTable.sort = this.matTbSort;
        this.cdr.detectChanges();
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.activityLogTable.filter = JSON.stringify(this.states.filterObj);
    }

    applyFilter(event: Event) {
        //
    }

    async addUptimeAlarm(alarmType: string) {
        const papertailUrl = this.ecsAppAWSHandler.getPapertrailUrl(this.states, this.states.clusterName);

        if (_.filter(this.states.cluster.upTimeAlarms, function (o) { if (o.check_type.toLowerCase() === alarmType.toLowerCase()) return o; }).length > 0) {
            swal({
                title: 'Alarm already exists',
                text: alarmType + ' already exists',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //
            });
        } else {
            if (alarmType === 'transaction') {
                await this.ecsAppAlarmsHandler.addUptimeAlarmTransaction(papertailUrl, this.states);
            } else if (alarmType === 'http') {
                await this.ecsAppAlarmsHandler.addUptimeAlarmHttp(papertailUrl, this.states);
            }

            this.cdr.detectChanges();
        }

    }

    async pauseUptimeAlarm(alarm: any, disable: boolean) {
        await this.ecsAppAlarmsHandler.pauseUptimeAlarm(disable, alarm, this.states);
        this.cdr.detectChanges();
    }

    async deleteUptimeAlarm(alarm: any) {
        swal({
            title: 'Confirm alarm deletion',
            text: 'Delete ' + alarm.name + '? 12 months of history will be only saved in the activity log',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const deleteReq = await this.ecsAppAlarmsHandler.deleteUptimeAlarm(alarm, this.states);
                this.cdr.detectChanges();

                if (!deleteReq.isSuccessful) {
                    swal({
                        title: 'Failed to delete check',
                        text: deleteReq.errorMessage,
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                    }).then(async (isConfirm: any) => {
                        //
                    });
                }
            }
        });

    }

    searchContainerEvents(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.serviceEventTable.filter = filterValue.trim().toLowerCase();
    }

    minimumEcsCountChanged() {
        // TODO
    }

    hideEcsEnvironmentVariable(envName: string) {

        const index = this.states.cluster.environment.hiddenEcsVariables.indexOf(envName);
        if (index >= 0) {
            this.states.cluster.environment.hiddenEcsVariables.splice(index, 1);
        } else {
            this.states.cluster.environment.hiddenEcsVariables.push(envName);
        }
    }

    filterHiddenEcsEnvironmentVariable: Function = (env: any) => {
        // filter out hidden environment variables
        if (this.states.isAdmin) {
            return true;
        } else {
            return this.states.cluster.environment.hiddenEcsVariables.indexOf(env.name) === -1;
        }
    };

    isEnvironmentVariablesValidToAdd() {
        const regexp = /^[a-zA-Z0-9-_]+$/;

        if (this.states.newEnvName.search(regexp) === -1 || this.states.newEnvValue.trim().length === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    isHiddenEcsEnvironmentVariable(envName: string) {
        return (this.states.cluster.environment.hiddenEcsVariables.indexOf(envName) >= 0) ? 'hidden' : 'hide';
    }

    async testLoad() {
        await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getAwsVpcs', parameters: {
                vpcFilter: [], // [ '*' + 'millennium' + '*'],
                awsAccountName: this.states.cluster.accountName
            }
        });
    }

    async addNewUserPermission() {
        const states = this.states;
        this.states.existingUsers = _.filter(states.argosUsers, (u: any) => {
            return (u.email === states.newUserPermission.email);
        });

        const isAdmin = _.filter(this.states.userAdmins, function (admin) {
            return admin.email === states.newUserPermission.email;
        });

        const isEnvUserAccess = _.filter(this.states.cluster.environment.userPermissions, function (user) {
            return user.email === states.newUserPermission.email;
        });

        if (states.existingUsers.length === 0) {
            swal({
                title: 'User does not exists',
                text: 'You cannot add ' + states.newUserPermission.email + ' because they dont have a argos account',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //
            });
        } else if (isAdmin.length > 0) {
            swal({
                title: 'User is already an Admin',
                text: 'You cannot add ' + states.newUserPermission.email + ' because they are already an admin',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //
            });
        } else if (isEnvUserAccess.length > 0) {
            swal({
                title: 'User permission already exists',
                text: states.newUserPermission.email + ' already has access to this environment',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //
            });
        } else {
            // you can add them
            await this.saveRow(states.newUserPermission);
            this.cdr.detectChanges();
        }
    }

    async saveRow(row: any) {
        await this.ecsAppRowHandler.saveRow(row, this.states);
    }

    cancelEdit(row: any) {
        this.states.editingMode = false;
        row.editMode = false;
    }

    editRow(row: any) {
        this.states.editingMode = true;
        row.editMode = true;
    }

    async deleteRow(row: any) {
        await this.ecsAppRowHandler.deleteRow(row, this.states);
        this.cdr.detectChanges();
    }

    checkCloneDbRequest(selectedFromEnvironmentToCloneId: any) {
        this.svc.checkCloneDbRequest(selectedFromEnvironmentToCloneId, this.states, this.cdr);
    }

    checkCloneArgosRequestClone() {
        this.svc.checkCloneArgosRequestClone(this.states, this.cdr);
    }

    isArgosStaging(clusterName: string) {
        return this.svc.isArgosStaging(clusterName, this.states);
    }

    isValidSelectedMetricDates() {
        return this.ecsAppValidHandler.isValidSelectedMetricDates(this.states);
    }

    isActionAllowed(actionName: string) {
        return this.svc.isActionAllowed(actionName, this.states);
    }

    async refreshInstanceMetrics() {
        this.states.refreshInstanceMetricsDisabled = true;

        const fromDate = new Date(this.states.selectedMetricDates.fromDate);  // -1 because months are from 0 to 11
        const toDate = new Date(this.states.selectedMetricDates.toDate);

        const filterEvents = _.filter(this.states.serviceEvents, function (e) {
            const createdAtDate = new Date(e.createdAt);
            if (createdAtDate.getTime() > fromDate.getTime() && createdAtDate.getTime() < toDate.getTime()) {
                return e;
            }
        });

        this.ecsAppServiceHandler.initServiceEventsTable(filterEvents, this.states);

        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getCloudWatchMetrics', parameters: {
                clusterName: this.states.cluster.clusterName,
                serviceNames: this.states.cluster.services,
                fromDate: this.states.selectedMetricDates.fromDate,
                toDate: this.states.selectedMetricDates.toDate,
                awsAccountName: this.states.cluster.accountName
            }
        });

        this.createServiceMetricsChart(result.data, this.states.cluster.webServiceName, this.states);

        this.states.refreshInstanceMetricsDisabled = false;
        this.cdr.detectChanges();
    }

    async chartClick(points: any) {

        const selectedPoint = points[0];
        // only do something if its a real point in the graph
        if (selectedPoint) {
            const xAxesIndex = selectedPoint.index;
            const dataSetIndex = selectedPoint.datasetIndex;

            const utcTimeStamp = ((new Date(this.states.chartSettings.data.labels[xAxesIndex])).getTime() / 1000);
            const clickedValue = this.states.chartSettings.data.datasets[dataSetIndex].data[xAxesIndex];
            const clickedTaskName = this.states.chartSettings.data.datasets[dataSetIndex].label;

            const response = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsDebugInfo', parameters: {
                    clusterName: this.states.cluster.clusterName,
                    serviceName: this.states.chartSettings.data.datasets[dataSetIndex].serviceName,
                    utcValue: utcTimeStamp,
                    awsAccountName: this.states.cluster.accountName
                }
            });
            if (response.paperTrailUrl) {
                window.open(response.paperTrailUrl, '_blank');
            }
            // sort service events
            if (response.events && response.events.length > 0) {
                response.events.sort(function (a: any, b: any) {
                    const keyA = new Date(a.createdAt);
                    const keyB = new Date(b.createdAt);
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }
        }
    }

    isContainerValid(container: any) {
        this.ecsAppValidHandler.isContainerValid(container, this.states);
    }

    addAwsCanary(name: string) {
        this.ecsAppAlarmsHandler.addAwsCanary(name, this.states);
    }

    async refreshAlarms() {
        await this.ecsAppAlarmsHandler.getCloudWatchAlarms(this.states.cluster.environment, this.states);
        this.cdr.detectChanges();
    }

    async excludeFromRelease() {
        const exclude = !this.states.cluster.isExcludedFromReleaseCycle;
        await this.ecsAppAlarmsHandler.updateEcsParameterProperty('isExcludedFromReleaseCycle', exclude, this.states);
        this.cdr.detectChanges();
    }

    async disabledContainerChanges() {
        const disabled = !this.states.cluster.isEnvironmentChangesDisabled;
        await this.ecsAppAlarmsHandler.updateEcsParameterProperty('isEnvironmentChangesDisabled', disabled, this.states);
        this.cdr.detectChanges();
    }

    async maintenanceModeClicked() {
        swal({
            title: 'Maintenance Mode',
            text: this.states.cluster.isMaintenanceModeEnabled ? 'Disabling maintenance mode will make your app available for requests again. Are you sure?' : 'Enabling maintenance mode will make your app unavailable. Are you sure?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                await this.ecsAppAlarmsHandler.maintenanceModeClicked(this.states);
                this.cdr.detectChanges();
            }
        });
    }

    isUseClusterSetting(envName: string) {
        if (envName.toLowerCase() === 'use_clustering') {
            this.states.useClusterSetting = true;
        }
    }

    openAwsEcsSite() {
        window.open('https://us-west-2.console.aws.amazon.com/ecs/home?region=us-west-2#/clusters/' + this.states.clusterName + '/services', '_blank');
    }

    async refreshContrainerTaskDefs() {
        this.states.refreshInstanceHealthStatusDisabled = true;
        await this.svc.refreshContrainerTaskDefs(this.states);
        this.states.refreshInstanceHealthStatusDisabled = false;
        this.cdr.detectChanges();
    }

    openApp() {
        this.ecsAppAWSHandler.openApp(this.states);
    }

    exportEnvironmentVariables(singleTask: any) {
        this.ecsAppAWSHandler.exportEnvironmentVariables(singleTask, this.states);
    }

    openAWSEcsService(serviceName: any) {
        return this.ecsAppAWSHandler.openAWSEcsService(serviceName, this.states);
    }

    openAWSEcsTasks() {
        this.ecsAppAWSHandler.openAWSEcsTasks(this.states);
    }

    openAWSEcsTaskOverview(clusterName: string, taskId: string, region: string) {
        return this.ecsAppAWSHandler.openAWSEcsTaskOverview(clusterName, taskId, region);
    }

    openAWSEcsTaskRevision(taskDefName: string, revisionId: string, region: string) {
        return this.ecsAppAWSHandler.openAWSEcsTaskRevision(taskDefName, revisionId, region);
    }

    getAWSEcsTargetGroupUrl() {
        return this.ecsAppAWSHandler.getAWSEcsTargetGroupUrl(this.states);
    }

    getAWSEcsLoadBalancerUrl() {
        return this.ecsAppAWSHandler.getAWSEcsLoadBalancerUrl(this.states);
    }

    getAWSCanaryURL(canaryName: string) {
        return this.ecsAppAWSHandler.getAWSCanaryURL(canaryName);
    }

    getUptimeURL(alarmId: number) {
        return this.ecsAppAWSHandler.getUptimeURL(alarmId);
    }

    async updateAlarmStatus(canary: any) {
        await this.ecsAppAlarmsHandler.updateAlarmStatus(canary, this.states);
        this.cdr.detectChanges();
    }

    getAwsTasksDefsLink(taskDefinitionName: string, revision?: any) {
        return this.ecsAppAWSHandler.getAwsTasksDefsLink(taskDefinitionName, revision);
    }

    getEcsServiceList() {
        return _.difference(this.states.cluster.services, this.states.cluster.fargateServices);
    }

    getAWSEc2InstanceURL(instanceId: string) {
        return this.ecsAppAWSHandler.getAWSEc2InstanceURL(instanceId);
    }

    refreshBuildVersionInfo() {
        this.ecsAppTaskHandler.getEnvironmentBuildVersion(this.states);
    }

    openMetrics() {
        this.ecsAppAWSHandler.openMetrics(this.states);
    }

    openLog(filter: string) {
        this.ecsAppAWSHandler.openLog(this.states, filter);
    }

    awsRdsSecretEnabledChanged(e: any) {

        swal({
            title: 'Update AWS Rotate State?',
            text: this.states.cluster.isMaintenanceModeEnabled ? 'Are you sure you want to enable RDS Secret auto rotation' : 'Are you sure you want to disable RDS Secret auto rotation. This can impact safety and compliance?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                // save changes
                this.states.isEcsServiceChangeInProgress = true;
                await this.ecsAppServiceHandler.awsRdsSecretEnabledChanged(e.source.checked, this.states);
                await this.svc.refreshRdsSecretInfo(this.states);
                this.states.isEcsServiceChangeInProgress = false;
            } else {
                // undo
                e.source.checked = !e.source.checked;
                this.states.cluster.isMaintenanceModeEnabled = !this.states.cluster.isMaintenanceModeEnabled;
            }
        });
        this.cdr.detectChanges();
    }

    selectedServiceUpdate() {
        if (this.states.repositoryName === 'clarify-platform' && this.states.currentService === (this.states.cluster.clusterName + '-service') && 
            this.states.selectedService.desiredCount === 0 && this.states.cluster.isFargateEnabled) {
            swal({
                title: 'Maintenance Mode',
                text: 'Turning off a app will exclude it from nightly jobs like metric data sync that store usage information. Please consider adding a schedule to turn on the app between 1:45-3am PST if one does not already exists. Do you want to continue?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    this.ecsAppServiceHandler.selectedServiceUpdate(this.states);
                }
            });
        } else {
            this.ecsAppServiceHandler.selectedServiceUpdate(this.states);
        }

        
    }

    async eventBridgeSchedulesClicked(e: any) {
        let isSuccessful = await this.ecsAppServiceHandler.addRemoveServiceEventBridgeSchedule(this.states);

        if (!isSuccessful) { 
            await swal({
                title: 'Failed to update Scheduler or user cancelled the request',
                text: 'Please contact Devops if you need further assistance',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //undo change
                e.source.checked = !e.source.checked;
                //this.states.selectedService.hasEventBridgeSchedule = false; //!this.states.selectedService.hasEventBridgeSchedule;
            });
        }
        this.states.selectedService.hasEventBridgeSchedule = e.source.checked;
        this.cdr.detectChanges();
    }

    async selectedServiceChanged(currentService: any) {
        this.states.newServiceScheduler = {
            scheduledActionName: '',
            scalableDimension: 'ecs:service:DesiredCount',
            hour: 23,
            minute: 0,
            minCapacity: 1,
            maxCapacity: 1,
            isEnabled: true
        };
        this.states.activeScalingMangementTab = 1;
        await this.ecsAppServiceHandler.loadServiceInfo(currentService, this.states);
        this.ecsAppServiceHandler.isServiceSetupValid(this.states);
        this.cdr.detectChanges();
    } 

    getTask(container: any, taskFilter: any) {
        return this.ecsAppShareHandler.getTask(container, taskFilter, this.states);
    }

    getTasks(container: any, taskFilter: any) {
        let result = [];
        let webTaskDef = this.ecsAppShareHandler.getTask(container, '-task', this.states);
        let workerTaskDef = this.ecsAppShareHandler.getTask(container, '-worker-task', this.states);
        
        if (webTaskDef) {
            result.push(webTaskDef);                
        }

        if (workerTaskDef) {
            result.push(workerTaskDef);                
        }

        return result;
    }


    getTaskDefinitions(container: any) {
        let result = [];
        let webTaskDef = this.ecsAppShareHandler.getTaskDefinition(container, '-task', this.states);
        let workerTaskDef = this.ecsAppShareHandler.getTaskDefinition(container, '-worker-task', this.states);

        if (webTaskDef) {
            result.push(webTaskDef);                
        }

        if (workerTaskDef) {
            result.push(workerTaskDef);                
        }

        return result;
    }

    getTaskDefContainerDefinitions(containers: any[]) {
        let result = this.ecsAppShareHandler.getTaskDefContainerDefinitions(containers, this.states);
        return result;
    } 

    getTaskDefContainerDefinitionRepo(containerDef: any) {
        let result = (containerDef && containerDef.image && containerDef.image.split(':').length > 0 ? containerDef.image.split('/').pop().split(':').shift() : 'NA');
        return result;
    }

    getTaskDefContainerDefinitionBuildTag(containerDef: any) {
        let result = (containerDef.image && containerDef.image.split(':').length > 0 ? containerDef.image.split(':').pop() : 'NA');
        return result;
    }

    getMinutesBetweenDates(startDateValue: string) {
        let result;
        const endDate = new Date();
        const startDate = new Date(startDateValue);

        const diff = endDate.getTime() - startDate.getTime();
        const diffByMins = (diff / 60000);

        if (isNaN(diffByMins) || diffByMins <= 0) {
            result = 'NA';
        } else if (diffByMins < 60) {
            result = Math.trunc(diffByMins) + ' minutes ago';
        } else if (diffByMins < 1440) {
            result = Math.trunc(diffByMins / 60) + ' hours ago';
        } else {
            result = Math.trunc(diffByMins / 60 / 24) + ' days ago';
        }

        return result;
    }

    getEnvironmentKeyValue(container: any, taskFilter: any, keyName: any) {
        let result;
        const taskWorkerDefs = this.ecsAppShareHandler.getContainerDefinitions(container, taskFilter, this.states);

        if (taskWorkerDefs) {
            result = this.getEcsConfigKeyValue(taskWorkerDefs, keyName);
        }

        return result;
    }

    getEcsConfigKeyValue(ecsSettingsObj: any, keyName: string) {
        let result;

        for (let k = 0; k < ecsSettingsObj.length; k++) {
            for (const key in ecsSettingsObj[k]) {
                if (key === keyName) {
                    result = ecsSettingsObj[k][key];
                    break;
                }
            }
        }
        return result;
    }

    async submitEnvChanges(container: any) {
        
        const upgradeTasks = await this.svc.getEcsUpgradeTasksActive(this.states);

        if (this.states.cluster.isEnvironmentChangesDisabled === true) {
            swal({
                title: 'Changes temporary disabled',
                text: 'Please contact Devops',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //
            });
        } else if (upgradeTasks.length > 0) {

            swal({
                title: 'Changes not allowed while an active Upgrade Task is running',
                text: `Wait for the upgrade to complete or go to the resources tab and \nclick the Task(s) Status subtab to find the upgrade and stop it: \n${upgradeTasks.join('\n')}`,
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //
            });
        } else if (this.ecsAppEventHandler.isFailoverEnvironment(this.states)) {
            //failover sites dont need to run upgrade database
            this.ecsAppEventHandler.submitEnvChanges(false, container, this.states);
        } else if (!this.ecsAppEventHandler.runUpgradeTask(this.states)) {
            //its not a failover environment and we are not running an upgrade task because
            //no build has changed or new patch is applied
            //so give the user the option to force upgrade 
            swal({
                title: 'Apply Changes with the same build tag',
                text: 'Would you like to Force Database Upgrade to run? This will resync all data. The default is to not run Upgrade.',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Force Upgrade',
                cancelButtonText: 'Default Behavior (No Force Upgrade)'
            }).then((isConfirm) => {
                if (isConfirm.value) {
                    //true = force upgrade
                    this.ecsAppEventHandler.submitEnvChanges(true, container, this.states);
                } else {
                    //false = no force upgrade
                    this.ecsAppEventHandler.submitEnvChanges(false, container, this.states);
                }
            });
        } else {
            this.ecsAppEventHandler.submitEnvChanges(true, container, this.states);
        }
    }  

    addEnvironmentVariable(containerTaskDef: any, singleTask: any) {
        this.ecsAppEnvironmentHandler.addEnvironmentVariable(containerTaskDef, singleTask, this.states);
    }

    deleteSetting(containerTaskDef: any, singleTask: any, key: any) {
        swal({
            title: 'Really?',
            text: `Confirm you want to delete the environment variable ${key}`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then((isConfirm) => {
            if (isConfirm.value) {
                this.ecsAppEnvironmentHandler.deleteSetting(singleTask, key, this.states);
                this.cdr.detectChanges();
            }
        });
    }

    async refreshInstanceHealthStatus() {
        this.states.refreshInstanceHealthStatusDisabled = true;
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getTargetGroupArn', parameters: {
                targetGroupArn: this.states.cluster.targetGroupInfo[0].TargetGroupArn,
                awsAccountName: this.states.cluster.accountName
            }
        });
        this.svc.initInstanceHealthTable(result.response, this.states);
        this.states.refreshInstanceHealthStatusDisabled = false;
        this.cdr.detectChanges();
    }

    async archiveEnvironment() {
        swal({
            title: 'Are you sure?',
            text: 'This will delete all uptime alarms and mark the environment as archived',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                await this.ecsAppAlarmsHandler.archiveEnvironment(this.states);
                this.cdr.detectChanges();
            }
        });

    }

    selectedEc2InstanceChanged(instanceType: any) {
        this.ecsAppEcsServiceHandler.selectedEc2InstanceChanged(instanceType, this.states);
    }
    clearAppRedisCache(taskObject: any) {
        this.ecsAppRestartHandler.clearAppRedisCache(taskObject, this.states);
    }

    restartApp() {
        this.ecsAppRestartHandler.restartApp(this.states);
    }
    requestRestartAppServer() {
        this.ecsAppRestartHandler.requestRestartAppServer(this.states);
    }

    restartAppServer(ec2Info: any) {
        this.ecsAppRestartHandler.restartAppServer(ec2Info, this.states);
    }

    ecsAppEditChanged() {
        if (this.state) {
            this.state.reload();
        }
    }

    async deleteEcsServiceAutoScalePolicy(deleteScalePolicy: any, index: Number) {
        await this.ecsAppEcsServiceHandler.deleteEcsServiceAutoScalePolicy(deleteScalePolicy, index, this.states);
        this.cdr.detectChanges();
    }

    async upsertEcsServiceAutoScalePolicy(newScalePolicy: any, isNew: any) {
        await this.ecsAppEcsServiceHandler.upsertEcsServiceAutoScalePolicy(newScalePolicy, isNew, this.states);
        this.ecsAppServiceHandler.isServiceSetupValid(this.states);
        this.cdr.detectChanges();
    }

    schedulerEnabledClicked() {
        if (!this.states.cluster.environment.ecsParameters.isSchedulerEnabled) {
            this.states.cluster.environment.ecsParameters.isSchedulerEnabled = 'true';
        } else {
            this.states.cluster.environment.ecsParameters.isSchedulerEnabled = (this.states.cluster.environment.ecsParameters.isSchedulerEnabled.toLowerCase()) === 'false' ? 'true' : 'false';
        }

        this.ecsAppShareHandler.saveEnvironmentParaInfo('isSchedulerEnabled', this.states.cluster.environment.ecsParameters.isSchedulerEnabled, this.states);
    }

    //currentTaskDef can be currentWebTaskDef or currentWorkerTaskDef
    setFargateTaskCpu(containerTaskDef: any, taskContainerDef: any) {
        this.ecsAppTaskHandler.setFargateTaskCpu(this.states, containerTaskDef, taskContainerDef);
    }

    async requestNewEcsServiceScalableTarget(capacity: number) {
        await this.ecsAppEcsServiceHandler.requestNewEcsServiceScalableTarget(capacity, this.states);
        await this.selectedServiceChanged(this.states.currentService);  // refresh selected service
    }

    async requestEcsServiceScalableTarget(scalableTarget: any) {
        await this.ecsAppEcsServiceHandler.requestEcsServiceScalableTarget(scalableTarget, this.states);
        this.ecsAppServiceHandler.isServiceSetupValid(this.states);
        this.cdr.detectChanges();
    }

    getPredefinedMetricTypes() {
        let result: any = [];
        const usedMetrics: any = [];

        if (this.states.selectedService && this.states.selectedService.scalingPolicies) {
            this.states.selectedService.scalingPolicies.forEach(function (config: any) {
                usedMetrics.push(config.TargetTrackingScalingPolicyConfiguration.PredefinedMetricSpecification.PredefinedMetricType);
            });

            result = _.difference(['ECSServiceAverageCPUUtilization', 'ECSServiceAverageMemoryUtilization', 'ALBRequestCountPerTarget'], usedMetrics);
        }

        return result;
    }


    isScalePolicyValid(sp: any) {
        let result = false;

        if (sp && sp.PolicyName && sp.TargetTrackingScalingPolicyConfiguration.TargetValue && sp.TargetTrackingScalingPolicyConfiguration.ScaleOutCooldown && sp.TargetTrackingScalingPolicyConfiguration.ScaleInCooldown && sp.TargetTrackingScalingPolicyConfiguration.PredefinedMetricSpecification.PredefinedMetricType) {
            result = true;
        }

        return result;
    }

    isServiceAutoScalingConfigDisabled(states: IEcsAppEditStates) {
        let result = false;
        const enabledList = _.filter(states.selectedService.scalingPolicies, function (sp) {
            return sp.TargetTrackingScalingPolicyConfiguration.DisableScaleIn === false;
        });

        if ((states.selectedService.scalingPolicies.length > 0 && enabledList.length > 0) || states.selectedService.scalingPolicies.length === 0) {
            result = true;
        }

        return result;
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
    }

    selectActiveScalingMangementTab(event: any) {
        this.states.activeScalingMangementTab = event.index;
    }

    async createEcsServiceScheduler(newServiceScheduler: any) {

        // hour: number, min: number, maxCapacity: number, minCapacity: number) {

        const exists = _.find(this.states.selectedService.scheduleActions, (sa) => {
            if (sa.ScheduledActionName === newServiceScheduler.scheduledActionName) {
                return sa;
            }
        });

        if (exists) {
            swal({
                title: 'The schedule already existing',
                text: 'You cannot create a duplicate schedule. Change your selections to be unique',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //
            });
        } else if (newServiceScheduler.minCapacity > newServiceScheduler.maxCapacity) {
            swal({
                title: 'Invalid setup',
                text: 'Minimum must be less than or equal to the max number of instances',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                //
            });
        } else {
            await this.ecsAppEcsServiceHandler.createEcsServiceScheduler(newServiceScheduler, this.states);
            await this.selectedServiceChanged(this.states.currentService);
        }

    }

    async deleteEcsServiceScheduler(scheduledActionName: string) {
        swal({
            title: 'Confirm schedule deletion',
            text: 'Delete ' + scheduledActionName + '?',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const resourceId = 'service/' + this.states.clusterName + '/' + this.states.selectedService.serviceName;
                await this.ecsAppEcsServiceHandler.deleteEcsServiceScheduler(resourceId, scheduledActionName, this.states);
                await this.selectedServiceChanged(this.states.currentService);
            }
        });
    }

    // https://docs.aws.amazon.com/autoscaling/application/userguide/scheduled-scaling-using-cron-expressions.html
    // https://docs.aws.amazon.com/autoscaling/application/userguide/application-auto-scaling-suspend-resume-scaling.html
    serviceSchedulerOptionChanged() {
        this.states.newServiceScheduler.resourceId = 'service/' + this.states.clusterName + '/' + this.states.selectedService.serviceName;
        this.states.newServiceScheduler.scheduledActionName = this.states.selectedService.serviceName + '_fg_scale_cron_' + this.states.newServiceScheduler.days.toLowerCase() + '_' + this.states.newServiceScheduler.hour + '_' + this.states.newServiceScheduler.minute;
        this.states.newServiceScheduler.scheduledActionName = this.states.newServiceScheduler.scheduledActionName.replace(/-/g, '_');
        this.cdr.detectChanges();
    }

    serviceScheduledDisabledChanged(e: any, scalableTarget: any) {
        const suspendedState = scalableTarget.SuspendedState;

        swal({
            title: 'Confirm action',
            text: (!suspendedState.ScheduledScalingSuspended ? 'Disable' : 'Enable') + ' all auto schedule(s)?',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                suspendedState.ScheduledScalingSuspended = !suspendedState.ScheduledScalingSuspended;
                e.source.checked = suspendedState.ScheduledScalingSuspended;
                await this.ecsAppEcsServiceHandler.updateEcsServiceSchedulerSuspendedState(scalableTarget, this.states);
                this.cdr.detectChanges();
            } else {
                e.source.checked = suspendedState.ScheduledScalingSuspended;
                this.cdr.detectChanges();
            }
        });
    }
    createServiceMetricsChart(data: any, title: string, states: IEcsAppEditStates) {
        const metricDatasets = [];

        for (let x = 0; x < data.length; x++) {
            const metricData = data[x];
            const xAxesData: any[] = [];
            const lineColor = this.chartSvc.getRandomColor();

            metricData.chartLabel = metricData.serviceName.replace(this.states.cluster.clusterName + '-', '') + '-' + metricData.Label.replace('Utilization', '');
            // sort data by date time
            metricData.Datapoints.sort(function (a: any, b: any) {
                const keyA = new Date(a.Timestamp);
                const keyB = new Date(b.Timestamp);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

            // round avgs
            metricData.Datapoints.map(function (a: any) {
                // in order for the chart to register a change you need to push values to the array
                xAxesData.push(a.Average.toFixed(2));
            });

            metricDatasets.push({
                serviceName: metricData.serviceName,
                label: metricData.chartLabel,
                data: xAxesData,
                backgroundColor: lineColor,
                borderColor: lineColor,
                hoverBorderWidth: 15,
                hoverBorderColor: lineColor,
                fill: false
            });
        } // for
        const xAxesLabel = data[0].Datapoints.map(function (a: any) {
            return a.TimestampLabel;
        });

        if (this.doubleLineChart) {
            this.doubleLineChart.destroy();
        }

        this.states.chartSettings = {
            type: 'line',
            data: {
                labels: xAxesLabel,
                datasets: metricDatasets,
            },
            options: {
                onClick: (evt: any, activeElements: any, chart: any) => {
                    this.chartClick(activeElements);
                    // console.log(`DatasetIndex: ${activeElements[0].datasetIndex} DataIndex: ${activeElements[0].index}`)
                },
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        position: 'top',
                        text: 'Service Task Metics %',
                        color: '#666',
                        font: {
                            size: 12,
                        },
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            color: '#999',
                            font: {
                                size: 14
                            }
                        },
                    },
                },
            },  // options
        };

        this.doubleLineChart = new Chart(this.doubleLineCanvas?.nativeElement, this.states.chartSettings);
    }

    async viewContainerEventLog(createdAt: any) {
        const utcTimeStamp = (new Date(createdAt).getTime() / 1000);

        const response = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsDebugInfo',
            parameters: {
                clusterName: this.states.cluster.clusterName,
                serviceName: this.states.cluster.services,
                utcValue: utcTimeStamp,
                awsAccountName: this.states.cluster.accountName
            }
        });

        if (response.paperTrailUrl) {
            window.open(response.paperTrailUrl, '_blank');
        }
    }

    copyToClipboard(data: any) {
        const value = JSON.stringify(data);

        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(value).select();
        document.execCommand('copy');
        $temp.remove();
    }

    async viewDbCloneStatus(event: any) {
        const data = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getScreenStatus', parameters: {
                runId: event.eventDetail.runId, buildLog: event.eventDetail.buildLog
            }
        });

        swal({
            title: 'DB Clone Status',
            text: (!data.status || data.status.length === 0 ? 'Clone process and status log not found' : data.status),
            type: 'info',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
        }).then(async (isConfirm: any) => {
            //
        });

    }

    triggerHover(event: any, chart: Chart) {

        const xPosition = _.findIndex(chart.data.labels, function (l) { return l === event.createdAt; });
        const yPosition = _.findIndex(chart.data.datasets, function (d: any) { return d.serviceName === event.serviceName; });

        // let yPosition = chart.data.datasets.findIndex(d: any => d.serviceName === event.serviceName);

        if (chart.getActiveElements().length > 0) {
            chart.setActiveElements([]);
        } else if (xPosition >= 0 && yPosition >= 0) {
            chart.setActiveElements([
                {
                    datasetIndex: yPosition,
                    index: xPosition,
                }
            ]);
        }
        chart.update();
    }

    onPatchSelect(event: any) {
        this.states.selectedPatches.push(event);
    }

    onPatchSelectAll(event: any[]) {
        this.states.selectedPatches = event;
    }

    onPatchDeSelect(singleTask: any, event: any) {
        if (_.find(this.states.cluster.appliedPatches, event)) {
            swal({
                title: 'Remove Patch?',
                text: 'You cannot remove an already applied Patch, this may result in data inconsistencies if you submit changes.',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                this.states.selectedPatches.push(event);
            });
        } else {
            const selectedItemFiles = this.states.selectedPatches;
            this.states.selectedPatches = _.filter(selectedItemFiles, patch => {
                return (patch.id !== event.id);
            });
        }
    }

    onPatchDeSelectAll(singleTask: any, event: any[]) {
        if (_.size(this.states.cluster.appliedPatches)) {
            swal({
                title: 'Remove Patches?',
                text: 'You cannot remove an already applied Patch, this may result in data inconsistencies if you submit changes.',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            }).then(async (isConfirm: any) => {
                this.states.selectedPatches = event;
            });
        } else {
            this.states.selectedPatches = [];
        }
    }

    onPatchDropDownClose(singleTask: any) {
        this.states.selectedPatches = _.uniqBy(this.states.selectedPatches, 'id');
    }

    createPatch() {
        this.state.go('argos.prism.patch_management.list');
    }

    async buildTagChanged($event: any, singleTask: any) {

        if (this.states.repositoryName === 'clarify-platform') {
            // refetch patchlist
            await this.svc.getPatchTableList(this.states);
            this.states.selectedPatches = this.states.selectedBuildTag === this.states.currentBuildTag ? _.cloneDeep(this.states.cluster.appliedPatches) : [];
            this.states.selectedPatchNames = this.states.selectedBuildTag === this.states.currentBuildTag ? _.map(this.states.cluster.appliedPatches, 'name').join(', ') : 'No Patches Applied';

            // if you switch buildtags on any env, maintain env vars for DATA_PATCHES
            if (this.states.selectedBuildTag !== this.states.currentBuildTag && this.states.cluster.appliedPatches && this.states.cluster.appliedPatches.length > 0) {
                swal({
                    title: 'Notice!',
                    text: 'Changing the build tag will remove existing patches if you submit changes.',
                    type: 'warning',
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                }).then(async (isConfirm: any) => {
                    //
                });
            }
            this.cdr.detectChanges();
        }
    }

    async stopTaskDef(taskDef: any) {
        swal({
            title: 'Confirm Task Stop?',
            text: `Are you sure you want to stop ${taskDef.taskDefName}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const result = await this.ecsAppTaskHandler.stopTaskDef(taskDef.taskId, taskDef.taskDefName, this.states);
                let title = 'Stop request was successful';
                let message = 'Refreshing the Task(s) Status list now';
                if (!result.isSuccessful) {
                    title = 'Stop request failed';
                    message = 'Please check the argos logs for errors';
                }

                swal({
                    title,
                    text: message,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                }).then(async (isConfirm: any) => {
                    await this.refreshContrainerTaskDefs();
                    this.cdr.detectChanges();
                });

            }
        });


    }
}
