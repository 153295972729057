import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IEnvironmentEditCareGroupingModalProps, IEnvironmentEditCareGroupingModalStates, IEnvironmentEditCareGroupingModalService } from './environmentEditCareGroupingModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { AlertService } from 'client/app/services/alert.service';
declare const $: any;

@Injectable()
export class EnvironmentEditCareGroupingModalService implements IEnvironmentEditCareGroupingModalService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService,
        private alertSvc: AlertService, private changeDetection: ChangeDetectorRef) {
        //
    }
    async initDelegate(props: IEnvironmentEditCareGroupingModalProps, states: IEnvironmentEditCareGroupingModalStates): Promise<object> {
        states.tableErrorMessages = states.tableErrorMessages || {};
        states.clientCareGrouping = props.clientCareGrouping;
        states.originalClientCareGrouping = _.cloneDeep(states.clientCareGrouping);
        _.forEach(states.overrides, function (override) {
            if (_.has(_.get(states.clientCareGrouping, 'overrides'), override.property)) {
                override.override = true;
            }
        });
        await this.initData(states);
        return {};
    }

    async changeDelegate(oldProps: IEnvironmentEditCareGroupingModalProps, newProps: IEnvironmentEditCareGroupingModalProps, states: IEnvironmentEditCareGroupingModalStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async initData(states: IEnvironmentEditCareGroupingModalStates) {
        const careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { order: 'title' }
        });
        const dataSource = await this.dataAccess.genericFind({
            model: 'ClientDataSource',
            filter: { where: { clientId: states.clientCareGrouping.clientId } }
        });
        // Filter care-groupings to only the ones that are not data-source specific
        // or for which this client has enabled data-sources
        const clientDataSources = _.map(dataSource, 'dataSource');
        states.careGroupings = _.filter(careGroupings, function (careGrouping) {
            if (_.includes(careGrouping.name, '.')) {
                const dataSource = _.split(careGrouping.name, '.')[1];
                return (_.findIndex(clientDataSources, function (clientDataSource) {
                    return (dataSource === clientDataSource);
                }) >= 0);
            } else {
                return true;
            }
        });

        this.careGroupingChangedHandler(states);
        this.initPiiMultiSelectSettings(states.careGrouping, states.clientCareGrouping, states);
        this.clausesChangedHandler(states);
        for (const override of states.overrides) {
            if (override?.type === 'table') {
                await this.checkTableExistence(states, _.get(states.clientCareGrouping, ['overrides', override?.property]), override?.property);
            }
        }
    }

    initOverrideSettings(states: IEnvironmentEditCareGroupingModalStates) {
        _.forEach(states.overrides, function (override) {

            // get the defualt ccg value if it exists
            _.forEach(states.selectedCareGroupings, function (cg) {
                if (cg.settings && cg.settings[override.property]) {
                    override.defaultValue = cg.settings[override.property];
                }
            });
        });
    }

    async saveHandler(states: IEnvironmentEditCareGroupingModalStates) {
        const clientCareGrouping = states.clientCareGrouping;

        const unsupportedOverrides = [
            'ccgDefaultMinDateOffset',
            'ccgDefaultMaxDate',
            'ccgDateQueryThreshold',
            'ccgMaxEndDate',
            'ccgMinStartDate',
        ];
        _.forEach(unsupportedOverrides, function (unsupportedOverride) {
            _.unset(clientCareGrouping, ['overrides', unsupportedOverride]);
        });

        _.forEach(states.overrides, function (override) {
            if (!override.override) {
                _.unset(clientCareGrouping, ['overrides', override.property]);
            }
        });

        // unset fixed overrides that null/undefined or blank
        _.forEach(['whereClause', 'joinClause', 'minVolume'], (fixedOverride) => {
            const override = _.get(clientCareGrouping, ['overrides', fixedOverride]);
            if (_.isEmpty(override) && !_.isNumber(override)) {
                _.unset(clientCareGrouping, ['overrides', fixedOverride]);
            }
        });

        if (clientCareGrouping.overrides.pii) {
            clientCareGrouping.overrides.pii.columns = _.map(_.filter(states.piiUISettings.columns, { isSelected: true }), 'label');
        }

        // All client ccg's use Athena, so really no reason to provide an option anymore
        clientCareGrouping.overrides.dbEngine = 'athena';

        clientCareGrouping.lastUpdatedBy = this.argosStore.getItem('username');
        clientCareGrouping.lastUpdateDate = new Date();

        try {
            const careGrouping = await this.dataAccess.genericUpsert({
                model: 'ClientCareGrouping',
                data: clientCareGrouping
            });
            await this.saveClientCareGroupingHistory(careGrouping, states);
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    allowPiiClickedHandler(states: IEnvironmentEditCareGroupingModalStates) {
        if (states.clientCareGrouping.overrides.pii.allow) {

            const propertyList = ['baseTable', 'databaseUrl', 'joinTo', 'joinFrom'];
            const careGroupingName = _.get(states, 'clientCareGrouping.careGrouping');
            const careGrouping: any = _.find(states.careGroupings, { name: careGroupingName });

            for (const propertyName in careGrouping.settings.pii) {
                if (propertyList.indexOf(propertyName) > -1 && !states.clientCareGrouping.overrides.pii.hasOwnProperty(propertyName)) {
                    // so we have a default value from the base model and the client care grouping doesn't already have a value set
                    // then set the value from the base model
                    states.clientCareGrouping.overrides.pii[propertyName] = careGrouping.settings.pii[propertyName];
                }
            }
        }
    }

    copyDefaultsHandler(states: IEnvironmentEditCareGroupingModalStates) {
        const result = ['selected ccg: ' + states.clientCareGrouping.careGrouping];
        states.overrides.forEach(function (o: any) {
            if (o.defaultValue) {
                result.push(o.property + ': ' + o.defaultValue);
            }
        });

        this.copyToClipboard(result.join('\r\n'));
    }

    overrideClickedHandler(states: IEnvironmentEditCareGroupingModalStates, override: any) {
        if (_.get(override, 'property') && override.type === 'checkbox') {
            states.clientCareGrouping.overrides[override.property] = override.defaultValue;
        }
    }

    copyToClipboard(value: any) {
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(value).select();
        document.execCommand('copy');
        $temp.remove();
    }

    validateFields(states: IEnvironmentEditCareGroupingModalStates) {
        states.propertyErrorsDefault = [];
        const validateProperties = _.map(states.overrides, 'property');   // list of properties to validate
        const isOverrideEqualToDefault = this.isOverrideEqualToDefault;

        validateProperties.forEach(function (fieldName: any) {
            const clientValue = states.clientCareGrouping.overrides[fieldName];

            if (clientValue) {
                isOverrideEqualToDefault(fieldName, clientValue, states);
            }
        });
    }

    isOverrideEqualToDefault(fieldName: string, value: any, states: IEnvironmentEditCareGroupingModalStates) {

        const cgObj = _.find(states.selectedCareGroupings, function (cg) {
            if (cg.settings && cg.settings[fieldName] && cg.settings[fieldName].toString().toLowerCase() === value.toString().toLowerCase()) {
                return cg;
            }
        });
        if (cgObj) {
            states.propertyErrorsDefault.push(fieldName);
        }
    }

    isValidHandler(states: IEnvironmentEditCareGroupingModalStates) {
        this.validateFields(states);
        return !(states.propertyErrorsDefault.length === 0 && states.clientCareGrouping.careGrouping && states.clientCareGrouping.overrides && states.clientCareGrouping.notes && states.clientCareGrouping.notes.trim().length > 0);
    }

    async saveClientCareGroupingHistory(clientCareGrouping: any, states: IEnvironmentEditCareGroupingModalStates) {
        // Only save a new version if something actually changed.
        if (_.isEqual(states.originalClientCareGrouping, clientCareGrouping)) {
            return Promise.resolve();
        }

        let version = 0;
        const username = this.argosStore.getItem('username');
        const histories = await this.dataAccess.genericFind({
            model: 'ClientCareGroupingHistory',
            filter: {
                where: {
                    clientCareGroupingId: clientCareGrouping.id
                }
            }
        });
        if (histories.length > 0) {
            version = _.max(_.map(histories, 'version')) || 0;
        }
        version++;
        const careGrouping = await this.dataAccess.genericUpsert({
            model: 'ClientCareGroupingHistory',
            data: {
                clientCareGroupingId: clientCareGrouping.id,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: clientCareGrouping
            }
        });
        return careGrouping;
    }

    careGroupingChangedHandler(states: IEnvironmentEditCareGroupingModalStates, updateMultiSelectOptions?: boolean) {
        const careGroupingName = _.get(states, 'clientCareGrouping.careGrouping');
        const careGrouping = _.find(states.careGroupings, { name: careGroupingName });
        states.careGrouping = careGrouping;
        states.baseTable = _.get(careGrouping, 'settings.baseTable');

        if (updateMultiSelectOptions) {
            this.updatePiiMultiSelectOptions(states.careGrouping, states.clientCareGrouping, states);
        }

        states.selectedCareGroupings = _.filter(states.careGroupings, function (cg) {
            return cg.name === states.clientCareGrouping.careGrouping;
        });

        this.initOverrideSettings(states);
    }

    async clausesChangedHandler(states: IEnvironmentEditCareGroupingModalStates) {
        delete states.errorMessage;
        const result = await this.dataAccess.genericMethod({
            model: 'ClientCareGrouping',
            method: 'validateCareGroupingOverrides',
            parameters: {
                clientCareGrouping: states.clientCareGrouping
            }
        });
        if (result?.errorMessage) {
            states.errorMessage = result?.errorMessage;
        }
    }

    async checkTableExistence(states: IEnvironmentEditCareGroupingModalStates, tableName: string, property: string) {
        states.tableErrorMessages = states.tableErrorMessages || {};
        if (tableName && property) {
            states.tableErrorMessages[property] = undefined;
            const result = await this.dataAccess.genericMethod({
                model: 'ClientCareGrouping',
                method: 'validateTableExistence',
                parameters: {
                    tableName
                }
            });
            if (!result?.exists) {
                states.tableErrorMessages[property] = `Table ${tableName} does not exist!`;
            }
        }
    }

    initPiiMultiSelectSettings(careGrouping: any, clientCareGrouping: any, states: IEnvironmentEditCareGroupingModalStates) {
        states.piiUISettings = {
            columns: [],
            selectedColumns: [],
            dropdownSettings: {
                enableSearch: true,
                scrollable: true,
                idField: 'id',
                textField: 'label',
                groupByTextProvider (groupValue: any) {
                    return groupValue;
                }
            },
            dropdownEvents: {
                onSelectionChanged () {
                    const columns = _.map(states.piiUISettings.selectedColumns, function (val: any) {
                        return _.get(_.find(states.piiUISettings.columns, { id: val.id }), 'label');
                    });
                    _.set(states.clientCareGrouping, 'overrides.pii.columns', _.compact(columns));
                }
            }
        };

        this.updatePiiMultiSelectOptions(careGrouping, clientCareGrouping, states);
    }

    updatePiiMultiSelectOptions(careGrouping: any, clientCareGrouping: any, states: IEnvironmentEditCareGroupingModalStates) {
        function multiSelDataMapper(val: any, idx: any) {
            return {
                id: idx,
                label: val
            };
        }

        const piiColumns = _.get(careGrouping, 'settings.pii.columns') || [];
        states.piiUISettings.columns = _.map(piiColumns, multiSelDataMapper);

        const selectedColumns = _.get(clientCareGrouping, 'overrides.pii.columns') || [];

        _.forEach(states.piiUISettings.columns, function (col) {
            col.isSelected = selectedColumns.includes(col.label);
        });

        const onSelectionChangedEventHandler = _.get(states.piiUISettings, 'dropdownEvents.onSelectionChanged');
        if (_.isFunction(onSelectionChangedEventHandler)) {
            onSelectionChangedEventHandler();
        }
    }
}
