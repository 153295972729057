import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IClarifyProviderRevisionListStates, IClarifyProviderRevisionListService } from './clarifyProviderRevisionList.component.d';
import { getGrainName, getParameters, getRevisionClass, getRevisionMethod } from '../commonUtils';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { StateService, UIRouter } from '@uirouter/core';
import swal from 'sweetalert2';

@Injectable()
export class ClarifyProviderRevisionListService implements IClarifyProviderRevisionListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService,
        private matDialog: MatDialog, private cdr: ChangeDetectorRef, private state: StateService,
        private uiRouter: UIRouter) {
        //
    }

    async initDelegate(states: IClarifyProviderRevisionListStates): Promise<object> {
        states.currentRevisionGrainSelection = this.uiRouter.globals.params.currentRevisionGrainSelection ?? states.currentRevisionGrainSelection
        await this.activate(states);
        return {};
    }

    refreshPage(states: IClarifyProviderRevisionListStates) {
        this.uiRouter.globals.params.currentRevisionGrainSelection = states.currentRevisionGrainSelection
    }

    async activate(states: IClarifyProviderRevisionListStates) {
        await this.queryClarifyProvider(states, {});
    }

    async queryClarifyProvider(states: IClarifyProviderRevisionListStates, filter:any) {
        const whereFilter: any = {};
        for (const [key, value] of Object.entries(filter)) {
            if (value) {
                whereFilter[key] = { like: `%${value}%` };
            }
        }
        states.clarifyProviderRevisions = await this.dataAccess.genericFind({
            model: getRevisionClass(states.currentRevisionGrainSelection),
            filter: {
                where: whereFilter,
                limit: 100
            }
        });

        const columns = _.keys(states.clarifyProviderRevisions[0]);
        columns.splice(columns.indexOf('id'), 1);
        columns.unshift('id');
        states.matTableColumns = columns;
        states.defaultDisplayMatTableColumns = ['id', states.currentRevisionGrainSelection, 'data_sources_to_apply'];

        // add the 5 keys with highest fill from all objects in clarifyProviderRevision
        const keysToSort = states.matTableColumns;
        const keysToSortWithFill = [];
        for (const key of keysToSort) {
            if (states.defaultDisplayMatTableColumns.includes(key)) {
                continue;
            }
            const fill = _.filter(states.clarifyProviderRevisions, (o) => {
                return o[key] !== null;
            }).length;
            keysToSortWithFill.push({ key, fill });
        }
        const sortedKeys = _.orderBy(keysToSortWithFill, ['fill'], ['desc']);
        const top5Keys = _.map(sortedKeys.slice(0, 5), 'key');
        states.defaultDisplayMatTableColumns = states.defaultDisplayMatTableColumns.concat(top5Keys);

        states.table.data = states.clarifyProviderRevisions;
    }

    setTablePageSize(states: IClarifyProviderRevisionListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.clarifyProviderRevisions.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IClarifyProviderRevisionListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IClarifyProviderRevisionListStates) {
        const data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    launchNpiSearch(states: IClarifyProviderRevisionListStates) {
        const currentRevisionGrainSelection = states.currentRevisionGrainSelection
        const textId = getGrainName(currentRevisionGrainSelection)

        swal({
            title: `Search for ${textId}`,
            text: `Enter an ${textId} to search for`,
            input: 'text',
            inputAttributes: {
                style: 'color: black;',
            },
            showCancelButton: true,
            confirmButtonText: 'Search',
            showLoaderOnConfirm: true,
            preConfirm: (id: string) => {
                return this.dataAccess.genericMethod({
                                            model: getRevisionClass(currentRevisionGrainSelection),
                                            method: getRevisionMethod(currentRevisionGrainSelection),
                                            parameters: getParameters(currentRevisionGrainSelection, id)
                                        })
                                            .then((response) => {
                                                if (!response) {
                                                    throw new Error(`${textId} not found`);
                                                } else if (response.error) {
                                                    swal.showValidationError(`Request failed: ${response.error}`);
                                                } else {
                                                    return response;
                                                }
                                            })
                                            .catch((error) => {
                                                swal.showValidationError(`Request failed: ${error}`);
                                            });
            },
        }).then((response) => {
            const results = response.value.result.results;
            const selectedValue = results[_.snakeCase(currentRevisionGrainSelection)];
            const existingNpiRevisions = _.filter(states.clarifyProviderRevisions, (o) => {
                                    return  o[currentRevisionGrainSelection] === selectedValue;
                                });
            if (existingNpiRevisions.length > 0) {
                swal({
                    title: `Overrides for ${textId} ${selectedValue} Already Exist`,
                    html: `<div style="text-align: left;"><table style="width: 100%;"><tr><th style="text-align: left;">Data Sources</th><th style="text-align: left;"></th></tr>${_.map(existingNpiRevisions, (o) => {
                        return currentRevisionGrainSelection !== 'npi' ? `<tr><td style="text-align: left;">${o.dataSourcesToApply.join(', ')}</td><td style="text-align: left;"></td></tr>` : `<tr><td style="text-align: left;">${o.dataSourcesToApply.join(', ')}</td><td style="text-align: left;"><a href="/argos/clarify_provider_revisions/edit/${o.id}">Edit</a></td></tr>`;
                    }).join('')}</table></div>`,
                    type: 'warning',
                    confirmButtonColor: '#57C84D',
                    confirmButtonText: 'Add New',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel'
                }).then(async (isConfirm: any) => {
                    if (isConfirm.value) {
                        this.state.go('argos.referenceData.clarifyProviderRevision.add', {npiAttributes: results, currentRevisionGrainSelection});
                    }
                });
            } else {
                this.state.go('argos.referenceData.clarifyProviderRevision.add', {npiAttributes: results, currentRevisionGrainSelection});
            }
        });
    }
}
