import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IQATestResultSubmoduleDetailStates } from './qaTestResultDashboardSubmoduleDetail.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { QATestResultDetailService } from './qaTestResultDashboardSubmoduleDetail.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as _ from 'lodash';

@Component({
    selector: 'argos-qaTestResultDetail',
    templateUrl: './qaTestResultDashboardSubmoduleDetail.component.html',
    providers: [QATestResultDetailService],
    styleUrls: ['../testResultsDashboard/qaTestResultDashboard.component.less']
})

export class QATestResultsSubmoduleDetailComponent extends ReactComponentBase<{}, IQATestResultSubmoduleDetailStates, {}> implements OnInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: QATestResultDetailService, private cdr: ChangeDetectorRef) {
        super({
            pctDataTable: new MatTableDataSource([])
        } as IQATestResultSubmoduleDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges;
    }

    searchMatTable(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.pctDataTable.filter = filterValue.trim().toLowerCase();
    }

}
