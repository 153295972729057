import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IProductAddEditStates } from './productAddEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ProductAddEditService } from './productAddEdit.component.service';
import * as _ from 'lodash';
@Component({
    selector: 'argos-productAddEdit',
    templateUrl: './productAddEdit.component.html',
    providers: [ProductAddEditService]
})

export class ProductAddEditComponent extends ReactComponentBase<{}, IProductAddEditStates, {}> implements OnInit {
    constructor(private svc: ProductAddEditService, private cdr: ChangeDetectorRef) {
        super({
            product: {},
            careGroupings: [],
            selectedCareGroupings: [],
            mode: null,
            uiSettings: {},
            productContent: [],
            productContentTypes: [],
            activeTab: 0,
            enabled: {}
        } as unknown as IProductAddEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
    }

    isAddMode() {
        return this.states.mode === 'ADD';
    }

    isEditMode() {
        return this.states.mode === 'EDIT';
    }

    getProductName() {
        return _.get(this.states, 'product.name');
    }

    updateProductName(title: any) {
        if (_.isNil(_.get(this.states, 'product.id'))) {
            _.set(this.states, 'product.name', _.camelCase(_.deburr(title)));
        }
    }

    selectAllCheckboxes(path: any, values?: any,
        mapField?: any) {
        this.svc.selectAllCheckboxes(this.states, path, values, mapField);
    }

    checkboxChecked(id: any, path: any) {
        this.svc.checkboxChecked(id, path, this.states);
    }

    saveHandler(andClose?: boolean) {
        this.svc.saveHandler(this.states, andClose);
    }
}
