import { Injectable } from '@angular/core';
import { RootScopeShareService } from './rootScopeShareService';
import * as _ from 'lodash';

@Injectable()
export class APIErrorService {

    constructor(private gdService: RootScopeShareService) {
    }

    logError(url: string, message: any) {
        const errorMessages = this.gdService.getGenericData('errorMessages');
        if (!errorMessages || !Array.isArray(errorMessages) || errorMessages.length > 100) {
            this.gdService.setGenericData('errorMessages', []);
        }
        this.gdService.getGenericData('errorMessages').push({
            screen: this.gdService.getGenericData('pageTitle'),
            screenUrl: _.get(window, 'location.href'),
            url,
            message,
            date: new Date()
        });
    }

    clearError() {
        this.gdService.setGenericData('errorMessages', []);
    }
}
