import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IReferenceListItemEditStates, IReferenceListItemEditService } from './referenceListItemEdit.component.d';
import { TreeNode } from './referenceListItemEdit.component';
import { SortDirection } from './sort-direction.enum';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { UIRouter, StateService } from '@uirouter/core';
import { MD5Service } from 'client/app/services/md5.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewReferenceListHistoryModalComponent } from '../viewReferenceListHistoryModal/viewReferenceListHistoryModal.component';
import * as Bluebird from 'bluebird';
const csv = require('csvtojson');

declare const saveAs: any;

@Injectable()
export class ReferenceListItemEditService implements IReferenceListItemEditService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private md5: MD5Service, private changeDetection: ChangeDetectorRef, private matDialog: MatDialog,
        private state: StateService) {
        //
    }
    async initDelegate(states: IReferenceListItemEditStates): Promise<object> {
        states.lodash = _;
        await this.activate(states);
        return {};
    }

    async activate(states: IReferenceListItemEditStates) {
        await this.getReferenceDataList(states);
        if (states.referenceDataList.referenceDataListTags && states.referenceDataList.referenceDataListTags.length > 0) {
            states.referenceDataList.referenceDataListTags = _.orderBy(states.referenceDataList.referenceDataListTags, 'tag');
        }
        states.parentTags = _.orderBy(_.filter(states.referenceDataList.referenceDataListTags, { parentListTagId: null, isParentTag: true }), 'tag');

        if (states.parentTags.length === 0 && !states.referenceDataList.autoCreated) {
            await this.viewTag(states.referenceDataList.referenceDataListTags[0], states);
        }

        states.selectedParentTag = null;
    }

    async getReferenceDataList(states: IReferenceListItemEditStates) {
        const referenceDataList = await this.dataAccess.genericMethod({
            model: 'ViewReferenceDataListSummary', method: 'findOne', parameters: {
                filter: {
                    where: {
                        id: _.toNumber(this.uiRouter.globals.params.id)
                    },
                    include: ['referenceDataListTags']
                }
            }
        });
        // Add hasChildTag, as these tags can't be deleted
        const parentListTagIds = _.uniq(_.map(referenceDataList.referenceDataListTags, 'parentListTagId'));
        _.forEach(referenceDataList.referenceDataListTags, (tag) => {
            tag.hasChildTag = parentListTagIds.includes(tag.id);
        });
        states.referenceDataList = referenceDataList;
    }

    async viewTag(row: any, states: IReferenceListItemEditStates) {
        states.selectedRowId = row.id;
        states.referenceDataListItemTags = [];
        states.usedInObservations = [];
        if (row.id) {
            states.isEditingDescription = false;
            states.tagDescription = row.description;
            states.referenceDataListItemTags = await this.dataAccess.genericFind({
                model: 'ReferenceDataListItemTag',
                filter: {
                    where: {
                        listTagId: row.id
                    },
                    include: ['referenceDataListItem']
                }
            });
        
            // get all listItemTags when parentTagId is in listTagIds
            const allChildItemTags = await this.dataAccess.genericFind({
                model: 'ReferenceDataListTag',
                filter: {
                    where: {
                        parentListTagId: row.id
                    },
                    include: ['referenceDataListItemTag']
                }});

            states.referenceDataListItemTags = states.referenceDataListItemTags.map(tag => {
                // Add haschildTag, as tags with children can't be deleted
                const hasChildTag = allChildItemTags.some((childTag: any) => childTag.referenceDataListItemTag.some((childItemTag: any) => childItemTag.listItemId === tag.referenceDataListItem.id));
                return {
                    ...tag,
                    hasChildTag
                };
            });

        }
        if (row.id) {
            states.usedInObservations = await this.dataAccess.genericFind({
                model: 'ObservationFilterPredicate',
                filter: {
                    where: {
                        valueReferenceListTagId: row.id
                    },
                    include: ['observation']
                }
            });
        }
        this.changeDetection.detectChanges();
    }

    async saveNewParentTag(states: IReferenceListItemEditStates) {
        states.addParentTag = false;
        const newRow: any = {
            referenceDataListId: Number(this.uiRouter.globals.params.id),
            tag: states.newParentTagName,
            isParentTag: true
        };
        const newReferenceDataListParentTag = await this.dataAccess.genericUpsert({
            model: 'ReferenceDataListTag',
            data: newRow
        });
        states.newParentTagName = '';
        states.parentTags.push(newReferenceDataListParentTag);
        states.parentTags = _.orderBy(states.parentTags, 'tag');
        this.changeDetection.detectChanges();
        const description = 'New parent tag of ' + newReferenceDataListParentTag.tag + ' added to ' + states.referenceDataList.name + ' (Tag ID ' + newReferenceDataListParentTag.id + ')';
        await this.dataAccess.genericMethod({
            model: 'Teradrome', method: 'saveReferenceListHistory', parameters: {
                listId: newReferenceDataListParentTag.referenceDataListId,
                parentTagId: newReferenceDataListParentTag.id,
                parentTag: newReferenceDataListParentTag.tag,
                definition: {description},
                username: this.argosStore.getItem('username')
            }
        });
    }

    async saveNewTag(states: IReferenceListItemEditStates) {
        states.addTag = false;
        const id = this.md5.md5(this.uiRouter.globals.params.id + states.newTagName);
        const newRow: any = {
            referenceDataListId: Number(this.uiRouter.globals.params.id),
            tag: states.newTagName,
            id
        };
        const newReferenceDataListTag = await this.dataAccess.genericUpsert({
            model: 'ReferenceDataListTag',
            data: newRow
        });
        states.newTagName = '';
        states.referenceDataList.referenceDataListTags.push(newReferenceDataListTag);
        states.referenceDataList.referenceDataListTags = _.orderBy(states.referenceDataList.referenceDataListTags, 'tag');
        await this.viewTag(newReferenceDataListTag, states);
        this.changeDetection.detectChanges();
    }

    async updateTagDescription(states: IReferenceListItemEditStates) {
        const tag: any = _.find(states.referenceDataList.referenceDataListTags, { id: states.selectedRowId });
        if (tag) {
            tag.description = states.tagDescription;
            const newListTag = await this.dataAccess.genericUpsert({
                model: 'ReferenceDataListTag',
                data: tag
            });
            // replace the tag in states.referenceDataList with newListTag
            states.referenceDataList.referenceDataListTags = states.referenceDataList.referenceDataListTags.map((elt: any) => {
                return elt.id === newListTag.id ? newListTag : elt;
            });
        }
    }

    async saveNewTagItems(states: IReferenceListItemEditStates) {
        const itemTags = states.newReferenceDataListItemTags.split(',').map(item => item.trim());
        if (states.selectedRowId && states.newReferenceDataListItemTags && states.newReferenceDataListItemTags.length > 0) {
            const findTag: any = _.find(states.referenceDataList.referenceDataListTags, { id: states.selectedRowId });
            if (findTag && findTag.id) {
                // Get existing items
                let items = await this.dataAccess.genericFind({
                    model: 'ReferenceDataListItem',
                    filter: {
                        where: {
                            key: { inq: itemTags },
                            listId: states.referenceDataList.id
                        }
                    }
                });

                const keys = _.map(items, 'key');

                // Create missing items
                const newItems = await Promise.all(_.map(itemTags, (newReferenceDataListItemTag) => {
                    if (!keys.includes(newReferenceDataListItemTag)) {
                        return this.dataAccess.genericUpsert({
                            model: 'ReferenceDataListItem',
                            data: {
                                key: newReferenceDataListItemTag,
                                listId: states.referenceDataList.id
                            }
                        });
                    }
                    return undefined;
                }));

                // Combined existing + new items
                if (newItems) {
                    items = items.concat(newItems);
                }

                const existingItemTags = _.map(states.referenceDataListItemTags, 'listItemId');
                Promise.all(_.map(itemTags, (newReferenceDataListItemTag) => {
                    const listItem: any = _.find(items, { key: newReferenceDataListItemTag });
                    // Only insert missing item/tags
                    if (!existingItemTags.includes(listItem.id)) {
                        return this.dataAccess.genericUpsert({
                            model: 'ReferenceDataListItemTag',
                            data: {
                                listItemId: listItem.id,
                                listTagId: Number(findTag.id)
                            }
                        });
                    }
                    return undefined;
                })).then(async () => {
                    states.addItems = false;
                    states.newReferenceDataListItemTags = '';
                    await this.viewTag(findTag, states);
                });
            }
        }
    }

    buildTree(data: any[]): any[] {
        const treeMap: { [key: string]: any } = {};
      
        data.forEach(item => {
            const { key, tag, parentTags, keyValue } = item;
            
            // Ensure the key exists in the treeMap
            if (!treeMap[key]) {
                treeMap[key] = { name: keyValue ? key + ' - ' + keyValue : key, children: [], level: 0 };
            }
            
            if (parentTags && parentTags.length > 0 && tag) {
                let currentNode = treeMap[key];
            
                // Traverse through parentTags and create nodes as necessary
                parentTags.forEach((parentTag: string, index: number) => {
                    let existingNode = currentNode.children?.find((child: any) => child.name === parentTag);
                
                    if (!existingNode) {
                        existingNode = { name: parentTag, children: [], level: index + 1 };
                        currentNode.children?.push(existingNode);
                    }
                
                    currentNode = existingNode;
                });
      
                // Finally, add the tag as a child node
                currentNode.children?.push({ name: tag, children: [], level: parentTags.length });
            } else if (tag) {
                treeMap[key].children.push({ name: tag, children: [], level: 1 });
            }
        });
      
        // Convert the treeMap into an array
        return Object.values(treeMap);
    }

    async loadKeys(states: IReferenceListItemEditStates) {
        const refListTag = await this.dataAccess.genericMethod({
            model: 'ViewReferenceDataListItemParentTags', method: 'find', parameters: {
                filter: {
                    where: {
                        listId: states.referenceDataList.id,
                        hasChildId: false
                    },
                    fields: ['key', 'parentTags', 'tag', 'keyValue'],
                    order: 'key'
                }
            }
        });
        states.allData = _.orderBy(this.buildTree(refListTag), 'name');
        this.updatePage(states);
        
    }

    async exportTags(parentTagId: any, states: IReferenceListItemEditStates) {
        const parent = await this.dataAccess.genericFind({
            model: 'ReferenceDataListTag',
            filter: {
                where: {
                    id: parentTagId
                }
            }
        });
        const parentTag = parent.length > 0 ? parent[0].tag : states.referenceDataList.name;

        const refListTags = await this.dataAccess.genericMethod({
            model: 'ViewReferenceDataListItemParentTags', method: 'find', parameters: {
                filter: {
                    where: {
                        listId: states.referenceDataList.id,
                        hasParentId: false
                    },
                    order: 'key'
                }
            }
        });

        const grouped = _.groupBy(refListTags, 'key');
        const sortedTags = _.sortBy(_.flatMap(grouped, (group) => {
            // Filter to rows that match the parentTagId
            const matchingEntries = _.filter(group, { topParentId: parentTagId });

            if (matchingEntries.length > 0) {
                return matchingEntries;
            } else {
                // If no matches, prioritize first object w/o child tag (this is for items with no tags in the sublist)
                const noChildTagEntry = _.find(group, { hasChildId: false });
                if (noChildTagEntry) {
                    return [noChildTagEntry];
                } else {
                    return _.take(group, 1);
                }
            }
        }), 'key');

        let exportString = '';
        _.forEach(sortedTags, (item) => {
            if (item.topParentId === parentTagId && item.hasChildId === false) {
                exportString += (item.keyId + ',"' + item.key + '"');
                _.forEach(item.parentTags, (tag) => {
                    if (tag === parentTag) {
                        return;
                    }
                    exportString += (',"' + tag + '"');
                });
                exportString += (',"' + (item.tag ? item.tag: '') + '"\n');
            } else if (item.hasChildId === true) {
                return;
            } else {
                exportString += (item.keyId + ',"' + item.key + '"\n');
            }

        });

        const header = parent.length > 0 ? '' : '"# This file should be used to upload tags that don\'t have a corresponding Sublist. In general, this is only recommended when there is only 1 tag being uploaded. If you are uploading 2 or more tags, consider adding under a new or existing Sublist"\n';
        const blob = new Blob([header + exportString], { type: 'text/plain;charset=utf-8' });
        return saveAs(blob, (parentTag.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '_tags.csv'));
    }

    async uploadTags(file: any, states: IReferenceListItemEditStates, reason: any) {
        let existingListTags = await this.dataAccess.genericFind({
            model: 'ReferenceDataListTag',
            filter: {
                where: {
                    referenceDataListId: this.uiRouter.globals.params.id
                },
                include: 'viewReferenceDataListParentTags'
            }
        });
        existingListTags = existingListTags.filter((elt: any) => states.selectedParentTag ? (elt.parentPath && states.selectedParentTag && elt.parentPath.startsWith(states.selectedParentTag.toString())) : (!elt.parentListTagId && !elt.isParentTag));
        const output: any = [];
        const itemIds = new Set();
        const uniqueTagsMap = new Map();
        const file_obj = file.target.files[0];
        const csvRaw: any = await this.parseCSV(file_obj);
        let keyDict: any = {};
        _.forEach(csvRaw, (item) => {
            const [keyId, key, ...tags] = item.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            // Columns surrounded w/ quotes are accounted for in split, but need to be removed before upload
            const fixedTags = tags.map((field: any) => field.replace(/^"|"$/g, ''));
            if (fixedTags.filter((n: any) => n).length === 0) {
                return;
            }
            if (!states.selectedParentTag && fixedTags.length > 1) {
                throw new Error(`Parent tagging is only supported when using a Sublist.`);
            }
            const originalTags = [...fixedTags];
            _.forEachRight(fixedTags, (tag) => {
                originalTags.pop();
                if (tag !== '') {
                    keyDict = {
                        keyId,
                        key,
                        tags: [...originalTags],
                        tag,
                        parentTagId: states.referenceDataList.autoCreated && !states.selectedParentTag ? undefined : states.selectedParentTag
                    };
                    output.push(keyDict);
                    itemIds.add(keyId);

                    const uniqueKey = `${tag}|${[...originalTags].join('|')}`;
                    if (!uniqueTagsMap.has(uniqueKey)) {
                        uniqueTagsMap.set(uniqueKey, keyDict);
                    }
                }
            });
        });

        // insert new ref list item
        const deduplicatedOutput = Array.from(uniqueTagsMap.values());
        // Filtering out existing tags, when there is a parent tag selected, we filter based on the parent path starting with that tag id + checking all tags
        // when there is no parent tag selected, we filter based on the parentListTagId being null and isParentTag being false
        const refRowsToAdd = deduplicatedOutput.filter((rr: any) => !existingListTags.some((elt: any) => elt.tag === rr.tag && (states.selectedParentTag ? (elt.parentPath && elt.parentPath.startsWith(rr.parentTagId.toString())) : (!elt.parentListTagId && !elt.isParentTag)) && (!elt.viewReferenceDataListParentTags || rr.tags.every((tag: any) => elt.viewReferenceDataListParentTags.parentTags.includes(tag)))));
        for (let index = 0; index < 5; index++) {
            const filteredRefRowsToAdd = refRowsToAdd.filter((rr: any) => rr.tags.length === index);
            if (filteredRefRowsToAdd.length === 0) {
                continue;
            }

            const newListTags = await this.dataAccess.genericFind({
                model: 'ReferenceDataListTag',
                filter: {
                    where: {
                        referenceDataListId: this.uiRouter.globals.params.id
                    },
                    fields: ['tag', 'id', 'parentListTagId', 'parentPath']
                }
            });

            await Promise.all(_.map(filteredRefRowsToAdd, (refRow: any) => {
                const newRow = {
                    referenceDataListId: this.uiRouter.globals.params.id,
                    tag: refRow.tag,
                    parentListTagId: refRow.parentTagId
                };
                if (index !== 0) {
                    // Update parent list tag id - query table
                    const parentID = _.find(newListTags, function (pc) {
                        return pc.tag === refRow.tags[refRow.tags.length - 1] && pc.parentPath.toString().split('.').length === index + 1 && pc.parentPath.startsWith(refRow.parentTagId.toString());
                    });
                    newRow.parentListTagId = parentID.id;
                }
                const newTag = this.dataAccess.genericUpsert({
                    model: 'ReferenceDataListTag',
                    data: newRow
                });
                return newTag;
            }
            ));
            console.log('added ' + (index + 1) + ' level tags');
        }

        let allListTags = await this.dataAccess.genericFind({
            model: 'ReferenceDataListTag',
            filter: {
                where: {
                    referenceDataListId: this.uiRouter.globals.params.id
                },
                fields: ['tag', 'id', 'parentListTagId', 'parentPath', 'isParentTag'],
                include: 'viewReferenceDataListParentTags'
            }
        });
        allListTags = allListTags.filter((elt: any) => states.selectedParentTag ? (elt.parentPath && elt.parentPath.startsWith(states.selectedParentTag.toString()) && elt.viewReferenceDataListParentTags) : (!elt.parentListTagId && !elt.isParentTag));
        const allListItemTags = await Promise.all(_.map(output, (refRow: any) => {
            const tagId = _.find(allListTags, function (alt) {
                if (states.selectedParentTag) {
                    return refRow.parentTagId && alt.tag === refRow.tag && alt.parentPath.toString().split('.').includes(refRow.parentTagId.toString()) && refRow.tags.every((tag: any) => alt.viewReferenceDataListParentTags.parentTags.includes(tag));
                }
                return !refRow.parentTagId && !refRow.isParentTag && alt.tag === refRow.tag;
            });
            return {
                listItemId: refRow.keyId,
                listTagId: tagId.id
            };
        }
        ));

        const existingListItemTags = await this.dataAccess.genericFind({
            model: 'ReferenceDataListItemTag',
            filter: {
                where: {
                    listItemId: {
                        inq: Array.from(itemIds)
                    }
                }
            }
        });

        // Previous way of comparing against existing list item tags was inefficient, handling in one loop
        const existingTagsSet = new Set(existingListItemTags.map((eit: any) => `${eit.listItemId}-${eit.listTagId}`));
        const listItemTagsToAdd = [];
        const seen = new Set();

        for (const item of allListItemTags) {
            const key = `${item.listItemId}-${item.listTagId}`;
            // Check if the item is seen or exists in the existingTagsSet
            if (!seen.has(key) && !existingTagsSet.has(key)) {
                listItemTagsToAdd.push(item);
                seen.add(key); // Mark as seen for deduplication
            }
        }
        console.log(`Adding ${listItemTagsToAdd.length} list item tags to the database.`);

        const chunks = _.chunk(listItemTagsToAdd, 1000);
        await Bluebird.map(chunks, (refRows: any) => {
            return this.dataAccess.genericCreateMany({
                model: 'ReferenceDataListItemTag',
                data: refRows
            }).catch(reason => {
                console.error('Promise was rejected with:', reason);
            });
        }, { concurrency: 10 });

        const parentTag = states.selectedParentTag ? _.find(states.parentTags, { id: states.selectedParentTag }).tag : undefined;
        const description = 'Tags updated for ' + parentTag ? ('parent tag ' + parentTag + ' of ') : '' + states.referenceDataList.name +  ': ' + refRowsToAdd.length + ' tag' + (refRowsToAdd.length === 1 ? '' : 's') + ' and ' + listItemTagsToAdd.length + ' list item tag' + (listItemTagsToAdd.length === 1 ? '' : 's') + ' were added';
        await this.dataAccess.genericMethod({
            model: 'Teradrome', method: 'saveReferenceListHistory', parameters: {
                listId: this.uiRouter.globals.params.id,
                parentTagId: states.selectedParentTag,
                parentTag,
                definition: {description, reason},
                username: this.argosStore.getItem('username')
            }
        });
    }

    async delete(type: string, id: number, description: string, states: IReferenceListItemEditStates) {
        const tag: any = _.find(states.referenceDataList.referenceDataListTags, { id: states.selectedRowId || id });
        let deleteMessage = `Are you sure you want to delete the ${tag.tag} ${description}? Confirm tag is not being used in any observations and search Github for usage.`;
        let allItems: any = [];
        if (type === 'tag') {
            allItems =  _.map(states.referenceDataListItemTags && states.referenceDataListItemTags.length > 0 ? states.referenceDataListItemTags : states.referenceDataListItemTags = await this.dataAccess.genericFind({
                model: 'ReferenceDataListItemTag',
                filter: {
                    where: {
                        listTagId: states.selectedRowId || id
                    },
                    include: ['referenceDataListItem']
                }
            }), 'referenceDataListItem.key');
            deleteMessage += `\nThis will delete the ${tag.tag} tag from ${allItems.join(', ')}?`;
        }
        swal({
            title: 'Delete ' + description + '?',
            text: deleteMessage,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                let description = '';
                if (type === 'tag') {
                    await this.dataAccess.genericMethod({
                        model: 'ReferenceDataListItemTag', method: 'deleteAll', parameters: { where: { listTagId: id }}
                    });
                    await this.dataAccess.genericMethod({
                        model: 'ReferenceDataListTag', method: 'deleteById', parameters: { id }
                    });
                    states.referenceDataList.referenceDataListTags = _.filter(states.referenceDataList.referenceDataListTags, (elt: any) => elt.id !== id);
                    // if the tag being deleted is the selected tag, select no tags
                    if (states.selectedRowId === id) {
                        states.selectedRowId = null;
                        states.referenceDataListItemTags = [];
                    }
                    
                    description = 'Tag ' + tag.tag + ' deleted from ' + states.referenceDataList.name + ' (Tag ID ' + tag.id + '): Deleted tag from ' + allItems.join(', ');

                } else if (type === 'itemTag') {
                    const item = _.find(states.referenceDataListItemTags, { id });
                    await this.dataAccess.genericMethod({
                        model: 'ReferenceDataListItemTag', method: 'deleteById', parameters: { id }
                    });
                    states.referenceDataListItemTags = _.filter(states.referenceDataListItemTags, (elt: any) => elt.id !== id);
                    description = 'Tag ' + tag.tag + ' deleted from ' + item.referenceDataListItem.key + ' (Tag Item ID ' + item.id + ')';
                }
                this.changeDetection.detectChanges();
                swal({
                    title: 'Deleted!',
                    text: `The ${_.lowerCase(_.startCase(type))} has been deleted.`,
                    type: 'success'
                });
                await this.dataAccess.genericMethod({
                    model: 'Teradrome', method: 'saveReferenceListHistory', parameters: {
                        listId: this.uiRouter.globals.params.id,
                        parentTagId: states.selectedParentTag, 
                        parentTag: states.selectedParentTag ? _.find(states.parentTags, { id: states.selectedParentTag }).tag : undefined,
                        definition: {description},
                        username: this.argosStore.getItem('username')
                    }
                });


            }
        });
    }

    saveNewTagName(row: any, states: IReferenceListItemEditStates) {
        const updatedRow = row;
        updatedRow.tag = states.editTagName;
        this.dataAccess.genericUpsert({
            model: 'ReferenceDataListTag',
            data: updatedRow
        });
        states.editTagId = null;
        states.editTagName = '';
    }

    addItems(states: IReferenceListItemEditStates) {
        swal({
            title: 'Add Tags',
            html: `
            <p>Enter a comma separated list of tags to add to the selected tag:</p>
            <input id="swal-input1" class="swal2-input" placeholder="Enter tags" style="color: black;">
            <p>Or upload a CSV file with tags:</p>
            <input type="file" id="swal-input2" accept=".csv" style="opacity: 0; width: 0.1px; height: 0.1px; position: absolute;">
            <label for="swal-input2" style="background-color: #57C84D; color: white; padding: 10px 20px; border-radius: 5px; cursor: pointer;">Upload CSV</label>
            `,
            showCancelButton: true,
            confirmButtonColor: '#57C84D',
            confirmButtonText: 'Add',
            cancelButtonText: 'Cancel',
            preConfirm: () => {
                const textInput = document.getElementById('swal-input1') as HTMLInputElement; 
                const fileInput = document.getElementById('swal-input2') as HTMLInputElement;
            
                return {
                    textInput: textInput ? textInput.value : '',
                    fileInput: fileInput && fileInput.files ? fileInput.files[0] : null
                };
            }
        }).then(async (result: any) => {
            if (result.value) {
                let processedData: any = [];
                if (result.value.fileInput) {
                    const reader = new FileReader();
            
                    // Create a promise to handle the file reading
                    const fileReadPromise = new Promise((resolve, reject) => {
                        reader.onload = async (e) => {
                            const text = e.target?.result;
                            try {
                                processedData = await processData(text);
                                resolve(processedData);
                            } catch (error) {
                                console.error('Error processing file data:', error);
                                reject(error);
                            }
                        };
            
                        reader.onerror = (error) => {
                            console.error('Error reading file:', error);
                            reject(error);
                        };
            
                        reader.readAsText(result.value.fileInput);
                    });
            
                    await fileReadPromise;
                } else if (result.value.textInput) {
                    await processData(result.value.textInput).then(data => {
                        processedData = data;
                    }).catch(error => {
                        console.error('Error processing direct input:', error);
                    });
                }      

                const listItemsToAdd = processedData.map((item: any) => {
                    return {
                        key: item,
                        listId: states.referenceDataList.id,
                        startDt: new Date(),
                    };
                });

                const chunks = _.chunk(listItemsToAdd, 1000);
                await Bluebird.map(chunks, (refRows: any) => {
                    return this.dataAccess.genericCreateMany({
                        model: 'ReferenceDataListItem',
                        data: refRows
                    }).catch(reason => {
                        console.error('Promise was rejected with:', reason);
                    });
                }, { concurrency: 10 });

                swal({
                    title: 'Tags Added',
                    text: 'Tags have been added to the list',
                    type: 'success'
                });
            }
        });
    
    }

    async uploadTagCsvFile(file: any, states: IReferenceListItemEditStates) {

        const fileNamePrefix = states.selectedParentTag ? _.find(states.parentTags, { id: states.selectedParentTag }).tag : states.referenceDataList.name;

        swal({
            title: 'List Tagging Updates',
            text: 'Reason for change?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#57C84D',
            confirmButtonText: 'Update',
            cancelButtonText: 'Cancel',
            input: 'text',
            inputClass: 'modal-full'
        }).then(async (isConfirm: any) => {
            if (!isConfirm.value) {
                swal({
                    title: 'Reason required',
                    text: 'Upload again and provide a reason for update.',
                    type: 'warning'});
            } else if (!file.target.files[0].name.startsWith(fileNamePrefix.replace(/[^a-z0-9]/gi, '_').toLowerCase())) {
                swal({
                    title: 'Check File or Selected Sublist',
                    text: 'It appears that the file name does not match the selected sublist. Please check the sublist and file before uploading file.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#9f9f9f',
                    confirmButtonText: 'Update Anyways',
                    cancelButtonText: 'Cancel',
                    cancelButtonColor: '#57C84D'
                }).then(async (isConfirm: any) => {
                    if (isConfirm.value) {
                        this.callTagUpload(file, states, isConfirm.value);
                    }});
            } else {
                this.callTagUpload(file, states, isConfirm.value);
            }});

    }

    callTagUpload(file: any, states: IReferenceListItemEditStates, reason: any) {
        swal({
            title: 'Uploading Tags',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: () => {
                swal.showLoading();
                (async () => {
                    try {
                        await this.uploadTags(file, states, reason);
                        swal.close();
                        await this.getReferenceDataList(states);
                        if (states.selectedRowId) {
                            await this.viewTag(_.find(states.referenceDataList.referenceDataListTags, { id: states.selectedRowId }), states);
                        }
                        this.changeDetection.detectChanges();
                    } catch (error: any) {
                        console.error('Caught an error:', error.message);
                        swal.close();
                        swal({
                            title: 'Error',
                            text: error.message,
                            type: 'warning'
                        });
                    }
                })();
            }
        });
    }

    async parseCSV(fileObj: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            let reader_string = '';
            reader.readAsText(fileObj);
            reader.onload = (e) => {
                reader_string = reader.result as string;
                const csvRaw = reader_string.split(/\r\n|\n|\r/).filter(line => !line.startsWith('"#'));
                resolve(csvRaw);
            };
            reader.onerror = function (e: any) {
                reject(e);
            };
        });
    }

    viewHistory(states: IReferenceListItemEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    listId: this.uiRouter.globals.params.id,
                    listName: states.referenceDataList.name,
                    parentTags: states.parentTags
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewReferenceListHistoryModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            this.matDialog.closeAll();
        });
    }

    async loadItems(query: any, states: IReferenceListItemEditStates) {
        // let deferred = Promise.defer();
        const filter: any = {
            filter: {
                where: {
                    listId: this.uiRouter.globals.params.id,

                },
                order: 'key'
            }
        };
        if (query && query.length > 0) {
            filter.filter.where = {
                and: [{
                    listId: this.uiRouter.globals.params.id
                }, {
                    or: [{
                        key: {
                            like: '%' + query + '%'
                        }
                    }, {
                        description: {
                            regexp: query + '/i'
                        }
                    }]
                }]
            };
        }
        const listItems = await this.dataAccess.genericFind({
            model: 'ReferenceDataListItem',
            filter: { filter }
        });
        return Promise.all(_.map(listItems, (listItem: any) => {
            return {
                id: listItem.id,
                label: listItem.key + ' (' + listItem.description + ')'
            };
        }));
    }

    async openReferenceDataSource(states: IReferenceListItemEditStates) {
        const referenceData = await this.dataAccess.genericFind({
            model: 'ReferenceData',
            filter: {
                where: {
                    refList: states.referenceDataList.name
                }
            }});

        this.state.go('argos.referenceData.referenceDataSources.edit', {id: referenceData[0].id});
    }

    sortData(states: IReferenceListItemEditStates, key: string) {
        states.sortKey = key;
        states.arrowDirection = states.arrowDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
    
        states.referenceDataListItemTags.sort((a, b) => {
            let aValue: string;
            let bValue: string;

            if (key === 'description') {
                aValue = a.referenceDataListItem.description || a.referenceDataListItem.value || '';
                bValue = b.referenceDataListItem.description || b.referenceDataListItem.value || '';
            } else {
                aValue = a.referenceDataListItem[key] || '';
                bValue = b.referenceDataListItem[key] || '';
            }
    
            if (states.arrowDirection === SortDirection.Ascending) {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
            }
        });
    }

    updatePage(states: IReferenceListItemEditStates) {
        const start = states.currentPage * states.pageSize;
        const end = start + states.pageSize;
        states.dataSource.data = states.allData.slice(start, end);
        states.treeControl.dataNodes = states.dataSource.data;
        this.changeDetection.detectChanges();
    }
    
    nextPage(states: IReferenceListItemEditStates) {
        if ((states.currentPage + 1) * states.pageSize < states.allData.length) {
            states.currentPage++;
            this.updatePage(states);
        }
    }
    
    previousPage(states: IReferenceListItemEditStates) {
        if (states.currentPage > 0) {
            states.currentPage--;
            this.updatePage(states);
        }
    }

    applyFilter(states: IReferenceListItemEditStates, clear: boolean) {
        states.currentPage = 0;
        if (!states.filterText || clear) {
            states.filterApplied = false;
            states.dataSource.data = states.allData.slice();
            states.filterText = '';
            this.updatePage(states);
        } else {
            const filteredData = states.allData.filter(node =>
                node.name.toLowerCase().includes(states.filterText.toLowerCase())
            );
            states.filterApplied = true;
            states.dataSource.data = filteredData;
        }
        
        states.treeControl.dataNodes = states.dataSource.data;
        this.changeDetection.detectChanges();
    }

    async triggerReferenceListCombined() {
        const dagId = 'reference-data-argos_sql-pipeline';
        const triggeredByUser = await this.argosStore.getItem('username').replace('@clarifyhealth.com', '');
        
        const astronomerResponse = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'triggerDag',
            parameters: {
                dagId, 
                dagConfig: JSON.stringify({
                    force_execution: false,
                    ref_data_sources_to_run: ['reference_list_combined']
                }),
                triggeredByUser,
            }
        });

        const dagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });

        const tablesToUpdate = await this.dataAccess.genericFind({
            model: 'ReferenceData',
            filter: {
                where: {
                    tableName: 'reference_list_combined'
                }
            }
        });
        const tableToUpdate = tablesToUpdate[0];

        const airflowUrl = `${dagsUrl.data}/${dagId}/grid?dag_run_id=${encodeURIComponent(astronomerResponse.data.dag_run_id)}`;
        
        tableToUpdate.pipelineStartTm = Date.now();
        tableToUpdate.airflowUrl = airflowUrl;
        tableToUpdate.pipelineIsPromotion = false;
        
        await this.dataAccess.genericUpsert({
            model: 'ReferenceData',
            data: tableToUpdate
        });
  

        swal({
            title: 'Pipeline Started Successfully',
            text: 'Would you like to go to Airflow to view the running pipeline?',
            type: 'success',
            showCancelButton: true,
            confirmButtonColor: '#57C84D',
            confirmButtonText: 'Go to Airflow',
            cancelButtonText: 'Back to Edit'
        }).then((thirdConfirm: any) => {
            if (thirdConfirm.value) {
                window.open(airflowUrl, '_blank');
            }
        });
    }
}

async function processData(csvData: any) {
    try {
        const jsonArray = await csv({
            noheader: true,
            output: 'csv'
        }).fromString(csvData);

        if (jsonArray.length === 1) {
            // Horizontal data, flat single row
            return jsonArray[0];
        } else if (jsonArray.every((row: any) => row.length === 1)) {
            // Vertical data, each row is a single value
            return jsonArray.map((row: any) => row[0]);
        } else {
            throw new Error('Items must be uploaded in a single column or row format.');
        }
    } catch (error: any) {
        console.error('Failed to parse CSV:', error);
        swal({
            title: 'Error',
            text: error.message,
            type: 'warning'
        });
    }
}
