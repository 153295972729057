import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { ITeraUsersListStates } from './teraUsersList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { TeraUsersListService } from './teraUsersList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-teraUsersList',
    templateUrl: './teraUsersList.component.html',
    providers: [TeraUsersListService]
})

export class TeraUsersListComponent extends ReactComponentBase<{}, ITeraUsersListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: TeraUsersListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            teraUsers: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['ownerName', 'ownerTeam', 'ownerJiraProject', 'ownerEmail', 'slackUserId'],
            matTableColumns: ['ownerName', 'ownerTeam', 'ownerJiraProject', 'ownerEmail', 'slackUserId'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'teraUsersList'
        } as unknown as ITeraUsersListStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    editTeraUser(id: any) {
        this.svc.editTeraUser(id, this.states);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }
}
