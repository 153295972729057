import { Injectable } from '@angular/core';
import { Resource, ResourceParams, ResourceAction, ResourceRequestMethod, IResourceMethodPromise, ResourceActionReturnType } from '@ngx-resource/core';
import { ResourceHandlerHttpClient } from '@ngx-resource/handler-ngx-http';

@ResourceParams({
    pathPrefix: '/api'
})

@Injectable()
export class NgDataAccess extends Resource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/DataAccess/{!model}/find',
        expectJsonArray: true,
        returnAs: ResourceActionReturnType.Promise
    }) genericFind: IResourceMethodPromise<{ model: string, filter?: any, limit?: number, order?: any }, any>;

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/DataAccess/upsert',
        returnAs: ResourceActionReturnType.Promise
    }) genericUpsert: IResourceMethodPromise<{ model: string, data: any }, any>;

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/DataAccess/createMany',
        expectJsonArray: true,
        returnAs: ResourceActionReturnType.Promise
    }) genericCreateMany: IResourceMethodPromise<{ model: string, data: any }, any>;

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/DataAccess/create',
        returnAs: ResourceActionReturnType.Promise
    }) genericCreate: IResourceMethodPromise<{ model: string, data: any }, any>;

    @ResourceAction({
        path: '/DataAccess/{!model}/count',
        expectJsonArray: true,
        returnAs: ResourceActionReturnType.Promise
    }) genericCount: IResourceMethodPromise<{ model: string, where?: any }, any>;

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/DataAccess/method',
        returnAs: ResourceActionReturnType.Promise
    }) genericMethod: IResourceMethodPromise<{ model: string, method: string, parameters?: any }, any>;

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/DataAccess/method',
        expectJsonArray: true,
        returnAs: ResourceActionReturnType.Promise
    }) genericArrayMethod: IResourceMethodPromise<{ model: string, method: string, parameters?: any }, any>;

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/DataAccess/delete/{!id}',
        returnAs: ResourceActionReturnType.Promise
    }) genericDelete: IResourceMethodPromise<{ model: string, id: string }, any>;

    constructor(restHandler: ResourceHandlerHttpClient) {
        super(restHandler);
    }
}
