import { Injectable } from '@angular/core';
import { IPublishReleaseNotesProps, IPublishReleaseNotesStates, IPublishReleaseNotesService } from './publishReleaseNotes.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';
import { AlertService } from 'client/app/services/alert.service';
@Injectable()
export class PublishReleaseNotesService implements IPublishReleaseNotesService {
    constructor(private dataAccess: NgDataAccess, private state: StateService,
        private alertSvc: AlertService) {
        //
    }
    async initDelegate(props: IPublishReleaseNotesProps, states: IPublishReleaseNotesStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async changeDelegate(oldProps: IPublishReleaseNotesProps, newProps: IPublishReleaseNotesProps, states: IPublishReleaseNotesStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async init(states: IPublishReleaseNotesStates) {
        const releases = await this.dataAccess.genericFind({
            model: 'Release',
            filter: {
                where: {
                    product: states.product
                },
                order: 'releaseDate desc',
                limit: 1
            }
        });
        if (releases && releases.length === 1) {
            states.fromBuild = releases[0].buildNumber + 1;
            this.refreshReleaseNotes(states);
        }
    }

    momentFromNow(date: any, states: IPublishReleaseNotesStates) {
        return moment(new Date(date)).fromNow();
    }

    async fetchReleaseNotes(states: IPublishReleaseNotesStates) {
        states.queryRunning = true;
        states.toBuild = parseInt(states.toBuild) || 0;
        await this.refreshReleaseNotes(states);
    }

    async refreshReleaseNotes(states: IPublishReleaseNotesStates) {
        try {
            states.queryRunning = true;
            const releaseNotes = await this.dataAccess.genericMethod({
                model: 'Release', method: 'getReleaseNotes', parameters: {
                    project: states.product,
                    fromBuild: states.fromBuild,
                    toBuild: states.toBuild ? states.toBuild : 0
                }
            });
            if (releaseNotes.length > 0) {
                states.releaseNotes = releaseNotes;

                states.buildNumber = states.fromBuild;
                states.releaseNotes.forEach(function (releaseNote) {
                    if (releaseNote.releaseNote) {
                        releaseNote.include = true;
                    }
                    if (releaseNote.fixedInBuild > states.buildNumber) {
                        states.buildNumber = releaseNote.fixedInBuild;
                    }
                });

                states.releaseNotesTable = _.orderBy(releaseNotes, ['id']);

                states.releaseName = states.product + ' - ' + moment(states.releaseDate).format('MM/DD/YYYY') + ' - Build ' + states.buildNumber;
            } else {
                states.noData = 'No Release Notes to display';
            }
            states.queryRunning = false;
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    async publishReleaseNotes(states: IPublishReleaseNotesStates) {
        const promiseList: any = [];
        const release: any = await this.dataAccess.genericUpsert({
            model: 'Release',
            data: {
                releaseName: states.releaseName,
                releaseDate: states.releaseDate,
                product: states.product,
                buildNumber: states.buildNumber
            }
        });
        states.releaseNotes.forEach(async (releaseNote) => {
            if (releaseNote.include) {
                promiseList.push(this.dataAccess.genericUpsert({
                    model: 'ReleaseNote',
                    data: {
                        releaseId: release.id,
                        releaseNote: releaseNote.releaseNote,
                        jiraItem: releaseNote.id,
                        itemType: releaseNote.type,
                        customerPublish: releaseNote.publish,
                        buildNumber: releaseNote.fixedInBuild,
                        summary: releaseNote.summary
                    }
                }));
            }
        });
        await Promise.all(promiseList).then(() => {
            this.state.go('argos.prism.releases');
        }).catch((error: any) => {
            this.alertSvc.handleError(error);
        });
    }
}
