import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IPayerRatesMetaEditStates } from './payerRatesMetaEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PayerRatesMetaEditService } from './payerRatesMetaEdit.component.service';

@Component({
    selector: 'argos-payerRatesMetaEdit',
    templateUrl: './payerRatesMetaEdit.component.html',
    providers: [PayerRatesMetaEditService]
})

export class PayerRatesMetaEditComponent extends ReactComponentBase<{}, IPayerRatesMetaEditStates, {}> implements OnInit {
    constructor(private svc: PayerRatesMetaEditService, private cdr: ChangeDetectorRef) {
        super({
            payerRatesMeta: {
                data_source: ''
            },
            saveInProgress: false
        } as IPayerRatesMetaEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.svc.save(this.states);
    }

    viewHistory() {
        this.svc.viewHistory(this.states);
    }
}
