import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
    selector: 'argos-view-data-provisioning-questionnaire-modal',
    templateUrl: './viewDataProvisioningQuestionnaireModal.component.html'
})

export class ViewDataProvisioningQuestionnaireModalComponent {

    response: any = {};
    fields = [
        'Full Name',
        'Email Address',
        'Title',
        'Organization',
        'Is Payvider?',
        'Is Payor Entity a Separate Legal Entity?',
        'Payvider Name',
        'Payvider\'s Security Officer Name',
        'Payvider\'s Security Officer Title',
        'Describe Payviders Separation Controls',
        'Describe privacy and security training',
        'Describe access controls for Provider users',
        'Confirm Payvider Separation',
        'Describe storage separation controls',
        'Date/Time Submitted'
    ];

    constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) dialogData: any) {
        this.response = dialogData?.response;
    }

    close() {
        this.dialogRef.close();
    }
}
