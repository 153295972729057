import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IEcsAppListStates, IClientProductConfiguration } from '../ecsAppList.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import { UtilsService } from '../../../../services/utils.service';
import { EcsAppPatch } from '../../../../components/ecs/ecsAppEdit/handler/ecsAppPatchHandler.service';
import { UrlService } from '../../../../services/url.service';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import * as moment from 'moment';
@Injectable()
export class EcsAppListShare {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess, 
        private ecsAppPatchHandler: EcsAppPatch, private utils: UtilsService, private urlService: UrlService) {  
        //
    }
/*
    postLoadEcsClusterData(states: IEcsAppListStates) {
        _.filter(data.clusters, { accountName: awsAccount }).forEach((ecsCluster: any) => { 

        });
    }
*/
    // load environments that have matching selected aws account
    async loadEnvClusterObjects(states: IEcsAppListStates) {

        let envObjs: any[] = states.environmentDetails;
        let enableProducts: IClientProductConfiguration[] = [];
        states.argosClientProductConfiguration = await this.dataAccess.genericFind({
            model: 'ClientProductConfiguration'
        });
        
        if (states.selectedAwsAccount !== 'all') {
            envObjs = _.filter(states.environmentDetails, { hostingAccount: states.selectedAwsAccount }); // only load envrionments with known matching account
        }

        envObjs.forEach((env: any) => {
            const clusterObj: any = {
                clusterName: env.hostingAppName,
                friendlyName: env.hostingAppName,
                accountName: env.hostingAccount,    //states.selectedAwsAccount,
                clientId: env.clientId,
                environmentId: env.id,
                environmentName: env.name,
                url: env.dnsCname + '.clarifyhealth.' + env.domainSuffix,
                environmentType: env.environmentType,
                applicationType: env.applicationType,
                uiVersion: 'Loading...',
                hasPrismPatch: false,
                isPrismApp: (env.applicationType === 'prism'),
                bulkSelected: false,
                isSiteDisabled: env.isDisabled,
                isExcludedFromReleaseCycle: false,
                isFailoverEnvironment: false,
                isArgosSyncing: (env.hostingAccount === 'pm2' ? false : true),
                salesforceAccountName: env.salesforceAccountName,
                createdAt: this.formatDate(env.createdAt),
                ecrRepoName: env.ecsParameters.ecrRepoName,
                upTimeAlarms: [],
                ec2Info: [],
                showAppEditLink: 'ecsEdit'   //assume ecs edit is allowed so users dont need to wait for loading
            };

            //set some parameters for pm2 specific rows
            if (clusterObj.accountName === 'pm2') {
              //for pm2 specific papertrail
              var re = /_/gi; 
              clusterObj.clusterName = clusterObj.environmentName.replace(re, '-');
              //for clustername in html
              clusterObj.friendlyName = clusterObj.environmentName;
            }

            // set properties from argos environment ECS Configurations
            if (env.ecsParameters.isExcludedFromReleaseCycle && (env.ecsParameters.isExcludedFromReleaseCycle === 'true' || env.ecsParameters.isExcludedFromReleaseCycle === true)) {
                clusterObj.isExcludedFromReleaseCycle = true;
            }

            // if isFailoverEnvironment is set its a backup ecs app to the primary so we do not need to run upgrade because
            // the db is a active clone of the master
            if (env.ecsParameters.isFailoverEnvironment && env.ecsParameters.isFailoverEnvironment === 'true') {
                clusterObj.isFailoverEnvironment = true;
            }

            if (clusterObj.isPrismApp || clusterObj.applicationType === 'argos') {
                this.getEnvironmentBuildNumbers(clusterObj, states);
            } else {
                clusterObj.uiVersion = 'NA';
            }

            enableProducts = _.orderBy(_.filter(states.argosClientProductConfiguration, { clientId: env.clientId }), ['productConfiguration'], ['asc']);
            
            if (enableProducts?.length > 0) {
                clusterObj.argosClientProductConfiguration = enableProducts;
                clusterObj.argosClientProductConfigurationFilter = _.uniq(_.map(enableProducts, 'productConfiguration'));
                clusterObj.argosClientProductConfigurationStatus = 'Argos Client Product Configured';
                _.forEach(enableProducts, (products: IClientProductConfiguration) => {
                    if (products.productConfiguration) {
                        clusterObj.argosClientProductConfigurationStatus += '\n' + products.productConfiguration;
                    }
                });
            } else {
                clusterObj.argosClientProductConfigurationFilter = [];
                clusterObj.argosClientProductConfigurationStatus = 'No Argos Client Product Configured';
            }

            const ccg = _.orderBy((_.filter(states.argosClientCareGroupings, function (c) {
                if (c.clientId === env.clientId && c.databaseName === env.dbName) {
                    return c;
                }
            })), ['careGrouping'], ['asc']);
            clusterObj.ccgEnabled = false;

            if (ccg && ccg.length > 0) {
                clusterObj.argosClientCareGroupings = ccg;
                clusterObj.argosClientCareGroupingsFilter = _.uniq(_.map(ccg, 'careGrouping'));
                clusterObj.argosClientCareGroupingsStatus = 'Argos Client Care Groupings Configured';
                clusterObj.ccgEnabled = true;
                ccg.forEach(function (c) {

                    if (c.careGrouping) {
                        clusterObj.argosClientCareGroupingsStatus += '\n' + c.careGrouping;

                        if (c.careGrouping.startsWith('referrals')) {
                            clusterObj.argosClientCareGroupingsHasReferral = true;
                        }

                        if (c.overrides.pii && c.overrides.pii.allow) {
                            clusterObj.argosClientCareGroupingsHasPatientPiiEnabled = true;
                        }

                        if (c.careGrouping.startsWith('clarifyAttributedProvider')) {
                            clusterObj.argosClientCareGroupingsHasCap = true;
                        }
                    }
                });
            } else {
                clusterObj.argosClientCareGroupingsFilter = [];
                clusterObj.argosClientCareGroupingsStatus = 'No Argos Client Care Groupings Configured';
            }

            states.clusters.push(clusterObj);
        }); // end for

        // recalculate totals
        states.environmentTypeCount = _.countBy(states.clusters, 'environmentType');
        states.clusterTable.data = _.orderBy(states.clusters, ['url']);

        const queryString = this.urlService.getQueryStringFromUrl();
        if (queryString) {
            states.clusterTable.filter = queryString;
            states.filterObj = JSON.parse(queryString);
        }
        states.cdr.detectChanges();
    }

    loadEcsClusterObjects(ecsData: any, states: IEcsAppListStates) {
        const ec2Count: any = [];

        for (let i = 0; ecsData.clusters.length > i; i++) {
            const cObj = ecsData.clusters[i];

            if (cObj.containers && cObj.containers.length > 0) {
                cObj.selectedContainer = cObj.containers[0];
            }

            this.setInstanceHealthState(cObj);
            this.setClusterServerInfo(cObj, states);
            this.setClusterMetricInfo(cObj);

            cObj.friendlyName = cObj.clusterName;
            cObj.tags.forEach(function (t: any) {
                if (t.key === 'name') {
                    cObj.friendlyName = t.value;
                }
            });
        }

        // if any clusters failed to load expected associated data then push it to our issues array
        ecsData.failedToLoad.forEach(function (cfl: any) {
            states.clusterEnvironmentIssues.push({
                clusterName: cfl.clusterName,
                type: 'Failed to load information',
                description: cfl.error
            });
        });

        // match up env with ecs clusters and account
        // anything not matching then push the ecs into the cluster list
        ecsData.clusters.forEach((ecsCluster: any) => {
            let envCluster = _.find(states.clusters, c => (c.friendlyName === ecsCluster.clusterName && c.accountName === ecsCluster.accountName));

            if (envCluster) {
                // found a existing match env and ecs object
                envCluster.argosSyncError = false;
                envCluster.isArgosSyncing = false;
                envCluster.showAppEditLink = 'ecsEdit';
                envCluster = Object.assign(envCluster, ecsCluster); // update cluster

            } else {
                ecsCluster.argosSyncError = true;
                ecsCluster.isArgosSyncing = false;
                const unknownEnv = _.find(states.environmentDetails, { hostingAppName: ecsCluster.clusterName });

                if (unknownEnv) {
                    // found a existing match env and ecs object but in the wrong hosting account
                    ecsCluster = Object.assign(unknownEnv, ecsCluster); // update cluster
                    ecsCluster.showAppEditLink = 'envEdit';
                    ecsCluster.argosSyncErrorMessage = `Argos and ECS AWS Account properties do not match: ECS ${ecsCluster.accountName} vs Argos ${envCluster.hostingAccount}`;
                    states.clusterEnvironmentIssues.push({
                        clusterName: ecsCluster.clusterName,
                        type: 'Mismatch Argos environment setting',
                        description: ecsCluster.argosSyncErrorMessage
                    });
                    states.clusters.push(ecsCluster);
                } else {
                    // no environment matching cluster found
                    ecsCluster.url = 'NA';
                    ecsCluster.environmentType = 'NA';
                    ecsCluster.showAppEditLink = 'awsEdit';
                    ecsCluster.argosSyncErrorMessage = `No matching argos environment found for ECS ${ecsCluster.clusterName}`;
                    states.clusterEnvironmentIssues.push({
                        clusterName: ecsCluster.clusterName,
                        type: 'No matching Argos environment',
                        description: ecsCluster.argosSyncErrorMessage
                    });
                    states.clusters.push(ecsCluster);
                }
            }
        });

        states.clusterTable.data = _.orderBy(states.clusters, ['url']); // with possible new entries reload entire list
        return _.uniq(_.map(ecsData.clusters, 'clusterName'));
    }

    loadEcsStats(states: IEcsAppListStates) {

        let ec2Count: any = [];
        // reset these values
        states.environmentTypeCount = {};
        states.environmentEc2TypeCount = {};

        states.clusters.forEach((c) => {
            c.ec2Info.forEach((e: any) => {
                ec2Count = ec2Count.concat(e);
            });
        });

        ec2Count = _.orderBy(ec2Count, ['ec2InstanceType']);
        states.environmentTypeCount = _.countBy(states.clusters, 'environmentType');
        states.environmentEc2TypeCount = _.countBy(ec2Count, 'ec2InstanceType');
    }

    setClusterMetricInfo(clusterObj: any) {
        if (clusterObj.metricData.data) {
            clusterObj.metricData.data.forEach((d: any) => {
                if (d.Label === 'MemoryUtilization') {
                    if (d.serviceName.endsWith(clusterObj.workerServiceName)) {
                        //
                    } else if (d.serviceName.endsWith(clusterObj.webServiceName)) {

                        clusterObj.currentMetricMemory = d.Datapoints.sort((a: any, b: any) => {
                            const memoryResult = new Date(a.Timestamp) > new Date(b.Timestamp) ? a : b;
                            return memoryResult;
                        })[0];

                        if (clusterObj.currentMetricMemory) {
                            clusterObj.currentMetricMemory.Average = parseInt(clusterObj.currentMetricMemory.Average);
                        }

                    }
                } else if (d.Label === 'CPUUtilization') {
                    if (d.serviceName.endsWith(clusterObj.workerServiceName)) {
                        //
                    } else if (d.serviceName.endsWith(clusterObj.webServiceName)) {
                        clusterObj.currentMetricCpu = d.Datapoints.sort(function (a: any, b: any) {
                            const cpuResult = new Date(a.Timestamp) > new Date(b.Timestamp) ? a : b;
                            return cpuResult;
                        })[0];
                        if (clusterObj.currentMetricCpu) {
                            clusterObj.currentMetricCpu.Average = parseInt(clusterObj.currentMetricCpu.Average);
                        }
                    }
                }
            });
        }
    }

    setClusterServerInfo(clusterObj: any, states: IEcsAppListStates) {
        if (clusterObj.ec2Info && clusterObj.ec2Info.length > 0) {
            clusterObj.containerService = clusterObj.ec2Info[0].ec2InstanceType;
            clusterObj.containerServiceId = clusterObj.ec2Info[0].ec2InstanceId;

            if (clusterObj.ec2Info.length > 1) {
                states.clusterEnvironmentIssues.push({
                    clusterName: clusterObj.clusterName,
                    type: 'multiple ec2 instances',
                    description: clusterObj.ec2Info.length + ' EC2 servers are up. Either a machine swap is happening or something is misconfigured. This will impact deploy until only 1 ec2 instance exists'
                });
            }

            clusterObj.ec2Info.forEach((containerObj: any) => {
                if (!containerObj.agentConnected) {
                    states.clusterEnvironmentIssues.push({
                        clusterName: clusterObj.clusterName,
                        type: 'aws agent not connected'
                        // description: clusterObj.clusterName + ' Container Agent is not connected. This means if a web or worker dies auto recovery will fail. Restart the Ec2 instance or change the EC2 instance size to force a agent restart.'
                    });
                }
            });
        } else if (clusterObj.isFargateEnabled) {
            clusterObj.containerService = 'Fargate Managed';
        }
    }

    setInstanceHealthState(clusterObj: any) {
        const instanceHealthCounts = {
            healthyCount: 0,
            drainingCount: 0,
            unhealthyCount: 0
        };

        clusterObj.targetInstancesHealth.forEach(function (target: any) {
            if (target.TargetHealth.State === 'healthy') {
                instanceHealthCounts.healthyCount++;
            } else if (target.TargetHealth.State === 'draining') {
                instanceHealthCounts.drainingCount++;
            } else if (target.TargetHealth.State === 'unhealthy' || target.TargetHealth.State === 'unavailable') {
                instanceHealthCounts.unhealthyCount++;
            }
        });

        clusterObj.instanceHealthStateStats = 'healthy: ' + instanceHealthCounts.healthyCount + '\ndraining: ' + instanceHealthCounts.drainingCount + '\nunhealthy: ' + instanceHealthCounts.unhealthyCount;

        // cover when we have at least one healthy instance
        if (instanceHealthCounts.healthyCount > 0) {
            if (instanceHealthCounts.unhealthyCount > 0) {
                clusterObj.instanceHealthState = 'warning';
            } else if (instanceHealthCounts.drainingCount > 0) {
                clusterObj.instanceHealthState = 'draining';
            } else {
                clusterObj.instanceHealthState = 'healthy';
            }
            // we have no healthy instances
        } else if (instanceHealthCounts.healthyCount + instanceHealthCounts.drainingCount + instanceHealthCounts.unhealthyCount === 0) {
            clusterObj.instanceHealthState = '';
            clusterObj.instanceHealthStateStats = '';
        } else {
            clusterObj.instanceHealthState = 'down';
        }
    }

    formatDate(dateValue: any) {

        const d = new Date(dateValue);
        const month = d.getUTCMonth() + 1;

        return d.getFullYear() + '/' + month + '/' + d.getDate();
    }

    async getEnvironmentBuildNumbers(clusterObj: any, states: IEcsAppListStates) {

        clusterObj.dbVersion = 'NA';
        clusterObj.uiVersion = 'NA';

        if (clusterObj.isSiteDisabled === true) {
            return;
        }
        try {
            const version = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getAppVersion',
                parameters: {
                    id: clusterObj.environmentId
                }
            });
            if (!version.installedVersions) {
                return;
            }
            clusterObj.uiVersion = version.localVersion || version.version;

            if (clusterObj.uiVersion) {
                clusterObj.uiVersion = clusterObj.uiVersion.toString().trim();

                // save the original ui version
                if (!clusterObj.originalUiVersion) {
                    clusterObj.originalUiVersion = clusterObj.uiVersion;
                }
            }

            if (version.lastLogin) {
                clusterObj.lastLoginDate = new Date((version.lastLogin && version.lastLogin.length > 0) ? version.lastLogin : null);
                clusterObj.lastLoginDaysAgo = moment().diff(new Date(clusterObj.lastLoginDate), 'days');
                clusterObj.lastLoginDate = moment(clusterObj.lastLoginDate).format('MM/DD/YYYY');
            }

            version.installedVersions.forEach(function (iv: any) {
                if (iv.settingKey && iv.settingKey === 'CarePrismFunctionsAndViews') {
                    clusterObj.dbVersion = iv.settingValue.toString().trim();
                    const environemntIssue = _.find(states.clusterEnvironmentIssues, { clusterName: clusterObj.clusterName });

                    if (clusterObj.dbVersion !== clusterObj.uiVersion) {

                        if (environemntIssue) {
                            environemntIssue.description = clusterObj.uiVersion + ' (UI) ' + clusterObj.dbVersion + ' (DB) does not equal';
                        } else {
                            states.clusterEnvironmentIssues.push({
                                clusterName: clusterObj.clusterName,
                                type: 'UI and DB mismatch',
                                description: clusterObj.uiVersion + ' (UI) ' + clusterObj.dbVersion + ' (DB) does not equal'
                            });
                        }
                    } else {
                        // they match so remove
                        states.clusterEnvironmentIssues = _.reject(states.clusterEnvironmentIssues, function (i) { return (i.clusterName === clusterObj.clusterName && i.type === 'UI and DB mismatch'); });
                    }
                } else if (iv.settingKey && iv.settingKey === 'PatchesApplied') {
                    clusterObj.appliedPatches = iv.settingValue === '0' ? [] : JSON.parse(iv.settingValue);
                    clusterObj.originalAppliedPatches = clusterObj.appliedPatches;
                    clusterObj.appliedPatchesFriendlyName = _.map(clusterObj.appliedPatches, 'name').join(', ');
                    if (clusterObj.appliedPatches.length > 0) {
                        clusterObj.hasPrismPatch = true;
                    }
                }
            });

            states.cdr.detectChanges();
        } catch (error) {
            states.clusterEnvironmentIssues.push({
                clusterName: clusterObj.clusterName,
                type: 'UI and DB mismatch',
                description: 'getAppVersion request failed. Is the site up?'
            });
        }
    }

     setClusterRunUpgradeState(cluster: any, states: IEcsAppListStates) {
        let result = true;  //default to always run upgrade task

        if (cluster.isFailoverEnvironment) {
            result = false
        } else if (states.selectedPromoteType === 'patch') {
            result = true;
        } else if (cluster.ecrBuildImage === states.selectedBuildTag) {
            result = states.runUpgradeTaskWhereBuildIsUnchanged;
        }

        return result;
    }
/*
    getClusterBuildTag(containerTaskDefinitions: any, clusterName: string) {
        let result;
        let containerObj = this.ecsAppTaskHandler.getTaskDefContainerDefintion(containerTaskDefinitions, clusterName);

        if (!_.isEmpty(containerObj) && containerObj.containersFiltered && containerObj.definition) { 
            let repoStr = containerObj.containersFiltered.image;

            const repoInfo = repoStr.split('/')[1].split(':');
            if (repoInfo[1]) {
                result = repoInfo[1];
            }
        }

        return result;
    }
*/
    async promoteBuildToClusters(states: IEcsAppListStates) {

        let promises: any[] = [];
        const chunk = 5;

        if (states.disablePromotingQueue === false) {
            this.moveQueuedToPromoteClusters(states);
        }
        // AWS has throttling limits we need to make api request in chunks
        for (let k = 0; k < states.clusterToPromote.length; k += chunk) {
            promises = [];  //clear the promises array
            const clusterChunkToPromote = states.clusterToPromote.slice(k, k + chunk);

            for (let i = 0; clusterChunkToPromote.length > i; i++) {
                const cluster = clusterChunkToPromote[i];

                if (cluster.promoteStatus !== 'ready to promote') {
                    continue;   // skip this k position because this cluster not yet ready or is already been promoted
                }

                cluster.promoteStatus = 'promoting';
                cluster.promoteDate = new Date();
                cluster.promoteCheckDate = cluster.promoteDate;
                cluster.promoteErrors = [];
                cluster.runUpgradeTaskDef = this.setClusterRunUpgradeState(cluster, states);    
 
                const servNames = (_.filter(cluster.services, function (service) {
                    if (service === cluster.clusterName + '-service' || service === cluster.clusterName + '-worker-service') {
                        return service;
                    }
                }));

                if (servNames && servNames.length > 0 && cluster.selectedTaskDefinition) {
                    const args: any = this.getPromoteBuildToClusters(cluster,
                        cluster.webServiceName,
                        cluster.selectedTaskDefinition,
                        cluster.webTaskName,
                        cluster.selectedTaskDefTags,
                        states);

                    promises.push(this.dataAccess.genericMethod({ model: 'Environment', method: 'createEcsTaskDefinition', parameters: args }));

                    // now create one for worker if it exists
                    if (cluster.workerServiceName && cluster.workerTaskName && cluster.selectedWorkerTaskDefinition) {
                        const wrkerArgs = this.getPromoteBuildToClusters(cluster,
                            cluster.workerServiceName,
                            cluster.selectedWorkerTaskDefinition,
                            cluster.workerTaskName,
                            cluster.selectedWorkerTaskDefTags,
                            states);

                        promises.push(this.dataAccess.genericMethod({ model: 'Environment', method: 'createEcsTaskDefinition', parameters: wrkerArgs }));
                    }

                    //deploy upgrade task if needed
                    if (cluster.upgradeTaskName && cluster.selectedUpgradeTaskDefinition && cluster.selectedUpgradeTaskDefinition.containerDefinitions && cluster.runUpgradeTaskDef === true) {

                        const upgradeTaskArg: any = {
                            clusterName: cluster.clusterName,
                            taskDefinitionName: cluster.upgradeTaskName,
                            taskDefinition: cluster.selectedUpgradeTaskDefinition.containerDefinitions,
                            awsAccountName: cluster.accountName,
                            requestedBy: this.argosStore.getItem('username'),
                            executionRoleArn: cluster.selectedUpgradeTaskDefinition.executionRoleArn,
                            taskRoleArn: cluster.selectedUpgradeTaskDefinition.taskRoleArn
                        };

                        if (cluster.buildToPromote) {
                            upgradeTaskArg.buildTag = cluster.buildToPromote;
                        } else if (cluster.dataPatches) {
                            upgradeTaskArg.dataPatches = cluster.dataPatches;
                        }

                        promises.push(this.dataAccess.genericMethod({ model: 'Environment', method: 'runEcsTaskDefinition', parameters: upgradeTaskArg }));

                    } else {
                        let skipUpgradeMsg = 'upgrade skipped ' + cluster.upgradeTaskName;
                        if (cluster.isFailoverEnvironment) {
                            skipUpgradeMsg += '. This Failover environment will assume the master has pushed the same build and the db changes have replicated already.'
                        }
                        this.appendedToCheckLog(cluster, skipUpgradeMsg, states);
                    }

                    if (_.size(cluster.dataPatches)) {
                        this.upsertToPatchEnvTable(cluster, states);
                    }

                } else {
                    cluster.promoteStatus = 'failed';
                    cluster.promoteStatusMessage = 'unable to push changes because multiple services found or web task def not found';
                }
            }   // for clusterChunkToPromote

            if (promises.length > 0) {
                await Promise.all(promises).then((responses) => {
                    responses.forEach((promoteResponse: any) => {
                        const matchedCluster = (_.find(clusterChunkToPromote, (c) => {
                            if (c.clusterName === promoteResponse.clusterName) {
                                return c;
                            }
                        }));

                        // make sure we have a valid match across all promises called above i.e web, worker, upgrade tasks
                        if (matchedCluster) {
                            if (promoteResponse.isSuccessful) {
                                matchedCluster.promoteStatus = 'submitted';
                                const successMsg = 'build pushed to task def ' + promoteResponse.taskDefinitionName + ' with revision number ' + promoteResponse.taskDefRevisionNumber;
                                this.appendedToCheckLog(matchedCluster, successMsg, states);
                            } else {
                                matchedCluster.promoteStatus = 'failed';
                                matchedCluster.promoteStopDate = new Date();
                                const errorMsg = 'failed to push build to task def ' + promoteResponse.taskDefinitionName + ' with error: ' + JSON.stringify(promoteResponse.error);
                                this.appendedToCheckLog(matchedCluster, errorMsg, states);
                                matchedCluster.promoteStatusMessage = JSON.stringify(promoteResponse.error);
                            }
                        } else {
                            console.log('unable to find matching cluster for ' + promoteResponse.clusterName + ' during build promotion');
                        }

                    }); // foreach
                }); // $q.all

                //wait a random amount of time range before making next api call per chunk
                let maxWaitMilliSeconds = states.envManagement.ecsImageDeployMaxDelaySeconds ? (states.envManagement.ecsImageDeployMaxDelaySeconds * 1000) : 2000;
                let milliSec = this.utils.getRandomInt(1000, maxWaitMilliSeconds);
                await this.utils.sleep(milliSec);
            }   // if
        }   // for chunk
    }

    async upsertToPatchEnvTable(cluster: any, states: IEcsAppListStates) {
        states.selectedPatches.forEach(async (p: any) => {
            const x = await this.dataAccess.genericUpsert({
                model: 'EnvironmentPatch',
                data: { environmentId: cluster.environmentId, patchId: p.id }
            });
        });
    }

    getPromoteBuildToClusters(cluster: any,
        serviceName: any,
        taskDefinition: any,
        taskDefinitionName: any, 
        resourceTags: any[],
        states: IEcsAppListStates) {

        const args: any = {
            clusterName: cluster.clusterName,
            serviceName,
            taskDefinition: taskDefinition.containerDefinitions,
            taskDefinitionName,
            resourceTags,
            requestedBy: this.argosStore.getItem('username'),
            awsAccountName: cluster.accountName,
            executionRoleArn: taskDefinition.executionRoleArn,
            taskRoleArn: taskDefinition.taskRoleArn
        };

        if (cluster.buildToPromote) {
            args.buildTag = cluster.buildToPromote;
        }

        if (taskDefinition.taskDefinitionArn && taskDefinition.taskDefinitionArn.split(':').length > 0) {
            const taskDefArray = taskDefinition.taskDefinitionArn.split(':');
            args.rollBackTaskDefRevisionNumber = taskDefArray[taskDefArray.length - 1];
        }

        return args;
    }

    moveQueuedToPromoteClusters(states: IEcsAppListStates) {

        // this limits the total number of active promotions at one moment
        const totalInPromoteAllowed = states.envManagement.ecsImageDeployBulkMaxInPromoteAllowed;
        let activePromotions = 0;
        const stillInQueue = (_.filter(states.clusterToPromote, function (c) {
            if (c.promoteStatus === 'queued') {
                return c;
            }
        }));

        const beingPromoted = (_.filter(states.clusterToPromote, function (c) {
            if (c.promoteStatus === 'promoting') {
                return c;
            }
        }));

        const beingSubmitted = (_.filter(states.clusterToPromote, function (c) {
            if (c.promoteStatus === 'submitted') {
                return c;
            }
        }));

        if (beingPromoted) {
            activePromotions = activePromotions + beingPromoted.length;
        }

        if (beingSubmitted) {
            activePromotions = activePromotions + beingSubmitted.length;
        }

        // we still have task that are in queue to promote
        if (stillInQueue.length > 0 && activePromotions < totalInPromoteAllowed) {
            const moveToReadyToPromoteCount = totalInPromoteAllowed - beingPromoted.length;

            let movedCount = 0;
            for (let i = 0; states.clusterToPromote.length > i; i++) {
                const cluster = states.clusterToPromote[i];

                // move queued to ready to promote
                if (cluster.promoteStatus === 'queued') {
                    // console.log('moving ready to promote ' + cluster.clusterName);
                    cluster.promoteStatus = 'ready to promote';
                    movedCount++;
                }
                // we have hit our quote so exit loop
                if (movedCount === moveToReadyToPromoteCount) {
                    break;
                }
            }
        }

    }

    appendedToCheckLog(cluster: any, message: any, states: IEcsAppListStates) {
        if (!cluster.promoteCheckLog) {
            cluster.promoteCheckLog = '';
        }
        cluster.promoteCheckLog = cluster.promoteCheckLog + '\n' + (new Date).toTimeString() + ': ' + message;
        states.cdr.detectChanges();
    }

    runUpgradeTask(cluster: any) {
        let result = false;
        let isFailoverEnv = cluster.isFailoverEnvironment; 
        let buildChanged = cluster.uiVersion !== cluster.originalUiVersion;
        
        if (isFailoverEnv) {
            result = false;
        } else if (buildChanged) {  
            result = true;
        }

        return result;
    }

    checkPromoteUpgradeDb(states: IEcsAppListStates, cdr: ChangeDetectorRef) {

        let checkUpgradeChoiceClusters = _.filter(states.clusterToPromote, function (c) {

        });

    }

    confirmPromotion(states: IEcsAppListStates, cdr: ChangeDetectorRef) {
        swal({
            title: 'Confirm Promotion',
            text: 'Do you want to start promoting?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then((result: any) => {

            if (result.value) {
                this.startPromoteBuildToClusters(states, cdr);
            }
        });
    }

    async checkPromoteBuildToClusters(states: IEcsAppListStates, cdr: ChangeDetectorRef) {

        // this.startTimerWithInterval(states, cdr);

        if (states.selectedPromoteType === 'build') {

            let patchCheck = await this.checkPatchPromoteBuildToClusters(states);
            if (!patchCheck) {
                return;
            }

            //find any environments where the build tag will be unchanged
            let appsNotUpgrading = _.filter(states.clusterToPromote, (c)=> {
                if (c.ecrBuildImage === states.selectedBuildTag) {
                    return c;
                }
            });
            
            //give the user the option of forcing the upgrade where the build is unchanged
            if (appsNotUpgrading.length > 0) {
                swal({
                    title: `Apply Changes with the same build tag against ${appsNotUpgrading.length} of ${states.clusterToPromote.length} environments?`,
                    text: 'Would you like to Force Database Upgrades to run? This will resync all data. The default is to not run Upgrade for these environments.',
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Run DB Upgrade',
                    cancelButtonText: 'Default Behavior (Do not run DB Upgrade)'
                }).then((isConfirm) => {
                    if (isConfirm.value) {
                        //true = force upgrade
                        states.runUpgradeTaskWhereBuildIsUnchanged = true;
                        this.confirmPromotion(states, cdr);
                    } else {
                        //false = no force upgrade
                        states.runUpgradeTaskWhereBuildIsUnchanged = false;
                        this.confirmPromotion(states, cdr);
                    }
                });
            } else {
                //we have environments where the build is changing so always run upgrade
                states.runUpgradeTaskWhereBuildIsUnchanged = true;
                this.confirmPromotion(states, cdr);
            }
        } else {
            //this is a patch so upgrade will always run
            states.runUpgradeTaskWhereBuildIsUnchanged = true;
            this.confirmPromotion(states, cdr);
        }        
    }

    async checkPatchPromoteBuildToClusters(states: IEcsAppListStates) {
        let proceed = true;

        //check if we have any environments that have patches and let user decide if they want to continue
        let appsWithPatches = _.filter(states.clusterToPromote, (c)=> {
            if (c.hasPrismPatch) {
                return c;
            }
        });

        if (appsWithPatches.length > 0) {
            await swal({
                title: `Applying a build with UPGRADE will clear any existing Patches against ${appsWithPatches.length} of ${states.clusterToPromote.length} environments?`,
                text: 'Would you like to proceed?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Proceed',
                cancelButtonText: 'Cancel'
            }).then((isConfirm) => {
                if (isConfirm.value) { 
                    //proceed
                } else {
                    proceed = false;
                }
            });
        }

        return proceed;
    }

    async startPromoteBuildToClusters(states: IEcsAppListStates, cdr: ChangeDetectorRef) {
        // turn on time counter
        this.startTimerWithInterval(states, cdr);
        await this.promoteBuildToClusters(states);
        
        // turn on timer checking for code push status
        const pollSeconds = states.envManagement.ecsImageDeployPollCheckSeconds ? (states.envManagement.ecsImageDeployPollCheckSeconds * 1000) : 30000;

        states.promotePollingTimer = setInterval(() => {
            this.pollPromoteStatus(states);
        }, pollSeconds);

        states.selectedClusterTab = 1;
    }

    startTimerWithInterval(states: IEcsAppListStates, cdr: ChangeDetectorRef) {
        if (states.myInterval) {
            clearInterval(states.myInterval);
        }

        states.myInterval = setInterval(() => {
            this.onInterval(states);
            cdr.detectChanges();    // refresh ui to show counter
        }, 1000);
    }

    onInterval(states: IEcsAppListStates) {
        states.timerWithInterval++;
    }

    async refreshSystemSettings(states: IEcsAppListStates) {
        states.envManagement = await this.dataAccess.genericMethod({
            model: 'EnvironmentManagement', method: 'findOne', parameters: {
            }
        });
    }

    async getDeployStatus(clusterStatus: string[], states: IEcsAppListStates) {
        let clusterNames = _.map(_.filter(states.clusterToPromote, function (c) {   
            if (clusterStatus.includes(c.promoteStatus) && !c.isFailoverEnvironment) {
                return c;
            }
        }), 'clusterName');

        let result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsDeployStatus', parameters: {
                clusterNames: clusterNames
            }
        });

        return result;
    }

    async pollPromoteStatus(states: IEcsAppListStates) {

        let isPromotePending = false;

        await this.refreshSystemSettings(states);
        this.promoteBuildToClusters(states);
        let clusterDeployStatus = await this.getDeployStatus(['promoting', 'submitted'], states);   //get latest status for active deploys

        // loop through all ecs clusters
        states.clusterToPromote.forEach(async (clusterObj: any) => {
            if (clusterObj.promoteStatus === 'promoting' || clusterObj.promoteStatus === 'submitted') {

                isPromotePending = true;

                //fail over environments have sync'd dbs so we can skip the upgrade status check
                if (clusterObj.isFailoverEnvironment) {
                    if (clusterObj.buildToPromote === clusterObj.ecrBuildImage) {
                        //no build image change so we can skip the upgrade UI checks and just mark as completed
                        this.setPromotionCompleted(clusterObj, states);
                        this.appendedToCheckLog(clusterObj, 'deploy was successful', states);
                        this.appendedToCheckLog(clusterObj, 'skipping checks because the same image was pushed to a failover environment. ', states);
                    } else {
                        this.checkAppAndDBVersion(clusterObj, false, states);    // no upgrade is run so we just check if the build number has changed
                    }
                } else if (clusterObj.runUpgradeTaskDef === false) {
                    this.setPromotionCompleted(clusterObj, states);
                    this.appendedToCheckLog(clusterObj, 'deploy was successful', states);
                    this.appendedToCheckLog(clusterObj, 'deploy image and current build match. no patches to apply. upgrade was skipped', states);
                } else {
                    let result: any = _.find(clusterDeployStatus.clusterStatus, { clusterName: clusterObj.clusterName }); 

                    if (result && result.response && result.response.events) {
                        const response = result.response;
                        for (let j = 0; response.events.length > j; j++) {
                            const eventDetail = response.events[j];
                            const reportedLogDate = new Date(eventDetail.generated_at);

                            // look for this specific cluster and its upgrade task name
                            if (clusterObj.promoteCheckDate < reportedLogDate && clusterObj.clusterName === result.clusterName && eventDetail.program && eventDetail.program.indexOf(clusterObj.clusterName + '-upgrade') >= 0) {
                                // confirmed that this is a new event
                                const messageCheck = eventDetail.message.toLowerCase();
                                clusterObj.promoteStatusMessage = JSON.stringify(eventDetail);
                                this.validatePromotion(messageCheck, clusterObj, states);
                            }
                        }
                    } else {
                        this.appendedToCheckLog(clusterObj, 'log check failed to get a response for upgrade status', states);
                    } // getEcsDeployStatus
                }
            } else if (clusterObj.promoteStatus !== 'completed') {
                // likely in failed status so keep polling
                isPromotePending = true;
            } 
        }); // forEach

        if (!isPromotePending) {
            clearInterval(states.promotePollingTimer);
            this.stopTimerWithInterval(states);
        }
    }

    async checkAppAndDBVersion(clusterObj: any, resetPromoteStatus: any, states: IEcsAppListStates) {        
        // now get the db and ui build numbers
        try {
            const version = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getAppVersion',
                parameters: {
                    id: clusterObj.environmentId
                }
            });

            if (_.isEmpty(version)) {
                this.appendedToCheckLog(clusterObj, 'app is not responding. check if the site is up', states);
                if (resetPromoteStatus) {
                    this.resetPromoteStatus(clusterObj, states);
                }
            } else {
                clusterObj.uiVersion = version.localVersion || version.version;

                if (clusterObj.uiVersion) {
                    clusterObj.uiVersion = clusterObj.uiVersion.toString().trim();
                }

                version.installedVersions.forEach((iv: any) => {
                    if (iv.settingKey && iv.settingKey === 'CarePrismFunctionsAndViews') {
                        clusterObj.dbVersion = iv.settingValue;

                        if (clusterObj.dbVersion) {
                            clusterObj.dbVersion = clusterObj.dbVersion.toString().trim();
                        }
                    }

                    if (iv.settingKey && iv.settingKey === 'PatchesApplied') {
                        clusterObj.appliedPatches = iv.settingValue === '0' ? [] : JSON.parse(iv.settingValue);
                    }
                });

                const environmentBuildCheckMessage = 'checking environment build numbers: ' + clusterObj.uiVersion + ' (UI) ' + clusterObj.dbVersion + ' (DB) ' + ((clusterObj.dbVersion === clusterObj.uiVersion && clusterObj.uiVersion !== clusterObj.originalUiVersion) ? 'equals' : 'does not equal or has not changed from the original build number ' + clusterObj.originalUiVersion);
                this.appendedToCheckLog(clusterObj, environmentBuildCheckMessage, states);



                // make sure they match for build deploy
                if (clusterObj.buildToPromote && clusterObj.dbVersion === clusterObj.uiVersion && clusterObj.uiVersion !== clusterObj.originalUiVersion) {
                    clusterObj.promoteStatus = 'completed';

                    clusterObj.promoteStopDate = new Date();
                    clusterObj.runTimeInfo = this.getTimeDiffMessage(clusterObj.promoteDate, clusterObj.promoteStopDate);

                    this.appendedToCheckLog(clusterObj, 'deploy was successful', states);
                    this.appendedToCheckLog(clusterObj, 'deploy took ' + clusterObj.runTimeInfo.message, states);
                    await this.addUpgradeActivityLog(clusterObj, states);
                } else if (clusterObj.dataPatches && clusterObj.originalAppliedPatches !== clusterObj.appliedPatches) {
                    clusterObj.promoteStatus = 'completed';

                    clusterObj.promoteStopDate = new Date();
                    clusterObj.runTimeInfo = this.getTimeDiffMessage(clusterObj.promoteDate, clusterObj.promoteStopDate);

                    const orgAppliedPatches = _.map(clusterObj.originalAppliedPatches, 'name').join(', ');
                    const appliedPatches = _.map(clusterObj.appliedPatches, 'name').join(', ');
                    clusterObj.appliedPatchesFriendlyName = appliedPatches;

                    const envPatchCheckMessage = 'checking environment patch value: ' + appliedPatches + ((orgAppliedPatches === appliedPatches) ? ' equals' : ' has changed from the original patch ' + orgAppliedPatches);
                    this.appendedToCheckLog(clusterObj, 'patch deploy was successful', states);
                    this.appendedToCheckLog(clusterObj, envPatchCheckMessage, states);
                    this.appendedToCheckLog(clusterObj, 'deploy took ' + clusterObj.runTimeInfo.message, states);
                } else if (resetPromoteStatus) {
                    this.resetPromoteStatus(clusterObj, states);
                }
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    resetPromoteStatus(clusterObj: any, states: IEcsAppListStates) {
        clusterObj.promoteStatus = 'promoting';
        clusterObj.promoteCheckDate = new Date();  // start checking from this new point
        this.appendedToCheckLog(clusterObj, 'restarting promotion check', states);
    }

    validatePromotion(messageCheck: any, clusterObj: any, states: IEcsAppListStates) {

        if (!clusterObj.promoteCheckLog) {
            clusterObj.promoteCheckLog = '';
        }
        this.appendedToCheckLog(clusterObj, messageCheck, states);

        if (clusterObj.promoteStatus === 'failed' || clusterObj.runUpgradeTaskDef === false) {
            return; // already failed so exit
        } else if (this.isErrorMessageStatus(messageCheck, states)) {
            clusterObj.promoteStatus = 'failed';
            clusterObj.promoteStopDate = new Date();

            //only add unique errors
            if (clusterObj.promoteErrors.indexOf(messageCheck) === -1) {
                clusterObj.promoteErrors.push(messageCheck.trim());
            } 
        } else if (this.isSuccessMessage(messageCheck, clusterObj.buildToPromote)) { 
            this.setPromotionCompleted(clusterObj, states);

            if (clusterObj.isWebNotRunning === true) {
                this.appendedToCheckLog(clusterObj, 'skipping patch, image and build match validation because no active web container was detected', states);
                this.appendedToCheckLog(clusterObj, 'deploy was successful', states);
                this.appendedToCheckLog(clusterObj, 'deploy took ' + clusterObj.runTimeInfo.message, states);
                this.addUpgradeActivityLog(clusterObj, states);
            } else {
                this.checkAppAndDBVersion(clusterObj, false, states);
            }
            //this.checkAppAndDBVersion(clusterObj, false, states); 
        } else if (this.isUpgradeExited(messageCheck)) {
            // this should always happen
            if (clusterObj.promoteStatus !== 'completed') {
                
                if (clusterObj.isWebNotRunning === true) {
                    clusterObj.promoteStatus = 'failed';
                    this.appendedToCheckLog(clusterObj, 'skipping patch, image and build match validation because no active web container was detected', states);
                    this.appendedToCheckLog(clusterObj, 'Upgrade exited without a successful deploy. Please check the logs for more details.', states);
                } else {
                    this.checkAppAndDBVersion(clusterObj, false, states);   // let check update to promoteStatus completed for build and patch if possible
                }

                clusterObj.promoteStopDate = new Date();
                clusterObj.runTimeInfo = this.getTimeDiffMessage(clusterObj.promoteDate, clusterObj.promoteStopDate);
            }
        }
    }
     
    async setPromotionCompleted(clusterObj: any, states: IEcsAppListStates) {
        clusterObj.promoteStatus = 'completed';
        clusterObj.promoteStopDate = new Date();
        clusterObj.runTimeInfo = this.getTimeDiffMessage(clusterObj.promoteDate, clusterObj.promoteStopDate);
        this.saveResourceTags(clusterObj, states); // update the cluster target group and load balancers
    }

    isUpgradeSkipped(message: string) {
        let result = false;

        if (message.toLowerCase().indexOf('image and current match. upgrade will be skipped') >= 0) {
            result = true;
        } else if (message.toLowerCase().indexOf('image and current match. no patches to apply. upgrade will be skipped') >= 0) {
            result = true;
        }

        return result;
    }

    isUpgradeExited(message: string) {
        let result = false;

        if (message.toLowerCase().indexOf('upgrade task exited') >= 0) {
            result = true;
        }

        return result;
    }

    isErrorMessageStatus(message: any, states: IEcsAppListStates) {
        let result = false;
        message = message.toLowerCase();

        if (message.indexOf('error') >= 0) {
            result = true;

            // check if its in our ignore list
            for (let i = 0; states.envManagement.ecsImageDeployErrorExclusion.length > i; i++) {
                const ignore = states.envManagement.ecsImageDeployErrorExclusion[i];
                if (message.indexOf(ignore) >= 0) {
                    result = false;
                    break;
                }
            }
        }

        return result;
    }

    isSuccessMessage(message: any, buildNumber: any) {
        let result = false;

        if (message.toLowerCase().indexOf('successfully updated views and functions to version') >= 0) {
            // words.trim().split(" ").pop();
            result = true;
        }

        return result;
    }

    stopTimerWithInterval(states: IEcsAppListStates) {
        clearInterval(states.myInterval);
    }

    getTimeDiffMessage(fromDate: any, toDate: any) {
        const result: any = {
            message: ''
        };

        // get total seconds between the times
        let diffInSeconds = Math.abs(toDate - fromDate) / 1000;
        result.totalSeconds = diffInSeconds;

        // calculate (and subtract) whole days
        const days = Math.floor(diffInSeconds / 86400);
        diffInSeconds -= days * 86400;

        // calculate (and subtract) whole hours
        const hours = Math.floor(diffInSeconds / 3600) % 24;
        diffInSeconds -= hours * 3600;

        // calculate (and subtract) whole minutes
        const minutes = Math.floor(diffInSeconds / 60) % 60;
        diffInSeconds -= minutes * 60;

        // what's left is seconds
        const seconds = diffInSeconds % 60;  // in theory the modulus is not required

        if (days > 0) {
            result.message = days + ' days ';
        }

        if (hours > 0) {
            result.message += hours + ' hours ';
        }

        if (minutes > 0) {
            result.message += minutes + ' minutes ';
        }

        if (seconds > 0) {
            result.message += seconds + ' seconds ';
        }

        return result;
    }

    async addUpgradeActivityLog(clusterObj: any, states: IEcsAppListStates) {
        const eventDetail: any = {
            serviceName: clusterObj.upgradeTaskName,
            toBuildTag: clusterObj.uiVersion,
            runTime: clusterObj.runTimeInfo.message,
            runTimeSeconds: clusterObj.runTimeInfo.totalSeconds
        };

        if (clusterObj.toDataPatch) {
            eventDetail.toDataPatch = clusterObj.toDataPatch;
        }

        const activityLog: any = {
            clusterName: clusterObj.clusterName,
            environmentAction: 'code change upgrade run time',
            eventDetail,
            triggeredBy: this.argosStore.getItem('username'),
            awsAccount: clusterObj.accountName
        };

        if (clusterObj.isFailoverEnvironment) {
            activityLog.eventDetail.isFailoverEnvironment = true;
            activityLog.eventDetail.upgradeTaskSkipped = true;
        }

        activityLog.message = 'code change upgrade took ' + clusterObj.runTimeInfo.message;
        await this.dataAccess.genericCreate({
            model: 'EnvironmentActivityLog',
            data: activityLog
        });
    }

    async saveResourceTags(clusterObj: any, states: IEcsAppListStates) {
        const resourceArns = [];

        let tags = states.envManagement.ecsResourceTags;

        if (clusterObj.salesforceAccountName) {
            tags = _.unionBy([{ key: 'customer', value: clusterObj.salesforceAccountName }], states.envManagement.ecsResourceTags, 'key');
        }

        // get all resourceArns to upsert
        for (let j = 0; j < clusterObj.targetGroupInfo.length; j++) {
            const tg = clusterObj.targetGroupInfo[0];

            resourceArns.push(tg.TargetGroupArn);
            // each tg can have many lbs
            for (let i = 0; i < tg.LoadBalancerArns.length; i++) {
                resourceArns.push(tg.LoadBalancerArns[i]);
            }
        }

        const tagArg = {
            resourceArnList: resourceArns,
            tags,
            requestedBy: this.argosStore.getItem('username'),
            awsAccountName: clusterObj.accountName
        };

        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'upsertElbResourceTags', parameters: tagArg
        });

        return result;
    }

    async initPatchTableList(states: IEcsAppListStates) {

        if (states.unqiueUiVersionCount.length === 1) {
            const buildTag = states.unqiueUiVersionCount[0];

            states.patchListTable = await this.dataAccess.genericFind({
                model: 'Patch',
                filter: {
                    order: 'createdDate desc',
                    where: {
                        and: [
                            { archived: false },
                            { 'releaseInfo.tagName': buildTag }
                        ]
                    }
                }
            });
        }
    }

    getSelectedAwsAccountNames(states: IEcsAppListStates) {
        return _.find(states.awsAccounts, { id: states.selectedAwsAccount });
    }

    isForceDeployStatusCheckHiiden(cluster: any) {
        let result = true;
        let excludedStatus = ['queued', 'ready to promote', 'completed'];
        
        if (!excludedStatus.includes(cluster.promoteStatus)) {
            let avgRunSeconds = ((cluster.averageUpgradeTimeInSeconds || 120) * 1.2);   // run above 1.2 times the average
            let secondsSinceStart = this.utils.getDateDiffBySeconds(cluster.promoteDate, new Date());
            if (secondsSinceStart > avgRunSeconds) {
                result = false;
            }
        }

        return result;
    }

}
