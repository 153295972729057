import { Injectable } from '@angular/core';
import { IEcsRepoEditStates, IEcsRepoEditService } from './ecsRepoEdit.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { AlertService } from 'client/app/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';

@Injectable()
export class EcsRepoEditService implements IEcsRepoEditService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private alertSvc: AlertService) {
        //
    }
    async initDelegate(states: IEcsRepoEditStates): Promise<object> {
        await this.loadEcrRepos(states);
        return {};
    }

    async loadEcrRepos(states: IEcsRepoEditStates) {
        try {
            const response = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcrRepos', parameters: {
                    filter: [], awsAccountName: states.selectedAwsAccount
                }
            });
            states.repoSelectionDisabled = true;

            if (response.repos && response.repos.length > 0) {
                states.repos = response.repos;
                states.selectedRepository = states.repos[0].repositoryName;
                await this.selectedRepoFieldChanged(states);
            }
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    async selectedRepoFieldChanged(states: IEcsRepoEditStates) {

        states.selectedAWSServiceDisabled = true;
        const data = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcrRepoImages', parameters: {
                repoName: states.selectedRepository,
                awsAccountName: states.selectedAwsAccount
            }
        });
        const repoImages = data.images;

        repoImages.sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            const BimagePushedAt = new Date(_.get(b, 'imagePushedAt', 0));
            const AimagePushedAt = new Date(_.get(a, 'imagePushedAt', 0));
            return BimagePushedAt.getDate() - AimagePushedAt.getDate();
        });

        const formatDate = this.formatDate;
        repoImages.forEach(function (image: any) {
            if (image.imageTags) {
                image.tagValue = image.imageTags.toString();
            } else {
                image.tagValue = '';
            }
            image.dateValue = formatDate(image.imagePushedAt);
        });
        states.repoImages = _.orderBy(repoImages, ['dateValue desc']);

        if (states.repoMatTable) {
            states.repoMatTable.data = states.repoImages;
        }

        // states.repoMatTable = new MatTableDataSource(states.repoImages);
        states.selectedAWSServiceDisabled = false;
    }

    formatDate: Function = (dateValue: any) => {

        const d = new Date(dateValue);
        const month = d.toLocaleString('default', { month: 'long' });
        const mins = ('0' + d.getMinutes()).slice(-2);

        return month + ' ' + d.getDate() + ', ' + d.getFullYear() + ' at ' + d.getHours() + ':' + mins;
    };

    async pollPromoteImageStatus(states: IEcsRepoEditStates) {
        console.log('pollPromoteImageStatus called ' + states.pollingVars.runId + ' buildLog ' + states.pollingVars.buildLog);
        const data = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getScreenStatus', parameters: {
                runId: states.pollingVars.runId,
                buildLog: states.pollingVars.buildLog
            }
        });
        if (data.status.indexOf('running') !== -1) {
            states.pushImageStatus += '.';
        } else if (data.status.indexOf('Side branch push completed') !== 0) {
            clearInterval(states.promotePollingTimer);
            states.pushImageStatus += data.status;

            this.saveEventToLog(true, states.pollingVars.buildLog, states);
            states.isPushingImage = false;
            this.selectedRepoFieldChanged(states);
        } else {
            clearInterval(states.promotePollingTimer);
            states.pushImageStatus += 'failed: ' + data.status;
            this.saveEventToLog(false, states.pollingVars.buildLog, states);
            states.isPushingImage = false;
        }
    }

    saveEventToLog(successful: any, buildLog: any, states: IEcsRepoEditStates) {

        const eventDetail: any = {
            repositoryName: states.selectedRepository,
            gitRepositoryName: states.gitRepositoryName,
            sideBranchLabel: states.sideBranchLabel,
            pushLog: buildLog
        };

        eventDetail.sideBranchName = (states.sideBranchName ? states.sideBranchName : '');
        eventDetail.sideBranchTag = (states.sideBranchTag ? states.sideBranchTag : '');

        const activityLog: any = {
            clusterName: '',
            environmentAction: 'image deploy',
            eventDetail,
            triggeredBy: this.argosStore.getItem('username'),
            awsAccount: states.selectedAwsAccount,
            isSuccessful: successful,
        };

        activityLog.message = activityLog.environmentAction + ' to ' + states.selectedRepository + ' repo using git repo ' + states.gitRepositoryName + (eventDetail.sideBranchName ? ' with side branch ' + eventDetail.sideBranchName : '') + ' with label ' + eventDetail.sideBranchLabel + (states.sideBranchTag ? ' and tag ' + states.sideBranchTag : '');

        this.dataAccess.genericCreate({
            model: 'EnvironmentActivityLog',
            data: activityLog
        });
    }
}
