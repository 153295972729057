import swal from 'sweetalert2';
import * as _ from 'lodash';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Component, ChangeDetectorRef, OnInit, Inject } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClarifyInsightsDataEvaluationUserPasswordResetModalService } from './clarifyInsightsDataEvaluationUserPasswordResetModal.component.service';
import { IClarifyInsightsDataEvaluationUserPasswordResetModal } from './clarifyInsightsDataEvaluationUserPasswordResetModal.d';


@Component({
    templateUrl: './clarifyInsightsDataEvaluationUserPasswordResetModal.component.html',
    providers: [ClarifyInsightsDataEvaluationUserPasswordResetModalService]
})

export class ClarifyInsightsDataEvaluationUserPasswordResetModalComponent extends ReactComponentBase<{}, IClarifyInsightsDataEvaluationUserPasswordResetModal, {}>  implements OnInit{

    passwordForm: FormGroup;
    constructor(private svc: ClarifyInsightsDataEvaluationUserPasswordResetModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
    public dialogRef: MatDialogRef<ClarifyInsightsDataEvaluationUserPasswordResetModalComponent>){
        super({
            id: dialogData.props.id,
            username: dialogData.props.username,
            passwordError: false,
            passwordErrorMessage: null,
            passwordConfirmationError: false,
            passwordConfirmationErrorMessage: null
        });
        this.passwordForm = new FormGroup({
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            passwordConfirmation: new FormControl('', [Validators.required, Validators.minLength(8)])
        },
        {validators: this.passwordsMatch})
    }

    passwordsMatch(ac: AbstractControl): ValidationErrors | null {
        return ac.value.password == ac.value.passwordConfirmation ? null : { passwordMismatch: true };
    }

    async ngOnInit() {
        this.cdr.detectChanges();
    }

    close() { 
        this.dialogRef.close();
    }

    async submit() {
        this.states.passwordError = false;
        this.states.passwordConfirmationError = false;
        if (this.passwordForm.status === 'INVALID' && !this.passwordForm.errors) {
            if (this.passwordForm.controls.password.errors && 'required' in this.passwordForm.controls.password.errors) { 
                this.states.passwordError = true;
                this.states.passwordErrorMessage = "Field is required";
            }
            if (this.passwordForm.controls.password.errors && 'minlength' in this.passwordForm.controls.password.errors) { 
                this.states.passwordError = true;
                this.states.passwordErrorMessage = "Password must be at least 8 characters long";
            }
            if (this.passwordForm.controls.passwordConfirmation.errors && 'required' in this.passwordForm.controls.passwordConfirmation.errors) { 
                this.states.passwordConfirmationError = true;
                this.states.passwordConfirmationErrorMessage = "Field is required";
            }
            if (this.passwordForm.controls.passwordConfirmation.errors && 'minlength' in this.passwordForm.controls.passwordConfirmation.errors) { 
                this.states.passwordConfirmationError = true;
                this.states.passwordConfirmationErrorMessage = "Password must be at least 8 characters long";
            }
        }
        else if (this.passwordForm.status === 'INVALID') {
            this.states.passwordConfirmationError = true;
            this.states.passwordConfirmationErrorMessage = "Passwords do not match";
        }
        else {
            await this.svc.updatePassword(this.states.id, this.states.username, this.passwordForm.controls.password.value);
            await swal({
                title: "Password successfuly updated",
                type: "info"
            })
            this.dialogRef.close();
        }
    }
}
