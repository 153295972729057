import { Injectable } from '@angular/core';
import { IRedshiftClustersStates, IRedshiftClustersService } from './redshiftClusters.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class RedshiftClustersService implements IRedshiftClustersService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(states: IRedshiftClustersStates): Promise<object> {
        const clusters = await this.dataAccess.genericFind({
            model: 'RedshiftClusterInfo',
            filter: { order: 'id' }
        });

        states.clientTable = _.orderBy(clusters, ['id']);
        return {};
    }

}
