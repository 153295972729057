import { Injectable } from '@angular/core';
import { IPipelineHistoryStates, IPipelineHistoryService } from './pipelineHistory.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IChartDataset, IChartFilterConfig } from '../pipelineHistoryChartModel/pipelineHistoryChartModel.component.d';
import { PipelineHistoryChartModelComponent } from '../pipelineHistoryChartModel/pipelineHistoryChartModel.component';
@Injectable()
export class PipelineHistoryService implements IPipelineHistoryService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog) {
        //
    }
    async initDelegate(states: IPipelineHistoryStates): Promise<object> {
        await this.init(states);
        return states;
    }

    async init(states: IPipelineHistoryStates) {
        // set default timeframe unless stored in current session storage
        states.currentTimeFrameSelection = sessionStorage.getItem('selectedPipelineTimeframes');
        if (states.currentTimeFrameSelection === null) {
            states.currentTimeFrameSelection = '7';
            sessionStorage.setItem('selectedPipelineTimeframes', states.currentTimeFrameSelection);
        }
        // set default pipeline type unless stored in current session storage
        states.currentPipelineTypeSelection = sessionStorage.getItem('currentPipelineTypeSelection');
        if (states.currentPipelineTypeSelection === null) {
            states.currentPipelineTypeSelection = 'prod';
            sessionStorage.setItem('currentPipelineTypeSelection', states.currentPipelineTypeSelection);
        }
        await this.queryStatus(states);
    }

    // on refresh store current filter selections to avoid losing this data
    refreshPage(states: IPipelineHistoryStates) {
        sessionStorage.setItem('selectedPipelineTimeframes', states.currentTimeFrameSelection);
        sessionStorage.setItem('currentPipelineTypeSelection', states.currentPipelineTypeSelection);
    }

    async queryStatus(states: IPipelineHistoryStates) {
        const { allPipelines, distinctPipelinesString, distinctPipelines, filteredPipelines, pipelineKeyIdsMap } = await this.dataAccess.genericMethod({
            model: 'PipelineHistory',
            method: 'getPipelineHistory',
            parameters: {
                timeFrame: states.currentTimeFrameSelection,
                piplineType: states.currentPipelineTypeSelection
            }
        });
        states.allPipelines = allPipelines;
        states.distinctPipelinesString = distinctPipelinesString;
        states.distinctPipelines = distinctPipelines;
        states.filteredPipelines = _.cloneDeep(_.orderBy(filteredPipelines, ['stage']));
        for (const [key, value] of Object.entries(pipelineKeyIdsMap)) {
            sessionStorage.setItem(key, value as any);
        }
        states.filteredPipelinesMatTable.data = states.filteredPipelines;
        states.dataSources = states.filteredPipelines;
        this.setTablePageSize(states);
        states.queryRunning = false;
    }

    viewChartHandler(states: IPipelineHistoryStates) {
        const chartLabels: string[] = [];
        const successRateData: any = [];
        const averageCostData: any = [];
        const filterConfig: IChartFilterConfig = {
            filterPredicate: 'stage',
            filterOptions: []
        };

        _.forEach(states.filteredPipelines, row => {
            chartLabels.push(row.dataSource);
            successRateData.push(row.averageSuccessRate);
            averageCostData.push(row.averagePipelineCost);
            filterConfig.filterOptions.push(row.stage);
        });
        const chartDatasets: IChartDataset[] = [
            {
                label: 'Success Rate',
                data: successRateData,
                companyName: '%'
            },
            {
                label: 'Average Cost',
                data: averageCostData,
                companyName: '$'
            }
        ];

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-large',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    chartLabels,
                    chartDatasets,
                    filterConfig
                }
            }
        };

        this.matDialog.open(PipelineHistoryChartModelComponent, dialogConfig);
    }

    setTablePageSize(states: IPipelineHistoryStates) {
        states.filteredPipelinesMatTable.data = states.dataSources;
    }
}
