import { Injectable } from '@angular/core';
import { IEditRestrictedGroupingsModalProps, IEditRestrictedGroupingsModalStates, IEditRestrictedGroupingsModalService } from './editRestrictedGroupingsModal.component.d';
import * as _ from 'lodash';

@Injectable()
export class EditRestrictedGroupingsModalService implements IEditRestrictedGroupingsModalService {
    initDelegate(props: IEditRestrictedGroupingsModalProps, states: IEditRestrictedGroupingsModalStates): object {
        states.restrictedGroupings = props.restrictedGroupings || [];
        states.validCareGroupings = props.validCareGroupings || [];

        states.episodeGroupings = _.filter(props.episodeGroupings, function (eg) {
            return _.size(_.intersection(eg.validCareGroupings, states.validCareGroupings)) > 0;
        });

        _.forEach(states.episodeGroupings, function (eg) {
            if (_.includes(states.restrictedGroupings, eg.groupByAttribute)) {
                eg.selected = true;
            }
        });
        return {};
    }

    changeDelegate(oldProps: IEditRestrictedGroupingsModalProps, newProps: IEditRestrictedGroupingsModalProps, states: IEditRestrictedGroupingsModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

}
