import {
    Component,
    OnChanges,
    SimpleChanges,
    OnInit,
    Inject,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import {
    IPatchManagementAddEditModalProps,
    IPatchManagementAddEditModalStates,
    IPatchManagementAddEditModalService,
} from './patchManagementAddEditModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatchManagementAddEditModalService } from './patchManagementAddEditModal.component.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown3';
import { StateService } from '@uirouter/core';
import { MatSort, Sort } from '@angular/material/sort';
import * as _ from 'lodash';

@Component({
    selector: 'argos-patch-management-add-edit-modal',
    templateUrl: './patchManagementAddEditModal.component.html',
    providers: [PatchManagementAddEditModalService],
})
export class PatchManagementAddEditModalComponent
    extends ReactComponentBase<{}, IPatchManagementAddEditModalStates, {}>
    implements OnInit, OnChanges {
    @ViewChild('tbMatSort') tbMatSort = new MatSort();
    _: any = _;
    readonly ARGOS_METADATA = ['careGroupings', 'episodeGroupings', 'episodeGroupingEntities', 'tablePartitionMappings', 'validValuesQueries', 'compoundFilters', 'otherMetrics', 'observations'];
    readonly PRISM_GOLD_METADATA = ['spotlightGroups', 'cohorts', 'cohortGroups', 'scorecardFormulas', 'scorecardTemplates', 'userRoles', 'dashboards', 'dashboardVariations', 'dashboardModules'];
    constructor(
        @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<PatchManagementAddEditModalComponent>,
        private svc: PatchManagementAddEditModalService,
        private state: StateService,
        private cdr: ChangeDetectorRef
    ) {
        super({
            nameError: false,
            loading: false,
            saveInProgress: false,
            edit: false,
            dropdownMetaDataFilesSettings: {
                singleSelection: false,
                idField: 'fileName',
                textField: 'fileName',
                selectAllText: 'Select All Files',
                unSelectAllText: 'UnSelect All Files',
                itemsShowLimit: 2,
                allowSearchFilter: true
            } as IDropdownSettings,
            selectedFiles: {},
            message: '',
            buildReleaseTags: [],
            awsAccounts: [
                { id: 'clarify', name: 'Clarify AWS Account' },
                { id: 'cleardata', name: 'Clear Data AWS Account' },
                { id: 'cleardata_east', name: 'Clear Data AWS Account East Fail Over' }
            ],
            repositoryName: 'clarify-platform',
            patch: {
                title: '',
                description: '',
                selectedFiles: {
                    dashboardModules: {
                        parentModules: [],
                        childModules: []
                    }
                },
                availableFiles: {},
                releaseFiles: {},
                releaseInfo: {
                    patchFolder: '',
                    tagName: ''
                }
            },
        } as IPatchManagementAddEditModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(
            changes.props.previousValue,
            changes.props.currentValue,
            this.states
        );
    }

    async tagNameChanged() {
        await this.svc.getBranchDataFromGithub(this.states, this.cdr);
        this.cdr.detectChanges();
    }

    patchNameChanged() {
        //
    }

    onFileSelect(event: any, item: string) {
        if (!(item in this.states.selectedFiles) && !_.includes(item, 'dashboardModules')) {
            _.set(this.states.selectedFiles, item, []);
        }
        if (_.includes(item, 'dashboardModules')) {
            if (_.includes(item, 'childModules')) {
                if (!_.find(this.states.selectedFiles.dashboardModules.childModules, event)) {
                    this.states.selectedFiles.dashboardModules.childModules.push(event);
                }
            } else {
                if (!_.find(this.states.selectedFiles.dashboardModules.parentModules, event)) {
                    this.states.selectedFiles.dashboardModules.parentModules.push(event);
                }
            }
        } else {
            _.uniqBy(this.states.selectedFiles[item], 'fileName');
        }
    }

    onFilesSelectAll(event: any[], item: string) {
        _.set(this.states.selectedFiles, item, event);
    }

    onFileDeSelect(event: any, item: string) {
        const selectedItemFiles = _.cloneDeep(_.get(this.states.selectedFiles, item));
        _.set(this.states.selectedFiles, item, _.filter(selectedItemFiles, metaData => {
            return (metaData.fileName !== event.fileName);
        }));
    }

    onFilesDeSelectAll(event: any[], item: string) {
        _.set(this.states.selectedFiles, item, []);
    }

    onConfigurationDropDownClose(event: any) {
        _.omitBy(this.states.selectedFiles, _.isNil);
        this.states.patch.selectedFiles = _.cloneDeep(this.states.selectedFiles);
    }

    close() {
        this.dialogRef.close('cancel');
    }

    gotoECS() {
        this.close();
        this.state.go('argos.ecsAccess.ecsAppList');
    }

    async save() {
        const result = await this.svc.savePatch(this.states);
        this.dialogRef.close(result);
    }
}
