import { Injectable } from '@angular/core';
import { IReleaseNotesStates, IReleaseNotesService } from './releaseNotes.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import * as moment from 'moment';
declare const saveAs: any;

@Injectable()
export class ReleaseNotesService implements IReleaseNotesService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: IReleaseNotesStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IReleaseNotesStates) {
        const environments = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEnvironments', parameters: {

            }
        });

        states.environments = _.orderBy(_.map(_.filter(environments, function (o) {
            return o.dnsCname && o.domainSuffix;
        }), function (o: any) {
            o.url = 'https://' + o.dnsCname + '.clarifyhealth.' + o.domainSuffix;
            return o;
        }), 'url');

        states.environment = _.find(states.environments, { url: 'https://greenfieldhospital.clarifyhealth.com' });
        await this.refreshReleaseNotes(states);
    }

    momentFromNow(date: any) {
        return moment(new Date(date)).fromNow();
    }

    async refreshReleaseNotes(states: IReleaseNotesStates) {
        states.queryRunning = true;
        const releaseNotes = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getReleaseNotes', parameters: {
                project: states.project,
                environmentId: states.environment ? states.environment.id : '',
                changesSince: states.changesSince
            }
        });
        states.releaseNotes = releaseNotes;
        states.buildFromText = 'From: ' + moment(releaseNotes.fromTime).format('LLLL') + ', Build: ' + (releaseNotes.fromBuild ? releaseNotes.fromBuild : '?');
        states.buildToText = 'To: ' + moment(releaseNotes.toTime).format('LLLL') + ', Build: ' + (releaseNotes.toBuild ? releaseNotes.toBuild : '?');

        states.releaseNotesTable = _.orderBy(releaseNotes.jiraIssues, ['id']);
        states.queryRunning = false;
    }

    exportReleaseNotes(states: IReleaseNotesStates) {
        let exportString = 'id\ttype\tsummary\n';
        states.releaseNotes.jiraIssues.forEach((note: any) => {
            exportString += (note.id + '\t' + note.type + '\t' + (note.releaseNote || note.summary) + '\n');
        });
        const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
        return saveAs(blob, 'release-notes-' + states.releaseNotes.fromBuild + '-' + states.releaseNotes.toBuild + '.csv');
    }
}
