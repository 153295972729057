import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IStatusDetailModalProps, IStatusDetailModalStates } from './statusDetailModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { StatusDetailModalService } from './statusDetailModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-statusDetailModal',
    templateUrl: './statusDetailModal.component.html',
    providers: [StatusDetailModalService]
})

export class StatusDetailModalComponent extends ReactComponentBase<IStatusDetailModalProps, IStatusDetailModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: StatusDetailModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<StatusDetailModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close();
    }
}
