import { Injectable } from '@angular/core';
import { IDataDashLegendModalService, IDataDashLegendModalStates  } from './dataDashLegendModal.component.d';

@Injectable()
export class DataDashLegendModalService implements IDataDashLegendModalService {
    constructor() {}

    async initDelegate(states: IDataDashLegendModalStates): Promise<object> {
        await this.init(states);
        return states;
    };

    async init(states: IDataDashLegendModalStates) {
        return states;
    };

} 