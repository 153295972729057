import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IAdvanceMetaDataListStates, IAdvanceMetaDataListService } from './advanceMetaDataList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import swal from 'sweetalert2';

@Injectable()
export class AdvanceMetaDataListService implements IAdvanceMetaDataListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, 
        private cdr: ChangeDetectorRef, private state: StateService, private changeDetectRef: ChangeDetectorRef) {
        //
    }
    
    async initDelegate(states: IAdvanceMetaDataListStates): Promise<Object> {
        await this.activate(states);
        return {};
    }
    
    async activate(states: IAdvanceMetaDataListStates) {
        let advanceEpMetaData = await this.dataAccess.genericFind({
            model: 'AdvanceEpMetaData',
            filter: {
                include: 'dataSource'
            }
        });

        advanceEpMetaData = advanceEpMetaData.map((meta: any) => {
            meta.runType = 'Elective Procedure';
            return meta;
        });

        let advanceSrMetaData = await this.dataAccess.genericFind({
            model: 'AdvanceSrMetaData',
            filter: {
                include: 'dataSource'
            }
        });

        advanceSrMetaData = advanceSrMetaData.map((meta: any) => {
            meta.runType = 'Specialist Referral';
            return meta;
        });

        states.advanceMeta = advanceEpMetaData.concat(advanceSrMetaData).map((meta: any) => {
            meta.lastModified = (meta.lastModified) ? new Date(meta.lastModified).toISOString().slice(0, 10) : undefined;
            meta.lastRun = (meta.lastRun) ? new Date(meta.lastRun).toISOString().slice(0, 10) : undefined;
            meta.dataSourceName = meta.dataSource.name;

            states.dataSources.add(meta.dataSource.code);

            return meta;
        });

        states.table.data = _.orderBy(states.advanceMeta, ['dataSourceName']);

        // Not awaiting so we load data while we are pulling run ids from tera_catalog
        this.getRevisionCount(states);
    }

    setTablePageSize(states: IAdvanceMetaDataListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.advanceMeta.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IAdvanceMetaDataListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IAdvanceMetaDataListStates) {
        const data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    addNewDataSource() {
        swal({
            title: 'Add New Data Source',
            type: 'question',
            input: 'select',
            inputOptions: {
                electiveProcedure: 'Elective Procedure',
                specialistReferral: 'Specialist Referral'
            },
            inputPlaceholder: 'Select a Data Source',
            showCancelButton: true,
            confirmButtonColor: '#57C84D', 
            confirmButtonText: 'Next',
            showLoaderOnConfirm: true,
        }).then(async (isConfirm: any) => {    
            if (isConfirm.value) {
                this.state.go('argos.modelMetaData.advanceMetaData.add', { runType: isConfirm.value });
            }
        });
    }

    async getRevisionCount(states: IAdvanceMetaDataListStates) {
        const tableName = 'clarify_provider_npi_revision';
        const tableRunIds = await this.dataAccess.genericMethod({
            model: 'Advance',
            method: 'getReferenceIds',
            parameters: {
                tableNames: [tableName]
            }
        });

        const apiCallHistory = await this.dataAccess.genericFind({
            model: 'AdvanceSalesforceApiCallHistory',
            filter: {
                where: {
                    timestamp: {
                        gt: new Date(tableRunIds[tableName].substring(0, 10))
                    }
                }
            }
        });

        const counts: any = {};

        states.dataSources.forEach(ds => counts[ds] = 0);
        
        apiCallHistory.forEach((item: any) => {
            const dsToApply = item.revisionAfter.dataSourcesToApply[0];
            if (counts.hasOwnProperty(dsToApply)) {
                counts[dsToApply]++;
            }
        });

        states.advanceMeta.forEach((meta: any) => {
            meta.salesforceRevisions = counts[meta.dataSource.code];
        });

        states.defaultDisplayMatTableColumns.push('salesforceRevisions');
        states.matTableColumns.push('salesforceRevisions');

        states.table.data = _.orderBy(states.advanceMeta, ['dataSourceName']);
        this.changeDetectRef.detectChanges();
    }
}
