import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewEpisodeGroupingHistoryModalProps, IViewEpisodeGroupingHistoryModalStates } from './viewEpisodeGroupingHistoryModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewEpisodeGroupingHistoryModalService } from './viewEpisodeGroupingHistoryModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'argos-viewEpisodeGroupingHistoryModal',
    templateUrl: './viewEpisodeGroupingHistoryModal.component.html',
    providers: [ViewEpisodeGroupingHistoryModalService]
})

export class ViewEpisodeGroupingHistoryModalComponent
    extends ReactComponentBase<IViewEpisodeGroupingHistoryModalProps, IViewEpisodeGroupingHistoryModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewEpisodeGroupingHistoryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewEpisodeGroupingHistoryModalComponent>) {
        super({ episodeGrouping: {} } as IViewEpisodeGroupingHistoryModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close({});
    }

    viewDiffs(version: any) {
        this.svc.viewDiffsHandler(version, this.states);
    }
}
