import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EcsAppEnvironment } from './ecsAppEnvironmentHandler.service';

@Injectable()
export class EcsAppPatch {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess, private ecsAppEnvironmentHandler: EcsAppEnvironment) {
        //
    }

    async buildVersionChanged(environmentId: string, uiVersion: string, appliedPatches: any[], patchListTable: any[]) {
        if (_.size(appliedPatches)) {
            try {
                // archive those patches
                const archived = await this.dataAccess.genericMethod({
                    model: 'Patch',
                    method: 'archivePatches',
                    parameters: {
                        patchIds: _.map(appliedPatches, 'id'),
                        userEmail: this.argosStore.getItem('username')
                    }
                });

                // delete unapplied patches for ui-version, if any.
                const filterPatchTable = _.filter(patchListTable, (item: any) => item.releaseInfo.tagName === uiVersion);
                const remainingPatchIds = _.xor(_.map(filterPatchTable, 'id'), _.map(appliedPatches, 'id'));
                if (_.size(remainingPatchIds)) {
                    await this.dataAccess.genericMethod({
                        model: 'Patch',
                        method: 'deleteUnAppliedPatches',
                        parameters: {
                            patchIds: remainingPatchIds,
                        }
                    });
                }
                // delete patches from env patch table.
                if (archived) {
                    const envs = await this.dataAccess.genericMethod({
                        model: 'Patch',
                        method: 'deleteEnvPatchRecord',
                        parameters: {
                            environmentId,
                            patchIds: _.map(appliedPatches, 'id')
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    async updateEnvPatches(environmentName: string, environmentId: string, appliedPatches: any[], selectedPatches: any[]) {
        if (!_.isEqual(selectedPatches, appliedPatches)) {
            // update environment patch table, and update patch table with envinfo
            const envPatchObj = _.map(selectedPatches, (patch: any) => {
                return {
                    environmentId,
                    patchId: _.get(patch, 'id'),
                    environmentName,
                };
            });
            try {
                const envPatchSaved = await this.dataAccess.genericMethod({
                    model: 'Patch',
                    method: 'updateEnvironmentPatch',
                    parameters: { envPatchObj }
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
}
