import { Injectable } from '@angular/core';
import { IViewPayerRatesMetaHistoryProps, IViewPayerRatesMetaHistoryStates, IViewPayerRatesMetaHistoryService } from './viewPayerRatesMetaHistory.component.d';
import * as _ from 'lodash';

@Injectable()
export class ViewPayerRatesMetaHistoryService implements IViewPayerRatesMetaHistoryService {
    initDelegate(props: IViewPayerRatesMetaHistoryProps, states: IViewPayerRatesMetaHistoryStates): Object {
        states.payerRatesMeta = props.payerRatesMeta;
        return {};
    }

    changeDelegate(oldProps: IViewPayerRatesMetaHistoryProps, newProps: IViewPayerRatesMetaHistoryProps, states: IViewPayerRatesMetaHistoryStates): Object {
        this.initDelegate(newProps, states);
        return {};
    }

    viewDiffs(version: number, states: IViewPayerRatesMetaHistoryStates) {
        states.showDiffs = true;
        states.compareHistory = [];
        states.currentVersion = ('(v' + version + ')');
        states.previousVersion = '';
        const currentRow: any = _.find(states.payerRatesMeta.payerRateMetaHistories, { version });
        let previousRow: any = {};
        if (currentRow) {
            if (version > 1) {
                const previousVersion = version - 1;
                states.previousVersion = ('(v' + previousVersion + ')');
                previousRow = _.find(states.payerRatesMeta.payerRateMetaHistories, { version: previousVersion });
            }
        }
        states.compareHistory = _.map(_.keys(currentRow.definition), function (key) {
            return {
                key: _.startCase(key),
                previousValue: _.get(previousRow, 'definition.' + key),
                currentValue: _.get(currentRow, 'definition.' + key)
            };
        });
    }
}
