import { Injectable } from '@angular/core';
import { ICcgProviderTypesStates, ICcgProviderTypesService } from './ccgProviderTypes.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from '../../../services/dataAccess.service';

@Injectable()
export class CcgProviderTypesService implements ICcgProviderTypesService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(states: ICcgProviderTypesStates): Promise<object> {
        await this.initialize(states);
        return {};
    }

    async initialize(states: ICcgProviderTypesStates) {
        const data = await this.dataAccess.genericFind({
            model: 'ClarifyAttributedProviderProviderType',
            filter: {}
        });
        states.data = _.orderBy(data, ['sortOrder', 'name']);
    }

    addProviderTypeHandler(states: ICcgProviderTypesStates) {
        states.newProviderType.name = _.trim(states.newProviderType.name);
        states.newProviderType.npiColumn = _.trim(states.newProviderType.npiColumn);
        states.newProviderType.sourceTable = _.trim(states.newProviderType.sourceTable);
        states.newProviderType.tableFilter = _.trim(states.newProviderType.tableFilter);
        states.newProviderType.observationReplaceFrom = _.trim(states.newProviderType.observationReplaceFrom);
        states.newProviderType.observationReplaceTo = _.trim(states.newProviderType.observationReplaceTo);

        if (!_.isEmpty(states.newProviderType.name) && !_.find(states.data, { name: states.newProviderType.name }) &&
            !_.isEmpty(states.newProviderType.npiColumn) && !_.isEmpty(states.newProviderType.sourceTable) &&
            !_.isEmpty(states.newProviderType.tableFilter)) {
            const newProviderType = {
                id: (_.get(_.maxBy(states.data, 'id'), 'id', 0) + 1),
                sortOrder: 2,
                name: states.newProviderType.name,
                npiColumn: states.newProviderType.npiColumn,
                sourceTable: states.newProviderType.sourceTable,
                tableFilter: states.newProviderType.tableFilter,
                observationReplaceFrom: states.newProviderType.observationReplaceFrom,
                observationReplaceTo: states.newProviderType.observationReplaceTo
            };
            states.data.unshift(newProviderType);
            states.newProviderType = {};
            states.data = _.orderBy(states.data, ['sortOrder', 'name']);
        }
    }

    async saveHandler(states: ICcgProviderTypesStates) {
        const data = states.data;
        if (!states.isSaving) {
            states.isSaving = true;
            await this.dataAccess.genericMethod({
                model: 'ClarifyAttributedProviderProviderType', method: 'save', parameters: { data }
            });
            states.isSaving = false;
        }
    }
}
