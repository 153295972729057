import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { IQPerformanceProductDetailStates } from './performanceTestResultsDashboardProductsDetail.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { QAPerformanceResultDetailService } from './performanceTestResultsDashboardProductsDetail.component.service';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { CategoryScale, Chart, ChartData, ChartOptions, LinearScale, LineController, LineElement, Legend, Title, Tooltip, SubTitle, PointElement } from 'chart.js';
import { ChartService } from '../../../services/chartService.service';

function registerChartComponents() {
    Chart.register(CategoryScale);
    Chart.register(LinearScale);
    Chart.register(LineController);
    Chart.register(LineElement);
    Chart.register(Legend);
    Chart.register(Title);
    Chart.register(Tooltip);
    Chart.register(SubTitle);
    Chart.register(PointElement);
}
registerChartComponents();

@Component({
    selector: 'argos-qaPerformanceResultDetail',
    templateUrl: './performanceTestResultsDashboardProductsDetail.component.html',
    providers: [QAPerformanceResultDetailService],
    styleUrls: ['../testResultsDashboard/qaTestResultDashboard.component.less']
})

export class PerformanceTestResultsDashboardProductsDetail extends ReactComponentBase<{}, IQPerformanceProductDetailStates, {}> implements OnInit {
    // chart
    @ViewChild('doubleLineCanvas') doubleLineCanvas: ElementRef | undefined;
    doubleLineChart: any;

    constructor(private svc: QAPerformanceResultDetailService, private cdr: ChangeDetectorRef, private chartSvc: ChartService) {
        super({
            performanceTestResults: [],
            performanceTestDataTable: new MatTableDataSource([]),
            performanceSubTable: new MatTableDataSource([]),
            chartSettings: {
                colors: ['#45b7cd', '#00ADF9', '#FF5252', '#631717']
            },
        } as IQPerformanceProductDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges;
        this.createPerformanceTestChart(this.states.performanceTestResults);
    }

    createPerformanceTestChart(data: any) {
        // Getting unique sorted dates for runs
        const mappedTestRanAt = _.map(data, (dt: any) => {
            return _.assign(dt, { release_name: new Date(dt.release_name).toLocaleDateString('es-pa') });
        });
        const chartLabelTestRanAt = _(mappedTestRanAt)
            .uniqBy('release_name')
            .map(d => d.release_name)
            .orderBy([(date) => {
                return new Date(date);
            }],['asc'])
            .value();

        // Group by page name
        const groupedPageName = _.groupBy(data, x => [x.dashboard, x.tab]);
        const performanceDataset = [];
        const groupedPageNameKeys = Object.keys(groupedPageName);
        for (let i = 0; i < groupedPageNameKeys.length; i++) {
            const legendLabel = groupedPageNameKeys[i].split(',')[0] + ' (' + groupedPageNameKeys[i].split(',')[1] + ')';
            const currentKey = groupedPageNameKeys[i];
            const performanceData: any[] = groupedPageName[currentKey];
            const uniqPerformanceData = _.uniqBy(performanceData, 'release_name');
            const lineColor = this.chartSvc.getRandomColor();
            const xAxisData: any[] = [];

            for (let j = 0; j < chartLabelTestRanAt.length; j++) {
                const containsDataByTestRanAt = uniqPerformanceData.find(e => e.release_name === chartLabelTestRanAt[j]);
                if (containsDataByTestRanAt) {
                    xAxisData.push(containsDataByTestRanAt.load_time);
                } else {
                    xAxisData.push(null);
                }
            }

            performanceDataset.push({
                label: legendLabel,
                data: xAxisData,
                backgroundColor: lineColor,
                borderColor: lineColor,
                hoverBorderWidth: 15,
                hoverBorderColor: lineColor,
                fill: false
            });
        }

        if (this.doubleLineChart) {
            this.doubleLineChart.destroy();
        }

        this.states.chartSettings = {
            type: 'line',
            data: {
                labels: chartLabelTestRanAt,
                datasets: performanceDataset,
            },
            options: {
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        labels: {
                            filter (legendItem: any, chartData: any) {
                                if (chartData.datasets[legendItem.datasetIndex].label === chartData.datasets[legendItem.datasetIndex - 1]?.label) {
                                    return false;
                                }
                                return true;
                            }
                        }
                    },
                },

            }
        };

        this.doubleLineChart = new Chart(this.doubleLineCanvas?.nativeElement, this.states.chartSettings);
    }

}
