import { Injectable } from '@angular/core';
import { ICcgDefinitionStates, ICcgDefinitionService } from './ccgDefinition.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from '../../../services/dataAccess.service';

@Injectable()
export class CcgDefinitionService implements ICcgDefinitionService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: ICcgDefinitionStates): Promise<ICcgDefinitionStates> {
        states.lodash = _;
        const newStates = await this.initialize(states);
        return newStates;
    }

    async initialize(initStates: ICcgDefinitionStates): Promise<ICcgDefinitionStates> {
        const states = _.cloneDeep(initStates);
        states.data = await this.dataAccess.genericMethod({
            model: 'ClarifyAttributedProviderCCG', method: 'getData'
        });
        states.data.ccg.fromDt = new Date(states.data.ccg.fromDt).toISOString().slice(0, 10);
        states.data.ccg.toDt = new Date(states.data.ccg.toDt).toISOString().slice(0, 10);
        this.getSelectedObjects(states);
        states.loading = false;
        states.saving = false;
        return states;
    }

    getSelectedObjects(states: ICcgDefinitionStates) {
        states.data.selectedCareGrouping = _.find(states.data.careGroupings, function (cg) { return cg.id === states.data.ccg.careGroupingId; });
        states.data.selectedSourceSchemas = _.filter(states.data.sourceSchemas, function (ss) { return _.includes(_.get(states.data.ccg, 'sourceSchema'), ss.id); });
        this.createMetaDataHandler(states);
    }

    async saveHandler(states: ICcgDefinitionStates): Promise<ICcgDefinitionStates> {
        states.saving = true;
        states.data.ccg.fromYear = new Date(states.data.ccg.fromDt).getFullYear();
        states.data.ccg.toYear = new Date(states.data.ccg.toDt).getFullYear();
        const body = {
            ccg: states.data.ccg,
            providerTypeObservationMappings: _.flatMap(states.data.providerTypeObservationMappings, 'observationMappings')
        };
        await this.dataAccess.genericMethod({
            model: 'ClarifyAttributedProviderCCG', method: 'save', parameters: { body }
        });
        const newStates = await this.initialize(states);
        return newStates;
    }

    sourceSchemaChangedHandler(states: ICcgDefinitionStates) {
        states.data.ccg.sourceSchema = _.map(states.data.selectedSourceSchemas, 'id');
        this.createMetaDataHandler(states);
    }

    createMetaDataHandler(states: ICcgDefinitionStates) {
        const metaData: any = {};

        metaData.careGroupingName = _.get(states, 'data.selectedCareGrouping.name');
        metaData.sourceCCGTableSchemas = _.map(states.data.selectedSourceSchemas, 'name');
        metaData.newCCGTableName = states.data.ccg?.destinationTable;
        metaData.fromDt = states.data.ccg?.fromDt;
        metaData.toDt = states.data.ccg?.toDt;

        const attributedProviderTypes = _.filter(states.data.providerTypeObservationMappings, function (pt) { return pt.name !== 'Not Attributed' && pt.name !== 'String Columns'; });
        const notAttrbutedProviderType = _.find(states.data.providerTypeObservationMappings, function (pt) { return pt.name === 'Not Attributed'; });
        const stringColumnProviderType = _.find(states.data.providerTypeObservationMappings, function (pt) { return pt.name === 'String Columns'; });

        metaData.providerTypeMetricMappings = _.map(_.orderBy(attributedProviderTypes, ['sortOrder', 'name']), function (pt) {
            const providerTypeMeta: any = {};
            providerTypeMeta.providerTypeId = pt.id;
            providerTypeMeta.providerType = pt.name;

            const observationReplaceFrom = pt.observationReplaceFrom;
            const observationReplaceTo = pt.observationReplaceTo;
            if (!_.isNil(observationReplaceFrom) && !_.isNil(observationReplaceTo)) {
                providerTypeMeta.uniqueReplace = {
                    from: observationReplaceFrom,
                    to: observationReplaceTo
                };
            }

            providerTypeMeta.sourceCCGTableName = pt.sourceTable;
            providerTypeMeta.npiColumn = pt.npiColumn;
            providerTypeMeta.filter = pt.tableFilter;

            providerTypeMeta.observations = _.map(_.orderBy(pt.observationMappings, function (om) { return om.observation?.name; }), function (om) {
                const observationMeta: any = {};
                observationMeta.baseObservation = om.observation?.name;

                if (!_.isNil(om.namePrefix)) {
                    observationMeta.newObservationPrefix = om.namePrefix;
                }

                if (!_.isNil(om.titleSuffix)) {
                    observationMeta.newObservationNameSuffix = om.titleSuffix;
                }

                if (!_.isNil(om.predictionLookupPrefix)) {
                    observationMeta.predictionLookupPrefix = om.predictionLookupPrefix;
                }

                if (om.rateDenominator === '-- Clear --') {
                    om.rateDenominator = undefined;
                    observationMeta.rateDenominator = undefined;
                    om.rateDenominatorNameOverride = undefined;
                    observationMeta.rateDenominatorNameOverride = undefined;
                } else if (!_.isNil(om.rateDenominator)) {
                    observationMeta.rateDenominator = om.rateDenominator;
                } else if (!_.isNil(observationMeta.rateDenominator)) {
                    om.rateDenominator = observationMeta.rateDenominator;
                }

                if (!_.isNil(om.rateDenominatorNameOverride)) {
                    observationMeta.rateDenominatorNameOverride = om.rateDenominatorNameOverride;
                }

                return observationMeta;
            });

            return providerTypeMeta;
        });

        if (notAttrbutedProviderType) {
            metaData.otherMetricsForAll = _.map(_.orderBy(notAttrbutedProviderType.observationMappings, function (om) { return om.observation?.name; }), function (om) { return om.observation?.name; });
        }

        if (stringColumnProviderType) {
            metaData.stringColumns = _.map(_.orderBy(stringColumnProviderType.observationMappings, function (om) { return om.observation?.name; }), function (om) { return om.observation?.name; });
        }
        
        _.set(states, 'data.ccg.metaData', metaData);
        // states.data.ccg.metaData = metaData;
    }

}
