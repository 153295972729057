import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IMarkedForDeletionListStates } from './markedForDeletionList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MarkedForDeletionListService } from './markedForDeletionList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'argos-marked-for-deletion-list',
    templateUrl: './markedForDeletionList.component.html',
    providers: [MarkedForDeletionListService]
})

export class MarkedForDeletionListComponent
    extends ReactComponentBase<{}, IMarkedForDeletionListStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: MarkedForDeletionListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            pageSize: 50,
            moment,
            filterObj: {},
            markedForDeletions: [],
            viewPreviouslyDeleted: false,
            matTableColumns: ['objectType', 'objectName', 'deletedBy', 'deletedDate', 'undelete'],
            markedForDeletionTable: new MatTableDataSource([])
        } as IMarkedForDeletionListStates);
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.markedForDeletionTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.markedForDeletionTable.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.states.markedForDeletionTable.paginator = this.paginator;
        this.states.markedForDeletionTable.sort = this.matTbSort;
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.markedForDeletionTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.markedForDeletionTable.filter);
    }

    generateDeleteScript() {
        this.svc.generateDeleteScript(this.states);
    }

    getTitle(objectType: string) {
        return this.svc.getTitle(objectType);
    }

    viewPreviouslyDeleted() {
        if (this.states.viewPreviouslyDeleted) {
            this.svc.activate(this.states);
        } else {
            this.svc.viewPreviouslyDeleted(this.states);
        }
    }

    linkToObject(row: any) {
        this.svc.linkToObject(row);
    }

    undelete(row: any) {
        this.svc.undelete(row, this.states);
    }
}
