import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IUserListStates, IUserListService } from './userRoleList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { UserRoleService } from 'client/app/services/userRole.service';
import { UtilsService } from '../../../services/utils.service';
import { StateService, UIRouter } from '@uirouter/core';
import * as _ from 'lodash';

@Injectable()
export class UserRoleListService implements IUserListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private cdr: ChangeDetectorRef,
        private userRoleService: UserRoleService, private utils: UtilsService, private state: StateService) {
    }

    async initDelegate(states: IUserListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IUserListStates) {
        states.userRoles = await this.dataAccess.genericMethod({
            model: 'ArgosUserRole', method: 'find', parameters: {
                filter: {
                    order: 'roleName',
                    include: ['argosUser', 'argosUserRoleHistories'] 
                } 
            }
        });

        _.forEach(states.userRoles, (userRole) => {
            userRole.numberOfUsers = userRole.argosUser.length;
        });

        states.userRoleTable.data = states.userRoles;
    }

    async saveRow(row: any, states: IUserListStates) {
        await this.dataAccess.genericUpsert({
            model: 'ArgosUser',
            data: row
        });
        states.editingMode = false;
        row.editMode = false;
    }

    async syncRolePermissions(states: IUserListStates) { 
        let roles = _.filter(states.userRoles, (role) => ['admin', 'inactive'].includes(role.roleName) === false);
        for (let i = 0; i < roles.length; i++) {
            let role = roles[i];
            let originalUserRole = _.cloneDeep(role);
            role.notes = 'synced latest required api access list';
            role.argosMenus = await this.userRoleService.initMenuSelections(role, this.state);  //pull the latest menu and permissions
            await this.userRoleService.saveMenuSelections(role, originalUserRole, role.argosMenus); //save the menu and permissions against the roles selected permissions
        }

        this.state.go('argos.admin.userRoles.list', {}, { reload: true });
    }

    // cancelEdit(row) {
    //     states.editingMode = false;
    //     row.editMode = false;
    // }

    openMatTableSettings(states: IUserListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.environmentMatTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
