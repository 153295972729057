import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IChartModalProps, IChartModalStates } from './pipelineHistoryChartModel.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ChartModalService } from './pipelineHistoryChartModel.component.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'app-chartModal',
    templateUrl: './pipelineHistoryChartModel.component.html',
    styleUrls: ['./pipelineHistoryChartModel.component.less'],
    providers: [ChartModalService]
})

export class PipelineHistoryChartModelComponent extends ReactComponentBase<IChartModalProps, IChartModalStates, {}>
    implements OnInit {

    constructor(private svc: ChartModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<PipelineHistoryChartModelComponent>, private cdr: ChangeDetectorRef) {
        super({
            chartTitle: 'Pipeline History Bar Chart',
            backgroundColors: [
                'rgba(75, 192, 192, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(255, 99, 132, 0.5)',
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 205, 86, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(201, 203, 207, 0.5)'
            ],
            pointTextNull: true
        } as unknown as IChartModalStates);
        this.props = dialogData.props;
    }
    ngOnInit() {
        const newStates = this.svc.initDelegate(this.props, this.states);
        this.setStates(newStates);
        this.cdr.detectChanges();
    }

    close() {
        this.dialogRef.close('cancel');
    }

    filterChange() {
        this.svc.filterChangeHandler(this.states);
    }
}
