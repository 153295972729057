import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IReImportEnvironmentListModalProps, IReImportEnvironmentListModalStates } from './reImportEnvironmentListModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ReImportEnvironmentListModalService } from './reImportEnvironmentListModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
@Component({
    selector: 'argos-reImportEnvironmentListModal',
    templateUrl: './reImportEnvironmentListModal.component.html',
    providers: [ReImportEnvironmentListModalService]
})

export class ReImportEnvironmentListModalComponent
    extends ReactComponentBase<IReImportEnvironmentListModalProps, IReImportEnvironmentListModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ReImportEnvironmentListModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ReImportEnvironmentListModalComponent>) {
        super({ selected: {} } as IReImportEnvironmentListModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(_.keys(this.states.selected));
    }
}
