import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IViewVersionHistoryModalProps, IViewVersionHistoryModalStates } from './viewVersionHistoryModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewVersionHistoryModalService } from './viewVersionHistoryModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OutputFormatType } from 'diff2html/lib/types';
import * as Diff2Html from 'diff2html';
import { createPatch } from 'diff';
import * as _ from 'lodash';

@Component({
    selector: 'ArgosUser-viewVersionHistoryModal',
    templateUrl: './viewVersionHistoryModal.component.html',
    providers: [ViewVersionHistoryModalService]
})

export class ViewVersionHistoryModalComponent extends ReactComponentBase<IViewVersionHistoryModalProps, IViewVersionHistoryModalStates, {}> implements OnInit, OnChanges {
    _: any = _;

    constructor(private svc: ViewVersionHistoryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewVersionHistoryModalComponent>, private cdr: ChangeDetectorRef) {
        super({
            showDiffs: false,
            versionHistory: []
        } as IViewVersionHistoryModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    closeModal() {
        this.dialogRef.close({});
    }

    showDiffsScreen(show: boolean) {
        this.states.showDiffs = show;
        this.states.diffJsonValue = null;
        this.cdr.detectChanges();
    }

    viewDiffs(version: any) {
        this.svc.viewDiffs(version, this.states);
        this.cdr.detectChanges();
    }

    viewJsonDiff(title: string, prevData: any, currentData: any) {
        let toStr = JSON.stringify(currentData, undefined, 2) || '';
        const fromStr = JSON.stringify(prevData, undefined, 2) || '';
        const outputFormat: OutputFormatType = 'side-by-side';

        if (fromStr === toStr) {
            // If the strings are identical, add a newline to one of them
            // to ensure that a diff is generated
            toStr += '\n';
        }

        const diffStr = createPatch(title, fromStr, toStr, undefined, undefined, { context: 99999999 });
        this.states.diffJsonValue = Diff2Html.html(diffStr, { drawFileList: false, matching: 'words', renderNothingWhenEmpty: false, outputFormat });
    }
}
