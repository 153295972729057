import { Component, OnInit } from '@angular/core';
import { IAllowedFieldListStates } from './allowedFieldList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { AllowedFieldListService } from './allowedFieldList.component.service';

@Component({
    selector: 'argos-allowedFieldList',
    templateUrl: './allowedFieldList.component.html',
    providers: [AllowedFieldListService]
})

export class AllowedFieldListComponent extends ReactComponentBase<{}, IAllowedFieldListStates, {}> implements OnInit {
    constructor(private svc: AllowedFieldListService) {
        super();
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
    }
}
