import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';

@Component({
    selector: 'argos-inactive',
    templateUrl: './inactive.component.html'
})

export class InactiveComponent extends ReactComponentBase<{}, {}, {}> {
    constructor() {
        super();
    }
}
