module.exports = {
    Dashboard: [
        {
            sref: 'argos.dashboard',
            name: 'Dashboard'
        }
    ],
    DataCollapse: [
        {
            sref: 'argos.data.datasource.list',
            name: 'Data Sources',
        },
        {
            sref: 'argos.data.terratestresults.list',
            name: 'Tera Test Results'
        },
        {
            sref: 'argos.data.stageSourceTable.list',
            name: 'Deequ Tests'
        },
        {
            sref: 'argos.data.pctTestResults.list',
            name: 'Atlas Release Test Results'
        },
        {
            sref: 'argos.data.pipeline_history.list',
            name: 'Pipeline History'
        },
        {
            sref: 'argos.data.pipeline_queue.list',
            name: 'Pipeline Queue'
        },
        {
            sref: 'argos.data.pipeline_error_map.list',
            name: 'EMR Error Mapping'
        },
        {
            sref: 'argos.data.data_dashboard',
            name: 'DWMD (Dude, where\'s my data?)'
        },
        {
            sref: 'argos.data.scratch_database_cleanup.list',
            name: 'Scratch Database Cleanup Manager'
        },
        {
            sref: 'argos.data.data_release_manager.list',
            name: 'Data Release Manager'
        },
        {
            sref: 'argos.data.clarifyBot.list',
            name: 'ClaraIQ Documentation'
        },
        {
            sref: 'argos.data.data_ai_chat',
            name: 'AI Text-to-SQL'
        },
        {
            sref: 'argos.data.dataProvisioningQuestionnaire',
            name: 'Data Provisioning Questionnaires'
        },
        {
            sref: 'argos.data.release_notes',
            name: 'Generate Release Notes'
        }
    ],
    HospitalRatesCollapse: [
        {
            sref: 'argos.hospitalRates.list',
            name: 'Hospital Rates Dashboard'
        }
    ],
    ClarifyInsightsCollapse: [
        {
            sref: 'argos.clarifyInsightsDeliveryHub',
            name: 'Data Delivery Hub'
        },
        {
            sref: 'argos.clarifyInsights',
            name: 'Customers'
        },
        {
            sref: 'argos.clarifyInsightsFilter.list',
            name: 'Filters'
        },
        {
            sref: 'argos.clarifyInsightsDataEvaluationUser',
            name: 'Data Evaluation Users'
        }
    ],
    EnvironmentsCollapse: [
        {
            sref: 'argos.environments.clients.list',
            name: 'Clients'
        },
        {
            sref: 'argos.environments.environments.list',
            name: 'Environments'
        },
        {
            sref: 'argos.environments.clientCareGroupings.list',
            name: 'Care Grouping Usage'
        },
        {
            sref: 'argos.environments.builds.list',
            name: 'Database Builds'
        },
        {
            sref: 'argos.environments.environment20Requests.list',
            name: 'Environment Requests'
        },
        {
            sref: 'argos.environments.filterPerformance',
            name: 'Filter Performance'
        },
        {
            sref: 'argos.environments.athenaUsage',
            name: 'Athena Usage'
        },
        {
            sref: 'argos.environments.queryPerformance',
            name: 'Query Performance'
        },
        {
            sref: 'argos.environments.querySettings',
            name: 'Query Settings'
        },
        {
            sref: 'argos.environments.salesforce_sync_status',
            name: 'Salesforce Sync Status'
        }
    ],
    AtlasCollapse: [
        {
            sref: 'argos.prism.prism_notifications',
            name: 'Atlas Notifications'
        },
        {
            sref: 'argos.prism.product.list',
            name: 'Products'
        },
        {
            sref: 'argos.prism.productBundles.list',
            name: 'Product Bundles'
        },
        {
            sref: 'argos.prism.product.sync',
            name: 'Product Sync'
        },
        {
            sref: 'argos.prism.patch_management.list',
            name: 'Patch Management'
        },
        {
            sref: 'argos.prism.prismUsage',
            name: 'Atlas Meta-Data Usage'
        },
        {
            sref: 'argos.prism.atlasClaraChatHistory',
            name: 'Atlas ClaraIQ Chat History'
        },
        {
            sref: 'argos.prism.automatedGoldPulldown.list',
            name: 'Automated Gold Pull Down'
        },
        {
            sref: 'argos.prism.columnUsage',
            name: 'Column Usage'
        }
    ],
    CCGDesignerCollapse: [
        {
            sref: 'argos.ccgDesigner.programmodels.list',
            name: 'Program Models'
        },
        {
            sref: 'argos.ccgDesigner.programepisodes.list',
            name: 'Program Episodes'
        },
        {
            sref: 'argos.ccgAdmin.careGroupings.list',
            name: 'Care Groupings'
        },
        {
            sref: 'argos.ccgDesigner.observations.list',
            name: 'Observations'
        },
        {
            sref: 'argos.ccgDesigner.observationHierarchies.list',
            name: 'Observations Hierarchies'
        },
        {
            sref: 'argos.ccgDesigner.episodeGroupings.list',
            name: 'Groupings/Filters'
        },
        {
            sref: 'argos.ccgDesigner.joinEntities.list',
            name: 'Join Definitions'
        },
        {
            sref: 'argos.ccgDesigner.prism1Metrics.list',
            name: 'Other Metrics'
        },
        {
            sref: 'argos.ccgDesigner.compoundFilters.list',
            name: 'Compound Filters'
        },
        {
            sref: 'argos.ccgDesigner.validValueQueries.list',
            name: 'Valid Value Queries'
        },
        {
            sref: 'argos.ccgDesigner.modelFeatures.list',
            name: 'Model Features'
        },
        {
            sref: 'argos.ccgDesigner.ccgfeatures.list',
            name: 'CCG Features'
        },
        {
            sref: 'argos.ccgDesigner.observationException.list',
            name: 'Exception List'
        },
        {
            sref: 'argos.ccgDesigner.ccgReportList',
            name: 'CCG Reports'
        },
        {
            sref: 'argos.ccgDesigner.markedForDeletion.list',
            name: 'Marked for Deletion'
        },
        {
            sref: 'argos.ccgDesigner.versionhistory.list',
            name: 'Version History'
        },
        {
            sref: 'argos.ccgDesigner.ccgReferenceData.list',
            name: 'CCG Reference Data'
        }
    ],
    ClarifyReportsCollapse: [
        {
            sref: 'argos.clarifyReports.list',
            name: 'Clarify Reports List'
        }
    ],
    ReferenceDataCollapse: [
        {
            sref: 'argos.referenceData.referenceLists.list',
            name: 'Reference Lists'
        },
        {
            sref: 'argos.referenceData.referenceDataSources.list',
            name: 'Reference Data'
        },
        {
            sref: 'argos.referenceData.clarifyProviderRevision.list',
            name: 'Clarify Provider Overrides'
        },
        {
            sref: 'argos.referenceData.teraUsers.list',
            name: 'Tera Users'
        }
    ],
    ModelMetaDataCollapse: [
        {
            sref: 'argos.modelMetaData.advanceMetaData.list',
            name: 'Advance CCGs'
        },
        {
            sref: 'argos.modelMetaData.marketShareMeta.list',
            name: 'Market Share CCG'
        },
        {
            sref: 'argos.modelMetaData.payerRatesMeta.list',
            name: 'Payer Rates'
        },
        {
            sref: 'argos.modelMetaData.pceMetaData.list',
            name: 'PCE CCG'
        }
    ],
    NetworksCollapse: [
        { sref: 'argos.networkDesigner.networksStatus', name: 'Networks Status' },
        { sref: 'argos.networkDesigner.attributedProviderCCG.sourceSchemas', name: 'Source Schemas' },
        { sref: 'argos.networkDesigner.attributedProviderCCG.providerTypes', name: 'Provider Types' },
        { sref: 'argos.networkDesigner.attributedProviderCCG.definition', name: 'Definition' }
    ],
    ECSCollapse: [
        {
            sref: 'argos.ecsAccess.ecsAppList',
            name: 'ECS Apps'
        },
        {
            sref: 'argos.ecsAccess.ecsRepoEdit',
            name: 'ECS Image Repo'
        }
    ],
    AdminCollapse: [
        {
            sref: 'argos.admin.users',
            name: 'Users'
        },
        {
            sref: 'argos.admin.userRoles.list',
            name: 'User Roles'
        },
        {
            sref: 'argos.admin.userEngagement',
            name: 'User Engagement'
        },
        {
            sref: 'argos.admin.ecsDashboard',
            name: 'ECS Dashboard'
        },
        {
            sref: 'argos.admin.ecsActivityLogList',
            name: 'ECS Event Log'
        }
    ]
}
