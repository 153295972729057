import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IAdvanceMetaDataEditStates, IAdvanceMetaDataEditService } from './advanceMetaDataEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as XLSX from 'xlsx';
import * as Bluebird from 'bluebird';
import swal from 'sweetalert2';
declare const saveAs: any;

const CHUNK_SIZE = 2000;

@Injectable()
export class AdvanceMetaDataEditService implements IAdvanceMetaDataEditService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private state: StateService, private changeDetectRef: ChangeDetectorRef, private argosStore: ArgosStoreService) {
    }

    async initDelegate(states: IAdvanceMetaDataEditStates): Promise<object> {
        await this.init(states);
        this.checkForSalesforceRevisions(states);
        return {};
    }

    async init(states: IAdvanceMetaDataEditStates) {
        states.runType = this.uiRouter.globals.params.runType;
        states.programNameFilter = states.runType === 'electiveProcedure' ? 'ep' : 'sr';
        states.lodash = _;
        states.dataSources = await this.dataAccess.genericFind({
            model: 'DataSource',
            filter: {
                or: [
                    {dataType: 'claim'},
                    {code: 'combined'}
                ],
                order: 'name ASC'
            }
        });

        if (this.uiRouter.globals.params.id) {

            await this.setAdvanceMetaData(states);

            this.setDataSourceFields(states);
        } else {
            states.advanceMetaData = {id: undefined};
        }

        if (states.runType === 'specialistReferral') {
            const specialties = await this.dataAccess.genericFind({
                model: 'ReferenceDataListItem',
                filter: {
                    where: { listId: 625}
                }

            });
            states.specialties = _.map(specialties, 'key').sort();
        }

        const tables = await this.dataAccess.genericFind({
            model: 'TeradromeTable',
            filter: {
                include: 'teradromeColumn',
                where: {
                    tableName: { inq: ['claim_header', 'claim_line'] }
                }
            }
        });
        
        const fieldsByTable = _.groupBy(tables, 'tableName');

        states.headerAmountFields = _.uniq(_.flatten(fieldsByTable['claim_header']?.map((table: any) => 
            table.teradromeColumn.filter((column: any) => column.columnName.includes('_amt'))
                .map((column: any) => column.columnName)
        )) || []).sort();
        
        states.lineAmountFields = _.uniq(_.flatten(fieldsByTable['claim_line']?.map((table: any) => 
            table.teradromeColumn.filter((column: any) => column.columnName.includes('_amt'))
                .map((column: any) => column.columnName)
        )) || []).sort();

        // Hard coded for now as pce predictions data hasn't been reported
        states.pceAmountFields = [
            'episode_allowed_amount',
            'episode_clarify_standard_amount_final',
            'episode_clarify_standard_amount',
            'episode_paid_amount',
            'initial_paid_amt',
            'initial_standardized_allowed_amt',
            'prediction_episode_clarify_standard_amount_final',
        ];
          
    }

    async setAdvanceMetaData(states: IAdvanceMetaDataEditStates, id: string = this.uiRouter.globals.params.id) {
        const advanceMetaData = await this.dataAccess.genericMethod({
            model: states.runType === 'electiveProcedure' ? 'AdvanceEpMetaData' : 'AdvanceSrMetaData',
            method: 'findById',
            parameters: {
                id,
                filter: {
                    include: 'dataSource'
                }
            }
        });

        if (states.runType === 'specialistReferral' || (states.runType === 'electiveProcedure' && advanceMetaData.useRollingYear)) {
            advanceMetaData.baselineStartDate = new Date(advanceMetaData.baselineStartDate).toISOString().slice(0, 10);
            advanceMetaData.baselineEndDate = new Date(advanceMetaData.baselineEndDate).toISOString().slice(0, 10);
        }

        states.advanceMetaData = _.cloneDeep(advanceMetaData);

        states.advanceMetaData.lastRun = (states.advanceMetaData.lastRun) ? new Date(states.advanceMetaData.lastRun).toISOString().slice(0, 10) : undefined;
        states.advanceMetaData.programStartDate = (states.advanceMetaData.programStartDate) ? new Date(states.advanceMetaData.programStartDate).toISOString().slice(0, 10) : undefined;
    }

    setDataSourceFields(states: IAdvanceMetaDataEditStates) {
        // get dataSource object from states.dataSources
        const dataSource = states.dataSources.find((dataSource: any) => {
            return dataSource.id === states.advanceMetaData.dataSourceId;
        });
        const firstYear = (dataSource.firstClaimDate) ? new Date(dataSource.firstClaimDate).getFullYear() : 2018;
        const lastYear = (dataSource.lastClaimDate) ? new Date(dataSource.lastClaimDate).getFullYear() : new Date().getFullYear();

        states.years = _.range(firstYear, lastYear + 1);

        if (states.runType === 'specialistReferral' && !states.advanceMetaData.srEpisodeAmtField) {
            states.advanceMetaData.srEpisodeAmtField = (dataSource.sourceType === 'vendor' || dataSource.dataType === 'other') ? 'episode_clarify_standard_amount_final' : 'episode_paid_amount';
        }
    }

    async save(states: IAdvanceMetaDataEditStates) {
        if (states.advanceMetaData.useRollingYear) {
            states.advanceMetaData.ccgYears = '{}';
        }
        if (states.runType === 'electiveProcedure' && !states.advanceMetaData.useRollingYear) {
            states.advanceMetaData.baselineStartDate = null;
            states.advanceMetaData.baselineEndDate = null;
        }

        // Ran into an issue trying to clear date, so setting to null if empty
        if (!states.advanceMetaData.programStartDate) {
            states.advanceMetaData.programStartDate = null;
        }

        if (typeof states.advanceMetaData.ccgYears !== 'string' && states.advanceMetaData.ccgYears && states.advanceMetaData.ccgYears.length > 0) {
            states.advanceMetaData.ccgYears = '{' + states.advanceMetaData.ccgYears.join(',') + '}';
        }
        states.saveInProgress = true;
        states.advanceMetaData = await this.dataAccess.genericUpsert({
            model: states.runType === 'electiveProcedure' ? 'AdvanceEpMetaData' : 'AdvanceSrMetaData',
            data: states.advanceMetaData
        });

        this.setAdvanceMetaData(states, states.advanceMetaData.id);

        const promisesData: any[] = [];
        let summary = '';
        const updatedTables = [];
        if (states.newNpiRevisions && states.newNpiRevisions.length > 0) {
            let npiRevisions = await this.dataAccess.genericFind({
                model: 'ClarifyProviderNpiRevision',
                filter: {
                    where: {
                        npi: { inq: states.newNpiRevisions.map(revision => String(revision.npi)) }
                    }
                }
            });

            npiRevisions = _.filter(npiRevisions, npiRevision => npiRevision.dataSourcesToApply.includes(states.advanceMetaData?.dataSource?.code));
            
            // Create a Map for faster lookup of NPI revisions
            const npiRevisionMap = new Map();
            if (npiRevisions.length > 0) {
                npiRevisions.forEach((npiRevision: any) => {
                    npiRevisionMap.set(String(npiRevision.npi), npiRevision);
                });
            }
            
            summary += `NPI Revisions: ${states.newNpiRevisions.length} rows added/edited\n`;
            updatedTables.push('ClarifyProviderNpiRevision');

            const newRevisions: any[] = [];
            const existingRevisions: any[] = [];

            states.newNpiRevisions.forEach((revision: any) => {
                const existingNpiRevision = npiRevisionMap.get(String(revision.npi));
                if (existingNpiRevision) {
                    revision.id = existingNpiRevision.id;
                    existingRevisions.push(revision);
                } else {
                    newRevisions.push(revision);
                }
            });

            // Process new revisions in chunks
            const newChunks = _.chunk(newRevisions, CHUNK_SIZE);
            newChunks.forEach((chunk) => {
                promisesData.push({
                    action: 'createMany',
                    model: 'ClarifyProviderNpiRevision',
                    data: chunk
                });
            });

            existingRevisions.forEach((revision) => {
                promisesData.push({
                    action: 'upsert',
                    model: 'ClarifyProviderNpiRevision',
                    data: revision
                });
            });
        }

        if (states.newAttributedParentIdRevisions && states.newAttributedParentIdRevisions.length > 0) {
            let parentIdRevisions = await this.dataAccess.genericFind({
                model: 'ClarifyProviderAttributedParentIdRevision',
                filter: {
                    where: {
                        attributedParentId: { inq: states.newAttributedParentIdRevisions.map(revision => String(revision.attributedParentId)) }
                    }
                }
            });

            parentIdRevisions = _.filter(parentIdRevisions, parentIdRevision => parentIdRevision.dataSourcesToApply.includes(states.advanceMetaData?.dataSource?.code));

            // Create a Map for faster lookup of parentId revisions
            const parentIdRevisionMap = new Map();
            if (parentIdRevisions.length > 0) {
                parentIdRevisions.forEach((parentIdRevision: any) => {
                    parentIdRevisionMap.set(String(parentIdRevision.attributedParentId), parentIdRevision);
                });
            }
            
            summary += `Attributed Parent Id Revisions: ${states.newAttributedParentIdRevisions.length} rows added/edited\n`;
            updatedTables.push('ClarifyProviderAttributedParentIdRevision');

            const newRevisions: any[] = [];
            const existingRevisions: any[] = [];

            states.newAttributedParentIdRevisions.forEach((revision: any) => {
                const existingParentIdRevision = parentIdRevisionMap.get(String(revision.attributedParentId));
                if (existingParentIdRevision) {
                    revision.id = existingParentIdRevision.id;
                    existingRevisions.push(revision);
                } else {
                    newRevisions.push(revision);
                }
            });

            const newChunks = _.chunk(newRevisions, CHUNK_SIZE);
            newChunks.forEach((chunk) => {
                promisesData.push({
                    action: 'createMany',
                    model: 'ClarifyProviderAttributedParentIdRevision',
                    data: chunk
                });
            });

            existingRevisions.forEach((revision) => {
                promisesData.push({
                    action: 'upsert',
                    model: 'ClarifyProviderAttributedParentIdRevision',
                    data: revision
                });
            });
        }

        if (states.newPhysicianGroupRevisions && states.newPhysicianGroupRevisions.length > 0) {
            let physicianGroupRevisions = await this.dataAccess.genericFind({
                model: 'ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision',
                filter: {
                    where: {
                        primaryPhysicianGroupAffiliationNpi: { inq: states.newPhysicianGroupRevisions.map(revision => String(revision.primaryPhysicianGroupAffiliationNpi)) }
                    }
                }
            });

            physicianGroupRevisions = _.filter(physicianGroupRevisions, pGRevision => pGRevision.dataSourcesToApply.includes(states.advanceMetaData?.dataSource?.code));

            // Create a Map for faster lookup of physician group revisions
            const physicianGroupRevisionMap = new Map();
            if (physicianGroupRevisions.length > 0) {
                physicianGroupRevisions.forEach((pgRevision: any) => {
                    physicianGroupRevisionMap.set(String(pgRevision.primaryPhysicianGroupAffiliationNpi), pgRevision);
                });
            }
            
            summary += `Primary Physician Group Revisions: ${states.newPhysicianGroupRevisions.length} rows added/edited\n`;
            updatedTables.push('ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision');

            const newRevisions: any[] = [];
            const existingRevisions: any[] = [];

            states.newPhysicianGroupRevisions.forEach((revision: any) => {
                const existingPhysicianGroupRevision = physicianGroupRevisionMap.get(String(revision.primaryPhysicianGroupAffiliationNpi));
                if (existingPhysicianGroupRevision) {
                    revision.id = existingPhysicianGroupRevision.id;
                    existingRevisions.push(revision);
                } else {
                    newRevisions.push(revision);
                }
            });

            const newChunks = _.chunk(newRevisions, CHUNK_SIZE);
            newChunks.forEach((chunk) => {
                promisesData.push({
                    action: 'createMany',
                    model: 'ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision',
                    data: chunk
                });
            });

            existingRevisions.forEach((revision) => {
                promisesData.push({
                    action: 'upsert',
                    model: 'ClarifyProviderPrimaryPhysicianGroupAffiliationNpiRevision',
                    data: revision
                });
            });
        }

        if (states.refData) {
            const models = [
                {
                    refDataKey: 'facilityExclPhysGroup',
                    model: 'AdvanceEpAttributedParentIdPhysicianGroupExclusion',
                    uniqueFields: ['attributedParentId', 'primaryPhysicianGroupAffiliationNpi']
                },
                {
                    refDataKey: 'facilityExclProv',
                    model: 'AdvanceEpAttributedParentIdNpiExclusion',
                    uniqueFields: ['attributedParentId', 'npi']
                },
                {
                    refDataKey: 'facilityExclAll',
                    model: 'AdvanceEpAttributedParentIdExclusion',
                    uniqueFields: ['attributedParentId']
                },
                {
                    refDataKey: 'groupEligibility',
                    model: 'AdvancePrimaryPhysicianGroupAffiliationNpiEligibility',
                    uniqueFields: ['primaryPhysicianGroupAffiliationNpi', 'programName', 'reason']
                },
                {
                    refDataKey: 'npiEligibility',
                    model: 'AdvanceNpiEligibility',
                    uniqueFields: ['npi', 'programName', 'reason']
                },
                {
                    refDataKey: 'alternateFacilitySelection',
                    model: 'AdvanceEpAlternateFacilitySelection',
                    uniqueFields: ['providerNpi', 'procedureGroup', 'attributedParentId', 'placeOfSvc', 'providerZip']
                },
                {
                    refDataKey: 'specialistExclProv',
                    model: 'AdvanceSrRenderingProviderNpiReferringNpiExclusion',
                    uniqueFields: ['renderingProviderNpi', 'referringProviderNpi']
                },
                {
                    refDataKey:'specialistExclAll',
                    model: 'AdvanceSrRenderingProviderNpiExclusion',
                    uniqueFields: ['renderingProviderNpi']
                },
                {
                    refDataKey: 'specialistExclPhysGroup',
                    model: 'AdvanceSrRenderingProviderNpiPrimaryPhysicianGroupExclusion',
                    uniqueFields: ['primaryPhysicianGroupAffiliationNpi', 'renderingProviderNpi']
                }
            ];

            for (const model of models) {
                if (states.refData[model.refDataKey]) {
                    let refData = await this.dataAccess.genericFind({
                        model: model.model,
                        filter: {
                            where: { dataSource: states.advanceMetaData?.dataSource?.code }
                        }
                    });

                    const uniqueFields = model.uniqueFields;
                    const newRefData = states.refData[model.refDataKey];

                    if (model.refDataKey === 'alternateFacilitySelection') {
                        _.forEach(newRefData, entry => entry.effectiveDate = new Date(entry.effectiveDate + 'T00:00:00Z'));
                    }

                    if (model.uniqueFields.includes('programName')) {
                        refData = _.filter(refData, (data: any) => {
                            return data.programName === states.programNameFilter;
                        });
                        _.forEach(newRefData, entry => entry.programName = states.programNameFilter);
                    }

                    const seenFields = new Set();

                    const newUniqueFields = _.map(newRefData, (data: any) => {
                        const uniqueField = _.pick(data, uniqueFields);
                        const fieldString = JSON.stringify(_.omit(uniqueField, 'reason'));
                        if (seenFields.has(fieldString)) {
                            swal({
                                title: 'Duplicate Rows Detected',
                                html: `Duplicate rows detected for ${model.model} Object: ${fieldString}`,
                                type: 'error'
                            });
                            this.changeDetectRef.detectChanges();
                            throw new Error('Duplicate rows detected');

                        }
                        seenFields.add(fieldString);
                        return uniqueField;
                    });

                    const precomputedNewUniqueFields = new Map(newUniqueFields.map(nuf => [
                        _.map(uniqueFields, field => String(nuf[field])).join('|'),
                        nuf
                    ]));

                    if (refData) {
                        const deleteRefData = refData.filter((rd: any) => {
                            const key = _.map(uniqueFields, field => String(rd[field])).join('|');
                            return !precomputedNewUniqueFields.has(key);
                        });

                        if (deleteRefData.length) {
                            summary += `${_.startCase(model.refDataKey)}: ${deleteRefData.length} rows deleted\n`;
                            updatedTables.push(model.model);
                            const deleteFunctions = deleteRefData.map((data: any) => {
                                return {
                                    action: 'delete',
                                    model: model.model,
                                    id: data.id
                                };
                            });
                            promisesData.push(...deleteFunctions);
                        }
                    }

                    const precomputedRefData = new Map(refData.map((rd: any) => [
                        _.map(uniqueFields, field => String(rd[field])).join('|'),
                        rd
                    ]));

                    const addRefData = newRefData.filter((nrd: any) => {
                        const key = _.map(uniqueFields, field => String(nrd[field])).join('|');
                        return !precomputedRefData.has(key);
                    });

                    if (addRefData.length) {
                        summary += `${_.startCase(model.refDataKey)}: ${addRefData.length} rows added\n`;
                        updatedTables.push(model.model);
                        const chunks = _.chunk(addRefData.map((data: any) => {
                            data.dataSource = states.advanceMetaData?.dataSource?.code;
                            data['advance' + _.capitalize(states.programNameFilter) + 'MetaDataId'] = states.advanceMetaData.id;
                            return data;
                        }), CHUNK_SIZE);

                        chunks.forEach((chunk) => {
                            promisesData.push({
                                action: 'createMany',
                                model: model.model,
                                data: chunk
                            });
                        });
                    }
                }
            }
        }
        if (!summary) {
            this.state.go('argos.modelMetaData.advanceMetaData.list');
        } else {
            const argosTables = _.uniq(updatedTables).map(table =>
                _.snakeCase(table)
            );
            swal({
                title: 'Continue with Saving Reference Data?',
                html: summary ? summary.replace(/\n/g, '<br>') : 'No changes made to reference data',
                type: 'success',
                showCancelButton: true,
                confirmButtonColor: '#57C84D', confirmButtonText: 'Save',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    await this.dataAccess.genericMethod({
                        model: 'Advance',
                        method: 'initilizeRefDataSync',
                        parameters: {
                            promisesData
                        }
                    });
    
                    if (states.excelUploadPromise && states.excelUploadPromise.length > 0) {
                        await Bluebird.all(states.excelUploadPromise.map((promise: any) => promise()));
                    }

                    swal({
                        title: `Would you like to trigger the Argos Table pipeline for the updated data?`,
                        html: `Updated tables: ${argosTables.join(', ')}`,
                        type: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#57C84D',
                        confirmButtonText: 'Run Pipeline',
                        cancelButtonText: "Don't Run"
                    }).then(async (secondConfirm: any) => {
                        if (secondConfirm.value) {
                            const dagId = 'reference-data-argos_table-pipeline';
                            const triggeredByUser = await this.argosStore.getItem('username').replace('@clarifyhealth.com', '');
                            const astronomerResponse = await this.dataAccess.genericMethod({
                                model: 'Astronomer',
                                method: 'triggerDag',
                                parameters: {
                                    dagId, 
                                    dagConfig: JSON.stringify({ ref_data_sources_to_run: argosTables }),
                                    triggeredByUser,
                                }
                            });

                            const dagsUrl = await this.dataAccess.genericMethod({
                                model: 'Astronomer',
                                method: 'getDagsUrl'
                            });

                            swal({
                                title: 'Pipeline Started Successfully',
                                text: 'Would you like to go to Airflow to view the running pipeline or return to the list?',
                                type: 'success',
                                showCancelButton: true,
                                confirmButtonColor: '#57C84D',
                                confirmButtonText: 'Go to Airflow',
                                cancelButtonText: 'Return to List'
                            }).then((thirdConfirm: any) => {
                                if (thirdConfirm.value) {
                                    window.location.href = `${dagsUrl.data}/${dagId}/grid?dag_run_id=${encodeURIComponent(astronomerResponse.data.dag_run_id)}`;
                                } else {
                                    this.state.go('argos.modelMetaData.advanceMetaData.list');
                                }
                            });
                        }
                    });
                } else {
                    states.saveInProgress = false;
                    this.changeDetectRef.detectChanges();
                }
            });
        }
    }

    async uploadFile(file: any, states: IAdvanceMetaDataEditStates) {
        states.newNpiRevisions = [];
        states.newAttributedParentIdRevisions = [];
        states.newPhysicianGroupRevisions = [];
        states.refData = {};

        const file_obj = file.target.files[0];
        const reader = new FileReader();

        reader.onload = (async function (this: AdvanceMetaDataEditService) {
            const data = reader.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            const processSheetInChunks = (sheetName: any, jsonData: any[]) => {
                for (let i = 0; i < jsonData.length; i += CHUNK_SIZE) {
                    const chunk = jsonData.slice(i, i + CHUNK_SIZE);

                    if (sheetName === 'prov_npi_general') {
                        const seenNpis = new Set<string>();

                        states.newNpiRevisions.push(...chunk.map((row: any) => {
                            const npi = row.npi;
                            if (seenNpis.has(npi)) {
                                swal({
                                    title: 'Duplicate Rows Detected',
                                    html: `Duplicate rows detected for NPI ${npi}`,
                                    type: 'error'
                                });
                                this.changeDetectRef.detectChanges();
                                throw new Error('Duplicate rows detected');
                            }
                            seenNpis.add(npi);

                            const revisionValues = _.mapValues(_.mapKeys(row, function (value: any, key: any) {
                                if ([ 'npi' ].includes(key)) {
                                    return key;
                                }
                                return _.camelCase('revised_' + key);
                            }), (value) => {
                                return typeof value === 'string' ? value.trim() ? _.lowerCase(value) === 'remove' ? null : value.trim() : undefined : value;
                            });
                            revisionValues.dataSourcesToApply = [ states.advanceMetaData?.dataSource?.code ];
                            return revisionValues;
                        }));
                    } else if (['combine_facilities', 'facility_name_address'].includes(sheetName)) {
                        const seenIds = new Set<string>();
                        states.newAttributedParentIdRevisions.push(...chunk.map((row: any) => {
                            const parentId = row.attributed_parent_id;
                            if (seenIds.has(parentId)) {
                                swal({
                                    title: 'Duplicate Rows Detected',
                                    html: `Duplicate rows detected for Parent ID ${parentId}`,
                                    type: 'error'
                                });
                                this.changeDetectRef.detectChanges();
                                throw new Error('Duplicate rows detected');
                            }
                            seenIds.add(parentId);

                            const revisionValues = _.mapValues(_.mapKeys(row, function (value: any, key: any) {
                                if (key === 'attributed_parent_id') {
                                    return _.camelCase(key);
                                }
                                return sheetName === 'facility_name_address' ? _.camelCase('revised_' + key) : _.camelCase(key.replace('new_', 'revised_'));
                            }), (value) => {
                                return typeof value === 'string' ? value.trim() ? _.lowerCase(value) === 'remove' ? null : value.trim() : undefined : value;
                            });
                            revisionValues.dataSourcesToApply = [ states.advanceMetaData?.dataSource?.code ];
                            return revisionValues;
                        }));
                    } else if (['phys_group_name_address', 'combine_phys_group'].includes(sheetName)) {
                        const seenNpis = new Set<string>();
                        states.newPhysicianGroupRevisions.push(...chunk.map((row: any) => {
                            const npi = row.primary_physician_group_affiliation_npi;
                            if (seenNpis.has(npi)) {
                                swal({
                                    title: 'Duplicate Rows Detected',
                                    html: `Duplicate rows detected for Physician Group NPI ${npi}`,
                                    type: 'error'
                                });
                                this.changeDetectRef.detectChanges();
                                throw new Error('Duplicate rows detected');
                            }
                            seenNpis.add(npi);

                            const revisionValues = _.mapValues(_.mapKeys(row, function (value: any, key: any) {
                                if (key === 'primary_physician_group_affiliation_npi') {
                                    return _.camelCase(key);
                                }
                                return sheetName === 'combine_phys_group' ? _.camelCase(key.replace('new_', 'revised_')) : _.camelCase('revised_' + key);
                            }), (value) => {
                                return typeof value === 'string' ? value.trim() ? _.lowerCase(value) === 'remove' ? null : value.trim() : undefined : value;
                            });
                            revisionValues.dataSourcesToApply = [ states.advanceMetaData?.dataSource?.code ];
                            return revisionValues;
                        }));
                    } else if (['facility_excl_phys_group', 'facility_excl_prov', 'facility_excl_all', 'group_eligibility', 'npi_eligibility', 'alternate_facility_selection', 'specialist_excl_prov', 'specialist_excl_all', 'specialist_excl_phys_group'].includes(sheetName.trim())) {
                        if (!states.refData[_.camelCase(sheetName)]) states.refData[_.camelCase(sheetName)] = [];
                        states.refData[_.camelCase(sheetName)].push(...chunk.map((row: any) => {
                            return _.mapValues(_.mapKeys(row, function (value: any, key: any) {
                                return _.camelCase(key);
                            }), (value) => {
                                return typeof value === 'string' ? value.trim() ? _.lowerCase(value) === 'remove' ? null : value.trim() : undefined : value;
                            });
                        }));
                    } else if (sheetName.trim() === 'pcp_npi_eligibility') {
                        if (!states.refData['npiEligibility']) states.refData['npiEligibility'] = [];
                        states.refData['npiEligibility'].push(...chunk.map((row: any) => {
                            return _.mapValues(_.mapKeys(row, function (value: any, key: any) {
                                if (key === 'referring_npi') {
                                    return 'npi';
                                }
                                return _.camelCase(key);
                            }), (value) => {
                                return typeof value === 'string' ? value.trim() ? _.lowerCase(value) === 'remove' ? null : value.trim() : undefined : value;
                            });
                        }));
                    }
                }
            };

            workbook.SheetNames.forEach(function (sheetName: any) {
                const jsonData: any[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 0, raw: true });
                processSheetInChunks(sheetName, jsonData);
            });

            const date = new Date();
            const dateString = date.toISOString().slice(0, 10) + '-' + date.toTimeString().slice(0, 8).replace(/:/g, '-');

            states.excelUploadPromise = [
                () => this.dataAccess.genericMethod({
                    model: 'Advance', method: 'uploadExcel', parameters: {
                        awsAccountName: 'clarify',
                        fileObject: data,
                        bucketName: 'tera-data-lake',
                        keyName: `advance/${states.advanceMetaData?.dataSource?.code}/${states.programNameFilter}/${dateString}/${file_obj.name}`
                    }
                })
            ];
        }).bind(this);

        await reader.readAsBinaryString(file_obj);
    }

    async downloadExcel(states: IAdvanceMetaDataEditStates) {
        const [data, fileName] = await this.dataAccess.genericMethod({
            model: 'Advance', method: 'retrieveExcel', parameters: {
                awsAccountName: 'clarify',
                bucketName: 'tera-data-lake',
                directoryPath: `advance/${states.advanceMetaData?.dataSource?.code}/${states.programNameFilter}/`
            }
        });

        const arrayBuffer = new Uint8Array(data.data).buffer;
        const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        return saveAs(blob, (fileName));
    }

    async checkForSalesforceRevisions(states: IAdvanceMetaDataEditStates) {
        const tableName = 'clarify_provider_npi_revision';
        const tableRunIds = await this.dataAccess.genericMethod({
            model: 'Advance',
            method: 'getReferenceIds',
            parameters: {
                tableNames: [tableName]
            }
        });

        const apiCallHistory = await this.dataAccess.genericFind({
            model: 'AdvanceSalesforceApiCallHistory',
            filter: {
                where: {
                    timestamp: {
                        gt: new Date(tableRunIds[tableName].substring(0, 10))
                    }
                }
            }
        });

        const filteredApiCallHistory = _.filter(apiCallHistory, history => history.revisionAfter.dataSourcesToApply.includes(states.advanceMetaData?.dataSource?.code));
        if (filteredApiCallHistory.length > 0) {
            swal({
                title: 'Automated Updates Detected',
                html: `
                    There have been automated updates from Salesforce since argos_table was last run.<br><br>
                    <table style="border-collapse: collapse; width: 100%;">
                        <thead>
                            <tr>
                                <th style="border: 1px solid #dddddd; padding: 8px;">Reference data table</th>
                                <th style="border: 1px solid #dddddd; padding: 8px;">Changes from Salesforce</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="border: 1px solid #dddddd; padding: 8px;">${tableName}</td>
                                <td style="border: 1px solid #dddddd; padding: 8px;">${filteredApiCallHistory.length}</td>
                            </tr>
                        </tbody>
                    </table>
                `,
                type: 'info',
                showCancelButton: false,
                confirmButtonColor: '#57C84D',
                confirmButtonText: 'OK'
            });
        }
    }
}
