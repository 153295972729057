import { Injectable } from '@angular/core';
import { ISalesforceSyncModalProps, ISalesforceSyncModalStates, ISalesforceSyncModalService } from './salesforceSyncModal.component.d';

@Injectable()
export class SalesforceSyncModalService implements ISalesforceSyncModalService {
    initDelegate(props: ISalesforceSyncModalProps, states: ISalesforceSyncModalStates): object {
        states.logText = props.logText;
        return {};
    }

    changeDelegate(oldProps: ISalesforceSyncModalProps, newProps: ISalesforceSyncModalProps, states: ISalesforceSyncModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

}
