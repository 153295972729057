import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IQuerySettingsStates } from './querySettings.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { QuerySettingsService } from './querySettings.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as _ from 'lodash';
import { MatOption } from '@angular/material/core';
@Component({
    selector: 'argos-querySettings',
    templateUrl: './querySettings.component.html',
    providers: [QuerySettingsService]
})

export class QuerySettingsComponent extends ReactComponentBase<{}, IQuerySettingsStates, {}> implements OnInit, AfterViewInit {

    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('matSelEnvironmentUrls') matSelEnvironmentUrls: MatSelect;
  
    constructor(private svc: QuerySettingsService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
          selectedEnvironmentUrls: new FormControl(''),
          queryRunning: false,
          filterObj: {},
          matTableColumns: ['url', 'category', 'label', 'name', 'value', 'default', 'isDefaultDiff', 'inputType'],
          environmentMatTable: new MatTableDataSource([]),
          searchResults: [],
          environmentMatTableSettingName: 'querySettings',
          environmentTypes: [],
          selectedToggleNumber: 0,
          queryFailedMessage: []
        } as unknown as IQuerySettingsStates);
    }

    async ngOnInit() {
        this.states.environmentMatTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.environmentMatTableSettingName);
        let result: any = await this.svc.initDelegate(this.states);
        this.states.environments = result;
        this.svc.generateToggles(this.states);
        
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.states.environmentMatTable.paginator = this.paginator;
        this.states.environmentMatTable.sort = this.matTbSort;
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.environmentMatTable.filter = JSON.stringify(this.states.filterObj);
    }

    fetchResults() {
        this.states.queryFailedMessage = [];
        this.svc.fetchResults(this.states);
    }

    toggleEnvironmentType(toggleType: string) {

        this.states.selectedToggleNumber = 0;

        var environmentList:string[] = [];

        if (toggleType === 'All') {
            environmentList = this.svc.toggleEnvironmentType(this.states, this.states.environmentTypes);
        } else {
            environmentList = this.svc.toggleEnvironmentType(this.states, [toggleType]);           
        }

        if (toggleType === 'None' || environmentList.length === 0) {
            this.matSelEnvironmentUrls.options.forEach((item: MatOption) => {
                item.deselect();
            })
            this.states.selectedToggleNumber = 0;
        } else {
            this.matSelEnvironmentUrls.options.forEach((item: MatOption) => {
                
                if (this.states.environmentTypes.some(i => i.includes(item.value))) {
                    if (item.selected === true) {
                        this.states.selectedToggleNumber ++;
                    }
                }
                else if (environmentList.some(i => i.includes(item.value))) { 
                    item.select();
                } 

            })
        }

        this.cdr.detectChanges();

    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.environmentMatTableSettingName);
        return result;
    }
}
