import { Injectable } from '@angular/core';
import { IOptimizationHintModalProps, IOptimizationHintModalStates, IOptimizationHintModalService } from './optimizationHintModal.component.d';

@Injectable()
export class OptimizationHintModalService implements IOptimizationHintModalService {
    
    initDelegate(props: IOptimizationHintModalProps, states: IOptimizationHintModalStates): Object {
        states.hintText = props.hintText;
        states.dataSource = props.dataSource;
        states.pipelineId = props.pipelineId;
        states.pipelineIds = props.pipelineIds;
        states.stage = props.stage;
        states.previousTimeFrameSelection = props.previousTimeFrameSelection;
        states.previousPipelineTypeSelection = props.previousPipelineTypeSelection;
        return {};
    }


}
