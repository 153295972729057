import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ICareGroupingsEditStates } from './careGroupingsEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { UrlService } from '../../../services/url.service';
import { CareGroupingsEditService } from './careGroupingsEdit.component.service';
import { CONST } from '../../../constants/globals';
import * as _ from 'lodash';
import { ValidationService } from '../../../services/validationService.service';

@Component({
    selector: 'argos-careGroupingsEdit',
    templateUrl: './careGroupingsEdit.component.html',
    providers: [CareGroupingsEditService]
})

export class CareGroupingsEditComponent extends ReactComponentBase<{}, ICareGroupingsEditStates, {}> implements OnInit {
    constructor(private svc: CareGroupingsEditService, private cdr: ChangeDetectorRef, private validationSrv: ValidationService, private urlService: UrlService) {
        super({
            settings: [{
                name: CONST.CG_BASE_TABLE_NAME,
                title: 'Base Table',
                required: true
            }, {
                name: 'predictionsTable',
                title: 'Predictions Table'
            }, {
                name: 'stagingBaseTable',
                title: 'Staging Base Table'
            }, {
                name: 'stagingMetaTable',
                title: 'Staging Meta Table'
            }, {
                name: 'stagingPredictionsTable',
                title: 'Staging Predictions Table'
            }, {
                name: 'optimizedBaseTable',
                title: 'Optimized Base Table'
            }, {
                name: 'optimizedPredictionsTable',
                title: 'Optimized Predictions Table'
            }, {
                name: 'explanationsTable',
                title: 'Explanations Table'
            }, {
                name: 'explanationColumnFormat',
                title: 'Explanations Column Name Format'
            }, {
                name: 'rmseColumnNameFormat',
                title: 'RMSE Column Name Format'
            }, {
                name: 'modelIdColumnNameFormat',
                title: 'Model ID Column Name Format'
            }, {
                name: 'expectedColumnNameFormat',
                title: 'Expected Column Name Format'
            }, {
                name: 'validExpectedColumnNameFormat',
                title: 'Valid Expected Column Name Format'
            }, {
                name: CONST.CG_START_DATE_COLUMN_NAME,
                title: 'Start Date Column'
            }, {
                name: CONST.CG_END_DATE_COLUMN_NAME,
                title: 'End Date Column'
            }, {
                name: 'patientTableName',
                title: 'Patient Table Name'
            }, {
                name: 'primaryKeyColumn',
                title: 'Primary Key Column'
            }, {
                name: 'referenceDataSchema',
                title: 'Reference Data Schema'
            }, {
                name: 'claimsDataSchema',
                title: 'Claims Data Schema'
            }, {
                name: 'rowDescription',
                title: 'Row Description',
                required: true
            }, {
                name: CONST.CG_MIN_START_DATE_NAME,
                title: 'CCG Min Start Date',
            }, {
                name: CONST.CG_MAX_END_DATE_NAME,
                title: 'CCG Max End Date'
            }, {
                name: 'ccgDefaultMinDateOffset',
                type: 'selector',
                options: CONST.CCG_MIN_OFFSET_OPTS,
                default: 'START_MONTH',
                title: 'CCG Default Minimum Date Offset'
            }, {
                name: 'ccgDefaultMaxDate',
                type: 'selector',
                options: CONST.CCG_MAX_OFFSET_OPTS,
                default: 'END_MONTH',
                title: 'CCG Default Maximum Date'
            }, {
                name: CONST.CG_DATE_QUERY_THRESHOLD,
                type: 'number',
                ngMin: 0,
                ngMax: 100,
                title: 'CCG Date Query Threshold (0 - 100)'
            }, {
                name: 'volumeMetric',
                title: 'Volume Metric',
                type: 'selector'
            }, {
                name: 'benchmarkMinVolume',
                title: 'Benchmark Min Volume',
                type: 'number',
                ngMin: 0
            }, {
                name: 'dateOptions',
                title: 'Date Options',
                type: 'textarea',
            }, {
                name: 'trendPeriods',
                title: 'Trend Periods',
                type: 'textarea',
            }, {
                name: 'requiredMinFilters',
                title: 'Required Minimum Filters',
                type: 'textarea'
            }, {
                name: 'benchmarkable',
                title: 'For Benchmarking Only',
                type: 'checkbox'
            }, {
                name: 'applyOpportunityPaymentAdjustment',
                title: 'Opportunity Payment Adjustment',
                type: 'checkbox'
            }, {
                name: 'disableFilterEntityCount',
                title: 'Disable Filter Entity Count',
                type: 'checkbox'
            }, {
                name: 'disableApplyAllExternals',
                title: 'Disable Applying Filters to All External Metrics',
                type: 'checkbox'
            }, {
                name: 'useLatestClarifyProviderData',
                title: 'Use Latest Clarify Provider Data',
                type: 'checkbox'
            }, {
                name: 'maskWithAvgLowVal',
                title: 'Mask Low Values With Average Value (in BE)',
                type: 'checkbox'
            }, { 
                name: 'avoidDistinctCTE',
                title: `Avoid 'distinct CTE' query generation`,
                type: 'checkbox'
            }],
            saveInProgress: false,
            careGrouping: {
                settings: {}
            },
            propertyErrors: [],
            existingCaregroupings: []
        } as unknown as ICareGroupingsEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
        if (this.urlService.checkShowViewHistoryFromUrl()) {
            this.viewHistory();
        }
    }

    viewHistory() {
        this.svc.viewHistory(this.states);
    }

    async fetchCcgDateDomainBounds() {
        await this.svc.fetchCcgDateDomainBounds(this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.states.propertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.careGrouping, ['name', 'title', 'description']);

        if (this.states.careGrouping.title !== this.states.originalCareGrouping.title) {
            this.states.propertyErrors = this.states.propertyErrors.concat(this.validationSrv.validatePropertyValueExistsInList('title', this.states.careGrouping.title, _.map(this.states.existingCaregroupings, (cg: any) => { return cg.title; })));
        }

        // only validate for new ccgs
        if (!this.states.careGrouping.id) {
            this.states.propertyErrors = this.states.propertyErrors.concat(this.validationSrv.validatePropertyValueExistsInList('name', this.states.careGrouping.name, _.map(this.states.existingCaregroupings, (cg: any) => { return cg.name; })));
        }

        if (this.states.propertyErrors.length === 0) {
            this.svc.save(this.states);
        }
    }

    getPropertyErrorMsg(propertyName: string) {
        const msg = this.validationSrv.getPropertyErrorMessage(propertyName, this.states.propertyErrors);
        return msg;
    }

    propertyErrorExists(propertyName: string) {
        const result = this.validationSrv.errorExists(propertyName, this.states.propertyErrors);
        return result;
    }

    titleChanged() {
        if (this.states.careGrouping.title && !this.states.careGrouping.id) {
            this.states.careGrouping.name = _.snakeCase(this.states.careGrouping.title);
        }
    }

    missingRequiredField() {
        if (!this.states.careGrouping.name || !this.states.careGrouping.title) {
            return true;
        }
        for (const setting of this.states.settings) {
            if (setting.required) {
                if (!this.states.careGrouping?.settings[setting.name]) {
                    return true;
                }
            }
        }
        return false;
    }

}
