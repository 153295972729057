import { Injectable } from '@angular/core';
import { IReleasesProps, IReleasesStates, IReleasesService } from './releases.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { AlertService } from 'client/app/services/alert.service';
@Injectable()
export class ReleasesService implements IReleasesService {
    constructor(private dataAccess: NgDataAccess, private alertSvc: AlertService) {
        //
    }
    async initDelegate(props: IReleasesProps, states: IReleasesStates): Promise<IReleasesStates> {
        const newStates = await this.findReleases(states);
        return newStates;
    }

    async changeDelegate(oldProps: IReleasesProps, newProps: IReleasesProps, states: IReleasesStates): Promise<IReleasesStates> {
        const newStates = await this.initDelegate(newProps, states);
        return newStates;
    }

    // momentFromNow(date) {
    //     return moment(new Date(date)).fromNow();
    // }

    async findReleases(initStates: IReleasesStates): Promise<IReleasesStates> {
        const states = _.cloneDeep(initStates);
        const releases = await this.dataAccess.genericFind({
            model: 'Release'
        });
        if (releases.length > 0) {
            states.releasesTable = _.orderBy(releases, ['releaseDate'], ['desc']);
        } else {
            states.noData = 'No releases found';
        }
        states.queryRunning = false;
        return states;
    }

    deleteReleaseHandler(id: any, name: any, states: IReleasesStates, cb: Function) {
        if (id && name) {
            swal({
                title: 'Really?',
                text: 'Are you sure you want to delete the Release Note, ' + name + '?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    try {
                        const data = await this.dataAccess.genericMethod({
                            model: 'Release', method: 'destroyById', parameters: {
                                id
                            }
                        });
                        const newStates = await this.findReleases(states);
                        cb(newStates);
                    } catch (error) {
                        this.alertSvc.handleError(error);
                    }
                }
            });
        }
    }
}
