import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpEvent, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ArgosStoreService } from './argosStore.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { UIRouter, StateService } from '@uirouter/core';

@Injectable()
export class LoopBackAuthRequestInterceptor implements HttpInterceptor {
    constructor(private argosStore: ArgosStoreService,
        private state: UIRouter, private uiState: StateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;

        const urlBase = '/api';
        const urlBaseHost = this.getHost(urlBase) || location.host;

        const host = this.getHost(request.url);
        if (host && host !== urlBaseHost) {
            return next.handle(req);
        }

        // const loopBackAuth = new LoopBakAuth();
        // if (loopBackAuth.accessTokenId) {
        //     request = request.clone({ setHeaders: { authorization: loopBackAuth.accessTokenId } });
        // }
        const token = this.argosStore.getItem('token');
        if (token) {
            request = req.clone({
                setHeaders: {
                    'authorization': 'Bearer' + ' ' + token,
                    'UTC-Offset': moment().utcOffset().toString(),
                    'Timezone': moment.tz.guess(),
                    // 'Cache-Control': 'no-cache',
                    // 'Pragma': 'no-cache'
                }
            });
        }
        else if (_.get(request, '__isGetCurrentUser__')) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            return new Observable(observer => {
                observer.error(new HttpResponse({
                    body: { error: { status: 401 } },
                    status: 401,
                    url: request.url,
                    headers: undefined
                }));
                observer.complete();
            });
        }

        /**
         *  This is the place to handle request response.
         */
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                return event;
            }
            return event;
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                const message = _.get(err, 'error.error.message');
                const url = request.url;
                if (message) {
                    console.error(url, err);
                    // this.apiErrorSvc.logError(url, message);
                }
                // TODO if (request.method === 'GET' && err.status === 401) {
                //     this.uiState.go('login');
                // }
                return err;
            }
            return err;
        }));
    }

    getHost(url: string) {
        const m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
        return m ? m[1] : undefined;
    }
}
