import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IPipelineDataSourceListStates } from './pipelineDataSourceList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PipelineDataSourceListService } from './pipelineDataSourceList.component.service';

@Component({
    selector: 'argos-pipelineDataSourceList',
    templateUrl: './pipelineDataSourceList.component.html',
    providers: [PipelineDataSourceListService]
})

export class PipelineDataSourceListComponent extends ReactComponentBase<{}, IPipelineDataSourceListStates, {}> implements OnInit {
    constructor(private svc: PipelineDataSourceListService, private cdr: ChangeDetectorRef) {
        super();
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }
}
