import { Component } from '@angular/core';

@Component({
    selector: 'argos-qaTestResultsRoot',
    template: `<ui-view></ui-view>`
})

export class QATestResultsRootComponent {

}
