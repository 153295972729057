import { Injectable } from '@angular/core';
import { ArgosStoreService } from './argosStore.service';
import { NgDataAccess } from './dataAccess.service';
import { CONST } from '../constants/globals';
import * as _ from 'lodash';

/**
 * This service emulates an Authentication Service.
 */
@Injectable()
export class UtilsService {

    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService) { }

    getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    sleep(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    getDateDiffBySeconds(date1: any, date2: any) {
        const result = Math.abs(date1 - date2) / 1000;
        return result;
    }

    getLocalDate(date: any) {
        const d = new Date(date);
        const month = d.toLocaleString('en-us', { month: 'long' });
        const mins = ('0' + d.getMinutes()).slice(-2);
        const result = month + ' ' + d.getDate() + ', ' + d.getFullYear() + ' at ' + d.getHours() + ':' + mins;

        return result;
    }

    isNumeric(val: any): val is number | string {
        // parseFloat NaNs numeric-cast false positives (null|true|false|"")
        // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
        // subtraction forces infinities to NaN
        // adding 1 corrects loss of precision from parseFloat (#15100)
        // From rxjs v6.5.4
        return !_.isArray(val) && (val - parseFloat(val) + 1) >= 0;
    }
}
