import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IProductBundlesListStates } from './productBundlesList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ProductBundlesListService } from './productBundlesList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { MatPaginator } from '@angular/material/paginator';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-productBundlesList',
    templateUrl: './productBundlesList.component.html',
    providers: [ProductBundlesListService]
})

export class ProductBundlesListComponent extends ReactComponentBase<{}, IProductBundlesListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: ProductBundlesListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            pageSize: 50,
            filterObj: {},
            productBundles: [],
            defaultDisplayMatTableColumns: ['editButton', 'shortName', 'title', 'description', 'usedByButton'],
            matTableColumns: ['editButton', 'id', 'shortName', 'title', 'description', 'usedByButton'],
            productBundleTable: new MatTableDataSource([]),
            matTableSettingName: 'productBundlesList'
        } as IProductBundlesListStates);
    }

    ngAfterViewInit() {
        this.states.productBundleTable.sort = this.matTbSort;
        this.states.productBundleTable.paginator = this.paginator;
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.productBundleTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.productBundleTable.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.productBundleTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.productBundleTable.filter);
    }

    addProductBundle() {
        this.states.editingMode = true;
        const newRow = {
            editMode: true
        };
        this.states.productBundleTable.data.unshift(newRow);
        this.states.productBundleTable.data = this.states.productBundleTable.data;
    }

    editRow(row: any) {
        this.states.editingMode = true;
        row.editMode = true;
    }

    isRowSavable(row: any) {
        const result = row.shortName && row.title && row.description;
        return !result;
    }

    saveRow(row: any) {
        this.states.editingMode = false;
        row.editMode = false;
        this.svc.saveRowHandler(row);
    }

    cancelEdit(row: any) {
        this.states.editingMode = false;
        row.editMode = false;
        if (!row.id) {
            this.states.productBundleTable.data.shift();
            this.states.productBundleTable.data = this.states.productBundleTable.data;
        }
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    viewClientUsage(row: any) {
        this.svc.viewClientUsage(row, this.states);
    }
}
