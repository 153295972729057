import { Injectable, ChangeDetectorRef } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { IViewPatientJourneyModalProps, IViewPatientJourneyModalStates, IViewPatientJourneyModalService } from './viewPatientJourneyModal.component.d';
import * as _ from 'lodash';


@Injectable()
export class ViewPatientJourneyModalService implements IViewPatientJourneyModalService {
    constructor(private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {}
    async initDelegate(props: IViewPatientJourneyModalProps, states: IViewPatientJourneyModalStates): Promise<object> {
        const patientJourneyColumns = await this.dataAccess.genericFind({
            model: 'ReferralPatientJourneyMarketShareColumn',
            filter: { include: 'teradromeColumn' }
        });

        states.patientJourneyColumns = _.sortBy(
            _.map(patientJourneyColumns, (column: any) => ({
                id: column.teradromeColumn.id,
                columnName: column.teradromeColumn.columnName
            })),
            'columnName'
        );

        states.originalPatientJourneyColumns = _.cloneDeep(states.patientJourneyColumns);

        const teradromeTable = await this.dataAccess.genericFind({
            model: 'TeradromeTable',
            filter: {
                include: 'teradromeColumn',
                where: {
                    tableName: 'market_share'
                }
            }
        });

        states.allMarketShareColumns = _.sortBy(
            _.map(teradromeTable[0].teradromeColumn, (column: any) =>
                _.pick(column, ['id', 'columnName'])
            ),
            'columnName'
        );

        this.setMarketShareColumn(states);

        return {};
    }

    setMarketShareColumn(states: IViewPatientJourneyModalStates) {
        states.selectColumns = _.filter(states.allMarketShareColumns, (column: any) => {
            return !_.find(states.patientJourneyColumns, { id: column.id });
        });
    }

    changeDelegate(oldProps: IViewPatientJourneyModalProps, newProps: IViewPatientJourneyModalProps, states: IViewPatientJourneyModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    async save(states: IViewPatientJourneyModalStates) {
        const deletedColumns = _.differenceBy(states.originalPatientJourneyColumns, states.patientJourneyColumns, 'id');
        const deletedColumnIds = deletedColumns.map((column: any) => column.id);

        await this.dataAccess.genericMethod({
            model: 'ReferralPatientJourneyMarketShareColumn', method: 'deleteAll',
            parameters: {  
                where: {
                    teradromeColumnId: {
                        inq: deletedColumnIds
                    }}}
        });

        const addedColumns = _.differenceBy(states.patientJourneyColumns, states.originalPatientJourneyColumns, 'id');
        const addedColumnIds = addedColumns.map((column: any) => column.id);

        await Promise.all(addedColumnIds.map((id: number) => this.dataAccess.genericUpsert({
            model: 'ReferralPatientJourneyMarketShareColumn',
            data: {teradromeColumnId: id}
        })));
    }
}
