import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IHierarchyEditStates } from './hierarchyEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { HierarchyEditService } from './hierarchyEdit.component.service';

@Component({
    selector: 'argos-hierarchyEdit',
    templateUrl: './hierarchyEdit.component.html',
    providers: [HierarchyEditService]
})

export class HierarchyEditComponent extends ReactComponentBase<{}, IHierarchyEditStates, {}> implements OnInit {
    constructor(private svc: HierarchyEditService, private cdr: ChangeDetectorRef) {
        super({
            observationHierarchy: {}
        } as IHierarchyEditStates);
    }

    async ngOnInit() {
        this.setStates(await this.svc.initDelegate(this.states));
        this.cdr.detectChanges();
    }

    save() {
        this.svc.saveHander(this.states);
    }
}
