import * as _ from 'lodash';
import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EcsAppRow {
    constructor(private dataAccess: NgDataAccess,
        private argosStore: ArgosStoreService) {

    }

    private async genericFind(states: IEcsAppEditStates) {
        const envObj = await this.dataAccess.genericFind({
            model: 'Environment',
            filter: {
                where: { id: states.cluster.environment.id }
            }
        });
        return envObj;
    }

    private async genericUpsert(envObj: any) {
        await this.dataAccess.genericUpsert({
            model: 'Environment',
            data: envObj
        });
    }
    async saveRow(row: any, states: IEcsAppEditStates) {
        // get the latest environment and update the permission column
        const envObj = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'findOne', parameters: {
                filter: { where: { id: states.cluster.environment.id } }
            }
        });

        if (envObj) {
            let previousUserPermission = {};
            let isNew = true;

            // remove the row to be saved from the list
            for (let x = 0; x < envObj.userPermissions.length; x++) {
                const p = envObj.userPermissions[x];
                if (p.email === row.email) {
                    isNew = false;
                    previousUserPermission = p;
                    envObj.userPermissions.splice(x, 1);
                    break;
                }
            }
            delete row.editMode;    // we dont need the editmode property
            envObj.userPermissions.push(row);   // add the changed row back into the permissions list
            // save the permission
            await this.genericUpsert(envObj);
            await this.addIndividualAccessToActivityLog({
                action: (isNew) ? 'access granted' : 'edit',
                impactedUser: row.email,
                currentUserPermission: { ...row},
                previousUserPermission,
            }, row.email, states);

            // delete the local row
            for (let i = 0; i < states.cluster.environment.userPermissions.length; i++) {
                const p = states.cluster.environment.userPermissions[i];
                if (p.email === row.email) {
                    states.cluster.environment.userPermissions.splice(i, 1);
                }
            }

            // push the refreshed row. removing and adding allows save to work for new and updated rows
            states.cluster.environment.userPermissions.push(row);
            // ();
            states.newUserPermission = { email: '', view: true, deploy: false, manage: false, operator: false };
            states.editingMode = false;
            row.editMode = false;
        }
    }

    private async addIndividualAccessToActivityLog(eventDetail: any, impactedUser: any, states: IEcsAppEditStates) {

        const activityLog: any = {
            clusterName: states.cluster.clusterName,
            environmentAction: 'individual access change',
            eventDetail,
            triggeredBy: this.argosStore.getItem('username'),
            awsAccount: states.cluster.accountName
        };

        activityLog.message = eventDetail.action + ' action against ' + impactedUser;
        await this.dataAccess.genericCreate({
            model: 'EnvironmentActivityLog',
            data: activityLog
        });
    }

    async deleteRow(row: any, states: IEcsAppEditStates) {
        // get the latest environment and update the permission column
        const envObj = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'findOne', parameters: {
                filter: { where: { id: states.cluster.environment.id } }
            }
        });

        if (envObj) {
            let deletedUserPermission = {};
            // delete the requested row
            for (let x = 0; x < envObj.userPermissions.length; x++) {
                const p = envObj.userPermissions[x];
                if (p.email === row.email) {
                    deletedUserPermission = p;
                    envObj.userPermissions.splice(x, 1);
                }
            }

            // save the changes
            await this.genericUpsert(envObj);
            await this.addIndividualAccessToActivityLog({
                action: 'delete',
                impactedUser: row.email,
                permission: deletedUserPermission
            }, row.email, states);

            // after save is done delete the local row to reflect the change
            for (let i = 0; i < states.cluster.environment.userPermissions.length; i++) {
                const p = states.cluster.environment.userPermissions[i];
                if (p.email === row.email) {
                    states.cluster.environment.userPermissions.splice(i, 1);
                    break;
                }
            }

            states.editingMode = false;
            row.editMode = false;
        }
    }
}
