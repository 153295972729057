import { Injectable } from '@angular/core';
import { IViewJoinEntityHistoryModalProps, IViewJoinEntityHistoryModalStates, IViewJoinEntityHistoryModalService } from './viewJoinEntityHistoryModal.component.d';
import { NgDataAccess } from 'client/app/services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class ViewJoinEntityHistoryModalService implements IViewJoinEntityHistoryModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(props: IViewJoinEntityHistoryModalProps, states: IViewJoinEntityHistoryModalStates): Promise<Object> {
        states.episodeGroupingEntity = props.episodeGroupingEntity;
        states.lodash = _;
        await this.init(states);
        return states;
    }

    async changeDelegate(oldProps: IViewJoinEntityHistoryModalProps, newProps: IViewJoinEntityHistoryModalProps, states: IViewJoinEntityHistoryModalStates): Promise<Object> {
        return this.initDelegate(newProps, states);
    }

    async init(states: IViewJoinEntityHistoryModalStates) {
        if (states.episodeGroupingEntity?.entity) {
            states.episodeGroupingEntity = await this.dataAccess.genericMethod({
                model: 'EpisodeGroupingEntity', method: 'findById',
                parameters: {
                    id: states.episodeGroupingEntity.entity,
                    filter: {
                        include: ['episodeGroupingEntityHistories']
                    }
                }
            });
        }
    }

    viewDiffsHandler(version: number, states: IViewJoinEntityHistoryModalStates) {
        states.showDiffs = true;
        states.compareHistory = [];
        states.currentVersion = ('(v' + version + ')');
        states.previousVersion = '';
        const currentRow: any = _.find(states.episodeGroupingEntity.episodeGroupingEntityHistories, { version });
        let previousRow: any = {};
        if (currentRow) {
            if (version > 1) {
                const previousVersion = version - 1;
                states.previousVersion = ('(v' + previousVersion + ')');
                previousRow = _.find(states.episodeGroupingEntity.episodeGroupingEntityHistories, { version: previousVersion });
            }
        }
        states.compareHistory = _.map(_.keys(currentRow.definition), function (key) {
            return {
                key: _.startCase(key),
                previousValue: _.get(previousRow, 'definition.' + key),
                currentValue: _.get(currentRow, 'definition.' + key)
            };
        });
    }
}
