import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ICompoundFiltersAddEditStates } from './compoundFiltersAddEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CompoundFiltersAddEditService } from './compoundFiltersAddEdit.component.service';

@Component({
    selector: 'argos-compoundFiltersAddEdit',
    templateUrl: './compoundFiltersAddEdit.component.html',
    providers: [CompoundFiltersAddEditService]
})

export class CompoundFiltersAddEditComponent extends ReactComponentBase<{}, ICompoundFiltersAddEditStates, {}> implements OnInit {

    @ViewChild('templateTokenTable') templateTokenTable: MatTable<any>;

    constructor(private svc: CompoundFiltersAddEditService, private cdr: ChangeDetectorRef) {
        super({
            nameError: true,
            compoundFilters: {
                title: ''
            },
            showTokenErrors: {
                sql: [],
                desc: []
            }
        } as ICompoundFiltersAddEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.refresh();
    }

    compoundFiltersNameChanged() {
        if (this.states.compoundFilters.name && this.states.compoundFilters.name.length > 0) {
            this.svc.compoundFiltersNameChangedHandler(this.states);
        }
    }
    deleteTemplateToken(row: any, index: number) {
        this.svc.deleteTemplateToken(row, index, this.states);
        this.refresh();
    }

    addTemplateToken() {
        this.svc.addTemplateToken(this.states);
        this.templateTokenTable.renderRows();
        this.refresh();
    }

    clearJoinEntity() {
        this.states.compoundFilters.joinEntity = null;
    }

    addCompoundFilter() {
        this.svc.addCompoundFilter(this.states);
        this.refresh();
    }

    editDropDownCategory(row: any, index: number) {
        this.svc.editDropDownCategoryHandler(row, index, this.states, this.refresh);
    }

    refresh: Function = () => {
        this.cdr.detectChanges();
    };
}
