import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewMetricHistoryModalProps, IViewMetricHistoryModalStates } from './viewMetricHistoryModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewMetricHistoryModalService } from './viewMetricHistoryModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-viewMetricHistoryModal',
    templateUrl: './viewMetricHistoryModal.component.html',
    providers: [ViewMetricHistoryModalService]
})

export class ViewMetricHistoryModalComponent extends ReactComponentBase<IViewMetricHistoryModalProps, IViewMetricHistoryModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewMetricHistoryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewMetricHistoryModalComponent>) {
        super({
            compareHistory: [],
            previousVersion: '',
            metric: {}
        } as unknown as IViewMetricHistoryModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close();
    }

    viewDiffs(version: number) {
        this.svc.viewDiffs(version, this.states);
    }
}
