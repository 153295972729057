import { Injectable } from '@angular/core';
import { IViewCareGroupingHistoryProps, IViewCareGroupingHistoryStates, IViewCareGroupingHistoryService } from './viewCareGroupingHistory.component.d';
import * as _ from 'lodash';

@Injectable()
export class ViewCareGroupingHistoryService implements IViewCareGroupingHistoryService {
    initDelegate(props: IViewCareGroupingHistoryProps, states: IViewCareGroupingHistoryStates): Object {
        states.careGrouping = props.careGrouping;
        return {};
    }

    changeDelegate(oldProps: IViewCareGroupingHistoryProps, newProps: IViewCareGroupingHistoryProps, states: IViewCareGroupingHistoryStates): Object {
        this.initDelegate(newProps, states);
        return {};
    }
    viewDiffs(version: any, states: IViewCareGroupingHistoryStates) {
        states.showDiffs = true;
        states.compareHistory = [];
        states.currentVersion = '(v' + version + ')';
        states.previousVersion = '';
        const currentRow = _.find(states.careGrouping.careGroupingHistories, { version });
        let previousRow: any = {};
        if (currentRow) {
            if (version > 1) {
                const previousVersion = version - 1;
                states.previousVersion = ('(v' + previousVersion + ')');
                previousRow = _.find(states.careGrouping.careGroupingHistories, { version: previousVersion });
            }
        }
        const currentSettingKeys = _.map(_.keys(_.get(currentRow, 'definition.settings')), function (key) {
            return 'settings.' + key;
        });
        const previousSettingKeys = _.map(_.keys(_.get(previousRow, 'definition.settings')), function (key) {
            return 'settings.' + key;
        });
        const allKeys = _.uniq(_.union(currentSettingKeys, previousSettingKeys));
        allKeys.unshift('id', 'name', 'title', 'description', 'excludeFromSync');
        states.compareHistory = _.map(allKeys, function (key) {
            return {
                key: _.startCase(key),
                previousValue: _.get(previousRow, 'definition.' + key),
                currentValue: _.get(currentRow, 'definition.' + key)
            };
        });
    }
}
