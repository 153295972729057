import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IJoinEntitiesListStates, IJoinEntitiesListService } from './joinEntitiesList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EditJoinEntityModalComponent } from '../editJoinEntityModal/editJoinEntityModal.component';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { UIRouter } from '@uirouter/core';
import { UrlService } from 'client/app/services/url.service';

@Injectable()
export class JoinEntitiesListService implements IJoinEntitiesListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef, private uiRouter: UIRouter, private urlService: UrlService) {
        //
    }
    async initDelegate(states: IJoinEntitiesListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IJoinEntitiesListStates) {

        const [episodeGroupingEntities, episodeGroupings, externalMetrics, markForDeletions] = await Promise.all([
            this.dataAccess.genericFind({
                model: 'EpisodeGroupingEntity'
            }),
            this.dataAccess.genericFind({
                model: 'EpisodeGrouping',
                filter: { fields: ['groupByAttribute', 'joinEntity'] }
            }),
            this.dataAccess.genericFind({
                model: 'OtherMetric',
                filter: { fields: ['id', 'name', 'externalJoinEntity'] }
            }),
            this.dataAccess.genericFind({
                model: 'MarkForDeletion',
                filter: { where: { objectType: 'episodeGroupingEntity' } }
            })
        ]);

        states.episodeGroupingEntities = _.orderBy(_.map(episodeGroupingEntities, function (ege: any) {
            const relatedJoins = _.map(_.filter(episodeGroupingEntities, (related: any) => {
                return _.includes(related.relatedEntity, ege.entity);
            }), 'entity');
            const markForDeletion: any = _.find(markForDeletions, { objectName: ege.entity });
            if (markForDeletion) {
                ege.markForDeletionId = markForDeletion.id;
            }
            ege.usedByGroupings = _.map(_.filter(episodeGroupings, { joinEntity: ege.entity }), 'groupByAttribute');
            ege.usedByMetrics = _.map(_.filter(externalMetrics, { externalJoinEntity: ege.entity }), 'name');
            ege.usageCount = _.size(ege.usedByGroupings) + _.size(ege.usedByMetrics) + _.size(relatedJoins);
            ege.usageTooltip = _.join(_.union(relatedJoins, ege.usedByGroupings, ege.usedByMetrics), '\n');
            ege.excludeFromSyncText = ege.excludeFromSync ? 'Yes' : 'No';
            return ege;
        }), ['entity']);

        states.matTable.data = _.orderBy(states.episodeGroupingEntities, ['entity']);
    }

    addOrEditJoinEntityHandler(states: IJoinEntitiesListStates, cb: Function, row?: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    episodeGroupingEntityId: row?.entity,
                    editMode: !_.isUndefined(row)
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            EditJoinEntityModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (event: any) => {
            if (event && event.eventName === 'apply') {
                await this.activate(states);
            }
            if (this.uiRouter.globals.params.openModal) {
                this.urlService.updateUrlWithQueryString(this.uiRouter.globals.params.queryString);
            }
            cb();
        });
    }

    markForDeletion(row: any) {
        swal({
            title: 'Are you sure?',
            text: `Do you want to delete join ${row.entity}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const newRow = await this.dataAccess.genericUpsert({
                    model: 'MarkForDeletion',
                    data: {
                        objectType: 'episodeGroupingEntity',
                        objectName: row.entity,
                        objectDefinition: row,
                        deletedBy: this.argosStore.getItem('username'),
                        deletedDate: new Date()
                    }
                });
                row.markForDeletionId = newRow.id;
                this.cdr.detectChanges();
            }
        });
    }

    async undelete(row: any) {
        if (row && row.markForDeletionId) {
            await this.dataAccess.genericMethod({
                model: 'MarkForDeletion',
                method: 'destroyById',
                parameters: {
                    id: row.markForDeletionId
                }
            });
            delete row.markForDeletionId;
            this.cdr.detectChanges();
        }
    }
}
