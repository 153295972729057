import { Component, OnChanges, SimpleChanges, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { IAutomatedGoldPulldownListProps, IAutomatedGoldPulldownListStates, IAutomatedGoldPulldownListService } from './automatedGoldPulldownList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutomatedGoldPulldownListService } from './automatedGoldPulldownList.component.service';
import * as _ from 'lodash';
import { AutomatedGoldPulldownBranchInfoComponent } from './automatedGoldPulldownBranchInfo/automatedGoldPulldownBranchInfo.component';
import * as moment from 'moment';

@Component({
    selector: 'argos-automated-gold-pulldown-list',
    templateUrl: './automatedGoldPulldownList.component.html',
    providers: [AutomatedGoldPulldownListService]
})

export class AutomatedGoldPulldownListComponent extends ReactComponentBase<{}, IAutomatedGoldPulldownListStates, {}> implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('tbMatSort') tbMatSort = new MatSort();
    _: any = _;
    moment: any = moment;
    constructor(private svc: AutomatedGoldPulldownListService, private cdr: ChangeDetectorRef, private matDialog: MatDialog) {
        super({
            loading: false,
            hasError: false,
            manual: false,
            branchInfo: {},
            allCreatedBranchesTable: new MatTableDataSource([]),
            selectedMetaDataTypes: [],
            logsText: ''
        } as IAutomatedGoldPulldownListStates);
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.states);
        this.setStates(newStates);
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.states.allCreatedBranchesTable.sort = this.tbMatSort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(this.states);
    }

    searchBranches(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.allCreatedBranchesTable.filter = filterValue.trim().toLowerCase();
    }

    async createManualPulldown() {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: true,
            data: {
                props: {
                    branchInfoView: true,
                    content: {},
                    states: this.states
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(AutomatedGoldPulldownBranchInfoComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (event: any) => {
            if (event === 'cancel') {
                return;
            }
            this.states.manual = true;
            this.states.branchInfo = event;
            await this.svc.pulldownMetaData(this.states, this.cdr);
            this.cdr.detectChanges();
        });
    }

    async closePR(row: any) {
        await this.svc.deleteBranchAndPR(row, this.states, this.cdr);
    }

    async deleteBranchRow(row: any) {
        await this.svc.deleteRow(row, this.states, this.cdr);
    }

    async showBuildLogs(row: any) {
        await this.svc.showLogs(row, this.states, this.cdr);
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: true,
            data: {
                props: {
                    branchInfoView: false,
                    content: {},
                    states: this.states
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(AutomatedGoldPulldownBranchInfoComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (event: any) => {
            if (event === 'cancel') {
                return;
            }
        });
    }
}