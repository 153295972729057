import { Component } from '@angular/core';

@Component({
    selector: 'argos-clarifyInsightsFilter',
    template: `<ui-view></ui-view>`
})

export class ClarifyInsightsFilterComponent {

}
