import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IPipelineStepDetailStates } from './pipelineStepDetail.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PipelineStepDetailService } from './pipelineStepDetail.component.service';

@Component({
    selector: 'argos-pipelineStepDetail',
    templateUrl: './pipelineStepDetail.component.html',
    providers: [PipelineStepDetailService]
})

export class PipelineStepDetailComponent extends ReactComponentBase<{}, IPipelineStepDetailStates, {}> implements OnInit {
    constructor(private svc: PipelineStepDetailService, private cdr: ChangeDetectorRef) {
        super({
            modelErrors: []
        } as unknown as IPipelineStepDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    openModal(error: any) {
        this.svc.openModal(error);
    }

    prettyPrintNumber(num: any, isWholeNumber: boolean = false) {
        if (num % 1 !== 0 && !isWholeNumber) {
            num = num.toFixed(2);
        }
        if (isWholeNumber) {
            num = num.toFixed(0);
            num = Number(num);
        }
        num = num.toLocaleString();
        return num 
    }
}
