import { Injectable } from "@angular/core";
import { NgDataAccess } from "../../../services/dataAccess.service";
import { IRunDataReleaseService, IRunDataReleaseStates } from "./runDataRelease.component.d";
import { UIRouter } from '@uirouter/core';
import { StateService } from '@uirouter/core';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';


@Injectable()
export class RunDataReleaseServices implements IRunDataReleaseService {

    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter, private argosStore: ArgosStoreService, private state: StateService) {
    }

    async initDelegate(states: IRunDataReleaseStates): Promise<object> {
        await this.init(states);
        return states;
    };

    async init(states: IRunDataReleaseStates) {
        states.releaseId = this.uiRouter.globals.params.releaseId;
        states.finalCatalogSources = await this.dataAccess.genericMethod({
            model: 'GitHub',
            method: 'getFinalCatalogSources'
        });
        const astronomerDagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });
        states.astronomerBaseUrl = astronomerDagsUrl.data;
        states.releaseSummary = [...await this.generateReleaseSummary(states)];
        this.startTimerWithInterval(states);
        // set wait times between dag launches
        // extended/max are for launching dags with same data sources sequentially w/o github conflicts
        states.defaultWaitTime = 60;
        states.extendedWaitTime = 120;
        states.maxWaitTime = 180;
        return states;
    };

    async generateReleaseSummary(states: IRunDataReleaseStates) {
        const releaseInfo = await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'getReleaseInfo',
            parameters: {
                releaseId: states.releaseId
            }
        });
        states.releaseInfo = [...releaseInfo];
        const releaseDagsInfo = await this.getReleaseDagsInfo(states)
        states.releaseDagsInfo = [...releaseDagsInfo];
        const dagsPendingCount = releaseDagsInfo.filter((obj: { dagStatus: string; }) => obj.dagStatus === 'Pending').length;
        if (dagsPendingCount < releaseDagsInfo.length && releaseInfo[0].releaseStatus !== 'Completed') {
            releaseInfo[0].releaseStatus = 'In-Progress'
            await this.dataAccess.genericMethod({
                model: 'DataRelease',
                method: 'updateReleaseStatus',
                parameters: {
                    releaseId: states.releaseId,
                    releaseStatus: 'In-Progress'
                }
            });
        }
        const releaseSummary = this.mapReleaseSummary(releaseInfo, releaseDagsInfo);
        return releaseSummary;
    };

    mapReleaseSummary(releaseInfo: any, releaseDagsInfo: any) {
        const dagsSuccessCount = releaseDagsInfo.filter((obj: { dagStatus: string; }) => obj.dagStatus === 'Success').length;
        const dagsFailedCount = releaseDagsInfo.filter((obj: { dagStatus: string; }) => obj.dagStatus === 'Failed').length;
        const dagsRunningCount = releaseDagsInfo.filter((obj: { dagStatus: string; }) => obj.dagStatus === 'Running').length;
        const dagsSkippedCount = releaseDagsInfo.filter((obj: { dagStatus: string; }) => obj.dagStatus === 'Skipped').length;
        const dagsPendingCount = releaseDagsInfo.filter((obj: { dagStatus: string; }) => obj.dagStatus === 'Pending').length;
        const pseudoDagsPendingOrFailed = releaseDagsInfo.filter((obj: { pseudoDagStatus: string }) => obj.pseudoDagStatus !== 'Success' && obj.pseudoDagStatus !== 'None' && obj.pseudoDagStatus !== 'Skipped');
        const allUpdatedTables = releaseDagsInfo.reduce((allTables: string[], dag: any) => allTables.concat(JSON.parse(dag.updatedTables)), []); 
        const uniqueUpdatedTables = allUpdatedTables.filter((table: string, index: number) => allUpdatedTables.indexOf(table) == index);
        let releaseSummary = [{
            releaseName: releaseInfo[0].releaseName,
            releaseDate: releaseInfo[0].releaseDate,
            releaseStatus: releaseInfo[0].releaseStatus,
            dagsPendingCount: dagsPendingCount,
            dagsSuccessCount: dagsSuccessCount,
            dagsFailedCount: dagsFailedCount,
            dagsRunningCount: dagsRunningCount,
            dagsSkippedCount: dagsSkippedCount,
            disableTriggerAllDags: pseudoDagsPendingOrFailed.length > 0 ? true : false,
            allUpdatedTables: uniqueUpdatedTables
        }];
        return releaseSummary;
    };

    async getReleaseDagsInfo(states: IRunDataReleaseStates) {
        let releaseDagsInfo = await this.dataAccess.genericMethod({
            model: 'DataReleaseDags',
            method: 'getDagsInRelease',
            parameters: {
                releaseId: states.releaseId
            }
        });
        const checkStatusStates = ['Queued','Running']
        for (const dag of releaseDagsInfo) {
            for (const isPseudo of [false, true]) {
                let dagStatus = !isPseudo ? dag.dagStatus : dag.pseudoDagStatus;
                let dagRunId = !isPseudo ? dag.dagRunId : dag.pseudoDagRunId;
                let dagId = !isPseudo ? dag.dagId : `pseudo-${dag.dagId}`;
                if (checkStatusStates.includes(dagStatus) && dagRunId){
                    const dagStatusResponse = await this.dataAccess.genericMethod({
                        model: 'Astronomer',
                        method: 'getDagStatus', 
                        parameters: {
                            dagId: dagId,
                            dagRunId: dagRunId
                        }
                    });
                    let currentDagStatus = (dagStatusResponse.data && dagStatusResponse.data[0].toUpperCase() + dagStatusResponse.data.slice(1)) || ""
                    currentDagStatus = (currentDagStatus === 'Queued') ? 'Running' : currentDagStatus;
                    if (currentDagStatus !== dagStatus){
                        await this.dataAccess.genericMethod({
                            model: 'DataReleaseDags',
                            method: 'updateDagStatus',
                            parameters: {
                                id: dag.id,
                                dagStatus: currentDagStatus,
                                isPseudo: isPseudo
                            }
                        });
                        await this.dataAccess.genericMethod({
                            model: 'DataReleaseDagRuns',
                            method: 'updateDagRunStatus',
                            parameters: {
                                dagRunId: dagRunId,
                                dagStatus: currentDagStatus
                            }
                        });
                        if (!isPseudo) {
                            dag.dagStatus = currentDagStatus;
                        }
                        else {
                            dag.psuedoDagStatus = currentDagStatus;
                        }
                        
                    }
                }
            }
        }
        releaseDagsInfo = await this.dataAccess.genericMethod({
            model: 'DataReleaseDags',
            method: 'getDagsInRelease',
            parameters: {
                releaseId: states.releaseId
            }
        });
        const groupedReleaseDagsInfo = this.roundRobinByDataSource(releaseDagsInfo, states);
        return groupedReleaseDagsInfo;
    };

    roundRobinByDataSource(releaseDagsInfo: any[], states: IRunDataReleaseStates) {
        // identify data sources
        for (const dag of releaseDagsInfo) {
            const matchingDataSource = !_.isEmpty(states.finalCatalogSources) ? states.finalCatalogSources.find((source) => dag.dagId.includes(source)) : undefined;
            releaseDagsInfo.find((obj: { id: string; }) => obj.id === dag.id).dataSource = matchingDataSource ? matchingDataSource : 'other';
        }
        const groupedDags = _.groupBy(releaseDagsInfo, 'dataSource');
        // round robin array starting with the longest array
        function recursiveMap(groupedDags: any[][], idx = 0): any[] {
            let tmpArray = groupedDags.map((dataSource: any[]) => dataSource[idx])
                           .filter((e: undefined) => e !== undefined);
            return tmpArray[0] ? tmpArray.concat(recursiveMap(groupedDags, idx + 1)) : [];
        };
        const finalDagOrder = recursiveMap(Object.values(groupedDags).sort(function(a, b){return b.length - a.length}));
        return finalDagOrder;
    }

    completeReleaseDelegate(states: IRunDataReleaseStates) {
        this.completeReleaseStatus(states);
    };

    markDagSuccessDelegate(states: IRunDataReleaseStates, id: string, dagRunId: string, isPseudo: boolean) {
        this.markDagSuccess(states, id, dagRunId, isPseudo);
    };

    markDagSkippedDelegate(states: IRunDataReleaseStates, id: string, dagRunId: string, isPseudo: boolean) {
        this.markDagSkipped(states, id, dagRunId, isPseudo);
    };

    async triggerDagDelegate(states: IRunDataReleaseStates, dataReleaseDagid: string, dagId: string, dagConfig: string, isPseudo: boolean) {
        clearInterval(states.myInterval);
        this.triggerDag(states.releaseId, dataReleaseDagid, dagId, dagConfig, isPseudo);
        await this.updateReleaseSummary(states);
        this.startTimerWithInterval(states);
    };

    async triggerAllDagsDelegate(states: IRunDataReleaseStates, isPseudo: boolean) {
        clearInterval(states.myInterval);
        this.triggerAllDags(states, isPseudo);
        this.startTimerWithInterval(states);
    };

    editDagConfigDelegate(states: IRunDataReleaseStates, id: string, dagConfig: string) {
        this.editDagConfig(states, id, dagConfig);
    };

    async markDagSuccess(states: IRunDataReleaseStates, id: string, dagRunId: string, isPseudo: boolean) {
        states.releaseDagsInfo.find((obj: { id: string; }) => obj.id === id).dagStatus = 'Success';
        await this.dataAccess.genericMethod({
            model: 'DataReleaseDags',
            method: 'updateDagStatus',
            parameters: {
                id: id,
                dagStatus: 'Success',
                isPseudo: isPseudo
            }
        });
        await this.dataAccess.genericMethod({
            model: 'DataReleaseDagRuns',
            method: 'updateDagRunStatus',
            parameters: {
                dagRunId: dagRunId,
                dagStatus: 'Success'
            }
        });
        const generateReleaseSummary = [...await this.generateReleaseSummary(states)];
        states.releaseSummary = [...generateReleaseSummary];
    };

    async markDagSkipped(states: IRunDataReleaseStates, id: string, dagRunId: string, isPseudo: boolean) {
        states.releaseDagsInfo.find((obj: { id: string; }) => obj.id === id).dagStatus = 'Skipped';
        await this.dataAccess.genericMethod({
            model: 'DataReleaseDags',
            method: 'updateDagStatus',
            parameters: {
                id: id,
                dagStatus: 'Skipped',
                isPseudo: isPseudo
            }
        });
        await this.dataAccess.genericMethod({
            model: 'DataReleaseDagRuns',
            method: 'updateDagRunStatus',
            parameters: {
                dagRunId: dagRunId,
                dagStatus: 'Skipped'
            }
        });
        const newReleaseSummary = await this.generateReleaseSummary(states);
        states.releaseSummary = [...newReleaseSummary];
    };

    triggerDag(releaseId: string, dataReleaseDagid: string, dagId: string, dagConfig: string, isPseudo: boolean) {
        if (isPseudo && !dagId.includes('pseudo')) { 
            dagId = `pseudo-${dagId}`;
        }
        this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'triggerDag',
            parameters: {
                dataReleaseDagId: dataReleaseDagid,
                dagId: dagId,
                dagConfig: dagConfig,
                triggeredByUser: this.getUser(),
                isPseudo: isPseudo,
                releaseId: releaseId
            }
        });
    }

    triggerAllDags(states: IRunDataReleaseStates, isPseudo: boolean) {
        this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'triggerAllDags',
            parameters: {
                states: states,
                isPseudo: isPseudo,
                triggeredByUser: this.getUser()
            }
        });
    }

    async updateReleaseSummary(states: IRunDataReleaseStates) {
        const generateReleaseSummary = await this.generateReleaseSummary(states);
        states.releaseSummary = [...generateReleaseSummary];
    };

    async completeReleaseStatus(states: IRunDataReleaseStates) {
        await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'updateReleaseStatus',
            parameters: {
                releaseId: states.releaseId,
                releaseStatus: 'Completed'
            }
        });
        await this.updateJiraTicketStatus(states.releaseInfo[0].rmJiraTicketId, 91); // set to Deployed
        this.state.go('argos.data.data_release_manager.list');
    };

    async editDagConfig(states: IRunDataReleaseStates, id: string, dagConfig: string) {
        await this.dataAccess.genericMethod({
            model: 'DataReleaseDags',
            method: 'updateDagInRelease',
            parameters: {
                id: id,
                dagConfig: dagConfig,
                lastEditedByUser: this.getUser()
            }
        });
        const generateReleaseSummary = await this.generateReleaseSummary(states);
        states.releaseSummary = [...generateReleaseSummary];
    };

    async updateJiraTicketStatus(jiraTicketId: string, newStatusCode: number) {
        const transitionResponse = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'transitionIssue',
            parameters: {
                jiraTicketId: jiraTicketId,
                transitionId: newStatusCode
            }
        });
        return transitionResponse;
    };

    getUser() {
        const username = this.argosStore.getItem('username');
        return username;
    };

    startTimerWithInterval(states: IRunDataReleaseStates) {
        if (states.myInterval) {
            clearInterval(states.myInterval);
        }

        states.myInterval = setInterval(async () => {
            this.onInterval(states);
            const generateReleaseSummary = [...await this.generateReleaseSummary(states)];
            states.releaseSummary = [...generateReleaseSummary];
        }, 60000)};

    onInterval(states: IRunDataReleaseStates) {
        states.timerWithInterval++;
    }
}
