import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { IQaTestResultStates } from './qaTestResultDashboard.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { QaTestResultService } from './qaTestResultDashboard.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as _ from 'lodash';
import { CategoryScale, Chart, ChartData, ChartOptions, LinearScale, LineController, LineElement, Legend, Title, Tooltip, SubTitle, PointElement } from 'chart.js';
import { ChartService } from '../../../services/chartService.service';

function registerChartComponents() {
    Chart.register(CategoryScale);
    Chart.register(LinearScale);
    Chart.register(LineController);
    Chart.register(LineElement);
    Chart.register(Legend);
    Chart.register(Title);
    Chart.register(Tooltip);
    Chart.register(SubTitle);
    Chart.register(PointElement);
}
registerChartComponents();

@Component({
    selector: 'argos-qaTestResult',
    templateUrl: './qaTestResultDashboard.component.html',
    providers: [QaTestResultService],
    styleUrls: ['./qaTestResultDashboard.component.less']
})

export class QATestResultsComponent extends ReactComponentBase<{}, IQaTestResultStates, {}> implements OnInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    // chart
    @ViewChild('doubleLineCanvas') doubleLineCanvas: ElementRef | undefined;
    doubleLineChart: any;

    constructor(private svc: QaTestResultService, private cdr: ChangeDetectorRef, private chartSvc: ChartService) {
        super({
            pctTestResults: [],
            pctProductCollapse: '',
            pctDataTable: new MatTableDataSource([]),
            pctSubTable: new MatTableDataSource([]),
            performanceTestResults: [],
            performanceTestResultsDetails: [],
            performanceProductCollapse: '',
            performanceTestDataTable: new MatTableDataSource([]),
            performanceSubTable: new MatTableDataSource([]),
            apiPerformanceTestResults: [],
            apiPerformanceTestDataTable: new MatTableDataSource([]),
            apiPerformanceSubTable: new MatTableDataSource([]),
            apiPerformanceTestResultsDetails: [],
            filters: {
                releaseDateList: [],
            },
            selectedDate: '',
            chartSettings: {
                colors: ['#45b7cd', '#00ADF9', '#FF5252', '#631717']
            },
            cypressTestResults: [],
            cypressTestResultsSummary: [],
            cypressTestDataTable: new MatTableDataSource([]),
            cypressTestResultsDetails: [],
        } as unknown as IQaTestResultStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges;
    }
    searchMatTable(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.pctDataTable.filter = filterValue.trim().toLowerCase();
    }

    selectTab(event: any) {
        this.svc.setSelectedTab(event.index);
    }

    async matPanelStateChange(productName: string): Promise<void> {
        const value = _.get(this.states, 'ProductCollapse');
        this.setStates({
            pctSubTable: new MatTableDataSource([])
        });
        if (value !== productName) {
            await this.svc.calculatePctResultsPercentageByProductName(productName, this.states);
            this.cdr.detectChanges();
        } else {
            this.setStates({
                pctProductCollapse: '',
            });
        }
    }

    changeReleaseDateFilter(selectedReleaseDate: any) {
        this.svc.fetchResults(this.states,
            selectedReleaseDate ? 'release_date' : '',
            selectedReleaseDate ? selectedReleaseDate : '');
    }

}
