import { Injectable } from '@angular/core';
import { IQueryPerformanceStates, IQueryPerformanceService } from './queryPerformance.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ViewEnvironmentUsageModalComponent } from '../../prismUsage/viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { AlertService } from 'client/app/services/alert.service';
declare const saveAs: any;

@Injectable()
export class QueryPerformanceService implements IQueryPerformanceService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private alertSvc: AlertService) {
        //
    }

    async initDelegate(states: IQueryPerformanceStates): Promise<object> {
        return this.getEnvironments();
    }

    async getEnvironments(): Promise<object> {
        // TODO HEROKU_API_TOKEN
        const environments = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEnvironments', parameters: {
            }
        });
        // TODO url: spotlightUsageUrl = 'https://' + environment.dnsCname + '.clarifyhealth.' + environment.domainSuffix

        const selectedEnvironmentId = 'all';

        const environmentUrls = _.orderBy(_.compact(_.map(environments, function (environment: any) {
            if (environment.dnsCname) {
                return {
                    id: environment.id,
                    url: 'https://' + environment.dnsCname + '.clarifyhealth.' + environment.domainSuffix
                };
            }
            return undefined;
        })), 'url');
        environmentUrls.unshift({
            id: 'all',
            url: 'All'
        });

        return { environmentUrls, selectedEnvironmentId };
    }

    async fetchResults(states: IQueryPerformanceStates, cb: Function): Promise<void> {
        states.queryRunning = true;
        let spotlightUsageIds;
        if (states.selectedEnvironmentId === 'all') {
            spotlightUsageIds = _.map(states.environmentUrls, 'id').slice(1);
        } else {
            spotlightUsageIds = [states.selectedEnvironmentId];
        }

        // spotlightUsageUrls = spotlightUsageUrls.slice(0, 10);

        Promise.all(spotlightUsageIds.map(async (id) => {
            return this.dataAccess.genericMethod({
                model: 'Environment', method: `getQueryPerformance`, parameters: {
                    id
                }
            });
        })).then((results) => {
            states.results = results;
            this.groupByChanged(states);
            cb();
        }).catch((error: any) => {
            return;
        });
    }

    groupByChanged(states: IQueryPerformanceStates) {
        states.queryRunning = true;

        states.allRows = [];
        states.results.forEach(function (result, index) {
            if (result && result.length > 0) {
                result.forEach(function (row: any) {
                    let rowId;
                    if (states.groupBy === 'careGroupingAndBaseTable') {
                        rowId = row.name + '.' + row.baseTable;
                    } else if (states.groupBy === 'environmentAndCareGroupingAndBaseTable') {
                        rowId = row.environmentUrl + '.' + row.name + '.' + row.baseTable;
                    } else {
                        rowId = row[states.groupBy];
                    }
                    const findRow = _.find(states.allRows, { rowId });
                    if (findRow) {
                        findRow.cacheTotal += row.cacheTotal;
                        findRow.totalViews += row.totalViews;
                        findRow.totalQueryTime += (row.averageQueryTime * row.cacheTotal);
                        findRow.totalErrors += row.totalErrors;
                    } else {
                        states.allRows.push({
                            rowId,
                            name: row.name,
                            baseTable: row.baseTable,
                            environmentUrl: row.environmentUrl,
                            cacheTotal: row.cacheTotal,
                            totalViews: row.totalViews,
                            totalQueryTime: row.averageQueryTime * row.cacheTotal,
                            totalErrors: row.totalErrors
                        });
                    }
                });
            }
        });
        states.allRows.forEach(function (row) {
            row.averageQueryTime = row.totalQueryTime / row.cacheTotal;
        });
        states.allRows = _.orderBy(states.allRows, 'rowId');

        states.queryRunning = false;

    }

    viewEnvironments(type: any, name: any, states: IQueryPerformanceStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    usageUrls: _.filter(states.allRows, { type, name }),
                    name
                }
            }
        };
        this.matDialog.open(ViewEnvironmentUsageModalComponent, dialogConfig);
    }

    exportToExcel(states: IQueryPerformanceStates) {

        let exportString = '';
        const delimitor = ',';
        const data = states.allRows;

        if (data && data.length > 0) {
            const columns: any = [];
            for (const prop in data[0]) {
                if (prop[0] !== '$' && !_.isFunction(data[0][prop]) && !_.isObject(data[0][prop])) {
                    columns.push(prop);
                    exportString += (prop + delimitor);
                }
            }
            exportString += '\n';

            data.forEach(function (row) {
                columns.forEach(function (column: any) {
                    exportString += (row[column] + delimitor);
                });
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, 'query_performance.csv');
        }
    }
}
