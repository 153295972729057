import { ChangeDetectorRef, ChangeDetectionStrategy, OnInit, Component, OnDestroy } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { IRunDataReleaseStates } from './runDataRelease.component.d';
import { RunDataReleaseServices } from './runDataRelease.component.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ReleaseAddDagModalComponent } from '../releaseAddDagModal/releaseAddDagModal.component';
import { ViewReleaseDagConfigModalComponent } from '../viewReleaseDagConfigModal/viewReleaseDagConfigModal.component';
import { DagLaunchConfirmationModalComponent } from '../dagLaunchConfirmationModal/dagLaunchConfirmationModal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'argos-data-release-manager-run',
    templateUrl: './runDataRelease.component.html',
    styleUrls: ['./runDataRelease.component.less'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [RunDataReleaseServices]
})

export class RunDataReleaseComponent extends ReactComponentBase<{}, IRunDataReleaseStates, {}>
    implements OnInit, OnDestroy {
    constructor(private svc: RunDataReleaseServices, private cdr: ChangeDetectorRef, private matDialog: MatDialog, private snackBar: MatSnackBar) {
        super({
        } as unknown as IRunDataReleaseStates);
    }
    showSpinner = false;

    async ngOnInit() {
        this.showSpinner = true;
        await this.svc.initDelegate(this.states);
        this.showSpinner = false;
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        if (this.states.myInterval) {
            clearInterval(this.states.myInterval);
        }
    }

    completeRelease() {
        this.svc.completeReleaseDelegate(this.states);
    }

    markDagSuccess(dagId: string, dagRunId: string, isPseudo: boolean) {
        this.svc.markDagSuccessDelegate(this.states, dagId, dagRunId, isPseudo);
    }

    markDagSkipped(dagId: string, dagRunId: string, isPseudo: boolean) {
        this.svc.markDagSkippedDelegate(this.states, dagId, dagRunId, isPseudo);
    }

    async triggerDag(id: string, dagId: string, dagConfig: string, isPseudo: boolean) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                isPseudo
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(DagLaunchConfirmationModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (result) => {
            if (!result.runDags) {
                return;
            }
            else {
                this.svc.triggerDagDelegate(this.states, id, dagId, dagConfig, isPseudo);
            }
            this.snackBar.open('Dag Trigger Sent Successfully', 'Close', {
                duration: 10000
            });
        });
    }

    async triggerAllDags(isPseudo: boolean) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                isPseudo
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(DagLaunchConfirmationModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (result) => {
            if (!result.runDags) {
                return;
            }
            else {
                this.svc.triggerAllDagsDelegate(this.states, isPseudo);
            }
            this.snackBar.open('Trigger all sent, dags starting in the background', 'Close', {
                duration: 10000
            });
        });
    }

    editDagConfig(id: string, dagId: string, dagConfig: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    dagId,
                    dagConfig
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ReleaseAddDagModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result) => {
            if (!result.dagConfig) {
                return;
            }
            else {
                this.svc.editDagConfigDelegate(this.states, id, result.dagConfig);
            }
        });
    }

    viewReleaseDagConfig(dagConfig: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    dagConfig
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ViewReleaseDagConfigModalComponent, dialogConfig);
    }

    openAirflowLink(dagId: string, dagRunId: string) {
        const airflowBaseUrl = this.states.astronomerBaseUrl;
        const airflowDagLink = (dagRunId) ? `${airflowBaseUrl}/${dagId}/grid?dag_run_id=${encodeURIComponent(dagRunId)}` :`${airflowBaseUrl}/${dagId}/grid`;
        window.open(airflowDagLink, '_blank');
    }

    disableReleaseCompletion(): boolean {
        // Check if states and the necessary properties are defined before accessing them
        if (this.states && this.states.releaseSummary && this.states.releaseSummary.length > 0 &&
            this.states.releaseInfo && this.states.releaseInfo.length > 0) {
            const releaseSummary = this.states.releaseSummary[0];
            const releaseInfo = this.states.releaseInfo[0];
            return (
                releaseSummary.dagsPendingCount !== 0 ||
                releaseSummary.dagsRunningCount !== 0 ||
                releaseSummary.dagsFailedCount !== 0 ||
                releaseInfo.releaseStatus === 'Completed'
            );
        } else {
            return false; 
        }
    }
}
export { IRunDataReleaseStates };

