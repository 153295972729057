import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IEnvironmentRequestsStates } from './environmentRequests.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EnvironmentRequestsService } from './environmentRequests.component.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'argos-environmentRequests',
    templateUrl: './environmentRequests.component.html',
    providers: [EnvironmentRequestsService]
})

export class EnvironmentRequestsComponent extends ReactComponentBase<{}, IEnvironmentRequestsStates, {}> implements OnInit {
    constructor(private state: StateService, private svc: EnvironmentRequestsService, private cdr: ChangeDetectorRef) {
        super({
            pageTitle: '2.0 Environment Requests (LDS)',
            daysToQuery: 30
        } as IEnvironmentRequestsStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    async load10Request(show10: boolean) {
        await this.svc.load10RequestHandler(show10, this.states);
        this.cdr.detectChanges();
    }

    requestEnvironment() {
        this.state.go('argos.environments.environment20Requests.add');
    }

    daysToQueryChange() {
        this.svc.queryRequests(this.states);
    }
}
