import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IPrism1MetricEditStates, IPrism1MetricEditService } from './prism1MetricEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { CONST } from '../../../constants/globals';
import { StateService, UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewMetricHistoryModalComponent } from '../viewMetricHistoryModal/viewMetricHistoryModal.component';
import { EditRestrictedGroupingsModalComponent } from '../editRestrictedGroupingsModal/editRestrictedGroupingsModal.component';
import { EditJoinEntityModalComponent } from '../../episodeGroupings/editJoinEntityModal/editJoinEntityModal.component';
import { TestSqlModalComponent } from '../../testSqlModal/testSqlModal.component';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class Prism1MetricEditService implements IPrism1MetricEditService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog,
        private state: StateService, private changeDetection: ChangeDetectorRef) {
        //
    }
    async initDelegate(states: IPrism1MetricEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IPrism1MetricEditStates) {
        const observationCategoryHierarchies = await this.dataAccess.genericFind({
            model: 'ObservationCategoryHierarchy'
        });
        const productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle',
            filter: { order: 'title' }
        });
        const careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { order: 'title', where: { assignable: true } }
        });
        const observations = await this.dataAccess.genericFind({
            model: 'Observation',
            filter: { order: 'name' }
        });
        const episodeGroupings = await this.dataAccess.genericFind({
            model: 'EpisodeGrouping',
            filter: { order: 'groupByName', fields: ['groupByAttribute', 'groupByName', 'validCareGroupings'] }
        });
        const entities = await this.dataAccess.genericFind({
            model: 'EpisodeGroupingEntity'
        });

        states.observationCategoryHierarchies = _.orderBy(_.map(observationCategoryHierarchies, (h: any) => {
            h.name = h.category + CONST.HIERARCHY_DELIMITER + h.category2 + CONST.HIERARCHY_DELIMITER + h.category3 + CONST.HIERARCHY_DELIMITER + h.category4;
            return h;
        }), 'name');
        states.productBundles = productBundles;
        states.careGroupings = careGroupings;
        states.episodeGroupings = episodeGroupings;
        states.observations = observations;
        states.episodeGroupingEntities = _.map(entities, 'entity');
        if (this.uiRouter.globals.params.id) {
            const metric: any = await this.getMetric(states);
            states.metric = metric;
            states.originalMetric = _.cloneDeep(metric);

            if (this.uiRouter.globals.current.name === 'argos.ccgDesigner.prism1Metrics.copy') {
                states.editMode = false;
                states.metric.defaultVisibility = 'Disable';
                delete states.metric.id;
                delete states.metric.name;
            }

            states.originalMetric = _.cloneDeep(metric);
            const hierarchy = metric.bucket + CONST.HIERARCHY_DELIMITER + metric.bucket2 + CONST.HIERARCHY_DELIMITER + metric.bucket3 + CONST.HIERARCHY_DELIMITER + metric.bucket4;
            states.metric.observationCategoryHierarchyId = _.get(_.find(states.observationCategoryHierarchies, { name: hierarchy }), 'id');
            _.forEach(states.metric.validCareGroupings, function (careGroupingName) {
                const validCareGrouping: any = _.find(states.careGroupings, { name: careGroupingName });
                if (validCareGrouping) {
                    validCareGrouping.selectedAsValid = true;
                }
            });
            _.forEach(states.metric.productBundles, (productBundleName: any) => {
                const productBundle: any = _.find(states.productBundles, { shortName: productBundleName });
                if (productBundle) {
                    productBundle.enabled = true;
                    states.selectedProductBundleCount += 1;
                }
            });
            states.displayRestrictedGroupingsLength = _.size(states.metric.restrictedGroupings);
            states.displayRestrictedGroupings = _.join(_.map(states.metric.restrictedGroupings, function (restrictedGrouping) {
                return _.get(_.find(states.episodeGroupings, { groupByAttribute: restrictedGrouping }), 'groupByName');
            }), ', ');

            if (_.isEmpty(metric.externalFunctionParam)) {
                states.externalFunctionParam = _.isNil(metric.externalFunctionParam) ? undefined : '';
            } else {
                states.externalFunctionParam = JSON.stringify(metric.externalFunctionParam, null, 2);
            }
        } else {
            states.metric = {
                storageType: 'Number',
                defaultVisibility: 'Disable',
                betterDirection: 'Lower'
            };
        }
    }

    async metricNameChangeHandler(states: IPrism1MetricEditStates) {
        states.metricformValidity = true;
        if (states.metric && states.metric.name && states.metric.name.length > 0) {
            const otherMetric = await this.dataAccess.genericFind({
                model: 'OtherMetric',
                filter: { where: { name: states.metric.name } }
            });
            const observation = await this.dataAccess.genericFind({
                model: 'Observation',
                filter: { where: { name: states.metric.name } }
            });
            if ((otherMetric && otherMetric.length > 0) || (observation && observation.length > 0)) {
                states.metricformValidity = false;
            }
        }
    }

    async metricSqlChangeHandler(states: IPrism1MetricEditStates) {
        states.metricSqlValidity = true;
        if (states.metric && states.metric.metricSql) {

            // If a closing parentheses is before an opening parentheses, this metric SQL is not valid. 
            const closeParentheses = _.indexOf(states.metric.metricSql, ')');
            const openParentheses = _.indexOf(states.metric.metricSql, '(');
            const metricHackSql = (closeParentheses >= 0 && openParentheses >=0 && openParentheses > closeParentheses);

            if (metricHackSql) {
                states.metricSqlValidity = false;
            }
        }
    }    

    editRestrictedGroupings(states: IPrism1MetricEditStates) {

        const validCareGroupings: any = [];
        _.forEach(states.careGroupings, (careGrouping) => {
            if (careGrouping.selectedAsValid) {
                validCareGroupings.push(careGrouping.name);
            }
        });
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    restrictedGroupings: states.metric.restrictedGroupings,
                    validCareGroupings,
                    episodeGroupings: states.episodeGroupings
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            EditRestrictedGroupingsModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            if (result) {
                states.metric.restrictedGroupings = result.restrictedGroupings || [];
                states.displayRestrictedGroupingsLength = _.size(states.metric.restrictedGroupings);
                states.displayRestrictedGroupings = _.join(_.map(states.metric.restrictedGroupings, function (restrictedGrouping) {
                    return _.get(_.find(states.episodeGroupings, { groupByAttribute: restrictedGrouping }), 'groupByName');
                }), ', ');
            }
        });
    }

    async getMetric(states: IPrism1MetricEditStates) {
        let id = this.uiRouter.globals.params.id;
        if (states.metric && states.metric.id) {
            id = states.metric.id;
        }
        const metric = await this.dataAccess.genericMethod({
            model: 'OtherMetric', method: 'findById',
            parameters: {
                id,
                filter: { include: ['metricHistories'] }
            }
        });
        return metric;
    }

    async getMetricByName(name: string) {
        const metric = await this.dataAccess.genericMethod({
            model: 'OtherMetric', method: 'findOne',
            parameters: {
                filter: {
                    where: {
                        name
                    }
                }
            }
        });
        return metric;
    }

    testSqlHandler(states: IPrism1MetricEditStates, isSaving = false, isClose = false) {
        this.setMetricProperties(states);

        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    type: 'OtherMetric',
                    metric: states.metric,
                    isSaving
                }
            }
        };

        const dialogRef = this.matDialog.open(TestSqlModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async (action: 'cancel' | 'save') => {
            switch(action) {
                case 'cancel':
                    states.saveInProgress = false;
                    this.changeDetection.detectChanges();
                    break;
                case 'save':
                    await this.saveMetric(states, isClose);
            }
        });
    }

    async saveHandler(states: IPrism1MetricEditStates, isClose: boolean) {
        // Need to add logic here
        states.saveInProgress = true;
        states.originalMetric = states.originalMetric || {};

        this.setMetricProperties(states);

        const aggregationTemplateSqlChanged = !_.isEqual(states.metric.aggregationTemplateSql, states.originalMetric.aggregationTemplateSql);
        const externalTablenameChanged = !_.isEqual(states.metric.externalTablename, states.originalMetric.externalTablename);
        const externalFunctionParamChanged = !_.isEqual(states.metric.externalFunctionParam, states.originalMetric.externalFunctionParam);
        const externalJoinSqlChanged = !_.isEqual(states.metric.externalJoinSql, states.originalMetric.externalJoinSql);
        const externalJoinEntityChanged = !_.isEqual(states.metric.externalJoinEntity, states.originalMetric.externalJoinEntity);
        const metricSqlChanged = !_.isEqual(states.metric.metricSql, states.originalMetric.metricSql);
        const metricWeightSqlChanged = !_.isEqual(states.metric.metricWeightSql, states.originalMetric.metricWeightSql);

        const runSqlTest = _.some([aggregationTemplateSqlChanged, externalTablenameChanged, externalFunctionParamChanged, externalJoinSqlChanged, externalJoinEntityChanged, metricSqlChanged, metricWeightSqlChanged]);
        if (runSqlTest) {
            console.log('Running SQL Test');
            this.testSqlHandler(states, true, isClose);
        } else {
            await this.saveMetric(states, isClose);
        }
    }

    setMetricProperties(states: IPrism1MetricEditStates) {
        if (!states.metric.id) {
            states.metric.id = CryptoJS.MD5(states.metric.name).toString();
        }

        // See ARG-399
        if (!states.metric.metricType) {
            states.metric.metricType = null;
        }

        states.metric.validCareGroupings = [];
        _.forEach(states.careGroupings, function (careGrouping) {
            if (careGrouping.selectedAsValid) {
                states.metric.validCareGroupings.push(careGrouping.name);
            }
        });
        states.metric.productBundles = _.map(_.filter(states.productBundles, { enabled: true }), 'shortName');

        if (_.get(states.metric, 'storageType') === 'Number' && _.get(states.metric, 'inputRegex')) {
            states.metric.inputRegex = null;
            states.metric.inputErrorMessage = null;
        }

        if (states.metric.observationCategoryHierarchyId) {
            const hierarchy = _.find(states.observationCategoryHierarchies, { id: states.metric.observationCategoryHierarchyId });
            states.metric.bucket = _.get(hierarchy, 'category');
            states.metric.bucket2 = _.get(hierarchy, 'category2');
            states.metric.bucket3 = _.get(hierarchy, 'category3');
            states.metric.bucket4 = _.get(hierarchy, 'category4');
        }

        if (_.isEmpty(states.externalFunctionParam)) {
            states.metric.externalFunctionParam = _.isNil(states.externalFunctionParam) ? undefined : '';
        } else {
            states.metric.externalFunctionParam = JSON.parse(states.externalFunctionParam);
        }
    }

    async saveMetric(states: IPrism1MetricEditStates, isClose: boolean) {
        this.setMetricProperties(states);

        let metric = {};
        try {
            metric = await this.dataAccess.genericUpsert({
                model: 'OtherMetric',
                data: states.metric
            });
        } catch (err) {
            console.log(err.toString());
        }

        states.metric = await this.getMetric(states);
        await this.saveMetricHistory(states);

        if (isClose) {
            this.state.go('argos.ccgDesigner.prism1Metrics.list');
        } else {
            this.state.go('argos.ccgDesigner.prism1Metrics.edit', { id: _.get(metric, 'id') }, { reload: true });
        }

        states.saveInProgress = false;

    }

    editExternalJoinEntityHandler(externalJoinEntity: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    episodeGroupingEntityId: externalJoinEntity,
                    editMode: true
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(EditJoinEntityModalComponent, dialogConfig);
    }

    async saveMetricHistory(states: IPrism1MetricEditStates) {

        // Only save a new version if something actually changed.
        if (_.isEqual(states.originalMetric, states.metric)) {
            return Promise.resolve();
        }

        let version = 0;
        const username = this.argosStore.getItem('username');
        if (states.metric && states.metric.metricHistories && states.metric.metricHistories.length > 0) {
            version = _.max(_.map(states.metric.metricHistories, 'version')) || 0;
        }
        version++;

        delete states.metric.metricHistories;
        return this.dataAccess.genericUpsert({
            model: 'MetricHistory',
            data: {
                otherMetricId: states.metric.id,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: states.metric
            }
        });
    }

    viewHistory(states: IPrism1MetricEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    metric: states.metric
                }
            }
        };
        this.matDialog.open(ViewMetricHistoryModalComponent, dialogConfig);
    }

    async otherMetricTitleChangeHandler(states: IPrism1MetricEditStates) {
        states.otherMetricTitleDuplicate = {};

        if (states.metric && states.metric.shortName && states.metric.shortName.length > 0) {
            const existingMetricfilter: any = {
                where: {
                    and: [
                        {
                            shortName: states.metric.shortName
                        },
                        {
                            id: { neq: states.metric.id }
                        }
                    ]
                }
            };
            const filter: any = {
                where: { shortName: states.metric.shortName }
            };

            const observations = await this.dataAccess.genericFind({
                model: 'Observation',
                filter: {
                    where: { title: states.metric.shortName }
                }
            });

            const otherMetrics = await this.dataAccess.genericFind({
                model: 'OtherMetric',
                filter: states.metric.id ? existingMetricfilter : filter
            });

            if (otherMetrics && otherMetrics.length > 0) {
                states.otherMetricTitleDuplicate = {
                    error: 'The name you selected is already used by an other metric, please consider renaming to prevent confusion.',
                    uiSref: 'prism1Metrics',
                    id: otherMetrics[0].id
                };
                this.changeDetection.detectChanges();
            } else if (observations.length > 0) {
                states.otherMetricTitleDuplicate = {
                    error: 'The name you selected is already used by an observation, please consider renaming to prevent confusion.',
                    uiSref: 'observations',
                    id: observations[0].id
                };
                this.changeDetection.detectChanges();
            }
        }
    }
}
