import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IHospitalRatesBulkAddStates, IHospitalRatesBulkAddService } from './hospitalRatesBulkAdd.component.d';
import * as _ from 'lodash';
import { UtilsService } from '../../../services/utils.service';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { ArgosStoreService } from '../../../services/argosStore.service';

@Injectable()
export class HospitalRatesBulkAddService implements IHospitalRatesBulkAddService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService,
        private argosStore: ArgosStoreService, private utilsSvc: UtilsService) { }

    async initDelegate(states: IHospitalRatesBulkAddStates): Promise<IHospitalRatesBulkAddStates> {
        return states;
    }

    async validateNewHospitals(newHospitals: any[]) {
        const errorHospitals: any[] = [];
        const existingHospitals = await this.dataAccess.genericFind({
            model: 'HospitalRatesHospitals'
        });
        const existingNpis = existingHospitals.map((hospital: { npi: any; }) => hospital.npi);
        const npisInFile = newHospitals.map((hospital: { npi: any; }) => hospital.npi);
        for (const [index,hospital] of newHospitals.entries()) {
            hospital.index = index;
            let validatedHospital = this.validateHospitalRecord(hospital, existingNpis, npisInFile);
            if (validatedHospital.errorList.length > 0) {
                errorHospitals.push(validatedHospital);
            }
        };
        if (errorHospitals.length > 0) {
            return errorHospitals;
        }
        else {
            return null;
        }
    };

    validateHospitalRecord(hospital: any, existingNpis: any[], npisInFile: any[]) {
        hospital.errorList = [];
        // Validation rules
        const validationRules = {
            priority: {
                message: 'Priority must be a number between 1 and 5',
                check: (value: any) => value && (value >= 1 && value <= 5)
            },
            ccn: {
                message: 'CCN must be a 6 digit number',
                check: (value: any) => !value || (value.toString().length === 6 && this.utilsSvc.isNumeric(value))
            },
            npi: {
                message: 'NPI must be a 10 digit number',
                check: (value: any) => value && (value.toString().length === 10 && this.utilsSvc.isNumeric(value))
            },
            hospitalName: {
                message: 'Hospital name is required',
                check: (value: any) => value
            },
            state: {
                message: 'State must be a 2 character abbreviation',
                check: (value: any) => value && (value.length === 2)
            },
            zip: {
                message: 'Zip code must be a 5 digit number',
                check: (value: any) => !value || (value.toString().length === 5 && this.utilsSvc.isNumeric(value))
            }
        };
        // Apply validation rules
        Object.entries(validationRules).forEach(([field, { message, check }]) => {
            if (!check(hospital[field])) {
                hospital.errorList.push(message);
            }
        });
        // Check for uniqueness
        if (existingNpis.includes(hospital.npi)) {
            hospital.errorList.push('NPI already exists in the system. Edit the existing record instead');
        }
        if (npisInFile.filter(npi => npi === hospital.npi).length > 1) {
            hospital.errorList.push('NPI is not unique in the file');
        }
        return hospital;
    };

    async uploadHospital(hospital: any, currentLoadSequence: number) {
        try {
            const createHospital = await this.dataAccess.genericCreate({
                model: 'HospitalRatesHospitals',
                data: {
                    npi: hospital.npi,
                    prvdrNum: hospital.ccn,
                    hospitalName: hospital.hospitalName,
                    streetAddressLine1: hospital.streetAddress,
                    city: hospital.city,
                    state: hospital.state,
                    zipCode: hospital.zipCode,
                    ratesFileUrl: hospital.ratesFileUrl
                }
            });
            await this.dataAccess.genericCreate({
                model: 'HospitalRatesPriority',
                data: {
                    hospitalId: createHospital.id,
                    priority: hospital.priority
                }
            });
            await this.dataAccess.genericCreate({
                model: 'HospitalRatesClarifyMetadata',
                data: {
                    hospitalId: createHospital.id,
                    customers: hospital.customers,
                    requestedBy: this.argosStore.getItem('username')
                }
            });
            await this.dataAccess.genericCreate({
                model: 'HospitalRatesHospitalLoadSequences',
                data: {
                    hospital: createHospital.id,
                    loadSequence: currentLoadSequence,
                    ratesFileSearchStatus: 'NOT_YET_ATTEMPTED'
                }
            });
        } catch (error: any) {
            console.log(error);
            let insertTime = new Date().toISOString();
            await this.dataAccess.genericCreate({
                model: 'HospitalRatesBulkAddErrors',
                data: {
                    npi: hospital.npi,
                    hospitalName: hospital.hospitalName,
                    insertTime: insertTime,
                    insertError: error?.body?.error?.message
                }
            })
            return hospital;
        }
        return null;
    };

    downloadHeadersTemplate(headerFields: any[]) {
        const fieldHints = ['Required Number 1-5 Only', 'Optional 6 digits', 'Required 10 digits', 'Required', 'Optional', 'Optional', 'Required 2 character abbreviation', 'Optional 5 digits', 'Optional', 'Optional'];
        const dataFields = headerFields.reduce((acc, headerField, index) => {
            return {
                ...acc,
                [headerField]: fieldHints[index]
            };
        }, {});
        this.exportToExcelSvc.exportToFile('hospitalRatesBulkTemplate', headerFields, [dataFields], true);
    };

    async getCurrentLoadSequence() {
        const currentLoadSequence = await this.dataAccess.genericMethod({
            model: 'HospitalRatesLoadSequences',
            method: 'getCurrentLoadSequence'
        })
        return currentLoadSequence[0].id;
    };

};