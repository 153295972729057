import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IBuildsStates } from './builds.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { BuildsService } from './builds.component.service';

@Component({
    selector: 'argos-builds',
    templateUrl: './builds.component.html',
    providers: [BuildsService]
})

export class BuildsComponent extends ReactComponentBase<{}, IBuildsStates, {}> implements OnInit {
    constructor(private svc: BuildsService, private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    daysToQueryChange() {
        this.svc.queryUsers(this.states);
    }
}
