import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IQATestResultTabAndTileDetailStates } from './qaTestResultsDashboardTabAndTileDetails.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { QATestResultDetailService } from './qaTestResultsDashboardTabAndTileDetails.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as _ from 'lodash';


@Component({
    selector: 'argos-qaTestResultDetail',
    templateUrl: './qaTestResultsDashboardTabAndTileDetails.component.html',
    providers: [QATestResultDetailService],
    styleUrls: ['../testResultsDashboard/qaTestResultDashboard.component.less']
})

export class QATestResultsTabAndTileDetailComponent extends ReactComponentBase<{}, IQATestResultTabAndTileDetailStates, {}> implements OnInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: QATestResultDetailService, private cdr: ChangeDetectorRef) {
        super({
            pctDataTable: new MatTableDataSource([])
        } as IQATestResultTabAndTileDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges;
    }

    searchMatTable(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.pctDataTable.filter = filterValue.trim().toLowerCase();
    }

    
}