import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IMarketShareMetaEditStates } from './marketShareMetaEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MarketShareMetaEditService } from './marketShareMetaEdit.component.service';

@Component({
    selector: 'argos-marketShareMetaEdit',
    templateUrl: './marketShareMetaEdit.component.html',
    providers: [MarketShareMetaEditService],
    styleUrls: ['./marketShareMetaEdit.component.css']
})

export class MarketShareMetaEditComponent extends ReactComponentBase<{}, IMarketShareMetaEditStates, {}> implements OnInit {
    constructor(private svc: MarketShareMetaEditService, private cdr: ChangeDetectorRef) {
        super({
            marketShareMeta: {
                source_table: ''
            },
            saveInProgress: false
        } as IMarketShareMetaEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.svc.save(this.states);
    }

    objectKeys(obj: any): string[] {
        return Object.keys(obj);
    }
    
    deleteParameter(key: string) {
        delete this.states.marketShareMeta.parameters[key];
    }

    addParameter() {
        this.states.marketShareMeta.parameters = {
            ['']: '',
            ...this.states.marketShareMeta.parameters,
        };
    }

    updateKey(oldKey: string, newKey: string) {
        this.states.marketShareMeta.parameters[newKey] = this.states.marketShareMeta.parameters[oldKey];
        delete this.states.marketShareMeta.parameters[oldKey];
    }

}
