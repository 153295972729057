import { Injectable } from '@angular/core';
import { IViewReleaseImpactEnvironmentModalService, IViewReleaseImpactEnvironmentModalProps, IViewReleaseImpactEnvironmentModalStates } from './viewReleaseImpactEnvironmentModal.component.d';

@Injectable()
export class ViewReleaseImpactEnvironmentModalService implements IViewReleaseImpactEnvironmentModalService {
    constructor() {}

    initDelegate(props: IViewReleaseImpactEnvironmentModalProps, states: IViewReleaseImpactEnvironmentModalStates): Object {
        this.init(props, states);
        return states;
    };

    init(props: IViewReleaseImpactEnvironmentModalProps, states: IViewReleaseImpactEnvironmentModalStates) {
        states.environments = props.environments.join('\n');
        return states;
    };

} 