import { Injectable } from '@angular/core';
import { IQATestResultTabAndTileDetailStates, IQATestResultTabAndTileDetailService } from './qaTestResultsDashboardTabAndTileDetails.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';
import { PlatformReleaseResultsService } from '../../../services/platformReleaseResultsService.service';

import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Injectable()
export class QATestResultDetailService implements IQATestResultTabAndTileDetailService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService, private platformReleaseResultsService: PlatformReleaseResultsService, private matDialog: MatDialog) {
        //
    }

    async initDelegate(states: IQATestResultTabAndTileDetailStates): Promise<object> {
        await this.fetchResults(states);
        return {};
    }

    async fetchResults(states: IQATestResultTabAndTileDetailStates) {
        states.pctProductId = this.platformReleaseResultsService.getproductId();
        const dateFilter = this.platformReleaseResultsService.getSelectedDate();
        const requestPayload: any = {
            name: this.uiRouter.globals.params.customer,
            submodule_name: this.uiRouter.globals.params.submoduleid,
            product_name: this.uiRouter.globals.params.productId,
            dashboard_name: this.uiRouter.globals.params.dashboard
        };
        if (dateFilter) {
            requestPayload.release_date = dateFilter;
        }
        const pctResults = await this.dataAccess.genericFind({
            model: 'ProductionConfigurationTestResults',
            filter: {

                where: requestPayload
            }
        });

        states.pctSelectedReleaseDate = dateFilter;
        states.pctSubmoduleId = this.uiRouter.globals.params.submoduleid;
        states.customerName = this.uiRouter.globals.params.customer;
        states.pctResults = pctResults

        const resultsGroupedByPageName = _(pctResults)
            .map((obj: any) => ({ ...obj }))
            .orderBy(['dashboard_name', 'tab_name', 'tile_name', 'status'])
            .groupBy((item) => [item['tab_name'], item['tile_name'], item['status']])
            .value();

        const structuredData: object[] = [];
        const releaseKeys: string[] = Object.keys(resultsGroupedByPageName);

        for (let i = 0; i < releaseKeys.length; i++) {
            const releaseData = _.orderBy(resultsGroupedByPageName[releaseKeys[i]], ['dashboard_name']);
            let currentPageData: any;
            for (let j = 0; j < releaseData.length; j++) {

                if (j === 0) {
                    currentPageData = {
                        id: releaseData[j].id,
                        dashboard: releaseData[j].dashboard_name,
                        tab: releaseData[j].tab_name,
                        tile: releaseData[j].tile_name,
                        status: releaseData[j].status,
                        comments: releaseData[j].comments
                    };
                }
            }
            structuredData.push(currentPageData);
        }
        console.log(structuredData)
        states.pctDataTable.data = _.orderBy(structuredData, ['status'], ['asc'])

    }
}