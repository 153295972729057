import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { ReactComponentBase } from "../../reactComponentBase/reactComponentBase.component";
import { IReleaseAddDagModalProps, IReleaseAddDagModalStates } from "./releaseAddDagModal.component.d";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReleaseAddDagModalService } from "./releaseAddDagModal.component.service";

@Component({
    selector: 'argos-release-add-dag-modal',
    templateUrl: './releaseAddDagModal.component.html',
    providers: [ReleaseAddDagModalService]
})

export class ReleaseAddDagModalComponent extends ReactComponentBase<IReleaseAddDagModalProps, IReleaseAddDagModalStates, {}>
    implements OnInit {
    constructor(private svc: ReleaseAddDagModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ReleaseAddDagModalComponent>, private SnackBar: MatSnackBar) {
            super();
            this.props = dialogData.props;
        }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    addDagToRelease(dagConfig: string) {
        const response = this.svc.addDagToRelease(this.props, dagConfig);
        if (response === 'error') {
            this.SnackBar.open('Invalid DAG Config', 'Close', {
                duration: 3000
            });
            return;
        }
        this.dialogRef.close({ dagId: this.props.dagId, dagConfig: dagConfig });
    }

    close() {
        this.dialogRef.close('cancel');
    }
}