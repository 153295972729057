import { Injectable } from '@angular/core';
import { UIRouter, StateService } from '@uirouter/core';

@Injectable()
export class UrlService {
    constructor(private stateService: StateService, private uiRouter: UIRouter){
        //
    }
    public getQueryStringFromUrl(): string {
        let queryString = '';
        const stateParams = this.uiRouter.globals.params;
        if (stateParams.queryString) {
            queryString = stateParams.queryString;
        }
        return queryString;
    }

    public getModalParamFromUrl(): boolean {
        let openModal = false;
        const stateParams = this.uiRouter.globals.params;
        if (stateParams.queryString && stateParams.openModal) {
            openModal = JSON.parse(stateParams.openModal);
        }
        return openModal;
    }

    public updateUrlWithQueryString(queryString: string, openModal?: boolean) {
        if (typeof window.history?.pushState === 'function') {
            const newHref = this.stateService.href('.', { queryString: queryString === '{}' ? '' : queryString, openModal: openModal === false ? '' : openModal });
            window.history.pushState({}, '', newHref);
        }
    }

    public checkShowViewHistoryFromUrl(): boolean {
        const stateParams = this.uiRouter.globals.params;
        if (stateParams.showViewHistory) {
            return true;
        } else {
            return false;
        }
    }
}
