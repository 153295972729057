import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ICcgReportListStates } from './ccgReportList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CcgReportListService } from './ccgReportList.component.service';

@Component({
    selector: 'argos-ccgReportList',
    templateUrl: './ccgReportList.component.html',
    providers: [CcgReportListService]
})

export class CcgReportListComponent extends ReactComponentBase<{}, ICcgReportListStates, {}> implements OnInit {
    constructor(private svc: CcgReportListService, private cdr: ChangeDetectorRef) {
        super({ clientTable: [] } as ICcgReportListStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    requestReport(reportName: any) {
        this.svc.requestReport(reportName);
    }
}
