import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IReferenceListItemsListStates } from './referenceListItemsList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ReferenceListItemsListService } from './referenceListItemsList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-referenceListItemsList',
    templateUrl: './referenceListItemsList.component.html',
    providers: [ReferenceListItemsListService]
})

export class ReferenceListItemsListComponent
    extends ReactComponentBase<{}, IReferenceListItemsListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;

    constructor(private svc: ReferenceListItemsListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            pageSize: 50,
            filterObj: {},
            referenceDataLists: [],
            referenceListItemsTable: new MatTableDataSource([]),
            matTableColumns: ['name', 'description', 'itemCount', 'tagCount', 'observationCount', 'supportsTagHierarchy']
        } as unknown as IReferenceListItemsListStates);
    }

    ngAfterViewInit() {
        this.states.referenceListItemsTable.paginator = this.paginator;
        this.states.referenceListItemsTable.sort = this.matTbSort;
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.referenceListItemsTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.referenceListItemsTable.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.referenceListItemsTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.referenceListItemsTable.filter);
    }

    searchList(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.referenceListItemsTable.filter = filterValue.trim().toLowerCase();
    }

    pageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        this.states.tableShowRows = this.states.referenceDataLists.slice(page, page + event.pageSize);
        this.states.referenceListItemsTable = new MatTableDataSource(this.states.tableShowRows);
    }
}
