import { Component, OnChanges, SimpleChanges, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { IPatchManagementListProps, IPatchManagementListStates, IPatchManagementListService } from './patchManagementList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PatchManagementListService } from './patchManagementList.component.service';
import { StateService } from '@uirouter/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgDataAccess } from 'client/app/services/dataAccess.service';
import { PatchManagementAddEditModalComponent } from '../patchManagementAddEditModal/patchManagementAddEditModal.component';
import * as _ from 'lodash';
import { PatchManagementPreviewModalComponent } from '../patchManagementPreviewModal/patchManagementPreviewModal.component';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';

@Component({
    selector: 'argos-patch-management-list',
    templateUrl: './patchManagementList.component.html',
    providers: [PatchManagementListService]
})

export class PatchManagementListComponent extends ReactComponentBase<{}, IPatchManagementListStates, {}> implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('tbMatSort') tbMatSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: PatchManagementListService, private state: StateService, private dataAccess: NgDataAccess, private matDialog: MatDialog, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            loading: true,
            hasError: false,
            patchList: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['id', 'name', 'description', 'tagName', 'deployedToEnvironmentCount', 'createdBy', 'createdDate', 'deleteButton', 'editButton', 'previewButton'],
            matTableColumns: ['id', 'name', 'description', 'tagName', 'deployedToEnvironmentCount', 'createdBy', 'createdDate', 'deleteButton', 'editButton', 'previewButton'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'patchManagementList'
        } as unknown as IPatchManagementListStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.tbMatSort;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(this.states);
    }

    async delete(row: any) {
        try {
            this.svc.deletePatch(row, this.states, this.cdr);
            this.cdr.detectChanges();
        } catch (error) {
            console.log(error);
        }
    }

    async edit(patch: any) {
        try {
            this.add(patch);
        } catch (error) {
            console.log(error);
        }
    }

    add(contentData?: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: true,
            data: {
                props: {
                    content: contentData ?? {},
                    states: this.states
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(PatchManagementAddEditModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (result: any) => {
            if (_.isObject(result)) {
                this.states.loading = true;
                await this.svc.getPatchTableList(this.states, this.cdr);
                this.states.loading = false;
                this.cdr.detectChanges();
            }
        });
    }

    preview(contentData: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: true,
            data: {
                props: {
                    content: contentData,
                    states: this.states
                }
            }
        };
        this.matDialog.open(PatchManagementPreviewModalComponent, dialogConfig);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }
}