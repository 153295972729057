import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IPipelineDataSourceEditStates } from './pipelineDataSourceEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PipelineDataSourceEditService } from './pipelineDataSourceEdit.component.service';
import { FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
    selector: 'argos-pipelineDataSourceEdit',
    templateUrl: './pipelineDataSourceEdit.component.html',
    providers: [PipelineDataSourceEditService]
})

export class PipelineDataSourceEditComponent extends ReactComponentBase<{}, IPipelineDataSourceEditStates, {}> implements OnInit {

    constructor(private svc: PipelineDataSourceEditService, private cdr: ChangeDetectorRef) {
        super({
            datasource: {},
            existingDataSources: []
        } as IPipelineDataSourceEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.svc.saveHandler(this.states);
    }

    propertyValueExists(field: string, value: string) {
        let result = false;

        if (value && value.trim().length > 0 && this.states.existingDataSources) {
            const exists = _.find(this.states.existingDataSources, function (ds) {
                if (ds[field].toLowerCase() === value.toLowerCase()) {
                    return ds;
                }
            });

            if (exists) {
                result = true;
            }
        }

        return result;
    }

    isSaveDisabled() {
        let result = false;

        if (this.propertyValueExists('code', this.states.datasource.code) || this.propertyValueExists('name', this.states.datasource.name)) {
            result = true;
        }

        return result;
    }
}
