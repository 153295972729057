import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IPrism1MetricImportStates } from './prism1MetricImport.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { Prism1MetricImportService } from './prism1MetricImport.component.service';
import { FileService } from 'client/app/services/handleFile.service';

@Component({
    selector: 'argos-prism1MetricImport',
    templateUrl: './prism1MetricImport.component.html',
    providers: [Prism1MetricImportService]
})

export class Prism1MetricImportComponent extends ReactComponentBase<{}, IPrism1MetricImportStates, {}> implements OnInit {
    constructor(private svc: Prism1MetricImportService, private fileHandler: FileService,
        private cdr: ChangeDetectorRef) {
        super({
            existingMetrics: [],
            eventAction: false,
            metrics: [],
            aggregationTypes: [],
            observationCategoryHierarchies: [],
            metricUnits: [],
            betterDirections: [],
            storageTypes: []
        } as unknown as IPrism1MetricImportStates);
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.states);
        this.setStates(newStates);
    }

    copyCSVHeaders() {
        this.svc.copyCSVHeaders(this.states);
    }

    uploadFile() {
        this.states.metrics = this.fileHandler.uploadFileHandler(this.states.importFile);
        this.states.importFile = undefined;
        const metrics = this.svc.validateMetrics(this.states);
        this.setStates({ metrics });
    }

    async importValidMetrics() {
        await this.svc.importValidMetrics(this.states, this.refresh);
    }

    async saveMetric(row: any, index: number) {
        row.imported = true;
        await this.svc.saveMetric(row, index, this.states);
    }

    getFieldErrorMessage(row: any) {
        return this.svc.getFieldErrorMessage(row, this.states);
    }

    copyErrorToClipboard(row: any) {
        this.svc.copyErrorToClipboard(row, this.states);
    }

    errorExists(row: any, name: string) {
        return this.svc.errorExists(row, name, this.states);
    }

    refresh: Function = () => {
        this.cdr.detectChanges();
    };

    importFile(event: any) {
        this.fileHandler.importFileHandler(event, (importFile: string) => {
            this.states.importFile = importFile;
            this.refresh();
        });
    }

    setValueNull(event: any): void {
        event.target.value = null;
    }

}
