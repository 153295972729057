// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bold-label {
    margin-bottom: 0px;
    font-weight: bold;
}

.static-input {
    width: 100%;
    margin-bottom: 5px;
}

.full-width-div {
    width: 100%;
}

.input-checkbox {
    width: initial;
}

.dag-config-display-box {
    width: 100%;
    margin-bottom: 1px;
}

.legend-section {
    margin-bottom: 5px; 
    margin-top:0px;
}

.body-container {
    max-height: calc(100vh - 150px); 
    overflow-y: scroll;
}

.title-h2 {
    text-align: center;
}

.header-container {
    width: 100%;
}

.filter-selector {
    margin-top: 10px;
    margin-bottom: 10px;
  }

.mat-form-field {
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./client/app/components/clarifyInsights/clarifyInsightsTriggerTransferModal/clarifyInsightsTriggerTransferModal.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,+BAA+B;IAC/B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;EACrB;;AAEF;EACE,iBAAiB;AACnB","sourcesContent":[".bold-label {\n    margin-bottom: 0px;\n    font-weight: bold;\n}\n\n.static-input {\n    width: 100%;\n    margin-bottom: 5px;\n}\n\n.full-width-div {\n    width: 100%;\n}\n\n.input-checkbox {\n    width: initial;\n}\n\n.dag-config-display-box {\n    width: 100%;\n    margin-bottom: 1px;\n}\n\n.legend-section {\n    margin-bottom: 5px; \n    margin-top:0px;\n}\n\n.body-container {\n    max-height: calc(100vh - 150px); \n    overflow-y: scroll;\n}\n\n.title-h2 {\n    text-align: center;\n}\n\n.header-container {\n    width: 100%;\n}\n\n.filter-selector {\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n\n.mat-form-field {\n  margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
