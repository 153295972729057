import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IClarifyInsightsFinalizeCustomerModalInterface } from './clarifyInsightsFinalizeCustomerModal.component.d';

@Injectable()
export class ClarifyInsightsFinalizeCustomerModalService {

    constructor(private dataAccess: NgDataAccess) { }

    initDelegate(props: any, states: IClarifyInsightsFinalizeCustomerModalInterface): any {
        states.customerId = props.customerId;
        states.customerCode = props.customerCode;
        states.s3DestinationBucket = props.s3DestinationBucket;
        return states;
    }

    async finalizeAccountHelper(row: any, customerCode: string, s3DestinationBucket: string) {
        try {
            await this.dataAccess.genericMethod({
                model: 'ClarifyInsightsCustomer',
                method: 'provisionAccount',
                parameters: {
                    body: {
                        awsAccountId: row.awsAccountId,
                        customerCode,
                        s3DestinationBucket
                    }
                }
            });
            await this.dataAccess.genericUpsert({
                model: 'ClarifyInsightsCustomer',
                data: row
            });
            return `Successfully updated ${customerCode} to AWS account ID ${row.awsAccountId} and generated corresponding AWS resources`;
        }
        catch (exception: any) {
            return `Resource creation failed for ${customerCode}: ${exception.body.error.message}`;
        }
    }
}
