import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
    selector: 'argos-view-filter-perf-env-modal',
    templateUrl: './viewFilterPerfEnvironmentModal.component.html'
})

export class ViewFilterPerfEnvironmentModalComponent {

    groupByAttribute: string = '';
    usages: any = [];

    constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) dialogData: any) {
        this.groupByAttribute = dialogData?.groupByAttribute;
        this.usages = dialogData?.usages;
    }

    close() {
        this.dialogRef.close();
    }
}
