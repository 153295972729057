import { Component } from '@angular/core';

@Component({
    selector: 'argos-pipelineHistoryDetailRoot',
    template: `<ui-view></ui-view>`
})

export class PipelineHistoryDetailRootComponent {

}
