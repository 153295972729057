import { Component, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { IReleasesProps, IReleasesStates } from './releases.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ReleasesService } from './releases.component.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'argos-releases',
    templateUrl: './releases.component.html',
    providers: [ReleasesService]
})

export class ReleasesComponent extends ReactComponentBase<IReleasesProps, IReleasesStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ReleasesService, private state: StateService,
        private cdr: ChangeDetectorRef) {
        super({
            noData: '',
            queryRunning: true
        } as unknown as IReleasesStates);
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.props, this.states);
        this.setStates(newStates);
        this.cdr.detectChanges();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        const newStates = await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.setStates(newStates);
        this.cdr.detectChanges();
    }

    newRelease() {
        this.state.go('argos.publish_release');
    }

    deleteRelease(id: any, name: any) {
        this.svc.deleteReleaseHandler(id, name, this.states, this.refreshPage);
    }

    refreshPage: Function = (newStates: IReleasesStates) => {
        this.setStates(newStates);
        this.cdr.detectChanges();
    };
}
