import { Injectable } from '@angular/core';
import { IPrismUsageStates, IPrismUsageService } from './prismUsage.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { StateService } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewEnvironmentUsageModalComponent } from '../viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { AlertService } from 'client/app/services/alert.service';
declare const saveAs: any;

@Injectable()
export class PrismUsageService implements IPrismUsageService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private state: StateService, private alertSvc: AlertService) {
        //
    }
    async initDelegate(states: IPrismUsageStates): Promise<object> {
        states.queryRunning = true;
        await this.getEnvironments(states);
        return {};
    }

    async getEnvironments(states: IPrismUsageStates) {
        states.observations = await this.dataAccess.genericFind({
            model: 'Observation',
            filter: { fields: ['id', 'name', 'title'] }
        });
        states.metrics = await this.dataAccess.genericFind({
            model: 'OtherMetric',
            filter: { fields: ['id', 'name', 'shortName'] }
        });
        states.episodeGroupings = await this.dataAccess.genericFind({
            model: 'EpisodeGrouping',
            filter: { fields: ['groupByAttribute', 'filterAttribute', 'groupByName'] }
        });
        states.careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { fields: ['id', 'name', 'title'] }
        });

        const environmentUrls = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEnvironments', parameters: {
            }
        });

        states.environmentUrls = _.orderBy(_.compact(_.map(environmentUrls, (environment: any) => {
            if (environment.dnsCname && !environment.isEpisodesByClient && environment.hostingProvider === 'ecs' && (!environment.ecsParameters || (environment.ecsParameters.ecrRepoName === 'clarify-platform'))) {
                return {
                    id: environment.id,
                    environmentType: environment.environmentType,
                    url: `https://${environment.dnsCname}.clarifyhealth.${environment.domainSuffix}`
                };
            }
            return undefined;
        })), 'url');

        states.usedBy = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage', method: 'getUsageTypes'
        });
        states.usedBy.unshift({ usage_type: 'Show All', column_filter: '*' });
    }

    singleEnvironmentSelected(states: IPrismUsageStates) {
        states.selectedEnvironmentOptions = [];
    }

    async fetchResults(states: IPrismUsageStates) {
        states.queryRunning = true;

        try {

            let environmentTypes, environmentId;
            if (states.selectedEnvironmentId) {
                environmentId = states.selectedEnvironmentId;
            } else if (_.size(states.selectedEnvironmentOptions) > 0) {
                environmentTypes = _.map(states.selectedEnvironmentOptions, 'id');
            }

            const allRows = await this.dataAccess.genericMethod({
                model: 'MetaDataUsage',
                method: 'aggregateUsageResults',
                parameters: {
                    environmentTypes,
                    environmentId
                }
            });
            states.allRows = _.map(allRows, (row: any) => {

                if (row.clarify_user_last_view) {
                    row.clarifyUserLastViewFromNow = moment(row.clarify_user_last_view).fromNow();
                } else {
                    row.clarifyUserLastViewFromNow = '-';
                    // set these to sort last
                    row.clarify_user_last_view = '1900-01-01';
                }
                if (row.client_user_last_view) {
                    row.clientUserLastViewFromNow = moment(row.client_user_last_view).fromNow();
                } else {
                    row.clientUserLastViewFromNow = '-';
                    // set these to sort last
                    row.client_user_last_view = '1900-01-01';
                }

                row.clarify_total_views = _.toNumber(row.clarify_total_views);
                row.client_total_views = _.toNumber(row.client_total_views);
                row.total_usage = _.toNumber(row.total_usage);
                row.total_environments = _.toNumber(row.total_environments);
                return row;
            });
            _.forEach(states.metaDataTypes, function (metaDataType) {
                metaDataType.allRows = _.filter(states.allRows, { meta_data_type: metaDataType.type });
            });

            await this.filterChanged(states);
        } catch (error) {
            this.alertSvc.handleError(error);
            states.queryRunning = false;
        }
    }

    async syncUsage(states: IPrismUsageStates) {
        states.queryRunning = true;

        try {
            const result = await this.dataAccess.genericMethod({
                model: 'MetaDataUsage', method: 'initializeMetaDataUsageSync',
                parameters: {}
            });

            states.queryRunning = false;

        } catch (error) {
            this.alertSvc.handleError(error);
            states.queryRunning = false;
        }
    }

    async filterChanged(states: IPrismUsageStates) {
        states.queryRunning = true;
        _.forEach(states.metaDataTypes, (usageType) => {
            usageType.rows = _.filter(usageType.allRows, row => {
                return states.filterByUsageType === '*' || +row[states.filterByUsageType] > 0;
            });
        });

        states.queryRunning = false;
    }

    viewItem(usageType: any, row: any, states: IPrismUsageStates) {
        let url;
        if (usageType.linkType === 'metric') {
            const observation: any = _.find(states.observations, { name: row.name });
            if (observation) {
                url = this.state.href('argos.ccgDesigner.observations.edit', { id: observation.id });
            } else {
                const metric: any = _.find(states.metrics, { name: row.name });
                if (metric) {
                    url = this.state.href('argos.ccgDesigner.prism1Metrics.edit', { id: metric.id });
                }
            }
        } else if (usageType.linkType === 'groupBy') {
            url = this.state.href('argos.ccgDesigner.episodeGroupings.edit', { id: row.name });
        } else if (usageType.linkType === 'filter') {
            const filter: any = _.find(states.episodeGroupings, { filterAttribute: row.name });
            if (filter) {
                url = this.state.href('argos.ccgDesigner.episodeGroupings.edit', { id: filter.groupByAttribute });
            }
        } else if (usageType.linkType === 'careGrouping') {
            const careGrouping: any = _.find(states.careGroupings, { name: row.name });
            if (careGrouping) {
                url = this.state.href('argos.ccgAdmin.careGroupings.edit', { id: careGrouping.id });
            }
        }
        if (url) {
            window.open(url, '_blank');
        }
    }

    sortData(event: any, metaDataType: any, states: IPrismUsageStates) {
        if (event?.active && metaDataType?.type) {
            const findMetaDataUsage: any = _.find(states.metaDataTypes, { type: metaDataType.type });
            if (findMetaDataUsage) {
                findMetaDataUsage.rows = _.orderBy(findMetaDataUsage.rows, event.active, event.direction);
            }
        }
    }

    filterData(event: any, metaDataType: any, states: IPrismUsageStates) {
        const filterValue = event?.target?.value;
        if (metaDataType?.type) {
            const findMetaDataUsage: any = _.find(states.metaDataTypes, { type: metaDataType.type });
            if (findMetaDataUsage) {
                if (filterValue) {
                    findMetaDataUsage.rows = _.filter(findMetaDataUsage.allRows, (row) => {
                        return _.includes(_.toLower(row.meta_data_name), _.toLower(filterValue));
                    });
                } else {
                    findMetaDataUsage.rows = findMetaDataUsage.allRows;
                }
            }
        }
    }

    viewEnvironments(metaDataType: any, metaDataName: any, states: IPrismUsageStates) {
        let usageTypes: any, environmentId: any, environmentTypes: any;
        if (states.filterByUsageType !== '*') {
            let usageType: any = _.find(states.usedBy, { column_filter: states.filterByUsageType });
            if (usageType) {
                usageTypes = [usageType.usage_type];
            }
        }

        if (states.selectedEnvironmentId) {
            environmentId = states.selectedEnvironmentId;
        } else if (_.size(states.selectedEnvironmentOptions) > 0) {
            environmentTypes = _.map(states.selectedEnvironmentOptions, 'id');
        }

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    metaDataTypes: [metaDataType],
                    metaDataNames: [metaDataName],
                    usageTypes,
                    environmentTypes,
                    environmentId
                }
            }
        };
        this.matDialog.open(ViewEnvironmentUsageModalComponent, dialogConfig);
    }

    exportToExcel(usageType: any) {

        let exportString = '';
        const delimitor = ',';
        const data = usageType.rows;

        if (data && data.length > 0) {
            const columns: any = [];
            for (const prop in data[0]) {
                if (prop[0] !== '$' && !_.isFunction(data[0][prop]) && !_.isObject(data[0][prop])) {
                    columns.push(prop);
                    exportString += (prop + delimitor);
                }
            }
            exportString += '\n';

            data.forEach(function (row: any) {
                columns.forEach(function (column: any) {
                    exportString += (row[column] + delimitor);
                });
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, usageType.name + '.csv');
        }
    }

}
