import { Component, OnInit } from '@angular/core';
import { IBuildDetailStates } from './buildDetail.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { BuildDetailService } from './buildDetail.component.service';

@Component({
    selector: 'argos-buildDetail',
    templateUrl: './buildDetail.component.html',
    providers: [BuildDetailService]
})

export class BuildDetailComponent extends ReactComponentBase<{}, IBuildDetailStates, {}> implements OnInit {
    constructor(private svc: BuildDetailService) {
        super({
            stopBuildMessage: '',
            showStopBuild: false,
        } as IBuildDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
    }

    stopBuild() {
        this.svc.stopBuildHandler(this.states);
    }
}
