import { Injectable } from '@angular/core';
import { IColumnUsageStates, IColumnUsageService } from './columnUsage.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
declare const saveAs: any;

@Injectable()
export class ColumnUsageService implements IColumnUsageService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: IColumnUsageStates): Promise<Object> {
        await this.getEnvironments(states);
        await this.getCareGroupings(states);
        return {};
    }

    async getEnvironments(states: IColumnUsageStates) {
        const environments = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEnvironments', parameters: {}
        });

        states.environmentUrls = _.orderBy(_.compact(_.map(environments, (environment: any) => {
            if (environment.dnsCname && !environment.isEpisodesByClient && environment.hostingProvider === 'ecs') {
                return {
                    id: environment.id,
                    url: 'https://' + environment.dnsCname + '.clarifyhealth.' + environment.domainSuffix
                };
            }
            return undefined;
        })), 'url');
        states.environmentUrls.unshift({
            id: 'all',
            url: 'All'
        });
        states.selectedEnvironmentId = 'all';
    }

    async getCareGroupings(states: IColumnUsageStates) {
        const careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: {
                order: 'title'
            }
        });
        states.careGroupings = careGroupings;
        states.careGrouping = states.careGroupings[0].name;
    }

    async fetchResults(states: IColumnUsageStates, cb: Function) {
        states.queryRunning = true;
        const dataAccess = this.dataAccess;
        // let handleErr = function(e) { return; };

        let spotlightUsageIds;
        if (states.selectedEnvironmentId === 'all') {
            spotlightUsageIds = _.map(states.environmentUrls, 'id').slice(1);
        } else {
            spotlightUsageIds = [states.selectedEnvironmentId];
        }

        // spotlightUsageUrls = spotlightUsageUrls.slice(0, 10);
        Promise.all(spotlightUsageIds.map(function (id) {
            return dataAccess.genericMethod({
                model: 'Environment', method: 'getColumnUsage', parameters: {
                    id, careGrouping: states.careGrouping
                }
            });
        }))
            .then((results: any[]) => {
                this.handletColumnUsage(states, results);
                cb();
            })
            .catch((err) => {
                states.queryRunning = false;
                cb();
            });

    }

    handletColumnUsage(states: IColumnUsageStates, results: any[]) {
        states.columnUsage = [];
        _.forEach(results, function (result) {
            const tables = _.get(result, 'tables') || {};
            if (_.isObject(tables)) {
                for (const table in tables) {
                    const columns = _.get(result, ['tables', table]);
                    if (_.isObject(columns)) {
                        for (const column in columns) {
                            const existingColumn = _.find(states.columnUsage, { table, column });
                            if (existingColumn) {
                                existingColumn.totalViews = existingColumn.totalViews + Number(result.tables[table][column]);
                            } else {
                                states.columnUsage.push({
                                    table,
                                    column,
                                    totalViews: Number(result.tables[table][column])
                                });
                            }
                        }
                    }
                }
            }
        });

        states.columnUsage = _.orderBy(states.columnUsage, ['table', 'totalViews', 'column'], ['asc', 'desc', 'asc']);
        let rowIndex = 1;
        let previousTable: any;
        _.forEach(states.columnUsage, function (row) {
            if (row.table !== previousTable) {
                rowIndex = 1;
            }
            row.index = rowIndex;
            rowIndex++;
            previousTable = row.table;
        });

        states.dataMatTable.data = states.columnUsage;
        states.queryRunning = false;
    }

    exportToExcel(states: IColumnUsageStates) {

        let exportString = '';
        const delimitor = ',';
        const data = states.columnUsage;

        if (data && data.length > 0) {
            const columns: any[] = [];
            for (const prop in data[0]) {
                if (prop[0] !== '$' && !_.isFunction(data[0][prop]) && !_.isObject(data[0][prop])) {
                    columns.push(prop);
                    exportString += (prop + delimitor);
                }
            }
            exportString += '\n';

            data.forEach(function (row) {
                columns.forEach(function (column) {
                    exportString += (row[column] + delimitor);
                });
                exportString += '\n';
            });
            const blob = new Blob([exportString], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, 'column_usage.csv');
        }
    }

    exportForTeraCatalog(states: IColumnUsageStates) {
        const careGrouping = _.find(states.careGroupings, { name: states.careGrouping });
        const baseTable = _.get(careGrouping, 'settings.baseTable');
        const predictionsTable = _.get(careGrouping, 'settings.predictionsTable');
        this.exportTable(states, baseTable);
        this.exportTable(states, predictionsTable);
    }

    exportTable(states: IColumnUsageStates, tableName: string) {
        if (tableName) {
            const exportJson: any = {};
            exportJson.prediction_slim_table_name = tableName + '_slim';
            exportJson.include_cols = _.map(_.filter(states.columnUsage, function (row) {
                return row.table === tableName && row.index <= states.numberToInclude;
            }), 'column');
            const blob = new Blob([JSON.stringify(exportJson, undefined, 4)], { type: 'text/plain;charset=utf-8' });
            return saveAs(blob, tableName + '.json');
        }
    }

    environmentSelectedHander(data: any, states: IColumnUsageStates) {
        // states.selectedEnvironmentName = data;
        states.selectedEnvironmentId = data;  // _.get(_.find(states.environmentUrls, {id: data}), 'id');
    }
}
