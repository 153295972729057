import { ChangeDetectorRef, Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { IJoinEntitiesListStates } from './joinEntitiesList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { JoinEntitiesListService } from './joinEntitiesList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';
import { UIRouter } from '@uirouter/core';

@Component({
    selector: 'argos-joinEntitiesList',
    templateUrl: './joinEntitiesList.component.html',
    providers: [JoinEntitiesListService]
})

export class JoinEntitiesListComponent extends ReactComponentBase<{}, IJoinEntitiesListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: JoinEntitiesListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService, private uiRouter: UIRouter) {
        super({
            pageSize: 50,
            episodeGroupingEntities: [],
            matTable: new MatTableDataSource([]),
            filterObj: {},
            matTableColumns: ['edit', 'entity', 'relatedEntity', 'joinSql', 'excludeFromSyncText', 'usageCount', 'markForDeletion']
        } as unknown as IJoinEntitiesListStates);
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        const openModal = this.urlService.getModalParamFromUrl();
        this.states.matTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.urlService.updateUrlWithQueryString(queryString, openModal);
            this.states.matTable.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
            if (openModal) {
                this.addOrEditJoinEntity(this.states.filterObj);
            }
        }
        this.refresh();
    }

    ngAfterViewInit() {
        this.states.matTable.paginator = this.paginator;
        this.states.matTable.sort = this.matTbSort;
    }

    addOrEditJoinEntity(row?: any) {
        this.svc.addOrEditJoinEntityHandler(this.states, this.refresh, row);
    }

    truncateText(text: string) {
        return _.truncate(text, { length: 100 });
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.matTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.matTable.filter, false);
    }

    pageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        this.states.tableShowRows = this.states.episodeGroupingEntities.slice(page, page + event.pageSize);
        this.states.matTable = new MatTableDataSource(this.states.tableShowRows);
    }

    refresh: Function = () => {
        this.cdr.detectChanges();
    };

    markForDeletion(row: any) {
        this.svc.markForDeletion(row);
    }

    undelete(row: any) {
        this.svc.undelete(row);
    }
}
