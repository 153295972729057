import { Component } from '@angular/core';

@Component({
    selector: 'argos-automated-gold-pulldown',
    template: `<ui-view></ui-view>`
})

export class AutomatedGoldPulldownComponent {

}
