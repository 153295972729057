import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IUserListStates, IUserListService } from './userList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import * as _ from 'lodash';
import { ViewVersionHistoryModalComponent } from '../../viewVersionHistory/viewVersionHistoryModal/viewVersionHistoryModal.component';
import { ArgosStoreService } from '../../../services/argosStore.service';

@Injectable()
export class UserListService implements IUserListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {
        //
    }

    async initDelegate(states: IUserListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IUserListStates) {
        let promises: any[] = [];

        promises.push(this.dataAccess.genericArrayMethod({
            model: 'ArgosUser', method: 'getUsers', parameters: {
            }
        }));

        promises.push(this.dataAccess.genericMethod({
            model: 'ArgosUserRole', method: 'find', parameters: {
                filter: {
                    order: 'roleName' 
                }
            }
        }));

        promises.push(this.dataAccess.genericMethod({
            model: 'ArgosUser', method: 'getPostgresUsers', parameters: {}
        }));

        promises.push(this.dataAccess.genericMethod({
            model: 'ArgosUser', method: 'getPostgresTables', parameters: {}
        }));

        promises.push(this.dataAccess.genericFind({
            model: 'ArgosUserActivity',
            filter: { 
                where: { argosUserActivityType: 'createdblogin' },
                order: 'id desc'
            }
        }));

        const [users, userRoles, dbConnUsers, allowedDbTables, dbLoginCreateActivity] = await Promise.all(promises);
        states.users = users;
        states.userRoles = userRoles;
        states.dbLoginUsers = dbConnUsers;
        states.allowedDbTables = allowedDbTables;

        _.forEach(states.users, (u:any) => {
            u.ecsAdmin = false;
            u.ecsAccess = false;
            
            this.loadDisplayOnlyProperties(u);
            u.editMode = false;
            let expectedDbUsername = u.email.split('@')[0].replace('-', '_').replace('.', '_').toLowerCase();
            u.argosUserRoleName = _.find(states.userRoles, { id: u.argosUserRoleId })?.roleName;
            u.allowedDbTables = states.allowedDbTables;
            u.hasDbLogin = false;
            let dbLoginObj = _.find(states.dbLoginUsers, { usename: expectedDbUsername });
            let dbLoginCreatedEvent = _.find(dbLoginCreateActivity, function(dbUserEvent) { return dbUserEvent.argosUserId === u.id; });

            if (dbLoginObj) {
                u.hasDbLogin = true;
                u.dbServerLoginName = dbLoginObj.usename;
            }

            if (dbLoginCreatedEvent) {
                u.hasDbLogin = true;
                u.currentDbLoginEvent = `${JSON.stringify(dbLoginCreatedEvent)}`;
            }
        });

        states.usersTable.data = states.users;
        states.originalUserData = _.cloneDeep(states.users);
    }

    //pull properties from the roles object to the user object for easier display
    loadDisplayOnlyProperties(userObj: any) {
        _.forOwn(userObj.roles, (value, key) => {
            if (['ecsAdmin', 'ecsAccess'].includes(key) && value) {
                userObj[key] = value;
            }
        })
    }

    async saveRow(row: any, states: IUserListStates) {
        const result: any = await this.dataAccess.genericUpsert({
            model: 'ArgosUser',
            data: row
        });

        row.argosUserRoleName = _.find(states.userRoles, { id: row.argosUserRoleId })?.roleName;
        states.editingMode = false;
        row.editMode = false;

        await this.saveHistory(row, states);
        this.loadDisplayOnlyProperties(row);
    }

    // cancelEdit(row) {
    //     states.editingMode = false;
    //     row.editMode = false;
    // }

    openMatTableSettings(states: IUserListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.environmentMatTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    viewHistory(row: any, states: IUserListStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    versionHistory: row.argosUserHistories
                }
            }
        };
        this.matDialog.open(ViewVersionHistoryModalComponent, dialogConfig);

    }

    async saveHistory(row: any, states: IUserListStates) {

        let index = states.originalUserData.findIndex((x: any) => x.id === row.id);

        //Only save a new version if something actually changed.
        if (_.isEqual(states.originalUserData[index], row)) {
            return Promise.resolve();
        }

        //set the next version
        let version = 0;
        const username = this.argosStore.getItem('username');
        if (row && row.argosUserHistories && row.argosUserHistories.length > 0) {
            version = _.max(_.map(row.argosUserHistories, 'version')) || 0;
        }
        version++;

        let originalArgosUserHistories = _.cloneDeep(row.argosUserHistories);
        delete row.argosUserHistories;    //dont save the history in the history table
        delete row.allowedDbTables;    
        //dont save display only properties
        delete row.ecsAdmin;
        delete row.ecsAccess;

        const argosUserHistory = await this.dataAccess.genericUpsert({
            model: 'ArgosUserHistory',
            data: {
                argosUserId: row.id,
                version: version,
                createdBy: username,
                creationDate: new Date(),
                definition: row
            }
        });

        originalArgosUserHistories.push(argosUserHistory);
        row.argosUserHistories = originalArgosUserHistories;

        return argosUserHistory;
    }

}