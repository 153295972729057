import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IDataAiChatStates, IDataAiChatService } from './dataAiChat.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';

import swal from 'sweetalert2';
import { StateService } from '@uirouter/core';

@Injectable()
export class DataAiChatService implements IDataAiChatService {
    constructor(private dataAccess: NgDataAccess, private state: StateService, private cdr: ChangeDetectorRef,
        private argosStore: ArgosStoreService) {
    }

    async initDelegate(states: IDataAiChatStates): Promise<object> {
        states.question = undefined;

        swal({
            title: 'Schema Type to Query',
            text: 'Pick the schema type you want to query.',
            type: 'question',
            confirmButtonColor: '#57C84D', 
            confirmButtonText: 'Ask Question',
            input: 'select',
            inputOptions: {
                abstract_claims: 'Abstract Claims',
                contracts: 'Contracts',
                payer_rates: 'Payer Rates'
            }
        }).then(async (isConfirm: any) => {      
            if (isConfirm.value) {
                states.clarifySchema = isConfirm.value;
                this.cdr.detectChanges();
            }
        });

        return {};
    }

    async sendQuestion(states: IDataAiChatStates, question: string) {
        states.messages.unshift({
            message: question,
            userMessage: true,
            includeCopy: false,
            sql: undefined
        });

        this.cdr.detectChanges();

        const params: any = {
            user_question: question,
            clarify_schema: states.clarifySchema,
            username: this.argosStore.getItem('username'),
        };
        if (states.sessionId) {
            params['session_id'] = states.sessionId;
        }

        const response = await this.dataAccess.genericMethod({
            model: 'ClarifyBot',
            method: 'post',
            parameters: {
                endpoint: 'ai_utils/text_to_sql/query',
                body: params
            }
        });
        states.sessionId = response.session_id;
        let message = response.llm_response;
        let includeCopy = false;
        let sql;
        if (message.indexOf('---') > -1) {
            sql = message.split('--------- SQL ---------')[1].split('-----------------------')[0];

            message = message.split('\n').filter((line: string) => {
                return line.indexOf('---') === -1;
            }).join('\n');
            includeCopy = true;
        }

        states.messages.unshift({
            message,
            userMessage: false,
            includeCopy,
            sql
        });

        states.question = undefined;

        this.cdr.detectChanges();
    }
}