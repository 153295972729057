import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ICcgFeatureEditStates } from './ccgFeatureEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CcgFeatureEditService } from './ccgFeatureEdit.component.service';

@Component({
    selector: 'argos-ccgFeatureEdit',
    templateUrl: './ccgFeatureEdit.component.html',
    providers: [CcgFeatureEditService]
})

export class CcgFeatureEditComponent extends ReactComponentBase<{}, ICcgFeatureEditStates, {}> implements OnInit {
    constructor(private svc: CcgFeatureEditService, private cdr: ChangeDetectorRef) {
        super({
            programEpisodesToDelete: [],
            dataSourcesToDelete: [],
            saveInProgress: false,
            ccgFeature: {},
            programEpisodeCcgFeature: []
        } as unknown as ICcgFeatureEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    addNewProgramEpisode() {
        this.states.ccgFeature.programEpisodeCcgFeature.push({
            ccgFeatureId: this.states.ccgFeature.id
        });
    }

    addNewDataSource(keyFieldId?: any) {
        this.states.ccgFeature.ccgFeatureDataSourceLimit.push({
            dataSourceId: keyFieldId,
            ccgFeatureId: this.states.ccgFeature.id
        });
    }

    deleteNewProgramEpisode(keyFieldId: any, index: number) {
        if (keyFieldId) {
            this.states.programEpisodesToDelete.push(keyFieldId);
        }
        this.states.ccgFeature.programEpisodeCcgFeature.splice(index, 1);
    }

    deleteNewDataSource(keyFieldId: any, index: number) {
        if (keyFieldId) {
            this.states.dataSourcesToDelete.push(keyFieldId);
        }
        this.states.ccgFeature.ccgFeatureDataSourceLimit.splice(index, 1);
    }

    save() {
        this.svc.save(this.states);
    }
}
