import { Injectable } from '@angular/core';
import { IQATestResultProductDetailStates, IQATestResultProductDetailService } from './qaTestResultDashboardProductsDetail.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';
import { MatTableDataSource } from '@angular/material/table';
import { PlatformReleaseResultsService } from '../../../services/platformReleaseResultsService.service';


@Injectable()
export class QATestResultDetailService implements IQATestResultProductDetailService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService, private platformReleaseResultsService: PlatformReleaseResultsService) {
        //
    }

    async initDelegate(states: IQATestResultProductDetailStates): Promise<object> {
        await this.fetchResults(states);
        return {};
    }

    async fetchResults(states: IQATestResultProductDetailStates) {
        const dateFilter = this.platformReleaseResultsService.getSelectedDate();
        this.platformReleaseResultsService.setproductId(this.uiRouter.globals.params.id);
        const requestPayload: any = {
            product_name: this.uiRouter.globals.params.id
        };
        if (dateFilter) {
            requestPayload.release_date = dateFilter;
        }
        const pctResults = await this.dataAccess.genericFind({
            model: 'ProductionConfigurationTestResults',
            filter: {
                where: requestPayload
            }
        });
        states.pctSelectedReleaseDate = dateFilter;
        states.pctProductId = this.uiRouter.globals.params.id;
        states.pctSubmoduleDetails = _.uniqBy(pctResults, 'submodule_name');
        states.pctDataTable.data = _.orderBy(states.pctSubmoduleDetails, ['submodule_name']);
    }

    async calculatepctResultsPercentageBySubmoduleName(submoduleName: string, states: IQATestResultProductDetailStates) {
        const dateFilter = this.platformReleaseResultsService.getSelectedDate();
        const requestPayload: any = {
            product_name: states.pctProductId,
            submodule_name: submoduleName
        };
        if (dateFilter) {
            requestPayload.release_date = dateFilter;
        }
        const pctResults = await this.dataAccess.genericFind({
            model: 'ProductionConfigurationTestResults',
            filter: {
                where: requestPayload
            }
        });

        states.pctSubmoduleId = submoduleName;
        const groupedByCustomerName = _.groupBy(pctResults, 'name');
        const mapped_array = _.map(groupedByCustomerName, customer => {
            const customerName = _.uniqBy(customer, 'name');
            const url = _.uniqBy(customer, 'url');
            const totalTests = customer.length;
            const passedTests = _.filter(customer, { status: 'PASS' }).length;
            const passedPCTPercentage = ((passedTests / totalTests) * 100).toFixed(1) + '%';
            const failedPCTPercentage = (((totalTests - passedTests) / totalTests) * 100).toFixed(1) + '%';
            const dataToDisplay = {
                customerName: customerName[0].name,
                url: url[0].url,
                passedPCTPercentage,
                failedPCTPercentage
            };
            states.pctSubTable = new MatTableDataSource([dataToDisplay]);
            states.pctSubmoduleCollapse = submoduleName;
            return dataToDisplay;
        });
        states.pctSubTable = _.orderBy(mapped_array, ['failedPCTPercentage'], ['desc']);
    }
}
