import { Component, OnChanges, SimpleChanges, OnInit, OnDestroy, ViewChild, ViewChildren, ElementRef, QueryList, ChangeDetectorRef } from '@angular/core';
import { IClarifySwimlaneProps, IClarifySwimlaneStates, IClarifySwimlaneOutput, NodeLineItem } from './clarifySwimlane.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { ClarifySwimlaneService } from './clarifySwimlane.component.service';
import {AdditionalInfoModalComponent} from '../dataDashboard/additionalInfoModal/additionalInfoModal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'clarify-swimlane',
    templateUrl: './clarifySwimlane.component.html',
    styleUrls: ['./clarifySwimlane.component.less'],
    providers: [ClarifySwimlaneService]
})

export class ClarifySwimlaneComponent extends ReactComponentBase<IClarifySwimlaneProps, IClarifySwimlaneStates, IClarifySwimlaneOutput> implements OnInit, OnChanges, OnDestroy {

    @ViewChild('swimlaneAreaContainer') swimlaneAreaContainer: ElementRef<any>;
    @ViewChild('swimlaneLineIframe') swimlaneLineIframe: ElementRef<any>;
    @ViewChildren('swimlaneNode') swimlaneNodes: QueryList<ElementRef>;

    nodeLines: NodeLineItem[] = [];

    constructor(private svc: ClarifySwimlaneService, private cdr: ChangeDetectorRef,  private matDialog: MatDialog) {
        super({
            nodeHeight: 20,
            nodePadding: 0,
            nodeMargin: 5,
            clusterPadding: 8,
            clusterMargin: 2,
            clusterLabelSize: 18,
            swimlaneLineSetting: {
                color: 'black', size: 1, path: 'magnet', startSocket: 'right', endSocket: 'left', startSocketGravity: 10, endSocketGravity: 20, dash: 'true',  endPlugSize: 1.5
            }
        } as IClarifySwimlaneStates);
    }

    ngOnInit() {
        this.setStates(this.svc.initDelegate(this.props, this.states));
        this.cdr.detectChanges();
        this.drawNodeLines();

        window.addEventListener('resize', this.resetSwimlaneLinesPosition);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setStates(this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states));
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.resetSwimlaneLinesPosition);
    }

    drawNodeLines() {
        let lineEles = undefined, lineDefs = undefined, swimlaneAreaContainerRect;
        this.nodeLines = this.svc.drawNodeLines(this.swimlaneNodes, this.props?.edges, this.states.swimlaneLineSetting);
        if (this.swimlaneAreaContainer?.nativeElement) {
            swimlaneAreaContainerRect = this.swimlaneAreaContainer.nativeElement.getBoundingClientRect();
            this.setStates({
                swimlaneLineIframeTop: -(swimlaneAreaContainerRect.top || 115),
                swimlaneLineIframeLeft: -(swimlaneAreaContainerRect.left || 305)
            });
        }

        lineDefs = document.querySelector('#leader-line-defs');
        lineEles = document.querySelectorAll('.leader-line');

        this.swimlaneLineIframe.nativeElement.appendChild(lineDefs);
        lineEles.forEach(line => {
            this.swimlaneLineIframe.nativeElement.appendChild(line);
        });
    }

    resetSwimlaneLinesPosition = () => {
        let swimlaneAreaContainerRect;

        this.swimlaneAreaContainer.nativeElement.scrollTop = 0;
        this.swimlaneAreaContainer.nativeElement.scrollLeft = 0;
        this.cdr.detectChanges();

        if (this.swimlaneAreaContainer?.nativeElement) {
            swimlaneAreaContainerRect = this.swimlaneAreaContainer.nativeElement.getBoundingClientRect();
            this.setStates({
                swimlaneLineIframeTop: -(swimlaneAreaContainerRect.top || 115),
                swimlaneLineIframeLeft: -(swimlaneAreaContainerRect.left || 305)
            });
        }
        this.cdr.detectChanges();

        _.forEach(this.nodeLines, item => {
            item.position();
        });
    };

    openAdditionalInfo(additionalInfo: any): void {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: additionalInfo
        };
        this.matDialog.open(AdditionalInfoModalComponent, dialogConfig);
      }
}
