import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IClientListStates } from './clientList.component.d';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';
import { ClientListService } from './clientList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-clientList',
    templateUrl: './clientList.component.html',
    providers: [ClientListService]
})

export class ClientListComponent extends ReactComponentBase<{}, IClientListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;
    constructor(private svc: ClientListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            showType: 'showAll',
            clients: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['name', 'internal_name', 'environmentsUrl', 'activeEnvironmentCount', 'assignedOwnerEmailString'],
            matTableColumns: ['name', 'internal_name', 'environmentsUrl', 'activeEnvironmentCount', 'assignedOwnerEmailString'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'clientList',
        } as unknown as IClientListStates);
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    pageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        this.states.tableShowRows = this.states.clients.slice(page, page + event.pageSize);
        this.states.table = new MatTableDataSource(this.states.tableShowRows);
    }

    ngAfterViewInit(): void {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    //pagination functions
    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }
}
