import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IPipelineHistoryStates } from './pipelineHistory.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PipelineHistoryService } from './pipelineHistory.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MaterialUtil } from 'client/app/services/materialUtil.service';
import { MatPaginator } from '@angular/material/paginator';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-pipelineHistory',
    templateUrl: './pipelineHistory.component.html',
    providers: [PipelineHistoryService]
})

export class PipelineHistoryComponent extends ReactComponentBase<{}, IPipelineHistoryStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;

    constructor(private svc: PipelineHistoryService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            currentTimeFrameSelection: 7,
            currentPipelineTypeSelection: 'prod',
            pageSize: 50,
            filterObj: {},
            matTableColumns: ['dataSource', 'stage', 'pipelineCount', 'averageRunTime', 'averageSuccessRate', 'averagePipelineCost'],
            dataSources: [],
            filteredPipelinesMatTable: new MatTableDataSource([]),
            queryRunning: true,
            distinctPipelines: [],
            filteredPipelines: [],
            distinctPipelinesString: {},
            allPipelines: []
        } as unknown as IPipelineHistoryStates);
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.filteredPipelinesMatTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.filteredPipelinesMatTable.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    refreshPage() {
        this.states.queryRunning = true;
        this.cdr.detectChanges();
        this.svc.refreshPage(this.states);
        this.ngOnInit();
    }

    ngAfterViewInit() {
        this.states.filteredPipelinesMatTable.paginator = this.paginator;
        this.states.filteredPipelinesMatTable.sort = this.matTbSort;
    }

    displayAverageRunTime(rawRunTime: number) {
        return Math.floor(rawRunTime) + ' hr ' + Math.round((rawRunTime - Math.floor(rawRunTime)) * 60.0) + ' min';
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.filteredPipelinesMatTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.filteredPipelinesMatTable.filter);
    }

    viewChart() {
        this.svc.viewChartHandler(this.states);
    }
}
