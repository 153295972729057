import { Injectable, ChangeDetectorRef } from '@angular/core';
import { INetworksStatusStates, INetworksStatusService } from './networksStatus.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { StatusDetailModalComponent } from '../statusDetailModal/statusDetailModal.component';
import { ReImportEnvironmentListModalComponent } from '../reImportEnvironmentListModal/reImportEnvironmentListModal.component';
import { UtilsService } from '../../../services/utils.service';
@Injectable()
export class NetworksStatusService implements INetworksStatusService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog, private cdr: ChangeDetectorRef, private utils: UtilsService) {
        //
    }
    async initDelegate(states: INetworksStatusStates): Promise<object> {
        await this.getNetworkEnvironmentsStatus(states, true);
        return {};
    }

    async getNetworkEnvironmentsStatus(states: INetworksStatusStates, fetchClusters?: any) {
        states.environments = await this.dataAccess.genericMethod({
            model: 'NetworksStatus', method: 'getNetworkEnvironmentsStatus', parameters: {}
        });

        let notLoadedString = "";
        let notLoadedEnvs = [];

        for (let i = 0; i < states.environments.length; i++) {
            if (!("networksStatistics" in states.environments[i])) {
                notLoadedString = notLoadedString + states.environments[i].hosting_app_name + " ";
                notLoadedEnvs.push(states.environments[i]);
                states.environments[i].hasError = true;     
            }
        }

        if (notLoadedString.length > 0) {
            swal({
                title: 'Error',
                text: "The following environments " + notLoadedString + "are not loaded",
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            });
        }

        this.cdr.detectChanges();
        if (fetchClusters) {
            this.getNetworkEnvironmentEcsInfo(states);
        } else {
            this.mergeEnvironmentsAndClusters(states);
            this.cdr.detectChanges();
        }

    }

    async getNetworkEnvironmentEcsInfo(states: INetworksStatusStates) {
        _.forEach(states.environments, function (e) {
            delete e.ecsInfo;
            e.isLoading = true;
        });
        states.clusters = await this.dataAccess.genericMethod({
            model: 'NetworksStatus',
            method: 'getNetworkEnvironmentEcsInfo',
            parameters: { environments: states.environments }
        });
        this.mergeEnvironmentsAndClusters(states);
        this.cdr.detectChanges();
        // this.isSchedulerBulk(states);
    }

    async getEcsSchedulerStatus(environment: any, states: INetworksStatusStates) {
        const clusterName = environment.ecsInfo.clusterName;
        const accountName = environment.ecsInfo.accountName;

        const responseEcsServiceStats = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsServiceInfo', parameters: {
                clusterName,
                serviceNames: [clusterName + '-service', clusterName + '-worker-service'],
                awsAccountName: accountName
            }
        });

        const responseWeb = _.find(responseEcsServiceStats.scalableTargets, function (st) {
            if (st.ResourceId.includes(clusterName + '-service')) {
                return st;
            }
        });
        const responseWorker = _.find(responseEcsServiceStats.scalableTargets, function (st) {
            if (st.ResourceId.includes(clusterName + '-worker-service')) {
                return st;
            }
        });

        // error for non-returned schedule for either web or worker
        if (!responseWeb || !responseWorker) {
            environment.isScheduled = '!';
        }
        // if both web and worker schedules are not set
        else if (responseWeb.SuspendedState.ScheduledScalingSuspended === true && responseWorker.SuspendedState.ScheduledScalingSuspended === true) {
            environment.isScheduled = '*';
        }
        // else both web and worker schedules are set
        else {
            environment.isScheduled = '';
        }
        this.cdr.detectChanges();
    }

    async isSchedulerBulk(states: INetworksStatusStates) {

        for (let i = 0; i < states.environments.length; i++) {

            this.getEcsSchedulerStatus(states.environments[i], states);

            if (i % 3 === 0) {
                const x = await (new Promise((resolve) => {
                    const waitSeconds = this.utils.getRandomInt(500, 3500);  // jitter wait between .5 and 3.5 seconds
                    setTimeout(resolve, waitSeconds);
                }));
                const y = 1;
            }

        }
        this.cdr.detectChanges();
    }

    mergeEnvironmentsAndClusters(states: INetworksStatusStates) {
        _.forEach(states.environments, function (e) {
            const ecsInfo = _.find(states.clusters, { clusterName: e.hosting_app_name });
            if (ecsInfo) {
                e.ecsInfo = ecsInfo;
            }
            e.isLoading = false;
        });
    }

    async reImportEnvironmentCheckerHandler(doNothingUpgrade: boolean, states: INetworksStatusStates) {
        const hasReimportOptionEnvList = _.filter(states.environments, function (e) { return _.get(e.networksStatistics, 'has_reimport_option'); });
        if (_.size(hasReimportOptionEnvList)) {
            const dialogConfig: MatDialogConfig = {
                panelClass: 'argos-modal-panel',
                autoFocus: false,
                hasBackdrop: false,
                data: {
                    props: {
                        environment: hasReimportOptionEnvList
                    }
                }
            };
            const modalInstance: MatDialogRef<any> = this.matDialog.open(
                ReImportEnvironmentListModalComponent, dialogConfig);
            modalInstance.afterClosed().subscribe(async (result: any) => {
                if (result) {
                    _.forEach(states.environments, function (e) {
                        if (_.includes(result, _.get(e, 'hosting_app_name'))) {
                            _.set(e, 'reImportNetwork', true);
                        }
                    });
                    // const maxWorkers = (_.isEmpty(states.workers) || _.isNaN(_.toNumber(states.maxWorkers)) || _.toNumber(states.maxWorkers) === 0) ? 150 : _.toNumber(states.maxWorkers);
                    const maxWorkers = (_.isNaN(_.toNumber(states.maxWorkers)) || _.toNumber(states.maxWorkers) === 0) ? 150 : _.toNumber(states.maxWorkers);
                    await this.dataAccess.genericMethod({
                        model: 'NetworksStatus',
                        method: 'upgradeAllNPOEnvironments',
                        parameters: {
                            environments: states.environments,
                            maxWorkers,
                            doNothingUpgrade
                        }
                    });
                }
            });
        } else {
            await this.upgradeAllNPOEnvironments(states);
        }
    }

    upgradeNPOEnvironment(environment: any, reImportNetwork: boolean, usePresto: boolean, doNothingUpgrade: boolean) {
        _.set(environment, 'reImportNetwork', reImportNetwork);
        _.set(environment, 'usePresto', usePresto);
        swal({
            title: 'Confirm ' + (reImportNetwork ? 'Upgrade with Reimport - ' : ('Upgrade - ' + (usePresto ? ' (Using Presto) ' : ''))) + environment.organization_name + ' (' + environment.dns_cname + ') Environment',
            text: 'You are requesting to ' + (reImportNetwork ? 'reimport' : 'upgrade') + ' network in ' + environment.organization_name + ' (' + environment.dns_cname + ') environment. Are you sure you want to do this?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const result = await this.dataAccess.genericMethod({
                    model: 'NetworksStatus',
                    method: 'upgradeNPOEnvironment',
                    parameters: { environment, doNothingUpgrade }
                });
                if (result.isValid) {
                    console.log('Upgrade Started');
                } else {
                    console.log('Upgrade Skipped - ' + result.invalidReason);
                }
            }
        });
    }

    getEnvironments(states: INetworksStatusStates) {
        if (states.showEnvironmentsWithZeroNetworks) {
            return states.environments;
        } else {
            return _.filter(states.environments, function (e) {
                const networkCount = _.get(e, 'networksStatistics.networks');
                return _.isNil(networkCount) || networkCount > 0;
            });
        }
    }

    showStatusDetails(environment: any, states: INetworksStatusStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    environment
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            StatusDetailModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            if (result) {
                //
            }
        });
    }

    resizeMachine(environment: any, resizeType: string, states: INetworksStatusStates) {
        swal({
            title: 'Confirm ' + _.capitalize(resizeType) + 'size ' + environment.organization_name + ' (' + environment.dns_cname + ') Machine',
            text: 'You are requesting to ' + resizeType + 'size ' + environment.organization_name + ' (' + environment.dns_cname + ') environment.  Are you sure you want to do this?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                if (resizeType === 'up') {
                    await this.updateScheduledScalingSuspendedState(environment, true, states);
                } else {
                    await this.updateScheduledScalingSuspendedState(environment, false, states);
                }
                await this.dataAccess.genericMethod({
                    model: 'NetworksStatus',
                    method: 'resizeMachine',
                    parameters: {
                        environment,
                        resizeType,
                        requestedBy: this.argosStore.getItem('username')
                    }
                });
            }
        });
    }

    resizeAllMachinesHandler(resizeType: string, states: INetworksStatusStates) {
        swal({
            title: 'Confirm ' + _.capitalize(resizeType) + 'size All NPO Machines',
            text: 'You are requesting to ' + resizeType + 'size all valid NPO environments.  Are you sure you want to do this?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                let disableScheduler = (resizeType === 'up');
                await this.updateAllScheduledScalingSuspendedState(disableScheduler, states);
                await this.dataAccess.genericMethod({
                    model: 'NetworksStatus',
                    method: 'resizeAllMachines',
                    parameters: {
                        environments: states.environments,
                        resizeType,
                        requestedBy: this.argosStore.getItem('username')
                    }
                });
            }
        });
    }

    async updateAllScheduledScalingSuspendedState(disable: boolean, states: INetworksStatusStates) {

        const bulkUpdateList = this.getEnvironments(states);

        // hca-east exception
        const pos = bulkUpdateList.map((e: any) => e.dns_cname).indexOf('hca-east');
        const hca = states.environments[pos];
        bulkUpdateList.splice(pos, 1);

        for (let i = 0; i < bulkUpdateList.length; i++) {
            const environmentTarget = bulkUpdateList[i];
            environmentTarget.clusterName = environmentTarget.ecsInfo.clusterName;
            environmentTarget.accountName = environmentTarget.ecsInfo.accountName;
            environmentTarget.url = environmentTarget.dns_cname + '.' + 'clarifyhealth.' + environmentTarget.domain_suffix;
            environmentTarget.friendlyName = environmentTarget.clusterName;
            environmentTarget.services = [environmentTarget.clusterName + '-service', environmentTarget.clusterName + '-worker-service'];
        }

        await this.upsertBulkScheduleStatus(bulkUpdateList, disable, states);
        states.environments.push(hca);
    }

    async updateScheduledScalingSuspendedState(environment: any, disable: boolean, states: INetworksStatusStates) {

        environment.clusterName = environment.ecsInfo.clusterName;
        environment.accountName = environment.ecsInfo.accountName;
        environment.url = environment.dns_cname + '.' + 'clarifyhealth.' + environment.domain_suffix;
        environment.friendlyName = environment.clusterName;
        environment.services = [environment.ecsInfo.clusterName + '-service', environment.ecsInfo.clusterName + '-worker-service'];

        await this.upsertBulkScheduleStatus([environment], disable, states);
    }

    async upsertBulkScheduleStatus(clusters: any, disable: boolean, states: INetworksStatusStates) {

        const disableSymbol = (disable === true) ? '*' : '';
        clusters = _.map(clusters, o => _.extend({ scheduledScalingUpdateStatus: 'pending' }, o));

        for (let i = 0; i < clusters.length; i++) {
            const c = clusters[i];
            c.scheduledScalingUpdateStatus = 'updating';
            // this.cdr.detectChanges();

            const response = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEcsServiceInfo', parameters: {
                    clusterName: c.clusterName,
                    serviceNames: c.services,
                    awsAccountName: c.accountName
                }
            });

            const promises = [];
            for (let j = 0; j < response.scalableTargets.length; j++) {
                const scheduledTarget = response.scalableTargets[j];

                scheduledTarget.SuspendedState.ScheduledScalingSuspended = disable;

                promises.push(this.dataAccess.genericMethod({
                    model: 'Environment', method: 'updateEcsServiceScheduleSuspendedState',
                    parameters: {
                        clusterName: c.clusterName,
                        scalableTarget: scheduledTarget,
                        requestedBy: this.argosStore.getItem('username'),
                        awsAccountName: c.accountName
                    }
                }));
            }

            const envFind = _.find(states.environments, { hosting_app_name: c.clusterName });

            if (promises.length > 0) {
                // isSuccessful
                const results = await Promise.all(promises);
                const succeeded = _.sum(_.map(results, (r) => {
                    return r.isSuccessful ? 1 : 0;
                }));
                c.scheduledScalingUpdateStatus = (promises.length === succeeded) ? 'success' : 'failed';
                envFind.isScheduled = disableSymbol;
            } else {
                c.scheduledScalingUpdateStatus = 'no scheduled scaling found';
                envFind.isScheduled = '!';
            }
            this.cdr.detectChanges();
        } // for

    }

    getCurrentEC2(environment: any, states: INetworksStatusStates) {
        if (environment && environment.ecsInfo && environment.ecsInfo.ec2Info && _.size(environment.ecsInfo.ec2Info) > 0) {
            return _.join(_.map(environment.ecsInfo.ec2Info, 'ec2InstanceType'), ', ');
        } else {
            return 'Fetching';
        }
    }

    upgradeAllNPOEnvironments(states: INetworksStatusStates) {
        const maxWorkers = (_.isEmpty(states.maxWorkers) || _.isNaN(_.toNumber(states.maxWorkers)) || _.toNumber(states.maxWorkers) === 0) ? 150 : _.toNumber(states.maxWorkers);
        swal({
            title: 'Confirm Full NPO Upgrade',
            text: 'You are requesting to upgrade all networks in all NPO environments with ' + maxWorkers + ' max workers.  Are you sure you want to do this?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                await this.dataAccess.genericMethod({
                    model: 'NetworksStatus',
                    method: 'upgradeAllNPOEnvironments',
                    parameters: {
                        environments: states.environments,
                        maxWorkers
                    }
                });
            }
        });
    }

    stopNPOUpgradeTask(environment: any, states: INetworksStatusStates) {
        swal({
            title: 'Confirm Stop Upgrade Task',
            text: 'You are requesting to stop upgrade task in ' + environment.organization_name + ' (' + environment.dns_cname + ') environment.  Are you sure you want to do this?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const result = await this.dataAccess.genericMethod({
                    model: 'NetworksStatus',
                    method: 'getRunningNPOTaskId',
                    parameters: {
                        clusterName: environment.ecsInfo?.clusterName,
                        awsAccountName: environment.ecsInfo?.accountName
                    }
                });
                if (result.isSuccessful && !_.isEmpty(result.taskId)) {
                    await this.dataAccess.genericMethod({
                        model: 'Environment',
                        method: 'stopTask',
                        parameters: {
                            clusterName: environment.ecsInfo.clusterName,
                            taskId: result.taskId,
                            awsAccountName: environment.ecsInfo.accountName
                        }
                    });
                }
            }
        });
    }

    stopUpgradeNPONetworksHandler() {
        swal({
            title: 'Confirm Stop Upgrade Networks',
            text: 'You are requesting to stop "Upgrade Networks".  This will kill the upgrade manager.  It does not stop upgade tasks already in progress. Are you sure you want to do this?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const result = await this.dataAccess.genericMethod({
                    model: 'NetworksStatus',
                    method: 'stopUpgradeNPONetworks',
                    parameters: {}
                });
                if (result.isSuccessful) {
                    console.log('Upgrade Networks Stopped');
                }
            }
        });
    }

    setMaxWorkersHandler(states: INetworksStatusStates) {
        const maxWorkers = (_.isEmpty(states.maxWorkers) || _.isNaN(_.toNumber(states.maxWorkers)) || _.toNumber(states.maxWorkers) === 0) ? 150 : _.toNumber(states.maxWorkers);
        swal({
            title: 'Confirm Set Max Workers (' + maxWorkers + ')',
            text: 'You are requesting to change "Upgrade Networks".  This will chnage the max workers in the active upgrade. Are you sure you want to do this?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const result = await this.dataAccess.genericMethod({
                    model: 'NetworksStatus',
                    method: 'setMaxWorkers',
                    parameters: { maxWorkers }
                });
                if (result.isSuccessful) {
                    console.log('Upgrade Networks Stopped');
                }
            }
        });
    }

    getESCInstanceClass(environment: any, states: INetworksStatusStates) {
        const ENVIRONMENT_MAX_BATCH_SIZE = 3.25;
        const networkCount = _.get(environment, 'networksStatistics.networks', 0);

        if (networkCount === 0) {
            return {};
        }

        let ecsInstanceForUpgrade = 'c5a.2xlarge';
        if (networkCount / 5 <= ENVIRONMENT_MAX_BATCH_SIZE) {
            ecsInstanceForUpgrade = 'c5a.2xlarge';
        } else if (networkCount / 10 <= ENVIRONMENT_MAX_BATCH_SIZE) {
            ecsInstanceForUpgrade = 'c5a.4xlarge';
        } else if (networkCount / 20 <= ENVIRONMENT_MAX_BATCH_SIZE) {
            ecsInstanceForUpgrade = 'c5a.8xlarge';
        } else if (networkCount / 30 <= ENVIRONMENT_MAX_BATCH_SIZE) {
            ecsInstanceForUpgrade = 'c5a.12xlarge';
        } else if (networkCount / 40 <= ENVIRONMENT_MAX_BATCH_SIZE) {
            ecsInstanceForUpgrade = 'c5a.16xlarge';
        } else {
            ecsInstanceForUpgrade = 'c5a.24xlarge';
        }

        let workersForUpgrade = 4;
        const workerCount = Math.ceil(networkCount / ENVIRONMENT_MAX_BATCH_SIZE / 5) * 5;
        if (workerCount <= 5) {
            workersForUpgrade = 4;
        } else if (workerCount >= 60) {
            workersForUpgrade = 60;
        } else {
            workersForUpgrade = workerCount;
        }

        const currentInstance = this.getCurrentEC2(environment, states);
        const currentWorkers = _.get(environment, 'ecsInfo.runningWorkers', 0) + _.get(environment, 'ecsInfo.pendingWorkers', 0);
        const correctWorkers = _.get(environment, 'ecsInfo.pendingWorkers', 0) === 0 &&
            _.get(environment, 'ecsInfo.runningWorkers', 0) >= 4 &&
            _.get(environment, 'ecsInfo.runningWorkers', 0) === _.get(environment, 'ecsInfo.desiredWorkers', 0);

        const isGood = ((currentInstance === 'c5a.2xlarge' || currentInstance === 'Fargate') && currentWorkers === 4 && correctWorkers) ||
            ((currentInstance === ecsInstanceForUpgrade || currentInstance === 'Fargate') && currentWorkers === workersForUpgrade && correctWorkers);

        if (isGood) {
            return { color: 'green' };
        } else {
            return { color: 'red' };
        }
    }

    getWorkerClass(environment: any, states: INetworksStatusStates) {
        if (_.get(environment, 'networksStatistics.networks', 0) === 0) {
            return {};
        }

        const isGood = environment.ecsInfo &&
            _.get(environment, 'ecsInfo.pendingWorkers', 0) === 0 &&
            _.get(environment, 'ecsInfo.runningWorkers', 0) >= 4 &&
            _.get(environment, 'ecsInfo.runningWorkers', 0) === _.get(environment, 'ecsInfo.desiredWorkers', 0);

        if (isGood) {
            return { color: 'green' };
        } else {
            return { color: 'red' };
        }
    }

    getRowClass(environment: any) {

        const networkCount = _.get(environment, 'networksStatistics.networks', 0);

        if (networkCount === 0) {
            return { color: 'darkgray' };
        }

        const isRunning = (
            _.get(environment, 'networksStatistics.upgrade_failed', 0) +
            _.get(environment, 'networksStatistics.failed', 0) +
            _.get(environment, 'networksStatistics.unknown_failed', 0) +
            _.get(environment, 'networksStatistics.complete', 0)
        ) < networkCount;

        if (isRunning) {
            return { 'font-weight': 'bold' };
        }

        return {};
    }

    isEcsInfoLoading(states: INetworksStatusStates) {
        const result = _.filter(states.environments, function (e) {
            if (e.isLoading === true) {
                return e;
            }
        });

        return result.length > 0;
    }

}
