import { Injectable } from '@angular/core';
import { IEditProgramObservationModalProps, IEditProgramObservationModalStates, IEditProgramObservationModalService } from './editProgramObservationModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class EditProgramObservationModalService implements IEditProgramObservationModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(props: IEditProgramObservationModalProps, states: IEditProgramObservationModalStates): Promise<object> {
        states.observation = props.observation;
        await this.init(states);
        return {};
    }

    async changeDelegate(oldProps: IEditProgramObservationModalProps, newProps: IEditProgramObservationModalProps, states: IEditProgramObservationModalStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async init(states: IEditProgramObservationModalStates) {

        if (!states.observation.programEpisodeObservations) {
            states.observation.programEpisodeObservations = [];
        }

        const programEpisodes = await this.dataAccess.genericFind({
            model: 'ProgramEpisode',
            filter: {
                include: {
                    relation: 'programModel', // include the owner object
                    scope: { // further filter the owner object
                        include: { // include orders for the owner
                            relation: 'ccgType'
                        }
                    }
                }
            }
        });
        const programModelCompatibleObservationGroups = await this.dataAccess.genericFind({
            model: 'ProgramModelCompatibleObservationGroup'
        });
        const ccgTypes = await this.dataAccess.genericFind({
            model: 'CcgType'
        });
        states.ccgTypes = ccgTypes;
        states.programEpisodes = _.orderBy(_.map(_.filter(programEpisodes, (pe) => {
            // Filter out program models that are not compatible with this observation group
            return (_.filter(programModelCompatibleObservationGroups, {
                programModelId: pe.programModel.id,
                observationGroupId: states.observation?.observationGroupId
            }).length > 0);
        }), function (pe) {
            pe.programModelName = _.get(pe, 'programModel.name');
            return pe;
        }), ['programModelName', 'name'], 'asc');
        states.programModels = _.uniq(_.map(states.programEpisodes, 'programModelName'));

        states.programEpisodes.forEach((programEpisode: any) => {
            const exists = _.find(_.get(states.observation, 'programEpisodeObservations'), { programEpisodeId: programEpisode.id });
            if (exists) {
                programEpisode.selected = true;
            }
        });
    }


    selectionChanged(row: any, states: IEditProgramObservationModalStates) {
        if (row.selected) {
            const exists = _.find(states.observation?.programEpisodeObservations, { programEpisodeId: row.id });
            if (!exists) {
                states.observation?.programEpisodeObservations.push({
                    programEpisodeId: row.id,
                    observationId: states.observation?.id
                });
            }
        } else {
            const index = _.findIndex(states.observation?.programEpisodeObservations, { programEpisodeId: row.id });
            if (index >= 0) {
                if (states.observation?.programEpisodeObservations[index].id) {
                    states.programEpisodeObservationsToDelete.push(states.observation?.programEpisodeObservations[index].id);
                }
                states.observation?.programEpisodeObservations.splice(index, 1);
            }
        }
    }

    unselectAll(states: IEditProgramObservationModalStates) {
        states.programEpisodes.forEach((pe: any) => {
            if (pe.selected) {
                pe.selected = false;
                this.selectionChanged(pe, states);
            }
        });
    }

    selectAll(programModelName: any, states: IEditProgramObservationModalStates) {
        states.programEpisodes.forEach((pe: any) => {
            if (pe.programModelName === programModelName) {
                pe.selected = true;
                this.selectionChanged(pe, states);
            }
        });
    }

    selectAllCcgType(typeId: any, states: IEditProgramObservationModalStates) {
        states.programEpisodes.forEach((pe: any) => {
            // exclude the program ids of test type
            if (pe.programModel.ccgType.id === typeId && (pe.id !== 1403 && pe.id !== 1404 && pe.id !== 1405)) {
                pe.selected = true;
                this.selectionChanged(pe, states);
            }
        });
    }
}
