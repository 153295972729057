import { Injectable } from '@angular/core';
import { ArgosStoreService } from './argosStore.service';
import * as _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';
declare const saveAs: any;

@Injectable()
export class MaterialUtil {
    constructor(private storeSVc: ArgosStoreService) {
        //
    }

    multiFilterChange(filterObj: any, columnName: string, event: MatSelectChange) {
        let filterArray = event.value;
        console.log(filterArray.toString());
        if (filterArray.indexOf(undefined) !== -1 || filterArray.length === 0) {
            event.source.options.forEach((item: any) => {
                item.deselect();
            });
            filterArray = [];
        } 

        let filterValue = (filterArray.length === 0) ? '' :_.uniq(filterArray).toString();
        filterObj[columnName] = filterValue.trim().toLowerCase();
        filterObj = _.omitBy(filterObj, value => _.isEmpty(value));

        return filterObj;
    }

    filterChange(filterObj: any, columnName: string, filterValue: string) {
        filterObj[columnName] = filterValue.trim().toLowerCase();
        filterObj = _.omitBy(filterObj, value => _.isEmpty(value));

        return filterObj;
    }

    // Custom filter method fot Angular Material Datatable
    matTableColumnAndFilterPredicate() {
        const filterFunction = function (data: any, filter: string): boolean {
            const searchTerms = JSON.parse(filter);
            const matchExtactColumnFilterValue = searchTerms.matchExtactColumnFilterValue || [];
            const multiColumnFilterValue = searchTerms.multiColumnFilterValue || [];            
            const multiColumnFilterORLogicValue = searchTerms.multiColumnFilterORLogicValue || [];            
            delete searchTerms.matchExtactColumnFilterValue;
            delete searchTerms.multiColumnFilterValue;
            delete searchTerms.multiColumnFilterORLogicValue;

            let isFilterSet = false;
            for (const col in searchTerms) {
                if (searchTerms[col].toString() !== '') {
                    isFilterSet = true;
                } else {
                    delete searchTerms[col];
                }
            }

            const nameSearch = () => {
                let found = false;
                if (isFilterSet) {
                    for (const col in searchTerms) {
                        found = false;  //reset found flag
                        const filterStr: string = searchTerms[col].trim().toLowerCase();
                        if (!(col in data)) {
                            // exclude missing property, empty or null values from filtered values
                            found = false;
                        } else {
                            let colValue = data[col];
                            if (Number.isFinite(colValue) || typeof colValue === 'boolean') {
                                colValue = colValue.toString(); // convert to string for string search
                            }

                            if (!colValue || colValue.length === 0) {
                                found = false;  // exclude empty or null values from filtered values
                            } else {
                                colValue = colValue.toString().toLowerCase();
                                if (matchExtactColumnFilterValue.includes(col)) {
                                    found = (colValue === filterStr) ? true : false;
                                } else if (multiColumnFilterValue.includes(col)) {
                                    //filter appears to be multi value so search if any of the values matches the objects column value
                                    if (Array.isArray(data[col])) {
                                        colValue = _.uniq(_.map(data[col], _.method('toLowerCase')));   //get the array of values
                                        found = (_.difference(filterStr.split(","), colValue).length === 0) ? true : false;
                                    } else {
                                        found = (filterStr.split(",").includes(colValue)) ? true : false;
                                    }
    
                                } else if (multiColumnFilterORLogicValue.includes(col)) {
                                    if (Array.isArray(data[col])) {
                                        colValue = _.uniq(_.map(data[col], _.method('toLowerCase')));
                                        const filterStrArray = filterStr.split(",");
                                        found = (_.intersection(filterStrArray, colValue).length > 0) ? true : false;
                                    } else {
                                        found = (filterStr.split(",").includes(colValue)) ? true : false;
                                    }
                                } else if (colValue.indexOf(filterStr) > -1) {
                                    //string or substring match
                                    found = true;
                                }
                            }
                        }

                        if (!found) {
                            break;  //if any of the filter condition is not met for a property, stop searching and exit loop
                        }
                    }
                    return found;
                } else {
                    return true;
                }
            };
            return nameSearch();
        };
        return filterFunction;
    }

    // Custom filter method fot Angular Material Datatable
    matTableColumnOrFilterPredicate() {
        const filterFunction = function (data: any, filter: string): boolean {
            const searchTerms = JSON.parse(filter);
            const matchExtactColumnFilterValue = searchTerms.matchExtactColumnFilterValue || [];
            delete searchTerms.matchExtactColumnFilterValue;

            let isFilterSet = false;
            for (const col in searchTerms) {
                if (searchTerms[col].toString() !== '') {
                    isFilterSet = true;
                } else {
                    delete searchTerms[col];
                }
            }

            const nameSearch = () => {
                let found = false;
                if (isFilterSet) {
                    for (const col in searchTerms) {
                        const word: string = searchTerms[col].trim().toLowerCase();
                        if (col in data) {
                            if (matchExtactColumnFilterValue.indexOf(col) > -1 && data[col].toString().toLowerCase() === word.toLowerCase()) {
                                found = true;
                            } else if (data[col].toString().toLowerCase().indexOf(word) !== -1) {
                                found = true;
                            }
                        }
                    }
                    return found;
                } else {
                    return true;
                }
            };
            return nameSearch();
        };
        return filterFunction;
    }

    getUserDefaultPageSize(settingName: any) {
        let result = 50;  // default
        const gridSettings = this.storeSVc.getItem('gridSettings');

        if (gridSettings && gridSettings[settingName]) {
            const settings = gridSettings[settingName];

            result = settings.defaultPageSize;
        }

        return result;

    }

    isColumnHidden(columnName: string, settingName: any, defaultDisplayedColumns: string[] = []) {
        let result = false;

        const gridSettings = this.storeSVc.getItem('gridSettings');

        if (gridSettings && gridSettings[settingName]) {
            const settings = gridSettings[settingName];
            result = _.includes(settings.hiddenTableColumns, columnName);
        } else if (!defaultDisplayedColumns || defaultDisplayedColumns.length === 0) {
            result = false;
        } else if (!_.includes(defaultDisplayedColumns, columnName)) {
            result = true;
        }

        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        let result: string[] = [];
        if (data && data.length > 0) {
            result = _.map(_.filter(_.uniqBy(data, columnName), (d) => {
                return (d[columnName] !== null);
            }), columnName.toString());
            result = _.uniq(_.flatten(result));
        }
        return result.sort();
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        let result = _.startCase(columnName);
        result += (filterValue === undefined) ? '' : `: ${filterValue.toString()}`;
        return result;
    }

    getColumnFilterListSelectedColor(optionValue: string, filterValue: string) {
        return _.toLower(optionValue) === _.toLower(filterValue) ? '#D3D3D3' : null;
    }
}
