import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { UserRoleService } from '../services/userRole.service';
import * as _ from 'lodash';

@Directive({
    selector: '[isAuthorized], [isAuthorizedOr]'
})
export class AuthorizeDirective implements OnInit {
    createdEmbeddedView = false;
    _hasScreenAccess: boolean;
    screenName: UIRouter['globals']['current']['name'];
    name: string;
    
    @Input() set isAuthorized(name: string) {
        this.name = name;
        this.setVisibiltiy();
    }

    or = false;
    @Input() set isAuthorizedOr(or: boolean) {
        this.or = or;
        this.setVisibiltiy();
    }

    _precondition = true;
    @Input() set isAuthorizedAndPrecondition(precondition: boolean) {
        this._precondition = precondition;
        this.setVisibiltiy();
    }

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private userRoleSvc: UserRoleService,
                private stateParam: UIRouter) {
        this.screenName = this.stateParam.globals.current.name;
    }

    async ngOnInit() {
        await this.setVisibiltiy();
    }

    async setVisibiltiy() {
        let allowedAccess = await this.hasAccess(); 
        if (this._precondition && (allowedAccess || this.or)) {
            if (!this.createdEmbeddedView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.createdEmbeddedView = true;
            }
        } else {
            this.viewContainer.clear();
            this.createdEmbeddedView = false;
        }
    }
    
    async hasAccess(): Promise<boolean> {
        if (_.isNil(this._hasScreenAccess)) {
            this._hasScreenAccess = await this.userRoleSvc.isValidScreenAccess(this.screenName + '.' + this.name);
        }
        return this._hasScreenAccess;
    }
}
