import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IClarifyInsightsFilterList } from './clarifyInsightsFilterList.component.d';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';


@Injectable()
export class ClarifyInsightsFilterListService {
    constructor(private dataAccess: NgDataAccess, private state: StateService) { }

    async initDelegate(states: IClarifyInsightsFilterList): Promise<object> {
        await this.init(states);
        return states;
    }

    async init(states: IClarifyInsightsFilterList) {
        let filters = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsFilter'
        });
        let customers = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsCustomer'
        })
        customers = customers.map((customer: { extractType: string; }) => {
            if (customer.extractType === 'enriched_claims') {
                customer.extractType = 'claims';
            }
            return customer;
        });
        filters = filters.map((filter: { customerId: any; extractType: any; filterName: any;}) => {
            const customer = customers.find((customer: { customerId: any; extractType: any; }) => customer.customerId === filter.customerId && customer.extractType === filter.extractType);
            return {
                ...filter,
                clarifyInsightsCustomer: customer
            };
        });


        const mappedFilters = filters.map((filter: any) => {
            filter.extractStartDate = (filter.extractStartDate) ? new Date(filter.extractStartDate).toISOString().slice(0, 10) : undefined;
            filter.extractEndDate = (filter.extractEndDate) ? new Date(filter.extractEndDate).toISOString().slice(0, 10) : undefined;
            filter.createdDate = (filter.createdDate) ? new Date(filter.createdDate).toISOString().slice(0, 10) : undefined;
            filter.extractType = this.mapExtractType(filter.extractType);
            filter.customerExtractType = this.mapExtractType(filter.clarifyInsightsCustomer.extractType);
            filter.customerName = filter.clarifyInsightsCustomer.customerName;
            return filter;
        });

        states.customers = _.uniqWith(_.orderBy(mappedFilters, 'filterVersion', 'desc'), (x, y) => x.customerId === y.customerId && x.dataSource === y.dataSource && x.extractType === y.extractType && (x.filterName === y.filterName));

        const nullSearchColumns = ['dataSource', 'filterName', 'extractStartDate', 'extractEndDate'];
        // replace nulls and undefined with empty string to allow search to work on all columns
        states.customers.forEach((customer: any) => {
            for (const key in customer) {
                if (nullSearchColumns.includes(key)) {
                    if (customer[key] === null || customer[key] === undefined) {
                        customer[key] = '';
                    }
                }
            }
        });

        states.table.data = states.customers;
    }

    mapExtractType(extractType: string) {
        let mappedExtractType: string;
        switch (extractType) {
            case 'claims':
                mappedExtractType = 'Enriched Claims';
                break;
            case 'hospital_rates':
                mappedExtractType = 'Hospital Rates';
                break;
            case 'market_share':
                mappedExtractType = 'Market Share Encounters';
                break;
            case 'provider_performance':
                mappedExtractType = 'Provider Performance';
                break;
            default:
                mappedExtractType = 'Provider Directory'
                break;
        }
        return mappedExtractType;
    }

    backToLandingPageDelegate() {
        this.state.go('argos.clarifyInsights');
    }

    backToDeliveryHubDelegate() {
        this.state.go('argos.clarifyInsightsDeliveryHub')
    }
}
