import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IEditReleaseNotesProps, IEditReleaseNotesStates } from './editReleaseNotes.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EditReleaseNotesService } from './editReleaseNotes.component.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'argos-editReleaseNotes',
    templateUrl: './editReleaseNotes.component.html',
    providers: [EditReleaseNotesService]
})

export class EditReleaseNotesComponent extends ReactComponentBase<IEditReleaseNotesProps, IEditReleaseNotesStates, {}> implements OnInit {
    constructor(private svc: EditReleaseNotesService, private state: StateService,
        private cdr: ChangeDetectorRef) {
        super({
            product: 'Care Prism',
            noData: '',
            releaseNotesTable: [],
            release: {}
        } as IEditReleaseNotesStates);
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.props, this.states);
        this.setStates(newStates);
        this.cdr.detectChanges();
    }

    cancel() {
        this.state.go('argos.prism.releases');
    }

    editReleaseNotes() {
        this.svc.editReleaseNotes(this.states, this.refreshPage);
    }

    refreshReleaseNotes() {
        this.svc.refreshReleaseNotes(this.states, this.refreshPage);
    }

    refreshPage: Function = () => {
        this.cdr.detectChanges();
    };
}
