import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IEnvironmentEditStates, IEnvironmentEditService, IEnvironmentEditProps } from './environmentEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { EnvironmentService } from '../../../services/environmentService.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { StateService } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentEditCareGroupingModalComponent } from '../environmentEditCareGroupingModal/environmentEditCareGroupingModal.component';
import { ViewClientCareGroupingHistoryModalComponent } from '../viewClientCareGroupingHistoryModal/viewClientCareGroupingHistoryModal.component';
import { MigrateCareGroupingModalComponent } from '../migrateCareGroupingModal/migrateCareGroupingModal.component';
import swal from 'sweetalert2';
import { AlertService } from 'client/app/services/alert.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { EcsAppAlarms } from '../../ecs/ecsAppEdit/handler/ecsAppAlarmsHandler.service';
declare const $: any;

@Injectable()
export class EnvironmentEditService implements IEnvironmentEditService {
    constructor(private dataAccess: NgDataAccess, private state: StateService,
        private environmentService: EnvironmentService, private matDialog: MatDialog,
        private alertSvc: AlertService, private argosStore: ArgosStoreService,
        private cdr: ChangeDetectorRef, private ecsAppAlarmsHandler: EcsAppAlarms) {

    }

    async initDelegate(props: IEnvironmentEditProps, states: IEnvironmentEditStates): Promise<object> {
        states.environment = props.environment;
        await this.init(states);
        return states;
    }

    async changeDelegate(oldProps: IEnvironmentEditProps, newProps: IEnvironmentEditProps, states: IEnvironmentEditStates): Promise<Object> {
        return this.initDelegate(newProps, states);
    }

    async init(states: IEnvironmentEditStates) {

        states.newEcsParameter = {
            key: null,
            value: null
        };

        states.environmentTypes = this.environmentService.environmentTypes;

        await this.reloadEnvironment(states);

        const databaseServers = await this.dataAccess.genericFind({
            model: 'DatabaseServer'
        });

        const auth0Clients = await this.dataAccess.genericFind({
            model: 'Auth0Client'
        });

        states.databaseServers = _.orderBy(databaseServers, ['name']);

        states.auth0Clients = _.orderBy(auth0Clients, ['auth0Connection']);
    }

    async environmentNameChangeHandler(states: IEnvironmentEditStates) {
        if (states.environment.name.length > 0) {
            const response = await this.dataAccess.genericFind({
                model: 'Environment',
                filter: {
                    where: {
                        name: states.environment.name
                    }
                }
            });
            if (response && response.length > 0 && states.environment.id !== response[0].id) {
                states.nameExists = true;
            }
            else {
                states.nameExists = false;
            }
        }
    }

    markForDeletion(row: any, states: IEnvironmentEditStates) {
        swal({
            title: 'Are you sure?',
            text: `Do you want to remove care grouping ${row.careGrouping} from this environment?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                await this.dataAccess.genericUpsert({
                    model: 'ClientCareGrouping',
                    data: {
                        id: row.id,
                        lastUpdatedBy: this.argosStore.getItem('username'),
                        lastUpdateDate: new Date(),
                        markedForDeletion: true
                    }
                });
                this.reloadEnvironment(states);
            }
        });
    }

    async undelete(row: any, states: IEnvironmentEditStates) {
        if (row && row.markedForDeletion) {
            await this.dataAccess.genericUpsert({
                model: 'ClientCareGrouping',
                data: {
                    id: row.id,
                    lastUpdatedBy: this.argosStore.getItem('username'),
                    lastUpdateDate: new Date(),
                    markedForDeletion: false
                }
            });
            this.reloadEnvironment(states);
        }
    }

    async saveHandler(states: IEnvironmentEditStates) {
        try {
            const data = states.environment;
            await this.dataAccess.genericUpsert({
                model: 'Environment',
                data
            });
            this.state.go('argos.environments.environments.list');
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    addEditCareGroupingHandler(states: IEnvironmentEditStates, row: any, cdr: ChangeDetectorRef) {
        let newRow = false;
        if (!row) {
            newRow = true;
            row = {
                clientId: states.environment.client.id,
                databaseName: states.environment.dbName,
                enabled: true,
                overrides: {
                    dbEngine: 'athena'
                }
            };
        }
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    clientCareGrouping: row
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            EnvironmentEditCareGroupingModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async ($event: any) => {
            if ($event) {
                await this.reloadEnvironment(states);
                cdr.detectChanges();  // force ui refresh
            }
        });
    }

    private async reloadEnvironment(states: IEnvironmentEditStates) {

        if (states.environment.id) {
            const environment = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'getEnvironment', parameters: {
                    id: states.environment.id
                }
            });
            const usages = await this.dataAccess.genericMethod({
                model: 'MetaDataUsage',
                method: 'getEnvironmentUsageDetails',
                parameters: {
                    metaDataTypes: ['careGrouping'],
                    environmentIds: [states.environment.id]
                }
            });

            states.environment.client = environment.client;
            states.environment.herokuletiables = this.environmentService.herokuVariables;
            states.environment.lets = environment.lets;
            states.environment.auth0 = environment.auth0;
            states.environment.dbServer = environment.dbServer;
            states.environment.ecsParameters = environment.ecsParameters;

            if (states.environment.lets && states.environment.herokuletiables) {
                states.environment.herokuletiables.forEach(function (letiable: any) {
                    letiable.herokuValue = states.environment.lets[letiable.name];
                    letiable.herokuDisplay = letiable.hide && letiable.herokuValue ? '******' : letiable.herokuValue;
                    if (_.isString(letiable.environmentProperty)) {
                        letiable.argosValue = _.get(states.environment, letiable.environmentProperty);
                        letiable.argosDisplay = letiable.hide && letiable.argosValue ? '******' : letiable.argosValue;
                    } else if (_.isFunction(letiable.environmentProperty)) {
                        letiable.argosValue = letiable.environmentProperty(states.environment);
                        letiable.argosDisplay = letiable.hide && letiable.argosValue ? '******' : letiable.argosValue;
                    }
                });
            }

            _.forEach(environment.clientCareGroupings, ccg => {
                ccg.displayTitle = ccg.title || ccg.careGroupingTitle;
                ccg.baseTable = ccg.overrides?.baseTable || ccg.defaultBaseTable;
                const usage = _.find(usages, { meta_data_name: ccg.careGrouping });
                ccg.usageCount = _.toNumber(_.get(usage, 'total_usage', 0));
                ccg.viewCount = _.toNumber(_.get(usage, 'total_views', 0));
                ccg.lastViewed = _.get(usage, 'last_viewed');
                if (ccg.lastViewed) {
                    ccg.lastViewedFromNow = moment(ccg.lastViewed).fromNow();
                } else {
                    ccg.lastViewedFromNow = '-';
                    // set these to sort last
                    ccg.lastViewed = '';
                }
            });

            states.environment.clientCareGroupings = _.orderBy(_.filter(environment.clientCareGroupings, { markedForDeletion: false }), 'careGrouping');
            states.environment.deletedClientCareGroupings = _.orderBy(_.filter(environment.clientCareGroupings, { markedForDeletion: true }), 'careGrouping');
            this.showGrid(states);
        }
    }

    showGrid(states: IEnvironmentEditStates) {
        if (states.showDeletedCareGroupings) {
            states.clientCareGroupingMatTable.data = states.environment.deletedClientCareGroupings;
        } else {
            states.clientCareGroupingMatTable.data = states.environment.clientCareGroupings;
        }
        this.cdr.detectChanges();
    }

    viewCareGroupingHistoryHandler(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    clientCareGrouping: row
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewClientCareGroupingHistoryModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(($event: any) => {
            if ($event) {
                //
            }
        });
    }

    migrateCareGroupingHandler(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    clientCareGrouping: row
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            MigrateCareGroupingModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(($event: any) => {
            if ($event) {
                //
            }
        });
    }

    async migrationCompleteHandler(row: any, states: IEnvironmentEditStates) {
        delete row.overrides.migrateFromCareGrouping;
        await this.dataAccess.genericUpsert({
            model: 'ClientCareGrouping',
            data: row
        });
        await this.reloadEnvironment(states);
    }

    addEcsKeyValueHandler(key: any, value: any, states: IEnvironmentEditStates) {
        if (key in states.environment.ecsParameters) {
            return;
        } else {
            states.environment.ecsParameters[key] = value;
            states.newEcsParameter.key = null;
            states.newEcsParameter.value = null;
        }
    }

    deleteEcsKeyValue(key: any, states: IEnvironmentEditStates) {
        delete states.environment.ecsParameters[key];
    }

    deleteEnvironment(states: IEnvironmentEditStates) {
        if (states.environment && states.environment.id) {
            let alertMessage = 'Are you sure you want to delete environment ' + states.environment.name + '?';
            let isHerokuDemoApp = false;

            if (states.environment.herokuSiteName.length > 0 && states.environment.environmentType === 'demo') {
                isHerokuDemoApp = true;
                alertMessage = 'This will delete the Heroku app ' + states.environment.herokuSiteName + ' and environment ' + states.environment.name + '. Proceed?';
            }
            swal({
                title: 'Really?',
                text: alertMessage,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm: any) => {
                if (isConfirm.value) {
                    if (isHerokuDemoApp) {
                        // do additional delete
                        const data = await this.dataAccess.genericMethod({
                            model: 'Environment', method: 'deleteEnvironmentHeroku',
                            parameters: { herokuAppName: states.environment.herokuSiteName }
                        });
                        console.log('deleteEnvironmentHeroku: ' + JSON.stringify(data));

                        try {
                            if ((data) && (data.isDnsEntriesDeleted) && (data.isAuth0EntriesDeleted)) {
                                await this.dataAccess.genericMethod({
                                    model: 'Environment', method: 'destroyById',
                                    parameters: { id: states.environment.id }
                                });
                                this.state.go('argos.environments.environments.list');
                            } else {
                                // alert the user
                                swal({
                                    title: 'Oops!',
                                    text: ((data.herokuAppDeleted) ? '' : 'Heroku app was not deleted' + '\n') + ((data.isDnsEntriesDeleted) ? '' : 'DNS delete was not all successful' + '\n') + ((data.isAuth0EntriesDeleted) ? '' : 'Auth0 delete was not all successful'),
                                    type: 'error',
                                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                                    cancelButtonText: 'Cancel',
                                });
                            }
                        } catch (error) {
                            this.alertSvc.handleError(error);
                        }
                    } else {
                        await this.dataAccess.genericMethod({
                            model: 'Environment', method: 'destroyById',
                            parameters: { id: states.environment.id }
                        });
                        this.state.go('argos.environments.environments.list');
                    }
                }
            });
        }
    }

    async findAlarms(url: any) {

        const alarms = await this.dataAccess.genericMethod({
            model: 'UpTimeApi', method: 'getAlarmChecks', parameters: {
                searchTerm: url
            }
        });

        return alarms;

    }

    async deleteUptimeAlarms(alarms: any, states: IEnvironmentEditStates) {

        let alarmString = '';

        for (let i = 0; i < alarms.alarms.length; i++) {
            alarmString = alarmString + alarms.alarms[i].name + ' ';
        }

        swal({
            title: 'Confirm alarm deletion',
            text: 'Delete ' + alarmString + '? 12 months of history will be only saved in the activity log',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {

                const vars = {
                    cluster: {
                        clusterName: states.environment.hostingAppName,
                        accountName: states.environment.hostingAccount
                    }
                };
                _.set(vars, 'cluster.upTimeAlarms', []);

                for (let i = 0; i < alarms.alarms.length; i++) {
                    const deleteReq = await this.ecsAppAlarmsHandler.deleteUptimeAlarm(alarms.alarms[i], vars);

                    if (!deleteReq.isSuccessful) {
                        swal({
                            title: 'Failed to delete check',
                            text: deleteReq.errorMessage,
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                        }).then(async (isConfirm: any) => {
                            //
                        });
                    } else {
                        swal({
                            title: 'Delete successful',
                            text: 'Deletion of ' + alarms.alarms[i].name + ' successful',
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                        }).then(async (isConfirm: any) => {
                            //
                        });
                    }
                }
            }
        });

    }

}
