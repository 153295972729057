import { Injectable } from '@angular/core';
import { IPipelineStepDetailStates, IPipelineStepDetailService } from './pipelineStepDetail.component.d';
import { ErrorLogModalComponent } from '../errorLogModal/errorLogModal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter } from '@uirouter/core';

@Injectable()
export class PipelineStepDetailService implements IPipelineStepDetailService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog) {
        //
    }

    async initDelegate(states: IPipelineStepDetailStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IPipelineStepDetailStates) {
        states.dataSource = this.uiRouter.globals.params.dsKey;
        states.stage = this.uiRouter.globals.params.stageKey;
        states.pipelineId = this.uiRouter.globals.params.pipelineId;
        states.pipelineIds = this.uiRouter.globals.params.pipelineIds;
        states.previousTimeFrameSelection = this.uiRouter.globals.params.previousTimeFrameSelection;
        states.previousPipelineTypeSelection = this.uiRouter.globals.params.previousPipelineTypeSelection;
        await this.querySteps(states);
    }

    openModal(error: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    error
                }
            }
        };
        this.matDialog.open(ErrorLogModalComponent, dialogConfig);
    }

    async querySteps(states: IPipelineStepDetailStates) {
        const res = await this.dataAccess.genericFind({
            model: 'PipelineStep',
            filter: {
                where: {
                    pipelineHistoryId: states.pipelineId
                }
            }
        });
        states.allPipelineSteps = res;

        const results = await this.dataAccess.genericFind({
            model: 'PipelineHistory',
            filter: {
                where: {
                    id: states.pipelineId
                }
            }
        });

        // parse cluster config into array

        results.forEach((result: any) => {
            states.emrClusterId = result.emrClusterId;
            states.modelErrors = result.modelErrors;
            states.clusterConfig = this.parseClusterConfig(result.clusterConfig);
            states.clusterConfig.sort((a: any, b: any) => {
                if (a.name === 'Master') {
                    return -1;
                }
                if (b.name === 'Master') {
                    return 1;
                }
                if (a.name === 'Core') {
                    return -1;
                }
                if (b.name === 'Core') {
                    return 1;
                }
                return 0;
            });
            states.diskSpaceUtilization = this.parseDiskSpaceUtilization(result.diskSpaceUtilization, result.clusterConfig);
            states.memoryUtilization = this.parseMemoryUtilization(result.memoryUtilization);
            states.clusterConfig.forEach((config: any) => {
                const memoryUtilization = states.memoryUtilization.find((mu: any) => mu.instanceGroup === config.name);
                if (memoryUtilization) {
                    config.allocated = memoryUtilization.allocated;
                }
            });

        });
    }

    parseClusterConfig(clusterConfig: string) {
        const clusterConfigJsonString = clusterConfig.replace(/'/g, '"');
        return JSON.parse(clusterConfigJsonString);
    }

    parseDiskSpaceUtilization(diskSpaceUtilization: any, clusterConfig: string) {
        const diskSpaceUtilizationArray: any[] = [];
        const instanceGroups: string[] = ['Master', 'Core', 'Task'];
        const targetThresholds: number[] = [70, 50]; // [max_percentage, min_percentage]
        const clusterConfigJson: any[] = this.parseClusterConfig(clusterConfig);
        instanceGroups.forEach(instanceGroup => {
            const config = clusterConfigJson.find(cc => cc.name === instanceGroup);
            let configSize = 0;
            if (config) {
                configSize = config?.Volume[0].VolumeSpecification.SizeInGB;
            }
            if (instanceGroup in diskSpaceUtilization) {
                let suggRange = 'N/A';
                let minRec = 0;
                let maxRec = 0;
                const usedPercent: number = (diskSpaceUtilization[instanceGroup].max_percentage_used / 100);
                if (configSize !== 0) {
                    const usedGB = Math.round(configSize * usedPercent);
                    minRec = Math.round(usedGB / (targetThresholds[0] / 100));
                    maxRec = Math.round(usedGB / (targetThresholds[1] / 100));
                }
                if ((minRec !== 0) && (maxRec !== 0) && (instanceGroup !== 'Master')) {
                    suggRange = minRec.toString().concat(' - ', maxRec.toString());
                }
                diskSpaceUtilizationArray.push({
                    instanceGroup,
                    meanDiskSpacePercentageUsed: diskSpaceUtilization[instanceGroup].mean_percentage_used,
                    medianDiskSpacePercentageUsed: diskSpaceUtilization[instanceGroup].median_percentage_used,
                    maxDiskSpacePercentageUsed: diskSpaceUtilization[instanceGroup].max_percentage_used,
                    suggRange
                });
            }
        });
        return diskSpaceUtilizationArray;
    }

    parseMemoryUtilization(memoryUtilization: any) {
        if (!memoryUtilization) {
            return [];
        }
        const memoryUtilizationArray: any[] = [];
        const instanceGroups: string[] = ['Master', 'Core', 'Task'];
        const targetThresholds: number[] = [80, 20]; // [max_percentage, min_percentage]
        instanceGroups.forEach(instanceGroup => {
            if (instanceGroup in memoryUtilization) {
                memoryUtilizationArray.push({
                    instanceGroup,
                    allocated: memoryUtilization[instanceGroup].allocated,
                    meanMemoryPercentageUsed: memoryUtilization[instanceGroup].mean_percentage_used,
                    medianMemoryPercentageUsed: memoryUtilization[instanceGroup].median_percentage_used,
                    maxMemoryPercentageUsed: memoryUtilization[instanceGroup].max_percentage_used,
                    usageFlag: (instanceGroup == 'Master') ? 'N/A' : (memoryUtilization[instanceGroup].max_percentage_used > targetThresholds[0]) ? 'High' : (memoryUtilization[instanceGroup].max_percentage_used < targetThresholds[1]) ? 'Low' : 'Normal'
                });
            }
        });

        return memoryUtilizationArray;
    }
}
