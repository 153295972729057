import { HostListener, Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { IEcsAppListStates } from './ecsAppList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EcsAppListService } from './ecsAppList.component.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import { EcsAppListShare } from './handler/ecsAppListShareHandler.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown3';
import { UrlService } from '../../../services/url.service';
import { MatSelectChange } from '@angular/material/select';

declare const $: any;
@Component({
    selector: 'argos-ecsAppList',
    templateUrl: './ecsAppList.component.html',
    providers: [EcsAppListService]
})

export class EcsAppListComponent extends ReactComponentBase<{}, IEcsAppListStates, {}> implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('clustersTbSort') clustersTbSort = new MatSort();
    _: any = _;

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        // confirm with user before tab close
        if (this.states.screenMode === 'promoteMode') {
            event.preventDefault();
            event.returnValue = false;
        }

    }

    constructor(private svc: EcsAppListService, private dataAccess: NgDataAccess,
        private ecsAppListShareHandler: EcsAppListShare, private uiRouter: UIRouter,
        private state: StateService, private cdr: ChangeDetectorRef, 
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            showType: 'hideEndPoint',
            clusters: [],
            awsAccounts: [
                { id: 'all', name: 'All', awsAccountName: ['clarify', 'cleardata', 'cleardata_east', 'beijing'] },
                { id: 'clarify', name: 'Clarify AWS Account', awsAccountName: ['clarify'] },
                { id: 'cleardata', name: 'Clear Data AWS Account', awsAccountName: ['cleardata'] },
                { id: 'cleardata_east', name: 'Clear Data AWS Account East Fail Over', awsAccountName: ['cleardata_east'] },
                { id: 'beijing', name: 'Beijing AWS Account', awsAccountName: ['beijing'] },
                { id: 'pm2', name: 'PM2 processes', awsAccountName: [] }
            ],
            promoteMode: false,
            screenMode: 'default',
            disablePromotingQueue: false,
            clusterToPromote: [],
            clusterEnvironmentIssues: [],
            buildTags: [],
            promoteStatusOptions: ['all', 'queued', 'ready to promote', 'promoting', 'submitted', 'failed', 'completed'],
            selectedPromoteStatus: 'all',
            selectedClusterTab: 0,
            environmentTypeCount: {},
            environmentEc2TypeCount: {},
            argosClientCareGroupings: [],
            argosClientProductConfiguration: [],
            unqiueUiVersionCount: [],
            environmentDetails: [],
            promoteTypeOptions: ['build', 'patch'],
            clusterLoadBlocks: 30,
            paginationBlocks: 30,
            totalClusters: 0,
            clusterMatTableSettingName: 'ecsAppList',
            clusterTable: new MatTableDataSource([]),
            cachedSearchData: [],
            filterClusters: [], 
            timerWithInterval: 0,
            filterObj: {
                multiColumnFilterValue: ['argosClientCareGroupingsFilter'],
                multiColumnFilterORLogicValue: ['argosClientProductConfigurationFilter']
            },
            defaultDisplayedColumns: ['bulkSelected', 'accountName', 'friendlyName', 'environmentType', 'url', 'uiVersion', 'ecrRepoName', 'argosClientCareGroupingsFilter', 'argosClientProductConfigurationFilter', 'hasPrismPatch', 'metrics', 'containerService', 'isExcludedFromReleaseCycle', 'isSiteDisabled', 'lastLoginDaysAgo', 'createdAt'],
            matTableColumns: ['bulkSelected', 'accountName', 'friendlyName', 'environmentType', 'url', 'uiVersion', 'ecrRepoName', 'argosClientCareGroupingsFilter', 'argosClientProductConfigurationFilter', 'hasPrismPatch', 'metrics', 'containerService', 'isExcludedFromReleaseCycle', 'isSiteDisabled', 'lastLoginDaysAgo', 'createdAt'],
            patchListTable: [],
            selectedPatches: [],
            selectedPatchNames: '',
            dropdownPatchSettings: {
                singleSelection: false,
                idField: 'id',
                textField: 'name',
                selectAllText: 'Select All Patches',
                unSelectAllText: 'UnSelect All Patches',
                itemsShowLimit: 3,
                allowSearchFilter: false,
                noDataAvailablePlaceholderText: 'No Patches available for current Build'
            } as IDropdownSettings,
            newEcsSearchObj: {
                key: '',
                value: ''
            },
            ecsSearchObjList: [],
            isSearch: false,
            runUpgradeTaskWhereBuildIsUnchanged: false
        } as unknown as IEcsAppListStates);
    }

    ngAfterViewInit() {
        this.states.clusterTable.paginator = this.paginator;
        this.states.clusterTable.sort = this.clustersTbSort;
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.clusterTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.clusterMatTableSettingName);
        this.states.cdr = this.cdr;
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.clusterTable.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.states.myInterval = null;
    }

    formatToTime(timeData: any) {
        const sec_num = parseInt(timeData, 10); // don't forget the second param
        let hours: any = Math.floor(sec_num / 3600);
        let minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds: any = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = '0' + hours; }
        if (minutes < 10) { minutes = '0' + minutes; }
        if (seconds < 10) { seconds = '0' + seconds; }

        const time = hours + ':' + minutes + ':' + seconds;

        return time;
    }

    multiFilterChange(columnName: string, event: MatSelectChange) {
        //if uninitialized or clear filter by setting to empty array
        this.states.filterObj = this.matSvc.multiFilterChange(this.states.filterObj, columnName, event);  
        this.states.clusterTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.clusterTable.filter);
        
    }

    filterChange(columnName: string, value: any) {
        if (value === undefined) {
            value = '';
        }

        this.states.filterObj = this.matSvc.filterChange(this.states.filterObj, columnName, value);  
        this.states.clusterTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.clusterTable.filter);
    }

    searchClusters(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.clusterTable.filter = filterValue.trim().toLowerCase();
    }

    updateScheduledScalingSuspendedState(disable: boolean) {
        swal({
            title: 'Are you sure?',
            text: 'Changing automated scaling scheduler can impact cost and performance',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then((isConfirm) => {
            if (isConfirm.value) {
                this.svc.updateScheduledScalingSuspendedState(this.states, disable);
            }
        });

    }

    selectTab(event: any) {
        this.states.selectedClusterTab = event.index;
    }

    getclusterEnvironmentIssueMessage(issueType: string) {
        const result = _.filter(this.states.clusterEnvironmentIssues, function (i) {
            if (i.type === issueType) {
                return i;
            }
        }).map(function (it: any) {
            return it.clusterName + ' with error: ' + JSON.stringify(it.description);
        });

        return result.join('\n');
    }

    getIssues() {
        return _.countBy(this.states.clusterEnvironmentIssues, 'type');
    }

    async getCreatedDate(cluster: any, env: any) {
        const result = await this.dataAccess.genericMethod({
            model: 'EnvironmentActivityLog', method: 'findOne', parameters: {
                where: { clusterName: cluster.clusterName },
                order: 'id'
            }
        });
        if (result.createdAt < env.createdAt || result.environmentAction === 'environment created') {
            cluster.createdAt = this.ecsAppListShareHandler.formatDate(result.createdAt);
            env.createdAt = result.createdAt;
            await this.dataAccess.genericUpsert({
                model: 'Environment',
                data: env
            });  // save the permission
        }
    }

    getCheckedRowCount() {
        return _.filter(this.states.clusters, function (c) { return c.bulkSelected === true; }).length;
    }

    getActiveDeployedCount(status: any) {
        return _.filter(this.states.clusterToPromote, function (o) { if (o.promoteStatus === status) return o; }).length;
    }

    getDeployErrorCount() {
        const result = _.sum(_.map(this.states.clusterToPromote, (c) => {
            return c.promoteErrors ? c.promoteErrors.length : 0;
        }));

        return result;
    }

    selectedAWSServiceChanged() {

        if (this.uiRouter.globals.params) {
            this.uiRouter.globals.params.accountname = this.states.selectedAwsAccount;
        }
        this.state.go(this.uiRouter.globals.current, this.uiRouter.globals.params, { reload: true, inherit: false });

    }

    selectPlatformReleaseRows() {
        this.states.clusters.forEach((c: any) => {
            if (c.isPrismApp === true && !c.isExcludedFromReleaseCycle && c.ecrRepoName === 'clarify-platform' && (c.ec2Info.length === 1 || c.isFargateEnabled) && this.isBulkSelectedDisabled(c) === false) {
                c.bulkSelected = true;
            }
        });
    }

    async selectNpoSitesRows() {
        let npoSites = await this.dataAccess.genericMethod({
            model: 'NetworksStatus', method: 'getNetworkEnvironmentsStatus', parameters: {}
        });

        npoSites = _.filter(npoSites, function (e) {
            const networkCount = _.get(e, 'networksStatistics.networks');
            return _.isNil(networkCount) || networkCount > 0;
        });

        this.states.clusters.forEach((c: any) => {
            if (_.find(npoSites, { id: c.environmentId }) && this.isBulkSelectedDisabled(c) === false) {
                c.bulkSelected = true;
            }
        });

        const selectedRows = _.filter(this.states.clusters, function (c) { return c.bulkSelected === true; });

        swal({
            title: 'NPO selection completed',
            text: 'Selected ' + selectedRows.length + ' rows',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
        }).then((isConfirm) => {
            //
        });

    }

    async loadUptimeAlarms() {
        const searchResult = await this.dataAccess.genericMethod({
            model: 'UpTimeApi', method: 'getAlarmChecks', parameters: {}
        });

        this.states.clusters.forEach(function (c: any) {
            c.upTimeAlarms = _.map(_.filter(searchResult.alarms, function (a) {
                if (a.msp_address && a.msp_address.toLowerCase().replace('https://', '') === c.url.toLowerCase()) {
                    return a;
                }
            }), (ut) => {
                return ut.check_type.toLowerCase().replace('transaction', 'ta').replace('http', 'ut');
            }).sort();
        });

        this.cdr.detectChanges();
    }


    selectVisibleRows(selected: boolean) {
        this.states.clusterTable.filteredData.forEach((c: any) => {
            if (selected) {
                if (c.isPrismApp && (c.ec2Info.length === 1 || c.isFargateEnabled) && this.isBulkSelectedDisabled(c) === false) {
                    c.bulkSelected = true;
                }
            } else {
                c.bulkSelected = false;
            }
        });
    }

    /** **************************************************************************************
     promote mode code
    ****************************************************************************************/
    openApp(url: any) {
        if (url && url.length > 0) {
            window.open('https://' + url, '_blank');
        }
    }

    getPaperTrailLogUrl(clusterName: string) {
        let exclusionStr = this.states.envManagement.papertrailLogExclusion.join('"%20OR%20"').replace(/ /g, '%20');
        let result = 'https://my.papertrailapp.com/groups/17647492/events?q=' + clusterName + '%20AND%20NOT%20%28"' + exclusionStr + '"%29';

        return result;
    }

    openUpgradeLog(clusterName: any) {
        let url = 'https://my.papertrailapp.com/groups/17647492/events?q=' + clusterName + '%20AND%20"upgrade"';
        window.open(url, '_blank');
    }

    getOpenLogLink(clusterName: any) {
        let url = this.getPaperTrailLogUrl(clusterName);
        return url;
    }

    getAwsTasksLink(clusterName: any) {
        return 'https://us-west-2.console.aws.amazon.com/ecs/v2/clusters/' + clusterName + '/tasks?region=us-west-2';
    }

    openAwsTasksLink(clusterName: any) {
        window.open('https://us-west-2.console.aws.amazon.com/ecs/home?region=us-west-2#/clusters/' + clusterName + '/tasks', '_blank');
    }

    getAwsTasksDefsLink(taskDefinitionName: any) {
        return 'https://us-west-2.console.aws.amazon.com/ecs/v2/task-definitions/' + taskDefinitionName + '?status=ACTIVE&region=us-west-2';
    }

    getAWSEc2InstanceURL(instanceId: any) {
        return 'https://us-west-2.console.aws.amazon.com/ec2/v2/home?region=us-west-2#Instances:instanceId=' + instanceId + ';sort=instanceId';
    }

    getAWSEcsLoadBalancerLink(loadBalancerName: any) {
        return 'https://us-west-2.console.aws.amazon.com/ec2/home?region=us-west-2#LoadBalancers:search=' + loadBalancerName + ';sort=loadBalancerName';
    }

    openAwsTaskDefinitionSite(arn: any) {
        const values = arn.split(':');
        window.open('https://us-west-2.console.aws.amazon.com/ecs/home?region=' + values[3] + '#/taskDefinitions/' + values[5].split('/')[1] + '/' + values[6], '_blank');
    }
    /*
      //returns true if we have any cluster thats promote status has been active i.e not null
      hasPromoted() {
        let result = (_.filter(this.states.clusterToPromote, function(c) {
            if (c.promoteStatus) {
                return c;
            }
        }));

        if (result && result.length > 0) {
            return true;
        } else {
            return false;
        }
      }
    */

    isShowScreenHidden(mode: string) {

        const result = !(this.states.screenMode === mode);

        return result;
    }

    cancelPromoteMode() {
        this.svc.cancelPromoteMode(this.states);
    }

    isPromoting() {
        const result = (_.filter(this.states.clusterToPromote, function (c) {
            if (c.promoteStatus === 'promoting' || c.promoteStatus === 'submitted') {
                return c;
            }
        }));

        if (result && result.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    isFilterPromotingClusterVisible(cluster: any) {
        let result = true;

        if (this.states.selectedPromoteStatus === 'all' || !cluster.promoteStatus) {
            result = false;
        } else if (cluster.promoteStatus === this.states.selectedPromoteStatus) {
            result = false;
        }

        return result;
    }

    enableQueue() {
        this.states.disablePromotingQueue = !this.states.disablePromotingQueue;
    }

    startPromoteBuildToClusters() {
        this.ecsAppListShareHandler.checkPromoteBuildToClusters(this.states, this.cdr);
    }

    restartClusterPollPromoteStatus(cluster: any) {
        this.checkAppAndDBVersion(cluster, true);
    }

    isForceDeployStatusCheckHiiden(cluster: any) {
        return this.ecsAppListShareHandler.isForceDeployStatusCheckHiiden(cluster);
    }

    forceDeployStatusCheck(cluster: any) {
        this.checkAppAndDBVersion(cluster, false);
    }

    stopTimerWithInterval() {
        this.ecsAppListShareHandler.stopTimerWithInterval(this.states);
    }

    checkAppAndDBVersion(clusterObj: any, resetPromoteStatus: any) {
        // now get the db and ui build numbers
        this.ecsAppListShareHandler.checkAppAndDBVersion(clusterObj, resetPromoteStatus, this.states);
    }

    pushCodeToSelectedRows() {
        const selectedRows = _.filter(this.states.clusters, function (c) { return c.bulkSelected === true; });
        const excludedBulkCount = _.filter(this.states.clusters, function (c) { return (c.isExcludedFromReleaseCycle === true && c.bulkSelected === true); });
        const unqiueReposCount = _.uniq(_.map(selectedRows, 'ecrRepoName'));
        this.states.unqiueUiVersionCount = _.uniq(_.map(selectedRows, 'uiVersion'));
        this.states.promoteTypeOptions = ['build'];

        if (unqiueReposCount[0] === 'clarify-platform') {
            this.states.promoteTypeOptions.push('patch');
        } else {
            this.states.selectedPromoteType = 'build';
        }
 
        if (unqiueReposCount.length > 1) {
            swal({
                title: 'Invalid selection',
                text: 'You cannot select multiple repos ' + unqiueReposCount.join(', '),
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes'
            }).then((isConfirm) => {
                //
            });
        } else if (selectedRows.length > 1 && excludedBulkCount.length > 0) {
            //warn if multi select and at least one is excluded from bulk selection
            swal({
                title: 'Confirm selection',
                text: 'The following apps are excluded from bulk deploys but they are selected now: ' + _.map(excludedBulkCount, 'friendlyName').join(', '),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
            }).then((isConfirm) => {
                if (isConfirm.value) {
                    this.svc.pushCodeToSelectedRows(this.states);
                }
            });

        } else if (this.states.unqiueUiVersionCount.length > 1) {
            swal({
                title: 'Confirm selection',
                text: 'Note that you have selected environments with different builds: ' + this.states.unqiueUiVersionCount.join(', '),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
            }).then((isConfirm) => {
                if (isConfirm.value) {
                    this.svc.pushCodeToSelectedRows(this.states);
                }
            });
        } else {
            this.svc.pushCodeToSelectedRows(this.states);
        }
    }

    getDeployErrorsReport() {
        let result = '';
        let clusters = _.filter(this.states.clusterToPromote, function (c) { return c.promoteErrors && c.promoteErrors.length > 0; });
        let errors = _.map(clusters, (c) => { 
            return {
                url: c.url,
                clusterName: c.clusterName,
                promoteErrors: c.promoteErrors
            };
        });

        _.forEach(errors, (e) => {
            result = result + '\n-------------------------\n';
            result = result + e.clusterName + '\n';
            result = result + e.url + '\n';
            result = result + '-------------------------\n';
            result = result + e.promoteErrors.join('\n');
        });

        return result;
    }

    async copyDeployErrorsReportToClipboard() { 
        let report = this.getDeployErrorsReport();        
        this.copyToClipboard(report);
    }

    async createDeployErrorsReportToJiraTicket() {
        let report = this.getDeployErrorsReport();
        let jiraRsp = await this.svc.createDeployErrorsReportToJiraTicket(report, this.states);
        
        let title = `Jira ticket created`
        let msg = `Issue: ${jiraRsp.response.issueUrl}`
        
        if (!jiraRsp.isSuccessful) {
            title = `Failed to create Jira ticket`;
            msg = `Error: ${jiraRsp.error}`;
        }
        
        swal({
            title: title,
            text: msg,
            type: 'info',
            showCancelButton: false,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
        }).then((isConfirm) => {
        });
    }

    async copyReleaseReportToClipboard() {

        const completedDeployments = _.filter(this.states.clusterToPromote, function (o) { if (o.promoteStatus === 'completed' && o.dbVersion !== 'NA') return o; });
        let message = 'no completed deployments found';

        if (completedDeployments.length > 0) {
            let buildApplied = completedDeployments[0].dbVersion;
            message = 'Deploy of atlas build ' + buildApplied + ' pushed to\n';

            const activityLogsData = await this.dataAccess.genericFind({
                model: 'EnvironmentActivityLog',
                filter: { where: { environmentAction: 'code change upgrade run time' } }
            });
            const envs = await this.dataAccess.genericFind({
                model: 'Environment',
                filter: {
                    where: {
                        and: [{ hostingProvider: 'ecs' },
                            { isDisabled: false }]
                    },
                    order: 'dnsCname desc'
                }
            });
            const activityLogs = _.filter(activityLogsData, (lg) => {
                if (lg.eventDetail.toBuildTag && lg.eventDetail.toBuildTag === buildApplied) {
                    return lg;
                }
            });

            envs.forEach(function (e: any) {
                e.url = e.dnsCname + '.clarifyhealth.' + e.domainSuffix;
            });

            message += this.getAwsAccountReleaseReport(activityLogs, envs, 'clarify');
            message += this.getAwsAccountReleaseReport(activityLogs, envs, 'cleardata');
            message += this.getAwsAccountReleaseReport(activityLogs, envs, 'cleardata_east');
        }

        this.copyToClipboard(message);
    }

    getEnvironmentBuildNumbers(clusterObj: any) {
        this.ecsAppListShareHandler.getEnvironmentBuildNumbers(clusterObj, this.states);
    }
    copyToClipboard(value: any) {
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(value).select();
        document.execCommand('copy');
        $temp.remove();
    }

    buildPromoteChanged(selectedBuild: any) {

        this.states.clusterToPromote.forEach(function (cluster: any) {
            cluster.buildToPromote = selectedBuild;
        });
    }

    removeClusterFromPromote(clusterName: any) {

        for (let i = 0; i < this.states.clusterToPromote.length; i++) {
            // console.log('clusterName search ' + clusterName + ' found ' + this.states.clusterToPromote[i].clusterName);
            if (this.states.clusterToPromote[i].clusterName === clusterName) {
                this.states.clusterToPromote.splice(i, 1);
                break;
            }
        }
    }

    getAwsAccountReleaseReport(activityLogs: any, envs: any, awsAccount: any) {
        let result = '\n-------------------------\n';
        result = result + awsAccount + '\n';
        result = result + '-------------------------\n';

        const releasedEnvs = _.flatten(_.map(activityLogs, function (alog: any) {
            return _.filter(envs, function (e) {
                if (e.hostingAppName === alog.clusterName && alog.awsAccount === awsAccount) {
                    return e;
                }
            });
        }));

        // return empty string if no matches are found
        if (releasedEnvs.length > 0) {
            result += _.uniq(_.map(_.sortBy(releasedEnvs, ['url', 'awsAccount']), 'url')).join('\n');
        } else {
            result = '';
        }

        return result;
    }

    promoteTypeOptionChanged() {
        // reset
        this.states.clusterToPromote.forEach(function (cluster: any) {
            delete cluster.buildToPromote;
            delete cluster.dataPatches;
        });

        this.states.selectedBuildTag = null;
        this.states.selectedPatches = [];
    }

    // patch events
    onPatchSelect(event: any) {
        this.states.selectedPatches.push(event);
    }

    onPatchSelectAll(event: any[]) {
        this.states.selectedPatches = event;
    }

    onPatchDeSelect(event: any) {
        const selectedItemFiles = this.states.selectedPatches;
        this.states.selectedPatches = _.filter(selectedItemFiles, patch => {
            return (patch.id !== event.id);
        });
    }

    onPatchDeSelectAll(event: any[]) {
        this.states.selectedPatches = [];
    }

    onPatchDropDownClose() {

        this.states.selectedPatches = _.uniqBy(this.states.selectedPatches, 'id');
        const p = _.orderBy(_.map(this.states.selectedPatches, 'id'), ['asc']).toString();

        this.states.clusterToPromote.forEach(cluster => {
            cluster.dataPatches = p;
        });
    }

    isPromoteBuildBtnDisabled() {
        let result = false;

        if (this.isPromoting() || this.states.buildTags.length === 0) {
            result = true;
        } else if (!this.states.selectedBuildTag && this.states.selectedPatches.length === 0) {
            result = true;
        }

        return result;
    }

    addkeyValueObj(key: any, value: any, keyValueList: any[]) {
        if (!_.find(keyValueList, { key })) {
            keyValueList.push({ key, value });
            this.states.newEcsSearchObj = {
                key: '',
                value: ''
            };

            this.cdr.detectChanges();
        }
    }

    deleteTagByKey(objlist: any, key: any) {
        _.remove(objlist, {
            key
        });

        this.cdr.detectChanges();
    }

    isBulkSelectedDisabled(cluster: any) {
        let result = false;

        result = !cluster.accountName || cluster.accountName === 'pm2' || cluster.argosSyncError || this.getLoadingEcsClusterCount() > 0 || !(cluster.isPrismApp && (cluster.ec2Info.length === 1 || cluster.isFargateEnabled))
        return result;
    }

    searchEcsVariables() {
        this.states.isSearch = true;
        this.pushCodeToSelectedRows();
    }

    getLoadingEcsClusterCount() {
        return this.svc.getLoadingEcsClusterCount(this.states);
    }

    exportToExcel() { 
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.clusterMatTableSettingName, this.states.defaultDisplayedColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        let result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    getColumnFilterListSelectedColor(optionValue: string, filterValue: string) {
        return this.matSvc.getColumnFilterListSelectedColor(optionValue, filterValue);
    }

    getMatSelectPlaceholderName(columnName: string, filterValue: any) {
        return this.matSvc.getMatSelectPlaceholderName(columnName, filterValue);
    }
}
