import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { IContentStates } from './content.component.d';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';
import { LoginService } from 'client/app/services/loginService.service';
import { UserRoleService } from 'client/app/services/userRole.service';

@Component({
    selector: 'argos-content',
    templateUrl: './content.component.html'
})

export class ContentComponent extends ReactComponentBase<{}, IContentStates, {}> implements OnInit {
    constructor(private loginService: LoginService, private userRoleSvc: UserRoleService) {
        super({
            email: '',
            userRole: '',
            displayName: ''
        } as IContentStates);
    }

    async ngOnInit() {
        this.states.email = this.userRoleSvc.getEmailAddress();
        this.states.displayName = this.userRoleSvc.getDisplayName();
        this.states.userRole = await this.userRoleSvc.getUserRoleDisplayName();
        let dbInfo = await this.userRoleSvc.getCurrentDatabaseInfo();

        this.states.databaseName = dbInfo.database;
        this.states.databaseHostName = dbInfo.host;
    }

    async logout() {
        await this.loginService.init();
        this.loginService.logout();
    }
}
