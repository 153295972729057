import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { IModelFeaturesListStates } from './modelFeaturesList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ModelFeaturesListService } from './modelFeaturesList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-modelFeaturesList',
    templateUrl: './modelFeaturesList.component.html',
    providers: [ModelFeaturesListService]
})

export class ModelFeaturesListComponent
    extends ReactComponentBase<{}, IModelFeaturesListStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;

    constructor(private svc: ModelFeaturesListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            table: new MatTableDataSource([]),
            modelFeatures: [],
            filterObj: {},
            matTableColumns: ['edit', 'name', 'title', 'columnSql', 'dependentColumnNames', 'units', 'hidden']
        } as IModelFeaturesListStates);
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.table.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.table.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.table.filter);
    }

    searchFeatures(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.table.filter = filterValue.trim().toLowerCase();
    }

    editRow(row: any) {
        this.states.editingMode = true;
        row.editMode = true;
    }

    saveRow(row: any) {
        this.states.editingMode = false;
        row.editMode = false;
        this.svc.saveRowHandler(row);
    }

    cancelEdit(row: any) {
        this.states.editingMode = false;
        row.editMode = false;
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    pageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        this.states.tableShowRows = this.states.modelFeatures.slice(page, page + event.pageSize);
        this.states.table = new MatTableDataSource(this.states.tableShowRows);
    }
}
