import { Injectable } from '@angular/core';
import { IBuildDetailStates, IBuildDetailService } from './buildDetail.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { UIRouter } from '@uirouter/core';
import swal from 'sweetalert2';
@Injectable()
export class BuildDetailService implements IBuildDetailService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter) {
        //
    }
    async initDelegate(states: IBuildDetailStates): Promise<object> {
        await this.queryUsers(states);
        return {};
    }

    stopBuildHandler(states: IBuildDetailStates) {
        swal({
            title: 'Really?',
            text: 'Are you sure you want to try and stop build run ' + this.uiRouter.globals.params.id + '?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes, stop it!',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const data = await this.dataAccess.genericMethod({
                    model: 'Client', method: 'stopBuild', parameters: { runId: this.uiRouter.globals.params.id }
                });
                states.stopBuildMessage = data.response;
            }
        });
    }

    async queryUsers(states: IBuildDetailStates) {
        const buildDetails = await this.dataAccess.genericFind({
            model: 'PrepareDatabaseRunDetail',
            filter: {
                order: 'id asc',
                where: {
                    prepareDatabaseRunId: this.uiRouter.globals.params.id
                }
            }
        });

        states.data = _.sortBy(buildDetails, ['id']);

        const builds = await this.dataAccess.genericFind({
            model: 'PrepareDatabaseRun',
            filter: {
                include: 'client',
                where: {
                    id: _.toNumber(this.uiRouter.globals.params.id)
                }
            }
        });
        if (builds && builds.length === 1) {
            states.build = builds[0];
            states.build.fullCommand = 'prepare_lds -y ' + states.build.rawCommand;

            states.showStopBuild = (states.build.rawCommand.indexOf('--setrunid') !== -1);
        }
    }
}
