import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ICcgSourceSchemasStates } from './ccgSourceSchemas.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CcgSourceSchemasService } from './ccgSourceSchemas.component.service';

@Component({
    selector: 'argos-ccgSourceSchemas',
    templateUrl: './ccgSourceSchemas.component.html',
    providers: [CcgSourceSchemasService]
})

export class CcgSourceSchemasComponent extends ReactComponentBase<{}, ICcgSourceSchemasStates, {}> implements OnInit {
    constructor(private svc: CcgSourceSchemasService, private cdr: ChangeDetectorRef) {
        super({
            newData: []
        } as unknown as ICcgSourceSchemasStates);
    }

    async ngOnInit() {
        const newStates: any = await this.svc.initDelegate(this.states);
        this.setStates(newStates);
        this.cdr.detectChanges();
    }

    addSourceSchema() {
        this.svc.addSourceSchemaHandler(this.states);
    }

    async save() {
        this.svc.saveHandler(this.states, this.refreshPage);
    }

    refreshPage: Function = () => {
        this.cdr.detectChanges();
    };
}
