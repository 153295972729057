import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { CypressFeatureTestResultDetailService } from './cypressFeatureTestResultDetail.component.service';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ICypressTestResultDetailStates } from './cypressFeatureTestResultDetail.component.d';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'argos-cypressTestResultsRoot',
    templateUrl: './cypressFeatureTestResultDetail.component.html',
    providers: [CypressFeatureTestResultDetailService]
})

export class CypressFeatureTestResultDetail extends ReactComponentBase<{}, ICypressTestResultDetailStates, {}> implements OnInit {

    constructor(private svc: CypressFeatureTestResultDetailService, private cdr: ChangeDetectorRef) {
        super({
            cypressTestResultsDetail: [],
            cypressTestResultsDataTable: new MatTableDataSource([]),
            feature_grouping: '',
            latestRunDate: ''
        } as unknown as ICypressTestResultDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges;
    }

}
