import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IProgramModelListStates, IProgramModelListService, IProgramModel } from './programModelList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class ProgramModelListService implements IProgramModelListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, private cdr: ChangeDetectorRef) {
        //
    }
    async initDelegate(states: IProgramModelListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IProgramModelListStates) {
        const prgModels = await this.dataAccess.genericFind({
            model: 'ProgramModel',
            filter: {
                include: [
                    {
                        relation: 'ccgType',
                        scope: {
                            fields: ['name']
                        }
                    }
                ]
            }
        });

        prgModels.forEach(async (prgModel: IProgramModel) => {
            const total = await this.dataAccess.genericCount({
                model: 'ProgramEpisode',
                where: { programModelId: prgModel.id }
            });
            prgModel.programEpisodeCount = total.count;
            prgModel.ccgTypeName = prgModel.ccgType?.name;
        });

        states.programModels = _.orderBy(prgModels, ['name']);
        states.table.data = states.programModels;
    }

    setTablePageSize(states: IProgramModelListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.programModels.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IProgramModelListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IProgramModelListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

}
