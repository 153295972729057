import { ChangeDetectorRef, Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IReferenceTableUsageModalProps, IReferenceTableUsageModalStates } from './referenceTableUsageModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ReferenceTableUsageModalService } from './referenceTableUsageModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-referenceTableUsageModal',
    templateUrl: './referenceTableUsageModal.component.html',
    styleUrls: ['./referenceTableUsageModal.component.css'],
    providers: [ReferenceTableUsageModalService]
})

export class ReferenceTableUsageModalComponent
    extends ReactComponentBase<IReferenceTableUsageModalProps, IReferenceTableUsageModalStates, {}> implements OnInit {
    constructor(private svc: ReferenceTableUsageModalService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ReferenceTableUsageModalComponent>) {
        super({
            referenceTable: dialogData.props.referenceTable,
            tableColumns: ["usageItem", "metadataType", "usageType", "environmentName", "message", "numUsageItemReferences"],
            referenceTableUsages: [],
            lodash: null
        });
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    closeModal() {
        this.dialogRef.close({});
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }
}
