// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-column-hospitalName {
    white-space: nowrap;
 }
 .mat-column-streetAddressLine1 {
    white-space: nowrap;
 }

 .mat-column-ratesFileUrl {
   white-space: normal;
   word-wrap: break-word;
   max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./client/app/components/hospitalRates/hospitalRatesLandingPage/hospitalRatesLandingPage.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;CACtB;CACA;IACG,mBAAmB;CACtB;;CAEA;GACE,mBAAmB;GACnB,qBAAqB;GACrB,gBAAgB;AACnB","sourcesContent":[".mat-column-hospitalName {\n    white-space: nowrap;\n }\n .mat-column-streetAddressLine1 {\n    white-space: nowrap;\n }\n\n .mat-column-ratesFileUrl {\n   white-space: normal;\n   word-wrap: break-word;\n   max-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
