import { Component, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';
import { UIRouter } from '@uirouter/core';
import { ArgosStoreService } from 'client/app/services/argosStore.service';
import { LoginService } from 'client/app/services/loginService.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'argos-login',
    templateUrl: './login.component.html'
})

export class LoginComponent extends ReactComponentBase<{}, {}, {}> implements OnInit, OnChanges {
    showVpnLink: boolean;
    currentUrl: string;
      //timer with interval
    myInterval: any;
    timerWithInterval: number;
    constructor(private uiRouter: UIRouter,
        private argosStore: ArgosStoreService, private loginService: LoginService, private http: HttpClient, private cdr: ChangeDetectorRef) {
        super();

    }
    async ngOnInit() {
        this.timerWithInterval = 10;
        let urlPath = this.uiRouter.globals.params.saveParams;  // Get the current URL using the router's location service
        if (!urlPath || urlPath === 'undefined') {
            urlPath = '/login';
        }
        this.currentUrl = `https://argos-vpn.clarifyhealth.com${urlPath}`;

        // Save login redirect state so that we retain it through the callback
        if (this.uiRouter.globals.params.saveState) {
            this.argosStore.setItem('saveState', this.uiRouter.globals.params.saveState);
            this.argosStore.setItem('saveParams', this.uiRouter.globals.params.saveParams);
        } else {
            this.argosStore.removeItem('saveState');
            this.argosStore.removeItem('saveParams');
        }
        this.argosStore.removeItem('token');

        const loginState: any = await this.http.get('/isLoginDisabled').toPromise();
        this.showVpnLink = loginState.isDisabled;
        if (!this.showVpnLink) {
            await this.loginService.init();
            this.loginService.authenticate();
        } else {
            this.startTimerWithInterval();
        }
        this.cdr.detectChanges();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.ngOnInit();
    }

    startTimerWithInterval() {
        if (this.myInterval) {
            clearInterval(this.myInterval);
        }

        this.myInterval = setInterval(() => {
            this.onInterval();
            this.cdr.detectChanges();    // refresh ui to show counter

            if (this.timerWithInterval <= 0) {
                clearInterval(this.myInterval);
                this.redirectToArgosVpn();
            }
        }, 1000);
    }

    onInterval() {
        this.timerWithInterval--;
    }


    redirectToArgosVpn() {
        window.location.href = this.currentUrl;
    }
}
