import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IStageSourceTableListStates } from './stageSourceTableList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { StageSourceTableListService } from './stageSourceTableList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-stageSourceTableList',
    templateUrl: './stageSourceTableList.component.html',
    providers: [StageSourceTableListService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('testListSection', [
            state('collapsed', style({height: 'calc(50vh - 115px)'})), 
            state('expanded', style({height: 'calc(100vh - 155px)'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('testListTable', [
            state('collapsed', style({height: 'calc(50vh - 243px)'})), 
            state('expanded', style({height: 'calc(100vh - 243px)'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('triggerDelete', [
            state('deletable', style({color: 'red'})), 
            state('current', style({color: '#4B88D4'})),
            transition('deletable <=> current', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})

export class StageSourceTableListComponent extends ReactComponentBase<{}, IStageSourceTableListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: StageSourceTableListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        
        super({
            stageSourceTable: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['stageName', 'dataSourceAgnosticSchema', 'tableCount', 'testCount'],
            matTableColumns: ['stageName', 'dataSourceAgnosticSchema', 'tableCount', 'testCount'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'stageSourceTableList',
            expandedElements: [],
            headerTestColumns: ['name', 'type', 'level', 'sourceName', 'editButton'],
            headerTable: new MatTableDataSource([]),
            filterSourceId: 'default'
        } as unknown as IStageSourceTableListStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        this.states.headerTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }


    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }


    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    filterList(filterId?: number, filterValue = '', level = '', currentLevel = '', agnosticSchema = true) {
        if (currentLevel === 'stage' && agnosticSchema) {
            level = 'table';
        } else if (currentLevel === 'stage') {
            level = 'source';
        }
        this.svc.filterList(this.states, level, filterValue, filterId, currentLevel, agnosticSchema);
    }

    viewTests(teradromeTableId: any, tableName: any) {
        this.svc.viewTests(this.states, teradromeTableId, tableName);
    }

    filterDataSource(filterValue: string) {
        this.svc.viewTests(this.states, this.states.teradromeTableId, this.states.tableName, filterValue);
    }

    deleteTable(tableId: any) {
        this.svc.deleteTable(this.states, tableId);
    }

    addDescription(row: any) {
        this.svc.addDescription(this.states, row);
    }

    launchTestModal() {
        // Need implement
    }
}
