import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ICareGroupingsListStates, ICareGroupingsListService } from './careGroupingsList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { CONST } from '../../../constants/globals';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewEnvironmentUsageModalComponent } from '../../prismUsage/viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class CareGroupingsListService implements ICareGroupingsListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private exportToExcelSvc: ExportToExcelService,
        private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef) {

    }
    async initDelegate(states: ICareGroupingsListStates): Promise<Object> {
        await this.activate(states);
        return {};
    }

    async activate(states: ICareGroupingsListStates) {
        const careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping'
        });
        states.careGroupings = _.orderBy(careGroupings, ['title']);
        // this.setTablePageSize(states);
        _.forEach(states.careGroupings, ccg => {
            ccg.differentDBOrSchemas =
                this.differentSchema([
                    ccg.settings?.baseTable || '',
                    ccg.settings?.predictionsTable || '',
                    ccg.settings?.explanationsTable || ''
                ]);
            ccg.displayBaseTable = ccg.settings?.baseTable || '';   // used by grid and filter base table
        });
        states.table.data = states.careGroupings;

        const usages = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage',
            method: 'aggregateUsageResults',
            parameters: {
                metaDataTypes: ['careGrouping']
            }
        });

        const markForDeletions = await this.dataAccess.genericFind({
            model: 'MarkForDeletion',
            filter: {
                where: {
                    objectType: 'careGrouping'
                }
            }
        });

        for (const careGrouping of states.table.data) {
            const usage = _.find(usages, { meta_data_name: careGrouping.name });
            careGrouping.usageCount = _.toNumber(_.get(usage, 'total_usage', 0));
            careGrouping.viewCount = _.toNumber(_.get(usage, 'total_views', 0));
            careGrouping.lastViewed = _.get(usage, 'last_viewed');
            careGrouping.excludeFromSyncText = careGrouping.excludeFromSync ? 'Yes' : 'No';
            if (careGrouping.lastViewed) {
                careGrouping.lastViewedFromNow = moment(careGrouping.lastViewed).fromNow();
            } else {
                careGrouping.lastViewedFromNow = '-';
                // set these to sort last
                careGrouping.lastViewed = '';
            }
            const markForDeletion: any = _.find(markForDeletions, { objectName: careGrouping.name });
            if (markForDeletion) {
                careGrouping.markForDeletionId = markForDeletion.id;
            }
        }

        await this.pollBulkStoreStatus(states);
    }

    differentSchema(tableList: string[]): boolean {
        let isDifferentSchema = false;
        const tableListCopy = _.filter(_.clone(tableList), tableString => {
            return tableString !== '';
        });
        if (tableListCopy.length > 0) {
            isDifferentSchema = _.some(tableListCopy, tableString => {
                return _.split(tableString, '.')[0] !== _.split(tableListCopy[0], '.')[0];
            });
        }

        return isDifferentSchema;
    }

    setTablePageSize(states: ICareGroupingsListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.careGroupings.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    async bulkStoreMetaData(states: ICareGroupingsListStates) {
        const request = _.map(states.careGroupings, function (careGrouping) {
            const baseTable = _.get(careGrouping, ['settings', CONST.CCG_BASE_TABLE_NAME]);
            const startDateColumn = _.get(careGrouping, ['settings', CONST.CCG_START_DATE_COLUMN_NAME]);
            const endDateColumn = _.get(careGrouping, ['settings', CONST.CCG_END_DATE_COLUMN_NAME]);
            const name = _.get(careGrouping, ['name']);
            const threshold = _.get(careGrouping, ['settings', CONST.CG_DATE_QUERY_THRESHOLD]);

            return {
                baseTable,
                startDateColumn,
                endDateColumn,
                name,
                threshold
            };
        });

        states.fetchingMetaData = true;

        await this.dataAccess.genericMethod({
            model: 'CareGrouping', method: 'findById', parameters: {
                id: 'bulkStoreMetaData',
                request
            }
        });
        await this.pollBulkStoreStatus(states);
    }

    async pollBulkStoreStatus(states: ICareGroupingsListStates) {
        const bulkStoreMetaData = await this.dataAccess.genericMethod({
            model: 'MetaDataSync', method: 'findById', parameters: {
                id: 'bulkStoreMetaData'
            }
        });

        if (_.get(bulkStoreMetaData, 'syncStatus') === 'Syncing') {
            states.fetchingMetaData = true;
        } else {
            states.fetchingMetaData = false;
        }
    }

    viewEnvironments(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    metaDataTypes: ['careGrouping'],
                    metaDataNames: [row.name]
                }
            }
        };
        this.matDialog.open(ViewEnvironmentUsageModalComponent, dialogConfig);
    }

    exportToExcel(states: ICareGroupingsListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile('careGroupings', columns, data);
    }

    markForDeletion(row: any) {
        swal({
            title: 'Are you sure?',
            text: `Do you want to delete care grouping ${row.name}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const newRow = await this.dataAccess.genericUpsert({
                    model: 'MarkForDeletion',
                    data: {
                        objectType: 'careGrouping',
                        objectName: row.name,
                        objectDefinition: row,
                        deletedBy: this.argosStore.getItem('username'),
                        deletedDate: new Date()
                    }
                });
                row.markForDeletionId = newRow.id;
                this.cdr.detectChanges();
            }
        });
    }

    async undelete(row: any) {
        if (row && row.markForDeletionId) {
            await this.dataAccess.genericMethod({
                model: 'MarkForDeletion',
                method: 'destroyById',
                parameters: {
                    id: row.markForDeletionId
                }
            });
            delete row.markForDeletionId;
            this.cdr.detectChanges();
        }
    }

    openMatTableSettings(states: ICareGroupingsListStates) {
        const data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.environmentMatTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
