import { Component } from '@angular/core';

@Component({
    selector: 'ng-progress',
    templateUrl: './progressSpinner.directive.html'
})

export class ProgressSpinnerDirective {

}
