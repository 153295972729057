import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IClientListStates, IClientListService } from './clientList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { LoginService } from 'client/app/services/loginService.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class ClientListService implements IClientListService {
    constructor(private dataAccess: NgDataAccess, private loginService: LoginService, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, private cdr: ChangeDetectorRef) {
        //
    }
    async initDelegate(states: IClientListStates): Promise<Object> {
        const clients = await this.dataAccess.genericFind({
            model: 'Client',
            filter: { include: 'environments' }
        });
        states.clients = _.orderBy(clients, ['name']);

        for (var index of states.clients) {
            let activeEnvironmentCount = 0;
            let environmentsUrl = '';
            for (var index2 of index.environments) {
                if (index2.isDisabled == false && index2.isArchived == false) {
                    activeEnvironmentCount++
                }
                environmentsUrl = environmentsUrl + index2.dnsCname + '.clarifyhealth.' + index2.domainSuffix + ', ';
            }
            
            index.activeEnvironmentCount = activeEnvironmentCount;
            index.environmentsUrl = environmentsUrl.substring(0, environmentsUrl.length - 2);
            index.assignedOwnerEmailString = _.get(index.assignedOwnerEmail, 'email', '');
        }
        states.table.data = states.clients;

        return {};
    }

    setTablePageSize(states: IClientListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.clients.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IClientListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        if (columns.includes('environments')) {
            let environmentsAll = "";
            for (var index of data)   {
                for (var index2 of index.environments) {
                    environmentsAll = environmentsAll + 'https://' + index2.dnsCname + '.clarifyhealth.' + index2.domainSuffix + ' ';
                }
                index.environments = environmentsAll;
            }
        }

        this.exportToExcelSvc.exportToFile('client', columns, data);
    }

    openMatTableSettings(states: IClientListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
