import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IEditRestrictedGroupingsModalProps, IEditRestrictedGroupingsModalStates } from './editRestrictedGroupingsModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EditRestrictedGroupingsModalService } from './editRestrictedGroupingsModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'argos-editRestrictedGroupingsModal',
    templateUrl: './editRestrictedGroupingsModal.component.html',
    providers: [EditRestrictedGroupingsModalService]
})

export class EditRestrictedGroupingsModalComponent
    extends ReactComponentBase<IEditRestrictedGroupingsModalProps, IEditRestrictedGroupingsModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: EditRestrictedGroupingsModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<EditRestrictedGroupingsModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    cancel() {
        this.dialogRef.close('cancel');
    }

    save() {
        this.dialogRef.close({
            restrictedGroupings: _.map(_.filter(this.states.episodeGroupings, { selected: true }), 'groupByAttribute')
        });
    }

    selectAll(selected?: boolean) {
        _.forEach(this.states.episodeGroupings, (eg) => {
            eg.selected = !!selected;
        });
    }
}
