import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { DataDashboardServices } from './dataDashboard.component.service';
import { IDataDashboardStates } from './dataDashboard.component.d';
import { Subject } from 'rxjs';
import { DataDashLegendModalComponent } from '../legendModal/dataDashLegendModal.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'argos-dataDashboard',
    templateUrl: './dataDashboard.component.html',
    styleUrls: ['./dataDashboard.component.less'],
    providers: [DataDashboardServices]
})

export class DataDashboardComponent extends ReactComponentBase<{}, IDataDashboardStates, {}>
    implements OnInit {

    update$: Subject<boolean> = new Subject();
    dataSource: string;
    constructor(private svc: DataDashboardServices, private cdr: ChangeDetectorRef, private matDialog: MatDialog ) {
        super();
        this.states.stageOrder = ['pre_raw', 'raw', 'abstract', 'enriched', 'ccg', 'predictions', 'predictions_combiner', 'post_predictions'];
    }

    async ngOnInit() {
        const dataSources = await this.getDataSourcesDelegate();
        this.states.dataSources = dataSources.sort();
        if (!this.dataSource) {
            // Set dataSource to the first item if not provided
            if (dataSources.length > 1 && dataSources[0] === 'all') {
                // skip the all data source for the first load
                this.dataSource = dataSources[1];
            } else {
                this.dataSource = dataSources[0];
            }
        }
        await this.svc.init(this.states, this.dataSource, dataSources);
        this.cdr.detectChanges();
    }

    async getDataSourcesDelegate() {
        return await this.svc.getDataSources();
    }

    async refreshDataSource(dataSource: string, dataSources: string[]) {
        this.states.clusters = undefined;
        this.states.nodes = undefined;
        this.states.edges = undefined;
        await this.svc.initDelegate(this.states, dataSource, dataSources);
        this.cdr.detectChanges();
    }

    openLegend() {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(DataDashLegendModalComponent, dialogConfig);
    }
}
