import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { IErrorMapStates } from './pipelineErrorMap.component.d';
import { PipelineErrorMapService } from './pipelineErrorMap.component.service';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';

@Component({
    selector: 'argos-pipelineErrorMap',
    templateUrl: './pipelineErrorMap.component.html',
    providers: [PipelineErrorMapService]
})

export class PipelineErrorMapComponent extends ReactComponentBase<{}, IErrorMapStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: PipelineErrorMapService, private cdr: ChangeDetectorRef) {
        super({
            allErrorsDetail: [],
            allErrors: new MatTableDataSource([]),
            newErrorMap: {}
        } as unknown as IErrorMapStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    async addNewErrorMap() {
        await this.svc.addErrorMap(this.states);
    }

    ngAfterViewInit() {
        this.states.allErrors.sort = this.matTbSort;
    }

}
