import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IBranchNameModalProps, IBranchNameModalStates, IBranchNameModalService } from './automatedGoldPulldownBranchInfo.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from 'client/app/services/dataAccess.service';
import { ArgosStoreService } from 'client/app/services/argosStore.service';
import { AutomatedGoldPulldownListService } from '../automatedGoldPulldownList.component.service';

@Injectable()
export class AutomatedGoldPulldownBranchInfoService implements IBranchNameModalService {
    constructor(private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService, private pulldownListService: AutomatedGoldPulldownListService) {
        //
    }

    async initDelegate(props: IBranchNameModalProps, states: IBranchNameModalStates): Promise<object> {
        await this.init(states, props);
        return {};
    }

    changeDelegate(oldProps: IBranchNameModalProps, newProps: IBranchNameModalProps, states: IBranchNameModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    async init(states: IBranchNameModalStates, props: IBranchNameModalProps) {
        states.branchInfo = _.cloneDeep(_.isEmpty(props.content) ? states.branchInfo : props.content);
        states.logsText = _.get(props, 'states.logsText', '');
        const result = await this.dataAccess.genericMethod({
            model: 'AutomatedPulldown',
            method: 'getGithubBranches',
            parameters: {}
        });
        states.releaseBranches = _.map(result, (res) => { return { baseBranch: res }; });
        states.releaseBranches.push({ baseBranch: 'master' });
        states.manual = true;
        this.cdr.detectChanges();
        return states;
    }

    async fetchPulldownFiles(states: IBranchNameModalStates, cdr: ChangeDetectorRef) {
        states.loading = true;
        const runId = Math.floor(new Date().getTime() / 1000);
        const logData = { runId, buildLog: runId + '-extracted-files.out' };

        try {
            const result = await this.dataAccess.genericMethod({
                model: 'AutomatedPulldown',
                method: 'fetchManualBranchFileStatus',
                parameters: {
                    runId: logData.runId,
                    metaDataTypeString: states.metaDataTypeString,
                    buildLog: logData.buildLog,
                    baseBranch: states.branchInfo.baseBranch
                }
            });
        } catch (error) {
            console.log(error);
        }

        let fileReadyCounter = 0;
        states.checkPullDownStatusTimer = window.setInterval(async () => {
            const status = await this.dataAccess.genericMethod({
                model: 'AutomatedPulldown',
                method: 'getExtractedFiles',
                parameters: {
                    runId: logData.runId,
                    buildLog: logData.buildLog
                }
            });
            fileReadyCounter++;
            if (!_.isEmpty(status)) {
                window.clearInterval(states.checkPullDownStatusTimer);
                states.loading = false;
                states.pulldown.availableFormattedFiles = _.cloneDeep(this.formatFiles(status.git_status.changes));
                states.pulldown.originalFiles = _.cloneDeep(status.git_status.changes);
                cdr.detectChanges();
            }
        }, 5000);

        if (fileReadyCounter > 40) {
            window.clearInterval(states.checkPullDownStatusTimer);
            states.loading = false;
            cdr.detectChanges();
        }
        return states;
    }

    formatFiles(files: string[]) {
        const formattedFiles: any = {
            dashboardModules:  {
                parentModules: [],
                childModules: []
            }
        };
        _(files).filter((file) => { return _.includes(file, 'data/');}).forEach(item => {
            const metaType = item.split('/')[1];
            if (metaType === 'dashboardModules') {
                if(item.split('/')[2] === 'parentModules') {
                    formattedFiles.dashboardModules.parentModules.push({ filePath: `${item}`, fileName:  item.split('/')[3] });
                } else {
                    formattedFiles.dashboardModules.childModules.push({ filePath: `${item}`, fileName:  item.split('/')[3] });
                }
            } else {
                if (!formattedFiles[`${metaType}`]) {
                    formattedFiles[`${metaType}`] = [];
                }
                formattedFiles[`${metaType}`].push({ filePath: `${item}`, fileName: item.split('/')[2] });
            }
        });
        return formattedFiles;
    }

    generateFilePathsForGithub(states: IBranchNameModalStates, cdr: ChangeDetectorRef) {
        const selectedFiles = _.cloneDeep(states.pulldown.selectedFiles);
        states.selectedFilesForGit = _.compact([..._.map(_.values(_.get(selectedFiles, 'dashboardModules.parentModules', [])), 'filePath'), ..._.map(_.values(_.get(selectedFiles, 'dashboardModules.childModules', [])), 'filePath'), ..._(selectedFiles).values().flatMap().map('filePath').value()]);
        cdr.detectChanges();
    }
}
