import { Injectable } from '@angular/core';
import { IReferenceTableUsageModalProps, IReferenceTableUsageModalStates, IReferenceTableUsageModalService } from './referenceTableUsageModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import * as _ from 'lodash';

@Injectable()
export class ReferenceTableUsageModalService implements IReferenceTableUsageModalService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService) {
    }

    async initDelegate(states: IReferenceTableUsageModalStates): Promise<object> {

        const referenceTableUsagesDb = await this.dataAccess.genericMethod({
            model: 'TableUsage', method: 'getTableUsage', parameters: {
                table: states.referenceTable
            }
        });
        const referenceTablesUsagesGit = await this.dataAccess.genericMethod({
            model: 'Teradrome', method: 'findTextInGitRepo', parameters: {
                reposToSearch: ['teradrome', 'tera_catalog', 'reference_data', 'tera_tests', 'argos', 'clarify-platform'],
                textToFind: states.referenceTable
            }
        });
        states.referenceTableUsages = referenceTableUsagesDb.concat(referenceTablesUsagesGit);
        states.lodash = _;

        return {};
    }

    exportToExcel(states: IReferenceTableUsageModalStates) {

        const data = states.referenceTableUsages;
        const columns = states.tableColumns;

        this.exportToExcelSvc.exportToFile(`${states.referenceTable}_usage`, columns, data);
    }
}
