import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IMigrateCareGroupingModalProps, IMigrateCareGroupingModalStates } from './migrateCareGroupingModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MigrateCareGroupingModalService } from './migrateCareGroupingModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'argos-migrateCareGroupingModal',
    templateUrl: './migrateCareGroupingModal.component.html',
    providers: [MigrateCareGroupingModalService]
})

export class MigrateCareGroupingModalComponent
    extends ReactComponentBase<IMigrateCareGroupingModalProps, IMigrateCareGroupingModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: MigrateCareGroupingModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<MigrateCareGroupingModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close();
    }

    save() {
        this.svc.saveHandler(this.states);
        this.closeModal();
    }

    careGroupingChanged() {
        this.svc.careGroupingChangedHandler(this.states);
    }

    findCareGrouping() {
        this.svc.findCareGroupingHandler(this.states);
    }
}
