import { Injectable } from '@angular/core';
import { INewEnvironmentRequestStates, INewEnvironmentRequestService } from './newEnvironmentRequest.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { EnvironmentService } from '../../../services/environmentService.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import { AlertService } from 'client/app/services/alert.service';
@Injectable()
export class NewEnvironmentRequestService implements INewEnvironmentRequestService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService,
        private state: StateService, private uiRouter: UIRouter,
        private environmentService: EnvironmentService, private alertSvc: AlertService) {

    }

    async initDelegate(states: INewEnvironmentRequestStates): Promise<object> {
        states.regions = this.environmentService.regions;
        await this.activate(states);
        return {};
    }

    async activate(states: INewEnvironmentRequestStates) {
        const rowChecked = this.rowChecked;
        const hospitalSystemNames = await this.dataAccess.genericFind({
            model: 'HospitalSystemSize'
        });
        states.hospitalSystemNames = _.orderBy(hospitalSystemNames, ['systemName']);

        const episodeTypes = await this.dataAccess.genericFind({
            model: 'EpisodeType'
        });
        states.episodeTypes = _.orderBy(episodeTypes, ['group', 'name']);

        if (this.uiRouter.globals.params.id) {
            states.screenTitle = 'Edit Environment Request';
            states.saveButton = 'Save Request';
            const request = await this.dataAccess.genericFind({
                model: 'EnvironmentRequest',
                filter: {
                    where: {
                        id: _.toNumber(this.uiRouter.globals.params.id)
                    }
                }
            });
            if (request && request.length === 1) {
                states.newRequest = request[0];
                states.newRequest.needByDate = new Date(states.newRequest.needByDate);
                if (states.newRequest.expirationDate) {
                    states.newRequest.expirationDate = new Date(states.newRequest.expirationDate);
                }

                for (let i = 0; i < states.filterProperties.length; i++) {
                    const prop = states.filterProperties[i];
                    const setting = _.get(states, 'newRequest.entityFilter.' + prop);
                    if (setting && setting.length > 0) {
                        states.hospitals['hospital_' + prop] = _.join(_.map(setting), ', ');
                    }
                }

                const drgs = _.get(states, 'newRequest.drgs');
                if (drgs && drgs.length > 0) {
                    drgs.forEach(function (drg: any) {
                        const episodeTypeRow = _.find(states.episodeTypes, function (et) {
                            return _.includes(et.drg, drg);
                        });
                        if (episodeTypeRow) {
                            episodeTypeRow.selected = true;
                            rowChecked(episodeTypeRow, states);
                        }
                    });
                }
            }
        } else {
            states.screenTitle = 'New Environment Request';
            states.saveButton = 'Request';
            const username = this.argosStore.getItem('username');

            states.newRequest = {
                status: 'In Progress',
                requester: username
            };
        }
    }

    selectGroupHandler(group: any, states: INewEnvironmentRequestStates) {
        const rowChecked = this.rowChecked;
        states.episodeTypes.forEach(function (row: any) {
            if (row.group === group) {
                row.selected = true;
                rowChecked(row, states);
            }
        });
    }

    rowChecked: Function = (row: any, states: INewEnvironmentRequestStates) => {
        if (row.selected) {
            if (!_.find(states.selectedEpisodes, { name: row.name })) {
                states.selectedEpisodes.push(row);
                states.selectedEpisodes = _.orderBy(states.selectedEpisodes, ['group', 'name']);
            }
        } else {
            const index = _.findIndex(states.selectedEpisodes, { name: row.name });
            if (index >= 0) {
                states.selectedEpisodes.splice(index, 1);
            }
        }
    };

    isLEJRSelectedHandler(states: INewEnvironmentRequestStates) {
        const lejrSelected = _.find(states.selectedEpisodes, function (et) {
            return _.includes(et.drg, '470');
        });
        if (!lejrSelected && states.newRequest) {
            states.newRequest.cjr = false;
        }
        return lejrSelected;
    }

    removeValueHandler(row: any, states: INewEnvironmentRequestStates) {
        row.selected = false;
        this.rowChecked(row, states);
    }

    async requestEnvironmentHandler(states: INewEnvironmentRequestStates) {

        for (let i = 0; i < states.filterProperties.length; i++) {
            const prop = states.filterProperties[i];

            if (states.hospitals['hospital_' + prop]) {
                _.set(states.newRequest, 'entityFilter.' + prop, _.map(_.split(states.hospitals['hospital_' + prop], ','), function (o) {
                    return _.trim(o);
                }));
            }
        }

        states.newRequest.drgs = [].concat.apply([], _.map(states.selectedEpisodes, 'drg'));
        states.newRequest.requestDate = new Date();
        try {
            const data = states.newRequest;
            await this.dataAccess.genericUpsert({
                model: 'EnvironmentRequest',
                data
            });
            this.state.go('argos.environments.environmentRequests.list');
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    cancel() {
        this.state.go('argos.environments.environmentRequests.list');
    }
}
