import { Injectable } from '@angular/core';
import { IProductAddEditStates, IProductAddEditService, IProductModel } from './productAddEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';

@Injectable()
export class ProductAddEditService implements IProductAddEditService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService) {
        //
    }
    async initDelegate(states: IProductAddEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IProductAddEditStates) {
        switch (this.uiRouter.globals.current.name) {
            case 'argos.prism.product.add':
                states.mode = 'ADD';
                break;
            case 'argos.prism.product.edit':
            default:
                states.mode = 'EDIT';
                break;
        }

        const id = this.uiRouter.globals.params.id;
        const product: IProductModel = {
            config: {
                content: {
                    'menu': [],
                    'Cohort': [],
                    'Benchmark': [],
                    'Cohort Group': [],
                    'Benchmark Group': [],
                    'Filter Set': [],
                    'Dashboard': [],
                    'User Role': [],
                    'Spotlight Group': [],
                    'Scorecard Formula': [],
                    'Scorecard Template': [],
                    'HIPAA': []
                },
                careGroupings: [],
                productBundles: []
            }
        }
            ;
        let careGroupings = undefined;
        let productBundles = undefined;
        let productContentData = undefined;
        if (id) {
            _.merge(product, await this.dataAccess.genericMethod({
                model: 'ProductConfiguration', method: 'findOne', parameters: {
                    filter: {
                        where: {
                            id: this.uiRouter.globals.params.id
                        }
                    }
                }
            }));
        }
        careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { order: 'title', where: { assignable: true } }
        });
        productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle',
            filter: { order: 'title' }
        });
        productContentData = await this.dataAccess.genericFind({
            model: 'ProductConfigurationContent'
        });

        const productContent = _.groupBy(productContentData, 'contentType');
        const productContentTypes = _.filter(_.keysIn(productContent), function (key) {
            return key !== 'Product' && key !== 'Module' && key !== 'Submodule';
        });
        const productDefinition = this.getProductDefinition(productContentData, states);

        _.set(states, ['enabled', 'config'], this.generateEnabled(product.config));

        if (states.mode === 'ADD') {
            _.unset(product, 'id');
            const name = _.get(product, 'name');
            if (name) {
                _.set(product, 'name', name + ' copy');
            }
        }
        this.initProductBundles(product, productBundles, states);
        this.initProductContent(product, productContent, states);

        states.product = product;
        states.careGroupings = careGroupings;
        states.productBundles = productBundles;
        states.productContent = productContent;
        states.productContentTypes = productContentTypes;
        states.productDefinition = productDefinition;
        states.submoduleNames = _.chain(states.productDefinition)
            .flatMap(function (prodDef) {
                return prodDef.menus;
            }).flatMap(function (menu) {
                return menu.items;
            }).map(function (item) {
                return item.name;
            }).value();
    }

    generateEnabled(productConfig: any) {
        const tranformFunc = function (acc: any, val: any, key: any, obj: any) {
            if (_.isArray(val)) {
                acc[key] = _.reduce(val, function (acc: any, val: any) {
                    acc[val] = true;
                    return acc;
                }, {});
            } else if (_.isObjectLike(val)) {
                acc[key] = _.transform(val, tranformFunc, {});
            } else {
                return acc;
            }
        };

        return _.transform(productConfig, tranformFunc, {});
    }

    getProductDefinition(productConfigurationContent: any, states: IProductAddEditStates) {
        const products = _.chain(productConfigurationContent)
            .filter({ contentType: 'Product' })
            .map(function (product: any) {
                const modules = _.chain(productConfigurationContent)
                    .filter({ contentType: 'Module' })
                    .filter(function (module: any) {
                        return _.startsWith(module.idOrName, product.idOrName + '.');
                    }).map(function (module: any) {
                        const name = _.last(_.split(module.idOrName, '.'));
                        const submodules = _.chain(productConfigurationContent)
                            .filter({ contentType: 'Submodule' })
                            .filter(function (submodule: any) {
                                return _.startsWith(submodule.idOrName, product.idOrName + '.' + name);
                            }).map(function (submodule: any) {
                                return {
                                    name: _.last(_.split(submodule.idOrName, '.')),
                                    type: submodule.contentType,
                                    pageTitle: submodule.displayName
                                };
                            }).value();
                        return {
                            name,
                            type: module.contentType,
                            title: module.displayName,
                            items: submodules
                        };
                    }).value();
                return {
                    name: product.idOrName,
                    type: product.contentType,
                    title: product.displayName,
                    menus: modules
                };
            }).value();
        return products;
    }

    checkboxChecked(id: any, path: any, states: IProductAddEditStates) {
        const ePath = _.concat(path, id);
        const currentConfig = _.get(states.product, path) || [];
        if (!_.get(states.enabled, ePath)) {
            _.set(states.product, path, _.reject(currentConfig, function (item) {
                return item === id;
            }));
            _.set(states.enabled, ePath, false);
        } else {
            currentConfig.push(id);
            _.set(states.product, path, _.orderBy(currentConfig, _.identity, 'asc'));
            _.set(states.enabled, ePath, true);
        }
    }

    selectAllCheckboxes(states: IProductAddEditStates, path: any, values?: any,
        mapField?: any) {
        values = values || [];
        if (mapField) {
            values = _.map(values, mapField);
        }
        _.set(states.product, path, values);
        const valObj = _.reduce(values, function (obj: any, val) {
            obj[val] = true;
            return obj;
        }, {});
        _.set(states.enabled, path, valObj);
    }

    initProductBundles(product: any, productBundles: any, states: IProductAddEditStates) {
        _.forEach(_.get(product, 'config.productBundles'), function (shortName) {
            const productBundle: any = _.find(productBundles, { shortName });
            _.set(productBundle, 'enabled', true);
        });
    }

    initProductContent(product: any, productContent: any, states: IProductAddEditStates) {
        _.forEach(_.keysIn(_.get(product, 'config.content')), function (contentType) {
            _.forEach(product.config.content[contentType], function (contentIdOrName) {
                const pc: any = _.find(productContent[contentType], { idOrName: contentIdOrName, contentType });
                if (pc) {
                    pc.enabled = true;
                }
            });
        });
    }

    updateProductBundle(productBundle: any, states: IProductAddEditStates) {
        const shortNames = _.get(states.product, 'config.productBundles') || [];
        const shortName = _.find(states.productBundles, productBundle.shortName);
        if (productBundle.enabled) {
            if (!shortName) {
                shortNames.push(shortName);
            }
        } else {
            if (shortName) {
                _.remove(shortNames, function (sn) {
                    return sn === shortName;
                });
            }
        }

        _.set(states.product, ['config', 'productBundles'],
            _.map(_.filter(states.productBundles, { enabled: true }), 'shortName')
        );
    }

    updateOtherContent(contentType: any, content: any, states: IProductAddEditStates) {
        const productContent = _.get(states.product, ['config', 'content'], {}) || {};

        const enabledContent = productContent[contentType] || [];
        const foundContent = _.find(enabledContent, function (enCont) {
            return enCont === content.idOrName;
        });
        if (content.enabled && !foundContent) {
            enabledContent.push(content.idOrName);
        } else {
            _.remove(enabledContent, function (enCont) {
                return enCont === content.idOrName;
            });
        }
        productContent[contentType] = enabledContent;

        _.set(states.product, ['config', 'content'], productContent);
    }

    async saveHandler(states: IProductAddEditStates, andClose?: boolean) {
        if (states.saveInProgress) {
            return;
        }
        states.saveInProgress = true;
        this.prepForSaving(states);
        try {
            const data = states.product;
            const product = await this.dataAccess.genericUpsert({
                model: 'ProductConfiguration',
                data
            });
            states.product.id = product.id;
            if (andClose) {
                this.state.go('argos.prism.product.list');
            } else if (states.mode === 'ADD') {
                this.state.go('argos.prism.product.edit', { id: product.id });
            }
            states.saveInProgress = false;
        } catch (error) {
            console.log(error);
            states.saveInProgress = false;
        }
    }

    prepForSaving(states: IProductAddEditStates) {
        states.product.config = _.isObject(states.product.config) ? states.product.config : {};
    }

}
