// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  display: flex;
  align-items: flex-start;
  /* Align items at the start of the flex container */
}
.example-full-width {
  width: 50%;
  /* Adjust width of the input field */
}
.btn-add {
  margin-top: 1rem;
  margin-left: 2rem;
  /* Adjust margin between the input field and the button */
}
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  /* semi-transparent white background */
  z-index: 9999;
  /* ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  /* Change spinner color here */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.bold-label {
  font-weight: bold;
  color: black;
}
.inline-form-group {
  display: block;
}
.inline-input {
  display: inline-block;
  width: calc(100% - 150px);
  /* Adjust the width as needed */
  margin-left: 10px;
  /* Adjust the spacing between label and input */
}
`, "",{"version":3,"sources":["webpack://./client/app/components/dataReleaseManager/runDataRelease/runDataRelease.component.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mDAAmD;AACrD;AACA;EACE,UAAA;EACA,oCAAoC;AACtC;AACA;EACE,gBAAA;EACA,iBAAA;EACA,yDAAyD;AAC3D;AACA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,sCAAsC;EAAtC,aAAA;EAEA,oCAAoC;EADpC,aAAA;EACA,uBAAA;EACA,mBAAA;AAGF;AAAA;EACE,oCAAA;EACA,uBAAA;EAEA,8BAA8B;EAD9B,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;AAGF;AAAA;EACE;IAAK,yBAAA;EAGL;AACF;AADA;EACE,iBAAA;EACA,YAAA;AAGF;AAAA;EACE,cAAA;AAEF;AACA;EACE,qBAAA;EACA,yBAAA;EACA,+BAA+B;EAA/B,iBAAA;EAEA,+CAA+C;AACjD","sourcesContent":[".form-container {\n  display: flex;\n  align-items: flex-start; /* Align items at the start of the flex container */\n}\n\n.example-full-width {\n  width: 50%; /* Adjust width of the input field */\n}\n\n.btn-add {\n  margin-top: 1rem;\n  margin-left: 2rem; /* Adjust margin between the input field and the button */\n}\n\n.spinner-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 255, 255, 0.5); /* semi-transparent white background */\n  z-index: 9999; /* ensure it's above other content */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.spinner {\n  border: 4px solid rgba(0, 0, 0, 0.1);\n  border-left-color: #333; /* Change spinner color here */\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  to { transform: rotate(360deg); }\n}\n\n.bold-label {\n  font-weight: bold;\n  color: black;\n}\n\n.inline-form-group {\n  display: block;\n}\n\n.inline-input {\n  display: inline-block;\n  width: calc(100% - 150px); /* Adjust the width as needed */\n  margin-left: 10px; /* Adjust the spacing between label and input */\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
