import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IProgramModelEditStates } from './programModelEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ProgramModelEditService } from './programModelEdit.component.service';

@Component({
    selector: 'argos-programModelEdit',
    templateUrl: './programModelEdit.component.html',
    providers: [ProgramModelEditService]
})

export class ProgramModelEditComponent extends ReactComponentBase<{}, IProgramModelEditStates, {}> implements OnInit {
    constructor(private svc: ProgramModelEditService, private cdr: ChangeDetectorRef) {
        super({
            programModel: {},
            CCGList: [],
            programModelNames: [],
            programModelNameExists: false
        } as unknown as IProgramModelEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    async save() {
        await this.svc.saveHandler(this.states);
    }

    handleValueReferenceListTagIdChange(index: number) {
        this.states.programModel.programSettings[index].valueReferenceListTagId = null;
    }

    programModelNameChange() {
        this.states.programModelNameExists = this.states.programModelNames.includes(this.states.programModel.name);
    }
}
