import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewReferenceListHistoryModalProps, IViewReferenceListHistoryModalStates } from './viewReferenceListHistoryModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewReferenceListHistoryModalService } from './viewReferenceListHistoryModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-viewReferenceListHistoryModal',
    templateUrl: './viewReferenceListHistoryModal.component.html',
    providers: [ViewReferenceListHistoryModalService]
})

export class ViewReferenceListHistoryModalComponent extends ReactComponentBase<IViewReferenceListHistoryModalProps, IViewReferenceListHistoryModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewReferenceListHistoryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewReferenceListHistoryModalComponent>) {
        super({
            showDiffs: false,
            compareHistory: [],
            previousVersion: '',
            observation: {}
        } as unknown as IViewReferenceListHistoryModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close();
    }

    showDetail(version: any) {
        this.svc.showDetail(version, this.states);
    }

    filterHistory() {
        this.svc.filterHistory(this.states);
    }

}
