import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ICcgReferenceDataListStates, ICcgReferenceDataListService } from './ccgReferenceDataList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { ExportToExcelService } from '../../../services/exportToExcel.service';


@Injectable()
export class CcgReferenceDataListService implements ICcgReferenceDataListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService) {
        //
    }
    async initDelegate(states: ICcgReferenceDataListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: ICcgReferenceDataListStates) {

        const ccgReferenceTables = await this.dataAccess.genericFind({
            model: 'CCGReferenceTableName',
            filter: { 
                include: {
                    relation: 'referenceData',
                    scope: {
                        include: {
                            relation: 'referenceDataClass',
                            scope: {
                                include: 'teraUser'
                            
                            }
                        }
                    },
                    order: 'referenceTable ASC' 
                }
            }
        });

        states.ccgReferenceTables = ccgReferenceTables.map((table: any) => {
            if (table.referenceData && table.referenceData.referenceDataClass && table.referenceData.referenceDataClass.teraUser) {
                table.owner = table.referenceData.referenceDataClass.teraUser.ownerName;
            } else {
                table.owner = 'Unknown';
            }
            return table;
        });

        states.matTable.data = states.ccgReferenceTables;
    }

    openMatTableSettings(states: ICcgReferenceDataListStates) {
        const data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    async addTable(states: ICcgReferenceDataListStates) {
        swal({
            title: 'Loading...',
            text: 'Fetching reference tables, please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: () => {
                swal.showLoading();
            }
        });
        
        // Fetch the data asynchronously
        const response = await this.dataAccess.genericMethod({
            model: 'EpisodeGrouping',
            method: 'getReferenceTables',
            parameters: {}
        });
        
        // Remove existing tables from response.result
        const newTables: any = _.difference(response.result, states.ccgReferenceTables.map((table: any) => table.referenceTable));
        
        // Close the loading modal
        swal.close();

        swal({
            title: 'Add Reference Table',
            text: 'Select a table to load in the CCG Engine.',
            input: 'select',
            inputOptions: newTables,
            inputPlaceholder: 'Select a table',
            showCancelButton: true,
            confirmButtonText: 'Add',
            cancelButtonText: 'Cancel'
        }).then(async (result: any) => {
            if (result.value) {
                const referenceTable = newTables[result.value];

                const referenceData = await this.dataAccess.genericFind({
                    model: 'ReferenceData',
                    filter: {
                        where: {
                            tableName: referenceTable
                        }
                    }
                });

                const newTable: any = { referenceTable };
                if (referenceData.length > 0) {
                    newTable.referenceDataId = referenceData[0].id;
                }

                await this.dataAccess.genericCreate({
                    model: 'CCGReferenceTableName',
                    data: newTable
                });

                await this.activate(states);
                this.cdr.detectChanges();
            }
        });
    }

    async deleteTable(row: any, states: ICcgReferenceDataListStates) {
        swal({
            title: 'Confirm Reference Table Deletion',
            text: `Are you sure you want to delete ${row.referenceTable}?`,
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                await this.dataAccess.genericDelete({
                    model: 'CCGReferenceTableName',
                    id: row.id
                });

                states.ccgReferenceTables = states.ccgReferenceTables.filter((table: any) => table.id !== row.id);
                states.matTable.data = states.ccgReferenceTables;
                this.cdr.detectChanges();
            }
        });
    }

    exportToExcel(states: ICcgReferenceDataListStates) {

        const data = states.matTable.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }
}
