import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IHospitalRatesAddHospitalModalService, IHospitalRatesAddHospitalModalStates, IHospitalRatesAddHospitalModalProps } from '../hospitalRatesAddHospitalModal/hospitalRatesAddHospitalModal.component.d';
import { UtilsService } from '../../../services/utils.service';
import { ArgosStoreService } from '../../../services/argosStore.service';

@Injectable()
export class HospitalRatesAddHospitalModalServices implements IHospitalRatesAddHospitalModalService {

    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService, private utilsSvc: UtilsService) {
    }

    async initDelegate(props:IHospitalRatesAddHospitalModalProps, states: IHospitalRatesAddHospitalModalStates): Promise<object> {
        await this.init(props, states);
        return states;
    };

    async init(props: IHospitalRatesAddHospitalModalProps, states: IHospitalRatesAddHospitalModalStates) {
        if (props.isEdit) {
            states.id = props.row.id;
            states.priority = props.row.priority;
            states.hospitalName = props.row.hospitalName;
            states.npi = props.row.npi;
            states.ccn = props.row.prvdrNum;
            states.streetAddress = props.row.streetAddressLine1;
            states.city = props.row.city;
            states.state = props.row.state;
            states.zipCode = props.row.zipCode;
            states.ratesFileUrl = props.row.ratesFileUrl;
            states.customers = props.row.customers;
            states.updatedBy = props.row.updatedBy;
            states.isEdit = true;
        }
        return states;
    }

    async validateHospitalRecord(states: IHospitalRatesAddHospitalModalStates, props?: IHospitalRatesAddHospitalModalProps) {
        if (!states.priority) {
            alert('Priority is required');
            return false;
        }
        if (states.ccn) {
            if (!(states.ccn.toString().length == 6 && this.utilsSvc.isNumeric(states.ccn))) {
                alert('CCN must be a 6 digit number');
                return false;
            }
        }
        if (!(states.npi && states.npi.toString().length == 10 && this.utilsSvc.isNumeric(states.npi))) {
            alert('NPI must be a 10 digit number');
            return false;
        }
        if (!states.hospitalName) {
            alert('Hospital name is required');
            return false;
        }
        if (!states.state) {
            alert('State is required');
            return false;
        }
        if (states.state) {
            if (!(states.state && states.state.length == 2)) {
                alert('State must be a 2 character abbreviation');
                return false;
            }
        }
        if (states.zipCode) {
            if (!(states.zipCode && states.zipCode.toString().length == 5 && this.utilsSvc.isNumeric(states.zipCode))) {
                alert('Zip code must be a 5 digit number');
                return false;
            }
        }
        if (props?.isEdit) {
            if (props.row.npi !== states.npi) {
                const isUnique = await this.checkUniqueHospital(states);
                if (!isUnique) {
                    alert('NPI already exists in the system. Edit the existing record instead');
                    return false;
                }
            }
        }
        else {
            const isUnique = await this.checkUniqueHospital(states);
            if (!isUnique) {
                alert('NPI already exists in the system. Edit the existing record instead');
                return false;
            }
        }
        return true;
    }

    async checkUniqueHospital(states: IHospitalRatesAddHospitalModalStates) {
        const existingHospitals = await this.dataAccess.genericFind({
            model: 'HospitalRatesHospitals'
        });
        const existingNpis = existingHospitals.map((hospital: { npi: any; }) => hospital.npi);
        if (existingNpis.includes(states.npi)) {
            return false;
        }
        return true;
    }

    async addHospital(states: IHospitalRatesAddHospitalModalStates, currentLoadSequence: number) {
        try {
            const createHospital = await this.dataAccess.genericCreate({
                model: 'HospitalRatesHospitals',
                data: {
                    npi: states.npi,
                    prvdrNum: states.ccn,
                    hospitalName: states.hospitalName,
                    streetAddressLine1: states.streetAddress,
                    city: states.city,
                    state: states.state,
                    zipCode: states.zipCode,
                    ratesFileUrl: states.ratesFileUrl
                }
            });
            await this.dataAccess.genericCreate({
                model: 'HospitalRatesPriority',
                data: {
                    hospitalId: createHospital.id,
                    priority: states.priority
                }
            });
            await this.dataAccess.genericCreate({
                model: 'HospitalRatesClarifyMetadata',
                data: {
                    hospitalId: createHospital.id,
                    customers: states.customers,
                    requestedBy: this.argosStore.getItem('username')
                }
            });
            await this.dataAccess.genericCreate({
                model: 'HospitalRatesHospitalLoadSequences',
                data: {
                    hospital: createHospital.id,
                    loadSequence: currentLoadSequence ,
                    ratesFileSearchStatus: 'NOT_YET_ATTEMPTED'
                }
            });
        } catch (error) {
            console.log(error);
            return false;
        }
        return true;
    };

    async editHospital(states: IHospitalRatesAddHospitalModalStates, props: IHospitalRatesAddHospitalModalProps) {
        try {
            if (this.isUpdatedHospitalRecord(states, props)) {
                await this.dataAccess.genericMethod({
                    model: 'HospitalRatesHospitals',
                    method: 'updateHospitalRecord',
                    parameters: {
                        id: states.id,
                        npi: states.npi,
                        prvdrNum: states.ccn,
                        hospitalName: states.hospitalName,
                        streetAddress: states.streetAddress,
                        city: states.city,
                        state: states.state,
                        zipCode: states.zipCode,
                        ratesFileUrl: states.ratesFileUrl
                    }
                });
            };
            const hospitalPriority = await this.dataAccess.genericFind({
                model: 'HospitalRatesPriority'
            });
            const existingPriority = hospitalPriority.map((hospitalPriority: { hospitalId: any; }) => hospitalPriority.hospitalId);
            if (this.isPriorityUpdated(states, props) && existingPriority.includes(states.id)) {
                await this.dataAccess.genericMethod({
                    model: 'HospitalRatesHospitals',
                    method: 'updatePriority',
                    parameters: {
                        id: states.id,
                        priority: states.priority?.toString().replace('P', '')
                    }
                });
            }
            else if (this.isPriorityUpdated(states, props) && !existingPriority.includes(states.id)) {
                await this.dataAccess.genericCreate({
                    model: 'HospitalRatesPriority',
                    data: {
                        hospitalId: states.id,
                        priority: states.priority?.toString().replace('P', '')
                    }
                });
            }
            const clarifyMetadata = await this.dataAccess.genericFind({
                model: 'HospitalRatesClarifyMetadata'
            });
            const existingMetadata = clarifyMetadata.map((clarify: { hospitalId: any; }) => clarify.hospitalId);
            if (existingMetadata.includes(states.id)) {
                await this.dataAccess.genericMethod({
                    model: 'HospitalRatesHospitals',
                    method: 'updateClarifyMetadata',
                    parameters: {
                        id: states.id,
                        customers: states.customers,
                        requestedBy: this.argosStore.getItem('username')
                    }
                });
            }
            else {
                await this.dataAccess.genericCreate({
                    model: 'HospitalRatesClarifyMetadata',
                    data: {
                        hospitalId: states.id,
                        customers: states.customers,
                        requestedBy: this.argosStore.getItem('username')
                    }
                });
            }
        } catch (error) {
            console.log(error);
            return false;
        }
        return true;
    };

    isUpdatedHospitalRecord(states: IHospitalRatesAddHospitalModalStates, props: IHospitalRatesAddHospitalModalProps) {
        if (props.isEdit) {
            return (
                states.hospitalName !== props.row.hospitalName ||
                states.npi !== props.row.npi ||
                states.ccn !== props.row.prvdrNum ||
                states.streetAddress !== props.row.streetAddressLine1 ||
                states.city !== props.row.city ||
                states.state !== props.row.state ||
                states.zipCode !== props.row.zipCode ||
                states.ratesFileUrl !== props.row.ratesFileUrl
            );
        };
        return false;
    };

    isPriorityUpdated(states: IHospitalRatesAddHospitalModalStates, props: IHospitalRatesAddHospitalModalProps) {
        if (typeof states.priority === 'string') {
            return (
                states.priority !== props.row.priority
            );
        }
        else {
            return (
                states.priority?.toString() !== props.row.priority.replace('P', '')
            );
        };
    };

    async getCurrentLoadSequence() {
        let currentLoadSequence = await this.dataAccess.genericMethod({
            model: 'HospitalRatesLoadSequences',
            method: 'getCurrentLoadSequence'
        });
        return currentLoadSequence[0].id;
    };
}