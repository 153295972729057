import { Injectable } from '@angular/core';
import { IViewReleaseDagConfigModalService, IViewReleaseDagConfigModalProps, IViewReleaseDagConfigModalStates } from './viewReleaseDagConfigModal.component.d';

@Injectable()
export class ViewReleaseDagConfigService implements IViewReleaseDagConfigModalService {
    constructor() {}

    initDelegate(props: IViewReleaseDagConfigModalProps, states: IViewReleaseDagConfigModalStates): Object {
        this.init(props, states);
        return states;
    };

    init(props: IViewReleaseDagConfigModalProps, states: IViewReleaseDagConfigModalStates) {
        states.formattedDagConfig = JSON.stringify(JSON.parse(props.dagConfig), null, 4);
        return states;
    };

} 