import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IDependentVariableMetadataSqlFilterProps, IDependentVariableMetadataSqlFilterStates } from './dependentVariableMetadataSqlFilter.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { DependentVariableMetadataSqlFilterService } from './dependentVariableMetadataSqlFilter.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'argos-dependentVariableMetadataSqlFilter',
    templateUrl: './dependentVariableMetadataSqlFilter.component.html',
    providers: [DependentVariableMetadataSqlFilterService]
})

export class DependentVariableMetadataSqlFilterComponent
    extends ReactComponentBase<IDependentVariableMetadataSqlFilterProps, IDependentVariableMetadataSqlFilterStates, {}> implements OnInit, OnChanges {
    constructor(private svc: DependentVariableMetadataSqlFilterService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<DependentVariableMetadataSqlFilterComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close('cancel');
    }

    done() {
        this.dialogRef.close({
            sqlFilters: this.states.sqlFilters
        });
    }

    addFilter() {
        this.states.sqlFilters.push(this.states.newFiliter.field + ' ' + this.states.newFiliter.condition + ' ' + this.states.newFiliter.field2);

        this.states.newFiliter = {
            field: '',
            field2: ''
        };
    }

    sqlFilterChange(row: any, event: any) {
        console.log('event', event);
        // TODO
        row = event;
    }
}
