import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IEnvironmentModalProps, IEnvironmentModalStates } from './environmentModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EnvironmentModalService } from './environmentModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'argos-environmentModal',
    templateUrl: './environmentModal.component.html',
    providers: [EnvironmentModalService]
})

export class EnvironmentModalComponent extends ReactComponentBase<IEnvironmentModalProps, IEnvironmentModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: EnvironmentModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<EnvironmentModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close('cancel');
    }

    copyToClipboard(command: any) {
        this.svc.copyToClipboardHandler(command, this.states);
    }
}
