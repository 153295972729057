import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IEcsActivityLogListStates, IEcsActivityLogListService } from './ecsActivityLogList.component.d';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatTableDataSource } from '@angular/material/table';
import { UtilsService } from '../../../services/utils.service';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
@Injectable()
export class EcsActivityLogListService implements IEcsActivityLogListService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess, private utils: UtilsService, 
                private matDialog: MatDialog, private cdr: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService) {
    }
    initDelegate(states: IEcsActivityLogListStates): Object {
        this.activate(states);
        return {};
    }

    async daysToQueryChangedhandler(states: IEcsActivityLogListStates) {
        this.argosStore.setItem('ecsEventLogDaysToQuery', states.daysToQuery);

        const dateAgo = new Date();
        dateAgo.setDate(dateAgo.getDate() - states.daysToQuery);
        const result = await this.dataAccess.genericFind({
            model: 'EnvironmentActivityLog',
            filter: {
                order: 'id desc',
                where: {
                    createdAt: {
                        gte: dateAgo
                    }
                }
            }
        });
        states.dataSources = result;
        states.dataSources.forEach((event) => {
            event.dateFullName = this.utils.getLocalDate(event.createdAt);
            event.detailMessage = Object.keys(event.eventDetail).map(function (k) { return k + ':' + event.eventDetail[k]; }).join('\n');
        });

        this.setTablePageSize(states);
    }

    activate(states: IEcsActivityLogListStates) {
        states.daysToQuery = this.argosStore.getItem('ecsEventLogDaysToQuery') || 30;
        this.daysToQueryChangedhandler(states);
    }

    setTablePageSize(states: IEcsActivityLogListStates) {
        states.table.data = states.dataSources;
    }

    exportToExcel(states: IEcsActivityLogListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IEcsActivityLogListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
