import { Injectable } from '@angular/core';
import { IEditObservationTagModalProps, IEditObservationTagModalStates, IEditObservationTagModalService } from './editObservationTagModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';


@Injectable()
export class EditObservationTagModalService implements IEditObservationTagModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(props: IEditObservationTagModalProps, states: IEditObservationTagModalStates): Promise<object> {
        states.referenceDataListId = props.referenceDataListId;
        states.tagIndex = props.tagIndex;
        states.valueReferenceListTagId = props.valueReferenceListTagId;
        states.lodash = _;
        await this.init(states);
        return {};
    }

    async changeDelegate(oldProps: IEditObservationTagModalProps, newProps: IEditObservationTagModalProps, states: IEditObservationTagModalStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async init(states: IEditObservationTagModalStates) {

        const referenceDataListTags = await this.dataAccess.genericFind({
            model: 'ReferenceDataListTag',
            filter: { include: 'referenceDataList' }
        });

        const existingTag: any = _.find(referenceDataListTags, { id: states.valueReferenceListTagId });

        states.tagPaths = (existingTag && existingTag.parentPath) ? existingTag.parentPath.split('.').map((obj: any) => ({ tagId: parseInt(obj) })) : [{}];
        states.referenceDataListTags = (states.referenceDataListId) ? _.orderBy(_.filter(referenceDataListTags, { referenceDataListId: states.referenceDataListId }), 'tag') : _.orderBy(referenceDataListTags, 'tag');
    }

    addTagLevel(states: IEditObservationTagModalStates) {
        if (!states.tagPaths) {
            states.tagPaths = [];
        }
        states.tagPaths.push({});
    }

    deleteTag(index: any, states: IEditObservationTagModalStates) {
        states.tagPaths.length = index;
        states.tagPaths.push({});
    }
}
