import { Injectable, ChangeDetectorRef } from '@angular/core';
import { INewEnvironment20RequestStates, INewEnvironment20RequestService } from './newEnvironment20Request.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { StateService, UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { EditEnvironmentDatasourceFilterModalComponent } from '../editEnvironmentDatasourceFilterModal/editEnvironmentDatasourceFilterModal.component';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class NewEnvironment20RequestService implements INewEnvironment20RequestService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog,
        private state: StateService, private alertSvc: AlertService, private changeDetection: ChangeDetectorRef) {
        //
    }

    async initDelegate(states: INewEnvironment20RequestStates): Promise<object> {
        await this.activate(states);
        this.changeDetection.detectChanges();
        return {};
    }

    initApprover(states: INewEnvironment20RequestStates) {

        if (states.env2_0_request.approvalManagers) {
            const approverFound = _.filter(states.env2_0_request.approvalManagers, (ap) => {
                if (ap.user === this.argosStore.getItem('username')) {
                    return ap;
                }
            });

            if (approverFound && approverFound.length === 1) {
                states.approver = approverFound[0];
            }
        }
        // no approver found
        if (!states.approver) {
            states.approver = {
                user: this.argosStore.getItem('username'),
                approved: true,
                comment: ''
            };

            states.env2_0_request.approvalManagers.push(states.approver);
        }
    }

    async loadRequest(id: any, states: INewEnvironment20RequestStates) {
        const request = await this.dataAccess.genericFind({
            model: 'Environment20Request',
            filter: {
                where: {
                    id: _.toNumber(this.uiRouter.globals.params.id)
                }
            }
        });
        if (request && request.length === 1) {
            states.env2_0_request = request[0];

            // convert values from db back into object
            states.env2_0_request.needByDate = new Date(states.env2_0_request.needByDate).toISOString().slice(0, 10);
            states.env2_0_request.requestDate = new Date(states.env2_0_request.requestDate).toISOString().slice(0, 10);
            if (states.env2_0_request.expirationDate) {
                states.env2_0_request.expirationDate = new Date(states.env2_0_request.expirationDate).toISOString().slice(0, 10);
            }

            const custFilter = _.filter(states.customers, function (cust) {
                if (cust.id === states.env2_0_request.customerId) {
                    return cust;
                }
            });

            if (custFilter && custFilter.length === 1) {
                states.customerName = custFilter[0].customerName;
                this.selectedCustomerNameChanged(states.customerName, states);
            }

            if (states.env2_0_request.approvalStatus === 'pending approval') {
                this.initApprover(states);
            }

            if (states.env2_0_request.clientDataIncludesPiiInEnvironment) {
                this.selectedAWSServiceChanged('cleardata', states);
            }

            if (_.filter(states.env2_0_request.datasourceParameters, function (ds) { if (ds.careGroupingId === 'fb4ef9d2-c230-4f09-9f77-b49f1b9ce920') { return ds; } }).length > 0) {
                states.env2_0_request.isNpoEnvironment = true;
                states.isCreateDedicatedRedis = true;
            }

            if (states.env2_0_request.domainName === 'clarifyhealth.com') {
                states.buildRequest.selectedClarifyRdsIdent = 'platform-rds';
            } else {
                states.buildRequest.selectedClarifyRdsIdent = 'platform-rds-dev';
            }

            if (states.env2_0_request.assignedOwnerEmails) {
                states.selectedAssignedOwnerEmails = states.env2_0_request.assignedOwnerEmails;
            }

            states.buildRequest.appCName = states.env2_0_request.url;

        } else {
            // if we dont find a value reroute to new
            this.state.go('argos.environments.environment20Requests.add');
        }
    }

    async activate(states: INewEnvironment20RequestStates) {
        const clients = await this.dataAccess.genericFind({
            model: 'Client',
            filter: {
                fields: { id: true, internal_name: true, salesforceAccountId: true }, include: ['clientProductConfigurations']
            }
        });
        states.auth0Connections = await this.dataAccess.genericFind({
            model: 'Auth0Client',
            filter: {
                fields: ['auth0Connection', 'auth0ClientId', 'auth0Tenant', 'id'],
                order: 'auth0Connection asc',
                where: {
                    isDeleted: false
                }
            }
        });
        states.careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: {
                fields: ['id', 'title'],
                order: 'title'
            }
        });
        states.customers = await this.dataAccess.genericFind({
            model: 'Customer',
            filter: {
                fields: ['id', 'customerName', 'internalName'],
                order: 'customerName'
            }
        });
        const buildTags = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEcsBuilds', parameters: {
                repositoryName: 'clarify-platform'
            }
        });
        states.productConfigs = await this.dataAccess.genericFind({
            model: 'ProductConfiguration',
            filter: { order: 'title' }
        });

        states.assignedOwnerEmails = await this.dataAccess.genericMethod({
            model: 'ArgosUser',
            method: 'getActiveUsers',
            parameters: {}
        });

        states.assignedOwnerEmails = _.map(states.assignedOwnerEmails, function (u: any) {
            return {
                id: u.id,
                email: u.email
            }
        });

        states.clients = _.orderBy(clients, ['internal_name', 'id']);
        states.auth0Connections.unshift({ id: -1, auth0ClientId: 'NA', auth0Connection: 'I need a new login group or I am not sure' });

        states.buildTags = buildTags.response;
        states.buildTags.sort();
        states.buildTags.reverse();

        if (this.uiRouter.globals.params.id && this.uiRouter.globals.params.id !== 'new') {
            states.screenTitle = 'Edit Environment 2.0 Request';
            await this.loadRequest(this.uiRouter.globals.params.id, states);
        } else {
            states.screenTitle = 'New Environment 2.0 Request';
            states.env2_0_request.minimumDate = new Date();
            states.env2_0_request.minimumDate.setDate(states.env2_0_request.minimumDate.getDate() + 1);
        }
    }

    selectedClientChange(states: INewEnvironment20RequestStates) {
        // only do a check if there is an existing client product configs
        if (states.buildRequest.client.clientProductConfigurations?.length > 0) {
            // get the full selected product config object
            let requestedProductConfig: any = _.flatten(_.map(states.env2_0_request.filterParameters.productConfiguration, function (pc: any) { return _.filter(states.productConfigs, pc); }));
            requestedProductConfig = requestedProductConfig.map(function (i: any) { return { productConfiguration: i.name }; });

            // when using differenceBy the order and references of result values are determined by the first array
            // so always put the large of the two in front
            let diffs = _.differenceBy(states.buildRequest.client.clientProductConfigurations, requestedProductConfig, 'productConfiguration');
            diffs = diffs.concat(_.differenceBy(requestedProductConfig, states.buildRequest.client.clientProductConfigurations, 'productConfiguration'));

            if (diffs.length > 0) {
                states.env2_0_request.isClientProductConfigsMismatch = true;
                states.env2_0_request.clientProductConfigsMismatch = diffs.map(function (d: any) { return d.productConfiguration; }).join(', ');
            }
        }

        states.buildRequest.ecsNamingConventionOverride = states.buildRequest.client.internal_name.replace(/_/g, '-').substring(0, 16);
    }

    clientProvidedDataUseOptionChanged(states: INewEnvironment20RequestStates) {
        if (states.env2_0_request.clientDataIncludesPiiInEnvironment) {
            delete states.env2_0_request.needByDate;
            states.env2_0_request.minimumDate.setDate(states.env2_0_request.minimumDate.getDate() + 3);
        }
    }

    buildEcsSiteFieldChanged(states: INewEnvironment20RequestStates) {
        if (states.env2_0_request.environmentType === 'demo') {
            states.env2_0_request.domainName = 'clarifyhealth.us';
        } else {
            states.env2_0_request.domainName = 'clarifyhealth.com';
        }
    }

    selectedCustomerNameChanged(selectedCustName: any, states: INewEnvironment20RequestStates) {

        if (selectedCustName && selectedCustName.length > 0) {

            const custFilter = _.filter(states.customers, function (cust) {
                if (cust.customerName.toLowerCase() === selectedCustName.toLowerCase()) {
                    return cust;
                }
            });


            // existing customer
            if (custFilter && custFilter.length === 1) {
                states.selectedCustomer = _.cloneDeep(custFilter[0]);
                states.env2_0_request.customerName = selectedCustName;
            } else {
                // new customer
                delete states.selectedCustomer.id;
                states.selectedCustomer.customerName = selectedCustName;
                states.selectedCustomer.internalName = selectedCustName;
                states.env2_0_request.customerName = selectedCustName;
            }

        } else {
            // empty so start fresh
            states.selectedCustomer = {};
            delete states.env2_0_request.customerName;
        }
    }

    validateAllRequiredFields(obj: any, requiredProperties: any, states: INewEnvironment20RequestStates) {

        for (const key in obj) {
            if (requiredProperties.indexOf(key) > -1 && (obj[key] === null || obj[key].toString().trim().length === 0)) {
                obj.isValidToSave = false;
                obj.propertyErrors.push({
                    field: key,
                    message: key + ' is a required field'
                });
            }
        }

        // check if the require property does not exists
        for (let i = 0; i < requiredProperties.length; i++) {
            const propName = requiredProperties[i];

            if (!obj.hasOwnProperty(propName)) {
                obj.isValidToSave = false;
                obj.propertyErrors.push({
                    field: propName,
                    message: propName + ' is not found and a required field'
                });
            }
        }
    }

    validateArrayLength(obj: any, requiredProperties: any, countMin: any, states: INewEnvironment20RequestStates) {
        for (let i = 0; i < requiredProperties.length; i++) {
            const propName = requiredProperties[i];

            if (!obj.hasOwnProperty(propName) || obj[propName].length < countMin) {
                obj.isValidToSave = false;
                obj.propertyErrors.push({
                    field: propName,
                    message: propName + ' is not found and a required field'
                });
            }
        }
    }

    isValid(states: INewEnvironment20RequestStates) {

        const requiredFields = ['environmentType', 'auth0Id', 'url', 'needByDate', 'clientProvidedData',
            'clientDataIncludesPiiInEnvironment', 'dopsJiraEmail',
            'dopsJiraDescription', 'duaJiraDescription', 'duaJiraBusinessContext', 'salesforceTicket', 'customerName', 'imageLogoData'];

        states.env2_0_request.isValidToSave = true;
        states.env2_0_request.propertyErrors = [];

        this.validateAllRequiredFields(states.env2_0_request, requiredFields, states);
        this.validateArrayLength(states.env2_0_request, ['datasourceParameters'], 1, states);
        // this.validateProductConfig(states.env2_0_request, states);
        this.validateAssignedOwnerEmails(states.env2_0_request, states);

        return states.env2_0_request.isValidToSave;
    }

    validateDate(states: INewEnvironment20RequestStates) {
        //
    }

    validateProductConfig(obj: any, states: INewEnvironment20RequestStates) {
        if (!obj.filterParameters.productConfiguration || obj.filterParameters.productConfiguration.length < 1) {
            const propName = 'productConfiguration';
            obj.propertyErrors.push({
                field: propName,
                message: propName + ' is a required field'
            });
        }
    }

    validateAssignedOwnerEmails(obj: any, states: INewEnvironment20RequestStates) {
        if (!obj.assignedOwnerEmails || obj.assignedOwnerEmails.length < 1) {
            const propName = 'assignedOwnerEmails';
            obj.isValidToSave = false;
            obj.propertyErrors.push({
                field: propName,
                message: propName + ' is a required field'
            });
        }
    }

    isValidBuildRequest(states: INewEnvironment20RequestStates) {
        states.buildRequest.isValidToSave = true;
        states.buildRequest.propertyErrors = [];
        this.validateAllRequiredFields(states.buildRequest,
            ['buildTag', 'client', 'auth0Obj', 'selectedEc2InstanceType', 'awsAccountName', 'ecsNamingConventionOverride']
            , states);

        if (states.buildRequest.awsAccountName === 'cleardata') {

            if (this.getAwsGroupSelectedCount(false, 'securityGroups', states) < 1 ||
                this.getAwsGroupSelectedCount(true, 'securityGroups', states) < 1 ||
                this.getAwsGroupSelectedCount(false, 'subnets', states) < 2 ||
                this.getAwsGroupSelectedCount(true, 'subnets', states) < 2) {
                states.buildRequest.isValidToSave = false;
            }
            this.validateAllRequiredFields(states.buildRequest, ['vpc'], states);
        }

        return states.buildRequest.isValidToSave;
    }

    errorExists(row: any, propertyName: any) {

        let result;
        if (row && row.propertyErrors) {
            result = row.propertyErrors.map(function (i: any) { return i.field; }).indexOf(propertyName) > -1;
        }
        return result;
    }

    addBuildLog(message: any, states: INewEnvironment20RequestStates) {
        states.buildLog += message;
        this.changeDetection.detectChanges();
    }

    cloneEnvironment(runId: any,
        envObj: any,
        hostingAppName: any,
        databaseName: any,
        databaseUserName: any,
        dbServerId: string,
        states: INewEnvironment20RequestStates) {
        const result = envObj;

        delete result.id;
        result.name = states.buildRequest.client.internal_name + ' ' + runId;
        result.hostingAppName = hostingAppName;
        result.dnsCname = states.buildRequest.appCName;
        result.auth0Id = states.buildRequest.auth0Obj.id;
        delete result.ecsParameters.canaryUptime;
        delete result.ecsParameters.canaryTransaction;
        result.dbName = databaseName;
        result.createdAt = new Date().toISOString();

        if (states.buildRequest.client.internal_name) {
            result.clientId = states.clients.find(x => x.internal_name === states.buildRequest.client.internal_name).id;
        }

        result.hostingProvider = 'ecs';
        result.domainSuffix = states.env2_0_request.domainName.replace('clarifyhealth.', '');
        result.environment20RequestId = states.env2_0_request.id;
        result.environmentType = states.env2_0_request.environmentType;
        result.dbOwnerUsername = databaseUserName;

        result.dbServerId = dbServerId;

        return result;
    }

    async createUptimeAlarm(clusterName: any, ssoEnabled: any, states: INewEnvironment20RequestStates) {
        const dnsCname = states.buildRequest.appCName;
        const domainSuffix = states.env2_0_request.domainName.substring(states.env2_0_request.domainName.indexOf('.') + 1, states.env2_0_request.domainName.length);
        const tenant = states.buildRequest.auth0Obj.auth0Tenant;
        const tags = [states.buildRequest.awsAccountName, states.env2_0_request.environmentType];
        const papertailUrl = 'https://my.papertrailapp.com/groups/17647492/events?q=' + clusterName + '%20AND%20NOT%20%28"handling%20container%20change"%20OR%20"Managed%20task"%20OR%20"ecs-agent"%20OR%20"uptime.com"%29';

        const alarmTransaction = await this.dataAccess.genericMethod({
            model: 'UpTimeApi', method: 'createAlarmTransaction', parameters: {
                dnsCname,
                domainSuffix,
                notes: papertailUrl,
                tags,
                isSSO: ssoEnabled,
                auth0Connection: states.buildRequest.auth0Obj.auth0Connection,
                tenantName: tenant,
                clusterName,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.buildRequest.awsAccountName
            }
        });

        const alarmHttp = await this.dataAccess.genericMethod({
            model: 'UpTimeApi', method: 'createAlarmHttp', parameters: {
                dnsCname,
                domainSuffix,
                notes: papertailUrl,
                tags,
                clusterName,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.buildRequest.awsAccountName
            }
        });

        if (alarmTransaction.isSuccessful && alarmHttp.isSuccessful) {
            return alarmHttp;
        }

    }

    async createEcsServiceScaling(clusterName: any, serviceName: any, days: any, minute: any, hour: any, minCapacity: any, maxCapacity: any, states: INewEnvironment20RequestStates) {
        return this.dataAccess.genericMethod({
            model: 'Environment', method: 'upsertEcsServiceScalableSchedule',
            parameters: {
                clusterName,
                resourceId: 'service/' + clusterName + '/' + clusterName + serviceName,
                scaleActionName: clusterName + serviceName + '_fg_scale_cron_' + days + '_' + hour + '_' + minute,
                days,
                hour,
                minute,
                maxCapacity,
                minCapacity,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.buildRequest.awsAccountName
            }
        });
    }

    async saveEnvironmentCreationToLog(runId: any, envObj: any) {

        const activityLog: any = {
            clusterName: envObj.hostingAppName,
            environmentAction: 'environment created',
            eventDetail: {
                runId
            },
            triggeredBy: this.argosStore.getItem('username')
        };
        activityLog.message = activityLog.environmentAction;
        await this.dataAccess.genericCreate({
            model: 'EnvironmentBuildLog',
            data: {
                runId,
                message: 'Argos environment created with id ' + envObj.id,
                isError: false,
                logSource: 'argos'
            }
        });
        await this.dataAccess.genericCreate({
            model: 'EnvironmentActivityLog',
            data: activityLog
        });
    }

    async createEnvironment(newEnvObj: any) {
        return this.dataAccess.genericCreate({
            model: 'Environment',
            data: newEnvObj
        });
    }

    async updateClient(newClientObj: any) {
        return this.dataAccess.genericUpsert({
            model: 'Client',
            data: newClientObj
        });
    }

    isDotComCert(states: INewEnvironment20RequestStates) {
        let result = false;
        if (states.env2_0_request.domainName && states.env2_0_request.domainName.indexOf('.com') >= 0) {
            result = true;
        }

        return result;

    }

    async restartEcsApp(clusterObj: any, states: INewEnvironment20RequestStates) {
        const upgradeTaskArg = {
            clusterName: clusterObj.clusterName,
            taskDefinitionName: clusterObj.upgradeTaskName,
            awsAccountName: states.buildRequest.awsAccountName
        };
        return this.dataAccess.genericMethod({
            model: 'Environment', method: 'runEcsTaskDefinition', parameters: {
                upgradeTaskArg
            }
        });
    }

    addDatasourceFilter(states: INewEnvironment20RequestStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    sourceObj: null,
                    readOnly: false
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            EditEnvironmentDatasourceFilterModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            if (result) {
                states.env2_0_request.datasourceParameters.push(result);
                this.changeDetection.detectChanges();
            }
        });
    }

    deleteDatasourceFilter(dsObj: any, states: INewEnvironment20RequestStates) {

        const index = _.findIndex(states.env2_0_request.datasourceParameters, { id: dsObj.id });
        if (index >= 0) {
            states.env2_0_request.datasourceParameters.splice(index, 1);
        }
    }

    editDatasourceFilter(dsObj: any, isReadOnly: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    sourceObj: dsObj,
                    readOnly: isReadOnly
                }
            }
        };
        this.matDialog.open(EditEnvironmentDatasourceFilterModalComponent, dialogConfig);
    }

    async selectedAWSServiceChanged(awsAccountName: any, states: INewEnvironment20RequestStates) {

        states.buildRequest.awsAccountName = awsAccountName;

        if (awsAccountName === 'cleardata') {
            const param = {
                vpcFilter: [''],
                awsAccountName
            };

            if (states.vpcList.length === 0) {
                try {
                    const results = await this.dataAccess.genericMethod({
                        model: 'Environment', method: 'getAwsVpcs', parameters: {
                            param
                        }
                    });
                    states.vpcList = results.vpcs;
                    console.log('states.vpcList ' + JSON.stringify(states.vpcList));
                } catch (error) {
                    this.alertSvc.handleError(error);
                }
            }
        }
    }

    getAwsGroupSelected(isPublic: any, groupType: any, states: INewEnvironment20RequestStates) {

        let groupList = [];
        let fieldName;

        if (groupType === 'securityGroups') {
            fieldName = 'groupId';
            groupList = states.buildRequest.vpc.securityGroups;
        } else if (groupType === 'subnets') {
            fieldName = 'subnetId';
            groupList = states.buildRequest.vpc.subnets;
        }

        const result = _.filter(groupList, function (i) {
            if (i.selected === true && i.isPublic === isPublic) {
                return i;
            }
        });

        return _.uniq(_.map(result, fieldName));
    }

    getAwsGroupSelectedCount(isPublic: any, groupType: any, states: INewEnvironment20RequestStates) {
        let result = 0;

        if (states.buildRequest.vpc) {

            let groupList = [];

            if (groupType === 'securityGroups') {
                groupList = states.buildRequest.vpc.securityGroups;
            } else if (groupType === 'subnets') {
                groupList = states.buildRequest.vpc.subnets;
            }

            const selectedCount = _.filter(groupList, function (i) {
                if (i.selected === true && i.isPublic === isPublic) {
                    return i;
                }
            });

            result = selectedCount ? selectedCount.length : 0;
        }

        return result;
    }

    async addAuth0Object(states: INewEnvironment20RequestStates) {
        try {
            const auth0AWS = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'createAuth0Secret', parameters: {
                    clientId: states.newAuth0Obj.auth0ClientId,
                    connectionName: states.newAuth0Obj.auth0Connection,
                    connectionSecret: states.newAuth0Obj.auth0Secret
                }
            });
            if (auth0AWS.isSuccessful === true) {
                states.newAuth0Obj.auth0ClientId = '';
                states.newAuth0Obj.auth0Secret = '';
                const data = states.newAuth0Obj;
                const auth0Obj: any = await this.dataAccess.genericUpsert({
                    model: 'Auth0Client',
                    data
                });
                delete auth0Obj.auth0Secret;
                states.auth0Connections.push(auth0Obj);
                states.isAddAuth0FieldsVisible = false;
            }
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    async approverUpdateRequest(states: INewEnvironment20RequestStates) {
        states.approver.approved = (states.approver.approved === '1' || states.approver.approved === true ? true : false);
        states.approver.submissionDate = new Date();
        states.env2_0_request.approvalStatus = (states.approver.approved ? 'approved' : 'denied');
        states.env2_0_request.approvalCompletedDate = new Date();

        // console.log('approverUpdateRequest ' + JSON.stringify(states.env2_0_request));
        try {
            const data = states.env2_0_request;
            await this.dataAccess.genericUpsert({
                model: 'Environment20Request',
                data
            });
            console.log('Environment20Request.upsert done');
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    requestApproval(states: INewEnvironment20RequestStates) {
        const promises = [
            this.dataAccess.genericMethod({
                model: 'JiraApi', method: 'getUserId', parameters: {
                    email: states.env2_0_request.dopsJiraEmail
                }
            }),

            this.dataAccess.genericMethod({
                model: 'JiraApi', method: 'getUserId', parameters: {
                    email: this.argosStore.getItem('username')
                }
            })
        ];

        Promise.all(promises).then(async (results) => {
            let isValidRequest = true;
            results.forEach((r) => {
                if (!r.isSuccessful) {
                    isValidRequest = false;
                }
            });
            if (isValidRequest) {
                states.env2_0_request.dopsAssigneeId = results[0].accountId;
                states.env2_0_request.jiraRequestorId = results[1].accountId;
                await this.saveRequest(states);
            } else {
                swal({
                    title: 'Request failed?',
                    text: 'Either the email assignee and/or your jira account is invalid',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel'
                }).then(async (isConfirm: any) => {
                    //
                });
            }
        });
    }

    async saveRequest(states: INewEnvironment20RequestStates) {

        states.env2_0_request.requesterEmail = this.argosStore.getItem('username');
        // TODO selectedDatasources
        // states.env2_0_request.environmentDatasources = states.selectedDatasources;
        states.env2_0_request.needByDate = new Date(states.env2_0_request.needByDate);
        states.env2_0_request.approvalStatus = 'pending approval';

        // make auth0 null if user does not select anything
        if (states.env2_0_request.auth0Id === -1 || undefined) {
            delete states.env2_0_request.auth0Id;
        }
        try {
            const data = states.selectedCustomer;
            const cust: any = await this.dataAccess.genericUpsert({
                model: 'Customer',
                data
            });
            states.env2_0_request.customerId = cust.id;
            const envRequest = await this.saveEnvRequest(states);
            const dopsTicket = await this.saveDopsJiraTicket(envRequest, states);
            const envRequestData = await this.updateRequestDopsTicket(dopsTicket, states);
            const damTicket = await this.saveDamJiraTicket(envRequestData, states);
            const dTicket = await this.updateRequestDamTicket(damTicket, states);
            this.state.go('argos.environments.environment20Requests.edit', { id: states.env2_0_request.id });
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    async saveEnvRequest(states: INewEnvironment20RequestStates) {

        const auth0ConnectionName = _.filter(states.auth0Connections, (conn) => {
            if (conn.auth0Connection === states.env2_0_request.auth0Id) {
                return conn;
            }
        });

        const data = states.env2_0_request;

        if (auth0ConnectionName.length > 0) {
            data.auth0Id = auth0ConnectionName[0].id;
        }

        return this.dataAccess.genericUpsert({
            model: 'Environment20Request',
            data
        });
    }

    async saveDopsJiraTicket(envRequest: any, states: INewEnvironment20RequestStates) {
        states.env2_0_request = envRequest;
        const description = 'This jira ticket was auto generated by Argos request https://argos.clarifyhealth.com/argos/environment2_0_requests/edit/' + envRequest.id + '\n\nRequestor email ' + this.argosStore.getItem('username') + '\n\n' + states.env2_0_request.dopsJiraDescription;
        return this.dataAccess.genericMethod({
            model: 'JiraApi', method: 'createIssue', parameters: {
                project: 'DOPS',
                title: 'Create Environment for ' + states.selectedCustomer.customerName,
                description,
                watcherId: states.env2_0_request.jiraRequestorId,
                assigneeId: states.env2_0_request.dopsAssigneeId
            }
        });
    }

    async saveDamJiraTicket(envRequest: any, states: INewEnvironment20RequestStates) {
        states.env2_0_request = envRequest;
        const description = 'This jira ticket was auto generated from Argos request https://argos.clarifyhealth.com/argos/environment2_0_requests/edit/' + envRequest.id + '\n\nRequestor email ' + this.argosStore.getItem('username') + '\n\n' + states.env2_0_request.duaJiraDescription;
        return this.dataAccess.genericMethod({
            model: 'JiraApi', method: 'createIssue', parameters: {
                project: 'DAM',
                title: 'DUA Request for ' + states.selectedCustomer.customerName,
                description,
                watcherId: states.env2_0_request.jiraRequestorId,
                assigneeId: '',    // DAM auto assigns
                linkedIssueKey: states.env2_0_request.jiraDopsTicketKey,
                businessContext: states.env2_0_request.duaJiraBusinessContext
            }
        });
    }

    async updateRequestDopsTicket(dopsTicket: any, states: INewEnvironment20RequestStates) {
        states.env2_0_request.jiraTicket = dopsTicket.response.issueUrl;
        states.env2_0_request.jiraDopsTicketKey = dopsTicket.response.key;
        const data = states.env2_0_request;
        return this.dataAccess.genericUpsert({
            model: 'Environment20Request',
            data
        });
    }

    async updateRequestDamTicket(damTicket: any, states: INewEnvironment20RequestStates) {
        states.env2_0_request.jiraDuaTicket = damTicket.response.issueUrl;
        states.env2_0_request.jiraDuaTicketKey = damTicket.response.key;
        const data = states.env2_0_request;
        return this.dataAccess.genericUpsert({
            model: 'Environment20Request',
            data
        });
    }

    async readLogoFile(file: any, states: INewEnvironment20RequestStates) {
        const file_obj = file.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file_obj);
        reader.onload = (e) => {
            if (e.target?.result) {
                states.env2_0_request.imageLogoData = e.target.result;
                states.env2_0_request.imageLogoExtension = file_obj.name.split('.')[1];
            }
        };
    }
}
