import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ITerraTestResultsStates, ITerraTestResultsService } from './terraTestResults.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class TerraTestResultsService implements ITerraTestResultsService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, private cdr: ChangeDetectorRef) {
        //
    }

    async initDelegate(states: ITerraTestResultsStates): Promise<object> {
        states.daysToQuery = this.argosStore.getItem('terraTestDaysToQuery') || 60;
        await this.queryRequests(states);
        return {};
    }

    loadData(states: ITerraTestResultsStates) {

        const oParser = new DOMParser();

        states.terraTests.forEach(function (test: any) {

            const xmlDoc = oParser.parseFromString(test.testsSummary, 'application/xml');
            const results = xmlDoc.getElementsByTagName('testsuite');

            for (let i = 0; i < results.length; i++) {
                let testDescription = 'errors: ' + results[i].getAttribute('errors');
                testDescription += '\nfailures: ' + results[i].getAttribute('failures');
                testDescription += '\nname: ' + results[i].getAttribute('name');
                testDescription += '\nskipped: ' + results[i].getAttribute('skipped');
                testDescription += '\ntests: ' + results[i].getAttribute('tests');
                testDescription += '\ntime: ' + results[i].getAttribute('time');
                test.description = testDescription;
            }

            test.circleciJobStartDate = moment(test.circleciJobStartDate).format('MM/DD/YYYY h:mm a');

        });

        states.table.data = _.orderBy(states.terraTests, ['name']);
    }

    async queryRequests(states: ITerraTestResultsStates) {

        this.argosStore.setItem('terraTestDaysToQuery', states.daysToQuery);
        if (!states.daysToQuery || states.daysToQuery <= 0 || states.daysToQuery > 300) {
            states.daysToQuery = 60;
        }

        const monthAgo = new Date();
        monthAgo.setDate(monthAgo.getDate() - states.daysToQuery);
        states.terraTests = await this.dataAccess.genericFind({
            model: 'TerraTestResult',
            filter: {
                order: 'circleciJobStartDate desc',
                where: {
                    circleciJobStartDate: {
                        gte: monthAgo
                    }
                }
            }
        });
        this.loadData(states);
    }

    setTablePageSize(states: ITerraTestResultsStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.terraTests.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: ITerraTestResultsStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: ITerraTestResultsStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    isNumeric(value: string) {
        return /^-?\d+$/.test(value);
    }

}
