import { Injectable } from '@angular/core';
import { IAddNewFieldModalStates } from './addNewFieldModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
@Injectable()
export class AddNewFieldModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async saveHandler(states: IAddNewFieldModalStates) {
        if (!states.listItem.key || !states.listItem.value) {
            return;
        }
        const data = states.listItem;
        const listItems = await this.dataAccess.genericFind({
            model: 'ListItem',
            filter: {
                where: {
                    listId: 3,
                    key: states.listItem.key,
                    value: states.listItem.value
                }
            }
        });
        if (listItems && listItems.length > 0) {
            states.errorMessage = 'Field already exists in the list_item table (id=' + listItems[0].id + ')!!!';
        } else {
            await this.dataAccess.genericUpsert({
                model: 'ListItem',
                data
            });
        }
    }
}
