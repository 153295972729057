import { Injectable } from '@angular/core';
import { ICcgSourceSchemasStates, ICcgSourceSchemasService } from './ccgSourceSchemas.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from '../../../services/dataAccess.service';

@Injectable()
export class CcgSourceSchemasService implements ICcgSourceSchemasService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: ICcgSourceSchemasStates): Promise<object> {
        const newStates = await this.initialize(states);
        return newStates;
    }

    async initialize(states: ICcgSourceSchemasStates): Promise<object> {
        const newStates = _.cloneDeep(states);
        const data = await this.dataAccess.genericFind({
            model: 'ClarifyAttributedProviderSourceSchema',
            filter: {}
        });
        newStates.data = _.orderBy(data, ['name']);
        return newStates;
    }

    addSourceSchemaHandler(states: ICcgSourceSchemasStates) {
        states.newSourceSchema = _.trim(states.newSourceSchema);
        if (!_.isEmpty(states.newSourceSchema) && !states.isSaving && !_.find(states.data, { name: states.newSourceSchema })) {
            states.data.push({ id: (_.get(_.maxBy(states.data, 'id'), 'id', 0) + 1), name: states.newSourceSchema });
            states.newData.push({ id: (_.get(_.maxBy(states.data, 'id'), 'id', 0) + 1), name: states.newSourceSchema });
            states.data = _.orderBy(states.data, ['name']);
            states.newSourceSchema = '';
        }
    }

    async saveHandler(states: ICcgSourceSchemasStates, cb: Function) {
        const data = states.newData;
        if (!states.isSaving) {
            states.isSaving = true;
            await this.dataAccess.genericMethod({
                model: 'ClarifyAttributedProviderSourceSchema', method: 'save', parameters: { data }
            });
            states.isSaving = false;
            states.newData = [];
            cb();
        }
    }
}
