import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ResourceModule, ResourceHandlerHttpClient } from '@ngx-resource/handler-ngx-http';
import { NgMultiselectDropdown3Module } from 'ng-multiselect-dropdown3';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsModule } from '@angular/material/chips';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ObserversModule } from '@angular/cdk/observers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxGraphModule } from '@swimlane/ngx-graph';

import { AppComponent } from './app.component';
import { NavigationComponent } from './components/shared/navigation/navigation.component';
import { ContentComponent } from './components/shared/content/content.component';
// component
import { Error404Component } from './components/shared/error404/error404.component';
import { InactiveComponent } from './components/shared/inactive/inactive.component';
import { AutomatedGoldPulldownListComponent } from './components/automatedGoldPulldown/automatedGoldPulldownList/automatedGoldPulldownList.component';
import { AutomatedGoldPulldownComponent } from './components/automatedGoldPulldown/automatedGoldPulldown.component';
import { AutomatedGoldPulldownBranchInfoComponent } from './components/automatedGoldPulldown/automatedGoldPulldownList/automatedGoldPulldownBranchInfo/automatedGoldPulldownBranchInfo.component';
import { CareGroupingsComponent } from './components/careGroupings/careGroupingsList/careGroupings.component';
import { CareAdminComponent } from './components/careGroupings/careGroupingsList/ccgAdmin.component';
import { CareGroupingsListComponent } from './components/careGroupings/careGroupingsList/careGroupingsList.component';
import { CareGroupingsEditComponent } from './components/careGroupings/careGroupingsEdit/careGroupingsEdit.component';
import { CcgDefinitionComponent } from './components/attributedProviderCCG/ccgDefinition/ccgDefinition.component';
import { CcgProviderTypesComponent } from './components/attributedProviderCCG/ccgProviderTypes/ccgProviderTypes.component';
import { CcgSourceSchemasComponent } from './components/attributedProviderCCG/ccgSourceSchemas/ccgSourceSchemas.component';
import { BuildsComponent } from './components/builds/builds/builds.component';
import { BuildsRootComponent } from './components/builds/builds/buildsRoot.component';
import { EnvironmentsRootComponent } from './components/builds/builds/environmentsRoot.component';
import { BuildDetailComponent } from './components/builds/buildDetail/buildDetail.component';
import { ColumnUsageComponent } from './components/columnUsage/columnUsage/columnUsage.component';
import { CompoundFiltersListComponent } from './components/compoundFilters/compoundFiltersList/compoundFiltersList.component';
import { CompoundFiltersComponent } from './components/compoundFilters/compoundFiltersList/compoundFilters.component';
import { CompoundFiltersAddEditComponent } from './components/compoundFilters/compoundFiltersAddEdit/compoundFiltersAddEdit.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { EcsAppListComponent } from './components/ecs/ecsAppList/ecsAppList.component';
import { EcsAppComponent } from './components/ecs/ecsAppList/ecsApp.component';
import { EcsAppEditComponent } from './components/ecs/ecsAppEdit/ecsAppEdit.component';
import { EcsAdminComponent } from './components/ecs/ecsActivityLogList/ecsAdmin.component';
import { EcsActivityLogListComponent } from './components/ecs/ecsActivityLogList/ecsActivityLogList.component';
import { EcsDashboardComponent } from './components/ecs/ecsDashboard/ecsDashboard.component';
import { EcsRepoEditComponent } from './components/ecs/ecsRepoEdit/ecsRepoEdit.component';
import { EnvironmentRequestsRootComponent } from './components/environmentRequests/environmentRequests/environmentRequestsRoot.component';
import { EnvironmentRequestsComponent } from './components/environmentRequests/environmentRequests/environmentRequests.component';
import { NewEnvironment20Component } from './components/environmentRequests/newEnvironment20Request/newEnvironment20.component';
import { NewEnvironment20RequestComponent } from './components/environmentRequests/newEnvironment20Request/newEnvironment20Request.component';
import { NewEnvironmentRequestComponent } from './components/environmentRequests/newEnvironmentRequest/newEnvironmentRequest.component';
import { EnvironmentsListComponent } from './components/environments/environmentsList/environmentsList.component';
import { EnvironmentsComponent } from './components/environments/environmentsList/environments.component';
import { EnvironmentEditComponent } from './components/environments/environmentEdit/environmentEdit.component';
import { ClientCareGroupingsComponent } from './components/clientCareGroupings/clientCareGroupingsList/clientCareGroupings.component';
import { ClientCareGroupingsListComponent } from './components/clientCareGroupings/clientCareGroupingsList/clientCareGroupingsList.component';
import { EpisodeGroupingEditComponent } from './components/episodeGroupings/episodeGroupingEdit/episodeGroupingEdit.component';
import { EpisodeGroupingImportComponent } from './components/episodeGroupings/episodeGroupingImport/episodeGroupingImport.component';
import { EpisodeGroupingListComponent } from './components/episodeGroupings/episodeGroupingList/episodeGroupingList.component';
import { EpisodeGroupingComponent } from './components/episodeGroupings/episodeGroupingList/episodeGrouping.component';
import { MarkedForDeletionComponent } from './components/markedForDeletion/markedForDeletionList/markedForDeletion.component';
import { MarkedForDeletionListComponent } from './components/markedForDeletion/markedForDeletionList/markedForDeletionList.component';
import { DeleteCommandsModalComponent } from './components/markedForDeletion/deleteCommandsModal/deleteCommandsModal.component';
import { JoinEntitiesListComponent } from './components/episodeGroupings/joinEntitiesList/joinEntitiesList.component';
import { JoinEntitiesComponent } from './components/episodeGroupings/joinEntitiesList/joinEntities.component';
import { ValidValueQueriesListComponent } from './components/validValueQueries/validValueQueriesList.component';
import { ValidValueQueriesComponent } from './components/validValueQueries/validValueQueries.component';
import { EditValidValueQueryModalComponent } from './components/validValueQueries/editValidValueQueryModal.component';
import { ReferenceDataComponent } from './components/modelFeatures/referenceData.component';
import { ModelFeaturesListComponent } from './components/modelFeatures/modelFeaturesList/modelFeaturesList.component';
import { ModelFeaturesComponent } from './components/modelFeatures/modelFeaturesList/modelFeatures.component';
import { TeraUsersComponent } from './components/teraUsers/teraUsersList/teraUsers.component';
import { TeraUsersListComponent } from './components/teraUsers/teraUsersList/teraUsersList.component';
import { TeraUsersEditComponent } from './components/teraUsers/teraUsersEdit/teraUsersEdit.component';
import { ModelMetaDataComponent } from './components/modelMetaData/modelMetaData.component';
import { NetworksStatusComponent } from './components/networksStatus/networksStatus/networksStatus.component';
import { NetworkDesignerComponent } from './components/networksStatus/networkDesigner.component';
import { ObservationExceptionListComponent } from './components/observationExceptions/observationExceptionList/observationExceptionList.component';
import { ObservationExceptionComponent } from './components/observationExceptions/observationExceptionList/observationException.component';
import { ObservationExceptionAddEditComponent } from './components/observationExceptions/observationExceptionAddEdit/observationExceptionAddEdit.component';
import { AllowedFieldEditComponent } from './components/observations/allowedFieldEdit/allowedFieldEdit.component';
import { AllowedFieldListComponent } from './components/observations/allowedFieldList/allowedFieldList.component';
import { AllowedFieldComponent } from './components/observations/allowedFieldList/allowedField.component';
import { CcgReportListComponent } from './components/observations/ccgReportList/ccgReportList.component';
import { HierarchyEditComponent } from './components/observations/hierarchyEdit/hierarchyEdit.component';
import { HierarchyListComponent } from './components/observations/hierarchyList/hierarchyList.component';
import { HierarchyComponent } from './components/observations/hierarchyList/hierarchy.component';
import { ObservationEditComponent } from './components/observations/observationEdit/observationEdit.component';
import { ObservationListComponent } from './components/observations/observationList/observationList.component';
import { ObservationComponent } from './components/observations/observationList/observation.component';
import { PipelineDataSourceListComponent } from './components/pipelineDataSources/pipelineDataSourceList/pipelineDataSourceList.component';
import { PipelineDataSourceComponent } from './components/pipelineDataSources/pipelineDataSourceList/pipelineDataSource.component';
import { PipelineDataComponent } from './components/pipelineDataSources/pipelineDataSourceList/pipelineData.component';
import { PipelineDataSourceEditComponent } from './components/pipelineDataSources/pipelineDataSourceEdit/pipelineDataSourceEdit.component';
import { PipelineErrorMapComponent } from './components/pipelineHistory/pipelineErrorMap/pipelineErrorMap.component';
import { PipelineErrorMapRootComponent } from './components/pipelineHistory/pipelineErrorMap/pipelineErrorMapRoot.component';
import { PipelineHistoryComponent } from './components/pipelineHistory/pipelineHistory/pipelineHistory.component';
import { PipelineHistoryRootComponent } from './components/pipelineHistory/pipelineHistory/pipelineHistoryRoot.component';
import { PipelineHistoryDetailComponent } from './components/pipelineHistory/pipelineHistoryDetail/pipelineHistoryDetail.component';
import { PipelineHistoryDetailRootComponent } from './components/pipelineHistory/pipelineHistoryDetail/pipelineHistoryDetailRoot.component';
import { PipelineQueueComponent } from './components/pipelineQueue/pipelineQueue.component';
import { PipelineQueueRootComponent } from './components/pipelineQueue/pipelineQueueRoot.component';
import { PipelineStepDetailComponent } from './components/pipelineHistory/pipelineStepDetail/pipelineStepDetail.component';
import { Prism1MetricEditComponent } from './components/prism1Metrics/prism1MetricEdit/prism1MetricEdit.component';
import { Prism1MetricImportComponent } from './components/prism1Metrics/prism1MetricImport/prism1MetricImport.component';
import { Prism1MetricListComponent } from './components/prism1Metrics/prism1MetricList/prism1MetricList.component';
import { Prism1MetricComponent } from './components/prism1Metrics/prism1MetricList/prism1Metric.component';
import { ReferenceListItemsListComponent } from './components/referenceListItems/referenceListItemsList/referenceListItemsList.component';
import { ReferenceListItemsComponent } from './components/referenceListItems/referenceListItemsList/referenceListItems.component';
import { ReferenceListItemEditComponent } from './components/referenceListItems/referenceListItemEdit/referenceListItemEdit.component';
import { ReleaseNotesComponent } from './components/releaseNotes/releaseNotes/releaseNotes.component';
import { PrismNotificationsRootComponent } from './components/prismNotifications/prismNotifications/prismNotificationsRoot.component';
import { PrismNotificationsComponent } from './components/prismNotifications/prismNotifications/prismNotifications.component';
import { PrismUsageComponent } from './components/prismUsage/prismUsage/prismUsage.component';
import { AtlasClaraChatHistoryComponent } from './components/atlasClaraChatHistory/atlasClaraChatHistory.component';
import { ProductListComponent } from './components/product/productList/productList.component';
import { ProductComponent } from './components/product/productList/product.component';
import { ProductAddEditComponent } from './components/product/productAddEdit/productAddEdit.component';
import { ProductBundlesListComponent } from './components/productBundles/productBundlesList/productBundlesList.component';
import { ProductBundlesComponent } from './components/productBundles/productBundlesList/productBundles.component';
import { CcgFeatureEditComponent } from './components/programEpisodes/ccgFeatureEdit/ccgFeatureEdit.component';
import { CcgFeatureListComponent } from './components/programEpisodes/ccgFeatureList/ccgFeatureList.component';
import { CcgFeatureComponent } from './components/programEpisodes/ccgFeatureList/ccgFeature.component';
import { ProgramEpisodeEditComponent } from './components/programEpisodes/programEpisodeEdit/programEpisodeEdit.component';
import { ProgramEpisodeListComponent } from './components/programEpisodes/programEpisodeList/programEpisodeList.component';
import { ProgramEpisodeComponent } from './components/programEpisodes/programEpisodeList/programEpisode.component';
import { ProgramModelListComponent } from './components/programModels/programModelList/programModelList.component';
import { ProgramModelComponent } from './components/programModels/programModelList/programModel.component';
import { ProgramModelEditComponent } from './components/programModels/programModelEdit/programModelEdit.component';
import { VersionHistoryListComponent } from './components/versionHistory/versionHistoryList.component';
import { VersionHistoryComponent } from './components/versionHistory/versionHistory.component';
import { EditReleaseNotesComponent } from './components/publishReleaseNotes/editReleaseNotes/editReleaseNotes.component';
import { PreviewReleaseNotesComponent } from './components/publishReleaseNotes/previewReleaseNotes/previewReleaseNotes.component';
import { PublishReleaseNotesComponent } from './components/publishReleaseNotes/publishReleaseNotes/publishReleaseNotes.component';
import { ReleasesComponent } from './components/publishReleaseNotes/releases/releases.component';
import { QueryPerformanceComponent } from './components/queryPerformance/queryPerformance/queryPerformance.component';
import { FilterPerformanceComponent } from './components/environments/filterPerformance/filterPerformance.component';
import { DataProvisioningQuestionnaireComponent } from './components/dataProvisioningQuestionnaire/dataProvisioningQuestionnaire.component';
import { ViewDataProvisioningQuestionnaireModalComponent } from './components/dataProvisioningQuestionnaire/viewDataProvisioningQuestionnaireModal.component';
import { AthenaUsageComponent } from './components/environments/athenaUsage/athenaUsage.component';
import { QuerySettingsComponent } from './components/querySettings/querySettings/querySettings.component';
import { RedshiftClustersComponent } from './components/redshiftClusters/redshiftClusters/redshiftClusters.component';
import { ReferenceDataSourcesEditComponent } from './components/referenceDataSources/referenceDataSourcesEdit/referenceDataSourcesEdit.component';
import { ReferenceDataSourcesListComponent } from './components/referenceDataSources/referenceDataSourcesList/referenceDataSourcesList.component';
import { ReferenceDataSourcesComponent } from './components/referenceDataSources/referenceDataSourcesList/referenceDataSources.component';
import { ClarifyProviderRevisionEditComponent } from './components/clarifyProviderRevision/clarifyProviderRevisionEdit/clarifyProviderRevisionEdit.component';
import { ClarifyProviderRevisionListComponent } from './components/clarifyProviderRevision/clarifyProviderRevisionList/clarifyProviderRevisionList.component';
import { ClarifyProviderRevisionComponent } from './components/clarifyProviderRevision/clarifyProviderRevisionList/clarifyProviderRevision.component';
import { DeequTestResultListComponent } from './components/deequTests/deequTestResultList/deequTestResultList.component';
import { SalesforceSyncStatusComponent } from './components/salesforceSync/salesforceSyncStatus/salesforceSyncStatus.component';
import { ProductConfigurationContentSyncStatusComponent } from './components/productConfigurationContentSync/productConfigurationContentSyncStatus.component';
import { ProductConfigurationContentSyncModalComponent } from './components/productConfigurationContentSync/productConfigurationContentSyncModal/productConfigurationContentSyncModal.component';
import { PatchManagementComponent } from './components/patchManagement/patchManagementList/patchManagement.component';
import { PatchManagementAddEditModalComponent } from './components/patchManagement/patchManagementAddEditModal/patchManagementAddEditModal.component';
import { PatchManagementListComponent } from './components/patchManagement/patchManagementList/patchManagementList.component';
import { PatchManagementPreviewModalComponent } from './components/patchManagement/patchManagementPreviewModal/patchManagementPreviewModal.component';
import { TerraTestResultEditComponent } from './components/terraTests/terraTestResultEdit/terraTestResultEdit.component';
import { TerraTestResultsComponent } from './components/terraTests/terraTestResults/terraTestResults.component';
import { TerraTestResultsRootComponent } from './components/terraTests/terraTestResults/terraTestResultsRoot.component';
import { UserListComponent } from './components/users/userList/userList.component';
import { UserEngagementComponent } from './components/users/userEngagement/userEngagement.component';
import { UserRoleComponent } from './components/users/userRoleList/userRole.component';
import { UserRoleListComponent } from './components/users/userRoleList/userRoleList.component';
import { UserRoleEditComponent } from './components/users/userRoleEdit/userRoleEdit.component';
import { MarketShareMetaEditComponent } from './components/referral/marketShareMetaEdit/marketShareMetaEdit.component';
import { ClientEditComponent } from './components/client/clientEdit/clientEdit.component';
import { ClientListComponent } from './components/client/clientList/clientList.component';
import { ClientComponent } from './components/client/clientList/client.component';
import { MarketShareMetaListComponent } from './components/referral/marketShareMetaList/marketShareMetaList.component';
import { MarketShareMetaComponent } from './components/referral/marketShareMetaList/marketShareMeta.component';
import { ViewPatientJourneyModalComponent } from './components/referral/patientJourneyModal/viewPatientJourneyModal.component';
import { LoginComponent } from './components/login/login/login.component';
import { CallbackComponent } from './components/login/callback/callback.component';
import { PayerRatesMetaListComponent } from './components/payerRates/payerRatesMetaList/payerRatesMetaList.component';
import { PayerRatesMetaComponent } from './components/payerRates/payerRatesMetaList/payerRatesMeta.component';
import { PayerRatesMetaEditComponent } from './components/payerRates/payerRatesMetaEdit/payerRatesMetaEdit.component';
import { QATestResultsComponent } from './components/platformReleaseTestsResults/testResultsDashboard/qaTestResultDashboard.component';
import { QATestResultsRootComponent } from './components/platformReleaseTestsResults/testResultsDashboard/qaTestResultsRoot.component';
import { QATestResultsProductDetailComponent } from './components/platformReleaseTestsResults/testResultsDashboardProductsDetail/qaTestResultDashboardProductsDetail.component';
import { QATestResultsSubmoduleDetailComponent } from './components/platformReleaseTestsResults/testResultsDashboardSubmoduleDetail/qaTestResultDashboardSubmoduleDetail.component';
import { PerformanceTestResultsDashboardProductsDetail } from './components/platformReleaseTestsResults/performanceTestResultsProductDetail/performanceTestResultsDashboardProductsDetail.component';
import { ClarifyInsightsLandingPageComponent } from './components/clarifyInsights/clarifyInsightsLandingPage/clarifyInsightsLandingPage.component';
import { ClarifyInsightsFilterListComponent } from './components/clarifyInsights/clarifyInsightsFilterList/clarifyInsightsFilterList.component';
import { ClarifyInsightsFilterComponent } from './components/clarifyInsights/clarifyInsightsFilterList/clarifyInsightsFilter.component';
import { ClarifyInsightsFilterEditComponent } from './components/clarifyInsights/clarifyInsightsFilterEdit/clarifyInsightsFilterEdit.component';
import { ClarifyInsightsFilterHistoryComponent } from './components/clarifyInsights/clarifyInsightsFilterHistory/clarifyInsightsFilterHistory.component';
import { ClarifyInsightsDataEvaluationUserComponent } from './components/clarifyInsights/clarifyInsightsDataEvaluationUser/clarifyInsightsDataEvaluationUser.component';
import { ClarifyInsightsDataEvaluationUserProvisionModalComponent } from './components/clarifyInsights/clarifyInsightsDataEvaluationUserProvisionModal/clarifyInsightsDataEvaluationUserProvisionModal.component';
import { ClarifyInsightsDataEvaluationUserPasswordResetModalComponent } from './components/clarifyInsights/clarifyInsightsDataEvaluationUserPasswordResetModal/clarifyInsightsDataEvaluationUserPasswordResetModal.component';
import { DataDashboardComponent } from './components/dataDashboard/dataDashboard/dataDashboard.component';
import { ApiPerformanceTestResultsDashboardProductsDetail } from './components/platformReleaseTestsResults/apiPerformanceTestResultsProductDetail/apiPerformanceTestResultsDashboardProductsDetail.component';
import { ClarifySwimlaneComponent } from './components/clarifySwimlane/clarifySwimlane.component';
import { CypressFeatureTestResultDetail } from './components/platformReleaseTestsResults/cypressFeatureTestResultsDetail/cypressFeatureTestResultDetail.component';
import { ScratchDatabaseCleanupComponent } from './components/scratchDatabaseCleanup/scratchDatabaseCleanupLandingPage/scratchDatabaseCleanup.component';
import { ScratchDatabaseCleanupRootComponent } from './components/scratchDatabaseCleanup/scratchDatabaseCleanupLandingPage/scratchDatabaseCleanupRoot.component';
import { StageSourceTableListComponent } from './components/deequTests/stageSourceTableList/stageSourceTableList.component';
import { StageSourceTableColumnListComponent } from './components/deequTests/stageSourceTableColumnList/stageSourceTableColumnList.component';
import { StageSourceTableComponent } from './components/deequTests/stageSourceTableList/stageSourceTable.component';
import { DataReleaseManagerComponent } from './components/dataReleaseManager/dataReleaseManagerMainPage/dataReleaseManager.component';
import { DataReleaseManagerRootComponent } from './components/dataReleaseManager/dataReleaseManagerMainPage/dataReleaseManagerRoot.component';
import { CreateDataReleaseComponent } from './components/dataReleaseManager/createDataRelease/createDataRelease.component';
import { RunDataReleaseComponent } from './components/dataReleaseManager/runDataRelease/runDataRelease.component';
import { EditDataReleaseComponent } from './components/dataReleaseManager/editDataRelease/editDataRelease.component';
import { DataAiChatComponent } from './components/dataAiChat/aiChat/dataAiChat.component';
import { ClarifyBotRootComponent } from './components/clarifyBot/clarifyBotRoot.component';
import { ClarifyBotComponent } from './components/clarifyBot/clarifyBot.component';
import { AdvanceMetaDataComponent } from './components/advanceCCG/advanceMetaDataList/advanceMetaData.component';
import { AdvanceMetaDataListComponent } from './components/advanceCCG/advanceMetaDataList/advanceMetaDataList.component';
import { AdvanceMetaDataEditComponent } from './components/advanceCCG/advanceMetaDataEdit/advanceMetaDataEdit.component';
import { HospitalRatesLandingComponent } from './components/hospitalRates/hospitalRatesLandingPage/hospitalRatesLandingPage.component';
import { HospitalRatesBulkAddComponent } from './components/hospitalRates/hospitalRatesBulkAdd/hospitalRatesBulkAdd.component';
import { HospitalRatesLandingPageRootComponent } from './components/hospitalRates/hospitalRatesLandingPage/hospitalRatesLandingPageRoot.component';
import { ViewReleaseImpactComponent } from './components/dataReleaseManager/viewReleaseImpact/viewReleaseImpact.component';
import { CcgReferenceDataListComponent } from './components/episodeGroupings/ccgReferenceDataList/ccgReferenceDataList.component';
import { CcgReferenceDataComponent } from './components/episodeGroupings/ccgReferenceDataList/ccgReferenceData.component';
import { PceMetaDataComponent } from './components/pceCCG/pceMetaDataList/pceMetaData.component';
import { PceMetaDataListComponent } from './components/pceCCG/pceMetaDataList/pceMetaDataList.component';
import { PceMetaDataEditComponent } from './components/pceCCG/pceMetaDataEdit/pceMetaDataEdit.component';

// modal
import { UserDbLoginSetupComponent } from './components/users/userDbLoginSetup/userDbLoginSetup.component';
import { ViewPayerRatesMetaHistoryComponent } from './components/payerRates/viewPayerRatesMetaHistory/viewPayerRatesMetaHistory.component';
import { ViewCareGroupingHistoryComponent } from './components/careGroupings/viewCareGroupingHistory/viewCareGroupingHistory.component';
import { ViewListModalComponent } from './components/attributedProviderCCG/viewListModal/viewListModal.component';
import { AnalyzeObservationModalComponent } from './components/attributedProviderCCG/analyzeObservationModal/analyzeObservationModal.component';
import { AnalyzeMetaDataModalComponent } from './components/attributedProviderCCG/analyzeMetaDataModal/analyzeMetaDataModal.component';
import { CompoundFiltersSelectCategoryModalComponent } from './components/compoundFilters/compoundFiltersSelectCategoryModal/compoundFiltersSelectCategoryModal.component';
import { EditEnvironmentDatasourceFilterModalComponent } from './components/environmentRequests/editEnvironmentDatasourceFilterModal/editEnvironmentDatasourceFilterModal.component';
import { EnvironmentModalComponent } from './components/environments/environmentModal/environmentModal.component';
import { EnvironmentEditCareGroupingModalComponent } from './components/environments/environmentEditCareGroupingModal/environmentEditCareGroupingModal.component';
import { MigrateCareGroupingModalComponent } from './components/environments/migrateCareGroupingModal/migrateCareGroupingModal.component';
import { ViewClientCareGroupingHistoryModalComponent } from './components/environments/viewClientCareGroupingHistoryModal/viewClientCareGroupingHistoryModal.component';
import { TestSqlModalComponent } from './components/testSqlModal/testSqlModal.component';
import { ViewEpisodeGroupingHistoryModalComponent } from './components/episodeGroupings/viewEpisodeGroupingHistoryModal/viewEpisodeGroupingHistoryModal.component';
import { DependentVariableMetadataSqlFilterComponent } from './components/modelMetaData/dependentVariableMetadataSqlFilter/dependentVariableMetadataSqlFilter.component';
import { ModelMetaDataEditComponent } from './components/modelMetaData/modelMetaDataEdit/modelMetaDataEdit.component';
import { ModelMetaDataListComponent } from './components/modelMetaData/modelMetaDataList/modelMetaDataList.component';
import { ReImportEnvironmentListModalComponent } from './components/networksStatus/reImportEnvironmentListModal/reImportEnvironmentListModal.component';
import { MatTableSettingsModelComponent } from './components/matTableSettingsModel/matTableSettingsModel.component';
import { StatusDetailModalComponent } from './components/networksStatus/statusDetailModal/statusDetailModal.component';
import { AddNewFieldModalComponent } from './components/observations/addNewFieldModal/addNewFieldModal.component';
import { EditProgramObservationModalComponent } from './components/observations/editProgramObservationModal/editProgramObservationModal.component';
import { EditObservationTagModalComponent } from './components/observations/editObservationTagModal/editObservationTagModal.component';
import { ViewHistoryModalComponent } from './components/observations/viewHistoryModal/viewHistoryModal.component';
import { ViewSQLModalComponent } from './components/observations/viewSQLModal/viewSQLModal.component';
import { EditRestrictedGroupingsModalComponent } from './components/prism1Metrics/editRestrictedGroupingsModal/editRestrictedGroupingsModal.component';
import { ViewMetricHistoryModalComponent } from './components/prism1Metrics/viewMetricHistoryModal/viewMetricHistoryModal.component';
import { ViewReferenceDataSourceHistoryModalComponent } from './components/referenceDataSources/viewReferenceDataSourceHistoryModal/viewReferenceDataSourceHistoryModal.component';
import { ViewTeraUsersModalComponent } from './components/referenceDataSources/viewTeraUsersModal/viewTeraUsersModal.component';
import { ViewEnvironmentUsageModalComponent } from './components/prismUsage/viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { ViewFilterPerfEnvironmentModalComponent } from './components/environments/filterPerformance/viewFilterPerfEnvironmentModal.component';
import { ViewClientUsageModalComponent } from './components/prismUsage/viewClientUsageModel/viewClientUsageModal.component';
import { SalesforceSyncModalComponent } from './components/salesforceSync/salesforceSyncModal/salesforceSyncModal.component';
import { AlertComponent } from './components/alert/alert/alert.component';
import { ErrorLogModalComponent } from './components/pipelineHistory/errorLogModal/errorLogModal.component';
import { OptimizationHintModalComponent } from './components/pipelineHistory/optimizationHintModal/optimizationHintModal.component';
import { CcgDefinitionRootComponent } from './components/attributedProviderCCG/ccgDefinition/ccgDefinitionRoot.component';
import { CCGDesignerComponent } from './components/attributedProviderCCG/ccgDefinition/ccgDesigner.component';
import { EditJoinEntityModalComponent } from './components/episodeGroupings/editJoinEntityModal/editJoinEntityModal.component';
import { ViewJoinEntityHistoryModalComponent } from './components/episodeGroupings/viewJoinEntityHistoryModal/viewJoinEntityHistoryModal.component';
import { PipelineHistoryChartModelComponent } from './components/pipelineHistory/pipelineHistoryChartModel/pipelineHistoryChartModel.component';
import { ScheduledScalingStateModelComponent } from './components/ecs/scheduledScalingStateModel/scheduledScalingStateModel.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ClarifyInsightsProvisionCustomerModalComponent } from './components/clarifyInsights/clarifyInsightsProvisionCustomerModal/clarifyInsightsProvisionCustomerModal.component';
import { ClarifyInsightsFinalizeCustomerModalComponent } from './components/clarifyInsights/clarifyInsightsFinalizeCustomerModal/clarifyInsightsFinalizeCustomerModal.component';
import { ClarifyInsightsTriggerTransferModalComponent } from './components/clarifyInsights/clarifyInsightsTriggerTransferModal/clarifyInsightsTriggerTransferModal.component';
import { ClarifyInsightsTriggerExtractModalComponent } from './components/clarifyInsights/clarifyInsightsTriggerExtractModal/clarifyInsightsTriggerExtractModal.component';
import { ClarifyInsightsDeliveryHubComponent } from './components/clarifyInsights/clarifyInsightsDeliveryHub/clarifyInsightsDeliveryHub.component';
import { ClarifyInsightsCreateDeliveryModalComponent } from './components/clarifyInsights/clarifyInsightsCreateDeliveryModal/clarifyInsightsCreateDeliveryModal.component';
import { ApiPerformanceTestResultsDashboardProductsDetailModalComponent } from './components/platformReleaseTestsResults/apiPerformanceTestResultsProductDetail/apiPerformanceTestResultsProductDetailModal/apiPerformanceTestResultsProductDetailModal.component';
import { ViewReferenceListHistoryModalComponent } from './components/referenceListItems/viewReferenceListHistoryModal/viewReferenceListHistoryModal.component';
import { ViewDeequModalComponent } from './components/deequTests/deequModal/viewDeequModal.component';
import { ViewDeequThresholdModalComponent } from './components/deequTests/deequThresholdModal/viewDeequThresholdModal.component';
import { SetTableConfigModalComponent } from './components/scratchDatabaseCleanup/setTablesConfigModal/setTablesConfigModal.component';
import { DagLaunchConfirmationModalComponent } from './components/dataReleaseManager/dagLaunchConfirmationModal/dagLaunchConfirmationModal.component';
import { HospitalRatesAddHospitalModalComponent } from './components/hospitalRates/hospitalRatesAddHospitalModal/hospitalRatesAddHospitalModal.component';
import { QATestResultsTabAndTileDetailComponent } from './components/platformReleaseTestsResults/testResultsDashboardTabAndTileDetails/qaTestResultsDashboardTabAndTileDetails.component';
import { ViewAiAnalysisModalComponent } from './components/referenceDataSources/aiAnalysisModal/viewAiAnalysisModal.component';
import { ReferenceTableUsageModalComponent } from './components/referenceDataSources/referenceTableUsageModal/referenceTableUsageModal.component';
import { ViewReleaseImpactEnvironmentModalComponent } from './components/dataReleaseManager/viewReleaseImpact/viewReleaseImpactEnvironmentModal/viewReleaseImpactEnvironmentModal.component';
import { ViewAiChatModalComponent } from './components/aiChat/aiChatModal/viewAiChatModal.component';
import { DeequTestResultComponent } from './components/deequTests/deequTestResultList/deequTestResult.component';
import { ClaraIqReleaseNotesComponent } from './components/claraIqReleaseNotes/claraIqReleaseNotes.component';
import { ViewVersionHistoryModalComponent } from './components/viewVersionHistory/viewVersionHistoryModal/viewVersionHistoryModal.component';

// pipes
import { MenuPipe } from './pipes/menu.pipes';
import { FilterPipe } from './pipes/filter.pipes';
// Directive

import { ProgressSpinnerDirective } from './directives/progressSpinner/progressSpinner.directive';
import { NgxTableFilterSelectDirective } from './directives/ngxTableFilterSelect.directive';
import { FillHeightDirective } from './directives/fillHeight.directive';
import { NgxTableResizeInitiatorDirective } from './directives/ngxTableResizeInitiator.directive';
import { AuthorizeDirective } from './directives/authorize.directive';
// Service
import { AlertService } from './services/alert.service';
import { ArgosStoreService } from './services/argosStore.service';
import { Broadcaster } from './services/broadCaster.service';
import { NgDataAccess } from './services/dataAccess.service';
import { AuthService } from './services/auth.service';
import { LoginService } from './services/loginService.service';
import { LoopBackAuthRequestInterceptor } from './services/loopbackAuthRequest.intercepter';
import { APIErrorService } from './services/APIError.service';
import { ObservationExceptionService } from './services/observationExceptionService.service';
import { MD5Service } from './services/md5.service';
import { FileService } from './services/handleFile.service';
import { ChartService } from './services/chartService.service';
import { MaterialUtil } from './services/materialUtil.service';
import { ValidationService } from './services/validationService.service';
import { PlatformReleaseResultsService } from './services/platformReleaseResultsService.service';
import { ExportToExcelService } from './services/exportToExcel.service';
import { UserRoleService } from './services/userRole.service';
import { UtilsService } from './services/utils.service';
import { UrlService } from './services/url.service';

// ecs app list handler
import { EcsAppListShare } from './components/ecs/ecsAppList/handler/ecsAppListShareHandler.service';

// ecs app
import { EcsAppActivityLog } from './components/ecs/ecsAppEdit/handler/ecsAppActivityLogHandler.service';
import { EcsAppAlarms } from './components/ecs/ecsAppEdit/handler/ecsAppAlarmsHandler.service';
import { EcsAppAWS } from './components/ecs/ecsAppEdit/handler/ecsAppAWSHandler.service';
import { EcsAppEcsService } from './components/ecs/ecsAppEdit/handler/ecsAppEcsServiceHandler.service';
import { EcsAppEnvironment } from './components/ecs/ecsAppEdit/handler/ecsAppEnvironmentHandler.service';
import { EcsAppEvent } from './components/ecs/ecsAppEdit/handler/ecsAppEventHandler.service';
import { EcsAppRestart } from './components/ecs/ecsAppEdit/handler/ecsAppRestartHandler.service';
import { EcsAppRow } from './components/ecs/ecsAppEdit/handler/ecsAppRowHandler.service';
import { EcsAppService } from './components/ecs/ecsAppEdit/handler/ecsAppServiceHandler.service';
import { EcsAppShare } from './components/ecs/ecsAppEdit/handler/ecsAppShareHandler.service';
import { EcsAppTask } from './components/ecs/ecsAppEdit/handler/ecsAppTaskHandler.service';
import { EcsAppValid } from './components/ecs/ecsAppEdit/handler/ecsAppValidHandler.service';
import { EcsAppPatch } from './components/ecs/ecsAppEdit/handler/ecsAppPatchHandler.service';


// Const
import { APP_STATES } from './app.states';
import { routerConfigFn } from './config';
import { EnvironmentService } from './services/environmentService.service';
import { AdditionalInfoModalComponent } from './components/dataDashboard/additionalInfoModal/additionalInfoModal.component';
import { ClarifyReportsComponent } from './components/clarifyReports/clarifyReportsList/clarifyReports.component';
import { ClarifyReportsListComponent } from './components/clarifyReports/clarifyReportsList/clarifyReportsList.component';



@NgModule({ declarations: [
        AppComponent,
        NavigationComponent,
        ContentComponent,
        CareGroupingsListComponent,
        CareGroupingsComponent,
        CareAdminComponent,
        Error404Component,
        InactiveComponent,
        CareGroupingsEditComponent,
        ViewCareGroupingHistoryComponent,
        CcgDefinitionComponent,
        CcgProviderTypesComponent,
        CcgSourceSchemasComponent,
        ViewListModalComponent,
        AnalyzeObservationModalComponent,
        AnalyzeMetaDataModalComponent,
        CompoundFiltersSelectCategoryModalComponent,
        EditJoinEntityModalComponent,
        ViewJoinEntityHistoryModalComponent,
        ViewReleaseImpactEnvironmentModalComponent,
        BuildsComponent,
        EnvironmentsRootComponent,
        BuildsRootComponent,
        BuildDetailComponent,
        ColumnUsageComponent,
        CompoundFiltersListComponent,
        CompoundFiltersComponent,
        CompoundFiltersAddEditComponent,
        DashboardComponent,
        EcsAppComponent,
        EcsAppListComponent,
        EcsAppEditComponent,
        EcsAdminComponent,
        EcsActivityLogListComponent,
        EcsDashboardComponent,
        EcsRepoEditComponent,
        EnvironmentRequestsComponent,
        EnvironmentRequestsRootComponent,
        NewEnvironment20Component,
        NewEnvironment20RequestComponent,
        NewEnvironmentRequestComponent,
        EditEnvironmentDatasourceFilterModalComponent,
        EnvironmentsListComponent,
        EnvironmentsComponent,
        EnvironmentEditComponent,
        EnvironmentModalComponent,
        EnvironmentEditCareGroupingModalComponent,
        ClientCareGroupingsComponent,
        ClientCareGroupingsListComponent,
        MigrateCareGroupingModalComponent,
        ViewClientCareGroupingHistoryModalComponent,
        EpisodeGroupingEditComponent,
        EpisodeGroupingImportComponent,
        EpisodeGroupingListComponent,
        EpisodeGroupingComponent,
        MarkedForDeletionComponent,
        MarkedForDeletionListComponent,
        MarkedForDeletionListComponent,
        JoinEntitiesListComponent,
        JoinEntitiesComponent,
        ValidValueQueriesListComponent,
        EditValidValueQueryModalComponent,
        ValidValueQueriesComponent,
        TestSqlModalComponent,
        ViewEpisodeGroupingHistoryModalComponent,
        ReferenceDataComponent,
        ModelFeaturesListComponent,
        ModelFeaturesComponent,
        TeraUsersComponent,
        TeraUsersListComponent,
        TeraUsersEditComponent,
        DependentVariableMetadataSqlFilterComponent,
        ModelMetaDataEditComponent,
        ModelMetaDataListComponent,
        ModelMetaDataComponent,
        NetworksStatusComponent,
        NetworkDesignerComponent,
        ReImportEnvironmentListModalComponent,
        MatTableSettingsModelComponent,
        StatusDetailModalComponent,
        ObservationExceptionListComponent,
        ObservationExceptionComponent,
        ObservationExceptionAddEditComponent,
        AddNewFieldModalComponent,
        AllowedFieldEditComponent,
        AllowedFieldListComponent,
        AllowedFieldComponent,
        CcgReportListComponent,
        EditProgramObservationModalComponent,
        EditObservationTagModalComponent,
        HierarchyEditComponent,
        HierarchyListComponent,
        HierarchyComponent,
        ObservationEditComponent,
        ObservationListComponent,
        ObservationComponent,
        ViewHistoryModalComponent,
        ViewReferenceListHistoryModalComponent,
        ViewSQLModalComponent,
        PipelineDataSourceListComponent,
        PipelineDataComponent,
        PipelineDataSourceComponent,
        PipelineDataSourceEditComponent,
        PipelineErrorMapComponent,
        PipelineErrorMapRootComponent,
        PipelineHistoryComponent,
        PipelineHistoryRootComponent,
        PipelineHistoryDetailComponent,
        PipelineHistoryDetailRootComponent,
        PipelineQueueComponent,
        PipelineQueueRootComponent,
        PipelineStepDetailComponent,
        EditRestrictedGroupingsModalComponent,
        Prism1MetricEditComponent,
        Prism1MetricImportComponent,
        Prism1MetricListComponent,
        Prism1MetricComponent,
        ViewMetricHistoryModalComponent,
        PrismNotificationsRootComponent,
        PrismNotificationsComponent,
        PrismUsageComponent,
        AtlasClaraChatHistoryComponent,
        ViewEnvironmentUsageModalComponent,
        ViewFilterPerfEnvironmentModalComponent,
        ViewClientUsageModalComponent,
        ProductListComponent,
        ProductComponent,
        ProductAddEditComponent,
        ProductBundlesListComponent,
        ProductBundlesComponent,
        CcgFeatureEditComponent,
        CcgFeatureListComponent,
        CcgFeatureComponent,
        ProgramEpisodeEditComponent,
        ProgramEpisodeListComponent,
        ProgramEpisodeComponent,
        ProgramModelListComponent,
        ProgramModelComponent,
        ProgramModelEditComponent,
        VersionHistoryComponent,
        VersionHistoryListComponent,
        PatchManagementAddEditModalComponent,
        PatchManagementComponent,
        PatchManagementListComponent,
        PatchManagementPreviewModalComponent,
        EditReleaseNotesComponent,
        PreviewReleaseNotesComponent,
        PublishReleaseNotesComponent,
        ReleasesComponent,
        QueryPerformanceComponent,
        FilterPerformanceComponent,
        DataProvisioningQuestionnaireComponent,
        ViewDataProvisioningQuestionnaireModalComponent,
        AthenaUsageComponent,
        QuerySettingsComponent,
        RedshiftClustersComponent,
        ReferenceDataSourcesEditComponent,
        ReferenceDataSourcesListComponent,
        ReferenceDataSourcesComponent,
        ClarifyProviderRevisionComponent,
        ClarifyProviderRevisionListComponent,
        ClarifyProviderRevisionEditComponent,
        DeequTestResultComponent,
        DeequTestResultListComponent,
        ViewReferenceDataSourceHistoryModalComponent,
        ViewDeequModalComponent,
        ViewDeequThresholdModalComponent,
        ViewTeraUsersModalComponent,
        ReferenceListItemsListComponent,
        ReferenceListItemsComponent,
        ReferenceListItemEditComponent,
        ReleaseNotesComponent,
        SalesforceSyncModalComponent,
        SalesforceSyncStatusComponent,
        ProductConfigurationContentSyncStatusComponent,
        ProductConfigurationContentSyncModalComponent,
        TerraTestResultEditComponent,
        TerraTestResultsComponent,
        TerraTestResultsRootComponent,
        UserListComponent,
        UserEngagementComponent,
        UserDbLoginSetupComponent,
        UserRoleComponent,
        UserRoleListComponent,
        UserRoleEditComponent,
        AlertComponent,
        ErrorLogModalComponent,
        OptimizationHintModalComponent,
        CCGDesignerComponent,
        CcgDefinitionRootComponent,
        MarketShareMetaEditComponent,
        MarketShareMetaListComponent,
        MarketShareMetaComponent,
        ViewPatientJourneyModalComponent,
        LoginComponent,
        CallbackComponent,
        ClientEditComponent,
        ClientListComponent,
        ClientComponent,
        MenuPipe,
        FilterPipe,
        ProgressSpinnerDirective,
        AuthorizeDirective,
        NgxTableFilterSelectDirective,
        FillHeightDirective,
        NgxTableResizeInitiatorDirective,
        PipelineHistoryChartModelComponent,
        PayerRatesMetaListComponent,
        PayerRatesMetaComponent,
        PayerRatesMetaEditComponent,
        ViewPayerRatesMetaHistoryComponent,
        QATestResultsComponent,
        QATestResultsRootComponent,
        QATestResultsProductDetailComponent,
        QATestResultsSubmoduleDetailComponent,
        PerformanceTestResultsDashboardProductsDetail,
        ScheduledScalingStateModelComponent,
        FooterComponent,
        ClarifyInsightsLandingPageComponent,
        ClarifyInsightsProvisionCustomerModalComponent,
        ClarifyInsightsFinalizeCustomerModalComponent,
        ClarifyInsightsFilterListComponent,
        ClarifyInsightsFilterComponent,
        ClarifyInsightsFilterEditComponent,
        ClarifyInsightsFilterHistoryComponent,
        ClarifyInsightsTriggerTransferModalComponent,
        ClarifyInsightsTriggerExtractModalComponent,
        ClarifyInsightsDeliveryHubComponent,
        ClarifyInsightsCreateDeliveryModalComponent,
        DataDashboardComponent,
        ApiPerformanceTestResultsDashboardProductsDetail,
        ClarifySwimlaneComponent,
        ApiPerformanceTestResultsDashboardProductsDetailModalComponent,
        CypressFeatureTestResultDetail,
        ScratchDatabaseCleanupComponent,
        ScratchDatabaseCleanupRootComponent,
        SetTableConfigModalComponent,
        AutomatedGoldPulldownListComponent,
        AutomatedGoldPulldownBranchInfoComponent,
        AutomatedGoldPulldownComponent,
        StageSourceTableListComponent,
        StageSourceTableColumnListComponent,
        StageSourceTableComponent,
        DataReleaseManagerComponent,
        DataReleaseManagerRootComponent,
        CreateDataReleaseComponent,
        RunDataReleaseComponent,
        EditDataReleaseComponent,
        DataAiChatComponent,
        ClarifyBotRootComponent,
        ClarifyBotComponent,
        AdvanceMetaDataComponent,
        AdvanceMetaDataListComponent,
        AdvanceMetaDataEditComponent,
        DagLaunchConfirmationModalComponent,
        HospitalRatesLandingPageRootComponent,
        HospitalRatesLandingComponent,
        HospitalRatesAddHospitalModalComponent,
        QATestResultsTabAndTileDetailComponent,
        HospitalRatesBulkAddComponent,
        ViewAiAnalysisModalComponent,
        AdditionalInfoModalComponent,
        ViewVersionHistoryModalComponent,
        ClarifyReportsComponent,
        ClarifyReportsListComponent,
        ReferenceTableUsageModalComponent,
        ClarifyInsightsDataEvaluationUserComponent,
        ClarifyInsightsDataEvaluationUserProvisionModalComponent,
        ClarifyInsightsDataEvaluationUserPasswordResetModalComponent,
        ViewReleaseImpactComponent,
        ViewAiChatModalComponent,
        CcgReferenceDataComponent,
        CcgReferenceDataListComponent,
        PceMetaDataComponent,
        PceMetaDataListComponent,
        PceMetaDataEditComponent,
        ClaraIqReleaseNotesComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [
        AppComponent
    ], imports: [BrowserModule,
        CommonModule,
        // Configures the `initial` rule, which chooses the state or URL to activate when the application initially starts, and no other routes matched.
        // Configures the `otherwise` rule, which chooses the state or URL to activate when no other routes matched.
        UIRouterModule.forRoot({
            states: APP_STATES,
            useHash: false,
            initial: { state: 'app' },
            otherwise: { state: 'argos.404' },
            config: <any>routerConfigFn
        }),
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        ResourceModule.forRoot(),
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatRippleModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        ClipboardModule,
        MatTooltipModule,
        MatToolbarModule,
        MatTreeModule,
        MatMenuModule,
        MatDividerModule,
        DragDropModule,
        MatSnackBarModule,
        ObserversModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgMultiselectDropdown3Module,
        AngularSvgIconModule.forRoot(),
        NgxGraphModule], providers: [
        MenuPipe,
        AlertService,
        FileService,
        ArgosStoreService,
        Broadcaster,
        NgDataAccess,
        ObservationExceptionService,
        MD5Service,
        AuthService,
        APIErrorService,
        FilterPipe,
        LoginService,
        ChartService,
        MaterialUtil,
        ValidationService,
        ResourceHandlerHttpClient,
        PlatformReleaseResultsService,
        ExportToExcelService,
        UserRoleService,
        UtilsService,
        UrlService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoopBackAuthRequestInterceptor,
            multi: true
        },
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS,
            useValue: { disabled: true }
        },
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA]
            }
        },
        {
            provide: MAT_DIALOG_DATA,
            useValue: {}
        },
        EcsAppListShare,
        EcsAppActivityLog,
        EcsAppAlarms,
        EcsAppAWS,
        EcsAppEcsService,
        EcsAppEnvironment,
        EcsAppEvent,
        EcsAppRestart,
        EcsAppRow,
        EcsAppService,
        EcsAppShare,
        EcsAppTask,
        EcsAppValid,
        EcsAppPatch,
        EnvironmentService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
