import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IAnalyzeObservationModalProps, IAnalyzeObservationModalStates } from './analyzeObservationModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { AnalyzeObservationModalService } from './analyzeObservationModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'argos-analyzeObservationModal',
    templateUrl: './analyzeObservationModal.component.html',
    providers: [AnalyzeObservationModalService]
})

export class AnalyzeObservationModalComponent extends ReactComponentBase<IAnalyzeObservationModalProps, IAnalyzeObservationModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: AnalyzeObservationModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<AnalyzeObservationModalComponent>) {
        super({ loading: true } as IAnalyzeObservationModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.props, this.states);
        this.setStates(newStates);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        const newStates = await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.setStates(newStates);
    }

    close() {
        this.dialogRef.close({ eventName: 'cancel' });
    }
}
