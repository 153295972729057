import { Injectable } from '@angular/core';
import { IModelFeaturesListStates, IModelFeaturesListService } from './modelFeaturesList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
@Injectable()
export class ModelFeaturesListService implements IModelFeaturesListService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(states: IModelFeaturesListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async saveRowHandler(row: any) {
        await this.dataAccess.genericUpsert({
            model: 'ModelFeature',
            data: row
        });
    }

    async activate(states: IModelFeaturesListStates) {
        const modelFeatures = await this.dataAccess.genericFind({
            model: 'ModelFeature'
        });
        states.modelFeatures = _.orderBy(modelFeatures, ['name']);
        states.table.data = states.modelFeatures;
        // this.setTablePageSize(states);
    }

    setTablePageSize(states: IModelFeaturesListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.modelFeatures.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }
}
