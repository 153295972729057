
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ArgosStoreService } from '../services/argosStore.service';
declare let Auth0Lock: any;

@Injectable()
export class LoginService {
    constructor(private argosStore: ArgosStoreService, private http: HttpClient) {
    }

    lock: any;

    async init() {
        if (_.isUndefined(this.lock)) {
            const tenant: any = await this.http.get('/tenant').toPromise();
            this.lock = new Auth0Lock(tenant.ARGOS_AUTH0_CLIENT_ID, tenant.ARGOS_AUTH0_DOMAIN, {
                configurationBaseUrl: tenant.ARGOS_AUTH0_CDN_URL || 'https://cdn.auth0.com',
                _enableIdPInitiatedLogin: true,
                allowSignUp: false,
                closable: false,
                avatar: null,
                rememberLastLogin: false,
                auth: {
                    redirectUrl: window.location.origin + '/callback',
                    responseType: 'token id_token',
                    params: {
                        scope: 'openid name email identities profile',
                    }
                },
                theme: {
                    logo: 'img/logo.png'
                },
                languageDictionary: {
                    title: ''
                }
            });
        }
    }

    authenticate() {
        this.lock.show();
    }

    clearCache() {
        this.argosStore.removeItem('token');
        this.argosStore.removeItem('username');
        this.argosStore.removeItem('profile');
        this.argosStore.removeItem('roleName');
        this.argosStore.removeItem('gridSettings');
        this.argosStore.removeItem('rolePermissionType');
        this.argosStore.removeItem('userRoleScreenAccess');
        this.argosStore.removeItem('currentDatabaseInfo');
    }

    logout() {
        this.clearCache();
        this.lock.logout({
            returnTo: window.location.origin
        });
    }

    isAdmin() {
        if (this.isInactive()) {
            return false;
        } else {
            const roles = this.argosStore.getItem('roles') || {};
            return (roles.admin === true);
        }
    }

    isInactive() {
        const roles = this.argosStore.getItem('roles') || {};
        return (roles.inactive === true);
    }

    hasRole(roleName: any) {
        if (this.isInactive()) {
            return false;
        } else {
            const roles = this.argosStore.getItem('roles') || {};
            return (roles[roleName] === true || this.isAdmin());
        }
    }
}
