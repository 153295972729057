import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IValidValueQueriesListStates, IValidValueQueriesListService } from './validValueQueriesList.component.d';
import { NgDataAccess } from '../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EditValidValueQueryModalComponent } from './editValidValueQueryModal.component';
import { ArgosStoreService } from '../../services/argosStore.service';
import { UIRouter } from '@uirouter/core';
import { UrlService } from 'client/app/services/url.service';

@Injectable()
export class ValidValueQueriesListService implements IValidValueQueriesListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef, private uiRouter: UIRouter, private urlService: UrlService) {
        //
    }
    async initDelegate(states: IValidValueQueriesListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IValidValueQueriesListStates) {

        const validValueQueries = await this.dataAccess.genericFind({
            model: 'ValidValuesQuery'
        });

        states.validValueQueries = _.filter(validValueQueries, { supportsCache: false });
        states.cacheGroupings = _.filter(validValueQueries, { supportsCache: true });

        states.queryMatTable.data = _.orderBy(states.validValueQueries, ['name']);
        states.cacheMatTable.data = _.orderBy(states.cacheGroupings, ['name']);

    }

    addOrEditValidValueQueryHandler(states: IValidValueQueriesListStates, cb: Function, row: any, supportsCache: boolean) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    validValueQueryName: row?.name,
                    supportsCache,
                    editMode: !_.isUndefined(row)
                }
            }
        };

        const modalInstance: MatDialogRef<any> = this.matDialog.open(EditValidValueQueryModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (event: any) => {
            if (event && event.eventName === 'apply') {
                await this.activate(states);
            }
            cb();
        });
    }
}
