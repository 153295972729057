import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IEditValidValueQueryModalProps, IEditValidValueQueryModalStates } from './editValidValueQueryModal.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { EditValidValueQueryModalService } from './editValidValueQueryModal.component.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-valid-value-query-modal',
    templateUrl: './editValidValueQueryModal.component.html',
    providers: [EditValidValueQueryModalService]
})

export class EditValidValueQueryModalComponent extends ReactComponentBase<IEditValidValueQueryModalProps, IEditValidValueQueryModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: EditValidValueQueryModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<EditValidValueQueryModalComponent>, private cdr: ChangeDetectorRef) {
        super({
            validValueQuery: {}
        } as IEditValidValueQueryModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    cancelModal(eventName = 'cancel') {
        this.dialogRef.close({ eventName, data: this.states.validValueQuery });
    }

    async save() {
        await this.svc.saveHandler(this.states);
        this.cancelModal('apply');
    }
}
