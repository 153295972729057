import { Injectable } from '@angular/core';
import { IAtlasClaraChatHistoryStates, IAtlasClaraChatHistoryService } from './atlasClaraChatHistory.component.d';
import { NgDataAccess } from '../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class AtlasClaraChatHistoryService implements IAtlasClaraChatHistoryService {
    constructor(private dataAccess: NgDataAccess,
        private state: StateService, private alertSvc: AlertService) {
        //
    }
    async initDelegate(states: IAtlasClaraChatHistoryStates): Promise<object> {
        states.queryRunning = true;
        states.conversationTypes = [
            { id: 'functionParam', name: 'Analyses (Function Param)' },
            { id: 'clarifyBot', name: 'Documentation (Clarify Bot)' },
            { id: 'physicianFinder', name: 'Physician Finder' },
            { id: 'anthropic', name: 'Stories (Anthropic)' },
            { id: 'chatGPT', name: 'Stories (Chat GPT)' },
            { id: 'cohorts', name: 'Cohorts' }
        ];
        states.conversationType = _.get(states, 'conversationTypes[0].id');
        states.screenMode = 'questionsOverview';

        states.questionsOveriewColumns = [
            { columnName: 'question', title: 'Question' },
            { columnName: 'total_asks', title: 'Total Asks' },
            { columnName: 'clarify_asks', title: 'Clarify Asks' },
            { columnName: 'client_asks', title: 'Client Asks' },
            { columnName: 'vote_count', title: 'Votes' },
            { columnName: 'up_count', title: 'Ups' },
            { columnName: 'down_count', title: 'Downs' },
            { columnName: 'feedback_count', title: 'Feedback' },
            { columnName: 'environment_count', title: 'Environments' },
            { columnName: 'first_ask_date', title: 'First Asked' },
            { columnName: 'last_ask_date', title: 'Last Asked' }
        ];
        await this.getQuestionsOverview(states);

        states.questionHistoryColumns = [
            { columnName: 'asked_date', title: 'Ask Date' },
            { columnName: 'client', title: 'Client' },
            { columnName: 'environment', title: 'Environment' },
            { columnName: 'created_by', title: 'Asked By' },
            { columnName: 'vote', title: 'Vote' },
            { columnName: 'feedback_type', title: 'Feedback Type' },
            { columnName: 'feedback_text', title: 'Feedback' }
        ];

        return {};
    }

    async getQuestionsOverview(states: IAtlasClaraChatHistoryStates) {
        states.queryRunning = true;

        try {
            const result = await this.dataAccess.genericMethod({
                model: 'ClaraChatHistory', method: 'getQuestionsOverview',
                parameters: { 
                    conversationType: states.conversationType || 'functionParam'
                }
            });

            states.questionsOverview = result;

            this.setScreenMode('questionsOverview', states);      
            states.queryRunning = false;
            
        } catch (error) {
            this.alertSvc.handleError(error);
            states.queryRunning = false;
        }
    }

    sortData(event: any, states: IAtlasClaraChatHistoryStates) {
        if (event?.active) {
            // states.questionsOverview = _.orderBy(states.questionsOverview, event.active, event.direction);
            states.questionsOverview = _.orderBy(states.questionsOverview, (q: any) => {
                return _.isNaN(_.toNumber(q[event.active])) ? _.toLower(q[event.active]) : _.toNumber(q[event.active]);
            }, [event.direction]);
        }
    }

    async viewHistory(question: any, states: IAtlasClaraChatHistoryStates) {
        states.queryRunning = true;

        try {
            states.selectedQuestion = question;

            const result = await this.dataAccess.genericMethod({
                model: 'ClaraChatHistory', method: 'getQuestionHistory',
                parameters: { 
                    conversationType: states.conversationType || 'functionParam',
                    question
                }
            });

            states.questionHistory = result;

            this.setScreenMode('questionHistory', states);

            states.queryRunning = false;
        } catch (error) {
            this.alertSvc.handleError(error);
            states.queryRunning = false;
        }
    }

    viewHistoryJSON(historyInstance: any, states: IAtlasClaraChatHistoryStates) {
        states.selectedHistory = historyInstance;
        this.setScreenMode('selectedHistory', states);
    }

    setScreenMode(screeMode: string, states: IAtlasClaraChatHistoryStates) {
        states.screenMode = screeMode;
    }

    async syncUsage(states: IAtlasClaraChatHistoryStates) {
        states.queryRunning = true;

        try {
            const result = await this.dataAccess.genericMethod({
                model: 'ClaraChatHistory', method: 'initializeClaraChatHistorySync',
                parameters: {}
            });

            states.queryRunning = false;

        } catch (error) {
            this.alertSvc.handleError(error);
            states.queryRunning = false;
        }
    }

}
