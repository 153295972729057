import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IPrism1MetricListStates, IPrism1MetricListService } from './prism1MetricList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewEnvironmentUsageModalComponent } from '../../prismUsage/viewEnvironmentUsageModal/viewEnvironmentUsageModal.component';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
@Injectable()
export class Prism1MetricListService implements IPrism1MetricListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private exportToExcelSvc: ExportToExcelService,
        private argosStore: ArgosStoreService, private cdr: ChangeDetectorRef) {
        //
    }
    async initDelegate(states: IPrism1MetricListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IPrism1MetricListStates) {
        const productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle'
        });
        const metrics = await this.dataAccess.genericFind({
            model: 'OtherMetric',
            filter: { order: 'name' }
        });
        const careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping'
        });
        states.productBundles = productBundles;
        states.metrics = _.map(metrics, function (metric: any) {
            metric.careGroupings = _.join(_.orderBy(_.map(metric.validCareGroupings, function (validCareGrouping) {
                return _.get(_.find(careGroupings, { name: validCareGrouping }), 'title');
            })), ', ');
            metric.productBundleList = _.join(_.map(metric.productBundles, function (productBundle) {
                return _.get(_.find(states.productBundles, { shortName: productBundle }), 'title');
            }), ', ');

            return metric;
        });

        states.table.data = states.metrics;

        const usages = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage',
            method: 'aggregateUsageResults',
            parameters: {
                metaDataTypes: ['metric']
            }
        });

        const markForDeletions = await this.dataAccess.genericFind({
            model: 'MarkForDeletion',
            filter: {
                where: {
                    objectType: 'otherMetric'
                }
            }
        });

        const metricHistory = await this.dataAccess.genericFind({
            model: 'ViewOtherMetricHistoryLatest',
            filter: {}
        });

        for (const metric of states.table.data) {
            const usage = _.find(usages, { meta_data_name: metric.name });
            const history = _.find(metricHistory, { metricId: metric.id });
            metric.metricUsage = _.toNumber(_.get(usage, 'total_usage', 0));
            metric.metricViews = _.toNumber(_.get(usage, 'total_views', 0));
            metric.lastViewed = _.get(usage, 'last_viewed');

            metric.lastUpdatedBy = _.get(history, 'lastUpdatedBy');
            metric.lastUpdateDate = _.get(history, 'lastUpdateDate');
            if (metric.lastUpdateDate) {
                metric.lastUpdateDateFromNow = moment(metric.lastUpdateDate).fromNow();
            } else {
                metric.lastUpdateDateFromNow = '-';
            }

            metric.excludeFromSyncText = metric.excludeFromSync ? 'Yes' : 'No';
            if (metric.lastViewed) {
                metric.lastViewedFromNow = moment(metric.lastViewed).fromNow();
            } else {
                metric.lastViewedFromNow = '-';
                // set these to sort last
                metric.lastViewed = '';
            }

            const markForDeletion: any = _.find(markForDeletions, { objectName: metric.name });
            if (markForDeletion) {
                metric.markForDeletionId = markForDeletion.id;
            }
        }
    }

    setTablePageSize(states: IPrism1MetricListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.metrics.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    viewEnvironments(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    metaDataTypes: ['metric'],
                    metaDataNames: [row.name]
                }
            }
        };
        this.matDialog.open(ViewEnvironmentUsageModalComponent, dialogConfig);
    }

    exportToExcel(states: IPrism1MetricListStates) {
        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile('groupings', columns, data);
    }

    markForDeletion(row: any) {
        swal({
            title: 'Are you sure?',
            text: `Do you want to delete metric ${row.name}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const newRow = await this.dataAccess.genericUpsert({
                    model: 'MarkForDeletion',
                    data: {
                        objectType: 'otherMetric',
                        objectName: row.name,
                        objectDefinition: row,
                        deletedBy: this.argosStore.getItem('username'),
                        deletedDate: new Date()
                    }
                });
                row.markForDeletionId = newRow.id;
                this.cdr.detectChanges();
            }
        });
    }

    async undelete(row: any) {
        if (row && row.markForDeletionId) {
            await this.dataAccess.genericMethod({
                model: 'MarkForDeletion',
                method: 'destroyById',
                parameters: {
                    id: row.markForDeletionId
                }
            });
            delete row.markForDeletionId;
            this.cdr.detectChanges();
        }
    }

    openMatTableSettings(states: IPrism1MetricListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };

        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe((result: any) => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
