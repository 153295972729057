import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewDeequThresholdModalProps, IViewDeequThresholdModalStates } from './viewDeequThresholdModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewDeequThresholdModalService } from './viewDeequThresholdModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationService } from '../../../services/validationService.service';


@Component({
    selector: 'argos-viewDeequThresholdModal',
    templateUrl: './viewDeequThresholdModal.component.html',
    providers: [ViewDeequThresholdModalService]
})

export class ViewDeequThresholdModalComponent
    extends ReactComponentBase<IViewDeequThresholdModalProps, IViewDeequThresholdModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewDeequThresholdModalService, private validationSrv: ValidationService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewDeequThresholdModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close({});
    }

    saveDelegate() {
        this.svc.saveDelegate(this.states);
    }

    argsUpdate(arg: any) {
        this.svc.argsUpdate(arg, this.states);
    }

}
