import { Injectable } from '@angular/core';
import { IMarketShareMetaListStates, IMarketShareMetaListService } from './marketShareMetaList.component.d';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ViewPatientJourneyModalComponent } from '../patientJourneyModal/viewPatientJourneyModal.component';
import * as _ from 'lodash';

@Injectable()
export class MarketShareMetaListService implements IMarketShareMetaListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog) {
        //
    }
    async initDelegate(states: IMarketShareMetaListStates): Promise<Object> {
        await this.activate(states);
        return {};
    }
    async activate(states: IMarketShareMetaListStates) {
        states.marketShareMeta = await this.dataAccess.genericFind({
            model: 'MarketShareSourceTableMetadata',
            filter: {
                fields: ['id', 'source_table', 'parameters'],
                order: 'source_table'
            }
        });
        states.dataTable = _.orderBy(states.marketShareMeta, ['name']);
    }

    launchPatientJourneyModal() {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {}
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewPatientJourneyModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async  (result: any) => {
            this.matDialog.closeAll();
        });
    }
}
