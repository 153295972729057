import { Injectable } from '@angular/core';
import { IQaTestResultStates, IQaTestResultService } from './qaTestResultDashboard.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';
import { MatTableDataSource } from '@angular/material/table';
import { PlatformReleaseResultsService } from '../../../services/platformReleaseResultsService.service';

@Injectable()
export class QaTestResultService implements IQaTestResultService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter, private platformReleaseResultsService: PlatformReleaseResultsService,
        private state: StateService) {
        //
    }

    async initDelegate(states: IQaTestResultStates): Promise<object> {
        states.activeTab = this.platformReleaseResultsService.getActiveTestResultsTab();
        await this.getReleaseDates(states);
        const latestReleaseDate = states.filters.releaseDateList[0];
        if (states.filters.releaseDateList.length) {
            const date = this.platformReleaseResultsService.getSelectedDate();
            const selecteddate = date + ' Platform';
            if (selecteddate !== latestReleaseDate && (window.location.pathname !== '/argos/platformReleaseTestResults')) {
                const releaseDate = _.find(states.filters.releaseDateList, (releaseDate) => {
                    return releaseDate === selecteddate;
                });
                if (releaseDate) {
                    states.selectedDate = releaseDate;
                }
            } else {
                states.selectedDate = latestReleaseDate;
            }
            await this.fetchResults(states, '', states.selectedDate);
        } else {
            await this.fetchResults(states);
        }
        this.setPerformanceTestSubTableProducts(states);
        this.setApiPerformanceTestSubTableProducts(states);
        this.getCustomerEnvWithNoUsers(states);
        return {};
    }

    async getReleaseDates(states: IQaTestResultStates) {
        const pctResults = await this.dataAccess.genericFind({
            model: 'ProductionConfigurationTestResults',
            filter: {

            }
        });
        const release_date: (string | null)[] = _.chain(pctResults).uniqBy('release_date')
            .filter((item: { release_date: string | null }) => item.release_date !== null)
            .uniqBy('release_date').map('release_date').value() as (string | null)[];

        states.filters.releaseDateList = release_date.sort().reverse();
        console.log(states.filters.releaseDateList)
        
    }

    async fetchResults(states: IQaTestResultStates, filterProp = '', filterValue = '') {
        if (filterValue) {
            this.platformReleaseResultsService.setSelectedDate(filterValue);
        }
        const whereFilter = filterValue ? { where: { release_date: filterValue } } : {};
        const pctResults = await this.dataAccess.genericFind({
            model: 'ProductionConfigurationTestResults',
            filter: whereFilter
        });
        const performanceTestResults = await this.dataAccess.genericFind({
            model: 'PerformanceTestResults',
            filter: {},
        });
        const apiPerformanceTestResults = await this.dataAccess.genericFind({
            model: 'ApiPerformanceTestResults',
            filter: {},
        });

        const cypressTestResults = await this.dataAccess.genericFind({
            model: 'CypressTestResults',
            filter: {
                order: 'test_ran_at desc'
            },
        });

        states.pctTestResults = _.uniqBy(pctResults, 'product_name');
        states.pctDataTable.data = _.orderBy(states.pctTestResults, ['product_name']);
        states.performanceTestResults = _.orderBy(performanceTestResults, ['product']);
        states.performanceTestDataTable.data = _.orderBy(states.performanceTestResults, ['product']);
        const productNameObjs = _.uniqBy(states.performanceTestDataTable.data, 'product');
        const productName = _.map(productNameObjs, 'product');
        states.performanceTestDataTable.data = productName;

        // set states for api performance test results
        states.apiPerformanceTestResults = _.orderBy(apiPerformanceTestResults, ['product_name']);
        states.apiPerformanceTestDataTable.data = _.orderBy(states.apiPerformanceTestResults, ['product_name']);
        const productNameObj = _.uniqBy(states.apiPerformanceTestDataTable.data, 'product_name');
        const productNames = _.map(productNameObj, 'product_name');
        states.apiPerformanceTestDataTable.data = productNames;

        // set states for cypress test results
        states.cypressTestResults = cypressTestResults;

        let featureName = _(states.cypressTestResults).uniqBy('feature_grouping').map('feature_grouping').value();
        featureName[featureName.indexOf('')] = 'Pending' // Set blank feature name to Pending

        const featurePassed: any = _.chain(states.cypressTestResults).filter(res => res.status === "passed").groupBy('feature_grouping').value();
        const featureFailed: any = _.chain(states.cypressTestResults).filter(res => res.status === "failed").groupBy('feature_grouping').value();
        const featurePending: any = _.chain(states.cypressTestResults)
            .filter(res => res.status === "pending")
            .forEach(res => res.feature_grouping="Pending")
            .groupBy('feature_grouping')
            .value();

        let cypressResultsByFeature = [] as any;
        _.forEach(featureName, feature => {
            // Get latest run date, then only show latest run dates
            let latestRunDate = Object.keys(featurePassed).includes(feature as string) 
                ? new Date(featurePassed[feature as string][0].test_ran_at).toLocaleDateString('es-pa')
                : '';

            let passes = Object.keys(featurePassed).includes(feature as string) 
                ? featurePassed[feature as string]
                    .filter((pass: any) => new Date(pass.test_ran_at).toLocaleDateString('es-pa') === latestRunDate)
                : [];
            let failures = Object.keys(featureFailed).includes(feature as string) 
                ? featureFailed[feature as string]
                    .filter((pass: any) => new Date(pass.test_ran_at).toLocaleDateString('es-pa') === latestRunDate)
                : [];
            let pendings = Object.keys(featurePending).includes(feature as string) 
                ? featurePending[feature as string] // Pending has no "test_ran_at" value
                : [];

            cypressResultsByFeature.push({ feature, passes: passes.length, failures: failures.length, pendings: pendings.length, latestRunDate })
        })
        
        const totalCypressTests = states.cypressTestResults.length;
        states.cypressTestResults = [{ 
            passes: (((_.chain(states.cypressTestResults).filter(res => res.status === "passed").value().length)/totalCypressTests)*100).toFixed() + '%',
            failures: (((_.chain(states.cypressTestResults).filter(res => res.status === "failed").value().length)/totalCypressTests)*100).toFixed() + '%',
            pending: (((_.chain(states.cypressTestResults).filter(res => res.status === "pending").value().length)/totalCypressTests)*100).toFixed() + '%'
        }];
        states.cypressTestDataTable.data = cypressResultsByFeature;
    }

    async calculatePctResultsPercentageByProductName(productName: string, states: IQaTestResultStates) {
        const dateFilter = this.platformReleaseResultsService.getSelectedDate();
        const requestPayload: any = {
            product_name: productName
        };
        if (dateFilter) {
            requestPayload.release_date = dateFilter;
        }
        const pctResults = await this.dataAccess.genericFind({
            model: 'ProductionConfigurationTestResults',
            filter: {
                where: requestPayload
            }
        });
        const groupedByCustomerName = _.groupBy(pctResults, 'product_name');
        const totalTests = groupedByCustomerName[productName].length;
        const passedTests = _.filter(groupedByCustomerName[productName], { status: 'PASS' }).length;
        const passedPCTPercentage = ((passedTests / totalTests) * 100).toFixed(1) + '%';
        const failedPCTPercentage = (((totalTests - passedTests) / totalTests) * 100).toFixed(1) + '%';
        states.pctSubTable = new MatTableDataSource([{
            productName,
            passedPCTPercentage,
            failedPCTPercentage
        }]);
        states.pctProductCollapse = productName;
    }

    setPerformanceTestSubTableProducts(states: IQaTestResultStates) {
        const productGroupedBy = _.groupBy(states.performanceTestResults, 'product');
        const productKeysGrouped = _.keys(productGroupedBy);
        let groupedResults = [] as any;
        _.forEach(productKeysGrouped, productKey => {
            const moduleGroupedBy = productGroupedBy[productKey].reduce((res, obj) => {
                const module = obj.module;
                if (module)
                {if (res[module])
                {res[module].push(obj);}
                else
                {res[module] = [obj];}}
                return res;
            }, {});

            const moduleGroupedByKeys = Object.keys(moduleGroupedBy);
            _.forEach(moduleGroupedByKeys, moduleKey => {
                const resObj = {} as any;
                resObj['product_name'] = productKey;
                resObj['module_name'] = moduleKey;
                groupedResults.push(resObj);
            });
        });
        groupedResults = _.orderBy(groupedResults, ['product_name', 'module_name']);

        states.performanceSubTable.data = new MatTableDataSource(groupedResults);
        states.performanceTestResultsDetails = groupedResults;
    }

    setApiPerformanceTestSubTableProducts(states: IQaTestResultStates) {
        const productGroupedBy = _.groupBy(states.apiPerformanceTestResults, 'product_name');
        const productKeysGrouped = _.keys(productGroupedBy);
        let groupedApiResults = [] as any;

        _.forEach(productKeysGrouped, productKey => {
            const moduleGroupedBy = productGroupedBy[productKey].reduce((res, obj) => {
                const module = obj.module_name;
                if (res[module])
                {res[module].push(obj);}
                else
                {res[module] = [obj];}
                return res;
            }, {});

            const moduleGroupedByKeys = Object.keys(moduleGroupedBy);
            _.forEach(moduleGroupedByKeys, moduleKey => {
                const resObj = {} as any;
                resObj['product_name'] = productKey;
                resObj['module_name'] = moduleKey;
                groupedApiResults.push(resObj);
            });
        });

        groupedApiResults = _.orderBy(groupedApiResults, ['product_name', 'module_name']);

        states.apiPerformanceSubTable.data = new MatTableDataSource(groupedApiResults);
        states.apiPerformanceTestResultsDetails = groupedApiResults;
    }

    setSelectedTab(index: number) {
        this.platformReleaseResultsService.setActiveTestResultsTab(index);
    }

    async getCustomerEnvWithNoUsers(states: IQaTestResultStates) {
        const pctResults = await this.dataAccess.genericFind({
            model: 'ProductionConfigurationTestResults',
            filter: {
                where: {
                    dashbaord_name: null,
                    status: null,
                    release_date: null
                }
            }
        });
        states.noActiveUsersCustomerEnvs = pctResults;
    }

}
