import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IProductListStates, IProductListService } from './productList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewClientUsageModalComponent } from '../../prismUsage/viewClientUsageModel/viewClientUsageModal.component';
import * as _ from 'lodash';
@Injectable()
export class ProductListService implements IProductListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private changeDetection: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService) {
        //
    }

    async initDelegate(states: IProductListStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IProductListStates) {
        states.productConfigs = await this.dataAccess.genericFind({
            model: 'ProductConfiguration'
        });
        states.productConfigs = states.productConfigs || [];

        const clientEnvSelectedProducts = await this.dataAccess.genericFind({
            model: 'ClientProductConfiguration',
            filter: {
                include: {
                    relation: 'client', // include the owner object
                    scope: { // further filter the owner object
                        include: { // include environments for each client
                            relation: 'environments'
                        }
                    }
                }
            }
        });

        _.forEach(states.productConfigs, (product: any) => {
            const clientProducts = _.map(_.filter(clientEnvSelectedProducts, { productConfiguration: product.name }), (clientProduct: any) => {

                const result = _.map(_.filter(clientProduct.client?.environments, (e: any) => { return (!e.isDisabled && !e.isArchived); }), (env: any) => {
                    const envInfo = {
                        clientName: clientProduct.client.name,
                        clientId: clientProduct.client.id,
                        environmentId: env.id,
                        environmentType: env.environmentType,
                        url: env.dnsCname + '.clarifyhealth.' + env.domainSuffix,
                        ClientProductConfigurationId: clientProduct.id,
                        productName: product.name
                    };

                    _.set(envInfo, 'productBundles', []);

                    if (product.config.productBundles) {
                        _.set(envInfo, 'productBundles', product.config.productBundles);
                    }

                    return envInfo;
                });

                return result;
            });

            const clientEnvProducts = _.flatten(_.filter(clientProducts, (cp: any) => { return cp.length > 0; }));
            product.clientProducts = _.orderBy(clientEnvProducts, ['clientName', 'url'], ['asc']);
        });

        states.productListTable.data = states.productConfigs;
    }

    exportToExcel(states: IProductListStates) {

        const data = states.productListTable.data;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IProductListStates) {

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-large',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                tableColumns: states.matTableColumns,
                defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
                settingName: states.matTableSettingName
            }
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, dialogConfig);

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe((result: any) => {
            setTimeout(() => {
                this.changeDetection.detectChanges();
            }, 1000);
        });
    }

    viewClientUsage(row: any, states: IProductListStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-large',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    productName: row.title,
                    clientInfo: row.clientProducts
                }
            }
        };
        this.matDialog.open(ViewClientUsageModalComponent, dialogConfig);
    }
}
