import { Injectable } from '@angular/core';
import { IAnalyzeObservationModalProps, IAnalyzeObservationModalStates, IAnalyzeObservationModalService } from './analyzeObservationModal.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from '../../../services/dataAccess.service';
@Injectable()
export class AnalyzeObservationModalService implements IAnalyzeObservationModalService {

    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(props: IAnalyzeObservationModalProps, states: IAnalyzeObservationModalStates): Promise<IAnalyzeObservationModalStates> {
        states.observationTitle = props.observationTitle;
        states.observationType = props.observationType;
        states.fromYear = props.fromYear;
        states.toYear = props.toYear;
        states.observations = props.observations;
        const newStates = await this.initialize(states);
        return newStates;
    }

    async changeDelegate(oldProps: IAnalyzeObservationModalProps, newProps: IAnalyzeObservationModalProps, states: IAnalyzeObservationModalStates): Promise<IAnalyzeObservationModalStates> {
        const newStates = await this.initDelegate(newProps, states);
        return newStates;
    }

    async initialize(initStates: IAnalyzeObservationModalStates): Promise<IAnalyzeObservationModalStates> {
        const states = _.cloneDeep(initStates);
        const parameters = {
            fromYear: _.get(states, 'fromYear'),
            toYear: _.get(states, 'toYear'),
            observations: states.observations,
            observationType: states.observationType
        };

        const data = await this.dataAccess.genericMethod({
            model: 'ClarifyAttributedProviderCCG', method: 'getObservationAnalysisDetails', parameters: {
                body: { params: parameters }
            }
        });

        states.loading = false;
        states.data = data;
        return states;
    }
}
