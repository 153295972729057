import { Injectable } from '@angular/core';
import { ITerraTestResultEditStates, ITerraTestResultEditService } from './terraTestResultEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter, StateService } from '@uirouter/core';

@Injectable()
export class TerraTestResultEditService implements ITerraTestResultEditService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter,
        private state: StateService) {
        //
    }

    async initDelegate(states: ITerraTestResultEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: ITerraTestResultEditStates) {
        if (this.uiRouter.globals.params.id) {
            const result = await this.dataAccess.genericMethod({
                model: 'TerraTestResult', method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.id
                }
            });
            states.terraTest = result;

            this.activate(states);
        } else {
            this.state.go('argos.data.terratestresults.list');
        }
    }

    activate(states: ITerraTestResultEditStates) {
        const oParser = new DOMParser();
        const xmlDoc = oParser.parseFromString(states.terraTest.testsSummary, 'application/xml');
        const results = xmlDoc.getElementsByTagName('testsuite');

        for (let i = 0; i < results.length; i++) {
            states.terraTest.errors = results[i].getAttribute('errors');
            states.terraTest.failures = results[i].getAttribute('failures');
            states.terraTest.name = results[i].getAttribute('name');
            states.terraTest.skipped = results[i].getAttribute('skipped');
            states.terraTest.tests = results[i].getAttribute('test');
            states.terraTest.time = results[i].getAttribute('time');

            const tests = results[i].getElementsByTagName('testcase');
            states.terraTest.testcases = [];

            for (let j = 0; j < tests.length; j++) {
                const testcase: any = {};
                const tempTC = tests[j];
                testcase.classname = JSON.stringify(tempTC.getAttribute('classname')).replace(/"/g, '');
                testcase.file = JSON.stringify(tempTC.getAttribute('file')).replace(/"/g, '');
                testcase.line = JSON.stringify(tempTC.getAttribute('line')).replace(/"/g, '');
                testcase.name = JSON.stringify(tempTC.getAttribute('name')).replace(/"/g, '');
                testcase.time = JSON.stringify(tempTC.getAttribute('time')).replace(/"/g, '');
                testcase.failures = [];
                testcase.status = 'success';

                // now check if we have any failures
                const failures = tempTC.getElementsByTagName('failure');
                for (let k = 0; k < failures.length; k++) {
                    const tempFailure = failures[k];
                    testcase.failures.push({
                        message: JSON.stringify(tempFailure.getAttribute('message')).replace(/"/g, ''),
                        detail: tempFailure.textContent
                    }
                    );
                    testcase.status = 'failed';
                }

                states.terraTest.testcases.push(testcase);
            }
        }

        states.dataTable.data = _.orderBy(states.terraTest.testcases, ['name']);
    }

    isNumeric(value: string) {
        return /^-?\d+$/.test(value);
    }
}
