import { Injectable } from '@angular/core';
import { IMarketShareMetaEditStates, IMarketShareMetaEditService } from './marketShareMetaEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';

@Injectable()
export class MarketShareMetaEditService implements IMarketShareMetaEditService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private state: StateService) {
        //
    }
    async initDelegate(states: IMarketShareMetaEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IMarketShareMetaEditStates) {
        if (this.uiRouter.globals.params.id) {
            const marketShareMetas = await this.dataAccess.genericFind({
                model: 'MarketShareSourceTableMetadata',
                filter: {
                    fields: ['id', 'source_table', 'parameters']
                }
            });
            const marketShareMeta: any = _.find(marketShareMetas, { id: _.toNumber(this.uiRouter.globals.params.id) });
            states.marketShareMeta = _.cloneDeep(marketShareMeta);

            const teradromeTable = await this.dataAccess.genericFind({
                model: 'TeradromeTable',
                filter: {
                    include: 'teradromeColumn',
                    where: {
                        tableName: states.marketShareMeta.source_table.includes('patient_year') ? 'ccg_hybrid_patient_year' : states.marketShareMeta.source_table
                    }
                }
            });
            const selectColumns = _.orderBy(
                _.uniq(
                    _.flatten(
                        teradromeTable.map((table: any) => {
                            return table.teradromeColumn.map((column: any) => {
                                return {
                                    ..._.pick(column, ['id', 'columnName']),
                                    columnName: _.toLower(column.columnName) // Convert to lowercase
                                };
                            });
                        })
                    ).sort()
                ),
                'columnName'
            );
              
            // Filter out the columns that are already in the parameters
            states.selectColumns = selectColumns.filter((column: any) => {
                return !Object.keys(states.marketShareMeta.parameters).includes(column.columnName);
            });
        }
    }

    async save(states: IMarketShareMetaEditStates) {
        states.saveInProgress = true;
        const data = states.marketShareMeta;
        const result: any = await this.dataAccess.genericUpsert({
            model: 'MarketShareSourceTableMetadata',
            data
        });

        states.marketShareMeta.id = result.id;
        this.state.go('argos.modelMetaData.marketShareMeta.list');
        states.saveInProgress = false;
    }
}
