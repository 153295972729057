import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class ClarifyInsightsDataEvaluationUserProvisionModalService {

    constructor(private dataAccess: NgDataAccess) { 

    }

    async submit(email: string, firstName: string, lastName: string, userDuration: number, roles: any, password: string) {

        let expirationDate = userDuration ? new Date(new Date().valueOf() + userDuration * 24 * 60 * 60 * 1000) : null;

        const supersetReponse = await this.dataAccess.genericMethod({
            model: "ClarifyInsightsDataEvaluationUser",
            method: "createUser",
            parameters: {
                userInfo: {
                    email: email,
                    username: email,
                    first_name: firstName,
                    last_name: lastName,
                    password: password, 
                    roles: roles,
                    active: true
                }
            }
        });
        if (supersetReponse.success) {
            await this.dataAccess.genericCreate({
                model: "ClarifyInsightsDataEvaluationUser", 
                data: {
                    id: supersetReponse.message.id,
                    email: email,
                    username: email,
                    firstName: firstName,
                    lastName: lastName,
                    roles: roles,
                    active: true,
                    expirationDate: expirationDate
                }
            })
        }
        return supersetReponse;
    }
}
