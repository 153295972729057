import { Injectable } from '@angular/core';
import { ISetTableConfigModalProps, ISetTableConfigModalStates, ISetTableConfigModalService } from './setTablesConfigModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';

@Injectable()
export class SetTableConfigModalService implements ISetTableConfigModalService {
    constructor(private dataAccess: NgDataAccess) {}
    
    async initDelegate(props: ISetTableConfigModalProps, states: ISetTableConfigModalStates): Promise<object> {
        await this.init(props, states)
        return states;
    }

    async init(props: ISetTableConfigModalProps, states: ISetTableConfigModalStates) {
        states.tables.data = await this.getCurrentTableConfig(props, states)
        return states;
    }

    mapTableConfig(states: ISetTableConfigModalStates) {
        const tableConfig = states.tables.data.filter((table: any) => {
            return table.deleteTable == true;
        }).map((table: any) => {
            return {
                tableName: table.Name,
                deleteTable: table.deleteTable,
                updateTime: table.lastUpdateDate
            }
        });
        return tableConfig
    }

    async removeAllTables(props: ISetTableConfigModalProps, states: ISetTableConfigModalStates) {
        states.tableConfig = [];
        const results = await this.dataAccess.genericMethod({
            model: 'ScratchDatabaseCleanup',
            method: 'updateTableDeletionConfig',
            parameters: {
                database: props.database,
                tableConfig: states.tableConfig
            }
        });
        return (results) ? 'Table Cleanup Config Saved: All Tables Unselected' : 'Error: Config Not Saved'
    }

    async updateTableConfig(props: ISetTableConfigModalProps, states: ISetTableConfigModalStates) {
        states.tableConfig = this.mapTableConfig(states)
        const results = await this.dataAccess.genericMethod({
            model: 'ScratchDatabaseCleanup',
            method: 'updateTableDeletionConfig',
            parameters: {
                database: props.database,
                tableConfig: states.tableConfig
            }
        });
        return (results) ? 'Table Cleanup Config Saved' : 'Error: Config Not Saved'
    }

     async updateAllTables(props: ISetTableConfigModalProps, states: ISetTableConfigModalStates) {
        states.tableConfig = states.tables.data.map((table: any) => {
            return {
                tableName: table.Name,
                deleteTable: true,
                updateTime: table.lastUpdateDate
            }
        });
        const results = await this.dataAccess.genericMethod({
            model: 'ScratchDatabaseCleanup',
            method: 'updateTableDeletionConfig',
            parameters: {
                database: props.database,
                tableConfig: states.tableConfig
            }
        });
        return (results) ? 'Table Cleanup Config Saved: All Tables' : 'Error: Config Not Saved'
    }

    convertToReadableDate(timestampString: string) {
        const extractedDate = new Date(new Date(timestampString).toLocaleString('en-US', { timeZone: "UTC" })).getDate();
        const extractedMonth = new Date(new Date(timestampString).toLocaleString('en-US', { timeZone: "UTC" })).getMonth() + 1;
        const extractedYear = new Date(new Date(timestampString).toLocaleString('en-US', { timeZone: "UTC" })).getFullYear();
        let friendlyDate = extractedMonth + '/' + extractedDate + '/' + extractedYear;
        friendlyDate = (friendlyDate == '12/31/1969' || friendlyDate == 'NaN/NaN/NaN') ? 'Never' : friendlyDate;
        return friendlyDate;
    }

    async getCurrentTableConfig(props: ISetTableConfigModalProps, states: ISetTableConfigModalStates ) {
        states.queryRunning = true
        const results = await this.dataAccess.genericMethod({
            model: 'ScratchDatabaseCleanup',
            method: 'getAllTables',
            parameters: {
                database: props.database
            }
        });
        
        // edit the array of objects to convert date objects to strings
        const fullResults = results.map((table: any) => {
            return {
                ...table,
                lastUpdateDate: this.convertToReadableDate(table.UpdateTime),
                deleteTable: (props.tablesMarkedDelete) ? props.tablesMarkedDelete.includes(table.Name): false
            }
        });
        states.queryRunning = false;
        return fullResults;
    }
}
