import * as _ from 'lodash';
import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { Injectable } from '@angular/core';
import { EcsAppShare } from './ecsAppShareHandler.service';

@Injectable()
export class EcsAppValid {
    constructor(private ecsAppShareHandler: EcsAppShare) {

    }

    isValidSelectedMetricDates(states: IEcsAppEditStates) {

        const diffTime = Math.abs(states.selectedMetricDates.toDate - states.selectedMetricDates.fromDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const isFromDateGreater = Date.parse(states.selectedMetricDates.fromDate) < Date.parse(states.selectedMetricDates.toDate);

        if (diffDays > 1 || !isFromDateGreater) {
            return false;
        } else {
            return true;
        }
    }

    isContainerValid(containerId: any, states: IEcsAppEditStates) {
        let tg: any;
        states.cluster.errorMessage = [];

        for (let i = 0; states.cluster.ec2Info.length > i; i++) {
            const c = states.cluster.ec2Info[i];
            if (c.containerInstanceArn === containerId) {
                if (!c.agentConnected) {
                    states.cluster.errorMessage.push('Container Agent is not connected. This means if a web or worker dies auto recovery will fail. Restart the Ec2 instance or change the EC2 instance size to force a agent restart.');
                    states.cluster.isValid = false;
                }
                break;
            }
        }

        // check task def are all sync'd
        if (states.cluster.taskGroupsInfo && states.cluster.environment.applicationType === 'prism') {

            for (let k = 0; states.cluster.taskGroupsInfo.length > k; k++) {
                tg = states.cluster.taskGroupsInfo[k];

                // loop through all environment variables
                const errors = _.filter(tg.envVars, function (env) {
                    if (env.isError) {
                        return env;
                    }
                    // check for redis
                    if (env.name.toUpperCase() === 'REDISCLOUD_URL') {
                        const certPathValue = _.get(_.find(tg.envVars, { name: 'SSL_CERT_FILE_PATH' }), 'value');
                        const certKeyValue = _.get(_.find(tg.envVars, { name: 'SSL_KEY_FILE_PATH' }), 'value');

                        if (env.value.indexOf('rediss') >= 0 && (!certPathValue || !certKeyValue)) {
                            // cert envs required not found
                            states.cluster.errorMessage.push(tg.name + ' secured Redis is missing required SSL cert or key path.');
                            states.cluster.isValid = false;
                        } else if (env.value.indexOf('rediss') < 0 && (certPathValue || certKeyValue)) {
                            // not secured redis so certs should not exits
                            states.cluster.errorMessage.push(tg.name + ' unsecured Redis is set but SSL cert or key path exists.');
                            states.cluster.isValid = false;
                        }
                    }   // end check for redis
                });

                if (errors.length > 0) {
                    states.cluster.errorMessage.push(tg.name + ' critical task defintion variables do not match.');
                    states.cluster.isValid = false;
                }
            }   // end for

            const webTg: any = _.find(states.cluster.taskGroupsInfo, { name: states.cluster.clusterName + '-task' });
            if (webTg && webTg.envVars) {
                states.cluster.auth0Connection = _.find(webTg.envVars, function (e) { return e.name === 'AUTH0_CONNECTION' && e.value; });
                states.cluster.auth0Tenant = _.find(webTg.envVars, function (e) { return e.name === 'AUTH0_TENANT' && e.value; });
                states.cluster.isSSOEnabled = _.find(webTg.envVars, function (e) { return e.name === 'AUTH0_ENABLE_SSO' && e.value; }) ? true : false;

                const auth0DomainExists = _.find(webTg.envVars, function (e) { return e.name === 'AUTH0_DOMAIN' && e.value; });
                if (!auth0DomainExists) {
                    // does not exists
                    states.cluster.errorMessage.push(webTg.name + ' AUTH0_DOMAIN is not set. Using legacy HS256');
                    states.cluster.isValid = false;
                }

            }
        }

        // check if prism app has active workers
        if (states.cluster.workerServiceName && states.cluster.workerTaskName) {

            const container = states.cluster.containers[0];
            const taskWorkerDefinitions = this.ecsAppShareHandler.getContainerDefinitions(container, '-worker-task', states);

            if (!taskWorkerDefinitions) {
                states.cluster.errorMessage.push('No worker defintion found for ' + states.cluster.workerTaskName + ' found');
                states.cluster.isValid = false;
            }
        }

        // is Fargate setup
        if (states.cluster.isFargateEnabled && states.cluster.containers && states.cluster.containers.length > 0) {

            const container = states.cluster.containers[0];
            let taskDef = this.ecsAppShareHandler.getContainerDefinitions(container, '-task', states);

            if (taskDef && taskDef.length > 0) {
                taskDef = taskDef[0];
                const envValue = _.get(_.find(taskDef.environment, { name: 'FARGATE_ENABLED' }), 'value');
                if (!envValue || envValue.toLowerCase() !== 'true') {
                    states.cluster.errorMessage.push((tg?.name || '') + ' Fargate requires FARGATE_ENABLED=true.');
                    states.cluster.isValid = false;
                }
            }
        }

        // alarms needed for prod
        if (states.cluster.environment.environmentType === 'demo') {
            const required = ['TRANSACTION', 'HTTP'];
            const existing = _.uniq(_.map(_.filter(states.cluster.upTimeAlarms, { is_paused: false }), 'check_type'));
            const diffs = _.difference(required, existing);

            diffs.forEach(function (a) {
                states.cluster.errorMessage.push('Uptime alarm ' + a + ' is not configured');
            });

        }
    }

    // fisEnvironmentVariablesValidToAdd(states: IEcsAppEditStates) {
    //   let regexp = /^[a-zA-Z0-9-_]+$/;

    //   if (states.newEnvName.search(regexp) === -1 || states.newEnvValue.trim().length === 0) {
    //       return true;
    //   }
    //   else {
    //       return false;
    //   }
    // }
}
