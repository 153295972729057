import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IReferenceDataSourcesListStates, IReferenceDataSourcesListService } from './referenceDataSourcesList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { ViewAiAnalysisModalComponent } from '../aiAnalysisModal/viewAiAnalysisModal.component';
import { ReferenceTableUsageModalComponent } from '../referenceTableUsageModal/referenceTableUsageModal.component';

@Injectable()
export class ReferenceDataSourcesListService implements IReferenceDataSourcesListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog,
        private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {
        //
    }

    async initDelegate(states: IReferenceDataSourcesListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IReferenceDataSourcesListStates) {
        const referenceDataSources = await this.dataAccess.genericFind({
            model: 'ReferenceData',
            filter: {
                include: {
                    relation: 'referenceDataClass',
                    scope: {
                        include: 'teraUser'
                    }
                }
            }
        });

        states.referenceDataSources = _.map(_.orderBy(referenceDataSources, 'tableName'), function (rds) {
            if (rds.referenceDataClass) {
                return {
                    tableName: rds.tableName,
                    id: rds.id,
                    ownerName: !rds.referenceDataClass || !rds.referenceDataClass.teraUser ? null : rds.referenceDataClass.teraUser.ownerName,
                    source: rds.referenceDataClass.source,
                    status: rds.status,
                    dataSourceDerivedSource: rds.dataSourceDerivedSource,
                    module: rds.referenceDataClass.source==='url_custom' ? rds.referenceDataClass.className : null,
                    statusDescription: rds.statusDescription,
                    lastRunDate: (rds.referenceDataClass.lastRunDatetime) ? new Date(rds.referenceDataClass.lastRunDatetime).toISOString().slice(0, 10) : null,
                    lastPromotedDate: (rds.lastPromoted) ? new Date(rds.lastPromoted,).toISOString().slice(0, 10) : null,
                    diffReportPath: rds.diffReportPath,
                    diffReportStatus: rds.diffReportStatus,
                    priority: _.startCase(rds.priority),
                    bulkSelected: false
                };
            }
            return {};
        });

        states.table.data = states.referenceDataSources;
    }

    setTablePageSize(states: IReferenceDataSourcesListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.referenceDataSources.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IReferenceDataSourcesListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IReferenceDataSourcesListStates) {
        const data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    async getDiffReportUrl(referenceDataId: number) {
        return this.dataAccess.genericMethod({
            model: 'ReferenceData', method: 'getDiffReportUrl', parameters: {
                referenceDataId,
                awsAccountName: 'clarify'
            }
        });
    }

    launchAiAnalysis(tableName?: string, isTableQuestion?: boolean) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            width: '75%',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    tableName,
                    isTableQuestion
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewAiAnalysisModalComponent, dialogConfig);
    }

    showUsageReport(tableName: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            width: '75%',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    referenceTable: tableName
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ReferenceTableUsageModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            this.matDialog.closeAll();
        });
    }

    async runPipelines(states: IReferenceDataSourcesListStates, isPromotion: boolean) {
        let filteredDataSources = _.filter(states.referenceDataSources, {bulkSelected: true});
        if (isPromotion) {
            filteredDataSources = _.filter(filteredDataSources, (dataSource: any) => dataSource.status.includes('Promotion'));
        }

        const dagConfigs: any = await this.dataAccess.genericMethod({
            model: 'ReferenceData',
            method: 'getDagConfigs',
            parameters: {
                referenceDataSources: filteredDataSources
            }
        });

        const grouped = _.groupBy(dagConfigs, isPromotion ? 'promotionDagId' : 'dagId');

        const mappedPipelines = _.map(grouped, (items) => {
            const base = _.cloneDeep(items[0]);
            const final: any = {};

            final.dagId = isPromotion ? base.promotionDagId : base.dagId;

            const dagConfigKey = isPromotion ? 'promotionDagConfig' : 'dagConfig';
            final.dagConfig = base[dagConfigKey];
            
            if (final.dagConfig?.ref_data_sources_to_run) {
                final.dagConfig.ref_data_sources_to_run = _.flatten(items.map(i => i.dagConfig?.ref_data_sources_to_run));
            } else if (final.dagConfig?.tables_to_promote) {
                final.dagConfig.tables_to_promote = _.flatten(items.map(i => i.dagConfig?.tables_to_promote));
            }
            final.count = items.length;
            return final;
        });



        const pipelineSummaries = _.sortBy(mappedPipelines, 'dagId').map(obj => {
            const { dagId, count } = obj;
          
            return `<strong>${dagId}</strong>: ${count} table(s)`;
        }).join('<br>');
        
        if (!pipelineSummaries) {
            swal({
                title: 'No Eligible Pipelines Selected',
                text: 'Please select at least one pipeline to run.',
                type: 'error'
            });
            return;
        }
        swal({
            title: 'Confirm Pipeline Start',
            html: `Are you sure you want to kick off the following pipelines?<br><br>${pipelineSummaries}`,
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#57C84D',
            confirmButtonText: 'Run Pipelines',
            cancelButtonText: 'Cancel'
        }).then((confirm: any) => {
            if (confirm.value) {
                this.triggerPipelines(mappedPipelines);
                this.updatePipelines(states, isPromotion);
            }
        });
    }

    async triggerPipelines(mappedPipelines: any) {
        const triggeredByUser = await this.argosStore.getItem('username').replace('@clarifyhealth.com', '');

        await Promise.all(
            _.map(mappedPipelines, async ({ dagId, dagConfig }) => {
                await this.dataAccess.genericMethod({
                    model: 'Astronomer',
                    method: 'triggerDag',
                    parameters: {
                        dagId,
                        dagConfig: JSON.stringify(dagConfig),
                        triggeredByUser,
                    }
                });
            })
        );
    }

    async updatePipelines(states: IReferenceDataSourcesListStates, isPromotion: boolean) {
        const triggeredTables = _.map(_.filter(states.referenceDataSources, {bulkSelected: true}), 'tableName');
        const startTm = Date.now();
        
        const tablesToUpdate = await this.dataAccess.genericFind({
            model: 'ReferenceData',
            filter: {
                where: {
                    tableName: { inq: triggeredTables }
                }
            }
        });
        
        const updatedRecords = _.map(tablesToUpdate, (referenceDataSource: any) => {
            return {
                ...referenceDataSource,
                pipelineStartTm: startTm,
                pipelineIsPromotion: isPromotion
            };
        });
        
        await Promise.all(
            _.map(updatedRecords, async (referenceDataSource) => {
                await this.dataAccess.genericUpsert({
                    model: 'ReferenceData',
                    data: referenceDataSource
                });
            })
        );
    }
}
