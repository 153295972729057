import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IRedshiftClustersStates } from './redshiftClusters.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { RedshiftClustersService } from './redshiftClusters.component.service';

@Component({
    selector: 'argos-redshiftClusters',
    templateUrl: './redshiftClusters.component.html',
    providers: [RedshiftClustersService]
})

export class RedshiftClustersComponent extends ReactComponentBase<{}, IRedshiftClustersStates, {}> implements OnInit {
    constructor(private svc: RedshiftClustersService, private cdr: ChangeDetectorRef) {
        super({
            showType: 'hideEndPoint'
        } as unknown as IRedshiftClustersStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }
}
