import { HostListener, Directive, ElementRef, Input, OnInit, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[fill-height]'
})

export class FillHeightDirective implements AfterViewInit {
    @Input() debounceWait = 250;
    @Input() additionalPadding = 0;
    @Input() minHeight = -1;

    clientHeight: any;

    debounceHandler: any;

    constructor(private el: ElementRef) {
        this.debounceHandler = this.debounce(this.onWindowResize, this.debounceWait).bind(this);
        this.clientHeight = FillHeightDirective.getClientHeight();
    }

    ngAfterViewInit() {
        this.onWindowResize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.clientHeight = event.target.innerHeight || FillHeightDirective.getClientHeight();
        this.debounceHandler();
    }

    private debounce(fn: any, time: number) {
        let timeout: any;
        // every time this returned fn is called, it clears and re-sets the timeout
        return () => {
            // const context = this;
            // set args so we can access it inside of inner function
            const args = arguments;
            const later = () => {
                timeout = undefined;
                fn.apply(this, args);
            };
            window.clearTimeout(timeout);
            timeout = setTimeout(later, time);
        };
    }

    private onWindowResize() {

        const elementOffsetTop = this.getElementOffsetDocument(this.el.nativeElement).top;
        const elementBottomMarginAndBorderHeight = this.getBottomMarginAndBorderHeight(this.el.nativeElement);

        const additionalPadding = this.additionalPadding;

        let elementHeight = this.clientHeight -
            elementOffsetTop -
            elementBottomMarginAndBorderHeight -
            additionalPadding;

        if (this.minHeight > 0 && this.minHeight > elementHeight) {
            elementHeight = this.minHeight;
        }

        this.el.nativeElement.style.height = elementHeight + 'px';
    }

    private getElementOffsetDocument(el: HTMLElement) {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    private getBottomMarginAndBorderHeight(element: any): number {
        const footerBottomMarginHeight = FillHeightDirective.getNumeric(element, 'marginBottom');
        const footerBottomBorderHeight = FillHeightDirective.getNumeric(element, 'borderBottomWidth');
        return footerBottomMarginHeight + footerBottomBorderHeight;
    }

    static getNumeric(element: any, propertyName: string): number {
        const footerStyle: any = window.getComputedStyle(element);
        return parseInt(footerStyle[propertyName], 10) || 0;
    }

    static getClientHeight() {
        return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }
}
