import { Component } from '@angular/core';

@Component({
    selector: 'argos-pipelineHistoryRoot',
    template: `<ui-view></ui-view>`
})

export class PipelineHistoryRootComponent {

}
