import { NgDataAccess } from '../../services/dataAccess.service';
import { ArgosStoreService } from '../../services/argosStore.service';
import { IMatTableSettingsModelProps, IMatTableSettingsModelStates, IMatTableSettingsModelService } from './matTableSettingsModel.component.d';
import { Injectable } from '@angular/core'; 
import * as _ from 'lodash';

@Injectable()
export class MatTableSettingsModelService implements IMatTableSettingsModelService {
	constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService) {
	
	}

  	initDelegate(props: IMatTableSettingsModelProps, states: IMatTableSettingsModelStates): object {
		states.tableColumns = props.tableColumns;
		states.defaultDisplayTableColumns = props.defaultDisplayTableColumns;
		states.readOnlyTableColumns = props.readOnlyTableColumns;
		states.settingName = props.settingName;

		let gridSettings = this.argosStore.getItem('gridSettings') || {};

		if (gridSettings[states.settingName]) {
			states.defaultPageSize = gridSettings[states.settingName].defaultPageSize;
			states.hiddenTableColumns = gridSettings[states.settingName].hiddenTableColumns;
		} else {
			states.hiddenTableColumns = _.difference(states.tableColumns, states.defaultDisplayTableColumns)
		}

		states.tableColumnsMatData = _.map(states.tableColumns, (col: string) => {
			return {
				columnName: col,
				isHidden: _.includes(states.hiddenTableColumns, col),
				isReadOnly: _.includes(states.readOnlyTableColumns, col)
			}
    	});

    	states.tableColumnsMatTable.data = states.tableColumnsMatData;
    	return {};
	}

  	changeDelegate(oldProps: IMatTableSettingsModelProps, newProps: IMatTableSettingsModelProps, states: IMatTableSettingsModelStates): object {
    	this.initDelegate(newProps, states);
    	return {};
  }

  	async save(states: IMatTableSettingsModelStates) {
		let currentUser = await this.dataAccess.genericMethod({
			model: 'ArgosUser', method: 'findOne', parameters: {
				filter: { where: { email: this.argosStore.getItem('username') } }
			}
		});

		//update grid settings for current user
		currentUser.gridSettings[states.settingName] = {
			defaultPageSize: states.defaultPageSize
		};

		currentUser.gridSettings[states.settingName].hiddenTableColumns = _.uniq(_.map(_.filter(states.tableColumnsMatData, (col: any) => {
			if (col.isHidden) {
				return col;
			}
		}), 'columnName'));

		//save settings
		this.argosStore.setItem('gridSettings', currentUser.gridSettings);
		await this.dataAccess.genericUpsert({
			model: 'ArgosUser',
			data: currentUser
		});
	}
}

 