export const getClarifyProviderRevisionsObj = (grain: string, npiAttributes: any) => {
    switch(grain){
        case 'attributedParentId':
            return {
                id: '',
                attributedParentId: npiAttributes.attributed_parent_id,
                dataSourcesToApply: ['all']
            };
        case 'primaryPhysicianGroupAffiliationNpi':
            return {
                id: '',
                primaryPhysicianGroupAffiliationNpi: npiAttributes.primary_physician_group_affiliation_npi,
                dataSourcesToApply: ['all']
            };
        default:
            return {
                id: '',
                npi: npiAttributes.npi,
                dataSourcesToApply: ['all']
            };
    }
};

export const getGrainName = (grain: string): string => {
    switch(grain) {
        case 'attributedParentId':  return 'Attributed Parent Id';
        case 'primaryPhysicianGroupAffiliationNpi': return 'Primary Physician Group Affiliation Npi';
        default: return 'NPI';
    }
};

export const getParameters = (grain: string, id: string) => {
    switch(grain) {
        case 'attributedParentId':  return {attributedParentId: id};
        case 'primaryPhysicianGroupAffiliationNpi': return {primaryPhysicianGroupAffiliationNpi: id};
        default: return {npi: id};
    }
};

export const getRevisionClass = (grain: string): string => {
    return 'ClarifyProvider' + grain.charAt(0).toUpperCase() + grain.slice(1) + 'Revision';
};

export const getRevisionMethod = (grain: string): string => {
    return 'fetch' + grain.charAt(0).toUpperCase() + grain.slice(1) + 'Attributed';
};

export const getTextName = (grain: string, npiAttributes: any): string => {
    switch(grain) {
        case 'attributedParentId': return npiAttributes.attributedParentName;
        case 'primaryPhysicianGroupAffiliationNpi': return npiAttributes.primaryPhysicianGroupAffiliation;
        default:
            return npiAttributes.attributedName;
    }
};

