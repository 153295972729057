import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ITerraTestResultEditStates } from './terraTestResultEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { TerraTestResultEditService } from './terraTestResultEdit.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
    selector: 'argos-terraTestResultEdit',
    templateUrl: './terraTestResultEdit.component.html',
    providers: [TerraTestResultEditService]
})

export class TerraTestResultEditComponent extends ReactComponentBase<{}, ITerraTestResultEditStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: TerraTestResultEditService, private cdr: ChangeDetectorRef) {
        super({
            terraTest: {},
            dataTable: new MatTableDataSource([])
        } as ITerraTestResultEditStates);
    }

    ngAfterViewInit() {
        this.states.dataTable.sort = this.matTbSort;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges;
    }

    openCircleCiJob(id: any) {
        window.open('https://circleci.com/gh/clarifyhealth/tera_tests/' + id, '_blank');
    }

    hideShow() {
        this.states.viewBtnShow = !this.states.viewBtnShow;
    }

    searchMatTable(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.dataTable.filter = filterValue.trim().toLowerCase();
    }

    isNumeric(value: string) {
        return this.svc.isNumeric(value);
    }
}
