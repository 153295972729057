import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IAlertStates, IAlertData } from './alert.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { AlertComponentService } from './alert.component.service';
import { Broadcaster } from '../../../services/broadCaster.service';

@Component({
    selector: 'argos-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.less'],
    providers: [AlertComponentService]
})

export class AlertComponent extends ReactComponentBase<{}, IAlertStates, {}> implements OnInit {
    constructor(private svc: AlertComponentService, private broadCaster: Broadcaster,
        private cdr: ChangeDetectorRef) {
        super({ alerts: [] } as IAlertStates);
    }

    ngOnInit() {
        this.broadCaster.on('alertError').subscribe((err: any) => {
            this.svc.alertErrorHandler(this.states, err);
        });
        this.broadCaster.on('alertSuccess').subscribe((message: any) => {
            this.svc.alertSuccessHandler(this.states, message, this.alertSuccessCallback);
        });
        this.broadCaster.on('clearErrors').subscribe(() => {
            this.states.alerts = [];
        });
    }

    alertSuccessCallback: Function = (alert: IAlertData) => {
        // Clear the message after 5 seconds.
        setTimeout(() => {
            this.closeAlert(alert);
            this.cdr.detectChanges();
        }, 5000);
    };

    closeAlert(alert: IAlertData) {
        this.svc.closeAlertHandler(alert, this.states);
    }
}
