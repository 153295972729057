import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IReferenceListItemEditStates } from './referenceListItemEdit.component.d';
import { SortDirection } from './sort-direction.enum';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ReferenceListItemEditService } from './referenceListItemEdit.component.service';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

export interface TreeNode {
    name: string;
    children?: TreeNode[];
  }

@Component({
    selector: 'argos-referenceListItemEdit',
    templateUrl: './referenceListItemEdit.component.html',
    providers: [ReferenceListItemEditService],
    styleUrls: ['./referenceListItemEdit.component.css']
})

export class ReferenceListItemEditComponent
    extends ReactComponentBase<{}, IReferenceListItemEditStates, {}> implements OnInit {

    sortDirection = SortDirection;
    
    constructor(private svc: ReferenceListItemEditService, private cdr: ChangeDetectorRef) {
        super({ 
            referenceDataList: {},
            isEditingDescription: false,
            sortKey: 'key',
            arrowDirection: SortDirection.Ascending,
            treeControl: new NestedTreeControl<TreeNode>(node => node.children),
            dataSource: new MatTreeNestedDataSource<TreeNode>(),
            pageSize: 50,
            currentPage: 0,
            allData: [],
            filterApplied: false
        } as IReferenceListItemEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    saveNewTag() {
        this.svc.saveNewTag(this.states);
    }

    saveNewTagItems() {
        this.svc.saveNewTagItems(this.states);
    }

    viewTag(row: any) {
        this.svc.viewTag(row, this.states);
    }

    parseCSV(fileObj: any) {
        this.svc.parseCSV(fileObj);
    }

    exportTags(parentTagId: any) {
        this.svc.exportTags(parentTagId, this.states);
    }

    uploadTagCsvFile(file: any) {
        this.svc.uploadTagCsvFile(file, this.states);
    }

    saveNewParentTag() {
        this.svc.saveNewParentTag(this.states);
    }

    viewHistory() {
        this.svc.viewHistory(this.states);
    }

    openReferenceDataSource() {
        this.svc.openReferenceDataSource(this.states);
    }

    clearListItemTags() {
        this.states.referenceDataListItemTags = [];
        this.cdr.detectChanges();
    }

    delete(type: string, id: number, description: string) {
        this.svc.delete(type, id, description, this.states);
    }

    editTag(row: any) {
        this.states.editTagId = row.id;
        this.states.editTagName = row.tag;
    }

    saveNewTagName(row: any) {
        this.svc.saveNewTagName(row, this.states);
    }

    cancelNewTagName() {
        this.states.editTagId = null;
        this.states.editTagName = '';
    }

    addItems() {
        this.svc.addItems(this.states);
    }

    sortData(key: string) {
        this.svc.sortData(this.states, key);
    }

    showGreyArrow(event: MouseEvent, show: boolean): void {
        const target = event.target as HTMLElement;
        const greyArrow: any = target.querySelector('.fa');
        if (greyArrow && greyArrow.style.color === 'darkgrey') {
            greyArrow.style.visibility = show ? 'visible' : 'hidden';
        }
    }

    toggleEditDescription(): void {
        this.states.isEditingDescription = !this.states.isEditingDescription;
    }
    
    updateTagDescription(newDescription: string): void {
        this.states.tagDescription = newDescription;
        this.toggleEditDescription();
        this.svc.updateTagDescription(this.states);
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
        if (event.index === 1 && this.states.allData.length === 0) {
            this.svc.loadKeys(this.states);
        }
    }

    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    updatePage() {
        this.svc.updatePage(this.states);
    }
    
    nextPage() {
        this.svc.nextPage(this.states);
    }

    previousPage() {
        this.svc.previousPage(this.states);
    }

    applyFilter(clear = false) {
        this.svc.applyFilter(this.states, clear);
    }

    getFilteredTotalNodes() {
        return this.states.filterApplied ? this.states.dataSource.data.length : this.states.allData.length;
    }
    
    getStartIndex() {
        return this.states.currentPage * this.states.pageSize;
    }
    
    getEndIndex() {
        if (this.states.filterApplied) {
            return this.getFilteredTotalNodes();
        }
        const endIndex = this.getStartIndex() + this.states.pageSize;
        return endIndex > this.getFilteredTotalNodes() ? this.getFilteredTotalNodes() : endIndex;
    }

    isNextPageDisabled() {
        return (this.states.currentPage + 1) * this.states.pageSize >= this.states.allData.length || this.states.filterApplied;
    }

    triggerReferenceListCombined() {
        this.svc.triggerReferenceListCombined();
    }
}
