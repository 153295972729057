import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IViewCareGroupingHistoryProps, IViewCareGroupingHistoryStates } from './viewCareGroupingHistory.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewCareGroupingHistoryService } from './viewCareGroupingHistory.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'argos-viewCareGroupingHistory',
    templateUrl: './viewCareGroupingHistory.component.html',
    providers: [ViewCareGroupingHistoryService]
})

export class ViewCareGroupingHistoryComponent extends ReactComponentBase<IViewCareGroupingHistoryProps, IViewCareGroupingHistoryStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewCareGroupingHistoryService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewCareGroupingHistoryComponent>, private cdr: ChangeDetectorRef) {
        super({
            showDiffs: false,
            careGrouping: {}
        } as IViewCareGroupingHistoryStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    closeModal() {
        this.dialogRef.close({});
    }

    viewDiffs(version: any) {
        this.svc.viewDiffs(version, this.states);
        this.cdr.detectChanges();
    }
}
