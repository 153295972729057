import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IScratchDatabaseCleanupStates, IScratchDatabaseCleanupService } from '../scratchDatabaseCleanupLandingPage/scratchDatabaseCleanup.component.d';


@Injectable()
export class ScratchDatabaseCleanupServices implements IScratchDatabaseCleanupService {

    constructor(private dataAccess: NgDataAccess) {
    }

    async initDelegate(states: IScratchDatabaseCleanupStates): Promise<object> {
        await this.init(states);
        return states;
    }

    async init(states: IScratchDatabaseCleanupStates) {
        states.currentQueue.data = await this.queryCurrentCleanupQueue(states);
        return states;
    }

    async queryCurrentCleanupQueue(states: IScratchDatabaseCleanupStates) {
        let currentCleanupQueue = await this.dataAccess.genericFind({
            model: 'ScratchDatabaseCleanup'
        });
        const currentCleanupQueueReadout = currentCleanupQueue.filter(((item: any) => item.tableCount != 0))
            .map((item: any) => {
                return {
                           id: item.id,
                           database: item.database,
                           lastUpdateDate: item.lastUpdateTime.slice(0,10),
                           lastUpdatedTable: item.lastUpdatedTable,
                           tableCount: item.tableCount,
                           tableRemovalEligible: item.tableRemovalEligible,
                           tableRemovalConfig: (item.tableRemovalConfig) ? item.tableRemovalConfig.map((table:any) => table.tableName) : null
                }
            });
        return currentCleanupQueueReadout
    }

}
