import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IEditObservationTagModalProps, IEditObservationTagModalStates } from './editObservationTagModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EditObservationTagModalService } from './editObservationTagModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-editObservationTagModal',
    templateUrl: './editObservationTagModal.component.html',
    providers: [EditObservationTagModalService]
})

export class EditObservationTagModalComponent
    extends ReactComponentBase<IEditObservationTagModalProps, IEditObservationTagModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: EditObservationTagModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<EditObservationTagModalComponent>) {
        super({
            vccgTypes: [],
            programEpisodeObservationsToDelete: []
        } as unknown as IEditObservationTagModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        const finalTags = this.states.tagPaths.filter((tag: any) => tag.tagId);
        this.dialogRef.close({
            tagIndex: this.states.tagIndex,
            tagIds: finalTags
        });
    }

    addTagLevel() {
        this.svc.addTagLevel(this.states);
    }

    deleteTag(index: any) {
        this.svc.deleteTag(index, this.states);
    }
}
