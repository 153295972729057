import { Component, OnInit } from '@angular/core';
import { INewEnvironmentRequestStates } from './newEnvironmentRequest.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { NewEnvironmentRequestService } from './newEnvironmentRequest.component.service';

@Component({
    selector: 'argos-newEnvironmentRequest',
    templateUrl: './newEnvironmentRequest.component.html',
    providers: [NewEnvironmentRequestService]
})

export class NewEnvironmentRequestComponent
    extends ReactComponentBase<{}, INewEnvironmentRequestStates, {}> implements OnInit {
    constructor(private svc: NewEnvironmentRequestService) {
        super({
            filterProperties: ['npis', 'ccns', 'states', 'msas']
        } as INewEnvironmentRequestStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
    }

    selectGroup(group: any) {
        this.svc.selectGroupHandler(group, this.states);
    }

    isLEJRSelected() {
        return this.svc.isLEJRSelectedHandler(this.states);
    }

    rowChecked(row: any) {
        this.svc.rowChecked(row, this.states);
    }

    cancel() {
        this.svc.cancel();
    }

    requestEnvironment() {
        this.svc.requestEnvironmentHandler(this.states);
    }

    removeValue(row: any) {
        this.svc.removeValueHandler(row, this.states);
    }
}
