import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EcsAppShare } from './ecsAppShareHandler.service';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class EcsAppEcsService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private ecsAppShareHandler: EcsAppShare, private alertSvc: AlertService) {
        //
    }

    async deleteEcsServiceAutoScalePolicy(deleteScalePolicy: any, index: Number, states: IEcsAppEditStates) {

        const param = {
            clusterName: states.clusterName,
            serviceName: states.selectedService.serviceName,
            requestedBy: this.argosStore.getItem('username'),
            awsAccountName: states.cluster.accountName,
            policyName: deleteScalePolicy.PolicyName
        };
        await this.dataAccess.genericMethod({
            model: 'Environment',
            method: 'deleteEcsServiceAutoScaling',
            parameters: param
        });
        states.selectedService.scalingPolicies.splice(index, 1);
    }

    async upsertEcsServiceAutoScalePolicy(newScalePolicy: any, isNew: any, states: IEcsAppEditStates) {

        const params: any = {
            clusterName: states.clusterName,
            serviceName: states.selectedService.serviceName,
            policyName: newScalePolicy.PolicyName,
            metricType: newScalePolicy.TargetTrackingScalingPolicyConfiguration.PredefinedMetricSpecification.PredefinedMetricType,
            scaleInCooldown: newScalePolicy.TargetTrackingScalingPolicyConfiguration.ScaleInCooldown,
            scaleOutCooldown: newScalePolicy.TargetTrackingScalingPolicyConfiguration.ScaleOutCooldown,
            disableScaleIn: newScalePolicy.TargetTrackingScalingPolicyConfiguration.DisableScaleIn,
            targetValue: newScalePolicy.TargetTrackingScalingPolicyConfiguration.TargetValue,
            requestedBy: this.argosStore.getItem('username'),
            awsAccountName: states.cluster.accountName
        };

        if (newScalePolicy.TargetTrackingScalingPolicyConfiguration.PredefinedMetricSpecification.PredefinedMetricType === 'ALBRequestCountPerTarget') {
            params.resourceLabel = states.cluster.targetGroupInfo[0].LoadBalancerArns[0].split('loadbalancer/')[1] + '/targetgroup' + states.cluster.targetGroupInfo[0].TargetGroupArn.split('targetgroup')[1];
        }
        const result = await this.dataAccess.genericMethod({
            model: 'Environment',
            method: 'upsertEcsServiceAutoScaling',
            parameters: params
        });
        if (result.isSuccessful) {
            // add a row to represent the new policy. these are the minimal fields needed
            const newPolicy = {
                PolicyName: newScalePolicy.PolicyName,
                TargetTrackingScalingPolicyConfiguration: {
                    TargetValue: newScalePolicy.TargetTrackingScalingPolicyConfiguration.TargetValue,
                    PredefinedMetricSpecification: {
                        PredefinedMetricType: newScalePolicy.TargetTrackingScalingPolicyConfiguration.PredefinedMetricSpecification.PredefinedMetricType
                    },
                    ScaleOutCooldown: newScalePolicy.TargetTrackingScalingPolicyConfiguration.ScaleOutCooldown,
                    ScaleInCooldown: newScalePolicy.TargetTrackingScalingPolicyConfiguration.ScaleInCooldown,
                    DisableScaleIn: newScalePolicy.TargetTrackingScalingPolicyConfiguration.DisableScaleIn
                }
            };

            if (isNew) {
                states.selectedService.scalingPolicies.push(newPolicy);

                states.newScalePolicy = {
                    TargetTrackingScalingPolicyConfiguration: {
                        PredefinedMetricSpecification: {}
                    }
                }; // clear the ui fields
            }
        }
    }

    async requestNewEcsServiceScalableTarget(capacity: number, states: IEcsAppEditStates) {

        const scalableTarget = {
            MaxCapacity: capacity,
            MinCapacity: capacity
        };

        await this.updateEcsServiceScalableTarget(scalableTarget, states);
    }

    async requestEcsServiceScalableTarget(scalableTarget: any, states: IEcsAppEditStates) {

        if (scalableTarget.MinCapacity > states.selectedService.desiredCount) {
            swal({
                title: 'Confirm Service Auto Scaling Configuration Change',
                text: 'Your minimum number of tasks ' + scalableTarget.MinCapacity + ' is greater than the current desired instances ' + states.selectedService.desiredCount + '.\nSubmitting this change will update the desired instances to ' + scalableTarget.MinCapacity,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm) => {
                if (isConfirm.value) {
                    states.selectedService.desiredCount = scalableTarget.MinCapacity;
                    await this.updateEcsServiceScalableTarget(scalableTarget, states);
                }
            });
        } else {
            await this.updateEcsServiceScalableTarget(scalableTarget, states);
        }
    }

    async updateEcsServiceScalableTarget(scalableTarget: any, states: IEcsAppEditStates) {
        states.isEcsServiceChangeInProgress = true;
        const param = {
            clusterName: states.clusterName,
            serviceName: states.selectedService.serviceName,
            maxCapacity: scalableTarget.MaxCapacity,
            minCapacity: scalableTarget.MinCapacity,
            requestedBy: this.argosStore.getItem('username'),
            awsAccountName: states.cluster.accountName
        };
        await this.dataAccess.genericMethod({
            model: 'Environment',
            method: 'upsertEcsServiceScalableTarget',
            parameters: param
        });
        states.isEcsServiceChangeInProgress = false;
    }

    isServiceSchedulerNameUnique(scheduledActionName: string, states: IEcsAppEditStates) {
        //
    }

    async createEcsServiceScheduler(newServiceScheduler: any, states: IEcsAppEditStates) {
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'upsertEcsServiceScalableSchedule',
            parameters: {
                clusterName: states.clusterName,
                resourceId: newServiceScheduler.resourceId,
                scaleActionName: newServiceScheduler.scheduledActionName,
                days: newServiceScheduler.days,
                hour: newServiceScheduler.hour,
                minute: newServiceScheduler.minute,
                maxCapacity: newServiceScheduler.maxCapacity,
                minCapacity: newServiceScheduler.minCapacity,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            }
        });

        if (result.isSuccessful) {
            states.newServiceScheduler = {
                scheduledActionName: '',
                scalableDimension: 'ecs:service:DesiredCount',
                minCapacity: 1,
                maxCapacity: 1
            };
        }
    }

    async deleteEcsServiceScheduler(resourceId: string, scheduledActionName: string, states: IEcsAppEditStates) {
        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'deleteEcsServiceScalableSchedule',
            parameters: {
                clusterName: states.clusterName,
                resourceId,
                scheduledActionName,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            }
        });
    }

    async updateEcsServiceSchedulerSuspendedState(scheduledTarget: any, states: IEcsAppEditStates) {

        const result = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'updateEcsServiceScheduleSuspendedState',
            parameters: {
                clusterName: states.clusterName,
                scalableTarget: scheduledTarget,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            }
        });
    }

    async selectedEc2InstanceChanged(instanceType: any, states: IEcsAppEditStates) {

        if (states.minimumEcsCount === 0 && !instanceType) {
            instanceType = states.cluster.ec2Info[0].ec2InstanceType;
        }

        const aMinuteAgo = 600000;  // 10 minutes in milliseconds
        const result = await this.dataAccess.genericFind({
            model: 'EnvironmentActivityLog',
            filter: {
                where: {
                    clusterName: states.cluster.clusterName,
                    environmentAction: 'ec2 instance change',
                    createdAt: {
                        gte: Date.now() - aMinuteAgo
                    }
                },
                order: 'id desc'
            }
        });
        if (result && result.length > 0) {
            const recentEc2InstanceChangeRequest = result[0];
            // you cant run because the same request was made 10 mins ago
            swal({
                title: 'EC2 change failed',
                text: 'A recent EC2 instance was requested by ' + recentEc2InstanceChangeRequest.triggeredBy + ' at ' + recentEc2InstanceChangeRequest.createdAt + '. Instance changes can only occur every 10 mins. ',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            });
        } else {

            const updateInstanceTypeArg: any = {
                clusterName: states.cluster.clusterName,
                instanceType,
                requestedBy: this.argosStore.getItem('username'),
                awsAccountName: states.cluster.accountName
            };

            for (let x = 0; x < states.cluster.services.length; x++) {
                const serv = states.cluster.services[x];
                if (serv.endsWith(states.cluster.clusterName + '-service')) {
                    updateInstanceTypeArg.webCount = states.selectedEc2InstanceCount[x];
                } else if (serv.endsWith(states.cluster.clusterName + '-worker-service')) {
                    updateInstanceTypeArg.workerCount = states.selectedEc2InstanceCount[x];
                }
            }

            updateInstanceTypeArg.minimumEcsCount = states.minimumEcsCount;

            try {
                // no recent runs so execute the request
                const result = await this.dataAccess.genericMethod({
                    model: 'Environment',
                    method: 'updateEcsInstanceType',
                    parameters: updateInstanceTypeArg
                });
                swal({
                    title: 'EC2 Instances request submitted',
                    text: 'This can take about 10 minutes to complete.\nRequest id ' + result.runId,
                    type: 'warning',
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                }).then((isConfirm) => {
                    if (isConfirm.value) {
                        this.ecsAppShareHandler.getEnvironmentActivityLog(states);
                    }
                });
            } catch (error) {
                this.alertSvc.handleError(error);
            }
        }
    }
}
