import { IEcsAppEditStates } from '../ecsAppEdit.component.d';
import { NgDataAccess } from '../../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../../services/argosStore.service';
import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EcsAppShare } from './ecsAppShareHandler.service';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class EcsAppRestart {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess,
        private ecsAppShareHandler: EcsAppShare, private alertSvc: AlertService) {
        //
    }

    async clearAppRedisCache(taskObject: any, states: IEcsAppEditStates) {

        let dbName;
        let redisHost;

        taskObject.environment.forEach(function (env: any) {
            if (env.name === 'DATABASE') {
                dbName = env.value;
            } else if (env.name === 'REDISCLOUD_URL') {
                redisHost = env.value;
            }
        });

        if (dbName && redisHost) {
            const result = await this.dataAccess.genericMethod({
                model: 'Environment', method: 'clearAppCache', parameters: {
                    clusterName: states.cluster.clusterName,
                    clusterDB: dbName,
                    redisHost,
                    requestedBy: this.argosStore.getItem('username')
                }
            });
            swal({
                title: 'Clear App Redis submitted',
                text: 'Clear request was run.\nRequest id ' + result.runId + '\nLog name: ' + result.log,
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            }).then((isConfirm) => {
                this.ecsAppShareHandler.getEnvironmentActivityLog(states);
            });
        }

    }

    restartApp(states: IEcsAppEditStates) {

        if (states.cluster.webServiceName && states.cluster.webTaskName && states.cluster.accountName) {

            swal({
                title: 'Really?',
                text: 'Confirm you want to restart ecs service(s)',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
            }).then((isConfirm) => {
                if (isConfirm.value) {
                    const updatePromises = [];
                    const result = this.dataAccess.genericMethod({
                        model: 'Environment', method: 'retartEcsService', parameters: {
                            clusterName: states.clusterName,
                            serviceName: states.cluster.webServiceName,
                            taskDefinition: states.cluster.webTaskName,
                            redeploy: true,
                            healthCheckSeconds: 300,
                            requestedBy: this.argosStore.getItem('username'),
                            awsAccountName: states.cluster.accountName
                        }
                    });
                    updatePromises.push(result);
                    if (states.cluster.workerServiceName && states.cluster.workerTaskName) {
                        const result = this.dataAccess.genericMethod({
                            model: 'Environment', method: 'retartEcsService', parameters: {
                                clusterName: states.clusterName,
                                serviceName: states.cluster.workerServiceName,
                                taskDefinition: states.cluster.workerTaskName,
                                redeploy: true,
                                healthCheckSeconds: -1,
                                requestedBy: this.argosStore.getItem('username'),
                                awsAccountName: states.cluster.accountName
                            }
                        });
                        updatePromises.push(result);
                    }

                    Promise.all(updatePromises).then((response) => {

                        swal({
                            title: 'Restart submitted',
                            text: response[0].response + '. This can take about 10 minutes to complete',
                            type: 'warning',
                            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                        }).then((isConfirm) => {
                            this.ecsAppShareHandler.getEnvironmentActivityLog(states);
                        });
                    });
                }
            });
        } else {
            swal({
                title: 'Restart unsuccessful',
                text: 'Sorry we do not have enough information to restart this app. Please contact DevOps for help',
                type: 'warning',
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
            });
        }
    }
    requestRestartAppServer(states: IEcsAppEditStates) {

        if (states.cluster.ec2Info && states.cluster.ec2Info.length === 1) {
            const ec2Info = states.cluster.ec2Info[0];

            swal({
                title: 'Confirm ecs server reboot',
                text: 'You are requesting to reboot the ec2 instance. This will take the environment down for 10 minutes. Users will see 502 connection errors.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            }).then(async (isConfirm) => {
                if (isConfirm.value) {
                    await this.restartAppServer(ec2Info, states);
                }
            });
        }
    }

    async restartAppServer(ec2Info: any, states: IEcsAppEditStates) {
        const minutesAgo = 900000;  // 15 minutes in milliseconds
        try {
            const result = await this.dataAccess.genericFind({
                model: 'EnvironmentActivityLog',
                filter: {
                    where: {
                        clusterName: states.cluster.clusterName,
                        environmentAction: 'environment server restart',
                        createdAt: {
                            gte: Date.now() - minutesAgo
                        }
                    },
                    order: 'id desc'
                }
            });
            if (result && result.length > 0) {
                const recentEc2RestartRequest = result[0];
                // you cant run because the same request was made 10 mins ago
                swal({
                    title: 'ECS server reboot failed',
                    text: 'A recent ECS server reboot was requested by ' + recentEc2RestartRequest.triggeredBy + ' at ' + recentEc2RestartRequest.createdAt + '. Server restarts can only occur every 15 mins. ',
                    type: 'warning',
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                });
            } else {
                // no recent runs so execute the request
                const result = this.dataAccess.genericMethod({
                    model: 'Environment', method: 'retartEc2Instance', parameters: {
                        ec2InstanceId: ec2Info.ec2InstanceId,
                        clusterName: states.cluster.clusterName,
                        requestedBy: this.argosStore.getItem('username'),
                        awsAccountName: states.cluster.accountName
                    }
                });
                console.log('retartEc2Instance ' + JSON.stringify(result));
            }
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }
}
