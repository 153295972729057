import { Injectable } from '@angular/core';
import {IViewReleaseImpactService, IViewReleaseImpactStates} from './viewReleaseImpact.component.d';
import { NgDataAccess } from 'client/app/services/dataAccess.service';
import { UIRouter } from '@uirouter/core';
import { RunDataReleaseServices } from '../runDataRelease/runDataRelease.component.service';
import { IRunDataReleaseStates } from '../runDataRelease/runDataRelease.component';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
@Injectable()
export class ViewReleaseImpactService implements IViewReleaseImpactService{
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter, private runDataServices: RunDataReleaseServices) {

    }

    async initDelegate(states: IViewReleaseImpactStates): Promise<object> {
        await this.init(states);
        return states;
    };
    
    async init(states: IViewReleaseImpactStates) {
        states.releaseId = this.uiRouter.globals.params.releaseId;
        states.releaseName = this.uiRouter.globals.params.releaseName;
        const runDataStates = await this.getReleaseSummary(states);
        const tables = _.flatten(_.map(runDataStates.releaseSummary, 'allUpdatedTables'));
        
        const careGroupingsKey = _.keyBy(await this.dataAccess.genericFind({
            model: 'CareGrouping'
        }), 'name');

        const clientCareGroupings = await this.dataAccess.genericMethod({
            model: 'ClientCareGrouping',
            method: 'getClientCareGroupings',
            parameters: {}
        });
        const tablesObject: any[] = [];
        _.forEach(tables, table => { 

            const ccg = _.filter(clientCareGroupings, clientCareGrouping => {
                if (clientCareGrouping?.overrideBaseTable !== table) {
                    return careGroupingsKey[clientCareGrouping?.careGrouping]?.settings?.baseTable === table;
                } else {
                    return true;
                }
            });

            tablesObject.push(..._(ccg).groupBy('careGrouping').map((k,v) => {
                return {
                    table: table,
                    ccgName: v,
                    ccgTitle: _.get(k, '[0].title'),
                    ccgEnvironmentIds: _.map(k, 'environmentId')
                }
            }).value());
            if (_.isEmpty(ccg)) {
                tablesObject.push({
                    table: table,
                    ccgName: '',
                    ccgTitle: '',
                    ccgEnvironmentIds: undefined

                });
            }
        });
        states.viewReleaseImpactTable.data = await this.getCCGs(tablesObject);
        return states;
    };
    async getCCGs(tables: any) {
        for (const table of tables) {
            if (table.ccgName && !_.isEmpty(table.ccgName)) {
                table.environments = await this.findEnvironments(table.ccgName, table.ccgEnvironmentIds);
            }
        }
        return tables;
    }
    async getReleaseSummary(states: IViewReleaseImpactStates) {
        const runDataStates = {} as IRunDataReleaseStates;
        runDataStates.releaseId = states.releaseId;
        runDataStates.finalCatalogSources = await this.dataAccess.genericMethod({
            model: 'GitHub',
            method: 'getFinalCatalogSources'
        });
        const astronomerDagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });
        runDataStates.astronomerBaseUrl = astronomerDagsUrl.data;
        runDataStates.releaseSummary = [...await this.runDataServices.generateReleaseSummary(runDataStates)];
        return runDataStates;
    };
    async findEnvironments(careGroupingName: string, careGroupingEnvironmentIds: string) {
        const environments = await this.dataAccess.genericMethod({
            model: 'MetaDataUsage',
            method: 'getEnvironmentUsageResults',
            parameters: {
                metaDataTypes: ['careGrouping'],
                metaDataNames: [careGroupingName],
                environmentIds: careGroupingEnvironmentIds
            }
        });
        return _.map(environments, 'environment_url');

    }
    setTablePageSize(states: IViewReleaseImpactStates) {
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        states.pageSize = (possibleRowsToShow > 10) ? possibleRowsToShow : 10;
        states.tableShowRows = states.tables.slice(0, states.pageSize);
        states.viewReleaseImpactTable = new MatTableDataSource(states.tableShowRows);
    }
}
