import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IClarifyInsightsLandingPage } from './clarifyInsightsLandingPage.component.d';
import { StateService } from '@uirouter/core';
import { ArgosStoreService } from 'client/app/services/argosStore.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';


@Injectable()
export class ClarifyInsightsLandingPageService {


    constructor(private dataAccess: NgDataAccess, private state: StateService, private argosStore: ArgosStoreService, private snackBar: MatSnackBar) { }

    async initDelegate(states: IClarifyInsightsLandingPage): Promise<object> {
        await this.init(states);
        return states;
    }

    async init(states: IClarifyInsightsLandingPage) {
        states.isLoading = true;
        const dagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });
        states.dagsUrl = `${dagsUrl.data}/insights-s3-to-s3-transfer-pipeline/grid?dag_run_id=`
        const customers = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsCustomer'
        });
        states.customers = _.cloneDeep(customers);
        states.customers.forEach(async (customer) => {
            if (!customer.isProvisioned) {
                const statusResponse = await this.dataAccess.genericMethod({
                    model: 'JiraApi',
                    method: 'getIssueStatus',
                    parameters: {
                        key: customer.accountProvisioningTicketNumber
                    }
                });
                if (statusResponse.fields.status.name.toLowerCase() === 'done') {
                    customer.isProvisioned = true;
                    await this.dataAccess.genericUpsert({
                        model: 'ClarifyInsightsCustomer',
                        data: customer
                    });
                }
            }
        });

        states.customersGrouped = _.map(_.groupBy(states.customers, 'customerId'), (value, key) => {
            return {
                customerId: key,
                customerCode: value[0].customerCode,
                customerName: value[0].customerName,
                extractTypes: value.map((extractType) => {
                    return extractType.extractType;
                }).join(', '),
                additionalFields: value.map(({extractType, awsAccountId, awsAccountName, s3DestinationBucket, createdBy, createdDate, accountProvisioningTicketNumber, isProvisioned, isActive, customerId, customerCode, customerName, id}) => {
                    return {
                        extractType: extractType,
                        awsAccountId: awsAccountId,
                        awsAccountName: awsAccountName,
                        s3DestinationBucket: s3DestinationBucket,
                        createdBy: createdBy.replace('@clarifyhealth.com', ''),
                        createdDate: this.displayDate(createdDate),
                        accountProvisioningTicketNumber: accountProvisioningTicketNumber,
                        isProvisioned: isProvisioned,
                        isActive: isActive,
                        customerId: customerId, // used for the finalize account modal
                        customerCode: customerCode, // used for the finalize account modal,
                        customerName: customerName, // used for navigation to filters
                        id: id // used for deletion
                    };
                })
            };
        });
        states.customersGrouped.sort((a: any, b: any) => a.customerCode.localeCompare(b.customerCode));
        for (const customer of states.customersGrouped) {
            for (const additionalFields of customer.additionalFields) {
                const lastRunInfo = await this.dataAccess.genericFind({
                    model: 'ClarifyInsightsTransferHistory',
                    filter: {
                        where: {
                            and: [{customerId: additionalFields.customerId}, {extractType: additionalFields.extractType}]}
                        },
                        order: 'createdDate DESC',
                        limit: 1
                })
                if (lastRunInfo.length > 0) {
                    additionalFields.dagRunLink = `${states.dagsUrl}${encodeURIComponent(lastRunInfo[0].dagRunId)}`;
                }
                else {
                    additionalFields.dagRunLink = '';
                }
            }
        }
        states.customersGrouped.forEach(async (customer: any) => {
            customer.additionalFields.forEach(async (additionalFields: any) => {
                additionalFields.deletionEligible = await this.checkDeletionEligibility(customer.customerId, additionalFields.extractType);
            });
        });
        states.isLoading = false;
    };

    displayDate(createdDate: string) {
        return new Date(createdDate).toLocaleDateString();
    };

    navigateToFilters(element: any) {
        let params: {} = {};
        if (element.customerName) {
            params = {...params, customerName: element.customerName};
        }
        if (element.extractType) {
            params = {...params, extractType: element.extractType.replace('_', ' ')};
        }
        this.state.go(`argos.clarifyInsightsFilter.list`, {'queryString':JSON.stringify(params)})
    }

    async checkDeletionEligibility(customerId: string, extractType: string) {
        const extracts = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsExtractHistory',
            filter: {
                where: {
                    customerId: customerId,
                    extractType: extractType
                }
            }
        });
        if (extracts.length > 0) {
            return false;
        }
        const transfers = await this.dataAccess.genericFind({
            model: 'ClarifyInsightsTransferHistory',
            filter: {
                where: {
                    customerId: customerId,
                    extractType: extractType
                }
            }
        });
        if (transfers.length > 0) {
            return false;
        }
        return true;
    }

    async deleteCustomerExtract(row: any) {
        try {
            const filters = await this.dataAccess.genericFind({
                model: 'ClarifyInsightsFilter',
                filter: {
                    where: {
                        customerId: row.customerId,
                        extractType: row.extractType
                    }
                }
            });
            const filtersIds = filters.map((filter: any) => filter.id);
            for (const filterId of filtersIds) {
                await this.dataAccess.genericDelete({
                    model: 'ClarifyInsightsFilter',
                    id: filterId
                });
            }
            await this.dataAccess.genericDelete({
                model: 'ClarifyInsightsCustomer',
                id: row.id
            });
            if (row.accountProvisioningTicketNumber) {
                    await this.dataAccess.genericMethod({
                    model: 'JiraApi',
                    method: 'addComment',
                    parameters: {
                        jiraTicketId: row.accountProvisioningTicketNumber,
                        comment: `DO NOT COMPLETE. Customer extract request deleted via Argos by ${this.getUser()}`
                    }
                });
            }
        }
        catch (exception: any) {
            console.log(exception);
            this.snackBar.open(`Error deleting customer extract: ${exception}`, 'Dismiss', {
                duration: 5000
            });
        }
    }


    getUser() {
        return this.argosStore.getItem('username').replace('@clarifyhealth.com', '');
    }

}