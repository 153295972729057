import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IUserRoleStates, IUserRoleService } from './userRoleEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { StateService, UIRouter } from '@uirouter/core'; 
import { ValidationService } from '../../../services/validationService.service';
import * as _ from 'lodash';
import { ReferenceDataSourcesListComponent } from '../../referenceDataSources/referenceDataSourcesList/referenceDataSourcesList.component';
import { ViewVersionHistoryModalComponent } from '../../viewVersionHistory/viewVersionHistoryModal/viewVersionHistoryModal.component';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { UserRoleService } from 'client/app/services/userRole.service';
import { UtilsService } from '../../../services/utils.service';
@Injectable()
export class UserRoleEditService implements IUserRoleService {
    constructor(private state: StateService, private dataAccess: NgDataAccess, private matDialog: MatDialog, 
                private uiRouter: UIRouter, private cdr: ChangeDetectorRef, private validationSrv: ValidationService, 
                private argosStore: ArgosStoreService, private userRoleService: UserRoleService, private utils: UtilsService) {
    }

    async initDelegate(states: IUserRoleStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    userMenuStateNameExists(states: IUserRoleStates, menuName: string, stateName: string, ): boolean {
        let result = _.find(states.userRole.accessSettings.menuItems[menuName], function (sref: string) {
            return sref === stateName;
        });

        return result !== undefined;
    }

    processMenuSelection(sysMenu: any, sysMenuStateName: string, defaultActions: any, defaultRequiredApiAccess: any, states: IUserRoleStates) {
        //init actions and requiredApiAccess if not defined and if nothign is passed in set it to empty
        if (!sysMenu.actions) {
            sysMenu.actions = defaultActions || {};
        }

        //we set the requiredApiAccess so it is save in the role object. You should NOT be able to change this from the UI
        if (!sysMenu.requiredApiAccess) {
            sysMenu.requiredApiAccess = defaultRequiredApiAccess || {};
        }
        
        if (states.userRole.accessSettings?.menuItems[sysMenuStateName]) {
            sysMenu.selected = true;
            _.forOwn(states.userRole.accessSettings?.menuItems[sysMenuStateName].actions, (value, key) => { 
                if (value === true) {           
                    sysMenu.actions[key] = true;
                }
            });
        }
    }
 
    async initMenuSelections(states: IUserRoleStates) {
        const argosSysMenus: any[] = [];
        _.forEach(this.state.get(), (cState: any) => {
            if (cState.name.startsWith('argos.')) {
                cState.data = _.pick(cState.data, Object.getOwnPropertyNames(cState.data));
                //if actions or requiredApiAccess are not defined, then define them as empty
                if (_.isUndefined(cState.data.actions)) {
                    cState.data.actions = {};
                }
                if (_.isUndefined(cState.data.requiredApiAccess)) {
                    cState.data.requiredApiAccess = {};
                }
                argosSysMenus.push(cState);
            }
        });

        states.argosMenus = _.cloneDeep(require('../../../constants/menus'));    //load menus object
        //figure out what menus are selected and therefore visible for this user
        _.forOwn(states.argosMenus, (value, key) => { 
            _.forEach(value, (m: any) => {
                let parentActions = _.filter(argosSysMenus, function (cState: any) { return cState.name === m.sref })
                this.processMenuSelection(m, m.sref, parentActions[0]?.data?.actions, parentActions[0]?.data?.requiredApiAccess, states);                
                
                //setup actions states for child menu items
                if (m.sref.endsWith('.list') || m.sref === 'argos.ecsAccess.ecsAppList') {
                    
                    //if this is a list item, then we assume there could be add/edit/delete
                    let parentStateName = m.sref.substring(0, m.sref.length - 4);                    
                    m.subMenus = [];
                    
                    const childSystemMenus:any[] = [];
                    _.forEach(argosSysMenus, (cState: any) => { 
                        if (cState.name.startsWith(parentStateName) && cState.name !== m.sref) {
                            childSystemMenus.push(_.clone(cState));
                        }
                    })

                    _.forEach(childSystemMenus, (childSystemMenu: any) => {
                        let displayName = childSystemMenu?.data.pageTitle || childSystemMenu.name.substring(childSystemMenu.name.lastIndexOf('.') + 1, childSystemMenu.name.length);
                        let newMenu = {
                            sref: childSystemMenu.name,
                            selected: false,
                            name: displayName
                        };
                        this.processMenuSelection(newMenu, newMenu.sref, childSystemMenu.data?.actions, childSystemMenu.data?.requiredApiAccess, states);  
                        m.subMenus.push(newMenu);
                    });
                    
                }
            });
        } );
    }

    async activate(states: IUserRoleStates) { 
        states.roleNames = await this.dataAccess.genericMethod({
            model: 'ArgosUserRole', method: 'find', parameters: {
                filter: {
                    include: ['argosUserRoleHistories'],
                    order: 'roleName'
                }
            }
        });

        //edit mode
        if (this.uiRouter.globals.params.id) {
            states.userRole = _.find(states.roleNames, { id: Number(this.uiRouter.globals.params.id) });

            //exclude current role from list
            states.roleNames = _.filter(states.roleNames, (r: any) => { return r.id !== Number(this.uiRouter.globals.params.id); });
            
        } else {
            //new mode
            states.userRole = {
                disabled: false,
                seeded: false,
                accessSettings: {
                    menuItems: {}
                },
                argosUserRoleHistories: []
            };
        }

        _.forEach(states.userRole.argosUserRoleHistories, (hist) => {
            hist.creationDate = this.utils.getLocalDate(hist.creationDate)
        });
        states.userRole.argosUserRoleHistories = _.orderBy(states.userRole.argosUserRoleHistories, ['id'], ['desc']);
        states.roleNames = _.uniq(_.map(states.roleNames, 'roleName'));
        states.originalUserRole = _.cloneDeep(states.userRole);

        states.userRole.propertyErrors = [];
        states.argosMenus = await this.userRoleService.initMenuSelections(states.userRole, this.state);
    }

    openMatTableSettings(states: IUserRoleStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.environmentMatTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    async save(states: IUserRoleStates) {
        await this.userRoleService.saveMenuSelections(states.userRole, states.originalUserRole, states.argosMenus);
        this.state.go('argos.admin.userRoles.list');
    }

    getPropertyErrorMsg(propertyName: string, states: IUserRoleStates) {
        const msg = this.validationSrv.getPropertyErrorMessage(propertyName, states.userRole.propertyErrors);
        return msg;
    }

    hasPropertyError(propertyName: string, states: IUserRoleStates) {
        return this.validationSrv.showPropertyError(propertyName, states.userRole.propertyErrors);
    }

    isSaveDisabled(states: IUserRoleStates) {
        let result = false;

        if (states.userRole.propertyErrors) {

            const reqProperties = ['roleName', 'roleTitle', 'description'];
            let propertyErrors = this.validationSrv.validateAllRequiredProperties(states.userRole, reqProperties);
            propertyErrors = propertyErrors.concat(this.validationSrv.validatePropertyValueNotExistsInList('roleName', states.userRole.roleName, states.roleNames));            
            propertyErrors = propertyErrors.concat(this.validationSrv.validatePropertyValueWithRegex('roleName', states.userRole.roleName, '^[a-zA-Z0-9_]*$', 'Role name can only contain letters, numbers, and underscores'));

            states.userRole.propertyErrors = propertyErrors;
            result = (result || (states.userRole.propertyErrors.length > 0));
        }

        return result;
    }

    rolePermissionChange(sysMenu: any, states: IUserRoleStates) {
        
        if (sysMenu.selected === false) {
            _.forOwn(sysMenu.actions, (value: any, key: string) => {
                sysMenu.actions[key] = false;   //clear actions
            });

            //clear sub menu selections
            _.forEach(sysMenu.subMenus, (subMenu: any) => {
                _.forOwn(subMenu.actions, (value: any, key: string) => {
                    subMenu.actions[key] = false;   //clear actions
                });
                subMenu.selected = false;   //clear sub menu selections
            })
        }

        this.cdr.detectChanges();
    }

    viewHistory(states: IUserRoleStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'modal-full',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    versionHistory: states.userRole.argosUserRoleHistories 
                }
            }
        };
        this.matDialog.open(ViewVersionHistoryModalComponent, dialogConfig);
    }
}
