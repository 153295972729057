import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ICcgFeatureListStates, ICcgFeatureListService } from './ccgFeatureList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class CcgFeatureListService implements ICcgFeatureListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, private cdr: ChangeDetectorRef) {
        //
    }

    async initDelegate(states: ICcgFeatureListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: ICcgFeatureListStates) {
        const response = await this.dataAccess.genericFind({
            model: 'CcgFeature'
        });
        
        states.ccgFeatures = _.orderBy(response, ['name']);
        states.table.data = states.ccgFeatures;
    }

    setTablePageSize(states: ICcgFeatureListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.ccgFeatures.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: ICcgFeatureListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: ICcgFeatureListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }
}
