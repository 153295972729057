import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IClarifyProviderRevisionEditStates, IClarifyProviderRevisionEditService } from './clarifyProviderRevisionEdit.component.d';
import { getClarifyProviderRevisionsObj, getGrainName, getRevisionClass, getRevisionMethod, getTextName } from '../commonUtils';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { MatTableDataSource } from '@angular/material/table';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { StateService, UIRouter } from '@uirouter/core';
import { MatDialog } from '@angular/material/dialog';
import { MD5Service } from '../../../services/md5.service';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class ClarifyProviderRevisionEditService implements IClarifyProviderRevisionEditService {
    constructor(private argosStore: ArgosStoreService, private exportToExcelSvc: ExportToExcelService,  private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog,
        private state: StateService, private md5: MD5Service,
        private alertSvc: AlertService, private cdr: ChangeDetectorRef) {
        //
    }

    getParameters(grain: string, clarifyProviderRevisions: any, database: any) {
        switch(grain) {
            case 'attributedParentId':  return {'attributedParentId': clarifyProviderRevisions.attributedParentId, database};
            case 'primaryPhysicianGroupAffiliationNpi': return {'primaryPhysicianGroupAffiliationNpi': clarifyProviderRevisions.primaryPhysicianGroupAffiliationNpi, database};
            default: return {'npi': clarifyProviderRevisions.npi, database};
        }
    }

    async initDelegate(states: IClarifyProviderRevisionEditStates): Promise<object> {
        states.currentRevisionGrainSelection = this.uiRouter.globals.params.currentRevisionGrainSelection
        await this.activate(states);
        return {};
    }

    async activate(states: IClarifyProviderRevisionEditStates) {
        const currentRevisionGrainSelection = this.uiRouter.globals.params.currentRevisionGrainSelection
        states.lodash = _;
        // If user goes to add URL directly, redirect to list
        if (!this.uiRouter.globals.params.npiAttributes && !this.uiRouter.globals.params.id) {
            this.state.go('argos.referenceData.clarifyProviderRevision.list');
        }

        // If revision already exists retrieve it, otherwise create a new object with null ID
        if (this.uiRouter.globals.params.id) {
            states.clarifyProviderRevisions = await this.getClarifyProviderRevisions();
        } else {
            states.clarifyProviderRevisions = getClarifyProviderRevisionsObj(currentRevisionGrainSelection, this.uiRouter.globals.params.npiAttributes);
        }

        // If npi attributes have already been retrieved map them to camel case, if not query athena to get
        if (this.uiRouter.globals.params.npiAttributes) {
            states.npiAttributes = _.mapKeys(this.uiRouter.globals.params.npiAttributes, function (value, key: any) {
                return _.camelCase(key);
            });
        } else {
            await this.getClarifyProviderData(states, undefined);
        }

        this.mapClarifyProviderData(states);

        states.dataSources = await this.dataAccess.genericFind({
            model: 'DataSource',
            filter: {
                dataType: 'claim',
                order: 'name ASC'
            }
        });

        states.dataLoaded = true;

        this.updateClarifyProviderVersions(states);

        this.cdr.detectChanges();
    }

    async getClarifyProviderRevisions() {
        const model = getRevisionClass(this.uiRouter.globals.params.currentRevisionGrainSelection);
        return this.dataAccess.genericMethod({
            model,
            method: 'findById',
            parameters: {
                id: this.uiRouter.globals.params.id
            }
        });
    }

    async getClarifyProviderData(states: IClarifyProviderRevisionEditStates, database: string | undefined) {
        const currentRevisionGrainSelection = this.uiRouter.globals.params.currentRevisionGrainSelection
        const clarifyProviderData = await this.dataAccess.genericMethod({
                model: getRevisionClass(this.uiRouter.globals.params.currentRevisionGrainSelection),
                method: getRevisionMethod(this.uiRouter.globals.params.currentRevisionGrainSelection),
                parameters: this.getParameters(this.uiRouter.globals.params.currentRevisionGrainSelection, states.clarifyProviderRevisions, database)
            });
        states.npiAttributes = _.mapKeys(clarifyProviderData.result.results, function (value, key: any) {
            return _.camelCase(key);
        });
    }

    mapClarifyProviderData(states: IClarifyProviderRevisionEditStates) {
        delete states.npiAttributes.year;
        delete states.npiAttributes.npiYear;
        delete states.npiAttributes.id;
        states.npiAttributes.dataSourcesToApply = states.clarifyProviderRevisions['dataSourcesToApply'];
        const tableKey = this.uiRouter.globals.params.currentRevisionGrainSelection
        // Create a new object, the 3 keys should be column, originalValues, revisedValues.
        // Columns should be the keys of states.npiAttributes,
        // orginalValues should be the values of states.npiAttributes,
        // revisedValues should be the values of states.clarifyProviderRevisions
        states.attributeData = _.map(states.npiAttributes, function (value: any, key: any) {
            const revisedValue = states.clarifyProviderRevisions[_.camelCase((['dataSourcesToApply', 'npi'].includes(key) ? '' : 'revised_') + key)];
            return {
                column: key,
                originalValue: value,
                revisedValue,
                dataType: revisedValue ? typeof revisedValue : ['true', 'false'].includes(value) ? 'boolean' : typeof value
            };
        });

        states.table.data = _.sortBy(states.attributeData , function (ad: any) {
            if (ad.column === 'npi' || ad.column === 'attributedParentId' && tableKey === 'attributedParentId' ||
                ad.column === 'primaryPhysicianGroupAffiliationNpi' && tableKey === 'primaryPhysicianGroupAffiliationNpi') {
                return 1;
            } else if (ad.column === 'dataSourcesToApply') {
                return 2;
            } else if (ad.revisedValue) {
                return 3;
            } else {
                return 4;
            }
        });

        states.originalAttributeData = _.cloneDeep(states.attributeData);
    }


    async save(states: IClarifyProviderRevisionEditStates) {
        states.saveInProgress = true;

        // Pivot column and revistedValues into an object, key from column and value from revisedValues
        const revisedValues = _.reduce(states.attributeData, function (result: any, value: any) {
            if (value.column === 'attributedParentId' || value.column === 'primaryPhysicianGroupAffiliationNpi') {
                result[value.column] = value.originalValue;
                result[_.camelCase(`revised_${value.column}`)] = value.revisedValue;
            } else {
                result[value.column] = value.revisedValue;
            }
            return result;
        }, {});

        const tableKey = this.uiRouter.globals.params.currentRevisionGrainSelection;

        const revisedValuesCamelCase = _.mapKeys(revisedValues, function (value, key: any) {
            if ([ tableKey, 'dataSourcesToApply' ].includes(key)) {
                return key;
            }
            return _.camelCase(key.indexOf('revised') >= 0 ? key : 'revised_' + key);
        });

        revisedValuesCamelCase.id = states.clarifyProviderRevisions.id;
        const model = getRevisionClass(tableKey)
        try {
            await this.dataAccess.genericUpsert({
                model,
                data: revisedValuesCamelCase
            });

            this.state.go('argos.referenceData.clarifyProviderRevision.list', {currentRevisionGrainSelection: tableKey});
            states.saveInProgress = false;
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    updateClarifyProviderVersions(states: IClarifyProviderRevisionEditStates) {
        states.clarifyProviderVersions = ['reference_data_staging', 'final_reference_data_prod'];
        const dataSourcesToApply = _.filter(states.attributeData, (o) => o.column === 'dataSourcesToApply')[0].revisedValue;
        _.forEach(dataSourcesToApply, function (value: any) {
            if (value === 'all') {
                return;
            }
            states.clarifyProviderVersions.push(`reference_data_${value}_staging`);
            states.clarifyProviderVersions.push(`final_${value}_prod`);
        });
    }

    setTablePageSize(states: IClarifyProviderRevisionEditStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.clarifyProviderRevisions.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IClarifyProviderRevisionEditStates) {
        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IClarifyProviderRevisionEditStates) {
        const data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    async deleteRevision(states: IClarifyProviderRevisionEditStates) {
        const currentRevisionGrainSelection = this.uiRouter.globals.params.currentRevisionGrainSelection
        const textGrain = getGrainName(currentRevisionGrainSelection);
        const textName = getTextName(currentRevisionGrainSelection, states.npiAttributes);
        swal({
            title: `Confirm Revisions Deletion for ${textGrain} ${states.npiAttributes[currentRevisionGrainSelection]} - ${textName}`,
            text: 'Are you sure you want to delete these revisions?',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                await this.dataAccess.genericDelete({
                    model: getRevisionClass(currentRevisionGrainSelection),
                    id: states.clarifyProviderRevisions.id
                });

                this.state.go('argos.referenceData.clarifyProviderRevision.list', {currentRevisionGrainSelection});
            }
        });

    }

    changeClarifyProviderVersion(states: IClarifyProviderRevisionEditStates) {
        this.getClarifyProviderData(states, states.clarifyProviderVersion);
        this.mapClarifyProviderData(states);
    }
}
