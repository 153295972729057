import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IHospitalRatesLandingService, IHospitalRatesLandingStates } from '../hospitalRatesLandingPage/hospitalRatesLandingPage.component.d';
import { ExportToExcelService } from '../../../services/exportToExcel.service';


@Injectable()
export class HospitalRatesLandingServices implements IHospitalRatesLandingService {

    constructor(private dataAccess: NgDataAccess, private exportToExcelService: ExportToExcelService) {
    }

    async initDelegate(states: IHospitalRatesLandingStates){
        await this.init(states);
    };

    async init(states: IHospitalRatesLandingStates) {
        let allHospitals = await this.query();
        for (const column of states.fixNullColumns) {
            await this.fixNullsForFilter(column, 'string', allHospitals);
        }
        states.allHospitals.data = allHospitals;
    };

    async query(){
        const allHospitals = await this.dataAccess.genericMethod({
            model: 'HospitalRatesHospitals',
            method: 'getAllHospitals'
        });
        return allHospitals;
    }

    async fixNullsForFilter(columnName: string, columnType: string, allHospitals: any) {
        // cast any null values to empty string to avoid type error in filter
        allHospitals.forEach((hospital: any) => {
            if (hospital[columnName] === null) {
                hospital[columnName] = '';
            }
        });
    }

    bulkExportToExcel(data: any, columnsToDisplay: string[]) {
        let timestamp = new Date().toISOString();
        let columnsToExclude = ['editButton'];
        let exportColumns = columnsToDisplay.filter(col => !columnsToExclude.includes(col));
        this.exportToExcelService.exportToFile(`hospital_rates_bulk_export_${timestamp}`, exportColumns, data, false);
    }
}