import { Injectable } from '@angular/core';
import { IPipelineHistoryDetailStates, IPipelineHistoryDetailService, IHintData } from './pipelineHistoryDetail.component.d';
import { ErrorLogModalComponent } from '../errorLogModal/errorLogModal.component';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PipelineHistoryChartModelComponent } from '../pipelineHistoryChartModel/pipelineHistoryChartModel.component';
import { IChartDataset } from '../pipelineHistoryChartModel/pipelineHistoryChartModel.component.d';
import { OptimizationHintModalComponent } from '../optimizationHintModal/optimizationHintModal.component';


@Injectable()
export class PipelineHistoryDetailService implements IPipelineHistoryDetailService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private matDialog: MatDialog) {
        //
    }

    async initDelegate(states: IPipelineHistoryDetailStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IPipelineHistoryDetailStates) {
        states.dataSource = this.uiRouter.globals.params.dsKey;
        states.stage = this.uiRouter.globals.params.stageKey;

        // Use timeframe selected from main landing page
        states.previousTimeFrameSelection = this.uiRouter.globals.params.currentTimeFrameSelection;

        // Use pipeline type selected from main landing page
        states.previousPipelineTypeSelection = this.uiRouter.globals.params.currentPipelineTypeSelection;

        // Get the relevant pipelineIds from session storage
        const pipelineIdsKey = states.stage + '|' + states.dataSource + '|' + states.previousTimeFrameSelection + '|' + states.previousPipelineTypeSelection;
        states.pipelineIds = sessionStorage.getItem(pipelineIdsKey);

        await this.querySteps(states);
    }

    openModal(error: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    error
                }
            }
        };
        this.matDialog.open(ErrorLogModalComponent, dialogConfig);
    }

    openOptModal(hintText: IHintData[], states: IPipelineHistoryDetailStates, pipelineId: string) {
        const optDialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    hintText,
                    dataSource: states.dataSource,
                    stage: states.stage,
                    previousTimeFrameSelection: states.previousTimeFrameSelection,
                    previousPipelineTypeSelection: states.previousPipelineTypeSelection,
                    pipelineIds: states.pipelineIds,
                    pipelineId: pipelineId
                }
            }
        };
        this.matDialog.open(OptimizationHintModalComponent, optDialogConfig);
    }

    async querySteps(states: IPipelineHistoryDetailStates) {
        const allPipelines: any[] = [];
        states.allPipelineSteps = [];
        const chunkSize = 50;
        const pipelineIds = states.pipelineIds?.split(',');
        for (let i = 0; i < pipelineIds?.length; i += chunkSize) {
            const res = await this.dataAccess.genericFind({
                model: 'PipelineStep',
                filter: {
                    where: {
                        pipelineHistoryId: { inq: pipelineIds.slice(i, i + chunkSize) }
                    }
                }
            });
            Array.prototype.push.apply(states.allPipelineSteps, res);
            const results = await this.dataAccess.genericFind({
                model: 'PipelineHistory',
                filter: {
                    where: {
                        id: { inq: pipelineIds.slice(i, i + chunkSize) }
                    }
                }
            });
            results.forEach((result: any) => {
                const clusterConfigJson: any[] = this.parseClusterConfig(result.clusterConfig);
                const isDiskWarningLevel: boolean = this.flagDiskSpaceWarning(result.diskSpaceUtilization);
                const isMemWarningLevel: boolean = this.flagMemWarning(result.memoryUtilization);
                const isRecommendable: boolean = this.checkEligibility(isDiskWarningLevel, clusterConfigJson);
                const flaggedInstances = this.flagInstanceTypeWarning(clusterConfigJson);
                const runTime: number = this.parseRunTime(result.endTime, result.startTime);
                const spotRecommended: boolean = this.checkSpotEligibility(clusterConfigJson, runTime);
                const currentPipeline = {
                    pipelineId: result.id,
                    pipelineName: result.pipelineName,
                    pipelineStartTime: result.startTime,
                    pipelineRunTime: runTime,
                    pipelineCost: parseInt(result.cost),
                    pipelineStatus: result.status,
                    pipelineFailureReason: result.failureReason,
                    pipelineAddInfo: result.addInfo,
                    emrClusterId: result.emrClusterId,
                    isRecommendable,
                    hintText: this.configureHintText(isRecommendable, isDiskWarningLevel, flaggedInstances, spotRecommended, result.diskSpaceUtilization, clusterConfigJson, result.memoryUtilization, isMemWarningLevel),
                    detailIsHidden: false,
                    pipelineCount: this.getStepCount(result.id, states)
                };
                allPipelines.push(currentPipeline);
            });
            states.allPipelines.data = _.cloneDeep(_.orderBy(allPipelines, ['pipelineStartTime'], ['desc']) || []);
        }
    }

    parseRunTime(endTime: any, startTime: any) {
        return (new Date(endTime).getTime() - new Date(startTime).getTime()) / (1000.0 * 60.0 * 60.0);
    }

    getStepCount(pipelineId: any, states: IPipelineHistoryDetailStates) {
        const results = _.filter(states.allPipelineSteps, function (p) {
            return p.pipelineHistoryId === pipelineId;
        });
        return results.length;
    }

    parseClusterConfig(clusterConfig: string) {
        const clusterConfigJsonString = clusterConfig.replace(/'/g, '"');
        return JSON.parse(clusterConfigJsonString);
    }

    checkSpotEligibility(clusterConfigJson: any[], runTime: number) {
        // eligible for spot recommendation
        let spotRecommended = false;
        const runTimeMax = 4;
        if (runTime <= runTimeMax) {
            const taskConfig = clusterConfigJson.find(cc => cc.name === 'Task');
            if (taskConfig) {
                const marketType = taskConfig?.market;
                if (marketType !== 'SPOT') {
                    spotRecommended = true;
                }
            }
        }
        return spotRecommended;
    }

    flagDiskSpaceWarning(diskSpaceUtilization: any) {
        // warning if usage inside minThreshold or maxThreshold
        const minThresholds: number[] = [0, 35];
        const maxThresholds: number[] = [75, 101];
        const instanceGroups: string[] = ['Core', 'Task'];
        let isWarningLevel = false;
        if (diskSpaceUtilization) {
            instanceGroups.forEach(instanceGroup => {
                if (instanceGroup in diskSpaceUtilization) {
                    const maxUsage = Number(diskSpaceUtilization[instanceGroup].max_percentage_used);
                    if ((_.inRange(maxUsage, minThresholds[0], minThresholds[1]) === true) || (_.inRange(maxUsage, maxThresholds[0], maxThresholds[1]) === true)) {
                        isWarningLevel = true;
                    }
                }
            });
        }
        return isWarningLevel;
    }

    flagMemWarning(memUtilization: any) {
        // warning if usage inside minThreshold or maxThreshold
        const minThresholds: number[] = [0, 30];
        const maxThresholds: number[] = [80, 101];
        const instanceGroups: string[] = ['Core', 'Task'];
        let isWarningLevel = false;
        if (memUtilization) {
            instanceGroups.forEach(instanceGroup => {
                if (instanceGroup in memUtilization) {
                    const maxUsage = Number(memUtilization[instanceGroup].max_percentage_used);
                    if ((_.inRange(maxUsage, minThresholds[0], minThresholds[1]) === true) || (_.inRange(maxUsage, maxThresholds[0], maxThresholds[1]) === true)) {
                        isWarningLevel = true;
                    }
                }
            });
        }
        return isWarningLevel;
    }

    generateMemoryOptimizations(memoryUtilization: any, clusterConfigJson: any[]) {
        const memoryOptimizationArray: string[] = [];
        const instanceGroups: string[] = ['Core', 'Task'];
        const supportedInstanceSizes: string[] = ['xlarge', '2xlarge', '4xlarge', '8xlarge', '12xlarge', '16xlarge', '24xlarge'];
        const targetThresholds: number[] = [80, 30]; // [max_percentage, min_percentage]
        instanceGroups.forEach(instanceGroup => {
            if (instanceGroup in memoryUtilization) {
                const usedPercent: number = (memoryUtilization[instanceGroup as keyof typeof memoryUtilization].max_percentage_used / 100);
                if (usedPercent > (targetThresholds[0] / 100)) {
                    const config = clusterConfigJson.find(cc => cc.name === instanceGroup);
                    const instanceType = config?.instanceType.split(',')[0];
                    const instanceFamily = instanceType[0];
                    const optMessage = (instanceFamily === 'm') ? 'Consider using memory-optimized instances from the r-family.' : '';
                    const instanceSize = instanceType.split('.')[1];
                    const instanceSizeIndex = supportedInstanceSizes.indexOf(instanceSize);
                    const nextInstanceSize = (supportedInstanceSizes[instanceSizeIndex + 1]) ? supportedInstanceSizes[instanceSizeIndex + 1] : 'a larger size';
                    memoryOptimizationArray.push(`High memory utilization ${instanceGroup} instances. Consider upscaling vertically by using ${nextInstanceSize} instances or horizontally by adding more instances. ${optMessage}`);
                }
                else if (usedPercent < (targetThresholds[1] / 100)) {
                    const config = clusterConfigJson.find(cc => cc.name === instanceGroup);
                    const instanceType = config?.instanceType.split(',')[0];
                    const instanceSize = instanceType.split('.')[1];
                    const instanceSizeIndex = supportedInstanceSizes.indexOf(instanceSize);
                    const previousInstanceSize = supportedInstanceSizes[instanceSizeIndex - 1] || 'a smaller size';
                    memoryOptimizationArray.push(`Low memory utilization ${instanceGroup} instances. Consider downscaling vertically by using ${previousInstanceSize} instances or horizontally by removing instances.`);
                }
            }
        });
        return memoryOptimizationArray;
    }

    generateDiskOptimizations(diskSpaceUtilization: any, clusterConfigJson: any[]) {
        const diskOptimizationArray: string[] = [];
        const instanceGroups: string[] = ['Core', 'Task'];
        const targetThresholds: number[] = [70, 50]; // [max_percentage, min_percentage]
        instanceGroups.forEach(instanceGroup => {
            const config = clusterConfigJson.find(cc => cc.name === instanceGroup);
            let configSize = 0;
            if (config) {
                configSize = config?.Volume[0].VolumeSpecification.SizeInGB;
                if (instanceGroup in diskSpaceUtilization) {
                    let suggRange = 'N/A';
                    let minRec = 0;
                    let maxRec = 0;
                    let underProvisioned = false;
                    let overProvisioned = false;
                    const usedPercent: number = (diskSpaceUtilization[instanceGroup].max_percentage_used / 100);
                    if (configSize !== 0) {
                        const usedGB = Math.round(configSize * usedPercent);
                        minRec = Math.round(usedGB / (targetThresholds[0] / 100));
                        maxRec = Math.round(usedGB / (targetThresholds[1] / 100));
                        if (usedGB > minRec) {
                            underProvisioned = true;
                        }
                        if (usedGB < maxRec) {
                            overProvisioned = true;
                        }
                    }
                    if ((minRec !== 0) && (maxRec !== 0) && (instanceGroup !== 'Master')) {
                        suggRange = minRec.toString().concat(' - ', maxRec.toString(), ' GB');
                    }
                    let messageVariation = (overProvisioned) ? 'Over Provisioned: ' : (underProvisioned) ? 'Under Provisioned: ' : '';
                    diskOptimizationArray.push(`${messageVariation}Set ${instanceGroup} Instance Group between ${suggRange}`);
                }
            }
        });
        return diskOptimizationArray;
    }

    configureHintText(isRecommendable: boolean, isDiskWarningLevel: boolean, flaggedInstances: Set<string>, spotRecommended: boolean, diskSpaceUtilization: any[], clusterConfigJson: any[], memoryUtilization: any[], isMemWarningLevel: boolean) {
        // add additional checks here
        const hintText: IHintData[] = [];
        if (isRecommendable === false) {
            return hintText;
        }
        if (isDiskWarningLevel) {
            const tmpMsg: string[] = [];
            const diskOptimizationArray: string[] = this.generateDiskOptimizations(diskSpaceUtilization, clusterConfigJson);
            for (const diskOpt of diskOptimizationArray) {
                tmpMsg.push(diskOpt);
            }
            if (tmpMsg.length > 0) {
                hintText.push({ title: 'EBS Volume', msg: tmpMsg });
            }
        }
        if (isMemWarningLevel) {
            const tmpMsg: string[] = [];
            const memoryOptimizationArray: string[] = this.generateMemoryOptimizations(memoryUtilization, clusterConfigJson);
            for (const memOpt of memoryOptimizationArray) {
                tmpMsg.push(memOpt);
            }
            if (tmpMsg.length > 0) {
                hintText.push({ title: 'Memory Utilization', msg: tmpMsg });
            }
        }
        const instances: string[] = Array.from(flaggedInstances);
        if (instances.length > 0) {
            const tmpMsg: string[] = [];
            for (const instance of instances) {
                tmpMsg.push(`Use newer instance types, current ${instance} instance type is outdated`);
            }
            hintText.push({ title: 'Instance Family', msg: tmpMsg });
        }
        if (spotRecommended) {
            hintText.push({ title: 'Spot Instance', msg: ['Pipeline runtime is less than 4 hours, consider increasing Spot Instance allocation for task instances'] });
        }
        return hintText;
    }

    checkEligibility(isDiskWarningLevel: boolean, clusterConfigJson: any[]) {
        let isRecommendable = true;
        const config = clusterConfigJson.find(cc => cc.name === 'Master');
        if (!(config || isDiskWarningLevel)) {
            isRecommendable = false;
        }
        return isRecommendable;
    }

    flagInstanceTypeWarning(clusterConfigJson: any[]) {
        const sunsetInstanceFamily: string[] = ['m4.', 'm5a.', 'r4.', 'c4.']; // outdated instance families
        const instanceGroups: string[] = ['Master', 'Core', 'Task'];
        const instanceTypes: string[] = [];
        const flaggedInstances: string[] = [];
        instanceGroups.forEach(instanceGroup => {
            const config = clusterConfigJson.find(cc => cc.name === instanceGroup);
            if (config) {
                const allTypes = config?.instanceType.split(',');
                allTypes.forEach((instance: string) => instanceTypes.push(instance));
            }
        });
        if (instanceTypes.length > 0) {
            sunsetInstanceFamily.forEach(instanceFamily => {
                const flagType: any = instanceTypes.find(e => e.includes(instanceFamily));
                if (flagType) {
                    flaggedInstances.push(flagType);
                }
            });
        }
        const flaggedInstanceSet = new Set(flaggedInstances);
        return flaggedInstanceSet;
    }


    viewChartHandler(states: IPipelineHistoryDetailStates) {
        const chartLabels: string[] = [];
        const runTimeData: string[] = [];
        const averageCostData: string[] = [];
        let successCount = 0, failureCount = 0;
        const allPipelines = _.orderBy(_.cloneDeep(states.allPipelines), ['pipelineStartTime'], ['asc']) || [];
        const tooltip = allPipelines[0]?.pipelineName || '';
        _.forEach(allPipelines, row => {
            chartLabels.push(row.pipelineStartTime);
            if (_.toUpper(row.pipelineStatus) === 'SUCCESS') {
                successCount++;
            } else {
                failureCount++;
            }
            runTimeData.push(parseFloat(row.pipelineRunTime).toFixed(2));
            averageCostData.push(row.pipelineCost);
        });
        const ratio = parseFloat(`${successCount / (successCount + failureCount)}`).toFixed(3);
        const extraText = `Success/Failure Ratio: ${_.toNumber(ratio) * 100}%`;
        const chartDatasets: IChartDataset[] = [
            {
                label: 'Run Time',
                data: runTimeData,
                companyName: 'h',
                tooltip
            },
            {
                label: 'Cost',
                data: averageCostData,
                companyName: '$',
                tooltip
            }
        ];

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-large',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    chartLabels,
                    chartDatasets,
                    extraText
                }
            }
        };

        this.matDialog.open(PipelineHistoryChartModelComponent, dialogConfig);
    }
}
