import { Injectable } from '@angular/core';
import {
    IChartModalProps,
    IChartModalStates,
    IChartModalService,
    IChartDataset
} from './pipelineHistoryChartModel.component.d';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables, ChartDataLabels);
declare const $: any;

import * as _ from 'lodash';

@Injectable()
export class ChartModalService implements IChartModalService {
    initDelegate(props: IChartModalProps, states: IChartModalStates): IChartModalStates {
        const newStates = _.cloneDeep(states);
        newStates.chartLabels = props.chartLabels || [];
        newStates.chartDatasets = props.chartDatasets || [];
        newStates.extraText = props.extraText || '';
        newStates.filterConfig = props.filterConfig;
        if (newStates.filterConfig) {
            newStates.filterConfig.filterOptionsUniq = _.uniq(newStates.filterConfig.filterOptions);
        }


        return this.initChart(newStates, newStates.chartDatasets, newStates.chartLabels);
    }

    initChart(newStates: IChartModalStates, chartDatasets: any[], chartLabels: string[]): IChartModalStates {
        _.forEach(chartDatasets, (data, index) => {
            if (index < 7) {
                data.backgroundColor = data.backgroundColor || newStates.backgroundColors[index];
            } else {
                data.backgroundColor = data.backgroundColor || newStates.backgroundColors[index % 7];
            }
            if (index !== 0) {
                data.hidden = true;
            }
        });

        const canvasHtml: HTMLCanvasElement = document.getElementById('myChart') as HTMLCanvasElement;
        const ctx = canvasHtml?.getContext('2d') || '';
        let newwidth = (chartLabels.length * 27) + 50;
        newwidth = newwidth < 900 ? 900 : newwidth;

        $('.chartAreaWrapper').width(newwidth);

        // name of y https://jsfiddle.net/Leelenaleee/L2vhwe6u/33/
        const customTitle = {
            id: 'customTitle',
            afterDraw: (chart: any, args: any, opts: any) => {
                const { ctx, scales: { x, y }, chartArea: { top, bottom, left, right } } = chart;

                if (opts.y.display) {
                    ctx.fillStyle = opts.y.color || Chart.defaults.color;
                    ctx.font = opts.y.font || '12px "Helvetica Neue", Helvetica, Arial, sans-serif';
                    ctx.fillText(opts.y.text, opts.y.offsetX || 3, (top + ((opts.y.offsetY * -1) || -15)));
                }
            }
        };

        const chart: any = {
            type: 'bar',
            data: {
                labels: _.cloneDeep(chartLabels),
                datasets: _.cloneDeep(chartDatasets)
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                interaction: {
                    mode: 'x',
                    axis: 'x',
                    intersect: false
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                        onClick: (e: any, legendItem: any, legend: any) => {
                            this.legendClick(legendItem, newStates);
                        },
                        onHover (e: any) {
                            e.native.target.style.cursor = 'pointer';
                        },
                        onLeave (e: any) {
                            e.native.target.style.cursor = 'default';
                        }
                    },
                    title: {
                        display: true,
                        text: chartDatasets[0].label
                    },
                    customTitle: {
                        y: {
                            display: true,
                            text: chartDatasets[0].companyName || '',
                        }
                    },
                    tooltip: {
                        callbacks: {
                            title (tooltipItem: any, data: any) {
                                return tooltipItem[0]?.dataset?.tooltip || tooltipItem[0]?.label || '';
                            }
                        }
                    }
                },
                onHover: (event: any, chartElement: any) => {
                    const target = event.native ? event.native.target : event.target;
                    target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                },
                animation: {
                    onComplete: (animation: any) => {
                        this.animationOnComplete(newStates);
                    }
                }
            },
            plugins: [customTitle]
        };
        newStates.chart = new Chart(ctx, chart);
        return newStates;
    }

    legendClick(legendItem: any, states: IChartModalStates) {
        _.forEach(states.chart.data.datasets, (set, index) => {
            states.chart.config.options.plugins.title.text = legendItem.text;
            states.chart.getDatasetMeta(index).hidden = !!(set.label !== legendItem.text);
            if (set.label === legendItem.text) {
                states.chart.config.options.plugins.customTitle.y.text = set.companyName;
            }
        });

        states.chart.update();
    }

    // chart.js pagination http://jsfiddle.net/9oLs4djm/1/
    animationOnComplete(states: IChartModalStates) {
        const sourceCanvas = states.chart?.canvas;
        const copyWidth = states.chart?.scales['y-axis-0']?.width - 10;
        if (copyWidth > 0) {
            const copyHeight = states.chart.scales['y-axis-0']?.height; // + states.chart.scales['y-axis-0']?.top
            const targetCtx: any = (document.getElementById('myChartAxis') as HTMLCanvasElement)?.getContext('2d');
            targetCtx.canvas.width = copyWidth;
            targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
        }
    }

    filterChangeHandler(states: IChartModalStates) {
        const initChartDatasets = _.cloneDeep(states.chartDatasets);
        const filterValue = states.filterConfig?.filterValue || '';
        const dataIndex: number[] = [];
        let datasets = _.cloneDeep(states.chart.data.datasets);
        const chartLabels = _.cloneDeep(states.chartLabels);
        let labels: string[] = [];
        if (filterValue === 'all') {
            labels = chartLabels;
            datasets = initChartDatasets;
        } else {
            _.forEach(states.filterConfig?.filterOptions, (option, index: number) => {
                if (option === filterValue) {
                    dataIndex.push(index);
                    labels.push(`${chartLabels[index]} ${filterValue}`);
                }
            });
            _.forEach(initChartDatasets, (set, index) => {
                datasets[index].data = [];
                _.forEach(dataIndex, i => {
                    datasets[index].data.push(set.data[i]);
                });
            });
        }

        // https://github.com/chartjs/Chart.js/issues/8416
        states.chart.destroy();
        states = this.initChart(states, datasets, labels);
    }
}
