import { Injectable } from '@angular/core';
import { ITestSqlModalProps, ITestSqlModalStates, ITestSqlModalService } from './testSqlModal.component.d';
import { NgDataAccess } from '../../services/dataAccess.service';
import * as _ from 'lodash';

declare const $: any;
@Injectable()
export class TestSqlModalService implements ITestSqlModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(props: ITestSqlModalProps, states: ITestSqlModalStates): Promise<object> {
        states.episodeGrouping = props.episodeGrouping;
        states.metric = props.metric;
        states.type = props.type;
        states.modalTitle = props.isSaving ? 'To complete saving, select one or more Care Groupings to test' : 'Select one or more Care Groupings to test';

        const careGroupings = await this.dataAccess.genericFind({ model: 'CareGrouping' });
        let validCareGroupings:any = [];
        if (props.type === 'OtherMetric') {
            validCareGroupings = _.get(states.metric, 'validCareGroupings', []);
        } else if (props.type === 'EpisodeGrouping') {
            validCareGroupings = _.get(states.episodeGrouping, 'validCareGroupings', []);
        }
        careGroupings.forEach((careGrouping: any) => {
            _.forEach(validCareGroupings, function (validCareGrouping) {
                if (validCareGrouping === careGrouping.name) {
                    states.careGroupings.push(careGrouping);
                } else if (_.startsWith(careGrouping.name, validCareGrouping + '.')) {
                    states.careGroupings.push(careGrouping);
                }
            });
        });
        states.careGroupings = _.orderBy(states.careGroupings, 'name');

        return {};
    }

    async changeDelegate(oldProps: ITestSqlModalProps, newProps: ITestSqlModalProps, states: ITestSqlModalStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async runTestsHandler(states: ITestSqlModalStates) {
        states.runningTests = true;
        const results = await this.dataAccess.genericMethod({
            model: _.get(states, 'type', 'EpisodeGrouping'), 
            method: 'testSql', parameters: {
                episodeGrouping: states.episodeGrouping,
                metric: states.metric,
                type: states.type,
                inputCareGroupings: this.selectedCareGroupings(states)
            }
        });
        _.forEach(results, function (result) {
            states.sampleSQL = result.sql;
            const careGrouping = _.find(states.careGroupings, { name: result.careGrouping });
            if (careGrouping) {
                careGrouping.sql = result.sql;
                careGrouping.message = result.message;
            }
        });
        states.runningTests = false;
    }

    selectedCareGroupings(states: ITestSqlModalStates) {
        return _.map(_.filter(states.careGroupings, { selected: true }), 'name');
    }

    copyToClipboardHandler(sql: any) {
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(sql).select();
        document.execCommand('copy');
        $temp.remove();
    }
}
