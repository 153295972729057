import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IProductListStates } from './productList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ProductListService } from './productList.component.service';
import { StateService } from '@uirouter/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';
@Component({
    selector: 'argos-productList',
    templateUrl: './productList.component.html',
    providers: [ProductListService]
})

export class ProductListComponent extends ReactComponentBase<{}, IProductListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('tbMatSort') tbMatSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: ProductListService, private state: StateService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            productConfigs: [],
            productListTable: new MatTableDataSource([]),
            pageSize: 50,
            filterObj: {},
            defaultDisplayMatTableColumns: ['copyButton', 'editButton', 'name', 'title', 'description', 'usedByButton'],
            matTableColumns: ['copyButton', 'editButton', 'id', 'name', 'title', 'description', 'usedByButton'],
            matTableSettingName: 'productList'
        } as IProductListStates);
    }

    ngAfterViewInit() {
        this.states.productListTable.sort = this.tbMatSort;
        this.states.productListTable.paginator = this.paginator;
    }

    async ngOnInit() {
        this.states.productListTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    add() {
        this.state.go('argos.prism.product.add');
    }

    copy(product: any) {
        this.state.go('argos.prism.product.add', { id: product.id });
    }

    edit(product: any) {
        this.state.go('argos.prism.product.edit', { id: product.id });
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.productListTable.filter = JSON.stringify(this.states.filterObj);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    viewClientUsage(row: any) {
        this.svc.viewClientUsage(row, this.states);
    }
}
