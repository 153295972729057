import { Injectable } from '@angular/core';
import { IEditJoinEntityModalProps, IEditJoinEntityModalStates, IEditJoinEntityModalService } from './editJoinEntityModal.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from 'client/app/services/dataAccess.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ViewJoinEntityHistoryModalComponent } from '../viewJoinEntityHistoryModal/viewJoinEntityHistoryModal.component';
import { ArgosStoreService } from 'client/app/services/argosStore.service';

@Injectable()
export class EditJoinEntityModalService implements IEditJoinEntityModalService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private argosStore: ArgosStoreService) {
        //
    }

    async initDelegate(props: IEditJoinEntityModalProps, states: IEditJoinEntityModalStates): Promise<Object> {
        states.editMode = props.editMode;
        states.episodeGroupingEntityId = props.episodeGroupingEntityId;
        await this.activate(states);
        return states;
    }

    async changeDelegate(oldProps: IEditJoinEntityModalProps, newProps: IEditJoinEntityModalProps, states: IEditJoinEntityModalStates): Promise<Object> {
        return this.initDelegate(newProps, states);
    }

    async activate(states: IEditJoinEntityModalStates) {
        if (states.episodeGroupingEntityId) {
            states.episodeGroupingEntity = await this.dataAccess.genericMethod({
                model: 'EpisodeGroupingEntity', method: 'findById',
                parameters: {
                    id: states.episodeGroupingEntityId
                }
            });

            const episodeGroupings = await this.dataAccess.genericFind({
                model: 'EpisodeGrouping',
                filter: {
                    fields: ['groupByAttribute'],
                    where: {
                        joinEntity: states.episodeGroupingEntityId
                    }
                }
            });

            const externalMetrics = await this.dataAccess.genericFind({
                model: 'OtherMetric',
                filter: {
                    fields: ['id', 'name'],
                    where: {
                        externalJoinEntity: states.episodeGroupingEntityId
                    }
                }
            });

            states.episodeGroupingEntity.usedByGroupings = _.map(episodeGroupings, 'groupByAttribute');
            states.episodeGroupingEntity.groupingCount = _.size(states.episodeGroupingEntity.usedByGroupings);
            states.episodeGroupingEntity.usedByMetrics = _.map(externalMetrics, (em: any) => { return { id: em.id, name: em.name }; });
            states.episodeGroupingEntity.metricCount = _.size(states.episodeGroupingEntity.usedByMetrics);
            states.originalEpisodeGroupingEntity = _.cloneDeep(_.pick(states.episodeGroupingEntity, ['entity', 'relatedEntity', 'joinSql']));
        } else {
            states.episodeGroupingEntity = {};
            states.originalEpisodeGroupingEntity = {};
        }
    }

    viewHistoryHandler(states: IEditJoinEntityModalStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    episodeGroupingEntity: states.episodeGroupingEntity,
                }
            }
        };

        this.matDialog.open(ViewJoinEntityHistoryModalComponent, dialogConfig);
    }

    async saveHandler(states: IEditJoinEntityModalStates) {
        if (!states.episodeGroupingEntity) {
            return;
        }
        if (!_.isEmpty(states.episodeGroupingEntity.relatedEntity)) {
            if (_.isString(states.episodeGroupingEntity.relatedEntity)) {
                states.episodeGroupingEntity.relatedEntity = '{' + states.episodeGroupingEntity.relatedEntity + '}';
            } else if (_.isArray(states.episodeGroupingEntity.relatedEntity)) {
                states.episodeGroupingEntity.relatedEntity = '{' + _.join(states.episodeGroupingEntity.relatedEntity, ',') + '}';
            }
        }
        else {
            // If the related entity is empty, set it to null.  If user clears the field, it will be an empty string 
            // and lead to an error when saving if we don't do this.
            states.episodeGroupingEntity.relatedEntity = null;
        }
        states.episodeGroupingEntity = await this.dataAccess.genericUpsert({
            model: 'EpisodeGroupingEntity', data: states.episodeGroupingEntity
        });

        await this.getEpisodeGroupingEntity(states);
        await this.saveEpisodeGroupingEntityHistory(states);
    }

    async getEpisodeGroupingEntity(states: IEditJoinEntityModalStates) {
        states.episodeGroupingEntity = await this.dataAccess.genericMethod({
            model: 'EpisodeGroupingEntity', method: 'findById',
            parameters: {
                id: states.episodeGroupingEntity.entity,
                filter: {
                    include: ['episodeGroupingEntityHistories']
                }
            }
        });
    }

    async saveEpisodeGroupingEntityHistory(states: IEditJoinEntityModalStates) {

        // Only save a new version if something actually changed.
        if (_.isEqual(states.originalEpisodeGroupingEntity, states.episodeGroupingEntity)) {
            return Promise.resolve();
        }

        let version = 0;
        const username = this.argosStore.getItem('username');
        if (states.episodeGroupingEntity && states.episodeGroupingEntity.episodeGroupingEntityHistories && states.episodeGroupingEntity.episodeGroupingEntityHistories.length > 0) {
            version = _.max(_.map(states.episodeGroupingEntity.episodeGroupingEntityHistories, 'version')) || 0;
        }
        version++;

        delete states.episodeGroupingEntity.episodeGroupingEntityHistories;
        return this.dataAccess.genericUpsert({
            model: 'EpisodeGroupingEntityHistory', data: {
                episodeGroupingEntityId: states.episodeGroupingEntity.entity,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: states.episodeGroupingEntity
            }
        });
    }
}
