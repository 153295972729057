import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IUserListStates } from './userRoleList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { UserRoleListService } from './userRoleList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LoginService } from '../../../services/loginService.service';
import { StateService, UIRouter } from '@uirouter/core';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import swal from 'sweetalert2';
import * as _ from 'lodash';
@Component({
    selector: 'argos-userRoleList',
    templateUrl: './userRoleList.component.html',
    providers: [UserRoleListService]
})

export class UserRoleListComponent extends ReactComponentBase<{}, IUserListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: UserRoleListService, private loginServiceHandler: LoginService, private state: StateService, 
                private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            editingMode: false,
            userRoles: [],
            userRoleTable: new MatTableDataSource([]),
            pageSize: 50,
            filterObj: {},
            defaultDisplayMatTableColumns: ['editButton', 'roleName', 'roleTitle', 'description', 'seeded', 'numberOfUsers', 'disabled'],
            matTableColumns: ['editButton', 'roleName', 'roleTitle', 'description', 'seeded', 'numberOfUsers', 'disabled'],
            environmentMatTableSettingName: 'userRoleList',
        } as unknown as IUserListStates);
    }

    ngAfterViewInit() {
        this.states.userRoleTable.sort = this.matTbSort;
        this.states.userRoleTable.paginator = this.paginator;
    }

    async ngOnInit() {

        this.states.userRoleTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.environmentMatTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.userRoleTable.filter = JSON.stringify(this.states.filterObj);
    }

    editRow(row: any, edit?: boolean) {
        this.states.editingMode = !!edit;
        row.editMode = !!edit;
    }

    async syncRolePermissions() {
        swal({
            title: 'Are you sure?',
            text: 'This will pull the latest Required Api Access list into each role',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result: any) => {
            if (result.value) {
                this.svc.syncRolePermissions(this.states);
            }
        });
    }

    async saveRow(row: any) {
        await this.svc.saveRow(row, this.states);
        this.cdr.detectChanges();
    }

    //pagination functions
    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.environmentMatTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }
}
