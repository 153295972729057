import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IStageSourceTableColumnListStates } from './stageSourceTableColumnList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { StageSourceTableColumnListService } from './stageSourceTableColumnList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-stageSourceTableColumnList',
    templateUrl: './stageSourceTableColumnList.component.html',
    providers: [StageSourceTableColumnListService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('testListSection', [
            state('collapsed', style({height: 'calc(50vh - 115px)'})), 
            state('expanded', style({height: 'calc(100vh - 155px)'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('testListTable', [
            state('collapsed', style({height: 'calc(50vh - 243px)'})), 
            state('expanded', style({height: 'calc(100vh - 243px)'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('triggerDelete', [
            state('deletable', style({color: 'red'})), 
            state('current', style({color: '#4B88D4'})),
            transition('deletable <=> current', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})

export class StageSourceTableColumnListComponent extends ReactComponentBase<{}, IStageSourceTableColumnListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('matTbSortHeader') matTbSortHeader = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('paginatorHeader') paginatorHeader: MatPaginator;
    _: any = _;

    constructor(private svc: StageSourceTableColumnListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        
        super({
            stageSourceTable: [],
            filterObj: {},
            filterObjHeader: {},
            pageSize: 50,
            pageSizeHeader: 50,
            defaultDisplayMatTableColumns: ['columnName', 'dataType', 'testCount', 'lastReported', 'addButton'],
            matTableColumns: ['columnName', 'dataType', 'testCount', 'lastReported', 'addButton'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'stageSourceTableColumnList',
            matTableSettingNameHeader: 'tableLevelTests',
            expandedElements: [],
            headerTestColumns: ['name', 'type', 'level', 'sourceName', 'editButton'],
            defaultHeaderTestColumns: ['name', 'type', 'level', 'sourceName', 'editButton'],
            headerTable: new MatTableDataSource([]),
            filterSourceId: 'default'
        } as unknown as IStageSourceTableColumnListStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
        this.states.headerTable.paginator = this.paginatorHeader;
        this.states.headerTable.sort = this.matTbSortHeader;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        this.states.pageSizeHeader = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingNameHeader);
        this.states.headerTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    exportToExcelHeader() {
        this.svc.exportToExcelHeader(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }
    openMatTableSettingsHeader() {
        this.svc.openMatTableSettingsHeader(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    isHeaderColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingNameHeader, this.states.defaultHeaderTestColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    filterChangeHeader(columnName: string, filterValue: string) {
        this.states.filterObjHeader[columnName] = filterValue.trim().toLowerCase();
        this.states.headerTable.filter = JSON.stringify(this.states.filterObjHeader);
    }

    filterList(filterId?: number, filterValue = '', level = '', currentLevel = '', agnosticSchema = true) {
        if (currentLevel === 'stage' && agnosticSchema) {
            level = 'table';
        } else if (currentLevel === 'stage') {
            level = 'source';
        }
        this.svc.filterList(this.states, level, filterValue, filterId, currentLevel, agnosticSchema);
    }

    viewTests(teradromeTableId: any, tableName: any) {
        this.svc.viewTests(this.states, teradromeTableId, tableName);
    }

    launchTestModal(test: object | undefined, columnId: number | undefined) {
        this.svc.launchTestModal(test, columnId, this.states);
    }

    pushPopExpandedElements(rowId: any) {
        if (this.states.expandedElements.includes(rowId)) {
            this.states.expandedElements.splice(this.states.expandedElements.indexOf(rowId), 1);
        } else {
            this.states.expandedElements.push(rowId);
        }
    }

    filterDataSource() {
        this.svc.viewTests(this.states, this.states.teradromeTableId, this.states.tableName, this.states.filterSourceId);
    }

    deleteTable(tableId: any) {
        this.svc.deleteTable(this.states, tableId);
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
    }

    deleteColumn(columnId: number) {
        this.svc.deleteColumn(this.states, columnId);
    }
}
