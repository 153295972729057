import { Injectable } from '@angular/core';
import { IEditEnvironmentDatasourceFilterModalProps, IEditEnvironmentDatasourceFilterModalStates, IEditEnvironmentDatasourceFilterModalService } from './editEnvironmentDatasourceFilterModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class EditEnvironmentDatasourceFilterModalService implements IEditEnvironmentDatasourceFilterModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }

    async initDelegate(props: IEditEnvironmentDatasourceFilterModalProps, states: IEditEnvironmentDatasourceFilterModalStates): Promise<object> {
        await this.activate(props, states);
        return {};
    }

    async changeDelegate(oldProps: IEditEnvironmentDatasourceFilterModalProps, newProps: IEditEnvironmentDatasourceFilterModalProps, states: IEditEnvironmentDatasourceFilterModalStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async activate(props: IEditEnvironmentDatasourceFilterModalProps, states: IEditEnvironmentDatasourceFilterModalStates) {
        if (props.readOnly) {
            states.isReadOnly = props.readOnly;
        }
        states.programEpisodes = await this.dataAccess.genericFind({
            model: 'ProgramEpisode',
            filter: {
                fields: ['id', 'name', 'description', 'programModelId'],
                order: 'name'
            }
        });

        states.careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { order: 'title' }
        });

        states.observations = await this.dataAccess.genericFind({
            model: 'Observation',
            filter: {
                where: { status: 'Active' },
                fields: ['id', 'name', 'title', 'description', 'observationGroupId', 'observationTypeId', 'productBundles'],
                order: 'observationGroupId, name'
            }
        });

        states.productBundles = await this.dataAccess.genericFind({
            model: 'ProductBundle',
            filter: {
                fields: ['id', 'shortName'],
                order: 'shortName'
            }
        });

        if (props.sourceObj) {

            states.dataSourceObj = props.sourceObj;

            states.selectedProgramEpisodes = states.dataSourceObj.filterParameters.programEpisodes;
            states.selectedObservations = states.dataSourceObj.filterParameters.observations || [];
            states.selectedProductBundles = states.dataSourceObj.filterParameters.productBundles;
            states.whereClause = states.dataSourceObj.filterParameters.whereClause;
            states.joinClause = states.dataSourceObj.filterParameters.joinClause;

            states.dataFilterFields = states.dataSourceObj.queryFilters;

            if (states.dataSourceObj.filterParameters.observationSelectionType) {
                states.selectedObservationsType = states.dataSourceObj.filterParameters.observationSelectionType;
            }

            states.selectedDataSourceName.useCustomDataSource = true;

        } else {
            states.dataSourceObj = {
                id: null,
                datasource: 'Aeon',
                title: '',
                database: null,
                filterParameters: [],
                queryFilters: [],
                careGroupingId: null
            };

            for (let i = 0; states.observations.length > i; i++) {
                // && states.selectedObservationsType === 'include'
                // select all observations that are not associated with a product bundle if include is selected
                // if(!states.observations[i].productBundles || (states.observations[i].productBundles && states.observations[i].productBundles.length == 0)) {
                //    states.selectedObservations.push({ id: states.observations[i].id });
                // }
                // exclude bundles for now
                if (states.observations[i].productBundles && states.observations[i].productBundles.length > 0) {
                    states.selectedObservations.push({ id: states.observations[i].id });
                }
            }

        }

        this.fieldChanged(states);
    }


    careGroupingChanged(states: IEditEnvironmentDatasourceFilterModalStates) {
        const careGrouping = _.find(states.careGroupings, { id: states.dataSourceObj.careGroupingId });
        states.careGrouping = careGrouping;
        states.dataSourceObj.database = _.get(careGrouping, 'settings.baseTable');
        this.fieldChanged(states);
    }

    fieldChanged(states: IEditEnvironmentDatasourceFilterModalStates) {

        if (states.dataSourceObj.database && states.dataSourceObj.database.length > 0 && states.dataSourceObj.careGroupingId && !states.isInvalidCsvString && states.dataSourceObj.database.indexOf(' ') === -1) {
            states.saveBtnEnabled = true;
        } else {
            states.saveBtnEnabled = false;
        }
    }

    validateQueryFilterValue(states: IEditEnvironmentDatasourceFilterModalStates) {
        if (states.queryFilter) {
            const pattern = /^[A-Za-z0-9]+(?:, ?[A-Za-z0-9]+)*$/;
            states.isInvalidCsvString = !(!!pattern.exec(states.queryFilter));
        }
    }

    queryFilterChange(states: IEditEnvironmentDatasourceFilterModalStates) {

        this.validateQueryFilterValue(states);
        this.fieldChanged(states);

        for (let i = 0; states.dataFilterFields.length > i; i++) {
            if (states.dataFilterFields[i].field === states.selectedDataFilter) {
                states.dataFilterFields[i].condition = states.queryFilter;
            }
        }
    }

    selectedDataFilterChanged(states: IEditEnvironmentDatasourceFilterModalStates) {

        const selectedFilter = _.filter(states.dataFilterFields, function (q) {
            if (q.field === states.selectedDataFilter) {
                return q;
            }
        });

        states.queryFilter = selectedFilter[0].condition;

        this.validateQueryFilterValue(states);
    }

    save(states: IEditEnvironmentDatasourceFilterModalStates) {

        const selectedFilter = {
            programEpisodes: states.selectedProgramEpisodes,
            whereClause: states.whereClause,
            joinClause: states.joinClause
            // productBundles: states.selectedProductBundles,
            // observations: states.selectedObservations,
            // observationSelectionType: states.selectedObservationsType
        };

        states.dataSourceObj.queryFilters = JSON.parse(JSON.stringify(states.dataFilterFields));
        states.dataSourceObj.filterParameters = selectedFilter;

        if (!states.dataSourceObj.id) {
            states.dataSourceObj.id = Math.floor((new Date()).getTime() / 1000);  // we need something to identify data sources to act with them in the UI
        }
    }
}
