
import { TransitionService } from '@uirouter/core';
import { AuthService } from './services/auth.service';
import { UserRoleService } from './services/userRole.service';
import * as _ from 'lodash';
import { CONST } from './constants/globals';

/**
    * This file contains a Transition Hook which protects a route that requires authentication.
    * This file contains two transition hooks.
    * - requiresAuthHook: protects a route that requires authentication.
    * - requiresPagePermissionHook: protects a route that requires permission to the page.
    *
    * requiresAuthHook redirects to /login when both:
    * - The user is not authenticated
    * - The user is navigating to a state that requires authentication
    *
    * requiresPagePermissionHook:
    * The contractor user shall only be able to access two url:
    * https://argos.clarifyhealth.com/argos/payerRatesMeta & https://argos.clarifyhealth.com/argos/payerRatesMeta/edit
    * If a logged in user does not have access to via their User Role, then we should navigate them to the login screen.
    *
    * This hook has lower priority than requiresAuthHook.
    */
export function requiresAuthHook(transitionService: TransitionService) {
    // Matches if the destination state's data property has a truthy 'requiresAuth' property
    const requiresAuthCriteria = {
        to: (state: any) => {
            return state.data && state.data.requiresAuth;
        }
    };
    // Function that returns a redirect for the current transition to the login state
    // if the user is not currently authenticated (according to the AuthService)

    const redirectToLogin = (transition: any) => {
        const to = transition.$to();
        const authService: AuthService = transition.injector().get(AuthService);
        const $state = transition.router.stateService;
        if (!authService.isAuthenticated()) {
            return $state.target('login', {
                saveState: to.name ? to.name : undefined,
                saveParams: transition.router.urlService.url()
            }, { location: true });
        }
    };
    // Register the "requires auth" hook with the TransitionsService
    transitionService.onBefore(requiresAuthCriteria, redirectToLogin, { priority: 10 });
}

export function requiresPagePermissionHook(transitionService: TransitionService) {

    // Matches if the target state's data property has a truthy 'requiresAuth' property and either 'pageTitle' or 'parentTitle'.
    const requiresPagePermissionCriteria = {
        to: (state: any) => {
            return state.data && state.data.requiresAuth;
        }
    };
    // Function that returns a redirect for the current transition to the error state
    // If the user does not currently have permission to the page.
    const redirectToLogin = async (transition: any) => {
        const to = transition.$to();
        const selfName = _.get(to, 'self.name');
        const parentName = _.get(to, 'parent.name');

        const userRoleSvc: UserRoleService = transition.injector().get(UserRoleService);
        const userRole: string = await userRoleSvc.getUserRole();
        const isValidPageByUserRole = await userRoleSvc.isValidPageByUserRole(userRole, selfName, parentName);
        const userRoleConfig = await userRoleSvc.getUserRoleConfigByRoleName(userRole);

        const $state = transition.router.stateService;
        if (!isValidPageByUserRole) {
            if (CONST.USER_ROLE_INACTIVE === userRole) {
                return $state.target('argos.inactive');
            } else if (!userRoleConfig?.redirectTo && _.find(userRoleConfig?.stateNames, item => (item === 'argos.dashboard'))) {
                return $state.target('argos.dashboard');
            } else {
                return $state.target('argos.404');
            }
        }
    };
    // Register the "page permission auth" hook with the TransitionsService
    // Hooks of the same type (onBefore, onStart, etc) are invoked in priority order.  A hook with a higher priority
    // is invoked before a hook with a lower priority.
    transitionService.onStart(requiresPagePermissionCriteria, redirectToLogin, { priority: 9 });
}
