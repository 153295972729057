import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IPceMetaDataListStates, IPceMetaDataListService } from './pceMetaDataList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import swal from 'sweetalert2';

@Injectable()
export class PceMetaDataListService implements IPceMetaDataListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, 
        private cdr: ChangeDetectorRef, private state: StateService) {
        //
    }
    
    async initDelegate(states: IPceMetaDataListStates): Promise<Object> {
        await this.activate(states);
        return {};
    }
    
    async activate(states: IPceMetaDataListStates) {
        let pceEpMetaData = await this.dataAccess.genericFind({
            model: 'PceObservation',
            filter: {
                include: 'teradromeColumn'
            }
        });

        states.table.data = _.orderBy(pceEpMetaData.map((pce: any) => {
            pce.selectionColumn = pce?.teradromeColumn?.columnName;
            pce.creationDate = (pce.creationDate) ? new Date(pce.creationDate).toISOString().slice(0, 10) : undefined;
            return pce;
        }), ['columnName']);
    }

    setTablePageSize(states: IPceMetaDataListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.pceMeta.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IPceMetaDataListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IPceMetaDataListStates) {
        const data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

}
