import { Injectable } from '@angular/core';
import { IStatusDetailModalProps, IStatusDetailModalStates, IStatusDetailModalService } from './statusDetailModal.component.d';

@Injectable()
export class StatusDetailModalService implements IStatusDetailModalService {
    initDelegate(props: IStatusDetailModalProps, states: IStatusDetailModalStates): object {
        states.environment = props.environment;
        return {};
    }

    changeDelegate(oldProps: IStatusDetailModalProps, newProps: IStatusDetailModalProps, states: IStatusDetailModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }
}
