import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IEcsDashboardStates, IEcsDashboardService } from './ecsDashboard.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { ArgosStoreService } from '../../../services/argosStore.service';
import swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
// charts
import { ChartService } from '../../../services/chartService.service';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

declare const $: any;
@Injectable()
export class EcsDashboardService implements IEcsDashboardService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess, private chartSvc: ChartService,
                private matDialog: MatDialog, private exportToExcelSvc: ExportToExcelService, private cdr: ChangeDetectorRef) {
        //
    }

    createUpgradeHorizontalBarChart(states: any) {
        const buildTags = _.uniq(_.map(states.releaseParameters.averageUpgradeTimes, function (e: any) { return e.id; }));
        const dataByAvgUpgrade = _.map(states.releaseParameters.averageUpgradeTimes, function (e: any) { return e.averageUpgradeTimeInMinutes; });

        const labels: string[] = [];
        const datasets: number[] = [];
        for (let i = 1; dataByAvgUpgrade.length > i; i++) {
            const value = dataByAvgUpgrade[i];
            if (value && value > 0) {
                labels.push(buildTags[i]);
                datasets.push(value);
            }
        }

        const data = {
            labels,
            datasets: [{
                label: '',
                data: datasets,
                borderColor: this.chartSvc.getRandomColor(),
                backgroundColor: this.chartSvc.getRandomColor(),
            }]
        };

        states.chartObj.upgradeHorizontalBarConfig = {
            type: 'bar',
            data,
            options: {
                indexAxis: 'y',
                // Elements options apply to all of the options unless overridden in a dataset
                // In this case, we are setting the border of each horizontal bar to be 2px wide
                elements: {
                    bar: {
                        borderWidth: 2,
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Build Average Upgrade By Mins'
                    }
                }
            },
        };

        if (states.chartObj.upgradeHorizontalBarChart) {
            states.chartObj.upgradeHorizontalBarChart.destroy();
        }

        states.chartObj.upgradeHorizontalBarChart = new Chart(states.chartObj.upgradeHorizontalBarCtx, states.chartObj.upgradeHorizontalBarConfig);

    }

    createServiceMetricsChart(states: any) {

        const codeChangeEvents = states.releaseParameters.eventLogs;

        const deployDates = _.uniq(_.sortBy(_.map(codeChangeEvents, (e: any) => { return e.dateOnly; }), 'dateOnly'));
        const buildTags = _.uniq(_.map(codeChangeEvents, (e: any) => { return e.toBuildTag; }));
        const dataByDate: any[] = [];
        const seriesList = [];

        // let deployCounts = _.map(_.countBy(codeChangeEvents, "dateTime"), (val, key) => ({ date: key, total: val }))

        // Date is what drives our grouping
        const groupedByDate = _.toArray(_.sortBy(_.groupBy(codeChangeEvents, 'dateOnly'), 'dateTime'));

        buildTags.forEach(function (el) {
            const emptyValues: any[] = [];
            deployDates.forEach(function (bt) {
                emptyValues.push(0);
            });

            dataByDate.push(emptyValues);
        });

        let index = 0;
        _.each(groupedByDate, function (eventDay) {
            const d1 = _.countBy(eventDay, function (ed) { return ed.toBuildTag; });
            const deployDate = _.uniq(_.map(eventDay, function (e) { return e.dateOnly; }));
            const datePosition = deployDates.indexOf(deployDate[0]);

            _.each(d1, function (value, key) {
                const buildPosition = buildTags.indexOf(key);
                dataByDate[buildPosition][datePosition] = value;
                index++;
                // console.log(key, value);
            });
        });

        const chartData = [];
        for (let i = 1; buildTags.length > i; i++) {
            const dataObj = {
                label: buildTags[i],
                data: dataByDate[i],
                backgroundColor: this.chartSvc.getRandomColor()
            };

            chartData.push(dataObj);
        }

        const DATA_COUNT = 7;
        const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };

        const labels = ['January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July'];
        const data = {
            labels: deployDates,
            datasets: [
                {
                    label: 'Dataset 1',
                    data: [1, 2, 3, 4, 5],
                    backgroundColor: this.chartSvc.getRandomColor()
                },
                {
                    label: 'Dataset 2',
                    data: [11, 12, 13, 14, 15],
                    backgroundColor: this.chartSvc.getRandomColor()
                },
                {
                    label: 'Dataset 3',
                    data: [21, 22, 23, 24, 25],
                    backgroundColor: this.chartSvc.getRandomColor()
                },
            ]
        };

        data.datasets = chartData;

        states.chartObj.releaseStackedBarChartSettings = {
            type: 'bar',
            data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart - Stacked'
                    },
                    tooltip: {
                        mode: 'index',
                        callbacks: {
                            label (tooltipItem: any, data: any) {
                                const buildTag = tooltipItem.dataset.label;
                                // only show tool tips that have deploys on that date i.e not 0
                                if (tooltipItem.parsed.y > 0) {
                                    return buildTag + ':' + tooltipItem.parsed.y;
                                } else {
                                    return null;
                                }
                            }
                        }
                    }
                },
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                },
                onClick: (event: any) => {
                    this.releaseChartClick(event, states);

                    /*
                    const points = states.chartObj.releaseStackedBarChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

                    if (points.length) {
                        const firstPoint = points[0];
                        const label = states.chartObj.releaseStackedBarChart.data.labels[firstPoint.index];
                        const value = states.chartObj.releaseStackedBarChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
                        let x = 1;z
                    }
                    */
                    // onBarClicked(model.datasetLabel, model.label);
                }
            }
        };

        if (states.chartObj.releaseStackedBarChart) {
            states.chartObj.releaseStackedBarChart.destroy();
        }

        states.chartObj.releaseStackedBarChart = new Chart(states.chartObj.releaseStackedBarCtx, states.chartObj.releaseStackedBarChartSettings);
    }

    async initDelegate(states: IEcsDashboardStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IEcsDashboardStates) {
        states.environments = await this.dataAccess.genericFind({
            model: 'Environment',
            filter: {
                where:
                {
                    and: [{ hostingProvider: 'ecs' },
                        { applicationType: 'prism' }
                    ]
                },
                order: 'dnsCname'
            }
        });
        states.envManagement = await this.dataAccess.genericMethod({
            model: 'EnvironmentManagement', method: 'findOne', parameters: {
            }
        });

        const data = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getEnvironments', parameters: {
            }
        });

        states.atlasEnvUrls = _.orderBy(_.compact(_.map(data, function (environment: any) {
            if (environment.dnsCname) {
                return {
                    id: environment.id,
                    url: ('https://' + environment.dnsCname + '.clarifyhealth.' + environment.domainSuffix).toLowerCase(),
                    clientId: environment.clientId
                };
            }
            return undefined;
        })), 'url');

        states.environments.forEach(function (e: any) {
            e.url = e.dnsCname + '.clarifyhealth.' + e.domainSuffix;
        });
        await this.releaseQueryRequests(states.releaseParameters.fromDate, states.releaseParameters.toDate, states);
    }

    initAuth0ConnectionTable(connections: any[], states: IEcsDashboardStates) {
        states.selectedClient = {
            callbacks: [],
            web_origins: []
        };
        states.auth0Object.connections = connections;
        _.map(states.auth0Object.connections, (conn: any) => {
            conn.enabledClientInfo = [];

            _.forEach(conn.enabled_clients, cliId => {
                const clientObj = _.find(states.auth0Object.clients, { client_id: cliId });
                if (clientObj) {
                    conn.enabledClientInfo.push(clientObj);
                }
            });

            // conn.enabledClientsCount = conn.enabled_clients.length;
            conn.enabledClientInfo = _.orderBy(conn.enabledClientInfo, ['name'], ['asc']);
            conn.enabledClientsCount = _.map(conn.enabledClientInfo, 'name').join(', ');
            conn.spaCount = _.filter(conn.enabledClientInfo, { app_type: 'spa' });

            return conn;
        });
        states.connectionMatTable.data = states.auth0Object.connections;
    }

    async removeAuth0AppConnections(connectionObject: any, cdr: ChangeDetectorRef, states: IEcsDashboardStates) {
        swal({
            title: 'Confirm action',
            text: 'This will remove the ability to login to the selected application by removing all applications from a connection',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const reqResult = await this.dataAccess.genericMethod({
                    model: 'Auth0Client', method: 'disableConnectionApps', parameters: {
                        tenantName: states.auth0Object.selectedDomain,
                        appName: states.auth0Object.selectedClient.name,
                        appId: states.auth0Object.selectedClient.client_id,
                        enabledClientIds: connectionObject.enabled_clients,
                        connectionName: connectionObject.name,
                        connectionId: connectionObject.id,
                        requestedBy: this.argosStore.getItem('username')
                    }
                });

                swal({
                    title: 'Request ' + reqResult.isSuccessful ? 'succeeded' : 'failed',
                    text: (reqResult.error ? reqResult.error + '. ' : '') + ' Reloading Auth0 Management Tab',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                }).then(async (isConfirm: any) => {
                    //
                });

                this.initAuth0ManagementTab(states);
                cdr.detectChanges();
            }
        });
    }

    async initAuth0ManagementTab(states: IEcsDashboardStates) {
        states.auth0Object.clients = [];
        states.auth0Object.cloneableClients = [];

        states.auth0Object.selectedClient = {
            callbacks: [],
            web_origins: []
        };

        const [result, connectionsInfo] = await Promise.all([
            this.dataAccess.genericMethod({
                model: 'Auth0Client', method: 'getApplications', parameters: {
                    tenantName: states.auth0Object.selectedDomain,
                    requestedBy: this.argosStore.getItem('username')
                }
            }),
            // load all connections by selectedDomain
            this.dataAccess.genericMethod({
                model: 'Auth0Client', method: 'getConnections', parameters: {
                    tenantName: states.auth0Object.selectedDomain
                }
            })
        ]);

        if (result.clients) {
            states.auth0Object.clients = result.clients;
            this.initAuth0ClientTable(states);
            this.initAuth0ConnectionTable(connectionsInfo.connections, states);
        }

        states.auth0Object.newAuth0DnsCname = '';
        states.auth0Object.newAuth0domainSuffixSelected = 'com';
        states.auth0Object.newAuth0ApplicationName = '';
    }

    auth0ExportValidWebOrigins(states: IEcsDashboardStates) {
        const list = _.map(_.filter(states.auth0Object.webOriginValidationResults, function (e) {
            if (e.isValid) {
                return e;
            }
        }), function (r) { return r.url; });

        this.copyToClipboard(list.join(','));
    }

    auth0ExportValidCallbacks(states: IEcsDashboardStates) {
        const list = _.map(_.filter(states.auth0Object.webOriginValidationResults, function (e) {
            if (e.isValid) {
                return e;
            }
        }), function (r) { return r.url + '/authorize'; });

        this.copyToClipboard(list.join(','));
    }

    async validateAuth0WebOrigin(env: any) {
        const version = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'getAppVersion',
            parameters: {
                id: env.id
            }
        });
        if (version && version.localVersion) {
            env.isValid = true;
            delete env.reason;
        }
    }

    copyToClipboard(value: any) {
        // eslint-disable-next-line
        const $temp = $('<textarea>');
        // eslint-disable-next-line
        $('body').append($temp);
        $temp.val(value).select();
        // eslint-disable-next-line
        document.execCommand('copy');
        $temp.remove();
    }

    auth0AppNameExists(states: IEcsDashboardStates) {
        return _.find(states.auth0Object.clients, function (c) { return c.name.toLowerCase() === states.auth0Object.newAuth0ApplicationName?.toLowerCase(); });
    }

    isDnsValid(states: IEcsDashboardStates) {
        let result = true;
        const regexp = /^[a-zA-Z0-9-]+$/;

        if (states.auth0Object.newAuth0DnsCname?.trim().length > 0 && states.auth0Object.newAuth0DnsCname?.search(regexp) === -1) {
            result = false;
        }

        return result;
    }

    initAuth0ClientTable(states: IEcsDashboardStates) {

        states.auth0Object.clients = _.orderBy(states.auth0Object.clients, ['name']);
        states.auth0Object.cloneableClients = _.filter(states.auth0Object.clients, function (c) {
            if (c.client_metadata && c.client_metadata.isCloneable) {
                return c;
            }
        });
    }

    addEc2Option(key: any, value: any, states: IEcsDashboardStates) {

        if (key in states.envManagement.ecsEc2Options) {
            return;
        } else {
            states.envManagement.ecsEc2Options[key] = value;
            states.newEc2 = {};
        }
    }

    addkeyValueObj(key: any, value: any, keyValueList: any[], states: IEcsDashboardStates, cdr: ChangeDetectorRef) {

        if (!_.find(keyValueList, { key })) {
            keyValueList.push({ key, value });
            states.newEcsTag = {
                key: '',
                value: ''
            };
            cdr.detectChanges();
        }
    }

    createAuth0Application(states: IEcsDashboardStates) {
        swal({
            title: 'Are you sure?',
            text: 'This will create a new application ' + states.auth0Object.newAuth0ApplicationName,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm) => {
            if (isConfirm.value) {
                const result = await this.dataAccess.genericMethod({
                    model: 'Auth0Client', method: 'createApplication', parameters: {
                        tenantName: states.auth0Object.selectedDomain,
                        appName: states.auth0Object.newAuth0ApplicationName,
                        dnsCname: states.auth0Object.newAuth0DnsCname,
                        domainSuffix: states.auth0Object.newAuth0domainSuffixSelected,
                        clientIdTemplate: states.auth0Object.newAuth0CloneFromApplicationSelected.client_id,
                        requestedBy: this.argosStore.getItem('username')
                    }
                });
                if (result.isSuccessful === true) {
                    const auth0RecordCreated = await this.addAuth0Object(result.clientId, result.connectionName, states);
                    await this.initAuth0ManagementTab(states);
                    swal({
                        title: auth0RecordCreated ? 'Application created' : 'Application created with errors',
                        text: auth0RecordCreated ? '' : 'Failed to create AWS Secret or Argos Auth0 record',
                        type: 'warning',
                        confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                    });
                } else {
                    const message = result.applicationCreated ? 'connection. Application was created.' : 'application and connection.';
                    swal({
                        title: 'Failed Creation',
                        text: message,
                        type: 'warning',
                        confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                    });
                }
            }
        });
    }

    async addAuth0Object(clientId: any, connectionName: string, states: IEcsDashboardStates) {

        const newAuth0 = {
            clientId,
            connectionName,
            connectionSecret: '',
            tenantName: states.auth0Object.selectedDomain
        };
        const auth0AWS = await this.dataAccess.genericMethod({
            model: 'Environment', method: 'createAuth0Secret', parameters: newAuth0
        });
        if (auth0AWS.isSuccessful === true) {
            await this.dataAccess.genericUpsert({
                model: 'Auth0Client',
                data: {
                    auth0ClientId: '',
                    auth0Connection: connectionName,
                    auth0Secret: '',
                    auth0Tenant: states.auth0Object.selectedDomain
                }
            });
        }

        return auth0AWS.isSuccessful;
    }

    createAuth0Login(states: IEcsDashboardStates) {
        swal({
            title: 'Are you sure?',
            text: 'Your user will be created under the client ' + states.auth0Object.selectedClient.name + '.\nYou will need to reset your password after',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (!isConfirm.dismiss) {
                const result = await this.dataAccess.genericMethod({
                    model: 'Auth0Client', method: 'createUser', parameters: {
                        tenantName: states.auth0Object.selectedDomain,
                        clientName: states.auth0Object.selectedClient.name,
                        clientAppId: states.auth0Object.selectedClient.client_id,
                        requestedBy: this.argosStore.getItem('username')
                    }
                });
                if (result.isSuccessful === true) {
                    swal({
                        title: 'User Created',
                        text: 'Please reset your password through the environment login page',
                        type: 'warning',
                        confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                    });
                } else {
                    swal({
                        title: 'Failed User Creation',
                        text: result.error,
                        type: 'warning',
                        confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok'
                    });
                }
            }
        });
    }

    async auth0DomainSelected(states: IEcsDashboardStates) {
        states.selectedConnection = null;
        states.selectedClient = null;
        states.auth0Object.newAuth0CloneFromApplicationSelected = null;

        await this.initAuth0ManagementTab(states);
    }

    addKeyValue(objArray: any, value: any, states: IEcsDashboardStates) {

        if (objArray.indexOf(value) >= 0) {
            return;
        } else {
            objArray.push(value);
            states.newKeyValue = '';
            states.newEcsAppEditEnv = '';
            states.newPapertrailExclusionKeyValue = '';
            this.cdr.detectChanges();
        }

    }

    async releaseQueryRequests(fromDate: any, toDate: any, states: IEcsDashboardStates) {
        states.releaseParameters.eventLogs = await this.dataAccess.genericFind({
            model: 'EnvironmentActivityLog',
            filter: {
                where:
                {
                    and: [{ createdAt: { between: [fromDate, toDate] } },
                        { environmentAction: 'code change' },
                        { message: { like: 'code change web service%' } },
                        { clusterName: { nlike: 'argos-%' } }]
                },
                order: 'id desc'
            }
        });
        states.releaseParameters.upgradeEvents = await this.dataAccess.genericFind({
            model: 'EnvironmentActivityLog',
            filter: {
                where:
                {
                    and: [{ createdAt: { between: [fromDate, toDate] } },
                        { environmentAction: 'code change upgrade run time' }]
                },
                order: 'id desc'
            }
        });
        states.releaseParameters.eventLogs.forEach((event: any) => {

            const d = new Date(event.createdAt);
            const month = d.toLocaleString('en-us', { month: 'long' });
            const mins = ('0' + d.getMinutes()).slice(-2);
            const envObj = _.filter(states.environments, { hostingAppName: event.clusterName });
            const upgradeEvents = _.filter(states.releaseParameters.upgradeEvents, function (e) { return (e.id > event.id && e.clusterName === event.clusterName); });

            event.dateFullName = month + ' ' + d.getDate() + ', ' + d.getFullYear() + ' at ' + d.getHours() + ':' + mins;
            event.detailMessage = this.propsAsString(event.eventDetail);
            event.dateTime = d; // .toDateString();
            event.dateOnly = d.toDateString();

            // set the environment
            if (envObj.length > 0) {
                event.environment = envObj[0];
                event.url = event.environment.dnsCname + '.clarifyhealth.' + event.environment.domainSuffix;
            }
            // set the upgrade event
            if (upgradeEvents.length > 0) {
                event.upgradeEvent = upgradeEvents[0];
            }
        });

        states.releaseParameters.eventLogs = _.sortBy(_.map(states.releaseParameters.eventLogs, function (oe: any) {
            oe.toBuildTag = oe.eventDetail.toBuildTag;
            oe.fromBuildTag = oe.eventDetail.fromBuildTag;
            return oe;
        }), 'dateTime');

        this.calculateReleaseInfo(states.releaseParameters.eventLogs, states);
        // this.initReleaseDataChart(states.releaseParameters.eventLogs, states);

    }

    propsAsString(obj: any) {
        return Object.keys(obj).map(function (k) { return k + ':' + obj[k]; }).join('\n');
    }

    getRandomColor(number: number) {
        const letters = '0123456789ABCDEF';
        const result = [];

        for (let k = 0; k < number; k++) {
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            result.push(color);
        }
        return result;
    }

    async intiUpgradeRunTimeSingleEnvChartSettings(selectedClusterName: any, selectedRange: any, states: IEcsDashboardStates) {

        const today = new Date();
        let fromDate: any = new Date('2019/01/01');

        if (selectedRange === '30 day') {
            fromDate = today.setDate(today.getDate() - 30);
        } else if (selectedRange === '90 day') {
            fromDate = today.setDate(today.getDate() - 90);
        }
        const envUpgradeEvents = await this.dataAccess.genericFind({
            model: 'EnvironmentActivityLog',
            filter: {
                where:
                {
                    and: [{ createdAt: { gt: fromDate } },
                        { clusterName: selectedClusterName },
                        { environmentAction: 'code change upgrade run time' }]
                },
                order: 'id'
            }
        });
        envUpgradeEvents.forEach(function (event: any) {
            const envObj = _.filter(states.environments, { hostingAppName: event.clusterName });

            // set the environment
            if (envObj.length > 0) {
                event.environment = envObj[0];
                event.url = event.environment.dnsCname + '.clarifyhealth.' + event.environment.domainSuffix;
            }

            // set the upgrade event
            event.runTimeSeconds = event.eventDetail.runTimeSeconds;
            event.runTimeMins = Math.floor(event.runTimeSeconds / 60);
        });

        const buildTags = _.map(envUpgradeEvents, function (e: any) { return e.eventDetail.toBuildTag; });
        const upgradeTime = _.map(envUpgradeEvents, function (e: any) {
            return e.runTimeMins;
        });

        states.releaseParameters.upgradeRunTimeSingleEnvChartSettings = {
            labels: buildTags, // x build
            series: [''],
            data: [upgradeTime],
            colors: this.getRandomColor(buildTags.length),
            datasetOverride: [],
            options: {
                title: {
                    display: true,
                    text: 'Upgrade Run Time (Mins)'
                },
            }
        };

        const labels = buildTags;
        const data = {
            labels,
            datasets: [{
                label: '',
                data: upgradeTime,
                fill: false,
                borderColor: this.getRandomColor(buildTags.length),
                tension: 0.1
            }]
        };

        states.chartObj.upgradeRunTimeSingleEnvConfig = {
            type: 'line',
            data,
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };

        if (states.chartObj.upgradeRunTimeSingleEnvChart) {
            states.chartObj.upgradeRunTimeSingleEnvChart.destroy();
        }

        states.chartObj.upgradeRunTimeSingleEnvChart = new Chart(states.chartObj.upgradeRunTimeSingleEnvCtx, states.chartObj.upgradeRunTimeSingleEnvConfig);


    }

    initReleaseDataChart(codeChangeEvents: any, states: IEcsDashboardStates) {

        const deployDates = _.uniq(_.sortBy(_.map(codeChangeEvents, (e: any) => { return e.dateOnly; }), 'dateOnly'));
        const buildTags = _.uniq(_.map(codeChangeEvents, (e: any) => { return e.toBuildTag; }));
        const dataByDate: any[] = [];
        const seriesList = [];

        // let deployCounts = _.map(_.countBy(codeChangeEvents, "dateTime"), (val, key) => ({ date: key, total: val }))

        // Date is what drives our grouping
        const groupedByDate = _.toArray(_.sortBy(_.groupBy(codeChangeEvents, 'dateOnly'), 'dateTime'));

        buildTags.forEach(function (el) {
            const emptyValues: any[] = [];
            deployDates.forEach(function (bt) {
                emptyValues.push(0);
            });

            dataByDate.push(emptyValues);
        });

        let index = 0;
        _.each(groupedByDate, function (eventDay) {
            const d1 = _.countBy(eventDay, function (ed) { return ed.toBuildTag; });
            const deployDate = _.uniq(_.map(eventDay, function (e) { return e.dateOnly; }));
            const datePosition = deployDates.indexOf(deployDate[0]);

            _.each(d1, function (value, key) {
                const buildPosition = buildTags.indexOf(key);
                dataByDate[buildPosition][datePosition] = value;
                index++;
                // console.log(key, value);

            });

        });

        // let buildTags = _.sortBy(_.uniq(_.map(codeChangeEvents, function(e){ return e.eventDetail.toBuildTag; })));

        states.releaseParameters.codeChangeChartSettings = {
            labels: deployDates, // ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            type: 'StackedBar',
            series: buildTags, // ['2015', '2016'],   //build image
            colors: this.getRandomColor(buildTags.length),
            options: {
                title: {
                    display: true,
                    text: 'Releases'
                },
                legend: {
                    display: true,
                    position: 'top'
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                },
                tooltips: {
                    callbacks: {
                        label (tooltipItem: any, data: any) {
                            const buildTag = data.datasets[tooltipItem.datasetIndex].label || '';
                            // only show tool tips that have deploys on that date
                            if (tooltipItem.yLabel > 0) {
                                return buildTag + ':' + tooltipItem.yLabel;
                            } else {
                                return null;
                            }
                        }
                    }
                }
            },
            data: dataByDate
        };
    }

    upgradeAvgChartClick(points: any, evt: any, states: IEcsDashboardStates) {

        const selectedPoint = points[0];
        // only do something if its a real point in the graph
        if (selectedPoint) {
            // let dataSource = _.filter(states.releaseParameters.eventLogs, function(e) { return e.dateOnly === dateFilter });
            const selectedBuild = states.releaseParameters.upgradeAvgRunTimeChartSettings.labels[selectedPoint._index];

        }
    }

    releaseChartClick(event: any, states: IEcsDashboardStates) {

        const points = states.chartObj.releaseStackedBarChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

        if (points.length) {
            const firstPoint = points[0];
            const dateFilter = states.chartObj.releaseStackedBarChart.data.labels[firstPoint.index];
            const value = states.chartObj.releaseStackedBarChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

            const dataSource = _.filter(states.releaseParameters.eventLogs, function (e) { return e.dateOnly === dateFilter; });
            states.releaseParameters.releaseEventTable.data = dataSource;
        }

    }

    calculateReleaseInfo(releaseInfo: any, states: IEcsDashboardStates) {
        states.releaseParameters.releaseList = _.uniq(_.map(releaseInfo, function (e: any) { return e.toBuildTag; }));
        states.releaseParameters.averageUpgradeTimes = _.map(_.groupBy(releaseInfo, 'toBuildTag'), function (o, idx) {
            return {
                id: idx,
                maxReleaseTimeEvent: _.maxBy(releaseInfo, function (e: any) { return (e.upgradeEvent ? e.upgradeEvent.eventDetail.runTimeSeconds : 0); }),
                averageUpgradeTimeInSeconds: _.meanBy(o, function (e) {
                    return e.upgradeEvent ? e.upgradeEvent.eventDetail.runTimeSeconds : 0;
                })
            };
        });

        states.releaseParameters.averageUpgradeTimes.forEach(function (time: any) {
            time.averageUpgradeTimeInMinutes = Math.floor(time.averageUpgradeTimeInSeconds / 60);
            time.maxReleaseTimeInMinutes = Math.floor(time.maxReleaseTimeEvent.upgradeEvent.eventDetail.runTimeSeconds / 60);
            time.maxTimeUrl = time.maxReleaseTimeEvent.url;
        });
    }

    async saveEcsManagement(states: IEcsDashboardStates) {
        states.isSaving = true;
        const data = states.envManagement;
        await this.dataAccess.genericUpsert({
            model: 'EnvironmentManagement',
            data
        });
        states.isSaving = false;
    }

    openMatTableSettings(states: IEcsDashboardStates) {
        const data = {
            tableColumns: states.releaseParameters.matTableColumns,
            defaultDisplayTableColumns: states.releaseParameters.defaultDisplayMatTableColumns,
            settingName: states.releaseParameters.releaseParametersMatTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    exportToExcel(states: IEcsDashboardStates) {
        const data = states.releaseParameters.releaseEventTable.data;
        const columns = states.releaseParameters.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.releaseParameters.releaseParametersMatTableSettingName, columns, data);
    }
}
