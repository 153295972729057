import { Component } from '@angular/core';

@Component({
    selector: 'argos-marked-for-deletion',
    template: `<ui-view></ui-view>`
})

export class MarkedForDeletionComponent {

}
