import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IDataReleaseManagerStates, IDataReleaseManagerService } from '../dataReleaseManagerMainPage/dataReleaseManager.component.d';


@Injectable()
export class DataReleaseManagerServices implements IDataReleaseManagerService {

    constructor(private dataAccess: NgDataAccess) {
    }

    async initDelegate(states: IDataReleaseManagerStates): Promise<object> {
        await this.init(states);
        return states;
    }

    async init(states: IDataReleaseManagerStates) {
        states.dataReleases.data = await this.queryReleases();
        return states;
    }

    async queryReleases() {
        const DataReleases = await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'queryReleases'
        });
        for (const dataRelease of DataReleases) {
            dataRelease.releaseDate = new Date(dataRelease.releaseDate).toISOString().slice(0, 10);
        }
        return DataReleases;
    }

    async filterDataByStatus(states: IDataReleaseManagerStates) {
        const inProgressStatuses = ['Editable', 'Finalized', 'In-Progress'];
        if (inProgressStatuses.includes(states.filterStatus)) {
            return states.dataReleases.data.filter((release: any) => inProgressStatuses.includes(release.releaseStatus));
        }
        else {
            return states.dataReleases.data.filter((release: any) => release.releaseStatus === states.filterStatus);
        }
    }

    async cancelRelease(releaseId: string) {
        await this.dataAccess.genericUpsert({
            model: 'DataRelease',
            data: { 
                id: releaseId,
                releaseStatus: 'Cancelled'
            }
        });
        const release = await this.dataAccess.genericMethod({
            model: 'DataRelease',
            method: 'getReleaseInfo',
            parameters: {
                releaseId: releaseId
            }
        });
        try {
            const transitionMap = await this.dataAccess.genericMethod({
                model: 'JiraApi',
                method: 'getTransitions',
                parameters: {
                    jiraTicketId: release[0].rmJiraTicketId
                }
            });
            // transitionId might be different depending on current status
            const transitionId = transitionMap.transitions.find((transition: { name: string; }) => transition.name === 'Reject')['id'];
            const transition = await this.updateJiraTicketStatus(release[0].rmJiraTicketId, transitionId);
            console.log(`Jira ticket status updated: ${release[0].rmJiraTicketId}`, transition);
        } catch (error) {
            console.log('Error updating Jira ticket status: ', error);
        }
    }

    async updateJiraTicketStatus(jiraTicketId: string, newStatusCode: number) {
        const transitionResponse = await this.dataAccess.genericMethod({
            model: 'JiraApi',
            method: 'transitionIssue',
            parameters: {
                jiraTicketId: jiraTicketId,
                transitionId: newStatusCode
            }
        });
        return transitionResponse;
    }
}
