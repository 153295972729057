import { Injectable } from '@angular/core';
import { IAlertData, IAlertStates } from './alert.component.d';
import * as _ from 'lodash';

@Injectable()
export class AlertComponentService {
    constructor() {
        //
    }

    alertSuccessHandler(states: IAlertStates, message: IAlertData, cb: Function) {
        const alertSuccess = {
            title: message?.title || 'Success!',
            msg: message?.msg || 'Success',
            id: states.alerts.length,
            class: 'alert-success'
        };
        states.alerts.push(alertSuccess);
        cb(alertSuccess);
    }

    alertErrorHandler(states: IAlertStates, err: IAlertData) {
        states.alerts.push({
            title: err?.title || 'Error!',
            msg: err?.msg || 'Error',
            id: states.alerts.length,
            class: 'alert-danger'
        });
    }

    closeAlertHandler(alert: IAlertData, states: IAlertStates) {
        const index = _.findIndex(states.alerts, { id: alert.id });
        states.alerts.splice(index, 1);
    }
}
