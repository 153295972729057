import { Injectable } from '@angular/core';
import { ICcgFeatureEditStates, ICcgFeatureEditService } from './ccgFeatureEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';

@Injectable()
export class CcgFeatureEditService implements ICcgFeatureEditService {
    constructor(private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private state: StateService) {
        //
    }
    async initDelegate(states: ICcgFeatureEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: ICcgFeatureEditStates) {
        if (this.uiRouter.globals.params.id) {
            states.ccgFeature = await this.dataAccess.genericMethod({
                model: 'CcgFeature',
                method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.id,
                    filter: { include: ['programEpisodeCcgFeature', 'ccgFeatureDataSourceLimit'] }
                }
            });

            states.prgmEpisodes = await this.dataAccess.genericFind({
                model: 'ProgramEpisode'
            });
            states.ccgFeature.parameters = JSON.stringify(states.ccgFeature.parameters);

            states.dataSources = await this.dataAccess.genericFind({
                model: 'DataSource',
                filter: {
                    order: 'name',
                    where: {
                        dataType: 'claim'
                    }
                }
            });
        }
    }



    async saveChildRecords(states: ICcgFeatureEditStates) {
        const updatePromises: any = [];
        if (states.ccgFeature.ccgFeatureDataSourceLimit) {
            states.ccgFeature.ccgFeatureDataSourceLimit.forEach((obj: any) => {
                const data = this.dataAccess.genericUpsert({
                    model: 'CcgFeatureDataSourceLimit',
                    data: obj
                });
                updatePromises.push(data);
            });
        }

        if (states.ccgFeature.programEpisodeCcgFeature) {
            states.ccgFeature.programEpisodeCcgFeature.forEach((obj: any) => {
                const data = this.dataAccess.genericUpsert({
                    model: 'ProgramEpisodeCcgFeature',
                    data: obj
                });
                updatePromises.push(data);
            });
        }
        return Promise.all(updatePromises);
    }

    deleteChildRecords(states: ICcgFeatureEditStates) {
        _.map(states.dataSourcesToDelete, (id) => {
            return this.dataAccess.genericMethod({
                model: 'CcgFeatureDataSourceLimit', method: 'destroyById', parameters: {
                    id
                }
            });
        });

        return _.map(states.programEpisodesToDelete, (id) => {
            return this.dataAccess.genericMethod({
                model: 'ProgramEpisodeCcgFeature', method: 'destroyById', parameters: {
                    id
                }
            });
        });
    }

    async save(states: ICcgFeatureEditStates) {
        states.saveInProgress = true;
        const obj = states.ccgFeature;
        const result = await this.dataAccess.genericUpsert({
            model: 'CcgFeature',
            data: obj
        });
        states.ccgFeature.id = result.id;
        await Promise.all(this.deleteChildRecords(states));
        this.saveChildRecords(states);
        this.state.go('argos.ccgDesigner.ccgfeatures.list');
        states.saveInProgress = false;
    }
}
