import { Injectable } from '@angular/core';
import { IAllowedFieldEditStates, IAllowedFieldEditService } from './allowedFieldEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddNewFieldModalComponent } from '../addNewFieldModal/addNewFieldModal.component';
@Injectable()
export class AllowedFieldEditService implements IAllowedFieldEditService {
    constructor(private dataAccess: NgDataAccess, private state: StateService,
        private uiRouter: UIRouter, private matDialog: MatDialog) {
        //
    }

    async initDelegate(states: IAllowedFieldEditStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IAllowedFieldEditStates) {
        if (this.uiRouter.globals.params.id) {
            states.observationGroupAllowedField = await this.dataAccess.genericMethod({
                model: 'PrismNotification', method: 'findById',
                parameters: {
                    id: this.uiRouter.globals.params.id,
                    filter: { include: { observationSelectionFields: ['observation'], observationKeyFields: ['observation'] } }
                }
            });
        } else {
            states.observationGroupAllowedField = {};
        }

        const observationGroups = await this.dataAccess.genericFind({
            model: 'ObservationGroup'
        });

        states.observationGroups = _.orderBy(observationGroups, 'name');

        const listItems = await this.dataAccess.genericFind({
            model: 'ListItem',
            filter: {
                where: {
                    listId: 3
                }
            }
        });
        states.listItems = _.orderBy(_.map(listItems, (li: any) => {
            li.displayName = li.key + '.' + li.value;
            return li;
        }), 'displayName');
    }

    addNewField(states: IAllowedFieldEditStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            AddNewFieldModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (result: any) => {
            if (result) {
                await this.activate(states);
            }
        });
    }

    async saveHandler(states: IAllowedFieldEditStates) {
        // Need to add logic here
        states.saveInProgress = true;
        const data = states.observationGroupAllowedField;
        const observationGroupAllowedField = await this.dataAccess.genericUpsert({
            model: 'ObservationGroupAllowedField',
            data
        });
        this.state.go('argos.ccgAdmin.observationGroupAllowedFields.list');
        states.saveInProgress = false;
    }
}
