import { Injectable } from '@angular/core';
import { IEditValidValueQueryModalProps, IEditValidValueQueryModalStates, IEditValidValueQueryModalService } from './editValidValueQueryModal.component.d';
import * as _ from 'lodash';
import { NgDataAccess } from 'client/app/services/dataAccess.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ArgosStoreService } from 'client/app/services/argosStore.service';

@Injectable()
export class EditValidValueQueryModalService implements IEditValidValueQueryModalService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog,
        private argosStore: ArgosStoreService) {
        //
    }

    async initDelegate(props: IEditValidValueQueryModalProps, states: IEditValidValueQueryModalStates): Promise<Object> {
        states.editMode = props.editMode;
        states.validValueQueryName = props.validValueQueryName;
        states.supportsCache = props.supportsCache;
        await this.activate(states);
        return states;
    }

    async changeDelegate(oldProps: IEditValidValueQueryModalProps, newProps: IEditValidValueQueryModalProps, states: IEditValidValueQueryModalStates): Promise<Object> {
        return this.initDelegate(newProps, states);
    }

    async activate(states: IEditValidValueQueryModalStates) {
        if (states.validValueQueryName) {
            states.validValueQuery = await this.dataAccess.genericMethod({
                model: 'ValidValuesQuery', method: 'findById',
                parameters: {
                    id: states.validValueQueryName
                }
            });

            const episodeGroupings = await this.dataAccess.genericFind({
                model: 'EpisodeGrouping',
                filter: {
                    fields: ['groupByAttribute'],
                    where: {
                        validValuesQueryName: states.validValueQueryName
                    }
                }
            });

            states.validValueQuery.usedByGroupings = _.map(episodeGroupings, 'groupByAttribute');
            states.validValueQuery.groupingCount = _.size(states.validValueQuery.usedByGroupings);
        } else {
            states.validValueQuery = {
                supportsCache: states.supportsCache
            };
        }
    }

    async saveHandler(states: IEditValidValueQueryModalStates) {
        if (!states.validValueQuery) {
            return;
        }
        states.validValueQuery = await this.dataAccess.genericUpsert({
            model: 'ValidValuesQuery', data: states.validValueQuery
        });

        await this.getvalidValueQuery(states);
    }

    async getvalidValueQuery(states: IEditValidValueQueryModalStates) {
        states.validValueQuery = await this.dataAccess.genericMethod({
            model: 'ValidValuesQuery', method: 'findById',
            parameters: {
                id: states.validValueQuery.name,
            }
        });
    }
}
