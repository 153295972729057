import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IDataProvisioningQuestionnaireStates } from './dataProvisioningQuestionnaire.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { NgDataAccess } from '../../services/dataAccess.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewDataProvisioningQuestionnaireModalComponent } from './viewDataProvisioningQuestionnaireModal.component';
import * as _ from 'lodash';

@Component({
    selector: 'argos-data-provisioning-questionnaire',
    templateUrl: './dataProvisioningQuestionnaire.component.html'
})

export class DataProvisioningQuestionnaireComponent
    extends ReactComponentBase<{}, IDataProvisioningQuestionnaireStates, {}>
    implements OnInit {

    constructor(private cdr: ChangeDetectorRef, 
        private dataAccess: NgDataAccess,
        private matDialog: MatDialog) {
        super({
            queryRunning: true,
            results: []
        } as IDataProvisioningQuestionnaireStates);
    }

    async ngOnInit() {
        await this.initDelegate();
    }

    async initDelegate() {
        const results = await this.dataAccess.genericMethod({
            model: 'DataProvisioningQuestionnaire',
            method: 'getResponses',
            parameters: {}
        });

        this.setStates({ results, queryRunning: false });
        this.cdr.detectChanges();
    }

    viewResponse(row: any) {

        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: { response: row }
        };

        this.matDialog.open(ViewDataProvisioningQuestionnaireModalComponent, dialogConfig);
    }    

}
