import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';

@Component({
    selector: 'argos-error404',
    templateUrl: './error404.component.html'
})

export class Error404Component extends ReactComponentBase<{}, {}, {}> {
    constructor() {
        super();
    }
}
