// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    width: 100%;
  }
  
  tr.detail-row {
    height: 0;
  }
  
  tr.element-row:not(.example-expanded-row):hover {
    background: whitesmoke;
  }
  
  tr.element-row:not(.example-expanded-row):active {
    background: #efefef;
  }
  
  .element-row td {
    border-bottom-width: 1px;
  }
  
  .element-detail {
    overflow: hidden;
    display: flex;
  }

  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* semi-transparent white background */
    z-index: 9999; /* ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333; /* Change spinner color here */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
`, "",{"version":3,"sources":["webpack://./client/app/components/clarifyInsights/clarifyInsightsLandingPage/clarifyInsightsLandingPage.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;EACb;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,0CAA0C,EAAE,sCAAsC;IAClF,aAAa,EAAE,oCAAoC;IACnD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,oCAAoC;IACpC,uBAAuB,EAAE,8BAA8B;IACvD,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC","sourcesContent":["table {\n    width: 100%;\n  }\n  \n  tr.detail-row {\n    height: 0;\n  }\n  \n  tr.element-row:not(.example-expanded-row):hover {\n    background: whitesmoke;\n  }\n  \n  tr.element-row:not(.example-expanded-row):active {\n    background: #efefef;\n  }\n  \n  .element-row td {\n    border-bottom-width: 1px;\n  }\n  \n  .element-detail {\n    overflow: hidden;\n    display: flex;\n  }\n\n  .spinner-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.5); /* semi-transparent white background */\n    z-index: 9999; /* ensure it's above other content */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-left-color: #333; /* Change spinner color here */\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    animation: spin 1s linear infinite;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
