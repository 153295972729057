import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit} from '@angular/core';
import { IClarifyInsightsDeliveryHub } from './clarifyInsightsDeliveryHub.component.d';
import { ClarifyInsightsDeliveryHubService } from './clarifyInsightsDeliveryHub.component.service';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { ClarifyInsightsTriggerTransferModalComponent } from '../clarifyInsightsTriggerTransferModal/clarifyInsightsTriggerTransferModal.component';
import { ClarifyInsightsCreateDeliveryModalComponent } from '../clarifyInsightsCreateDeliveryModal/clarifyInsightsCreateDeliveryModal.component';
import { ClarifyInsightsTriggerExtractModalComponent } from '../clarifyInsightsTriggerExtractModal/clarifyInsightsTriggerExtractModal.component';
import swal from 'sweetalert2';


@Component({
    selector: 'argos-clarifyInsightsDeliveryHub',
    templateUrl: './clarifyInsightsDeliveryHub.component.html',
    providers: [ClarifyInsightsDeliveryHubService],
    styleUrls: ['./clarifyInsightsDeliveryHub.component.css']
})

export class ClarifyInsightsDeliveryHubComponent extends ReactComponentBase<{}, IClarifyInsightsDeliveryHub, {}> implements OnInit, AfterViewInit {
    
    @ViewChild('matTbSort') deliveryMatTbSort = new MatSort();
    @ViewChild('matTbSortExtract') extractMatTbSort = new MatSort();
    @ViewChild('matTbSortTransfer') transferMatTbSort = new MatSort();
    @ViewChild('deliveryPaginator') deliveryPaginator: MatPaginator;
    @ViewChild('extractPaginator') extractPaginator: MatPaginator;
    @ViewChild('transferPaginator') transferPaginator: MatPaginator;

    constructor(private svc: ClarifyInsightsDeliveryHubService, private cdr: ChangeDetectorRef,
                private matDialog: MatDialog, private snackBar: MatSnackBar) {
        super({
            extractColumns: ['customerCode', 'extractType', 'filterName', 'filterVersion', 'runId', 'runConfig', 'extractTablesSchema', 'clarifyReportingPaths', 'deliveryId'],
            transferColumns: ['customerCode', 'extractType', 'filterName', 'filterVersion', 'runConfig', 'dagRunId', 'extractRunId', 'createdBy', 'deliveryId'],
            deliveryColumns: ['customerCode', 'filterName', 'extractType', 'dataSource', 'deliveryDate', 'isComplete', 'exportAsCsv', 'latestExtractRunId', 'latestClarifyReports', 'latestQaRunId', 'latestTransfer', 'clearLastStage', 'deliveryNote', 'requestedBy', 'requestedAt'],
            deliveryCustomColumns: ['filterName', 'latestExtractRunId', 'latestQaRunId', 'latestExtractRunId','requestedAt', 'deliveryDate', 'isComplete', 'clearLastStage', 'exportAsCsv', 'deliveryNote', 'latestClarifyReports'],
            historyCustomColumns: ['runConfig', 'extractTablesSchema', 'filterName', 'dagRunId', 'clarifyReportingPaths'],
            showExtractLinkStatuses: ['transferStart', 'transferFailed', 'transferComplete', 'QA', 'Complete'],
            extractHistory: new MatTableDataSource<Object>(),
            transferHistory: new MatTableDataSource<Object>(),
            deliveryHistory: new MatTableDataSource<Object>(),
            displayedDeliveryHistory: new MatTableDataSource<Object>(),
            recentExtracts: [],
            isCustomerSelected: false,
            isExtractSelected: false,
            pageSize: 10,
            pageSizeOptions: [5, 10, 25, 50],
            activeDeliveryStatus: 'In-Progress'
        } as unknown as IClarifyInsightsDeliveryHub);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
        await this.ngAfterViewInit();
    }

    async ngAfterViewInit() {
        setTimeout(() => {
            this.states.displayedDeliveryHistory.paginator = this.deliveryPaginator;
            this.states.displayedDeliveryHistory.sort = this.deliveryMatTbSort;
            this.states.extractHistory.paginator = this.extractPaginator;
            this.states.extractHistory.sort = this.extractMatTbSort;
            this.states.transferHistory.paginator = this.transferPaginator;
            this.states.transferHistory.sort = this.transferMatTbSort;
            this.cdr.detectChanges();
        });
    }

    async changeEvent(event: any, fieldName: string) {
        let populateHistories: boolean = false;
        switch (fieldName) {
            case 'customerCode': 
                this.states.activeExtractType = undefined;
                this.states.activeFilterName = undefined;
                this.states.activeCustomerCode = (event !== 'All') ? event : undefined;
                this.states.activeCustomerId = (this.states.activeCustomerCode) ? this.states.distinctCustomers.find(customer => customer.customerCode === this.states.activeCustomerCode).customerId : undefined;
                populateHistories = (event === 'All') ? true : false;
                break;
            case 'extractType':
                this.states.activeFilterName = undefined;
                this.states.activeExtractType = (event !== 'All') ? event : undefined;
                break;
            case 'filterName':
                this.states.activeFilterName = (event !== 'All') ? event : undefined;
                break;
        };
        this.states.isCustomerSelected = (this.states.activeCustomerCode || populateHistories) ? true : false;
        this.states.isExtractSelected = (this.states.activeExtractType) ? true: false;
        await this.svc.refreshData(this.states);
        await this.ngAfterViewInit();
        this.cdr.detectChanges();
    }

    async changeDeliveryStatusFilter(event: any) {
        this.states.activeDeliveryStatus = event;
        await this.svc.refreshData(this.states);
        await this.ngAfterViewInit();
        this.cdr.detectChanges();
    }

    openConfig(runConfig: string) {
        let jsonString = JSON.stringify(JSON.parse(runConfig), null, 4).replace('{', '').replace('}', '').replace(/,/g, '\n')
        jsonString = '<pre>' + jsonString + '</pre>';
        swal({
            title: 'Run Config',
            html: jsonString,
            type: 'info',
            confirmButtonText: 'Close',
            width: '70%'
        });
    }

    openSchema(extractTablesSchema: any) {
        let jsonString = JSON.stringify(extractTablesSchema, null, 4);
        jsonString = '<code>' + jsonString + '</code>';
        swal({
            title: 'Extract Tables Schemas',
            html: jsonString,
            type: 'info',
            confirmButtonText: 'Close',
            width: '70%'
        });
    }

    triggerTransferFromRow(id: string, customerCode: string, extractType: string, filterName: string, dataSource: string, extractRun: string) {
        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    customerCode: customerCode,
                    extractType: (extractType === 'claims') ? 'enriched_claims' : extractType,
                    filterName: filterName,
                    dataSource: dataSource,
                    extractRun: extractRun
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ClarifyInsightsTriggerTransferModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (dagRunId) => {
            await this.svc.updateDeliveryTransfer(id, dagRunId);
            await this.svc.refreshData(this.states);
            await this.ngAfterViewInit();
            this.cdr.detectChanges();
        });
    }

    triggerExtractFromRow(element: any) {
        const dagConfig = this.svc.buildExtractDagConfig(element);
        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    deliveryId: element.id,
                    customerCode: element.customerCode,
                    filterName: element.filterName,
                    extractType: element.extractType,
                    dataSource: element.dataSource,
                    dagName: dagConfig.dagName,
                    dagConfig: dagConfig,
                    exportAsCsv: element.exportAsCsv
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ClarifyInsightsTriggerExtractModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async () => {
            await this.svc.refreshData(this.states);
            await this.ngAfterViewInit();
            this.cdr.detectChanges();
        });
    }
    
    openCreateDeliveryModal() {
        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {} // may be used to pass selected filters to modal in future
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ClarifyInsightsCreateDeliveryModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async () => {
            await this.ngOnInit();
            this.cdr.detectChanges();
        });
    }

    async triggerQaFromRow(element: any) {
        const swalResponse = await swal({
            title: 'QA Step',
            text: 'Are you sure you want to mark QA complete for this delivery?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        })
        if (swalResponse.value) {
            await this.svc.triggerQaFromRow(element);
            await this.svc.refreshData(this.states);
            await this.ngAfterViewInit();
            this.cdr.detectChanges();
        }

    }

    openAirflow(element: any, dagType: string) {
        let dagRunLink: string;
        if (dagType === 'transferDelivery') {
            const variableString = `insights-s3-to-s3-transfer-pipeline/grid?dag_run_id=${encodeURIComponent(element.latestTransferRunId)}`
            dagRunLink = `${this.states.dagsUrlBase}/${variableString}`;
        }
        else if (dagType === 'transferHistory') {
            const variableString = `insights-s3-to-s3-transfer-pipeline/grid?dag_run_id=${encodeURIComponent(element.dagRunId)}`
            dagRunLink = `${this.states.dagsUrlBase}/${variableString}`;
        }
        else if (dagType === 'extract') {
            const variableString = `${element.dagConfig.dagName}/grid?dag_run_id=${encodeURIComponent(element.latestExtractDagRunId)}`
            dagRunLink = `${this.states.dagsUrlBase}/${variableString}`;
        }
        else {
            this.snackBar.open('Invalid DAG type', 'Close', { duration: 5000 });
            return;
        }
        window.open(dagRunLink, '_blank');
        
    }

    async clearLastStage(element: any) {
        const swalResponse = await swal({
            title: 'Clear Last Stage',
            text: 'Are you sure you want to clear the last stage for this delivery?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        })
        if (swalResponse.value) {
            await this.svc.clearLastStage(element);
            await this.svc.refreshData(this.states);
            await this.ngAfterViewInit();
            this.cdr.detectChanges();
        }
    }

    async openDeliveryNote(element: any) {
        const swalResponse = await swal({
            title: 'Delivery Note',
            input: 'textarea',
            inputValue: element.deliveryNote,
            inputAttributes: {
                style: 'color: black'
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save'
        });
        if (swalResponse.value) {
            await this.svc.updateDeliveryNote(element, swalResponse.value);
            await this.svc.refreshData(this.states);
            await this.ngAfterViewInit();
            this.cdr.detectChanges();
        }
    }

    async clearSelections(states: IClarifyInsightsDeliveryHub) {
        await this.svc.clearSelections(states);
        this.states.isCustomerSelected = false;
        this.states.isExtractSelected = false;
        await this.svc.refreshData(this.states);
        await this.ngAfterViewInit();
        this.cdr.detectChanges();
    }

    navToCustomerFiltersDelegate(element: any, fieldsToPass: string[] = [], states: IClarifyInsightsDeliveryHub) {
        this.svc.navToCustomerFilters(element, fieldsToPass, states);
    }


    getCustomerCode(customerId: string, states: IClarifyInsightsDeliveryHub) {
        return this.svc.getCustomerCode(customerId, states);
    }

    getCustomerDestinationBucket(customerId: string, extractType: string, states: IClarifyInsightsDeliveryHub) {
        return this.svc.getCustomerDestinationBucket(customerId, extractType, states);
    }

    friendlyColumnName(columnName: string) {
        const renameColumns: { [key: string]: string } = {
            'Customer Code': 'Customer',
            'Created Date': 'Date',
            'Created By': 'User',
            'Output S3 Path Run Id': 'Output Run Id',
            'Input S3 Path Run Id': 'Input Run Id',
            'Trigger S3 Transfer': 'Initiate Transfer',
            'Is Complete': 'Complete',
            'Delivery Note': 'Note',
            'Export As Csv': 'CSV Export',
            'Requested By': 'Requestor',
            'Requested At': 'Date',
            'Clear Last Stage': 'Clear Stage'
        };
        let tempName = columnName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
        tempName = tempName.replace('Latest ', '').replace('Triggered ', '');
        return renameColumns[tempName] || tempName;
    }

    truncateDisplay(value: string, length: number) {
        return (!value) ? '' : (value.length > length) ? value.substring(0, length) : value;
    }

    formatDateField(dateField: string) {
        if (!dateField) {
            return '';
        }
        dateField = (dateField.startsWith('manual__')) ? dateField.replace('manual__', '').replace(/_\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/, '') : dateField;
        return (dateField.includes('.')) ? dateField.replace('T', ' ').replace(/\.\d{3}\+\d{2}:\d{2}$/, '').split('.')[0].split(' ')[0] : new Date(dateField).toLocaleString().substring(0, new Date(dateField).toLocaleString().indexOf(','));
    }

    displayClarifyReportName(s3Path: string) {
        let s3PathComponents = s3Path.split('/');
        return s3PathComponents[s3PathComponents.length-1];
    }

    async openClarifyReport(s3Path: string) {
        let presignedS3Url = await this.svc.generatedPresignedS3Url(s3Path.replace('.ipynb', '.html'));
        window.open(presignedS3Url, '_blank');
    }

}