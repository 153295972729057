import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IClarifyInsightsTriggerTransferModalInterface } from './clarifyInsightsTriggerTransferModal.component.d';
import { MD5Service } from '../../../services/md5.service';
import { ArgosStoreService } from 'client/app/services/argosStore.service';

@Injectable()
export class ClarifyInsightsTriggerTransferModalService {

    constructor(private dataAccess: NgDataAccess, private md5: MD5Service, private argosStore: ArgosStoreService) { }

    async initDelegate(states: IClarifyInsightsTriggerTransferModalInterface): Promise<object> {
        await this.init(states)
        return states;
    }

    async init(states: IClarifyInsightsTriggerTransferModalInterface) {
        const dagsUrl = await this.dataAccess.genericMethod({
            model: 'Astronomer',
            method: 'getDagsUrl'
        });
        states.dagsUrl = `${dagsUrl.data}/insights-s3-to-s3-transfer-pipeline/grid?dag_run_id=`
        await this.updateConfig(states);
        return states;
    }

    async updateConfig(states: IClarifyInsightsTriggerTransferModalInterface) {
        // confirm src_path and data_source (if required)
        states.validConfig = false;
        if (states.autoGenerateDestPath) {
            await this.generateDestPath(states);
        }
        if (states.autoGenerateSrcPath) {
            await this.generateSrcPath(states);
        }
        if (states.autoGenerateAthenaDatabaseName) {
            this.generateAthenaDatabaseName(states);
        }
        const dagConfig = {
            's3_paths': [
                {
                    'src_path': states.srcPath,
                    'dest_path': states.destPath.endsWith('/') ? states.destPath : states.destPath + '/',
                    'extract_type': states.activeExtractType,
                    'customer_code': states.activeCustomerCode,
                    'data_source': states.activeDataSource,
                    'athena_database_name': states.athenaDatabaseName,
                    'exclusions': states.exclusions
                }
            ],
            'verify_manifest': true,
            'report_transfer_size_paths': [] as any[]
        };
        if (!states.athenaDatabaseName) {
            delete dagConfig.s3_paths[0].athena_database_name
        }
        if (this.disableDataSource(states)) {
            delete dagConfig.s3_paths[0].data_source
        }
        states.dagConfig = JSON.stringify(dagConfig, null, 4);
        if ((states.srcPath.startsWith('s3://') || states.autoGenerateSrcPath) && (states.destPath.startsWith('s3://') || states.autoGenerateDestPath)) {
            // paths are valid
            if (states.activeExtractType && states.activeCustomerCode && states.activeFilterName && states.activeDataSource && states.activeExtractRun) {
                // all required fields are populated, activeDataSource is optional for dagConfig but required for transferHistory
                states.validConfig = true;
                return;
            }
            else {
                return;
            }
        }
        else {
            return;
        }
    }

    async triggerTransfer(states: IClarifyInsightsTriggerTransferModalInterface) {
        const astroResponse = await this.dataAccess.genericMethod({
            model: 'ClarifyInsightsTransferHistory',
            method: 'triggerDag',
            parameters: {
                dagConfig: states.dagConfig,
                triggeredByUser: this.argosStore.getItem('username').replace('@clarifyhealth.com', '')
            }
        });
        if (!astroResponse.isSuccessful) {
            return {isSuccessful: false, error: astroResponse.error};
        }
        const dagRunId = astroResponse.data.dag_run_id;
        try {
            await this.dataAccess.genericCreate({
                model: 'ClarifyInsightsTransferHistory',
                data: {
                    customerId: this.md5.md5(states.activeCustomerCode),
                    customerCode: states.activeCustomerCode,
                    extractType: states.activeExtractType,
                    srcPath: states.srcPath,
                    destPath: states.destPath.endsWith('/') ? states.destPath : states.destPath + '/',
                    dataSource: states.activeDataSource,
                    athenaDatabaseName: states.athenaDatabaseName,
                    dagRunId: dagRunId,
                    dagConfig: states.dagConfig,
                    createdBy: this.argosStore.getItem('username').replace('@clarifyhealth.com', ''),
                    createdDate: new Date().toISOString(),
                    filterName: states.activeFilterName,
                    filterVersion: await this.getRunFilterVersion(states),
                    extractRunId: states.activeExtractRun,
                    exclusions: states.exclusions
                }
            });
        }
        catch (e) {
            console.log(e);
            return {dagRunId: dagRunId, isSuccessful: false, error: e};
        }
        return {isSuccessful: true, dagRunId: dagRunId, error: null};
    }

    async populateS3DestinationBucket(states: IClarifyInsightsTriggerTransferModalInterface) {
        states.s3DestinationBucket = await this.dataAccess.genericMethod({
            model: 'ClarifyInsightsCustomer',
            method: 'getDestinationBucketByCustomerAndExtractType',
            parameters: {
                body: {
                    customerCode: states.activeCustomerCode,
                    extractType: states.activeExtractType
                }
            }
        });
    };

    async generateDestPath(states: IClarifyInsightsTriggerTransferModalInterface) {
        states.destPath = '';
        let destBucket = states.s3DestinationBucket ? states.s3DestinationBucket : '';
        if (!destBucket) {
            await this.populateS3DestinationBucket(states);
            destBucket = states.s3DestinationBucket;
        }
        let destPath = (destBucket.endsWith('/')) ? `s3://${destBucket}` : `s3://${destBucket}/`;
        destPath += (states.activeExtractRun) ? `${states.activeExtractRun}` : '';
        states.destPath = destPath;
    }

    async generateSrcPath(states: IClarifyInsightsTriggerTransferModalInterface) {
        states.srcPath = '';
        const extractRun = await this.dataAccess.genericMethod({
            model: 'ClarifyInsightsExtractHistory',
            method: 'getExtractRunOutputPath',
            parameters: {
                body: {
                    customerId: this.md5.md5(states.activeCustomerCode),
                    extractType: states.activeExtractType,
                    filterName: states.activeFilterName,
                    extractRun: states.activeExtractRun
                }
            }
        });
        states.srcPath = (extractRun === 'notFound') ? '' : extractRun;
    }

    generateAthenaDatabaseName(states: IClarifyInsightsTriggerTransferModalInterface) {
        states.athenaDatabaseName = '';
        let athenaDatabaseName = '';
        if (!states.activeExtractType) {
            states.athenaDatabaseName = athenaDatabaseName;
            return
        }
        if (!states.activeExtractType.startsWith('clarify_')) {
            athenaDatabaseName = `clarify_${states.activeExtractType}`;
        }
        else {
            athenaDatabaseName = states.activeExtractType;
        }
        states.athenaDatabaseName = (states.activeCustomerCode.toLowerCase() == 'evaluation') ? `${athenaDatabaseName}_${this.cleanFilterName(states.activeFilterName)}` : athenaDatabaseName;
    }

    async getRunFilterVersion(states: IClarifyInsightsTriggerTransferModalInterface) {
        const filterVersion = await this.dataAccess.genericMethod({
            model: 'ClarifyInsightsExtractHistory',
            method: 'getRunFilterVersion',
            parameters: {
                body: {
                    customerId: this.md5.md5(states.activeCustomerCode),
                    extractType: states.activeExtractType,
                    dataSource: states.activeDataSource,
                    filterName: states.activeFilterName,
                    runId: states.activeExtractRun
                }
            }
        });
        if (filterVersion === 'notFound') {
            return 0;
        }
        return filterVersion;
    }

    disableDataSource(states: IClarifyInsightsTriggerTransferModalInterface) {
        if (states.dataSourceExtractTypes.includes(states.activeExtractType)) {
            return false;
        }
        return true;
    }

    cleanFilterName(filterName: string) {
        return filterName.replace(/%/g, '').replace(/[\s-]/g, '_').toLowerCase();
    }

}
