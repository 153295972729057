import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IObservationExceptionListStates } from './observationExceptionList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ObservationExceptionListService } from './observationExceptionList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-observationExceptionList',
    templateUrl: './observationExceptionList.component.html',
    providers: [ObservationExceptionListService]
})

export class ObservationExceptionListComponent
    extends ReactComponentBase<{}, IObservationExceptionListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: ObservationExceptionListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            pageSize: 50,
            filterObj: {},
            matTableColumns: ['edit', 'observationId', 'observationName', 'prismTitle', '_dataSourceName', 'issueDescription', '_status', 'ticketNumber', '_createdDateFmt', '_updatedDateFmt'],
            observationExceptions: new MatTableDataSource([])
        } as IObservationExceptionListStates);
    }

    ngAfterViewInit() {
        this.states.observationExceptions.paginator = this.paginator;
        this.states.observationExceptions.sort = this.matTbSort;
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.observationExceptions.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.observationExceptions.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    copy(observationException: any) {
        this.svc.copyHandler(observationException, this.states);
    }

    edit(observationException: any) {
        this.svc.editHandler(observationException, this.states);
    }

    add() {
        this.svc.addHandler(this.states);
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.observationExceptions.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.observationExceptions.filter);
    }

    searchObservationExceptions(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.observationExceptions.filter = filterValue.trim().toLowerCase();
    }
}
