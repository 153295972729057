import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IDeequTestResultListStates, IDeequTestResultListService } from './deequTestResultList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as moment from 'moment';
import { UIRouter } from '@uirouter/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class DeequTestResultListService implements IDeequTestResultListService {
    constructor(private argosStore: ArgosStoreService, private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, private matDialog: MatDialog, private cdr: ChangeDetectorRef, private uiRouter: UIRouter) {
        //
    }

    async initDelegate(states: IDeequTestResultListStates): Promise<object> {
        await this.loadData(states);
        return {};
    }

    async loadData(states: IDeequTestResultListStates) {
        const tableName = this.uiRouter.globals.params.tableName;
        if (Array.isArray(tableName)){
            states.tableName = ` IN ( '${tableName.join("','")}')`
        } else {
            states.tableName = ` = '${tableName}'`
        }
        states.database = this.uiRouter.globals.params.database;
        states.runId = this.uiRouter.globals.params.runId;
        const deequTestResultsResponse = await this.dataAccess.genericMethod({
                model: 'DataUnitTestResult',
                method: 'fetchDataUnitTestResult',
                parameters: {
                    table_name: states.tableName,
                    run_id: states.runId,
                    database: states.database
                    }
            });

        const deequTestResults = deequTestResultsResponse.result.results;
        states.table.data = deequTestResults;
        states.dataUnitTestResults = deequTestResults;
    }

    setTablePageSize(states: IDeequTestResultListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IDeequTestResultListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IDeequTestResultListStates) {
        let data = {
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });

        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    isNumeric(value: string) {
        return /^-?\d+$/.test(value);
    }

}
