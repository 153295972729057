import { Injectable, ChangeDetectorRef } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IViewDeequModalProps, IViewDeequModalStates, IViewDeequModalService } from './viewDeequModal.component.d';
import { ViewDeequModalComponent } from './viewDeequModal.component';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { MatDialogConfig, MatDialog, MatDialogRef  } from '@angular/material/dialog';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { ViewDeequThresholdModalComponent } from '../deequThresholdModal/viewDeequThresholdModal.component';
const csv = require('csvtojson');


@Injectable()
export class ViewDeequModalService implements IViewDeequModalService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private dialogRef: MatDialogRef<ViewDeequModalComponent>, private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {}
    async initDelegate(props: IViewDeequModalProps, states: IViewDeequModalStates): Promise<object> {
        states.lodash = _;
        states.deequTest = props.deequTest;
        states.teradromeTable = props.teradromeTable;
        states.columns = props.columns;
        states.source = props.source;
        states.defaultLimitSource = states.source ? states.source.name : 'common';
        states.stage = props.stage;
        states.columnId = props.columnId;
        states.sources = props.sources.map((source: any) => {
            source.id = parseInt(source.id);
            if (states.deequTest.teradromeStageSourceId && source.id === parseInt(states.deequTest.teradromeStageSourceId)) {
                states.defaultLimitSource = source.sourceName;
                if (typeof states.deequTest.teradromeStageSourceId === 'string') {
                    states.deequTest.teradromeStageSourceId = source.id;
                }
            }
            return source;
        });
        states.testNameEditDisabled = true;
        states.containedInManualDisabled = true;
        states.columnAConst = states.deequTest.args.columnAType ? true : false;
        states.columnBConst = states.deequTest.args.columnBType ? true : false;
        states.saveErrorMessage = '';
        states.messages = [];
        states.sessionId = undefined;
        states.question = '';
        states.fetchingResponse = false;
        states.unsupportedDataTypes = ['array', 'map', 'struct'];
        const column = states.columns.find(column => column.id === states.columnId);
        if (props.testType === 'table') {
            states.testDict = {
                hasSize: [['upper_limit', 'lower_limit']],
                satisfies: [['columnCondition', 'constraintName', 'upper_limit', 'lower_limit', 'sample_fraction']],
            };
        } else if (column && states.unsupportedDataTypes.includes(column.dataType)){
            states.testDict = {satisfies: [['columnCondition', 'constraintName', 'upper_limit', 'lower_limit', 'sample_fraction']]};
        } else {
            states.testDict = {
                isComplete: [['column']],
                hasCompleteness: [['column', 'upper_limit', 'lower_limit']],
                areComplete: [['columns']],
                haveCompleteness: [['columns', 'upper_limit', 'lower_limit']],
                areAnyComplete: [['columns']],
                haveAnyCompleteness: [['columns', 'upper_limit', 'lower_limit']],
                isUnique: [['column', 'sample_fraction']],
                hasUniqueness: [['columns', 'upper_limit', 'lower_limit', 'sample_fraction']],
                hasDistinctness: [['columns', 'upper_limit', 'lower_limit', 'sample_fraction']],
                hasUniqueValueRatio: [['columns', 'upper_limit', 'lower_limit']],
                hasNumberOfDistinctValues: [['column', 'upper_limit', 'lower_limit', 'binningUdf', 'maxBins']],
                hasMinLength: [['column', 'upper_limit', 'lower_limit']],
                hasMaxLength: [['column', 'upper_limit', 'lower_limit']],
                hasMin: [['column', 'upper_limit', 'lower_limit']],
                hasMax: [['column', 'upper_limit', 'lower_limit']],
                hasMean: [['column', 'upper_limit', 'lower_limit']],
                hasSum: [['column', 'upper_limit', 'lower_limit']],
                hasStandardDeviation: [['column', 'upper_limit', 'lower_limit']],
                hasApproxCountDistinct: [['column', 'upper_limit', 'lower_limit']],
                hasCorrelation: [['columnA', 'columnB', 'upper_limit', 'lower_limit']],
                satisfies: [['columnCondition', 'constraintName', 'upper_limit', 'lower_limit', 'sample_fraction']],
                hasPattern: [['column', 'pattern', 'upper_limit', 'lower_limit', 'name', 'sample_fraction']],
                hasDataType: [['column', 'datatype', 'sample_fraction']],
                isNonNegative: [['column', 'upper_limit', 'lower_limit']],
                isPositive: [['column', 'upper_limit', 'lower_limit']],
                isLessThan: [['columnA', 'columnB', 'upper_limit', 'lower_limit']],
                isLessThanOrEqualTo: [['columnA', 'columnB']],
                isGreaterThan: [['columnA', 'columnB', 'upper_limit', 'lower_limit']],
                isGreaterThanOrEqualTo: [['columnA', 'columnB']],
                isContainedIn: [['column', 'allowed_values_type', 'sample_fraction']]
                // Might enable these later, but for now disabling in the UI
                // hasHistogramValues: [['column', 'upper_limit', 'lower_limit', 'binningUdf', 'maxBins']],
                // kllSketchSatisfies: [['column', 'upper_limit', 'lower_limit', 'kllParameters']],
                // hasEntropy: [['column', 'upper_limit', 'lower_limit']],
                // hasMutualInformation: [['columnA', 'columnB', 'upper_limit', 'lower_limit']],
                // hasApproxQuantile: [['column', 'quantile', 'upper_limit', 'lower_limit']],
                // containsCreditCardNumber: [['column', 'upper_limit', 'lower_limit']],
                // containsEmail: [['column', 'upper_limit', 'lower_limit']],
                // containsURL: [['column', 'upper_limit', 'lower_limit']],
                // containsSocialSecurityNumber: [['column', 'upper_limit', 'lower_limit']],
            };
        }

        if (states.deequTest.id) {
            // Check for any missing args for existing tests and adds them
            const missingArgs = _.difference(states.testDict[states.deequTest.type][0], Object.keys(states.deequTest.args));
            if (missingArgs.length > 0) {
                const emptyArgsObject: any = {};
                _.forEach(missingArgs, function (ma: string) {
                    if (['lower_limit', 'upper_limit'].includes(ma)) {
                        emptyArgsObject[ma] = {};
                    } else {
                        emptyArgsObject[ma] = undefined;
                    }
                });
                states.deequTest.args = _.assign(states.deequTest.args, emptyArgsObject);
            }
            // 'allowed_values_type' is only valid for new tests
            if ('allowed_values_type' in states.deequTest.args) {
                delete states.deequTest.args.allowed_values_type;
            }
        }


        const allTestNames = await this.dataAccess.genericFind({
            model: 'TeradromeDeequTest',
            filter: {
                where: {
                    teradromeTableId: states.teradromeTable.id
                },
                fields: ['name']
            }
        });

        states.testNames = _.map(allTestNames, 'name');
        const index = states.testNames.indexOf(states.deequTest.name);
        if (index > -1) {
            states.testNames.splice(index, 1);
        }

        states.allColumns = states.deequTest.args.all_columns_arg ? true : false;
        if (states.allColumns) {
            this.updateSatisfiesSQL(states);
        }


        states.originalDeequTest = _.cloneDeep(states.deequTest);

        if (states.deequTest.id) {
            states.history = await this.dataAccess.genericFind({
                model: 'TeradromeDeequTestHistory',
                filter: {
                    include: ['teraUser'],
                    where: {
                        teradromeDeequTestId: states.deequTest.id
                    },
                    order: 'version DESC'
                }
            });
        } else {
            states.history = [];
        }

        // Upper/Lower Limit need to be an object, if undefined HTML fails
        for (const arg of ['lower_limit', 'upper_limit']) {
            if (arg in states.deequTest.args && !states.deequTest.args[arg]) {
                states.deequTest.args[arg] = {};
            }
        }

        // Get Tera User ID and store as current ownner, this will allow us to slack individuals (most recent person to edit) when tests fail
        const teraUsers = await this.dataAccess.genericFind({
            model: 'TeraUser',
            filter: {
                where: {
                    ownerEmail: this.argosStore.getItem('username').toLowerCase()
                }
            }
        });

        states.teraUserId = teraUsers ? teraUsers[0].id : undefined;
        return {};
    }

    changeDelegate(oldProps: IViewDeequModalProps, newProps: IViewDeequModalProps, states: IViewDeequModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    testTypeChanges(states: IViewDeequModalStates) {
        const requiredArgs = states.testDict[states.deequTest.type][0];
        const emptyArgsObject: any= {};
        _.forEach(requiredArgs, function (ra: string) {
            if (['lower_limit', 'upper_limit'].includes(ra)) {
                emptyArgsObject[ra] = {};
            } else if (['column', 'columns', 'columnA'].includes(ra) && states.columnId) {
                const columnName = _.find(states.columns, { id: states.columnId }).columnName;
                emptyArgsObject[ra] = ra === 'columns' ? [columnName] : columnName;
            }
            else {
                emptyArgsObject[ra] = undefined;
            }});
        states.deequTest.args = emptyArgsObject;
        if (states.deequTest.type === 'satisfies') {
            this.updateSatisfiesSQL(states);
        }
        this.updateName(states);
    }

    updateName(states: IViewDeequModalStates) {
        if (states.testNameEditDisabled) {
            const level = states.deequTest.level ? ' ' + states.deequTest.level.toLowerCase() : ' test';
            const type = states.deequTest.type ? ' ' + _.startCase(states.deequTest.type) : '';
            const col =  states.deequTest.args.column ? ' ' + states.deequTest.args.column : states.deequTest.args.columns ? states.deequTest.args.columns.join(', ') : '';
            states.deequTest.name = states.teradromeTable.tableName + type + level + ':' + col.slice(0, 30);
        }
    }

    argsUpdate(arg: any, updateName: boolean | undefined, states: IViewDeequModalStates) {
        if (arg.key === 'allowed_values_type') {
            if (arg.value === 'sql') {
                states.deequTest.args['allowed_values_list'] = undefined;
                delete states.deequTest.args.allowed_values;
            }  else {
                states.deequTest.args['allowed_values'] = [];
                delete states.deequTest.args.allowed_values_list;
            }
            delete states.deequTest.args.allowed_values_type;
        } else if (['lower_limit', 'upper_limit'].includes(arg.key)) {
            states.deequTest.args[arg.key][states.defaultLimitSource] = arg.value[states.defaultLimitSource];
        } else if (arg.key === 'sample_fraction'){
            states.deequTest.args[arg.key] = parseFloat(arg.value);
        }
        else {
            states.deequTest.args[arg.key] = arg.value;
        }
        if (['all_columns_boolean', 'all_columns_exclusions'].includes(arg.key)) {
            this.updateSatisfiesSQL(states);
        }

        if (updateName) {
            this.updateName(states);
        }
    }

    argsCleared(arg: any, states: IViewDeequModalStates) {
        states.deequTest.args[arg.key] = undefined;
    }

    clearFile(states: IViewDeequModalStates) {
        states.deequTest.args.allowed_values = [];
    }

    uploadFile(file: any, states: IViewDeequModalStates) {
        const result: string[] = [];
        const file_obj = file.target.files[0];
        const reader = new FileReader();

        reader.readAsText(file_obj);
        reader.onload = async (e) => {
            const readerString = e.target?.result;

            try {
                const headers = ['values'];
                const jsonArray = await csv({
                    noheader: true,
                    headers
                }).fromString(readerString);

                if (jsonArray && jsonArray[0].field2) {
                    swal({
                        title: 'Warning',
                        text: 'CSV values should be in 1 column vs 1 row',
                        type: 'warning',
                        confirmButtonColor: '#DD6B55', confirmButtonText: 'Ok',
                    });
                    return;
                }

                jsonArray.forEach((value: any) => {
                    result.push(value.values);
                });
            } catch (error) {
                console.error('Error parsing CSV:', error);
            }

            states.deequTest.args.allowed_values = result;
        };

    }

    useConstant(states: IViewDeequModalStates) {
        states.columnBConst = !states.columnBConst;
        states.deequTest.args.columnB = '';
        if (states.columnBConst) {
            states.deequTest.args.columnBType = '';
        } else {
            delete states.deequTest.args.columnBType;
        }
    }

    deleteTest(states: IViewDeequModalStates) {
        swal({
            title: 'Confirm Test Deletion',
            text: 'Are you sure you want to delete the "' + states.deequTest.name + '" test?',
            type: 'warning',
            confirmButtonColor: '#DD6B55', confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                this.dataAccess.genericMethod({
                    model: 'TeradromeDeequTest', method: 'destroyById',
                    parameters: { id: states.deequTest.id }
                });
                this.dialogRef.close({});
            }
        });
    }

    async saveModal(states: IViewDeequModalStates) {
        Object.entries(states.deequTest.args).forEach(entry => {
            const [key, value] = entry;
            if (value === undefined) {
                states.deequTest.args[key] = null;
            }
        });
        states.deequTest.teradromeTableId = states.teradromeTable.id;
        states.deequTest.lastModified = new Date();
        if (states.source) {
            states.deequTest.teradromeStageSourceId = states.source.id;
        }

        // Check args dictionary of deequTest for column or columns key, and use string names of columns to get list of column ids from states.columns array
        // First get all columns in args
        const columnKeys = ['column', 'columns', 'columnA', 'columnB'];
        let columnIds: number[] = [];
        _.forEach(columnKeys, function (ck: string) {
            if (states.deequTest.args[ck]) {
                if (ck === 'columns') {
                    columnIds = columnIds.concat(states.deequTest.args[ck].map((col: string) => {
                        return states.columns.find((column: any) => {
                            return column.columnName === col;
                        }).id.toString();
                    }));
                } else {
                    const column = states.columns.find((column: any) => {
                        return column.columnName === states.deequTest.args[ck];
                    });
                    if (column) {
                        columnIds.push(column.id.toString());
                    }
                }
            }
        });

        if (columnIds.length > 0) {
            states.deequTest.teradromeColumnIds = '{' + columnIds.join(',') + '}';
        }

        states.deequTest.teraUserId = states.teraUserId;

        states.deequTest = await this.dataAccess.genericUpsert({
            model: 'TeradromeDeequTest',
            data: states.deequTest
        });

        await this.saveReferenceDataHistory(states);
    }

    launchThreshModal(thresholdKey: string, states: IViewDeequModalStates) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    sources: states.sources,
                    args: states.deequTest.args,
                    thresholdKey
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            ViewDeequThresholdModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async  (result: any) => {
            if (Object.keys(result).length > 0) {
                states.deequTest.args = result;
            }
        });
    }

    changeTeradromeStageSource(sourceId: string, states: IViewDeequModalStates) {
        // Get source from source id and store as defaultLimitSource
        states.defaultLimitSource = states.sources.find((source: any) => {
            return source.id === sourceId;
        }).sourceName;

        // if lower_limit or upper_limit are populated in args, then all keys but defaultLimitScore should be dropped
        if (states.deequTest.args.lower_limit || states.deequTest.args.upper_limit) {
            states.deequTest.args.lower_limit = {
                [states.defaultLimitSource]: states.deequTest.args.lower_limit ? states.deequTest.args.lower_limit[states.defaultLimitSource] : undefined
            };
            states.deequTest.args.upper_limit = {
                [states.defaultLimitSource]: states.deequTest.args.upper_limit ? states.deequTest.args.upper_limit[states.defaultLimitSource] : undefined
            };
        }
    }

    addAllColumnConstraints(states: IViewDeequModalStates) {
        states.allColumns = !states.allColumns;
        if (states.allColumns) {
            states.deequTest.args.all_columns_arg = 'columnCondition';
            states.deequTest.args.all_columns_boolean = undefined;
            states.deequTest.args.all_columns_exclusions = undefined;
        } else {
            delete states.deequTest.args.all_columns_arg;
            delete states.deequTest.args.all_columns_boolean;
            delete states.deequTest.args.all_columns_exclusions;
        }
        this.cdr.detectChanges();
    }

    updateSatisfiesSQL(states: IViewDeequModalStates) {
        const columns = states.columns.filter((column: any) => {
            if (states.deequTest.args.all_columns_exclusions) {
                return !states.deequTest.args.all_columns_exclusions.includes(column.columnName);
            }
            return true;
        });

        const sqlStrings = columns.map((column: any) => {
            if (states.deequTest.args.columnCondition) {
                return states.deequTest.args.columnCondition.replace(/all_columns/g, column.columnName);
            }
            return '';
        });
        states.allColumnsText = states.deequTest.args.columnCondition && states.deequTest.args.all_columns_boolean ? 'Satisfies SQL: ' + sqlStrings.join(' ' + states.deequTest.args.all_columns_boolean + ' ') : 'Once Column Condition and All Columns Boolean have been specified, example SQL will be in this hover text.';
    }

    async saveReferenceDataHistory(states: IViewDeequModalStates) {
        // Only save a new version if something actually changed.
        if (_.isEqual(states.originalDeequTest, states.deequTest)) {
            return Promise.resolve();
        }

        let version = 0;
        if (states.history && states.history.length > 0) {
            version = _.max(_.map(states.history, 'version')) || 0;
        }
        version++;

        // Not columns in the TeradromeDeequTest model, so delete before saving
        delete states.deequTest.sourceName;
        delete states.deequTest.teradromeColumnId;

        await this.dataAccess.genericUpsert({
            model: 'TeradromeDeequTestHistory',
            data: {
                teradromeDeequTestId: states.deequTest.id,
                version,
                teraUserId: states.teraUserId,
                creationDate: new Date(),
                definition: states.deequTest
            }
        });

    }

    viewDiffsHandler(version: any, states: IViewDeequModalStates) {
        states.showDiffs = true;
        states.compareHistory = [];
        states.currentVersion = ('(v' + version + ')');
        states.previousVersion = '';

        const currentRow: any = _.find(states.history, { version });
        let previousRow: any = {};
        if (currentRow) {
            if (version > 1) {
                const previousVersion = version - 1;
                states.previousVersion = `(v${previousVersion})`;
                previousRow = _.find(states.history, { version: previousVersion });
            }
        }
        states.compareHistory = _.map(_.keys(currentRow.definition), function (key) {
            const previousValue = _.get(previousRow, 'definition.' + key);
            const currentValue = _.get(currentRow, 'definition.' + key);
            return {
                key: _.startCase(key),
                previousValue: ['args', 'teraUser'].includes(key) ? JSON.stringify(previousValue) : previousValue,
                currentValue: ['args', 'teraUser'].includes(key) ? JSON.stringify(currentValue) : currentValue
            };
        });
    }

    async containedInManualUpload(states: IViewDeequModalStates, updateVal: any | undefined) {
        if (states.containedInManualDisabled && states.deequTest.args.allowed_values) {
            states.deequTest.args.allowed_values = [];
        } else if (!states.containedInManualDisabled && updateVal) {
            try {
                const jsonArray = await csv({
                    noheader: true
                }).fromString(updateVal);

                const result = jsonArray.reduce((acc: any, obj: any) => {
                    const values = Object.values(obj);
                    acc.push(...values);
                    return acc;
                }, []);

                states.deequTest.args.allowed_values = result;
            } catch (error) {
                console.error('Error parsing:', error);
            }
        }

        states.containedInManualDisabled = !states.containedInManualDisabled;
    }

    async sendQuestion(states: IViewDeequModalStates, question: string | undefined) {
        if (question) {
            states.messages.unshift({
                message: question,
                userMessage: true
            });

            states.question = undefined;

            this.cdr.detectChanges();
        }

        // Check to see if user is providing a description for the table
        if (!states.teradromeTable.description) {
            const description = question;
            states.teradromeTable.description = description;
            await this.dataAccess.genericUpsert({
                model: 'TeradromeTable',
                data: states.teradromeTable
            });
            question = undefined;
        }

        const column = _.find(states.columns, { id: states.columnId });

        const params: any = {
            user_question: question,
            username: this.argosStore.getItem('username'),
            table_name: states.teradromeTable.tableName,
            table_description: states.teradromeTable.description,
            column_name: column.columnName,
            column_type: column.dataType,
        };
        if (states.sessionId) {
            params['session_id'] = states.sessionId;
        }

        states.fetchingResponse = true;
        const response = await this.dataAccess.genericMethod({
            model: 'ClarifyBot',
            method: 'post',
            parameters: {
                endpoint: 'ai_utils/deequ_tests/query',
                body: params

            }
        });

        states.sessionId = response.session_id;
        const message = (response.detail && response.detail instanceof Array && response.detail[0]?.msg) ? response.detail[0]?.msg : response.llm_response;

        states.messages.unshift({
            message,
            userMessage: false,
        });

        this.cdr.detectChanges();
        states.fetchingResponse = false;
    }

}
