import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { IViewReleaseImpactStates } from './viewReleaseImpact.component.d';
import { ViewReleaseImpactService } from './viewReleaseImpact.component.service';
import { RunDataReleaseServices } from '../runDataRelease/runDataRelease.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { MaterialUtil } from 'client/app/services/materialUtil.service';
import { C } from '@angular/cdk/keycodes';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ViewReleaseImpactEnvironmentModalComponent } from './viewReleaseImpactEnvironmentModal/viewReleaseImpactEnvironmentModal.component';

@Component({
    selector: 'argos-view-release-impact',
    templateUrl: './viewReleaseImpact.component.html',
    styleUrl: './viewReleaseImpact.component.less',
    providers: [ViewReleaseImpactService, RunDataReleaseServices]

})
export class ViewReleaseImpactComponent extends ReactComponentBase<{}, IViewReleaseImpactStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;
    showSpinner = false;

    constructor(private svc: ViewReleaseImpactService, private cdr: ChangeDetectorRef, private runDataServices: RunDataReleaseServices,  private matSvc: MaterialUtil, private matDialog: MatDialog) {
        super({
            releaseId: '',
            filterObj: {},
            tables: [],
            pageSize: 50,
            matTableColumns: ['table', 'ccgName', 'ccgTitle', 'viewEnvironmentsButton'],
            matTableSettingName: 'viewReleaseImpactList',
            viewReleaseImpactTable: new MatTableDataSource([])
        } as unknown as IViewReleaseImpactStates); // Add the necessary parameters here

    }
    ngAfterViewInit() {
        this.states.viewReleaseImpactTable.paginator = this.paginator;
        this.matTbSort.sort(({id: 'ccgName', start: 'desc'}) as MatSortable);
        this.states.viewReleaseImpactTable.sort = this.matTbSort;
    }

    async ngOnInit() {
        this.showSpinner = true;
        this.states.viewReleaseImpactTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);

        await this.svc.initDelegate(this.states);
        this.showSpinner = false;
        this.cdr.detectChanges();
    }
    filterChange(columnName: string, event: any) {
        let eventValue = '';
        if (_.isString(event)){
            eventValue = event.trim().toLowerCase();
        } else {
            eventValue = event.target.value.trim().toLowerCase();
        }
        this.states.filterObj[columnName] = eventValue;
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.viewReleaseImpactTable.filter = JSON.stringify(this.states.filterObj);
    }
    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }
    openEnvironmentsModal(environments: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel-xlarge',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    environments
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ViewReleaseImpactEnvironmentModalComponent, dialogConfig);
    }
}
