import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IUserRoleStates } from './userRoleEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { UserRoleEditService } from './userRoleEdit.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LoginService } from '../../../services/loginService.service';
import { StateService, UIRouter } from '@uirouter/core';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
@Component({
    selector: 'argos-userRoleEdit',
    templateUrl: './userRoleEdit.component.html',
    styleUrls: ['./userRoleEdit.component.less'],
    providers: [UserRoleEditService]
})

export class UserRoleEditComponent extends ReactComponentBase<{}, IUserRoleStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: UserRoleEditService, private loginServiceHandler: LoginService, private state: StateService, 
                private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            activeTab: 1,
            editingMode: false,
            userRole: {},
            users: [],
            usersTable: new MatTableDataSource([]),
            pageSize: 50,
            filterObj: {},
            defaultDisplayMatTableColumns: ['editButton', 'email', 'inactive', 'admin', 'prism', 'prismAdmin', 'ccgDesigner', 'ccgAdmin', 'ecsAccess', 'ecsAdmin', 'environmentManager', 'contractor'],
            matTableColumns: ['editButton', 'email', 'inactive', 'admin', 'prism', 'prismAdmin', 'ccgDesigner', 'ccgAdmin', 'ecsAccess', 'ecsAdmin', 'environmentManager', 'contractor'],
            environmentMatTableSettingName: 'userList',
        } as unknown as IUserRoleStates);
    }

    public keepOriginalOrder = (a: any, b: any) => a.key

    ngAfterViewInit() {
        this.states.usersTable.sort = this.matTbSort;
        this.states.usersTable.paginator = this.paginator;
    }

    async ngOnInit() {

        this.states.usersTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.environmentMatTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();

    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.usersTable.filter = JSON.stringify(this.states.filterObj);
    }

    //pagination functions
    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.environmentMatTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
    }

    rolePermissionChange(menu: any) {
        this.svc.rolePermissionChange(menu, this.states);
    }

    getPropertyErrorMsg(propertyName: string) {
        return this.svc.getPropertyErrorMsg(propertyName, this.states);
    }
    hasPropertyError(propertyName: string) {
        return this.svc.hasPropertyError(propertyName, this.states);
    }

    isSaveDisabled() {
        return this.svc.isSaveDisabled(this.states);
    }

    save() {
        swal({
            text: "Please enter the reason or jira ticket for this change",
            input: 'text',
            confirmButtonText: 'Save',
            showCancelButton: true,
            inputAttributes: {
                style: 'color: black;',
            }
        }).then(async (isConfirm: any) => {
            if (isConfirm.value && isConfirm.value.length > 0) {
                this.states.userRole.notes = isConfirm.value;
                this.svc.save(this.states);
            }
        });
    }

    viewHistory() {
        this.svc.viewHistory(this.states);
    }
}
