// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    width: 100%;
    padding: 5px;
}

.body-container {
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
    padding: 5px;
}

.static-input {
    width: 100%;
    margin-bottom: 2px;
}

.full-width-div {
    width: 100%;
}

.bold-label {
    font-weight: bold;
}

.title-h2 {
    text-align: center;
}

section.default-fields {
    margin-top: 0px;
}

section.optional-fields {
    margin-bottom: 0px;
}

section {
    display: inline-block;
}

.leading-legend {
    margin-top: 0px;
}

.trailing-legend {
    margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/clarifyInsights/clarifyInsightsTriggerExtractModal/clarifyInsightsTriggerExtractModal.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".header-container {\n    width: 100%;\n    padding: 5px;\n}\n\n.body-container {\n    max-height: calc(100vh - 150px);\n    overflow-y: scroll;\n    padding: 5px;\n}\n\n.static-input {\n    width: 100%;\n    margin-bottom: 2px;\n}\n\n.full-width-div {\n    width: 100%;\n}\n\n.bold-label {\n    font-weight: bold;\n}\n\n.title-h2 {\n    text-align: center;\n}\n\nsection.default-fields {\n    margin-top: 0px;\n}\n\nsection.optional-fields {\n    margin-bottom: 0px;\n}\n\nsection {\n    display: inline-block;\n}\n\n.leading-legend {\n    margin-top: 0px;\n}\n\n.trailing-legend {\n    margin-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
