import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { parse } from 'papaparse';
import * as _ from 'lodash';

@Injectable()
export class ChartService {
    constructor() {
        //
    }

    getRandomColor(num?: number) {
        const letters = '0123456789ABCDEF';
        const result = [];

        if (!num) {
            num = Math.floor(Math.random() * 100);
        }

        for (let k = 0; k < num; k++) {
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            result.push(color);
        }
        return result;
    }
}
