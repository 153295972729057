import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ICcgProviderTypesStates } from './ccgProviderTypes.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { CcgProviderTypesService } from './ccgProviderTypes.component.service';

@Component({
    selector: 'argos-ccgProviderTypes',
    templateUrl: './ccgProviderTypes.component.html',
    providers: [CcgProviderTypesService]
})

export class CcgProviderTypesComponent extends ReactComponentBase<{}, ICcgProviderTypesStates, {}> implements OnInit {
    constructor(private svc: CcgProviderTypesService, private cdr: ChangeDetectorRef) {
        super({ newProviderType: {} } as ICcgProviderTypesStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }
    async save() {
        await this.svc.saveHandler(this.states);
        this.cdr.detectChanges();
    }

    addProviderType() {
        this.svc.addProviderTypeHandler(this.states);
    }
}
