import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { IHospitalRatesBulkAddStates } from './hospitalRatesBulkAdd.component.d';
import { HospitalRatesBulkAddService } from './hospitalRatesBulkAdd.component.service';
import { FileService } from 'client/app/services/handleFile.service';
import { StateService } from '@uirouter/core';


@Component({
    selector: 'argos-hospitalRatesBulkAdd',
    templateUrl: './hospitalRatesBulkAdd.component.html',
    providers: [HospitalRatesBulkAddService],
    styleUrls: ['./hospitalRatesBulkAdd.component.less']
})

export class HospitalRatesBulkAddComponent extends ReactComponentBase<{}, IHospitalRatesBulkAddStates, {}> implements OnInit {
    constructor(private svc: HospitalRatesBulkAddService, private cdr: ChangeDetectorRef, private fileHandler: FileService, private state: StateService) {
        super({
            hospitalFields: ['priority', 'ccn', 'npi', 'hospitalName', 'streetAddress', 'city', 'state','zip', 'ratesFileUrl','customers'],
            importFileName: null
        } as unknown as IHospitalRatesBulkAddStates);
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.states);
        return newStates;
    }

    importFile(event: any) {
        this.states.importFileName = event?.target?.files[0].name;
        this.fileHandler.importFileHandler(event, (importFile: string) => {
            this.states.errorHospitals = undefined;
            this.states.newHospitals = undefined;
            this.states.importFile = importFile;
            this.refresh();
        });
    }

    refresh: Function = () => {
        this.cdr.detectChanges();
    };

    setValueNull(event: any) {
        event.target.value = null;
    };

    async uploadFile() {
        const headers = this.states.importFile.split('\n')[0].replace(/\s+/g, ' ').trim().split(',');
        if (JSON.stringify(headers) !== JSON.stringify(this.states.hospitalFields)) {
            alert('The headers in the file do not match the expected headers');
            return;
        }
        const newHospitals = this.fileHandler.uploadFileHandler(this.states.importFile);
        this.states.importFile = undefined;
        this.states.errorHospitals = await this.svc.validateNewHospitals(newHospitals);
        if (!this.states.errorHospitals) {
            this.states.newHospitals = newHospitals;
        }
        this.refresh();
    };

    async uploadAllHospitals(states: IHospitalRatesBulkAddStates) {
        const failedUploads = [];
        const currentLoadSequence = await this.svc.getCurrentLoadSequence();
        for (const hospital of states.newHospitals) {
            const result = await this.svc.uploadHospital(hospital, currentLoadSequence);
            if (result) {
                failedUploads.push(hospital);
            }
        }
        if (failedUploads.length > 0) {
            alert('The following NPIs failed to upload: ' + failedUploads.map((hospital: { npi: any; }) => hospital.npi).join(', '));
            this.state.go('argos.hospitalRates.list');
        }
        else {
            alert('All hospitals uploaded successfully');
            this.state.go('argos.hospitalRates.list');
        }
    };

    downloadHeadersTemplate() {
        this.svc.downloadHeadersTemplate(this.states.hospitalFields);
    };

    returnToDashboard() {
        this.state.go('argos.hospitalRates.list');
    };

}