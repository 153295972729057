import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IEpisodeGroupingImportStates } from './episodeGroupingImport.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EpisodeGroupingImportService } from './episodeGroupingImport.component.service';
import { FileService } from 'client/app/services/handleFile.service';

@Component({
    selector: 'argos-episodeGroupingImport',
    templateUrl: './episodeGroupingImport.component.html',
    providers: [EpisodeGroupingImportService]
})

export class EpisodeGroupingImportComponent extends ReactComponentBase<{}, IEpisodeGroupingImportStates, {}> implements OnInit {
    constructor(private svc: EpisodeGroupingImportService, private cdr: ChangeDetectorRef,
        private fileHandler: FileService) {
        super({
            episodeGroupings: [],
            eventAction: false,
            existingEpisodeGroupings: []
        } as unknown as IEpisodeGroupingImportStates);
    }

    async ngOnInit() {
        const newStates = await this.svc.initDelegate(this.states);
        this.setStates(newStates);
    }

    importFile(event: any) {
        this.fileHandler.importFileHandler(event, (importFile: string) => {
            this.states.importFile = importFile;
            this.refresh();
        });
    }

    refresh: Function = () => {
        this.cdr.detectChanges();
    };

    uploadFile() {
        this.states.episodeGroupings = this.fileHandler.uploadFileHandler(this.states.importFile);
        this.states.importFile = undefined;
        const episodeGroupings = this.svc.validateEpisodeGroupings(this.states);
        this.setStates({ episodeGroupings });
    }

    copyCSVHeaders() {
        this.svc.copyCSVHeadersHandler(this.states);
    }

    async importValidEpisodeGroups() {
        await this.svc.importValidEpisodeGroupsHandler(this.states, this.refresh);
    }

    async saveEpisodeGrouping(episodeGrouping: any) {
        episodeGrouping.imported = true;
        await this.svc.saveEpisodeGrouping(episodeGrouping, this.states);
    }

    getFieldErrorMessage(row: any) {
        return this.svc.getFieldErrorMessage(row, this.states);
    }

    copyErrorToClipboard(eg: any) {
        this.svc.copyErrorToClipboardHandler(eg, this.states);
    }

    errorExists(row: any, propertyName: any) {
        let result;
        if (row.propertyErrors) {
            result = row.propertyErrors.map(function (i: any) { return i.field; }).indexOf(propertyName) > -1;
        }
        return result;
    }

    setValueNull(event: any) {
        event.target.value = null;
    }
}
