import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IViewAiChatModalProps, IViewAiChatModalStates } from './viewAiChatModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewAiChatModalService } from './viewAiChatModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationService } from '../../../services/validationService.service';
import swal from 'sweetalert2';


@Component({
    selector: 'argos-viewAiChatModal',
    templateUrl: './viewAiChatModal.component.html',
    providers: [ViewAiChatModalService]
})

export class ViewAiChatModalComponent
    extends ReactComponentBase<IViewAiChatModalProps, IViewAiChatModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewAiChatModalService, private validationSrv: ValidationService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewAiChatModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        if (this.states.fetchingResponse) {
            swal({
                title: 'Are you sure?',
                text: 'It looks like you are awaiting a response in your chat. You will lose your chat history if you close.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result: any) => {
                if (result.value) {
                    this.dialogRef.close({});
                }
            });
        } else {
            this.dialogRef.close({});
        } 
    }

    sendQuestion(question: string) {
        this.svc.sendQuestion(this.states, question);
    }

    reload() {
        this.dialogRef.close({reload: true});
    }

}
