import { Injectable } from '@angular/core';
import { IApiPerformanceTestResultsDashboardProductsDetailModalProps, IApiPerformanceTestResultsDashboardProductsDetailModalStates, IApiPerformanceTestResultsDashboardProductsDetailModalService } from './apiPerformanceTestResultsProductDetailModal.component.d';
import * as _ from 'lodash';

@Injectable()
export class ApiPerformanceTestResultsDashboardProductsDetailModalService implements IApiPerformanceTestResultsDashboardProductsDetailModalService {
    

    async initDelegate(props: IApiPerformanceTestResultsDashboardProductsDetailModalProps, states: IApiPerformanceTestResultsDashboardProductsDetailModalStates): Promise<object> {
        states.apiPerformanceTestResults = props.apiPerformanceTestResults || {};
  
        const resultsGroupedByPageName = _(props.apiPerformanceTestResults)
            .map((obj: any) => ({ ...obj, test_ran_at: obj.test_ran_at.split('T')[0] }))
            .orderBy(['submodule_name', 'dashboard_name', 'tab_name', 'product_name', 'test_ran_at'], ['asc', 'asc', 'desc', 'asc'])
            .groupBy((item) => [item['submodule_name'], item['dashboard_name'], item['tab_name']])
            .value();

        const apiColumnKeys = _(props.apiPerformanceTestResults)
            .map((obj: any) => ({ ...obj, test_ran_at: obj.test_ran_at.split('T')[0] }))
            .orderBy(['product_name', 'test_ran_at'], ['desc', 'asc'])
            .groupBy('test_ran_at')
            .value();

        const structuredData: object[] = [];

        const releaseKeys: string[] = Object.keys(resultsGroupedByPageName);

        for (let i = 0; i < releaseKeys.length; i++) {
            const releaseData = _.orderBy(resultsGroupedByPageName[releaseKeys[i]], ['dashboard_name']);
            let currentPageCachedData: any;
           
            for (let j = 0; j < releaseData.length; j++) {
                const releaseKey = releaseData[j].test_ran_at;
                if (j === 0) {
                    currentPageCachedData = {
                        submodule: releaseData[j].submodule_name,
                        dashboard: releaseData[j].dashboard_name,
                        tab: releaseData[j].tab_name,
                        [releaseKey]: releaseData[j].cached_load_time,
                        change: 'none',
                    };
                } else {
                    const prevTime = parseFloat(releaseData[j - 1].cached_load_time);
                    const currentTime = parseFloat(releaseData[j].cached_load_time);

                    if (currentTime > prevTime) {
                        currentPageCachedData[releaseKey] = releaseData[j].cached_load_time;
                        currentPageCachedData.change = 'increased';
                    } else if (currentTime < prevTime) {
                        currentPageCachedData[releaseKey] = releaseData[j].cached_load_time;
                        currentPageCachedData.change = 'decreased';
                    }
                }
            }

            structuredData.push(currentPageCachedData);
            
        }
        states.apiPerformanceTestModalDataTable.data = structuredData;
        states.apiDisplayedColumns = ['submodule', 'dashboard', 'tab', ...(Object.keys(apiColumnKeys))];
        return {};
    }

    async fetchResults(newProps: IApiPerformanceTestResultsDashboardProductsDetailModalProps, states: IApiPerformanceTestResultsDashboardProductsDetailModalStates) {
        this.initDelegate(newProps, states);
        return {};
       
    }
}

