import { IMatTableSettingsModelProps, IMatTableSettingsModelStates, IMatTableSettingsModelService } from './matTableSettingsModel.component.d';
import { Component, OnChanges, SimpleChanges, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core'; 
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { MatTableSettingsModelService } from './matTableSettingsModel.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; 
import { MatSort, Sort } from '@angular/material/sort';  
import { MaterialUtil } from '../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-MatTableSettingsModel',
    templateUrl: './matTableSettingsModel.component.html',
    providers: [MatTableSettingsModelService]
})

export class MatTableSettingsModelComponent
extends ReactComponentBase<IMatTableSettingsModelProps, IMatTableSettingsModelStates, {}> implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('matTbSort') matTbSort = new MatSort();
  
    constructor(private svc: MatTableSettingsModelService, @Inject(MAT_DIALOG_DATA) dialogData: any, private matSvc: MaterialUtil, 
        private dialogRef: MatDialogRef<MatTableSettingsModelComponent>) {
        super({  
            tableColumns: [],
            hiddenTableColumns: [],
            readOnlyTableColumns: [],
            defaultDisplayTableColumns: [],
            filterObj: {},
            tableColumnsMatTable: new MatTableDataSource([]),
            tableColumnsMatData: null,
            tableColumnsMatColumns: ['columnName'],
            defaultPageSize: 50,
            settingName: ''
        } as IMatTableSettingsModelStates);
        this.props = dialogData;
    }

    ngOnInit() {
        this.states.tableColumnsMatTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.svc.initDelegate(this.props, this.states);
    }

    ngAfterViewInit() { 
        this.states.tableColumnsMatTable.sort = this.matTbSort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.tableColumnsMatTable.filter = JSON.stringify(this.states.filterObj);
    }

    updateHiddenTableColumns(columnName: string, e: any) {
        this.states.hiddenTableColumns = _.map(_.filter(this.states.tableColumnsMatData, (col: any) => {
            return col.isHidden;
        }), (col: any) => {
            return col.columnName;
        });
    }

    closeModal() {
        this.dialogRef.close();
    };

    save() {
        this.svc.save(this.states);
        this.dialogRef.close();
    }
}
