
import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { HospitalRatesLandingServices } from './hospitalRatesLandingPage.component.service';
import { IHospitalRatesLandingStates } from '../hospitalRatesLandingPage/hospitalRatesLandingPage.component.d';
import { HospitalRatesAddHospitalModalComponent } from '../hospitalRatesAddHospitalModal/hospitalRatesAddHospitalModal.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSort} from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from 'client/app/services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-hospital-rates-landing-page-list',
    templateUrl: './hospitalRatesLandingPage.component.html',
    styleUrls: ['./hospitalRatesLandingPage.component.css'],
    providers: [HospitalRatesLandingServices]
})

export class HospitalRatesLandingComponent extends ReactComponentBase<{}, IHospitalRatesLandingStates, {}>
    implements OnInit, AfterViewInit {
        @ViewChild('matTbSort') matTbSort = new MatSort();
        @ViewChild('paginator') paginator: MatPaginator;

    constructor(private svc: HospitalRatesLandingServices, private cdr: ChangeDetectorRef, private matDialog: MatDialog, private matSvc: MaterialUtil) {
        super({
            allHospitals: new MatTableDataSource([]),
            pageSize: 100,
            filterObj: {},
            columnsToDisplay: ['editButton', 'priority', 'id','hospitalName', 'npi', 'prvdrNum', 'streetAddressLine1', 'city', 'state', 'zipCode', 'ratesFileUrl', 'inputFileStatus', 'ratesFilesSearchStatus', 'nonSsFilesProcessed', 'inputFileProcessingStartedAt', 'inputFileProcessingCompletedAt', 'customers', 'updatedBy'],
            filterColumns: ['priority', 'id', 'hospitalName', 'npi', 'prvdrNum', 'streetAddressLine1', 'city', 'state', 'zipCode', 'ratesFileUrl', 'ratesFilesSearchStatus'],
            fixNullColumns: ['npi', 'prvdrNum', 'zipCode', 'ratesFileUrl', 'ratesFilesSearchStatus']
        }
        );
    };

    async ngOnInit() {
        this.states.allHospitals.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    };

    ngAfterViewInit() {
        this.states.allHospitals.paginator = this.paginator;
        this.states.allHospitals.sort = this.matTbSort;
    };

    addHospital() {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {}
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(HospitalRatesAddHospitalModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(() => {
            this.ngOnInit()
            this.ngAfterViewInit()
        });
    }

    editHospital(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: { 
                props: {
                    row,
                    isEdit: true
                }
            }
        }
        const modalInstance: MatDialogRef<any> = this.matDialog.open(HospitalRatesAddHospitalModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(() => {
            this.ngOnInit();
            this.ngAfterViewInit();
        });
    }

    filterChange(columnName: string, event: any) {
        const stringValue = event.target.value.toString();
        this.states.filterObj[columnName] = stringValue.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.allHospitals.filter = JSON.stringify(this.states.filterObj);
    }

    bulkExportToExcel() {
        this.svc.bulkExportToExcel(this.states.allHospitals.data, this.states.columnsToDisplay);
    }

    clearFilters() {
        for (const column of this.states.filterColumns) {
            this.filterChange(column, { target: { value: '' } });
        };
        this.cdr.detectChanges();
    }

};
