import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IClarifyProviderRevisionEditStates } from './clarifyProviderRevisionEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ClarifyProviderRevisionEditService } from './clarifyProviderRevisionEdit.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';


@Component({
    selector: 'argos-clarifyProviderRevisionEdit',
    templateUrl: './clarifyProviderRevisionEdit.component.html',
    providers: [ClarifyProviderRevisionEditService]
})


export class ClarifyProviderRevisionEditComponent extends ReactComponentBase<{}, IClarifyProviderRevisionEditStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: ClarifyProviderRevisionEditService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            saveInProgress: false,
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['column', 'originalValue', 'revisedValue'],
            matTableColumns: ['column', 'originalValue', 'revisedValue'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'clarifyProviderRevisionEdit',
            dataLoaded: false,
            clarifyProviderVersion: 'reference_data_staging',
            currentRevisionGrainSelection: 'npi'
        } as unknown as IClarifyProviderRevisionEditStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.svc.save(this.states);
    }

    isSaveDisabled() {
        const dataSourceToApplyFirst = _.filter(this.states.attributeData, (o) => o.column === 'dataSourcesToApply')[0]
        if(dataSourceToApplyFirst){
            const dataSourcesToApply = dataSourceToApplyFirst.revisedValue;
            const dataSourcesToApplyDisabled = dataSourcesToApply.length === 0 || (dataSourcesToApply.length > 1 && dataSourcesToApply.includes('all'));
            this.states.saveDisabledMessage = dataSourcesToApply.length === 0 ? 'Please select a data source to apply.' : dataSourcesToApplyDisabled ? 'If applying to all data sources, cannot include additional data sources.' : '';
            return _.isEqual(this.states.originalAttributeData, this.states.attributeData) || this.states.saveInProgress || dataSourcesToApplyDisabled;
        }
        return true
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    deleteRevision() {
        this.svc.deleteRevision(this.states);
    }

    changeClarifyProviderVersion() {
        this.svc.changeClarifyProviderVersion(this.states);
    }

    updateClarifyProviderVersions() {
        this.svc.updateClarifyProviderVersions(this.states);
    }
}
