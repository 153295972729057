import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IClientEditStates } from './clientEdit.component.d';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';
import { ClientEditService } from './clientEdit.component.service';
import { LoginService } from 'client/app/services/loginService.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-clientEdit',
    templateUrl: './clientEdit.component.html',
    providers: [ClientEditService]
})

export class ClientEditComponent extends ReactComponentBase<{}, IClientEditStates, {}> implements OnInit {
    constructor(private svc: ClientEditService, private loginService: LoginService,
        private cdr: ChangeDetectorRef) {
        super({
            client: {},
            clientform: {},
            clientformNameExists: false,
            assignedOwnerEmailOptions: [],
            selectedAssignedOwnerId: '',
            dropdownAssignedOwnerEmailsSettings: {
                singleSelection: false,
                idField: 'id',
                textField: 'email',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 5,
                allowSearchFilter: true
            },
            includeProspects: false
        } as unknown as IClientEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    clientNameChange() {
        this.svc.clientNameChange(this.states);
    }

    internalNameChange() {
        this.svc.internalNameChange(this.states);
    }

    addEditDataSource() {
        this.svc.addEditDataSource(this.states);
    }

    async deleteDataSource(cds: any) {
        await this.svc.deleteDataSource(cds, this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.svc.save(this.states);
    }

    getSalesforceAccounts() {
        let result: any[] = [];

        if (this.states.salesforceAccounts && this.states.salesforceAccounts.length > 0) {
            result = _.filter(this.states.salesforceAccounts, (item) => { 
                let show = (this.states.includeProspects) || (!this.states.includeProspects && item.type !== 'Prospect')
                return show;
            });            
        }

        return result;
    }


    addEnvironment() {
        this.svc.addEnvironment(this.states);
    }

    getProductConfiguration(idOrName: any): any {
        return this.svc.getProductConfiguration(idOrName, this.states);
    }

    async addEditProductConfiguraton(id: any) {
        await this.svc.addEditProductConfiguraton(id, this.states);
    }

    async deleteProductConfiguration(clientProductConfiguration: any) {
        await this.svc.deleteProductConfiguration(clientProductConfiguration, this.states);
        this.cdr.detectChanges();
    }

    selectTab(event: any) {
        this.states.activeTab = event.index;
    }

    changeDataSource() {
        const newAssignee = _.find(this.states.assignedOwnerEmailOptions, { id: this.states.selectedAssignedOwnerId });
        this.states.client.assignedOwnerEmail = _.cloneDeep(newAssignee);
    }

    changeSalesforceAccount() {
        const newSalesforceAccount = _.find(this.states.salesforceAccounts, { salesforceId: this.states.client?.salesforceAccountId });
        if (newSalesforceAccount) {
            this.states.client.organizationType = newSalesforceAccount.segmentType;
        }
    }
}
