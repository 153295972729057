import { Injectable } from '@angular/core';
import { IPipelineQueueStates, IPipelineQueueService } from './pipelineQueue.component.d';
import { NgDataAccess } from '../../services/dataAccess.service';
import { ArgosStoreService } from '../../services/argosStore.service';

import * as _ from 'lodash';
@Injectable()
export class PipelineQueueService implements IPipelineQueueService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService) {
        //
    }
    async initDelegate(states: IPipelineQueueStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IPipelineQueueStates) {
        states.pipelineQueue = await this.dataAccess.genericFind({
            model: 'PipelineQueueDetail',
            filter: { order: 'queueOrder asc' }
        });
        states.toBeRemoved = [];
    }


    async changeDelegate(oldProps: IPipelineQueueStates, newProps: IPipelineQueueStates, states: IPipelineQueueStates) {
        await this.init(states);
    }

    async addHandler(states: IPipelineQueueStates) {
        try {
            const currentPipelineQueue = await this.dataAccess.genericFind({
                model: 'PipelineQueueDetail'
            });
            let maxOrder = 0;
            if (currentPipelineQueue.length > 0) {
                maxOrder = Math.max(...currentPipelineQueue.map((item: { queueOrder: any; }) => item.queueOrder));
            }
            let executeDate = states.executeDate ? new Date(states.executeDate): null;
            const queueData = {
                dagName: states.dagId,
                queueOrder: maxOrder + 1,
                config: states.config,
                executeDate: executeDate,
                createdAt: new Date(),
                createdBy: this.argosStore.getItem('username')
            };
            const resp = await this.dataAccess.genericCreate({
                model: 'PipelineQueueDetail',
                data: queueData
            });
            if (resp) {
                const auditData = {
                    dagId: states.dagId,
                    sourceId: resp.id,
                    action: 'add',
                    createdBy: this.argosStore.getItem('username'),
                    createdAt: resp.createdAt
                };
                const auditResp = await this.dataAccess.genericCreate({
                    model: 'PipelineQueueAudit',
                    data: auditData
                });
                states.pipelineQueue = currentPipelineQueue.concat([resp]); // Update pipelineQueue
                states.toBeRemoved = [];
                states.dagId = ''; // Clear dagId
            }
        } catch (error) {
            console.error("Error occurred during addHandler:", error);
            // Handle the error appropriately, such as logging or throwing further
        }
    }
    

    async saveHandler(states: IPipelineQueueStates) {
        const currentPipelineQueue = states.pipelineQueue;
        const removedPipelines = states.toBeRemoved;
        if (removedPipelines.length > 0) {
            for (const rp of removedPipelines) {
                let existingRecord = await this.dataAccess.genericMethod({
                    model: 'PipelineQueueDetail', method: 'findOne',
                    parameters: {
                        filter: {
                            where:  { dagName: rp.dagName, queueOrder: rp.queueOrder}
                        }
                    }
                });
                const rep = await this.dataAccess.genericDelete({
                    model: 'PipelineQueueDetail',
                    id: existingRecord.id
                });
                const auditData = {
                    dagId: rp.dagName,
                    sourceId: existingRecord.id,
                    action: 'remove',
                    createdAt: new Date(),
                    createdBy: this.argosStore.getItem('username')
                };
                const auditResp = await this.dataAccess.genericCreate({
                    model: 'PipelineQueueAudit',
                    data: auditData
                });
            }
            states.pipelineQueue = currentPipelineQueue;
            states.toBeRemoved = [];
            states.dagId = '';
        }
        if (states.changes.length > 0) {
            const existingPipelines = await this.dataAccess.genericFind({
                model: 'PipelineQueueDetail'
            });

            for (let c = 0; c < states.changes.length; c++) {
                const ep = _.find(existingPipelines, { dagName: states.changes[c].dag_info.dagName, queueOrder: states.changes[c].dag_info.queueOrder });
                const data = {
                    id: states.changes[c].dag_info.id,
                    dagName: states.changes[c].dag_info.dagName,
                    queueOrder: states.changes[c].new_index,
                    config: states.changes[c].dag_info.config,
                    executeDate: states.changes[c].dag_info.executeDate
                };
                const resp = await this.dataAccess.genericUpsert({
                    model: 'PipelineQueueDetail',
                    data
                });
                const auditData = {
                    dagId: states.changes[c].dag_info.dagName,
                    changeInfo: `from ${states.changes[c].previous_index} to ${states.changes[c].new_index}`,
                    sourceId: states.changes[c].dag_info.id,
                    action: 'updated',
                    createdBy: this.argosStore.getItem('username'),
                    createdAt: new Date()
                };
                const auditResp = await this.dataAccess.genericCreate({
                    model: 'PipelineQueueAudit',
                    data: auditData
                });
                states.changes.splice(c, 1);
                c--; // decrement c because splice changes the array length and shifts indexes
            }            

        }
    }

    async getAuditHandler(states: IPipelineQueueStates) {
        const auditResults = await this.dataAccess.genericFind({
            model: 'PipelineQueueAudit',
            filter: { order: 'createdAt desc', limit: 100 }
        });
        for (const ar of auditResults) {
            if (ar.action === 'add') {
                ar.changeInfo = `added to queue`;
            } else if (ar.action === 'remove') {
                ar.changeInfo = 'removed from queue';
            } else if (ar.action === 'updated') {
                ar.changeInfo = `queue order updated from ${ar.changeInfo}`;
            } else if (ar.action === 'executed') {
                ar.changeInfo = 'executed by airflow';
            }
        }
        states.pipelineQueueAudit = auditResults;
    }

    async getDags(filter:string= '') {
        let dagConfig:any = {
            model: 'Astronomer',
            method: 'getDagsByPattern',
        };
        if (filter) {
            dagConfig['parameters'] = {
                pattern: filter
            }
        }   
        const dags = await this.dataAccess.genericMethod(dagConfig);
        const dagList = _.map(dags.data, 'dag_id')
        return _.filter(dagList, (dag: string) => !dag.startsWith('deployment') && !dag.startsWith('pseudo'));
    }
}
