import { Injectable } from '@angular/core';
import { IUserDbLoginSetupProps, IUserDbLoginSetupStates, IUserDbLoginSetupService } from './userDbLoginSetup.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class UserDbLoginSetupService implements IUserDbLoginSetupService {
    constructor(private dataAccess: NgDataAccess) { 
    }
    initDelegate(props: IUserDbLoginSetupProps, states: IUserDbLoginSetupStates): Object {
        states.argosUser = props.argosUser;
        let selectedDbTables: any[] = states.argosUser.allowedDbTables;
        
        states.selectedDbTables = _.map(selectedDbTables, obj => {
            return { ...obj, selected: false };
        });

        states.selectedDbTablesMatTable.data = states.selectedDbTables;
        return {};
    }

    changeDelegate(oldProps: IUserDbLoginSetupProps, newProps: IUserDbLoginSetupProps, states: IUserDbLoginSetupStates): Object {
        this.initDelegate(newProps, states);
        return {};
    }

    async create(states: IUserDbLoginSetupStates) {
        let result = await this.dataAccess.genericMethod({
                model: 'ArgosUser', method: 'createPostgresUsers', parameters: {
                argosUserId: states.argosUser.id,
                tables: _.filter(states.selectedDbTables, { selected: true })
            }
        });
        return result;
    }
}
