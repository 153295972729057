import { Injectable } from '@angular/core';
import { IModelMetaDataEditStates, IModelMetaDataEditService } from './modelMetaDataEdit.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { StateService, UIRouter } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DependentVariableMetadataSqlFilterComponent } from '../dependentVariableMetadataSqlFilter/dependentVariableMetadataSqlFilter.component';
@Injectable()
export class ModelMetaDataEditService implements IModelMetaDataEditService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService,
        private uiRouter: UIRouter, private matDialog: MatDialog,
        private state: StateService) {
        //
    }

    async initDelegate(states: IModelMetaDataEditStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IModelMetaDataEditStates) {
        if (this.uiRouter.globals.params.id) {
            try {
                const data = await this.dataAccess.genericMethod({
                    model: 'ModelMetaData', method: 'findById',
                    parameters: {
                        id: this.uiRouter.globals.params.id,
                        filter: { include: ['dependentVariableMetadata'] }
                    }
                });
                states.modelMetaDataObject = data;
                states.modelMetaDataObject.isValidToSave = true;
                states.modelMetaDataObject.propertyErrors = [];
            } catch (error) {
                console.log('error');
            }
        } else {
            states.modelMetaDataObject.createdBy = this.argosStore.getItem('username');
        }
    }

    addDependentVariableMetaDataSqlFilterOptionHandler(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    filters: row.sqlFilters
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(
            DependentVariableMetadataSqlFilterComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result && result.sqlFilters) {
                    row.sqlFilters = result.sqlFilters;
                }
                console.log('modalInstance result ' + JSON.stringify(result));
            }
        });
    }

    addDependentVariableMetaDataHandler(states: IModelMetaDataEditStates) {
        if (!states.modelMetaDataObject.dependentVariableMetadata) {
            states.modelMetaDataObject.dependentVariableMetadata = [];
        }
        states.modelMetaDataObject.dependentVariableMetadata.push({
            target: null,
            columnPrefix: null,
            modelType: 'rf',
            attributesAre1: [],
            attributesAre0: [],
            sqlFilters: []
        });
    }

    async saveDependentVariableMetaData(states: IModelMetaDataEditStates) {
        const savePromises: any = [];
        const dataAccess = this.dataAccess;
        if (states.modelMetaDataObject.dependentVariableMetadata) {
            states.modelMetaDataObject.dependentVariableMetadata.forEach(function (dependent: any) {
                dependent.modelMetadataId = states.modelMetaDataObject.id;
                const careGroupingHistor = dataAccess.genericUpsert({
                    model: 'DependentVariableMetadata',
                    data: dependent
                });
                savePromises.push(careGroupingHistor);
            });
        }

        await Promise.all(savePromises);
    }

    removeDependentVariableMetaDataHandler(row: any, index: number, states: IModelMetaDataEditStates) {
        if (row.id) {
            states.deleteDependentVariableMetaData.push(row.id);
        }

        states.modelMetaDataObject.dependentVariableMetadata.splice(index, 1);
    }

    async deleteDependentVariable(states: IModelMetaDataEditStates) {
        const dataAccess = this.dataAccess;
        return Promise.all(_.union(_.map(states.deleteDependentVariableMetaData, function (id) {
            return dataAccess.genericMethod({
                model: 'DependentVariableMetadata', method: 'destroyById', parameters: {
                    id
                }
            });
        })));
    }

    async saveHandler(states: IModelMetaDataEditStates) {
        // if(states.modelMetaDataObject.dependentVariableMetadata && states.modelMetaDataObject.dependentVariableMetadata.sqlFilterOptions) {
        // delete states.modelMetaDataObject.dependentVariableMetadata.sqlFilterOptions;
        // }
        try {
            const data = states.modelMetaDataObject;
            const savedObj: any = this.dataAccess.genericUpsert({
                model: 'ModelMetaData',
                data
            });
            console.log('savedObj ' + JSON.stringify(savedObj));
            states.modelMetaDataObject.id = savedObj.id;
            await this.deleteDependentVariable(states);
            await this.saveDependentVariableMetaData(states);
            this.state.go('argos.ccgDesigner.modelMetaData.list');
        } catch (error) {
            // states.disableField = false;
            console.log('error ' + JSON.stringify(error));
        }
    }

    getErrorInDependentModelHandler(row: any) {
        let result = '';
        if (row.propertyErrors) {
            result = row.propertyErrors.map(function (err: any) { return err.message; }).join('\n');
        }
        return result;
    }

    validateListInList(modelDataObj: any,
        propertyName: any,
        validList: any,
        canBeEmpty: any, states: IModelMetaDataEditStates) {

        const valueList = modelDataObj[propertyName];

        if (valueList && valueList.toString().trim().length > 0) {
            const values = valueList.toString().split(',');

            if (values) {
                values.forEach(function (v: any) {
                    if (validList.indexOf(v) === -1) {
                        modelDataObj.isValidToSave = false;
                        modelDataObj.propertyErrors.push({
                            field: propertyName,
                            message: v + ' does not exists in valid list. valid values are ' + validList.join(', ')
                        });
                    }
                });
            }
        } else if (!canBeEmpty) {
            modelDataObj.isValidToSave = false;
            modelDataObj.propertyErrors.push({
                field: propertyName,
                message: propertyName + ' cannot be empty. valid values are ' + validList.join(', ')
            });
        }
    }

    validateAllRequiredFields(obj: any, requiredProperties: any, states: IModelMetaDataEditStates) {

        for (const key in obj) {
            if (requiredProperties.indexOf(key) > -1 && (obj[key] === null || obj[key].toString().trim().length === 0)) {
                obj.isValidToSave = false;
                obj.propertyErrors.push({
                    field: key,
                    message: key + ' is a required field'
                });
            }
        }
    }

    validateValueInList(modelDataObj: any,
        propertyName: any,
        validValues: any, states: IModelMetaDataEditStates) {

        const propertyValue = modelDataObj[propertyName];

        if (propertyValue && propertyValue.toString().trim().length > 0 && validValues.indexOf(propertyValue) > -1) {
            return;
        } else {
            modelDataObj.isValidToSave = false;
            modelDataObj.propertyErrors.push({
                field: propertyName,
                message: propertyName + ' is empty or an invalid value. valid values are ' + validValues.join(', ')
            });
        }
    }

    validateValueExistsInList(modelDataObj: any,
        propertyName: any,
        propertyValueToSearch: any,
        validValues: any, states: IModelMetaDataEditStates) {

        if (propertyValueToSearch && propertyValueToSearch.toString().trim().length > 0 && validValues.indexOf(propertyValueToSearch) > -1) {
            return;
        } else {
            modelDataObj.isValidToSave = false;
            modelDataObj.propertyErrors.push({
                field: propertyName,
                message: propertyName + ' is empty or an invalid value missing in list. valid value missing ' + propertyValueToSearch
            });
        }
    }

    validateDependantData(states: IModelMetaDataEditStates) {
        if (states.modelMetaDataObject.dependentVariableMetadata) {
            // console.log('states.modelMetaDataObject.dependentVariableMetadata ' + JSON.stringify(states.modelMetaDataObject.dependentVariableMetadata))
            states.modelMetaDataObject.dependentVariableMetadata.forEach((dependent: any) => {
                dependent.propertyErrors = [];
                this.validateAllRequiredFields(dependent, ['target', 'columnPrefix'], states);

                const filterString = (dependent.target ? dependent.target : 'targetvalue') + ' >= 0';
                this.validateValueExistsInList(dependent, 'sqlFilters', filterString, dependent.sqlFilters, states);

                this.validateListInList(dependent, 'attributesAre1', states.attributesAreOptions, false, states);
                this.validateListInList(dependent, 'attributesAre0', states.attributesAreOptions, false, states);

                if (dependent.propertyErrors.length > 0) {
                    states.modelMetaDataObject.isValidToSave = false;
                }
            });
        }
    }

    isValidHandler(states: IModelMetaDataEditStates) {
        states.modelMetaDataObject.isValidToSave = true;
        states.modelMetaDataObject.propertyErrors = [];

        this.validateValueInList(states.modelMetaDataObject, 'trainingSource', states.trainingSourceOptions, states);
        this.validateValueInList(states.modelMetaDataObject, 'predictionSource', states.predictionSourceOptions, states);
        this.validateValueInList(states.modelMetaDataObject, 'ccgType', states.ccgTypeOptions, states);
        this.validateValueInList(states.modelMetaDataObject, 'predictionType', states.predictionTypeOptions, states);
        this.validateValueInList(states.modelMetaDataObject, 'features', states.featureOptions, states);
        this.validateValueInList(states.modelMetaDataObject, 'episodeCcgs', states.episodeCcgsOptions, states);
        this.validateValueInList(states.modelMetaDataObject, 'ccgName', states.ccgNameOptions, states);

        this.validateListInList(states.modelMetaDataObject, 'trainYears', states.trainYearOptions, false, states);
        this.validateListInList(states.modelMetaDataObject, 'predictYears', states.predictYearOptions, false, states);
        this.validateListInList(states.modelMetaDataObject, 'additionalFeatures', states.additionalFeatureOptions, false, states);

        this.validateDependantData(states);

        // console.log('is valid object ' + JSON.stringify(states.modelMetaDataObject));
        // console.log('is valid property errors ' + JSON.stringify(states.modelMetaDataObject.propertyErrors));
        // console.log('is valid ' + JSON.stringify(states.modelMetaDataObject.isValidToSave));

        return states.modelMetaDataObject.isValidToSave;
    }

}
