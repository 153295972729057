import { Injectable } from '@angular/core';
import { IMigrateCareGroupingModalProps, IMigrateCareGroupingModalStates, IMigrateCareGroupingModalService } from './migrateCareGroupingModal.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { ArgosStoreService } from '../../../services/argosStore.service';
import * as _ from 'lodash';
import { AlertService } from 'client/app/services/alert.service';

@Injectable()
export class MigrateCareGroupingModalService implements IMigrateCareGroupingModalService {
    constructor(private dataAccess: NgDataAccess, private argosStore: ArgosStoreService,
        private alertSvc: AlertService) {
        //
    }
    async initDelegate(props: IMigrateCareGroupingModalProps, states: IMigrateCareGroupingModalStates): Promise<object> {
        states.oldClientCareGrouping = props.oldClientCareGrouping;
        await this.init(states);
        return {};
    }

    async changeDelegate(oldProps: IMigrateCareGroupingModalProps, newProps: IMigrateCareGroupingModalProps, states: IMigrateCareGroupingModalStates): Promise<object> {
        await this.initDelegate(newProps, states);
        return {};
    }

    async init(states: IMigrateCareGroupingModalStates) {
        const careGroupings = await this.dataAccess.genericFind({
            model: 'CareGrouping',
            filter: { order: 'title' }
        });
        states.careGroupings = careGroupings;

        states.careGroupingSettings = [];
        for (const override in states.oldClientCareGrouping.overrides) {
            states.careGroupingSettings.push({
                name: override,
                previousValue: states.oldClientCareGrouping.overrides[override]
            });
        }
    }

    async saveHandler(states: IMigrateCareGroupingModalStates) {
        states.oldClientCareGrouping.overrides.migrateFromCareGrouping = states.oldClientCareGrouping.careGrouping;
        states.oldClientCareGrouping.careGrouping = states.newCareGrouping;
        states.oldClientCareGrouping.title = states.newTitle;
        states.oldClientCareGrouping.lastUpdatedBy = this.argosStore.getItem('username');
        states.oldClientCareGrouping.lastUpdateDate = new Date();
        _.forEach(states.careGroupingSettings, function (setting) {
            if (setting.retain) {
                if (setting.json) {
                    states.oldClientCareGrouping.overrides[setting.name] = JSON.parse(setting.override);
                } else {
                    states.oldClientCareGrouping.overrides[setting.name] = setting.override;
                }
            } else {
                delete states.oldClientCareGrouping.overrides[setting.name];
            }
        });
        try {
            const clientCareGrouping = await this.dataAccess.genericUpsert({
                model: 'ClientCareGrouping',
                data: states.oldClientCareGrouping
            });
            await this.saveClientCareGroupingHistory(clientCareGrouping, states);
        } catch (error) {
            this.alertSvc.handleError(error);
        }
    }

    careGroupingChangedHandler(states: IMigrateCareGroupingModalStates) {
        states.newTitle = states.oldClientCareGrouping.title;
        const newCareGrouping = _.find(states.careGroupings, { name: states.newCareGrouping });
        if (newCareGrouping) {
            _.forEach(states.careGroupingSettings, function (setting) {
                const newValue = _.get(newCareGrouping, ['settings', setting.name]);
                setting.newValue = newValue;
                if (setting.newValue !== setting.previousValue && setting.name !== 'programModels') {
                    if (_.isObject(setting.previousValue)) {
                        setting.override = JSON.stringify(setting.previousValue);
                        setting.json = true;
                    } else {
                        setting.override = setting.previousValue;
                    }
                    setting.retain = true;
                }
            });
        }
    }

    findCareGroupingHandler(states: IMigrateCareGroupingModalStates) {
        let newCareGrouping;
        _.forEach(states.careGroupings, function (careGrouping) {
            if (careGrouping.name !== _.get(states.oldClientCareGrouping, 'careGrouping') && _.get(careGrouping, 'settings.baseTable') === _.get(states.oldClientCareGrouping, 'overrides.baseTable')) {
                newCareGrouping = careGrouping.name;
            }
        });
        states.newCareGrouping = newCareGrouping;
        this.careGroupingChangedHandler(states);
    }

    async saveClientCareGroupingHistory(clientCareGrouping: any, states: IMigrateCareGroupingModalStates) {

        let version = 0;
        const username = this.argosStore.getItem('username');
        const histories = await this.dataAccess.genericFind({
            model: 'ClientCareGroupingHistory',
            filter: {
                where: {
                    clientCareGroupingId: clientCareGrouping.id
                }
            }
        });
        if (histories.length > 0) {
            version = _.max(_.map(histories, 'version')) || 0;
        }
        version++;
        const history = await this.dataAccess.genericUpsert({
            model: 'ClientCareGroupingHistory',
            data: {
                clientCareGroupingId: clientCareGrouping.id,
                version,
                createdBy: username,
                creationDate: new Date(),
                definition: clientCareGrouping
            }
        });
        return history;
    }
}
