import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';

@Injectable()
export class ClarifyInsightsDataEvaluationUserPasswordResetModalService {

    constructor(private dataAccess: NgDataAccess) {}

    async updatePassword(id: number, username: string, password: string) {
        const enableUserResponse = await this.dataAccess.genericMethod({
            model: "ClarifyInsightsDataEvaluationUser",
            method: "editUser",
            parameters: {
                userId: id,
                userInfo: {
                    active: true,
                    password: password
                }
            }
        });
        if (enableUserResponse.success) {
            await this.dataAccess.genericUpsert({
                model: "ClarifyInsightsDataEvaluationUser", 
                data: {
                    id: id,
                    active: true
                }
            })
        }
    }
}
