import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IHierarchyListStates, IHierarchyListService } from './hierarchyList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { AlertService } from 'client/app/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';

@Injectable()
export class HierarchyListService implements IHierarchyListService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog, private alertSvc: AlertService, private changeDetection: ChangeDetectorRef, private exportToExcelSvc: ExportToExcelService) {
        //
    }
    async initDelegate(states: IHierarchyListStates): Promise<object> {
        const newStates = _.cloneDeep(states);
        const observationCategoryHierarchiesAll = await this.dataAccess.genericFind({
            model: 'ObservationCategoryHierarchy'
        });

        states.observationCategoryHierarchies = _.orderBy(observationCategoryHierarchiesAll, ['category', 'category2', 'category3', 'category4']);
        states.table.data = states.observationCategoryHierarchies;
        return {};
    }

    setTablePageSize(states: IHierarchyListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.observationCategoryHierarchies.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IHierarchyListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IHierarchyListStates) {
        let data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        //wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.changeDetection.detectChanges();
            }, 1000);
        });
    }

}
