import { ChangeDetectorRef, Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { IValidValueQueriesListStates } from './validValueQueriesList.component.d';
import { ReactComponentBase } from '../reactComponentBase/reactComponentBase.component';
import { ValidValueQueriesListService } from './validValueQueriesList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../services/materialUtil.service';
import { UrlService } from '../../services/url.service';
import * as _ from 'lodash';
import { UIRouter } from '@uirouter/core';

@Component({
    selector: 'argos-valid-value-queries-list',
    templateUrl: './validValueQueriesList.component.html',
    providers: [ValidValueQueriesListService]
})

export class ValidValueQueriesListComponent extends ReactComponentBase<{}, IValidValueQueriesListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('queryPaginator') queryPaginator: MatPaginator;
    @ViewChild('cachePaginator') cachePaginator: MatPaginator;

    constructor(private svc: ValidValueQueriesListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService, private uiRouter: UIRouter) {
        super({
            pageSize: 50,
            validValueQueries: [],
            cacheGroupings: [],
            queryMatTable: new MatTableDataSource([]),
            cacheMatTable: new MatTableDataSource([])
        } as unknown as IValidValueQueriesListStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.refresh();
    }

    ngAfterViewInit() {
        this.states.queryMatTable.paginator = this.queryPaginator;
        this.states.cacheMatTable.paginator = this.cachePaginator;
    }

    addOrEditValidValueQuery(row: any, supportsCache: boolean) {
        this.svc.addOrEditValidValueQueryHandler(this.states, this.refresh, row, supportsCache);
    }

    truncateText(text: string) {
        return _.truncate(text, { length: 100 });
    }

    queryPageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        const tableShowRows = this.states.validValueQueries.slice(page, page + event.pageSize);
        this.states.queryMatTable = new MatTableDataSource(tableShowRows);
    }

    cachePageChange(event: any) {
        const page = event.pageIndex * event.pageSize;
        const tableShowRows = this.states.cacheGroupings.slice(page, page + event.pageSize);
        this.states.cacheMatTable = new MatTableDataSource(tableShowRows);
    }

    refresh: Function = () => {
        this.cdr.detectChanges();
    };
}
