import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IUserDbLoginSetupProps, IUserDbLoginSetupStates } from './userDbLoginSetup.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { UserDbLoginSetupService } from './userDbLoginSetup.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; 
import { MatSort, Sort } from '@angular/material/sort';  
import { MaterialUtil } from '../../../services/materialUtil.service';
import { MatPaginator } from '@angular/material/paginator';
import * as _ from 'lodash';

@Component({
    selector: 'argos-userDbLoginSetup',
    templateUrl: './userDbLoginSetup.component.html',
    providers: [UserDbLoginSetupService]
})

export class UserDbLoginSetupComponent extends ReactComponentBase<IUserDbLoginSetupProps, IUserDbLoginSetupStates, {}> implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('selectedDbMatTbSort', { static: false }) selectedDbMatTbSort: MatSort;
    @ViewChild('selectedDbPaginator', { static: false }) selectedDbPaginator: MatPaginator;
    _: any = _;
    constructor(private svc: UserDbLoginSetupService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<UserDbLoginSetupComponent>, private cdr: ChangeDetectorRef, 
        private matSvc: MaterialUtil) {
            super({  
                argosUser: {},
                defaultDisplayTableColumns: ['schemaName', 'tableName', 'selected'],
                selectedDbSettingName: 'userDbLoginSetup',
                selectedDbTablesMatTable: new MatTableDataSource([]),
                selectedDbTables: [],
                selectedDbTablesMatColumns: ['schemaName', 'tableName','selected'],
                filterObj: {},
                defaultPageSize: 20
            } as IUserDbLoginSetupStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.states.selectedDbTablesMatTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    ngAfterViewInit() { 
        this.states.selectedDbTablesMatTable.sort = this.selectedDbMatTbSort;
        this.states.selectedDbTablesMatTable.paginator = this.selectedDbPaginator;
    }

    filterChange(columnName: string, value: string, filterObj: any, gridTable: any) {
        filterObj[columnName] = value.trim().toLowerCase();
        gridTable.filter = JSON.stringify(filterObj);
        this.cdr.detectChanges();
    }

    isColumnHidden(columnName: string, matTableSettingName: string, defaultDisplayMatTableColumns: string[]) {
        let result = this.matSvc.isColumnHidden(columnName, matTableSettingName, defaultDisplayMatTableColumns);
        return result;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    closeModal() {
        this.dialogRef.close();
    }
 
    updateHiddenTableColumns(columnName: string, e: any) {
        this.states.hiddenTableColumns = _.map(_.filter(this.states.selectedDbTables, (col: any) => {
            return col.isHidden;
        }), (col: any) => {
            return col.columnName;
        });
    }

    updateSelctedRows(isSelected: boolean) {
        this.states.selectedDbTablesMatTable.filteredData.forEach((r: any) => {
            r.selected = isSelected;
        });
    }

    async create() {
        let result = await this.svc.create(this.states);
        this.dialogRef.close(result);
    }

    isSaveDisabled() {
        return (_.filter(this.states.selectedDbTables, { selected: true }).length === 0);
    }

}
