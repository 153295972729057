import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ReactComponentBase } from '../../../components/reactComponentBase/reactComponentBase.component';
import * as _ from 'lodash';
import { ArgosStoreService } from '../../../services/argosStore.service';
import { StateService, UIRouter } from '@uirouter/core';
import { LoginService } from 'client/app/services/loginService.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { UserRoleService } from '../../../services/userRole.service';
declare let Auth0Lock: any;
@Component({
    selector: 'argos-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.less']
})

export class CallbackComponent extends ReactComponentBase<{}, {}, {}> implements OnInit, OnChanges {
    constructor(private argosStore: ArgosStoreService, private state: StateService,
        private loginService: LoginService, private dataAccess: NgDataAccess,
        private uiRouter: UIRouter, private userRoleSvc: UserRoleService) {
        super();
    }

    async ngOnInit() {
        await this.loginService.init();
        // resumeAuth window.location.hash
        this.loginService.lock.resumeAuth(window.location.hash, async (err: any, authResult: any) => {

            if (err) {
                let errMsg = JSON.stringify(err);
                this.handleLoginError(errMsg);
            } else if (authResult && authResult.idToken) {
                this.loginService.lock.hide();
                this.loginService.clearCache(); //make sure the cached data is cleared
                this.argosStore.setItem('token', authResult.idToken);
                this.userRoleSvc.setUserProfile(authResult.idTokenPayload);
                
                let email = this.userRoleSvc.getEmailAddress();
                this.argosStore.setItem('username', email);
                
                //should validate user exists

                const saveState = this.argosStore.getItem('saveState');
                const userRole: string = await this.userRoleSvc.getUserRole();  //roleName is saved here in the argosStore if not already
                const userRolePermissionType: string = this.userRoleSvc.getUserRolePermissionType();
                const auth0UserId: string = this.userRoleSvc.getAuth0UserId();

                //first check does this role exits
                let userRoleConfig = await this.userRoleSvc.getUserRoleConfigByRoleName(userRole);
                if (_.isEmpty(userRoleConfig)) {
                    //disabled role should also fall into this condition
                    let errMsg = userRole + ' role not found for user ' + email;
                    this.handleLoginError(errMsg);
                } else {
                    await this.initUserSettings(email, userRole, userRoleConfig, userRolePermissionType, auth0UserId);   //load user db settings
                    if (saveState) {
                        try {
                            const saveParams = this.argosStore.getItem('saveParams');
                            const url = decodeURIComponent(saveParams);
                            this.uiRouter.locationService.url(url, true);

                            this.argosStore.removeItem('saveState');
                            this.argosStore.removeItem('saveParams');
                        } catch (err) {
                            // If the redirect didn't work, just go to the dashboard
                            console.log(err);
                            this.state.go('argos.dashboard');
                        }
                    }
                    else {

                        if (userRoleConfig && userRoleConfig?.redirectTo) {
                            this.state.go(userRoleConfig.redirectTo);
                        } else {
                            this.state.go('argos.dashboard');
                        }
                    }
                }
            } else {  
                this.handleLoginError('invalid authorization token');
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnInit();
    }

    handleLoginError(message: string) {
        console.error(`argos login error ${message}`);
        this.loginService.logout();
    }

    async initUserSettings(email: string, userRole: string, userRoleConfig: any, userRolePermissionType: string, auth0UserId: string) {

        try {
            //only sso enabled users are allowed
            let currentUser = await this.dataAccess.genericMethod({
                model: 'ArgosUser',
                method: 'initLoginUser',
                parameters: {
                    email: email, 
                    userRole: userRole, 
                    userRoleConfig: userRoleConfig, 
                    userRolePermissionType: userRolePermissionType,
                    authId: auth0UserId
                }
            });

            //if you add items remember to clean them up at loginService logout 
            this.argosStore.setItem('roles', currentUser.roles);    //old roles
            this.argosStore.setItem('gridSettings', currentUser.gridSettings);
            this.argosStore.removeItem('userRoleScreenAccess');

        } catch (err) {
            this.handleLoginError(JSON.stringify(err));
        }
    }
}
