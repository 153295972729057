
import { Component } from '@angular/core';

@Component({
    selector: 'argos-productBundles',
    template: `<ui-view></ui-view>`
})

export class ProductBundlesComponent {

}
