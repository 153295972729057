import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IDeleteCommandsModalProps, IDeleteCommandsModalStates } from './deleteCommandsModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { DeleteCommandsModalService } from './deleteCommandsModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'argos-deleteCommandsModal',
    templateUrl: './deleteCommandsModal.component.html',
    providers: [DeleteCommandsModalService]
})

export class DeleteCommandsModalComponent extends ReactComponentBase<IDeleteCommandsModalProps, IDeleteCommandsModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: DeleteCommandsModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<DeleteCommandsModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close('cancel');
    }

    copyToClipboard(text: string) {
        this.svc.copyToClipboardHandler(text);
    }
}
