import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IClarifyProviderRevisionListStates } from './clarifyProviderRevisionList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ClarifyProviderRevisionListService } from './clarifyProviderRevisionList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'argos-clarifyProviderRevisionList',
    templateUrl: './clarifyProviderRevisionList.component.html',
    providers: [ClarifyProviderRevisionListService]
})

export class ClarifyProviderRevisionListComponent extends ReactComponentBase<{}, IClarifyProviderRevisionListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    filterControls: { [key: string]: FormControl } = {};

    constructor(private svc: ClarifyProviderRevisionListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            clarifyProviderRevisions: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: [],
            matTableColumns: [],
            table: new MatTableDataSource([]),
            matTableSettingName: 'clarifyProviderRevisionList',
            currentRevisionGrainSelection: 'npi',
            selected: 'npi'
        } as unknown as IClarifyProviderRevisionListStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    refreshPage() {
        this.cdr.detectChanges();
        this.svc.refreshPage(this.states);
        this.ngOnInit();
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.initializeFilterControls();
        this.cdr.detectChanges();
    }

    initializeFilterControls() {
        console.log(this.states.matTableColumns);
        for (const colName of this.states.matTableColumns) {
          this.filterControls[colName] = new FormControl('');
          this.filterControls[colName].valueChanges.pipe(
            debounceTime(500)
          ).subscribe(value => {
            this.filterChange(colName, value);
          });
        }
      }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.svc.queryClarifyProvider(this.states, this.states.filterObj);
    }

    launchNpiSearch() {
        this.svc.launchNpiSearch(this.states);
    }

}
