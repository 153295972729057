// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#clarify-dashboard .box {
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/dashboard/dashboard/dashboard.component.less"],"names":[],"mappings":"AAAA;EAEM,mBAAA;AAAN","sourcesContent":["#clarify-dashboard {\n  .box {\n      margin-bottom: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
