import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable()
export class ObservationExceptionService {

    DATE_FORMAT = 'MMM Do YYYY, h:mm a';
    STATUS_MAPPINGS: any = {
        OPEN: 'Open',
        CLOSED: 'Closed',
        CLOSED_NOT_FIXABLE: 'Closed - Not Fixable'
    };

    dataSources: object[];
    public setDataSources(params: any) {
        this.dataSources = params;
    }
    public getDataSources() {
        return this.dataSources;
    }

    observationException: object;
    public setObservationException(params: any) {
        this.observationException = params;
    }
    public getObservationException() {
        return this.observationException;
    }

    public processObservationException(observationException: any, dataSources: any) {
        observationException.dataSource = _.toNumber(observationException.dataSource);
        const _dataSource: any = _.find(dataSources, { id: observationException.dataSource }) || {
            id: observationException.dataSource,
            name: observationException.dataSource,
            code: observationException.dataSource
        };
        const _dataSourceName = _dataSource && _dataSource.name || observationException.dataSource;
        const _createdDate = moment(observationException.createdDate);
        const _createdDateFmt = _createdDate.format(this.DATE_FORMAT);
        const _updatedDate = moment(observationException.updatedDate);
        const _updatedDateFmt = _updatedDate.format(this.DATE_FORMAT);
        const _status = this.STATUS_MAPPINGS[observationException.status];

        return _.assign(observationException, {
            _dataSource,
            _dataSourceName,
            _createdDate,
            _createdDateFmt,
            _updatedDate,
            _updatedDateFmt,
            _status
        });
    }

    public getStatusOptions() {
        return _.map(this.STATUS_MAPPINGS, function (value, key) {
            return {
                id: key,
                name: value,
            };
        });
    }

}
