import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MD5Service } from './md5.service';

@Injectable()
export class EnvironmentService {
    constructor(private md5: MD5Service) {
        //
    }
    public regions = _.map(['New England', 'Middle Atlantic', 'East North Central', 'West North Central', 'South Atlantic', 'East South Central', 'West South Central', 'Mountain', 'Pacific'], (r: string) => {
        const hash = this.md5.md5(r);
        const md5hash = _.join([
            hash.substring(0, 8),
            hash.substring(8, 12),
            hash.substring(12, 16),
            hash.substring(16, 20),
            hash.substring(20)
        ], '-');
        return {
            id: md5hash,
            name: r
        };
    });

    public environmentTypes = [{
        id: 'demo',
        title: 'Demo'
    }, {
        id: 'client_staging',
        title: 'Staging'
    }, {
        id: 'client_prod',
        title: 'Production'
    }, {
        id: 'dev_qa',
        title: 'Dev/QA'
    }];

    public hostingProviders = [{
        id: 'heroku',
        title: 'Heroku'
    }, {
        id: 'datica',
        title: 'Datica'
    }, {
        id: 'ecs',
        title: 'ECS'
    }];

    public hostingAccounts = [{
        id: 'cleardata',
        title: 'ClearDATA'
    }, {
        id: 'cleardata_east',
        title: 'ClearDATA East'
    }, {
        id: 'beijing',
        title: 'Beijing'
    }];

    public herokuVariables = [{
        name: 'AUTH0_CLIENT_ID',
        environmentProperty: 'auth0.auth0ClientId',
        hide: false
    }, {
        name: 'AUTH0_CONNECTION',
        environmentProperty: 'auth0.auth0Connection',
        hide: false
    }, {
        name: 'AUTH0_SECRET',
        environmentProperty: 'auth0.auth0Secret',
        hide: true
    }, {
        name: 'DATABASE_ADMIN_URL',
        environmentProperty: this.getDatabaseOwnerUrl,
        hide: true
    }, {
        name: 'DATABASE_URL',
        environmentProperty: this.getDatabaseUrl,
        hide: true
    }, {
        name: 'PRISM_LOGIN_IMG',
        environmentProperty: 'loginImage',
        hide: false
    }];

    public getDatabaseUrl(env: any) {
        return 'postgres://' + env.dbServer.dbUsername + ':' + env.dbServer.dbPassword + '@' + env.dbServer.dbHostname + ':' + (env.dbServer.dbPort ? env.dbServer.dbPort : '5432') + '/' + env.dbName + (env.dbServer.dbUseSSL ? '?ssl=true' : '');
    }

    public getDatabaseOwnerUrl(env: any) {
        return 'postgres://' + env.dbServer.dbOwnerUsername + ':' + env.dbServer.dbOwnerPassword + '@' + env.dbServer.dbHostname + ':' + (env.dbServer.dbPort ? env.dbServer.dbPort : '5432') + '/' + env.dbName + (env.dbServer.dbUseSSL ? '?ssl=true' : '');
    }
}
