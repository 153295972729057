import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IReferenceDataSourcesListStates } from './referenceDataSourcesList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ReferenceDataSourcesListService } from './referenceDataSourcesList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-referenceDataSourcesList',
    templateUrl: './referenceDataSourcesList.component.html',
    providers: [ReferenceDataSourcesListService]
})

export class ReferenceDataSourcesListComponent extends ReactComponentBase<{}, IReferenceDataSourcesListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: ReferenceDataSourcesListService, private cdr: ChangeDetectorRef, private matSvc: MaterialUtil) {
        super({
            referenceDataSources: [],
            filterObj: {},
            pageSize: 50,
            defaultDisplayMatTableColumns: ['status', 'tableName', 'module', 'source', 'ownerName', 'lastRunDate', 'lastPromotedDate', 'diffReport', 'aiAnalysis', 'priority', 'usage', 'bulkSelected'],
            matTableColumns: ['status', 'tableName','module', 'source', 'ownerName', 'lastRunDate', 'lastPromotedDate', 'diffReport', 'aiAnalysis', 'priority', 'usage', 'bulkSelected'],
            table: new MatTableDataSource([]),
            matTableSettingName: 'referenceDataSourcesList'
        } as unknown as IReferenceDataSourcesListStates);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
        this.states.table.sort = this.matTbSort;
    }

    async ngOnInit() {
        this.states.table.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    getColumnFilterList(columnName: string, data: any[]) {
        const result: string[] = this.matSvc.getColumnFilterList(columnName, data);
        return result;
    }

    filterChange(columnName: string, filterValue: string) {
        this.states.filterObj[columnName] = filterValue.trim().toLowerCase();
        this.states.table.filter = JSON.stringify(this.states.filterObj);
    }

    async getDiffReportUrl(referenceDataId: number, clarifyReportS3Path: string) {
        if (clarifyReportS3Path) {
            const url = await this.svc.getDiffReportUrl(referenceDataId);
            window.open(url, '_blank');
        }
    }

    launchAiAnalysis(tableName?: string, isTableQuestion?: boolean) {
        this.svc.launchAiAnalysis(tableName, isTableQuestion);
    }

    showUsageReport(tableName: string) {
        this.svc.showUsageReport(tableName);
    }

    runPipelines(isPromotion: boolean) {
        this.svc.runPipelines(this.states, isPromotion);
    }
}
