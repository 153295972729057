import { Component, OnChanges, SimpleChanges, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { IViewListModalProps, IViewListModalStates } from './viewListModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewListModalService } from './viewListModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';

@Component({
    selector: 'argos-viewListModal',
    templateUrl: './viewListModal.component.html',
    providers: [ViewListModalService]
})

export class ViewListModalComponent
    extends ReactComponentBase<IViewListModalProps, IViewListModalStates, {}>
    implements OnInit, OnChanges, AfterViewInit {

    @ViewChild('paginator') paginator: MatPaginator;

    constructor(private svc: ViewListModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewListModalComponent>) {
        super({
            pageSize: 20,
            table: new MatTableDataSource([])
        } as IViewListModalStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close({ eventName: 'cancel' });
    }

    done() {
        this.dialogRef.close(_.filter(this.states.rows, { selected: true }));
    }
    search() {
        this.svc.searchHandler(this.props, this.states);
    }

    ngAfterViewInit() {
        this.states.table.paginator = this.paginator;
    }

    searchMatTable(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.table.filter = filterValue.trim().toLowerCase();
    }
}
