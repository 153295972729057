import { Injectable } from '@angular/core';
import { IObservationExceptionListStates, IObservationExceptionListService } from './observationExceptionList.component.d';
import { ObservationExceptionService } from '../../../services/observationExceptionService.service';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core';

@Injectable()
export class ObservationExceptionListService implements IObservationExceptionListService {
    constructor(private observationExceptionService: ObservationExceptionService,
        private dataAccess: NgDataAccess, private state: StateService) {
        //
    }
    async initDelegate(states: IObservationExceptionListStates): Promise<object> {
        await this.init(states);
        return {};
    }

    async init(states: IObservationExceptionListStates) {
        this.observationExceptionService.setObservationException(null);
        this.observationExceptionService.setDataSources(null);
        states.dataSources = await this.dataAccess.genericFind({
            model: 'DataSource'
        });
        const observationExceptions = await this.dataAccess.genericFind({
            model: 'ObservationException'
        });
        states.observationExceptions.data = _.map(observationExceptions, (ox) => {
            return this.observationExceptionService.processObservationException(ox, states.dataSources);
        });
    }

    copyHandler(observationException: any, states: IObservationExceptionListStates) {
        const ox = this.cloneHandler(observationException, true);
        this.observationExceptionService.setObservationException(ox);
        this.observationExceptionService.setDataSources(states.dataSources);
        this.state.go('argos.ccgDesigner.observationException.add');
    }

    editHandler(observationException: any, states: IObservationExceptionListStates) {
        const ox = this.cloneHandler(observationException);
        this.observationExceptionService.setObservationException(ox);
        this.observationExceptionService.setDataSources(states.dataSources);
        this.state.go('argos.ccgDesigner.observationException.edit', { id: ox.id });
    }

    addHandler(states: IObservationExceptionListStates) {
        this.observationExceptionService.setObservationException(null);
        this.observationExceptionService.setDataSources(states.dataSources);
        this.state.go('argos.ccgDesigner.observationException.add');
    }

    cloneHandler(observationException: any, excludeId?: any) {
        const clone = _.cloneDeep(observationException);
        if (excludeId) {
            _.unset(clone, 'id');
        }

        return clone;
    }
}
