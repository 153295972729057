import { Injectable } from '@angular/core';
import { IProductConfigurationContentSyncStatusStates, IProductConfigurationContentSyncStatusService } from './productConfigurationContentSyncStatus.component.d';
import { NgDataAccess } from '../../services/dataAccess.service';
import * as _ from 'lodash';
import { ProductConfigurationContentSyncModalComponent } from './productConfigurationContentSyncModal/productConfigurationContentSyncModal.component';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Injectable()
export class ProductConfigurationContentSyncStatusService implements IProductConfigurationContentSyncStatusService {
    constructor(private dataAccess: NgDataAccess, private matDialog: MatDialog) {
        //
    }

    async initDelegate(states: IProductConfigurationContentSyncStatusStates): Promise<object> {
        states.moment = moment;
        await this.queryStatus(states);
        return {};
    }

    async queryStatus(states: IProductConfigurationContentSyncStatusStates) {
        const productConfigurationSyncLogs = await this.dataAccess.genericFind({
            model: 'ProductConfigurationSyncLog',
            filter: {
                order: 'startDate desc'
            }
        });
        states.productConfigurationSyncStatusTable = productConfigurationSyncLogs;
    }

    async startSync(states: IProductConfigurationContentSyncStatusStates) {
        this.dataAccess.genericMethod({
            model: 'ProductConfigurationSyncLog', method: 'startSync', parameters: {}
        });
        await this.queryStatus(states);
    }

    viewCounts(productConfigurationSyncLog: any) {
        this.showModal(productConfigurationSyncLog.counts, 'Content Type Counts');
    }

    viewContent(productConfigurationSyncLog: any) {
        this.showModal(productConfigurationSyncLog.content, 'Sync Content');
    }

    showModal(contentData: any, title: any) {
        let content = '';
        try {
            content = JSON.stringify(JSON.parse(contentData), undefined, 4);
        } catch (e) {
            content = JSON.stringify(contentData);
        }
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    content,
                    title
                }
            }
        };
        this.matDialog.open(ProductConfigurationContentSyncModalComponent, dialogConfig);
    }

}
