import { ChangeDetectorRef, Injectable } from '@angular/core';
import { IAutomatedGoldPulldownListProps, IAutomatedGoldPulldownListStates, IAutomatedGoldPulldownListService } from './automatedGoldPulldownList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { ArgosStoreService } from 'client/app/services/argosStore.service';
import * as moment from 'moment';

@Injectable()
export class AutomatedGoldPulldownListService implements IAutomatedGoldPulldownListService {
    constructor(private dataAccess: NgDataAccess, private cdr: ChangeDetectorRef, private argosStore: ArgosStoreService) {
        // lll
    }
    async initDelegate(states: IAutomatedGoldPulldownListStates): Promise<object> {
        const newStates = await this.init(states);
        return {};
    }

    changeDelegate(states: IAutomatedGoldPulldownListStates): object {
        this.initDelegate(states);
        return states;
    }

    async init(states: IAutomatedGoldPulldownListStates) {
        states.loading = true;
        this.cdr.detectChanges();
        try {
            await this.checkAllBranchesStatus(states, this.cdr);
            await this.getAllCreatedBranches(states, this.cdr);
            states.loading = false;
            return states;
        } catch (error) {
            states.loading = false;
            return states;
        }
    }

    async getAllCreatedBranches(states: IAutomatedGoldPulldownListStates, cdr: ChangeDetectorRef) {
        const results = await this.fetchBranchList();
        states.allCreatedBranchesTable = new MatTableDataSource(results);
        cdr.detectChanges();
    }

    async checkAllBranchesStatus(states: IAutomatedGoldPulldownListStates, cdr: ChangeDetectorRef) {
        try {
            const fetchBranchList = await this.fetchBranchList({ where: { 'branchInfo.branchState' : { neq: 'Branch Closed and Deleted.' } }});
            const filtererdBranchList= _.filter(fetchBranchList, list => moment(list.createdDate) <= moment().subtract(2, 'days'));
            if (_.size(filtererdBranchList)) {
                await this.dataAccess.genericMethod({
                    model: 'AutomatedPulldown',
                    method: 'checkAllBranchesStatus',
                    parameters: { branchNames : _.map(filtererdBranchList, 'branchName') }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async pulldownMetaData(states: IAutomatedGoldPulldownListStates, cdr: ChangeDetectorRef) {
        // states.loading = true;
        cdr.detectChanges();
        try {
            let logData = {};
            if (states.manual) {
                const runId = Math.floor(new Date().getTime() / 1000);
                logData = { runId, buildLog: runId + '-' + _.split(states.branchInfo.branchName, '/')[1] + '.out' };
            }
            const params = {
                metaDataTypeString: _.get(states.branchInfo, 'metaDataTypeString'),
                manual: states.manual,
                branchName: _.get(states.branchInfo, 'branchName'),
                baseBranch: _.get(states.branchInfo, 'baseBranch', 'master'),
                commitMessage: _.get(states.branchInfo, 'commitMessage'),
                selectedFileString: _.join(states.branchInfo.selectedFilesForGit, ' '),
            };
            const manualBranch = await this.dataAccess.genericUpsert({
                model: 'AutomatedPulldown',
                data: {
                    branchName: params.branchName,
                    createdBy: this.argosStore.getItem('username') ?? 'Argos user',
                    branchInfo: { baseBranch: params.baseBranch, commitMessage: params.commitMessage, manual: params.manual, circleCIURL: 'N/A', prURL: 'N/A', branchState: 'N/A', metadataTypes: _.size(states.selectedMetaDataTypes) ? states.selectedMetaDataTypes : 'All' },
                    logData
                }
            });
            if (manualBranch) {
                const result = await this.fetchBranchList();
                states.allCreatedBranchesTable = new MatTableDataSource(result);
                // states.loading = false;
                cdr.detectChanges();
            }
            const pulldown = await this.dataAccess.genericMethod({
                model: 'AutomatedPulldown',
                method: 'pushAutomatedPulldownBranchToAtlas',
                parameters: {
                    metaDataTypeString: params.metaDataTypeString,
                    manual: states.manual,
                    branchName: params.branchName,
                    baseBranch: params.baseBranch,
                    selectedFileString: params.selectedFileString,
                    runId: _.get(logData, 'runId'),
                    buildLog: _.get(logData, 'buildLog'),
                    commitMessage: params.commitMessage
                }
            });
            states.metaDataTypeString = '';
            states.selectedMetaDataTypes = [];
            states.checkPullDownStatusTimer = window.setInterval(async () => {
                const result = await this.fetchBranchList();
                if (_.find(result, ['branchName', params.branchName])) {
                    const branch = _.find(result, ['branchName', params.branchName]);
                    if (_.includes(branch.status, 'Success') || _.includes(branch.status, 'Failed')) {
                        window.clearInterval(states.checkPullDownStatusTimer);
                        // states.loading = false;
                        cdr.detectChanges();
                        swal({
                            title: _.includes(branch.status, 'Success') ? 'Success' : 'Error',
                            text: _.includes(branch.status, 'Success') ? 'Pulldown completed successfully' : branch.status,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: 'Ok'
                        }).then(async (isConfirm: any) => {
                            states.allCreatedBranchesTable = new MatTableDataSource(result);
                            cdr.detectChanges();
                        });
                    }
                }
            }, 5000);
        } catch (error) {
            const result = await this.fetchBranchList();
            states.allCreatedBranchesTable = new MatTableDataSource(result);
            states.metaDataTypeString = '';
            states.selectedMetaDataTypes = [];
            states.loading = false;
            cdr.detectChanges();
        }
    }

    async fetchBranchList(filterCriteria?: any) {
        try {
            const result = await this.dataAccess.genericFind({
                model: 'AutomatedPulldown',
                filter: {
                    order: 'createdDate desc',
                    ...filterCriteria
                }
            });
            return result;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteBranchAndPR(row: any, states: IAutomatedGoldPulldownListStates, cdr: ChangeDetectorRef) {
        const result = await this.dataAccess.genericMethod({
            model: 'AutomatedPulldown',
            method: 'closeAndDeleteBranch',
            parameters: {
                branchName: _.get(row, 'branchName')
            }
        });
        if (result) {
            swal({
                title: 'Success',
                text: `Closed and deleted branch ${_.get(row, 'branchName')} successfully`  ,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Ok'
            }).then(async (isConfirm: any) => {
                const result = await this.fetchBranchList();
                states.allCreatedBranchesTable = new MatTableDataSource(result);
                cdr.detectChanges();
            });
        }

    }

    async deleteRow(row: any, states: IAutomatedGoldPulldownListStates, cdr: ChangeDetectorRef) {
        swal({
            title: 'Are you sure?',
            text: `Delete branch ${_.get(row, 'branchName')}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (isConfirm: any) => {
            if (isConfirm.value) {
                const result = await this.dataAccess.genericMethod({
                    model: 'AutomatedPulldown',
                    method: 'deleteBranchRow',
                    parameters: {
                        branchName: _.get(row, 'branchName')
                    }
                });
                if (result) {
                    swal({
                        title: 'Success',
                        text: `Deleted branch ${_.get(row, 'branchName')} successfully`  ,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Ok'
                    }).then(async (isConfirm: any) => {
                        const result = await this.fetchBranchList();
                        states.allCreatedBranchesTable = new MatTableDataSource(result);
                        cdr.detectChanges();
                    });
                }
            }
        });
    }
    async showLogs(row: any, states: IAutomatedGoldPulldownListStates, cdr: ChangeDetectorRef) {
        try {
            const result =  await this.dataAccess.genericMethod({
                model: 'AutomatedPulldown',
                method: 'getScreenStatus',
                parameters: {
                    runId: _.get(row, 'logData.runId'),
                    buildLog: _.get(row, 'logData.buildLog')
                }
            });
            if (result && _.get(result, 'status')) {
                states.logsText = _.get(result, 'status');
            }
        } catch (error) {
            const result = await this.fetchBranchList();
            states.allCreatedBranchesTable = new MatTableDataSource(result);
            states.loading = false;
            cdr.detectChanges();
        }

    }
}