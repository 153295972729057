import { Injectable, ChangeDetectorRef } from '@angular/core';
import { IStageSourceTableListStates, IStageSourceTableListService } from './stageSourceTableList.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { UIRouter, StateService } from '@uirouter/core';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { MatTableSettingsModelComponent } from '../../matTableSettingsModel/matTableSettingsModel.component';
import { ViewDeequModalComponent } from '../deequModal/viewDeequModal.component';
import swal from 'sweetalert2';


@Injectable()
export class StageSourceTableListService implements IStageSourceTableListService {
    constructor(private dataAccess: NgDataAccess, private exportToExcelSvc: ExportToExcelService, 
        private matDialog: MatDialog, private cdr: ChangeDetectorRef, private uiRouter: UIRouter, private state: StateService) {
        //
    }

    async initDelegate(states: IStageSourceTableListStates): Promise<object> {
        await this.activate(states);
        return {};
    }

    async activate(states: IStageSourceTableListStates) {
        states.lodash = _;
        if (!this.uiRouter.globals.params.stage && !this.uiRouter.globals.params.source) {
            const stageSourceTable = await this.dataAccess.genericFind({
                model: 'TeradromeStage',
                filter: {
                    include: {
                        relation: 'viewTeradromeDeequStats',
                    }
                }
            });
    
            // Map through stageSourceTable and sum tableCount and testCount in the viewTeradromeDeequStats object
            states.stageSourceTable = _.map(stageSourceTable, function(sst: any) {
                return {
                    ...sst,
                    tableCount: _.sumBy(sst.viewTeradromeDeequStats, function (o: any) { return Number(o.tableCount); }),
                    testCount: _.sumBy(sst.viewTeradromeDeequStats, function (o: any) { return Number(o.testCount); })
    
                };
            });
            
            states.table.data = _.sortBy(states.stageSourceTable, 'stageName');
        } else {
            const table = await this.dataAccess.genericFind({
                model: 'TeradromeTable',
                filter: {
                    include: {
                        relation: 'teradromeStage',
                    },
                    where: {
                        id: this.uiRouter.globals.params.tableId
                    }
                }
            });
            states.stage = this.uiRouter.globals.params.stage;
            states.source = this.uiRouter.globals.params.source;
            const filterLevel = (states.stage.dataSourceAgnosticSchema && states.stage) || states.source ? 'table' : states.stage ? 'source' : 'stage';
            this.filterList(states, filterLevel, undefined, filterLevel === 'source' ? states.stage.id : undefined, 'table', states.stage ? states.stage.dataSourceAgnosticSchema : undefined);
        }
        
    }

    async filterList(states: IStageSourceTableListStates, level: any, filterValue?: any, filterId?: number, currentLevel = '', agnosticSchema?: boolean) {
        // This method is called when a user clicks a stage in list, if stage is data source agnostic then the list to should filtered to TeradromeTable. If it is not data source schema agnostic then the list should be filtered to TeradromeStageSource
        let list = [];
        if (level === 'stage') {
            list = await this.dataAccess.genericFind({
                model: 'TeradromeStage',
                filter: {
                    include: {
                        relation: 'viewTeradromeDeequStats',
                    }
                }
            });
            states.defaultDisplayMatTableColumns = ['stageName', 'dataSourceAgnosticSchema', 'tableCount', 'testCount'];
            states.matTableColumns = ['stageName', 'dataSourceAgnosticSchema', 'tableCount', 'testCount'];
            states.stage = undefined;
            states.source = undefined;
        } else if (level === 'source') {
            list = await this.dataAccess.genericFind({
                model: 'TeradromeStageSource',
                filter: {
                    include: {
                        relation: 'viewTeradromeDeequStats',
                    },
                    where: {
                        teradromeStageId: filterId ? filterId : states.source.id
                    }
                }
            });
            states.defaultDisplayMatTableColumns = ['sourceName', 'tableCount', 'testCount', 'lastReported'];
            states.matTableColumns = ['sourceName', 'tableCount', 'testCount', 'lastReported'];
            if (currentLevel === 'stage') {
                states.stage = {id: filterId, name: filterValue, agnosticSchema};
            }
            states.source = undefined;
        } else if (level === 'table') {
            if (currentLevel === 'stage') {
                states.stage = {id: filterId, name: filterValue, agnosticSchema};
            } else if (currentLevel === 'source') {
                states.source = {id: filterId, name: filterValue};
            } else {
                states.tableName = undefined;
            }
            list = await this.dataAccess.genericFind({
                model: 'TeradromeTable',
                filter: {
                    include: {
                        relation: 'viewTeradromeDeequStats',
                    },
                    where: {
                        teradromeStageId: states.stage.id,
                        teradromeStageSourceId: states.stage.agnosticSchema || states.stage.dataSourceAgnosticSchema ? null : states.source.id
                    }
                }
            });
            states.defaultDisplayMatTableColumns = ['tableName', 'testCount', 'lastReported', 'descriptionButton'];
            states.matTableColumns = ['tableName', 'testCount', 'lastReported', 'descriptionButton'];
        }
        states.tableName = undefined;

        if (currentLevel === 'stage') {
            const sources = await this.dataAccess.genericFind({
                model: 'TeradromeStageSource',
                filter: {
                    where: {
                        teradromeStageId: filterId
                    },
                    fields: ['id', 'sourceName']
                }
            });
            // Exclude datasources that end in _sample
            states.sources = _.filter(sources, function(s: any) {
                return !s.sourceName.endsWith('_sample');
            });
        }

        let showDeleteColumn = false;

        states.table.data = list = _.sortBy(_.map(list, function(sst: any) {
            // Check to see if last reported is greater than a year from today, if so then we'll show delete button and color table in red
            const deletable = new Date(sst.lastReported) < new Date(new Date().setFullYear(new Date().getFullYear() - 1));
            if (deletable && level === 'table') {
                showDeleteColumn = true;
            }
            return {
                ...sst,
                deleteButton: showDeleteColumn,
                tableCount: _.sumBy(sst.viewTeradromeDeequStats, function (o: any) { return Number(o.tableCount); }),
                testCount: _.sumBy(sst.viewTeradromeDeequStats, function (o: any) { return Number(o.testCount); }),
                lastReported: new Date(sst.lastReported).toISOString().slice(0, 10)

            };
        }), states.defaultDisplayMatTableColumns[0]);

        if (showDeleteColumn) {
            // remove descriptionButton
            states.defaultDisplayMatTableColumns.pop();
            states.defaultDisplayMatTableColumns.push('deleteButton');
            states.matTableColumns.pop();
            states.matTableColumns.push('deleteButton');
        }

        // Clear filters
        states.filterObj = {};
        states.table.filter = JSON.stringify(states.filterObj);

        this.cdr.detectChanges();
    }

    async viewTests(states: IStageSourceTableListStates, teradromeTableId: any, tableName: any, dataSourceFilter?: any) {
        this.state.go('argos.data.stageSourceTable.columnList', {tableId: teradromeTableId, tableName, dataSourceFilter, source: states.source, stage: states.stage});
    }

    setTablePageSize(states: IStageSourceTableListStates) {
        states.pageSize = 10;
        const possibleRowsToShow = Math.round((window.innerHeight - 388) / 40);
        if (possibleRowsToShow > states.pageSize) {
            states.pageSize = possibleRowsToShow;
        }
        states.tableShowRows = states.stageSourceTable.slice(0, states.pageSize);
        states.table = new MatTableDataSource(states.tableShowRows);
    }

    exportToExcel(states: IStageSourceTableListStates) {

        const data = states.table.filteredData;
        const columns = states.matTableColumns;

        this.exportToExcelSvc.exportToFile(states.matTableSettingName, columns, data);
    }

    openMatTableSettings(states: IStageSourceTableListStates) {
        const data = { 
            tableColumns: states.matTableColumns,
            defaultDisplayTableColumns: states.defaultDisplayMatTableColumns,
            settingName: states.matTableSettingName
        };
        const dialogRef = this.matDialog.open(MatTableSettingsModelComponent, {
            data,
        });
  
        // wait a second after closing to refresh the screen
        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        });
    }

    async deleteTable(states: IStageSourceTableListStates, tableId: any) {
        const deequTestIds = await this.dataAccess.genericFind({
            model: 'TeradromeDeequTest',
            filter: {
                where: {
                    teradromeTableId: tableId
                },
                fields: ['id']
            }
        });
        await Promise.all(
            _.map(deequTestIds, (id: any) => {
                return this.dataAccess.genericMethod({
                    model: 'TeradromeDeequTest', method: 'destroyById',
                    parameters: { id: id.id }
                }
                );
            })
        );

        const columnIds = await this.dataAccess.genericFind({
            model: 'TeradromeColumn',
            filter: {
                where: {
                    teradromeTableId: tableId
                },
                fields: ['id']
            }
        });
        await Promise.all(
            _.map(columnIds, (id: any) => {
                return this.dataAccess.genericMethod({
                    model: 'TeradromeColumn', method: 'destroyById',
                    parameters: { id: id.id }
                }
                );
            })
        );

        await this.dataAccess.genericMethod({
            model: 'TeradromeTable', method: 'destroyById',
            parameters: { id: tableId }
        }
        );

        this.filterList(states, 'table', states.stage.name, states.stage.id, '', states.stage.agnosticSchema);
    }

    addDescription(states: IStageSourceTableListStates, row: any) {
        swal({
            title: `${row.description ? 'Edit' : 'Add'} Description for ${row.tableName}`,
            input: 'textarea',
            inputValue: row.description,
            inputAttributes: {
                style: 'color: black; min-height: 300px;',
            },
            showCancelButton: true,
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#57C84D' 
        }).then(async (isConfirm: any) => {      
            if (isConfirm.value) {
                const table = await this.dataAccess.genericUpsert({
                    model: 'TeradromeTable',
                    data: {
                        id: row.id,
                        description: isConfirm.value
                    }
                });
                row.description = table.description;
                const index = _.findIndex(states.table.data, {id: row.id});
                states.table.data[index] = row;

                this.cdr.detectChanges();
            }
        });
    }


}
