import { Component, OnChanges, SimpleChanges, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IViewPayerRatesMetaHistoryProps, IViewPayerRatesMetaHistoryStates } from './viewPayerRatesMetaHistory.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ViewPayerRatesMetaHistoryService } from './viewPayerRatesMetaHistory.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'argos-viewPayerRatesMetaHistory',
    templateUrl: './viewPayerRatesMetaHistory.component.html',
    providers: [ViewPayerRatesMetaHistoryService]
})

export class ViewPayerRatesMetaHistoryComponent extends ReactComponentBase<IViewPayerRatesMetaHistoryProps, IViewPayerRatesMetaHistoryStates, {}> implements OnInit, OnChanges {
    constructor(private svc: ViewPayerRatesMetaHistoryService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<ViewPayerRatesMetaHistoryComponent>, private cdr: ChangeDetectorRef) {
        super({
            showDiffs: false,
            payerRatesMeta: {}
        } as IViewPayerRatesMetaHistoryStates);
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
        this.cdr.detectChanges();
    }

    closeModal() {
        this.dialogRef.close({});
    }

    viewDiffs(version: any) {
        this.svc.viewDiffs(version, this.states);
        this.cdr.detectChanges();
    }
}
