import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IPipelineHistoryDetailStates, IHintData } from './pipelineHistoryDetail.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { PipelineHistoryDetailService } from './pipelineHistoryDetail.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';

@Component({
    selector: 'argos-pipelineHistoryDetail',
    templateUrl: './pipelineHistoryDetail.component.html',
    styleUrls: ['./pipelineHistoryDetail.component.less'],
    providers: [PipelineHistoryDetailService]
})

export class PipelineHistoryDetailComponent extends ReactComponentBase<{}, IPipelineHistoryDetailStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: PipelineHistoryDetailService, private cdr: ChangeDetectorRef) {
        super({
            allPipelineSteps: [],
            allPipelines: new MatTableDataSource([])
        } as unknown as IPipelineHistoryDetailStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    getStepDetail(pipelineId: any) {
        const results = _.filter(this.states.allPipelineSteps, (p) => {
            return p.pipelineHistoryId === pipelineId;
        });
        return results;
    }

    displayAverageRunTime(rawRunTime: any) {
        return Math.floor(rawRunTime) + ' hr ' + Math.round((rawRunTime - Math.floor(rawRunTime)) * 60.0) + ' min';
    }

    openEmrLink(id: string) {
        window.open('https://us-west-2.console.aws.amazon.com/emr/home?region=us-west-2#/clusterDetails/' + id, '_blank');
    }

    openModal(error: any) {
        this.svc.openModal(error);
    }

    openOptModal(hintText: IHintData[], states: any, pipelineId: string) {
        this.svc.openOptModal(hintText, states, pipelineId);
    }

    viewChart() {
        this.svc.viewChartHandler(this.states);
    }

    ngAfterViewInit() {
        this.states.allPipelines.sort = this.matTbSort;
    }

    filterPipelines(event: Event) {
        const filterValue = (event.target as HTMLInputElement).textContent;
        this.states.allPipelines.filterPredicate = (data: any, filter: string) => {
            if (filter.trim().toLowerCase() === 'all') {
                return true;
            }
            else if (data.pipelineStatus.trim().toLowerCase() === filter.trim().toLowerCase()) {
                return true;
            }
            else {
                return false;
            }
        };
        this.states.allPipelines.filter = filterValue;
    }

}
