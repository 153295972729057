import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IEditProgramObservationModalProps, IEditProgramObservationModalStates } from './editProgramObservationModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EditProgramObservationModalService } from './editProgramObservationModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-editProgramObservationModal',
    templateUrl: './editProgramObservationModal.component.html',
    providers: [EditProgramObservationModalService]
})

export class EditProgramObservationModalComponent
    extends ReactComponentBase<IEditProgramObservationModalProps, IEditProgramObservationModalStates, {}> implements OnInit, OnChanges {
    constructor(private svc: EditProgramObservationModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<EditProgramObservationModalComponent>) {
        super({
            ccgTypes: [],
            programEpisodeObservationsToDelete: []
        } as unknown as IEditProgramObservationModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    closeModal() {
        this.dialogRef.close({
            programEpisodeObservationsToDelete: this.states.programEpisodeObservationsToDelete,
            observation: this.states.observation
        });
    }

    selectionChanged(row: any) {
        this.svc.selectionChanged(row, this.states);
    }

    unselectAll() {
        this.svc.unselectAll(this.states);
    }

    selectAll(programModelName: any) {
        this.svc.selectAll(programModelName, this.states);
    }

    selectAllCcgType(typeId: any) {
        this.svc.selectAllCcgType(typeId, this.states);
    }
}
