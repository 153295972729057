import { Injectable } from '@angular/core';
import { NgDataAccess } from '../../../services/dataAccess.service';
import { IViewReferenceListHistoryModalProps, IViewReferenceListHistoryModalStates, IViewReferenceListHistoryModalService } from './viewReferenceListHistoryModal.component.d';
import * as _ from 'lodash';
@Injectable()
export class ViewReferenceListHistoryModalService implements IViewReferenceListHistoryModalService {
    constructor(private dataAccess: NgDataAccess) {
        //
    }
    async initDelegate(props: IViewReferenceListHistoryModalProps, states: IViewReferenceListHistoryModalStates): Promise<object> {
        states.listId = props.listId;
        states.listName = props.listName;
        states.parentTags = props.parentTags;
        states.selectedParentTag = '';
        states.lodash = _;
        states.fullListHistory = await this.dataAccess.genericMethod({
            model: 'ReferenceDataListHistory', method: 'find', parameters: {
                filter: {
                    where: {
                        listId: states.listId
                    }
                }
            }
        });
        states.listHistory = [...states.fullListHistory];
        return {};
    }

    changeDelegate(oldProps: IViewReferenceListHistoryModalProps, newProps: IViewReferenceListHistoryModalProps, states: IViewReferenceListHistoryModalStates): object {
        this.initDelegate(newProps, states);
        return {};
    }

    showDetail(version: any, states: IViewReferenceListHistoryModalStates) {
        states.showDetail = true;
        states.currentRow = _.find(states.listHistory, { version });
    }

    filterHistory(states: IViewReferenceListHistoryModalStates) {
        states.listHistory = _.filter(states.fullListHistory, { parentTagId: states.selectedParentTag });
    }
}
