// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#clarify-chat-history .widget {
  padding-bottom: 0px;
}
#clarify-chat-history .column-header-container {
  position: sticky;
  top: 0px;
  background-color: skyblue;
}
#clarify-chat-history .selected-question {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/atlasClaraChatHistory/atlasClaraChatHistory.component.less"],"names":[],"mappings":"AAAA;EAEQ,mBAAA;AAAR;AAFA;EAKQ,gBAAA;EACA,QAAA;EACA,yBAAA;AAAR;AAPA;EAUQ,eAAA;AAAR","sourcesContent":["#clarify-chat-history {\n    .widget {\n        padding-bottom: 0px;\n    }\n    .column-header-container {\n        position: sticky;\n        top: 0px;\n        background-color: skyblue;\n    }\n    .selected-question {\n        font-size: 16px;;\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
