import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IEpisodeGroupingListStates } from './episodeGroupingList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EpisodeGroupingListService } from './episodeGroupingList.component.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UrlService } from '../../../services/url.service';
import * as _ from 'lodash';

@Component({
    selector: 'argos-episodeGroupingList',
    templateUrl: './episodeGroupingList.component.html',
    providers: [EpisodeGroupingListService]
})

export class EpisodeGroupingListComponent
    extends ReactComponentBase<{}, IEpisodeGroupingListStates, {}>
    implements OnInit, AfterViewInit {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('matTbSort') matTbSort = new MatSort();
    _: any = _;

    constructor(private svc: EpisodeGroupingListService, private cdr: ChangeDetectorRef,
        private matSvc: MaterialUtil, private urlService: UrlService) {
        super({
            pageSize: 50,
            episodeGroupings: [],
            filterObj: {},
            defaultDisplayMatTableColumns: ['copyButton', 'groupByAttribute', 'groupByName', 'careGroupings', 'productBundleList', 'excludeFromSyncText', 'usageCount', 'viewCount', 'lastViewed', 'markForDeletionButton'],
            matTableColumns: ['copyButton', 'groupByAttribute', 'groupByName', 'careGroupings', 'productBundleList', 'excludeFromSyncText',
                'filterAttribute','filterName','groupIdSql','groupNameSql','groupCategory','displayGroup','displayGroup2',
                'displayGroup3','displayGroup4','description','defaultVisibility','allowGrouping','joinEntity','pluralName','mapBoundary',
                'columnName','validValues','cacheable','orderBy','notes','filterOnly','usesCrossJoin','patientAttribute','benchmarkableAcrossCareGroupings',
                'usesArrayFiltering','inputRegex','inputErrorMessage','usageGuide','calculateBy','sequenceType','tablePartitionMappingName','validValuesQueryName',
                'usageCount', 'viewCount', 'lastViewed', 'lastUpdatedBy', 'lastUpdateDate', 'markForDeletionButton'],
            episodeGroupingTable: new MatTableDataSource([]),
            matTableSettingName: 'episodeGroupingList'
        } as IEpisodeGroupingListStates);
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.episodeGroupingTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.matTableSettingName);
        await this.svc.initDelegate(this.states);
        if (queryString) {
            this.states.episodeGroupingTable.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.states.episodeGroupingTable.paginator = this.paginator;
        this.states.episodeGroupingTable.sort = this.matTbSort;
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.filterObj = _.omitBy(this.states.filterObj, value => _.isEmpty(value));
        this.states.episodeGroupingTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.episodeGroupingTable.filter);
    }

    searchEpisodeGroup(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.states.episodeGroupingTable.filter = filterValue.trim().toLowerCase();
    }

    viewEnvironments(row: any) {
        this.svc.viewEnvironments(row);
    }

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    markForDeletion(row: any) {
        this.svc.markForDeletion(row);
    }

    undelete(row: any) {
        this.svc.undelete(row);
    }

    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        const result = this.matSvc.isColumnHidden(columnName, this.states.matTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }
}
