// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-detail {
  background-color: transparent;
}
.product-detail .td-url {
  display: flex;
}
.product-detail .td-url .launch-url {
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-left: 3px;
}
.pass-percentage {
  color: green;
}
.fail-percentage {
  color: red;
}
.pending-percentage {
  color: cornflowerblue;
}
.performance-product-container {
  padding: 8px 16px;
}
.performance-detail {
  padding: 4px 8px;
}
#peformance-results {
  padding: 30px;
}
.modal-body {
  position: relative;
  padding: 0px;
}
.table-data {
  font-size: 12px;
}
.arrow-up {
  color: red;
  padding: 5px;
  font-weight: bold;
  font-size: 15px;
}
.arrow-down {
  color: green;
  padding: 5px;
  font-weight: bold;
  font-size: 15px;
}
.no-change {
  color: blue;
  padding: 5px;
  font-weight: bold;
  font-size: 15px;
}
.symboltext {
  font-weight: bold;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./client/app/components/platformReleaseTestsResults/testResultsDashboard/qaTestResultDashboard.component.less"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;AAFA;EAGI,aAAA;AAEJ;AALA;EAKM,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AAGN;AAEA;EACE,YAAA;AAAF;AAGA;EACE,UAAA;AADF;AAIA;EACE,qBAAA;AAFF;AAKA;EACE,iBAAA;AAHF;AAMA;EACE,gBAAA;AAJF;AAOA;EACE,aAAA;AALF;AAQA;EACE,kBAAA;EACA,YAAA;AANF;AASA;EACE,eAAA;AAPF;AAUA;EACE,UAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AARF;AAWA;EACE,YAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AATF;AAYA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AAVJ;AAaA;EACE,iBAAA;EACA,eAAA;AAXF","sourcesContent":[".product-detail {\n  background-color: transparent;\n  .td-url {\n    display: flex;\n    .launch-url {\n      width: 18px;\n      height: 18px;\n      font-size: 18px;\n      margin-left: 3px;\n    }\n  }\n}\n\n.pass-percentage {\n  color: green;\n}\n\n.fail-percentage {\n  color: red;\n}\n\n.pending-percentage {\n  color: cornflowerblue;\n}\n\n.performance-product-container {\n  padding: 8px 16px;\n}\n\n.performance-detail {\n  padding: 4px 8px;\n}\n\n#peformance-results {\n  padding: 30px;\n}\n\n.modal-body {\n  position: relative;\n  padding: 0px;\n}\n\n.table-data {\n  font-size: 12px;\n}\n\n.arrow-up {\n  color: red;\n  padding: 5px;\n  font-weight: bold;\n  font-size: 15px;\n}\n\n.arrow-down {\n  color: green;\n  padding: 5px;\n  font-weight: bold;\n  font-size: 15px;\n}\n\n.no-change {\n    color: blue;\n    padding: 5px;\n    font-weight: bold;\n    font-size: 15px;\n}\n\n.symboltext {\n  font-weight: bold;\n  font-size: 15px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
