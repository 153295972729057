import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IUserListStates } from './userList.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { UserListService } from './userList.component.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LoginService } from '../../../services/loginService.service';
import { StateService, UIRouter } from '@uirouter/core';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialUtil } from '../../../services/materialUtil.service';
import { UserRoleService } from '../../../services/userRole.service';
import { UrlService } from '../../../services/url.service';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDbLoginSetupComponent } from '../../users/userDbLoginSetup/userDbLoginSetup.component';
import * as _ from 'lodash';
import swal from 'sweetalert2';
@Component({
    selector: 'argos-userList',
    templateUrl: './userList.component.html',
    providers: [UserListService]
})

export class UserListComponent extends ReactComponentBase<{}, IUserListStates, {}> implements OnInit, AfterViewInit {
    @ViewChild('matTbSort') matTbSort = new MatSort();
    @ViewChild('paginator') paginator: MatPaginator;
    _: any = _;

    constructor(private svc: UserListService, private loginServiceHandler: LoginService, private state: StateService, 
                private cdr: ChangeDetectorRef, private matSvc: MaterialUtil, private userRoleSvc: UserRoleService, 
                private urlService: UrlService, private matDialog: MatDialog, private uiRouter: UIRouter) {
        super({
            editingMode: false,
            users: [],
            userRoles: [],
            usersTable: new MatTableDataSource([]),
            originalUserData: [],
            rolePermissionType: '',
            pageSize: 50,
            filterObj: {},
            defaultDisplayMatTableColumns: ['editButton', 'email', 'argosUserRoleName',  'ecsAccess', 'ecsAdmin', 'ssoBlocked', 'dbServerLoginName', 'historyButton'],
            matTableColumns: ['editButton', 'email', 'argosUserRoleName', 'ecsAccess', 'ecsAdmin', 'ssoBlocked', 'dbServerLoginName', 'historyButton'],
            environmentMatTableSettingName: 'userList',
        } as unknown as IUserListStates);
    }

    ngAfterViewInit() {
        this.states.usersTable.sort = this.matTbSort;
        this.states.usersTable.paginator = this.paginator;
        this.cdr.detectChanges();
    }

    async ngOnInit() {
        const queryString = this.urlService.getQueryStringFromUrl();
        this.states.usersTable.filterPredicate = this.matSvc.matTableColumnAndFilterPredicate();
        this.states.pageSize = this.matSvc.getUserDefaultPageSize(this.states.environmentMatTableSettingName);
        await this.svc.initDelegate(this.states);
        this.states.rolePermissionType = this.userRoleSvc.getUserRolePermissionType();
        if (queryString) {
            this.states.usersTable.filter = queryString;
            this.states.filterObj = JSON.parse(queryString);
        }
        this.cdr.detectChanges();
    }

    isRoleDropDownDisabled(row: any) {
        let result = true;

        if (this.states.rolePermissionType === 'db') {
            result = !row.editMode;
        }

        return result;
    }

    filterChange(columnName: string, event: any) {
        this.states.filterObj[columnName] = event.target.value.trim().toLowerCase();
        this.states.usersTable.filter = JSON.stringify(this.states.filterObj);
        this.urlService.updateUrlWithQueryString(this.states.usersTable.filter);
    }

    editRow(row: any, edit?: boolean) {
        this.states.editingMode = !!edit;
        row.editMode = !!edit;
    }

    async saveRow(row: any) {
        swal({
            text: "Please enter the reason or jira ticket for this change",
            input: 'text',
            confirmButtonText: 'Save',
            showCancelButton: true,
            inputAttributes: {
                style: 'color: black;',
            }
        }).then(async (isConfirm: any) => {
            if (isConfirm.value && isConfirm.value.length > 0) {
                row.notes = isConfirm.value;
                await this.svc.saveRow(row, this.states);
                this.cdr.detectChanges();
            }
        }); 
    }

    //pagination functions
    openMatTableSettings() {
        this.svc.openMatTableSettings(this.states);
    }

    isColumnHidden(columnName: string) {
        let result = this.matSvc.isColumnHidden(columnName, this.states.environmentMatTableSettingName, this.states.defaultDisplayMatTableColumns);
        return result;
    }

    viewHistory(row: any) {
        this.svc.viewHistory(row, this.states);
    }

    createDbLogin(row: any) {

        swal({
            title: `Are you sure you want to create staging db access for ${row.email}?`,
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#57C84D', 
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (response) => {
            if(response.value) {
                this.showDbLogin(row);
            }
        });
    }

    showDbLogin(row: any) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    argosUser: row
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(UserDbLoginSetupComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(async (event: any) => {
            if (event) {
                let msg = 'failed';
                if (event.isSuccessful) {
                    msg = 'successful';
                    row.dbServerLoginName = event.dbLoginName;
                    await this.svc.saveRow(row, this.states);
                }
                swal({
                    title: `DB user ${msg}`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#57C84D', 
                    confirmButtonText: 'Ok'
                }).then(async (response) => {
                    //refresh screen
                    this.state.go(this.uiRouter.globals.current, this.uiRouter.globals.params, { reload: true, inherit: false });
                });
            }
        });
    }

}
