import { Component, OnChanges, SimpleChanges, OnInit, Inject } from '@angular/core';
import { IEnvironmentEditCareGroupingModalProps, IEnvironmentEditCareGroupingModalStates } from './environmentEditCareGroupingModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EnvironmentEditCareGroupingModalService } from './environmentEditCareGroupingModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CONST } from '../../../constants/globals';

@Component({
    selector: 'argos-environmentEditCareGroupingModal',
    templateUrl: './environmentEditCareGroupingModal.component.html',
    providers: [EnvironmentEditCareGroupingModalService]
})

export class EnvironmentEditCareGroupingModalComponent
    extends ReactComponentBase<IEnvironmentEditCareGroupingModalProps, IEnvironmentEditCareGroupingModalStates, {}> implements OnInit, OnChanges {

    private debounceTimer: any;

    constructor(private svc: EnvironmentEditCareGroupingModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<EnvironmentEditCareGroupingModalComponent>) {
        super({
            overrides: [
                {
                    property: 'baseTable',
                    title: 'Base Table',
                    override: false,
                    type: 'table'
                }, {
                    property: 'predictionsTable',
                    title: 'Predictions Table',
                    override: false,
                    type: 'table'
                }, {
                    property: 'stagingBaseTable',
                    title: 'Staging Base Table',
                    override: false,
                    type: 'table'
                }, {
                    property: 'stagingPredictionsTable',
                    title: 'Staging Predictions Table',
                    override: false,
                    type: 'table'
                }, {
                    property: 'explanationsTable',
                    title: 'Explanations Table',
                    override: false,
                    type: 'table'
                }, {
                    property: 'clarifyProviderTable',
                    title: 'Clarify Provider Table (Customer-specific)',
                    override: false,
                    type: 'table'
                }, {
                    property: 'clarifyProviderYearlyTable',
                    title: 'Clarify Provider Yearly Table (Customer-specific)',
                    override: false,
                    type: 'table'
                }, {
                    property: 'useLatestClarifyProviderData',
                    title: 'Use Latest Clarify Provider Data',
                    override: false,
                    defaultValue: false,
                    type: 'checkbox'
                }],
            propertyErrorsDefault: []
        } as unknown as IEnvironmentEditCareGroupingModalStates);
        this.props = dialogData.props;
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.props, this.states);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.svc.changeDelegate(changes.props.previousValue, changes.props.currentValue, this.states);
    }

    close() {
        this.dialogRef.close('cancel');
    }

    save() {
        this.svc.saveHandler(this.states);
        this.dialogRef.close('saved');
    }

    careGroupingChanged(updateMultiSelectOptions?: boolean) {
        this.svc.careGroupingChangedHandler(this.states, updateMultiSelectOptions);
    }

    clausesChanged() {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(() => {
            this.svc.clausesChangedHandler(this.states);
        }, 1000);        
    }

    checkTableExistence(tableName: string, property: string) {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(() => {
            if (tableName) {
                this.svc.checkTableExistence(this.states, tableName, property);
            }
        }, 500);
    }

    copyDefaults() {
        this.svc.copyDefaultsHandler(this.states);
    }

    allowPiiClicked() {
        this.svc.allowPiiClickedHandler(this.states);
    }

    overrideClicked(override: any) {
        if (override.type === 'checkbox') {
            this.svc.overrideClickedHandler(this.states, override);
        }
    }

    errorExistsDefault(fieldName: any) {
        return (this.states.propertyErrorsDefault.indexOf(fieldName) >= 0);
    }

    isValid() {
        return this.svc.isValidHandler(this.states);
    }
}
