// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-container {
    position: relative;
    width: 100%;
    pointer-events: none;
  }`, "",{"version":3,"sources":["webpack://./client/app/components/claraIqReleaseNotes/claraIqReleaseNotes.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,oBAAoB;EACtB","sourcesContent":[".markdown-container {\n    position: relative;\n    width: 100%;\n    pointer-events: none;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
