import { Injectable } from '@angular/core';
import { IDeleteCommandsModalProps, IDeleteCommandsModalStates, IDeleteCommandsModalService } from './deleteCommandsModal.component.d';
import * as _ from 'lodash';
declare const $: any;

@Injectable()
export class DeleteCommandsModalService implements IDeleteCommandsModalService {
    initDelegate(props: IDeleteCommandsModalProps, states: IDeleteCommandsModalStates): object {
        states.fileDeletion = props.fileDeletion;
        states.sqlDeletion = props.sqlDeletion;
        states.argosDeletion = props.argosDeletion;
        return {};
    }

    changeDelegate(oldProps: IDeleteCommandsModalProps, newProps: IDeleteCommandsModalProps, states: IDeleteCommandsModalStates): object {
        if (!oldProps) {
            return {};
        }
        return {};
    }

    copyToClipboardHandler(text: string) {
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(text).select();
        document.execCommand('copy');
        $temp.remove();
    }
}
