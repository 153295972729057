import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ScratchDatabaseCleanupServices } from './scratchDatabaseCleanup.component.service';
import { IScratchDatabaseCleanupStates } from '../scratchDatabaseCleanupLandingPage/scratchDatabaseCleanup.component.d';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SetTableConfigModalComponent } from '../setTablesConfigModal/setTablesConfigModal.component';
import { MatSort} from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'argos-scratch-database-cleanup-list',
    templateUrl: './scratchDatabaseCleanup.component.html',
    providers: [ScratchDatabaseCleanupServices]
})

export class ScratchDatabaseCleanupComponent extends ReactComponentBase<{}, IScratchDatabaseCleanupStates, {}>
    implements OnInit, AfterViewInit {
        @ViewChild('matTbSort') matTbSort = new MatSort();

    constructor(private svc: ScratchDatabaseCleanupServices, private cdr: ChangeDetectorRef, private matDialog: MatDialog) {
        super({
            currentQueue: new MatTableDataSource<Object>()
        }
        );
    }

    ngOnInit() {
        this.svc.init(this.states);
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.states.currentQueue.sort = this.matTbSort;
    }

    async setTableDeletionConfig(database: string) {
        const tablesMarkedDelete = this.states.currentQueue.data.filter((item: any) => (item.database == database)).map((table:any) => table.tableRemovalConfig)[0];
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                        database: database,
                        tablesMarkedDelete: tablesMarkedDelete
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(SetTableConfigModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe(() => {
            this.ngOnInit()
            this.ngAfterViewInit()
        });
    }
}


