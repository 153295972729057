import { Input, Output, EventEmitter, Directive } from '@angular/core';
import * as _ from 'lodash';

@Directive()
export class ReactComponentBase<P, S, O> {
    private _props: P;
    private _states: S;
    @Input('props')
    set props(val: P) {
        this._props = _.mergeWith(this._props, val, (src) => {
            if (_.isArray(src)) {
                return src;
            }
            return undefined;
        });
    }

    get props(): P {
        return this._props;
    }

    set states(val: S) {
        this.setStates(val);
    }

    get states(): S {
        return this._states;
    }

    @Output() changed: EventEmitter<O> = new EventEmitter();

    constructor(defaultStates?: S, defaultProps?: P) {
        this._props = {} as P;
        this._states = {} as S;
        if (defaultProps) {
            this._props = defaultProps;
        }
        if (defaultStates) {
            this._states = defaultStates;
        }
    }

    setStates(data: { [key: string]: any; }, cb?: () => void) {
        if (_.isObject(data)) {
            _.forEach(_.keys(data as any), (key) => {
                if (_.isObject((data as any)[key])) {
                    (this.states as any)[key] = _.cloneDeep((data as any)[key]);
                } else {
                    (this._states as any)[key] = (data as any)[key];
                }
            });
            if (cb) {
                cb();
            }
        } else {
            console.error('setStates param should be an object: ', data);
        }
    }
}
