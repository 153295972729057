import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IEpisodeGroupingEditStates } from './episodeGroupingEdit.component.d';
import { UrlService } from '../../../services/url.service';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { EpisodeGroupingEditService } from './episodeGroupingEdit.component.service';
import * as _ from 'lodash';
@Component({
    selector: 'argos-episodeGroupingEdit',
    templateUrl: './episodeGroupingEdit.component.html',
    providers: [EpisodeGroupingEditService]
})

export class EpisodeGroupingEditComponent
    extends ReactComponentBase<{}, IEpisodeGroupingEditStates, {}> implements OnInit {
    constructor(private svc: EpisodeGroupingEditService, private cdr: ChangeDetectorRef, private urlService: UrlService) {
        super({
            saveInProgress: false,
            episodeGrouping: {
                defaultVisibility: 'Disable'
            },
            newValidValue: {},
            tablePartitionMappings: [],
            episodeGroupingForm: {}
        } as IEpisodeGroupingEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
        if (this.urlService.checkShowViewHistoryFromUrl()) {
            this.viewHistory();
        }
    }

    async groupByAttributeChange() {
        await this.svc.checkExistingEpisodeGroupingGroupByAttribute(this.states);
    }

    async groupByNameChange() {
        await this.svc.checkExistingEpisodeGroupingGroupByName(this.states);
    }


    async filterAttributeChange() {
        await this.svc.checkExistingEpisodeGroupingFilterAttribute(this.states);
    }

    viewHistory() {
        this.svc.viewHistoryHnadler(this.states);
    }

    testSQL(isSaving = false) {
        this.svc.testSQLHandler(this.states, isSaving);
    }

    checkedUsesArrayFiltering() {
        this.svc.checkedUsesArrayFilteringHandler(this.states);
    }

    clearJoinEntity() {
        this.states.episodeGrouping.joinEntity = null;
    }

    editJoinEntity(joinEntity: string) {
        this.svc.editJoinEntityHandler(joinEntity);
    }

    editValidValuesQuery(validValuesQueryName: string) {
        this.svc.editValidValuesQueryHandler(validValuesQueryName);
    }

    async fetchValidValues() {
        await this.svc.fetchValidValuesHandler(this.states);
        this.cdr.detectChanges();
    }

    addValidValue() {
        this.svc.addValidValueHandler(this.states);
        this.cdr.detectChanges();
    }

    deleteValidValue(row: any) {
        this.svc.deleteValidValueHandler(row, this.states);
        this.cdr.detectChanges();
    }

    getSelectedProductBundleCount() {
        return _.filter(this.states.productBundles, { enabled: true }).length;
    }

    async save(isClose = false) {
        await this.svc.saveHandler(this.states, isClose);
    }

    clearTablePartitionMappingName() {
        this.states.episodeGrouping.tablePartitionMappingName = null;
        this.cdr.detectChanges();
    }

    clearValidValuesQuery() {
        this.states.episodeGrouping.validValuesQueryName = null;
        this.cdr.detectChanges();
    }

}
