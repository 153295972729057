import { Component, OnInit, Inject } from '@angular/core';
import { IOptimizationHintModalProps, IOptimizationHintModalStates } from './optimizationHintModal.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { OptimizationHintModalService } from './optimizationHintModal.component.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'argos-optimizationHintModal',
    templateUrl: './optimizationHintModal.component.html',
    providers: [OptimizationHintModalService]
})

export class OptimizationHintModalComponent extends ReactComponentBase<IOptimizationHintModalProps, IOptimizationHintModalStates, {}> implements OnInit {
    constructor(private svc: OptimizationHintModalService, @Inject(MAT_DIALOG_DATA) dialogData: any,
        private dialogRef: MatDialogRef<OptimizationHintModalComponent>) {
        super();
        this.props = dialogData.props;
    }

    ngOnInit() {
        this.svc.initDelegate(this.props, this.states);
    }

    close() {
        this.dialogRef.close('cancel');
    }
}
