import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { IReferenceDataSourcesEditStates } from './referenceDataSourcesEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ReferenceDataSourcesEditService } from './referenceDataSourcesEdit.component.service';
import { ValidationService } from '../../../services/validationService.service';
import * as _ from 'lodash';


@Component({
    selector: 'argos-referenceDataSourcesEdit',
    templateUrl: './referenceDataSourcesEdit.component.html',
    providers: [ReferenceDataSourcesEditService]
})

export class ReferenceDataSourcesEditComponent extends ReactComponentBase<{}, IReferenceDataSourcesEditStates, {}> implements OnInit, OnDestroy {
    constructor(private svc: ReferenceDataSourcesEditService, private cdr: ChangeDetectorRef, private validationSrv: ValidationService) {
        super({
            settings: [{
                name: 'purpose',
                title: 'Purpose',
            }, {
                name: 'custodian',
                title: 'Custodian'
            }, {
                name: 'licenseIssues',
                title: 'License Issues'
            }, {
                name: 'sourceOfRecord',
                title: 'Source of Record'
            }, {
                name: 'rawFormat',
                title: 'Raw Format'
            }, {
                name: 'approximateSize',
                title: 'Approximate Size'
            }, {
                name: 'updateFrequency',
                title: 'Update Frequency'
            }, {
                name: 'processing',
                title: 'Processing'
            }, {
                name: 'destinations',
                title: 'Destinations'
            }, {
                name: 'currentProcessing',
                title: 'Current Processing'
            }, {
                name: 'jiraTickets',
                title: 'Jira Tickets'
            }, {
                name: 'category',
                title: 'Category'
            }, {
                name: 'versionInUse',
                title: 'Version In Use'
            }, {
                name: 'codeSetDependency',
                title: 'Code Set Dependency'
            }, {
                name: 'owner',
                title: 'Owner'
            }, {
                name: 'lastUpdatedBy',
                title: 'Last Updated By'
            }, {
                name: 'lastUpdated',
                title: 'Last Updated'
            }, {
                name: 'openIssues',
                title: 'Open Issues'
            }],
            saveInProgress: false,
            referenceDataSource: {
                settings: {}
            },
            pipelineTriggerText: '',
            avgRuntime: undefined,
            pipelineIsPromotion: undefined
        } as unknown as IReferenceDataSourcesEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        if (this.states.intervalId) {
            clearInterval(this.states.intervalId);
        }
    }

    ownerChanged() {
        this.svc.ownerChanged(this.states);
    }

    save() {
        this.svc.save(this.states);
    }

    saveNewUrlCustomClass() {
        this.svc.saveNewUrlCustomClass(this.states);
    }

    tableNameChangeHandler() {
        this.svc.tableNameChangeHandler(this.states);
    }

    isSaveDisabled() {
        const reqProperties = ['tableName', 'priority'];
        if (this.states.referenceDataSource.referenceDataClass.source === 'url_custom' && !this.states.referenceDataSource.id) {
            reqProperties.push('className');
        }
        const reqClassProperties = ['source', 'ownerId'];
        const propertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.referenceDataSource, reqProperties);
        const classPropertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.referenceDataSource.referenceDataClass, reqClassProperties);
        return propertyErrors.length + classPropertyErrors.length > 0;
    }

    goToGithub() {
        window.open(this.states.refDataRepoLink, '_blank');
    }

    setClass(className: any) {
        if (!this.states.addedClassNames || !this.states.addedClassNames.includes(className)) {
            this.states.referenceDataSource.referenceDataClass = _.find(this.states.fullReferenceDataClasses, { className });
            this.svc.ownerChanged(this.states);
        }
    }

    viewHistory() {
        this.svc.viewHistory(this.states);
    }

    addNewTeraUser() {
        this.svc.addNewTeraUser(this.states);
    }

    deleteReferenceTable() {
        this.svc.deleteReferenceTable(this.states);
    }

    canColumnBePrimaryKey(columnName: string) {
        return this.svc.canColumnBePrimaryKey(columnName);
    }
    triggerPipeline(isPromotion: boolean) {
        this.svc.triggerPipeline(this.states, isPromotion);
    }

    openAirflowUrl() {
        this.svc.openAirflowUrl(this.states);
    }
}
