import { Injectable } from '@angular/core';
import { IPreviewReleaseNotesProps, IPreviewReleaseNotesStates, IPreviewReleaseNotesService } from './previewReleaseNotes.component.d';
import { NgDataAccess } from '../../../services/dataAccess.service';
import * as _ from 'lodash';
import { UIRouter } from '@uirouter/core';

@Injectable()
export class PreviewReleaseNotesService implements IPreviewReleaseNotesService {
    constructor(private dataAccess: NgDataAccess, private uiRouter: UIRouter) {
        //
    }

    async initDelegate(props: IPreviewReleaseNotesProps, states: IPreviewReleaseNotesStates): Promise<IPreviewReleaseNotesStates> {
        const newStates = await this.init(states);
        return newStates;
    }

    //   momentFromNow(date) {
    //     return moment(new Date(date)).fromNow();
    // }

    async init(initStates: IPreviewReleaseNotesStates): Promise<IPreviewReleaseNotesStates> {
        const states = _.cloneDeep(initStates);
        states.release = await this.dataAccess.genericMethod({
            model: 'Release', method: 'findById', parameters: {
                id: this.uiRouter.globals.params.id
            }
        });
        states.release.releaseDate = new Date(states.release.releaseDate);

        const releaseNotes = await this.dataAccess.genericFind({
            model: 'ReleaseNote',
            filter: {
                where: {
                    releaseId: this.uiRouter.globals.params.id
                }
            }
        });
        states.newFeatures = _.filter(releaseNotes, { itemType: 'Story' });
        states.improvements = _.filter(releaseNotes, { itemType: 'Improvement' });
        states.bugs = _.filter(releaseNotes, { itemType: 'Bug' });
        return states;
    }

}
