import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IColumnUsageStates } from './columnUsage.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ColumnUsageService } from './columnUsage.component.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'argos-columnUsage',
    templateUrl: './columnUsage.component.html',
    providers: [ColumnUsageService]
})

export class ColumnUsageComponent extends ReactComponentBase<{}, IColumnUsageStates, {}> implements OnInit {
    constructor(private svc: ColumnUsageService, private cdr: ChangeDetectorRef) {
        super({
            dataMatTable: new MatTableDataSource([])
        } as IColumnUsageStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    fetchResults() {
        this.svc.fetchResults(this.states, this.refreshPage);
    }

    refreshPage: Function = () => {
        this.cdr.detectChanges();
    };

    exportToExcel() {
        this.svc.exportToExcel(this.states);
    }

    exportForTeraCatalog() {
        this.svc.exportForTeraCatalog(this.states);
    }

    selectedEnvironmentChanged(data: any) {
        this.svc.environmentSelectedHander(data, this.states);
    }
}
