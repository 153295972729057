import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IAdvanceMetaDataEditStates } from './advanceMetaDataEdit.component.d';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { AdvanceMetaDataEditService } from './advanceMetaDataEdit.component.service';
import { ValidationService } from '../../../services/validationService.service';

@Component({
    selector: 'argos-advanceMetaDataEdit',
    templateUrl: './advanceMetaDataEdit.component.html',
    providers: [AdvanceMetaDataEditService]
})

export class AdvanceMetaDataEditComponent extends ReactComponentBase<{}, IAdvanceMetaDataEditStates, {}> implements OnInit {
    constructor(private svc: AdvanceMetaDataEditService, private cdr: ChangeDetectorRef, private validationSrv: ValidationService) {
        super({} as unknown as IAdvanceMetaDataEditStates);
    }

    async ngOnInit() {
        await this.svc.initDelegate(this.states);
        this.cdr.detectChanges();
    }

    save() {
        this.svc.save(this.states);
    }

    changeDataSource() {
        this.svc.setDataSourceFields(this.states);
    }

    uploadFile(file: any) {
        this.svc.uploadFile(file, this.states);
    }

    isSaveDisabled() {
        let saveDisabled = false;
        this.states.errorMessage = '';

        let reqProperties = this.states.runType === 'electiveProcedure' ? ['dataSourceId', 'headerAllowedAmtField', 'lineAllowedAmtField'] : ['dataSourceId', 'baselineStartDate', 'baselineEndDate' , 'srEpisodeAmtField'];
        if (this.states.runType === 'electiveProcedure' && this.states.advanceMetaData.useRollingYear) {
            reqProperties = reqProperties.concat(['baselineStartDate', 'baselineEndDate']);
        }

        const propertyErrors = this.validationSrv.validateAllRequiredProperties(this.states.advanceMetaData, reqProperties);
        if (propertyErrors.length > 0) {
            saveDisabled = true;
            const allMissingArgs = this.states.lodash.map(this.states.lodash.map(propertyErrors, 'propertyName'), (ma: string) => this.states.lodash.startCase(ma));
            this.states.errorMessage += 'The following arguments are missing: ' + allMissingArgs.join(', ') + '. ';
        }

        if (this.states.runType === 'specialistReferral') {
            if (this.states.advanceMetaData.baselineStartDate > this.states.advanceMetaData.baselineEndDate) {
                saveDisabled = true;
                this.states.errorMessage += 'Baseline Start Date must be before Baseline End Date. ';
            }
            if (this.states.years && this.states.advanceMetaData.baselineStartDate && this.states.advanceMetaData.baselineEndDate &&
                (!this.states.years.includes(new Date(this.states.advanceMetaData.baselineStartDate).getFullYear()) || !this.states.years.includes(new Date(this.states.advanceMetaData.baselineEndDate).getFullYear()))) {
                saveDisabled = true;
                this.states.errorMessage += `Baseline Start Date and Baseline End Date must occur between ${Math.min(...this.states.years)} and ${Math.max(...this.states.years)}. `;
            }
        }

        return saveDisabled;
    }

    checkChangeUseRollingYear(event: Event) {
        const rollingYearCheckBox = event.target as HTMLInputElement;
        if (!rollingYearCheckBox.checked) {
            this.states.advanceMetaData.applyMemberVolumeWeight = false;
        }
    }

    downloadExcel() {
        this.svc.downloadExcel(this.states);
    }

}
