import { ChangeDetectorRef, OnInit, Component } from '@angular/core';
import { ReactComponentBase } from '../../reactComponentBase/reactComponentBase.component';
import { ICreateDataReleaseStates } from './createDataRelease.component.d';
import { CreateDataReleaseServices } from './createDataRelease.component.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ReleaseAddDagModalComponent } from '../releaseAddDagModal/releaseAddDagModal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewReleaseDagConfigModalComponent } from '../viewReleaseDagConfigModal/viewReleaseDagConfigModal.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, switchMap} from 'rxjs/operators';

@Component({
    selector: 'argos-data-release-manager-create',
    templateUrl: './createDataRelease.component.html',
    styleUrls: ['./createDataRelease.component.less'],
    providers: [CreateDataReleaseServices]
})

export class CreateDataReleaseComponent extends ReactComponentBase<{}, ICreateDataReleaseStates, {}> implements OnInit {
    constructor(private svc: CreateDataReleaseServices, private cdr: ChangeDetectorRef, private matDialog: MatDialog, private SnackBar: MatSnackBar) {
        super({
            dagsInRelease: []
        } as unknown as ICreateDataReleaseStates);
    }

    myCustomerControl = new FormControl('customer');
    myClarifyControl = new FormControl('clarify_data');
    filteredCustomerDags: Observable<string[]>;
    filteredClarifyDags: Observable<string[]>;
    activeDags: string[] = [];
    activeCustomerDags: string[] = [];
    activeClarifyDags: string[] = [];
    showSpinner = false;

    private async _filterCustomer(value: string): Promise<string[]> {
        const filterValue = value.toLowerCase();
        return this.activeCustomerDags.filter((option: string) => option.toLowerCase().includes(filterValue));
    }

    private async _filterClarify(value: string): Promise<string[]> {
        const filterValue = value.toLowerCase();
        return this.activeClarifyDags.filter((option: string) => option.toLowerCase().includes(filterValue));
    }

    async ngOnInit() {
        this.showSpinner = true;
        await this.svc.initDelegate(this.states);
        this.activeDags = await this.svc.getActiveDags();
        this.activeCustomerDags = await this.svc.filterDagsByType('customer', this.activeDags);
        this.activeClarifyDags = await this.svc.filterDagsByType('clarify_data', this.activeDags);
        this.filteredClarifyDags = this.myClarifyControl.valueChanges.pipe(
            startWith(''),
            switchMap((value: string) => this._filterClarify(value || '')),
        );
        this.filteredCustomerDags = this.myCustomerControl.valueChanges.pipe(
            startWith(''),
            switchMap((value: string) => this._filterCustomer(value || '')),
        );
        this.showSpinner = false;
        this.cdr.detectChanges();
    }

    viewReleaseDagConfig(dagConfig: string) {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    dagConfig
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ViewReleaseDagConfigModalComponent, dialogConfig);
    }

    updateReleaseDate(event: any) {
        this.svc.updateReleaseDateDelegate(this.states, event.value);
    }

    removeDagFromRelease(dagId: string) {
        this.svc.removeDagFromReleaseDelegate(this.states, dagId);
    }

    async createRelease() {
        if (!this.states.releaseDate) {
            this.SnackBar.open('Please Select a Release Date', 'Close', {
                duration: 3000
            });
            return;
        }
        if (!this.states.releaseName) {
            this.SnackBar.open('Please Enter a Release Name', 'Close', {
                duration: 3000
            });
            return;
        }
        const existingReleaseNames = await this.svc.getReleaseNames();
        if (existingReleaseNames.includes(this.states.releaseName)) {
            this.SnackBar.open('Release Name Already Exists. Please Enter a New Name', 'Close', {
                duration: 3000
            });
            return;
        }
        this.svc.createReleaseDelegate(this.states, this.activeDags);
    }

    async addDagToRelease(dagId: string) {
        if (this.states.dagsInRelease.map((dag) => dag.dagId).includes(dagId)) {
            this.SnackBar.open('Dag Already in Release. Remove the existing dag first', 'Close', {
                duration: 3000
            });
            return;
        }
        const dialogConfig: MatDialogConfig = {
            panelClass: 'argos-modal-panel',
            autoFocus: false,
            hasBackdrop: false,
            data: {
                props: {
                    dagId,
                    dagConfig: ''
                }
            }
        };
        const modalInstance: MatDialogRef<any> = this.matDialog.open(ReleaseAddDagModalComponent, dialogConfig);
        modalInstance.afterClosed().subscribe((result) => {
            if (!result.dagConfig || !result.dagId) {
                return;
            }
            this.svc.addDagToReleaseDelegate(this.states, result.dagId, result.dagConfig);
            this.cdr.detectChanges();
        });
    }

    openAirflowLink(dagId: string) {
        const airflowBaseUrl = this.states.astronomerBaseUrl;
        const airflowDagLink = `${airflowBaseUrl}/${dagId}/grid`;
        window.open(airflowDagLink, '_blank');
    }

    cancelAlert() {
        // no alert here for now
    }
}
